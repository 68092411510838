import React from 'react';
import Board from '../../../../../domain/board/Board';
import useTeamStoryStoryBoard from '../hook/useTeamStoryStoryBoard';
import ImgFeedBoardContentCard from '../../../../../component/board/ImgFeedBoardContentCard';

interface TeamStoryBoardTabProps {
    teamNo: string;
    boardOfTeam?: Board[];
}

function TeamStoryBoardTab(props: TeamStoryBoardTabProps) {
    const storyBoardContents = useTeamStoryStoryBoard(props.teamNo, props.boardOfTeam);
    return (
        <div className="home-wrap">
            <div className="home-title">
                <span>팀 스토리</span>
                <span>전체보기</span>
            </div>
            <div className="clear"></div>
            <div className="home-box">
                <ul className="teamstory-list"> 
                    {
                        storyBoardContents.map((storyBoardContent, index) => {
                            return <ImgFeedBoardContentCard key={index} boardContent={storyBoardContent}></ImgFeedBoardContentCard>
                        })
                    }
                </ul>
                <div className="clear"></div>
            </div>
        </div>
    )
}

export default TeamStoryBoardTab;