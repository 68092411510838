import React from 'react';
import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('basalPhysical')
export default class BasalPhysical {
    @JsonProperty('graspingPower', [String], true)
    graspingPower: string[] = ['', '', ''];
    @JsonProperty('relativeGrip', [String], true)
    relativeGrip: string[] = ['', '', ''];
    @JsonProperty('sitUp', [String], true)
    sitUp: string[] = ['', '', ''];
    @JsonProperty('crossSitUp', [String], true)
    crossSitUp: string[] = ['', '', ''];
    @JsonProperty('repeatJump', [String], true)
    repeatJump: string[] = ['', '', ''];
    @JsonProperty('buckling', [String], true)
    buckling: string[] = ['', '', ''];
    @JsonProperty('shuttleRun10', [String], true)
    shuttleRun10: string[] = ['', '', ''];
    @JsonProperty('shuttleRun15', [String], true)
    shuttleRun15: string[] = ['', '', ''];
    @JsonProperty('shuttleRun20', [String], true)
    shuttleRun20: string[] = ['', '', ''];
    @JsonProperty('jumpInPlace', [String], true)
    jumpInPlace: string[] = ['', '', ''];
    @JsonProperty('repeatSideJump', [String], true)
    repeatSideJump: string[] = ['', '', ''];
    @JsonProperty('flightTime', [String], true)
    flightTime: string[] = ['', '', ''];
    @JsonProperty('reactionTime', [String], true)
    reactionTime: string[] = ['', '', ''];
    @JsonProperty('agilityTest', [String], true)
    agilityTest: string[] = ['', '', ''];
    @JsonProperty('coordination', [String], true)
    coordination: string[] = ['', '', ''];
}
