import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import CommonHeader from './common/CommonHeader';
import SubBoardComponent from './common/SubBoardComponents';
import { RootState } from '../moldules/index';
import MainSlider from './common/MainSliderComponent';

import Slider from 'react-slick';

function MainLayout() {
    const dispatch = useDispatch();
    const userState = useSelector((state: RootState) => state.userSessionReducer);
    let isDisplay = { display: 'block' };
    let isNotDisplay = { display: 'none' };
    let dot = 'dot';
    let dotActive = 'dot activee';
    const [displayDot01, setDisplayDot01] = useState<any>(dot);
    const [displayDot02, setDisplayDot02] = useState<any>(dot);
    const [displayDot03, setDisplayDot03] = useState<any>(dotActive);
    const [display01, setDisplay01] = useState<any>(isNotDisplay);
    const [display02, setDisplay02] = useState<any>(isNotDisplay);
    const [display03, setDisplay03] = useState<any>(isDisplay);
    useEffect(() => {}, []);

    const screenHandler = (evt: any) => {
        const { id } = evt.target;
        setDisplayDot01(dot);
        setDisplayDot02(dot);
        setDisplayDot03(dot);
        setDisplay01(isNotDisplay);
        setDisplay02(isNotDisplay);
        setDisplay03(isNotDisplay);
        if (id === 'slide1') {
            setDisplayDot01(dotActive);
            setDisplay01(isDisplay);
        } else if (id === 'slide2') {
            setDisplayDot02(dotActive);
            setDisplay02(isDisplay);
        } else if (id === 'slide3') {
            setDisplayDot03(dotActive);
            setDisplay03(isDisplay);
        }
    };
    return (
        <div>
            <section className="main-visual">
                <CommonHeader></CommonHeader>
            </section>
            <div className="un-rolling">
                {/* <Slider {...settings}> */}
                <div className="un-visual01">
                    <div className="visual" data-wow-delay="0.0s"></div>
                    <div className="r_title">
                        <p className="s-title">ICT&#38;헬스케어 기반 스포츠산업</p>
                        <p className="big-title">
                            방문스포츠팀
                            <br />
                            정보통합 플랫폼
                        </p>
                    </div>
                </div>
            </div>
            <div className="rolling">
                {/* <Slider {...settings}> */}
                <div className="visual01" style={display01}>
                    <div className="visual" data-wow-delay="0.0s"></div>
                    <div className="r_title">
                        <p className="s-title">ICT&#38;헬스케어 기반 스포츠산업</p>
                        <p className="big-title">
                            방문스포츠팀
                            <br />
                            정보통합 플랫폼
                        </p>
                    </div>
                </div>
                <div className="visual02" style={display02}>
                    <div className="visual" data-wow-delay="0.0s"></div>
                    <div className="r_title">
                        <p className="s-title">ICT&#38;헬스케어 기반 스포츠산업</p>
                        <p className="big-title">
                            방문스포츠팀
                            <br />
                            정보통합 플랫폼
                        </p>
                    </div>
                </div>
                <div className="visual03" style={display03}>
                    <div className="visual" data-wow-delay="0.0s"></div>
                    <video
                        controls={false}
                        playsInline
                        width="100%"
                        height="90%"
                        autoPlay
                        muted
                        loop
                        style={{ margin: '90px auto', background: '#fff' }}
                    >
                        <source src="/assets/image/clip_ad.mp4" type="video/mp4" />
                        Sorry, your browser doesn't support embedded videos.
                    </video>
                </div>
                {/* </Slider> */}
                <div className="r_btn">
                    <div className="dot-box">
                        <span id="slide1" className={displayDot01} onClick={screenHandler}></span>
                        <span id="slide2" className={displayDot02} onClick={screenHandler}></span>
                        <span id="slide3" className={displayDot03} onClick={screenHandler}></span>
                    </div>
                </div>
            </div>
            <SubBoardComponent></SubBoardComponent>
            <div className="family-contents" style={{}}>
                <MainSlider></MainSlider>
            </div>
        </div>
    );
}

export default MainLayout;
