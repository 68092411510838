import React, { useEffect, useState } from 'react';
import Layout from '../layouts/Layout';
import Header from '../layouts/Header';
import SideBar from '../layouts/SideBar';
import '../assets/css/center.css';
import '../assets/css/animate.min.css';
import RehabStatisticsBasicInfoTab from './statistics/RehabStatisticsBasicInfoTab';
import RehabStatisticsMeasureInfoByTimeTab from './statistics/RehabStatisticsMeasureInfoByTimeTab';
import RehabStatisticsRehabInfoByTimeTab from './statistics/RehabStatisticsRehabInfoByTimeTab';

let centerKey: string = '';
const menuTitle: string = '* 센터 방문자 통계';
const title: string = '통계';

function RehabStatisticsComponent(props: any) {
    const [tabSeq, setTabSeq] = useState('tab1');
    let path = props.location.pathname;
    centerKey = props.match.params.centerId;

    const tabs: any = {
        tab1: <RehabStatisticsBasicInfoTab></RehabStatisticsBasicInfoTab>,
        tab2: <RehabStatisticsMeasureInfoByTimeTab></RehabStatisticsMeasureInfoByTimeTab>,
        tab3: <RehabStatisticsRehabInfoByTimeTab></RehabStatisticsRehabInfoByTimeTab>,
    };

    const filters = () => {
        return <></>;
    };

    let statisticsLayout = (
        <Layout
            header={<Header></Header>}
            sideBar={<SideBar name={path}></SideBar>}
            path={path}
            naviTitle={title}
            menuTitle={menuTitle}
            filter={filters()}
        >
            <div className="center-statistics">
                <div className="center-popup-bnt center-three">
                    <a onClick={() => setTabSeq('tab1')} href="javascript:void(0)" id="tab-btn1" className={tabSeq=='tab1'? 'on' : ''}>
                        <i className="fas fa-chart-pie"></i> 기본정보
                    </a>
                    <a onClick={() => setTabSeq('tab2')} href="javascript:void(0)" id="tab-btn2" className={tabSeq=='tab2'? 'on' : ''} >
                        <i className="far fa-chart-bar"></i> 시기별 측정인원
                    </a>
                    <a onClick={() => setTabSeq('tab3')} href="javascript:void(0)" id="tab-btn3" className={tabSeq=='tab3'? 'on' : ''}>
                        <i className="fas fa-chart-area"></i>시기별 운동재활 인원
                    </a>
                </div>
                {tabs[tabSeq]}
            </div>
        </Layout>
    );

    return statisticsLayout;
}

export default RehabStatisticsComponent;
