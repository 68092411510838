import React from 'react';
import MyPageMainLayout from '../../../layout/MyPageMainLayout';
import SubMenuNav from '../../../nav/SubMenuNav';
import UserPageSubMenuNav from '../nav/UserPageSubMenuNav';
import MyPageRightContent from '../../../layout/MyPageRightContent';

function UserMyPageNotiContainer() {
    return (
        <MyPageMainLayout >
            <MyPageRightContent contentTitle={'공지 사항'}>
                <button className="accordion activeee">아침 전국 대부분 영하권…곳곳 눈발 날려</button>
                <div className="panel" style={{maxHeight: '400px'}}>
				  <p>금요일인 4일은 전국 대부분 지역에서 아침 기온이 영하로 떨어져 매우 춥겠다.낮 기온도 중부지방 5도 내외, 남부지방 10도 내외의 분포를 보이며 쌀쌀하겠다. 아침 최저기온은 -9∼2도, 낮 최고기온은 3∼11도로 예보됐다.제주도에서는 오전 9시까지 산발적으로 빗방울이 떨어지겠고 산지엔 눈이 내릴 것으로 예보됐다. 경기 남부와 충청도, 전북에는 이날 저녁부터 다음날 새벽까지 가끔 눈 날리는 곳이 있겠다.건조특보가 발효된 강원 영동과 경상 해안, 서울과 일부 경기도, 경상 내륙, 전남 동부는 대기가 매우 건조하겠으니 화재 예방에 각별히 신경 써야 한다.</p>
				</div>
                <button className="accordion activeee">아침 전국 대부분 영하권…곳곳 눈발 날려</button>
                <div className="panel" style={{maxHeight: '400px'}}>
				  <p>금요일인 4일은 전국 대부분 지역에서 아침 기온이 영하로 떨어져 매우 춥겠다.낮 기온도 중부지방 5도 내외, 남부지방 10도 내외의 분포를 보이며 쌀쌀하겠다. 아침 최저기온은 -9∼2도, 낮 최고기온은 3∼11도로 예보됐다.제주도에서는 오전 9시까지 산발적으로 빗방울이 떨어지겠고 산지엔 눈이 내릴 것으로 예보됐다. 경기 남부와 충청도, 전북에는 이날 저녁부터 다음날 새벽까지 가끔 눈 날리는 곳이 있겠다.건조특보가 발효된 강원 영동과 경상 해안, 서울과 일부 경기도, 경상 내륙, 전남 동부는 대기가 매우 건조하겠으니 화재 예방에 각별히 신경 써야 한다.</p>
				</div>
                <button className="accordion activeee">아침 전국 대부분 영하권…곳곳 눈발 날려</button>
                <div className="panel" style={{maxHeight: '400px'}}>
				  <p>금요일인 4일은 전국 대부분 지역에서 아침 기온이 영하로 떨어져 매우 춥겠다.낮 기온도 중부지방 5도 내외, 남부지방 10도 내외의 분포를 보이며 쌀쌀하겠다. 아침 최저기온은 -9∼2도, 낮 최고기온은 3∼11도로 예보됐다.제주도에서는 오전 9시까지 산발적으로 빗방울이 떨어지겠고 산지엔 눈이 내릴 것으로 예보됐다. 경기 남부와 충청도, 전북에는 이날 저녁부터 다음날 새벽까지 가끔 눈 날리는 곳이 있겠다.건조특보가 발효된 강원 영동과 경상 해안, 서울과 일부 경기도, 경상 내륙, 전남 동부는 대기가 매우 건조하겠으니 화재 예방에 각별히 신경 써야 한다.</p>
				</div>
            </MyPageRightContent>
        </MyPageMainLayout>
    )
}

export default UserMyPageNotiContainer;