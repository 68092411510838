import React, { useEffect, useState } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import AdminFacilityTable from '../../../../component/admin/AdminFacilityTable';
import AdminMainTitle from '../../../../component/admin/AdminMainTitle';
import InfraServiceImpl from '../../../../service/infra/impl/InfraServiceImpl';
import UUIDUtils from '../../../../utils/UUIDUtils';
import Infra from '../../../../domain/infra/Infra';
import AdminCommonTemplate from '../../layout/AdminCommonTemplate';
import InfraSearchCondition from '../../../../domain/infra/param/InfraSearchCondition';
import { InfraCategoryType } from '../../../../domain/infra/InfraCategoryType';
import usePagingInfos from '../../hook/usePagingInfos';
import useInfraRetrieveBySearchCondition from '../../../../hook/infra/useInfraRetrieveBySearchCondition';
import AdminItemPaging from '../../component/AdminItemPaging';
import CodeSelectFilter from '../../component/filter/element/CodeSelectFilter';
import useInfraOfCategoryRetrieve from '../../../../hook/infra/useInfraCategoryRetrieve';
import InfraCategory from '../../../../domain/infra/InfraCategory';
import useChildCodeRetrieve from '../../../../hook/code/useChildCodeRetrieve';
import Code from '../../../../domain/code/Code';
import useParentInfraCategoriesRetrieve from '../../../../hook/infra/useParentInfraCategoriesRetrieve';
import ChartDataUtils from '../../../../utils/ChartDataUtils';

const infraService = new InfraServiceImpl();

function AdminSubInfraListContainer() {
    const location = useLocation();
    const history = useHistory();
    const defaultParentRegionCodeId = 20001;

    const subInfraNos = `${InfraCategoryType.SUB_INFRA_NO},${InfraCategoryType.LODGMENT_NO},${InfraCategoryType.TOURISM_NO},${InfraCategoryType.FOOD_NO}`;

    const regionCodes = useChildCodeRetrieve(defaultParentRegionCodeId);

    const infraRegisteEvent = () => {
        const uuid = UUIDUtils.getUUID();
        const newTmpInfra = new Infra();
        newTmpInfra.infraNo = uuid;
        newTmpInfra.infraCategoryNo = '11';
        infraService.registeInfra(newTmpInfra).then((registedInfra) => {
            infraSearchCondition.flag = 'registry';
            history?.push({
                pathname: '/admin/facility/' + uuid,
                state: infraSearchCondition,
            });
        });
    };

    const [searchWord, setSearchWord] = useState<string>();

    const { parentInfraCategories } = useParentInfraCategoriesRetrieve();
    const [parentInfraCategoryNo, setParentInfraCategoryNo] = useState<number>(2);
    //const infraCategoriesOfSubInfraInfra = useInfraOfCategoryRetrieve(parentInfraCategoryNo);

    const [infraCategoryNoOfSearch, setInfraCategoryNoOfSearch] = useState<number>();
    const [regionCodeIdOfSearch, setRegionCodeIdOfSearch] = useState<number>();
    const [recommandationOfSearch, setRecommandationOfSearch] = useState<boolean>();

    const condition: InfraSearchCondition | any = location.state ? location.state : new InfraSearchCondition(parentInfraCategoryNo?.toString());
    const [infraSearchCondition, setInfraSearchCondition] = useState<InfraSearchCondition>(condition);
    const { page, setPage, pagingSize, setPagingSize, pagingCnt, paging, currentPageGroupIdx, setCurrentPageGroupIdx } = usePagingInfos(condition);
    const { infras, infrasCnt, setInfras, setActionFlag } = useInfraRetrieveBySearchCondition(infraSearchCondition, paging);

    const keyEventHandler = (e: any) => {
        if (e.key == 'Enter') {
            searchClickHandler();
        }
    };

    const searchClickHandler = () => {
        const newInfraSearchCondition = new InfraSearchCondition();
        newInfraSearchCondition.searchWord = searchWord;
        newInfraSearchCondition.recommandation = recommandationOfSearch;

        if (infraCategoryNoOfSearch === 0) {
            newInfraSearchCondition.parentInfraCategory = subInfraNos;
        } else {
            newInfraSearchCondition.parentInfraCategory = String(infraCategoryNoOfSearch);
        }

        if (regionCodeIdOfSearch) newInfraSearchCondition.regionCodeIds = String(regionCodeIdOfSearch);

        setInfraSearchCondition(newInfraSearchCondition);
        setCurrentPageGroupIdx(0);
        setPage(0);
    };

    useEffect(() => {
        setSearchWord(condition.searchWord);
        setInfraCategoryNoOfSearch(condition.parentInfraCategory);
        setRegionCodeIdOfSearch(condition.regionCodeIds);
        setRecommandationOfSearch(condition.recommandation);
    }, []);

    let title = '부대 시설 목록 < ' + ChartDataUtils.getNumberFormat(infrasCnt) + '개 >';

    const infraHandler = (infra: Infra, recommandation: boolean) => {
        infra.recommandation = recommandation;
        setInfra(Object.setPrototypeOf(Object.assign({}, infra), Infra.prototype));
    };

    const infraRecommandHandler = (recommandation: boolean) => {
        infras?.map((val: Infra) => {
            infraHandler(val, recommandation);
        });
        let flag = 'GET';
        if (recommandation) {
            flag = 'RECOMM';
        } else {
            flag = 'UNRECOMM';
        }
        setActionFlag(flag);

        setInfras((prevState) => {
            prevState?.map((newVal: Infra) => {
                newVal.recommandation = recommandation;
            });
            return prevState;
        });
    };

    const [infra, setInfra] = useState<Infra>();
    const excludeCodeIds = [2000100];

    return (
        <>
            <AdminMainTitle title={title}>
                <div className="member-search">
                    <select
                        value={infraCategoryNoOfSearch}
                        onChange={(event) => {
                            let parentInfraCategoryNo: number | undefined = event.currentTarget.value ? Number(event.currentTarget.value) : 0;
                            //setParentInfraCategoryNo(parentInfraCategoryNo);
                            setInfraCategoryNoOfSearch(parentInfraCategoryNo);
                            document.getElementById('keyHandler')?.focus();
                        }}
                    >
                        <option value={0}>전체</option>
                        {parentInfraCategories?.map((infraCategory: InfraCategory, index: number) => {
                            if (infraCategory.infraCategoryNo != 1)
                                return (
                                    <option key={index} value={infraCategory.infraCategoryNo}>
                                        {infraCategory.name}
                                    </option>
                                );
                        })}
                    </select>
                    <select
                        value={regionCodeIdOfSearch}
                        onChange={(event) => {
                            let codeIdOfSearch = Number(event.currentTarget.value) === 0 ? undefined : Number(event.currentTarget.value);
                            setRegionCodeIdOfSearch(codeIdOfSearch);
                            document.getElementById('keyHandler')?.focus();
                        }}
                    >
                        <option value="0">지역 전체</option>
                        {regionCodes?.map((code: Code, index: number) => {
                            if (excludeCodeIds.indexOf(code.codeId) == -1) {
                                return (
                                    <option value={code.codeId} key={index}>
                                        {code.name}
                                    </option>
                                );
                            }
                        })}
                    </select>
                    <select
                        value={recommandationOfSearch?.toString()}
                        onChange={(event) => {
                            let recommandation = undefined;

                            if (event.currentTarget.value === 'true' || event.currentTarget.value === 'false') {
                                recommandation = JSON.parse(event.currentTarget.value);
                            }

                            setRecommandationOfSearch(recommandation);
                            document.getElementById('keyHandler')?.focus();
                        }}
                    >
                        추천여부
                        <option value={'undefined'}>추천 전체</option>
                        <option value={'true'}>추천</option>
                        <option value={'false'}>비추천</option>
                    </select>
                    <input
                        type="text"
                        placeholder="시설명을 입력하세요."
                        onChange={(event) => {
                            setSearchWord(event.currentTarget.value);
                        }}
                        onKeyPress={keyEventHandler}
                        value={searchWord}
                    ></input>
                    <input id="keyHandler" value="" onKeyPress={keyEventHandler} style={{ width: '0px', border: '0px' }} />
                    <button
                        className="bg-blue white mouse-pointer"
                        onClick={() => {
                            searchClickHandler();
                        }}
                        style={{ fontWeight: 900 }}
                    >
                        <i className="fas fa-search"></i>시설 검색
                    </button>
                </div>
                <div className="member-search">
                    <button
                        className="bg-blue white"
                        onClick={() => {
                            infraRecommandHandler(true);
                        }}
                        style={{ fontWeight: 900 }}
                    >
                        모두추천
                    </button>
                    <button
                        className="bg-red white"
                        onClick={() => {
                            infraRecommandHandler(false);
                        }}
                        style={{ fontWeight: 900 }}
                    >
                        모두비추천
                    </button>
                    <button className="bg-white white" onClick={infraRegisteEvent} style={{ fontWeight: 900, backgroundColor: '#2f9d27' }}>
                        시설 등록
                    </button>
                </div>
            </AdminMainTitle>
            <AdminFacilityTable
                page={page}
                pageCnt={pagingCnt}
                pagingSize={pagingSize}
                infras={infras}
                searchCondition={infraSearchCondition}
            ></AdminFacilityTable>
            <AdminItemPaging
                totalCnt={infrasCnt}
                pagingCnt={pagingCnt}
                pagingSize={pagingSize}
                setPagingSize={setPagingSize}
                setPage={setPage}
                currentPageNum={page}
                currentPageGroupIdx={currentPageGroupIdx}
            ></AdminItemPaging>
        </>
    );
}

export default AdminSubInfraListContainer;
