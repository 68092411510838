import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import AdminTable from '../../../component/admin/AdminTable';
import VisitedTeamInfoSearchCondition from '../../../domain/visitedInfo/pagam/VisitedTeamInfoSearchCondition';
import VisitedTeamInfo from '../../../domain/visitedInfo/VisitedTeamInfo';
import VisitedTeamInfoServiceImpl from '../../../service/visitedInfo/impl/VisitedTeamInfoServiceImpl';
import AdminVisitedTeamInfoInputForm from '../page/visitedInfo/AdminVisitedTeamInfoInputForm';

interface AdminVisitedTeamInfosTableProps {
    page: number;
    pagingSize: number;
    visitedTeamNo: string;
    visitedTeamInfos?: VisitedTeamInfo[];
}

const service = new VisitedTeamInfoServiceImpl();

function AdminVisitedTeamInfosDetailTable(props: AdminVisitedTeamInfosTableProps) {
    const history = useHistory();

    const goModifying = (evt: any) => {
        var teamInfoNo = evt.currentTarget.id;
        let condition: VisitedTeamInfoSearchCondition = new VisitedTeamInfoSearchCondition();
        condition.visitedTeamNo = props.visitedTeamNo;
        condition.visitedTeamInfoNo = teamInfoNo;
        condition.returnPath = `/admin/visited-team-info/detail/${props.visitedTeamNo}`;

        history.replace({
            pathname: `/admin/visited-team-info/${teamInfoNo}`,
            state: condition,
        });
    };

    const deleteHandler = (evt: any) => {
        var teamInfoNo = evt.currentTarget.id;
        service.deleteVisitedTeamInfo(teamInfoNo).then((result) => {
            window.location.reload();
        });
    };
    return (
        <AdminTable>
            <thead>
                <tr>
                    <td>No.</td>
                    <td>방문지역</td>
                    <td>방문일</td>
                    <td>방문종료일</td>
                    <td>참가인원</td>
                    <td>체류기간</td>
                    <td>연인원(참가인원 X 체류기간 or 입력값)</td>
                    <td>등록자</td>
                    <td>관리</td>
                </tr>
            </thead>
            <tbody>
                {props.visitedTeamInfos ? (
                    props.visitedTeamInfos.length > 0 ? (
                        props.visitedTeamInfos.map((visitedTeamInfo: VisitedTeamInfo, index: number) => {
                            let visitedMembers = visitedTeamInfo.visitedMemberCnt ? visitedTeamInfo.visitedMemberCnt : 0;
                            let visitedDays = visitedTeamInfo.visitedDayCnt ? visitedTeamInfo.visitedDayCnt : 0;
                            let yearVisitedMemCnt = visitedMembers * visitedDays;
                            return (
                                <tr key={index}>
                                    <td>{props.page * props.pagingSize + (index + 1)}</td>
                                    <td>
                                        {visitedTeamInfo.parentRegionCode?.name}-{visitedTeamInfo.regionCode?.name}
                                    </td>
                                    <td>{visitedTeamInfo.visitedStartDate}</td>
                                    <td>{visitedTeamInfo.visitedEndDate}</td>
                                    <td>{visitedTeamInfo.visitedMemberCnt}명</td>
                                    <td>{visitedTeamInfo.visitedDayCnt}일</td>
                                    <td>연 {visitedTeamInfo.visitedMembersForYear}명 방문</td>
                                    <td>{visitedTeamInfo.register?.name}</td>
                                    <td>
                                        <button className="bg-light-blue" onClick={goModifying} id={visitedTeamInfo.visitedTeamInfoNo}>
                                            <i className="fas fs-search"></i>
                                            수정
                                        </button>
                                        <button className="bg-red white" onClick={deleteHandler} id={visitedTeamInfo.visitedTeamInfoNo}>
                                            <i className="fas fs-search"></i>
                                            삭제
                                        </button>
                                    </td>
                                </tr>
                            );
                        })
                    ) : (
                        <tr>
                            <td colSpan={10}>
                                <h5>조회 조건에 맞는 내용이 없습니다.</h5>
                            </td>
                        </tr>
                    )
                ) : (
                    <></>
                )}
            </tbody>
        </AdminTable>
    );
}

export default AdminVisitedTeamInfosDetailTable;
