import React, { useEffect, useState } from 'react';

interface SearchViewTypeProps {
    setSearchViewType: (viewType: string) => void;
    listViewTypeActive: boolean;
    mapViewTypeActive: boolean;
}

function SearchViewType(props: SearchViewTypeProps) {
    const [viewType, setViewType] = useState('list');

    useEffect(() => {
        // props.setSearchViewType(viewType);
    }, []);

    return (
        <ul className="select02">
            {props.listViewTypeActive ? (
                <li
                    onClick={() => {
                        setViewType('list');
                        props.setSearchViewType('list');
                    }}
                    className={viewType == 'list' ? 'on' : ''}
                >
                    <div className={viewType == 'list' ? 'on' : ''}>
                        <img src={viewType == 'list' ? '/assets/image/icon-list-on.png' : '/assets/image/icon-list.png'}></img>
                        <a>목록</a>
                    </div>
                </li>
            ) : (
                <></>
            )}
            {props.mapViewTypeActive ? (
                <li
                    onClick={() => {
                        setViewType('map');
                        props.setSearchViewType('map');
                    }}
                    className={viewType == 'map' ? 'on' : ''}
                >
                    <div className={viewType == 'map' ? 'on' : ''}>
                        <a>지도</a>
                        <img src={viewType == 'map' ? '/assets/image/icon-map-on.png' : '/assets/image/icon-map.png'}></img>
                    </div>
                </li>
            ) : (
                <></>
            )}
        </ul>
    );
}

export default SearchViewType;
