import React, { useEffect, useState } from 'react';
import SearchPageHeader from '../layout/SearchPageHeader';
import SearchLeft from '../../../component/layout/SearchLeft';
import SearchIn from '../../../component/layout/SearchIn';
import NearInfraSearchConditionContainer from '../../../container/search/NearInfraSearchConditionContainer';
import Infra from '../../../domain/infra/Infra';
import { InfraCategoryType } from '../../../domain/infra/InfraCategoryType';
import useSearchPageHook from './hook/useSearchPageHook';
import SearchRight from '../../../component/layout/SearchRight';
import InfraServiceImpl from '../../../service/infra/impl/InfraServiceImpl';
import SearchOfInfraContainer from '../../../container/search/SearchOfInfraContainer';
import { useDispatch } from 'react-redux';
import { putConditionAction } from '../../../moldules/SearchCondition';
import { CodeType } from '../../../domain/code/CodeType';
import useMyTeamScheduleHook from '../../../hook/useMyTeamScheduleHook';
import useGeographic from '../../../hook/useGeographic';
import { NEAR_DISTAINCE } from '../../../config/Constant';
import SearchCnt from '../../../component/search/SearchCnt';
import SearchIteamPaging from '../../../component/search/SearchItemPaging';

const infraService = new InfraServiceImpl();

function NearSearchPageContainer() {
    const { reservation } = useMyTeamScheduleHook();
    const [infras, setInfras] = useState<Infra[]>();
    const [searchCnt, setSearchCnt] = useState(0);

    const {
        searchType,
        setSearchType,
        searchConditionMapParsing,
        parsedSearchConditionMap,
        pagingSize,
        pagingCnt,
        setPage,
        page,
    } = useSearchPageHook(InfraCategoryType.SUB_INFRA_NO);

    useEffect(() => {
        if (reservation && parsedSearchConditionMap) {
            if (reservation.parentInfra) {
                setSearchTargetInfra(reservation.parentInfra);
            } else {
                setSearchTargetInfra(reservation.infra);
            }
        }
    }, [reservation, parsedSearchConditionMap]);

    const [infraNameTitle, setInfraNameTitle] = useState<string>('');
    const [searchTargetInfra, setSearchTargetInfra] = useState<Infra>();

    useEffect(() => {
        if(searchTargetInfra) {
            if(searchTargetInfra?.name) setInfraNameTitle(searchTargetInfra.name);
                parsedSearchConditionMap['parentInfraCategory'] = searchType;
                infraService
                    .getInfraOfSearchConditionAndLocation(
                        parsedSearchConditionMap,
                        undefined,
                        searchTargetInfra?.latitude,
                        searchTargetInfra?.longitude,
                        NEAR_DISTAINCE,
                    )
                    .then((infras: Infra[]) => {
                        setInfras(infras);
                        setSearchCnt(infras.length);
                    });
        }
    }, [searchTargetInfra]);
    

    return (
        <>
            <SearchPageHeader></SearchPageHeader>
            <div className="content-wrap">
                <div className="new_near_search">
                    <SearchLeft>
                        <SearchIn>
                            <div className="near_search_box">
                            <NearInfraSearchConditionContainer
                                setSearchType={setSearchType}
                                targetInfra={reservation?.infra}
                            ></NearInfraSearchConditionContainer>
                            </div>
                            <div className="apply-box">
                                <button onClick={() => searchConditionMapParsing(0)}>조회</button>
                            </div>
                        </SearchIn>
                    </SearchLeft>
                </div>
                <div className="new_near_search_r">
                    <SearchRight>
                        <SearchIn>
                            <SearchCnt title={`${infraNameTitle} 주변 검색 `} searchCnt={searchCnt}></SearchCnt>
                            <SearchOfInfraContainer searchCnt={searchCnt} searchedInfras={infras}></SearchOfInfraContainer>
                            <SearchIteamPaging
                                totalCnt={searchCnt}
                                pagingSize={pagingSize}
                                pagingCnt={pagingCnt}
                                setPage={setPage}
                                currentPageNum={page}
                            ></SearchIteamPaging>
                        </SearchIn>
                    </SearchRight>
                </div>
            </div>
        </>
    );
}

export default NearSearchPageContainer;
