import React from 'react';
import PoliciesHeader from '../components/PoliciesHeader';
import PrivacyPolicy from '../components/PrivacyPolicy';
function PoliciesPrivacyPage() {
    return (
        <>
            <PoliciesHeader></PoliciesHeader>
            <section className="main-list" style={{fontSize: '15px'}}>
                <PrivacyPolicy></PrivacyPolicy>
            </section>
        </>
    )
}

export default PoliciesPrivacyPage;