import CommonSearchCondition from '../../common/param/CommonSearchCondition';

class InfraSearchCondition extends CommonSearchCondition {
    parentInfraCategory?: string;
    childInfraCategory?: string;
    recommandation?: boolean;
    latitude?: number;
    longitude?: number;
    distance?: number;
    page?: number;
    groupIdx?: number;
    flag?: string;

    constructor(parentInfraCategory?: string, parentRegionCodeId?: string, searchWord?: string) {
        super();
        this.parentInfraCategory = parentInfraCategory;
        this.parentRegionCodeIds = parentRegionCodeId;
        this.searchWord = searchWord;
    }
}

export default InfraSearchCondition;
