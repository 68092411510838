import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Event from '../../domain/event/Event';
import { useSelector } from 'react-redux';
import { RootState } from '../../moldules/index';
import { confirmAlert } from 'react-confirm-alert';
import EventServiceImpl from '../../service/event/impl/EventServiceImpl';

interface CouponCardProps {
    coupon: Event;
    link: string;
}

const eventService = new EventServiceImpl();

function CouponCard(props: CouponCardProps) {
    const [coupon, setCoupon] = useState<Event>(props.coupon);
    const [usingCouponCnt, setUsingCouponCnt] = useState<number>(0);
    const [usedCouponCnt, setUsedCouponCnt] = useState<number>();
    const { user, myTeam, hasTeamAuth } = useSelector((state: RootState) => state.userSessionReducer);

    useEffect(() => {
        setUsedCouponCnt(coupon.usedCouponCnt ? coupon.usedCouponCnt : 0);
    }, []);

    const usedCouponHandler = (evt: any) => {
        let useCnt: number = Number(evt.currentTarget.value);
        if (isNaN(useCnt)) {
            confirmAlert({
                title: '경고',
                message: '숫자만 입력 가능 합니다.',
                buttons: [
                    {
                        label: '확인',
                        onClick: () => {
                            setUsedCouponCnt(0);
                        },
                    },
                ],
            });
        } else {
            let couponCnt: number = coupon.issuedCouponCnt ? coupon.issuedCouponCnt : 0;
            let usedCouponCnt: number = coupon.usedCouponCnt ? coupon.usedCouponCnt : 0;
            let totUsingCouponCnt: number = usedCouponCnt + useCnt;

            if (couponCnt >= totUsingCouponCnt) {
                setUsingCouponCnt(useCnt);
            } else {
                confirmAlert({
                    title: '경고',
                    message: '사용 쿠폰 수는 등록 된 수 <' + couponCnt + ' 장> 을 넘을 수 없습니다. 입력 값을 확인해 주십시오',
                    buttons: [
                        {
                            label: '확인',
                            onClick: () => {
                                setUsingCouponCnt(0);
                                document.getElementById('inputUsedCouponCnt')?.focus();
                            },
                        },
                    ],
                });
            }
        }
    };

    const usedCouponBtnHandler = () => {
        coupon.usedCouponCnt = usingCouponCnt;
        if (coupon.eventNo)
            eventService
                .useCoupon(coupon.eventNo, coupon)
                .then((result: Event) => {
                    confirmAlert({
                        title: '쿠폰 사용 성공',
                        message: '쿠폰을 정상적으로 사용 등록 하였습니다.',
                        buttons: [
                            {
                                label: '확인',
                                onClick: () => {
                                    setUsedCouponCnt(result.usedCouponCnt);
                                    window.location.reload();
                                },
                            },
                        ],
                    });
                })
                .catch((reason) => {
                    confirmAlert({
                        title: '경고',
                        message: '이미 사용 된 쿠폰 입니다. 확인 하여 주십시오.',
                        buttons: [
                            {
                                label: '확인',
                                onClick: () => {
                                    window.location.reload();
                                },
                            },
                        ],
                    });
                });
    };

    let used = coupon.usedCouponCnt ? coupon.usedCouponCnt : 0;
    let registured = coupon.issuedCouponCnt ? coupon.issuedCouponCnt : 0;

    return (
        <div className="my-in">
            <div className="my-in-info">
                <div className="my-in-info-box">
                    <div style={{ width: '10%' }}>
                        <p>
                            <span style={{ fontWeight: 900 }}>사용지역</span> <br />
                            <span style={{ margin: '0px 10px' }}>{coupon.regionCode?.name}</span>
                        </p>
                    </div>
                    <div style={{ width: '40%' }}>
                        <Link to={props.link}>
                            <span style={{ fontWeight: 900 }}>쿠폰내용</span>
                            <p style={{ fontSize: '21px', margin: '0px 10px' }}>{coupon.content}</p>
                        </Link>
                    </div>
                    <div style={{ width: '10%' }}>
                        <p>
                            <span style={{ fontWeight: 900 }}>쿠폰유형</span> <br />
                            <span className="blue" style={{ margin: '0px 10px' }}>
                                {coupon.eventCode?.name}
                            </span>
                        </p>
                    </div>
                    <div style={{ width: '10%' }}>
                        <p>
                            <span style={{ fontWeight: 900 }}>서비스</span>
                            <br />
                            <span style={{ margin: '0px 10px' }}> {coupon.couponCode?.name}</span>
                        </p>
                    </div>
                    <div style={{ width: '15%' }}>
                        <p>
                            <span style={{ fontWeight: 900 }}>사용 / 발급매수</span> <br />
                            <span style={{ color: '#00f', margin: '0px 10px' }}>
                                &lt; {usedCouponCnt}/{coupon.issuedCouponCnt} &gt;
                            </span>
                        </p>
                    </div>
                    <div className="flex-basic" style={{ width: '25%', textAlign: 'right', marginTop: '15px', marginRight: '15px' }}>
                        {used < registured ? (
                            <>
                                <input
                                    id="inputUsedCouponCnt"
                                    value={usingCouponCnt}
                                    type="text"
                                    onChange={usedCouponHandler}
                                    placeholder="사용할 쿠폰 수를 입력하세요."
                                ></input>
                                <button
                                    className="bg-light-blue"
                                    style={{
                                        padding: '6px 12px',
                                        margin: '2px 5px',
                                        fontSize: '16px',
                                        fontWeight: 700,
                                        border: '1px solid #e3e3e3',
                                        borderRadius: '5px',
                                    }}
                                    onClick={usedCouponBtnHandler}
                                >
                                    사용
                                </button>
                            </>
                        ) : (
                            <>
                                <span style={{ fontWeight: 900, color: '#f00' }}>모두 사용하였습니다.</span>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CouponCard;
