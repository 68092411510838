import React from 'react';
import AbstractService from '../../../../service/AbstractService';
import PrescriptionService from '../PrescriptionService';
import Exercise from '../../domain/Exercise';
import APIServerConfig from '../../../../config/APIServerConfig';
import Code from '../../../../domain/code/Code';
import Prescription from '../../domain/Prescription';

class PrescriptionServiceImpl extends AbstractService implements PrescriptionService {
    getExerciseLOVs(): Promise<{ bodyParts: Code[]; exerTypes: Code[]; message: string; sportTypes: Code[] }> {
        return new Promise((handler, reject) => {
            APIServerConfig.recordAPIAxios
                .post('/exercise/lovs', JSON.stringify({}), {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                .then((response) => {
                    handler(response.data);
                });
        });
    }

    getRecordingResultForTarget(targetNo: number): Promise<Prescription> {
        return new Promise((handler, reject) => {
            APIServerConfig.recordAPIAxios
                .post('/prescribe/recordingResult', JSON.stringify({ targetNo: targetNo, domain: 'REHAB' }), {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                .then((response) => {
                    handler(response.data);
                });
        });
    }

    getExerciseListByTypes(types: string, pagination: { size: number; page: number } | undefined): Promise<{ data: Exercise[]; totalSize: number }> {
        return new Promise((handler, reject) => {
            APIServerConfig.recordAPIAxios
                .post('/exercise/list', JSON.stringify({ exerType: types, pageSize: pagination?.size, pageIdx: pagination?.page }), {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                .then((response) => {
                    let exercises: Exercise[] = this.jsonConvert.deserializeArray(response.data.exerciseList, Exercise);
                    let totalSize: number = response.data.totalSize;
                    let data = { data: exercises, totalSize: totalSize };
                    handler(data);
                });
        });
    }

    //Managing Exercise
    manageExercise(exercise: FormData): Promise<Exercise> {
        return new Promise((handler, reject) => {
            APIServerConfig.recordAPIAxiosForFile.post('/exercise/manage/prescription', exercise).then((response) => {
                let data: Exercise = this.jsonConvert.deserializeObject(response.data.exercise, Exercise);
                handler(data);
            });
        });
    }

    deleteExercise(exerNo: number): Promise<Exercise> {
        return new Promise((handler, reject) => {
            APIServerConfig.recordAPIAxios
                .post('/exercise/delete', JSON.stringify({ exerNo: exerNo }), {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                .then((response) => {
                    let exercise: Exercise = this.jsonConvert.deserializeObject(response.data.exercise, Exercise);
                    handler(exercise);
                });
        });
    }

    // Managing Prescription
    managePrescription(prescription: Prescription, action: string): Promise<any> {
        return new Promise((handler, reject) => {
            prescription.action = action;
            APIServerConfig.recordAPIAxios
                .post('/prescribe/exercise/management', JSON.stringify(prescription), {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                .then((response) => {
                    handler(response);
                });
        });
    }
    completePrescription(prescription: Prescription): Promise<any> {
        return new Promise((handler, reject) => {
            APIServerConfig.recordAPIAxios
                .post('/prescribe/exercise/complete', JSON.stringify(prescription), {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                .then((response) => {
                    handler(response);
                });
        });
    }
}

export default PrescriptionServiceImpl;
