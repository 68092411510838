import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import MyPageMainLayout from '../../../layout/MyPageMainLayout';
import MyPageRightContent from '../../../layout/MyPageRightContent';
import useFillInQuestionnaireOfUser from '../../../../../hook/questionnaire/useFillInQuestionnaireOfUser';
import Questionnaire from '../../../../rehabcenter/domain/Questionnaire';
import Question from '../../../../rehabcenter/domain/Question';
import QuestionCard from '../../../components/QuestionCard';
import AnswerItem from '../../../../rehabcenter/domain/AnswerItem';
import useFillInQuestionAnswerRegiste from '../../../../../hook/questionnaire/useFillInQuestionAnswerRegiste';
import FillInQuestionnarie from '../../../../rehabcenter/domain/FillInQuestionnarie';
import QuestionnariesFormFactory from '../../../../rehabcenter/pages/components/questionnaires/forms/QuestionnariesFormFactory';

function UserMyPageQuestionnaireViewPage() {
    var params: any = useParams();

    const { fillInQuestionnarie, setFillInQuestionnarie } = useFillInQuestionnaireOfUser(params.answerNo, params.targetNo, params.quesNo);
    const [questions, setQuestions] = useState<Question[]>();
    const [answerItemsMap, setAnswerItemsMap] = useState(new Map<number, AnswerItem>());
    const [questionnaire, setQuestionnaire] = useState<Questionnaire>();
    const { answerDate } = useFillInQuestionAnswerRegiste(fillInQuestionnarie);

    useEffect(() => {
        if (fillInQuestionnarie?.questionnaire) {
            const questionnaire = fillInQuestionnarie.getQuestionnaire();
            setQuestionnaire(questionnaire);
            const answerItemsMap = fillInQuestionnarie.getAnswerItemsMap();
            if (answerItemsMap) setAnswerItemsMap(answerItemsMap);
        }
    }, [fillInQuestionnarie]);

    useEffect(() => {
        if (answerDate) {
            alert('설문 결과 제출이 완료 되었습니다.');
        }
    }, [answerDate]);

    const setAnswer = (puesIndex: number, answerItem: AnswerItem) => {
        answerItemsMap.set(puesIndex, answerItem);
        const newMap = new Map<number, AnswerItem>();

        answerItemsMap.forEach((value, key) => {
            newMap.set(key, value);
        });

        setAnswerItemsMap(newMap);
    };

    const qnaCompleteCheck = (): boolean => {
        // if(answerItemsMap.size === questions?.length) {
        //     return true;
        // } else {
        //     return false;
        // }
        return true;
    };

    return (
        <MyPageMainLayout>
            <MyPageRightContent contentTitle={`${fillInQuestionnarie?.questionnaire?.displayTitle} 설문 작성`}>
                {/* <h5>설문. {fillInQuestionnarie?.questionnaire?.displayTitle}</h5> */}
                {/* <p>{fillInQuestionnarie?.questionnaire?.description}</p>
                {
                    
                    questions?.map((question: Question, index: number) => {
                            return <QuestionCard key={index} 
                                                index={index+1} 
                                                question={question} 
                                                answerItemsMap={answerItemsMap}
                                                setAnswer={setAnswer}
                                    ></QuestionCard>
                        })
                } */}
                {questionnaire?.questionnaireKey ? (
                    // QuestionnariesFormFactory(questionnaire?.questionnaireKey, answerItemsMap)
                    <QuestionnariesFormFactory
                        formType={questionnaire?.questionnaireKey}
                        questionnaire={questionnaire}
                        answerItems={answerItemsMap}
                        setAnswer={setAnswer}
                    ></QuestionnariesFormFactory>
                ) : (
                    <></>
                )}
                <a
                    className="bt-blue-2"
                    onClick={() => {
                        if (fillInQuestionnarie && answerItemsMap) {
                            if (qnaCompleteCheck()) {
                                const newAnswerItems = AnswerItem.getAnswerItemsOfMap(answerItemsMap);
                                fillInQuestionnarie.answer = JSON.stringify(newAnswerItems);
                                setFillInQuestionnarie(Object.setPrototypeOf(Object.assign({}, fillInQuestionnarie), FillInQuestionnarie.prototype));
                            } else {
                                alert('설문을 완료해주세요.');
                            }
                        }
                    }}
                >
                    제출하기
                </a>
            </MyPageRightContent>
        </MyPageMainLayout>
    );
}

export default UserMyPageQuestionnaireViewPage;
