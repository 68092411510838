import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import Layout from '../layouts/Layout';
import Header from '../layouts/Header';
import SideBar from '../layouts/SideBar';
import '../assets/css/center.css';
import '../assets/css/animate.min.css';
import RehabServiceImpl from '../service/impl/RehabServiceImpl';
import ActionComponent from './components/ActionComponent';
import QuestSheetPopup from './components/managements/QuestionnairePopupComponent';
import PaginationComponent from './components/PaginationComponent';
import RehabQuestionnariesServiceImpl from '../service/impl/RehabQuestionnairesServiceImpl';
import ManageQuestTable from './components/managements/comps/ManageQuestTable';
import Questionnaire from '../domain/Questionnaire';
import ManageQuestDetailPopup from './components/managements/comps/ManageQuestDetailPopup';

const menuTitle: string = '* 설문지관리';
const title: string = '설문관리';

const rehabService = new RehabServiceImpl();
const rehabQuestionnairesService = new RehabQuestionnariesServiceImpl();

interface IPopups {
    openable: boolean;
    setOpenable: (openable: boolean) => void;
    targetIndex?: string;
    closeClickHander: () => void;
    data?: Questionnaire[];
}

export default function RehabManageQuestSheetComponent(props: any) {
    let path = props.location.pathname;
    const [question, setQuestion] = useState<Questionnaire[]>();
    const [questPopupOpenable, setQuestPopupOpenable] = useState(false);
    const [targetIndex, setTargetIndex] = useState<string>();

    useEffect(() => {
        rehabQuestionnairesService.getQuestionnairesList().then((results: Questionnaire[]) => {
            setQuestion(results);
        });
    }, []);

    const addQuestionnaireHandler = () => {
        let centerPopupWrap = document.getElementById('center-popup-wrap');
        if (centerPopupWrap) centerPopupWrap.style.display = 'block';
        ReactDOM.render(<QuestSheetPopup></QuestSheetPopup>, document.getElementById('center-popup-wrap'));
    };

    const editClickHandler = (e: React.MouseEvent<HTMLElement>) => {
        let targetId = e.currentTarget.id;
        let quesNo = targetId.split('_')[1];
        let selectedQues;
        let selectedArr = question?.filter((item) => {
            return item.quesNo.toString() === quesNo;
        });

        if (selectedArr) selectedQues = selectedArr[0];

        let centerPopupWrap = document.getElementById('center-popup-wrap');
        if (centerPopupWrap) centerPopupWrap.style.display = 'block';
        ReactDOM.render(<QuestSheetPopup forms={selectedQues}></QuestSheetPopup>, document.getElementById('center-popup-wrap'));
    };
    const checkingClickHandler = (e: React.MouseEvent<HTMLElement>) => {
        let targetId = e.currentTarget.id;
        console.log(targetId);
        setTargetIndex(targetId);
        setQuestPopupOpenable(true);
    };

    const closeClickHander = () => {
        setTargetIndex(undefined);
        setQuestPopupOpenable(false);
    };

    const [checkIndex, setCheckIndex] = useState([]);

    const removeHandler = (e: React.MouseEvent<HTMLElement>) => {
        let id = e.currentTarget.id;
        let index = id.split('_')[1];
        // rehabQuestionnairesService.deleteQuestionnaire()

        let removeData = question?.find((item, idx) => {
            return idx === Number(index);
        });

        if (removeData) {
            rehabQuestionnairesService.deleteQuestionnaire(removeData).then((result: Questionnaire) => {
                alert('삭제되었습니다.');
                window.location.replace('/rehab/manage/questsheet');
            });
        }
    };
    const addItemElem = () => {
        const actions = ActionComponent();

        return (
            <button className="center-button-adminplus" onClick={addQuestionnaireHandler}>
                <i className="fas fa-folder-plus"></i> 설문지 추가하기
            </button>
        );
    };

    const filters = () => {
        return (
            <div id="tb-filter" className="center-bnt-right3 flex">
                <div className="center-select-box">
                    <select className="center-select">
                        <option>전체</option>
                        <option>이름</option>
                        <option>생년월일</option>
                        <option>소속팀</option>
                        <option>운동종목</option>
                    </select>
                    <div className="center-search">
                        <input className="center-input" placeholder="검색어를 입력하세요" />
                        <button className="btn">
                            <i className="fas fa-search"></i>
                        </button>
                    </div>
                </div>
            </div>
        );
    };

    function popups(props: IPopups) {
        let id = props.targetIndex ? props.targetIndex.split('_')[1] : undefined;
        let data = id && props.data ? props.data[Number(id)] : undefined;

        return (
            <>
                <div id="center-popup-wrap" style={{ display: `${props.openable ? 'block' : 'none'}` }}>
                    {props.openable ? (
                        <ManageQuestDetailPopup
                            index={Number(id)}
                            data={data}
                            closeClickHander={props.closeClickHander}
                            removeHandler={removeHandler}
                            setQuestPopupOpenable={props.setOpenable}
                        />
                    ) : (
                        <></>
                    )}
                </div>
            </>
        );
    }

    let funcObj: IPopups = {
        openable: questPopupOpenable,
        setOpenable: setQuestPopupOpenable,
        closeClickHander: closeClickHander,
        targetIndex: targetIndex,
        data: question,
    };

    return (
        <Layout
            header={<Header></Header>}
            sideBar={<SideBar name={path}></SideBar>}
            path={path}
            naviTitle={title}
            menuTitle={menuTitle}
            filter={filters()}
            pagination={PaginationComponent((evt: any) => {}, 0, 0, 0)}
            popups={popups(funcObj)}
            addItemElem={addItemElem()}
        >
            <ManageQuestTable
                questionnaires={question}
                checkingClickHandler={checkingClickHandler}
                editClickHandler={editClickHandler}
            ></ManageQuestTable>
        </Layout>
    );
}
