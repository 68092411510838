import ThirdPartyMapRenderable from './ThirdPartyMapRenderable';
import Infra from '../../../domain/infra/Infra';
import UserLocation from '../../../domain/user/UserLocation';

abstract class ThirdPartyMapRenderer implements ThirdPartyMapRenderable {
    mapId: string;
    mapLevel: number;
    infras: Infra[];
    thirdParty: any = window;
    // userLocation?: UserLocation;
    setReservationInfra: (infra: Infra) => void;
    setReservationModalOpenable: (open: boolean) => void;
    readonly map: any;
    readonly renderedOverlaysMemoArr: Array<any> = [];
    readonly renderedInfraMemoMap: any = {};

    constructor(
        mapId: string,
        mapLevel: number,
        infras: Infra[],
        setReservationInfra: (infra: Infra) => void,
        setReservationModalOpenable: (open: boolean) => void,
    ) {
        this.mapId = mapId;
        this.mapLevel = mapLevel;
        this.infras = infras;
        this.setReservationInfra = setReservationInfra;
        this.setReservationModalOpenable = setReservationModalOpenable;
        this.map = this.getMapInstance(infras[0]);
    }

    render(userLocation?: UserLocation) {
        //this.addMapTypeControl();
        //this.addZoomControl();
        this.infraInfoMakeAt();
        if (userLocation) this.myLocationMarkerRender(userLocation);
    }

    abstract getMapInstance(infra: Infra): any;
    abstract addMapTypeControl(): void;
    abstract addZoomControl(): void;
    abstract infraInfoMakeAt(): void;
    abstract myLocationMarkerRender(userLocation: UserLocation): void;
}

export default ThirdPartyMapRenderer;
