import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import TouringMainContainer from '../page/main/TouringMainContainer';
import TouringInfraSearchContainer from '../page/search/TouringInfraSearchContainer';
import { InfraCategoryType } from '../../../domain/infra/InfraCategoryType';
import TrainningRouter from '../../trainning/router/TrainningRouter';
import SearchPageContainer from '../../search/page/SearchPageContainer';
import CurrPositionOfNearSearchPageContainer from '../../search/page/CurrPositionOfNearSearchPageContainer';
import IndexRouter from '../../IndexRouter';

function TouringRouter() {
    return (
        <>
            <Switch>
                <Route path="/trainning" component={TrainningRouter}></Route>
                <Route exact={true} path="/touring" component={TouringMainContainer}></Route>
                <Route
                    path="/touring/s"
                    component={() => (
                        <TouringInfraSearchContainer
                            title={'축제'}
                            parentInfraCategoryNo={InfraCategoryType.TOURISM_NO}
                        ></TouringInfraSearchContainer>
                    )}
                ></Route>
                <Route
                    path="/touring/s2"
                    component={() => (
                        <TouringInfraSearchContainer
                            title={'숙박'}
                            parentInfraCategoryNo={InfraCategoryType.LODGMENT_NO}
                        ></TouringInfraSearchContainer>
                    )}
                ></Route>
                <Route
                    path="/touring/s3"
                    component={() => (
                        <TouringInfraSearchContainer title={'음식'} parentInfraCategoryNo={InfraCategoryType.FOOD_NO}></TouringInfraSearchContainer>
                    )}
                ></Route>
                <IndexRouter></IndexRouter>
            </Switch>
        </>
    );
}

export default TouringRouter;
