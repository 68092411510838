import { JsonObject, JsonProperty } from 'json2typescript';
import Code from '../code/Code';

@JsonObject('regionIssuedEvent')
class RegionIssuedEvent {
    @JsonProperty('parentRegionCode')
    parentRegionCode?: Code;
    @JsonProperty('regionCodeId')
    regionCodeId?: number;
    @JsonProperty('regionCode')
    regionCode?: Code;
    @JsonProperty('eventCnt')
    eventCnt?: number;
    @JsonProperty('totalIssuedCouponCnt')
    totalIssuedCouponCnt?: number;
    @JsonProperty('usedCouponCnt')
    usedCouponCnt?: number;
}

export default RegionIssuedEvent;
