import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { last, find, cloneDeep } from 'lodash';
import ReactDOM from 'react-dom';
import { confirmAlert } from 'react-confirm-alert';
import '../assets/css/center.css';
import '../assets/css/animate.min.css';
import Layout from '../layouts/Layout';
import Header from '../layouts/Header';
import SideBar from '../layouts/SideBar';
import RehabServiceImpl from '../service/impl/RehabServiceImpl';
import MngQuestSheetPopup from './components/questionnaires/MngQuestionnaireSheetComponent';

import TargetPerson from '../domain/TargetPerson';
import RehabHistoryServiceImpl from '../service/impl/RehabHistoryServiceImpl';
import RehabTargetHistory from '../domain/RehabTargetHistory';
import MngReservationComponent from '../components/MngReservationComponent';
import Reservation from '../../../domain/reservation/Reservation';

const rehabService = new RehabServiceImpl();
const rehabHistoryService = new RehabHistoryServiceImpl();
const menuTitle: string = '* 검진대상자 상세정보';
const title: string = '검진대상자 상세정보';

export default function CheckedUpTargetDetail(props: any) {
    let path = props.location.pathname;
    const history = useHistory();
    const location = useLocation();
    let targetInfo: any = location.state ? location.state : new TargetPerson();
    let reservation: Reservation = targetInfo.reservation;

    //설문 팝업 제어
    const [openPopup, setOpenPopup] = useState<any>({ display: 'none' });
    const [contrPopup, setContrPopup] = useState<string>('center-question-popup wow  animate__animated animate__fadeInUp');
    //예약변경 팝업 제어
    const [openReservationPopup, setOpenReservationPopup] = useState<any>({ display: 'none' });
    const [contrReservationPopup, setContrReservationPopup] = useState<string>('center-question-popup wow  animate__animated animate__fadeInUp');

    const [checkupHistory, setCheckupHistory] = useState<RehabTargetHistory[]>();
    const [checkupTarget, setCheckupTarget] = useState<RehabTargetHistory>();
    const [latestCheckup, setLatestCheckup] = useState<RehabTargetHistory>();
    const [latestPhyStatus, setLatestPhyStatus] = useState<any>();

    const [selectedHistoryTarget, setSelectedHistoryTarget] = useState<TargetPerson>();
    const [questionnaireDomain, setQuestionnaireDomain] = useState<string>();

    useEffect(() => {
        console.log('##### Checkup Detail Page #####');
        if (targetInfo.reservater) {
            let userNo: string = targetInfo.reservater.userNo;

            rehabHistoryService.getRehabHistoryForUserno(userNo).then((history: RehabTargetHistory[]) => {
                if (history.length > 0) {
                    let latest = find(history, (item) => {
                        return item.reservationNo === targetInfo.latestRehab;
                    });
                    let checkup = find(history, (item) => {
                        return item.reservationNo === targetInfo.reservationNo;
                    });

                    let latestPhy = findLatestPhyStatus(history);

                    setCheckupTarget(checkup);
                    setLatestCheckup(latest);
                    setLatestPhyStatus(latestPhy);
                    setCheckupHistory(history);
                }
            });
        }
    }, []);

    const completeCheckedupHandler = () => {
        let targetNo: number = checkupTarget?.targetNo ? checkupTarget.targetNo : 0;
        let historyNo: number = checkupTarget?.historyNo ? checkupTarget.historyNo : 0;
        if (historyNo === 0) {
            confirmAlert({
                title: '경고',
                message: '검진 전 입니다. 검진 완료는 검진 진행 후 가능 합니다.',
                buttons: [{ label: '확인', onClick: () => {} }],
            });
        } else {
            rehabHistoryService.complateCheckupForTarget(historyNo, targetNo).then((targetHistory) => {
                confirmAlert({
                    title: '검진완료',
                    message: '<' + targetHistory.historyNo + '>님의 검진이 완료 되었습니다. 감사합니다.',
                    buttons: [
                        {
                            label: '확인',
                            onClick: () => {
                                goBack();
                            },
                        },
                    ],
                });
            });
        }
    };

    const goBack = () => {
        history.replace('/rehab/checkedup');
    };

    const goInputHistoryHandler = (reservationNo: string) => {
        //let state = { checkedupTarget: targetInfo, historyTarget: checkupHistory, returnPath: '/rehab/checkedup/detail' };
        let state = {
            checkedupTarget: targetInfo,
            targetReservation: reservationNo,
            historyTarget: checkupHistory,
            returnPath: '/rehab/checkedup/detail',
        };
        history.push({
            pathname: '/rehab/checkedup/mngdata/physical',
            state: state,
        });
    };

    const goMakingReport = (targetNo: number, historyNo: number) => {
        let state = { checkedupTarget: targetInfo, historyTarget: null, returnPath: '/rehab/checkedup/detail' };
        history.push({
            pathname: '/rehab/reporting/' + targetNo + '/' + historyNo,
            state: state,
        });
    };

    const findLatestPhyStatus = (history: RehabTargetHistory[]) => {
        let latest = null;
        history?.map((item, idx) => {
            let physicalStatus = item.physicalStatus;
            if (physicalStatus != null && physicalStatus != '') {
                let obj = JSON.parse(physicalStatus);
                if (obj.height != '') {
                    obj.date = item.reservation?.startDate;
                    latest = obj;
                }
            }
        });
        return latest;
    };

    const openQuestPopup = (targetNo: number, reservation?: Reservation, domain?: string) => {
        let isDisplay = { display: 'block' };
        let fadeInStyle = 'center-question-popup wow  animate__animated animate__fadeInUp';

        let targetPerson = cloneDeep(targetInfo);
        targetPerson.targetNo = targetNo;
        targetPerson.reservation = reservation;
        targetPerson.reservationNo = reservation?.reservationNo ? reservation.reservationNo : '';
        setQuestionnaireDomain(domain);
        setSelectedHistoryTarget(targetPerson);
        setOpenPopup(isDisplay);
        setContrPopup(fadeInStyle);
    };
    const closeQuestPopup = () => {
        let isNotDisplay = { display: 'none' };
        let fadeOutStyle = 'center-question-popup wow  animate__animated animate__fadeOutDown';

        setContrPopup(fadeOutStyle);
        setOpenPopup(isNotDisplay);
    };

    const openPopupMngReservation = () => {
        let isDisplay = { display: 'block' };
        let fadeInStyle = 'center-question-popup wow  animate__animated animate__fadeInUp';
        setOpenReservationPopup(isDisplay);
        setContrReservationPopup(fadeInStyle);
    };

    const closePopupForMngReservation = (evt: any) => {
        let isNotDisplay = { display: 'none' };
        let fadeOutStyle = 'center-question-popup wow  animate__animated animate__fadeOutDown';
        setContrReservationPopup(fadeOutStyle);
        setOpenReservationPopup(isNotDisplay);

        if (evt === 'complete') {
            history.push({
                pathname: '/rehab/reserved',
            });
        }
    };

    let btnSet = (
        <>
            {checkupTarget?.targetState == 8001 ? (
                <button
                    className="btn btn-success"
                    style={{ borderRadius: '3px', padding: '8px', float: 'right', margin: '5px 2px' }}
                    onClick={openPopupMngReservation}
                >
                    예약변경
                </button>
            ) : (
                <></>
            )}

            <button className="btn btn-info" style={{ borderRadius: '3px', padding: '8px', float: 'right', margin: '5px 2px' }} onClick={goBack}>
                돌아가기
            </button>
            <button
                className="btn btn-danger"
                style={{ borderRadius: '3px', padding: '8px', float: 'right', margin: '5px 2px' }}
                onClick={completeCheckedupHandler}
            >
                검진완료
            </button>
        </>
    );

    return (
        <>
            <Layout
                header={<Header></Header>}
                sideBar={<SideBar name={path}></SideBar>}
                path={path}
                naviTitle={'검진자 상세정보화면'}
                menuTitle={'* 검진자 상세정보'}
            >
                <div style={{ width: '100%' }}>{btnSet}</div>
                {/* 검진 대상자 정보 부분 */}
                <div style={{ display: 'flex', padding: '10px 5px', width: '100%' }}>* 대상자 기본 정보</div>
                <div>
                    <table className="center-list-table" cellPadding="0" cellSpacing="0">
                        <colgroup>
                            <col style={{ width: '25%' }} />
                            <col style={{ width: '25%' }} />
                            <col style={{ width: '25%' }} />
                            <col style={{ width: '25%' }} />
                        </colgroup>
                        <thead>
                            <tr>
                                <td>이름</td>
                                <td>생년월일</td>
                                <td>소속</td>
                                <td>최근 검진 정보</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{targetInfo?.reservater?.name}</td>
                                <td>{targetInfo?.reservater?.birthdate}</td>
                                <td>{targetInfo?.team?.name}</td>
                                <td>{latestCheckup?.reservation?.startDate}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                {/* 검진 중 정보 부분 */}
                <div style={{ display: 'flex', padding: '10px 5px', width: '100%' }}>* 검진 중인 예약 정보</div>
                <div>
                    <table className="center-list-table" cellPadding="0" cellSpacing="0">
                        <colgroup>
                            <col style={{ width: '25%' }} />
                            <col style={{ width: '25%' }} />
                            <col style={{ width: '25%' }} />
                        </colgroup>
                        <thead>
                            <tr>
                                <td>예약일자</td>
                                <td>검진센터</td>
                                <td>검진번호</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    {checkupTarget?.reservation?.startDate} ~ {checkupTarget?.reservation?.endDate}
                                </td>
                                <td>{targetInfo?.infra?.name}</td>
                                <td>
                                    {checkupTarget?.historyNo === 0 ? <>(검진 전...)</> : <>{checkupTarget?.historyNo}</>}/{checkupTarget?.targetNo}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                {/* 최근 검진 이력 부분 */}
                <div style={{ display: 'flex', padding: '10px 5px', width: '100%' }}>* 최근 검진 이력</div>
                <div>
                    <table className="center-list-table" cellPadding="0" cellSpacing="0">
                        <colgroup>
                            <col style={{ width: '25%' }} />
                            <col style={{ width: '25%' }} />
                            <col style={{ width: '25%' }} />
                            <col style={{ width: '25%' }} />
                        </colgroup>
                        <thead>
                            <tr>
                                <td>측정일자</td>
                                <td>신장</td>
                                <td>체중</td>
                                <td>혈압</td>
                            </tr>
                        </thead>
                        <tbody>
                            {latestCheckup ? (
                                <tr>
                                    {latestPhyStatus ? (
                                        <>
                                            <td>{latestPhyStatus.date}</td>
                                            <td>{latestPhyStatus.height} Cm</td>
                                            <td>{latestPhyStatus.weight} Kg</td>
                                            <td>
                                                {latestPhyStatus.lBloodPressure}/{latestPhyStatus.hBloodPressure}
                                            </td>
                                        </>
                                    ) : (
                                        <td colSpan={4}>신체정보를 측정 이력이 없습니다.</td>
                                    )}
                                </tr>
                            ) : (
                                <tr>
                                    <td colSpan={4}>최근 검진 기록이 없습니다.</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
                {/* 재활 이력 부분 */}
                <div style={{ display: 'flex', padding: '10px 5px', width: '100%' }}>* 재활 이력</div>
                <div>
                    <table className="center-list-table" cellPadding="0" cellSpacing="0">
                        <colgroup>
                            <col style={{ width: '10%' }} />
                            <col style={{ width: '15%' }} />
                            <col style={{ width: '15%' }} />
                            <col style={{ width: '10%' }} />
                            <col style={{ width: '10%' }} />
                            <col style={{ width: '20%' }} />
                            <col style={{ width: '20%' }} />
                        </colgroup>
                        <thead>
                            <tr>
                                <td>검진번호/검진자번호</td>
                                <td>방문 일자</td>
                                <td>방문 센터</td>
                                <td>검진 상태</td>
                                <td>리포트 관리</td>
                                <td>검진데이터 상태</td>
                                <td>설문 관리</td>
                            </tr>
                        </thead>
                        <tbody>
                            {checkupHistory?.map((item, idx) => {
                                return (
                                    <tr key={idx}>
                                        <td>
                                            {item.historyNo === 0 ? <>(검진 전...)</> : <>{item.historyNo}</>} / {item.targetNo}
                                        </td>
                                        <td>{item.reservation?.startDate}</td>
                                        <td>{item.reservation?.infra?.name}</td>
                                        <td>{item.targetStateCode?.name}</td>
                                        <td>
                                            {item.targetState !== 8004 ? (
                                                <>
                                                    <button
                                                        className="btn btn-warning"
                                                        onClick={() => {
                                                            goMakingReport(item.targetNo, item.historyNo);
                                                        }}
                                                    >
                                                        리포트생성
                                                    </button>
                                                    <button
                                                        className="btn"
                                                        onClick={() => {
                                                            goInputHistoryHandler(item.reservationNo);
                                                        }}
                                                    >
                                                        입력
                                                    </button>
                                                </>
                                            ) : (
                                                <>
                                                    <button
                                                        className="btn btn-letter"
                                                        onClick={() => {
                                                            goMakingReport(item.targetNo, item.historyNo);
                                                        }}
                                                    >
                                                        리포트확인
                                                    </button>
                                                    <button
                                                        className="btn"
                                                        onClick={() => {
                                                            goInputHistoryHandler(item.reservationNo);
                                                        }}
                                                    >
                                                        수정
                                                    </button>
                                                </>
                                            )}
                                        </td>
                                        <td>
                                            {item.physicalStatus ? '신.정/' : ''}
                                            {item.conditions ? '상.확/' : ''}
                                            {item.basalPhysical ? '기.체/' : ''}
                                            {item.specialPhysical ? '전.체/' : ''}
                                            {item.basalExercisePrescription ||
                                            item.conditioningPrescription ||
                                            item.itemPrescription ||
                                            item.bodyPrescription ||
                                            item.rehabProgramPrescription ||
                                            item.opinions
                                                ? '운.처'
                                                : ''}
                                        </td>
                                        <td>
                                            <button
                                                className="btn btn-letter"
                                                value="REHAB"
                                                onClick={(e: any) => {
                                                    let domain = e.target.value;
                                                    openQuestPopup(item.targetNo, item.reservation, domain);
                                                }}
                                            >
                                                일반설문
                                            </button>
                                            <button
                                                className="btn btn-letter"
                                                value="PSYCH"
                                                onClick={(e: any) => {
                                                    let domain = e.target.value;
                                                    openQuestPopup(item.targetNo, item.reservation, domain);
                                                }}
                                            >
                                                심리상담설문
                                            </button>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
                <div id="center-popup-wrap" style={openPopup}>
                    <div className={contrPopup} data-wow-delay="0.2s">
                        <MngQuestSheetPopup
                            domain={questionnaireDomain}
                            targetPerson={selectedHistoryTarget}
                            closingHandler={closeQuestPopup}
                        ></MngQuestSheetPopup>
                    </div>
                </div>
                <div id="center-popup-wrap" style={openReservationPopup}>
                    <div className={contrReservationPopup} data-wow-delay="0.2s" style={{ minHeight: '400px', height: '500px' }}>
                        <MngReservationComponent
                            actionType="modify"
                            reservation={reservation}
                            closingHandler={closePopupForMngReservation}
                        ></MngReservationComponent>
                    </div>
                </div>
            </Layout>
        </>
    );
}
