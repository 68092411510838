import React, { useState } from 'react';
import Infra from '../../domain/infra/Infra';
import InfraCategoryComponent from './InfraCategoryComponent';
import InfraViewCard from './InfraViewCard';
import InfraCategory from '../../domain/infra/InfraCategory';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { addSearchWordAction } from '../../moldules/SearchWordReduce';

interface InfraMainViewerProps {
    title: string;
    setChildInfraCategoryNo?: (infraCategoryNo: number | undefined) => void;
    infras?: Infra[];
    infraCategories?: InfraCategory[];
    infraType?: string;
    searchingType: string;
    latitude?: number;
    longitude?: number;
}

function InfraMainViewer(props: InfraMainViewerProps) {
    const dispatch = useDispatch();
    const [infraCategoryName, setInfraCategoryName] = useState('전체');
    const [clickCategoryNo, setClickCategoryNo] = useState<number | undefined>(undefined);

    const infraCategoryEventHandler = (infraCategoryNo: number | undefined, infraCategoryName: string) => {
        if (props.setChildInfraCategoryNo) {
            setClickCategoryNo(infraCategoryNo);
            setInfraCategoryName(infraCategoryName);
            props.setChildInfraCategoryNo(infraCategoryNo);
        }
    };

    let infraType = props.infraType;
    let searchingType = props.searchingType;
    let listUrl = '/search-list';

    if (searchingType === 'recommendation') {
        listUrl += '/recomm';
    } else if (searchingType === 'nearBy') {
        listUrl += '/nearby';
    } else {
        listUrl += '/default';
    }

    if (infraType) {
        if (clickCategoryNo) {
            listUrl += '/' + infraType + '/' + clickCategoryNo;
        } else {
            listUrl += '/' + infraType + '/0';
        }
    } else {
        listUrl += '/sport/0';
    }

    if (searchingType === 'nearBy') {
        listUrl += '/' + props.latitude + '/' + props.longitude;
    } else {
        listUrl += '/0/0';
    }

    return (
        <section className="main-list">
            <div className="main-list-in">
                <div className="main-list-box">
                    <div className="sub-tit-box">
                        <div>
                            <h2>{props.title}</h2>
                            <div className="more-btn-box">
                                {/* <Link to={listUrl}>더보기</Link> */}
                                <a href={listUrl}>더보기</a>
                            </div>
                        </div>
                    </div>
                    <InfraCategoryComponent
                        infraCategories={props.infraCategories}
                        infraCategoryEventHandler={(infraCategoryNo, infraCategoryName) =>
                            infraCategoryEventHandler(infraCategoryNo, infraCategoryName)
                        }
                        clickedCategoryNo={clickCategoryNo}
                    ></InfraCategoryComponent>
                    <div className="clear"></div>
                    <div className="list01">
                        <ul>
                            {props.infras ? (
                                props.infras.length == 0 ? (
                                    <h5 style={{ textAlign: 'center' }}>{infraCategoryName} 시설이 없습니다.</h5>
                                ) : (
                                    props.infras.map((infra, index) => {
                                        return <InfraViewCard key={index} infra={infra}></InfraViewCard>;
                                    })
                                )
                            ) : (
                                <></>
                            )}
                        </ul>
                    </div>
                    <div className="clear"></div>
                </div>
            </div>
        </section>
    );
}
export default InfraMainViewer;
