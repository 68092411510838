import { JsonObject, JsonProperty } from 'json2typescript';
import Role from './Role';
import Authority from './Authority';
import RolesForUser from './RolesForUser';
import BelongTeam from './BelongTeam';

@JsonObject('user')
class User {
    @JsonProperty('userNo')
    userNo?: string;
    @JsonProperty('email')
    email?: string;
    @JsonProperty('imageUrl')
    imageUrl?: string;
    @JsonProperty('name')
    name?: string;
    @JsonProperty('authorities')
    authorities: Authority[] = new Array<Authority>();
    @JsonProperty('provider')
    provider?: string;
    @JsonProperty('phoneNumber')
    phoneNumber?: string;
    @JsonProperty('userTypeCodeId')
    userTypeCodeId?: number;
    @JsonProperty('sportCodeId')
    sportCodeId?: number;
    @JsonProperty('classificationCodeId')
    classificationCodeId?: number;
    @JsonProperty('belongedCodeId')
    belongedCodeId?: number;
    @JsonProperty('regionCodeId')
    regionCodeId?: number;
    @JsonProperty('genderCodeId')
    genderCodeId?: number;
    @JsonProperty('teamName')
    teamName?: string;
    password?: string;
    originalPassword?: string;
    checkTeamCrew(): boolean {
        let findedRole: boolean = false;
        console.log(this.authorities.length);
        if (this.authorities.length === 0) {
            //let roles = this.authorities.find((authrority: Authority) => authrority.authority == Role.TEAM_PLAYER);
            findedRole = true;
        } else if (this.authorities.length > 0) {
            let roles = this.authorities.find((authrority: Authority) => authrority.authority == Role.TEAM_PLAYER);
            if (roles) findedRole = true;
        }

        return findedRole;
    }
    checkTeamAdmin(): boolean {
        let findedRole;
        if (this.authorities) {
            findedRole = this.authorities.find((authrority: Authority) => authrority.authority == Role.TEAM_OPER);
        }

        return findedRole ? true : false;
    }

    checkSystemAdmin(): boolean {
        let findedRole;
        if (this.authorities) {
            findedRole = this.authorities.find((authrority: Authority) => authrority.authority == Role.SUPER_ADMIN);
        }
        return findedRole ? true : false;
    }
    // MUNICIPALITY_OPER
    checkMunicipalityOper(): boolean {
        let findedRole;
        if (this.authorities) {
            findedRole = this.authorities.find((authrority: Authority) => authrority.authority == Role.MUNICIPALITY_OPER);
        }

        return findedRole ? true : false;
    }

    checkCenterOperAuth(): boolean {
        let findedRole;
        if (this.authorities) {
            findedRole = this.authorities.find((authrority: Authority) => authrority.authority == Role.CENTER_OPER);
        }

        return findedRole ? true : false;
    }

    checkCenterRecorderAuth(): boolean {
        let findedRole;
        if (this.authorities) {
            findedRole = this.authorities.find((authrority: Authority) => authrority.authority == Role.CENTER_RECORDER);
        }

        return findedRole ? true : false;
    }

    getProvider(): string {
        let providerType = 'GSSC';
        if (this.provider == 'google') {
            providerType = '구글';
        } else if (this.provider == 'facebook') {
            providerType = '페이스북';
        } else if (this.provider == 'kakao') {
            providerType = '카카오';
        } else if ((this.provider = 'naver')) {
            providerType = '네이버';
        }

        return providerType;
    }
}

export default User;
