import React from 'react';
import SpecialPhysical from '../../domain/recording/SpecialPhysical';
interface IProps {
    data?: SpecialPhysical;
}
export default function FormCheckerReporting(props: IProps) {
    return (
        <>
            <table className="reporting-table" cellPadding={0} cellSpacing={0}>
                <colgroup>
                    <col style={{ width: '5%' }} />
                    <col style={{ width: '10%' }} />
                    <col style={{ width: '10%' }} />
                    <col style={{ width: '75%' }} />
                </colgroup>
                <tbody>
                    <tr>
                        <td rowSpan={11} style={{ fontWeight: 700, fontSize: '1em' }}>
                            폼 체커
                        </td>
                        <th>세부 항목</th>
                        <th>기울기 방향</th>
                        <th>기울기</th>
                    </tr>
                    <tr>
                        <td>전신</td>
                        <td>앞</td>
                        <td>{props.data?.formChecker.loinFlexion}</td>
                    </tr>
                    <tr>
                        <td>거북목</td>
                        <td>　</td>
                        <td>{props.data?.formChecker.loinFlexion}</td>
                    </tr>
                    <tr>
                        <td>어깨</td>
                        <td>　</td>
                        <td>
                            {props.data?.formChecker.shuolderL}:{props.data?.formChecker.shuolderR}
                        </td>
                    </tr>
                    <tr>
                        <td>골반</td>
                        <td>　</td>
                        <td>
                            {props.data?.formChecker.pelvisL} : {props.data?.formChecker.pelvisR}
                        </td>
                    </tr>
                    <tr>
                        <td>무릎</td>
                        <td>왼쪽 아래</td>
                        <td>
                            {props.data?.formChecker.kneeL} : {props.data?.formChecker.kneeR}
                        </td>
                    </tr>
                    <tr>
                        <td>발목</td>
                        <td>　</td>
                        <td>
                            {props.data?.formChecker.ankleL} : {props.data?.formChecker.ankleR}
                        </td>
                    </tr>
                    <tr>
                        <td rowSpan={4}>허리</td>
                        <td>Flexion</td>
                        <td>{props.data?.formChecker.loinFlexion}</td>
                    </tr>
                    <tr>
                        <td>Extension</td>
                        <td>{props.data?.formChecker.loinExtension}</td>
                    </tr>
                    <tr>
                        <td>Lateral Flexion Right</td>
                        <td>{props.data?.formChecker.loinLaterFR}</td>
                    </tr>
                    <tr>
                        <td>Lateral Flexion Left</td>
                        <td>{props.data?.formChecker.loinLaterFL}</td>
                    </tr>
                </tbody>
            </table>
            <table className="eval-table">
                <tbody>
                    <tr>
                        <td>
                            <span style={{ fontWeight: 700, fontSize: '15px' }}>* 평가</span>
                            <br />
                            {''}
                        </td>
                    </tr>
                </tbody>
            </table>
        </>
    );
}
