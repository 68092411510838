import React from 'react';
import CustomQnAType from '../../../../domain/CustomQnAType';
import Questionnaire from '../../../../domain/Questionnaire';
import QuestionnaireType from '../../../../domain/QuestionnarieType';

interface IManageQuest {
    questionnaires?: Questionnaire[];
    checkingClickHandler: (e: React.MouseEvent<HTMLElement>) => void;
    editClickHandler: (e: React.MouseEvent<HTMLElement>) => void;
}

function ManageQuestTable(props: IManageQuest) {
    return (
        <table className="center-list-table" cellPadding="0" cellSpacing="0">
            <thead>
                <tr>
                    <td>No.</td>
                    <td>구분</td>
                    <td>항목</td>
                    <td>제목</td>
                    <td>공개여부</td>
                    <td>관리</td>
                </tr>
            </thead>
            <tbody>
                {props.questionnaires?.length !== 0 ? (
                    props.questionnaires?.map((question: Questionnaire, index: number) => {
                        return (
                            <tr key={index}>
                                <td>{index + 1}.</td>
                                <td>{question.questionnaireDomain === 'REHAB' ? CustomQnAType.Rehabilitation : CustomQnAType.Satisfy}</td>
                                <td>{question.displayTitle}</td>
                                <td>{question.title}</td>
                                <td>
                                    <select className="center-select">
                                        <option>공개</option>
                                        <option>비공개</option>
                                    </select>
                                </td>
                                <td>
                                    <button className="center-button-yes" id={'quest_' + index} onClick={props.checkingClickHandler}>
                                        <i className="fas fa-search-plus"></i> 보기
                                    </button>
                                    {question.isDefaultable !== 1 ? (
                                        <button
                                            className="center-button-letter"
                                            style={{ marginLeft: '3px' }}
                                            id={'edit_' + question.quesNo}
                                            onClick={(e) => {
                                                props.editClickHandler(e);
                                            }}
                                        >
                                            <i className="fas fa-edit"></i> 편집
                                        </button>
                                    ) : (
                                        <></>
                                    )}
                                </td>
                            </tr>
                        );
                    })
                ) : (
                    <tr>
                        <td colSpan={7}>설문 데이터가 없습니다.</td>
                    </tr>
                )}
            </tbody>
        </table>
    );
}

export default ManageQuestTable;
