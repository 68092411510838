import React from 'react';
import { JsonObject, JsonProperty, JsonConvert } from 'json2typescript';
import Question from './Question';

@JsonObject('questionnaire')
export default class Questionnaire {
    @JsonProperty('quesNo')
    quesNo: number = 0;
    // @JsonProperty('answerNo')
    // answerNo: number = 0;
    @JsonProperty('displayTitle')
    displayTitle: string = '';
    @JsonProperty('questionnaireDomain')
    questionnaireDomain: string = '';
    @JsonProperty('title')
    title: string = '';
    @JsonProperty('description')
    description: string = '';
    @JsonProperty('questions')
    questions: string = '';
    // @JsonProperty('answer')
    // answer?: string;
    @JsonProperty('isReleased')
    isReleased: number = 0;
    @JsonProperty('questionnaireKey')
    questionnaireKey?: string;
    @JsonProperty('isDefaultable')
    isDefaultable: number = 0;
    isSend: boolean = false;
}
