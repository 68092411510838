import React from 'react';

interface BoardCardProps {
    notiYn: boolean;
    content: string;
    regiDate: Date;
}

function BoardCard(props: BoardCardProps) {
    return (
        <li>
            <div className="notice-box">
                <div>
                    {props.notiYn === true ? <img src="/assets/image/icon-notice.png"/> : ''}
                </div>
                <div className="notice_zone_txt_line">
                    <span className="notice-text">
                        {props.notiYn === true ? '공지' : ''} 
                    </span>
                    {props.content}
                    <br/>
                    {props.regiDate}
                </div>							
            </div>
        </li>
    )
}

export default BoardCard;