class RoleSearchCondition {
    roleGroupNos?: string;

    static getTeamRoleGroupCondition() {
        const roleSearchCondition = new RoleSearchCondition();
        roleSearchCondition.roleGroupNos = String(1);
        return roleSearchCondition;
    }
}

export default RoleSearchCondition;