import React, { useEffect, useState } from 'react';
import MyPageMainLayout from '../../../layout/MyPageMainLayout';
import TmpRegistedTeamUser from '../../../../../domain/team/TmpRegistedTeamUser';
import TmpRegistedTeamUserServiceImpl from '../../../../../service/team/impl/TmpRegistedTeamUserServiceImpl';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../moldules/index';
import MyPageRightContent from '../../../layout/MyPageRightContent';
import { Link } from 'react-router-dom';
import BelongTeam from '../../../../../domain/user/BelongTeam';
import TeamServiceImpl from '../../../../../service/team/impl/TeamServiceImpl';
import MyPageTeamAdminNavType from '../nav/MyPageTeamAdminNavType';
import MyPageTeamAdminNav from '../nav/MyPageTeamAdminNav';
import UserMyPagePlayerListItem from '../../../components/playerList/UserMyPagePlayerListItem';
import TempRegistedTeamUserSearchCondition from '../../../../../domain/team/param/TempRegistedTeamUserSearchCondition';
import { confirmAlert } from 'react-confirm-alert';
import UserServiceImpl from '../../../../../service/user/impl/UserServiceImpl';
import UserSearchCondition from '../../../../../domain/user/param/UserSearchCondition';
import Paging from '../../../../../domain/common/Paging';

const tmpRegistedTeamUserService = new TmpRegistedTeamUserServiceImpl();
const teamService = new TeamServiceImpl();
function UserMyPagePlayerListOfTeamAdmin() {
    const { myTeam } = useSelector((state: RootState) => state.userSessionReducer);
    const [tmpRegistedTeamUsers, setTmpRegistedTeamUsers] = useState<TmpRegistedTeamUser[]>();
    const [belongTeams, setBelongTeams] = useState<BelongTeam[]>();

    const [userName, setUserName] = useState<string>();
    const [phoneNumber, setPhoneNumber] = useState<string>();

    useEffect(() => {
        tmpRegistedTeamUserService.getTmpUsers(myTeam.teamNo, new TempRegistedTeamUserSearchCondition()).then((tmpRegistedTeamUsers) => {
            setTmpRegistedTeamUsers(tmpRegistedTeamUsers);
        });
    }, [myTeam]);

    const registeUserEvent = () => {
        if (!userName || !phoneNumber) {
            confirmAlert({
                title: '경고',
                message: '선수이름과 모바일번호 두가지 모두 입력 되어야 합니다. 확인해 주십시오',
                buttons: [{ label: '확인', onClick: () => {} }],
            });
        } else {
            let tmpRegistedTeamUser = new TmpRegistedTeamUser();
            let userService = new UserServiceImpl();

            let searchCondition = new UserSearchCondition();
            searchCondition.phoneNumber = phoneNumber;
            let paging = new Paging(0, 9999);

            userService.getUserInfos(searchCondition, paging).then((response) => {
                let user = response[0];
                if (user?.teamName === myTeam.name && user?.name === userName) {
                    tmpRegistedTeamUser.makeInstance(myTeam.teamNo, userName, phoneNumber);
                    tmpRegistedTeamUserService.userRegiste(tmpRegistedTeamUser).then((tmpRegistedTeamUser) => {
                        confirmAlert({
                            title: '등록성공',
                            message: '소속 선수 등록이 완료 되었습니다. 목록을 확인해 주십시오',
                            buttons: [{ label: '확인', onClick: () => {} }],
                        });
                        if (tmpRegistedTeamUsers) {
                            let test = [tmpRegistedTeamUser].concat(tmpRegistedTeamUsers);
                            setTmpRegistedTeamUsers(test);
                        }
                    });
                } else {
                    confirmAlert({
                        title: '알림',
                        message: '입력 된 정보로 소속 팀 <' + myTeam.name + '>로 등록 된 사용자가 없습니다. 확인하여 주십시오!',
                        buttons: [{ label: '확인', onClick: () => {} }],
                    });
                }
            });
        }
    };

    return (
        <MyPageMainLayout>
            <MyPageRightContent contentTitle={`팀 소속 선수 ${tmpRegistedTeamUsers ? tmpRegistedTeamUsers.length : 0}명`}>
                <>
                    <div className="lay-basic mgt10">
                        <div style={{ width: '40%' }}>
                            {/* 이름 */}
                            <input
                                onChange={(event) => setUserName(event.currentTarget.value)}
                                className="basic-gray"
                                placeholder="이름(2~15자)"
                                value={userName}
                                style={{ width: '80%' }}
                            />
                        </div>
                        <div style={{ width: '40%' }}>
                            {/* 휴대폰번호 */}
                            <input
                                onChange={(event) => setPhoneNumber(event.currentTarget.value)}
                                className="basic-gray"
                                placeholder="휴대폰번호 입력"
                                value={phoneNumber}
                                style={{ width: '80%' }}
                            />
                        </div>
                        <div style={{ width: '20%' }}>
                            <button onClick={registeUserEvent} className="bt-blue-2" style={{ width: '100%', margin: '5px auto' }}>
                                추가선수검색
                            </button>
                        </div>
                    </div>
                    <nav>
                        <table className="mypage-table">
                            <tr className="mypage-table-header">
                                <td colSpan={2}>이름</td>
                                <td>휴대번호</td>
                                <td>가입상태</td>
                                <td>관리</td>
                            </tr>
                            {tmpRegistedTeamUsers?.map((tmpRegistedTeamUser: TmpRegistedTeamUser, index) => {
                                return <UserMyPagePlayerListItem key={index} tmpRegistedTeamUser={tmpRegistedTeamUser}></UserMyPagePlayerListItem>;
                            })}
                        </table>
                    </nav>
                </>
            </MyPageRightContent>
        </MyPageMainLayout>
    );
}

export default UserMyPagePlayerListOfTeamAdmin;
