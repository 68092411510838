import { useEffect, useState } from 'react';
import Authority from '../../../domain/user/Authority';
import RoleSearchCondition from '../../../domain/code/param/RoleSearchCondition';
import useReoleRetrieve from '../../../hook/code/useRoleRetrieve';
import useUserRetrieveByUserNo from '../../../hook/user/useUserRetrieveByUserNo';
import Code from '../../../domain/code/Code';
import Roles from '../../../domain/user/Roles';

function useAdminManagerInfo(userNo?: string) {
    const { userDetailInfo, userBelongedTeams } = useUserRetrieveByUserNo(userNo);
    const [userRoleGroupNo, setUserRoleGroupNo] = useState<number>();
    const [userRole, setUserRole] = useState<Roles>();
    const [userRoleNo, setUserRoleNo] = useState<number>();
    const [userName, setUserName] = useState<string>();
    const [userPhoneNumber, setUserPhoneNumber] = useState<string>();
    const [userEmail, setUserEmail] = useState<string>();
    const [userPassword, setUserPassword] = useState<string>();
    const [userAuthority, setUserAuthority] = useState<Authority>();
    const [userParentRegionCodeId, setUserParentRegionCodeId] = useState<number>();
    const [userRegionCodeId, setUserRegionCodeId] = useState<number>();
    const [roleSearchCondition, setRoleSearchCondition] = useState<RoleSearchCondition>();
    const { roles } = useReoleRetrieve(roleSearchCondition);
    const [userClassificationCodeId, setUserClassificationCodeId] = useState<number>();
    const [userSportCodeId, setUserSportCodeId] = useState<number>();
    const [userBelongedCodeId, setUserBelongedCodeId] = useState<string | undefined>();

    useEffect(() => {
        if (userDetailInfo) {
            const userRoleGroupNo: number | undefined = userDetailInfo.authorities[0].roles?.roleGroups?.roleGroupNo;
            const userRoles: Roles | undefined = userDetailInfo.authorities[0].roles;
            const userRoleNo: number | undefined = userDetailInfo.authorities[0].roles?.roleNo;
            const userName: string | undefined = userDetailInfo.name;
            const userPhoneNumber: string | undefined = userDetailInfo.phoneNumber;
            const userEmail: string | undefined = userDetailInfo.email;
            const userPassword: string | undefined = undefined;
            const userAuthority = userDetailInfo.authorities[0];
            const userRegionCodeId = userDetailInfo.regionCodeId;
            const userRegionCode = userDetailInfo.regionCode;
            const userClassificationCodeId = userDetailInfo.classificationCodeId;
            const userSportCodeId = userDetailInfo.sportCodeId;
            const userBelongedCodeId = String(userDetailInfo.belongedCodeId);

            setUserRoleGroupNo(userRoleGroupNo);
            setUserRole(userRoles);
            setUserRoleNo(userRoleNo);
            setUserName(userName);
            setUserPhoneNumber(userPhoneNumber);
            setUserEmail(userEmail);
            setUserPassword(userPassword);
            setUserAuthority(userAuthority);
            setUserParentRegionCodeId(userRegionCode?.parentCodeId);
            setUserRegionCodeId(userRegionCodeId);
            setUserClassificationCodeId(userClassificationCodeId);
            setUserSportCodeId(userSportCodeId);
            setUserBelongedCodeId(userBelongedCodeId);
        }
    }, [userDetailInfo]);

    return {
        userDetailInfo,
        userBelongedTeams,
        userRoleGroupNo,
        setUserRoleGroupNo,
        userRole,
        userRoleNo,
        setUserRoleNo,
        userName,
        setUserName,
        userPhoneNumber,
        setUserPhoneNumber,
        userEmail,
        setUserEmail,
        userPassword,
        setUserPassword,
        userAuthority,
        setUserAuthority,
        setRoleSearchCondition,
        userParentRegionCodeId,
        setUserParentRegionCodeId,
        userRegionCodeId,
        setUserRegionCodeId,
        roles,
        userClassificationCodeId,
        setUserClassificationCodeId,
        userSportCodeId,
        setUserSportCodeId,
        userBelongedCodeId,
        setUserBelongedCodeId,
    };
}

export default useAdminManagerInfo;
