import { useEffect, useState } from "react";
import CouponStatusOfEvent from '../../domain/event/CouponStatusOfEvent';
import EventStatisticsService from '../../service/event/EventStatisticsService';
import EventStatisticsServiceImpl from '../../service/event/impl/EventStatisticsServiceImpl';

const eventStatisticsService = new EventStatisticsServiceImpl();
function useStatisticCouponByEvent() {
    const [couponStatusOfEvents, setCouponStatusOfEvents] = useState<CouponStatusOfEvent[]>();
    useEffect(() => {
        eventStatisticsService.getCouponeByEvent()
        .then((couponStatusOfEvents) => {
             setCouponStatusOfEvents(couponStatusOfEvents);           
        });
    }, []);

    return couponStatusOfEvents;
}

export default useStatisticCouponByEvent;