import React, { useState } from 'react';
import TempRegistedTeamUserSearchCondition from '../../../../domain/team/param/TempRegistedTeamUserSearchCondition';

interface AdminTmpTeamUserInfoFilterProps {
    setTempRegistedTeamUserSearchCondition: (searchCondition: TempRegistedTeamUserSearchCondition) => void;
}

function AdminTmpTeamUserInfoFilter(props: AdminTmpTeamUserInfoFilterProps) {
    const [join, setJoin] = useState<boolean>();
    const [searchWord, setSearchWord] = useState<string>();

    return (
        <div className="flex-basic">
            <div className="member-join">
                <select
                    onChange={(event) => {
                        console.log(event.currentTarget.value);
                        if(event.currentTarget.value === 'undefined') {
                            setJoin(undefined);
                        } else {
                            setJoin(JSON.parse(event.currentTarget.value));
                        }
                    }}
                >
                    <option value="undefined">가입여부전체</option>
                    <option value="true">가입</option>
                    <option value="false">미가입</option>
                </select>
            </div>
            <div className="member-search">
                <input type="text" placeholder="선수명을 입력하세요."
                    onChange={(event) => {
                        setSearchWord(event.currentTarget.value);
                    }}
                    value={searchWord}
                ></input>
            </div>
            <button className="bg-blue white round"
                onClick={() => {
                    const newSearchCondition = new TempRegistedTeamUserSearchCondition();
                    newSearchCondition.join = join;
                    newSearchCondition.searchWord = searchWord;

                    props.setTempRegistedTeamUserSearchCondition(newSearchCondition);
                }}
            >
                <i className="fas fa-search">선수 검색</i>
            </button>
        </div>
    )
}

export default AdminTmpTeamUserInfoFilter;