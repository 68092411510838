import React, { useEffect, useState } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { RouteComponentProps } from 'react-router-dom';
import AdminMainHeader from '../../layout/AdminMainHeader';
import AdminMainSider from '../../layout/AdminMainSider';
import useInfraRetrieveInfraNo from '../../../../hook/infra/useInfraRetrieveByInfraNo';
import InfraServiceImpl from '../../../../service/infra/impl/InfraServiceImpl';
import useCodeRetrieve from '../../../../hook/code/useCodeRetrieve';
import Infra from '../../../../domain/infra/Infra';
import AdminInfraChargeTr from '../../../../component/admin/AdminInfraChargeTr';
import AdminUpdateFormInputTr from '../../../../component/admin/form/AdminUpdateFormInputTr';
import AdminUpdateFormInputTextAreaTr from '../../../../component/admin/form/AdminUpdateFormInputTextAreaTr';
import AdminUpdateImageOfCardType from '../../../../component/admin/form/AdminUpdateImageOfCardType';
import InfraFileServiceImpl from '../../../../service/file/impl/InfraFileServiceImpl';
import AdminMainTitle from '../../../../component/admin/AdminMainTitle';
import InfraSearchCondition from '../../../../domain/infra/param/InfraSearchCondition';

import useGeographic from '../../../../hook/useGeographic';
import ChoiceLocationRenderer from '../../../../component/map/thirdParty/choiceLocation/ChoiceLocationRenderer';
import KaKaoMapForChoiceLocationRenderer from '../../../../component/map/thirdParty/choiceLocation/KaKaoMapForChoiceLocationRenderer';
import { confirmAlert } from 'react-confirm-alert';
import InfraCategory from '../../../../domain/infra/InfraCategory';
import { InfraCategoryType } from '../../../../domain/infra/InfraCategoryType';
import useInfraOfCategoryRetrieve from '../../../../hook/infra/useInfraCategoryRetrieve';
import useParentInfraCategoriesRetrieve from '../../../../hook/infra/useParentInfraCategoriesRetrieve';
import { CodeType } from '../../../../domain/code/CodeType';
import useChildCodeRetrieve from '../../../../hook/code/useChildCodeRetrieve';
import Code from '../../../../domain/code/Code';

interface AdminInfraFormContainerProps {
    facilityNo: string;
}

const infraService = new InfraServiceImpl();
const infraFileService = new InfraFileServiceImpl();

function AdminSubInfraFormContainer({ match }: RouteComponentProps<AdminInfraFormContainerProps>) {
    let location = useLocation();
    let history = useHistory();
    const mapId = 'location_map';
    const userLocation = useGeographic();

    let condition: InfraSearchCondition | any = location.state;
    let isRegisting = condition.flag === 'registry' ? true : false;

    const { facilityNo } = match.params;

    const { infra, setInfra } = useInfraRetrieveInfraNo(facilityNo);
    const { codes: parentRegionCodes } = useCodeRetrieve(CodeType.REGION);
    const [parentRegionCodeId, setParentRegionCodeId] = useState<number>(20001);
    const childRegionCodes = useChildCodeRetrieve(parentRegionCodeId);
    const [updateRegionCodeId, setUpdateRegionCodeId] = useState<number>();

    const { parentInfraCategories } = useParentInfraCategoriesRetrieve();
    const [parentInfraCategoryNo, setParentInfraCategoryNo] = useState<number>(2);
    const infraCategories = useInfraOfCategoryRetrieve(parentInfraCategoryNo);
    const [updateInfraCategoryNo, setUpdateInfraCategoryNo] = useState<string>();

    const [mapRenderer, setMapRenderer] = useState<KaKaoMapForChoiceLocationRenderer>();

    //form 관련해서 처리할 state
    const [infraNameOfNewChildInfra, setInfraNameOfNewChildInfra] = useState<string>();
    const [reservationTimeUnitOfNewChildInfra, setReservationTimeUnitOfNewChildInfra] = useState<number>();

    const [updateInfraName, setUpdateInfraName] = useState<string>();
    const [updateAddress, setUpdateAddress] = useState<string>();
    const [updateHomepageUrl, setUpdateHomepageUrl] = useState<string>();
    const [updatePhoneNumber, setUpdatePhoneNumber] = useState<string>();
    const [updateFacilityDescription, setUpdateFacilityDescription] = useState<string>();
    const [updateUseRuleDescription, setUpdateUseRuleDescription] = useState<string>();
    const [updateRefundPolicyDescription, setUpdateRefundPolicyDescription] = useState<string>();
    const [updateLatitude, setUpdateLatitude] = useState<number>();
    const [updateLongitude, setUpdateLongitude] = useState<number>();
    const [updateRecommendation, setUpdateRecommendation] = useState<string>();

    useEffect(() => {
        infraService.getInfra(facilityNo).then((infra) => {
            setParentInfraCategoryNo(infra.infraCategory?.parentInfraCategoryNo ? infra.infraCategory?.parentInfraCategoryNo : 2);
            setUpdateInfraCategoryNo(infra.infraCategoryNo ? infra.infraCategoryNo : '11');
            setParentRegionCodeId(infra.regionCode?.parentCodeId ? infra.regionCode?.parentCodeId : 20001);
            setUpdateRegionCodeId(infra.regionCodeId ? infra.regionCodeId : 2000113);

            setUpdateInfraName(infra.name);
            setUpdateAddress(infra.address);
            setUpdateHomepageUrl(infra.homepageUrl);
            setUpdatePhoneNumber(infra.phoneNumber);

            setUpdateFacilityDescription(infra.facilityDescription);
            setUpdateRefundPolicyDescription(infra.refundPolicyDescription);
            setUpdateUseRuleDescription(infra.useRuleDescription);

            setUpdateLatitude(infra.latitude);
            setUpdateLongitude(infra.longitude);

            setUpdateRecommendation(infra.recommandation ? 'true' : 'false');

            let mapRenderer = new KaKaoMapForChoiceLocationRenderer(mapId, 5, infra.latitude, infra.longitude, userLocation);
            mapRenderer.render();

            setMapRenderer(mapRenderer);
        });
    }, []);

    useEffect(() => {
        if (infraCategories && infraCategories.length > 0) {
            setUpdateInfraCategoryNo(infraCategories[0].infraCategoryNo.toString());
        }
    }, [infraCategories]);

    const goBack = () => {
        history?.replace({
            pathname: '/admin/facility/list',
            state: condition,
        });
    };

    const delFacility = (e: any) => {
        confirmAlert({
            title: '알림',
            message: updateInfraName + '을 삭제 하시겠습니까? <삭제진행> 하시면 삭제가 진행 됩니다.',
            buttons: [
                {
                    label: '삭제진행',
                    onClick: () => {
                        infraService.deleteInfra(facilityNo).then((result: Infra) => {
                            confirmAlert({
                                title: '성공',
                                message: '삭제가 완료 되었습니다. 리스트 화면으로 돌아 갑니다.',
                                buttons: [
                                    {
                                        label: '확인',
                                        onClick: () => {
                                            goBack();
                                        },
                                    },
                                ],
                            });
                        });
                    },
                },
                { label: '취소', onClick: () => console.log('') },
            ],
        });
    };

    const breakRegistry = (e: any) => {
        if (isRegisting) {
            const options: any = {
                title: '경고',
                message: '입력 중인 데이터가 있습니다.\n중지하시면 입력 중인 데이터가 삭제 됩니다.\n등록을 중단하시겠습니까?',
                buttons: [
                    {
                        label: '확인',
                        onClick: () => {
                            infraService.deleteInfra(facilityNo).then((result: Infra) => {
                                goBack();
                            });
                        },
                    },
                    { label: '취소' },
                ],
            };
            confirmAlert(options);
        }
    };

    const completeNBack = (e: any) => {
        let newInfraInfo: Infra = new Infra();
        if (facilityNo && updateInfraCategoryNo && updateRegionCodeId && updateInfraName) {
            newInfraInfo.infraNo = facilityNo;
            newInfraInfo.infraCategoryNo = updateInfraCategoryNo;
            newInfraInfo.regionCodeId = updateRegionCodeId;
            newInfraInfo.name = updateInfraName;
            newInfraInfo.address = updateAddress;
            newInfraInfo.homepageUrl = updateHomepageUrl;
            newInfraInfo.phoneNumber = updatePhoneNumber;
            newInfraInfo.facilityDescription = updateFacilityDescription;
            newInfraInfo.useRuleDescription = updateUseRuleDescription;
            newInfraInfo.refundPolicyDescription = updateRefundPolicyDescription;
            newInfraInfo.latitude = updateLatitude;
            newInfraInfo.longitude = updateLongitude;
            newInfraInfo.recommandation = updateRecommendation === 'true' ? true : false;

            infraService.updateInfra(facilityNo, newInfraInfo).then((result: Infra) => {
                let message: string = '<' + updateInfraName + '>의 정보가 정상 처리 되었습니다.';
                confirmAlert({
                    title: '성공',
                    message: message,
                    buttons: [
                        {
                            label: '확인',
                            onClick: () => {
                                goBack();
                            },
                        },
                    ],
                });
            });
        } else {
            confirmAlert({
                title: '경고',
                message: '입력 값을 확인해 주십시오.',
                buttons: [{ label: '확인', onClick: () => {} }],
            });
        }
    };

    const addressNMapHandler = () => {
        if (updateAddress) {
            mapRenderer?.setCoordiByAddress(updateAddress, setUpdateAddress, setUpdateLatitude, setUpdateLongitude);
        } else {
            confirmAlert({
                title: '경고',
                message: '입력 된 주소가 없습니다. 확인해 주세요.',
                buttons: [{ label: '확인', onClick: () => console.log('') }],
            });
        }
    };

    let pageTitle: any = <> </>;
    let contrBtns: any = <> </>;

    if (!isRegisting) {
        pageTitle = <h3>&lt; {updateInfraName} &gt; 상세정보관리 </h3>;
        contrBtns = (
            <>
                <button className="bg-red" onClick={delFacility}>
                    시설삭제
                </button>
                <button className="bg-white" onClick={completeNBack} style={{ backgroundColor: '#2f9d27' }}>
                    시설수정완료
                </button>
                <button className="bg-white" onClick={goBack}>
                    돌아가기
                </button>
            </>
        );
    } else {
        pageTitle = <h3>시설 정보 등록 중... </h3>;
        contrBtns = (
            <>
                <button className="bg-white" onClick={completeNBack}>
                    시설등록완료
                </button>
                <button className="bg-red" onClick={breakRegistry}>
                    시설등록중지
                </button>
            </>
        );
    }
    return (
        <>
            <div className="member-tit flex">
                {pageTitle}
                <div className="btn-wrap">{contrBtns}</div>
            </div>
            <div className="two-wrap">
                <div className="half">
                    <div>
                        <h4 className="blue">{updateInfraName}</h4>
                    </div>
                    <div className="thume-list" style={{ marginBottom: '25px' }}>
                        <AdminUpdateImageOfCardType
                            attachFileTargetNo={facilityNo}
                            upload={(file: any, mainFileYn: boolean) => {
                                return infraFileService.upload(facilityNo, file, mainFileYn);
                            }}
                            fileDelete={(saveFilePath: string) => {
                                return infraFileService.delete(facilityNo, saveFilePath);
                            }}
                            attachFiles={infra?.attachFiles}
                        ></AdminUpdateImageOfCardType>
                    </div>
                    <div className="member-list">
                        <table>
                            <colgroup>
                                <col style={{ width: '15%' }} />
                                <col style={{ width: '85%' }} />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <td className="txt-left">시설분류</td>
                                    <td className="txt-left">
                                        <select
                                            value={parentInfraCategoryNo}
                                            className="bg-light-gray"
                                            onChange={(event) => {
                                                setParentInfraCategoryNo(Number(event.currentTarget.value));
                                            }}
                                        >
                                            {parentInfraCategories?.map((infraCategory: InfraCategory, index: number) => {
                                                return <option value={infraCategory.infraCategoryNo}>{infraCategory.name}</option>;
                                            })}
                                        </select>
                                        <select
                                            value={updateInfraCategoryNo}
                                            className="bg-light-gray"
                                            onChange={(event) => {
                                                setUpdateInfraCategoryNo(event.currentTarget.value);
                                            }}
                                        >
                                            {infraCategories?.map((infraCategory: InfraCategory, index: number) => {
                                                return (
                                                    <option key={index} value={infraCategory.infraCategoryNo}>
                                                        {infraCategory.name}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="txt-left">시설 소재지역</td>
                                    <td className="txt-left">
                                        <select
                                            value={parentRegionCodeId}
                                            className="bg-light-gray"
                                            onChange={(event) => {
                                                setParentRegionCodeId(Number(event.currentTarget.value));
                                            }}
                                        >
                                            {parentRegionCodes?.map((regionCode: Code, index: number) => {
                                                return (
                                                    <option key={index} value={regionCode.codeId}>
                                                        {regionCode.name}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                        <select
                                            value={updateRegionCodeId}
                                            className="bg-light-gray"
                                            onChange={(event) => {
                                                setUpdateRegionCodeId(Number(event.currentTarget.value));
                                            }}
                                        >
                                            {childRegionCodes?.map((regionCode: Code, index: number) => {
                                                return (
                                                    <option key={index} value={regionCode.codeId}>
                                                        {regionCode.name}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="txt-left">추천 여부</td>
                                    <td className="txt-left">
                                        <select
                                            value={updateRecommendation?.toString()}
                                            className="bg-light-gray"
                                            onChange={(event) => {
                                                setUpdateRecommendation(event.currentTarget.value);
                                            }}
                                        >
                                            <option value="true">추천</option>
                                            <option value="false">비추천</option>
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="txt-left">시설 명</td>
                                    <td className="txt-left" style={{ display: 'flex' }}>
                                        <input
                                            style={{ width: '70%' }}
                                            className="bg-light-gray"
                                            type="text"
                                            placeholder="시설 명"
                                            value={updateInfraName}
                                            onChange={(event) => {
                                                setUpdateInfraName(event.currentTarget.value);
                                            }}
                                        ></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="txt-left">소재지 주소</td>
                                    <td className="txt-left" style={{ display: 'flex' }}>
                                        <input
                                            style={{ width: '70%' }}
                                            className="bg-light-gray"
                                            type="text"
                                            placeholder="주소 입력"
                                            value={updateAddress}
                                            onChange={(event) => {
                                                setUpdateAddress(event.currentTarget.value);
                                            }}
                                        ></input>
                                        <button
                                            className="white"
                                            onClick={addressNMapHandler}
                                            style={{ fontWeight: 900, backgroundColor: '#2f9d27' }}
                                        >
                                            지도 위치 지정
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="txt-left">홈페이지</td>
                                    <td className="txt-left" style={{ display: 'flex' }}>
                                        <input
                                            style={{ width: '70%' }}
                                            className="bg-light-gray"
                                            type="text"
                                            placeholder="홈페이지 주소 입력 예> http://www.homepage.org"
                                            value={updateHomepageUrl}
                                            onChange={(event) => {
                                                setUpdateHomepageUrl(event.currentTarget.value);
                                            }}
                                        ></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="txt-left">연락처</td>
                                    <td className="txt-left" style={{ display: 'flex' }}>
                                        <input
                                            style={{ width: '70%' }}
                                            className="bg-light-gray"
                                            type="text"
                                            placeholder="휴대폰 번호 입력"
                                            value={updatePhoneNumber}
                                            onChange={(event) => {
                                                setUpdatePhoneNumber(event.currentTarget.value);
                                            }}
                                        ></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="bg-light-gray txt-left">부속장비</td>
                                    <td className="bg-light-gray txt-right">
                                        <input
                                            type="textarea"
                                            className="s"
                                            placeholder="주경기장, 보조경기장, 체력단력,장, 세미나실, 숙소"
                                            value={updateFacilityDescription}
                                            onChange={(event) => {
                                                setUpdateFacilityDescription(event.currentTarget.value);
                                            }}
                                        />
                                    </td>
                                </tr>

                                <tr>
                                    <td className="bg-light-gray txt-left">시설이용규칙</td>
                                    <td className="bg-light-gray txt-right">
                                        <input
                                            type="textarea"
                                            className="s"
                                            placeholder="사용시간 준수, 정리정돈 철저"
                                            value={updateUseRuleDescription}
                                            onChange={(event) => {
                                                setUpdateUseRuleDescription(event.currentTarget.value);
                                            }}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="bg-light-gray txt-left">환불정책</td>
                                    <td className="bg-light-gray txt-right">
                                        <input
                                            type="textarea"
                                            className="s"
                                            placeholder="환불 정책"
                                            value={updateRefundPolicyDescription}
                                            onChange={(event) => {
                                                setUpdateRefundPolicyDescription(event.currentTarget.value);
                                            }}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="half">
                    <div className="member-list">
                        <table>
                            <tbody>
                                <tr>
                                    <td style={{ textAlign: 'left' }}>
                                        * 소재지주소를 입력하여 &lt;지도위치지정&gt; 으로 대략적인 좌표를 찾고 <br />* 상세한 위치는 지도 상에서
                                        선택하고 &lt;상세위치지정&gt; 버튼으로 설정
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div style={{ margin: 'auto 0' }}>
                                            <div
                                                id={mapId}
                                                style={{
                                                    width: '100%',
                                                    height: '500px',
                                                }}
                                            ></div>
                                        </div>
                                        <button
                                            onClick={() => {
                                                if (mapRenderer) {
                                                    setUpdateLatitude(mapRenderer.latitude);
                                                    setUpdateLongitude(mapRenderer.longitude);
                                                    confirmAlert({
                                                        title: '알림',
                                                        message: '좌표가 정상적으로 설정 되었습니다.',
                                                        buttons: [{ label: '확인', onClick: () => console.log('') }],
                                                    });
                                                }
                                            }}
                                            className="white"
                                            style={{ backgroundColor: '#2f9d27', fontWeight: 900, margin: '5px 5px 5px 5px' }}
                                        >
                                            상세위치지정
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AdminSubInfraFormContainer;
