import React from 'react';
import MapComponent from '../../../../../component/map/MapComponent';
import MyPageMainLayout from '../../../layout/MyPageMainLayout';
import UserPageSubMenuNav from '../nav/UserPageSubMenuNav';
function UserMyPageQnAContainer() {
    return (
        <MyPageMainLayout>
            <div className="my-training mgt10">
                <div className="my-title">운영센터</div>
                {/* <div className="mgt10"><p>도움이 필요하신가요?<br/>투어링센터는 언제나 최선을 다하겠습니다.</p></div> */}
                <div className="qna_list" style={{ fontSize: '15px' }}>
                    <ul>
                        <li>- 운영시간 : 평일 오전 10시 ~ 오후 6시, 점심시간 오후 12~ 13시, 공휴일 휴무</li>
                        <li>- 전화 : 055-230-0168</li>
                        <li>- 이메일 : jjh1013@masan.ac.kr</li>
                        <li>
                            <button
                                className="askbtn"
                                onClick={() => window.open('http://pf.kakao.com/_ZLxnzb', '_black')}
                                style={{ fontWeight: 700, fontSize: '20px' }}
                            ></button>
                        </li>
                    </ul>
                </div>
                {/* <div className="my-in">
                    <div className="my-in-info">
                        <div className="my-in-info-box">
                            <MapComponent longitude={128.505119} latitude={35.259648}></MapComponent>
                        </div>
                    </div>
                </div> */}
                <div style={{ border: '1px solid #e3e3e3', borderRadius: '5px' }}>
                    <MapComponent longitude={128.505119} latitude={35.259648}></MapComponent>
                </div>
            </div>
        </MyPageMainLayout>
    );
}

export default UserMyPageQnAContainer;
