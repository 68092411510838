import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router';
import AdminMainTitle from '../../../../component/admin/AdminMainTitle';
import { CodeType } from '../../../../domain/code/CodeType';
import useCodeRetrieve from '../../../../hook/code/useCodeRetrieve';
import Code from '../../../../domain/code/Code';
import useChildCodeRetrieve from '../../../../hook/code/useChildCodeRetrieve';
import Team from '../../../../domain/team/Team';
import useTeamRegiste from '../../../../hook/team/useTeamRegiste';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../moldules/index';
import TeamSearchCondition from '../../../../domain/team/param/TeamSearchCondition';
import { confirmAlert } from 'react-confirm-alert';

function AdminTeamFormPage() {
    const history = useHistory();
    const location = useLocation();

    const userState = useSelector((state: RootState) => state.userSessionReducer);
    const [teamName, setTeamName] = useState<string>();
    const [phoneNumber, setPhoneNumber] = useState<string>();
    const [homepageUrl, setHomePageUrl] = useState<string>();
    const [classificationCodeId, setClassificationCodeId] = useState<number>();
    const [belongedCodeId, setBelongedCodeId] = useState<number>();
    const [genderCodeId, setGenderCodeId] = useState<number>();
    const [parentRegionCodeId, setParentRegionCodeId] = useState<number>();
    const [regionCodeId, setRegionCodeId] = useState<number>();
    const [sportCodeId, setSportCodeId] = useState<number>();

    //const { codes: classficationCodes } = useCodeRetrieve(CodeType.CLASSIFICATION);
    const { codes: classficationCodes } = useCodeRetrieve(CodeType.TEAM_TYPE);
    const { codes: belongedCodes } = useCodeRetrieve(CodeType.BELONGED);
    const { codes: genderCodes } = useCodeRetrieve(CodeType.GENDER);
    const { codes: sportCodes } = useCodeRetrieve(CodeType.SPORT);
    const { codes: regionCodes } = useCodeRetrieve(CodeType.REGION);
    const childRegionCodes = useChildCodeRetrieve(parentRegionCodeId);

    const [newTeam, setNewTeam] = useState<Team>();

    const registedTeam = useTeamRegiste(newTeam);

    const style = {
        width: '250px',
    };
    const manStyle = {
        fontSize: '5px',
        color: '#f00',
    };

    useEffect(() => {
        if (registedTeam) {
            confirmAlert({
                title: '등록 성공',
                message: '팀 정보가 정상적으로 등록 되었습니다.',
                buttons: [
                    {
                        label: '확인',
                        onClick: () => {
                            goBack();
                        },
                    },
                ],
            });
            //alert('팀정보가 정상적으로 등록되었습니다.');
        }
    }, [registedTeam]);

    const goBack = () => {
        let condition: TeamSearchCondition | any = location.state;
        history?.replace({
            pathname: '/admin/team',
            state: condition,
        });
    };

    const registHandler = () => {
        if (teamName && classificationCodeId && belongedCodeId && genderCodeId && regionCodeId && sportCodeId) {
            const newTeam = new Team();
            newTeam.name = teamName;
            newTeam.phoneNumber = phoneNumber;
            newTeam.homepageUrl = homepageUrl;
            newTeam.classificationCodeId = classificationCodeId;
            newTeam.belongedCodeId = belongedCodeId;
            newTeam.genderCodeId = genderCodeId;
            newTeam.regionCodeId = regionCodeId;
            newTeam.sportCodeId = sportCodeId;
            newTeam.registerNo = userState.user.userNo;

            setNewTeam(newTeam);
        } else {
            confirmAlert({
                title: '경고',
                message: '입력 값을 확인해 주십시오!',
                buttons: [{ label: '확인', onClick: () => {} }],
            });
        }
    };
    return (
        <>
            <AdminMainTitle title="팀 정보 등록"></AdminMainTitle>
            <div className="two-wrap">
                <div className="half">
                    <div className="member-list">
                        <table>
                            <colgroup>
                                <col style={{ width: '15%', backgroundColor: '#f2f2f2' }} />
                                <col style={{ width: '85%' }} />
                            </colgroup>
                            <thead>
                                <tr>
                                    <td colSpan={2} style={{ padding: '2px 0' }}>
                                        <button
                                            id="modifyInfoBtn"
                                            className="bg-blue white"
                                            style={{ float: 'right', marginBottom: '5px', marginTop: '5px', fontWeight: 700 }}
                                            onClick={registHandler}
                                        >
                                            팀 등록
                                        </button>
                                        <button
                                            onClick={goBack}
                                            style={{
                                                backgroundColor: '#ffc107',
                                                color: '#3a4652',
                                                float: 'right',
                                                marginBottom: '5px',
                                                marginTop: '5px',
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            돌아가기
                                        </button>
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="txt-left">
                                        <div className=" pdl30 blue">
                                            <i className="fas fa-star-of-life" style={manStyle}></i>&nbsp;팀 명
                                        </div>
                                    </td>
                                    <td className="txt-left">
                                        <input type="text" value={teamName} onChange={(event) => setTeamName(event.currentTarget.value)}></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="txt-left">
                                        <div className=" pdl30 blue">대표 연락처</div>
                                    </td>
                                    <td className="txt-left">
                                        <input
                                            placeholder="000-0000-0000"
                                            type="text"
                                            value={phoneNumber}
                                            onChange={(event) => setPhoneNumber(event.currentTarget.value)}
                                        ></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="txt-left">
                                        <div className=" pdl30 blue">홈페이지</div>
                                    </td>
                                    <td className="txt-left">
                                        <input
                                            placeholder="http://www.homepage.com"
                                            type="text"
                                            value={homepageUrl}
                                            onChange={(event) => setHomePageUrl(event.currentTarget.value)}
                                        ></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="txt-left">
                                        <div className="pdl30 blue">
                                            <i className="fas fa-star-of-life" style={manStyle}></i>&nbsp;팀 성별
                                        </div>
                                    </td>
                                    <td className="txt-left">
                                        <select
                                            style={style}
                                            onChange={(event) => {
                                                setGenderCodeId(Number(event.currentTarget.value));
                                            }}
                                        >
                                            <option value={0}>팀성별선택</option>
                                            {genderCodes?.map((genderCode: Code, index: number) => {
                                                return (
                                                    <option key={index} value={genderCode.codeId}>
                                                        {genderCode.name}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="txt-left">
                                        <div className="pdl30 blue">
                                            <i className="fas fa-star-of-life" style={manStyle}></i>&nbsp;팀 분류그룹
                                        </div>
                                    </td>
                                    <td className="txt-left">
                                        <select
                                            style={style}
                                            onChange={(event) => {
                                                setBelongedCodeId(Number(event.currentTarget.value));
                                            }}
                                        >
                                            <option>팀분류그룹선택</option>
                                            {belongedCodes?.map((blongedCode: Code, index: number) => {
                                                return (
                                                    <option key={index} value={blongedCode.codeId}>
                                                        {blongedCode.name}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="txt-left">
                                        <div className=" pdl30 blue">
                                            <i className="fas fa-star-of-life" style={manStyle}></i>&nbsp;팀 분류
                                        </div>
                                    </td>
                                    <td className="txt-left">
                                        <select
                                            style={style}
                                            onChange={(event) => {
                                                setClassificationCodeId(Number(event.currentTarget.value));
                                            }}
                                        >
                                            <option value={0}>팀 분류선택</option>
                                            {classficationCodes?.map((classficationCode: Code, index: number) => {
                                                return (
                                                    <option key={index} value={classficationCode.codeId}>
                                                        {classficationCode.name}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="txt-left">
                                        <div className="pdl30 blue">
                                            <i className="fas fa-star-of-life" style={manStyle}></i>&nbsp;팀 종목
                                        </div>
                                    </td>
                                    <td className="txt-left">
                                        <select
                                            style={style}
                                            onChange={(event) => {
                                                setSportCodeId(Number(event.currentTarget.value));
                                            }}
                                        >
                                            <option value={0}>종목선택</option>
                                            {sportCodes?.map((sportCode: Code, index: number) => {
                                                return (
                                                    <option key={index} value={sportCode.codeId}>
                                                        {sportCode.name}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="txt-left">
                                        <div className="pdl30 blue">
                                            <i className="fas fa-star-of-life" style={manStyle}></i>&nbsp;연고지역
                                        </div>
                                    </td>
                                    <td className="txt-left">
                                        <select
                                            style={style}
                                            onChange={(event) => {
                                                setRegionCodeId(Number(event.currentTarget.value));
                                            }}
                                        >
                                            <option value={0}>연고지역선택</option>
                                            {regionCodes?.map((regionCode: Code, index: number) => {
                                                return <option value={regionCode.codeId}>{regionCode.name}</option>;
                                            })}
                                        </select>
                                        {/* <select
                                            onChange={(event) => {
                                                setRegionCodeId(Number(event.currentTarget.value));
                                            }}
                                        >
                                            <option value={0}>세부지역선택</option>
                                            {childRegionCodes?.map((regionCode: Code, index: number) => {
                                                return <option value={regionCode.codeId}>{regionCode.name}</option>;
                                            })}
                                        </select> */}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AdminTeamFormPage;
