import APIServerConfig from '../../../../config/APIServerConfig';
import AbstractService from '../../common/service/AbstractRehabService';
import Questionnaire from '../../domain/Questionnaire';
import RehabQuestionnairesService from '../RehabQuestionnairesService';
import QuestionnaireSearchCondition from '../../domain/param/QuestionnaireSearchCondition';
import QnA from '../../domain/QnA';

class RehabQuestionnairesServiceImpl extends AbstractService implements RehabQuestionnairesService {
    getQuestionnairesList(): Promise<Questionnaire[]> {
        return new Promise((resolve) => {
            APIServerConfig.recordAPIAxios.post('/questionnaire/list', JSON.stringify({})).then((response) => {
                let qusetionnaires: Questionnaire[] = this.jsonConvert.deserializeArray(response.data.questionnaires, Questionnaire);
                resolve(qusetionnaires);
            });
        });
    }

    getQuestionnairesListByUser(userNo: string): Promise<QnA[]> {
        const searchCondition = new QuestionnaireSearchCondition();
        searchCondition.userNo = userNo;
        return new Promise((resolve, reject) => {
            APIServerConfig.recordAPIAxios
                .post(`/questionnaire/assigned`, JSON.stringify(searchCondition))
                .then((response) => {
                    const questionnaireList: QnA[] = this.jsonConvert.deserializeArray(response.data.questionnaireList, QnA);
                    console.log(questionnaireList);
                    resolve(questionnaireList);
                })
                .catch((err) => {
                    console.log(err);
                });
        });
    }

    createQuestionnaire(questionnaire: Questionnaire): Promise<Questionnaire> {
        return new Promise((handler, reject) => {
            APIServerConfig.recordAPIAxios.post('/questionnaire/create', JSON.stringify(questionnaire)).then((response) => {
                handler(questionnaire);
            });
        });
    }

    deleteQuestionnaire(questionnaire: Questionnaire): Promise<Questionnaire> {
        return new Promise((handler, reject) => {
            APIServerConfig.recordAPIAxios.post('/questionnaire/delete', JSON.stringify(questionnaire)).then((response) => {
                handler(questionnaire);
            });
        });
    }
}

export default RehabQuestionnairesServiceImpl;
