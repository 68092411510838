import React from 'react';
import Infra from '../../../../../domain/infra/Infra';
import Paging from '../../../../../domain/common/Paging';
import { useEffect, useState } from 'react';
import EventSearchCondition from '../../../../../domain/param/EventSearchCondition';
import useRetrieveEventsByCondition from '../../../../../hook/event/useRetrieveEventsByCondition';
import Event from '../../../../../domain/event/Event';
interface CommonInfraEventTabProps {
    infra: Infra;
}
function CommonInfraEventTab(props: CommonInfraEventTabProps) {
    const [eventSearchCondition, setEventSearchCondition] = useState<EventSearchCondition>();
    const [paging, setPaging] = useState(new Paging(0, 10));
    const {events} = useRetrieveEventsByCondition(eventSearchCondition, paging);

    useEffect(() => {
        const eventSearchCondition = new EventSearchCondition();
        eventSearchCondition.regionCodeIds = String(props.infra.regionCodeId);
        eventSearchCondition.parentInfraCategoryNos = String(props.infra.infraCategory?.parentInfraCategoryNo);
        setEventSearchCondition(eventSearchCondition);
    }, [props.infra]);

    return (
        <div className="home-wrap">
            <div className="home-title">
                <span>행사</span>						
            </div>				
            <div className="home-box bg-gay clear">
                {

                    events? 
                        events.length > 0 ? 
                            events?.map((event: Event, index) => {
                                return (
                                    <div key={index} className="price-table">
                                        <div className="price-table-area">
                                            <p>{event.content}</p>
                                            <p>행사 기간 {event.startTime} ~ {event.expiredTime}</p>
                                        </div>
                                        <div className="price-table-price">
                                            <p>{event.eventCode?.name}</p>
                                            <p>사용률 {event.usedCouponCnt}/{event.issuedCouponCnt}</p>
                                        </div>
                                    </div>
                                )
                            }) 
                            : 
                            <h5 className="alert-h5">등록된 행사가 없습니다.</h5>
                        : 
                        <></>
                        
                }
                <div className="clear"></div>
            </div>					
        </div>
    )
}

export default CommonInfraEventTab;