import React, { useEffect, useRef, useState } from 'react';
import { Link, RouteComponentProps, useHistory } from 'react-router-dom';
import { useLocation } from 'react-router';
import useInfraRetrieveInfraNo from '../../../../hook/infra/useInfraRetrieveByInfraNo';
import Infra from '../../../../domain/infra/Infra';
import useCodeRetrieve from '../../../../hook/code/useCodeRetrieve';
import { CodeType } from '../../../../domain/code/CodeType';
import InfraServiceImpl from '../../../../service/infra/impl/InfraServiceImpl';
import AdminInfraChargeTr from '../../../../component/admin/AdminInfraChargeTr';
import AdminUpdateFormInputTr from '../../../../component/admin/form/AdminUpdateFormInputTr';
import AdminUpdateFormInputTextAreaTr from '../../../../component/admin/form/AdminUpdateFormInputTextAreaTr';
import useGeographic from '../../../../hook/useGeographic';
import KaKaoMapForChoiceLocationRenderer from '../../../../component/map/thirdParty/choiceLocation/KaKaoMapForChoiceLocationRenderer';
import ChoiceLocationRenderer from '../../../../component/map/thirdParty/choiceLocation/ChoiceLocationRenderer';
import Code from '../../../../domain/code/Code';
import AdminUpdateImageOfCardType from '../../../../component/admin/form/AdminUpdateImageOfCardType';
import InfraFileServiceImpl from '../../../../service/file/impl/InfraFileServiceImpl';
import AdminInfraNav from '../../nav/AdminInfraNav';
import AdminInfraNavTabEnum from '../../nav/enum/AdminInfraNavTabEnum';
import AdminInfraContainerProps from './props/AdminInfraContainerProps';
import useChildCodeRetrieve from '../../../../hook/code/useChildCodeRetrieve';
import useInfraOfCategoryRetrieve from '../../../../hook/infra/useInfraCategoryRetrieve';
import { InfraCategoryType } from '../../../../domain/infra/InfraCategoryType';
import InfraCategory from '../../../../domain/infra/InfraCategory';
import ValidationUtils from '../../../../utils/ValidationUtils';
import InfraSearchCondition from '../../../../domain/infra/param/InfraSearchCondition';
import { confirmAlert } from 'react-confirm-alert';

const infraService = new InfraServiceImpl();
const infraFileService = new InfraFileServiceImpl();
function AdminInfraFormContainer({ match }: RouteComponentProps<AdminInfraContainerProps>) {
    let history = useHistory();
    const location = useLocation();
    const userLocation = useGeographic();

    const mapId = 'location_map';

    let condition: InfraSearchCondition | any = location.state;
    let isRegisting = condition.flag === 'registry' ? true : false;

    const { infraNo } = match.params;
    const { infra, setInfra } = useInfraRetrieveInfraNo(infraNo);
    const { codes: sportCodes } = useCodeRetrieve(CodeType.SPORT);
    const infraCategories = useInfraOfCategoryRetrieve(InfraCategoryType.SPORT_INFRA_NO);
    const { codes: parentRegionCodes } = useCodeRetrieve(CodeType.REGION);
    const [parentRegionCodeId, setParentRegionCodeId] = useState<number>(20001);
    const childRegionCodes = useChildCodeRetrieve(parentRegionCodeId);
    const [childInfras, setChildInfras] = useState<Infra[]>();

    //form 관련해서 처리할 state
    const [infraNameOfNewChildInfra, setInfraNameOfNewChildInfra] = useState<string>();
    const [reservationTimeUnitOfNewChildInfra, setReservationTimeUnitOfNewChildInfra] = useState<number>();

    //update 관련 처리 state
    const [updateChildInfra, setUpdateChildInfra] = useState<Infra>();
    const [updateInfraName, setUpdateInfraName] = useState<string>();
    const [updateSportCodeId, setUpdateSportCodeId] = useState<number>();
    const [updateInfraCategoryNo, setUpdateInfraCategoryNo] = useState<string>();
    const [updateRegionCodeId, setUpdateRegionCodeId] = useState<number>();
    const [updateAddress, setUpdateAddress] = useState<string>();
    const [updateHomepageUrl, setUpdateHomepageUrl] = useState<string>();
    const [updatePhoneNumber, setUpdatePhoneNumber] = useState<string>();
    const [updateFacilityDescription, setUpdateFacilityDescription] = useState<string>();
    const [updateEquipmentDescription, setUpdateEquipmentDescription] = useState<string>();
    const [updateEtcDescription, setUpdateEtcDescription] = useState<string>();
    const [updateUseRuleDescription, setUpdateUseRuleDescription] = useState<string>();
    const [updateRefundPolicyDescription, setUpdateRefundPolicyDescription] = useState<string>();

    const [updateFirstPrVideoUrl, setUpdateFirstPrVideoUrl] = useState<string>();
    const [updateSecondPrVideoUrl, setUpdateSecondPrVideoUrl] = useState<string>();
    const [updateThirdPrVideoUrl, setUpdateThirdPrVideoUrl] = useState<string>();

    const [updateLatitude, setUpdateLatitude] = useState<number>();
    const [updateLongitude, setUpdateLongitude] = useState<number>();
    const [updateRecommendation, setUpdateRecommendation] = useState<string>();

    const [mapRenderer, setMapRenderer] = useState<KaKaoMapForChoiceLocationRenderer>();

    const updateInfraEventHandler = (propertyObj: string, value: any) => {
        if (infra) {
            let newInfraInfo: any = infra;
            if (newInfraInfo.hasOwnProperty(propertyObj)) newInfraInfo[`${propertyObj}`] = value;
            else throw new Error(`infra에 ${propertyObj} 속성이 존재하지 않습니다.`);
            //infraService.updateInfra(infraNo, newInfraInfo);
        }
    };

    useEffect(() => {
        if (infra) {
            setChildInfras(infra.childInfras);

            setUpdateInfraName(infra.name);
            setUpdateAddress(infra.address);
            setUpdateHomepageUrl(infra.homepageUrl);
            setUpdatePhoneNumber(infra.phoneNumber);
            setUpdateFacilityDescription(infra.facilityDescription);
            setUpdateEquipmentDescription(infra.equipmentDescription);
            setUpdateEtcDescription(infra.etcDescription);
            setUpdateUseRuleDescription(infra.useRuleDescription);
            setUpdateRefundPolicyDescription(infra.refundPolicyDescription);
            setUpdateFirstPrVideoUrl(infra.firstPrVideoUrl);
            setUpdateSecondPrVideoUrl(infra.secondPrVideoUrl);
            setUpdateThirdPrVideoUrl(infra.thirdPrVideoUrl);

            let mapRenderer = new KaKaoMapForChoiceLocationRenderer(mapId, 5, infra.latitude, infra.longitude, userLocation);
            mapRenderer.render();
            setMapRenderer(mapRenderer);

            setUpdateLatitude(infra.latitude);
            setUpdateLongitude(infra.longitude);

            setUpdateInfraCategoryNo(infra.infraCategoryNo ? infra.infraCategoryNo : '56');
            setParentRegionCodeId(infra.regionCode?.parentCodeId ? infra.regionCode?.parentCodeId : 20001);
            setUpdateRegionCodeId(infra.regionCodeId ? infra.regionCodeId : 2000113);
            setUpdateRecommendation(infra.recommandation ? 'true' : 'false');
        }
    }, [infra]);

    useEffect(() => {
        if (updateChildInfra)
            infraService.registeInfra(updateChildInfra).then((infra) => {
                let newChildInfras = childInfras?.concat([updateChildInfra]);
                setChildInfras(newChildInfras);
                alert('정상 등록');
                //input form 초기화
                setInfraNameOfNewChildInfra('');
                setReservationTimeUnitOfNewChildInfra(0);
            });
    }, [updateChildInfra]);

    const childInfraForm = (
        <>
            <div className="flex-basic">
                <h4 className="blue">하위 시설 목록</h4>
                <button
                    onClick={() => {
                        if (infra && infraNameOfNewChildInfra && reservationTimeUnitOfNewChildInfra) {
                            if (!ValidationUtils.nameExistCheck(infraNameOfNewChildInfra)) {
                                alert('시설명을 입력하세요.');
                                return;
                            }
                            if (reservationTimeUnitOfNewChildInfra <= 0) {
                                alert('예약 시간을 입력하세요.');
                                return;
                            }
                            const newChildInfra: Infra = Infra.makeChildInfraInfo(
                                infra,
                                infraNameOfNewChildInfra,
                                reservationTimeUnitOfNewChildInfra,
                            );
                            setUpdateChildInfra(newChildInfra);
                        }
                    }}
                    className="bg-blue white"
                >
                    <i className="fas fa-plus-circle"></i>하위 시설 추가
                </button>
            </div>
            <div className="member-list">
                <table>
                    <thead>
                        <tr>
                            <td>시설 명</td>
                            <td>최대 예약 시간</td>
                        </tr>
                    </thead>
                    <tbody>
                        {childInfras ? (
                            childInfras.length > 0 ? (
                                childInfras?.map((childInfra: Infra, index: number) => {
                                    return (
                                        <tr key={index}>
                                            <td className="txt-left">{childInfra.name}</td>
                                            <td className="txt-right">{childInfra.reservationTimeUnit}시간</td>
                                        </tr>
                                    );
                                })
                            ) : (
                                <></>
                            )
                        ) : (
                            <></>
                        )}
                    </tbody>
                    {/* 시설 등록 구간 */}
                    <tfoot>
                        <tr>
                            <td colSpan={2} style={{ textAlign: 'center' }}>
                                <h5 className="alert-h5">
                                    하위 시설 명과 최대예약 가능시간을 입력 하시고 상단의 &lt;하위 시설 추가&gt; 버튼을 클릭해 주세요{' '}
                                </h5>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <input
                                    style={{ width: '90%' }}
                                    onChange={(event) => {
                                        setInfraNameOfNewChildInfra(event.currentTarget.value);
                                    }}
                                    type="text"
                                    placeholder="시설명을 입력하세요."
                                    value={infraNameOfNewChildInfra}
                                />
                            </td>
                            <td>
                                <input
                                    style={{ width: '90%' }}
                                    onChange={(event) => {
                                        const reservationTime = Number(event.currentTarget.value);
                                        if (!Number.isNaN(reservationTime)) {
                                            setReservationTimeUnitOfNewChildInfra(Number(event.currentTarget.value));
                                        } else {
                                            alert('숫자를 입력하세요.');
                                        }
                                    }}
                                    type="text"
                                    placeholder="최대 예약 가능 시간을 입력하세요."
                                    value={reservationTimeUnitOfNewChildInfra}
                                />
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </>
    );

    const infraChargeForm = (
        <>
            <div className="flex-basic">
                <h4 className="blue">하위 시설 별 요금테이블</h4>
                {/* <button className="bg-blue white"><i className="fas fa-plus-circle"></i>요금 추가</button> */}
            </div>
            <div className="member-list">
                <table>
                    <thead>
                        <tr>
                            <td>시설 명</td>
                            <td>가격</td>
                        </tr>
                    </thead>
                    <tbody>
                        {childInfras ? (
                            childInfras.length > 0 ? (
                                childInfras?.map((childInfra: Infra, index: number) => {
                                    return <AdminInfraChargeTr infra={childInfra} key={index}></AdminInfraChargeTr>;
                                })
                            ) : (
                                <tr>
                                    <td colSpan={2} style={{ textAlign: 'center' }}>
                                        <h5 className="alert-h5">하위 시설을 등록하시면 요금등록이 진행됩니다.</h5>
                                    </td>
                                </tr>
                            )
                        ) : (
                            <></>
                        )}
                    </tbody>
                </table>
            </div>
        </>
    );

    const goBack = () => {
        let condition: InfraSearchCondition | any = location.state;
        history?.replace({
            pathname: '/admin/infra/list',
            state: condition,
        });
    };

    const delFacility = (e: any) => {
        confirmAlert({
            title: '알림',
            message: updateInfraName + '을 삭제 하시겠습니까? <삭제진행> 하시면 삭제가 진행 됩니다.',
            buttons: [
                {
                    label: '삭제진행',
                    onClick: () => {
                        infraService.deleteInfra(infraNo).then((result: Infra) => {
                            confirmAlert({
                                title: '성공',
                                message: '삭제가 완료 되었습니다. 리스트 화면으로 돌아 갑니다.',
                                buttons: [
                                    {
                                        label: '확인',
                                        onClick: () => {
                                            goBack();
                                        },
                                    },
                                ],
                            });
                        });
                    },
                },
                { label: '취소', onClick: () => console.log('') },
            ],
        });
    };

    const breakRegistry = (e: any) => {
        if (isRegisting) {
            const options: any = {
                title: '경고',
                message: '입력 중인 데이터가 있습니다.\n중지하시면 입력 중인 데이터가 삭제 됩니다.\n등록을 중단하시겠습니까?',
                buttons: [
                    {
                        label: '확인',
                        onClick: () => {
                            infraService.deleteInfra(infraNo).then((result: Infra) => {
                                goBack();
                            });
                        },
                    },
                    { label: '취소' },
                ],
            };
            confirmAlert(options);
        }
    };

    const completeNBack = (e: any) => {
        let newInfraInfo: Infra = new Infra();

        newInfraInfo.infraNo = infraNo;
        newInfraInfo.infraCategoryNo = updateInfraCategoryNo;
        newInfraInfo.regionCodeId = updateRegionCodeId;
        newInfraInfo.name = updateInfraName;
        newInfraInfo.address = updateAddress;
        newInfraInfo.homepageUrl = updateHomepageUrl;
        newInfraInfo.phoneNumber = updatePhoneNumber;
        newInfraInfo.facilityDescription = updateFacilityDescription;
        newInfraInfo.useRuleDescription = updateUseRuleDescription;
        newInfraInfo.refundPolicyDescription = updateRefundPolicyDescription;
        newInfraInfo.equipmentDescription = updateEquipmentDescription;
        newInfraInfo.latitude = updateLatitude;
        newInfraInfo.longitude = updateLongitude;
        newInfraInfo.recommandation = updateRecommendation === 'true' ? true : false;
        newInfraInfo.firstPrVideoUrl = updateFirstPrVideoUrl;
        newInfraInfo.secondPrVideoUrl = updateSecondPrVideoUrl;
        newInfraInfo.thirdPrVideoUrl = updateThirdPrVideoUrl;

        infraService.updateInfra(infraNo, newInfraInfo).then((result: Infra) => {
            let message: string = '<' + updateInfraName + '>의 정보가 정상 처리 되었습니다.';
            confirmAlert({
                title: '성공',
                message: message,
                buttons: [
                    {
                        label: '확인',
                        onClick: () => {
                            goBack();
                        },
                    },
                ],
            });
        });
    };

    const addressNMapHandler = () => {
        if (updateAddress) {
            mapRenderer?.setCoordiByAddress(updateAddress, setUpdateAddress, setUpdateLatitude, setUpdateLongitude);
        } else {
            confirmAlert({
                title: '경고',
                message: '입력 된 주소가 없습니다. 확인해 주세요.',
                buttons: [{ label: '확인', onClick: () => console.log('') }],
            });
        }
    };

    let pageTitle: any = <> </>;
    let contrBtns: any = <> </>;
    let infraNav: any = <> </>;
    if (!isRegisting) {
        pageTitle = <h3>&lt; {updateInfraName} &gt; 상세정보관리 </h3>;
        contrBtns = (
            <>
                <button className="bg-red" onClick={delFacility}>
                    시설삭제
                </button>
                <button className="bg-white" onClick={completeNBack} style={{ backgroundColor: '#2f9d27' }}>
                    시설수정완료
                </button>
                <button className="bg-white" onClick={goBack}>
                    돌아가기
                </button>
            </>
        );
        infraNav = (
            <>
                <br />
                <AdminInfraNav infraNo={infraNo} tab={AdminInfraNavTabEnum.DETAIL}></AdminInfraNav>
            </>
        );
    } else {
        pageTitle = <h3>시설 정보 등록 중... </h3>;
        contrBtns = (
            <>
                <button className="bg-white" onClick={completeNBack}>
                    시설등록완료
                </button>
                <button className="bg-red" onClick={breakRegistry}>
                    시설등록중지
                </button>
            </>
        );
    }

    return (
        <>
            <div className="member-tit flex">
                {pageTitle}
                <div className="btn-wrap">{contrBtns}</div>
            </div>
            {/* {infraNav} */}
            <div className="two-wrap">
                <div className="half">
                    <div className="thume-list">
                        <AdminUpdateImageOfCardType
                            attachFileTargetNo={infraNo}
                            upload={(file, mainFileYn) => {
                                return infraFileService.upload(infraNo, file, mainFileYn);
                            }}
                            fileDelete={(saveFilePath: string) => {
                                return infraFileService.delete(infraNo, saveFilePath);
                            }}
                            attachFiles={infra?.attachFiles}
                        ></AdminUpdateImageOfCardType>
                    </div>
                    <div className="member-list" style={{ marginTop: '15px' }}>
                        <table>
                            <tbody>
                                <tr>
                                    <td className="txt-left">시설 카테고리</td>
                                    <td className="txt-left">
                                        <select
                                            className="bg-light-gray"
                                            onChange={(event) => {
                                                setUpdateInfraCategoryNo(event.currentTarget.value);
                                            }}
                                        >
                                            {infraCategories?.map((infraCategory: InfraCategory, index: number) => {
                                                return (
                                                    <option
                                                        selected={updateInfraCategoryNo == String(infraCategory.infraCategoryNo)}
                                                        value={infraCategory.infraCategoryNo}
                                                    >
                                                        {infraCategory.name}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="txt-left">지역</td>
                                    <td className="txt-left">
                                        <select
                                            value={parentRegionCodeId}
                                            className="bg-light-gray"
                                            onChange={(event) => {
                                                setParentRegionCodeId(Number(event.currentTarget.value));
                                            }}
                                        >
                                            {parentRegionCodes?.map((regionCode: Code, index: number) => {
                                                return (
                                                    <option key={index} value={regionCode.codeId}>
                                                        {regionCode.name}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                        <select
                                            className="bg-light-gray"
                                            onChange={(event) => {
                                                setUpdateRegionCodeId(Number(event.currentTarget.value));
                                            }}
                                        >
                                            {childRegionCodes?.map((regionCode: Code, index: number) => {
                                                return (
                                                    <option key={index} value={regionCode.codeId} selected={updateRegionCodeId == regionCode.codeId}>
                                                        {regionCode.name}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="txt-left">추천 여부</td>
                                    <td className="txt-left">
                                        <select
                                            value={updateRecommendation?.toString()}
                                            className="bg-light-gray"
                                            onChange={(event) => {
                                                setUpdateRecommendation(event.currentTarget.value);
                                            }}
                                        >
                                            <option value="true">추천</option>
                                            <option value="false">비추천</option>
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="txt-left">시설 명</td>
                                    <td className="txt-left" style={{ display: 'flex' }}>
                                        <input
                                            style={{ width: '70%' }}
                                            className="bg-light-gray"
                                            type="text"
                                            placeholder="시설 명"
                                            value={updateInfraName}
                                            onChange={(event) => {
                                                setUpdateInfraName(event.currentTarget.value);
                                            }}
                                        ></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="txt-left">소재지 주소</td>
                                    <td className="txt-left" style={{ display: 'flex' }}>
                                        <input
                                            style={{ width: '70%' }}
                                            className="bg-light-gray"
                                            type="text"
                                            placeholder="주소 입력"
                                            value={updateAddress}
                                            onChange={(event) => {
                                                setUpdateAddress(event.currentTarget.value);
                                            }}
                                        ></input>
                                        <button
                                            className="white"
                                            onClick={addressNMapHandler}
                                            style={{ fontWeight: 900, backgroundColor: '#2f9d27' }}
                                        >
                                            지도 위치 지정
                                        </button>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="txt-left">홈페이지</td>
                                    <td className="txt-left" style={{ display: 'flex' }}>
                                        <input
                                            style={{ width: '70%' }}
                                            className="bg-light-gray"
                                            type="text"
                                            placeholder="홈페이지 주소 입력 예> http://www.homepage.org"
                                            value={updateHomepageUrl}
                                            onChange={(event) => {
                                                setUpdateHomepageUrl(event.currentTarget.value);
                                            }}
                                        ></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="txt-left">연락처</td>
                                    <td className="txt-left" style={{ display: 'flex' }}>
                                        <input
                                            style={{ width: '70%' }}
                                            className="bg-light-gray"
                                            type="text"
                                            placeholder="휴대폰 번호 입력"
                                            value={updatePhoneNumber}
                                            onChange={(event) => {
                                                setUpdatePhoneNumber(event.currentTarget.value);
                                            }}
                                        ></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="bg-light-gray txt-left">부속장비</td>
                                    <td className="bg-light-gray txt-right">
                                        <input
                                            type="textarea"
                                            className="s"
                                            placeholder="주경기장, 보조경기장, 체력단력,장, 세미나실, 숙소"
                                            value={updateFacilityDescription}
                                            onChange={(event) => {
                                                setUpdateFacilityDescription(event.currentTarget.value);
                                            }}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="bg-light-gray txt-left">기타 편의시설</td>
                                    <td className="bg-light-gray txt-right">
                                        <input
                                            type="textarea"
                                            className="s"
                                            placeholder="편의점, 카페, 선수대기실, 심판실, 감독실, 치어리더실"
                                            value={updateEquipmentDescription}
                                            onChange={(event) => {
                                                setUpdateEquipmentDescription(event.currentTarget.value);
                                            }}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="bg-light-gray txt-left">시설이용규칙</td>
                                    <td className="bg-light-gray txt-right">
                                        <input
                                            type="textarea"
                                            className="s"
                                            placeholder="사용시간 준수, 정리정돈 철저"
                                            value={updateUseRuleDescription}
                                            onChange={(event) => {
                                                setUpdateUseRuleDescription(event.currentTarget.value);
                                            }}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="bg-light-gray txt-left">환불정책</td>
                                    <td className="bg-light-gray txt-right">
                                        <input
                                            type="textarea"
                                            className="s"
                                            placeholder="환불 정책"
                                            value={updateRefundPolicyDescription}
                                            onChange={(event) => {
                                                setUpdateRefundPolicyDescription(event.currentTarget.value);
                                            }}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="half">
                    <div className="member-list" style={{ marginTop: '5px', marginBottom: '10px' }}>
                        <table>
                            <thead>
                                <th colSpan={2} style={{ padding: '5px', textAlign: 'left', fontSize: '20px', backgroundColor: '#e3e3e3' }}>
                                    홍보영상관리
                                </th>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="txt-left">홍보 영상 1번</td>
                                    <td className="txt-left" style={{ display: 'flex' }}>
                                        <input
                                            style={{ width: '70%' }}
                                            className="bg-light-gray"
                                            type="text"
                                            placeholder="http://pr_video_1.com"
                                            value={updateFirstPrVideoUrl}
                                            onChange={(event) => {
                                                setUpdateFirstPrVideoUrl(event.currentTarget.value);
                                            }}
                                        ></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="txt-left">홍보 영상 2번</td>
                                    <td className="txt-left" style={{ display: 'flex' }}>
                                        <input
                                            style={{ width: '70%' }}
                                            className="bg-light-gray"
                                            type="text"
                                            placeholder="http://pr_video_2.com"
                                            value={updateSecondPrVideoUrl}
                                            onChange={(event) => {
                                                setUpdateSecondPrVideoUrl(event.currentTarget.value);
                                            }}
                                        ></input>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="txt-left">홍보 영상 3번</td>
                                    <td className="txt-left" style={{ display: 'flex' }}>
                                        <input
                                            style={{ width: '70%' }}
                                            className="bg-light-gray"
                                            type="text"
                                            placeholder="http://pr_video_3.com"
                                            value={updateThirdPrVideoUrl}
                                            onChange={(event) => {
                                                setUpdateThirdPrVideoUrl(event.currentTarget.value);
                                            }}
                                        ></input>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="member-list">
                        <table>
                            <tbody>
                                <tr>
                                    <td style={{ textAlign: 'left' }}>
                                        * 소재지주소를 입력하여 &lt;지도위치지정&gt; 으로 대략적인 좌표를 찾고 <br />* 상세한 위치는 지도 상에서
                                        선택하고 &lt;상세위치지정&gt; 버튼으로 설정
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div style={{ margin: 'auto 0' }}>
                                            <div
                                                id={mapId}
                                                style={{
                                                    width: '100%',
                                                    height: '380px',
                                                }}
                                            ></div>
                                        </div>
                                        <button
                                            onClick={() => {
                                                if (mapRenderer) {
                                                    setUpdateLatitude(mapRenderer.latitude);
                                                    setUpdateLongitude(mapRenderer.longitude);
                                                    confirmAlert({
                                                        title: '알림',
                                                        message: '좌표가 정상적으로 설정 되었습니다.',
                                                        buttons: [{ label: '확인', onClick: () => console.log('') }],
                                                    });
                                                }
                                            }}
                                            className="white"
                                            style={{ backgroundColor: '#2f9d27', fontWeight: 900, margin: '5px 5px 5px 5px' }}
                                        >
                                            상세위치지정
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        {/* {childInfraForm}
                        {infraChargeForm} */}
                    </div>
                </div>
            </div>
        </>
    );
}

export default AdminInfraFormContainer;
