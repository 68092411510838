import React, { useEffect, useState } from 'react';
import AnswerItem from '../../../../domain/AnswerItem';
import QuestionnariesFormProps from './QuestionnariesFormsProps';
import useReportQuestionnarieAnswerMake from '../../../../../../hook/report/useReportQuestionnarieAnswerMake';
import ReportTypeEnum from '../../../../../../domain/report/param/ReportTypeEnum';
import APIServerConfig from '../../../../../../config/APIServerConfig';

export default function SpadiForm(props: QuestionnariesFormProps) {
    const putAnswerItem = (qNum: number, answerNumber: number) => {
        if (props.answerItems) {
            const newAnswerItem = AnswerItem.getNewAnswerItem(qNum, answerNumber);
            // props.answerItems.set(qNum, newAnswerItem);
            if (props.setAnswer) props.setAnswer(qNum, newAnswerItem);
        }
    };

    const getCheckOnClassName = (qNum: number, answerNumber: number): string => {
        if (props.answerItems) {
            if (props.answerItems.get(qNum)?.itemNoOfAnswer == answerNumber) {
                return 'check-on';
            } else {
                return '';
            }
        } else {
            return '';
        }
    };

    // const [firstQuestionScores, setFirstQustionScores] = useState(new Array<number|undefined>(undefined, undefined, undefined, undefined, undefined));
    const [firstQuestionAnswerCheck, setFirstQuestionAnswerCheck] = useState(
        new Array<number | undefined>(undefined, undefined, undefined, undefined, undefined),
    );
    const [firstQuestionScore, setFirstQuestionScore] = useState<number>(0);
    const [firstQuestionScoreRatio, setFirstQuestionScoreRatio] = useState<number>(0);

    const [secondQuestionAnswerCheck, setSecondQuestionAnswerCheck] = useState(
        new Array<number | undefined>(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
    );
    const [secondQuestionAnswerScore, setSecondQuestionAnswerScore] = useState<number>(0);
    const [secondQuestionScoreRatio, setSecondQuestionScoreRatio] = useState<number>(0);

    const [totalQuestionScore, setTotalQuestionScore] = useState<number>(0);
    const [totalQuestionScoreRatio, setTotalQuestionScoreRatio] = useState<number>(0);

    useEffect(() => {
        if (props.answerItems) {
            const answer1 = getAnswer(props.answerItems.get(1));
            const answer2 = getAnswer(props.answerItems.get(2));
            const answer3 = getAnswer(props.answerItems.get(3));
            const answer4 = getAnswer(props.answerItems.get(4));
            const answer5 = getAnswer(props.answerItems.get(5));

            setFirstQuestionScore(answer1 + answer2 + answer3 + answer4 + answer5);
            setFirstQuestionAnswerCheck([
                getAnswerCheck(props.answerItems.get(1)),
                getAnswerCheck(props.answerItems.get(2)),
                getAnswerCheck(props.answerItems.get(3)),
                getAnswerCheck(props.answerItems.get(4)),
                getAnswerCheck(props.answerItems.get(5)),
            ]);

            const answer6 = getAnswer(props.answerItems.get(6));
            const answer7 = getAnswer(props.answerItems.get(7));
            const answer8 = getAnswer(props.answerItems.get(8));
            const answer9 = getAnswer(props.answerItems.get(9));
            const answer10 = getAnswer(props.answerItems.get(10));
            const answer11 = getAnswer(props.answerItems.get(11));
            const answer12 = getAnswer(props.answerItems.get(12));
            const answer13 = getAnswer(props.answerItems.get(13));

            setSecondQuestionAnswerScore(answer6 + answer7 + answer8 + answer9 + answer10 + answer11 + answer12 + answer13);
            setSecondQuestionAnswerCheck([
                getAnswerCheck(props.answerItems.get(6)),
                getAnswerCheck(props.answerItems.get(7)),
                getAnswerCheck(props.answerItems.get(8)),
                getAnswerCheck(props.answerItems.get(9)),
                getAnswerCheck(props.answerItems.get(10)),
                getAnswerCheck(props.answerItems.get(11)),
                getAnswerCheck(props.answerItems.get(12)),
                getAnswerCheck(props.answerItems.get(13)),
            ]);
        }
    }, [props.answerItems]);

    useEffect(() => {
        const answerCnt = firstQuestionAnswerCheck.filter((value) => (value !== undefined ? true : false)).length;

        if (answerCnt) {
            setFirstQuestionScoreRatio((firstQuestionScore / (answerCnt * 10)) * 100);
        } else {
            setFirstQuestionScoreRatio(0);
        }
    }, [firstQuestionScore, firstQuestionAnswerCheck]);

    useEffect(() => {
        const answerCnt = secondQuestionAnswerCheck.filter((value) => (value !== undefined ? true : false)).length;
        if (answerCnt) {
            setSecondQuestionScoreRatio((secondQuestionAnswerScore / (answerCnt * 10)) * 100);
        } else {
            setSecondQuestionScoreRatio(0);
        }
    }, [secondQuestionAnswerScore, secondQuestionAnswerCheck]);

    useEffect(() => {
        const answerCnt =
            firstQuestionAnswerCheck.filter((value) => (value !== undefined ? true : false)).length +
            secondQuestionAnswerCheck.filter((value) => (value !== undefined ? true : false)).length;
        const totalQuestionScore = firstQuestionScore + secondQuestionAnswerScore;
        const totalQuestionScoreRatio = (totalQuestionScore / (answerCnt * 10)) * 100;
        setTotalQuestionScore(totalQuestionScore);
        setTotalQuestionScoreRatio(totalQuestionScoreRatio);
    }, [firstQuestionScore, firstQuestionAnswerCheck, secondQuestionAnswerScore, secondQuestionAnswerCheck]);

    const getAnswer = (answerItem?: AnswerItem): number => {
        if (answerItem) {
            if (answerItem.itemNoOfAnswer) {
                return answerItem.itemNoOfAnswer;
            } else {
                return 0;
            }
        } else {
            return 0;
        }
    };

    const getAnswerCheck = (answerItem?: AnswerItem): number | undefined => {
        if (answerItem) {
            if (answerItem.itemNoOfAnswer) {
                return answerItem.itemNoOfAnswer;
            } else {
                return undefined;
            }
        } else {
            return undefined;
        }
    };

    const { reportData } = useReportQuestionnarieAnswerMake(props.answerItems);

    return (
        <>
            <div className="center-center">
                어깨통증
                {props.resultPrintable ? (
                    <>
                        <form action={`${APIServerConfig.resourceAPIURL}/report/pdf`} method="POST">
                            <input type="hidden" name="reportType" value={ReportTypeEnum.SPADI_FORM}></input>
                            <input type="hidden" name="reportData" value={JSON.stringify(reportData)}></input>
                            <input type="submit" value="설문 결과 PDF 내려받기" />
                        </form>
                        <form action={`${APIServerConfig.resourceAPIURL}/report/xlsx`} method="POST">
                            <input type="hidden" name="reportType" value={ReportTypeEnum.SPADI_FORM}></input>
                            <input type="hidden" name="reportData" value={JSON.stringify(reportData)}></input>
                            <input type="submit" value="설문 결과 엑셀 내려받기" />
                        </form>
                    </>
                ) : (
                    <></>
                )}
            </div>

            <div className="tab-story-number">
                <h4>Shoulder Pain and Disability Index(SPADI)</h4>

                <table className="center-check-table-basic" cellPadding="0" cellSpacing="0">
                    <thead>
                        <tr>
                            <th colSpan={12}>
                                <div className="flex-basic">
                                    <div>※통증 척도-당신의 통증은 어느 정도 입니까?</div> <div>0=통증 없음, 10=심한 통증</div>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th>가장 심할 때</th>
                            <td
                                className={getCheckOnClassName(1, 0)}
                                onClick={() => {
                                    putAnswerItem(1, 0);
                                }}
                            >
                                0
                            </td>
                            <td
                                className={getCheckOnClassName(1, 1)}
                                onClick={() => {
                                    putAnswerItem(1, 1);
                                }}
                            >
                                1
                            </td>
                            <td
                                className={getCheckOnClassName(1, 2)}
                                onClick={() => {
                                    putAnswerItem(1, 2);
                                }}
                            >
                                2
                            </td>
                            <td
                                className={getCheckOnClassName(1, 3)}
                                onClick={() => {
                                    putAnswerItem(1, 3);
                                }}
                            >
                                3
                            </td>
                            <td
                                className={getCheckOnClassName(1, 4)}
                                onClick={() => {
                                    putAnswerItem(1, 4);
                                }}
                            >
                                4
                            </td>
                            <td
                                className={getCheckOnClassName(1, 5)}
                                onClick={() => {
                                    putAnswerItem(1, 5);
                                }}
                            >
                                5
                            </td>
                            <td
                                className={getCheckOnClassName(1, 6)}
                                onClick={() => {
                                    putAnswerItem(1, 6);
                                }}
                            >
                                6
                            </td>
                            <td
                                className={getCheckOnClassName(1, 7)}
                                onClick={() => {
                                    putAnswerItem(1, 7);
                                }}
                            >
                                7
                            </td>
                            <td
                                className={getCheckOnClassName(1, 8)}
                                onClick={() => {
                                    putAnswerItem(1, 8);
                                }}
                            >
                                8
                            </td>
                            <td
                                className={getCheckOnClassName(1, 9)}
                                onClick={() => {
                                    putAnswerItem(1, 9);
                                }}
                            >
                                9
                            </td>
                            <td
                                className={getCheckOnClassName(1, 10)}
                                onClick={() => {
                                    putAnswerItem(1, 10);
                                }}
                            >
                                10
                            </td>
                        </tr>
                        <tr>
                            <th>통증이 있는 쪽으로 누울 때</th>
                            <td
                                className={getCheckOnClassName(2, 0)}
                                onClick={() => {
                                    putAnswerItem(2, 0);
                                }}
                            >
                                0
                            </td>
                            <td
                                className={getCheckOnClassName(2, 1)}
                                onClick={() => {
                                    putAnswerItem(2, 1);
                                }}
                            >
                                1
                            </td>
                            <td
                                className={getCheckOnClassName(2, 2)}
                                onClick={() => {
                                    putAnswerItem(2, 2);
                                }}
                            >
                                2
                            </td>
                            <td
                                className={getCheckOnClassName(2, 3)}
                                onClick={() => {
                                    putAnswerItem(2, 3);
                                }}
                            >
                                3
                            </td>
                            <td
                                className={getCheckOnClassName(2, 4)}
                                onClick={() => {
                                    putAnswerItem(2, 4);
                                }}
                            >
                                4
                            </td>
                            <td
                                className={getCheckOnClassName(2, 5)}
                                onClick={() => {
                                    putAnswerItem(2, 5);
                                }}
                            >
                                5
                            </td>
                            <td
                                className={getCheckOnClassName(2, 6)}
                                onClick={() => {
                                    putAnswerItem(2, 6);
                                }}
                            >
                                6
                            </td>
                            <td
                                className={getCheckOnClassName(2, 7)}
                                onClick={() => {
                                    putAnswerItem(2, 7);
                                }}
                            >
                                7
                            </td>
                            <td
                                className={getCheckOnClassName(2, 8)}
                                onClick={() => {
                                    putAnswerItem(2, 8);
                                }}
                            >
                                8
                            </td>
                            <td
                                className={getCheckOnClassName(2, 9)}
                                onClick={() => {
                                    putAnswerItem(2, 9);
                                }}
                            >
                                9
                            </td>
                            <td
                                className={getCheckOnClassName(2, 10)}
                                onClick={() => {
                                    putAnswerItem(2, 10);
                                }}
                            >
                                10
                            </td>
                        </tr>
                        <tr>
                            <th>높은 선반의 물건에 팔을 뻗을 때</th>
                            <td
                                className={getCheckOnClassName(3, 0)}
                                onClick={() => {
                                    putAnswerItem(3, 0);
                                }}
                            >
                                0
                            </td>
                            <td
                                className={getCheckOnClassName(3, 1)}
                                onClick={() => {
                                    putAnswerItem(3, 1);
                                }}
                            >
                                1
                            </td>
                            <td
                                className={getCheckOnClassName(3, 2)}
                                onClick={() => {
                                    putAnswerItem(3, 2);
                                }}
                            >
                                2
                            </td>
                            <td
                                className={getCheckOnClassName(3, 3)}
                                onClick={() => {
                                    putAnswerItem(3, 3);
                                }}
                            >
                                3
                            </td>
                            <td
                                className={getCheckOnClassName(3, 4)}
                                onClick={() => {
                                    putAnswerItem(3, 4);
                                }}
                            >
                                4
                            </td>
                            <td
                                className={getCheckOnClassName(3, 5)}
                                onClick={() => {
                                    putAnswerItem(3, 5);
                                }}
                            >
                                5
                            </td>
                            <td
                                className={getCheckOnClassName(3, 6)}
                                onClick={() => {
                                    putAnswerItem(3, 6);
                                }}
                            >
                                6
                            </td>
                            <td
                                className={getCheckOnClassName(3, 7)}
                                onClick={() => {
                                    putAnswerItem(3, 7);
                                }}
                            >
                                7
                            </td>
                            <td
                                className={getCheckOnClassName(3, 8)}
                                onClick={() => {
                                    putAnswerItem(3, 8);
                                }}
                            >
                                8
                            </td>
                            <td
                                className={getCheckOnClassName(3, 9)}
                                onClick={() => {
                                    putAnswerItem(3, 9);
                                }}
                            >
                                9
                            </td>
                            <td
                                className={getCheckOnClassName(3, 10)}
                                onClick={() => {
                                    putAnswerItem(3, 10);
                                }}
                            >
                                10
                            </td>
                        </tr>
                        <tr>
                            <th>목 뒤를 잡을 때</th>
                            <td
                                className={getCheckOnClassName(4, 0)}
                                onClick={() => {
                                    putAnswerItem(4, 0);
                                }}
                            >
                                0
                            </td>
                            <td
                                className={getCheckOnClassName(4, 1)}
                                onClick={() => {
                                    putAnswerItem(4, 1);
                                }}
                            >
                                1
                            </td>
                            <td
                                className={getCheckOnClassName(4, 2)}
                                onClick={() => {
                                    putAnswerItem(4, 2);
                                }}
                            >
                                2
                            </td>
                            <td
                                className={getCheckOnClassName(4, 3)}
                                onClick={() => {
                                    putAnswerItem(4, 3);
                                }}
                            >
                                3
                            </td>
                            <td
                                className={getCheckOnClassName(4, 4)}
                                onClick={() => {
                                    putAnswerItem(4, 4);
                                }}
                            >
                                4
                            </td>
                            <td
                                className={getCheckOnClassName(4, 5)}
                                onClick={() => {
                                    putAnswerItem(4, 5);
                                }}
                            >
                                5
                            </td>
                            <td
                                className={getCheckOnClassName(4, 6)}
                                onClick={() => {
                                    putAnswerItem(4, 6);
                                }}
                            >
                                6
                            </td>
                            <td
                                className={getCheckOnClassName(4, 7)}
                                onClick={() => {
                                    putAnswerItem(4, 7);
                                }}
                            >
                                7
                            </td>
                            <td
                                className={getCheckOnClassName(4, 8)}
                                onClick={() => {
                                    putAnswerItem(4, 8);
                                }}
                            >
                                8
                            </td>
                            <td
                                className={getCheckOnClassName(4, 9)}
                                onClick={() => {
                                    putAnswerItem(4, 9);
                                }}
                            >
                                9
                            </td>
                            <td
                                className={getCheckOnClassName(4, 10)}
                                onClick={() => {
                                    putAnswerItem(4, 10);
                                }}
                            >
                                10
                            </td>
                        </tr>
                        <tr className="center-bottom-black">
                            <th>통증이 있는 팔로 밀 때</th>
                            <td
                                className={getCheckOnClassName(5, 0)}
                                onClick={() => {
                                    putAnswerItem(5, 0);
                                }}
                            >
                                0
                            </td>
                            <td
                                className={getCheckOnClassName(5, 1)}
                                onClick={() => {
                                    putAnswerItem(5, 1);
                                }}
                            >
                                1
                            </td>
                            <td
                                className={getCheckOnClassName(5, 2)}
                                onClick={() => {
                                    putAnswerItem(5, 2);
                                }}
                            >
                                2
                            </td>
                            <td
                                className={getCheckOnClassName(5, 3)}
                                onClick={() => {
                                    putAnswerItem(5, 3);
                                }}
                            >
                                3
                            </td>
                            <td
                                className={getCheckOnClassName(5, 4)}
                                onClick={() => {
                                    putAnswerItem(5, 4);
                                }}
                            >
                                4
                            </td>
                            <td
                                className={getCheckOnClassName(5, 5)}
                                onClick={() => {
                                    putAnswerItem(5, 5);
                                }}
                            >
                                5
                            </td>
                            <td
                                className={getCheckOnClassName(5, 6)}
                                onClick={() => {
                                    putAnswerItem(5, 6);
                                }}
                            >
                                6
                            </td>
                            <td
                                className={getCheckOnClassName(5, 7)}
                                onClick={() => {
                                    putAnswerItem(5, 7);
                                }}
                            >
                                7
                            </td>
                            <td
                                className={getCheckOnClassName(5, 8)}
                                onClick={() => {
                                    putAnswerItem(5, 8);
                                }}
                            >
                                8
                            </td>
                            <td
                                className={getCheckOnClassName(5, 9)}
                                onClick={() => {
                                    putAnswerItem(5, 9);
                                }}
                            >
                                9
                            </td>
                            <td
                                className={getCheckOnClassName(5, 10)}
                                onClick={() => {
                                    putAnswerItem(5, 10);
                                }}
                            >
                                10
                            </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colSpan={12}>
                                <div className="center-check-input-name">
                                    종합 통증 점수 :<input readOnly={true} type="text" className="center-day" value={firstQuestionScore} /> / 50 × 100
                                    =
                                    <input readOnly={true} type="text" className="center-day" value={firstQuestionScoreRatio} />%
                                    <br />
                                    (주의 : 만약 대상자가 한 항목을 체크하지 않았다면 40으로 나누어 계산함)
                                </div>
                            </td>
                        </tr>
                    </tfoot>
                </table>

                <table className="center-check-table-basic" cellPadding="0" cellSpacing="0">
                    <thead>
                        <tr>
                            <th colSpan={12}>
                                <div className="flex-basic">
                                    <div>※장애 척도-아래 일을 할 때 어느 정도로 힘이 듭니까?</div> <div>0=통증 없음, 10=심한 통증</div>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th>머리를 감을 때</th>
                            <td
                                className={getCheckOnClassName(6, 0)}
                                onClick={() => {
                                    putAnswerItem(6, 0);
                                }}
                            >
                                0
                            </td>
                            <td
                                className={getCheckOnClassName(6, 1)}
                                onClick={() => {
                                    putAnswerItem(6, 1);
                                }}
                            >
                                1
                            </td>
                            <td
                                className={getCheckOnClassName(6, 2)}
                                onClick={() => {
                                    putAnswerItem(6, 2);
                                }}
                            >
                                2
                            </td>
                            <td
                                className={getCheckOnClassName(6, 3)}
                                onClick={() => {
                                    putAnswerItem(6, 3);
                                }}
                            >
                                3
                            </td>
                            <td
                                className={getCheckOnClassName(6, 4)}
                                onClick={() => {
                                    putAnswerItem(6, 4);
                                }}
                            >
                                4
                            </td>
                            <td
                                className={getCheckOnClassName(6, 5)}
                                onClick={() => {
                                    putAnswerItem(6, 5);
                                }}
                            >
                                5
                            </td>
                            <td
                                className={getCheckOnClassName(6, 6)}
                                onClick={() => {
                                    putAnswerItem(6, 6);
                                }}
                            >
                                6
                            </td>
                            <td
                                className={getCheckOnClassName(6, 7)}
                                onClick={() => {
                                    putAnswerItem(6, 7);
                                }}
                            >
                                7
                            </td>
                            <td
                                className={getCheckOnClassName(6, 8)}
                                onClick={() => {
                                    putAnswerItem(6, 8);
                                }}
                            >
                                8
                            </td>
                            <td
                                className={getCheckOnClassName(6, 9)}
                                onClick={() => {
                                    putAnswerItem(6, 9);
                                }}
                            >
                                9
                            </td>
                            <td
                                className={getCheckOnClassName(6, 10)}
                                onClick={() => {
                                    putAnswerItem(6, 10);
                                }}
                            >
                                10
                            </td>
                        </tr>
                        <tr>
                            <th>등을 닦을 때</th>
                            <td
                                className={getCheckOnClassName(7, 0)}
                                onClick={() => {
                                    putAnswerItem(7, 0);
                                }}
                            >
                                0
                            </td>
                            <td
                                className={getCheckOnClassName(7, 1)}
                                onClick={() => {
                                    putAnswerItem(7, 1);
                                }}
                            >
                                1
                            </td>
                            <td
                                className={getCheckOnClassName(7, 2)}
                                onClick={() => {
                                    putAnswerItem(7, 2);
                                }}
                            >
                                2
                            </td>
                            <td
                                className={getCheckOnClassName(7, 3)}
                                onClick={() => {
                                    putAnswerItem(7, 3);
                                }}
                            >
                                3
                            </td>
                            <td
                                className={getCheckOnClassName(7, 4)}
                                onClick={() => {
                                    putAnswerItem(7, 4);
                                }}
                            >
                                4
                            </td>
                            <td
                                className={getCheckOnClassName(7, 5)}
                                onClick={() => {
                                    putAnswerItem(7, 5);
                                }}
                            >
                                5
                            </td>
                            <td
                                className={getCheckOnClassName(7, 6)}
                                onClick={() => {
                                    putAnswerItem(7, 6);
                                }}
                            >
                                6
                            </td>
                            <td
                                className={getCheckOnClassName(7, 7)}
                                onClick={() => {
                                    putAnswerItem(7, 7);
                                }}
                            >
                                7
                            </td>
                            <td
                                className={getCheckOnClassName(7, 8)}
                                onClick={() => {
                                    putAnswerItem(7, 8);
                                }}
                            >
                                8
                            </td>
                            <td
                                className={getCheckOnClassName(7, 9)}
                                onClick={() => {
                                    putAnswerItem(7, 9);
                                }}
                            >
                                9
                            </td>
                            <td
                                className={getCheckOnClassName(7, 10)}
                                onClick={() => {
                                    putAnswerItem(7, 10);
                                }}
                            >
                                10
                            </td>
                        </tr>
                        <tr>
                            <th>내의나 겉옷을 입을 때</th>
                            <td
                                className={getCheckOnClassName(8, 0)}
                                onClick={() => {
                                    putAnswerItem(8, 0);
                                }}
                            >
                                0
                            </td>
                            <td
                                className={getCheckOnClassName(8, 1)}
                                onClick={() => {
                                    putAnswerItem(8, 1);
                                }}
                            >
                                1
                            </td>
                            <td
                                className={getCheckOnClassName(8, 2)}
                                onClick={() => {
                                    putAnswerItem(8, 2);
                                }}
                            >
                                2
                            </td>
                            <td
                                className={getCheckOnClassName(8, 3)}
                                onClick={() => {
                                    putAnswerItem(8, 3);
                                }}
                            >
                                3
                            </td>
                            <td
                                className={getCheckOnClassName(8, 4)}
                                onClick={() => {
                                    putAnswerItem(8, 4);
                                }}
                            >
                                4
                            </td>
                            <td
                                className={getCheckOnClassName(8, 5)}
                                onClick={() => {
                                    putAnswerItem(8, 5);
                                }}
                            >
                                5
                            </td>
                            <td
                                className={getCheckOnClassName(8, 6)}
                                onClick={() => {
                                    putAnswerItem(8, 6);
                                }}
                            >
                                6
                            </td>
                            <td
                                className={getCheckOnClassName(8, 7)}
                                onClick={() => {
                                    putAnswerItem(8, 7);
                                }}
                            >
                                7
                            </td>
                            <td
                                className={getCheckOnClassName(8, 8)}
                                onClick={() => {
                                    putAnswerItem(8, 8);
                                }}
                            >
                                8
                            </td>
                            <td
                                className={getCheckOnClassName(8, 9)}
                                onClick={() => {
                                    putAnswerItem(8, 9);
                                }}
                            >
                                9
                            </td>
                            <td
                                className={getCheckOnClassName(8, 10)}
                                onClick={() => {
                                    putAnswerItem(8, 10);
                                }}
                            >
                                10
                            </td>
                        </tr>
                        <tr>
                            <th>앞에 단추가 있는 셔츠를 입을 때</th>
                            <td
                                className={getCheckOnClassName(9, 0)}
                                onClick={() => {
                                    putAnswerItem(9, 0);
                                }}
                            >
                                0
                            </td>
                            <td
                                className={getCheckOnClassName(9, 1)}
                                onClick={() => {
                                    putAnswerItem(9, 1);
                                }}
                            >
                                1
                            </td>
                            <td
                                className={getCheckOnClassName(9, 2)}
                                onClick={() => {
                                    putAnswerItem(9, 2);
                                }}
                            >
                                2
                            </td>
                            <td
                                className={getCheckOnClassName(9, 3)}
                                onClick={() => {
                                    putAnswerItem(9, 3);
                                }}
                            >
                                3
                            </td>
                            <td
                                className={getCheckOnClassName(9, 4)}
                                onClick={() => {
                                    putAnswerItem(9, 4);
                                }}
                            >
                                4
                            </td>
                            <td
                                className={getCheckOnClassName(9, 5)}
                                onClick={() => {
                                    putAnswerItem(9, 5);
                                }}
                            >
                                5
                            </td>
                            <td
                                className={getCheckOnClassName(9, 6)}
                                onClick={() => {
                                    putAnswerItem(9, 6);
                                }}
                            >
                                6
                            </td>
                            <td
                                className={getCheckOnClassName(9, 7)}
                                onClick={() => {
                                    putAnswerItem(9, 7);
                                }}
                            >
                                7
                            </td>
                            <td
                                className={getCheckOnClassName(9, 8)}
                                onClick={() => {
                                    putAnswerItem(9, 8);
                                }}
                            >
                                8
                            </td>
                            <td
                                className={getCheckOnClassName(9, 9)}
                                onClick={() => {
                                    putAnswerItem(9, 9);
                                }}
                            >
                                9
                            </td>
                            <td
                                className={getCheckOnClassName(9, 10)}
                                onClick={() => {
                                    putAnswerItem(9, 10);
                                }}
                            >
                                10
                            </td>
                        </tr>
                        <tr>
                            <th>바지를 입을 때</th>
                            <td
                                className={getCheckOnClassName(10, 0)}
                                onClick={() => {
                                    putAnswerItem(10, 0);
                                }}
                            >
                                0
                            </td>
                            <td
                                className={getCheckOnClassName(10, 1)}
                                onClick={() => {
                                    putAnswerItem(10, 1);
                                }}
                            >
                                1
                            </td>
                            <td
                                className={getCheckOnClassName(10, 2)}
                                onClick={() => {
                                    putAnswerItem(10, 2);
                                }}
                            >
                                2
                            </td>
                            <td
                                className={getCheckOnClassName(10, 3)}
                                onClick={() => {
                                    putAnswerItem(10, 3);
                                }}
                            >
                                3
                            </td>
                            <td
                                className={getCheckOnClassName(10, 4)}
                                onClick={() => {
                                    putAnswerItem(10, 4);
                                }}
                            >
                                4
                            </td>
                            <td
                                className={getCheckOnClassName(10, 5)}
                                onClick={() => {
                                    putAnswerItem(10, 5);
                                }}
                            >
                                5
                            </td>
                            <td
                                className={getCheckOnClassName(10, 6)}
                                onClick={() => {
                                    putAnswerItem(10, 6);
                                }}
                            >
                                6
                            </td>
                            <td
                                className={getCheckOnClassName(10, 7)}
                                onClick={() => {
                                    putAnswerItem(10, 7);
                                }}
                            >
                                7
                            </td>
                            <td
                                className={getCheckOnClassName(10, 8)}
                                onClick={() => {
                                    putAnswerItem(10, 8);
                                }}
                            >
                                8
                            </td>
                            <td
                                className={getCheckOnClassName(10, 9)}
                                onClick={() => {
                                    putAnswerItem(10, 9);
                                }}
                            >
                                9
                            </td>
                            <td
                                className={getCheckOnClassName(10, 10)}
                                onClick={() => {
                                    putAnswerItem(10, 10);
                                }}
                            >
                                10
                            </td>
                        </tr>
                        <tr>
                            <th>높은 선반 위에 물건을 놓을 때</th>
                            <td
                                className={getCheckOnClassName(11, 0)}
                                onClick={() => {
                                    putAnswerItem(11, 0);
                                }}
                            >
                                0
                            </td>
                            <td
                                className={getCheckOnClassName(11, 1)}
                                onClick={() => {
                                    putAnswerItem(11, 1);
                                }}
                            >
                                1
                            </td>
                            <td
                                className={getCheckOnClassName(11, 2)}
                                onClick={() => {
                                    putAnswerItem(11, 2);
                                }}
                            >
                                2
                            </td>
                            <td
                                className={getCheckOnClassName(11, 3)}
                                onClick={() => {
                                    putAnswerItem(11, 3);
                                }}
                            >
                                3
                            </td>
                            <td
                                className={getCheckOnClassName(11, 4)}
                                onClick={() => {
                                    putAnswerItem(11, 4);
                                }}
                            >
                                4
                            </td>
                            <td
                                className={getCheckOnClassName(11, 5)}
                                onClick={() => {
                                    putAnswerItem(11, 5);
                                }}
                            >
                                5
                            </td>
                            <td
                                className={getCheckOnClassName(11, 6)}
                                onClick={() => {
                                    putAnswerItem(11, 6);
                                }}
                            >
                                6
                            </td>
                            <td
                                className={getCheckOnClassName(11, 7)}
                                onClick={() => {
                                    putAnswerItem(11, 7);
                                }}
                            >
                                7
                            </td>
                            <td
                                className={getCheckOnClassName(11, 8)}
                                onClick={() => {
                                    putAnswerItem(11, 8);
                                }}
                            >
                                8
                            </td>
                            <td
                                className={getCheckOnClassName(11, 9)}
                                onClick={() => {
                                    putAnswerItem(11, 9);
                                }}
                            >
                                9
                            </td>
                            <td
                                className={getCheckOnClassName(11, 10)}
                                onClick={() => {
                                    putAnswerItem(11, 10);
                                }}
                            >
                                10
                            </td>
                        </tr>
                        <tr>
                            <th>4.5kg(10 파운드)의 무거운 물체를 들어 나를 때</th>
                            <td
                                className={getCheckOnClassName(12, 0)}
                                onClick={() => {
                                    putAnswerItem(12, 0);
                                }}
                            >
                                0
                            </td>
                            <td
                                className={getCheckOnClassName(12, 1)}
                                onClick={() => {
                                    putAnswerItem(12, 1);
                                }}
                            >
                                1
                            </td>
                            <td
                                className={getCheckOnClassName(12, 2)}
                                onClick={() => {
                                    putAnswerItem(12, 2);
                                }}
                            >
                                2
                            </td>
                            <td
                                className={getCheckOnClassName(12, 3)}
                                onClick={() => {
                                    putAnswerItem(12, 3);
                                }}
                            >
                                3
                            </td>
                            <td
                                className={getCheckOnClassName(12, 4)}
                                onClick={() => {
                                    putAnswerItem(12, 4);
                                }}
                            >
                                4
                            </td>
                            <td
                                className={getCheckOnClassName(12, 5)}
                                onClick={() => {
                                    putAnswerItem(12, 5);
                                }}
                            >
                                5
                            </td>
                            <td
                                className={getCheckOnClassName(12, 6)}
                                onClick={() => {
                                    putAnswerItem(12, 6);
                                }}
                            >
                                6
                            </td>
                            <td
                                className={getCheckOnClassName(12, 7)}
                                onClick={() => {
                                    putAnswerItem(12, 7);
                                }}
                            >
                                7
                            </td>
                            <td
                                className={getCheckOnClassName(12, 8)}
                                onClick={() => {
                                    putAnswerItem(12, 8);
                                }}
                            >
                                8
                            </td>
                            <td
                                className={getCheckOnClassName(12, 9)}
                                onClick={() => {
                                    putAnswerItem(12, 9);
                                }}
                            >
                                9
                            </td>
                            <td
                                className={getCheckOnClassName(12, 10)}
                                onClick={() => {
                                    putAnswerItem(12, 10);
                                }}
                            >
                                10
                            </td>
                        </tr>
                        <tr className="center-bottom-black">
                            <th>바지 뒷주머니에서 무엇인가를 꺼낼 때</th>
                            <td
                                className={getCheckOnClassName(13, 0)}
                                onClick={() => {
                                    putAnswerItem(13, 0);
                                }}
                            >
                                0
                            </td>
                            <td
                                className={getCheckOnClassName(13, 1)}
                                onClick={() => {
                                    putAnswerItem(13, 1);
                                }}
                            >
                                1
                            </td>
                            <td
                                className={getCheckOnClassName(13, 2)}
                                onClick={() => {
                                    putAnswerItem(13, 2);
                                }}
                            >
                                2
                            </td>
                            <td
                                className={getCheckOnClassName(13, 3)}
                                onClick={() => {
                                    putAnswerItem(13, 3);
                                }}
                            >
                                3
                            </td>
                            <td
                                className={getCheckOnClassName(13, 4)}
                                onClick={() => {
                                    putAnswerItem(13, 4);
                                }}
                            >
                                4
                            </td>
                            <td
                                className={getCheckOnClassName(13, 5)}
                                onClick={() => {
                                    putAnswerItem(13, 5);
                                }}
                            >
                                5
                            </td>
                            <td
                                className={getCheckOnClassName(13, 6)}
                                onClick={() => {
                                    putAnswerItem(13, 6);
                                }}
                            >
                                6
                            </td>
                            <td
                                className={getCheckOnClassName(13, 7)}
                                onClick={() => {
                                    putAnswerItem(13, 7);
                                }}
                            >
                                7
                            </td>
                            <td
                                className={getCheckOnClassName(13, 8)}
                                onClick={() => {
                                    putAnswerItem(13, 8);
                                }}
                            >
                                8
                            </td>
                            <td
                                className={getCheckOnClassName(13, 9)}
                                onClick={() => {
                                    putAnswerItem(13, 9);
                                }}
                            >
                                9
                            </td>
                            <td
                                className={getCheckOnClassName(13, 10)}
                                onClick={() => {
                                    putAnswerItem(13, 10);
                                }}
                            >
                                10
                            </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colSpan={12}>
                                <div className="center-check-input-name">
                                    종합 통증 점수 :<input readOnly={true} type="text" className="center-day" value={secondQuestionAnswerScore} /> /
                                    80 × 100 =
                                    <input readOnly={true} type="text" className="center-day" value={secondQuestionScoreRatio} />%
                                    <br />
                                    (주의 : 만약 대상자가 한 항목을 체크하지 않았다면 70으로 나누어 계산함)
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={12}>
                                <div className="center-check-input-name">
                                    총합 점수 :<input readOnly={true} type="text" className="center-day" value={totalQuestionScore} /> / 130 × 100 =
                                    <input readOnly={true} type="text" className="center-day" value={totalQuestionScoreRatio} />%
                                    <br />
                                    최소 변화 점수 (90% 신뢰도) = 13 점
                                </div>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </>
    );
}
