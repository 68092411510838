import React, { MouseEvent, useEffect, useState } from 'react';
import Infra from '../../domain/infra/Infra';
import useReservationPopupContainerHook from './hook/useReservationPopupContainerHook';
import DateUtils from '../../utils/DateUtils';
import { useSelector } from 'react-redux';
import { RootState } from '../../moldules/index';
import Team from '../../domain/team/Team';
import User from '../../domain/user/User';
import Reservation from '../../domain/reservation/Reservation';
import ReservationServiceImpl from '../../service/reservation/impl/ReservationServiceImpl';
import CalendarComponent from '../../component/calendar/CalendarComponent';
import { Link } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';

const reservationService = new ReservationServiceImpl();

interface ReservationPopupConatiner {
    openable: boolean;
    changeReservationPopupOpenable: (openable: boolean) => void;
    mainInfra: Infra;
    childInfras?: Infra[];
}

function reservate(
    props: ReservationPopupConatiner,
    reservationStartDay?: string,
    reservationEndDay?: string,
    reservationInfra?: Infra,
    team?: Team,
    user?: User,
) {
    if (reservationStartDay && reservationEndDay && reservationInfra && team && user && props.mainInfra.infraNo) {
        const reservation = Reservation.makeReservation(
            user,
            user,
            team,
            props.mainInfra.infraNo,
            reservationInfra,
            reservationStartDay,
            reservationEndDay,
        );
        reservationService
            .registeReservation(reservation)
            .then(() => {
                closeReservationPopup(props);
                alert('예약이 완료되었습니다.');
                window.location.reload();
            })
            .catch((err) => {
                alert(err);
            });
    } else {
        if (!reservationStartDay) {
            alert('예약 시작 날짜 정보가 누락되었습니다.');
            return;
        }
        if (!reservationEndDay) {
            alert('예약 종료 날짜 정보가 누락되었습니다.');
            return;
        }
        if (!reservationInfra) {
            alert('예약 시설 정보가 누락되었습니다.');
            return;
        }
    }
}

function closeReservationPopup(props: ReservationPopupConatiner) {
    props.changeReservationPopupOpenable(false);
}

function ReservationPopupContainer(props: ReservationPopupConatiner) {
    var initStartDate = new Date();
    const { user, myTeam, isLogin, hasTeamAuth, hasSystemAuth } = useSelector((state: RootState) => state.userSessionReducer);

    const {
        reservationInfraVisible,
        setReservationInfraVisible,
        reservationStartDay,
        setReservationStartDay,
        reservationEndDay,
        setReservationEndDay,
        reservationInfra,
        setReservationInfra,
        reservationTimeTable,
        setReservationTimeTable,
        reservationTime,
        setReservationTime,
        reservationStartDayVisible,
        setReservationStartDayVisible,
        reservationEndDayVisible,
        setReservationEndDayVisible,
    } = useReservationPopupContainerHook(props.openable);

    useEffect(() => {
        // let timeTable = props.mainInfra.getReservationTimeTable();
        setReservationStartDay(DateUtils.getDateForm(initStartDate));
        setReservationEndDay(DateUtils.getDateForm(initStartDate));
        // setReservationTimeTable(timeTable);
    }, [props.mainInfra]);

    return (
        <div className={props.openable ? 'needpopup-opened needpopup-scrolled' : ''}>
            <div className="needpopup_wrapper">
                {isLogin && (hasTeamAuth || hasSystemAuth) ? (
                    <div id="reserve" className={`needpopup stacked ${props.openable ? 'opened' : ''}`} style={{ display: 'block' }}>
                        <p className="pop-title">예약가능 여부를 확인해 보세요</p>
                        <div id="visit-date">
                            <div className="popup">
                                <div className="visit-strat">
                                    <div
                                        id="myPopup"
                                        onClick={() => {
                                            setReservationStartDayVisible(!reservationStartDayVisible);
                                            setReservationEndDayVisible(false);
                                        }}
                                    >
                                        방문시작일<p>{reservationStartDay}</p>
                                    </div>
                                    <div className={`popuptext ${reservationStartDayVisible ? 'show' : ''}`}>
                                        <CalendarComponent onDateChange={setReservationStartDay}></CalendarComponent>
                                    </div>
                                </div>
                                <div className="visit-end">
                                    <div
                                        onClick={() => {
                                            setReservationStartDayVisible(false);
                                            setReservationEndDayVisible(!reservationEndDayVisible);
                                        }}
                                    >
                                        방문종료일<p>{reservationEndDay}</p>
                                    </div>
                                    <div id="myPopup2" className={`popuptext ${reservationEndDayVisible ? 'show' : ''}`}>
                                        <CalendarComponent onDateChange={setReservationEndDay}></CalendarComponent>
                                    </div>
                                </div>
                            </div>

                            <div className="clear"></div>
                        </div>
                        <div>
                            <div className="container-pop">
                                {/* <ul>
                                <li className="dropdown">
                                <a onClick={() => {setReservationTimeVisible(!reservationTimeVisilble)}} data-toggle="dropdown">방문시간 </a>
                                    <ul className={`dropdown-menu ${reservationTimeVisilble ? 'show' : 'hide'}`}>
                                        
                                        {
                                            reservationTimeTable ? reservationTimeTable.map((time, index) => {
                                                return <li key={index}><label><input onChange={e => {setReservationTime(e.target.value)}} name='reservationTime' type="radio" value={time}/> {time} </label></li>
                                            }) : '예약 가능 시간이 없습니다.'
                                        }
                                    </ul>
                                </li>
                            </ul> */}
                                {/* <ul>
                                <li className="dropdown">
                                    <a data-toggle="dropdown">방문시간</a>
                                    <ul className="dropdown-menu show"> 
                                        {
                                            reservationTimeTable ? reservationTimeTable.map((time, index) => {
                                                return <li key={index}><label><input onChange={e => {setReservationTime(e.target.value)}} name='reservationTime' type="radio" value={time}/> {time} </label></li>
                                            }) : '예약 가능 시간이 없습니다.'
                                        }
                                    </ul>
                                </li>
                            </ul> */}
                                <ul>
                                    <li className="dropdown">
                                        <a data-toggle="dropdown">방문 시설</a>
                                        <ul className={`dropdown-menu show`}>
                                            {props.childInfras ? (
                                                props.childInfras.length > 0 ? (
                                                    props.childInfras.map((infra, index) => {
                                                        return (
                                                            <li key={index}>
                                                                <label>
                                                                    <input
                                                                        checked={infra.infraNo == reservationInfra?.infraNo}
                                                                        onChange={(e) => {
                                                                            setReservationInfra(infra);
                                                                        }}
                                                                        name="reservationInfra"
                                                                        type="radio"
                                                                        value={infra.infraNo}
                                                                    />
                                                                    {infra.name}
                                                                </label>
                                                            </li>
                                                        );
                                                    })
                                                ) : (
                                                    <li>
                                                        <label>
                                                            <input
                                                                checked={props.mainInfra.infraNo == reservationInfra?.infraNo}
                                                                onChange={(e) => {
                                                                    setReservationInfra(props.mainInfra);
                                                                }}
                                                                name="reservationInfra"
                                                                type="radio"
                                                                value={props.mainInfra.infraNo}
                                                            />
                                                            {props.mainInfra.name}
                                                        </label>
                                                    </li>
                                                )
                                            ) : (
                                                <></>
                                            )}
                                        </ul>
                                    </li>
                                </ul>
                            </div>

                            <div className="clear"></div>
                            <div className="txt-center">
                                <a
                                    className="pop-in-bt"
                                    data-needpopup-show="#ok"
                                    onClick={() => {
                                        confirmAlert({
                                            title: '알림',
                                            message: '서비스 준비 중입니다. 더 좋은 모습으로 찾아 뵙겠습니다.',
                                            buttons: [{ label: '확인', onClick: () => console.log('') }],
                                        });
                                        //reservate(props, reservationStartDay, reservationEndDay, reservationInfra, myTeam, user);
                                    }}
                                >
                                    예약하기
                                </a>
                            </div>
                            <a
                                onClick={() => {
                                    closeReservationPopup(props);
                                }}
                                className="needpopup_remover"
                            ></a>
                        </div>
                    </div>
                ) : (
                    <div id="reserve" className={`needpopup stacked ${props.openable ? 'opened' : ''}`} style={{ display: 'block' }}>
                        <p className="pop-title">예약 권한이 없습니다. 권한이 있는 계정으로 로그인 해주세요.</p>
                        <p className="pop-title">
                            <Link to="/login">로그인하러가기</Link>
                        </p>
                        <div>
                            <a
                                onClick={() => {
                                    closeReservationPopup(props);
                                }}
                                className="needpopup_remover"
                            ></a>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default ReservationPopupContainer;
