import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import AdminUsersTable from '../../../../component/admin/AdminUsersTable';
import AdminMainTitle from '../../../../component/admin/AdminMainTitle';
import AdminRetrieveItemPaging from '../../component/AdminRetrieveItemPaging';
import UserSearchCondition from '../../../../domain/user/param/UserSearchCondition';
import AdminUserInfoFilter from '../../component/filter/AdminUserInfoFilter';
import useAdminPaging from '../../hook/useAdminPaging';
import useMemberPagingInfos from '../../hook/useMemberPagingInfos';
import useUserRetrieveBySearchCondition from '../../../../hook/user/useUserRetrieveBySearchCondition';
import RoleGroupType from '../../../../domain/user/RoleGroupType';

function AdminMainContainer() {
    const location = useLocation();
    let initUserSearchCondition: UserSearchCondition | any = location.state ? location.state : new UserSearchCondition();
    //const initUserSearchCondition = new UserSearchCondition();

    const { page, setPage, pagingSize, setPagingSize, pagingCnt, paging, currentPageGroupIdx, setCurrentPageGroupIdx } = useMemberPagingInfos(
        initUserSearchCondition,
    );
    //const { page, setPage, pagingSize, pagingCnt, paging, setPagingSize, currentPageGroupIdx, setCurrentPageGroupIdx } = useAdminPaging();

    initUserSearchCondition.roleGroupNames = `${RoleGroupType.TEAMS}`;
    const [userSearchCondition, setUserSearchCondition] = useState(initUserSearchCondition);
    const { users, usersCnt } = useUserRetrieveBySearchCondition(userSearchCondition, paging);

    let title = '회원목록 < ' + usersCnt + '명 >';
    return (
        <>
            <AdminMainTitle title={title}>
                <span></span>
                <AdminUserInfoFilter userSearchCondition={userSearchCondition} setUserSearchCondition={setUserSearchCondition}></AdminUserInfoFilter>
            </AdminMainTitle>
            {users ? (
                <>
                    <AdminUsersTable
                        page={page}
                        pagingCnt={pagingCnt}
                        pagingSize={pagingSize}
                        users={users}
                        searchCondition={userSearchCondition}
                    ></AdminUsersTable>
                    <AdminRetrieveItemPaging
                        totalCnt={usersCnt}
                        pagingCnt={pagingCnt}
                        pagingSize={pagingSize}
                        setPage={setPage}
                        currentPageNum={page}
                        setPagingSize={setPagingSize}
                        currentPageGroupIdx={currentPageGroupIdx}
                    ></AdminRetrieveItemPaging>
                </>
            ) : (
                <></>
            )}
        </>
    );
}

export default AdminMainContainer;
