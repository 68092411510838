import React from 'react';

function SportTouringTab() {
    return (
        <div className="tab_content">
            <p>방문스포츠팀 스포츠 투어링 설명</p>
            <p>방문스포츠팀 스포츠 투어링 설명</p>
            <p>방문스포츠팀 스포츠 투어링 설명</p>
            <p>방문스포츠팀 스포츠 투어링 설명</p>
        </div>
    )
}

export default SportTouringTab;