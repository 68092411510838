import React from 'react';
import { Link } from 'react-router-dom';

function SiteMainNav() {
    return (
        <ul>
            <li>
                <Link to="/introduce">센터소개</Link>
            </li>
            <li>
                <Link to="/news">센터소식</Link>
            </li>
            <li>
                <Link to="/business">사업안내</Link>
            </li>
            <li>
                <Link to="/trainning">전지훈련시설</Link>
            </li>
            {/* <li><Link to="/touring">투어링</Link></li> */}
            <li>
                <Link to="/rehabilitation">스포츠재활</Link>
            </li>
        </ul>
    );
}

export default SiteMainNav;
