import React, { useEffect } from 'react';
import useInfraOfSearchCondition from '../../hook/infra/useInfraOfSearchCondition';
import AdminTable from './AdminTable';
import Infra from '../../domain/infra/Infra';
import { Link } from 'react-router-dom';
import useInfraRetrieve from '../../hook/infra/useInfraRetrieve';
import { InfraCategoryType } from '../../domain/infra/InfraCategoryType';
import AdminInfrasTableTr from './AdminInfrasTableTr';
import InfraSearchCondition from '../../domain/infra/param/InfraSearchCondition';

interface AdminInfrasTableProps {
    page: number;
    pagingSize: number;
    pageCnt: number;
    infras?: Infra[];
    searchCondition?: InfraSearchCondition;
}

function AdminInfrasTable(props: AdminInfrasTableProps) {
    return (
        <>
            <AdminTable>
                <colgroup>
                    <col style={{ width: '5%' }} />
                    <col style={{ width: '20%' }} />
                    <col style={{ width: '5%' }} />
                    <col style={{ width: '40%', textAlign: 'center' }} />
                    <col style={{ width: '5%' }} />
                    <col style={{ width: '25%' }} />
                </colgroup>
                <thead>
                    <tr>
                        <td>No.</td>
                        <td>시설명</td>
                        <td>종목</td>
                        <td>주소</td>
                        <td>추천</td>
                        <td>관리</td>
                    </tr>
                </thead>
                <tbody>
                    {props.infras ? (
                        props.infras.length > 0 ? (
                            props.infras?.map((infra: Infra, index: number) => {
                                return (
                                    <AdminInfrasTableTr
                                        key={index}
                                        seq={props.page * props.pagingSize + (index + 1)}
                                        infra={infra}
                                        page={props.page}
                                        pageCnt={props.pageCnt}
                                        searchCondition={props.searchCondition}
                                    ></AdminInfrasTableTr>
                                );
                            })
                        ) : (
                            <tr>
                                <td colSpan={6}>
                                    <h5>검색된 스포츠 시설이 없습니다.</h5>
                                </td>
                            </tr>
                        )
                    ) : (
                        <></>
                    )}
                </tbody>
            </AdminTable>
        </>
    );
}

export default AdminInfrasTable;
