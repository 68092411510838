import { useEffect, useRef, useState } from 'react';
import useTeamRetrieveBySearchCondition from '../../hook/team/useTeamRetrieveBySearchCondition';
import TeamSearchCondition from '../../domain/team/param/TeamSearchCondition';
import Paging from '../../domain/common/Paging';
import Team from '../../domain/team/Team';

interface TeamSearchInputProps {
    setTeamNo: (teamNo?: string) => void;
    setSelectedTeam?: (selectedTeam: Team | undefined) => void;
    setBelongedCode?: (belongCode: number | undefined) => void;
    setClassficationCode?: (classCode: number | undefined) => void;
    setSportsCode?: (sportsCode?: number) => void;
    setTeamName?: (teamName: string | undefined) => void;
    teamName?: string;
    className?: any;
}

function TeamSearchInput(props: TeamSearchInputProps) {
    const [openable, setOpenable] = useState<boolean>(false);
    const [selectedTeam, setSelectedTeam] = useState<Team>();
    const [paging, setPaging] = useState(new Paging(0, 1000));
    const [tmpTeamNameSearchWord, setTmpTeamNameSearchWord] = useState<string>();
    const [teamNameSearchWord, setTeamNameSearchWord] = useState<string>();
    const [teamSearchCondition, setTeamSearchCondition] = useState<TeamSearchCondition>();
    const { teams } = useTeamRetrieveBySearchCondition(teamSearchCondition, paging);

    useEffect(() => {
        if (teamNameSearchWord) {
            const teamSearchCondition = new TeamSearchCondition();
            teamSearchCondition.searchWord = teamNameSearchWord;
            setTeamSearchCondition(teamSearchCondition);
            if (props.setTeamName) props.setTeamName(teamNameSearchWord);
        }
    }, [teamNameSearchWord]);

    useEffect(() => {
        console.log('TeamSearchInput: ' + props.teamName);
        if (props.teamName === 'init') setTeamNameSearchWord('');
        else setTeamNameSearchWord(props.teamName);
    }, [props.teamName]);

    useEffect(() => {
        if (tmpTeamNameSearchWord) {
            setOpenable(true);
        } else {
            setOpenable(false);
        }
    }, [tmpTeamNameSearchWord]);

    useEffect(() => {
        if (selectedTeam) {
            setTeamNameSearchWord(selectedTeam.name);
            props.setTeamNo(selectedTeam.teamNo);
            if (props.setSelectedTeam) props.setSelectedTeam(selectedTeam);
            if (props.setBelongedCode) props.setBelongedCode(selectedTeam.belongCode?.codeId);
            if (props.setClassficationCode) props.setClassficationCode(selectedTeam.classificationCode?.codeId);
            if (props.setSportsCode) props.setSportsCode(selectedTeam.sportCode?.codeId);
            setOpenable(false);
        }
    }, [selectedTeam]);

    return (
        <>
            <input
                placeholder="찾는 팀명을 입력하세요."
                onChange={(event) => {
                    setTmpTeamNameSearchWord(event.currentTarget.value);
                    setTeamNameSearchWord(event.currentTarget.value);
                }}
                type="text"
                value={teamNameSearchWord}
                className={props.className}
            ></input>
            <div className="team-info" style={{ display: teams.length > 0 && openable ? 'block' : 'none' }}>
                {teams?.map((value: Team, index: number) => {
                    return (
                        <div
                            key={index}
                            onClick={() => {
                                setSelectedTeam(value);
                            }}
                        >
                            {`${value.name}(${value.belongCode?.name}, ${value.genderCode?.name}-${value.sportCode?.name}`})
                        </div>
                    );
                })}
            </div>
        </>
    );
}

export default TeamSearchInput;
