import ReservationSearchCondition from "../../domain/reservation/param/ReservationSearchCondition";
import Paging from '../../domain/common/Paging';
import { useEffect, useState } from "react";
import Reservation from "../../domain/reservation/Reservation";
import { RESERVATION_SERVICE } from '../../service/index';

function useReservationSearchCondition(searchCondition?: ReservationSearchCondition, paging?: Paging) {
    const [reservations, setReservations] = useState<Reservation[]>();
    const [reservationsCnt, setReservationsCnt] = useState<number>(0);

    useEffect(() => {
        if(searchCondition && paging) {
            RESERVATION_SERVICE.getReservationOfCondition(searchCondition, paging)
            .then((reservations: Reservation[]) => {
                setReservations(reservations);
            });
    
            RESERVATION_SERVICE.getReservationCntOfCondition(searchCondition)
            .then((reservationsCnt: number) => {
                setReservationsCnt(reservationsCnt);
            })
        }
    }, [searchCondition, paging]);

    return {
        reservations, 
        reservationsCnt
    }
}

export default useReservationSearchCondition;