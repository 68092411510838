import { useDispatch } from 'react-redux';
import { registeUserSessionAction, registeLoginInfo } from '../../moldules/UserSession';
function useAuthHook() {
    const dispatch = useDispatch();
    //사용자 세션 등록 액션 호출
    dispatch(registeUserSessionAction());
    //사용자 로그인 정보 등록 호출
    dispatch(registeLoginInfo());
}

export default useAuthHook;