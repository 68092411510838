import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import ResultFormComponent from './prescription/forms/ResultFormComponent';
import PrescriptionFormComponent from './prescription/forms/PrescriptionFormComponent';
import StatusGraphFormComponent from './prescription/forms/StatusGraphFormComponent';
import ReportingComponent from './prescription/forms/ReportingComponent';
import TargetPerson from '../../domain/TargetPerson';
import Prescription from '../../domain/Prescription';
import PrescriptionServiceImpl from '../../service/impl/PrescriptionServiceImpl';
import { confirmAlert } from 'react-confirm-alert';
import RehabTargetHistory from '../../domain/RehabTargetHistory';
import RehabHistoryServiceImpl from '../../service/impl/RehabHistoryServiceImpl';
import { JsonConvert } from 'json2typescript';

import BasalExerciseFormComponent from './prescription/forms/prescriptionForms/BasalExerciseFormComponent';
import { BasalPrescription } from '../../domain/prescription/BasalPrescription';
import ItemsPrescription from '../../domain/prescription/ItemsPrescription';
import Opinions from '../../domain/prescription/Opinions';
import ConditioningPrescription from '../../domain/prescription/ConditioningPrescription';
import { RehabProgramPrescription } from '../../domain/prescription/RehabProgramPrescription';
import ConditioningFormComponent from './prescription/forms/prescriptionForms/ConditioningFormComponent';
import ByTypePrescriptionFormComponent from './prescription/forms/prescriptionForms/ByTypeFormComponent';
import ByPartPrescriptionFormComponent from './prescription/forms/prescriptionForms/ByPartFormComponent';
import OpinionFormComponent from './prescription/forms/prescriptionForms/OpinionFormComponent';
import RehabProgramFormComponent from './prescription/forms/prescriptionForms/RehabProgramFormComponent';

interface IProps {
    basalPrescription: BasalPrescription;
    conditionningPrescription: ConditioningPrescription;
    itemPrescription: ItemsPrescription;
    bodyPrescription: ItemsPrescription;
    rehabProgramPrescription: RehabProgramPrescription;
    opinions: Opinions;
    history: RehabTargetHistory;
}
const jsonConvert = new JsonConvert();

const rehabHistoryService = new RehabHistoryServiceImpl();

let isStored: boolean = true;
let isCheckedPrescription: boolean = false;

const PrescriptionPopupComponent = (props: IProps) => {
    //const targetPerson = props.selectedTarget;
    const targetPerson = new TargetPerson();

    const [rehabHistory, setRehabHistory] = useState<RehabTargetHistory[]>([]);
    const [recordingItem, setRecordingItem] = useState<RehabTargetHistory>();
    const [selectedHistory, setSelectedHistory] = useState<number>(0);
    const [historyOptions, setHistoryOptions] = useState(<></>);
    const [isModifying, setIsModifying] = useState(false);

    const [basalPrescription, setBasalPrescription] = useState<BasalPrescription>(new BasalPrescription());
    const [conditionningPrescription, setConditionningPrescription] = useState<ConditioningPrescription>(new ConditioningPrescription());
    const [itemPrescription, setItemPrescription] = useState<ItemsPrescription>(new ItemsPrescription());
    const [bodyPrescription, setBodyPrescription] = useState<ItemsPrescription>(new ItemsPrescription());
    const [rehabProgramPrescription, setRehabProgramPrescription] = useState<RehabProgramPrescription>(new RehabProgramPrescription());

    const [opinions, setOpinions] = useState<Opinions>(new Opinions());

    useEffect(() => {
        if (props.basalPrescription) setBasalPrescription(props.basalPrescription);
        if (props.conditionningPrescription) setConditionningPrescription(props.conditionningPrescription);
        if (props.itemPrescription) setItemPrescription(props.itemPrescription);
        if (props.bodyPrescription) setBodyPrescription(props.bodyPrescription);
        if (props.rehabProgramPrescription) setRehabProgramPrescription(props.rehabProgramPrescription);
        if (props.opinions) setOpinions(props.opinions);
    }, [
        props.basalPrescription,
        props.conditionningPrescription,
        props.itemPrescription,
        props.bodyPrescription,
        props.rehabProgramPrescription,
        props.opinions,
    ]);

    let isDisplay = { display: 'block' };
    let isNotDisplay = { display: 'none' };
    let cursorStyl = { cursor: 'pointer' };
    let tabOn = 'tabon';
    let tabOff = 'taboff';

    const [tabBasic, setTabBasic] = useState<any>(tabOn);
    const [tabConditioning, setTabConditioning] = useState<any>(tabOff);
    const [tabByType, setTabByType] = useState<any>(tabOff);
    const [tabByPart, setTabByPart] = useState<any>(tabOff);
    const [tabRehabProgram, setTabRehabProgram] = useState<any>(tabOff);
    const [tabOpinions, setTabOpinions] = useState<any>(tabOff);

    const [tabBasicStyle, setTabBasicStyle] = useState<any>(isDisplay);
    const [tabConditioningStyle, setTabConditioningStyle] = useState<any>(isNotDisplay);
    const [tabByTypeStyle, setTabByTypeStyle] = useState<any>(isNotDisplay);
    const [tabByPartStyle, setTabByPartStyle] = useState<any>(isNotDisplay);
    const [tabRehabProgramStyle, setTabRehabProgramStyle] = useState<any>(isNotDisplay);
    const [tabOpinionsStyle, setTabOpinionsStyle] = useState<any>(isNotDisplay);

    const tabHandler = (evt: any) => {
        let target = evt.target.id;

        setTabBasic(tabOff);
        setTabConditioning(tabOff);
        setTabRehabProgram(tabOff);
        setTabByType(tabOff);
        setTabByPart(tabOff);
        setTabOpinions(tabOff);

        setTabBasicStyle(isNotDisplay);
        setTabConditioningStyle(isNotDisplay);
        setTabRehabProgramStyle(isNotDisplay);
        setTabByTypeStyle(isNotDisplay);
        setTabByPartStyle(isNotDisplay);
        setTabOpinionsStyle(isNotDisplay);

        if (target === 'basisPrescription') {
            setTabBasic(tabOn);
            setTabBasicStyle(isDisplay);
        } else if (target === 'conditioning') {
            setTabConditioning(tabOn);
            setTabConditioningStyle(isDisplay);
        } else if (target === 'byType') {
            setTabByType(tabOn);
            setTabByTypeStyle(isDisplay);
        } else if (target === 'byPart') {
            setTabByPart(tabOn);
            setTabByPartStyle(isDisplay);
        } else if (target === 'opinions') {
            setTabOpinions(tabOn);
            setTabOpinionsStyle(isDisplay);
        } else if (target === 'rehabProgram') {
            setTabRehabProgram(tabOn);
            setTabRehabProgramStyle(isDisplay);
        }
    };

    const inputHandler = (evt: any) => {
        const { name, id, value } = evt.target;

        if (name === 'basalExercisePrescription') {
            setBasalPrescription((prevState) => {
                return { ...prevState, [id]: value };
            });
        } else if (name === 'conditioningPrescription') {
            setConditionningPrescription((prevState) => {
                return { ...prevState, [id]: [value, '', ''] };
            });
        } else if (name === 'itemPrescription') {
            setItemPrescription((prevState) => {
                return { ...prevState, [id]: value };
            });
        } else if (name === 'bodyPrescription') {
            setBodyPrescription((prevState) => {
                return { ...prevState, [id]: value };
            });
        } else if (name === 'rehabProgramPrescription') {
            setRehabProgramPrescription((prevState) => {
                return { ...prevState, [id]: value };
            });
        } else if (name === 'opinions') {
            setOpinions((prevState) => {
                return { ...prevState, [id]: value };
            });
        }
    };

    const storedHandler = (evt: any) => {
        let history: RehabTargetHistory = props.history;
        history.basalExercisePrescription = JSON.stringify(basalPrescription);
        history.conditioningPrescription = JSON.stringify(conditionningPrescription);
        history.itemPrescription = JSON.stringify(itemPrescription);
        history.bodyPrescription = JSON.stringify(bodyPrescription);
        history.rehabProgramPrescription = JSON.stringify(rehabProgramPrescription);
        history.opinions = JSON.stringify(opinions);

        rehabHistoryService.managePrescriptionForTarget(history).then((result: RehabTargetHistory) => {
            confirmAlert({
                title: '저장 성공',
                message: '운동 처방 정보를 저장 하였습니다.',
                buttons: [{ label: '확인', onClick: () => {} }],
            });
        });
    };

    return (
        <>
            <div className="center-popup-title">
                <h1 className="center">
                    <i className="fa fa-check"></i> 스포츠재활 처방
                </h1>
                <div>
                    <button className="center-button-complete" onClick={storedHandler} id="complete">
                        저 장 <i className="fas fa-file-download"></i>
                    </button>
                </div>
            </div>

            {/* 운동처방 영역 */}
            <div className="center-popup-list-tab">
                <div className="tab-menu">
                    <ul>
                        <li id="basisPrescription" className={tabBasic} onClick={tabHandler} style={cursorStyl}>
                            기본운동처방
                        </li>
                        <li id="conditioning" className={tabConditioning} onClick={tabHandler} style={cursorStyl}>
                            컨디셔닝
                        </li>
                        <li id="rehabProgram" className={tabRehabProgram} onClick={tabHandler} style={cursorStyl}>
                            재활프로그램
                        </li>
                        {/* <li id="byType" className={tabByType} onClick={tabHandler} style={cursorStyl}>
                            운동종목별
                            <br />
                            운동처방
                        </li>
                        <li id="byPart" className={tabByPart} onClick={tabHandler} style={cursorStyl}>
                            손상부위별
                            <br />
                            운동처방
                        </li> */}
                        <li id="opinions" className={tabOpinions} onClick={tabHandler} style={cursorStyl}>
                            소견 내용
                        </li>
                    </ul>
                </div>

                <div className="tab-story">
                    <div id="tabstory1" style={tabBasicStyle}>
                        {/* 기본 운동 처방 작성 Form */}
                        <BasalExerciseFormComponent handler={inputHandler} prescription={basalPrescription}></BasalExerciseFormComponent>
                    </div>
                    <div id="tabstory2" style={tabConditioningStyle}>
                        {/* 컨디셔닝 처방 작성 Form */}
                        <ConditioningFormComponent handler={inputHandler} prescription={conditionningPrescription}></ConditioningFormComponent>
                    </div>
                    <div id="tabstory6" style={tabRehabProgramStyle}>
                        {/* 재활 프로그램 처방 작성 Form */}
                        <RehabProgramFormComponent handler={inputHandler} prescription={rehabProgramPrescription}></RehabProgramFormComponent>
                    </div>
                    {/* <div id="tabstory3" style={tabByTypeStyle}> */}
                    {/* 운동 종목 별 처방 작성 Form */}
                    {/* <ByTypePrescriptionFormComponent handler={inputHandler} prescription={itemPrescription}></ByTypePrescriptionFormComponent> */}
                    {/* </div> */}
                    {/* <div id="tabstory4" style={tabByPartStyle}> */}
                    {/* 신체 부위 별 처방 작성 Form */}
                    {/* <ByPartPrescriptionFormComponent handler={inputHandler} prescription={bodyPrescription}></ByPartPrescriptionFormComponent> */}
                    {/* </div> */}
                    <div id="tabstory5" style={tabOpinionsStyle}>
                        {/* 처방 소견 작성 Form */}
                        <OpinionFormComponent handler={inputHandler} opinions={opinions}></OpinionFormComponent>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PrescriptionPopupComponent;
