import { useEffect, useState } from "react";
import SearchWord from "../../../domain/search/SearchWord";
import useWordSearchHook from '../../../hook/useWordSearchHook';
import SearchServiceImpl from '../../../service/search/impl/SearchServiceImpl';

const searchService = new SearchServiceImpl();

function useSearchBarHook() {
    const [searchWord, setSearchWord] = useState<string>();
    const {searchItems} = useWordSearchHook(searchWord);
    const [searchWordVisible, setSearchWordVisible] = useState(false);
    const [lastedSearchWord, setLastedSearchWord] = useState<string>();
    const [mySearchWords, setMySearchWords] = useState<SearchWord[]>();
    const [mostFavoritesSearchWords, setMostFavoritesSearchWords] = useState<SearchWord[]>();

    useEffect(() => {
        if(lastedSearchWord) 
            setSearchWord(lastedSearchWord);
    }, [lastedSearchWord]);

    useEffect(() => {
        searchService.getSearchWordsOf()
        .then((searchWords) => {
            setMySearchWords(searchWords)
        });

        searchService.getSearchWordsOfMostFavorites()
        .then((searchWords) => {
            setMostFavoritesSearchWords(searchWords);
        })
    }, [])

    return {
        searchWord, setSearchWord,
        searchItems, 
        searchWordVisible, setSearchWordVisible,
        lastedSearchWord, setLastedSearchWord,
        mySearchWords, setMySearchWords,
        mostFavoritesSearchWords, setMostFavoritesSearchWords
    }
}

export default useSearchBarHook;