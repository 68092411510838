import React, { useEffect, useState } from 'react';
import Reservation from '../../../domain/reservation/Reservation';
import Infra from '../../../domain/infra/Infra';
import InfraLocationMap from '../../../component/map/InfraLocationMap';

interface ReservationDetailComponentProps {
    reservation: Reservation;
    reservationParentInfra?: Infra;
    reservationInfra: Infra;
}

function ReservationDetailComponent(props: ReservationDetailComponentProps) {
    const [addInfraInfoInstance, setAddInfraInfoInstance] = useState<Infra>();
    useEffect(() => {
        const addInfraInfoInstance = props.reservationParentInfra ? props.reservationParentInfra : props.reservationInfra;
        setAddInfraInfoInstance(addInfraInfoInstance);
    }, [props]);
    return (
        <>
            <div className="my-in-photo">
                <img width={'73px'} height={'75px'} src={addInfraInfoInstance?.getMainAttachFile()} />
                <h4>
                    {props.reservationParentInfra ? props.reservationParentInfra.name + '-' : <></>}
                    {props.reservationInfra?.name}
                </h4>
                <span className="blue">●</span>
                <br></br>
                <span className="blue">{props.reservation?.reservationStateCode?.name}</span>
            </div>
            <div className="my-in-story-wrap">
                <div className="my-in-story">
                    <h4>예약상세정보</h4>
                    <p>
                        <span className="sub bg-blue">예약 일시</span> &nbsp;&nbsp;
                        <span style={{ fontWeight: 700 }}>{props.reservation?.startDate}</span>
                    </p>
                    <p>
                        <span className="sub bg-blue">예약상태</span> &nbsp;&nbsp;
                        <span style={{ fontWeight: 700 }}>{props.reservation?.reservationStateCode?.name}</span>
                    </p>
                    <p>
                        <span className="sub bg-blue">예약대상자</span> &nbsp;&nbsp;
                        <span style={{ fontWeight: 700 }}>
                            &lt;{props.reservation?.team?.name}&gt;&nbsp;
                            {props.reservation?.reservater?.name}
                        </span>
                    </p>
                    <p>
                        <span className="sub bg-blue">예약신청자</span> &nbsp;&nbsp;
                        <span style={{ fontWeight: 700 }}>{props.reservation?.register?.name}</span>
                    </p>
                </div>
                <div className="my-in-story">
                    <h4>시설 상세 정보</h4>
                    <p>
                        <span className="sub bg-blue">시설이름</span>
                        &nbsp;&nbsp;
                        <span style={{ fontWeight: 700 }}>
                            {props.reservationParentInfra ? props.reservationParentInfra.name + '-' : <></>}
                            {props.reservation?.infra?.name}
                        </span>
                    </p>
                    <p>
                        <span className="sub bg-blue">시설주소</span> &nbsp;&nbsp;
                        <span style={{ fontWeight: 700 }}>{addInfraInfoInstance?.address}</span>
                    </p>
                    <p>
                        <span className="sub bg-blue">연락처</span> &nbsp;&nbsp;
                        <span style={{ fontWeight: 700 }}>{addInfraInfoInstance?.phoneNumber}</span>
                    </p>
                </div>
            </div>
            <div className="my-in-story">
                <h4>시설 위치</h4>
                {addInfraInfoInstance ? (
                    <InfraLocationMap mapLevel={5} infras={[Object.setPrototypeOf(addInfraInfoInstance, Infra.prototype)]}></InfraLocationMap>
                ) : (
                    <></>
                )}
            </div>
        </>
    );
}

export default ReservationDetailComponent;
