import React, { useEffect, useState } from 'react';
import MiniSearchBar from '../../component/search/MiniSearchBar';
import Infra from '../../domain/infra/Infra';
import InfraCategory from '../../domain/infra/InfraCategory';
import RadioSelect from '../../component/search/filter/RadioSelect';
import Code from '../../domain/code/Code';
import useParentInfraCategoriesRetrieve from '../../hook/infra/useParentInfraCategoriesRetrieve';
import useInfraOfCategoryRetrieve from '../../hook/infra/useInfraCategoryRetrieve';
import { InfraCategoryType } from '../../domain/infra/InfraCategoryType';
import MultiSelect from '../../component/search/filter/MultiSelect';
import { CodeType } from '../../domain/code/CodeType';
import MultiSelectByInfraCategory from '../../component/search/filter/MultiSelectByInfraCategory';
import useCodeRetrieve from '../../hook/code/useCodeRetrieve';
import useSearchPageHook from '../../view/search/page/hook/useSearchPageHook';
import useChildCodeRetrieve from '../../hook/code/useChildCodeRetrieve';

interface NearInfraSearchConditionContainerProps {
    setSearchType: (no: number) => void;
    targetInfra?: Infra;
}

function NearInfraSearchConditionContainer(props: NearInfraSearchConditionContainerProps) {
    const {parentInfraCategories} = useParentInfraCategoriesRetrieve();
    const [searchType, setSearchType] = useState<number>(InfraCategoryType.SUB_INFRA_NO);
    const childInfraCategories = useInfraOfCategoryRetrieve(searchType);
    const regionCodes = useChildCodeRetrieve(20001);

    useEffect(() => {
        props.setSearchType(searchType);
    }, [searchType])

    return (
        <>
            <MiniSearchBar searchWord={props.targetInfra?.name}></MiniSearchBar>
            <RadioSelect initChoiceId={InfraCategoryType.SUB_INFRA_NO} codeType={CodeType.PARENT_INFRA_CATEGORY} setType={setSearchType} title={"구분"} codes={Code.convertTo(parentInfraCategories)}></RadioSelect> 
            {
                childInfraCategories?
                <MultiSelectByInfraCategory codeType={CodeType.CHILD_INFRA_CATEGORY} title="시설 분류" infraCategories={childInfraCategories}></MultiSelectByInfraCategory>
                :
                <></>
            }
            {
                regionCodes?
                <MultiSelect codeType={CodeType.REGION} title="지역" codes={regionCodes}></MultiSelect>
                :
                <></>
            }
        </>
    )
}

export default NearInfraSearchConditionContainer;