import React, { useEffect, useState } from 'react';
import Authority from '../../../domain/user/Authority';

function SupportDetailComponent() {
    return (
        <div className="content-wrap">
            <div className="sub_contents">
                <div className="snb">
                    <h2>방문스포츠 팀 현황</h2>
                    <ul>
                        <li>
                            <a href="/status">지역별 현황</a>
                        </li>
                        <li className="menu_on">
                            <a href="/status/support-detail">지역별 지원내용</a>
                        </li>
                    </ul>
                </div>

                <div className="contents_ad">
                    <div className="page_tle" style={{ marginBottom: '35px' }}>
                        <h3>지역별 지원내용</h3>
                        <div className="page_nav">
                            <ul>
                                <li>
                                    <a href="/iis/web/main.do">홈</a>
                                </li>
                                <li>
                                    <a href="/status">방문스포츠팀 현황</a>
                                </li>
                                <li>
                                    <a href="/status/support-detail">지역별 지원내용</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="wrap-h">
                        <div className="wrap-h-left">
                            <div>
                                <h4>전지훈련은 경상남도로 오세요.</h4>
                            </div>
                            <div>
                                <p>
경상남도는 사계절 기온이 따뜻하고 산과 바다의 자연이 어우러져 맑은 공기 속에서 자연의 아름다움을 관광하고 해양 및 다양한 레포츠를 체험할 수 있습니다.
                                </p>
                            </div>
                            <div>
                                <p>
최고 수준의 체육 인프라와 다양한 인센티브와 함께 재활 및 컨디셔닝 등 방문기간동안 최상의 컨디션으로 훈련에 집중할 수 있도록 경상남도가 지원합니다.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div id="board_list">
                        <div className="board" style={{ paddingTop: '5px', textAlign: 'center', overflowX: 'auto' }}>
                            <img src="/assets/image/status/SupportDetail.png"></img>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SupportDetailComponent;
