import React from 'react';
import AdminFormTitle from '../../../../component/admin/AdminFormTitle';
import AdminManagerInfoForm from '../../component/manager/AdminManagerInfoForm';
import UserServiceImpl from '../../../../service/user/impl/UserServiceImpl';

const userService = new UserServiceImpl();
function AdminManagerRegistePage() {
    return (
        <>
            <AdminFormTitle title={<>운영자 추가</>}></AdminFormTitle>
            <div className="two-wrap">
                <div className="half">
                    <div className="member-list">
                        {<AdminManagerInfoForm flag={'registry'} buttonTitle={'운영자 정보 추가'}></AdminManagerInfoForm>}
                    </div>
                </div>
            </div>
        </>
    );
}

export default AdminManagerRegistePage;
