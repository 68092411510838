import { useEffect, useState } from 'react';
import ReservationSearchCondition from '../domain/reservation/param/ReservationSearchCondition';
import Reservation from '../domain/reservation/Reservation';
import ReservationServiceImpl from '../service/reservation/impl/ReservationServiceImpl';
import Paging from '../domain/common/Paging';

const reservationService = new ReservationServiceImpl();

function useReservationOfTeam(teamNo: string, searchCondition: ReservationSearchCondition, paging: Paging) {
    const [reservations, setReservations] = useState<Reservation[]>();

    useEffect(() => {
        // const searchCondition = new ReservationSearchCondition();
        // searchCondition.teamNos = teamNo;
        reservationService.getReservationOfCondition(searchCondition, paging).then((reservations: Reservation[]) => setReservations(reservations));
    }, []);

    return { reservations };
}

export default useReservationOfTeam;
