import React, { useEffect } from 'react';
import ActionComponent from '../../ActionComponent';

interface ReportingPopupInter {
    savedFilePath?: string;
    setPopupOpenable: (popupOpenable: boolean) => void;
}

interface ReportingFormComponentProps {
    savedFilePath?: string;
}

export default function ReportingFormComponent(props: ReportingFormComponentProps) {
    const actions = ActionComponent();
    useEffect(() => {}, [props.savedFilePath]);
    return (
        <>
            <div className="center-popup-reporting-box">
                <div className="center-print">
                    {props.savedFilePath ? (
                        <iframe
                            width="100%"
                            height="520px"
                            src={`${process.env.REACT_APP_RESOURCE_API_HOST}/static-file${props.savedFilePath}`}
                        ></iframe>
                    ) : (
                        <h1>파일 없음</h1>
                    )}
                </div>
            </div>
        </>
    );
}
