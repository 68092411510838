import React, { useEffect, useState } from 'react';
import { RouteComponentProps, useLocation } from 'react-router-dom';
import AdminInfraContainerProps from './props/AdminInfraContainerProps';
import useInfraRetrieveInfraNo from '../../../../hook/infra/useInfraRetrieveByInfraNo';
import AdminInfraNav from '../../nav/AdminInfraNav';
import AdminInfraNavTabEnum from '../../nav/enum/AdminInfraNavTabEnum';
import { momentLocalizer, Calendar } from 'react-big-calendar';
import moment from 'moment';
import useReservationOfInfra from '../../../../hook/reservation/useReservationsOfInfra';
import useReservationOnReservationNo from '../../../../hook/reservation/useReservationOfReservationNo';
import Reservation from '../../../../domain/reservation/Reservation';
import ReservationSearchCondition from '../../../../domain/reservation/param/ReservationSearchCondition';

function AdminSportInfraScheduleDetailContainer({match}: RouteComponentProps<AdminInfraContainerProps>) {
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const choiceReservationStartDate = query.get('reservationStartDate');
    const [initReservationDate, setInitReservationDate] = useState<Date>(new Date());
    
    const {reservationNo} = match.params;
    const {infraNo} = match.params;
    const {infra, setInfra} = useInfraRetrieveInfraNo(infraNo);
    const [month, setMonth] = useState<string>(String(new Date().getMonth() + 1));
    const [reservationSearchCondition, setReservationSearchCondition] = useState(new ReservationSearchCondition());
    const {reservations, setReservations} = useReservationOfInfra(infraNo, reservationSearchCondition);
    const [events, setEvents] = useState(new Array());

    const {reservation} = useReservationOnReservationNo(reservationNo);
    const localizer = momentLocalizer(moment);

    const reserDateForm = reservation?.startDate?.split(" ")[0];
    const reserYear = Number(reserDateForm?.split("-")[0]);
    const reserMonth = Number(reserDateForm?.split("-")[1]) - 1;
    const reserDate = Number(reserDateForm?.split("-")[2]);
    let reservationDate = reserDateForm ? new Date(reserYear, reserMonth, reserDate) : new Date();

    useEffect(() => {
        if(choiceReservationStartDate) {
            if(choiceReservationStartDate!=null) {
                const initDate = new Date(choiceReservationStartDate);
                setInitReservationDate(initDate);
            }
        }
    }, [choiceReservationStartDate]);

    useEffect(() => {
        if(reservations && reservations.length !== 0) {
            console.log(reservations);
            const newEvents: any[] = [];
            reservations.forEach((reservation: Reservation, index: number) => {
                //2020-11-22
                const dateForm = reservation.startDate?.split(" ")[0];
                const year = Number(dateForm?.split("-")[0]);
                const month = Number(dateForm?.split("-")[1]) - 1;
                const date = Number(dateForm?.split("-")[2]);

                const endDateForm = reservation.endDate?.split(" ")[0];
                const endYear = Number(endDateForm?.split("-")[0]);
                const endMonth = Number(endDateForm?.split("-")[1])-1;
                const endDate = Number(endDateForm?.split("-")[2]);
                console.log(year, month + 1, date);
                //사용하는 api에서 month처리를 0~11로 해서 1~12월로 체크해줘서 -1로 사용
                // 이 부분을 boolean 체크 할 때는 +1 해줘야함
                if(year && (month + 1) && date) {
                    const newEvent = {
                        id: index,
                        title: `${reservation.team?.name}-${reservation.reservationStateCode?.name}`,
                        allDay: false,
                        start: new Date(year, month, date, 0, 0),
                        end: new Date(endYear, endMonth, endDate, 24, 0),
                        link: 'test'
                    }

                    newEvents.push(newEvent);
                }
            });
            setEvents(newEvents);
        }
    }, [reservations])
    
    return (
        <>
            <div className="member-tit">
                <h3>{infra?.name}</h3>
            </div>
            <AdminInfraNav infraNo={infraNo} tab={AdminInfraNavTabEnum.SCHEDULE}></AdminInfraNav>
            <Calendar
                localizer={localizer}
                events={events}
                onSelectEvent={(event) => console.log(event)}
                style={{height: '700px'}}
                onNavigate={date => {
                    setInitReservationDate(date);
                    const reservationSearchCondition = new ReservationSearchCondition();
                    reservationSearchCondition.month = String(date.getMonth() + 1);
                    setReservationSearchCondition(reservationSearchCondition);
                }}
                date={initReservationDate}
            >
            </Calendar>
        </>
    )
}

export default AdminSportInfraScheduleDetailContainer;