import Code from '../../../../../domain/code/Code';
interface CodeSelectFilterProps {
    initCodeId?: number;
    codes?: Code[];
    allOptionTitle: string;
    setCodeIdOfSearchTypeNum?: (codeId: number | undefined) => void;
    setCodeIdOfSearchTypeStr?: (codeId: string | undefined) => void;
    setCodeIdOfSearchTypeBool?: (codeId: boolean | undefined) => void;
    changeEventHandler?: (evt: any) => void;
}

function CodeSelectFilter(props: CodeSelectFilterProps) {
    const excludeCodeIds = [2000100];

    return (
        <select
            value={props.initCodeId}
            onChange={(event) => {
                if (props.setCodeIdOfSearchTypeNum) {
                    let codeIdOfSearch = Number(event.currentTarget.value) === 0 ? undefined : Number(event.currentTarget.value);
                    props.setCodeIdOfSearchTypeNum(codeIdOfSearch);
                } else if (props.setCodeIdOfSearchTypeStr) {
                    let codeIdOfSearch = event.currentTarget.value === '0' ? undefined : event.currentTarget.value;
                    props.setCodeIdOfSearchTypeStr(codeIdOfSearch);
                } else if (props.setCodeIdOfSearchTypeBool) {
                    let codeIdOfSearch = event.currentTarget.value === '0' ? undefined : JSON.parse(event.currentTarget.value);
                    props.setCodeIdOfSearchTypeBool(codeIdOfSearch);
                }

                if (props.changeEventHandler) props.changeEventHandler(event);
            }}
        >
            <option value="0">{props.allOptionTitle}</option>
            {props.codes?.map((code: Code, index: number) => {
                if (excludeCodeIds.indexOf(code.codeId) == -1) {
                    return (
                        <option value={code.codeId} key={index}>
                            {code.name}
                        </option>
                    );
                }
            })}
        </select>
    );
}

export default CodeSelectFilter;
