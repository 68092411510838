import TmpRegistedTeamUser from '../../domain/team/TmpRegistedTeamUser';
import { Link } from 'react-router-dom';
import useTmpRegistedTeamUserDelete from '../../hook/team/useTmpRegistedTeamUserDelete';
import { useEffect, useRef } from 'react';
interface AdminRegistedUserTableTrProps {
    tmpUser: TmpRegistedTeamUser;
    index: number;
}
function AdminRegistedUserTableTr(props: AdminRegistedUserTableTrProps) {
    const { deleteHandler, deleteDate } = useTmpRegistedTeamUserDelete();

    const trElementRef = useRef<HTMLTableRowElement>(null);

    useEffect(() => {
        if (deleteDate) {
            if (trElementRef.current) trElementRef.current.remove();
        }
    }, [deleteDate]);
    return (
        <tr ref={trElementRef} key={props.index}>
            <td>{props.tmpUser.user ? <span className="blue">가입</span> : <span className="red">미가입</span>}</td>
            <td>{props.tmpUser.userName}</td>
            <td>{props.tmpUser.user?.email}</td>
            <td>{props.tmpUser.phoneNumber}</td>
            <td>
                {props.tmpUser.user ? (
                    <>
                        <Link to={`/admin/members/${props.tmpUser.user?.userNo}`}>
                            <button className="bg-white round">
                                <i className="fas fa-search"></i>보기
                            </button>
                        </Link>
                        <button
                            className="bg-blue white"
                            onClick={() => {
                                deleteHandler(props.tmpUser.tmpRegistedNo);
                            }}
                        >
                            선수 삭제
                        </button>
                    </>
                ) : (
                    <>
                        <button
                            className="bg-blue white"
                            onClick={() => {
                                deleteHandler(props.tmpUser.tmpRegistedNo);
                            }}
                        >
                            선수 삭제
                        </button>
                    </>
                )}
            </td>
        </tr>
    );
}

export default AdminRegistedUserTableTr;
