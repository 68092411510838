import React from 'react';
import AdminMainHeader from './AdminMainHeader';
import AdminMainSider from './AdminMainSider';
import AdminTeamNav from '../nav/AdminTeamNav';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';

interface AdminCommonTemplateProps {
    children: React.ReactNode;
}
function AdminCommonTemplate(props: AdminCommonTemplateProps) {
    return (
        <>
            <AdminMainHeader></AdminMainHeader>
            <div className="admin-body">
                <AdminMainSider></AdminMainSider>
                <div className="admin-content">{props.children}</div>
            </div>
        </>
    );
}

export default AdminCommonTemplate;
