import React from 'react';

import { InfraCategoryType } from '../../../../domain/infra/InfraCategoryType';
import InfraMainViewer from '../../../../component/infra/InfraMainViewer';
import useInfraRetrieve from '../../../../hook/infra/useInfraRetrieve';

function TrainningMainContainerOfNotExistSchedule() {
    const {
        infras: infrasOfSport,
        infraCategories: infraCategoriesOfSport,
        setChildInfraCategoryNo: setChildInfraCategoryNoOfSport,
    } = useInfraRetrieve(InfraCategoryType.SPORT_INFRA_NO);
    const {
        infras: infrasOfSubInfra,
        infraCategories: infraCategoriesOfSubInfra,
        setChildInfraCategoryNo: setChildInfraCategoryNoOfSubInfra,
    } = useInfraRetrieve(InfraCategoryType.SUB_INFRA_NO);
    const { infras: infrasOfTour, infraCategories: infraCategoriesOfTour, setChildInfraCategoryNo: setChildInfraCategoryNoOfTour } = useInfraRetrieve(
        InfraCategoryType.TOURISM_NO,
    );
    const { infras: infrasOfFood, infraCategories: infraCategoriesOfFood, setChildInfraCategoryNo: setChildInfraCategoryNoOfFood } = useInfraRetrieve(
        InfraCategoryType.FOOD_NO,
    );
    const {
        infras: infrasOfLodgment,
        infraCategories: infraCategoriesOfLodgment,
        setChildInfraCategoryNo: setChildInfraCategoryNoOfLodgment,
    } = useInfraRetrieve(InfraCategoryType.LODGMENT_NO);
    return (
        <>
            <InfraMainViewer
                infras={infrasOfSport}
                infraCategories={infraCategoriesOfSport}
                setChildInfraCategoryNo={setChildInfraCategoryNoOfSport}
                infraType={`sport`}
                searchingType="default"
                title="스포츠 시설"
            ></InfraMainViewer>
            <InfraMainViewer
                infras={infrasOfSubInfra}
                infraCategories={infraCategoriesOfSubInfra}
                setChildInfraCategoryNo={setChildInfraCategoryNoOfSubInfra}
                infraType={`sub`}
                searchingType="default"
                title="부대 시설"
            ></InfraMainViewer>
            <InfraMainViewer
                infras={infrasOfTour}
                infraCategories={infraCategoriesOfTour}
                setChildInfraCategoryNo={setChildInfraCategoryNoOfTour}
                infraType={`touring`}
                searchingType="default"
                title="관광 시설"
            ></InfraMainViewer>
            <InfraMainViewer
                infras={infrasOfLodgment}
                infraCategories={infraCategoriesOfLodgment}
                setChildInfraCategoryNo={setChildInfraCategoryNoOfLodgment}
                infraType={`lodgment`}
                searchingType="default"
                title="숙박 시설"
            ></InfraMainViewer>
            <InfraMainViewer
                infras={infrasOfFood}
                infraCategories={infraCategoriesOfFood}
                setChildInfraCategoryNo={setChildInfraCategoryNoOfFood}
                infraType={`food`}
                searchingType="default"
                title="음식 시설"
            ></InfraMainViewer>
        </>
    );
}

export default TrainningMainContainerOfNotExistSchedule;
