import { JsonObject, JsonProperty } from "json2typescript";
import RoleGroups from "./RoleGroups";

@JsonObject("roles")
class Roles {
    @JsonProperty("roleNo")
    roleNo?: number
    @JsonProperty("roleName")
    roleName?: string;
    @JsonProperty("roleGroups")
    roleGroups?: RoleGroups;
    @JsonProperty("roleDesc")
    roleDesc?: string;
}

export default Roles;