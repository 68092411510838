import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject("rehabCenterMeasureByMonthOfYear")
class RehabCenterMeasureByMonthOfYear {
    @JsonProperty("yearOfMonth")
    yearOfMonth?: string;
    @JsonProperty("measureCnt")
    measureCnt?: number;
}

export default RehabCenterMeasureByMonthOfYear;