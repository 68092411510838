class EventUtils {
    static makeEvent(eventIdx: number, startDate: string, endDate: string, eventTitle: string, eventLink: string) {
        const {year: startYear, month: startMonth, day: startDay} = this.dateSplite(startDate);
        const {year: endYear, month: endMonth ,day: endDay} = this.dateSplite(endDate);
        
        const newEvent = {
            id: eventIdx,
            title: eventTitle,
            allDay: true,
            start: new Date(startYear, startMonth, startDay),
            //FIXME:
            end: new Date(endYear, endMonth, endDay + 1),
            eventLink: eventLink
        }
        
        return newEvent;
    }

    static dateSplite(date: string) {
        const dateForm = date.split(" ")[0];
        const year = Number(dateForm.split("-")[0]);
        const month = Number(dateForm.split("-")[1]) - 1;
        const day = Number(dateForm.split("-")[2]);
        
        return {
            year, month, day
        }
    }
}

export default EventUtils;