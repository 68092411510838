import React, { useEffect, useState } from 'react';
import SpecialPhysical from '../../../../../domain/recording/SpecialPhysical';
interface IProps {
    handler: (evt: any) => void;
    status?: SpecialPhysical;
}
export default function InBodyForm(props: IProps) {
    const resourceAPIURL = process.env.REACT_APP_RESOURCE_API_HOST;
    const [inBody, setInBody] = useState<any>(new SpecialPhysical().inBody);

    useEffect(() => {
        if (props.status) {
            setInBody(props.status.inBody);
            console.log(inBody);
        }
    }, [props.status]);

    let uploadImage = inBody.uploadIBFile;

    let imageElem = <></>;
    if (typeof uploadImage != 'undefined' && uploadImage != '')
        imageElem = <img id="uploadedImages" src={resourceAPIURL + inBody.uploadIBFile} width="580" />;

    return (
        <>
            <div className="center-popup-title">
                <h1 className="center">인바디(체성분검사)</h1>
            </div>
            {/* <div className="center-special-physical-btn">
                <label htmlFor={'uploadIBFile'} className="center-label-up">
                    검사지업로드
                </label>
                <input type="file" id="uploadIBFile" name="inBody" onChange={props.handler} style={{ display: 'none' }} accept=".gif, .jpg, .png" />
            </div> */}

            <div className="flex-basic">
                <div className="center-half">
                    <h2 className="center2">BMI</h2>
                    <div className="center-bg-gray flex">
                        <div className="center-bg-blue">1회차</div>
                        <input
                            className="center-input-gray center-w100"
                            type="text"
                            name="inBody"
                            id="bmi"
                            value={inBody?.bmi[0]}
                            onChange={props.handler}
                        />
                        <label className="center-label">kg/㎥</label>
                    </div>
                </div>
                <div className="center-half">
                    <h2 className="center2">제지방</h2>
                    <div className="center-bg-gray flex">
                        <div className="center-bg-blue">1회차</div>
                        <input
                            className="center-input-gray center-w100"
                            type="text"
                            name="inBody"
                            id="leanBodyMass"
                            value={inBody?.leanBodyMass}
                            onChange={props.handler}
                        />
                        <label className="center-label">%</label>
                    </div>
                </div>
            </div>
            <div className="flex-basic">
                <div className="center-half">
                    <h2 className="center2">체지방률</h2>
                    <div className="center-bg-gray flex">
                        <div className="center-bg-blue">1회차</div>
                        <input
                            className="center-input-gray center-w100"
                            type="text"
                            name="inBody"
                            id="bodyFatRate"
                            value={inBody?.bodyFatRate[0]}
                            onChange={props.handler}
                        />
                        <label className="center-label">%</label>
                    </div>
                </div>
                <div className="center-half">
                    <h2 className="center2">내장지방지수</h2>
                    <div className="center-bg-gray flex">
                        <div className="center-bg-blue">1회차</div>
                        <input
                            className="center-input-gray center-w100"
                            type="text"
                            name="inBody"
                            id="visceralFatLev"
                            value={inBody?.visceralFatLev[0]}
                            onChange={props.handler}
                        />
                        <label className="center-label">레벨</label>
                    </div>
                </div>
            </div>
            <div className="flex-basic">
                <div className="center-half">
                    <h2 className="center2">복부지방률</h2>
                    <div className="center-bg-gray flex">
                        <div className="center-bg-blue">1회차</div>
                        <input
                            className="center-input-gray center-w100"
                            type="text"
                            name="inBody"
                            id="abdominalFatRate"
                            value={inBody.abdominalFatRate[0]}
                            onChange={props.handler}
                        />
                        <label className="center-label">%</label>
                    </div>
                </div>
                <div className="center-half">
                    <h2 className="center2">기초대사량</h2>
                    <div className="center-bg-gray flex">
                        <div className="center-bg-blue">1회차</div>
                        <input
                            className="center-input-gray center-w100"
                            type="text"
                            name="inBody"
                            id="bmr"
                            value={inBody?.bmr}
                            onChange={props.handler}
                        />
                        <label className="center-label">Kcal</label>
                    </div>
                </div>
            </div>
            <div id="uploadedIBImages" className="center-mauto">
                {imageElem}
            </div>
        </>
    );
}
