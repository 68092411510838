import React, { useEffect, useState } from 'react';
import AttachFile from '../../domain/file/AttachFile';
import JsonConvertUtils from '../../utils/JsonConvertUtils';

interface ImgSliderProps {
    attachFiles: AttachFile[];
}

function ImgSlider(props: ImgSliderProps) {
    //최대 5장
    // const maxActiveImgSeq = 5;
    const [attachFiles, setAttachFiles] = useState(new Array<AttachFile>());
    const [MaxSlideImgCnt, setMaxSlideImgCnt] = useState(6);
    const [activeImgSeq, setActiveImgSeq] = useState(1);
    const nextImgEventHandler = () => {
        setActiveImgSeq(activeImgSeq + 1);
        if(activeImgSeq >= MaxSlideImgCnt) {
            setActiveImgSeq(1);
        }
    }

    const prevImgEventHandler = () => {
        const changeSeq = activeImgSeq - 1;
        if(changeSeq <= 0) {
            setActiveImgSeq(MaxSlideImgCnt);
        } else {
            setActiveImgSeq(changeSeq);
        }
    }

    const imgPickEventHandler = (activeImgSeq: number) => {
        setActiveImgSeq(activeImgSeq);
    }

    useEffect(() => {
        let receivedAttachFiles = props.attachFiles;
        // receivedAttachFiles.push(AttachFile.getDefaulInfraImg());
        // receivedAttachFiles.push(AttachFile.getDefaulFoodImg());
        setMaxSlideImgCnt(receivedAttachFiles.length);
        setAttachFiles(receivedAttachFiles);

    }, [props.attachFiles]);

    return (
        <div className="container">
            {
                attachFiles.map((attachFile, index) => {
                    let parsedAttachFile = JsonConvertUtils.parseToObj(attachFile, AttachFile);
                    if(parsedAttachFile) {
                        return (
                            <div key={index} className="mySlides" style={{display: (index+1)==activeImgSeq?'block':'none'}}>			
                                <img style={{maxWidth: '1496px', maxHeight:'761px'}} src={parsedAttachFile.getPath()}/>
                            </div>
                        )
                    }
                })
            }
            
            <a className="prev" onClick={() => prevImgEventHandler()}>❮</a>
            <a className="next" onClick={() => nextImgEventHandler()}>❯</a>

            <div className="row">
                {
                    attachFiles.map((attachFile: AttachFile, index) => {
                        let parsedAttachFile = JsonConvertUtils.parseToObj(attachFile, AttachFile);
                        return (
                            <div onClick={() => imgPickEventHandler(index+1)} key={index} className="column">
                                <img className="demo cursor active" src={parsedAttachFile?.getPath()} alt="The Woods"/>
                            </div>
                        )
                    })
                }

            </div>
        </div>
    )
}

export default ImgSlider;