import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject("rehabCenterMeasureByQuarter")
class RehabCenterMeasureByQuarter {
    @JsonProperty("quarterOfYear")
    quarterOfYear?: number;
    @JsonProperty("measureCnt")
    measureCnt?: number;
}

export default RehabCenterMeasureByQuarter;