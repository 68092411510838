import React, { useEffect, useState } from 'react';
import { useLocation, useHistory, useParams } from 'react-router-dom';

import '../assets/css/center.css';
import '../assets/css/animate.min.css';
import Layout from '../layouts/Layout';
import Header from '../layouts/Header';
import SideBar from '../layouts/SideBar';
import RehabServiceImpl from '../service/impl/RehabServiceImpl';
import RehabHistoryServiceImpl from '../service/impl/RehabHistoryServiceImpl';

import TargetPerson from '../domain/TargetPerson';
import { confirmAlert } from 'react-confirm-alert';

import MngPhysicalStatusComponent from './components/MngPhysicalStatusComponent';
import MngConditionComponent from './components/MngConditionComponent';
import MngBasalPhysicalComponent from './components/MngBasalPhysicalComponent';
import MngSpecialPhysicalComponent from './components/MngSpecialPhysicalComponant';
import MngPrescriptionComponent from './components/MngPrescriptionComponent';
import SpecialPhysical from '../domain/recording/SpecialPhysical';
import PhysicalStatus from '../../../domain/report/PhysicalStatus';
import CheckCondition from '../domain/recording/CheckCondition';
import { JsonConvert } from 'json2typescript';
import RehabTargetHistory from '../domain/RehabTargetHistory';
import BasalPhysical from '../../../domain/report/BasalPhysical';
import { BasalPrescription } from '../domain/prescription/BasalPrescription';
import ConditioningPrescription from '../domain/prescription/ConditioningPrescription';
import ItemsPrescription from '../domain/prescription/ItemsPrescription';
import Opinions from '../domain/prescription/Opinions';
import { RehabProgramPrescription } from '../domain/prescription/RehabProgramPrescription';

const rehabService = new RehabServiceImpl();
const rehabHistoryService = new RehabHistoryServiceImpl();

const menuTitle: string = '* 검진대상자 상세정보';
const title: string = '검진대상자 상세정보';

const jsonConvert: JsonConvert = new JsonConvert();
export default function CheckedUpTargetMngData(props: any) {
    const history = useHistory();
    const location = useLocation();
    const params: any = useParams();

    const [physicalStatus, setPhysicalStatus] = useState<any>();
    const [conditions, setConditions] = useState<any>();
    const [basalPhysical, setBasalPhysical] = useState<any>();
    const [specialPhysical, setSpecialPhysical] = useState<any>();
    const [basalPrescription, setBasalPrescription] = useState<any>();
    const [conditionningPrescription, setConditionningPrescription] = useState<any>();
    const [itemPrescription, setItemPrescription] = useState<any>();
    const [bodyPrescription, setBodyPrescription] = useState<any>();
    const [rehabProgramPrescription, setRehabProgramPrescription] = useState<any>();
    const [opinions, setOpinions] = useState<any>();
    let path = props.location.pathname;

    let locationState = location.state as any;
    const [targetInfo, setTargetInfo] = useState<TargetPerson>(new TargetPerson());
    const [targetReservationNo, setTargetReservationNo] = useState<string>();
    const [targetHistory, setTargetHistory] = useState<RehabTargetHistory>(new RehabTargetHistory());
    const [breakdownHistory, setBreakdownHistory] = useState<RehabTargetHistory[]>();

    let tab = params.tab;
    useEffect(() => {
        console.log('##### Checked up  target for managing data Page #####');
        let defaultInfo = locationState?.checkedupTarget as TargetPerson;
        let defaultReservation = locationState?.targetReservation as string;
        let defaultBreakdown = locationState?.historyTarget as RehabTargetHistory[];

        setTargetInfo(defaultInfo);
        setTargetReservationNo(defaultReservation);
        setBreakdownHistory(defaultBreakdown);
    }, []);

    useEffect(() => {
        if (targetReservationNo) {
            let targetNo: number = targetInfo.targetNo;
            let reservationNo: string = targetReservationNo;

            rehabHistoryService.getRecordingRehabHistoryForTarget(targetNo, reservationNo).then((data: RehabTargetHistory) => {
                setTargetHistory(data);
                setPhysicalStatus(
                    data.physicalStatus != '' ? jsonConvert.deserializeObject(JSON.parse(data.physicalStatus), PhysicalStatus) : new PhysicalStatus(),
                );
                setConditions(
                    data.conditions != '' ? jsonConvert.deserializeObject(JSON.parse(data.conditions), CheckCondition) : new CheckCondition(),
                );
                setBasalPhysical(
                    data.physicalStatus != '' ? jsonConvert.deserializeObject(JSON.parse(data.basalPhysical), BasalPhysical) : new BasalPhysical(),
                );
                setSpecialPhysical(
                    data.specialPhysical != ''
                        ? jsonConvert.deserializeObject(JSON.parse(data.specialPhysical), SpecialPhysical)
                        : new SpecialPhysical(),
                );

                setBasalPrescription(
                    data.basalExercisePrescription != ''
                        ? jsonConvert.deserializeObject(JSON.parse(data.basalExercisePrescription), BasalPrescription)
                        : new BasalPrescription(),
                );
                setConditionningPrescription(
                    data.conditioningPrescription != ''
                        ? jsonConvert.deserializeObject(JSON.parse(data.conditioningPrescription), ConditioningPrescription)
                        : new ConditioningPrescription(),
                );
                setItemPrescription(
                    data.itemPrescription != ''
                        ? jsonConvert.deserializeObject(JSON.parse(data.itemPrescription), ItemsPrescription)
                        : new ItemsPrescription(),
                );
                setBodyPrescription(
                    data.bodyPrescription != ''
                        ? jsonConvert.deserializeObject(JSON.parse(data.bodyPrescription), ItemsPrescription)
                        : new ItemsPrescription(),
                );
                setRehabProgramPrescription(
                    data.rehabProgramPrescription != ''
                        ? jsonConvert.deserializeObject(JSON.parse(data.rehabProgramPrescription), RehabProgramPrescription)
                        : new RehabProgramPrescription(),
                );
                setOpinions(data.opinions != '' ? jsonConvert.deserializeObject(JSON.parse(data.opinions), Opinions) : new Opinions());
            });
        }
    }, [targetReservationNo]);

    const goBack = () => {
        let returnPath = locationState.returnPath;
        history.replace({
            pathname: returnPath,
            state: locationState.checkedupTarget,
        });
    };

    const changeHistoryHandler = (evt: any) => {
        let target = evt.target;
        let reservationNo = target.value;
        setTargetReservationNo(reservationNo);
    };

    let btnSet = (
        <>
            {/* <button className="center-button" style={{ borderRadius: '3px', padding: '8px', float: 'right', margin: '5px 2px' }}>
                심리상담설문
            </button>
            <button className="center-button" style={{ borderRadius: '3px', padding: '8px', float: 'right', margin: '5px 2px' }}>
                일반설문
            </button> */}
            <button className="btn btn-info" style={{ borderRadius: '3px', padding: '8px', float: 'right', margin: '5px 2px' }} onClick={goBack}>
                돌아가기
            </button>
        </>
    );

    const tabHandler = (evt: any) => {
        let target = evt.target;
        history.replace({
            pathname: '/rehab/checkedup/mngdata/' + target.value,
            state: locationState,
        });
    };

    let content: any = null;
    let tabs = (tabName: any) => {
        let className = 'center-tab';
        let physicalTab = className;
        let conditionTab = className;
        let basalRecordingTab = className;
        let specialRecordingTab = className;
        let prescriptionTab = className;

        if (tabName === 'physical') {
            physicalTab += ' active';
            content = <MngPhysicalStatusComponent history={targetHistory} status={physicalStatus}></MngPhysicalStatusComponent>;
        } else if (tabName === 'condition') {
            conditionTab += ' active';
            content = <MngConditionComponent history={targetHistory} status={conditions}></MngConditionComponent>;
        } else if (tabName === 'basal') {
            basalRecordingTab += ' active';
            content = <MngBasalPhysicalComponent history={targetHistory} status={basalPhysical}></MngBasalPhysicalComponent>;
        } else if (tabName === 'special') {
            specialRecordingTab += ' active';
            content = <MngSpecialPhysicalComponent history={targetHistory} status={specialPhysical}></MngSpecialPhysicalComponent>;
        } else if (tabName === 'prescription') {
            prescriptionTab += ' active';
            content = (
                <MngPrescriptionComponent
                    history={targetHistory}
                    basalPrescription={basalPrescription}
                    conditionningPrescription={conditionningPrescription}
                    itemPrescription={itemPrescription}
                    bodyPrescription={bodyPrescription}
                    rehabProgramPrescription={rehabProgramPrescription}
                    opinions={opinions}
                ></MngPrescriptionComponent>
            );
        }

        return (
            <>
                <button className={physicalTab} value="physical" onClick={tabHandler}>
                    신체정보
                </button>
                <button className={conditionTab} value="condition" onClick={tabHandler}>
                    상태확인
                </button>
                <button className={basalRecordingTab} value="basal" onClick={tabHandler}>
                    기초체력측정
                </button>
                <button className={specialRecordingTab} value="special" onClick={tabHandler}>
                    전문체력측정
                </button>
                <button className={prescriptionTab} value="prescription" onClick={tabHandler}>
                    운동처방
                </button>
            </>
        );
    };

    let options = breakdownHistory?.map((item) => {
        return (
            <option value={item.reservationNo}>
                &lt;{item.historyNo}&gt;. {item.reservation?.startDate}~{item.reservation?.endDate}
            </option>
        );
    });

    return (
        <>
            <Layout
                header={<Header></Header>}
                sideBar={<SideBar name={path}></SideBar>}
                path={path}
                naviTitle={'검진자 기록 관리'}
                menuTitle={'검진자 기록 관리'}
            >
                <div style={{ width: '100%' }}>{btnSet}</div>
                <div style={{ display: 'flex', padding: '5px 5px 5px 5px', width: '100%' }}>
                    <div style={{ width: '80%', fontSize: '15px', fontWeight: 900 }}>* 대상자 기본 정보</div>
                    <div style={{ width: '20%' }}>
                        <select
                            className="center-select"
                            style={{ float: 'right', marginRight: '15px' }}
                            value={targetReservationNo}
                            onChange={changeHistoryHandler}
                        >
                            {options}
                        </select>
                    </div>
                </div>
                <div>
                    <table className="center-list-table" cellPadding="0" cellSpacing="0">
                        <colgroup>
                            <col style={{ width: '5%' }} />
                            <col style={{ width: '5%' }} />
                            <col style={{ width: '20%' }} />
                            <col style={{ width: '25%' }} />
                            <col style={{ width: '25%' }} />
                            <col style={{ width: '20%' }} />
                        </colgroup>
                        <thead>
                            <tr>
                                <td>검진번호</td>
                                <td>이름</td>
                                <td>생년월일</td>
                                <td>소속</td>
                                <td>검진예약일</td>
                                <td>검진센터</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{targetHistory?.historyNo}</td>
                                <td>{targetInfo?.reservater?.name}</td>
                                <td>{targetInfo?.reservater?.birthdate}</td>
                                <td>{targetInfo?.team?.name}</td>
                                <td>
                                    {targetHistory?.reservation?.startDate} ~ {targetHistory?.reservation?.endDate}
                                </td>
                                <td>{targetHistory?.reservation?.infra?.name}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div>{tabs(tab)}</div>
                <div>{content}</div>
            </Layout>
        </>
    );
}
