import React, { useState } from 'react';
import AnswerItem from '../../../../domain/AnswerItem';
import QuestionnariesFormProps from './QuestionnariesFormsProps';
import ReportDataParam from '../../../../../../domain/report/param/ReportDataParam';
import ReportTypeEnum from '../../../../../../domain/report/param/ReportTypeEnum';
import axios from 'axios';
import APIServerConfig from '../../../../../../config/APIServerConfig';
import useReportQuestionnarieAnswerMake from '../../../../../../hook/report/useReportQuestionnarieAnswerMake';

export default function NDI(props: QuestionnariesFormProps) {
    const putAnswerItem = (qNum: number, answerNumber: number) => {
        if (props.answerItems) {
            const newAnswerItem = AnswerItem.getNewAnswerItem(qNum, answerNumber);
            if (props.setAnswer) props.setAnswer(qNum, newAnswerItem);
        }
    };

    const getCheckOnClassName = (qNum: number, answerNumber: number): string => {
        if (props.answerItems) {
            if (props.answerItems.get(qNum)?.itemNoOfAnswer == answerNumber) {
                return 'check-on';
            } else {
                return '';
            }
        } else {
            return '';
        }
    };

    const { reportData } = useReportQuestionnarieAnswerMake(props.answerItems);

    return (
        <>
            <div className="center-center">
                Neck Disability Index(NDI) Questionnaire
                {props.resultPrintable ? (
                    <>
                        <form action={`${APIServerConfig.resourceAPIURL}/report/pdf`} method="POST">
                            <input type="hidden" name="reportType" value={ReportTypeEnum.NDI}></input>
                            <input type="hidden" name="reportData" value={JSON.stringify(reportData)}></input>
                            <input type="submit" value="설문 결과 PDF 내려받기" />
                        </form>
                        <form action={`${APIServerConfig.resourceAPIURL}/report/xlsx`} method="POST">
                            <input type="hidden" name="reportType" value={ReportTypeEnum.NDI}></input>
                            <input type="hidden" name="reportData" value={JSON.stringify(reportData)}></input>
                            <input type="submit" value="설문 결과 엑셀 내려받기" />
                        </form>
                    </>
                ) : (
                    <></>
                )}
            </div>
            <p>
                다음은 당신의 목과 팔의 통증이 어떻게 일상생활 능력에 영향을 주는지를 알아보기 위한 설문 조사입니다. 각 문항에서 현재 자신의 상태와
                가장 근접한 항목 하나에만 표시하세요.
            </p>
            <div className="tab-story-number">
                <h2 className="center2">제 1항목 - 통증 강도</h2>
                <ul>
                    <li
                        className={getCheckOnClassName(1, 0)}
                        onClick={() => {
                            putAnswerItem(1, 0);
                        }}
                    >
                        ⓪ 전혀 통증이 없다.
                    </li>
                    <li
                        className={getCheckOnClassName(1, 1)}
                        onClick={() => {
                            putAnswerItem(1, 1);
                        }}
                    >
                        ① 약한 통증이 있다.
                    </li>
                    <li
                        className={getCheckOnClassName(1, 2)}
                        onClick={() => {
                            putAnswerItem(1, 2);
                        }}
                    >
                        ② 중간 정도의 통증이 있다.
                    </li>
                    <li
                        className={getCheckOnClassName(1, 3)}
                        onClick={() => {
                            putAnswerItem(1, 3);
                        }}
                    >
                        ③ 심한 통증이 있다.
                    </li>
                    <li
                        className={getCheckOnClassName(1, 4)}
                        onClick={() => {
                            putAnswerItem(1, 4);
                        }}
                    >
                        ④ 매우 심한 통증이 있다.
                    </li>
                    <li
                        className={getCheckOnClassName(1, 5)}
                        onClick={() => {
                            putAnswerItem(1, 5);
                        }}
                    >
                        ⑤ 상상할 수 없을 정도의 극심한 통증이 있다.
                    </li>
                </ul>
            </div>
            <div className="tab-story-number">
                <h2 className="center2">제 2항목 - 자기 관리(씻기, 옷 입기…)</h2>
                <ul>
                    <li
                        className={getCheckOnClassName(2, 0)}
                        onClick={() => {
                            putAnswerItem(2, 0);
                        }}
                    >
                        ⓪ 통증 없이 정상적으로 나 자신을 돌볼 수 있다.
                    </li>
                    <li
                        className={getCheckOnClassName(2, 1)}
                        onClick={() => {
                            putAnswerItem(2, 1);
                        }}
                    >
                        ① 정상적으로 나 자신을 돌볼 수 있지만 통증이 있다.
                    </li>
                    <li
                        className={getCheckOnClassName(2, 2)}
                        onClick={() => {
                            putAnswerItem(2, 2);
                        }}
                    >
                        ② 나 자신을 돌보기가 고통스럽고 천천히 조심스럽게 움직인다.
                    </li>
                    <li
                        className={getCheckOnClassName(2, 3)}
                        onClick={() => {
                            putAnswerItem(2, 3);
                        }}
                    >
                        ③ 약간의 도움이 필요하지만 대부분의 자기 관리를 할 수 있다.
                    </li>
                    <li
                        className={getCheckOnClassName(2, 4)}
                        onClick={() => {
                            putAnswerItem(2, 4);
                        }}
                    >
                        ④ 대부분의 자기 관리를 위해서 매일 도움이 필요하다.
                    </li>
                    <li
                        className={getCheckOnClassName(2, 5)}
                        onClick={() => {
                            putAnswerItem(2, 5);
                        }}
                    >
                        ⑤ 옷을 입지 못하고 힘들게 씻으며 침대에만 누워 지낸다.
                    </li>
                </ul>
            </div>
            <div className="tab-story-number">
                <h2 className="center2">제 3항목 - 들어올리기</h2>
                <ul>
                    <li
                        className={getCheckOnClassName(3, 0)}
                        onClick={() => {
                            putAnswerItem(3, 0);
                        }}
                    >
                        ⓪ 통증 없이 무거운 물건을 들 수 있다.
                    </li>
                    <li
                        className={getCheckOnClassName(3, 1)}
                        onClick={() => {
                            putAnswerItem(3, 1);
                        }}
                    >
                        ① 무거운 물건을 들 수는 있지만 통증이 심해진다.
                    </li>
                    <li
                        className={getCheckOnClassName(3, 2)}
                        onClick={() => {
                            putAnswerItem(3, 2);
                        }}
                    >
                        ② 통증으로 인해 바닥에서 무거운 물건을 들어 올릴 수는 없지만 탁자 위와 같이 편한 위치에 있는 경우에는 무거운 물건도 들어 올릴
                        수 있다.
                    </li>
                    <li
                        className={getCheckOnClassName(3, 3)}
                        onClick={() => {
                            putAnswerItem(3, 3);
                        }}
                    >
                        ③ 통증으로 인해 무거운 물건을 들어 올릴 수는 없지만 탁자 위와 같이 편한 위치에 있는 경우는 가볍거나 중간 정도 무게의
                        물건이라면 들어 올릴 수 있다.
                    </li>
                    <li
                        className={getCheckOnClassName(3, 4)}
                        onClick={() => {
                            putAnswerItem(3, 4);
                        }}
                    >
                        ④ 아주 가벼운 물건만 들 수 있다.
                    </li>
                    <li
                        className={getCheckOnClassName(3, 5)}
                        onClick={() => {
                            putAnswerItem(3, 5);
                        }}
                    >
                        ⑤ 전혀 물건을 들거나 옮길 수 없다.
                    </li>
                </ul>
            </div>
            <div className="tab-story-number">
                <h2 className="center2">제 4항목 - 읽기</h2>
                <ul>
                    <li
                        className={getCheckOnClassName(4, 0)}
                        onClick={() => {
                            putAnswerItem(4, 0);
                        }}
                    >
                        ⓪ 목 통증 없이 원하는 만큼 독서 할 수 있다.
                    </li>
                    <li
                        className={getCheckOnClassName(4, 1)}
                        onClick={() => {
                            putAnswerItem(4, 1);
                        }}
                    >
                        ① 약간의 목 통증은 있지만, 원하는 만큼 독서 할 수 있다.
                    </li>
                    <li
                        className={getCheckOnClassName(4, 2)}
                        onClick={() => {
                            putAnswerItem(4, 2);
                        }}
                    >
                        ② 중간 정도의 목 통증은 있지만, 원하는 만큼 독서 할 수 있다.
                    </li>
                    <li
                        className={getCheckOnClassName(4, 3)}
                        onClick={() => {
                            putAnswerItem(4, 3);
                        }}
                    >
                        ③ 중간 정도의 목 통증 때문에 원하는 만큼의 독서를 할 수 없다.
                    </li>
                    <li
                        className={getCheckOnClassName(4, 4)}
                        onClick={() => {
                            putAnswerItem(4, 4);
                        }}
                    >
                        ④ 극심한 목 통증 때문에 거의 독서를 할 수 없다.
                    </li>
                    <li
                        className={getCheckOnClassName(4, 5)}
                        onClick={() => {
                            putAnswerItem(4, 5);
                        }}
                    >
                        ⑤ 전혀 독서 할 수 없다.
                    </li>
                </ul>
            </div>
            <div className="tab-story-number">
                <h2 className="center2">제 5항목 - 두통</h2>
                <ul>
                    <li
                        className={getCheckOnClassName(5, 0)}
                        onClick={() => {
                            putAnswerItem(5, 0);
                        }}
                    >
                        ⓪ 전혀 두통이 없다.
                    </li>
                    <li
                        className={getCheckOnClassName(5, 1)}
                        onClick={() => {
                            putAnswerItem(5, 1);
                        }}
                    >
                        ① 드물게 약간의 두통이 있다.
                    </li>
                    <li
                        className={getCheckOnClassName(5, 2)}
                        onClick={() => {
                            putAnswerItem(5, 2);
                        }}
                    >
                        ② 드물게 중간 정도의 두통이 있다.
                    </li>
                    <li
                        className={getCheckOnClassName(5, 3)}
                        onClick={() => {
                            putAnswerItem(5, 3);
                        }}
                    >
                        ③ 자주 중간 정도의 두통이 있다.
                    </li>
                    <li
                        className={getCheckOnClassName(5, 4)}
                        onClick={() => {
                            putAnswerItem(5, 4);
                        }}
                    >
                        ④ 자주 심한 두통이 있다.
                    </li>
                    <li
                        className={getCheckOnClassName(5, 5)}
                        onClick={() => {
                            putAnswerItem(5, 5);
                        }}
                    >
                        ⑤ 거의 항상 두통이 있다.
                    </li>
                </ul>
            </div>
            <div className="tab-story-number">
                <h2 className="center2">제 6항목 - 집중도</h2>
                <ul>
                    <li
                        className={getCheckOnClassName(6, 0)}
                        onClick={() => {
                            putAnswerItem(6, 0);
                        }}
                    >
                        ⓪ 아무 어려움 없이(원하면) 언제든 집중할 수 있다.
                    </li>
                    <li
                        className={getCheckOnClassName(6, 1)}
                        onClick={() => {
                            putAnswerItem(6, 1);
                        }}
                    >
                        ① 약간의 어려움은 있으나, 언제든 집중할 수 있다.
                    </li>
                    <li
                        className={getCheckOnClassName(6, 2)}
                        onClick={() => {
                            putAnswerItem(6, 2);
                        }}
                    >
                        ② 집중 시 중간 정도의 어려움이 있다.
                    </li>
                    <li
                        className={getCheckOnClassName(6, 3)}
                        onClick={() => {
                            putAnswerItem(6, 3);
                        }}
                    >
                        ③ 집중 시 많은 어려움이 있다.
                    </li>
                    <li
                        className={getCheckOnClassName(6, 4)}
                        onClick={() => {
                            putAnswerItem(6, 4);
                        }}
                    >
                        ④ 집중 시 상당히 많은 어려움이 있다.
                    </li>
                    <li
                        className={getCheckOnClassName(6, 5)}
                        onClick={() => {
                            putAnswerItem(6, 5);
                        }}
                    >
                        ⑤ 전혀 집중할 수 없다.
                    </li>
                </ul>
            </div>
            <div className="tab-story-number">
                <h2 className="center2">제 7항목 - 일</h2>
                <ul>
                    <li
                        className={getCheckOnClassName(7, 0)}
                        onClick={() => {
                            putAnswerItem(7, 0);
                        }}
                    >
                        ⓪ 내가 원하는 만큼 일 할 수 있다.
                    </li>
                    <li
                        className={getCheckOnClassName(7, 1)}
                        onClick={() => {
                            putAnswerItem(7, 1);
                        }}
                    >
                        ① 일상 생활은 할 수 있지만 그 이상은 불가능하다.
                    </li>
                    <li
                        className={getCheckOnClassName(7, 2)}
                        onClick={() => {
                            putAnswerItem(7, 2);
                        }}
                    >
                        ② 대부분의 일상 생활은 할 수 있지만 그 이상은 불가능하다.
                    </li>
                    <li
                        className={getCheckOnClassName(7, 3)}
                        onClick={() => {
                            putAnswerItem(7, 3);
                        }}
                    >
                        ③ 일상 생활이 불가능하다.
                    </li>
                    <li
                        className={getCheckOnClassName(7, 4)}
                        onClick={() => {
                            putAnswerItem(7, 4);
                        }}
                    >
                        ④ 어떤 일도 거의 할 수 없다.
                    </li>
                    <li
                        className={getCheckOnClassName(7, 5)}
                        onClick={() => {
                            putAnswerItem(7, 5);
                        }}
                    >
                        ⑤ 어떤 일도 전혀 할 수 없다.
                    </li>
                </ul>
            </div>
            <div className="tab-story-number">
                <h2 className="center2">제 8항목 - 운전(운전을 하는 경우만 답해 주세요)</h2>
                <ul>
                    <li
                        className={getCheckOnClassName(8, 0)}
                        onClick={() => {
                            putAnswerItem(8, 0);
                        }}
                    >
                        ⓪ 목 통증 없이 운전 할 수 있다.
                    </li>
                    <li
                        className={getCheckOnClassName(8, 1)}
                        onClick={() => {
                            putAnswerItem(8, 1);
                        }}
                    >
                        ① 약간의 목 통증은 있지만 내가 원하는 만큼 운전 할 수 있다.
                    </li>
                    <li
                        className={getCheckOnClassName(8, 2)}
                        onClick={() => {
                            putAnswerItem(8, 2);
                        }}
                    >
                        ② 중간 정도의 목 통증은 있지만 내가 원하는 만큼 운전 할 수 있다.
                    </li>
                    <li
                        className={getCheckOnClassName(8, 3)}
                        onClick={() => {
                            putAnswerItem(8, 3);
                        }}
                    >
                        ③ 중간 정도의 목 통증 때문에 내가 원하는 만큼의 운전을 할 수 없다.
                    </li>
                    <li
                        className={getCheckOnClassName(8, 4)}
                        onClick={() => {
                            putAnswerItem(8, 4);
                        }}
                    >
                        ④ 심한 목 통증 때문에 거의 운전 할 수 없다.
                    </li>
                    <li
                        className={getCheckOnClassName(8, 5)}
                        onClick={() => {
                            putAnswerItem(8, 5);
                        }}
                    >
                        ⑤ 전혀 운전 할 수 없다.
                    </li>
                </ul>
            </div>
            <div className="tab-story-number">
                <h2 className="center2">제 9 항목 - 수면</h2>
                <ul>
                    <li
                        className={getCheckOnClassName(9, 0)}
                        onClick={() => {
                            putAnswerItem(9, 0);
                        }}
                    >
                        ⓪ 수면 시 전혀 문제 없다.
                    </li>
                    <li
                        className={getCheckOnClassName(9, 1)}
                        onClick={() => {
                            putAnswerItem(9, 1);
                        }}
                    >
                        ① 수면 시 아주 약간의 문제가 있다(잠들지 못하는 시간이 1시간 이하이다).
                    </li>
                    <li
                        className={getCheckOnClassName(9, 2)}
                        onClick={() => {
                            putAnswerItem(9, 2);
                        }}
                    >
                        ② 수면 시 약간의 문제가 있다(1-2시간 이상 잠들지 못함).
                    </li>
                    <li
                        className={getCheckOnClassName(9, 3)}
                        onClick={() => {
                            putAnswerItem(9, 3);
                        }}
                    >
                        ③ 수면 시 중간 정도의 문제가 있다(2-3시간 이상 잠들지 못함).
                    </li>
                    <li
                        className={getCheckOnClassName(9, 4)}
                        onClick={() => {
                            putAnswerItem(9, 4);
                        }}
                    >
                        ④ 수면 시 상당히 문제가 있다(3-5시간 이상 잠들지 못함).
                    </li>
                    <li
                        className={getCheckOnClassName(9, 5)}
                        onClick={() => {
                            putAnswerItem(9, 5);
                        }}
                    >
                        ⑤ 수면이 불가능 하다(5-7시간 이상 잠들지 못함).
                    </li>
                </ul>
            </div>
            <div className="tab-story-number">
                <h2 className="center2">제 10항목 - 여가 생활(취미생활, 여행 등)</h2>
                <ul>
                    <li
                        className={getCheckOnClassName(10, 0)}
                        onClick={() => {
                            putAnswerItem(10, 0);
                        }}
                    >
                        ⓪ 목 통증이 전혀 없이 모든 여가 생활이 가능하다.
                    </li>
                    <li
                        className={getCheckOnClassName(10, 1)}
                        onClick={() => {
                            putAnswerItem(10, 1);
                        }}
                    >
                        ① 약간의 목 통증이 있지만, 모든 여가 생활이 가능하다.
                    </li>
                    <li
                        className={getCheckOnClassName(10, 2)}
                        onClick={() => {
                            putAnswerItem(10, 2);
                        }}
                    >
                        ② 목 통증 때문에 모두는 아니지만 대부분의 일상 여가 생활은 가능하다.
                    </li>
                    <li
                        className={getCheckOnClassName(10, 3)}
                        onClick={() => {
                            putAnswerItem(10, 3);
                        }}
                    >
                        ③ 목 통증 때문에 몇 가지 여가 생활만 가능하다.
                    </li>
                    <li
                        className={getCheckOnClassName(10, 4)}
                        onClick={() => {
                            putAnswerItem(10, 4);
                        }}
                    >
                        ④ 목 통증 때문에 거의 여가 생활이 불가능하다.
                    </li>
                    <li
                        className={getCheckOnClassName(10, 5)}
                        onClick={() => {
                            putAnswerItem(10, 5);
                        }}
                    >
                        ⑤ 어떠한 여가 생활도 전혀 할 수 없다.
                    </li>
                </ul>
            </div>
        </>
    );
}
