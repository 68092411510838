import { Redirect } from "react-router";
import URLParamMakerUtils from '../../../utils/URLParamMakeUtils';

interface SearchIdRedirectPage {
    location: any;
}

function SearchIdRedirectPage(props: SearchIdRedirectPage) {
    const id = URLParamMakerUtils.getUrlParameter('id', props.location);
    return (
        <Redirect
            to={{
                pathname: '/idfinder',
                state: {
                    id: id
                }
            }}
        />
    )
}

export default SearchIdRedirectPage;