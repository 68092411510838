import React from 'react';
import { Link } from 'react-router-dom';
function CommonFooter(prop: any) {
    return (
        <>
            <div id="footer-wrap" className="footer">
                <div className="footer_ad">
                    <div className="footer_logo">
                        <img src="/assets/image/logo_gray.png" />
                    </div>
                    <div className="footer_txt">
                        <ul>
                            <li>
                                <a href="/iis/web/introduce06.do">찾아오시는 길</a>
                            </li>
                            <li>
                                <a href="/iis/web/terms.do">이용약관</a>
                            </li>
                            <li>
                                <a href="/iis/web/privacy.do">개인정보취급방침</a>
                            </li>
                        </ul>
                        <div className="address">
                            <p>경남 창원시 마산회원구 내서읍 함마대로 2640 마산대학교 미래관 TEL : 055-230-0168 </p>
                            <p>Copyright(c) 2021 경남스포츠산업육성지원센터. All rights reserved.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CommonFooter;
