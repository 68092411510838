class UserSearchCondition {
    signUpApprove?: boolean;
    searchCont?: string;
    searchWord?: string;
    roleGroupNames?: string;
    phoneNumber?: string;
    email?: string;
    page?: number;
    groupIdx?: number;

    constructor(signUpApprove?: boolean, searchCont?: string, searchWord?: string, roleGroupNames?: string) {
        this.signUpApprove = signUpApprove;
        this.searchCont = searchCont;
        this.searchWord = searchWord;
        this.roleGroupNames = roleGroupNames;
    }
}

export default UserSearchCondition;
