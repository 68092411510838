import "core-js/stable";
import React from 'react';
import './App.css';
import IndexRouter from './view/IndexRouter';
import useAuthHook from './hook/auth/useAuthHook';

/**
 * 사용자 로그인 정보 셋팅 처리 
 */
function App() {
  useAuthHook();
  return (
      <IndexRouter></IndexRouter>
  );
}

(function (arr) {
  arr.forEach(function (item) {
    if (item.hasOwnProperty('remove')) {
      return;
    }
    Object.defineProperty(item, 'remove', {
      configurable: true,
      enumerable: true,
      writable: true,
      value: function remove() {
        this.parentNode.removeChild(this);
      }
    });
  });
})([Element.prototype, CharacterData.prototype, DocumentType.prototype]);

export default App;
