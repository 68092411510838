import { useEffect, useState } from 'react';
import ClubTeamInfoSearchCondition from '../../domain/clubteam/param/ClubTeamInfoSearchCondition';
import Paging from '../../domain/common/Paging';
import ClubTeamInfo from '../../domain/clubteam/ClubTeamInfo';
import { CLUB_TEAM_INFO_SERVICE } from '../../service/index';
function useClubTeamInfosRetrieve(searchCondition: ClubTeamInfoSearchCondition, paging: Paging) {
    const [clubTeamInfos, setClubTeamInfos] = useState<ClubTeamInfo[]>();
    const [clubTeamInfosCnt, setClubTeamInfosCnt] = useState<number>(0);

    useEffect(() => {
        CLUB_TEAM_INFO_SERVICE.getClubInfosBy(searchCondition, paging).then((clubTeamInfos: ClubTeamInfo[]) => {
            setClubTeamInfos(clubTeamInfos);
        });

        CLUB_TEAM_INFO_SERVICE.getClubInfosCntBy(searchCondition).then((clubTeamInfosCnt: number) => {
            setClubTeamInfosCnt(clubTeamInfosCnt);
        });
    }, [searchCondition, paging]);

    return {
        clubTeamInfos,
        clubTeamInfosCnt,
    };
}

export default useClubTeamInfosRetrieve;
