import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../moldules/index';
import { unRegisteUserSessionAction } from '../../../moldules/UserSession';

function AdminMainHeader() {
    const dispatch = useDispatch();
    const { user } = useSelector((state: RootState) => state.userSessionReducer);

    const logoutEvent = () => {
        dispatch(unRegisteUserSessionAction());
        window.location.href = '/';
    };
    return (
        <header>
            <div className="flex-basic">
                <div className="admin-logo flex-basic">
                    <a href="/">
                        <img src="/assets/image/logo_without_name.png" />
                    </a>
                    <span>방문스포츠팀 정보통합 플랫폼 어드민</span>&nbsp;&nbsp;
                    {/* <a href="/admin/members"><span style={inlineStyle}>서비스 관리자</span></a> */}
                </div>
                <div className="admin-login flex-basic">
                    <div className="flex-basic">
                        <i className="fas fa-portrait"></i>
                        <span>
                            {user.name}({user.email})
                        </span>
                    </div>
                    님 환영합니다. &nbsp;&nbsp;
                    <a onClick={logoutEvent} className="white" style={{ fontWeight: 900, cursor: 'pointer' }}>
                        로그아웃&nbsp;<i className="fas fa-caret-square-down"></i>
                    </a>
                </div>
            </div>
        </header>
    );
}

export default AdminMainHeader;
