import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import ClubTeamInfoSearchCondition from '../../../../domain/clubteam/param/ClubTeamInfoSearchCondition';
import useChildCodeRetrieve from '../../../../hook/code/useChildCodeRetrieve';
import { defaultParentRegionCodeId } from '../../../../config/Constant';
import useCodeRetrieve from '../../../../hook/code/useCodeRetrieve';
import { CodeType } from '../../../../domain/code/CodeType';
import Code from '../../../../domain/code/Code';
import CodeSelectFilter from './element/CodeSelectFilter';
interface AdminClubTeamInfosFilterProps {
    setClubTeamInfoSearchCondition: (condition: ClubTeamInfoSearchCondition) => void;
}

function AdminClubTeamInfosFilter(props: AdminClubTeamInfosFilterProps) {
    const history = useHistory();

    const [years, setYears] = useState<number[]>();
    const [halfYears, setHalfYears] = useState<number[]>();
    const [year, setYear] = useState<number>();
    const [halfYear, setHalfYear] = useState<number>();
    const regionCodes = useChildCodeRetrieve(defaultParentRegionCodeId);
    const { codes: sportCodes } = useCodeRetrieve(CodeType.CLUB_SPORT);

    const [regionCodeId, setRegionCodeId] = useState<number>();
    const [sportCodeId, setSportCodeId] = useState<number>();

    const goRegistryForm = () => {
        history.push({
            pathname: '/admin/club-team-info/registry',
            state: null,
        });
    };

    const keyEventHandler = (e: any) => {
        if (e.key == 'Enter') {
            retrievHandler();
        }
    };

    const retrievHandler = () => {
        const searchCondition = new ClubTeamInfoSearchCondition();
        if (year) searchCondition.years = String(year);
        if (halfYear) searchCondition.partOfYears = String(halfYear);
        if (regionCodeId) searchCondition.regionCodeIds = String(regionCodeId);
        if (sportCodeId) searchCondition.sportCodeIds = String(sportCodeId);

        props.setClubTeamInfoSearchCondition(searchCondition);
    };

    useEffect(() => {
        setYears([2018, 2019, 2020, 2021, 2022, 2023]);
        setHalfYears([1, 2]);
    }, []);
    return (
        <>
            <div className="member-search">
                <select
                    value={year}
                    onChange={(evt: any) => {
                        let val = evt.currentTarget.value;
                        setYear(Number(val));
                        document.getElementById('keyHandler')?.focus();
                    }}
                >
                    <option value="0">시즌선택</option>
                    {years?.map((year, idx) => {
                        return <option value={year}>{year} 시즌</option>;
                    })}
                </select>
                <select
                    value={halfYear}
                    onChange={(evt: any) => {
                        let val = evt.currentTarget.value;
                        setHalfYear(Number(val));
                        document.getElementById('keyHandler')?.focus();
                    }}
                >
                    <option value="0">반기선택</option>
                    {halfYears?.map((half, idx) => {
                        return <option value={half}>{half == 1 ? '상반기' : '하반기'}</option>;
                    })}
                </select>
                <select
                    value={regionCodeId}
                    onChange={(evt: any) => {
                        let val = evt.currentTarget.value;
                        setRegionCodeId(Number(val));
                        document.getElementById('keyHandler')?.focus();
                    }}
                >
                    <option value="0">전체지역</option>
                    {regionCodes?.map((region, idx) => {
                        return <option value={region.codeId}>{region.name}</option>;
                    })}
                </select>
                <select
                    value={sportCodeId}
                    onChange={(evt: any) => {
                        let val = evt.currentTarget.value;
                        setSportCodeId(Number(val));
                        document.getElementById('keyHandler')?.focus();
                    }}
                >
                    <option value="0">전체종목</option>
                    {sportCodes?.map((sports, idx) => {
                        return <option value={sports.codeId}>{sports.name}</option>;
                    })}
                </select>
            </div>
            <div className="member-search">
                <button className="bg-light-blue" style={{ fontWeight: 700 }} onClick={goRegistryForm}>
                    현황등록
                </button>
                <button className="bg-blue white" style={{ fontWeight: 800 }} onClick={retrievHandler}>
                    조회
                </button>
                <input id="keyHandler" value="" onKeyPress={keyEventHandler} style={{ width: '0px', border: '0px' }} />
            </div>
        </>
    );
}

export default AdminClubTeamInfosFilter;
