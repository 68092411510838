import React from 'react';
import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('conditionPrescription')
export default class ConditioningPrescription {
    @JsonProperty('icing')
    icing: string[] = ['', '', ''];
    @JsonProperty('massage')
    massage: string[] = ['', '', ''];
    @JsonProperty('sling')
    sling: string[] = ['', '', ''];
    @JsonProperty('painDoctor')
    painDoctor: string[] = ['', '', ''];
    @JsonProperty('radioFrq')
    radioFrq: string[] = ['', '', ''];
    @JsonProperty('eswt')
    eswt: string[] = ['', '', ''];
    @JsonProperty('vibro')
    vibro: string[] = ['', '', ''];
}
