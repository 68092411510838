import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject("board")
class Board {
    @JsonProperty("boardNo")
    boardNo: number = 0;
    @JsonProperty("boardName")
    boardName: string = '';
    @JsonProperty("boardTargetNo")
    boardTargetNo: string = '';
    @JsonProperty("contentWritable")
    contentWritable: boolean = false;
    @JsonProperty("replyWritable")
    replyWritable: boolean = false;
    @JsonProperty("fileAttachable")
    fileAttachable: boolean = false;
    @JsonProperty("boardSubType")
    boardSubType: string = '';
}

export default Board;