class ChartDataUtils {
    static getChartData(dataOfDataSet: Array<number | undefined>, backgoundColor: Array<string | undefined>, labels: Array<string | undefined>): any {
        const data = {
            datasets: [
                {
                    data: dataOfDataSet,
                    backgroundColor: backgoundColor,
                },
            ],
            labels: labels,
        };

        return data;
    }

    static getNumberFormat(source: number): string {
        let target = '0';
        if (!isNaN(source)) target = source.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        //console.log(isNaN(target));
        return target;
    }
}

export default ChartDataUtils;
