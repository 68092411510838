class URLParamMakerUtils {
    static getParam(searchConditionMap: any, searchWord: string|undefined): string|undefined {
        let param = "?";
        if(searchWord) {
            param = `${param}searchWord=${searchWord}`;
        }
        if(searchConditionMap) {
            let keys = Object.keys(searchConditionMap);
            keys.map((key, index) => {
                let prefix = '';
                if(index != 0 || searchWord) {
                    prefix = '&'
                }
                param = `${param}${prefix}${key}=${searchConditionMap[key]}`;
            });
        }
        return param;
    }

    static getUrlParameter(name: any, location: any) {
        name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
        var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');

        var results = regex.exec(location.search);
        return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
    }
}

export default URLParamMakerUtils;