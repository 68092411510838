import React from 'react';
interface SiteDepthProps {
    pageTitle: string;
    depth1: string
}
function SiteDepth(props: SiteDepthProps) {
    return (
        <div className="ouput-tit">
            <div className="tit f-left">
                {props.pageTitle}
            </div>
            <div className="f-right sub-navi">
                <p>홈 &gt; {props.depth1}</p>
            </div>
            <div className="clear"></div>
        </div>
    )
}

export default SiteDepth;