import { mobileConfirmCompanyHost } from '../../../config/Constant';
interface SignUpUserValidationCheckComponentProps {
    trCert?: string;
    trUrl?: string;
    trAdd?: string;
}

function SignUpUserValidationCheckComponent(props: SignUpUserValidationCheckComponentProps) {
    const mobileCheckStartEvent = (event: any) => {
        if (props.trCert && props.trUrl && props.trAdd) {
            var winX = window.screenX || window.screenLeft || 0;
            var winY = window.screenY || window.screenTop || 0;
            var popupX = window.innerWidth / 2 - 425 / 2;
            var popupY = window.innerHeight / 2 - 550 / 2;
            popupX += winX;
            popupY += winY + 20;
            var KMCIS_window: any = window.open(
                '',
                'KMCISWindow',
                `width=425, height=550, resizable=0, scrollbars=no, status=0, titlebar=0, toolbar=0, left=${popupX}, top=${popupY}`,
            );
            if (KMCIS_window == null) {
                alert('팝업 해제해주세요.');
            }
            window.name = 'kmcis_web_sample';
            var formTag: any = document.getElementById('test');
            formTag.target = 'KMCISWindow';
            formTag.action = mobileConfirmCompanyHost;

            formTag.submit();
        }
    };
    return (
        <div className="user-confirm-wrap-container">
            <div className="user-confirm-wrap-body">
                <h5 className="header-h5">휴대폰 인증</h5>
                <p>본인 명의로 가입한 휴대 전화로만 인증 가능합니다.</p>
                <form id="test">
                    <input type="hidden" name="tr_cert" value={props.trCert}></input>
                    <input type="hidden" name="tr_url" value={props.trUrl}></input>
                    <input type="hidden" name="tr_add" value={props.trAdd}></input>
                    {/* <button className="" onClick={mobileCheckStartEvent}>인증 하기</button> */}
                    <button className="agree_btn" onClick={(event) => mobileCheckStartEvent(event)}>
                        인증하기
                    </button>
                </form>
            </div>
        </div>
    );
}

export default SignUpUserValidationCheckComponent;
