import React, { useEffect, useState } from 'react';
import usePagingHook from '../../../hook/usePagingHook';

interface AdminRetrieveItemPaging {
    totalCnt: number;
    pagingSize: number;
    setPagingSize: (pagingSize: number) => void;
    pagingCnt: number;
    setPage: (pageNum: number) => void;
    currentPageNum: number;
    currentPageGroupIdx?: number;
}

function AdminRetrieveItemPaging(props: AdminRetrieveItemPaging) {
    const {
        currentPageGroupIdx,
        setCurrentPageGroupIdx,
        pageCnt,
        pageGroupCnt,
        pageGroupArr,
        onPrevEventHandler,
        onNextEventHandler,
    } = usePagingHook(props.totalCnt, props.pagingSize, props.pagingCnt, props.setPage, props.currentPageGroupIdx);

    return (
        <div className="member-btn flex-basic">
            <div className="btn-del"></div>
            <div className="pageno">
                {currentPageGroupIdx != 0 ? (
                    <>
                        <button id="first" key={100} className="bg-btn-white" onClick={() => onPrevEventHandler(1)}>
                            <b> &lt;&lt; </b>
                        </button>
                        <button id="prev" key={0} className="bg-btn-white" onClick={() => onPrevEventHandler(currentPageGroupIdx)}>
                            <b> &lt; </b>
                        </button>
                    </>
                ) : (
                    <></>
                )}
                {pageGroupArr.map((value: number, index: number) => {
                    let pageNum = props.pagingCnt * currentPageGroupIdx + (value + 1);
                    return pageNum <= pageCnt ? (
                        <button
                            key={index}
                            className={`${pageNum - 1 == props.currentPageNum ? 'bg-blue' : 'bg-gray'} white`}
                            onClick={() => {
                                props.setPage(pageNum - 1);
                            }}
                        >
                            &nbsp;{pageNum}&nbsp;
                        </button>
                    ) : (
                        <></>
                    );
                })}
                {currentPageGroupIdx < pageGroupCnt - 1 ? (
                    <>
                        <button id="next" className="bg-btn-white" onClick={() => onNextEventHandler(currentPageGroupIdx)} key={10}>
                            <b> &gt; </b>
                        </button>
                        <button id="last" className="bg-btn-white" onClick={() => onNextEventHandler(pageGroupCnt - 2)} key={101}>
                            <b> &gt;&gt; </b>
                        </button>
                    </>
                ) : (
                    <></>
                )}
            </div>
            <div className="list-ea">
                <select
                    onChange={(event) => {
                        const pagingSize = Number(event.currentTarget.value);
                        props.setPagingSize(pagingSize);
                        props.setPage(0);
                        setCurrentPageGroupIdx(0);
                    }}
                >
                    <option value={10}>10개씩 보기</option>
                    <option value={15}>15개씩 보기</option>
                    <option value={20}>20개씩 보기</option>
                </select>
            </div>
        </div>
    );
}

export default AdminRetrieveItemPaging;
