import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import useStatusData from './hooks/GraphDataHooks';

import TargetPerson from '../../../../domain/TargetPerson';

interface IProps {
    targetPerson: TargetPerson;
}

interface IChartHandler {
    all: boolean;
    weight: boolean;
    skelMuscle: boolean;
    bmi: boolean;
    relativeGrip: boolean;
    pom: boolean;
    vas: boolean;
}

export default function StatusGraphFormComponent(props: IProps) {
    const target = props.targetPerson;
    /* let handler: IChartHandler = {
        all: true,
        weight: true,
        skelMuscle: true,
        bmi: true,
        relativeGrip: true,
        pom: true,
        vas: true,
    };
    const [chartHandler, setChartHandler] = useState<IChartHandler>(handler);
    useEffect(() => {
        let weightBlock = document.getElementById('weight-block');
        let skelMuscleBlock = document.getElementById('skelMuscle-block');
        let bmiBlock = document.getElementById('bmi-block');
        let relativeBlock = document.getElementById('relativeGrap-block');
        let pomBlock = document.getElementById('pom-block');
        let vasBlock = document.getElementById('vas-block');
        if (chartHandler.weight) {
            if (weightBlock) weightBlock.style.display = 'block';
        } else {
            if (weightBlock) weightBlock.style.display = 'none';
        }
        if (chartHandler.skelMuscle) {
            if (skelMuscleBlock) skelMuscleBlock.style.display = 'block';
        } else {
            if (skelMuscleBlock) skelMuscleBlock.style.display = 'none';
        }
        if (chartHandler.bmi) {
            if (bmiBlock) bmiBlock.style.display = 'block';
        } else {
            if (bmiBlock) bmiBlock.style.display = 'none';
        }
        if (chartHandler.relativeGrip) {
            if (relativeBlock) relativeBlock.style.display = 'block';
        } else {
            if (relativeBlock) relativeBlock.style.display = 'none';
        }
        if (chartHandler.pom) {
            if (pomBlock) pomBlock.style.display = 'block';
        } else {
            if (pomBlock) pomBlock.style.display = 'none';
        }
        if (chartHandler.vas) {
            if (vasBlock) vasBlock.style.display = 'block';
        } else {
            if (vasBlock) vasBlock.style.display = 'none';
        }
    }, [chartHandler]);
    const { weight, skelMuscle, bmi, relativeGrap, pom, vases } = useStatusData(target);
    const options = {
        legend: {
            display: true, // label 숨기기
        },
        //maintainAspectRatio: false,
        scales: {
            yAxes: [
                {
                    ticks: {
                        min: 0, // 스케일에 대한 최솟갓 설정, 0 부터 시작
                        stepSize: 50, // 스케일에 대한 사용자 고정 정의 값
                        beginAtZero: true,
                    },
                },
            ],
        },
    };
    const selectChartHandler = (e: any) => {
        let target = e.target;
        let targetId = target.id;
        let inputVal = target.checked;
        let allChecked = true;

        setChartHandler((prevState) => {
            if (targetId !== 'all') {
                allChecked = false;
                return {
                    ...prevState,
                    [targetId]: inputVal,
                    ['all']: allChecked,
                };
            } else {
                let newObj: IChartHandler = {
                    all: inputVal,
                    weight: inputVal,
                    skelMuscle: inputVal,
                    bmi: inputVal,
                    relativeGrip: inputVal,
                    pom: inputVal,
                    vas: inputVal,
                };
                return newObj;
            }
        });
    }; */

    return (
        <>
            <div className="center-popup-list-box">
                <div className="flex-top">
                    <span>서비스 준비 중입니다.</span>
                    {/* <div className="tabonleft center-tbpd20">
                        <ul>
                            <li>
                                <input id="all" name="chartHandler" type="checkbox" checked={chartHandler.all} onChange={selectChartHandler} /> All
                            </li>
                            <li>
                                <input id="weight" name="chartHandler" type="checkbox" checked={chartHandler.weight} onChange={selectChartHandler} />{' '}
                                체중
                            </li>
                            <li>
                                <input
                                    id="skelMuscle"
                                    name="chartHandler"
                                    type="checkbox"
                                    checked={chartHandler.skelMuscle}
                                    onChange={selectChartHandler}
                                />{' '}
                                골격근량
                            </li>
                            <li>
                                <input id="bmi" name="chartHandler" type="checkbox" checked={chartHandler.bmi} onChange={selectChartHandler} /> BMI
                            </li>
                            <li>
                                <input
                                    id="relativeGrip"
                                    name="chartHandler"
                                    type="checkbox"
                                    checked={chartHandler.relativeGrip}
                                    onChange={selectChartHandler}
                                />{' '}
                                상대약력
                            </li>
                            <li>
                                <input id="pom" name="chartHandler" type="checkbox" checked={chartHandler.pom} onChange={selectChartHandler} /> POM
                            </li>
                            <li>
                                <input id="vas" name="chartHandler" type="checkbox" checked={chartHandler.vas} onChange={selectChartHandler} />{' '}
                                통증강도
                            </li>
                        </ul>
                    </div>
                    <div className="tabonright-chart">
                        <div className="flex-basic">
                            <div id="weight-block">
                                <p>체중</p>
                                <Bar data={weight} options={options} width={200} height={200}></Bar>
                            </div>
                            <div id="skelMuscle-block">
                                <p>골격근량</p>
                                <Bar data={skelMuscle} options={options} width={200} height={200}></Bar>
                            </div>
                        </div>
                        <div className="flex-basic">
                            <div id="bmi-block">
                                <p>BMI</p>
                                <Bar data={bmi} options={options} width={200} height={200}></Bar>
                            </div>
                            <div id="relativeGrap-block">
                                <p>상대악력</p>
                                <Bar data={relativeGrap} options={options} width={200} height={200}></Bar>
                            </div>
                        </div>

                        <div className="flex-basic">
                            <div id="pom-block" className="full-chart">
                                <p>POM</p>
                                <Bar data={pom} options={options} width={200} height={200}></Bar>
                            </div>
                        </div>
                        <div className="flex-basic">
                            <div id="vas-block" className="full-chart">
                                <p>통증강도</p>
                                <Bar data={vases} options={options} width={200} height={200}></Bar>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </>
    );
}
