import React, { useEffect, useState } from 'react';
import SearchPageHeader from '../layout/SearchPageHeader';
import SearchConditionContainer from '../../../container/search/SearchConditionContainer';
import SearchLeft from '../../../component/layout/SearchLeft';
import SearchIn from '../../../component/layout/SearchIn';
import SearchRight from '../../../component/layout/SearchRight';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../moldules/index';
import { initConditionAction } from '../../../moldules/SearchCondition';
import SearchConditionUtils from '../../../utils/SearchConditionUtils';
import SearchOfInfraContainer from '../../../container/search/SearchOfInfraContainer';
import SearchOfTeamContainer from '../../../container/search/SearchOfTeamContainer';
import useSearchPageHook from './hook/useSearchPageHook';
import InfraServiceImpl from '../../../service/infra/impl/InfraServiceImpl';
import Infra from '../../../domain/infra/Infra';
import Team from '../../../domain/team/Team';
import TeamServiceImpl from '../../../service/team/impl/TeamServiceImpl';
import SearchCnt from '../../../component/search/SearchCnt';
import SearchIteamPaging from '../../../component/search/SearchItemPaging';

/**
 * searchType은 검색 유형, 팀 검색인지 시설 조회 인지를 관리하는 플래그 타입
 */
const infraService = new InfraServiceImpl();
const teamService = new TeamServiceImpl();

function SearchPageContainer() {
    const [infras, setInfras] = useState<Infra[]>();
    const [teams, setTeams] = useState<Team[]>();
    const [searchCnt, setSearchCnt] = useState(0);
    const searchWord = useSelector((state: RootState) => state.searchWordReduce.searchWord);

    const {
        searchType,
        setSearchType,
        searchConditionMapParsing,
        parsedSearchConditionMap,
        pagingSize,
        pagingCnt,
        setPage,
        page,
    } = useSearchPageHook(4001);

    useEffect(() => {
        if (Object.keys(parsedSearchConditionMap).length > 0) {
            if (searchType == 4001) {
                infraService.getInfraOfSearchCondition(parsedSearchConditionMap, searchWord).then((infras: Infra[]) => {
                    setInfras(infras);
                });
                infraService.getInfraCntOfSearchCondition(parsedSearchConditionMap, searchWord).then((infraCnt) => {
                    setSearchCnt(infraCnt);
                });
            } else {
                teamService.getTeams(parsedSearchConditionMap, searchWord).then((teams: Team[]) => {
                    setTeams(teams);
                    setSearchCnt(teams.length);
                });

                teamService.getTeamsCnt(parsedSearchConditionMap, searchWord).then((teamCnt) => {
                    setSearchCnt(teamCnt);
                });
            }
        }
    }, [parsedSearchConditionMap, searchWord, searchType]);

    return (
        <>
            <SearchPageHeader></SearchPageHeader>
            <div className="content-wrap">
                <SearchLeft>
                    <SearchIn>
                        <SearchConditionContainer receivedSearchWord={searchWord} setSearchType={setSearchType}></SearchConditionContainer>
                        <div className="apply-box">
                            <button onClick={() => searchConditionMapParsing(0)}>조회</button>
                        </div>
                    </SearchIn>
                </SearchLeft>
                <SearchRight>
                    <SearchIn>
                        {searchType == 4001 ? (
                            <>
                                <SearchCnt title={`시설 검색 결과`} searchCnt={searchCnt}></SearchCnt>
                                <SearchOfInfraContainer searchCnt={searchCnt} searchedInfras={infras}></SearchOfInfraContainer>
                                <SearchIteamPaging
                                    totalCnt={searchCnt}
                                    pagingSize={pagingSize}
                                    pagingCnt={pagingCnt}
                                    setPage={setPage}
                                    currentPageNum={page}
                                ></SearchIteamPaging>
                            </>
                        ) : (
                            <>
                                <SearchCnt title={`팀 검색 결과`} searchCnt={searchCnt}></SearchCnt>
                                <SearchOfTeamContainer searchCnt={searchCnt} searchedTeams={teams}></SearchOfTeamContainer>
                            </>
                        )}
                    </SearchIn>
                </SearchRight>
            </div>
        </>
    );
}

export default SearchPageContainer;
