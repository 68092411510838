import FavoritesInfraService from './favorities/FavoritesInfraService';
import FavoritesInfraServiceImpl from './favorities/impl/FavoritesInfraServiceImpl';
import UserService from './user/UserService';
import UserServiceImpl from './user/impl/UserServiceImpl';
import TmpRegistedTeamUserService from './team/TmpRegistedTeamUserService';
import TmpRegistedTeamUserServiceImpl from './team/impl/TmpRegistedTeamUserServiceImpl';
import VisitedTeamInfoService from './visitedInfo/VisitedTeamInfoService';
import VisitedTeamInfoServiceImpl from './visitedInfo/impl/VisitedTeamInfoServiceImpl';
import ReservationService from './reservation/ReservationService';
import ReservationServiceImpl from './reservation/impl/ReservationServiceImpl';
import TeamServiceImpl from './team/impl/TeamServiceImpl';
import TeamService from './team/TeamService';
import VisitedTeamInfoStatisticsService from './visitedInfo/VisitedTeamInfoStatisticsService';
import VisitedTeamInfoStatisticsServiceImpl from './visitedInfo/impl/VisitedTeamInfoStatisticsServiceImpl';
import ClubTeamInfoStatisticsService from './clubteam/ClubTeamInfoStatisticsService';
import ClubTeamInfoStatisticsServiceImpl from './clubteam/impl/ClubTeamInfoStatisticsServiceImpl';
import ClubTeamInfoService from './clubteam/ClubTeamInfoService';
import ClubTeamInfoServiceImpl from './clubteam/impl/ClubTeamInfoServiceImpl';
import KMCUserIdentifyService from './user/KMCUserIdentifyService';
import KMCUserIdentifyServiceImpl from './user/impl/KMCUserIdentifyServiceImpl';

export const FAVORITES_INFRA_SERVICE: FavoritesInfraService = new FavoritesInfraServiceImpl();
export const USER_SERVICE: UserService = new UserServiceImpl();
export const TMP_REGISTED_TEAM_USER_SERVICE: TmpRegistedTeamUserService = new TmpRegistedTeamUserServiceImpl();
export const VISITED_TEAM_INFO_SERVICE: VisitedTeamInfoService = new VisitedTeamInfoServiceImpl();
export const VISITED_TEAM_INFO_STATISTICS_SERVICE: VisitedTeamInfoStatisticsService = new VisitedTeamInfoStatisticsServiceImpl();
export const RESERVATION_SERVICE: ReservationService = new ReservationServiceImpl();
export const TEAM_SERVICE: TeamService = new TeamServiceImpl();
export const CLUB_TEAM_STATISTICS_SERVICE: ClubTeamInfoStatisticsService = new ClubTeamInfoStatisticsServiceImpl();
export const CLUB_TEAM_INFO_SERVICE: ClubTeamInfoService = new ClubTeamInfoServiceImpl();
export const KMC_USE_IDENTIFY_SERVICE: KMCUserIdentifyService = new KMCUserIdentifyServiceImpl();