import AbstractService from '../../common/service/AbstractRehabService';
import RehabService from '../RehabService';
import Booking from '../../domain/Booking';
import TargetPerson from '../../domain/TargetPerson';
import APIServerConfig from '../../../../config/APIServerConfig';
import Questionnaire from '../../domain/Questionnaire';
import Prescription from '../../domain/Prescription';
import Reservation from '../../../../domain/reservation/Reservation';

class RehabServiceImpl extends AbstractService implements RehabService {
    getBookingList(
        searching: { type: string; keyword: string },
        pagination: { size: number; page: number } | undefined,
    ): Promise<{ data: Booking[]; totalSize: number }> {
        let type = searching.type;
        let url = '/book/list';

        if (type !== '0') {
            url = '/book/list/search';
        }

        return new Promise((handler, reject) => {
            APIServerConfig.recordAPIAxios
                .post(
                    url,
                    JSON.stringify({
                        pageSize: pagination?.size,
                        pageIdx: pagination?.page,
                        type: searching.type,
                        keyword: searching.keyword,
                        reservationState: 1001,
                    }),
                    {
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    },
                )
                .then((response) => {
                    let booking: Booking[] = this.jsonConvert.deserializeArray(response.data.reservedList, Booking);
                    let totalSize: number = response.data.totalPage;
                    let data = { data: booking, totalSize: totalSize };

                    handler(data);
                });
        });
    }
    getManagingTargetPersons(
        searching: { type: string; keyword: string },
        pagination: { size: number; page: number } | undefined,
    ): Promise<{ data: TargetPerson[]; totalSize: number }> {
        let type = searching.type;
        let url = '/target/managingList';
        if (type !== '0') {
            url = '/target/managingList/search';
        }

        return new Promise((handler, reject) => {
            APIServerConfig.recordAPIAxios
                .post(
                    url,
                    JSON.stringify({ pageSize: pagination?.size, pageIdx: pagination?.page, type: searching.type, keyword: searching.keyword }),
                    {
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    },
                )
                .then((response) => {
                    let targetPersons: TargetPerson[] = this.jsonConvert.deserializeArray(response.data.targetList, TargetPerson);
                    let totalSize: number = response.data.totalPage;
                    let data = { data: targetPersons, totalSize: totalSize };
                    handler(data);
                });
        });
    }

    getTargetForCheckup(pagination: { size: number; page: number } | undefined): Promise<{ data: TargetPerson[]; totalSize: number }> {
        return new Promise((handler, reject) => {
            APIServerConfig.recordAPIAxios
                .post('/target/checkupList', JSON.stringify({ pageSize: pagination?.size, pageIdx: pagination?.page }), {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                .then((response) => {
                    let checkups: TargetPerson[] = this.jsonConvert.deserializeArray(response.data.targetList, TargetPerson);
                    let totalSize: number = response.data.totalPage;
                    let data = { data: checkups, totalSize: totalSize };
                    handler(data);
                });
        });
    }

    getTargetForQuestionnaires(pagination: { size: number; page: number } | undefined): Promise<{ data: TargetPerson[]; totalSize: number }> {
        return new Promise((handler, reject) => {
            APIServerConfig.recordAPIAxios
                .post('/target/quesList', JSON.stringify({ pageSize: pagination?.size, pageIdx: pagination?.page }), {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                .then((response) => {
                    let questionnaires: TargetPerson[] = this.jsonConvert.deserializeArray(response.data.targetList, TargetPerson);
                    let totalSize: number = response.data.totalPage;
                    let data = { data: questionnaires, totalSize: totalSize };
                    handler(data);
                });
        });
    }

    getTargetForRecording(pagination: { size: number; page: number } | undefined): Promise<{ data: TargetPerson[]; totalSize: number }> {
        return new Promise((handler, reject) => {
            APIServerConfig.recordAPIAxios
                .post('/target/recordingList', JSON.stringify({ pageSize: pagination?.size, pageIdx: pagination?.page }), {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                .then((response) => {
                    let targetPersons: TargetPerson[] = this.jsonConvert.deserializeArray(response.data.targetList, TargetPerson);
                    let totalSize: number = response.data.totalPage;
                    let data = { data: targetPersons, totalSize: totalSize };
                    handler(data);
                });
        });
    }

    getTargetForPrescription(pagination: { size: number; page: number } | undefined): Promise<{ data: TargetPerson[]; totalSize: number }> {
        return new Promise((handler, reject) => {
            APIServerConfig.recordAPIAxios
                .post('/target/prescriptionList', JSON.stringify({ pageSize: pagination?.size, pageIdx: pagination?.page }), {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                .then((response) => {
                    let targetPersons: TargetPerson[] = this.jsonConvert.deserializeArray(response.data.targetList, TargetPerson);
                    let totalSize: number = response.data.totalPage;
                    let data = { data: targetPersons, totalSize: totalSize };
                    handler(data);
                });
        });
    }

    manageRecordingStepForTarget(targetNo: number, step: string): Promise<TargetPerson> {
        return new Promise((handler, reject) => {
            APIServerConfig.recordAPIAxios
                .post(
                    '/target/recordingStep',
                    JSON.stringify({
                        targetNo: targetNo,
                        recordingStep: step,
                    }),
                    {
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    },
                )
                .then((response) => {
                    let targetPerson: TargetPerson = this.jsonConvert.deserializeObject(response.data.targetPerson, TargetPerson);
                    handler(targetPerson);
                });
        });
    }

    bookApproval(reservationNo: string, comment: string): Promise<TargetPerson> {
        return new Promise((handler, reject) => {
            APIServerConfig.recordAPIAxios
                .post('/book/approval', JSON.stringify({ reservationNo: reservationNo, comment: comment }), {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                .then((response) => {
                    let booking: TargetPerson = this.jsonConvert.deserializeObject(response.data.targetPerson, TargetPerson);
                    handler(booking);
                });
        });
    }
    bookCancellation(reservationNo: string, comment: string): Promise<Reservation> {
        return new Promise((handler, reject) => {
            APIServerConfig.recordAPIAxios
                .post('/book/cancellation', JSON.stringify({ reservationNo: reservationNo, comment: comment }), {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                .then((response) => {
                    let booking: Reservation = this.jsonConvert.deserializeObject(response.data.targetPerson, Reservation);
                    handler(booking);
                });
        });
    }
    deletingBook(reservationNo: string, comment: string): Promise<TargetPerson> {
        return new Promise((handler, reject) => {
            APIServerConfig.recordAPIAxios
                .post('/book/deleting', JSON.stringify({ reservationNo: reservationNo, comment: comment }), {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                .then((response) => {
                    let booking: TargetPerson = this.jsonConvert.deserializeObject(response.data.targetPerson, TargetPerson);
                    handler(booking);
                });
        });
    }

    bookApprovals(reservationNos: string[]): Promise<TargetPerson[]> {
        return new Promise((handler, reject) => {
            APIServerConfig.recordAPIAxios
                .post('/book/approvals', JSON.stringify({ reservationNos: reservationNos }), {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                .then((response) => {
                    let booking: TargetPerson[] = this.jsonConvert.deserializeArray(response.data.targetPerson, TargetPerson);
                    handler(booking);
                });
        });
    }
    bookCancellations(reservationNos: string[]): Promise<Reservation[]> {
        return new Promise((handler, reject) => {
            APIServerConfig.recordAPIAxios
                .post('/book/cancellations', JSON.stringify({ reservationNos: reservationNos }), {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                .then((response) => {
                    let booking: Reservation[] = this.jsonConvert.deserializeArray(response.data.targetPerson, Reservation);
                    handler(booking);
                });
        });
    }

    getQuestionnairesNAnswer(targetNo: number, reservationNo?: string, domain?: string): Promise<Questionnaire[]> {
        return new Promise((handler, reject) => {
            APIServerConfig.recordAPIAxios
                .post('/questionnaire/QuestNAnswer', JSON.stringify({ domain: domain, targetNo: targetNo, reservationNo: reservationNo }), {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                .then((response) => {
                    handler(response.data);
                });
        });
    }

    sendQuestionnaireToTargetNo(targetNo: number, quesNo: number, reservationNo?: string, domain?: string): Promise<Questionnaire[]> {
        return new Promise((handler, reject) => {
            APIServerConfig.recordAPIAxios
                .post('/questionnaire/send', JSON.stringify({ domain: domain, quesNo: quesNo, targetNo: targetNo, reservationNo: reservationNo }), {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                .then((response) => {
                    handler(response.data);
                });
        });
    }
    cancelQuestionnaireToTargetNo(answerNo: number, targetNo?: number, reservationNo?: string, domain?: string): Promise<Questionnaire[]> {
        return new Promise((handler, reject) => {
            APIServerConfig.recordAPIAxios
                .post(
                    '/questionnaire/cancel',
                    JSON.stringify({ answerNo: answerNo, targetNo: targetNo, reservationNo: reservationNo, domain: domain }),
                    {
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    },
                )
                .then((response) => {
                    handler(response.data);
                });
        });
    }

    addtionalRehabForTarget(targetNo: number): Promise<TargetPerson> {
        return new Promise((handler, reject) => {
            APIServerConfig.recordAPIAxios
                .post('/target/restart', JSON.stringify({ targetNo: targetNo }), {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                .then((response) => {
                    handler(response.data);
                });
        });
    }
    reduceRehabForTarget(targetNo: number): Promise<TargetPerson> {
        return new Promise((handler, reject) => {
            APIServerConfig.recordAPIAxios
                .post('/target/cancelAdditional', JSON.stringify({ targetNo: targetNo }), {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                .then((response) => {
                    handler(response.data);
                });
        });
    }
}

export default RehabServiceImpl;
