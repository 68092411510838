import React from 'react';

interface SearchRightProps {
    children?: React.ReactNode;
}

function SearchRight({children}: SearchRightProps) {
    return (
        <section className="search-wrap search-right">
            {children}
        </section>
    )
}

export default SearchRight;