import React from 'react';
import { Link } from 'react-router-dom';
import Infra from '../../domain/infra/Infra';

interface InfraCardProps {
    infra: Infra;
}

function InfraCard(props: InfraCardProps) {
    return (
        // <Link to={"/trainning/infra/" + props.infra.infraNo}>
        <Link to={props.infra.getDetailLink()}>
            <li>
                <div><img src={props.infra.getMainAttachFile()}/></div>
                <div className="">
                    <p className="room-name">{props.infra.name}</p>
                    <p className="room-address">{props.infra.address}</p>
                </div>
            </li>
        </Link>
    )
}

export default InfraCard;