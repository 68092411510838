import React from 'react';
import SpecialPhysical from '../../domain/recording/SpecialPhysical';
interface IProps {
    data?: SpecialPhysical;
}
export default function TreadMillReporting(props: IProps) {
    return (
        <>
            <table className="reporting-table" cellPadding={0} cellSpacing={0}>
                <colgroup>
                    <col style={{ width: '20%' }} />
                    <col style={{ width: '20%' }} />
                    <col style={{ width: '20%' }} />
                    <col style={{ width: '20%' }} />
                    <col style={{ width: '20%' }} />
                </colgroup>
                <tbody>
                    <tr>
                        <th>VO2/kg</th>
                        <th>METS</th>
                        <th>RQ</th>
                        <th>VE(L/min)</th>
                        <th>VT(btps)</th>
                    </tr>
                    <tr>
                        <td>{props.data?.treadMillTest.vo2}</td>
                        <td>{props.data?.treadMillTest.mets}</td>
                        <td>{props.data?.treadMillTest.rq}</td>
                        <td>{props.data?.treadMillTest.ve}</td>
                        <td>{props.data?.treadMillTest.vt}</td>
                    </tr>
                </tbody>
            </table>
            <table className="eval-table" cellPadding={0} cellSpacing={0}>
                <tbody>
                    <tr>
                        <td>
                            <span style={{ fontWeight: 700, fontSize: '15px' }}>* 평가</span>
                            <br />
                        </td>
                    </tr>
                </tbody>
            </table>
        </>
    );
}
