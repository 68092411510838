enum Role {
    SUPER_ADMIN="SUPER_ADMIN",
    SEMI_ADMIN="SEMI_ADMIN",
    TEAM_OPER="TEAM_OPER",
    TEAM_PLAYER="TEAM_PLAYER",
    TEAM_AGENT="TEAM_AGENT",
    ROLE_USER="ROLE_USER",
    CENTER_OPER="CENTER_OPER",
    CENTER_RECORDER="CENTER_RECORDER",
    MUNICIPALITY_OPER="MUNICIPALITY_OPER",
    ANOMYOUS="ANOMYOUS"
}

export default Role;