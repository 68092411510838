import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import ReactDOM from 'react-dom';
import { forEach, isArray, last } from 'lodash';
import '../assets/css/center.css';
import '../assets/css/animate.min.css';
import Layout from '../layouts/Layout';
import Header from '../layouts/Header';
import SideBar from '../layouts/SideBar';
import RehabServiceImpl from '../service/impl/RehabServiceImpl';

import { bookingStatus } from './components/StatusUtils';
import pagination from './components/PaginationComponent';
import RehabHistoryServiceImpl from '../service/impl/RehabHistoryServiceImpl';

import UserDetailInfo from '../../../domain/user/UserDetailInfo';
import TargetPerson from '../domain/TargetPerson';
import { confirmAlert } from 'react-confirm-alert';
import RehabTargetHistory from '../domain/RehabTargetHistory';
import MngReservationComponent from '../components/MngReservationComponent';
import Reservation from '../../../domain/reservation/Reservation';
import { isObject } from 'underscore';

const rehabService = new RehabServiceImpl();
const menuTitle: string = '* 예약자 상세정보';
const navTitle: string = '예약자 상세정보';
const rehabHistoryService = new RehabHistoryServiceImpl();

export default function ReservatorDetailPage(props: any) {
    let path = props.location.pathname;
    const history = useHistory();
    const location = useLocation();

    const [isFirst, setIsFirst] = useState<boolean>(false);
    const [booking, setBooking] = useState<any>(location.state);

    const [openPopup, setOpenPopup] = useState<any>({ display: 'none' });
    const [contrPopup, setContrPopup] = useState<string>('center-question-popup wow  animate__animated animate__fadeInUp');
    const [bookStateComment, setBookStateComment] = useState<any>();
    const [bookingStatusId, setBookingStatusId] = useState<any>();
    const [status, setStatus] = useState<any>();

    const [rehabProgramCnt, setRehabProgramCnt] = useState<number>(0);
    const [conditioningCnt, setConditioningCnt] = useState<number>(0);
    const [physicalCnt, setPhysicalCnt] = useState<number>(0);
    const [prescriptionCnt, setPrescriptionCnt] = useState<number>(0);
    const [latestHistory, setLatestHistory] = useState<RehabTargetHistory>();

    //const reservatorState = location.state;
    let teamNo = booking.teamNo;
    let reservationNo = booking.reservationNo;

    useEffect(() => {
        let userNo: string = booking.reservaterNo;
        setBookStateComment(booking.comment ? booking.comment : '');
        setBookingStatusId(booking.reservationStateCodeId);

        rehabHistoryService.getRehabHistoryForUserno(userNo).then((history: RehabTargetHistory[]) => {
            let size = history.length;
            if (size === 0) {
                setIsFirst(true);
            } else {
                setLatestHistory(last(history));
                makePrescriptionHistory(history);
            }
        });
    }, []);

    useEffect(() => {
        let status = bookingStatus(booking.reservationStatusCode, bookingStatusId);
        setStatus(status);
    }, [bookingStatusId]);

    const makePrescriptionHistory = (history: RehabTargetHistory[]) => {
        let prescriptionHistory = [];
        let prescription: {
            basalPhysical: any;
            specialPhysical: any;
            basalPrescription: any;
            conditioning: any;
            bodyPrescription: any;
            itemPrescription: any;
            opinions: any;
        };
        let physiCnt: number = 0;
        let prescrCnt: number = 0;
        let conditionCnt: number = 0;

        for (let i = 0; i < history.length; i++) {
            prescription = {
                basalPhysical: null,
                specialPhysical: null,
                basalPrescription: null,
                conditioning: null,
                bodyPrescription: null,
                itemPrescription: null,
                opinions: null,
            };

            let item: RehabTargetHistory = history[i];

            if (item.basalPhysical != '') {
                prescription.basalPhysical = JSON.parse(item.basalPhysical);
                if (isIncludedValue(prescription.basalPhysical)) physiCnt++;
            }
            if (item.specialPhysical != '') {
                prescription.specialPhysical = JSON.parse(item.specialPhysical);
                if (isIncludedValue(prescription.specialPhysical)) physiCnt++;
            }

            if (item.basalExercisePrescription != '') {
                prescription.basalPrescription = JSON.parse(item.basalExercisePrescription);
                if (isIncludedValue(prescription.basalPrescription)) prescrCnt++;
            }
            if (item.bodyPrescription != '') {
                prescription.bodyPrescription = JSON.parse(item.bodyPrescription);
                if (isIncludedValue(prescription.bodyPrescription)) prescrCnt++;
            }
            if (item.itemPrescription != '') {
                prescription.itemPrescription = JSON.parse(item.itemPrescription);
                if (isIncludedValue(prescription.itemPrescription)) prescrCnt++;
            }

            if (item.conditioningPrescription != '') {
                prescription.conditioning = JSON.parse(item.conditioningPrescription);
                if (isIncludedValue(prescription.conditioning)) conditionCnt++;
            }
            if (item.opinions != '') prescription.opinions = JSON.parse(item.opinions);

            prescriptionHistory.push(prescription);
        }
        setPhysicalCnt(physiCnt);
        setPrescriptionCnt(prescrCnt);
        setConditioningCnt(conditionCnt);
        setRehabProgramCnt(0);

        return prescriptionHistory;
    };

    const isIncludedValue = (target: any) => {
        let isInValue = false;
        forEach(target, function (item, key) {
            if (isArray(item) || isObject(item)) {
                isInValue = isIncludedValue(item);
            } else {
                isInValue = item != '' && item != null;
            }
            if (isInValue) return false;
        });

        return isInValue;
    };

    const goBack = () => {
        history.replace({
            pathname: '/rehab/reserved',
            state: booking,
        });
    };

    const openPopupMngReservation = () => {
        let isDisplay = { display: 'block' };
        let fadeInStyle = 'center-question-popup wow  animate__animated animate__fadeInUp';
        setOpenPopup(isDisplay);
        setContrPopup(fadeInStyle);
    };
    const closePopupForMngReservation = () => {
        let isNotDisplay = { display: 'none' };
        let fadeOutStyle = 'center-question-popup wow  animate__animated animate__fadeOutDown';
        setContrPopup(fadeOutStyle);
        setOpenPopup(isNotDisplay);
        setBookStateComment(booking.comment);
    };

    const bookingHandler = (action: any) => {
        if (action === 'approval') {
            approvalHandler();
        } else if (action === 'cancellation') {
            cancellationHandler();
        } else if (action === 'completation') {
            completionHandler();
        }
    };

    const alertHandler = (title: string, message: string, handler: () => void) => {
        confirmAlert({
            title: title,
            message: message,
            buttons: [
                {
                    label: '확인',
                    onClick: handler,
                },
            ],
        });
    };

    const approvalHandler = () => {
        rehabService.bookApproval(reservationNo, bookStateComment).then((reservedResult: TargetPerson) => {
            if (reservedResult != null) {
                let reservaterName = reservedResult.reservater?.name;
                let reservationStateId = reservedResult.reservation?.reservationStateCodeId;
                let message = '승인이 완료되었습니다.';
                if (reservaterName) message += ' <검진관리> 메뉴에서 [' + reservaterName + '] 님 확인 가능합니다.';
                setBookingStatusId(reservationStateId);
                alertHandler('승인성공', message, goBack);
            } else {
                alertHandler('승인 실패', '검진 진행 중인 동일 계정 사용자가 있습니다. 확인하여 주십시오', () => {});
            }
        });
    };

    const cancellationHandler = () => {
        rehabService.bookCancellation(reservationNo, bookStateComment).then((reservedResult: Reservation) => {
            if (reservedResult != null) {
                alertHandler('예약 취소 성공', '예약이 취소 되었습니다.', goBack);
            } else {
                alertHandler('예약 취소 실패', '검진 진행 중인 계정입니다. 확인하여 주십시오.', () => {});
            }
        });
    };

    const completionHandler = () => {
        rehabService.deletingBook(reservationNo, bookStateComment).then((reservedResult: TargetPerson) => {
            alertHandler('예약 완료 성공', '예약 건의 검진이 완료 되었습니다.', goBack);
        });
    };

    let btnSet = (
        <>
            <button
                className="btn btn-right btn-warning"
                onClick={() => {
                    bookingHandler('cancellation');
                }}
            >
                예약취소
            </button>
            <button
                className="btn btn-right"
                onClick={() => {
                    bookingHandler('approval');
                }}
            >
                승인
            </button>
            <button className="btn btn-right btn-success" onClick={openPopupMngReservation}>
                예약변경
            </button>
            <button className="btn btn-right btn-info" onClick={goBack}>
                돌아가기
            </button>
        </>
    );

    let summaryInfo = (
        <>
            <div>
                <table className="center-list-table" cellPadding="0" cellSpacing="0">
                    <colgroup>
                        <col style={{ width: '10%' }} />
                        <col style={{ width: '40%' }} />
                        <col style={{ width: '10%' }} />
                        <col style={{ width: '40%' }} />
                    </colgroup>
                    <tbody>
                        <tr>
                            <td colSpan={4} style={{ backgroundColor: '#f1f5f8', textAlign: 'left' }}>
                                <span style={{ width: '88%', fontSize: '16px', verticalAlign: 'middle' }}>* 검진대상자 정보 요약</span>
                            </td>
                        </tr>
                        <tr>
                            <td style={{ backgroundColor: '#5c5c5c', color: '#fff', fontWeight: 900 }}>이름</td>
                            <td>{booking.reservater.name}</td>
                            <td style={{ backgroundColor: '#5c5c5c', color: '#fff', fontWeight: 900 }}>소속 팀</td>
                            <td>{booking.reservater.teamName}</td>
                        </tr>
                        <tr>
                            <td style={{ backgroundColor: '#5c5c5c', color: '#fff', fontWeight: 900 }}>종목</td>
                            <td>{booking.reservater.sportCode.name}</td>
                            <td style={{ backgroundColor: '#5c5c5c', color: '#fff', fontWeight: 900 }}>성별</td>
                            <td>{booking.reservater.genderCode.name}</td>
                        </tr>
                        <tr>
                            <td style={{ backgroundColor: '#5c5c5c', color: '#fff', fontWeight: 900 }}>예약일</td>
                            <td style={{ fontWeight: 900, color: '#166ec7' }}>{booking.startDate}</td>
                            <td style={{ backgroundColor: '#5c5c5c', color: '#fff', fontWeight: 900 }}>예약상태</td>
                            <td>{status}</td>
                        </tr>
                        <tr>
                            <td style={{ backgroundColor: '#5c5c5c', color: '#fff', fontWeight: 900 }}>예약센터</td>
                            <td colSpan={3}>{booking.infra.name}</td>
                            {/* <td style={{ backgroundColor: '#5c5c5c', color: '#fff', fontWeight: 900 }}>지난최근예약센터</td>
                            <td>
                                {isFirst ? (
                                    <span className="center-span-yes">첫 방문</span>
                                ) : (
                                    <>
                                        {latestHistory?.reservation?.startDate} / {latestHistory?.reservation?.infra?.name}
                                    </>
                                )}
                            </td> */}
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    );

    let latestInfos = (
        <>
            <div>
                <table className="center-list-table" cellPadding="0" cellSpacing="0">
                    <colgroup>
                        <col style={{ width: '10%' }} />
                        <col style={{ width: '40%' }} />
                        <col style={{ width: '10%' }} />
                        <col style={{ width: '40%' }} />
                    </colgroup>
                    <tbody>
                        <tr>
                            <td colSpan={4} style={{ backgroundColor: '#f1f5f8' }}>
                                <div style={{ width: '88%', fontSize: '16px', textAlign: 'left' }}>* 최근 검진 정보 요약</div>
                            </td>
                        </tr>
                        <tr>
                            <td style={{ backgroundColor: '#5c5c5c', color: '#fff', fontWeight: 900 }}> 최근 검진 내용</td>
                            <td colSpan={3}></td>
                        </tr>
                        <tr>
                            <td style={{ backgroundColor: '#5c5c5c', color: '#fff', fontWeight: 900 }}>재활프로그램</td>
                            <td>{rehabProgramCnt} 회</td>
                            <td style={{ backgroundColor: '#5c5c5c', color: '#fff', fontWeight: 900 }}>컨디셔닝</td>
                            <td>{conditioningCnt} 회</td>
                        </tr>
                        <tr>
                            <td style={{ backgroundColor: '#5c5c5c', color: '#fff', fontWeight: 900 }}>체력측정</td>
                            <td>{physicalCnt} 회</td>
                            <td style={{ backgroundColor: '#5c5c5c', color: '#fff', fontWeight: 900 }}>운동처방</td>
                            <td>{prescriptionCnt} 회</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    );
    const byteCheck = (txt: string) => {
        var codeByte = 0;
        let maxByte = 2000;
        let isValid = true;
        for (var i = 0; i < txt.length; i++) {
            var oneChar: any = txt.charAt(i);
            if (oneChar.length == 1) {
                codeByte++;
            } else if (oneChar.indexOf('%u') != -1) {
                codeByte += 2;
            } else if (oneChar.indexOf('%') != -1) {
                codeByte++;
            }
        }
        if (codeByte > maxByte) {
            confirmAlert({
                title: '경고',
                message: '제한 길이보다 깁니다. 글자수를 확인해 주십시오',
                buttons: [{ label: '확인', onClick: () => {} }],
            });
            isValid = false;
        }

        return isValid;
    };

    return (
        <>
            <Layout header={<Header></Header>} sideBar={<SideBar name={path}></SideBar>} path={path} naviTitle={navTitle} menuTitle={menuTitle}>
                {btnSet}
                {summaryInfo}
                {latestInfos}
                <div style={{ margin: '10px 0px 5px 0px' }}>
                    * 예약 Comment <span style={{ fontSize: '8px', color: '#f00' }}>(최대2000자)</span>
                </div>
                <textarea
                    rows={5}
                    style={{ width: '100%' }}
                    value={bookStateComment}
                    className="meas"
                    onChange={(e: any) => {
                        let value = e.target.value;
                        if (byteCheck(value)) setBookStateComment(value);
                    }}
                ></textarea>
                <div id="center-popup-wrap" style={openPopup}>
                    <div className={contrPopup} data-wow-delay="0.2s" style={{ minHeight: '400px', height: '500px' }}>
                        <MngReservationComponent
                            actionType="modify"
                            reservation={booking}
                            closingHandler={closePopupForMngReservation}
                        ></MngReservationComponent>
                    </div>
                </div>
            </Layout>
        </>
    );
}
