import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useHistory } from 'react-router-dom';

import '../assets/css/center.css';
import '../assets/css/animate.min.css';

import Layout from '../layouts/Layout';
import Header from '../layouts/Header';
import SideBar from '../layouts/SideBar';

import QuestSheetPopup from './components/questionnaires/MngQuestionnaireSheetComponent';

import RehabServiceImpl from '../service/impl/RehabServiceImpl';
import PaginationComponent from './components/PaginationComponent';
import TargetPerson from '../domain/TargetPerson';
import UserDetailInfo from '../../../domain/user/UserDetailInfo';
import Booking from '../domain/Booking';
import RehabHistoryService from '../service/RehabHistoryService';
import Team from '../../../domain/team/Team';
import RehabService from '../service/RehabService';

const menuTitle: string = '* 검진 대상자 관리 목록';
const title: string = '검진 대상자 목록';

const rehabService: RehabService = new RehabServiceImpl();

export default function CheckUpTargetList(props: any) {
    const history = useHistory();

    let path = props.location.pathname;
    let centerKey = props.match.params.centerId;

    const [targetInfos, setTargetInfos] = useState<TargetPerson[]>();
    const [retrieveKeyword, setRetrieveKeyword] = useState<{ type: string; keyword: string }>({ type: 'name', keyword: '' });

    const [totalSize, setTotalSize] = useState(1);
    const [displaySize, setDisplaySize] = useState(10);
    const [currPage, setCurrPage] = useState(0);

    const [openPopup, setOpenPopup] = useState<any>({ display: 'none' });
    const [contrPopup, setContrPopup] = useState<string>('center-question-popup wow  animate__animated animate__fadeInUp');
    const [questPopup, setQuestPopup] = useState<any>(<></>);

    const openGeneralQ = (targetNo: number, questType: string) => {
        let isDisplay = { display: 'block' };
        let fadeInStyle = 'center-question-popup wow  animate__animated animate__fadeInUp';
        setOpenPopup(isDisplay);
        setContrPopup(fadeInStyle);
    };
    const closeGeneralQ = () => {
        let isNotDisplay = { display: 'none' };
        let fadeOutStyle = 'center-question-popup wow  animate__animated animate__fadeOutDown';
        setContrPopup(fadeOutStyle);
        setOpenPopup(isNotDisplay);
    };

    useEffect(() => {
        let pagination = { size: 10, page: 0 };
        rehabService.getTargetForCheckup(pagination).then((targetInfos: { data: TargetPerson[]; totalSize: number }) => {
            setTargetInfos(targetInfos.data);
            setTotalSize(targetInfos.totalSize);
        });
    }, []);

    const retrieveHandler = () => {
        setCurrPage(0);
        setDisplaySize(10);
        let pagination = { size: 10, page: 0 };
        rehabService.getTargetForCheckup(pagination).then((targetInfos: { data: TargetPerson[]; totalSize: number }) => {
            setTargetInfos(targetInfos.data);
            setTotalSize(targetInfos.totalSize);
        });
    };

    const pagingHandler = (pagination: { size: number; page: number }) => {
        setCurrPage(pagination.page);
        setDisplaySize(pagination.size);
        rehabService.getTargetForCheckup(pagination).then((targetInfos: { data: TargetPerson[]; totalSize: number }) => {
            setTargetInfos(targetInfos.data);
            setTotalSize(targetInfos.totalSize);
        });
    };

    const changeKeywordHandler = (e: any) => {
        let key = e.target.id;
        let inputVal = e.target.value;

        if (key === 'selectKeywordType') {
            if (inputVal === 'status') {
                setRetrieveKeyword((prevState) => {
                    return { ...prevState, ['type']: inputVal, ['keyword']: '0' };
                });
            } else if (inputVal === 'centers') {
                setRetrieveKeyword((prevState) => {
                    return { ...prevState, ['type']: inputVal, ['keyword']: '0' };
                });
            } else {
                setRetrieveKeyword((prevState) => {
                    return { ...prevState, ['type']: inputVal, ['keyword']: '' };
                });
            }
        } else if (key === 'inputKeyword') {
            setRetrieveKeyword((prevState) => {
                return { ...prevState, ['keyword']: inputVal };
            });
        } else if (key === 'selectKeyword') {
            // setRetrieveKeyword((prevState) => {
            //     let selecting = { ...prevState, ['keyword']: inputVal };
            //     selectingHandler(selecting);
            //     return selecting;
            // });
        } else if (key === 'selectCenter') {
            // setRetrieveKeyword((prevState) => {
            //     let selecting = { ...prevState, ['keyword']: inputVal };
            //     selectingHandler(selecting);
            //     return selecting;
            // });
        }
    };

    const keyEventHandler = (e: any) => {
        if (e.key == 'Enter') {
            retrieveHandler();
        }
    };

    const filters = () => {
        return (
            <div className="center-btn-right350">
                <div className="center-select-box">
                    {/* <label># 검색: </label>
                    <select id="selectKeywordType" className="center-select" value={retrieveKeyword.type} onChange={changeKeywordHandler}>
                        <option value="0">전체</option>
                        <option value="name">이름</option>
                        <option value="belongTeam">소속팀</option>
                        <option value="type">운동종목</option>
                        <option value="status">대상자상태</option>
                        <option value="centers">검진센터</option>
                    </select>
                    <div id="keyword-input" className="center-search">
                        <input
                            id="inputKeyword"
                            className="center-input"
                            placeholder="검색어를 입력하세요"
                            onChange={changeKeywordHandler}
                            onKeyPress={keyEventHandler}
                            value={retrieveKeyword.keyword}
                            style={{ border: '1px solid #e3e3e3' }}
                        />
                        <button className="btn" onClick={retrieveHandler} style={{ marginLeft: '5px', border: '1px solid #e3e3e3' }}>
                            <i className="fas fa-search"></i>
                        </button>
                    </div>
                    <div id="keyword-select" className="center-search" style={{ display: 'none' }}>
                        <select id="selectKeyword" className="center-reserved-select" value={retrieveKeyword.keyword} onChange={changeKeywordHandler}>
                            <option value="0">대상자상태를 선택해주세요</option>
                            <option value="8001">상담대상자</option>
                            <option value="8002">측정대상자</option>
                            <option value="8003">처방대상자</option>
                            <option value="8004">처방완료자</option>
                        </select>
                    </div>
                    <div id="centers-select" className="center-search" style={{ display: 'none' }}>
                        <select id="selectCenter" className="center-reserved-select" value={retrieveKeyword.keyword} onChange={changeKeywordHandler}>
                            <option value="0">예약센터를선택해주세요</option>
                            <option value="center_1">마산대센터</option>
                            <option value="center_2">의령센터</option>
                            <option value="center_3">고성센터</option>
                        </select>
                    </div> */}
                </div>
            </div>
        );
    };

    const popups = () => {
        return (
            <>
                <div id="center-popup-wrap"></div>
                <div id="center-check-wrap"></div>
            </>
        );
    };

    const goDetailHandler = (targetInfo: TargetPerson) => {
        history.push({
            pathname: 'checkedup/detail',
            state: targetInfo,
        });
    };

    const goInputDetailHandler = (status: any) => {
        history.push({
            pathname: '/rehab/checkedup/mngdata/physical',
            state: status,
        });
    };

    const closingHandler = () => {
        let pagination = { size: displaySize, page: currPage };
        rehabService.getTargetForQuestionnaires(pagination).then((targetPersons: { data: TargetPerson[]; totalSize: number }) => {
            setTargetInfos(targetPersons.data);
            setTotalSize(targetPersons.totalSize);
        });
    };

    const trElems = (list: TargetPerson[] | undefined) => {
        let trElems = <> </>;
        let page = currPage ? currPage : 0;
        let pageSize = displaySize ? displaySize : 0;
        let rowNum = page * pageSize;

        if (typeof list != 'undefined' && list.length > 0) {
            trElems = (
                <>
                    {list?.map((targetInfo: TargetPerson, idx) => {
                        let reservater: UserDetailInfo | undefined = targetInfo.reservater;
                        let reservation: Booking | undefined = targetInfo.reservation;
                        let team: Team | undefined = targetInfo.team;
                        let infraInfo: any = targetInfo.infra;
                        let booking: string | undefined = reservation?.startDate;
                        let timeArr: string[] | undefined = booking?.split(' ');
                        let bookingDate = '';
                        let bookingTime = '';

                        if (timeArr) {
                            bookingDate = timeArr[0];
                            bookingTime = timeArr[1];
                        }

                        return (
                            <tr key={idx}>
                                <td>{targetInfo.targetNo}</td>
                                <td
                                    onClick={() => {
                                        goDetailHandler(targetInfo);
                                    }}
                                    style={{ cursor: 'pointer' }}
                                >
                                    {reservater?.name}
                                </td>
                                <td>{team?.name}</td>
                                <td>{bookingDate}</td>
                                <td>{bookingTime}</td>
                                <td>{targetInfo.targetStateCode?.name}</td>
                                {/* <td>
                                    <button
                                        className="btn btn-danger"
                                        onClick={() => {
                                            goInputDetailHandler(targetInfo);
                                        }}
                                        id="checkCondition"
                                    >
                                        검진기록입력
                                    </button>
                                </td> */}
                                {/* <td>
                                    <button
                                        className="btn btn-letter"
                                        onClick={() => {
                                            openGeneralQ(targetInfo.targetNo, 'general');
                                        }}
                                    >
                                        일반설문
                                    </button>
                                    <button
                                        className="btn btn-letter"
                                        onClick={() => {
                                            openGeneralQ(targetInfo.targetNo, 'phy');
                                        }}
                                    >
                                        심리상담설문
                                    </button>
                                </td> */}
                                <td>
                                    <button
                                        className="btn btn-letter"
                                        onClick={() => {
                                            goDetailHandler(targetInfo);
                                        }}
                                    >
                                        상세보기
                                    </button>
                                </td>
                                {/* <td>0회</td> */}
                                <td>{infraInfo.name}</td>
                            </tr>
                        );
                    })}
                </>
            );
        } else {
            trElems = (
                <>
                    <tr>
                        <td colSpan={9}>검진 대상자 조회 중...</td>
                    </tr>
                </>
            );
        }

        return trElems;
    };

    return (
        <Layout
            header={<Header></Header>}
            sideBar={<SideBar name={path}></SideBar>}
            path={path}
            naviTitle={title}
            menuTitle={menuTitle}
            filter={filters()}
            pagination={PaginationComponent(pagingHandler, totalSize, currPage, displaySize)}
            popups={popups()}
        >
            <table className="center-list-table" cellPadding="0" cellSpacing="0">
                <colgroup>
                    <col style={{ width: '10%' }} />
                    <col style={{ width: '10%' }} />
                    <col style={{ width: '10%' }} />
                    <col style={{ width: '10%' }} />
                    <col style={{ width: '10%' }} />
                    <col style={{ width: '10%' }} />
                    <col style={{ width: '10%' }} />
                    {/* <col style={{ width: '10%' }} /> */}
                    <col style={{ width: '20%' }} />
                </colgroup>
                <thead>
                    <tr>
                        <td>검진자번호</td>
                        <td>이름</td>
                        <td>소속팀</td>
                        <td>예약일</td>
                        <td>예약시간</td>
                        <td>검진상세보기</td>
                        {/* <td>검진관리</td> */}
                        {/* <td>설문관리</td> */}
                        <td>상세보기</td>
                        {/* <td>재활횟차</td> */}
                        <td>검진진행센터</td>
                    </tr>
                </thead>
                <tbody>{trElems(targetInfos)}</tbody>
            </table>

            <div id="center-popup-wrap" style={openPopup}>
                <div className={contrPopup} data-wow-delay="0.2s">
                    <QuestSheetPopup targetPerson={new TargetPerson()} closingHandler={closeGeneralQ}></QuestSheetPopup>
                </div>
            </div>
        </Layout>
    );
}
