import React, { useState } from 'react';
import SportTouringTab from './tab/SportTouringTab';
import MobileUseTabe from './tab/MobileUseTab';
import CenterIntruduceTab from './tab/CenterIntruduceTab';
import MyPageMainLayout from '../../../layout/MyPageMainLayout';
import SubMenuNav from '../../../nav/SubMenuNav';
import UserPageSubMenuNav from '../nav/UserPageSubMenuNav';
import MyPageRightContent from '../../../layout/MyPageRightContent';

const tabPage = [
    <SportTouringTab/>,
    <MobileUseTabe/>,
    <CenterIntruduceTab/>
]

function UserMyPageGuideContainer() {
    const [tabSeq, setTabSeq] = useState(0);
    return (
        <>
            <MyPageMainLayout>
                <MyPageRightContent contentTitle={"서비스 가이드"}>
                    <div className="my-in">
                        <div className="container3">
                            <ul className="tabs three">
                                <li onClick={() => setTabSeq(0)} className={tabSeq == 0? 'active': ''}><a>스포츠투어링</a></li>
                                <li onClick={() => setTabSeq(1)} className={tabSeq == 1? 'active': ''}><a>모바일 이용</a></li>
                                <li onClick={() => setTabSeq(2)} className={tabSeq == 2? 'active': ''}><a>운영센터 소개</a></li>
                            </ul>
                            <div className="tab_container">
                                {tabPage[tabSeq]}
                            </div>				
                        </div>
                    </div>
                </MyPageRightContent>
            </MyPageMainLayout>
        </>
    )
}

export default UserMyPageGuideContainer;