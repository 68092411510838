import React from 'react';
import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('QnA')
export default class QnA {
    @JsonProperty('quesNo')
    quesNo?: number;
    @JsonProperty('targetNo')
    targetNo?: number;
    @JsonProperty('answerNo')
    answerNo?: number;
    @JsonProperty('displayTitle')
    displayTitle?: string;
    @JsonProperty('questionnaireDomain')
    questionnaireDomain?: string;
    @JsonProperty('title')
    title?: string;
    @JsonProperty('description')
    description?: string;
    @JsonProperty('questions')
    questions?: string;
    @JsonProperty('answer')
    answer?: string;
    @JsonProperty('answerDate')
    answerDate?: Date;
    @JsonProperty('answerModDate')
    answerModDate?: Date;
    isSend: boolean = false;
    @JsonProperty('questionnaireKey')
    questionnaireKey: string = '';
    @JsonProperty('reservationNo')
    reservationNo: string = '';
}
