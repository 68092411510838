import { JsonObject, JsonProperty } from 'json2typescript';
import ParentInfraCategory from './ParentInfraCategory';
import Code from '../code/Code';

@JsonObject("infraCategory")
class InfraCategory {
    @JsonProperty("infraCategoryNo")
    infraCategoryNo: number = 0;
    @JsonProperty("parentInfraCategoryNo")
    parentInfraCategoryNo: number = 0;
    @JsonProperty("parentInfraCategory")
    parentInfraCategory: ParentInfraCategory = new ParentInfraCategory();
    @JsonProperty("name")
    name: string = '';
    @JsonProperty("iconClassName")
    iconClassName?: string;
}

export default InfraCategory;