import { Redirect } from 'react-router-dom';
import URLParamMakerUtils from '../../../utils/URLParamMakeUtils';
interface SignUpRedirectPageProps {
    location: any;
}

function SignUpRedirectPage(props: SignUpRedirectPageProps) {
    const userName = URLParamMakerUtils.getUrlParameter('userName', props.location);

    return (
        <Redirect
            to={{
                pathname: '/signup',
                state: {
                    userName: userName
                }
            }}
        />
    )
}

export default SignUpRedirectPage;