import React, { useEffect, useState } from 'react';
import { Link, Redirect, RouteComponentProps } from 'react-router-dom';
import SignupInfo from '../../../domain/user/SignupInfo';
import UserServiceImpl from '../../../service/user/impl/UserServiceImpl';
import TrainningSubHeader from '../../trainning/layout/TrainningSubHeader';
import TeamServiceImpl from '../../../service/team/impl/TeamServiceImpl';
import Team from '../../../domain/team/Team';
import useCodeRetrieve from '../../../hook/code/useCodeRetrieve';
import { CodeType } from '../../../domain/code/CodeType';
import useChildCodeRetrieve from '../../../hook/code/useChildCodeRetrieve';
import Code from '../../../domain/code/Code';
import CalendarComponent from '../../../component/calendar/CalendarComponent';
import TeamSearchInput from '../../../component/input/TeamSearchInput';

interface SignupPageForSNSContainerProps {}
const userService = new UserServiceImpl();
const teamService = new TeamServiceImpl();
function SignupPageForSNSContainer(props: RouteComponentProps<SignupPageForSNSContainerProps>) {
    let state: any = props.location.state;

    const [belongedTeamNo, setBelongedTeamNo] = useState<string>();
    const [sportCodeId, setSportCodeId] = useState<number>();
    const [userTypeCodeId, setUserTypeCodeId] = useState<number>();
    const [genderCodeId, setGenderCodeId] = useState<number>();
    const [phoneNumber, setPhoneNumber] = useState<string>();
    const [teams, setTeams] = useState<Team[]>();
    const [parentRegionCodeId, setParentRegionCodeId] = useState<number>();
    const [regionCodeId, setRegionCodeId] = useState<number>();
    const { codes: parentRegionCodes } = useCodeRetrieve(CodeType.REGION);
    const regionCodes = useChildCodeRetrieve(parentRegionCodeId);
    const { codes: sportCodes } = useCodeRetrieve(CodeType.SPORT);
    const { codes: classificationCodes } = useCodeRetrieve(CodeType.CLASSIFICATION);
    const [classificationCodeId, setClassificationCodeId] = useState<number>(0);
    const [birthDate, setBirthDate] = useState<string>();

    useEffect(() => {
        if (parentRegionCodes) {
            if (parentRegionCodes.length > 0) {
                setParentRegionCodeId(parentRegionCodes[0].codeId);
            }
        }
    }, [parentRegionCodes]);

    useEffect(() => {
        teamService.getTeams(undefined, undefined).then((teams) => {
            setTeams(teams);
        });
    }, []);

    const signUpForSns = () => {
        if (!classificationCodeId) {
            alert('소속을 선택하세요.');
            return;
        }
        if (!belongedTeamNo) {
            alert('팀을 선택하세요.');
            return;
        }

        if (belongedTeamNo == 'undefined') {
            alert('팀을 선택하세요.');
            return;
        }

        if (!userTypeCodeId) {
            alert('사용자 유형을 선택하세요.');
            return;
        }

        if (!genderCodeId) {
            alert('성별 을 선택하세요.');
            return;
        }

        if (!sportCodeId) {
            alert('종목을 선택하세요.');
            return;
        }

        if (!regionCodeId) {
            alert('세부 지역을 선택하세요.');
            return;
        }

        if (!birthDate) {
            alert('생년월일을 입력하세요.');
            return;
        }

        if (!phoneNumber) {
            alert('연락처를 입력하세요.');
            return;
        }

        if (belongedTeamNo && userTypeCodeId && genderCodeId && sportCodeId && regionCodeId && phoneNumber && classificationCodeId && birthDate) {
            let signupInfo = new SignupInfo();
            signupInfo.name = state.userName;
            signupInfo.provider = state.provider;
            signupInfo.providerId = state.providerId;
            signupInfo.email = state.email;
            signupInfo.imageUrl = state.imageUrl;
            signupInfo.belongedTeamNo = belongedTeamNo;
            signupInfo.userTypeCodeId = userTypeCodeId;
            signupInfo.genderCodeId = genderCodeId;
            signupInfo.sportCodeId = sportCodeId;
            signupInfo.regionCodeId = regionCodeId;
            signupInfo.phoneNumber = phoneNumber;
            signupInfo.classificationCodeId = classificationCodeId;
            signupInfo.birthDate = birthDate;

            userService.signUpBySNS(signupInfo).then((response: any) => {
                console.log(response);
                if (response.executeCode == 2000) {
                    alert('가입 신청이 완료되었습니다.');
                    window.location.href = '/login';
                } else {
                    alert(response.message);
                }
            });
            // .catch(err => {
            //     console.log(err);
            //     alert("로그인 실패 - 관리자에게 문의하세요.");
            // });
        }
    };

    const styled = {
        // display:'block',
        // width: '26%'
    };

    return (
        <>
            <TrainningSubHeader></TrainningSubHeader>
            <div className="popup-opened">
                {/* <div className="popup_wrapper" style={popup_styled}> */}
                <div id="join">
                    <div id="sportman" className="mypage-login" style={styled}>
                        <p className="intro-title">
                            <img src="/assets/image/logo_with_name.png" width={300} />
                        </p>
                        <div className="lay-basic">
                            {/* <Link to="#" data-needpopup-show="#login-popup" className="bt-back">&lsaquo;</Link> */}
                            <h5 style={{ textAlign: 'left' }}>
                                {state.provider == 'naver' ? '네이버로 ' : ''}
                                {state.provider == 'kakao' ? '카카오로 ' : ''}
                                {state.provider == 'google' ? '구글로 ' : ''}
                                회원가입
                            </h5>
                            <img style={{ borderRadius: '100px', width: '50px', height: '50px' }} src={state.imageUrl} />
                        </div>
                        <div className="txt-left mgt10">
                            <h5 className="alert-h5">가입 유형</h5>
                        </div>
                        {/* <label>
                            <input checked={userTypeCodeId==21} name="userType" type="radio" onClick={() => setUserTypeCodeId(21)}/>선수
                        </label>
                        <label>
                            <input checked={userTypeCodeId==22} name="userType" type="radio" onClick={() => setUserTypeCodeId(22)}/>팀 운영자
                        </label> */}
                        <select
                            onChange={(event) => {
                                var value = Number(event.currentTarget.value);
                                if (value == 0) {
                                    setUserTypeCodeId(undefined);
                                } else {
                                    setUserTypeCodeId(value);
                                }
                            }}
                            className="basic-gray w100"
                        >
                            <option value={0}>가입유형</option>
                            <option value={21}>선수</option>
                            <option value={21}>감독</option>
                        </select>
                        {!userTypeCodeId ? (
                            <div className="dan">
                                <span className="dan-red">!</span>가입 유형을 선택하세요.
                            </div>
                        ) : (
                            <></>
                        )}

                        <div className="txt-left mgt10">
                            <h5 className="alert-h5">이름</h5>
                        </div>
                        <input className="basic-gray signup-input" placeholder="이름 입력" value={state.userName}></input>
                        {!state.userName ? (
                            <div className="dan">
                                <span className="dan-red">!</span>이름을 정확하게 입력 하셨나요?
                            </div>
                        ) : (
                            <></>
                        )}

                        <div className="txt-left mgt10">
                            <h5 className="alert-h5">이메일</h5>
                        </div>
                        <input readOnly={true} className="basic-gray signup-input" placeholder="이메일 입력" value={state.email}></input>
                        {!state.userName ? (
                            <div className="dan">
                                <span className="dan-red">!</span>이메일을 정확하게 입력 하셨나요?
                            </div>
                        ) : (
                            <></>
                        )}

                        <div className="txt-left mgt10">
                            <h5 className="alert-h5">생년월일</h5>
                        </div>
                        <CalendarComponent onDateChange={setBirthDate}></CalendarComponent>
                        {!birthDate ? (
                            <div className="dan">
                                <span className="dan-red">!</span>생년월일을 입력하세요.
                            </div>
                        ) : (
                            <></>
                        )}

                        <div className="txt-left mgt10">
                            <h5 className="alert-h5">성별</h5>
                        </div>
                        {/* <label>
                            <input checked={genderCodeId==11} name="userGender" type="radio" onClick={() => setGenderCodeId(11)}/>남성
                        </label>
                        <label>
                            <input checked={genderCodeId==12} name="userGender" type="radio" onClick={() => setGenderCodeId(12)}/>여성
                        </label> */}
                        <select
                            className="basic-gray w100"
                            onChange={(event) => {
                                const value = Number(event.currentTarget.value);
                                if (value == 0) {
                                    setGenderCodeId(undefined);
                                } else {
                                    setGenderCodeId(value);
                                }
                            }}
                        >
                            <option value={0}>성별</option>
                            <option value={11}>남자</option>
                            <option value={12}>여자</option>
                        </select>
                        {!genderCodeId ? (
                            <div className="dan">
                                <span className="dan-red">!</span>성별을 선택하세요.
                            </div>
                        ) : (
                            <></>
                        )}

                        <div className="txt-left mg10">
                            <h5 className="alert-h5">소속</h5>
                        </div>
                        <select
                            onChange={(event) => {
                                setClassificationCodeId(Number(event.currentTarget.value));
                            }}
                            className="basic-gray w100"
                        >
                            <option value={0}>소속 선택</option>
                            {classificationCodes?.map((classificationCode: Code, index: number) => {
                                return (
                                    <option key={index} value={classificationCode.codeId}>
                                        {classificationCode.name}
                                    </option>
                                );
                            })}
                        </select>
                        {!classificationCodeId ? (
                            <div className="dan">
                                <span className="dan-red">!</span>
                                소속을 선택하세요.
                            </div>
                        ) : (
                            <></>
                        )}

                        <div className="txt-left mgt10">
                            <h5 className="alert-h5">팀</h5>
                        </div>
                        {/* <select
                            onChange={(event) => {
                                setBelongedTeamNo(event.currentTarget.value);
                            }}
                            className="basic-gray w100"
                        >
                            <option value="undefined">팀선택</option>
                            {teams?.map((team, index) => {
                                return (
                                    <option key={index} value={team.teamNo}>
                                        {team.name}
                                    </option>
                                );
                            })}
                        </select> */}
                        <TeamSearchInput setTeamNo={setBelongedTeamNo} className="basic-gray signup-input"></TeamSearchInput>
                        {!belongedTeamNo ? (
                            <div className="dan">
                                <span className="dan-red">!</span>팀을 선택 해주세요.
                            </div>
                        ) : (
                            <></>
                        )}

                        <div className="txt-left mgt10">
                            <h5 className="alert-h5">종목</h5>
                        </div>
                        <select
                            onChange={(event) => {
                                setSportCodeId(Number(event.currentTarget.value));
                            }}
                            className="basic-gray w100"
                        >
                            <option value={0}>종목선택</option>
                            {sportCodes?.map((code, index) => {
                                return (
                                    <option key={index} value={code.codeId}>
                                        {code.name}
                                    </option>
                                );
                            })}
                        </select>

                        {!sportCodeId ? (
                            <div className="dan">
                                <span className="dan-red">!</span>종목을 선택 해주세요.
                            </div>
                        ) : (
                            <></>
                        )}

                        <div className="txt-left mgt10">
                            <h5 className="alert-h5">지역</h5>
                        </div>
                        <select
                            onChange={(event) => {
                                setParentRegionCodeId(Number(event.currentTarget.value));
                            }}
                            className="basic-gray w100"
                        >
                            <option value={0}>지역선택</option>
                            {parentRegionCodes?.map((code, index) => {
                                return (
                                    <option key={index} value={code.codeId}>
                                        {code.name}
                                    </option>
                                );
                            })}
                        </select>
                        <select
                            onChange={(event) => {
                                setRegionCodeId(Number(event.currentTarget.value));
                            }}
                            className="basic-gray w100"
                            style={{ marginTop: '4px' }}
                        >
                            <option value={0}>세부지역선택</option>
                            {regionCodes?.map((regionCodeId: Code, index: number) => {
                                return <option value={regionCodeId.codeId}>{regionCodeId.name}</option>;
                            })}
                        </select>
                        {!regionCodeId ? (
                            <div className="dan">
                                <span className="dan-red">!</span>지역을 선택 해주세요.
                            </div>
                        ) : (
                            <></>
                        )}

                        <div className="txt-left mgt10">
                            <h5 className="alert-h5">휴대폰번호</h5>
                        </div>
                        <input
                            onChange={(event) => setPhoneNumber(event.currentTarget.value)}
                            className="basic-gray signup-input"
                            placeholder="휴대폰번호"
                            value={phoneNumber}
                        />
                        {!phoneNumber ? (
                            <div className="dan">
                                <span className="dan-red">!</span>본인 명의 휴대폰 번호를 등록하세요.
                            </div>
                        ) : (
                            <></>
                        )}

                        <div className="lay-basic mgt10">
                            <button onClick={signUpForSns} className="center-button-login">
                                가입
                            </button>
                        </div>
                        <div className="mgt10"></div>
                    </div>
                </div>
                {/* </div> */}
            </div>
        </>
    );
}

export default SignupPageForSNSContainer;
