import { JsonConvert } from 'json2typescript';
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import Question from '../../../domain/Question';
import QuestionItem from '../../../domain/QuestionItem';
import Questionnaire from '../../../domain/Questionnaire';
import QuestionnaireType from '../../../domain/QuestionnarieType';
import RehabQuestionnairesServiceImpl from '../../../service/impl/RehabQuestionnairesServiceImpl';
import ManageQuestions from './comps/ManageQuestions';

interface IProps {
    forms?: Questionnaire;
}

const closingHandler = () => {
    let centerPopupWrap = document.getElementById('center-popup-wrap');
    if (centerPopupWrap) {
        centerPopupWrap.style.display = 'none';
        ReactDOM.render(<></>, centerPopupWrap);
    }
};

const defaultArr = [
    { itemNo: 0, content: '' },
    { itemNo: 1, content: '' },
    { itemNo: 2, content: '' },
    { itemNo: 3, content: '' },
    { itemNo: 4, content: '' },
];

const rehabQuestionnairesServiceImpl = new RehabQuestionnairesServiceImpl();
const jsonConvert = new JsonConvert();

export default function QuestSheetPopupComponent(props: IProps) {
    const [questionnaire, setQuestionnaire] = useState<Questionnaire>(props.forms ? props.forms : new Questionnaire());
    const [questions, setQuestions] = useState<Question[]>([new Question()]);

    useEffect(() => {
        let storedQuestions = questionnaire.questions;
        if (storedQuestions != '') setQuestions(JSON.parse(storedQuestions));
    }, []);

    useEffect(() => {
        setQuestionnaire((prevState) => {
            return {
                ...prevState,
                ['questions']: JSON.stringify(questions),
            };
        });
    }, [questions]);

    const manageQuesHandler = (e: any) => {
        let action = e.target.id;
        let quesLength = questions.length;
        if (action === 'addQues') {
            let newQuestion = new Question();
            newQuestion.questionNum = quesLength + 1;
            setQuestions([...questions, newQuestion]);
        } else {
            let idx = action.split('_')[1];

            setQuestions((prevState) => {
                let newQuestions = [...prevState];
                newQuestions.splice(idx, 1);
                newQuestions.forEach((item, idx) => {
                    item.questionNum = idx + 1;
                });
                return newQuestions;
            });
        }
    };

    const manageItemsHandler = (e: any) => {
        let id = e.target.id;
        let idArr = id.split('_');

        if (idArr[0] === 'addItem') {
            setQuestions((prevState) => {
                let newQuestions = [...prevState];
                let items = [...newQuestions[idArr[1]].items];
                let itemLength = items.length;
                let newItems = [...items, { itemNo: itemLength + 1, content: '' }];
                newQuestions[idArr[1]].items = newItems;
                return newQuestions;
            });
        } else {
            setQuestions((prevState) => {
                let newQuestions = [...prevState];
                let items = newQuestions[idArr[1]].items;

                items.splice(idArr[2], 1);
                items.forEach((item, idx) => {
                    item.itemNo = idx + 1;
                });

                return newQuestions;
            });
        }
    };

    const onInputHandler = (e: any) => {
        let id = e.target.id;
        let inputVal = e.target.value;

        setQuestionnaire((prevState) => {
            return { ...prevState, [id]: inputVal };
        });
    };

    const domainChangeHandler = (e: React.MouseEvent<HTMLElement>) => {
        let id = e.currentTarget.id;
        setQuestionnaire((prevState) => {
            return { ...prevState, ['questionnaireDomain']: id };
        });
    };

    const inputQuestionHandler = (e: any) => {
        let quesIdArr = e.target.id.split('_');
        let inputVal = e.target.value;
        setQuestions((prevState) => {
            let newQuestions = [...prevState];
            if (quesIdArr[0] === 'ques') {
                newQuestions[quesIdArr[1]].question = inputVal;
            } else {
                newQuestions[quesIdArr[0]].items[quesIdArr[1]].content = inputVal;
            }
            return newQuestions;
        });
    };

    const saveEventHandler = () => {
        //questionnaire['questions'] = JSON.stringify(questions);
        rehabQuestionnairesServiceImpl.createQuestionnaire(questionnaire).then((test: Questionnaire) => {
            alert('등록 되었습니다.');
            window.location.replace('/rehab/manage/questsheet');
        });
    };

    return (
        <>
            <div className="center-popup wow animate__animated animate__fadeInUp" data-wow-delay="0.2s">
                <div className="center-popup-title">
                    <h1 className="center">설문지 등록 / 수정</h1>
                    <div>
                        <button
                            className="center-button-save"
                            onClick={() => {
                                saveEventHandler();
                            }}
                        >
                            저장
                            <i className="fas fa-times-circle"></i>
                        </button>
                        <button className="center-button-close" onClick={closingHandler} /* onclick="popupClose()" */>
                            닫기
                            <i className="fas fa-times-circle"></i>
                        </button>
                    </div>
                </div>
                <div className="center-popup-cont">
                    <div className="center-popup-bnt">
                        <a
                            href="#"
                            id="SATISFY"
                            className={questionnaire.questionnaireDomain === 'SATISFY' ? 'on' : ''}
                            onClick={(e) => domainChangeHandler(e)}
                        >
                            <i className="fas fa-running"></i>
                            만족도조사
                        </a>
                        <a
                            href="#"
                            id="REHAB"
                            className={questionnaire.questionnaireDomain === 'REHAB' ? 'on' : ''}
                            onClick={(e) => domainChangeHandler(e)}
                        >
                            <i className="fas fa-comment-medical"></i>
                            재활
                        </a>
                    </div>
                    <div id="center-popup-list" className="center-popup-list-tab">
                        <div className="center-popup-list-box">
                            <div className="flex center-tpd5">
                                <div className="center-label-questionnaire">제목</div>
                                <input
                                    type="text"
                                    className="center-input-sports"
                                    id="title"
                                    value={questionnaire.title}
                                    onChange={(e) => {
                                        onInputHandler(e);
                                    }}
                                />
                            </div>
                            <div className="flex center-tpd5">
                                <div className="center-label-questionnaire">
                                    소제목
                                    <br />
                                    <span>(화면에노출)</span>
                                </div>
                                <input
                                    type="text"
                                    className="center-input-sports"
                                    id="displayTitle"
                                    value={questionnaire.displayTitle}
                                    onChange={(e) => {
                                        onInputHandler(e);
                                    }}
                                />
                            </div>

                            <div className="flex center-tpde5">
                                <div className="center-label-questionnaire">설명</div>
                                <textarea
                                    className="center-textarea"
                                    name=""
                                    value={questionnaire.description}
                                    id="description"
                                    onChange={(e) => {
                                        onInputHandler(e);
                                    }}
                                ></textarea>
                            </div>
                            <ManageQuestions
                                questions={questions}
                                manageQuesHandler={manageQuesHandler}
                                manageItemsHandler={manageItemsHandler}
                                inputQuestionHandler={inputQuestionHandler}
                            ></ManageQuestions>
                            <div>
                                <button id="addQues" className="center-plus-cate" onClick={manageQuesHandler}>
                                    <i className="fas fa-plus-circle"></i>&nbsp;&nbsp;문항 추가하기
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
