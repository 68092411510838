import React, { useEffect, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import Event from '../../domain/event/Event';
import useEventDelete from '../../hook/event/useEventDelete';

interface AdminEventsTableTrProps {
    no: number;
    event: Event;
}

function AdminEventsTableTr(props: AdminEventsTableTrProps) {
    const [event, setEvent] = useState<Event>();
    const [deleteEventNo, setDeleteEventNo] = useState<string>();
    const deletedEvent = useEventDelete(deleteEventNo);

    const deleteBtnHandler = () => {
        confirmAlert({
            title: '알림',
            message: '쿠폰을 삭제하시겠습니까? 삭제후 복원할 수 없습니다.',
            buttons: [
                {
                    label: '확인',
                    onClick: () => {
                        setDeleteEventNo(event?.eventNo);
                    },
                },
                { label: '취소', onClick: () => {} },
            ],
        });
    };
    useEffect(() => {
        setEvent(props.event);
    }, [props.event]);

    useEffect(() => {
        if (deletedEvent) {
            confirmAlert({
                title: '삭제성공',
                message: '쿠폰이 정상적으로 삭제 되었습니다. 감사합니다.',
                buttons: [
                    {
                        label: '확인',
                        onClick: () => {
                            setEvent(deletedEvent);
                            window.location.reload();
                        },
                    },
                ],
            });
        }
    }, [deletedEvent]);

    return (
        <tr>
            <td>{props.no}</td>
            <td>{event?.eventNo}</td>
            <td>{event?.register?.name}</td>
            <td>{event?.regionCode?.name}</td>
            <td>{event?.infraCategory?.name}</td>
            <td>{event?.eventCode?.name}</td>
            <td>{event?.couponCode ? event?.couponCode?.name : event?.eventCode?.name}</td>
            <td>{event?.content}</td>
            <td>{event?.startTime}</td>
            <td>{event?.expiredTime}</td>
            <td>
                {event?.usedCouponCnt ? event?.usedCouponCnt : 0} / {event?.issuedCouponCnt}
            </td>
            <td>{event?.registeDate}</td>
            <td>
                {!event?.deleteYn ? (
                    <button className="bg-blue white" onClick={deleteBtnHandler} style={{ fontWeight: 700 }}>
                        쿠폰삭제
                    </button>
                ) : (
                    <span style={{ color: 'red' }}>취소</span>
                )}
            </td>
        </tr>
    );
}

export default AdminEventsTableTr;
