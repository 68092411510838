import React from 'react';
import useReportQuestionnarieAnswerMake from '../../../../../../hook/report/useReportQuestionnarieAnswerMake';
import AnswerItem from '../../../../domain/AnswerItem';
import QuestionnariesFormProps from './QuestionnariesFormsProps';
import ReportTypeEnum from '../../../../../../domain/report/param/ReportTypeEnum';
import APIServerConfig from '../../../../../../config/APIServerConfig';

export default function IESRK(props: QuestionnariesFormProps) {
    const putAnswerItem = (qNum: number, answerNumber: number) => {
        if(props.answerItems) {
            const newAnswerItem = AnswerItem.getNewAnswerItem(qNum, answerNumber);
            if(props.setAnswer)
                props.setAnswer(qNum, newAnswerItem);
        }
    }

    const isCheck = (qNum: number, answerNumber: number): boolean => {
        if(props.answerItems) {
            if(props.answerItems.get(qNum)?.itemNoOfAnswer == answerNumber) {
                return true;
            } else {
                return false; 
            }
        } else {
            return false;
        }
    }

    const {reportData} = useReportQuestionnarieAnswerMake(props.answerItems);

    return (
        <>
            <div className="center-center">외상후 스트레스
                {
                    props.resultPrintable ?
                    <>
                        <form action={`${APIServerConfig.resourceAPIURL}/report/pdf`} method="POST">
                            <input type="hidden" name="reportType" value={ReportTypeEnum.IESRK}></input>
                            <input type="hidden" name="reportData" value={JSON.stringify(reportData)}></input>
                            <input type="submit" value="설문 결과 PDF 내려받기"/>
                        </form>
                        <form action={`${APIServerConfig.resourceAPIURL}/report/xlsx`} method="POST">
                            <input type="hidden" name="reportType" value={ReportTypeEnum.IESRK}></input>
                            <input type="hidden" name="reportData" value={JSON.stringify(reportData)}></input>
                            <input type="submit" value="설문 결과 엑셀 내려받기"/>
                        </form>
                    </>
                    :
                    <></>
                }
            </div>
            <h4>외상후 스트레스장애 선별검사(IES-R-K)</h4>
            <p>
                살처분 과정 등에서 겪게 되는 심리적인 충격이 장기간 남아 정신 건강에 장애를 일으킬 수 있습니다. <br />
                주된 증상은
                <br />
                (1) 겪었던 충격적인 사건을 잊으려고 해도 자꾸 생각이 남. 혹은 관련된 악몽을 꿈. <br />
                (2) 충격적인 사건의 장소나 비슷한 상황 등을 피하게 됨.
                <br />
                (3) 사건 이후 쉽게 놀라거나, 경각심이 높아짐
                <br />
                (4) 주위 사람이나 환경에 대해 무감각하게 느껴짐 등입니다.
            </p>
            <div className="tab-story-number">
                <table className="center-check-table-basic" cellPadding="0" cellSpacing="0">
                    <thead>
                        <tr>
                            <th colSpan={6}>
                                다음은 지난 1주일 동안 당신의 상태에 대한 질문입니다.
                                <br />
                                다음 같은 일들이 지난 1주일 동안 얼마나 자주 일어났는지 해당하는 곳에 표시하십시오
                            </th>
                        </tr>
                        <tr>
                            <th> A. 극히 드물다.(일주일 동안 1일 이하)</th>
                            <th colSpan={5}> B. 가끔 있었다.(일주일 동안 1일에서 2일간)</th>
                        </tr>
                        <tr>
                            <th>C. 종종 있었다.(일주일 동안 3일에서 4일간) </th>
                            <th colSpan={5}> D. 대부분 그랬다.(일주일 동안 5일이상)</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th className="center-bgcolor-blue">검 사 내 용</th>
                            <td className="center-bgcolor-blue">
                                전혀
                                <br />
                                없다
                                <br />
                                (0점)
                            </td>
                            <td className="center-bgcolor-blue">
                                드물게
                                <br />
                                있다
                                <br />
                                (1점)
                            </td>
                            <td className="center-bgcolor-blue">
                                가끔
                                <br />
                                있다
                                <br />
                                (2점)
                            </td>
                            <td className="center-bgcolor-blue">
                                자주
                                <br />
                                있다
                                <br />
                                (3점)
                            </td>
                            <td className="center-bgcolor-blue">
                                많이
                                <br />
                                있다
                                <br />
                                (4점)
                            </td>
                        </tr>
                        <tr>
                            <th>1. 그 사건을 상기시켜 주는 것들이 그 사건에 대한 감정 (느낌)들을 다시 되살아나게 한다.</th>
                            <td>
                                <input checked={isCheck(1, 0)} onClick={() => {putAnswerItem(1, 0)}} type="checkbox" />
                            </td>
                            <td>
                                <input checked={isCheck(1, 1)} onClick={() => {putAnswerItem(1, 1)}} type="checkbox" />
                            </td>
                            <td>
                                <input checked={isCheck(1, 2)} onClick={() => {putAnswerItem(1, 2)}} type="checkbox" />
                            </td>
                            <td>
                                <input checked={isCheck(1, 3)} onClick={() => {putAnswerItem(1, 3)}} type="checkbox" />
                            </td>
                            <td>
                                <input checked={isCheck(1, 4)} onClick={() => {putAnswerItem(1, 4)}} type="checkbox" />
                            </td>
                        </tr>
                        <tr>
                            <th>2. 나는 수면을 지속하는 데 어려움이 있었다. </th>
                            <td>
                                <input checked={isCheck(2, 0)} onClick={() => {putAnswerItem(2, 0)}} type="checkbox" />
                            </td>
                            <td>
                                <input checked={isCheck(2, 1)} onClick={() => {putAnswerItem(2, 1)}} type="checkbox" />
                            </td>
                            <td>
                                <input checked={isCheck(2, 2)} onClick={() => {putAnswerItem(2, 2)}} type="checkbox" />
                            </td>
                            <td>
                                <input checked={isCheck(2, 3)} onClick={() => {putAnswerItem(2, 3)}} type="checkbox" />
                            </td>
                            <td>
                                <input checked={isCheck(2, 4)} onClick={() => {putAnswerItem(2, 4)}} type="checkbox" />
                            </td>
                        </tr>
                        <tr>
                            <th>3. 나는 다른 일들로 인해 그 사건을 생각하게 된다.</th>
                            <td>
                                <input checked={isCheck(3, 0)} onClick={() => {putAnswerItem(3, 0)}} type="checkbox" />
                            </td>
                            <td>
                                <input checked={isCheck(3, 1)} onClick={() => {putAnswerItem(3, 1)}} type="checkbox" />
                            </td>
                            <td>
                                <input checked={isCheck(3, 2)} onClick={() => {putAnswerItem(3, 2)}} type="checkbox" />
                            </td>
                            <td>
                                <input checked={isCheck(3, 3)} onClick={() => {putAnswerItem(3, 3)}} type="checkbox" />
                            </td>
                            <td>
                                <input checked={isCheck(3, 4)} onClick={() => {putAnswerItem(3, 4)}} type="checkbox" />
                            </td>
                        </tr>
                        <tr>
                            <th>4. 나는 그 사건 이후로 예민하고 화가 난다고 느꼈다. </th>
                            <td>
                                <input checked={isCheck(4, 0)} onClick={() => {putAnswerItem(4, 0)}} type="checkbox" />
                            </td>
                            <td>
                                <input checked={isCheck(4, 1)} onClick={() => {putAnswerItem(4, 1)}} type="checkbox" />
                            </td>
                            <td>
                                <input checked={isCheck(4, 2)} onClick={() => {putAnswerItem(4, 2)}} type="checkbox" />
                            </td>
                            <td>
                                <input checked={isCheck(4, 3)} onClick={() => {putAnswerItem(4, 3)}} type="checkbox" />
                            </td>
                            <td>
                                <input checked={isCheck(4, 4)} onClick={() => {putAnswerItem(4, 4)}} type="checkbox" />
                            </td>
                        </tr>
                        <tr>
                            <th>5. 그 사건에 대해 생각하거나 떠오를 때마다 혼란스러워 지기 때문에 회피하려고 했다. </th>
                            <td>
                                <input checked={isCheck(5, 0)} onClick={() => {putAnswerItem(5, 0)}} type="checkbox" />
                            </td>
                            <td>
                                <input checked={isCheck(5, 1)} onClick={() => {putAnswerItem(5, 1)}} type="checkbox" />
                            </td>
                            <td>
                                <input checked={isCheck(5, 2)} onClick={() => {putAnswerItem(5, 2)}} type="checkbox" />
                            </td>
                            <td>
                                <input checked={isCheck(5, 3)} onClick={() => {putAnswerItem(5, 3)}} type="checkbox" />
                            </td>
                            <td>
                                <input checked={isCheck(5, 4)} onClick={() => {putAnswerItem(5, 4)}} type="checkbox" />
                            </td>
                        </tr>
                        <tr>
                            <th>6. 내가 생각하지 않으려고 해도 그 사건이 생각난다. </th>
                            <td>
                                <input checked={isCheck(6, 0)} onClick={() => {putAnswerItem(6, 0)}} type="checkbox" />
                            </td>
                            <td>
                                <input checked={isCheck(6, 1)} onClick={() => {putAnswerItem(6, 1)}} type="checkbox" />
                            </td>
                            <td>
                                <input checked={isCheck(6, 2)} onClick={() => {putAnswerItem(6, 2)}} type="checkbox" />
                            </td>
                            <td>
                                <input checked={isCheck(6, 3)} onClick={() => {putAnswerItem(6, 3)}} type="checkbox" />
                            </td>
                            <td>
                                <input checked={isCheck(6, 4)} onClick={() => {putAnswerItem(6, 4)}} type="checkbox" />
                            </td>
                        </tr>
                        <tr>
                            <th>7. 그 사건이 일어나지 않았거나, 현실이 아닌것처럼 느꼈다.</th>
                            <td>
                                <input checked={isCheck(7, 0)} onClick={() => {putAnswerItem(7, 0)}} type="checkbox" />
                            </td>
                            <td>
                                <input checked={isCheck(7, 1)} onClick={() => {putAnswerItem(7, 1)}} type="checkbox" />
                            </td>
                            <td>
                                <input checked={isCheck(7, 2)} onClick={() => {putAnswerItem(7, 2)}} type="checkbox" />
                            </td>
                            <td>
                                <input checked={isCheck(7, 3)} onClick={() => {putAnswerItem(7, 3)}} type="checkbox" />
                            </td>
                            <td>
                                <input checked={isCheck(7, 4)} onClick={() => {putAnswerItem(7, 4)}} type="checkbox" />
                            </td>
                        </tr>
                        <tr>
                            <th>8. 그 사건을 상기시키는 것들을 멀리하며 지냈다. </th>
                            <td>
                                <input checked={isCheck(8, 0)} onClick={() => {putAnswerItem(8, 0)}} type="checkbox" />
                            </td>
                            <td>
                                <input checked={isCheck(8, 1)} onClick={() => {putAnswerItem(8, 1)}} type="checkbox" />
                            </td>
                            <td>
                                <input checked={isCheck(8, 2)} onClick={() => {putAnswerItem(8, 2)}} type="checkbox" />
                            </td>
                            <td>
                                <input checked={isCheck(8, 3)} onClick={() => {putAnswerItem(8, 3)}} type="checkbox" />
                            </td>
                            <td>
                                <input checked={isCheck(8, 4)} onClick={() => {putAnswerItem(8, 4)}} type="checkbox" />
                            </td>
                        </tr>
                        <tr>
                            <th>9. 그 사건의 영상이 나의 마음 속에 갑자기 떠오르곤 했다.</th>
                            <td>
                                <input checked={isCheck(9, 0)} onClick={() => {putAnswerItem(9, 0)}} type="checkbox" />
                            </td>
                            <td>
                                <input checked={isCheck(9, 1)} onClick={() => {putAnswerItem(9, 1)}} type="checkbox" />
                            </td>
                            <td>
                                <input checked={isCheck(9, 2)} onClick={() => {putAnswerItem(9, 2)}} type="checkbox" />
                            </td>
                            <td>
                                <input checked={isCheck(9, 3)} onClick={() => {putAnswerItem(9, 3)}} type="checkbox" />
                            </td>
                            <td>
                                <input checked={isCheck(9, 4)} onClick={() => {putAnswerItem(9, 4)}} type="checkbox" />
                            </td>
                        </tr>
                        <tr>
                            <th>10. 나는 신경이 예민해졌고 쉽게 깜짝 놀랐다. </th>
                            <td>
                                <input checked={isCheck(10, 0)} onClick={() => {putAnswerItem(10, 0)}} type="checkbox" />
                            </td>
                            <td>
                                <input checked={isCheck(10, 1)} onClick={() => {putAnswerItem(10, 1)}} type="checkbox" />
                            </td>
                            <td>
                                <input checked={isCheck(10, 2)} onClick={() => {putAnswerItem(10, 2)}} type="checkbox" />
                            </td>
                            <td>
                                <input checked={isCheck(10, 3)} onClick={() => {putAnswerItem(10, 3)}} type="checkbox" />
                            </td>
                            <td>
                                <input checked={isCheck(10, 4)} onClick={() => {putAnswerItem(10, 4)}} type="checkbox" />
                            </td>
                        </tr>
                        <tr>
                            <th>11. 그 사건에 관해 생각하지 않기 위해 노력했다. </th>
                            <td>
                                <input checked={isCheck(11, 0)} onClick={() => {putAnswerItem(11, 0)}} type="checkbox" />
                            </td>
                            <td>
                                <input checked={isCheck(11, 1)} onClick={() => {putAnswerItem(11, 1)}} type="checkbox" />
                            </td>
                            <td>
                                <input checked={isCheck(11, 2)} onClick={() => {putAnswerItem(11, 2)}} type="checkbox" />
                            </td>
                            <td>
                                <input checked={isCheck(11, 3)} onClick={() => {putAnswerItem(11, 3)}} type="checkbox" />
                            </td>
                            <td>
                                <input checked={isCheck(11, 4)} onClick={() => {putAnswerItem(11, 4)}} type="checkbox" />
                            </td>
                        </tr>
                        <tr>
                            <th>12. 나는 그 사건에 관해 여전히 많은 감정을 가지고 있다는 것을 알지만 신경 쓰고 싶지 않았다.</th>
                            <td>
                                <input checked={isCheck(12, 0)} onClick={() => {putAnswerItem(12, 0)}} type="checkbox" />
                            </td>
                            <td>
                                <input checked={isCheck(12, 1)} onClick={() => {putAnswerItem(12, 1)}} type="checkbox" />
                            </td>
                            <td>
                                <input checked={isCheck(12, 2)} onClick={() => {putAnswerItem(12, 2)}} type="checkbox" />
                            </td>
                            <td>
                                <input checked={isCheck(12, 3)} onClick={() => {putAnswerItem(12, 3)}} type="checkbox" />
                            </td>
                            <td>
                                <input checked={isCheck(12, 4)} onClick={() => {putAnswerItem(12, 4)}} type="checkbox" />
                            </td>
                        </tr>
                        <tr>
                            <th>13. 그 사건에 대한 나의 감정은 무감각한 느낌이었다. </th>
                            <td>
                                <input checked={isCheck(13, 0)} onClick={() => {putAnswerItem(13, 0)}} type="checkbox" />
                            </td>
                            <td>
                                <input checked={isCheck(13, 1)} onClick={() => {putAnswerItem(13, 1)}} type="checkbox" />
                            </td>
                            <td>
                                <input checked={isCheck(13, 2)} onClick={() => {putAnswerItem(13, 2)}} type="checkbox" />
                            </td>
                            <td>
                                <input checked={isCheck(13, 3)} onClick={() => {putAnswerItem(13, 3)}} type="checkbox" />
                            </td>
                            <td>
                                <input checked={isCheck(13, 4)} onClick={() => {putAnswerItem(13, 4)}} type="checkbox" />
                            </td>
                        </tr>
                        <tr>
                            <th>14. 나는 마치 사건 당시로 돌아간 것처럼 느끼거나 행동할 때가 있었다. </th>
                            <td>
                                <input checked={isCheck(14, 0)} onClick={() => {putAnswerItem(14, 0)}} type="checkbox" />
                            </td>
                            <td>
                                <input checked={isCheck(14, 1)} onClick={() => {putAnswerItem(14, 1)}} type="checkbox" />
                            </td>
                            <td>
                                <input checked={isCheck(14, 2)} onClick={() => {putAnswerItem(14, 2)}} type="checkbox" />
                            </td>
                            <td>
                                <input checked={isCheck(14, 3)} onClick={() => {putAnswerItem(14, 3)}} type="checkbox" />
                            </td>
                            <td>
                                <input checked={isCheck(14, 4)} onClick={() => {putAnswerItem(14, 4)}} type="checkbox" />
                            </td>
                        </tr>
                        <tr>
                            <th>15. 나는 그 사건 이후로 잠들기가 어려웠다. </th>
                            <td>
                                <input checked={isCheck(15, 0)} onClick={() => {putAnswerItem(15, 0)}} type="checkbox" />
                            </td>
                            <td>
                                <input checked={isCheck(15, 1)} onClick={() => {putAnswerItem(15, 1)}} type="checkbox" />
                            </td>
                            <td>
                                <input checked={isCheck(15, 2)} onClick={() => {putAnswerItem(15, 2)}} type="checkbox" />
                            </td>
                            <td>
                                <input checked={isCheck(15, 3)} onClick={() => {putAnswerItem(15, 3)}} type="checkbox" />
                            </td>
                            <td>
                                <input checked={isCheck(15, 4)} onClick={() => {putAnswerItem(15, 4)}} type="checkbox" />
                            </td>
                        </tr>
                        <tr>
                            <th>16. 나는 그 사건에 대한 강한 감정이 물밀 듯 밀려 오는 것을 느꼈다.</th>
                            <td>
                                <input checked={isCheck(16, 0)} onClick={() => {putAnswerItem(16, 0)}} type="checkbox" />
                            </td>
                            <td>
                                <input checked={isCheck(16, 1)} onClick={() => {putAnswerItem(16, 1)}} type="checkbox" />
                            </td>
                            <td>
                                <input checked={isCheck(16, 2)} onClick={() => {putAnswerItem(16, 2)}} type="checkbox" />
                            </td>
                            <td>
                                <input checked={isCheck(16, 3)} onClick={() => {putAnswerItem(16, 3)}} type="checkbox" />
                            </td>
                            <td>
                                <input checked={isCheck(16, 4)} onClick={() => {putAnswerItem(16, 4)}} type="checkbox" />
                            </td>
                        </tr>
                        <tr>
                            <th>17. 내 기억에서 그 사건을 지워버리려고 노력했다</th>
                            <td>
                                <input checked={isCheck(17, 0)} onClick={() => {putAnswerItem(17, 0)}} type="checkbox" />
                            </td>
                            <td>
                                <input checked={isCheck(17, 1)} onClick={() => {putAnswerItem(17, 1)}} type="checkbox" />
                            </td>
                            <td>
                                <input checked={isCheck(17, 2)} onClick={() => {putAnswerItem(17, 2)}} type="checkbox" />
                            </td>
                            <td>
                                <input checked={isCheck(17, 3)} onClick={() => {putAnswerItem(17, 3)}} type="checkbox" />
                            </td>
                            <td>
                                <input checked={isCheck(17, 4)} onClick={() => {putAnswerItem(17, 4)}} type="checkbox" />
                            </td>
                        </tr>
                        <tr>
                            <th>18. 나는 집중하는 데 어려움이 있었다. </th>
                            <td>
                                <input checked={isCheck(18, 0)} onClick={() => {putAnswerItem(18, 0)}} type="checkbox" />
                            </td>
                            <td>
                                <input checked={isCheck(18, 1)} onClick={() => {putAnswerItem(18, 1)}} type="checkbox" />
                            </td>
                            <td>
                                <input checked={isCheck(18, 2)} onClick={() => {putAnswerItem(18, 2)}} type="checkbox" />
                            </td>
                            <td>
                                <input checked={isCheck(18, 3)} onClick={() => {putAnswerItem(18, 3)}} type="checkbox" />
                            </td>
                            <td>
                                <input checked={isCheck(18, 4)} onClick={() => {putAnswerItem(18, 4)}} type="checkbox" />
                            </td>
                        </tr>

                        <tr>
                            <th>19. 그 사건을 떠올리게 하는 어떤 것에도 식은땀, 호흡곤란, 구역질, 심장두근거림 같은 신체적인 반응을 일으켰다.</th>
                            <td>
                                <input checked={isCheck(19, 0)} onClick={() => {putAnswerItem(19, 0)}} type="checkbox" />
                            </td>
                            <td>
                                <input checked={isCheck(19, 1)} onClick={() => {putAnswerItem(19, 1)}} type="checkbox" />
                            </td>
                            <td>
                                <input checked={isCheck(19, 2)} onClick={() => {putAnswerItem(19, 2)}} type="checkbox" />
                            </td>
                            <td>
                                <input checked={isCheck(19, 3)} onClick={() => {putAnswerItem(19, 3)}} type="checkbox" />
                            </td>
                            <td>
                                <input checked={isCheck(19, 4)} onClick={() => {putAnswerItem(19, 4)}} type="checkbox" />
                            </td>
                        </tr>
                        <tr>
                            <th>20. 내가 주위를 경계하고 감시하고 있다고 느꼈다.</th>
                            <td>
                                <input checked={isCheck(20, 0)} onClick={() => {putAnswerItem(20, 0)}} type="checkbox" />
                            </td>
                            <td>
                                <input checked={isCheck(20, 1)} onClick={() => {putAnswerItem(20, 1)}} type="checkbox" />
                            </td>
                            <td>
                                <input checked={isCheck(20, 2)} onClick={() => {putAnswerItem(20, 2)}} type="checkbox" />
                            </td>
                            <td>
                                <input checked={isCheck(20, 3)} onClick={() => {putAnswerItem(20, 3)}} type="checkbox" />
                            </td>
                            <td>
                                <input checked={isCheck(20, 4)} onClick={() => {putAnswerItem(20, 4)}} type="checkbox" />
                            </td>
                        </tr>
                        <tr className="center-bottom-black">
                            <th>21. 나는 그 사건에 대해 이야기하지 않으려고 노력했다. </th>
                            <td>
                                <input checked={isCheck(21, 0)} onClick={() => {putAnswerItem(21, 0)}} type="checkbox" />
                            </td>
                            <td>
                                <input checked={isCheck(21, 1)} onClick={() => {putAnswerItem(21, 1)}} type="checkbox" />
                            </td>
                            <td>
                                <input checked={isCheck(21, 2)} onClick={() => {putAnswerItem(21, 2)}} type="checkbox" />
                            </td>
                            <td>
                                <input checked={isCheck(21, 3)} onClick={() => {putAnswerItem(21, 3)}} type="checkbox" />
                            </td>
                            <td>
                                <input checked={isCheck(21, 4)} onClick={() => {putAnswerItem(21, 4)}} type="checkbox" />
                            </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td className="center-text-left" colSpan={6}>
                                <b>0점 ~ 17점</b> : 사건에 의한 정신적인 충격을 별로 받지 않은 것으로 보임
                                <br />
                                <b>18점 ~ 43점</b> : 급성스트레스장애나 외상 후 스트레스장애가 있을 가능성이 상당히 높습니다. 전문가와 상담을
                                받아보시길 권유합니다.
                                <br />
                                <b>44점 ~ 88점</b> : 급성스트레스장애나 외상 후 스트레스장애가 있을 가능성이 상당히 높습니다. 빨리 전문가와 상담
                                받아보시길 권유합니다.
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </>
    );
}
