import React, { useEffect, useState } from 'react';
import LayoutHeader from '../layouts/Header';
import '../assets/css/center.css';
import '../assets/css/animate.min.css';
import useLogin from '../../../hook/user/useLogin';
import { useParams } from 'react-router-dom';
import { RootState } from '../../../moldules/index';
import User from '../../../domain/user/User';
import { useSelector } from 'react-redux';

function LoginComponent(props: any) {
    const param: any = useParams();
    const [id, setId] = useState<string>();
    const [pw, setPw] = useState<string>();
    const { loginProccess } = useLogin(`/rehab/reserved`, id, pw);

    const { user } = useSelector((state: RootState) => state.userSessionReducer);
    if (user) {
        if (user.checkCenterOperAuth() || user.checkCenterRecorderAuth()) {
            window.location.replace(`/rehab/reserved`);
        }
    }
    return (
        <div className="center-body">
            {/* <LayoutHeader></LayoutHeader> */}
            <div className="center-wrap-dark">
                {/* <!--//center-top--> */}
                <div className="center-login-bg"></div>
                {/* <!--//center-login-bg--> */}
                <div className="center-login wow animate__animated animate__fadeInDown" data-wow-delay="0.2s">
                    <div className="center-login-title">
                        반갑습니다!<i className="fas fa-smile-wink"></i>
                    </div>
                    <div className="center-login-box">
                        {/* <label className="center-white">
                            <input type="radio" name="adm" value="suberadmin" defaultChecked={checked} />
                            수퍼 관리자
                        </label>
                        <label className="center-white">
                            <input type="radio" name="adm" value="admin" />
                            일반 관리자
                        </label> */}
                        <br />
                        <br />
                        <input
                            onChange={(event) => setId(event.currentTarget.value)}
                            className="center-input-login"
                            type="text"
                            placeholder="EMAIL"
                            value={id}
                        />
                        <input
                            onChange={(event) => setPw(event.currentTarget.value)}
                            className="center-input-login"
                            type="password"
                            placeholder="PASSWORD"
                            value={pw}
                        />
                        <button className="center-button-login" onClick={loginProccess}>
                            LOGIN
                        </button>
                    </div>
                    {/* <!--//center-login-box--> */}
                </div>
                {/* <!--//center-login-->	   */}
            </div>
        </div>
    );
}

export default LoginComponent;
