import React from 'react';
import Layout from '../layouts/Layout';
import Header from '../layouts/Header';
import { useParams, useLocation } from 'react-router-dom';
import SideBar from '../layouts/SideBar';
import AdminManagerInfoForm from '../../admin/component/manager/AdminManagerInfoForm';
import RehabManageUserInfoForm from '../components/manageUser/RehabManageUserInfoForm';

const menuTitle: string = '* 센터 계정 관리';
const title: string = '계정관리';

function RehabManageUserRegistePage() {
    const params: any = useParams();
    const location = useLocation();
    let path = location.pathname;
    const centerKey = params.centerId;

    return (
        <Layout
            path={path}
            naviTitle={title}
            menuTitle={menuTitle}
        >
            <div className="two-wrap">
                <div className="half">
                    <div className="member-list">
                        {
                            <RehabManageUserInfoForm></RehabManageUserInfoForm>
                        }
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default RehabManageUserRegistePage;