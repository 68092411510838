import { JsonConvert } from 'json2typescript';

class JsonConvertUtils {
    static jsonConvert: JsonConvert = new JsonConvert();

    static parseToObj<T>(json: any, classReference: {new (): T}): T|undefined  {
        if(json) {
            return this.jsonConvert.deserializeObject(json, classReference);
        } else {
            return undefined;
        }
    }
}

export default JsonConvertUtils;