import { JsonProperty, JsonObject } from 'json2typescript';
import { BasalPrescription } from './prescription/BasalPrescription';
import ConditioningPrescription from './prescription/ConditioningPrescription';
import ItemsPrescription from './prescription/ItemsPrescription';
import Opinions from './prescription/Opinions';
import moment, { Moment, Moment as MomentTypes } from 'moment';
import PhysicalStatus from '../../../domain/report/PhysicalStatus';
import BasalPhysical from '../../../domain/report/BasalPhysical';
import SpecialPhysical from './recording/SpecialPhysical';
import Infra from '../../../domain/infra/Infra';
import Reservation from '../../../domain/reservation/Reservation';
import Code from '../../../domain/code/Code';

@JsonObject('rehabTargetHistory')
export default class RehabTargetHistory {
    @JsonProperty('historyNo')
    historyNo: number = 0;
    @JsonProperty('targetNo')
    targetNo: number = 0;
    @JsonProperty('targetState')
    targetState: number = 0;
    @JsonProperty('targetStateCode')
    targetStateCode?: Code;

    @JsonProperty('conditions')
    conditions: string = '';
    @JsonProperty('physicalStatus')
    physicalStatus: string = '';
    @JsonProperty('basalPhysical')
    basalPhysical: string = '';
    @JsonProperty('specialPhysical')
    specialPhysical: string = '';
    @JsonProperty('basalExercisePrescription')
    basalExercisePrescription: string = '';
    @JsonProperty('conditioningPrescription')
    conditioningPrescription: string = '';
    @JsonProperty('itemPrescription')
    itemPrescription: string = '';
    @JsonProperty('bodyPrescription')
    bodyPrescription: string = '';
    @JsonProperty('rehabProgramPrescription')
    rehabProgramPrescription: string = '';
    @JsonProperty('opinions')
    opinions: string = '';

    @JsonProperty('infraNo')
    infraNo: string = '';
    @JsonProperty('infra')
    infra?: Infra;
    @JsonProperty('reservationNo')
    reservationNo: string = '';
    @JsonProperty('reservation')
    reservation?: Reservation;

    @JsonProperty('regDate')
    regDate: string = '';
    modDate: string = '';
    action: string = '';
    ocrJson: string = '';

    getRegDate() {
        let tempDate = moment(this.regDate);
        let oriMonth = tempDate.month();
        tempDate.month(oriMonth - 1);
        const response = tempDate.format('YYYY-MM-DD') as string;
        return response;
    }
}
