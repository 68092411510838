import React from 'react';
import { Link } from 'react-router-dom';
import LoginState from '../../../component/header/LoginState';
import MobileMenuContainer from '../../../component/header/MobileMenuContainer';
import SiteMainNav from '../../../component/nav/SiteMainNav';
import CommonHeader from '../../common/CommonHeader';
import CommonSearchBar from '../../../component/search/CommonSearchBar';

function RehabilitationMainHeader() {
    return (
        <section className="main-visual">
            <CommonHeader></CommonHeader>
            <div className="sub_visual sub_visual6">
                <div className="subTop">
                    <h2>스포츠재활센터</h2>
                    <span className="line"></span>
                    <p>간편히 온라인으로 예약하고 내 몸 측정하기!</p>
                </div>
            </div>
        </section>
    );
}

export default RehabilitationMainHeader;
