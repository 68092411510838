import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { RootState } from '../moldules/index';
import User from '../domain/user/User';

interface RehabPrivateRouterProps extends RouteProps {
    component: any;
}

const accessCheck = (user: User): boolean => {
    if (user) {
        if (user.checkCenterOperAuth() || user.checkCenterRecorderAuth()) return true;
    }

    return false;
};

/**
 * ...rest에는 Router일 경우 React에서 내부적으로 넘겨주는
 * computedMatch, location, path의 값이 저장되어 있다.
 * @param props
 */
const RehabPrivateRouter = (props: RehabPrivateRouterProps) => {
    const { component: Component, ...rest } = props;
    const { user } = useSelector((state: RootState) => state.userSessionReducer);

    return (
        <Route
            {...rest}
            render={(props) =>
                accessCheck(user) ? (
                    <Component {...rest} {...props} />
                ) : (
                    <Redirect
                        to={{
                            pathname: '/rehab/login',
                            state: { from: props.location },
                        }}
                    />
                )
            }
        />
    );
};

export default RehabPrivateRouter;
