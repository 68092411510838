import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import AdminMainTitle from '../../../../component/admin/AdminMainTitle';
import useChildCodeRetrieve from '../../../../hook/code/useChildCodeRetrieve';
import useCodeRetrieve from '../../../../hook/code/useCodeRetrieve';
import { CodeType } from '../../../../domain/code/CodeType';

import AdminVisitedTeamInfoFilter from '../../component/filter/AdminVisitedTeamInfoFilter';
import VisitedTeamStatusBySportTypeTables from '../../component/VisitedTeamStatusBySportTypeTables';

import { defaultParentRegionCodeId } from '../../../../config/Constant';

import ClubTeamInfoStatisticsServiceImpl from '../../../../service/clubteam/impl/ClubTeamInfoStatisticsServiceImpl';
import ClubTeamInfoSearchCondition from '../../../../domain/clubteam/param/ClubTeamInfoSearchCondition';
import ClubTeamInfoStatistics from '../../../../domain/clubteam/ClubTeamInfoStatistics';
import AdminClubStatusFilter from '../../component/filter/AdminClubStatusFilter';
import ClubStatusBySportsTables from '../../component/ClubStatusBySportsTables';
import AdminClubTeamInfosFilter from '../../component/filter/AdminClubTeamInfosFilter';

const statisticsService = new ClubTeamInfoStatisticsServiceImpl();

function AdminClubTeamStatusBySports() {
    const location = useLocation<ClubTeamInfoSearchCondition>();

    const { codes: sportsCodes } = useCodeRetrieve(CodeType.CLUB_SPORT);

    let condition = location.state ? location.state : new ClubTeamInfoSearchCondition();

    const [searchCondition, setSearchCondition] = useState<ClubTeamInfoSearchCondition>(condition);
    const [clubStatusBySports, setClubStatusBySports] = useState<ClubTeamInfoStatistics[]>();
    const [clubTotalStatusBySports, setClubTotalStatusBySports] = useState<ClubTeamInfoStatistics>();

    useEffect(() => {
        setSearchCondition(condition);
    }, []);

    useEffect(() => {
        statisticsService.getClubStatusBySport(searchCondition).then((data) => {
            setClubStatusBySports(data);
            let typeTotal: any = new ClubTeamInfoStatistics();

            typeTotal.cnt = data.length;
            data.map((typeInfo) => {
                typeTotal.clubTeamCount ? (typeTotal.clubTeamCount += typeInfo.clubTeamCount) : (typeTotal.clubTeamCount = typeInfo.clubTeamCount);
                typeTotal.clubTeamMemberCount
                    ? (typeTotal.clubTeamMemberCount += typeInfo.clubTeamMemberCount)
                    : (typeTotal.clubTeamMemberCount = typeInfo.clubTeamMemberCount);
            });

            setClubTotalStatusBySports(typeTotal);
        });
    }, [searchCondition]);

    let title = '종목 별 동호회 현황 정보';

    return (
        <>
            <AdminMainTitle title={title}>
                <AdminClubTeamInfosFilter setClubTeamInfoSearchCondition={setSearchCondition}></AdminClubTeamInfosFilter>
            </AdminMainTitle>
            <ClubStatusBySportsTables
                sportsCodes={sportsCodes}
                searchCondition={searchCondition}
                clubTotalStatusBySports={clubTotalStatusBySports}
                clubStatusBySports={clubStatusBySports}
            ></ClubStatusBySportsTables>
        </>
    );
}

export default AdminClubTeamStatusBySports;
