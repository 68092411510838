import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject("searchWord")
class SearchWord {
    @JsonProperty("searcherNo")
    searcherNo?: string;
    @JsonProperty("searchWord")
    searchWord?: string;
}

export default SearchWord;