import { useEffect, useState } from 'react';
import ReservationServiceImpl from '../../service/reservation/impl/ReservationServiceImpl';
import Reservation from '../../domain/reservation/Reservation';
import ReservationSearchCondition from '../../domain/reservation/param/ReservationSearchCondition';
const reservationService = new ReservationServiceImpl();

function useReservationOfInfra(infraNo?: string, reservationSearchCondition?: ReservationSearchCondition) {
    const [reservations, setReservations] = useState<Reservation[]>();

    useEffect(() => {
        if (infraNo && reservationSearchCondition) {
            reservationService.getReservationOfInfra(infraNo, reservationSearchCondition).then((reservations: Reservation[]) => {
                setReservations(reservations);
            });
        }
    }, [reservationSearchCondition, infraNo]);

    return { reservations, setReservations };
}

export default useReservationOfInfra;
