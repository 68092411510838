import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import '../assets/css/center.css';
import '../assets/css/animate.min.css';

import Layout from '../layouts/Layout';
import Header from '../layouts/Header';
import SideBar from '../layouts/SideBar';

import QuestionnairesPopup from './components/questionnaires/QuestPopupComponent';
import CheckConditionPopup from './components/questionnaires/CheckPopupComponent';

import RehabServiceImpl from '../service/impl/RehabServiceImpl';
import PaginationComponent from './components/PaginationComponent';
import TargetPerson from '../domain/TargetPerson';
import UserDetailInfo from '../../../domain/user/UserDetailInfo';
import Booking from '../domain/Booking';
import RehabHistoryService from '../service/RehabHistoryService';
import Team from '../../../domain/team/Team';

const menuTitle: string = '* 상담 대상자 관리';
const title: string = '상담';

const rehabService = new RehabServiceImpl();

export default function QuestionnairesComponent(props: any) {
    let path = props.location.pathname;
    let centerKey = props.match.params.centerId;

    const [targetInfos, setTargetInfos] = useState<TargetPerson[]>();

    const filters = () => {
        return (
            <div className="center-bnt-right2">
                <div className="center-select-box"></div>
            </div>
        );
    };

    const popups = () => {
        return (
            <>
                <div id="center-popup-wrap"></div>
                <div id="center-check-wrap"></div>
            </>
        );
    };

    const closingHandler = () => {
        let pagination = { size: displaySize, page: currPage };
        rehabService.getTargetForQuestionnaires(pagination).then((targetPersons: { data: TargetPerson[]; totalSize: number }) => {
            setTargetInfos(targetPersons.data);
            setTotalSize(targetPersons.totalSize);
        });
    };

    const popupHandler = (e: any) => {
        let val: number = e.target.value;
        let id: string = e.target.id;
        let findTarget = targetInfos?.find((item) => {
            return item.targetNo == val;
        });

        let targetPerson = findTarget ? findTarget : new TargetPerson();

        if (id === 'sendQuestionnaire') {
            let centerPopupWrap = document.getElementById('center-popup-wrap');
            if (centerPopupWrap) centerPopupWrap.style.display = 'block';
            ReactDOM.render(
                <QuestionnairesPopup targetPerson={targetPerson} targetNo={val}></QuestionnairesPopup>,
                document.getElementById('center-popup-wrap'),
            );
        } else if (id === 'checkCondition') {
            let checkPopupWrap = document.getElementById('center-check-wrap');
            if (checkPopupWrap) checkPopupWrap.style.display = 'block';

            ReactDOM.render(
                <CheckConditionPopup targetNo={val} targetPerson={targetPerson} closingHandler={closingHandler}></CheckConditionPopup>,
                document.getElementById('center-check-wrap'),
            );
        }
    };

    const trElems = (list: TargetPerson[] | undefined) => {
        let trElems = <> </>;
        let page = currPage ? currPage : 0;
        let pageSize = displaySize ? displaySize : 0;
        let rowNum = page * pageSize;

        if (typeof list != 'undefined' && list.length > 0) {
            trElems = (
                <>
                    {list?.map((targetInfo: TargetPerson, idx) => {
                        let reservater: UserDetailInfo | undefined = targetInfo.reservater;
                        console.log(targetInfo);
                        let reservation: Booking | undefined = targetInfo.reservation;
                        let team: Team | undefined = targetInfo.team;
                        let infraInfo: any = targetInfo.infra;
                        let booking: string | undefined = reservation?.startDate;

                        let genderCode: any = reservater?.genderCode;
                        let regionCode: any = reservater?.regionCode;
                        let sportCode: any = reservater?.sportCode;
                        let timeArr: string[] | undefined = booking?.split(' ');
                        let bookingDate = '';
                        let bookingTime = '';

                        if (timeArr) {
                            bookingDate = timeArr[0];
                            bookingTime = timeArr[1];
                        }

                        return (
                            <tr key={idx}>
                                <td>{rowNum + idx + 1}</td>
                                <td>
                                    {reservater?.name}({targetInfo.targetNo})
                                </td>
                                <td>{genderCode.name}</td>
                                <td>{reservater?.birthdate}</td>
                                <td>
                                    {team?.name}/{regionCode.name}
                                </td>
                                <td>{sportCode.name}</td>
                                <td>{bookingDate}</td>
                                <td>{bookingTime}</td>
                                <td>
                                    <button
                                        className="center-button-letter"
                                        onClick={popupHandler}
                                        value={targetInfo.targetNo}
                                        id="sendQuestionnaire"
                                    >
                                        <i className="fas fa-sticky-note"></i> 설문지발송
                                    </button>
                                </td>
                                <td>
                                    <button
                                        className="center-button-condition"
                                        onClick={popupHandler}
                                        value={targetInfo.targetNo}
                                        id="checkCondition"
                                    >
                                        <i className="fas fa-user-check"></i> 상태확인
                                    </button>
                                </td>
                                <td>{infraInfo.regionCode.name}</td>
                            </tr>
                        );
                    })}
                </>
            );
        } else {
            trElems = (
                <>
                    <tr>
                        <td colSpan={12}>상담 대상자가 없습니다.</td>
                    </tr>
                </>
            );
        }

        return trElems;
    };

    const contents = (list: TargetPerson[] | undefined) => {
        return (
            <table className="center-list-table" cellPadding="0" cellSpacing="0">
                <thead>
                    <tr>
                        <td>No.</td>
                        <td>이름</td>
                        <td>성별</td>
                        <td>생년월일</td>
                        <td>소속팀</td>
                        <td>운동종목</td>
                        <td>예약일</td>
                        <td>예약시간</td>
                        <td>설문지</td>
                        <td>상태확인</td>
                        <td>상담진행 센터</td>
                    </tr>
                </thead>
                <tbody>{trElems(list)}</tbody>
            </table>
        );
    };

    useEffect(() => {
        let pagination = { size: 10, page: 0 };
        rehabService.getTargetForQuestionnaires(pagination).then((targetInfos: { data: TargetPerson[]; totalSize: number }) => {
            setTargetInfos(targetInfos.data);
            setTotalSize(targetInfos.totalSize);
        });
    }, []);

    const [totalSize, setTotalSize] = useState(1);
    const [displaySize, setDisplaySize] = useState(10);
    const [currPage, setCurrPage] = useState(0);

    const pagingHandler = (pagination: { size: number; page: number }) => {
        setCurrPage(pagination.page);
        setDisplaySize(pagination.size);
        rehabService.getTargetForQuestionnaires(pagination).then((targetInfos: { data: TargetPerson[]; totalSize: number }) => {
            setTargetInfos(targetInfos.data);
            setTotalSize(targetInfos.totalSize);
        });
    };
    let questionList = (
        <Layout
            header={<Header></Header>}
            sideBar={<SideBar name={path}></SideBar>}
            path={path}
            naviTitle={title}
            menuTitle={menuTitle}
            filter={filters()}
            pagination={PaginationComponent(pagingHandler, totalSize, currPage, displaySize)}
            popups={popups()}
        >
            {contents(targetInfos)}
        </Layout>
    );

    return questionList;
}
