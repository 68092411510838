import Code from '../code/Code';
import { JsonProperty, JsonObject } from 'json2typescript';
import AttachFile from '../file/AttachFile';
@JsonObject("team")
export default class Team {
    @JsonProperty("teamNo")
    teamNo?: string;
    @JsonProperty("name")
    name?: string;
    @JsonProperty("phoneNumber")
    phoneNumber?: string;
    @JsonProperty("homepageUrl")
    homepageUrl?: string;
    @JsonProperty("sportCode")
    sportCode?: Code;
    @JsonProperty("regionCode")
    regionCode?: Code;
    @JsonProperty("genderCode")
    genderCode?: Code;
    @JsonProperty("classificationCode")
    classificationCode?: Code;
    @JsonProperty("belongCode")
    belongCode?: Code;
    @JsonProperty("memberCnt")
    memberCnt: number = 0;
    @JsonProperty("attachFiles")
    attachFiles?: Array<AttachFile>;
    
    @JsonProperty("belongedCodeId")
    belongedCodeId?: number;
    @JsonProperty("classificationCodeId")
    classificationCodeId?:number;
    @JsonProperty("genderCodeId")
    genderCodeId?:number;
    @JsonProperty("regionCodeId")
    regionCodeId?:number;
    @JsonProperty("sportCodeId")
    sportCodeId?:number;

    @JsonProperty("registerNo")
    registerNo?: string;



    getMainAttachFile() {
        if(this.attachFiles) {
            if(this.attachFiles.length == 0) {
                return '/assets/image/list-1.png';
            } else {
                return `${process.env.REACT_APP_RESOURCE_API_HOST}/static-file${this.attachFiles[0].saveFilePath}`;
            }
        } else {
            return '/assets/image/list-1.png';
        }       
    }
}