import React, { useEffect } from 'react';
import Reservation from '../../../domain/reservation/Reservation';

interface ReservationInfraSelectProps {
    reservations?: Reservation[];
    setSelectedReservation: (selectedReservation: Reservation) => void;
    initReservation?: Reservation;
}

function ReservationInfraSelect(props: ReservationInfraSelectProps) {
    useEffect(() => {
        if(props.initReservation) 
            props.setSelectedReservation(props.initReservation);
    }, [props.initReservation]);
    
    const setSelectedReservationEventHandler = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        if(props.reservations) {
            const selectedIdx = Number(event.currentTarget.value);
            const selectedReservation = props.reservations[selectedIdx];
            props.setSelectedReservation(selectedReservation);
        }
    };
    return (
        <div className="menu-item">
            <div className="notice-open">
                <h5>예약 시설 및 프로그램</h5>
                <select
                    className="basic-gray"
                    onChange={setSelectedReservationEventHandler}
                >
                    <option>선택 안함</option>
                    {
                        props.reservations?
                        props.reservations.map((reservation: Reservation, index: number) => {
                            return (
                                <option 
                                    key={index}
                                    value={index}
                                    selected={props.initReservation?.reservationNo == reservation.reservationNo}
                                >{reservation.infra?.name} [{reservation.startDate}~{reservation.endDate}] - {reservation.reservater?.name}</option>
                            )
                        })
                        :
                        <></>
                    }
                </select>
            </div>
        </div>
    )
}

export default ReservationInfraSelect;