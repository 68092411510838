import React, { useEffect, useState } from 'react';
import Question from '../../../../domain/Question';

interface IProps {
    questions: Question[];
    manageQuesHandler: (e: any) => void;
    manageItemsHandler: (e: any) => void;
    inputQuestionHandler: (e: any) => void;
}

export default function ManageQuestions(props: IProps) {
    const questions = props.questions;
    console.log(questions);
    let questionElem = questions.map((question, quesIdx) => {
        return (
            <>
                <div className="flex center-bpd10" style={{ borderTop: '3px double #6d6d6d' }}>
                    <div className="center-label-questionnaire">
                        문항{question.questionNum}&nbsp;&nbsp;
                        {question.questionNum > 1 ? (
                            <i className="fas fa-minus-square" id={'remove_' + quesIdx} onClick={props.manageQuesHandler}></i>
                        ) : (
                            <></>
                        )}
                    </div>
                    <div>
                        <div className="center-sports-report center-bg-gray center-border-bottom">
                            <div className="center-tpd5 flex-basic">
                                <label>문제 </label>
                                <input
                                    id={'ques_' + quesIdx}
                                    type="text"
                                    className="center-input-sports-in"
                                    value={question.question}
                                    onChange={props.inputQuestionHandler}
                                />
                            </div>
                        </div>
                        <div className="center-sports-report center-bg-gray">
                            {question.items.map((item, itemIdx) => {
                                return (
                                    <div className="center-tpd5 flex-basic" key={itemIdx}>
                                        <label>
                                            옵션 {item.itemNo}&nbsp;&nbsp;
                                            {itemIdx + 1 > 2 ? (
                                                <i
                                                    className="fas fa-minus-square"
                                                    id={'item_' + quesIdx + '_' + itemIdx}
                                                    onClick={props.manageItemsHandler}
                                                ></i>
                                            ) : (
                                                <></>
                                            )}
                                        </label>
                                        <input
                                            type="text"
                                            id={quesIdx + '_' + itemIdx}
                                            value={item.content}
                                            onChange={props.inputQuestionHandler}
                                            className="center-input-sports-in"
                                        />
                                    </div>
                                );
                            })}
                        </div>
                        <div>
                            <button id={'addItem_' + quesIdx} className="center-plus-option" onClick={props.manageItemsHandler}>
                                <i className="fas fa-plus-circle"></i>
                                답안 추가하기
                            </button>
                        </div>
                    </div>
                </div>
            </>
        );
    });
    return <>{questionElem}</>;
}
