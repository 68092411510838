import { useEffect, useState } from 'react';
import Schedule from '../../domain/schedule/Schedule';
import TeamScheduleServiceImpl from '../../service/schedule/impl/TeamScheduleServiceImpl';

const teamScheduleService = new TeamScheduleServiceImpl();
function useTeamScheduleModify(updateSchedule?: Schedule) {
    const [updateDate, setUpdateDate] = useState<Date>();
    useEffect(() => {
        if(updateSchedule) {
            if(!updateSchedule.scheduleNo) throw new Error("스케쥴 고유 번호가 필요합니다.");
            else {
                teamScheduleService.updateSchedule(updateSchedule)
                .then(() => {
                    setUpdateDate(new Date());
                });
            }
        }
    }, [updateSchedule]);

    return updateDate;
}

export default useTeamScheduleModify;