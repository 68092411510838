export default class DateUtils {
    static getCurrentYear() {
        return new Date().getFullYear();
    }

    static getCurrentMonth() {
        return new Date().getMonth() + 1;
    }

    static getCurrentDate() {
        return new Date().getDate();
    }

    static getDayGap = (d1: Date, d2: Date) => {
        const date1 = new Date(d1.setHours(0, 0, 0, 0));
        const date2 = new Date(d2.setHours(0, 0, 0, 0));
        return Math.floor(Math.abs((date1.getTime() - date2.getTime()) / 1000 / 60 / 60 / 24));
    };
    static getStrDayGap = (d1: string, d2: string) => {
        let date1: Date = new Date(d1);
        let date2: Date = new Date(d2);

        let gap = DateUtils.getDayGap(date1, date2);
        return gap;
    };

    static addMonth(date: Date, month: number) {
        date.setDate(date.getMonth() + month);
        // return date;
    }

    static addDay(date: Date, day: number) {
        const result = new Date(date.getTime());
        result.setDate(result.getDate() + day);
        return result;
    }

    static convertStrToDate(dateStr: string | undefined): Date {
        let date: Date = new Date();
        if (dateStr) {
            date = new Date(dateStr);
        }

        return date;
    }

    static getBeforeDay(startDay: string, endDay: string | undefined): string {
        if (endDay && endDay < startDay) return endDay;
        else return startDay;
    }

    static getAfterDay(startDay: string, endDay: string | undefined): string {
        if (endDay && startDay > endDay) return startDay;
        else return endDay ? endDay : DateUtils.getDateForm(new Date());
    }

    static getCompareDate(c1: string | undefined, c2: string | undefined): number {
        let compare: number = -1;
        // c1 > c2 then 1
        // c1 < c2 then 2
        // c1 == c2 then 0
        // otherwise -1

        if (c1 && c2) {
            if (c1 > c2) compare = 1;
            else if (c1 < c2) compare = 2;
            else if (c1 === c2) compare = 0;
        }

        return compare;
    }

    static getDate(startDay: string, endDay: string) {
        startDay = DateUtils.getBeforeDay(startDay, endDay);
        endDay = DateUtils.getAfterDay(startDay, endDay);

        const startDate = new Date(startDay);
        const endDate = new Date(endDay);

        return {
            startDate,
            endDate,
        };
    }

    static getDateForm(date: Date) {
        let month = date.getMonth() + 1;
        return date.getFullYear() + '-' + (month < 10 ? '0' + month : month) + '-' + (date.getDate() < 10 ? '0' + date.getDate() : date.getDate());
    }

    static dayOfWeek: string[] = new Array('일', '월', '화', '수', '목', '금', '토');
    static getDayOfWeek(dateStr: string | undefined) {
        if (dateStr) {
            const date = new Date(dateStr);
            let idx = date.getDay();
            return this.dayOfWeek[idx];
        }
    }

    static getHours(): string[] {
        let hours = new Array<string>();

        for (var hour = 0; hour < 24; hour++) {
            if (hour < 10) {
                hours.push(`0${hour}`);
            } else {
                hours.push(hour + '');
            }
        }

        return hours;
    }

    static getMinutes(): string[] {
        let mins = new Array<string>();

        for (var min = 0; min <= 60; min++) {
            if (min < 10) {
                mins.push(`0${min}`);
            } else {
                mins.push(min + '');
            }
        }

        return mins;
    }

    static getYears(startYear: number, endYear: number): number[] {
        const years = [];
        for (let year = startYear; year <= endYear; year++) {
            years.push(year);
        }

        return years;
    }

    static getMonths(): string[] {
        let months = new Array<string>();

        for (var month = 1; month <= 12; month++) {
            if (month < 10) months.push(`0${month}`);
            else months.push(month + '');
        }

        return months;
    }

    static getDays(): string[] {
        let days = new Array<string>();
        for (var day = 1; day <= 31; day++) {
            if (day < 10) days.push(`0${day}`);
            else days.push(day + '');
        }
        return days;
    }

    static getToday(): string {
        var date = new Date();
        var year = date.getFullYear();
        var month = ('0' + (1 + date.getMonth())).slice(-2);
        var day = ('0' + date.getDate()).slice(-2);

        return year + '-' + month + '-' + day;
    }
}
