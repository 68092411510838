import CommonSearchCondition from '../../common/param/CommonSearchCondition';
class ReservationSearchCondition extends CommonSearchCondition {
    startDay?: string;
    month?: string;
    teamNos?: string;
    parentInfraCategoryNo?: string;
    reservationCodeIds?: string;
    reservaterNos?: string;
}

export default ReservationSearchCondition;