import React, { useEffect, useState } from 'react';
import AnswerItem from '../../../../domain/AnswerItem';
import QuestionnariesFormProps from './QuestionnariesFormsProps';
import ReportDataParam from '../../../../../../domain/report/param/ReportDataParam';
import ReportTypeEnum from '../../../../../../domain/report/param/ReportTypeEnum';
import axios from 'axios';
import APIServerConfig from '../../../../../../config/APIServerConfig';

export default function StressCheckForm(props: QuestionnariesFormProps) {
    const putAnswerItem = (qNum: number, answerNumber: number) => {
        if (props.answerItems) {
            const newAnswerItem = AnswerItem.getNewAnswerItem(qNum, answerNumber);
            // props.answerItems.set(qNum, newAnswerItem);
            if (props.setAnswer) props.setAnswer(qNum, newAnswerItem);
        }
    };

    const getCheckOnClassName = (qNum: number, answerNumber: number): string => {
        if (props.answerItems) {
            if (props.answerItems.get(qNum)?.itemNoOfAnswer == answerNumber) {
                return 'check-on';
            } else {
                return '';
            }
        } else {
            return '';
        }
    };

    const isCheck = (qNum: number, answerNumber: number): boolean => {
        if (props.answerItems) {
            if (props.answerItems.get(qNum)?.itemNoOfAnswer == answerNumber) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    };

    const [reportData, setReportData] = useState<any>();

    useEffect(() => {
        if (props.answerItems) {
            var reportData: any = {};
            props.answerItems.forEach((answerItem: AnswerItem, index: number) => {
                reportData[`questionNum${answerItem.questionNum}`] = String(answerItem.itemNoOfAnswer);
            });

            setReportData(reportData);
        }
    }, [props.answerItems]);

    return (
        <>
            <div className="center-center">스트레스 측정</div>
            {props.resultPrintable ? (
                <>
                    <form action={`${APIServerConfig.resourceAPIURL}/report/pdf`} method="POST">
                        <input type="hidden" name="reportType" value={ReportTypeEnum.BEPSI_K}></input>
                        <input type="hidden" name="reportData" value={JSON.stringify(reportData)}></input>
                        <input type="submit" value="설문 결과 PDF 내려받기" />
                    </form>
                    <form action={`${APIServerConfig.resourceAPIURL}/report/xlsx`} method="POST">
                        <input type="hidden" name="reportType" value={ReportTypeEnum.BEPSI_K}></input>
                        <input type="hidden" name="reportData" value={JSON.stringify(reportData)}></input>
                        <input type="submit" value="설문 결과 엑셀 내려받기" />
                    </form>
                </>
            ) : (
                <></>
            )}

            <div className="tab-story-number">
                <h3>
                    <span className="center-blue">Appendix 1.</span> Korean-translated Brief Encounter Psychosocial Instrument (BEPSI-K)
                </h3>
                <h4>
                    <b> BEPSI-K </b>
                </h4>
                <table className="center-check-table-full" cellPadding="0" cellSpacing="0">
                    <thead>
                        <tr>
                            <th>
                                다음의 질문들은 자신이 느끼는 스트레스를 측정하고자 만든 설문입니다.
                                <br />잘 읽어 보신 후에 해당 항목에 <i className="fa fa-check"></i>
                                표시를 하시기 바랍니다.
                            </th>
                        </tr>
                    </thead>
                    <tbody className="center-td-widthfull">
                        <tr>
                            <th className="center-bgcolor-blue">
                                1. 지난 한 달 동안 살아가는데, 정신적·신체적으로 감당하기 힘들다고 느끼신 적이 있습니까?
                            </th>
                        </tr>
                        <tr>
                            <th>
                                <input
                                    checked={isCheck(1, 1)}
                                    onChange={() => {
                                        putAnswerItem(1, 1);
                                    }}
                                    type="checkbox"
                                />
                                ① 항상 있었다
                                <br />
                                <input
                                    checked={isCheck(1, 2)}
                                    onChange={() => {
                                        putAnswerItem(1, 2);
                                    }}
                                    type="checkbox"
                                />
                                ② 거의 언제나 있었다
                                <br />
                                <input
                                    checked={isCheck(1, 3)}
                                    onChange={() => {
                                        putAnswerItem(1, 3);
                                    }}
                                    type="checkbox"
                                />
                                ③ 종종 여러번 있었다
                                <br />
                                <input
                                    checked={isCheck(1, 4)}
                                    onChange={() => {
                                        putAnswerItem(1, 4);
                                    }}
                                    type="checkbox"
                                />
                                ④ 간혹 있었다
                                <br />
                                <input
                                    checked={isCheck(1, 5)}
                                    onChange={() => {
                                        putAnswerItem(1, 5);
                                    }}
                                    type="checkbox"
                                />
                                ⑤ 전혀 없었다
                            </th>
                        </tr>
                        <tr>
                            <th className="center-bgcolor-blue">
                                2. 지난 한 달 동안, 자신의 생활신념에 따라 살려고 애쓰시다가 좌절을 느끼신 적이 있습니까?
                            </th>
                        </tr>
                        <tr>
                            <th>
                                <input
                                    checked={isCheck(2, 1)}
                                    onChange={() => {
                                        putAnswerItem(2, 1);
                                    }}
                                    type="checkbox"
                                />
                                ① 항상 있었다
                                <br />
                                <input
                                    checked={isCheck(2, 2)}
                                    onChange={() => {
                                        putAnswerItem(2, 2);
                                    }}
                                    type="checkbox"
                                />
                                ② 거의 언제나 있었다
                                <br />
                                <input
                                    checked={isCheck(2, 3)}
                                    onChange={() => {
                                        putAnswerItem(2, 3);
                                    }}
                                    type="checkbox"
                                />
                                ③ 종종 여러번 있었다
                                <br />
                                <input
                                    checked={isCheck(2, 4)}
                                    onChange={() => {
                                        putAnswerItem(2, 4);
                                    }}
                                    type="checkbox"
                                />
                                ④ 간혹 있었다
                                <br />
                                <input
                                    checked={isCheck(2, 5)}
                                    onChange={() => {
                                        putAnswerItem(2, 5);
                                    }}
                                    type="checkbox"
                                />
                                ⑤ 전혀 없었다
                            </th>
                        </tr>
                        <tr>
                            <th className="center-bgcolor-blue">
                                3. 지난 한 달 동안, 한 인간으로서 기본적인 요구가 충족되지 않았다고 느끼신 적이 있습니까?
                            </th>
                        </tr>
                        <tr>
                            <th>
                                <input
                                    checked={isCheck(3, 1)}
                                    onChange={() => {
                                        putAnswerItem(3, 1);
                                    }}
                                    type="checkbox"
                                />
                                ① 항상 있었다
                                <br />
                                <input
                                    checked={isCheck(3, 2)}
                                    onChange={() => {
                                        putAnswerItem(3, 2);
                                    }}
                                    type="checkbox"
                                />
                                ② 거의 언제나 있었다
                                <br />
                                <input
                                    checked={isCheck(3, 3)}
                                    onChange={() => {
                                        putAnswerItem(3, 3);
                                    }}
                                    type="checkbox"
                                />
                                ③ 종종 여러번 있었다
                                <br />
                                <input
                                    checked={isCheck(3, 4)}
                                    onChange={() => {
                                        putAnswerItem(3, 4);
                                    }}
                                    type="checkbox"
                                />
                                ④ 간혹 있었다
                                <br />
                                <input
                                    checked={isCheck(3, 5)}
                                    onChange={() => {
                                        putAnswerItem(3, 5);
                                    }}
                                    type="checkbox"
                                />
                                ⑤ 전혀 없었다
                            </th>
                        </tr>
                        <tr>
                            <th className="center-bgcolor-blue">4. 지난 한 달 동안, 미래에 대해 불확실하게 느끼거나 불안해 하신 적이 있습니까?</th>
                        </tr>
                        <tr>
                            <th>
                                <input
                                    checked={isCheck(4, 1)}
                                    onChange={() => {
                                        putAnswerItem(4, 1);
                                    }}
                                    type="checkbox"
                                />
                                ① 항상 있었다
                                <br />
                                <input
                                    checked={isCheck(4, 2)}
                                    onChange={() => {
                                        putAnswerItem(4, 2);
                                    }}
                                    type="checkbox"
                                />
                                ② 거의 언제나 있었다
                                <br />
                                <input
                                    checked={isCheck(4, 3)}
                                    onChange={() => {
                                        putAnswerItem(4, 3);
                                    }}
                                    type="checkbox"
                                />
                                ③ 종종 여러번 있었다
                                <br />
                                <input
                                    checked={isCheck(4, 4)}
                                    onChange={() => {
                                        putAnswerItem(4, 4);
                                    }}
                                    type="checkbox"
                                />
                                ④ 간혹 있었다
                                <br />
                                <input
                                    checked={isCheck(4, 5)}
                                    onChange={() => {
                                        putAnswerItem(4, 5);
                                    }}
                                    type="checkbox"
                                />
                                ⑤ 전혀 없었다
                            </th>
                        </tr>
                        <tr>
                            <th className="center-bgcolor-blue"> 지난 한 달 동안, 할 일이 너무 많아 정말 중요한 일들을 잊으신 적이 있습니까?</th>
                        </tr>
                        <tr className="center-bottom-black">
                            <th>
                                <input
                                    checked={isCheck(5, 1)}
                                    onChange={() => {
                                        putAnswerItem(5, 1);
                                    }}
                                    type="checkbox"
                                />
                                ① 항상 있었다
                                <br />
                                <input
                                    checked={isCheck(5, 2)}
                                    onChange={() => {
                                        putAnswerItem(5, 2);
                                    }}
                                    type="checkbox"
                                />
                                ② 거의 언제나 있었다
                                <br />
                                <input
                                    checked={isCheck(5, 3)}
                                    onChange={() => {
                                        putAnswerItem(5, 3);
                                    }}
                                    type="checkbox"
                                />
                                ③ 종종 여러번 있었다
                                <br />
                                <input
                                    checked={isCheck(5, 4)}
                                    onChange={() => {
                                        putAnswerItem(5, 4);
                                    }}
                                    type="checkbox"
                                />
                                ④ 간혹 있었다
                                <br />
                                <input
                                    checked={isCheck(5, 5)}
                                    onChange={() => {
                                        putAnswerItem(5, 5);
                                    }}
                                    type="checkbox"
                                />
                                ⑤ 전혀 없었다
                            </th>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div className="tab-story-number">
                <h3>
                    <span className="center-blue">Appendix 2.</span> Cognitive Stress Response Scale (CSRS)
                </h3>
                <h4>
                    <b>인지적 스트레스반응 척도(CSRS) </b>
                </h4>
                <table className="center-check-table-basic" cellPadding="0" cellSpacing="0">
                    <tbody>
                        <tr>
                            <th className="center-bgcolor-blue"></th>
                            <td className="center-bgcolor-blue">
                                전혀
                                <br />
                                그렇지
                                <br />
                                않다
                            </td>
                            <td className="center-bgcolor-blue">
                                약간
                                <br />
                                그렇다
                            </td>
                            <td className="center-bgcolor-blue">
                                웬만큼
                                <br />
                                그렇다
                            </td>
                            <td className="center-bgcolor-blue">
                                상당히
                                <br />
                                그렇다
                            </td>
                            <td className="center-bgcolor-blue">
                                아주
                                <br />
                                그렇다
                            </td>
                        </tr>
                        <tr>
                            <th>울고 싶다</th>
                            <td>
                                <input
                                    checked={isCheck(6, 1)}
                                    onChange={() => {
                                        putAnswerItem(6, 1);
                                    }}
                                    type="checkbox"
                                />
                            </td>
                            <td>
                                <input
                                    checked={isCheck(6, 2)}
                                    onChange={() => {
                                        putAnswerItem(6, 2);
                                    }}
                                    type="checkbox"
                                />
                            </td>
                            <td>
                                <input
                                    checked={isCheck(6, 3)}
                                    onChange={() => {
                                        putAnswerItem(6, 3);
                                    }}
                                    type="checkbox"
                                />
                            </td>
                            <td>
                                <input
                                    checked={isCheck(6, 4)}
                                    onChange={() => {
                                        putAnswerItem(6, 4);
                                    }}
                                    type="checkbox"
                                />
                            </td>
                            <td>
                                <input
                                    checked={isCheck(6, 5)}
                                    onChange={() => {
                                        putAnswerItem(6, 5);
                                    }}
                                    type="checkbox"
                                />
                            </td>
                        </tr>
                        <tr>
                            <th>의심이 많아졌다</th>
                            <td>
                                <input
                                    checked={isCheck(7, 1)}
                                    onChange={() => {
                                        putAnswerItem(7, 1);
                                    }}
                                    type="checkbox"
                                />
                            </td>
                            <td>
                                <input
                                    checked={isCheck(7, 2)}
                                    onChange={() => {
                                        putAnswerItem(7, 2);
                                    }}
                                    type="checkbox"
                                />
                            </td>
                            <td>
                                <input
                                    checked={isCheck(7, 3)}
                                    onChange={() => {
                                        putAnswerItem(7, 3);
                                    }}
                                    type="checkbox"
                                />
                            </td>
                            <td>
                                <input
                                    checked={isCheck(7, 4)}
                                    onChange={() => {
                                        putAnswerItem(7, 4);
                                    }}
                                    type="checkbox"
                                />
                            </td>
                            <td>
                                <input
                                    checked={isCheck(7, 5)}
                                    onChange={() => {
                                        putAnswerItem(7, 5);
                                    }}
                                    type="checkbox"
                                />
                            </td>
                        </tr>
                        <tr>
                            <th>무엇인가 부수고 싶다</th>
                            <td>
                                <input
                                    checked={isCheck(8, 1)}
                                    onChange={() => {
                                        putAnswerItem(8, 1);
                                    }}
                                    type="checkbox"
                                />
                            </td>
                            <td>
                                <input
                                    checked={isCheck(8, 2)}
                                    onChange={() => {
                                        putAnswerItem(8, 2);
                                    }}
                                    type="checkbox"
                                />
                            </td>
                            <td>
                                <input
                                    checked={isCheck(8, 3)}
                                    onChange={() => {
                                        putAnswerItem(8, 3);
                                    }}
                                    type="checkbox"
                                />
                            </td>
                            <td>
                                <input
                                    checked={isCheck(8, 4)}
                                    onChange={() => {
                                        putAnswerItem(8, 4);
                                    }}
                                    type="checkbox"
                                />
                            </td>
                            <td>
                                <input
                                    checked={isCheck(8, 5)}
                                    onChange={() => {
                                        putAnswerItem(8, 5);
                                    }}
                                    type="checkbox"
                                />
                            </td>
                        </tr>
                        <tr>
                            <th>나는 무능한 사람이다</th>
                            <td>
                                <input
                                    checked={isCheck(9, 1)}
                                    onChange={() => {
                                        putAnswerItem(9, 1);
                                    }}
                                    type="checkbox"
                                />
                            </td>
                            <td>
                                <input
                                    checked={isCheck(9, 2)}
                                    onChange={() => {
                                        putAnswerItem(9, 2);
                                    }}
                                    type="checkbox"
                                />
                            </td>
                            <td>
                                <input
                                    checked={isCheck(9, 3)}
                                    onChange={() => {
                                        putAnswerItem(9, 3);
                                    }}
                                    type="checkbox"
                                />
                            </td>
                            <td>
                                <input
                                    checked={isCheck(9, 4)}
                                    onChange={() => {
                                        putAnswerItem(9, 4);
                                    }}
                                    type="checkbox"
                                />
                            </td>
                            <td>
                                <input
                                    checked={isCheck(9, 5)}
                                    onChange={() => {
                                        putAnswerItem(9, 5);
                                    }}
                                    type="checkbox"
                                />
                            </td>
                        </tr>
                        <tr>
                            <th>삶의 의미를 잃어 버렸다</th>
                            <td>
                                <input
                                    checked={isCheck(10, 1)}
                                    onChange={() => {
                                        putAnswerItem(10, 1);
                                    }}
                                    type="checkbox"
                                />
                            </td>
                            <td>
                                <input
                                    checked={isCheck(10, 2)}
                                    onChange={() => {
                                        putAnswerItem(10, 2);
                                    }}
                                    type="checkbox"
                                />
                            </td>
                            <td>
                                <input
                                    checked={isCheck(10, 3)}
                                    onChange={() => {
                                        putAnswerItem(10, 3);
                                    }}
                                    type="checkbox"
                                />
                            </td>
                            <td>
                                <input
                                    checked={isCheck(10, 4)}
                                    onChange={() => {
                                        putAnswerItem(10, 4);
                                    }}
                                    type="checkbox"
                                />
                            </td>
                            <td>
                                <input
                                    checked={isCheck(10, 5)}
                                    onChange={() => {
                                        putAnswerItem(10, 5);
                                    }}
                                    type="checkbox"
                                />
                            </td>
                        </tr>
                        <tr>
                            <th>어떤 일을 하던지 간에 완벽해야 한다</th>
                            <td>
                                <input
                                    checked={isCheck(11, 1)}
                                    onChange={() => {
                                        putAnswerItem(11, 1);
                                    }}
                                    type="checkbox"
                                />
                            </td>
                            <td>
                                <input
                                    checked={isCheck(11, 2)}
                                    onChange={() => {
                                        putAnswerItem(11, 2);
                                    }}
                                    type="checkbox"
                                />
                            </td>
                            <td>
                                <input
                                    checked={isCheck(11, 3)}
                                    onChange={() => {
                                        putAnswerItem(11, 3);
                                    }}
                                    type="checkbox"
                                />
                            </td>
                            <td>
                                <input
                                    checked={isCheck(11, 4)}
                                    onChange={() => {
                                        putAnswerItem(11, 4);
                                    }}
                                    type="checkbox"
                                />
                            </td>
                            <td>
                                <input
                                    checked={isCheck(11, 5)}
                                    onChange={() => {
                                        putAnswerItem(11, 5);
                                    }}
                                    type="checkbox"
                                />
                            </td>
                        </tr>
                        <tr>
                            <th>한 가지 생각에서 헤어나지 못한다</th>
                            <td>
                                <input
                                    checked={isCheck(12, 1)}
                                    onChange={() => {
                                        putAnswerItem(12, 1);
                                    }}
                                    type="checkbox"
                                />
                            </td>
                            <td>
                                <input
                                    checked={isCheck(12, 2)}
                                    onChange={() => {
                                        putAnswerItem(12, 2);
                                    }}
                                    type="checkbox"
                                />
                            </td>
                            <td>
                                <input
                                    checked={isCheck(12, 3)}
                                    onChange={() => {
                                        putAnswerItem(12, 3);
                                    }}
                                    type="checkbox"
                                />
                            </td>
                            <td>
                                <input
                                    checked={isCheck(12, 4)}
                                    onChange={() => {
                                        putAnswerItem(12, 4);
                                    }}
                                    type="checkbox"
                                />
                            </td>
                            <td>
                                <input
                                    checked={isCheck(12, 5)}
                                    onChange={() => {
                                        putAnswerItem(12, 5);
                                    }}
                                    type="checkbox"
                                />
                            </td>
                        </tr>
                        <tr>
                            <th>말하기 싫다</th>
                            <td>
                                <input
                                    checked={isCheck(13, 1)}
                                    onChange={() => {
                                        putAnswerItem(13, 1);
                                    }}
                                    type="checkbox"
                                />
                            </td>
                            <td>
                                <input
                                    checked={isCheck(13, 2)}
                                    onChange={() => {
                                        putAnswerItem(13, 2);
                                    }}
                                    type="checkbox"
                                />
                            </td>
                            <td>
                                <input
                                    checked={isCheck(13, 3)}
                                    onChange={() => {
                                        putAnswerItem(13, 3);
                                    }}
                                    type="checkbox"
                                />
                            </td>
                            <td>
                                <input
                                    checked={isCheck(13, 4)}
                                    onChange={() => {
                                        putAnswerItem(13, 4);
                                    }}
                                    type="checkbox"
                                />
                            </td>
                            <td>
                                <input
                                    checked={isCheck(13, 5)}
                                    onChange={() => {
                                        putAnswerItem(13, 5);
                                    }}
                                    type="checkbox"
                                />
                            </td>
                        </tr>
                        <tr>
                            <th>누군가를 때리고 싶다</th>
                            <td>
                                <input
                                    checked={isCheck(14, 1)}
                                    onChange={() => {
                                        putAnswerItem(14, 1);
                                    }}
                                    type="checkbox"
                                />
                            </td>
                            <td>
                                <input
                                    checked={isCheck(14, 2)}
                                    onChange={() => {
                                        putAnswerItem(14, 2);
                                    }}
                                    type="checkbox"
                                />
                            </td>
                            <td>
                                <input
                                    checked={isCheck(14, 3)}
                                    onChange={() => {
                                        putAnswerItem(14, 3);
                                    }}
                                    type="checkbox"
                                />
                            </td>
                            <td>
                                <input
                                    checked={isCheck(14, 4)}
                                    onChange={() => {
                                        putAnswerItem(14, 4);
                                    }}
                                    type="checkbox"
                                />
                            </td>
                            <td>
                                <input
                                    checked={isCheck(14, 5)}
                                    onChange={() => {
                                        putAnswerItem(14, 5);
                                    }}
                                    type="checkbox"
                                />
                            </td>
                        </tr>
                        <tr>
                            <th>나는 아무 쓸모없는 사람이다</th>
                            <td>
                                <input
                                    checked={isCheck(15, 1)}
                                    onChange={() => {
                                        putAnswerItem(15, 1);
                                    }}
                                    type="checkbox"
                                />
                            </td>
                            <td>
                                <input
                                    checked={isCheck(15, 2)}
                                    onChange={() => {
                                        putAnswerItem(15, 2);
                                    }}
                                    type="checkbox"
                                />
                            </td>
                            <td>
                                <input
                                    checked={isCheck(15, 3)}
                                    onChange={() => {
                                        putAnswerItem(15, 3);
                                    }}
                                    type="checkbox"
                                />
                            </td>
                            <td>
                                <input
                                    checked={isCheck(15, 4)}
                                    onChange={() => {
                                        putAnswerItem(15, 4);
                                    }}
                                    type="checkbox"
                                />
                            </td>
                            <td>
                                <input
                                    checked={isCheck(15, 5)}
                                    onChange={() => {
                                        putAnswerItem(15, 5);
                                    }}
                                    type="checkbox"
                                />
                            </td>
                        </tr>
                        <tr>
                            <th>자신감을 잃었다</th>
                            <td>
                                <input
                                    checked={isCheck(16, 1)}
                                    onChange={() => {
                                        putAnswerItem(16, 1);
                                    }}
                                    type="checkbox"
                                />
                            </td>
                            <td>
                                <input
                                    checked={isCheck(16, 2)}
                                    onChange={() => {
                                        putAnswerItem(16, 2);
                                    }}
                                    type="checkbox"
                                />
                            </td>
                            <td>
                                <input
                                    checked={isCheck(16, 3)}
                                    onChange={() => {
                                        putAnswerItem(16, 3);
                                    }}
                                    type="checkbox"
                                />
                            </td>
                            <td>
                                <input
                                    checked={isCheck(16, 4)}
                                    onChange={() => {
                                        putAnswerItem(16, 4);
                                    }}
                                    type="checkbox"
                                />
                            </td>
                            <td>
                                <input
                                    checked={isCheck(16, 5)}
                                    onChange={() => {
                                        putAnswerItem(16, 5);
                                    }}
                                    type="checkbox"
                                />
                            </td>
                        </tr>
                        <tr>
                            <th>누구에게 욕을 먹어서는 안된다</th>
                            <td>
                                <input
                                    checked={isCheck(17, 1)}
                                    onChange={() => {
                                        putAnswerItem(17, 1);
                                    }}
                                    type="checkbox"
                                />
                            </td>
                            <td>
                                <input
                                    checked={isCheck(17, 2)}
                                    onChange={() => {
                                        putAnswerItem(17, 2);
                                    }}
                                    type="checkbox"
                                />
                            </td>
                            <td>
                                <input
                                    checked={isCheck(17, 3)}
                                    onChange={() => {
                                        putAnswerItem(17, 3);
                                    }}
                                    type="checkbox"
                                />
                            </td>
                            <td>
                                <input
                                    checked={isCheck(17, 4)}
                                    onChange={() => {
                                        putAnswerItem(17, 4);
                                    }}
                                    type="checkbox"
                                />
                            </td>
                            <td>
                                <input
                                    checked={isCheck(17, 5)}
                                    onChange={() => {
                                        putAnswerItem(17, 5);
                                    }}
                                    type="checkbox"
                                />
                            </td>
                        </tr>
                        <tr>
                            <th>죽고 싶다</th>
                            <td>
                                <input
                                    checked={isCheck(18, 1)}
                                    onChange={() => {
                                        putAnswerItem(18, 1);
                                    }}
                                    type="checkbox"
                                />
                            </td>
                            <td>
                                <input
                                    checked={isCheck(18, 2)}
                                    onChange={() => {
                                        putAnswerItem(18, 2);
                                    }}
                                    type="checkbox"
                                />
                            </td>
                            <td>
                                <input
                                    checked={isCheck(18, 3)}
                                    onChange={() => {
                                        putAnswerItem(18, 3);
                                    }}
                                    type="checkbox"
                                />
                            </td>
                            <td>
                                <input
                                    checked={isCheck(18, 4)}
                                    onChange={() => {
                                        putAnswerItem(18, 4);
                                    }}
                                    type="checkbox"
                                />
                            </td>
                            <td>
                                <input
                                    checked={isCheck(18, 5)}
                                    onChange={() => {
                                        putAnswerItem(18, 5);
                                    }}
                                    type="checkbox"
                                />
                            </td>
                        </tr>
                        <tr>
                            <th>사람들이 나를 싫어한다</th>
                            <td>
                                <input
                                    checked={isCheck(19, 1)}
                                    onChange={() => {
                                        putAnswerItem(19, 1);
                                    }}
                                    type="checkbox"
                                />
                            </td>
                            <td>
                                <input
                                    checked={isCheck(19, 2)}
                                    onChange={() => {
                                        putAnswerItem(19, 2);
                                    }}
                                    type="checkbox"
                                />
                            </td>
                            <td>
                                <input
                                    checked={isCheck(19, 3)}
                                    onChange={() => {
                                        putAnswerItem(19, 3);
                                    }}
                                    type="checkbox"
                                />
                            </td>
                            <td>
                                <input
                                    checked={isCheck(19, 4)}
                                    onChange={() => {
                                        putAnswerItem(19, 4);
                                    }}
                                    type="checkbox"
                                />
                            </td>
                            <td>
                                <input
                                    checked={isCheck(19, 5)}
                                    onChange={() => {
                                        putAnswerItem(19, 5);
                                    }}
                                    type="checkbox"
                                />
                            </td>
                        </tr>
                        <tr>
                            <th>잘 하는게 하나도 없다</th>
                            <td>
                                <input
                                    checked={isCheck(20, 1)}
                                    onChange={() => {
                                        putAnswerItem(20, 1);
                                    }}
                                    type="checkbox"
                                />
                            </td>
                            <td>
                                <input
                                    checked={isCheck(20, 2)}
                                    onChange={() => {
                                        putAnswerItem(20, 2);
                                    }}
                                    type="checkbox"
                                />
                            </td>
                            <td>
                                <input
                                    checked={isCheck(20, 3)}
                                    onChange={() => {
                                        putAnswerItem(20, 3);
                                    }}
                                    type="checkbox"
                                />
                            </td>
                            <td>
                                <input
                                    checked={isCheck(20, 4)}
                                    onChange={() => {
                                        putAnswerItem(20, 4);
                                    }}
                                    type="checkbox"
                                />
                            </td>
                            <td>
                                <input
                                    checked={isCheck(20, 5)}
                                    onChange={() => {
                                        putAnswerItem(20, 5);
                                    }}
                                    type="checkbox"
                                />
                            </td>
                        </tr>
                        <tr>
                            <th>내가 하는 일에 전망이 없다</th>
                            <td>
                                <input
                                    checked={isCheck(21, 1)}
                                    onChange={() => {
                                        putAnswerItem(21, 1);
                                    }}
                                    type="checkbox"
                                />
                            </td>
                            <td>
                                <input
                                    checked={isCheck(21, 2)}
                                    onChange={() => {
                                        putAnswerItem(21, 2);
                                    }}
                                    type="checkbox"
                                />
                            </td>
                            <td>
                                <input
                                    checked={isCheck(21, 3)}
                                    onChange={() => {
                                        putAnswerItem(21, 3);
                                    }}
                                    type="checkbox"
                                />
                            </td>
                            <td>
                                <input
                                    checked={isCheck(21, 4)}
                                    onChange={() => {
                                        putAnswerItem(21, 4);
                                    }}
                                    type="checkbox"
                                />
                            </td>
                            <td>
                                <input
                                    checked={isCheck(21, 5)}
                                    onChange={() => {
                                        putAnswerItem(21, 5);
                                    }}
                                    type="checkbox"
                                />
                            </td>
                        </tr>
                        <tr>
                            <th>일하기 싫다</th>
                            <td>
                                <input
                                    checked={isCheck(22, 1)}
                                    onChange={() => {
                                        putAnswerItem(22, 1);
                                    }}
                                    type="checkbox"
                                />
                            </td>
                            <td>
                                <input
                                    checked={isCheck(22, 2)}
                                    onChange={() => {
                                        putAnswerItem(22, 2);
                                    }}
                                    type="checkbox"
                                />
                            </td>
                            <td>
                                <input
                                    checked={isCheck(22, 3)}
                                    onChange={() => {
                                        putAnswerItem(22, 3);
                                    }}
                                    type="checkbox"
                                />
                            </td>
                            <td>
                                <input
                                    checked={isCheck(22, 4)}
                                    onChange={() => {
                                        putAnswerItem(22, 4);
                                    }}
                                    type="checkbox"
                                />
                            </td>
                            <td>
                                <input
                                    checked={isCheck(22, 5)}
                                    onChange={() => {
                                        putAnswerItem(22, 5);
                                    }}
                                    type="checkbox"
                                />
                            </td>
                        </tr>
                        <tr>
                            <th>아무런 생각을 하고 싶지 않다</th>
                            <td>
                                <input
                                    checked={isCheck(23, 1)}
                                    onChange={() => {
                                        putAnswerItem(23, 1);
                                    }}
                                    type="checkbox"
                                />
                            </td>
                            <td>
                                <input
                                    checked={isCheck(23, 2)}
                                    onChange={() => {
                                        putAnswerItem(23, 2);
                                    }}
                                    type="checkbox"
                                />
                            </td>
                            <td>
                                <input
                                    checked={isCheck(23, 3)}
                                    onChange={() => {
                                        putAnswerItem(23, 3);
                                    }}
                                    type="checkbox"
                                />
                            </td>
                            <td>
                                <input
                                    checked={isCheck(23, 4)}
                                    onChange={() => {
                                        putAnswerItem(23, 4);
                                    }}
                                    type="checkbox"
                                />
                            </td>
                            <td>
                                <input
                                    checked={isCheck(23, 5)}
                                    onChange={() => {
                                        putAnswerItem(23, 5);
                                    }}
                                    type="checkbox"
                                />
                            </td>
                        </tr>
                        <tr>
                            <th>누군가를 죽이고 싶다</th>
                            <td>
                                <input
                                    checked={isCheck(24, 1)}
                                    onChange={() => {
                                        putAnswerItem(24, 1);
                                    }}
                                    type="checkbox"
                                />
                            </td>
                            <td>
                                <input
                                    checked={isCheck(24, 2)}
                                    onChange={() => {
                                        putAnswerItem(24, 2);
                                    }}
                                    type="checkbox"
                                />
                            </td>
                            <td>
                                <input
                                    checked={isCheck(24, 3)}
                                    onChange={() => {
                                        putAnswerItem(24, 3);
                                    }}
                                    type="checkbox"
                                />
                            </td>
                            <td>
                                <input
                                    checked={isCheck(24, 4)}
                                    onChange={() => {
                                        putAnswerItem(24, 4);
                                    }}
                                    type="checkbox"
                                />
                            </td>
                            <td>
                                <input
                                    checked={isCheck(24, 5)}
                                    onChange={() => {
                                        putAnswerItem(24, 5);
                                    }}
                                    type="checkbox"
                                />
                            </td>
                        </tr>
                        <tr>
                            <th>나는 인생의 낙오자(또는 실패자)다</th>
                            <td>
                                <input
                                    checked={isCheck(25, 1)}
                                    onChange={() => {
                                        putAnswerItem(25, 1);
                                    }}
                                    type="checkbox"
                                />
                            </td>
                            <td>
                                <input
                                    checked={isCheck(25, 2)}
                                    onChange={() => {
                                        putAnswerItem(25, 2);
                                    }}
                                    type="checkbox"
                                />
                            </td>
                            <td>
                                <input
                                    checked={isCheck(25, 3)}
                                    onChange={() => {
                                        putAnswerItem(25, 3);
                                    }}
                                    type="checkbox"
                                />
                            </td>
                            <td>
                                <input
                                    checked={isCheck(25, 4)}
                                    onChange={() => {
                                        putAnswerItem(25, 4);
                                    }}
                                    type="checkbox"
                                />
                            </td>
                            <td>
                                <input
                                    checked={isCheck(25, 5)}
                                    onChange={() => {
                                        putAnswerItem(25, 5);
                                    }}
                                    type="checkbox"
                                />
                            </td>
                        </tr>
                        <tr className="center-bottom-black">
                            <th>내 자신이 싫다</th>
                            <td>
                                <input
                                    checked={isCheck(26, 1)}
                                    onChange={() => {
                                        putAnswerItem(26, 1);
                                    }}
                                    type="checkbox"
                                />
                            </td>
                            <td>
                                <input
                                    checked={isCheck(26, 2)}
                                    onChange={() => {
                                        putAnswerItem(26, 2);
                                    }}
                                    type="checkbox"
                                />
                            </td>
                            <td>
                                <input
                                    checked={isCheck(26, 3)}
                                    onChange={() => {
                                        putAnswerItem(26, 3);
                                    }}
                                    type="checkbox"
                                />
                            </td>
                            <td>
                                <input
                                    checked={isCheck(26, 4)}
                                    onChange={() => {
                                        putAnswerItem(26, 4);
                                    }}
                                    type="checkbox"
                                />
                            </td>
                            <td>
                                <input
                                    checked={isCheck(26, 5)}
                                    onChange={() => {
                                        putAnswerItem(26, 5);
                                    }}
                                    type="checkbox"
                                />
                            </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <th colSpan={6}>a)극단-부정적 사고; b)공격-적대적 사고; c)자기비하적 사고.</th>
                        </tr>
                    </tfoot>
                </table>
            </div>

            <div className="tab-story-number">
                <h3>
                    <span className="center-blue">Appendix 3.</span> Stress Questionnaire for Korean National Health and Nutrition Examination Survey
                    (KNHANES): short form
                </h3>
                <table className="center-check-table-full" cellPadding="0" cellSpacing="0">
                    <thead>
                        <tr>
                            <th>
                                지난 한 달 동안 당신이 스트레스를 받았다면 어떤 문제로 인해 가장 큰 영향을 받았습니까? 중요하다고 생각되는 것을 하나만
                                선택하세요.
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th>
                                <input
                                    checked={isCheck(27, 1)}
                                    onChange={() => {
                                        putAnswerItem(27, 1);
                                    }}
                                    type="checkbox"
                                />
                                ① (스스로 원하든 원치 않던 간에) 일, 직업 및 학교와 관련된 일<br />
                                <input
                                    checked={isCheck(27, 2)}
                                    onChange={() => {
                                        putAnswerItem(27, 2);
                                    }}
                                    type="checkbox"
                                />
                                ② 대인관계(가족 및 기타 중요한 사람들과의 관계)
                                <br />
                                <input
                                    checked={isCheck(27, 3)}
                                    onChange={() => {
                                        putAnswerItem(27, 3);
                                    }}
                                    type="checkbox"
                                />
                                ③ 대인관계의 변화(사망, 출생, 이혼, 결혼 등)
                                <br />
                                <input
                                    checked={isCheck(27, 4)}
                                    onChange={() => {
                                        putAnswerItem(27, 4);
                                    }}
                                    type="checkbox"
                                />
                                ④ 자신 혹은 타인의 병이나 상해
                                <br />
                                <input
                                    checked={isCheck(27, 5)}
                                    onChange={() => {
                                        putAnswerItem(27, 5);
                                    }}
                                    type="checkbox"
                                />
                                ⑤ 금전적인 문제
                                <br />
                                <input
                                    checked={isCheck(27, 6)}
                                    onChange={() => {
                                        putAnswerItem(27, 6);
                                    }}
                                    type="checkbox"
                                />
                                ⑥ 일상적인 것이 아닌 사건들(범죄, 자연재해, 우발사고, 이사 등)
                                <br />
                                <input
                                    checked={isCheck(27, 7)}
                                    onChange={() => {
                                        putAnswerItem(27, 7);
                                    }}
                                    type="checkbox"
                                />
                                ⑦ 일상생활의 혹은 변화가 없기 때문에
                            </th>
                        </tr>
                    </tbody>
                </table>

                <table className="center-check-table-basic" cellPadding="0" cellSpacing="0">
                    <thead>
                        <tr>
                            <th colSpan={6}>
                                다음 문항들은 여러분이 경험했던 사건들을 생각하면서 지난 한 달간 각 항목의 느낌을 어느 정도로 경험했는지를 해당되는
                                빈칸에 0표를 하십시오. 생각하시지 말고 최대한 빨리 응답해 주세요. 문항을 하나도 빠지지 말고 반드시 한곳에만 표시해
                                주십시오.
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th className="center-bgcolor-blue">문항</th>
                            <td>
                                전혀
                                <br />
                                그렇지
                                <br />
                                않다
                            </td>
                            <td>
                                약간
                                <br />
                                그렇다
                            </td>
                            <td>
                                웬만틈
                                <br />
                                그렇다
                            </td>
                            <td>
                                상당히
                                <br />
                                그렇다
                            </td>
                            <td>
                                아주
                                <br />
                                그렇다
                            </td>
                        </tr>
                        <tr>
                            <th>1. 문제들이 풀리지 않고 계속 쌓이는 느낌이다.</th>
                            <td
                                className={getCheckOnClassName(28, 1)}
                                onClick={() => {
                                    putAnswerItem(28, 1);
                                }}
                            >
                                1
                            </td>
                            <td
                                className={getCheckOnClassName(28, 2)}
                                onClick={() => {
                                    putAnswerItem(28, 2);
                                }}
                            >
                                2
                            </td>
                            <td
                                className={getCheckOnClassName(28, 3)}
                                onClick={() => {
                                    putAnswerItem(28, 3);
                                }}
                            >
                                3
                            </td>
                            <td
                                className={getCheckOnClassName(28, 4)}
                                onClick={() => {
                                    putAnswerItem(28, 4);
                                }}
                            >
                                4
                            </td>
                            <td
                                className={getCheckOnClassName(28, 5)}
                                onClick={() => {
                                    putAnswerItem(28, 5);
                                }}
                            >
                                5
                            </td>
                        </tr>
                        <tr>
                            <th>2. 다른 사람으로부터 비난받거나 심판받는다고 느껴진다.</th>
                            <td
                                className={getCheckOnClassName(29, 1)}
                                onClick={() => {
                                    putAnswerItem(29, 1);
                                }}
                            >
                                1
                            </td>
                            <td
                                className={getCheckOnClassName(29, 2)}
                                onClick={() => {
                                    putAnswerItem(29, 2);
                                }}
                            >
                                2
                            </td>
                            <td
                                className={getCheckOnClassName(29, 3)}
                                onClick={() => {
                                    putAnswerItem(29, 3);
                                }}
                            >
                                3
                            </td>
                            <td
                                className={getCheckOnClassName(29, 4)}
                                onClick={() => {
                                    putAnswerItem(29, 4);
                                }}
                            >
                                4
                            </td>
                            <td
                                className={getCheckOnClassName(29, 5)}
                                onClick={() => {
                                    putAnswerItem(29, 5);
                                }}
                            >
                                5
                            </td>
                        </tr>
                        <tr>
                            <th>3. 정신적으로 지친다.</th>
                            <td
                                className={getCheckOnClassName(30, 1)}
                                onClick={() => {
                                    putAnswerItem(30, 1);
                                }}
                            >
                                1
                            </td>
                            <td
                                className={getCheckOnClassName(30, 2)}
                                onClick={() => {
                                    putAnswerItem(30, 2);
                                }}
                            >
                                2
                            </td>
                            <td
                                className={getCheckOnClassName(30, 3)}
                                onClick={() => {
                                    putAnswerItem(30, 3);
                                }}
                            >
                                3
                            </td>
                            <td
                                className={getCheckOnClassName(30, 4)}
                                onClick={() => {
                                    putAnswerItem(30, 4);
                                }}
                            >
                                4
                            </td>
                            <td
                                className={getCheckOnClassName(30, 5)}
                                onClick={() => {
                                    putAnswerItem(30, 5);
                                }}
                            >
                                5
                            </td>
                        </tr>
                        <tr>
                            <th>4. 긴장을 풀기 어렵다.</th>
                            <td
                                className={getCheckOnClassName(31, 1)}
                                onClick={() => {
                                    putAnswerItem(31, 1);
                                }}
                            >
                                1
                            </td>
                            <td
                                className={getCheckOnClassName(31, 2)}
                                onClick={() => {
                                    putAnswerItem(31, 2);
                                }}
                            >
                                2
                            </td>
                            <td
                                className={getCheckOnClassName(31, 3)}
                                onClick={() => {
                                    putAnswerItem(31, 3);
                                }}
                            >
                                3
                            </td>
                            <td
                                className={getCheckOnClassName(31, 4)}
                                onClick={() => {
                                    putAnswerItem(31, 4);
                                }}
                            >
                                4
                            </td>
                            <td
                                className={getCheckOnClassName(31, 5)}
                                onClick={() => {
                                    putAnswerItem(31, 5);
                                }}
                            >
                                5
                            </td>
                        </tr>
                        <tr>
                            <th>5. 화가 난다.</th>
                            <td
                                className={getCheckOnClassName(32, 1)}
                                onClick={() => {
                                    putAnswerItem(32, 1);
                                }}
                            >
                                1
                            </td>
                            <td
                                className={getCheckOnClassName(32, 2)}
                                onClick={() => {
                                    putAnswerItem(32, 2);
                                }}
                            >
                                2
                            </td>
                            <td
                                className={getCheckOnClassName(32, 3)}
                                onClick={() => {
                                    putAnswerItem(32, 3);
                                }}
                            >
                                3
                            </td>
                            <td
                                className={getCheckOnClassName(32, 4)}
                                onClick={() => {
                                    putAnswerItem(32, 4);
                                }}
                            >
                                4
                            </td>
                            <td
                                className={getCheckOnClassName(32, 5)}
                                onClick={() => {
                                    putAnswerItem(32, 5);
                                }}
                            >
                                5
                            </td>
                        </tr>
                        <tr>
                            <th>6. 의욕이 떨어진다.</th>
                            <td
                                className={getCheckOnClassName(33, 1)}
                                onClick={() => {
                                    putAnswerItem(33, 1);
                                }}
                            >
                                1
                            </td>
                            <td
                                className={getCheckOnClassName(33, 2)}
                                onClick={() => {
                                    putAnswerItem(33, 2);
                                }}
                            >
                                2
                            </td>
                            <td
                                className={getCheckOnClassName(33, 3)}
                                onClick={() => {
                                    putAnswerItem(33, 3);
                                }}
                            >
                                3
                            </td>
                            <td
                                className={getCheckOnClassName(33, 4)}
                                onClick={() => {
                                    putAnswerItem(33, 4);
                                }}
                            >
                                4
                            </td>
                            <td
                                className={getCheckOnClassName(33, 5)}
                                onClick={() => {
                                    putAnswerItem(33, 5);
                                }}
                            >
                                5
                            </td>
                        </tr>
                        <tr>
                            <th>7. 신경이 날카로워졌다.</th>
                            <td
                                className={getCheckOnClassName(34, 1)}
                                onClick={() => {
                                    putAnswerItem(34, 1);
                                }}
                            >
                                1
                            </td>
                            <td
                                className={getCheckOnClassName(34, 2)}
                                onClick={() => {
                                    putAnswerItem(34, 2);
                                }}
                            >
                                2
                            </td>
                            <td
                                className={getCheckOnClassName(34, 3)}
                                onClick={() => {
                                    putAnswerItem(34, 3);
                                }}
                            >
                                3
                            </td>
                            <td
                                className={getCheckOnClassName(34, 4)}
                                onClick={() => {
                                    putAnswerItem(34, 4);
                                }}
                            >
                                4
                            </td>
                            <td
                                className={getCheckOnClassName(34, 5)}
                                onClick={() => {
                                    putAnswerItem(34, 5);
                                }}
                            >
                                5
                            </td>
                        </tr>
                        <tr>
                            <th>8. 무언가를 부수고 싶다.</th>
                            <td
                                className={getCheckOnClassName(35, 1)}
                                onClick={() => {
                                    putAnswerItem(35, 1);
                                }}
                            >
                                1
                            </td>
                            <td
                                className={getCheckOnClassName(35, 2)}
                                onClick={() => {
                                    putAnswerItem(35, 2);
                                }}
                            >
                                2
                            </td>
                            <td
                                className={getCheckOnClassName(35, 3)}
                                onClick={() => {
                                    putAnswerItem(35, 3);
                                }}
                            >
                                3
                            </td>
                            <td
                                className={getCheckOnClassName(35, 4)}
                                onClick={() => {
                                    putAnswerItem(35, 4);
                                }}
                            >
                                4
                            </td>
                            <td
                                className={getCheckOnClassName(35, 5)}
                                onClick={() => {
                                    putAnswerItem(35, 5);
                                }}
                            >
                                5
                            </td>
                        </tr>
                        <tr className="center-bottom-black">
                            <th>9. 아무런 생각을 하고 싶지 않다.</th>
                            <td
                                className={getCheckOnClassName(36, 1)}
                                onClick={() => {
                                    putAnswerItem(36, 1);
                                }}
                            >
                                1
                            </td>
                            <td
                                className={getCheckOnClassName(36, 2)}
                                onClick={() => {
                                    putAnswerItem(36, 2);
                                }}
                            >
                                2
                            </td>
                            <td
                                className={getCheckOnClassName(36, 3)}
                                onClick={() => {
                                    putAnswerItem(36, 3);
                                }}
                            >
                                3
                            </td>
                            <td
                                className={getCheckOnClassName(36, 4)}
                                onClick={() => {
                                    putAnswerItem(36, 4);
                                }}
                            >
                                4
                            </td>
                            <td
                                className={getCheckOnClassName(36, 5)}
                                onClick={() => {
                                    putAnswerItem(36, 5);
                                }}
                            >
                                5
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div className="tab-story-number">
                <h3>
                    <span className="center-blue">Appendix 4.</span> Korean Occupational Stress Scale (KOSS): short form
                </h3>
                <table className="center-check-table-basic" cellPadding="0" cellSpacing="0">
                    <tbody>
                        <tr>
                            <th className="center-bgcolor-blue">설 문 내 용</th>
                            <td>
                                전혀
                                <br />
                                그렇지
                                <br />
                                않다
                            </td>
                            <td>
                                그렇지
                                <br />
                                않다
                            </td>
                            <td>그렇다</td>
                            <td>
                                매우
                                <br />
                                그렇다
                            </td>
                        </tr>
                        <tr>
                            <th>1. 나는 일이 많아 항상 시간에 쫓기며 일한다.</th>
                            <td
                                className={getCheckOnClassName(37, 1)}
                                onClick={() => {
                                    putAnswerItem(37, 1);
                                }}
                            >
                                1
                            </td>
                            <td
                                className={getCheckOnClassName(37, 2)}
                                onClick={() => {
                                    putAnswerItem(37, 2);
                                }}
                            >
                                2
                            </td>
                            <td
                                className={getCheckOnClassName(37, 3)}
                                onClick={() => {
                                    putAnswerItem(37, 3);
                                }}
                            >
                                3
                            </td>
                            <td
                                className={getCheckOnClassName(37, 4)}
                                onClick={() => {
                                    putAnswerItem(37, 4);
                                }}
                            >
                                4
                            </td>
                        </tr>
                        <tr>
                            <th>2. 업무량이 현저하게 증가하였다.</th>
                            <td
                                className={getCheckOnClassName(38, 1)}
                                onClick={() => {
                                    putAnswerItem(38, 1);
                                }}
                            >
                                1
                            </td>
                            <td
                                className={getCheckOnClassName(38, 2)}
                                onClick={() => {
                                    putAnswerItem(38, 2);
                                }}
                            >
                                2
                            </td>
                            <td
                                className={getCheckOnClassName(38, 3)}
                                onClick={() => {
                                    putAnswerItem(38, 3);
                                }}
                            >
                                3
                            </td>
                            <td
                                className={getCheckOnClassName(38, 4)}
                                onClick={() => {
                                    putAnswerItem(38, 4);
                                }}
                            >
                                4
                            </td>
                        </tr>
                        <tr>
                            <th>3. 업무 수행 중에 충분한 휴식(짬)이 주어진다.</th>
                            <td
                                className={getCheckOnClassName(39, 4)}
                                onClick={() => {
                                    putAnswerItem(39, 4);
                                }}
                            >
                                4
                            </td>
                            <td
                                className={getCheckOnClassName(39, 3)}
                                onClick={() => {
                                    putAnswerItem(39, 3);
                                }}
                            >
                                3
                            </td>
                            <td
                                className={getCheckOnClassName(39, 2)}
                                onClick={() => {
                                    putAnswerItem(39, 2);
                                }}
                            >
                                2
                            </td>
                            <td
                                className={getCheckOnClassName(39, 1)}
                                onClick={() => {
                                    putAnswerItem(39, 1);
                                }}
                            >
                                1
                            </td>
                        </tr>
                        <tr>
                            <th>4. 여러 가지 일을 동시에 해야 한다.</th>
                            <td
                                className={getCheckOnClassName(40, 1)}
                                onClick={() => {
                                    putAnswerItem(40, 1);
                                }}
                            >
                                1
                            </td>
                            <td
                                className={getCheckOnClassName(40, 2)}
                                onClick={() => {
                                    putAnswerItem(40, 2);
                                }}
                            >
                                2
                            </td>
                            <td
                                className={getCheckOnClassName(40, 3)}
                                onClick={() => {
                                    putAnswerItem(40, 3);
                                }}
                            >
                                3
                            </td>
                            <td
                                className={getCheckOnClassName(40, 4)}
                                onClick={() => {
                                    putAnswerItem(40, 4);
                                }}
                            >
                                4
                            </td>
                        </tr>
                        <tr>
                            <th>5. 내 업무는 창의력을 필요로 한다.</th>
                            <td
                                className={getCheckOnClassName(41, 4)}
                                onClick={() => {
                                    putAnswerItem(41, 4);
                                }}
                            >
                                4
                            </td>
                            <td
                                className={getCheckOnClassName(41, 3)}
                                onClick={() => {
                                    putAnswerItem(41, 3);
                                }}
                            >
                                3
                            </td>
                            <td
                                className={getCheckOnClassName(41, 2)}
                                onClick={() => {
                                    putAnswerItem(41, 2);
                                }}
                            >
                                2
                            </td>
                            <td
                                className={getCheckOnClassName(41, 1)}
                                onClick={() => {
                                    putAnswerItem(41, 1);
                                }}
                            >
                                1
                            </td>
                        </tr>
                        <tr>
                            <th>6. 내 업무를 수행하기 위해서는 높은 수준의 기술이나 지식이 필요하다.</th>
                            <td
                                className={getCheckOnClassName(42, 4)}
                                onClick={() => {
                                    putAnswerItem(42, 4);
                                }}
                            >
                                4
                            </td>
                            <td
                                className={getCheckOnClassName(42, 3)}
                                onClick={() => {
                                    putAnswerItem(42, 3);
                                }}
                            >
                                3
                            </td>
                            <td
                                className={getCheckOnClassName(42, 2)}
                                onClick={() => {
                                    putAnswerItem(42, 2);
                                }}
                            >
                                2
                            </td>
                            <td
                                className={getCheckOnClassName(42, 1)}
                                onClick={() => {
                                    putAnswerItem(42, 1);
                                }}
                            >
                                1
                            </td>
                        </tr>
                        <tr>
                            <th>7. 작업시간, 업무수행과정에서 나에게 결정할 권한이 주어지며 영향력을행사할 수 있다.</th>
                            <td
                                className={getCheckOnClassName(43, 4)}
                                onClick={() => {
                                    putAnswerItem(43, 4);
                                }}
                            >
                                4
                            </td>
                            <td
                                className={getCheckOnClassName(43, 3)}
                                onClick={() => {
                                    putAnswerItem(43, 3);
                                }}
                            >
                                3
                            </td>
                            <td
                                className={getCheckOnClassName(43, 2)}
                                onClick={() => {
                                    putAnswerItem(43, 2);
                                }}
                            >
                                2
                            </td>
                            <td
                                className={getCheckOnClassName(43, 1)}
                                onClick={() => {
                                    putAnswerItem(43, 1);
                                }}
                            >
                                1
                            </td>
                        </tr>
                        <tr>
                            <th>8. 나의 업무량과 작업스케줄을 스스로 조절할 수 있다.</th>
                            <td
                                className={getCheckOnClassName(44, 4)}
                                onClick={() => {
                                    putAnswerItem(44, 4);
                                }}
                            >
                                4
                            </td>
                            <td
                                className={getCheckOnClassName(44, 3)}
                                onClick={() => {
                                    putAnswerItem(44, 3);
                                }}
                            >
                                3
                            </td>
                            <td
                                className={getCheckOnClassName(44, 2)}
                                onClick={() => {
                                    putAnswerItem(44, 2);
                                }}
                            >
                                2
                            </td>
                            <td
                                className={getCheckOnClassName(44, 1)}
                                onClick={() => {
                                    putAnswerItem(44, 1);
                                }}
                            >
                                1
                            </td>
                        </tr>
                        <tr>
                            <th>9. 나의 상사는 업무를 완료하는데 도움을 준다.</th>
                            <td
                                className={getCheckOnClassName(45, 4)}
                                onClick={() => {
                                    putAnswerItem(45, 4);
                                }}
                            >
                                4
                            </td>
                            <td
                                className={getCheckOnClassName(45, 3)}
                                onClick={() => {
                                    putAnswerItem(45, 3);
                                }}
                            >
                                3
                            </td>
                            <td
                                className={getCheckOnClassName(45, 2)}
                                onClick={() => {
                                    putAnswerItem(45, 2);
                                }}
                            >
                                2
                            </td>
                            <td
                                className={getCheckOnClassName(45, 1)}
                                onClick={() => {
                                    putAnswerItem(45, 1);
                                }}
                            >
                                1
                            </td>
                        </tr>
                        <tr>
                            <th>10. 나의 동료는 업무를 완료하는데 도움을 준다.</th>
                            <td
                                className={getCheckOnClassName(46, 4)}
                                onClick={() => {
                                    putAnswerItem(46, 4);
                                }}
                            >
                                4
                            </td>
                            <td
                                className={getCheckOnClassName(46, 3)}
                                onClick={() => {
                                    putAnswerItem(46, 3);
                                }}
                            >
                                3
                            </td>
                            <td
                                className={getCheckOnClassName(46, 2)}
                                onClick={() => {
                                    putAnswerItem(46, 2);
                                }}
                            >
                                2
                            </td>
                            <td
                                className={getCheckOnClassName(46, 1)}
                                onClick={() => {
                                    putAnswerItem(46, 1);
                                }}
                            >
                                1
                            </td>
                        </tr>
                        <tr>
                            <th>11. 직장에서 내가 힘들 때 내가 힘들다는 것을 알아주고 이해해 주는 사람이 있다.</th>
                            <td
                                className={getCheckOnClassName(47, 4)}
                                onClick={() => {
                                    putAnswerItem(47, 4);
                                }}
                            >
                                4
                            </td>
                            <td
                                className={getCheckOnClassName(47, 3)}
                                onClick={() => {
                                    putAnswerItem(47, 3);
                                }}
                            >
                                3
                            </td>
                            <td
                                className={getCheckOnClassName(47, 2)}
                                onClick={() => {
                                    putAnswerItem(47, 2);
                                }}
                            >
                                2
                            </td>
                            <td
                                className={getCheckOnClassName(47, 1)}
                                onClick={() => {
                                    putAnswerItem(47, 1);
                                }}
                            >
                                1
                            </td>
                        </tr>
                        <tr>
                            <th>12. 직장사정이 불안하여 미래가 불확실하다.</th>
                            <td
                                className={getCheckOnClassName(48, 1)}
                                onClick={() => {
                                    putAnswerItem(48, 1);
                                }}
                            >
                                1
                            </td>
                            <td
                                className={getCheckOnClassName(48, 2)}
                                onClick={() => {
                                    putAnswerItem(48, 2);
                                }}
                            >
                                2
                            </td>
                            <td
                                className={getCheckOnClassName(48, 3)}
                                onClick={() => {
                                    putAnswerItem(48, 3);
                                }}
                            >
                                3
                            </td>
                            <td
                                className={getCheckOnClassName(48, 4)}
                                onClick={() => {
                                    putAnswerItem(48, 4);
                                }}
                            >
                                4
                            </td>
                        </tr>
                        <tr>
                            <th>13. 나의 근무조건이나 상황에 바람직하지 못한 변화(예, 구조조정)가 있었거나 있을것으로 예상된다.</th>
                            <td
                                className={getCheckOnClassName(49, 1)}
                                onClick={() => {
                                    putAnswerItem(49, 1);
                                }}
                            >
                                1
                            </td>
                            <td
                                className={getCheckOnClassName(49, 2)}
                                onClick={() => {
                                    putAnswerItem(49, 2);
                                }}
                            >
                                2
                            </td>
                            <td
                                className={getCheckOnClassName(49, 3)}
                                onClick={() => {
                                    putAnswerItem(49, 3);
                                }}
                            >
                                3
                            </td>
                            <td
                                className={getCheckOnClassName(49, 4)}
                                onClick={() => {
                                    putAnswerItem(49, 4);
                                }}
                            >
                                4
                            </td>
                        </tr>
                        <tr>
                            <th>14. 우리 직장은 근무평가, 인사제도(승진, 부서배치 등)가 공정하고 합리적이다.</th>
                            <td
                                className={getCheckOnClassName(50, 4)}
                                onClick={() => {
                                    putAnswerItem(50, 4);
                                }}
                            >
                                4
                            </td>
                            <td
                                className={getCheckOnClassName(50, 3)}
                                onClick={() => {
                                    putAnswerItem(50, 3);
                                }}
                            >
                                3
                            </td>
                            <td
                                className={getCheckOnClassName(50, 2)}
                                onClick={() => {
                                    putAnswerItem(50, 2);
                                }}
                            >
                                2
                            </td>
                            <td
                                className={getCheckOnClassName(50, 1)}
                                onClick={() => {
                                    putAnswerItem(50, 1);
                                }}
                            >
                                1
                            </td>
                        </tr>
                        <tr>
                            <th>15. 업무수행에 필요한 인원, 공간, 시설, 장비, 훈련 등의 지원이 잘 이루어지고 있다.</th>
                            <td
                                className={getCheckOnClassName(51, 4)}
                                onClick={() => {
                                    putAnswerItem(51, 4);
                                }}
                            >
                                4
                            </td>
                            <td
                                className={getCheckOnClassName(51, 3)}
                                onClick={() => {
                                    putAnswerItem(51, 3);
                                }}
                            >
                                3
                            </td>
                            <td
                                className={getCheckOnClassName(51, 2)}
                                onClick={() => {
                                    putAnswerItem(51, 2);
                                }}
                            >
                                2
                            </td>
                            <td
                                className={getCheckOnClassName(51, 1)}
                                onClick={() => {
                                    putAnswerItem(51, 1);
                                }}
                            >
                                1
                            </td>
                        </tr>
                        <tr>
                            <th>16. 우리 부서와 타 부서 간에는 마찰이 없고 업무 협조가 잘 이루어진다.</th>
                            <td
                                className={getCheckOnClassName(52, 4)}
                                onClick={() => {
                                    putAnswerItem(52, 4);
                                }}
                            >
                                4
                            </td>
                            <td
                                className={getCheckOnClassName(52, 3)}
                                onClick={() => {
                                    putAnswerItem(52, 3);
                                }}
                            >
                                3
                            </td>
                            <td
                                className={getCheckOnClassName(52, 2)}
                                onClick={() => {
                                    putAnswerItem(52, 2);
                                }}
                            >
                                2
                            </td>
                            <td
                                className={getCheckOnClassName(52, 1)}
                                onClick={() => {
                                    putAnswerItem(52, 1);
                                }}
                            >
                                1
                            </td>
                        </tr>
                        <tr>
                            <th>17. 일에 대한 나의 생각을 반영할 수 있는 기회와 통로가 있다.</th>
                            <td
                                className={getCheckOnClassName(53, 4)}
                                onClick={() => {
                                    putAnswerItem(53, 4);
                                }}
                            >
                                4
                            </td>
                            <td
                                className={getCheckOnClassName(53, 3)}
                                onClick={() => {
                                    putAnswerItem(53, 3);
                                }}
                            >
                                3
                            </td>
                            <td
                                className={getCheckOnClassName(53, 2)}
                                onClick={() => {
                                    putAnswerItem(53, 2);
                                }}
                            >
                                2
                            </td>
                            <td
                                className={getCheckOnClassName(53, 1)}
                                onClick={() => {
                                    putAnswerItem(53, 1);
                                }}
                            >
                                1
                            </td>
                        </tr>
                        <tr>
                            <th>18. 나의 모든 노력과 업적을 고려할 때, 나는 직장에서 제대로 존중과 신임을 받고 있다.</th>
                            <td
                                className={getCheckOnClassName(54, 4)}
                                onClick={() => {
                                    putAnswerItem(54, 4);
                                }}
                            >
                                4
                            </td>
                            <td
                                className={getCheckOnClassName(54, 3)}
                                onClick={() => {
                                    putAnswerItem(54, 3);
                                }}
                            >
                                3
                            </td>
                            <td
                                className={getCheckOnClassName(54, 2)}
                                onClick={() => {
                                    putAnswerItem(54, 2);
                                }}
                            >
                                2
                            </td>
                            <td
                                className={getCheckOnClassName(54, 1)}
                                onClick={() => {
                                    putAnswerItem(54, 1);
                                }}
                            >
                                1
                            </td>
                        </tr>
                        <tr>
                            <th>19. 내 사정이 앞으로 더 좋아질 것을 생각하면 힘든 줄 모르고 일하게 된다.</th>
                            <td
                                className={getCheckOnClassName(55, 4)}
                                onClick={() => {
                                    putAnswerItem(55, 4);
                                }}
                            >
                                4
                            </td>
                            <td
                                className={getCheckOnClassName(55, 3)}
                                onClick={() => {
                                    putAnswerItem(55, 3);
                                }}
                            >
                                3
                            </td>
                            <td
                                className={getCheckOnClassName(55, 2)}
                                onClick={() => {
                                    putAnswerItem(55, 2);
                                }}
                            >
                                2
                            </td>
                            <td
                                className={getCheckOnClassName(55, 1)}
                                onClick={() => {
                                    putAnswerItem(55, 1);
                                }}
                            >
                                1
                            </td>
                        </tr>
                        <tr>
                            <th>20. 나의 능력을 개발하고 발휘할 수 있는 기회가 주어진다.</th>
                            <td
                                className={getCheckOnClassName(56, 1)}
                                onClick={() => {
                                    putAnswerItem(56, 1);
                                }}
                            >
                                4
                            </td>
                            <td
                                className={getCheckOnClassName(56, 2)}
                                onClick={() => {
                                    putAnswerItem(56, 2);
                                }}
                            >
                                3
                            </td>
                            <td
                                className={getCheckOnClassName(56, 3)}
                                onClick={() => {
                                    putAnswerItem(56, 3);
                                }}
                            >
                                2
                            </td>
                            <td
                                className={getCheckOnClassName(56, 4)}
                                onClick={() => {
                                    putAnswerItem(56, 4);
                                }}
                            >
                                1
                            </td>
                        </tr>
                        <tr>
                            <th>21. 회식자리가 불편하다.</th>
                            <td
                                className={getCheckOnClassName(57, 1)}
                                onClick={() => {
                                    putAnswerItem(57, 1);
                                }}
                            >
                                1
                            </td>
                            <td
                                className={getCheckOnClassName(57, 2)}
                                onClick={() => {
                                    putAnswerItem(57, 2);
                                }}
                            >
                                2
                            </td>
                            <td
                                className={getCheckOnClassName(57, 3)}
                                onClick={() => {
                                    putAnswerItem(57, 3);
                                }}
                            >
                                3
                            </td>
                            <td
                                className={getCheckOnClassName(57, 4)}
                                onClick={() => {
                                    putAnswerItem(57, 4);
                                }}
                            >
                                4
                            </td>
                        </tr>
                        <tr>
                            <th>22. 기준이나 일관성이 없는 상태로 업무 지시를 받는다.</th>
                            <td
                                className={getCheckOnClassName(58, 1)}
                                onClick={() => {
                                    putAnswerItem(58, 1);
                                }}
                            >
                                1
                            </td>
                            <td
                                className={getCheckOnClassName(58, 2)}
                                onClick={() => {
                                    putAnswerItem(58, 2);
                                }}
                            >
                                2
                            </td>
                            <td
                                className={getCheckOnClassName(58, 3)}
                                onClick={() => {
                                    putAnswerItem(58, 3);
                                }}
                            >
                                3
                            </td>
                            <td
                                className={getCheckOnClassName(58, 4)}
                                onClick={() => {
                                    putAnswerItem(58, 4);
                                }}
                            >
                                4
                            </td>
                        </tr>
                        <tr>
                            <th>23. 직장의 분위기가 권위적이고 수직적이다.</th>
                            <td
                                className={getCheckOnClassName(59, 1)}
                                onClick={() => {
                                    putAnswerItem(59, 1);
                                }}
                            >
                                1
                            </td>
                            <td
                                className={getCheckOnClassName(59, 2)}
                                onClick={() => {
                                    putAnswerItem(59, 2);
                                }}
                            >
                                2
                            </td>
                            <td
                                className={getCheckOnClassName(59, 3)}
                                onClick={() => {
                                    putAnswerItem(59, 3);
                                }}
                            >
                                3
                            </td>
                            <td
                                className={getCheckOnClassName(59, 4)}
                                onClick={() => {
                                    putAnswerItem(59, 4);
                                }}
                            >
                                4
                            </td>
                        </tr>
                        <tr className="center-bottom-black">
                            <th>24. 남성, 여성이라는 성적인 차이 때문에 불이익을 받는다.</th>
                            <td
                                className={getCheckOnClassName(60, 1)}
                                onClick={() => {
                                    putAnswerItem(60, 1);
                                }}
                            >
                                1
                            </td>
                            <td
                                className={getCheckOnClassName(60, 2)}
                                onClick={() => {
                                    putAnswerItem(60, 2);
                                }}
                            >
                                2
                            </td>
                            <td
                                className={getCheckOnClassName(60, 3)}
                                onClick={() => {
                                    putAnswerItem(60, 3);
                                }}
                            >
                                3
                            </td>
                            <td
                                className={getCheckOnClassName(60, 4)}
                                onClick={() => {
                                    putAnswerItem(60, 4);
                                }}
                            >
                                4
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    );
}
