import React, { ChangeEvent, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import AdminMainTitle from '../../../../component/admin/AdminMainTitle';
import useParentInfraCategoriesRetrieve from '../../../../hook/infra/useParentInfraCategoriesRetrieve';
import useCodeRetrieve from '../../../../hook/code/useCodeRetrieve';
import { CodeType } from '../../../../domain/code/CodeType';
import useChildCodeRetrieve from '../../../../hook/code/useChildCodeRetrieve';
import CalendarComponent from '../../../../component/calendar/CalendarComponent';
import Event from '../../../../domain/event/Event';
import DateUtils from '../../../../utils/DateUtils';
import { RootState } from '../../../../moldules/index';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ko } from 'date-fns/esm/locale';
import TeamSearchInput from '../../../../component/input/TeamSearchInput';
import { confirmAlert } from 'react-confirm-alert';
import ClubTeamInfo from '../../../../domain/clubteam/ClubTeamInfo';
import ClubTeamInfoServiceImpl from '../../../../service/clubteam/impl/ClubTeamInfoServiceImpl';

let clubTeamInfoService = new ClubTeamInfoServiceImpl();
function AdminClubTeamFormPage() {
    const { user } = useSelector((state: RootState) => state.userSessionReducer);
    const history = useHistory();
    const location = useLocation();

    const { codes: regionCodes } = useCodeRetrieve(CodeType.REGION);
    const { codes: sportCodes } = useCodeRetrieve(CodeType.CLUB_SPORT);

    const [years, setYears] = useState<number[]>();
    const [halfYears, setHalfYears] = useState<number[]>();
    const [parentRegionCodeId, setParentRegionCodeId] = useState<number>();
    const childRegionCodes = useChildCodeRetrieve(parentRegionCodeId);

    const [clubTeamSeason, setClubTeamSeason] = useState<number>();
    const [clubTeamSeasonPart, setClubTeamSeasonPart] = useState<number>();
    const [regionCodeId, setRegionCodeId] = useState<number>();
    const [clubSportCodeId, setClubSportCodeId] = useState<number>();
    const [clubTeamType, setClubTeamType] = useState<string>();
    const [clubTeamCount, setClubTeamCount] = useState<number>();
    const [clubTeamMemberCount, setClubTeamMemberCount] = useState<number>();

    const manStyle = {
        fontSize: '5px',
        color: '#f00',
    };

    const registureBtnHandler = () => {
        const registingInfo = new ClubTeamInfo();
        registingInfo.clubTeamSeason = clubTeamSeason;
        registingInfo.clubTeamSeasonPart = clubTeamSeasonPart;
        registingInfo.regionCodeId = regionCodeId;
        registingInfo.clubSportCodeId = clubSportCodeId;
        registingInfo.clubTeamType = clubTeamType;
        registingInfo.clubTeamCount = clubTeamCount;
        registingInfo.clubTeamMemberCount = clubTeamMemberCount;

        if (clubTeamSeason && clubTeamSeasonPart && regionCodeId && clubSportCodeId && clubTeamType && clubTeamCount && clubTeamMemberCount) {
            clubTeamInfoService.registeClubInfo(registingInfo).then((result: ClubTeamInfo) => {
                confirmAlert({
                    title: '등록 성공',
                    message: '현황 정보가 정상적으로 등록 되었습니다. 감사합니다.',
                    buttons: [
                        {
                            label: '확인',
                            onClick: () => {
                                goBack();
                            },
                        },
                    ],
                });
            });
        } else {
            confirmAlert({
                title: '경고',
                message: '입력값을 확인해 주십시오. 필수 입력 값이 누락 되었습니다.',
                buttons: [{ label: '확인', onClick: () => {} }],
            });
        }
    };

    const goBack = () => {
        history.replace('/admin/club-team-info');
    };

    const messageAlert = (message: string) => {
        confirmAlert({
            title: '경고',
            message: message,
            buttons: [{ label: '확인', onClick: () => {} }],
        });
    };

    useEffect(() => {
        setParentRegionCodeId(20001);
        setYears([2018, 2019, 2020, 2021, 2022, 2023]);
        setHalfYears([1, 2]);
    }, []);

    // 등록 정보 -- 시즌 / 반기 / 지역/ 종목 / 클럽형태 / 팀수/ 팀인원수
    return (
        <>
            <AdminMainTitle title="동호회 현황 등록"></AdminMainTitle>
            <div className="two-wrap">
                <div className="half">
                    <div className="member-list">
                        <table>
                            <colgroup>
                                <col style={{ width: '18%', backgroundColor: '#f2f2f2' }} />
                                <col style={{ width: '*' }} />
                            </colgroup>
                            <thead>
                                <tr>
                                    <td colSpan={2} style={{ textAlign: 'right', paddingTop: '7px', paddingBottom: '7px' }}>
                                        <button style={{ backgroundColor: '#ffc107', color: '#3a4652', fontWeight: 700 }} onClick={goBack}>
                                            돌아가기
                                        </button>
                                        <button className="bg-blue white" style={{ fontWeight: 700 }} onClick={registureBtnHandler}>
                                            현황등록
                                        </button>
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="txt-left">
                                        <div className=" pdl30 blue">
                                            <i className="fas fa-star-of-life" style={manStyle}></i>&nbsp; 시즌/반기
                                        </div>
                                    </td>
                                    <td className="txt-left">
                                        <select
                                            value={clubTeamSeason}
                                            style={{ width: '250px' }}
                                            className="bg-light-gray"
                                            onChange={(evt: any) => {
                                                let val = evt.currentTarget.value;
                                                setClubTeamSeason(val);
                                            }}
                                        >
                                            <option>시즌 선택</option>
                                            {years?.map((year, index) => {
                                                return (
                                                    <option key={index} value={year}>
                                                        {year} 시즌
                                                    </option>
                                                );
                                            })}
                                        </select>
                                        <select
                                            value={clubTeamSeasonPart}
                                            className="bg-light-gray"
                                            onChange={(evt: any) => {
                                                let val = evt.currentTarget.value;
                                                setClubTeamSeasonPart(val);
                                            }}
                                        >
                                            <option>반기 선택</option>
                                            {halfYears?.map((halfYear, index) => {
                                                return (
                                                    <option key={index} value={halfYear}>
                                                        {halfYear == 1 ? '상반기' : '하반기'}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="txt-left">
                                        <div className=" pdl30 blue">
                                            <i className="fas fa-star-of-life" style={manStyle}></i>&nbsp; 지역
                                        </div>
                                    </td>
                                    <td className="txt-left">
                                        <select
                                            value={parentRegionCodeId}
                                            className="bg-light-gray"
                                            onChange={(event) => {
                                                const regionCodeId = Number(event.currentTarget.value);
                                                setParentRegionCodeId(regionCodeId);
                                            }}
                                        >
                                            <option>발급 도 단위 지역 선택</option>
                                            {regionCodes?.map((regionCode, index) => {
                                                return (
                                                    <option key={index} value={regionCode.codeId}>
                                                        {regionCode.name}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                        <select
                                            value={regionCodeId}
                                            onChange={(evt: any) => {
                                                let codeId = evt.currentTarget.value;
                                                setRegionCodeId(Number(codeId));
                                            }}
                                            style={{ width: '250px' }}
                                        >
                                            <option>발급 시.군 단위 지역 선택</option>
                                            {childRegionCodes?.map((regionCode, index) => {
                                                return (
                                                    <option key={index} value={regionCode.codeId}>
                                                        {regionCode.name}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="txt-left">
                                        <div className=" pdl30 blue">
                                            <i className="fas fa-star-of-life" style={manStyle}></i>&nbsp; 종목
                                        </div>
                                    </td>
                                    <td className="txt-left">
                                        <select
                                            value={clubSportCodeId}
                                            style={{ width: '250px' }}
                                            className="bg-light-gray"
                                            onChange={(evt: any) => {
                                                let val = Number(evt.currentTarget.value);
                                                setClubSportCodeId(val);
                                            }}
                                        >
                                            <option>동호회 종목 선택</option>
                                            {sportCodes?.map((sportCode, index) => {
                                                return (
                                                    <option key={index} value={sportCode.codeId}>
                                                        {sportCode.name}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="txt-left">
                                        <div className="pdl30 blue">
                                            <i className="fas fa-star-of-life" style={manStyle}></i>&nbsp; 동호회 소속
                                        </div>
                                    </td>
                                    <td className="txt-left">
                                        <div className="flex-basic">
                                            <select
                                                value={clubTeamType}
                                                style={{ width: '250px' }}
                                                className="bg-light-gray"
                                                onChange={(evt: any) => {
                                                    let val = evt.currentTarget.value;
                                                    setClubTeamType(val);
                                                }}
                                            >
                                                <option>소속선택</option>
                                                <option value="region">지역소속</option>
                                                <option value="company">직장소속</option>
                                            </select>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="txt-left">
                                        <div className=" pdl30 blue">
                                            <i className="fas fa-star-of-life" style={manStyle}></i>&nbsp; 팀 총수
                                        </div>
                                    </td>
                                    <td className="txt-left flex-basic">
                                        <input
                                            type="text"
                                            value={clubTeamCount}
                                            onChange={(evt: any) => {
                                                let val = Number(evt.currentTarget.value);
                                                if (isNaN(val)) {
                                                    messageAlert('숫자만 입력해 주십시오.');
                                                } else {
                                                    setClubTeamCount(val);
                                                }
                                            }}
                                            placeholder="동호회 팀 총수를 입력해 주세요"
                                        />{' '}
                                        개 팀
                                    </td>
                                </tr>
                                <tr>
                                    <td className="txt-left">
                                        <div className=" pdl30 blue">
                                            <i className="fas fa-star-of-life" style={manStyle}></i>&nbsp; 팀 총 인원수
                                        </div>
                                    </td>
                                    <td className="txt-left flex-basic">
                                        <input
                                            type="text"
                                            value={clubTeamMemberCount}
                                            onChange={(evt: any) => {
                                                let val = Number(evt.currentTarget.value);
                                                if (isNaN(val)) {
                                                    messageAlert('숫자만 입력해 주십시오.');
                                                } else {
                                                    setClubTeamMemberCount(val);
                                                }
                                            }}
                                            placeholder="동호회 팀 총 인원 수를 입력해 주세요"
                                        />{' '}
                                        명
                                    </td>
                                </tr>

                                <tr>
                                    <td colSpan={2}></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AdminClubTeamFormPage;
