import { useEffect, useState } from "react";
function usePasswordValidation(password?: string) {
    const [passwordValidation, setPasswordValidation] = useState<boolean>();
    useEffect(() => {
        if(password) {
            var smalAlpaRex = new RegExp('[a-z]+');
            var bigAlpaRex = new RegExp('[A-Z]+');
            var numberRex = new RegExp('[0-9]+');
            var specialWordRex = new RegExp('[~,!,@,#,$,%,^,&,*,(,)]+');
            const lengthCheck = password.length > 7 && password.length < 17;
            const wordCheck = [
                smalAlpaRex.test(password),
                bigAlpaRex.test(password),
                numberRex.test(password),
                specialWordRex.test(password)
            ].filter(value => {
                return value;
            }).length;
            if(lengthCheck && wordCheck) {
                setPasswordValidation(true);
            } else {
                setPasswordValidation(false);
            }
        }
    }, [password]);

    return passwordValidation;
}

export default usePasswordValidation;