import React from 'react';
interface SearchInProps {
    children?: React.ReactNode;
}
function SearchIn({children}: SearchInProps) {
    return (
        <div className="search-in">
            {children}
        </div>
    )
}

export default SearchIn;