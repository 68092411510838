import React, { useEffect } from 'react';
import { useState } from 'react';
import { CodeType } from '../../../../domain/code/CodeType';
import useCodeRetrieve from '../../../../hook/code/useCodeRetrieve';
import Code from '../../../../domain/code/Code';
import DateUtils from '../../../../utils/DateUtils';
import { serviceStartYear, defaultParentRegionCodeId } from '../../../../config/Constant';
import VisitedTeamInfoSearchCondition from '../../../../domain/visitedInfo/pagam/VisitedTeamInfoSearchCondition';
import useChildCodeRetrieve from '../../../../hook/code/useChildCodeRetrieve';
import VisitedTeamInfoServiceImpl from '../../../../service/visitedInfo/impl/VisitedTeamInfoServiceImpl';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ko } from 'date-fns/esm/locale';

interface AdminVisitedTeamInfoListFilterProps {
    searchCondition: VisitedTeamInfoSearchCondition;
    setVisitedTeamInfoSearchCondition: (condition: VisitedTeamInfoSearchCondition) => void;
}

const visitedTeamInfoService = new VisitedTeamInfoServiceImpl();

function AdminVisitedTeamInfoListFilter(props: AdminVisitedTeamInfoListFilterProps) {
    const [belongCodeId, setBelongCodeId] = useState<string>();
    const [classificationCodeId, setClassficationCodeId] = useState<string>();
    const [sportCodeId, setSportCodeId] = useState<string>();
    const [regionCodeId, setRegionCodeId] = useState<string>();
    const [visitedStartDate, setVisitedStartDate] = useState<any>(new Date());
    const [visitedEndDate, setVisitedEndDate] = useState<any>(new Date());
    const [searchWord, setSearchWord] = useState<string>();

    const { codes: belongCodes } = useCodeRetrieve(CodeType.BELONGED);
    const { codes: classficationCodes } = useCodeRetrieve(CodeType.TEAM_TYPE);
    const { codes: sportCodes } = useCodeRetrieve(CodeType.SPORT);
    const regionCodes = useChildCodeRetrieve(defaultParentRegionCodeId);

    const keyEventHandler = (e: any) => {
        if (e.key == 'Enter') {
            searchClickHandler();
        }
    };

    const searchClickHandler = () => {
        const searchCondition = VisitedTeamInfoSearchCondition.makeVisitedteamInfoSearchCondition(
            belongCodeId,
            classificationCodeId,
            sportCodeId,
            regionCodeId,
            visitedStartDate ? DateUtils.getDateForm(visitedStartDate) : '',
            visitedEndDate ? DateUtils.getDateForm(visitedEndDate) : '',
            searchWord,
        );
        props.setVisitedTeamInfoSearchCondition(searchCondition);
    };

    const downloadExcelHandler = () => {
        const searchCondition = VisitedTeamInfoSearchCondition.makeVisitedteamInfoSearchCondition(
            belongCodeId,
            classificationCodeId,
            sportCodeId,
            regionCodeId,
            visitedStartDate ? DateUtils.getDateForm(visitedStartDate) : '',
            visitedEndDate ? DateUtils.getDateForm(visitedEndDate) : '',
            searchWord,
        );

        visitedTeamInfoService.downloadExcelDataForVisitedTeamInfo(searchCondition);
    };

    useEffect(() => {
        let condition = props.searchCondition;

        setBelongCodeId(condition.belongCodeIds);
        setClassficationCodeId(condition.classficationCodeIds);
        setSportCodeId(condition.sportCodeIds);
        setRegionCodeId(condition.regionCodeIds);
        setVisitedStartDate(condition.visitedStartDate ? new Date(condition.visitedStartDate) : null);
        setVisitedEndDate(condition.visitedEndDate ? new Date(condition.visitedEndDate) : null);
        setSearchWord(condition.searchWord);
    }, []);

    return (
        <>
            <div className="member-join">
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <select
                                    value={belongCodeId}
                                    onChange={(event) => {
                                        let codeId: string | undefined = event.currentTarget.value;
                                        if (codeId == '0') codeId = undefined;
                                        setBelongCodeId(codeId);
                                        document.getElementById('keyHandler')?.focus();
                                    }}
                                >
                                    <option value="0">팀분류그룹선택</option>
                                    {belongCodes?.map((code: Code, index: number) => {
                                        return (
                                            <option key={index} value={code.codeId}>
                                                {code.name}
                                            </option>
                                        );
                                    })}
                                </select>
                            </td>
                            <td>
                                <select
                                    value={classificationCodeId}
                                    onChange={(event: any) => {
                                        let codeId: string | undefined = event.currentTarget.value;
                                        if (codeId == '0') codeId = undefined;
                                        setClassficationCodeId(codeId);
                                        document.getElementById('keyHandler')?.focus();
                                    }}
                                >
                                    <option value="0">팀분류선택</option>
                                    {classficationCodes?.map((code: Code, index: number) => {
                                        return (
                                            <option key={index} value={code.codeId}>
                                                {code.name}
                                            </option>
                                        );
                                    })}
                                </select>
                            </td>
                            <td>
                                <select
                                    value={sportCodeId}
                                    onChange={(event) => {
                                        let codeId: string | undefined = event.currentTarget.value;
                                        if (codeId == '0') codeId = undefined;
                                        setSportCodeId(codeId);
                                        document.getElementById('keyHandler')?.focus();
                                    }}
                                >
                                    <option value="0">종목선택</option>
                                    {sportCodes?.map((code: Code, index: number) => {
                                        return (
                                            <option key={index} value={code.codeId}>
                                                {code.name}
                                            </option>
                                        );
                                    })}
                                </select>
                            </td>
                            <td>
                                <select
                                    value={regionCodeId}
                                    onChange={(event) => {
                                        let codeId: string | undefined = event.currentTarget.value;
                                        if (codeId == '0') codeId = undefined;
                                        setRegionCodeId(codeId);
                                        document.getElementById('keyHandler')?.focus();
                                    }}
                                >
                                    <option value="0">방문지역</option>
                                    {regionCodes?.map((code: Code, index: number) => {
                                        return (
                                            <option key={index} value={code.codeId}>
                                                {code.name}
                                            </option>
                                        );
                                    })}
                                </select>
                            </td>
                        </tr>
                        {/* <tr>
                            <td colSpan={4}>
                                <table style={{ margin: '5px' }}>
                                    <colgroup>
                                        <col style={{ width: '10%' }} />
                                        <col style={{ width: '30%' }} />
                                        <col style={{ width: '4%' }} />
                                        <col style={{ width: '10%' }} />
                                        <col style={{ width: '*' }} />
                                    </colgroup>
                                    <tbody>
                                        <tr>
                                            <td style={{ fontWeight: 500, fontSize: '15px' }}>시작일:</td>
                                            <td>
                                                <DatePicker
                                                    locale={ko}
                                                    dateFormat="yyyy-MM-dd"
                                                    closeOnScroll={true}
                                                    selected={visitedStartDate}
                                                    onChange={(date) => {
                                                        setVisitedStartDate(date);
                                                        document.getElementById('keyHandler')?.focus();
                                                    }}
                                                />
                                            </td>
                                            <td style={{ fontWeight: 900, fontSize: '15px' }}>~</td>
                                            <td style={{ fontWeight: 500, fontSize: '15px' }}>종료일:</td>
                                            <td>
                                                <DatePicker
                                                    locale={ko}
                                                    dateFormat="yyyy-MM-dd"
                                                    closeOnScroll={true}
                                                    selected={visitedEndDate}
                                                    onChange={(date) => {
                                                        setVisitedEndDate(date);
                                                        document.getElementById('keyHandler')?.focus();
                                                    }}
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr> */}
                    </tbody>
                </table>
            </div>
            <div className="member-search">
                <input
                    value={searchWord}
                    onChange={(event) => {
                        setSearchWord(event.currentTarget.value);
                    }}
                    onKeyPress={keyEventHandler}
                    type="text"
                    placeholder="팀명을 입력하세요."
                />
                <input id="keyHandler" value="" onKeyPress={keyEventHandler} style={{ width: '0px', border: '0px' }} />
                <button style={{ fontWeight: 'bold' }} className="bg-blue white round" onClick={searchClickHandler}>
                    <i className="fas fa-search"></i>조 회
                </button>
                <button style={{ fontWeight: 'bold' }} className="bg-blue white round" onClick={downloadExcelHandler}>
                    현황받기
                </button>
            </div>
        </>
    );
}

export default AdminVisitedTeamInfoListFilter;
