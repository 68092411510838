import React from 'react';
interface AdminMainTitleProps {
    title: string;
    children?: React.ReactNode
    tab?: React.ReactNode
}

function AdminMainTitle(props: AdminMainTitleProps) {
    return (
        <>
        <div className="member-tit">
            <h3>{props.title}</h3>
        </div>
        {props.tab || <></>}
        <div className="flex-basic" style={{justifyContent:'flex-end', paddingBottom:'10px'}}>
            {props.children}
        </div>
        </>
    )
}

export default AdminMainTitle;