import React, { useState, useRef, useEffect } from 'react';
import QnA from '../../../../domain/QnA';
import { each } from 'lodash';
import TopsForm from '../forms/2TopsForm';
import SpadiForm from '../forms/3SpadiForm';
import StressCheckForm from '../forms/4StressCheckForm';
import WHOQOL from '../forms/5WHOQQOL';
import FABQ from '../forms/6FABQ';
import IESRK from '../forms/7IESRK';
import FillInQuestionnarie from '../../../../domain/FillInQuestionnarie';
import AnswerItem from '../../../../domain/AnswerItem';
import { JsonConvert } from 'json2typescript';

let qnaTitles = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J'];
let itemNumbers = ['24EA', '2460', '2461', '2462', '2463', '2464', '2465', '2466', '2467', '2468', '2469', '246A'];

export default function GenerateQuestionnaireComponent(props: any) {
    let results = props.answers;
    const tabs = useRef<any>([]);
    const tabContents = useRef<any>([]);

    const contentInitHandler = () => {
        each(tabContents.current, (content, idx) => {
            content.style.display = 'none';
        });
    };
    const tabInitHandler = () => {
        each(tabs.current, (tab, idx) => {
            tab.classList.remove('tabon');
            tab.classList.add('taboff');
        });
    };
    const tabHandler = (evt: any, idx: number) => {
        contentInitHandler();
        tabInitHandler();
        tabContents.current[idx].style.display = 'block';
        evt.target.classList.add('tabon');
        evt.target.classList.remove('taboff');
    };

    const questionnaireTitles = (results: QnA[]) => {
        return (
            <>
                <ul>
                    {results?.map((li, idx) => {
                        let title = li.displayTitle;
                        let tabClass = idx === 0 ? 'tabon' : 'taboff';
                        return (
                            <li
                                key={idx}
                                ref={(elem) => {
                                    tabs.current[idx] = elem;
                                }}
                                className={tabClass}
                                onClick={(evt: any) => {
                                    tabHandler(evt, idx);
                                }}
                                style={{ cursor: 'pointer' }}
                            >
                                {title}
                            </li>
                        );
                    })}
                </ul>
            </>
        );
    };

    const questionnaireContents = (results: QnA[]) => {
        let questionnaireForm = results?.map((item, idx) => {
            let key = item.questionnaireKey;
            let answerStr: string | undefined = item.answer;

            let id = 'tabstory' + (idx + 1);
            let questionnaireSheet = getQuestionnaireSheet(key, item, answerStr);

            let style = { display: 'none' };
            if (idx === 0) {
                style.display = 'block';
            }
            let frame = (
                <div
                    id={id}
                    key={id}
                    style={style}
                    ref={(elem) => {
                        tabContents.current[idx] = elem;
                    }}
                >
                    {questionnaireSheet}
                </div>
            );

            return frame;
        });

        return questionnaireForm;
    };

    const getQuestionnaireSheet = (key: string, item: any, answerStr?: string) => {
        let questionnaire;

        let answerItemsMap = new Map<number, AnswerItem>();

        if (answerStr) {
            const jsonConvert: JsonConvert = new JsonConvert();
            const answerItems = jsonConvert.deserializeArray(JSON.parse(answerStr), AnswerItem);

            answerItems.forEach((value, index) => {
                if (value.questionNum) answerItemsMap.set(value.questionNum, value);
            });
        }

        if (key === 'NDI') {
            questionnaire = (
                <>
                    <div className="center-center">{item.title}</div>
                    <p>{item.description}</p>
                    {makeQuestion(item, answerStr)}
                </>
            );
        } else if (key === 'Tops') {
            questionnaire = <TopsForm answerItems={answerItemsMap}></TopsForm>;
        } else if (key === 'Spadi') {
            questionnaire = <SpadiForm answerItems={answerItemsMap}></SpadiForm>;
        } else if (key === 'StressCheckForm') {
            questionnaire = <StressCheckForm answerItems={answerItemsMap}></StressCheckForm>;
        } else if (key === 'WhoQ') {
            questionnaire = <WHOQOL answerItems={answerItemsMap}></WHOQOL>;
        } else if (key === 'FABQ') {
            questionnaire = <FABQ answerItems={answerItemsMap}></FABQ>;
        } else if (key === 'IESRK') {
            questionnaire = <IESRK answerItems={answerItemsMap}></IESRK>;
        }

        return questionnaire;
    };

    const makeQuestion = (item: QnA, answerStr: string | undefined) => {
        let questionnaires;
        let questStr: string | undefined = item.questions;
        let answers: [] | undefined = [];
        let answer: {} | undefined;
        let questions;
        if (questStr) {
            questionnaires = JSON.parse(questStr);

            if (answerStr) {
                answers = JSON.parse(answerStr);
            }

            questions = questionnaires.map((q: any, num: number) => {
                let contents = q.items;
                let question = q.question;
                let questNum = q.questionNum;
                let answer: { questionNum: number; itemNoOfAnswer: number } = { questionNum: -1, itemNoOfAnswer: -1 };
                let selectedAnswer: string = '';

                if (answers) answer = answers[num];

                return (
                    <div className="tab-story-number" key={num}>
                        <h2 className="center2">
                            제 {questNum}항목 - {question}
                        </h2>
                        <ul>
                            {contents.map((content: any, i: number) => {
                                let numb = String.fromCharCode(parseInt(itemNumbers[content.itemNo], 16));
                                selectedAnswer = answer?.itemNoOfAnswer === content.itemNo ? 'selected' : '';
                                return (
                                    <li key={i} className={selectedAnswer}>
                                        {numb}. {content.content}
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                );
            });
        }

        return questions;
    };

    return (
        <>
            <div className="quest-menu">{questionnaireTitles(results)}</div>
            <div className="quest-content">{questionnaireContents(results)}</div>
        </>
    );
}
