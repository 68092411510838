import React, { useEffect, useState } from 'react';
import SearchCnt from '../../component/search/SearchCnt';
import SearchCategoryDisplay from '../../component/search/SearchCategoryDisplay';
import SearchViewTypeWithCategory from '../../component/search/SearchViewTypeWithCategory';
import Infra from '../../domain/infra/Infra';
import { InfraSearchList } from '../../component/infra/InfraSearchList';
import InfraSearchMap from '../../component/infra/InfraSearchMap';
import InfraServiceImpl from '../../service/infra/impl/InfraServiceImpl';
import UserLocation from '../../domain/user/UserLocation';
import SearchIteamPaging from '../../component/search/SearchItemPaging';
import InfraSearchCondition from '../../domain/infra/param/InfraSearchCondition';
interface SearchOfInfraContainerProps {
    searchedInfras?: Infra[];
    searchCnt: number;
    userLocation?: UserLocation;
    searchingType: string;
    infraSearchCondition: InfraSearchCondition;
    setInfraSearchCondition: React.Dispatch<React.SetStateAction<InfraSearchCondition>>;
}

function SearchOfInfraContainer(props: SearchOfInfraContainerProps) {
    const [searchViewType, setSearchViewType] = useState('list');

    return (
        <>
            {/* <SearchCnt searchCnt={props.searchCnt}></SearchCnt> */}
            <div className="output-cate">
                <div className="cate-left">
                    <SearchCategoryDisplay></SearchCategoryDisplay>
                </div>
                <div className="cate-right">
                    <SearchViewTypeWithCategory
                        setSearchViewType={setSearchViewType}
                        infraSearchCondition={props.infraSearchCondition}
                        listViewTypeActive={true}
                        mapViewTypeActive={true}
                        searchingType={props.searchingType}
                        setInfraSearchCondition={props.setInfraSearchCondition}
                    ></SearchViewTypeWithCategory>
                </div>
            </div>
            <div className="clear"></div>
            <div className="output-left">{infraSearchRender(props.searchedInfras, props.userLocation, searchViewType)}</div>
        </>
    );
}

function infraSearchRender(infras: Infra[] | undefined, userLocation: UserLocation | undefined, viewType: string) {
    if (infras) {
        if (infras.length > 0 && viewType === 'list') {
            return <InfraSearchList searchedInfras={infras}></InfraSearchList>;
        } else if (infras.length > 0 && viewType === 'map') {
            return <InfraSearchMap userLocation={userLocation} searchedInfras={infras}></InfraSearchMap>;
        } else {
            return <h5>검색 된 시설이 없습니다.</h5>;
        }
    }
}

export default SearchOfInfraContainer;
