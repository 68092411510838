import React from 'react';
import { Link } from 'react-router-dom';
import Infra from '../../domain/infra/Infra';

interface InfraViewCardProps {
    infra: Infra;
}

function InfraViewCard(props: InfraViewCardProps) {
    const imgUrl = props.infra.getMainAttachFileReturnUndfeined();

    return (
        // <Link to={`/trainning/infra/${props.infra.infraNo}`}>
        <Link to={props.infra.getDetailLink()}>
            {/* <li className="wow  animate__animated animate__fadeInUp" data-wow-delay="0.2s"> */}
            <li>
                <div>
                    {props.infra.eventCnt ? (
                        <img src={'/assets/image/icon-favrit.png'} style={{ position: 'absolute', width: '40px', height: '40px' }} />
                    ) : (
                        <></>
                    )}
                    {/* <img className="main-list-thum" src={props.infra.getMainAttachFile()}/> */}
                    {imgUrl ? (
                        <img className="main-list-thum" src={imgUrl} />
                    ) : (
                        <div
                            className="main-list-thum no_thum"
                            style={{
                                backgroundSize: '200px',
                                backgroundImage: "url('/assets/image/logo_with_name.png')",
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center center',
                                opacity: '0.8',
                            }}
                        >
                            {/* <div className="box">
                                <span>{props.infra.name}</span>
                            </div> */}
                            <table className="box">
                                <tr>
                                    <td>
                                        <h5 style={{ opacity: '1' }}>{props.infra.name}</h5>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    )}
                    <p className="tit">{props.infra.name}</p>
                    <p>{props.infra.address}</p>
                    <p>관련 행사 {props.infra.eventCnt}개</p>
                </div>
            </li>
        </Link>
    );
}

export default InfraViewCard;
