import React from 'react';
import Infra from '../../domain/infra/Infra';
import InfraLocationMap from '../map/InfraLocationMap';
import UserLocation from '../../domain/user/UserLocation';

interface InfraSearchMapProp {
    searchedInfras: Infra[];
    userLocation?: UserLocation;
}

function InfraSearchMap(props: InfraSearchMapProp) {
    return (
        <div className="w100">
            <InfraLocationMap userLocation={props.userLocation} mapLevel={5} infras={props.searchedInfras}></InfraLocationMap>
        </div>
    )
}

export default InfraSearchMap;