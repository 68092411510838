import React from 'react';
import MyPageMainLayout from '../../../layout/MyPageMainLayout';
import SubMenuNav from '../../../nav/SubMenuNav';
import UserPageSubMenuNav from '../nav/UserPageSubMenuNav';
import MyPageRightContent from '../../../layout/MyPageRightContent';

function UserMyPageUsePolicyContainer() {
    return (
        <MyPageMainLayout >
            <MyPageRightContent contentTitle={""}>
                <div className="tabcontent" style={{width: '100%', marginBottom: '10px'}}>
                    <h3>이용약관</h3>
                    <p>여러분을 환영합니다.
                    다양한 투어링 서비스를 즐겨보세요.
                    회원으로 가입하시면 투어링 서비스를 보다 편리하게 이용할 수 있습니다.
                    여러분이 제공한 콘텐츠를 소중히 다룰 것입니다.
                    여러분의 개인정보를 소중히 보호합니다.
                    타인의 권리를 존중해 주세요.
                    투어링 서비스 이용과 관련하여 몇 가지 주의사항이 있습니다.
                    스포츠투어링에서 제공하는 다양한 포인트를 요긴하게 활용해 보세요.
                    부득이 서비스 이용을 제한할 경우 합리적인 절차를 준수합니다.
                    스포츠투어링의 잘못은 스포츠투어링가 책임집니다.
                    일부 투어링 서비스에는 광고가 포함되어 있습니다.
                    언제든지 투어링 서비스 이용계약을 해지하실 수 있습니다.
                    서비스 중단 또는 변경 시 꼭 알려드리겠습니다.
                    주요 사항을 잘 안내하고 여러분의 소중한 의견에 귀 기울이겠습니다.
                    여러분이 쉽게 알 수 있도록 약관 및 운영정책을 게시하며 사전 공지 후 개정합니다.
                    여러분을 환영합니다.
                    다양한 투어링 서비스를 즐겨보세요.
                    회원으로 가입하시면 투어링 서비스를 보다 편리하게 이용할 수 있습니다.
                    여러분이 제공한 콘텐츠를 소중히 다룰 것입니다.
                    여러분의 개인정보를 소중히 보호합니다.
                    타인의 권리를 존중해 주세요.
                    투어링 서비스 이용과 관련하여 몇 가지 주의사항이 있습니다.
                    스포츠투어링에서 제공하는 다양한 포인트를 요긴하게 활용해 보세요.
                    부득이 서비스 이용을 제한할 경우 합리적인 절차를 준수합니다.
                    스포츠투어링의 잘못은 스포츠투어링가 책임집니다.
                    일부 투어링 서비스에는 광고가 포함되어 있습니다.
                    언제든지 투어링 서비스 이용계약을 해지하실 수 있습니다.
                    서비스 중단 또는 변경 시 꼭 알려드리겠습니다.
                    주요 사항을 잘 안내하고 여러분의 소중한 의견에 귀 기울이겠습니다.
                    여러분이 쉽게 알 수 있도록 약관 및 운영정책을 게시하며 사전 공지 후 개정합니다.
                    </p>
                </div>
                <div className="tabcontent" style={{width: '100%', marginBottom: '10px'}}>
				  <h3>개인정보취급방침</h3>
				  <p>개인정보취급방침</p>
				</div>
                <div className="tabcontent" style={{width: '100%', marginBottom: '10px'}}>
				  <h3>서비스</h3>
				  <p>서비스</p>
				</div>
            </MyPageRightContent>
        </MyPageMainLayout>
    )
}

export default UserMyPageUsePolicyContainer;