import { constants } from 'buffer';
import { useEffect, useState } from 'react';
import UserLocation from '../domain/user/UserLocation';
import { INIT_LATITUDE, INIT_LONGITUDE } from '../config/Constant';
function useGeographic() {
    const [userLocation, setUserLocation] = useState<UserLocation>();
    // if(navigator.geolocation) {
    //     navigator.geolocation.getCurrentPosition((position) => {
    //         if(userLocation === undefined) {
    //             const curUserLocation = new UserLocation(position.coords.latitude, position.coords.longitude);
    //             setUserLocation(curUserLocation);
    //         }
    //     },
    //     (err) => {
    //         console.error("localhost or https 에서만 위치가 받아와짐");
    //         console.error(err);
    //         // const curUserLocation = new UserLocation(INIT_LATITUDE, INIT_LONGITUDE);
    //         // setUserLocation(curUserLocation);
    //     },
    //     {
    //         enableHighAccuracy: false,
    //         maximumAge: 0,
    //         timeout: Infinity
    //     });
    // }
    useEffect(() => {
        const curUserLocation = new UserLocation(35.238287, 128.692490);
        setUserLocation(curUserLocation);
    }, []);

    return userLocation;
}

export default useGeographic;