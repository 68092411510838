import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import TmpRegistedTeamUser from '../../domain/team/TmpRegistedTeamUser';
import useTmpRegistedTeamUserDelete from '../../hook/team/useTmpRegistedTeamUserDelete';
import AdminRegistedUserTableTr from './AdminRegistedUserTableTr';

interface AdminTableProps {
    users?: TmpRegistedTeamUser[];
}

function AdminRegistedUserTable(props: AdminTableProps) {
    return (
        <>
            <div className="member-list">
                <table cellPadding="0" cellSpacing="0">
                    <thead>
                        <tr>
                            <td>가입여부</td>
                            <td>선수명</td>
                            <td>아이디(이메일)</td>
                            <td>휴대폰번호</td>
                            <td>관리</td>
                        </tr>
                    </thead>
                    <tbody>
                        {props.users && props.users.length !== 0 ? (
                            props.users.map((team: TmpRegistedTeamUser, index: number) => {
                                return <AdminRegistedUserTableTr tmpUser={team} index={index}></AdminRegistedUserTableTr>;
                            })
                        ) : (
                            <tr>
                                <td colSpan={5}>
                                    <h5>검색된 선수가 없습니다.</h5>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </>
    );
}

export default AdminRegistedUserTable;
