import React, { useState } from 'react';
import AnswerItem from '../../../../domain/AnswerItem';
import QuestionnariesFormProps from './QuestionnariesFormsProps';
import ReportDataParam from '../../../../../../domain/report/param/ReportDataParam';
import ReportTypeEnum from '../../../../../../domain/report/param/ReportTypeEnum';
import axios from 'axios';
import APIServerConfig from '../../../../../../config/APIServerConfig';
import useReportQuestionnarieAnswerMake from '../../../../../../hook/report/useReportQuestionnarieAnswerMake';
import Questionnaire from '../../../../domain/Questionnaire';

export default function SatisfyForm(props: QuestionnariesFormProps) {
    let itemNumbers = ['24EA', '2460', '2461', '2462', '2463', '2464', '2465', '2466', '2467', '2468', '2469', '246A'];
    let questionnaire = props.questionnaire;

    const makeQuestion = (item: Questionnaire | undefined) => {
        let questionnaires;
        let questStr: string | undefined = item?.questions;
        let answers: [] | undefined = [];
        let questions;
        if (questStr) {
            questionnaires = JSON.parse(questStr);

            questions = questionnaires.map((q: any, quesNum: number) => {
                let contents = q.items;
                let question = q.question;
                let questNum = q.questionNum;
                let answer: { questionNum: number; itemNoOfAnswer: number } = { questionNum: -1, itemNoOfAnswer: -1 };
                let selectedAnswer: string = '';

                if (answers) answer = answers[quesNum];

                return (
                    <div className="tab-story-number" key={quesNum}>
                        <h2 className="center2">
                            제 {questNum}항목 - {question}
                        </h2>
                        <ul>
                            {contents.map((content: any, i: number) => {
                                let numb = String.fromCharCode(parseInt(itemNumbers[content.itemNo], 16));
                                selectedAnswer = answer?.itemNoOfAnswer === content.itemNo ? 'selected' : '';
                                return (
                                    <li
                                        key={i}
                                        className={getCheckOnClassName(questNum, content.itemNo)}
                                        onClick={() => {
                                            putAnswerItem(questNum, content.itemNo);
                                        }}
                                    >
                                        {numb}. {content.content}
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                );
            });
        }

        return questions;
    };

    const putAnswerItem = (qNum: number, answerNumber: number) => {
        if (props.answerItems) {
            const newAnswerItem = AnswerItem.getNewAnswerItem(qNum, answerNumber);
            if (props.setAnswer) props.setAnswer(qNum, newAnswerItem);
        }
    };

    const getCheckOnClassName = (qNum: number, answerNumber: number): string => {
        if (props.answerItems) {
            if (props.answerItems.get(qNum)?.itemNoOfAnswer == answerNumber) {
                return 'check-on';
            } else {
                return '';
            }
        } else {
            return '';
        }
    };

    const { reportData } = useReportQuestionnarieAnswerMake(props.answerItems);

    return <>{makeQuestion(questionnaire)}</>;
}
