import React, { useState, useEffect } from 'react';
import TrainningSubHeader from '../../layout/TrainningSubHeader';
import InfraRecommandContainer from '../../../../container/infra/InfraRecommandContainer';
import { InfraCategoryType } from '../../../../domain/infra/InfraCategoryType';
import { RouteComponentProps } from 'react-router-dom';
import InfraDetailViewContainer from '../../../../container/infra/InfraDetailViewContainer';
import Infra from '../../../../domain/infra/Infra';
import InfraServiceImpl from '../../../../service/infra/impl/InfraServiceImpl';
import MiniSearchBar from '../../../../component/search/MiniSearchBar';
import SearchLeft from '../../../../component/layout/SearchLeft';
import SearchIn from '../../../../component/layout/SearchIn';
import InfraNearDistanceContainer from '../../../../container/infra/InfraNearDistanceContainer';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../moldules/index';

const infraService = new InfraServiceImpl();
interface TrainningInfraDetailContainerProps {
    infraNo: string;
}

const TrainningInfraDetailContainer = ({ match }: RouteComponentProps<TrainningInfraDetailContainerProps>) => {
    const { user } = useSelector((rootState: RootState) => rootState.userSessionReducer);
    const [infra, setInfra] = useState<Infra>();

    useEffect(() => {
        infraService.getInfra(match.params.infraNo).then((infra: Infra) => {
            setInfra(infra);
        });
    }, [match.params.infraNo]);

    return (
        <>
            <TrainningSubHeader></TrainningSubHeader>
            <div className="content-wrap">
                <SearchLeft>
                    <SearchIn>
                        <MiniSearchBar placeholder="시설 및 팀 검색하세요."></MiniSearchBar>
                        <div className="h30"></div>
                        <div className="filter-box">
                            <InfraNearDistanceContainer
                                title={'주변 스포츠 시설'}
                                parentInfraCategoryNo={InfraCategoryType.SPORT_INFRA_NO}
                                infra={infra}
                            ></InfraNearDistanceContainer>
                            <InfraNearDistanceContainer
                                title={'주변 숙소'}
                                parentInfraCategoryNo={InfraCategoryType.LODGMENT_NO}
                                infra={infra}
                            ></InfraNearDistanceContainer>
                            <InfraNearDistanceContainer
                                title={'주변 맛집'}
                                parentInfraCategoryNo={InfraCategoryType.FOOD_NO}
                                infra={infra}
                            ></InfraNearDistanceContainer>
                        </div>
                    </SearchIn>
                </SearchLeft>
                {infra ? <InfraDetailViewContainer user={user} infra={infra}></InfraDetailViewContainer> : <></>}
            </div>
        </>
    );
};

export default TrainningInfraDetailContainer;
