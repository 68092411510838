import React, { useEffect, useState } from 'react';
import AdminMainTitle from '../../../../component/admin/AdminMainTitle';
import useRetrieveEventsByCondition from '../../../../hook/event/useRetrieveEventsByCondition';
import EventSearchCondition from '../../../../domain/param/EventSearchCondition';
import AdminEventsTable from '../../../../component/admin/AdminEventsTable';
import { Link } from 'react-router-dom';
import useAdminPaging from '../../hook/useAdminPaging';
import AdminRetrieveItemPaging from '../../component/AdminRetrieveItemPaging';

function AdminEventIndexPage() {
    const [eventSearchCondition, setEventSearchCondition] = useState(new EventSearchCondition());
    const { page, setPage, pagingSize, setPagingSize, pagingCnt, paging } = useAdminPaging();

    const { events, eventsCnt } = useRetrieveEventsByCondition(eventSearchCondition, paging);

    return (
        <>
            <AdminMainTitle title="쿠폰 관리">
                <div className="flex-basic"></div>
                <Link to="/admin/event/registry">
                    <button className="bg-light-blue" style={{ float: 'right' }}>
                        쿠폰등록
                    </button>
                </Link>
            </AdminMainTitle>
            <AdminEventsTable page={page} pagingSize={pagingSize} events={events}></AdminEventsTable>
            <AdminRetrieveItemPaging
                totalCnt={eventsCnt}
                pagingCnt={pagingCnt}
                pagingSize={pagingSize}
                setPagingSize={setPagingSize}
                setPage={setPage}
                currentPageNum={page}
            ></AdminRetrieveItemPaging>
        </>
    );
}

export default AdminEventIndexPage;
