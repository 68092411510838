import React from 'react';
import { JsonObject, JsonProperty } from 'json2typescript';
import Exercise from '../Exercise';

@JsonObject('itemsPrescription')
export default class ItemsPrescription {
    @JsonProperty('exerItem')
    exerItem: string = '';
    @JsonProperty('applyedLevel')
    applyedLevel: string = '';
    @JsonProperty('exercises')
    exercises: Exercise[] = [];
    @JsonProperty('opinion')
    opinion: string = '';
}
