import React from 'react';
function CenterIntruduceTab() {
    return (
        <div className="tab_content">
            <p>운영 설명</p>
            <p>운영 설명</p>
            <p>운영 설명</p>
            <p>운영 설명</p>
        </div>
    )
}

export default CenterIntruduceTab;