import React, { useEffect, useState } from 'react';
import { Link, useRouteMatch, useParams } from 'react-router-dom';
import MyPageMainLayout from '../../../layout/MyPageMainLayout';
import MyPageRightContent from '../../../layout/MyPageRightContent';
import Reservation from '../../../../../domain/reservation/Reservation';
import ReservationInfraCard from '../../../../../component/infra/ReservationInfraCard';

import { useSelector } from 'react-redux';
import { RootState } from '../../../../../moldules/index';
import Paging from '../../../../../domain/common/Paging';
import ReservationSearchCondition from '../../../../../domain/reservation/param/ReservationSearchCondition';
import useReservationGroupOfTeam from '../../../../../hook/useReservationGroupOfTeam';
import ReservationServiceImpl from '../../../../../service/reservation/impl/ReservationServiceImpl';

const reservationService = new ReservationServiceImpl();

function MyPageReservationListPage() {
    const { path, url } = useRouteMatch();
    const params: any = useParams();
    const { user, myTeam, isLogin, hasTeamAuth, hasSystemAuth } = useSelector((state: RootState) => state.userSessionReducer);
    const [reservations, setReservations] = useState<Reservation[]>();
    const paging = new Paging(0, 50);
    const searchCondition = new ReservationSearchCondition();
    const groupNo: any = params.reservationGroupNo;

    if (!hasTeamAuth) {
        searchCondition.reservaterNos = user.userNo;
    }

    const [title, setTitle] = useState<string>('예약 상세 현황'); //팀 명과 함께 예약 목록 출력
    useEffect(() => {
        reservationService.getReservationOfGroupNo(groupNo).then((reservations: Reservation[]) => {
            let length = reservations.length;
            reservations.map((reservation) => {
                setTitle('<' + reservation.infra?.name + ' (' + length + '명)> 예약 상세 목록');
            });
            setReservations(reservations);
        });
    }, []);

    return (
        <MyPageMainLayout>
            <MyPageRightContent contentTitle={title}>
                {reservations ? (
                    reservations.length > 0 ? (
                        reservations.map((reservation: Reservation, index: number) => {
                            return (
                                <ReservationInfraCard
                                    key={index}
                                    reservation={reservation}
                                    link={`/mypage/team-admin/reservation/${reservation.reservationNo}/detail`}
                                ></ReservationInfraCard>
                            );
                        })
                    ) : (
                        <div className="my-in">
                            <div className="my-in-info">
                                <div className="my-in-info-box">
                                    <p className="txt-center">예약내역이 없습니다.</p>
                                    {/* <Link className="bt-blue" to={'/trainning'}>
                                        스포츠 시설 찾기
                                    </Link> */}
                                </div>
                            </div>
                        </div>
                    )
                ) : (
                    <></>
                )}
            </MyPageRightContent>
        </MyPageMainLayout>
    );
}

export default MyPageReservationListPage;
