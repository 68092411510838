import { useEffect, useState } from 'react';
import Infra from '../../domain/infra/Infra';
import InfraServiceImpl from '../../service/infra/impl/InfraServiceImpl';
import { NEAR_DISTAINCE } from '../../config/Constant';
import useInfraOfCategoryRetrieve from './useInfraCategoryRetrieve';

const infraService = new InfraServiceImpl();
function useInfraOfNearDistanceRetrieve(parentInfraCategoryNo: number, latitude: number = 0, longitude: number = 0) {
    const infraCategories = useInfraOfCategoryRetrieve(parentInfraCategoryNo);
    const [infras, setInfras] = useState<Infra[]>();
    const [childInfraCategoryNo, setChildInfraCategoryNo] = useState<number|undefined>();

    useEffect(() => {
        if(childInfraCategoryNo) {
            infraService.getInfraOfChildCategoryNoAndNearDistance(parentInfraCategoryNo, childInfraCategoryNo, latitude, longitude, NEAR_DISTAINCE)
            .then((infras: Infra[]) => {
                setInfras(infras);
            });
        } else {
            infraService.getInfraOfParentCategoryNoAndNearDistance(parentInfraCategoryNo, latitude, longitude, NEAR_DISTAINCE)
            .then((infras: Infra[]) => {
                setInfras(infras);
            });
        }
    }, [childInfraCategoryNo]);

    return {infras, infraCategories, setChildInfraCategoryNo};
}

export default useInfraOfNearDistanceRetrieve;