import React from 'react';

function MobileUseTabe() {
    return (
        <div className="tab_content">
            <p>모바일 사용 설명</p>
            <p>모바일 사용 설명</p>
            <p>모바일 사용 설명</p>
            <p>모바일 사용 설명</p>
        </div>
    )
}

export default MobileUseTabe;