import APIServerConfig from '../../../../config/APIServerConfig';
import AbstractService from '../../../../service/AbstractService';
import FillInQuestionnarie from '../../domain/FillInQuestionnarie';
import RehabFillInQuestionnairesService from '../RehabFillInQuestionnairesService';

class RehabFillInQuestionnairesServiceImpl extends AbstractService implements RehabFillInQuestionnairesService {
    getFillInQuestionnarie(answerNo: number, targetNo: number, quesNo: number): Promise<FillInQuestionnarie> {
        return new Promise((resolve, reject) => {
            APIServerConfig.recordAPIAxios.get(`/fill-in-questionnarie/answer`, {
                params: {
                    answerNo: answerNo, targetNo: targetNo, quesNo: quesNo     
                }
            })
            .then(response => {
                const fillInQuestionnarie: FillInQuestionnarie = this.jsonConvert.deserializeObject(response.data, FillInQuestionnarie);
                resolve(fillInQuestionnarie);
            })
            .catch(err => {
                reject(err);
            });
        });
    }

    answerFillInQuestionnarie(fillInQuestionnaire: FillInQuestionnarie): Promise<string> {
        return new Promise((resolve, reject) => {
            APIServerConfig.recordAPIAxios.post(`/fill-in-questionnarie/answer`, JSON.stringify(fillInQuestionnaire))
            .then(response => {
                resolve(response.data);
            })
            .catch(err => {
                reject(err);
            });
        });
    }

}

export default RehabFillInQuestionnairesServiceImpl;