import React from 'react';
import MembersInfoContainer from '../members/MembersInfoContainer';
import {RouteComponentProps} from 'react-router-dom';

interface MatchParams {
    memberNo : string;
}

function MembersDetailContainer({match} : RouteComponentProps<MatchParams>) {
    const {memberNo} = match.params;
    
    return (
        <MembersInfoContainer memberNo={memberNo}></MembersInfoContainer>
    )
}

export default MembersDetailContainer;