import { JsonObject, JsonProperty } from 'json2typescript';
import User from '../user/User';
import Team from '../team/Team';
import Infra from '../infra/Infra';
import Code from '../code/Code';
import DateUtils from '../../utils/DateUtils';
import ScheduleAble from '../common/ScheduleAble';
@JsonObject('reservation')
class GroupedReservation {
    @JsonProperty('reservationGroupNo')
    reservationGroupNo?: string;
    @JsonProperty('reservationNos')
    reservationNos?: string;
    @JsonProperty('cnt')
    cnt?: string;
}

export default GroupedReservation;
