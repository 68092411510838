import UserSearchCondition from '../../domain/user/param/UserSearchCondition';
import Paging from '../../domain/common/Paging';
import { useEffect, useState } from 'react';
import UserServiceImpl from '../../service/user/impl/UserServiceImpl';
import RetrieveUser from '../../domain/user/RetrieveUser';

const userService = new UserServiceImpl();
function useUserRetrieveBySearchCondition(condition?: UserSearchCondition, paging?: Paging) {
    const [users, setUsers] = useState<RetrieveUser[]>();
    const [usersCnt, setUserCnt] = useState<number>(0);
    useEffect(() => {
        if (condition && paging) {
            userService.getUserInfos(condition, paging).then((users: RetrieveUser[]) => {
                setUsers(users);
            });

            userService.getUserInfosCnt(condition).then((userCnt) => {
                setUserCnt(userCnt);
            });
        }
    }, [condition, paging]);

    return {
        users,
        usersCnt,
    };
}

export default useUserRetrieveBySearchCondition;
