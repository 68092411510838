import React, { useEffect, useState } from 'react';
import Infra from '../../domain/infra/Infra';
import { Link } from 'react-router-dom';
import ReservationPopupContainer from '../../container/reservation/ReservationPopupContainer';
import { confirmAlert } from 'react-confirm-alert';

interface InfraSearchListProps {
    searchedInfras: Infra[];
}

export function InfraSearchList(props: InfraSearchListProps) {
    let [reservationInfra, setReservationInfra] = useState(new Infra());
    let [reservationModalOpenable, setReservationModalOpenable] = useState(false);
    // if(!reservationInfra.childInfras) throw new Error('하위 인프라 없음');
    return (
        <>
            <ReservationPopupContainer
                mainInfra={reservationInfra}
                childInfras={reservationInfra.childInfras}
                openable={reservationModalOpenable}
                changeReservationPopupOpenable={() => setReservationModalOpenable(!reservationModalOpenable)}
            ></ReservationPopupContainer>
            <table className="output-list">
                <tbody>
                    {props.searchedInfras.map((searchedInfra, index) => {
                        {
                            return (
                                <InfraSearchListItem
                                    key={index}
                                    searchedInfra={searchedInfra}
                                    setReservationInfra={setReservationInfra}
                                    setReservationModalOpenable={setReservationModalOpenable}
                                ></InfraSearchListItem>
                            );
                        }
                    })}
                </tbody>
            </table>
        </>
    );
}

interface InfraSearchListItemProps {
    searchedInfra: Infra;
    setReservationInfra: (infra: Infra) => void;
    setReservationModalOpenable: (opeable: boolean) => void;
}

export function InfraSearchListItem(props: InfraSearchListItemProps) {
    const imgUrl = props.searchedInfra.getMainAttachFileReturnUndfeined();
    return (
        <tr>
            <td>
                {/* <Link to={"/trainning/infra/" + props.searchedInfra.infraNo}> */}
                <Link to={props.searchedInfra.getDetailLink()}>
                    {props.searchedInfra.eventCnt ? (
                        <img src={'/assets/image/icon-favrit.png'} style={{ position: 'absolute', width: '40px', height: '40px' }} />
                    ) : (
                        <></>
                    )}
                    {/* <img className="list-thum" src={props.searchedInfra.getMainAttachFile()}/> */}
                    {
                        imgUrl ? <img className="list-thum" src={imgUrl} /> : <img className="list-thum" src="/assets/image/logo-intro.png"></img>
                        // <div className="list-thum" style={{border: '0px', backgroundImage: "url('/assets/image/logo-intro.png')", backgroundRepeat: 'no-repeat', backgroundSize: 'contain', backgroundPosition: 'center center', opacity: '0.8'}}>
                        //     <span>{props.searchedInfra.name}</span>
                        // </div>
                    }
                </Link>
            </td>
            <td>
                <div>
                    <p>
                        <Link to={props.searchedInfra.getDetailLink()}>{props.searchedInfra.name}</Link>
                    </p>
                    <p>{props.searchedInfra.address}</p>
                </div>
            </td>
            <td>
                <button
                    onClick={() => {
                        confirmAlert({
                            title: '알림',
                            message: '서비스 준비 중입니다. 더 좋은 모습으로 찾아 뵙겠습니다.',
                            buttons: [{ label: '확인', onClick: () => console.log('') }],
                        });
                        //props.setReservationInfra(props.searchedInfra);
                        //props.setReservationModalOpenable(true);
                    }}
                    className="reservation"
                >
                    예약하기
                </button>
                {/* <button className="viewmap">길찾기</button> */}
            </td>
        </tr>
    );
}
