import React, {useEffect, useState} from 'react';
import { SearchItemType, SearchItemCodeValue, SearchItemInfraCategoryValue } from '../../../../component/search/LeftSearcher';
import Code from '../../../../domain/code/Code';
import LeftSearcher from '../../../../component/search/LeftSearcher';
import RightSearcher from '../../../../component/search/RightSearcher';
import InfraCategoryServiceImpl from '../../../../service/infra/impl/InfraCategoryServiceImpl';
import InfraCategoryService from '../../../../service/infra/InfraCategoryService';
import InfraCategory from '../../../../domain/infra/InfraCategory';
import CodeService from '../../../../service/code/CodeService';
import CodeServiceImpl from '../../../../service/code/impl/CodeServiceImpl';
import { CodeType } from '../../../../domain/code/CodeType';
import TouringSubHeader from '../../layout/TouringSubHeader';

const infraCategoryService: InfraCategoryService = new InfraCategoryServiceImpl();
const codeService: CodeService = new CodeServiceImpl();

function getSearchItemCodeValues(regionCodes: Code[]): SearchItemCodeValue[] {
    return [
        new SearchItemCodeValue(SearchItemType.REGION, regionCodes)
    ]
}

function getSearchItemInfraCategoryValues(infraCategoreis: InfraCategory[]) {
    return [
        new SearchItemInfraCategoryValue(SearchItemType.INFRA, infraCategoreis)
    ]
}

interface TouringInfraSearchContainerProps {
    title: string;
    parentInfraCategoryNo: number;
}

function TouringInfraSearchContainer(props: TouringInfraSearchContainerProps) {
    const [infraCategories, setInfraCategories] = useState(new Array<InfraCategory>());
    const [regionCodes, setRegionCodes] = useState(new Array<Code>());
    
    useEffect(() => {
        infraCategoryService.retrieveChildInfraCategory(props.parentInfraCategoryNo)
        .then(infraCategories => {
            setInfraCategories(infraCategories);
        })

        codeService.getCode(CodeType.REGION)
        .then(regionCodes => {
            setRegionCodes(regionCodes);
        })
    }, []);

    return (
        <div>
            <TouringSubHeader></TouringSubHeader>
            <LeftSearcher parentInfraCategoryNo={props.parentInfraCategoryNo}
                          searchItemInfraCategoryValues={getSearchItemInfraCategoryValues(infraCategories)} 
                          searchItemCodeValues={getSearchItemCodeValues(regionCodes)}></LeftSearcher>
            <RightSearcher title={props.title} parentInfraCategoryNo={props.parentInfraCategoryNo}></RightSearcher>
        </div>
    )
}

export default TouringInfraSearchContainer;