import React from 'react';
import { CodeType } from '../../../domain/code/CodeType';
import InfraCategory from '../../../domain/infra/InfraCategory';
import useMultiSelectHook from './hook/useMuliSelectHook';

interface MultiSelectByInfraCategoryProps {
    codeType: CodeType;
    title: string;
    infraCategories: InfraCategory[];
}

function MultiSelectByInfraCategory(props: MultiSelectByInfraCategoryProps) {
    const {
        selectedCnt,
        allConditionCheck, conditionCheck,
        selectedEventHandler, selectedClearEventHandler
    } = useMultiSelectHook(props.codeType);
    

    return (
        <div className="type">
            <p className="search-tit">{props.title} {selectedCnt == 0 ? '전체' : selectedCnt}</p>
            <ul className="two">
                <li>
                    <label>
                        <input onChange={() => selectedClearEventHandler()} type="checkbox" value='all' checked={allConditionCheck()}/>전체
                    </label>
                </li>
                {
                    props.infraCategories.map((infraCategory, index) => {
                        return (
                            <li key={index}>
                                <label>
                                    <input onChange={() => selectedEventHandler(infraCategory.infraCategoryNo)} 
                                            type="checkbox" 
                                            checked={conditionCheck(infraCategory.infraCategoryNo)}/>{infraCategory.name}
                                </label>
                            </li>
                        )
                    })
                }
            </ul>
            <div className="clear"></div>
        </div>
    )
}

export default MultiSelectByInfraCategory;