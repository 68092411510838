import React, { useState, useEffect } from 'react';
import ActionComponent from '../ActionComponent';
import Button from '../utils/ElementsComponent';
import RehabServiceImpl from '../../../service/impl/RehabServiceImpl';
import QnA from '../../../domain/QnA';
import GeneraterQuestionnaires from './utils/GenerateSatifyingQuestionnaires';
import TargetPerson from '../../../domain/TargetPerson';
import { confirmAlert } from 'react-confirm-alert';

interface SatisfyingPopupInter {
    targetNo: number;
    selectedTarget: TargetPerson;
    setSatisfyOpenable: (satisfyOpenable: boolean) => void;
}

const rehabService = new RehabServiceImpl();

export default function SatisfyingPopupComponent(props: SatisfyingPopupInter) {
    const actions = ActionComponent();
    const [questionnaires, setQuestionnaires] = useState<QnA[]>();
    const [answers, setAnswers] = useState<QnA[]>();

    let targetNo: number = props.targetNo;
    let answerList: QnA[] = new Array();
    let targetPerson = props.selectedTarget;

    useEffect(() => {
        rehabService.getQuestionnairesNAnswer(targetNo).then((data: any) => {
            let qnaList = data.qnaList;
            setQuestionnaireStatus(qnaList);
        });
    }, []);

    const sendQuestionnaireHandler = (e: any) => {
        let quesNo: number = e.target.value;
        rehabService.sendQuestionnaireToTargetNo(targetNo, quesNo).then((data: any) => {
            let qnaList = data.qnaList;
            let question = questionnaires?.find((item) => {
                return item.quesNo == quesNo;
            });
            let message = targetPerson?.reservater?.name + '님 에게 설문 "' + question?.displayTitle + '"을 발송하였습니다.';

            confirmAlert({
                title: '발송성공',
                message: message,
                buttons: [
                    {
                        label: '확인',
                        onClick: () => {
                            setQuestionnaireStatus(qnaList);
                        },
                    },
                ],
            });
            //setQuestionnaireStatus(qnaList);
        });
    };
    const openQuestionnaireListHandler = (e: any) => {
        actions.researchSend();
    };
    const openResultHandler = (e: any) => {
        actions.researchView();
    };

    function setQuestionnaireStatus(qnaList: QnA[]) {
        qnaList.map((questionnaire, idx) => {
            let answerNo = questionnaire.answerNo;
            if (answerNo !== 0) {
                answerList.push(questionnaire);
                questionnaire.isSend = true;
            }
        });

        setQuestionnaires(qnaList);
        setAnswers(answerList);
    }

    const sendState = (questionnaire: QnA) => {
        let isSend = questionnaire.isSend;
        let className = 'fas fa-share-square';
        let name = '발송하기';
        if (isSend) {
            className = 'fas fa-check-circle';
            name = '발송완료';
        }

        return (
            <>
                <i className={className}></i> {name}
            </>
        );
    };
    const contents = (list: QnA[] | undefined) => {
        return (
            <>
                {list?.map((questionnaire: QnA, idx) => {
                    let isSend = questionnaire.isSend;
                    let className = 'center-button-no';
                    let btn = (
                        <Button className={className} value={questionnaire.quesNo} handler={sendQuestionnaireHandler} rel={targetNo}>
                            {sendState(questionnaire)}
                        </Button>
                    );
                    if (isSend) {
                        className = 'center-button-cancle';
                        btn = <Button className={className}>{sendState(questionnaire)}</Button>;
                    }

                    return (
                        <div className="center-popup-list" key={idx}>
                            <span>{questionnaire.displayTitle}</span>
                            {btn}
                        </div>
                    );
                })}
            </>
        );
    };

    const popupContents = (
        <div className="center-popup wow  animate__animated animate__fadeInUp" data-wow-delay="0.2s">
            <div className="center-popup-title">
                <h1 className="center">설문지 발송 및 확인</h1>
                <div className="flex">
                    <h1 className="center">
                        <i className="fas fa-user-tie"></i> {targetPerson?.reservater?.name}
                    </h1>{' '}
                    <span className="center-birth">
                        {targetPerson?.reservater?.genderCode?.name} / {targetPerson?.reservater?.birthdate}
                    </span>
                </div>
                <button className="center-button-close" onClick={actions.popupClose}>
                    닫기 <i className="fas fa-times-circle"></i>
                </button>
            </div>
            <div className="center-popup-cont">
                <div className="center-popup-bnt">
                    <a href="#" id="tab-btn1" className="on" onClick={openQuestionnaireListHandler}>
                        <i className="fas fa-file-import"></i>설문지 발송하기
                    </a>
                    <a href="#" id="tab-btn2" onClick={openResultHandler}>
                        <i className="fas fa-file-alt"></i>설문결과 보기
                    </a>
                </div>
                <div id="center-popup-list" className="center-popup-list-box">
                    {contents(questionnaires)}
                </div>
                {/* <!--//.center-popup-list-box--> */}

                <div id="list-tab" className="center-popup-question-tab">
                    <GeneraterQuestionnaires answers={answers} handler={openResultHandler}></GeneraterQuestionnaires>
                </div>
            </div>
        </div>
    );

    return popupContents;
}
