import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import UserModifyParam from '../../domain/user/param/UserModifyParam';
import UserSearchCondition from '../../domain/user/param/UserSearchCondition';
import RetrieveUser from '../../domain/user/RetrieveUser';
import UserServiceImpl from '../../service/user/impl/UserServiceImpl';

interface AdminManagersTableProps {
    page: number;
    pagingSize: number;
    users?: RetrieveUser[];
    pagingCnt: number;
    searchCondition: UserSearchCondition;
}
const userService = new UserServiceImpl();
function AdminManagersTable(props: AdminManagersTableProps) {
    const history = useHistory();

    const goDetailHandler = (evt: any) => {
        var userNo = evt.currentTarget.id;
        var condition: UserSearchCondition = props.searchCondition;
        let groupIdx = Math.floor(props.page / props.pagingCnt);
        condition.page = props.page;
        condition.groupIdx = groupIdx;

        history?.push({
            pathname: `/admin/manager/${userNo}`,
            state: condition,
        });
    };

    return (
        <>
            <div className="member-list">
                <table>
                    <thead>
                        <tr>
                            <td>No.</td>
                            <td>회원그룹</td>
                            <td>아이디</td>
                            <td>운영자 명</td>
                            <td>사용자 권한</td>
                            <td>등록일자</td>
                            {/* <td>가입상태</td> */}
                            <td>관리</td>
                        </tr>
                    </thead>
                    <tbody>
                        {props.users?.length != 0 ? (
                            props.users?.map((user: RetrieveUser, index: number) => {
                                return (
                                    <tr key={index}>
                                        <td>{props.pagingSize * props.page + (index + 1)}</td>
                                        <td>{user.authorities[0].roles?.roleGroups?.groupDesc}</td>
                                        <td>{user.email}</td>
                                        <td>{user.name}</td>
                                        <td>{user.authorities[0].roles?.roleDesc}</td>
                                        <td>{user.registeDate}</td>
                                        {/* <td>
                                        {user.signupApprove ? (
                                            <span style={{ color: 'green' }}>가입승인</span>
                                        ) : (
                                            <span style={{ color: 'red' }}>승인대기</span>
                                        )}
                                    </td> */}
                                        <td>
                                            {/* <button className="bg-blue white" onClick={() => approveHandler(user, true)}>
                                            <i className="fas fa-check-circle"></i> 승인
                                        </button>
                                        <button className="bg-gray white" onClick={() => approveHandler(user, false)}>
                                            <i className="fas fa-ban"></i> 비승인
                                        </button> */}
                                            <button id={user.userNo} className="bg-white" onClick={goDetailHandler}>
                                                <i className="fas fa-search"></i>
                                                보기
                                            </button>
                                        </td>
                                    </tr>
                                );
                            })
                        ) : (
                            <tr>
                                <td colSpan={7} style={{ height: '35px' }}>
                                    등록 된 운영자가 없습니다.
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
                {/* member-list */}
            </div>
        </>
    );
}

export default AdminManagersTable;
