import { useEffect, useState } from 'react';
import Schedule from '../../domain/schedule/Schedule';
import TeamScheduleServiceImpl from '../../service/schedule/impl/TeamScheduleServiceImpl';
const scheduleService = new TeamScheduleServiceImpl();
function useTeamScheduleByMonth(teamNo: string, month: string) {
    const [teamSchedules, setTeamSchedules] = useState<Schedule[]>();

    useEffect(() => {
        scheduleService.getSchedulesByMonth(teamNo, month)
            .then((scheduls: Schedule[]) => {
                setTeamSchedules(scheduls);
            });
    }, [month]);

    return {teamSchedules, setTeamSchedules};
}

export default useTeamScheduleByMonth;