import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { RootState } from '../../../../moldules/index';

function TestAuth() {
    const {isLogin} = useSelector((state: RootState) => state.userSessionReducer);
    useEffect(() => {
        console.log(isLogin);
    }, [isLogin])
    return (
        <>
            {/* {
                isLogin ? <></> : <Redirect to={{
                    pathname: '/hi'
                }}
                />
            } */}
        </>
    )
}

export default TestAuth;