import React, { useState } from 'react';
import LoginState from '../../../component/header/LoginState';
import SiteMainNav from '../../../component/nav/SiteMainNav';
import { Link } from 'react-router-dom';
import CommonHeader from '../../common/CommonHeader';
import CommonSearchBar from '../../../component/search/CommonSearchBar';
import MobileMenuContainer from '../../../component/header/MobileMenuContainer';
import useMyTeamScheduleHook from '../../../hook/useMyTeamScheduleHook';
import Infra from '../../../domain/infra/Infra';

function TrainningMainHeadeder() {
    const { reservation } = useMyTeamScheduleHook();
    const [hoverState, setHoverState] = useState(true);

    const hoverEventHandler = (hoverState: boolean) => {
        setHoverState(hoverState);
    };

    const spanStyle = {
        color: 'black',
    };
    return (
        <section className="main-visual">
            <CommonHeader></CommonHeader>
            <div className="sub_visual sub_visual4">
                <div className="subTop">
                    <h2>전지훈련시설</h2>
                    <span className="line"></span>
                    <p>경남스포츠산업육성지원센터 전지훈련시설입니다.</p>
                </div>
            </div>

            {reservation ? (
                <>
                    <div className="lasted-reservation-info">
                        {/* <div style={{borderBottom:'2px solid black'}}>
                        <p className="lasted-schedule">최근 예약 일정 시설</p>
                    </div> */}
                        <div style={{ display: 'flex' }}>
                            <div style={{ width: '15%' }}>
                                <img
                                    width={'100%'}
                                    height={'100%'}
                                    alt={'Infra'}
                                    style={{ borderRadius: '30px' }}
                                    src={Object.setPrototypeOf(reservation.infra, Infra.prototype).getMainAttachFile()}
                                />
                            </div>
                            <div style={{ width: '70%', display: 'flex', justifyContent: 'center' }}>
                                <div style={{ alignSelf: 'center' }}>
                                    <div style={{ fontSize: '1.1em', color: '#5a5a5a' }}>{reservation.infra?.name}</div>
                                    <span style={spanStyle}>{reservation.startDate?.split(' ')[0]}</span>
                                    <span style={spanStyle}>~</span>
                                    <span style={spanStyle}>{reservation.endDate?.split(' ')[0]}</span>
                                </div>
                            </div>
                            <div style={{ width: '15%', alignSelf: 'center' }}>
                                <div>
                                    <Link
                                        to={`/near-search`}
                                        style={spanStyle}
                                        // onMouseEnter={() => hoverEventHandler(true)}
                                        // onMouseLeave={() => hoverEventHandler(false)}
                                    >
                                        <i
                                            className="far fa-map"
                                            style={{
                                                color: `${hoverState ? '#166fc6' : 'black'}`,
                                                fontSize: '1.5em',
                                                border: `1px solid ${hoverState ? '#166fc6' : 'black'}`,
                                                padding: '5px',
                                                borderRadius: '15px',
                                            }}
                                        ></i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <></>
            )}
        </section>
    );
}

export default TrainningMainHeadeder;
