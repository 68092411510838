import React from 'react';
import SpecialPhysical from '../../domain/recording/SpecialPhysical';
interface IProps {
    data?: SpecialPhysical;
}
export default function BioDexReporting(props: IProps) {
    return (
        <>
            <div style={{ width: '100%', paddingTop: '10px', paddingBottom: '10px', fontSize: '15px', fontWeight: 700 }}>등속성근기능</div>
            <table className="reporting-table" cellPadding={0} cellSpacing={0}>
                <colgroup>
                    <col style={{ width: '8%' }} />
                    <col style={{ width: '3%' }} />
                    <col style={{ width: '7%' }} />
                    <col style={{ width: '7%' }} />
                    <col style={{ width: '7%' }} />
                    <col style={{ width: '7%' }} />
                    <col style={{ width: '7%' }} />
                    <col style={{ width: '7%' }} />
                    <col style={{ width: '7%' }} />
                    <col style={{ width: '7%' }} />
                    <col style={{ width: '7%' }} />
                    <col style={{ width: '7%' }} />
                    <col style={{ width: '7%' }} />
                    <col style={{ width: '7%' }} />
                </colgroup>
                <tbody>
                    <tr>
                        <td rowSpan={2} colSpan={2}>
                            REPS : 5/10/15
                        </td>
                        <th colSpan={4}>60 DEG/SEC</th>
                        <th colSpan={4}>180 DEG/SEC</th>
                        <th colSpan={4}>300 DEG/SEC</th>
                    </tr>
                    <tr>
                        <th colSpan={2}>EXTENSION</th>
                        <th colSpan={2}>FLEXION</th>
                        <th colSpan={2}>EXTENSION</th>
                        <th colSpan={2}>FLEXION</th>
                        <th colSpan={2}>EXTENSION</th>
                        <th colSpan={2}>FLEXION</th>
                    </tr>
                    <tr>
                        <td>　</td>
                        <td>Unit</td>
                        <td>L</td>
                        <td>R</td>
                        <td>L</td>
                        <td>R</td>
                        <td>L</td>
                        <td>R</td>
                        <td>L</td>
                        <td>R</td>
                        <td>L</td>
                        <td>R</td>
                        <td>L</td>
                        <td>R</td>
                    </tr>
                    <tr>
                        <td>PEAK TORQUE</td>
                        <td>N-M</td>
                        <td>{props.data?.bioDex.pt60ExtL}</td>
                        <td>{props.data?.bioDex.pt60ExtR}</td>
                        <td>{props.data?.bioDex.pt60FlxL}</td>
                        <td>{props.data?.bioDex.pt60FlxR}</td>
                        <td>{props.data?.bioDex.pt180ExtL}</td>
                        <td>{props.data?.bioDex.pt180ExtR}</td>
                        <td>{props.data?.bioDex.pt180FlxL}</td>
                        <td>{props.data?.bioDex.pt180FlxR}</td>
                        <td>{props.data?.bioDex.pt300ExtL}</td>
                        <td>{props.data?.bioDex.pt300ExtR}</td>
                        <td>{props.data?.bioDex.pt300FlxL}</td>
                        <td>{props.data?.bioDex.pt300FlxR}</td>
                    </tr>
                    <tr>
                        <td>PEAK TQ/BW</td>
                        <td>%</td>
                        <td>{props.data?.bioDex.ptb60ExtL}</td>
                        <td>{props.data?.bioDex.ptb60ExtR}</td>
                        <td>{props.data?.bioDex.ptb60FlxL}</td>
                        <td>{props.data?.bioDex.ptb60FlxR}</td>
                        <td>{props.data?.bioDex.ptb180ExtL}</td>
                        <td>{props.data?.bioDex.ptb180ExtR}</td>
                        <td>{props.data?.bioDex.ptb180FlxL}</td>
                        <td>{props.data?.bioDex.ptb180FlxR}</td>
                        <td>{props.data?.bioDex.ptb300ExtL}</td>
                        <td>{props.data?.bioDex.ptb300ExtR}</td>
                        <td>{props.data?.bioDex.ptb300FlxL}</td>
                        <td>{props.data?.bioDex.ptb300FlxR}</td>
                    </tr>
                    <tr>
                        <td>TORQ @ 30DEG</td>
                        <td>{props.data?.bioDex.torq30_60ExtL}</td>
                        <td>{props.data?.bioDex.torq30_60ExtR}</td>
                        <td>{props.data?.bioDex.torq30_60FlxL}</td>
                        <td>{props.data?.bioDex.torq30_60FlxR}</td>
                        <td>{props.data?.bioDex.torq30_180ExtL}</td>
                        <td>{props.data?.bioDex.torq30_180ExtR}</td>
                        <td>{props.data?.bioDex.torq30_180FlxL}</td>
                        <td>{props.data?.bioDex.torq30_180FlxR}</td>
                        <td>{props.data?.bioDex.torq30_300ExtL}</td>
                        <td>{props.data?.bioDex.torq30_300ExtR}</td>
                        <td>{props.data?.bioDex.torq30_300FlxL}</td>
                        <td>{props.data?.bioDex.torq30_300FlxR}</td>
                    </tr>
                    <tr>
                        <td>TORQ @0.18 SEC</td>
                        <td>N-M</td>
                        <td>{props.data?.bioDex.torq18_60ExtL}</td>
                        <td>{props.data?.bioDex.torq18_60ExtR}</td>
                        <td>{props.data?.bioDex.torq18_60FlxL}</td>
                        <td>{props.data?.bioDex.torq18_60FlxR}</td>
                        <td>{props.data?.bioDex.torq18_180ExtL}</td>
                        <td>{props.data?.bioDex.torq18_180ExtR}</td>
                        <td>{props.data?.bioDex.torq18_180FlxL}</td>
                        <td>{props.data?.bioDex.torq18_180FlxR}</td>
                        <td>{props.data?.bioDex.torq18_300ExtL}</td>
                        <td>{props.data?.bioDex.torq18_300ExtR}</td>
                        <td>{props.data?.bioDex.torq18_300FlxL}</td>
                        <td>{props.data?.bioDex.torq18_300FlxR}</td>
                    </tr>
                    <tr>
                        <td>COFEE. OF VAR.</td>
                        <td>%</td>
                        <td>{props.data?.bioDex.cofe60ExtL}</td>
                        <td>{props.data?.bioDex.cofe60ExtR}</td>
                        <td>{props.data?.bioDex.cofe60FlxL}</td>
                        <td>{props.data?.bioDex.cofe60FlxR}</td>
                        <td>{props.data?.bioDex.cofe180ExtL}</td>
                        <td>{props.data?.bioDex.cofe180ExtR}</td>
                        <td>{props.data?.bioDex.cofe180FlxL}</td>
                        <td>{props.data?.bioDex.cofe180FlxR}</td>
                        <td>{props.data?.bioDex.cofe300ExtL}</td>
                        <td>{props.data?.bioDex.cofe300ExtR}</td>
                        <td>{props.data?.bioDex.cofe300FlxL}</td>
                        <td>{props.data?.bioDex.cofe300FlxR}</td>
                    </tr>
                    <tr>
                        <td>TOTAL WORK</td>
                        <td>J</td>
                        <td>{props.data?.bioDex.totWo60ExtL}</td>
                        <td>{props.data?.bioDex.totWo60ExtR}</td>
                        <td>{props.data?.bioDex.totWo60FlxL}</td>
                        <td>{props.data?.bioDex.totWo60FlxR}</td>
                        <td>{props.data?.bioDex.totWo180ExtL}</td>
                        <td>{props.data?.bioDex.totWo180ExtR}</td>
                        <td>{props.data?.bioDex.totWo180FlxL}</td>
                        <td>{props.data?.bioDex.totWo180FlxR}</td>
                        <td>{props.data?.bioDex.totWo300ExtL}</td>
                        <td>{props.data?.bioDex.totWo300ExtR}</td>
                        <td>{props.data?.bioDex.totWo300FlxL}</td>
                        <td>{props.data?.bioDex.totWo300FlxR}</td>
                    </tr>
                    <tr>
                        <td>WORK FATIQUE</td>
                        <td>%</td>
                        <td>{props.data?.bioDex.workFat60ExtL}</td>
                        <td>{props.data?.bioDex.workFat60ExtR}</td>
                        <td>{props.data?.bioDex.workFat60FlxL}</td>
                        <td>{props.data?.bioDex.workFat60FlxR}</td>
                        <td>{props.data?.bioDex.workFat180ExtL}</td>
                        <td>{props.data?.bioDex.workFat180ExtR}</td>
                        <td>{props.data?.bioDex.workFat180FlxL}</td>
                        <td>{props.data?.bioDex.workFat180FlxR}</td>
                        <td>{props.data?.bioDex.workFat300ExtL}</td>
                        <td>{props.data?.bioDex.workFat300ExtR}</td>
                        <td>{props.data?.bioDex.workFat300FlxL}</td>
                        <td>{props.data?.bioDex.workFat300FlxR}</td>
                    </tr>
                    <tr>
                        <td>AVG. POWER</td>
                        <td>WATTS</td>
                        <td>{props.data?.bioDex.avgPow60ExtL}</td>
                        <td>{props.data?.bioDex.avgPow60ExtR}</td>
                        <td>{props.data?.bioDex.avgPow60FlxL}</td>
                        <td>{props.data?.bioDex.avgPow60FlxR}</td>
                        <td>{props.data?.bioDex.avgPow180ExtL}</td>
                        <td>{props.data?.bioDex.avgPow180ExtR}</td>
                        <td>{props.data?.bioDex.avgPow180FlxL}</td>
                        <td>{props.data?.bioDex.avgPow180FlxR}</td>
                        <td>{props.data?.bioDex.avgPow300ExtL}</td>
                        <td>{props.data?.bioDex.avgPow300ExtR}</td>
                        <td>{props.data?.bioDex.avgPow300FlxL}</td>
                        <td>{props.data?.bioDex.avgPow300FlxR}</td>
                    </tr>
                    <tr>
                        <td>AGON/ANTAG RATI</td>
                        <td>%</td>
                        <td>{props.data?.bioDex.agan60ExtL}</td>
                        <td>{props.data?.bioDex.agan60ExtR}</td>
                        <td>{props.data?.bioDex.agan60FlxL}</td>
                        <td>{props.data?.bioDex.agan60FlxR}</td>
                        <td>{props.data?.bioDex.agan180ExtL}</td>
                        <td>{props.data?.bioDex.agan180ExtR}</td>
                        <td>{props.data?.bioDex.agan180FlxL}</td>
                        <td>{props.data?.bioDex.agan180FlxR}</td>
                        <td>{props.data?.bioDex.agan300ExtL}</td>
                        <td>{props.data?.bioDex.agan300ExtR}</td>
                        <td>{props.data?.bioDex.agan300FlxL}</td>
                        <td>{props.data?.bioDex.agan300FlxR}</td>
                    </tr>
                </tbody>
            </table>
            <table className="eval-table" cellSpacing={0} cellPadding={0}>
                <tbody>
                    <tr>
                        <td>
                            <span style={{ fontWeight: 700, fontSize: '15px' }}>* 평가</span>
                            <br />
                            {''}
                        </td>
                    </tr>
                </tbody>
            </table>
        </>
    );
}
