import { JsonConvert } from 'json2typescript';
import BasalPhysical from '../../view/rehabcenter/domain/recording/BasalPhysical';
import PhysicalStatus from '../../view/rehabcenter/domain/recording/PhysicalStatus';
import TreadMillTest from '../../view/rehabcenter/pages/components/recording/forms/tabs/TreadMillTestForm';
class ClinicalChartReportData {
    jsonConvert: JsonConvert = new JsonConvert();
    name?: string;
    height?: string;
    weight?: string;
    bmi?: string;

    //basalPhysical value
    gripTestFirst?: string;
    gripTestSecond?: string;
    gripTestThird?: string;
    relativeGripTestFirst?: string;
    relativeGripTestSecond?: string;
    relativeGripTestThird?: string;
    sitUpTestFirst?: string;
    sitUpTestSecond?: string;
    sitUpTestThird?: string;
    crossSitUpTestFirst?: string;
    crossSitUpTestSecond?: string;
    crossSitUpTestThird?: string;
    sitAndBendForwardTestFirst?: string;
    sitAndBendForwardTestSecond?: string;
    sitAndBendForwardTestThird?: string;
    repeatJumpTestFirst?: string;
    repeatJumpTestSecond?: string;
    repeatJumpTestThird?: string;
    bucklingTestFirst?: string;
    bucklingTestSecond?: string;
    bucklingTestThird?: string;
    shuttle15mRunTestFirst?: string;
    shuttle15mRunTestSecond?: string;
    shuttle15mRunTestThird?: string;
    shuttle20mRunTestFirst?: string;
    shuttle20mRunTestSecond?: string;
    shuttle20mRunTestThird?: string;
    repeatSideJumpTestFirst?: string;
    repeatSideJumpTestSecond?: string;
    repeatSideJumpTestThird?: string;
    illinoisAgilityTestFirst?: string;
    illinoisAgilityTestSecond?: string;
    illinoisAgilityTestThird?: string;
    flightTimeTestFirst?: string;
    flightTimeTestSecond?: string;
    flightTimeTestThird?: string;
    eyeHandCoordinationTestFirst?: string;
    eyeHandCoordinationTestSecond?: string;
    eyeHandCoordinationTestThird?: string;

    constructor(physicalStatusStr: string, basalPhysicalStr: string, specialPhysicalStr: string) {
        this.setPhysicalStatus(physicalStatusStr);
        this.setBasalPhysicalReportData(basalPhysicalStr);
        this.setSpecialPhysicalReportData(specialPhysicalStr);
        this.setTreadMillTest(specialPhysicalStr);
    }

    setPhysicalStatus(physicalStatusStr: string) {
        const physicalStatus: PhysicalStatus = this.jsonConvert.deserializeObject(JSON.parse(physicalStatusStr), PhysicalStatus);
        this.height = physicalStatus.height;
        this.weight = physicalStatus.weight;
        
        const bmiConst = (Number(physicalStatus.weight) / Math.pow(Number(physicalStatus.height) / 100, 2));
        this.bmi = String(Math.round(bmiConst / 10) * 10);
    }

    setBasalPhysicalReportData(basalPhysicalStr: string) {
        const basalPhysicalJsonObj = JSON.parse(basalPhysicalStr);
        const basalPhysical: BasalPhysical = this.jsonConvert.deserializeObject(basalPhysicalJsonObj, BasalPhysical);
        this.gripTestFirst = basalPhysical.graspingPower[0];
        this.gripTestSecond = basalPhysical.graspingPower[1];
        this.gripTestThird = basalPhysical.graspingPower[2];
        this.relativeGripTestFirst = basalPhysical.graspingPower[0];
        this.relativeGripTestSecond = basalPhysical.graspingPower[1];
        this.relativeGripTestThird = basalPhysical.graspingPower[2];
        this.sitUpTestFirst = basalPhysical.sitUp[0];
        this.sitUpTestSecond = basalPhysical.sitUp[1];
        this.sitUpTestThird = basalPhysical.sitUp[2];
        this.crossSitUpTestFirst = basalPhysical.crossSitUp[0];
        this.crossSitUpTestSecond = basalPhysical.crossSitUp[1];
        this.crossSitUpTestThird = basalPhysical.crossSitUp[2];
        this.sitAndBendForwardTestFirst = basalPhysical.buckling[0];
        this.sitAndBendForwardTestSecond = basalPhysical.buckling[1];
        this.sitAndBendForwardTestThird = basalPhysical.buckling[2];
        this.repeatJumpTestFirst = basalPhysical.jumpInPlace[0];
        this.repeatJumpTestSecond = basalPhysical.jumpInPlace[1];
        this.repeatJumpTestThird = basalPhysical.jumpInPlace[2];
        this.bucklingTestFirst = basalPhysical.buckling[0];
        this.bucklingTestSecond = basalPhysical.buckling[1];
        this.bucklingTestThird = basalPhysical.buckling[2];
        this.shuttle15mRunTestFirst = basalPhysical.shuttleRun15[0];
        this.shuttle15mRunTestSecond = basalPhysical.shuttleRun15[1];
        this.shuttle15mRunTestThird = basalPhysical.shuttleRun15[2];
        this.shuttle20mRunTestFirst = basalPhysical.shuttleRun20[0];
        this.shuttle20mRunTestFirst = basalPhysical.shuttleRun20[1];
        this.shuttle20mRunTestThird = basalPhysical.shuttleRun20[2];
        this.repeatSideJumpTestFirst = basalPhysical.repeatSideJump[0];
        this.repeatSideJumpTestSecond = basalPhysical.repeatSideJump[1];
        this.repeatSideJumpTestThird = basalPhysical.repeatSideJump[2];
        this.illinoisAgilityTestFirst = basalPhysical.agilityTest[0];
        this.illinoisAgilityTestSecond = basalPhysical.agilityTest[1];
        this.illinoisAgilityTestSecond = basalPhysical.agilityTest[2];
        this.flightTimeTestFirst = basalPhysical.flightTime[0];
        this.flightTimeTestSecond = basalPhysical.flightTime[1];
        this.flightTimeTestThird = basalPhysical.flightTime[2];
        // this.eyeHandCoordinationTestFirst = basalPhysical.
    }

    footCheckerTestLeftFront?: string;
    footCheckerTestRightFront?: string;
    footCheckerTestLeftRear?: string;
    footCheckerTestRightRear?: string;

    pomCheckerShoulderLeft?: string;
    pomCheckerShoulderRight?: string;
    pomCheckerPelvisLeft?: string;
    pomCheckerPelvisRight?: string;
    pomCheckerKneeLeft?: string;
    pomCheckerKneeRight?: string;
    pomCheckerAnkleLeft?: string;
    pomCheckerAnkleRight?: string;
    pomCheckerLoginFlexion?: string;
    pomCheckerLoginExtension?: string;
    pomCheckerLoginFlexionLeft?: string;
    pomCheckerLoginFlexionRight?: string;

    biodexSectionOne60ExtensionLeft?: string;
    biodexSectionOne60FlexionLeft?: string;
    biodexSectionOne180ExtensionLeft?: string;
    biodexSectionOne180FlexionLeft?: string;
    biodexSectionTwo60ExtensionLeft?: string;
    biodexSectionTwo60FlexionLeft?: string;
    biodexSectionTwo180ExtensionLeft?: string;
    biodexSectionTwo180FlexionLeft?: string;
    biodexSectionTwo180FlextionRigth?: string;
    biodexSectionThree60ExtensionLeft?: string;
    biodexSectionThree60FlexionLeft?: string;
    biodexSectionThree180ExtensionLeft?: string;
    biodexSectionThree180FlexionLeft?: string;
    biodexSectionFour60ExtensionLeft?: string;
    biodexSectionFour60FlexionLeft?: string;
    biodexSectionFour180ExtensionLeft?: string;
    biodexSectionFour180FlexionLeft?: string;
    biodexSectionFive60ExtensionLeft?: string;
    biodexSectionFive60FlexionLeft?: string;
    biodexSectionFive180ExtensionLeft?: string;
    biodexSectionFive180FlexionLeft?: string;
    biodexSectionSix60ExtensionLeft?: string;
    biodexSectionSix60FlexionLeft?: string;
    biodexSectionSix180ExtensionLeft?: string;
    biodexSectionSix180FlexionLeft?: string;
    biodexSectionSeven60ExtensionLeft?: string;
    biodexSectionSeven60FlexionLeft?: string;
    biodexSectionSeven180ExtensionLeft?: string;
    biodexSectionSeven180FlexionLeft?: string;
    biodexSectionEight60ExtensionLeft?: string;
    biodexSectionEight60FlexionLeft?: string;
    biodexSectionEight180ExtensionLeft?: string;
    biodexSectionEight180FlexionLeft?: string;
    biodexSectionNine60ExtensionLeft?: string;
    biodexSectionNine60FlexionLeft?: string;
    biodexSectionNine180ExtensionLeft?: string;
    biodexSectionNine180FlexionLeft?: string;
    biodexSectionOne60ExtensionRight?: string;
    biodexSectionOne60FlexionRight?: string;
    biodexSectionOne180ExtensionRight?: string;
    biodexSectionOne180FlexionRight?: string;
    biodexSectionTwo60ExtensionRight?: string;
    biodexSectionTwo60FlexionRight?: string;
    biodexSectionTwo180ExtensionRight?: string;
    biodexSectionTwo180FlexionRight?: string;
    biodexSectionThree60ExtensionRight?: string;
    biodexSectionThree60FlexionRight?: string;
    biodexSectionThree180ExtensionRight?: string;
    biodexSectionThree180FlexionRight?: string;
    biodexSectionFour60ExtensionRight?: string;
    biodexSectionFour60FlexionRight?: string;
    biodexSectionFour180ExtensionRight?: string;
    biodexSectionFour180FlexionRight?: string;
    biodexSectionFive60ExtensionRight?: string;
    biodexSectionFive60FlexionRight?: string;
    biodexSectionFive180ExtensionRight?: string;
    biodexSectionFive180FlexionRight?: string;
    biodexSectionSix60ExtensionRight?: string;
    biodexSectionSix60FlexionRight?: string;
    biodexSectionSix180ExtensionRight?: string;
    biodexSectionSix180FlexionRight?: string;
    biodexSectionSeven60ExtensionRight?: string;
    biodexSectionSeven60FlexionRight?: string;
    biodexSectionSeven180ExtensionRight?: string;
    biodexSectionSeven180FlexionRight?: string;
    biodexSectionEight60ExtensionRight?: string;
    biodexSectionEight60FlexionRight?: string;
    biodexSectionEight180ExtensionRight?: string;
    biodexSectionEight180FlexionRight?: string;
    biodexSectionNine60ExtensionRight?: string;
    biodexSectionNine60FlexionRight?: string;
    biodexSectionNine180ExtensionRight?: string;
    biodexSectionNine180FlexionRight?: string;


    //biodexSectionTwo180FlextionRigth 리포트에서 빠짐
    setSpecialPhysicalReportData(specialPhysicalStr: string) {
        const specialPhysicalJson = JSON.parse(specialPhysicalStr);
        console.log(specialPhysicalJson);
        this.footCheckerTestLeftFront = specialPhysicalJson.footChecker.leftFront; 
        this.footCheckerTestRightFront = specialPhysicalJson.footChecker.rightFront;
        this.footCheckerTestLeftRear = specialPhysicalJson.footChecker.leftRear;
        this.footCheckerTestRightRear =  specialPhysicalJson.footChecker.rightRear;

        this.pomCheckerShoulderLeft = specialPhysicalJson.formChecker.shuolderL;
        this.pomCheckerShoulderRight = specialPhysicalJson.formChecker.shuolderR;
        this.pomCheckerPelvisLeft = specialPhysicalJson.formChecker.pelvisL;
        this.pomCheckerPelvisRight = specialPhysicalJson.formChecker.pelvisR;
        this.pomCheckerKneeLeft = specialPhysicalJson.formChecker.kneeL;
        this.pomCheckerKneeRight = specialPhysicalJson.formChecker.kneeR;
        this.pomCheckerAnkleLeft = specialPhysicalJson.formChecker.ankleL;
        this.pomCheckerAnkleRight = specialPhysicalJson.formChecker.ankleR;
        this.pomCheckerLoginFlexion = specialPhysicalJson.formChecker.loinFlexion;
        this.pomCheckerLoginExtension = specialPhysicalJson.formChecker.loinExtension;
        this.pomCheckerLoginFlexionLeft = specialPhysicalJson.formChecker.loinLaterFL;
        this.pomCheckerLoginFlexionRight = specialPhysicalJson.formChecker.loinLaterFR;

        this.biodexSectionOne60ExtensionLeft = specialPhysicalJson.bioDex.pt60ExtL;
        this.biodexSectionOne60ExtensionRight = specialPhysicalJson.bioDex.pt60ExtR;
        this.biodexSectionOne60FlexionLeft = specialPhysicalJson.bioDex.pt60FlxL;
        this.biodexSectionOne60FlexionRight = specialPhysicalJson.bioDex.pt60FlxR;
        this.biodexSectionOne180ExtensionLeft = specialPhysicalJson.bioDex.pt180ExtL;
        this.biodexSectionOne180ExtensionRight = specialPhysicalJson.bioDex.pt180ExtR;
        this.biodexSectionOne180FlexionLeft = specialPhysicalJson.bioDex.pt180FlxL;
        this.biodexSectionOne180FlexionRight = specialPhysicalJson.bioDex.pt180FlxR;

        this.biodexSectionTwo60ExtensionLeft = specialPhysicalJson.bioDex.ptb60ExtL;
        this.biodexSectionTwo60ExtensionRight = specialPhysicalJson.bioDex.ptb60ExtR;
        this.biodexSectionTwo60FlexionLeft = specialPhysicalJson.bioDex.ptb60FlxL;
        this.biodexSectionTwo60FlexionRight = specialPhysicalJson.bioDex.ptb60FlxR;
        this.biodexSectionTwo180ExtensionLeft = specialPhysicalJson.bioDex.ptb180ExtL;
        this.biodexSectionTwo180ExtensionRight = specialPhysicalJson.bioDex.ptb180ExtR;
        this.biodexSectionTwo180FlexionLeft = specialPhysicalJson.bioDex.ptb180FlxL;
        this.biodexSectionTwo180FlextionRigth = specialPhysicalJson.bioDex.ptb180FlxR;
        
        this.biodexSectionThree60ExtensionLeft = specialPhysicalJson.bioDex.torq30_60ExtL;
        this.biodexSectionThree60ExtensionRight = specialPhysicalJson.bioDex.torq30_60ExtR;
        this.biodexSectionThree60FlexionLeft = specialPhysicalJson.bioDex.torq30_60FlxL;
        this.biodexSectionThree60FlexionRight = specialPhysicalJson.bioDex.torq30_60FlxR;
        this.biodexSectionThree180ExtensionLeft = specialPhysicalJson.bioDex.torq30_180ExtL;
        this.biodexSectionThree180ExtensionRight = specialPhysicalJson.bioDex.torq30_180ExtR;
        this.biodexSectionThree180FlexionLeft = specialPhysicalJson.bioDex.torq30_180FlxL;
        this.biodexSectionThree180FlexionRight = specialPhysicalJson.bioDex.torq30_180FlxL;
        
        this.biodexSectionFour60ExtensionLeft = specialPhysicalJson.bioDex.torq18_60ExtL;
        this.biodexSectionFour60ExtensionRight = specialPhysicalJson.bioDex.torq18_60ExtR;
        this.biodexSectionFour60FlexionLeft = specialPhysicalJson.bioDex.torq18_60FlxL;
        this.biodexSectionFour60FlexionRight = specialPhysicalJson.bioDex.torq18_60FlxR;
        this.biodexSectionFour180ExtensionLeft = specialPhysicalJson.bioDex.torq18_180ExtL;
        this.biodexSectionFour180ExtensionRight = specialPhysicalJson.bioDex.torq18_180ExtR;
        this.biodexSectionFour180FlexionLeft = specialPhysicalJson.bioDex.torq18_180FlxL;
        this.biodexSectionFour180FlexionRight = specialPhysicalJson.bioDex.torq18_180FlxR;

        this.biodexSectionFive60ExtensionLeft = specialPhysicalJson.bioDex.cofe60ExtL;
        this.biodexSectionFive60ExtensionRight = specialPhysicalJson.bioDex.cofe60ExtR;
        this.biodexSectionFive60FlexionLeft = specialPhysicalJson.bioDex.cofe60FlxL;
        this.biodexSectionFive60FlexionRight = specialPhysicalJson.bioDex.cofe60FlxR;
        this.biodexSectionFive180ExtensionLeft = specialPhysicalJson.bioDex.cofe180ExtL;
        this.biodexSectionFive180ExtensionRight = specialPhysicalJson.bioDex.cofe180ExtR;
        this.biodexSectionFive180FlexionLeft = specialPhysicalJson.bioDex.cofe180FlxL;
        this.biodexSectionFive180FlexionRight = specialPhysicalJson.bioDex.cofe180FlxR;

        this.biodexSectionSix60ExtensionLeft = specialPhysicalJson.bioDex.totWo60ExtL;
        this.biodexSectionSix60ExtensionRight = specialPhysicalJson.bioDex.totWo60ExtR;
        this.biodexSectionSix60FlexionLeft = specialPhysicalJson.bioDex.totWo60FlxL;
        this.biodexSectionSix60FlexionRight = specialPhysicalJson.bioDex.totWo60FlxR;
        this.biodexSectionSix180ExtensionLeft = specialPhysicalJson.bioDex.totWo180ExtL;
        this.biodexSectionSix180ExtensionRight = specialPhysicalJson.bioDex.totWo180ExtR;
        this.biodexSectionSix180FlexionLeft = specialPhysicalJson.bioDex.totWo180FlxL;
        this.biodexSectionSix180FlexionRight = specialPhysicalJson.bioDex.totWo180FlxR;

        this.biodexSectionSeven60ExtensionLeft = specialPhysicalJson.bioDex.workFat60ExtL;
        this.biodexSectionSeven60ExtensionRight = specialPhysicalJson.bioDex.workFat60ExtR;
        this.biodexSectionSeven60FlexionLeft = specialPhysicalJson.bioDex.workFat60FlxL;
        this.biodexSectionSeven60FlexionRight = specialPhysicalJson.bioDex.workFat60FlxR;
        this.biodexSectionSeven180ExtensionLeft = specialPhysicalJson.bioDex.workFat180ExtL;
        this.biodexSectionSeven180ExtensionRight = specialPhysicalJson.bioDex.workFat180ExtR;
        this.biodexSectionSeven180FlexionLeft = specialPhysicalJson.bioDex.workFat180FlxL;
        this.biodexSectionSeven180FlexionRight = specialPhysicalJson.bioDex.workFat180FlxR;

        this.biodexSectionEight60ExtensionLeft = specialPhysicalJson.bioDex.avgPow60ExtL;
        this.biodexSectionEight60ExtensionRight = specialPhysicalJson.bioDex.avgPow60ExtR;
        this.biodexSectionEight60FlexionLeft = specialPhysicalJson.bioDex.avgPow60FlxL;
        this.biodexSectionEight60FlexionRight = specialPhysicalJson.bioDex.avgPow60FlxR;
        this.biodexSectionEight180ExtensionLeft = specialPhysicalJson.bioDex.avgPow180ExtL;
        this.biodexSectionEight180ExtensionRight = specialPhysicalJson.bioDex.avgPow180ExtR;
        this.biodexSectionEight180FlexionLeft = specialPhysicalJson.bioDex.avgPow180FlxL;
        this.biodexSectionEight180FlexionRight = specialPhysicalJson.bioDex.avgPow180FlxR;

        this.biodexSectionNine60ExtensionLeft = specialPhysicalJson.bioDex.agan60ExtL;
        this.biodexSectionNine60ExtensionRight = specialPhysicalJson.bioDex.agan60ExtR;
        this.biodexSectionNine60FlexionLeft = specialPhysicalJson.bioDex.agan60FlxL;
        this.biodexSectionNine60FlexionRight = specialPhysicalJson.bioDex.agan60FlxR;
        this.biodexSectionNine180ExtensionLeft = specialPhysicalJson.bioDex.agan180ExtL;
        this.biodexSectionNine180ExtensionRight = specialPhysicalJson.bioDex.agan180ExtR;
        this.biodexSectionNine180FlexionLeft = specialPhysicalJson.bioDex.agan180FlxL;
        this.biodexSectionNine180FlexionRight = specialPhysicalJson.bioDex.agan180FlxR;

        // this.biodexSectionOne180FlexionLeft = specialPhysicalJson.bioDex.
    }

    //가스 분석기, exercise stress testing
    exerciseStressTestOneSection?: string;
    exerciseStressTestTwoSection?: string;
    exerciseStressTestThreeSection?: string;
    exerciseStressTestFourSection?: string;

    setTreadMillTest(specialPhysicalStr: string) {
        const specialPhysicalJson = JSON.parse(specialPhysicalStr);
        
        this.exerciseStressTestOneSection = specialPhysicalJson.treadMillTest.vo2;
        this.exerciseStressTestTwoSection = specialPhysicalJson.treadMillTest.mets;
        this.exerciseStressTestThreeSection = specialPhysicalJson.treadMillTest.ve;
        this.exerciseStressTestFourSection = specialPhysicalJson.treadMillTest.vt;
    }
}

export default ClinicalChartReportData;