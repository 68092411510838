import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import Infra from '../../../../../domain/infra/Infra';
interface CommonInfraPrVideoTabProps {
    infra: Infra;
}
function CommonInfraPrVideoTab(props: CommonInfraPrVideoTabProps) {
    const [playVideoUrl, setPlayVideoUrl] = useState<string>();

    useEffect(() => {
        if(props.infra) {
            if(props.infra.firstPrVideoUrl != null) {
                setPlayVideoUrl(props.infra.firstPrVideoUrl);
            } else if(props.infra.secondPrVideoUrl != null) {
                setPlayVideoUrl(props.infra.secondPrVideoUrl);
            } else if(props.infra.thirdPrVideoUrl != null) {
                setPlayVideoUrl(props.infra.thirdPrVideoUrl);
            }
        }
    }, [props.infra]);

    return (
        <div className="home-wrap">
            <div className="home-title">
                <span>홍보 영상</span>						
            </div>				
            <div className="home-box bg-gay clear">
                <>
                    {
                        (props.infra.firstPrVideoUrl != null || props.infra.secondPrVideoUrl != null || props.infra.thirdPrVideoUrl != null) ?
                        <>
                            <div className="pr_video_container">
                                <div className="pr_video_header">
                                {
                                    props.infra.firstPrVideoUrl ? 
                                    <button className="round-button bg-blue white"
                                        onClick={(event) => {
                                            setPlayVideoUrl(props.infra.firstPrVideoUrl);
                                        }}
                                    >
                                        <i className="fas fa-photo-video">홍보영상-1</i>
                                    </button>
                                    :
                                    <></>
                                }
                                {
                                    props.infra.secondPrVideoUrl ? 
                                    <button className="round-button bg-blue white"
                                        onClick={(event) => {
                                            setPlayVideoUrl(props.infra.secondPrVideoUrl);
                                        }}
                                    >
                                        <i className="fas fa-photo-video">홍보영상-2</i>
                                    </button>
                                    :
                                    <></>
                                }
                                {
                                    props.infra.thirdPrVideoUrl ? 
                                    <button className="round-button bg-blue white"
                                        onClick={(event) => {
                                            setPlayVideoUrl(props.infra.thirdPrVideoUrl);
                                        }}
                                    >
                                        <i className="fas fa-photo-video">홍보영상-2</i>
                                    </button>
                                    : 
                                    <></>
                                }
                                </div>
                                <div className="pr_video_body">
                                {
                                    playVideoUrl ? 
                                    <ReactPlayer
                                        className="pr_video_player"
                                        url={playVideoUrl}
                                        controls={true}
                                    ></ReactPlayer>
                                    :
                                    <></>
                                }
                                </div> 
                            </div>
                        </>
                        :
                        <h5 className="alert-h5">홍보 영상이 없습니다.</h5>
                    }
                
                </>
                <div className="clear"></div>
            </div>					
        </div>
        
    )
}

export default CommonInfraPrVideoTab;