import React from 'react';
import BoardContent from '../../domain/board/BoardContent';

interface ImgFeedBoardContentCardProps {
    boardContent: BoardContent;
}

function ImgFeedBoardContentCard(props: ImgFeedBoardContentCardProps) {
    return (
        <li>
            <div className="teamstory-admin">
                <div><img src="/assets/image/admin.png"/></div>
                <div>
                    <span className="admin-name">{props.boardContent.writer.name}</span><br/>
                    {props.boardContent.registeDate}
                </div>
            </div>
            <div className="clear"></div>
            <div className="teamstory-photo">
                <div>{props.boardContent.title}</div>
                <div className="teamstory-photo-box">
                    <div className="photo-left">
                        <img style={{height: '557.219px'}} src={props.boardContent.getMainAttachFile(0)}/>
                    </div>
                    <div className="photo-right">
                        <div><img src={props.boardContent.getMainAttachFile(1)}/></div>
                        <div><img src={props.boardContent.getMainAttachFile(2)}/></div>
                    </div>
                </div>
            </div>								
        </li>							
    )
}

export default ImgFeedBoardContentCard;