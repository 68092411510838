import RetrieveUser from '../../domain/user/RetrieveUser';
import UserModifyParam from '../../domain/user/param/UserModifyParam';
import { USER_SERVICE } from '../../service/index';
import { useState } from 'react';
function useSignupApprove(user?: RetrieveUser) {
    const [signUpStateUser, setSignUpStateUser] = useState<RetrieveUser>();
    const approveHandler = (approve: boolean) => {
        if(user) {
            user.signupApprove = approve;
            let modifyParam = UserModifyParam.getInstance(user, undefined);
            USER_SERVICE.updateUserInfo(modifyParam)
            .then(() => {
                const newState = Object.setPrototypeOf(Object.assign({}, user), RetrieveUser.prototype);
                setSignUpStateUser(newState);
            });
        }
    }

    return {approveHandler, signUpStateUser, setSignUpStateUser};
}

export default useSignupApprove;