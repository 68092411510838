import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject("KMCTRInfo")
class KMCTRInfo {
    @JsonProperty("trUrl")
    trUrl?: string;
    @JsonProperty("trAdd")
    trAdd?: string;
    @JsonProperty("trCert")
    trCert?: string;
}

export default KMCTRInfo;