import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../moldules/index';
import SearchConditionUtils from '../../../../utils/SearchConditionUtils';

/**
 * 검색 페이지 화면에서 공통으로 사용하는 Hook 정의
 * @see SearchPageContainer
 * @see NearSearchPageContainer
 * @see CurrPositionOfNearSearchPageContainer
 * @param initSearchType 
 * @author CWH
 */
function useSearchPageHook(initSearchType: number) {
    const searchConditionMap = useSelector((state: RootState) => state.searchCondition.searchConditionMap);
    const [searchType, setSearchType] = useState(initSearchType);
    const [parsedSearchConditionMap, setParsedSearchConditionMap] = useState<any>({});

    const searchConditionMapParsing = (page: number) => {
        let parsedSearchConditionMap = SearchConditionUtils.makeSearchCondition(searchConditionMap);
        console.log(parsedSearchConditionMap)
        parsedSearchConditionMap['page'] = page;
        parsedSearchConditionMap['size'] = pagingSize;
        setParsedSearchConditionMap(parsedSearchConditionMap);
    };

    const [pagingSize, setPagingSize] = useState<number>(10);
    const [pagingCnt, setPagingCnt] = useState<number>(5);
    const [page, setPage] = useState<number>(0);

    useEffect(() => {
        searchConditionMapParsing(page);
    }, [page]);

    useEffect(() => {
        setPage(0);
    }, [searchType])

    return {
        searchType, setSearchType,
        searchConditionMapParsing,
        parsedSearchConditionMap, setParsedSearchConditionMap,
        pagingSize, setPagingSize,
        pagingCnt, setPagingCnt,
        page, setPage
    }
}

export default useSearchPageHook;