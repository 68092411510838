import { useState } from "react";
import { TMP_REGISTED_TEAM_USER_SERVICE } from '../../service/index';

function useTmpRegistedTeamUserDelete() {
    const [deleteDate, setDeleteDate] = useState<Date>();

    const deleteHandler = (tmpRegsitedNo?: string) => {
        if(tmpRegsitedNo) {
            TMP_REGISTED_TEAM_USER_SERVICE.userDelete(tmpRegsitedNo)
            .then(() => {
                setDeleteDate(new Date());
            });
        }
    }

    return {
        deleteDate,
        deleteHandler
    }
}

export default useTmpRegistedTeamUserDelete;