import React from 'react';
import { Link } from 'react-router-dom';

interface MyPageRightContentProps {
    children?: React.ReactNode;
    contentTitle: string;
    allDisplayPath?: string;
    allDisplayTitle?: string;
}

function MyPageRightContent(props: MyPageRightContentProps) {
    return (
        <div className="my-training mgt10">
            <div className="my-title" style={{ marginBottom: '15px' }}>
                {props.contentTitle}
                <span></span>
                <span>
                    {props.allDisplayPath ? (
                        <Link to={props.allDisplayPath}>{props.allDisplayTitle ? props.allDisplayTitle : '전체보기'}</Link>
                    ) : (
                        <></>
                    )}
                </span>
            </div>
            {props.children}
        </div>
    );
}

export default MyPageRightContent;
