import React from 'react';
import PhysicalStatusForm from './PhysicalStatusForm';
import BasalPhysical from '../../../../domain/recording/BasalPhysical';

interface IProps {
    status: BasalPhysical;
    handler: (evt: any) => void;
}

export default function BasalPhysicalForm(props: IProps) {
    let basalPhyData: BasalPhysical = props.status;
    let changeHandler = props.handler;

    return (
        <>
            <div id="list-tab" className="center-popup-list-tab">
                <div className="center-popup-list-box">
                    <div className="flex-basic">
                        <div className="center-half">
                            <h2 className="center2">악력</h2>
                            <div className="center-bg-gray flex">
                                <div className="center-bg-blue">1회차</div>
                                <input
                                    className="center-input-gray center-w120"
                                    type="text"
                                    id="graspingPower_0"
                                    value={basalPhyData.graspingPower[0]}
                                    onChange={changeHandler}
                                />
                                <label className="center-label">kg</label>
                            </div>
                        </div>
                        <div className="center-half">
                            <h2 className="center2">상대악력</h2>
                            <div className="center-bg-gray flex">
                                <div className="center-bg-blue">1회차</div>
                                <input
                                    className="center-input-gray center-w120"
                                    type="text"
                                    id="relativeGrip_0"
                                    value={basalPhyData.relativeGrip[0]}
                                    onChange={changeHandler}
                                />
                                <label className="center-label">%</label>
                            </div>
                        </div>
                    </div>
                    <div className="flex-basic">
                        <div className="center-half">
                            <h2 className="center2">윗몸말아올리기</h2>
                            <div className="center-bg-gray flex">
                                <div className="center-bg-blue">1회차</div>
                                <input
                                    className="center-input-gray center-w120"
                                    type="text"
                                    id="sitUp_0"
                                    value={basalPhyData.sitUp[0]}
                                    onChange={changeHandler}
                                />
                                <label className="center-label">회</label>
                            </div>
                        </div>
                        <div className="center-half">
                            <h2 className="center2">교차윗몸일으키기</h2>
                            <div className="center-bg-gray flex">
                                <div className="center-bg-blue">1회차</div>
                                <input
                                    className="center-input-gray center-w120"
                                    type="text"
                                    id="crossSitUp_0"
                                    value={basalPhyData.crossSitUp[0]}
                                    onChange={changeHandler}
                                />
                                <label className="center-label">회</label>
                            </div>
                        </div>
                    </div>
                    <div className="flex-basic">
                        <div className="center-half">
                            <h2 className="center2">반복점프</h2>
                            <div className="center-bg-gray flex">
                                <div className="center-bg-blue">1회차</div>
                                <input
                                    className="center-input-gray center-w120"
                                    type="text"
                                    id="repeatJump_0"
                                    value={basalPhyData.repeatJump[0]}
                                    onChange={changeHandler}
                                />
                                <label className="center-label">회</label>
                            </div>
                        </div>
                        <div className="center-half">
                            <h2 className="center2">좌전굴(앉아윗몸앞으로굽히기)</h2>
                            <div className="center-bg-gray flex">
                                <div className="center-bg-blue">1회차</div>
                                <input
                                    className="center-input-gray center-w120"
                                    type="text"
                                    id="buckling_0"
                                    value={basalPhyData.buckling[0]}
                                    onChange={changeHandler}
                                />
                                <label className="center-label">cm</label>
                            </div>
                        </div>
                    </div>

                    <div className="flex-basic">
                        <div className="center-half">
                            <h2 className="center2">15m 왕복오래달리기</h2>
                            <div className="center-bg-gray flex">
                                <div className="center-bg-blue">1회차</div>
                                <input
                                    className="center-input-gray center-w120"
                                    type="text"
                                    id="shuttleRun15_0"
                                    value={basalPhyData.shuttleRun15[0]}
                                    onChange={changeHandler}
                                />
                                <label className="center-label">초</label>
                            </div>
                        </div>
                        <div className="center-half">
                            <h2 className="center2">20m 왕복오래달리기</h2>
                            <div className="center-bg-gray flex">
                                <div className="center-bg-blue">1회차</div>
                                <input
                                    className="center-input-gray center-w120"
                                    type="text"
                                    id="shuttleRun20_0"
                                    value={basalPhyData.shuttleRun20[0]}
                                    onChange={changeHandler}
                                />
                                <label className="center-label">초</label>
                            </div>
                        </div>
                    </div>

                    <div className="flex-basic">
                        <div className="center-half">
                            <h2 className="center2">제자리멀리뛰기</h2>
                            <div className="center-bg-gray flex">
                                <div className="center-bg-blue">1회차</div>
                                <input
                                    className="center-input-gray center-w120"
                                    type="text"
                                    id="jumpInPlace_0"
                                    value={basalPhyData.jumpInPlace[0]}
                                    onChange={changeHandler}
                                />
                                <label className="center-label">cm</label>
                            </div>
                        </div>
                        <div className="center-half">
                            <h2 className="center2">반복옆뛰기</h2>
                            <div className="center-bg-gray flex">
                                <div className="center-bg-blue">1회차</div>
                                <input
                                    className="center-input-gray center-w120"
                                    type="text"
                                    id="repeatSideJump_0"
                                    value={basalPhyData.repeatSideJump[0]}
                                    onChange={changeHandler}
                                />
                                <label className="center-label">회</label>
                            </div>
                        </div>
                    </div>
                    <div className="flex-basic">
                        <div className="center-half">
                            <h2 className="center2">체공시간</h2>
                            <div className="center-bg-gray flex">
                                <div className="center-bg-blue">1회차</div>
                                <input
                                    className="center-input-gray center-w120"
                                    type="text"
                                    id="flightTime_0"
                                    value={basalPhyData.flightTime[0]}
                                    onChange={changeHandler}
                                />
                                <label className="center-label">초</label>
                            </div>
                        </div>
                        <div className="center-half">
                            <h2 className="center2">반응시간</h2>
                            <div className="center-bg-gray flex">
                                <div className="center-bg-blue">1회차</div>
                                <input
                                    className="center-input-gray center-w120"
                                    type="text"
                                    id="reactionTime_0"
                                    value={basalPhyData.reactionTime[0]}
                                    onChange={changeHandler}
                                />
                                <label className="center-label">초</label>
                            </div>
                        </div>
                    </div>

                    <div className="flex-basic">
                        <div className="center-half">
                            <h2 className="center2">일리노이민청성검사</h2>
                            <div className="center-bg-gray flex">
                                <div className="center-bg-blue">1회차</div>
                                <input
                                    className="center-input-gray center-w120"
                                    type="text"
                                    id="agilityTest_0"
                                    value={basalPhyData.agilityTest[0]}
                                    onChange={changeHandler}
                                />
                                <label className="center-label">초</label>
                            </div>
                        </div>
                        <div className="center-half">
                            <h2 className="center2">눈/손 협응력</h2>
                            <div className="center-bg-gray flex">
                                <div className="center-bg-blue">1회차</div>
                                <input
                                    className="center-input-gray center-w120"
                                    type="text"
                                    id="coordination_0"
                                    value={basalPhyData.coordination[0]}
                                    onChange={changeHandler}
                                />
                                <label className="center-label">초</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
