import AbstractService from '../../AbstractService';
import ReservationService from '../ReservationService';
import Reservation from '../../../domain/reservation/Reservation';
import GroupedReservation from '../../../domain/reservation/GroupedReservation';
import APIServerConfig from '../../../config/APIServerConfig';
import ReservationSearchCondition from '../../../domain/reservation/param/ReservationSearchCondition';
import Paging from '../../../domain/common/Paging';
import { rejects } from 'assert';
class ReservationServiceImpl extends AbstractService implements ReservationService {
    registeReservation(reservation: Reservation | undefined): Promise<Boolean> {
        return new Promise((resolve, reject) => {
            if (reservation) {
                APIServerConfig.recordAPIAxios
                    .post('/reservation', JSON.stringify(reservation), {
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    })
                    .then((response) => {
                        resolve(true);
                    })
                    .catch((err) => {
                        reject('예약 실패');
                    });
            } else {
                reject('예약 내용이 없습니다.');
            }
        });
    }

    registeReservations(reservations: Reservation[]): Promise<Reservation[]> {
        return new Promise((resolve, reject) => {
            APIServerConfig.recordAPIAxios.post(`/reservation/multiple`, JSON.stringify(reservations)).then((response) => {
                const registedReservations = this.jsonConvert.deserializeArray(response.data, Reservation);
                resolve(registedReservations);
            });
        });
    }

    updateReservation(reservationNo: string, reservation: Reservation): Promise<Reservation> {
        return new Promise((resolve, reject) => {
            APIServerConfig.recordAPIAxios
                .put(`/reservation/${reservationNo}`, JSON.stringify(reservation))
                .then((response) => {
                    let reservation: Reservation = this.jsonConvert.deserializeObject(response.data, Reservation);
                    resolve(reservation);
                })
                .catch((err) => {
                    reject('수정 실패');
                });
        });
    }

    updateGroupReservation(reservationGroupNo: string, reservation: Reservation): Promise<Reservation[]> {
        return new Promise((resolve, reject) => {
            APIServerConfig.recordAPIAxios
                .put(`/reservation/team/grouped/${reservationGroupNo}`, JSON.stringify(reservation))
                .then((response) => {
                    let reservation: Reservation[] = this.jsonConvert.deserializeArray(response.data, Reservation);
                    resolve(reservation);
                })
                .catch((err) => {
                    reject('수정 실패');
                });
        });
    }

    getReservationOf(reservationNo: string): Promise<Reservation> {
        return new Promise((resolve, reject) => {
            APIServerConfig.recordAPIAxios
                .get(`/reservation/${reservationNo}`)
                .then((response) => {
                    let reservation: Reservation = this.jsonConvert.deserializeObject(response.data, Reservation);
                    resolve(reservation);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    getReservationsOf(parentInfraCategoryNo: number): Promise<Reservation[]> {
        return new Promise((resolve, reject) => {
            APIServerConfig.recordAPIAxios.get(`/reservation?parentInfraCategoryNo=${parentInfraCategoryNo}`).then((response) => {
                let reservations: Reservation[] = this.jsonConvert.deserializeArray(response.data, Reservation);
                resolve(reservations);
            });
        });
    }

    getReservationOfTeam(teamNo: string): Promise<Reservation[]> {
        return new Promise((resolve, reject) => {
            APIServerConfig.recordAPIAxios.get(`/reservation/team/${teamNo}`).then((response) => {
                let reservations: Reservation[] = this.jsonConvert.deserializeArray(response.data, Reservation);
                resolve(reservations);
            });
        });
    }

    getReservationOfGroupNo(groupNo: string): Promise<Reservation[]> {
        return new Promise((resolve, reject) => {
            APIServerConfig.recordAPIAxios.get(`/reservation/team/detail/${groupNo}`).then((response) => {
                let reservations: Reservation[] = this.jsonConvert.deserializeArray(response.data, Reservation);
                resolve(reservations);
            });
        });
    }

    getReservationGroupOfTeam(teamNo: string, paging: Paging): Promise<any> {
        return new Promise((resolve, reject) => {
            APIServerConfig.recordAPIAxios.get(`/reservation/team/grouped/${teamNo}`, { params: paging }).then((response) => {
                let reservations: Reservation[] = this.jsonConvert.deserializeArray(response.data.resultList, Reservation);
                let reservationCnt: GroupedReservation[] = this.jsonConvert.deserializeArray(response.data.cnt, GroupedReservation);
                reservations.map((reservation, idx) => {
                    reservationCnt.map((cnt, idx) => {
                        if (reservation.reservationGroupNo === cnt.reservationGroupNo) reservation.cnt = cnt.cnt;
                    });
                });

                let reservationInfo = {
                    reservations: reservations,
                    reservationCnt: reservationCnt.length,
                };

                resolve(reservationInfo);
            });
        });
    }

    getReservationGroupForUser(userNo: string, paging: Paging): Promise<any> {
        return new Promise((resolve, reject) => {
            APIServerConfig.recordAPIAxios.get(`/reservation/player/grouped/${userNo}`, { params: paging }).then((response) => {
                let reservations: Reservation[] = this.jsonConvert.deserializeArray(response.data.resultList, Reservation);
                let reservationCnt: GroupedReservation[] = this.jsonConvert.deserializeArray(response.data.cnt, GroupedReservation);
                reservations.map((reservation, idx) => {
                    reservationCnt.map((cnt, idx) => {
                        if (reservation.reservationGroupNo === cnt.reservationGroupNo) reservation.cnt = cnt.cnt;
                    });
                });

                let reservationInfo = {
                    reservations: reservations,
                    reservationCnt: reservationCnt.length,
                };

                resolve(reservationInfo);
            });
        });
    }

    getReservationOfInfra(infraNo: string, reservationSearchCondition: ReservationSearchCondition): Promise<Reservation[]> {
        return new Promise((resolve, reject) => {
            APIServerConfig.recordAPIAxios
                .get(`/reservation/infra/${infraNo}`, {
                    params: reservationSearchCondition,
                })
                .then((response) => {
                    let reservations: Reservation[] = this.jsonConvert.deserializeArray(response.data, Reservation);
                    resolve(reservations);
                });
        });
    }

    getReservationOfTeamAndState(teamNo: string, reservationStateCodeId: number): Promise<Reservation[]> {
        return new Promise((resolve) => {
            APIServerConfig.recordAPIAxios.get(`/reservation/team/${teamNo}/state/${reservationStateCodeId}`).then((response) => {
                let reservations: Reservation[] = this.jsonConvert.deserializeArray(response.data, Reservation);
                resolve(reservations);
            });
        });
    }

    getReservationOfCondition(searchCondition: ReservationSearchCondition, paging: Paging): Promise<Reservation[]> {
        return new Promise((resolve, reject) => {
            APIServerConfig.recordAPIAxios
                .get(`/reservation`, {
                    params: Object.assign(searchCondition, paging),
                })
                .then((response) => {
                    let resources = this.jsonConvert.deserializeArray(response.data, Reservation);
                    resolve(resources);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    getReservationCntOfCondition(searchCondition: ReservationSearchCondition): Promise<number> {
        return new Promise((resolve, reject) => {
            APIServerConfig.recordAPIAxios
                .get(`/reservation/cnt`, {
                    params: searchCondition,
                })
                .then((response) => {
                    let reservationCnt: number = response.data;
                    resolve(reservationCnt);
                });
        });
    }
}

export default ReservationServiceImpl;
