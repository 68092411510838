import { useEffect, useState } from 'react';
import RoleGroupsSearchCondition from '../../domain/code/param/RoleGroupsSearchCondition';
import RoleGroups from '../../domain/user/RoleGroups';
import CodeServiceImpl from '../../service/code/impl/CodeServiceImpl';

const codeService = new CodeServiceImpl();
function useRoleGroupRetrieveByCondition(searchCondition: RoleGroupsSearchCondition) {
    const [roleGroups, setRoleGroups] = useState<RoleGroups[]>();

    useEffect(() => {
        codeService.getRoleGroup(searchCondition).then((roleGroups: RoleGroups[]) => {
            setRoleGroups(roleGroups);
        });
    }, []);

    return {
        roleGroups,
    };
}

export default useRoleGroupRetrieveByCondition;
