import { Link, useLocation } from 'react-router-dom';
import SignUpHeader from "../layout/SignUpHeader";

function SearchIdPage() {
    const location: any = useLocation();

    console.log(process.env.REACT_APP_ID_SEARCH_REDIRECT_SERVER_URL);

    return (
        <>
            <SignUpHeader title="아이디 찾기"></SignUpHeader>
            <div className="mgt10"></div>
            <div className="content-wrap">
                <div className="signup-content-wrap">
                    <h2>아이디 찾기</h2>
                    <div className="use-confirm-wrap-title">
                        <p>* 가입 시 등록한 이메일을 찾습니다.</p>
                        <p>* 회원님의 아이디 찾기를 위해 본인인증이 필요합니다.</p>
                        <p>* 비밀번호를 잊어버리셨나요? <Link to="/pwfinder/id-checker">비밀번호 찾기</Link></p>
                    </div>
                    <div className="mgt30"></div>
                    {
                        location.state.id == 'null' ? 
                        <>
                            <h2>회원가입이 되지 않으셨습니다.</h2>
                        </>
                        : 
                        <>
                            <h2>찾으시는 아이디</h2>
                            <h5>{location.state.id}</h5>
                        </>
                    }
                    <div className="mgt30"></div>
                </div>
            </div>
        </>
    )
}

export default SearchIdPage;