import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import CalendarComponent from '../../../component/calendar/CalendarComponent';
import { RootState } from '../../../moldules';
import UserComponent from '../components/UsersComponent';
import RehabilitationListComponent from './RehabilitationListComponent';
import Infra from '../../../domain/infra/Infra';
import RehabilitationUnitTimeComponent from './RehabilitationUnitTimeComponent';
import User from '../../../domain/user/User';
import useReservationOfInfra from '../../../hook/reservation/useReservationsOfInfra';
import ReservationSearchCondition from '../../../domain/reservation/param/ReservationSearchCondition';
import Reservation from '../../../domain/reservation/Reservation';
import useReservationsRegiste from '../../../hook/reservation/useReservationsRegiste';
import DateUtils from '../../../utils/DateUtils';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ko } from 'date-fns/esm/locale';
import ReservationServiceImpl from '../../../service/reservation/impl/ReservationServiceImpl';
import { confirmAlert } from 'react-confirm-alert';

interface ReservationPopupContainer {
    // reservationDay? : string
    // setReservationDay : (reservationDay: string) => void;
}

const reservationService = new ReservationServiceImpl();

function ReservationCenterComponent(props: ReservationPopupContainer) {
    const history = useHistory();

    const initDate = new Date();
    const [reservationSearchCondition, setReservationSearchCondition] = useState<ReservationSearchCondition>();
    const { user, isLogin, hasTeamAuth, hasSystemAuth, myTeam } = useSelector((state: RootState) => state.userSessionReducer);

    const teamId = myTeam ? myTeam['teamNo'] : undefined;

    const [userOpenable, setUserOpenable] = useState(false);
    const [centerOpenable, setCenterOpenable] = useState(false);
    const [timeTableOpenable, setTimeTableOpenable] = useState(false);
    const [reservationVisible, setReservationVisible] = useState(false);

    const [popupOpenable, setPopupOpenable] = useState(false);

    const [centerName, setCenterName] = useState('센터 선택');

    const [infra, setInfra] = useState<Infra>();
    const [reservaterUserNos, setReservaterUserNos] = useState<string[]>();
    const [reservationDay, setReservationDay] = useState<any>();
    const [reservationStartDate, setReservationStartDate] = useState<string>();
    const [reservationEndDate, setReservationEndDate] = useState<string>();

    useEffect(() => {
        setReservationDay(DateUtils.getDateForm(initDate));
    }, []);

    useEffect(() => {
        if (reservationDay) {
            const reservationSearchCondition = new ReservationSearchCondition();
            reservationSearchCondition.startDay = reservationDay;
            setReservationSearchCondition(reservationSearchCondition);
        }
    }, [reservationDay]);

    const { reservations } = useReservationOfInfra(infra?.infraNo, reservationSearchCondition);

    function userSettingFunc(userBool: boolean) {
        setCenterOpenable(false);
        setTimeTableOpenable(false);
        setReservationVisible(false);
        setUserOpenable(userBool);
    }

    function centerSettingFunc(centerBool: boolean) {
        setCenterOpenable(centerBool);
        setTimeTableOpenable(false);
        setReservationVisible(false);
        setUserOpenable(false);
    }

    function calendarSettingFunc(calBool: boolean) {
        setCenterOpenable(false);
        setTimeTableOpenable(false);
        setReservationVisible(calBool);
        setUserOpenable(false);
    }

    function timeTableSettingFunc(timeTableBool: boolean) {
        setCenterOpenable(false);
        setTimeTableOpenable(timeTableBool);
        setReservationVisible(false);
        setUserOpenable(false);
    }

    const registureBtnHandler = () => {
        let reservations = new Array<Reservation>();
        let reservationTime: Date = new Date();
        let reservationGroupNo: string = reservationTime.getTime().toString();

        if (reservaterUserNos && reservationDay && reservationStartDate && reservationEndDate && infra) {
            reservaterUserNos?.forEach((reservaterUserNo: string) => {
                const reservation: Reservation = new Reservation();
                reservation.reservationGroupNo = reservationGroupNo;
                reservation.teamNo = myTeam.teamNo;
                reservation.reservaterNo = reservaterUserNo;
                reservation.registerNo = user.userNo;
                reservation.infraNo = infra?.infraNo;
                reservation.startDate = reservationStartDate;
                reservation.endDate = reservationEndDate;
                reservation.reservationStateCodeId = 1001;

                reservations.push(reservation);
            });

            reservationService.registeReservations(reservations).then((result: Reservation[]) => {
                let message = result.length + '건의 예약이 완료 되었습니다. 예약 확인은 마이페이지에서 가능합니다.';
                confirmAlert({
                    title: '예약성공',
                    message: message,
                    buttons: [
                        {
                            label: '확인',
                            onClick: () => {
                                history.replace('/mypage/team-admin/reservation');
                            },
                        },
                    ],
                });
            });
        } else {
            confirmAlert({
                title: '입력 경고',
                message: '입력 값을 확인해 주십시오.',
                buttons: [{ label: '확인', onClick: () => {} }],
            });
        }
    };

    return (
        <>
            <h4>예약가능 여부를 확인해보세요.</h4>
            {isLogin && (hasTeamAuth || hasSystemAuth) ? (
                <>
                    <span>예약할 선수를 선택해 주세요!</span>
                    <div className="sportsman-wrap">
                        <div
                            className="sportsman"
                            onClick={() => {
                                userSettingFunc(!userOpenable);
                            }}
                        >
                            <div className="sportsman-in">
                                <p>선수선택-{reservaterUserNos?.length ? reservaterUserNos?.length : 0}명</p>
                            </div>
                            <div className="sportsman-bt">
                                <a id="dbt" className="down" style={{ display: `${userOpenable ? 'none' : 'block'}` }}>
                                    &or;
                                </a>
                                <a id="ubt" className="up" style={{ display: `${userOpenable ? 'block' : 'none'}` }}>
                                    &and;
                                </a>
                            </div>
                        </div>
                        <UserComponent setCheckedUserNos={setReservaterUserNos} teamId={teamId} openable={userOpenable}></UserComponent>
                    </div>
                    <br />
                    <span>측정센터를 선택해 주세요!</span>
                    <div className="sportsman-wrap">
                        <div
                            className="sportsman"
                            onClick={() => {
                                centerSettingFunc(!centerOpenable);
                            }}
                        >
                            <div className="sportsman-in">
                                <p>{centerName}</p>
                            </div>
                            <div className="sportsman-bt">
                                <a id="ddbt" className="down" style={{ display: `${centerOpenable ? 'none' : 'block'}` }}>
                                    &or;
                                </a>
                                <a id="uubt" className="up" style={{ display: `${centerOpenable ? 'block' : 'none'}` }}>
                                    &and;
                                </a>
                            </div>
                        </div>
                        <RehabilitationListComponent
                            openable={centerOpenable}
                            centerName={centerName}
                            setCenterOpenable={setCenterOpenable}
                            setCenterName={setCenterName}
                            setSelectedInfra={setInfra}
                        />
                    </div>
                    <br />
                    <span>희망측정일과 시간을 선택해 주세요</span>
                    <div className="sportsman-wrap">
                        <div className="sportsman">
                            <DatePicker
                                className="sportsman-datepicker"
                                locale={ko}
                                dateFormat="yyyy-MM-dd"
                                closeOnScroll={true}
                                selected={DateUtils.convertStrToDate(reservationDay)}
                                onChange={(date) => {
                                    setReservationDay(date);
                                }}
                            />
                            {/* <div className="sportsman-in">
                                <div
                                    className="titled"
                                    onClick={() => {
                                        calendarSettingFunc(!reservationVisible);
                                    }}
                                >
                                    {reservationDay}
                                </div>
                                <div id="calendar" className="calendar-day popuptext" style={{ display: `${reservationVisible ? 'block' : 'none'}` }}>
                                    <div style={{ textAlign: 'right' }}>
                                        <button onClick={() => closeButtonHandler()}>x</button>
                                    </div>
                                    <CalendarComponent onDateChange={setReservationDay}></CalendarComponent>
                                    <div className="clear"></div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                    <div className="sportsman-wrap" style={{ marginTop: '3px' }}>
                        <div
                            className="sportsman"
                            onClick={() => {
                                timeTableSettingFunc(!timeTableOpenable);
                            }}
                        >
                            <div className="sportsman-in">
                                <p>
                                    {reservationStartDate && reservationEndDate ? `[${reservationStartDate}] ~ [${reservationEndDate}]` : '시간 선택'}
                                </p>
                            </div>
                            <div className="sportsman-bt">
                                <a id="ddbt" className="down" style={{ display: `${timeTableOpenable ? 'none' : 'block'}` }}>
                                    &or;
                                </a>
                                <a id="uubt" className="up" style={{ display: `${timeTableOpenable ? 'block' : 'none'}` }}>
                                    &and;
                                </a>
                            </div>
                        </div>
                        <RehabilitationUnitTimeComponent
                            setReservationStartDate={setReservationStartDate}
                            setReservationEndDate={setReservationEndDate}
                            reservationDay={reservationDay}
                            reservations={reservations}
                            infra={infra}
                            timeTableOpenable={timeTableOpenable}
                            setTimeTableOpenable={setTimeTableOpenable}
                        />
                    </div>
                    <div className="reser-bt-blue mouse-pointer" onClick={registureBtnHandler}>
                        <a>예약하기</a>
                    </div>
                </>
            ) : (
                <div
                    className="disabled"
                    onClick={() => {
                        setPopupOpenable(true);
                    }}
                >
                    <span>예약할 선수를 선택해 주세요!</span>
                    <div className="sportsman-wrap">
                        <div className="sportsman">
                            <div className="sportsman-in">
                                <p>선수선택</p>
                            </div>
                            <div className="sportsman-bt">
                                <a id="dbt" className="down">
                                    &or;
                                </a>
                                <a id="ubt" className="up">
                                    &and;
                                </a>
                            </div>
                        </div>
                    </div>
                    <br />
                    <span>측정센터를 선택해 주세요!</span>
                    <div className="center-wrap" style={{ height: 'auto', minWidth: 'auto' }}>
                        <div className="center">
                            <div className="center-in">
                                <p>센터 선택</p>
                            </div>
                            <div className="center-bt">
                                <a id="ddbt" className="down">
                                    &or;
                                </a>
                                <a id="uubt" className="up">
                                    &and;
                                </a>
                            </div>
                        </div>
                    </div>
                    <br />
                    <div className="calendar-wrap">
                        <span>희망측정일과 시간을 선택해 주세요</span>
                        <div className="calendar">
                            <div className="calendar-in">
                                <div className="titled">{reservationDay}</div>
                            </div>
                        </div>
                        <div className="time">
                            <div className="calendar-cho">
                                <div className="titlet">
                                    <p>시간 선택</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <div className={popupOpenable ? 'needpopup-opened needpopup-scrolled' : ''}>
                <div className="needpopup_wrapper">
                    <div id="reserve" className={`needpopup ${popupOpenable ? 'opened' : ''}`} style={{ display: 'block' }}>
                        <p className="pop-title">예약 권한이 없습니다. 권한이 있는 계정으로 로그인 해주세요.</p>
                        <p className="pop-title">
                            <Link to="/login">로그인하러가기</Link>
                        </p>
                    </div>
                    <a
                        onClick={() => {
                            setPopupOpenable(false);
                        }}
                        className="needpopup_remover"
                    ></a>
                </div>
            </div>
        </>
    );
}

export default ReservationCenterComponent;
