import { useEffect, useState } from "react";
import SearchServiceImpl from '../service/search/impl/SearchServiceImpl';
import SearchItem from "../domain/search/SearchItem";

const searchService = new SearchServiceImpl();
function useWordSearchHook(word: string|undefined) {
    const [searchItems, setSearchItems] = useState<Array<SearchItem>>();
    useEffect(() => {
        if(word == '' || word === undefined) {
            setSearchItems(new Array<SearchItem>());
        } else {
            searchService.getSearchItems(word)
            .then((searchItems: SearchItem[]) => {
                setSearchItems(searchItems);
            });
        }
        
    }, [word]);
    
    return {searchItems};
}

export default useWordSearchHook;