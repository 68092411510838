import React, { useEffect, useState } from 'react';
import Code from '../../../domain/code/Code';
import AdminTable from '../../../component/admin/AdminTable';
import ChartDataUtils from '../../../utils/ChartDataUtils';
import DateUtils from '../../../utils/DateUtils';
import ClubTeamInfoStatisticsServiceImpl from '../../../service/clubteam/impl/ClubTeamInfoStatisticsServiceImpl';
import ClubTeamInfoSearchCondition from '../../../domain/clubteam/param/ClubTeamInfoSearchCondition';
import ClubTeamInfoStatistics from '../../../domain/clubteam/ClubTeamInfoStatistics';

interface ClubStatusByRegionTableProps {
    searchCondition?: ClubTeamInfoSearchCondition;
    regionCodes?: Code[];
    clubTotalStatusByRegion?: ClubTeamInfoStatistics;
    clubStatusByRegion?: ClubTeamInfoStatistics[];
}
const statisticsService = new ClubTeamInfoStatisticsServiceImpl();

function ClubStatusByRegionTables(props: ClubStatusByRegionTableProps) {
    const [typeDetailTable, setTypeDetailTable] = useState<any>(<></>);

    const typeStatus: ClubTeamInfoStatistics[] | undefined = props.clubStatusByRegion;
    const [selectedCodeId, setSelectedCodeId] = useState<string>();

    let selectedlHandler = (evt: any) => {
        let selectedVal = evt.currentTarget.id;
        let valArr = selectedVal.split('_');
        let selectedCodeId = valArr[0];

        let condition = props.searchCondition ? props.searchCondition : new ClubTeamInfoSearchCondition();
        condition.regionCodeIds = selectedCodeId;
        let codes = props.regionCodes;
        let regionName = valArr[1];

        statisticsService.getClubDetailStatusByRegion(condition).then((data) => {
            let size = data.length;
            let detailTypeTable = (
                <>
                    <table>
                        <thead>
                            <tr>
                                <td colSpan={4} style={{ textAlign: 'left', paddingLeft: '5px', background: '#474747' }}>
                                    <i className="fa fa-check"></i>&nbsp;&nbsp; 지역 종목 별 정보 요약 (선택지역: {regionName} / {size} 종목)
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="txt-center" style={{ background: '#d9d9d9', width: '15%', height: '30px' }}>
                                    종목
                                </td>
                                <td className="txt-right" style={{ background: '#d9d9d9', width: '15%' }}>
                                    팀 수
                                </td>
                                <td className="txt-right" style={{ background: '#d9d9d9', width: '15%', height: '30px' }}>
                                    총 인원
                                </td>
                                <td className="txt-right" style={{ background: '#d9d9d9', width: '55%', height: '30px' }}>
                                    팀 당 평균 인원
                                </td>
                            </tr>
                            {data?.length > 0 ? (
                                data.map((state: ClubTeamInfoStatistics, index: number) => {
                                    return (
                                        <tr key={index}>
                                            <td>{state?.clubSportCode?.name}</td>
                                            <td style={{ textAlign: 'right' }}>{state?.clubTeamCount} 팀</td>
                                            <td style={{ textAlign: 'right' }}>
                                                {ChartDataUtils.getNumberFormat(state.clubTeamMemberCount ? state.clubTeamMemberCount : 0)}명
                                            </td>
                                            <td style={{ textAlign: 'right' }}>
                                                {state?.clubTeamMemberCount && state?.clubTeamCount && state?.clubTeamCount > 0
                                                    ? (state?.clubTeamMemberCount / state.clubTeamCount).toFixed(2)
                                                    : 0}{' '}
                                                명
                                            </td>
                                        </tr>
                                    );
                                })
                            ) : (
                                <tr>
                                    <td colSpan={4}>
                                        <h5>조회 조건에 맞는 내용이 없습니다.</h5>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </>
            );
            setTypeDetailTable(detailTypeTable);
        });
    };

    useEffect(() => {
        setTypeDetailTable(
            <table>
                <thead>
                    <tr>
                        <td colSpan={4} style={{ textAlign: 'left', paddingLeft: '5px', background: '#474747' }}>
                            <i className="fa fa-check"></i>&nbsp;&nbsp; 지역 종목 별 정보 요약 (선택지역: )
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="txt-center" style={{ background: '#d9d9d9', width: '15%', height: '30px' }}>
                            종목
                        </td>
                        <td className="txt-right" style={{ background: '#d9d9d9', width: '15%' }}>
                            팀 수
                        </td>
                        <td className="txt-right" style={{ background: '#d9d9d9', width: '15%', height: '30px' }}>
                            총 인원
                        </td>
                        <td className="txt-right" style={{ background: '#d9d9d9', width: '55%', height: '30px' }}>
                            팀 당 평균 인원
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={4} className="txt-center blue" style={{ height: '30px' }}>
                            종목을 선택 하여 주십시오.
                        </td>
                    </tr>
                </tbody>
            </table>,
        );
    }, [typeStatus]);

    let typeTotal = props.clubTotalStatusByRegion;
    // let dateGap =
    // typeTotal?.firstVisitedDate && typeTotal?.lastVisitedDate
    //     ? DateUtils.getStrDayGap(typeTotal?.firstVisitedDate, typeTotal?.lastVisitedDate) + 1
    //     : 0;
    const typeTotalTable = (
        <>
            <table>
                <thead>
                    <tr>
                        <td colSpan={6} style={{ textAlign: 'left', paddingLeft: '5px', background: '#474747' }}>
                            지역 별 동호회 현황
                            {/* <i className="fa fa-check"></i>&nbsp;&nbsp;유치 팀 정보 종목 별 집계 ({typeTotal?.firstVisitedDate} ~{' '}
                            {typeTotal?.lastVisitedDate} &lt;{dateGap} 일간&gt;) */}
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="txt-center" style={{ background: '#d9d9d9', width: '10%', height: '30px' }}>
                            총 지역 수
                        </td>
                        <td className="txt-left" style={{ width: '15%' }}>
                            {typeTotal ? ChartDataUtils.getNumberFormat(Number(typeTotal.cnt)) : 0} 지역
                        </td>
                        <td className="txt-center" style={{ background: '#d9d9d9', width: '10%', height: '30px' }}>
                            총 동호회 팀 수
                        </td>
                        <td className="txt-left" style={{ width: '15%' }}>
                            {typeTotal ? ChartDataUtils.getNumberFormat(Number(typeTotal.clubTeamCount)) : 0} 팀
                        </td>
                        <td className="txt-center" style={{ background: '#d9d9d9', width: '10%', height: '30px' }}>
                            총 동호회 팀 인원
                        </td>
                        <td className="txt-left" style={{ width: '15%' }}>
                            {typeTotal ? ChartDataUtils.getNumberFormat(Number(typeTotal.clubTeamMemberCount)) : 0} 명
                        </td>
                    </tr>
                </tbody>
            </table>
        </>
    );

    let typeTable = (
        <>
            <table>
                <thead>
                    <tr>
                        <td colSpan={5} style={{ textAlign: 'left', paddingLeft: '5px', background: '#474747' }}>
                            <i className="fa fa-check"></i>&nbsp;&nbsp;지역 별 동호회 현황 요약
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="txt-center" style={{ background: '#d9d9d9', width: '20%', height: '30px' }}>
                            지역
                        </td>
                        <td className="txt-right" style={{ background: '#d9d9d9', width: '20%' }}>
                            팀 수
                        </td>
                        <td className="txt-right" style={{ background: '#d9d9d9', width: '20%', height: '30px' }}>
                            팀 인원
                        </td>
                        <td className="txt-center" style={{ background: '#d9d9d9', width: '20%' }}>
                            상세정보
                        </td>
                    </tr>
                    {typeStatus && typeStatus.length > 0 ? (
                        typeStatus.map((state: ClubTeamInfoStatistics, index: number) => {
                            let regionCodeId: string = state?.regionCode?.codeId ? state?.regionCode?.codeId.toString() : 'Empty';

                            return (
                                <tr key={index}>
                                    <td>{state?.regionCode?.name}</td>
                                    <td style={{ textAlign: 'right' }}>{ChartDataUtils.getNumberFormat(Number(state?.clubTeamCount))} 팀</td>
                                    <td style={{ textAlign: 'right' }}>{ChartDataUtils.getNumberFormat(Number(state?.clubTeamMemberCount))} 명</td>

                                    <td>
                                        <button
                                            id={regionCodeId + `_` + state?.regionCode?.name}
                                            className="bg-light-blue"
                                            style={{ fontWeight: 'bold', borderRadius: '4px', padding: '5px' }}
                                            onClick={selectedlHandler}
                                        >
                                            <i className="fas fs-search"></i>
                                            상세확인
                                        </button>
                                    </td>
                                </tr>
                            );
                        })
                    ) : (
                        <tr>
                            <td colSpan={5}>
                                <h5>조회 조건에 맞는 내용이 없습니다.</h5>
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </>
    );

    return (
        <>
            <div className="flex-basic">
                <div className="member-list" style={{ width: '100%' }}>
                    {typeTotalTable}
                </div>
            </div>
            <div className="two-wrap">
                <div className="half" style={{ width: '30%' }}>
                    <div className="member-list" style={{ width: '98%' }}>
                        {typeTable}
                    </div>
                </div>
                <div className="half" style={{ width: '70%' }}>
                    <div className="member-list" style={{ width: '100%' }}>
                        {typeDetailTable}
                    </div>
                </div>
            </div>
        </>
    );
}
export default ClubStatusByRegionTables;
