export enum CodeType {
    SPORT = 'sport',
    GENDER = 'gender',
    REGION = 'region',
    CLASSIFICATION = 'classification',
    PARENT_INFRA_CATEGORY = 'parentInfraCategory',
    CHILD_INFRA_CATEGORY = 'childInfraCategory',
    SEARCH_WORD = 'searchWord',
    EVENT_TYPE = 'event_type',
    TEAM_TYPE = 'team-type',
    BELONGED = 'belonged',
    RESERVATION_STATE = 'reservation-state',
    CLUB_SPORT = 'club_sport',
}
