import React from 'react';
import { Link } from 'react-router-dom';

interface liProps {
    uri: string;
    selectedMenu: string;
    name: string;
    iconclass: string;
    hasChild: boolean;
    subMenus: string;
    children: { uri: string; name: string }[];
}

function LIElement(props: liProps) {
    let menu = props.selectedMenu;
    let subMenus = props.subMenus;
    let uri = props.uri;

    let children = props.children;
    let li;
    if (menu.indexOf(uri) >= 0 || subMenus.indexOf(menu) >= 0) {
        if (!props.hasChild) {
            li = (
                <li className="on">
                    <Link to={props.uri}>
                        <i className={props.iconclass}></i>
                        {props.name}
                    </Link>
                </li>
            );
        } else {
            li = (
                <li className="onmore">
                    <Link to={props.uri}>
                        <i className={props.iconclass}></i>
                        {props.name}
                    </Link>
                    <div className="inbox">
                        {children.map((item, idx) => {
                            let li;
                            if (item.uri.indexOf(menu) >= 0) {
                                li = (
                                    <div key={idx}>
                                        <Link key={idx} to={item.uri} className="ons">
                                            <i className="fa fa-angle-right"></i> {item.name}
                                        </Link>
                                    </div>
                                );
                            } else {
                                li = (
                                    <div key={idx}>
                                        <Link key={idx} to={item.uri}>
                                            <i className="fa fa-angle-right"></i> {item.name}
                                        </Link>
                                    </div>
                                );
                            }
                            return li;
                        })}
                    </div>
                </li>
            );
        }
    } else {
        li = (
            <li>
                <Link to={props.uri}>
                    <i className={props.iconclass}></i>
                    {props.name}
                </Link>
            </li>
        );
    }
    return li;
}

export default LIElement;
