import CodeService from '../CodeService';
import Code from '../../../domain/code/Code';
import AbstractService from '../../AbstractService';
import APIServerConfig from '../../../config/APIServerConfig';
import Roles from '../../../domain/user/Roles';
import RoleGroupsSearchCondition from '../../../domain/code/param/RoleGroupsSearchCondition';
import RoleGroups from '../../../domain/user/RoleGroups';
import RoleSearchCondition from '../../../domain/code/param/RoleSearchCondition';
export default class CodeServiceImpl extends AbstractService implements CodeService {

    getRoleCode(searchCondition: RoleSearchCondition): Promise<Roles[]>{
        return new Promise((resolve) => {
            APIServerConfig.recordAPIAxios.get(`/code/role`, {
                params: searchCondition
            })
            .then((response) => {
                let roles: Roles[] = this.jsonConvert.deserializeArray(response.data, Roles);
                resolve(roles);
            })
        });
    }

    getRoleGroup(searchCondition: RoleGroupsSearchCondition): Promise<RoleGroups[]> {
        return new Promise((resolve, reject) => {
            APIServerConfig.recordAPIAxios.get(`/code/role-group`, {
                params: searchCondition
            })
            .then((response) => {
                let roleGruops: RoleGroups[] = this.jsonConvert.deserializeArray(response.data, RoleGroups);
                resolve(roleGruops);
            })
        });
    }

    getCode(codeType: string): Promise<Code[]> {
        return new Promise((resolve) => {
            APIServerConfig.recordAPIAxios.get(`/code/${codeType}`)
            .then((response) => {
                let codes: Code[] = this.jsonConvert.deserializeArray(response.data, Code);
                resolve(codes);
            })
        });
    }

    getCodeByParentCodeId(pCodeId: number): Promise<Code[]> {
        return new Promise((resolve, reject) => {
            APIServerConfig.recordAPIAxios.get(`/code/${pCodeId}/child-code`)
            .then((response) => {
                let codes: Code[] = this.jsonConvert.deserializeArray(response.data, Code);
                resolve(codes);
            })
            .catch(err => {
                reject(err);
            });
        });
    }
}