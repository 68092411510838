import { useEffect, useState } from "react";
import UserFavoritesInfra from '../../domain/favorities/UserFavoritesInfra';
import { FAVORITES_INFRA_SERVICE } from '../../service/index';

function useFavoritesInfraRetrieve(userNo?: string, infraNo?: string) {
    const [userFavoritesInfra, setUserFavoritesInfra] = useState<UserFavoritesInfra>();

    useEffect(() => {
        if(userNo && infraNo) {
            FAVORITES_INFRA_SERVICE.getUserFavoritesInfra(userNo, infraNo)
            .then((userFavoritesInfra: UserFavoritesInfra) => {
                setUserFavoritesInfra(userFavoritesInfra);
            });
        }    
    }, [userNo, infraNo]);

    return {
        userFavoritesInfra, setUserFavoritesInfra
    };
}

export default useFavoritesInfraRetrieve;