import React from 'react';
import { RouteComponentProps} from 'react-router-dom';
import AdminMainHeader from '../../layout/AdminMainHeader';
import AdminMainSider from '../../layout/AdminMainSider';
import AdminSurveyListContainer from './AdminSurveyListContainer';
import AdminSurveyInfoContainer from './AdminSurveyInfoContainer';

interface MatchParams {
    teamNo: string;
}

function AdminSurveyContainer({match} : RouteComponentProps){
    const url = match.path;

    return (
        <div className="admin-body">
            <AdminMainHeader></AdminMainHeader>
            <div className="admin-container">
                <AdminMainSider></AdminMainSider>
                {
                    (url.indexOf('list') > -1) ?
                    <AdminSurveyListContainer url={url}></AdminSurveyListContainer>
                    :
                    <AdminSurveyInfoContainer url={url}></AdminSurveyInfoContainer>
                }
            </div>
        </div>
    )
}

export default AdminSurveyContainer;