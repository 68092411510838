import React, { useState } from 'react';
import SiteMainNav from '../nav/SiteMainNav';
import LoginState from './LoginState';

function MobileMenuContainer() {
    const [mobileOpenable, setMobileOpenable] = useState(false);

    const clickEventHandler = () => {
        setMobileOpenable(!mobileOpenable);
    };

    return (
        <>
            <div className="mobile-container">
                <div className="topnav">
                    {/* <a href="#home" className="active"></a> */}
                    <div id="myLinks" style={{ display: mobileOpenable ? 'block' : 'none' }}>
                        <SiteMainNav></SiteMainNav>
                        <LoginState></LoginState>
                    </div>
                    <a className="icon">
                        <i className="fa fa-bars" style={{ cursor: 'pointer' }} onClick={() => clickEventHandler()}></i>
                    </a>
                </div>
            </div>
        </>
    );
}

export default MobileMenuContainer;
