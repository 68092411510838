import React from 'react';
import QnADynamic from '../../../../domain/QnADynamic';
import Questionnaire from '../../../../domain/Questionnaire';
import NDI from '../../questionnaires/forms/1NDI';
import Tops from '../../questionnaires/forms/2TopsForm';
import Spaid from '../../questionnaires/forms/3SpadiForm';
import StressCheckForm from '../../questionnaires/forms/4StressCheckForm';
import WhoQ from '../../questionnaires/forms/5WHOQQOL';
import FABQ from '../../questionnaires/forms/6FABQ';
import IESRK from '../../questionnaires/forms/7IESRK';

interface IManageQuest {
    setQuestPopupOpenable: (bool: boolean) => void;
    closeClickHander: () => void;
    removeHandler: (e: any) => void;
    data?: Questionnaire;
    index: number;
}

function ManageQuestDetailPopup(props: IManageQuest) {
    let dataObj = props.data?.questions ? JSON.parse(props.data?.questions) : '';
    const isDefaultable = props.data?.isDefaultable ? props.data.isDefaultable : 0;
    let { displayTitle, title, description } = props.data || {};
    let { children } = dataObj;

    function keyRender() {
        let form: any = '';
        if (props.data?.questionnaireKey) {
            if (props.data?.questionnaireKey === 'NDI') {
                form = NDI({});
            } else if (props.data?.questionnaireKey === 'Spadi') {
                form = Spaid({});
            } else if (props.data?.questionnaireKey === 'WhoQ') {
                form = WhoQ({});
            } else if (props.data?.questionnaireKey === 'FABQ') {
                form = FABQ({});
            } else if (props.data?.questionnaireKey === 'IESRK') {
                form = IESRK({});
            } else if (props.data?.questionnaireKey === 'Tops') {
                form = Tops({});
            } else if (props.data?.questionnaireKey === 'StressCheckForm') {
                form = StressCheckForm({});
            }
        } else {
            form = '<></>';
        }

        return form;
    }
    let actionBtnElem = <></>;
    if (isDefaultable === 0) {
        actionBtnElem = (
            <>
                <button className="center-button-delete" id={'remove_' + props.index} onClick={props.removeHandler}>
                    <i className="fas fa-trash-alt"></i>삭제
                </button>
                <button className="center-button-close" onClick={props.closeClickHander}>
                    닫기
                    <i className="fas fa-times-circle"></i>
                </button>
            </>
        );
    } else {
        actionBtnElem = (
            <>
                <button className="center-button-close" onClick={props.closeClickHander}>
                    닫기
                    <i className="fas fa-times-circle"></i>
                </button>
            </>
        );
    }
    return (
        <>
            <div className="center-popup wow  animate__animated animate__fadeInUp" data-wow-delay="0.2s">
                <div className="center-popup-title">
                    <h1 className="center">설문지</h1>
                    {actionBtnElem}
                </div>
                <div className="center-popup-cont">
                    <div className="center-popup-bnt">
                        <div className="popup-inner">
                            <i className="fas fa-file-alt"></i>설문 보기
                        </div>
                    </div>
                    {props.data?.questionnaireKey && (props.data?.questionnaireKey).indexOf('SATISFY') < 0 ? (
                        keyRender()
                    ) : (
                        <div id="list-tab" className="center-popup-list-tab" style={{ display: 'block' }}>
                            <div className="tab-menu">
                                <ul>
                                    <li>{displayTitle}</li>
                                </ul>
                            </div>
                            <div className="tab-story">
                                <div id="tabstory1">
                                    <div className="center-center">{title}</div>
                                    <p>{description}</p>
                                    {dataObj.map((item: QnADynamic, idx: number) => {
                                        console.log(item);
                                        return (
                                            <div className="tab-story-number" key={idx}>
                                                <h2 className="center2">
                                                    제 {idx + 1}항목 - {item.question}
                                                </h2>
                                                <ul>
                                                    {item.items?.map((inner, innerIdx: number) => {
                                                        return (
                                                            <li key={innerIdx}>
                                                                {innerIdx + 1}. {inner.content}
                                                            </li>
                                                        );
                                                    })}
                                                </ul>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}

export default ManageQuestDetailPopup;
