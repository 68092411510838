class SearchConditionUtils {
    /**
     * {region: {1:1, 2:2}} 형태의 자료 구조를 
     * {region: "1,2"} 형태의 자료 구조로 변경하는 메소드
     * @param searchConditionMap 
     */
    static makeSearchCondition(searchConditionMap: any) {
        let parsedSearchConditionMap: any = {};
        let coditionNames = Object.keys(searchConditionMap);

        coditionNames.map((conditionName) => {
            let values = Object.keys(searchConditionMap[conditionName]);
            if(values.length > 0) {
                parsedSearchConditionMap[conditionName] = values.toString();
            }
        });

        return parsedSearchConditionMap;
    }
}

export default SearchConditionUtils;