import TmpRegistedTeamUserService from '../TmpRegistedTeamUserService';
import AbstractService from '../../AbstractService';
import TmpRegistedTeamUser from '../../../domain/team/TmpRegistedTeamUser';
import APIServerConfig from '../../../config/APIServerConfig';
import TempRegistedTeamUserSearchCondition from '../../../domain/team/param/TempRegistedTeamUserSearchCondition';

class TmpRegistedTeamUserServiceImpl extends AbstractService implements TmpRegistedTeamUserService {
    userRegiste(tmpRegistedTeamUser: TmpRegistedTeamUser): Promise<TmpRegistedTeamUser> {
        return new Promise((resolve) => {
            APIServerConfig.recordAPIAxios
                .post('/tmp-team-player', JSON.stringify(tmpRegistedTeamUser), {
                    headers: { 'Content-Type': 'application/json' },
                })
                .then((response) => {
                    let tmpRegistedTeamUser = this.jsonConvert.deserializeObject(response.data, TmpRegistedTeamUser);
                    resolve(tmpRegistedTeamUser);
                });
        });
    }
    getTmpUsers(teamNo: string, searchCondition: TempRegistedTeamUserSearchCondition): Promise<TmpRegistedTeamUser[]> {
        return new Promise((resolve) => {
            APIServerConfig.recordAPIAxios
                .get(`/tmp-team-player/${teamNo}`, {
                    params: searchCondition,
                })
                .then((response) => {
                    let tmpRegistedTeamUser = this.jsonConvert.deserializeArray(response.data, TmpRegistedTeamUser);
                    resolve(tmpRegistedTeamUser);
                });
        });
    }

    userDelete(tmpRegistedNo: string): Promise<string> {
        return new Promise((resolve) => {
            APIServerConfig.recordAPIAxios.delete(`/tmp-team-player/${tmpRegistedNo}`).then((response) => {
                resolve(response.data);
            });
        });
    }
    userModify(tmpRegistedTeamUser: TmpRegistedTeamUser): Promise<TmpRegistedTeamUser> {
        return new Promise((resolve) => {
            APIServerConfig.recordAPIAxios.put(`/tmp-team-player`, JSON.stringify(tmpRegistedTeamUser)).then((response) => {
                const tmpRegistedTeamUser = this.jsonConvert.deserializeObject(response.data, TmpRegistedTeamUser);
                resolve(tmpRegistedTeamUser);
            });
        });
    }
}

export default TmpRegistedTeamUserServiceImpl;
