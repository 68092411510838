import React from 'react';
import { JsonProperty, JsonObject } from 'json2typescript';

@JsonObject('rehabProgramPrescription')
export class RehabProgramPrescription {
    @JsonProperty('leftElbow')
    leftElbow: string = '';
    @JsonProperty('leftWrist')
    leftWrist: string = '';
    @JsonProperty('leftAnkle')
    leftAnkle: string = '';
    @JsonProperty('leftShoulder')
    leftShoulder: string = '';
    @JsonProperty('leftPelvis')
    leftPelvis: string = '';
    @JsonProperty('leftKnee')
    leftKnee: string = '';

    @JsonProperty('rightElbow')
    rightElbow: string = '';
    @JsonProperty('rightWrist')
    rightWrist: string = '';
    @JsonProperty('rightAnkle')
    rightAnkle: string = '';
    @JsonProperty('rightShoulder')
    rightShoulder: string = '';
    @JsonProperty('rightPelvis')
    rightPelvis: string = '';
    @JsonProperty('rightKnee')
    rightKnee: string = '';

    @JsonProperty('neck')
    neck: string = '';
    @JsonProperty('waist')
    waist: string = '';
}
