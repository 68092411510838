import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Infra from '../../../../../domain/infra/Infra';
import SportInfraFeeTab from './tab/SportInfraFeeTab';
import CommonInfraLocationTab from '../commonTab/CommonInfraLocationTab';
import CommonInfraEventTab from '../commonTab/CommonInfraEventTab';
import CommonInfraPrVideoTab from '../commonTab/CommonInfraPrVideoTab';
import ImgSlider from '../../../../../component/img/ImgSlider';
import SportInfraDetailInfoTab from './tab/SportInfraDetailInfoTab';
import User from '../../../../../domain/user/User';
import UserFavoritesInfra from '../../../../../domain/favorities/UserFavoritesInfra';
import useFavoritesInfraRegiste from '../../../../../hook/favorites/useFavoritesInfraRegiste';
import useFavoritesInfraDelete from '../../../../../hook/favorites/useFavoritesInfraDelete';
import useInfraFavoritesHook from '../../../hook/useInfraFavoritesHook';
interface SportInfraDetailComponentProps {
    infra: Infra;
    user?: User;
    immediatlyUserFavoritesInfra?: UserFavoritesInfra;
    setImmediatlyUserFavoritesInfra: (immediatlyUserFavoritesInfra?: UserFavoritesInfra) => void;
    setReservationPopupOpenable: (openable: boolean) => void;
}
function SportInfraDetailComponent(props: SportInfraDetailComponentProps) {
    let history = useHistory();
    const { immediatlyUserFavoritesInfra, favoritesRegisteEventHandler, favoritesDeleteEventHandler } = useInfraFavoritesHook(
        props.user,
        props.infra,
    );

    const [tabSeq, setTabSeq] = useState<string>('tab1');
    const tabs: any = {
        tab1: <SportInfraDetailInfoTab infra={props.infra}></SportInfraDetailInfoTab>,
        tab2: <SportInfraFeeTab infra={props.infra}></SportInfraFeeTab>,
        tab3: <CommonInfraLocationTab infra={props.infra}></CommonInfraLocationTab>,
        tab4: <CommonInfraEventTab infra={props.infra}></CommonInfraEventTab>,
        tab5: <CommonInfraPrVideoTab infra={props.infra}></CommonInfraPrVideoTab>,
    };
    const goBack = () => {
        history?.goBack();
    };
    return (
        <>
            <div className="room-area room-area-new flex" style={{ marginBottom: '15px' }}>
                <div className="flex" style={{ width: '51vw' }}>
                    <p>
                        {props.infra.name}
                        <button className="reservation_button" onClick={() => props.setReservationPopupOpenable(true)}></button>
                        {props.user && !immediatlyUserFavoritesInfra ? (
                            <button className={`favorites_button`} onClick={favoritesRegisteEventHandler}></button>
                        ) : (
                            <></>
                        )}
                        {props.user && immediatlyUserFavoritesInfra ? (
                            <button className={`favorites_button change_color`} onClick={favoritesDeleteEventHandler}></button>
                        ) : (
                            <></>
                        )}
                    </p>
                    &nbsp;&nbsp;&nbsp;
                    <p>
                        {props.infra.infraCategory?.parentInfraCategory.name} &gt; {props.infra.infraCategory?.name}
                    </p>
                </div>
                <button
                    className="bg-white"
                    onClick={goBack}
                    style={{
                        padding: '10px 12px',
                        float: 'right',
                        border: '1px solid #b8b8b8',
                        borderRadius: '5px',
                        background: '#2d4fc1',
                        color: '#fff',
                    }}
                >
                    <i className="fas fa-search">목록</i>
                </button>
            </div>
            <div style={{ margin: '15px 0px' }}>
                {props.infra.attachFiles ? <ImgSlider attachFiles={props.infra.attachFiles}></ImgSlider> : <></>}
            </div>

            <ul className="tabs sports">
                <li className={tabSeq == 'tab1' ? 'active' : ''} onClick={() => setTabSeq('tab1')}>
                    <a>시설정보</a>
                </li>
                <li className={tabSeq == 'tab2' ? 'active' : ''} onClick={() => setTabSeq('tab2')}>
                    <a>요금</a>
                </li>
                <li className={tabSeq == 'tab3' ? 'active' : ''} onClick={() => setTabSeq('tab3')}>
                    <a>위치</a>
                </li>
                <li className={tabSeq == 'tab4' ? 'active' : ''} onClick={() => setTabSeq('tab4')}>
                    <a>행사정보</a>
                </li>
                <li className={tabSeq == 'tab5' ? 'active' : ''} onClick={() => setTabSeq('tab5')}>
                    <a>홍보영상</a>
                </li>
            </ul>
            <div className="tab_container">
                <div className="tab_content">{tabs[tabSeq]}</div>
            </div>
        </>
    );
}

export default SportInfraDetailComponent;
