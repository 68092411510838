import APIServerConfig from '../../../../config/APIServerConfig';
import AbstractService from '../../../../service/AbstractService';
import RehabCenterMeasureByGender from '../../domain/statistics/RehabCenterMeasureByGender';
import RehabCenterMeasureBySport from '../../domain/statistics/RehabCenterMeasureBySport';
import RehabCenterQnaRation from '../../domain/statistics/RehabCenterQnaRation';
import RehabCenterStatisticsService from '../RehabCenterStatisticsService';
import RehabCenterMeasureByYear from '../../domain/statistics/RehabCenterMeasureByYear';
import RehabCenterMeasureByMonthOfYear from '../../domain/statistics/RehabCenterMeasureByMonthOfYear';
import RehabCenterMeasureByQuarter from '../../domain/statistics/RehabCenterMeasureByQuarter';

class RehabCenterStatisticsServiceImpl extends AbstractService implements RehabCenterStatisticsService {
    getMeasureByGender(): Promise<RehabCenterMeasureByGender[]> {
        return new Promise((resolve, reject) => {
            APIServerConfig.recordAPIAxios.get(`/rehab-center-statistic/measure-by-gender`)
            .then(response => {
                let rehabCenterMeasureByGenders: RehabCenterMeasureByGender[] = 
                    this.jsonConvert.deserializeArray(response.data, RehabCenterMeasureByGender);
                resolve(rehabCenterMeasureByGenders);
            });
        });
    }

    getMeasureBySport(): Promise<RehabCenterMeasureBySport[]> {
        return new Promise((resolve, reject) => {
            APIServerConfig.recordAPIAxios.get(`/rehab-center-statistic/measure-by-sport`)
            .then(response => {
                let rehabCenterMeasureBySports: RehabCenterMeasureBySport[] = this.jsonConvert.deserializeArray(response.data, RehabCenterMeasureBySport);
                resolve(rehabCenterMeasureBySports);
            });
        });
    }
    
    getMeasureByQna(): Promise<RehabCenterQnaRation> {
        return new Promise((resolve, reject) => {
            APIServerConfig.recordAPIAxios.get(`/rehab-center-statistic/measure-by-qna`)
            .then(response => {
                let rehabCenterQnaRation: RehabCenterQnaRation = this.jsonConvert.deserializeObject(response.data, RehabCenterQnaRation);
                resolve(rehabCenterQnaRation);
            });
        });
    }

    getMeasureByYear(): Promise<RehabCenterMeasureByYear[]> {
        return new Promise((resolve, reject) => {
            APIServerConfig.recordAPIAxios.get(`/rehab-center-statistic/measure-by-year`)
            .then((response) => {
                let rehabCenterMeasureByYears: RehabCenterMeasureByYear[] = this.jsonConvert.deserializeArray(response.data, RehabCenterMeasureByYear);
                resolve(rehabCenterMeasureByYears);
            });
        });
    }

    getMeasureByMonthOfYear(): Promise<RehabCenterMeasureByMonthOfYear[]> {
        return new Promise((resolve, reject) => {
            APIServerConfig.recordAPIAxios.get(`/rehab-center-statistic/measure-by-month-of-year`)
            .then((response) => {
                let rehabCenterMeasureByYears: RehabCenterMeasureByMonthOfYear[] = this.jsonConvert.deserializeArray(response.data, RehabCenterMeasureByMonthOfYear);
                resolve(rehabCenterMeasureByYears);
            });
        });
    }

    getMeasureByQuarterOfYear(): Promise<RehabCenterMeasureByQuarter[]> {
        return new Promise((resolve, reject) => {
            APIServerConfig.recordAPIAxios.get(`/rehab-center-statistic/measure-by-quarter-of-year`)
            .then((response) => {
                let rehabCenterMeasureByYears: RehabCenterMeasureByQuarter[] = this.jsonConvert.deserializeArray(response.data, RehabCenterMeasureByQuarter);
                resolve(rehabCenterMeasureByYears);
            });
        });
    }
}

export default RehabCenterStatisticsServiceImpl;