import React, { useEffect, useState } from 'react';
import Code from '../../../../domain/code/Code';
import DateUtils from '../../../../utils/DateUtils';
import TotalVisitedTeamInfoSearchCondition from '../../../../domain/visitedInfo/statistics/TotalVisitedTeamInfoSearchCondition';
import { defaultParentRegionCodeId, serviceStartYear } from '../../../../config/Constant';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ko } from 'date-fns/esm/locale';

interface AdminVisitedTeamInfoFilterProps {
    regionCodes?: Code[];
    childRegionCodes?: Code[];
    belongCodes?: Code[];
    classificationCodes?: Code[];
    sportCodes?: Code[];

    setSearchCondition: (condition: TotalVisitedTeamInfoSearchCondition) => void;
}

function AdminVisitedTeamInfoFilter(props: AdminVisitedTeamInfoFilterProps) {
    const [parentRegionCodeIds, setParentRegionCodeIds] = useState<string>(String(defaultParentRegionCodeId));
    const [regionCodeIds, setRegionCodeIds] = useState<string>();
    const [belongCodeIds, setBelongCodeIds] = useState<string>();
    const [classificationCodeIds, setClassificationCodeIds] = useState<string>();
    const [sportCodeIds, setSportCodeIds] = useState<string>();
    const [visitedStartDate, setVisitedStartDate] = useState<any>();
    const [visitedEndDate, setVisitedEndDate] = useState<any>();

    const keyEventHandler = (e: any) => {
        if (e.key == 'Enter') {
            retrieveBtnHandler();
        }
    };
    const retrieveBtnHandler = () => {
        const condition: TotalVisitedTeamInfoSearchCondition = new TotalVisitedTeamInfoSearchCondition();

        condition.visitedStartDate = visitedStartDate ? DateUtils.getDateForm(visitedStartDate) : '';
        condition.visitedEndDate = visitedEndDate ? DateUtils.getDateForm(visitedEndDate) : '';
        condition.regionCodeIds = regionCodeIds;
        condition.belongCodeIds = belongCodeIds;
        condition.classficationCodeIds = classificationCodeIds;
        condition.sportCodeIds = sportCodeIds;

        props.setSearchCondition(condition);
    };

    useEffect(() => {
        retrieveBtnHandler();
    }, []);

    return (
        <>
            <div className="flex-basic" style={{ marginBottom: '10px' }}>
                <div className="member-join">
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    {/* 방문지역선택 */}
                                    <select
                                        onChange={(event) => {
                                            var regionCodeIds = event.currentTarget.value;

                                            if (regionCodeIds == '0') {
                                                setRegionCodeIds(undefined);
                                            } else {
                                                setRegionCodeIds(regionCodeIds);
                                            }
                                            document.getElementById('keyHandler')?.focus();
                                        }}
                                    >
                                        <option value="0">방문 지역 선택</option>
                                        {props.childRegionCodes?.map((regionCode: Code, index: number) => {
                                            return (
                                                <option key={index} value={regionCode.codeId}>
                                                    {regionCode.name}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </td>
                                <td>
                                    {/* 팀 분류그룹 선택 */}
                                    <select
                                        value={belongCodeIds}
                                        onChange={(event) => {
                                            var belongCodeIds = event.currentTarget.value;

                                            if (belongCodeIds == '0') {
                                                setBelongCodeIds(undefined);
                                            } else {
                                                setBelongCodeIds(belongCodeIds);
                                            }
                                            document.getElementById('keyHandler')?.focus();
                                        }}
                                    >
                                        <option value="0">팀 분류그룹 선택</option>
                                        {props.belongCodes?.map((belongCode: Code, index: number) => {
                                            return (
                                                <option key={index} value={belongCode.codeId}>
                                                    {belongCode.name}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </td>
                                <td>
                                    {/* 팀 분류 선택 */}
                                    <select
                                        value={classificationCodeIds}
                                        onChange={(event) => {
                                            var classificationCodeIds = event.currentTarget.value;

                                            if (classificationCodeIds == '0') {
                                                setClassificationCodeIds(undefined);
                                            } else {
                                                setClassificationCodeIds(classificationCodeIds);
                                            }
                                            document.getElementById('keyHandler')?.focus();
                                        }}
                                    >
                                        <option value="0">팀 분류 선택</option>
                                        {props.classificationCodes?.map((classificationCode: Code, index: number) => {
                                            return (
                                                <option key={index} value={classificationCode.codeId}>
                                                    {classificationCode.name}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </td>
                                <td>
                                    {/* 팀 종목 선택 */}
                                    <select
                                        value={sportCodeIds}
                                        onChange={(event) => {
                                            var sportCodeIds = event.currentTarget.value;

                                            if (sportCodeIds == '0') {
                                                setSportCodeIds(undefined);
                                            } else {
                                                setSportCodeIds(sportCodeIds);
                                            }
                                            document.getElementById('keyHandler')?.focus();
                                        }}
                                    >
                                        <option value="0">팀 종목 선택</option>
                                        {props.sportCodes?.map((sportCode: Code, index: number) => {
                                            return (
                                                <option key={index} value={sportCode.codeId}>
                                                    {sportCode.name}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={4}>
                                    <table style={{ margin: '5px' }}>
                                        <colgroup>
                                            <col style={{ width: '10%' }} />
                                            <col style={{ width: '30%' }} />
                                            <col style={{ width: '4%' }} />
                                            <col style={{ width: '10%' }} />
                                            <col style={{ width: '*' }} />
                                        </colgroup>
                                        <tbody>
                                            <tr>
                                                <td style={{ fontWeight: 500, fontSize: '15px' }}>시작일:</td>
                                                <td>
                                                    <DatePicker
                                                        locale={ko}
                                                        dateFormat="yyyy-MM-dd"
                                                        closeOnScroll={true}
                                                        selected={visitedStartDate}
                                                        onChange={(date) => {
                                                            setVisitedStartDate(date);
                                                            document.getElementById('keyHandler')?.focus();
                                                        }}
                                                    />
                                                </td>
                                                <td style={{ fontWeight: 900, fontSize: '15px' }}>~</td>
                                                <td style={{ fontWeight: 500, fontSize: '15px' }}>종료일:</td>
                                                <td>
                                                    <DatePicker
                                                        locale={ko}
                                                        dateFormat="yyyy-MM-dd"
                                                        closeOnScroll={true}
                                                        selected={visitedEndDate}
                                                        onChange={(date) => {
                                                            setVisitedEndDate(date);
                                                            document.getElementById('keyHandler')?.focus();
                                                        }}
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="member-join">
                <button className="bg-blue white" style={{ fontSize: '15px', fontWeight: 700 }} onClick={retrieveBtnHandler}>
                    <i className="fas fa-search"></i>조 회
                </button>
                <input id="keyHandler" value="" onKeyPress={keyEventHandler} style={{ width: '0px', border: '0px' }} />
            </div>
        </>
    );
}

export default AdminVisitedTeamInfoFilter;
