import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import AdminMainTitle from '../../../../component/admin/AdminMainTitle';
import useMemberPagingInfos from '../../hook/useMemberPagingInfos';
import UserSearchCondition from '../../../../domain/user/param/UserSearchCondition';
import RoleGroupType from '../../../../domain/user/RoleGroupType';
import AdminManagersTable from '../../../../component/admin/AdminManagersTable';
import AdminManagerInfoFilter from '../../component/filter/AdminManagerInfoFilter';
import useUserRetrieveBySearchCondition from '../../../../hook/user/useUserRetrieveBySearchCondition';
import AdminRetrieveItemPaging from '../../component/AdminRetrieveItemPaging';
import { Link } from 'react-router-dom';

function AdminManagerListContainer() {
    const location = useLocation();
    const history = useHistory();

    let condition: UserSearchCondition | any = location.state ? location.state : new UserSearchCondition();
    //const initUserSearchCondition = new UserSearchCondition();

    const { page, setPage, pagingSize, setPagingSize, pagingCnt, paging, currentPageGroupIdx, setCurrentPageGroupIdx } = useMemberPagingInfos(
        condition,
    );
    //const { page, setPage, pagingSize, setPagingSize, pagingCnt, paging, currentPageGroupIdx, setCurrentPageGroupIdx } = useAdminPaging();

    condition.roleGroupNames = condition.roleGroupNames
        ? condition.roleGroupNames
        : `${RoleGroupType.SYSTEMS},${RoleGroupType.MUNICIPALITY},${RoleGroupType.CENTER}`;
    const [userSearchCondition, setUserSearchCondition] = useState(condition);
    const { users, usersCnt } = useUserRetrieveBySearchCondition(userSearchCondition, paging);

    // useEffect(() => {
    //     setCurrentPageGroupIdx(0);
    //     setPage(0);
    // }, [userSearchCondition]);

    const goRegistryHandler = () => {
        history?.push({
            pathname: '/admin/manager/registry',
            state: condition,
        });
    };
    let title = '운영자 목록 < ' + usersCnt + '명 >';

    return (
        <>
            <AdminMainTitle title={title}>
                <button className="bg-light-blue round" onClick={goRegistryHandler}>
                    운영자 추가하기
                </button>

                <AdminManagerInfoFilter
                    userSearchCondition={userSearchCondition}
                    setUserSearchCondition={setUserSearchCondition}
                ></AdminManagerInfoFilter>
            </AdminMainTitle>
            <AdminManagersTable
                page={page}
                pagingCnt={pagingCnt}
                pagingSize={pagingSize}
                users={users}
                searchCondition={userSearchCondition}
            ></AdminManagersTable>
            <AdminRetrieveItemPaging
                totalCnt={usersCnt}
                pagingCnt={pagingCnt}
                pagingSize={pagingSize}
                setPage={setPage}
                currentPageNum={page}
                setPagingSize={setPagingSize}
                currentPageGroupIdx={currentPageGroupIdx}
            ></AdminRetrieveItemPaging>
        </>
    );
}

export default AdminManagerListContainer;
