import React from 'react';
import { useSelector } from 'react-redux';
import { Route, RouteProps, Redirect } from 'react-router-dom';
import { RootState } from '../moldules/index';
import Team from '../domain/team/Team';
import User from '../domain/user/User';

interface SystemPrivateRouteProps extends RouteProps {
    component: any;
}

const accessCheck = (user: User): boolean => {
    if (user) {
        if (user.checkSystemAdmin()) return true;
        else if (user.checkMunicipalityOper()) return true;
    }

    return false;
};

const SystemPrivateRoute = (props: SystemPrivateRouteProps) => {
    const { component: Component, ...rest } = props;
    const { user } = useSelector((state: RootState) => state.userSessionReducer);

    return (
        <Route
            {...rest}
            render={(props) =>
                // 사용자 로그인 상태이면서 접근하려는 페이지의 팀 번호가 사용자 팀과 번호가 같다면 진입
                accessCheck(user) ? (
                    <Component {...rest} {...props} />
                ) : (
                    <Redirect
                        to={{
                            pathname: '/login',
                            state: { from: props.location },
                        }}
                    />
                )
            }
        />
    );
};

export default SystemPrivateRoute;
