import { useEffect, useState } from 'react';
import VisitedTeamInfo from '../../domain/visitedInfo/VisitedTeamInfo';
import VisitedTeamInfoServiceImpl from '../../service/visitedInfo/impl/VisitedTeamInfoServiceImpl';
import VisitedTeamInfoSearchCondition from '../../domain/visitedInfo/pagam/VisitedTeamInfoSearchCondition';
import Paging from '../../domain/common/Paging';
const visitedTeamInfoService = new VisitedTeamInfoServiceImpl();

function useGroupingVisitedTeamInfoRetrieve(searchCondition: VisitedTeamInfoSearchCondition, paging: Paging) {
    const [visitedTeamInfos, setVisitedTeamInfos] = useState<VisitedTeamInfo[]>();
    const [visitedTeamInfosCnt, setVisitedTeamInfosCnt] = useState<number>(0);

    useEffect(() => {
        visitedTeamInfoService.getGroupingVisitedTeamInfos(searchCondition, paging).then((visitedTeamInfos: VisitedTeamInfo[]) => {
            setVisitedTeamInfos(visitedTeamInfos);
        });

        visitedTeamInfoService.getGroupingVisitedTeamInfosCnt(searchCondition).then((visitedTeamInfosCnt: number) => {
            setVisitedTeamInfosCnt(visitedTeamInfosCnt);
        });
    }, [searchCondition, paging]);

    return { visitedTeamInfos, visitedTeamInfosCnt };
}

export default useGroupingVisitedTeamInfoRetrieve;
