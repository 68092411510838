import React, { useEffect, useState } from 'react';
import CalendarComponent from '../../../component/calendar/CalendarComponent';
import Reservation from '../../../domain/reservation/Reservation';

interface DateChoiceProps {
    scheduleStartDay?: string;
    scheduleEndDay?: string;
    setScheduleStartDay: (scheduleStartDay: string) => void;
    setScheduleEndDay: (scheduleEndDay: string) => void;
}

function DateChoice(props: DateChoiceProps) {
    // const [scheduleStartDay, setScheduleStartDay] = useState<string>('선택하세요.');
    // const [scheduleEndDay, setScheduleEndDay] = useState<string>('선택하세요.');
    const [scheduleStartDayVisible, setScheduleStartDayVisible] = useState<boolean>(false);
    const [scheduleEndDayVisible, setScheduleEndDayVisible] = useState<boolean>(false);

    // useEffect(() => {
    //     props.setScheduleStartDay(scheduleStartDay);
    // }, [scheduleStartDay]);

    // useEffect(() => {
    //     props.setScheduleEndDay(scheduleEndDay);
    // }, [scheduleEndDay])

    return (
        <div className="menu-item">
            <div className="notice-open">
                <h5>일정 날짜 선택</h5>
                <div id="visit-date">
                    <div className="popup">
                        <div className="visit-strat">
                            <div id="myPopup"  
                                    onClick={() => {
                                        setScheduleStartDayVisible(!scheduleStartDayVisible); 
                                        setScheduleEndDayVisible(false);
                                    }}
                            >일정 시작일
                                <p>{props.scheduleStartDay}</p>
                            </div>
                            <div className={`popuptext ${scheduleStartDayVisible ? 'show' : ''}`}>
                                <CalendarComponent onDateChange={props.setScheduleStartDay}></CalendarComponent>
                            </div>
                        </div>
                        <div className="visit-end">
                            <div onClick={() => {
                                setScheduleStartDayVisible(false);
                                setScheduleEndDayVisible(!scheduleEndDayVisible);
                            }}>
                                일정 종료일
                                <p>{props.scheduleEndDay}</p>
                            </div>
                            <div id="myPopup2" className={`popuptext ${scheduleEndDayVisible ? 'show' : ''}`}>
                                <CalendarComponent onDateChange={props.setScheduleEndDay}></CalendarComponent>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )    
}

export default DateChoice;