import { useEffect, useState } from 'react';

function usePagingHook(totalCnt: number, pagingSize: number, pagingCnt: number, setPage: (pageNum: number) => void, pCurrentPageGroupIdx?: number) {
    const [currentPageGroupIdx, setCurrentPageGroupIdx] = useState<number>(pCurrentPageGroupIdx ? pCurrentPageGroupIdx : 0);
    const [pageCnt, setPageCnt] = useState<number>(0);
    const [pageGroupCnt, setPageGroupCnt] = useState<number>(0);
    const [pageGroupArr, setPageGroupArr] = useState(new Array<number>());

    useEffect(() => {
        const pageCnt = Math.ceil(totalCnt / pagingSize); // 7
        const pageGroupCnt = Math.ceil(pageCnt / pagingCnt); // 2
        const pageGroupArr = new Array<number>();

        for (var i = 0; i < pagingCnt; i++) {
            pageGroupArr.push(i);
        }

        setPageGroupArr(pageGroupArr);
        setPageGroupCnt(pageGroupCnt);
        setPageCnt(pageCnt);

        if (pCurrentPageGroupIdx !== undefined) {
            //setPage(0);
            //setCurrentPageGroupIdx(0);
        }
    }, [totalCnt, pagingSize, pCurrentPageGroupIdx]);

    const onPrevEventHandler = (pageGroupIdx: number) => {
        setCurrentPageGroupIdx(pageGroupIdx - 1);
        const prevPageNum = pagingCnt * pageGroupIdx - 1;
        setPage(prevPageNum);
    };

    const onNextEventHandler = (pageGroupIdx: number) => {
        setCurrentPageGroupIdx(pageGroupIdx + 1);
        const nextPageNum = pagingCnt * (pageGroupIdx + 1);
        setPage(nextPageNum);
    };

    return {
        currentPageGroupIdx,
        setCurrentPageGroupIdx,
        pageCnt,
        setPageCnt,
        pageGroupCnt,
        setPageGroupCnt,
        pageGroupArr,
        setPageGroupArr,
        onPrevEventHandler,
        onNextEventHandler,
    };
}

export default usePagingHook;
