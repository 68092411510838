import React from 'react';
import Prescription from '../../../../../domain/Prescription';
import { JsonConvert } from 'json2typescript';
import ConditioningPrescription from '../../../../../domain/prescription/ConditioningPrescription';

interface IProps {
    prescription: Prescription;
}

const jsonConvert = new JsonConvert();

const ConditioningFormComponent = (props: IProps) => {
    const handler = (e: any) => {};
    let conditioning: ConditioningPrescription = props.prescription.conditioningPrescription;

    return (
        <>
            <div className="flex-basic">
                <div className="center-half">
                    <h2 className="center">ICE</h2>
                    <div className="center-bg-gray flex">
                        <div className="center-bg-blue">1회차</div>
                        <input
                            className="center-input-gray center-w50"
                            type="text"
                            name="conditioningPrescription"
                            id="icing_0"
                            onChange={handler}
                            value={conditioning.icing[0]}
                        />
                        <label className="center-label">분</label>
                    </div>
                </div>
                <div className="center-half">
                    <h2 className="center">Massage</h2>
                    <div className="center-bg-gray flex">
                        <div className="center-bg-blue">1회차</div>
                        <input
                            className="center-input-gray center-w50"
                            type="text"
                            name="conditioningPrescription"
                            id="massage_0"
                            onChange={handler}
                            value={conditioning.massage[0]}
                        />
                        <label className="center-label">분</label>
                    </div>
                </div>
            </div>

            <div className="flex-basic">
                <div className="center-half">
                    <h2 className="center">Sling</h2>
                    <div className="center-bg-gray flex">
                        <div className="center-bg-blue">1회차</div>
                        <input
                            className="center-input-gray center-w50"
                            type="text"
                            name="conditioningPrescription"
                            id="sling_0"
                            onChange={handler}
                            value={conditioning.sling[0]}
                        />
                        <label className="center-label">분</label>
                    </div>
                </div>
                <div className="center-half">
                    <h2 className="center">Pain Doctor</h2>
                    <div className="center-bg-gray flex">
                        <div className="center-bg-blue">1회차</div>
                        <input
                            className="center-input-gray center-w50"
                            type="text"
                            name="conditioningPrescription"
                            id="painDoctor_0"
                            onChange={handler}
                            value={conditioning.painDoctor[0]}
                        />
                        <label className="center-label">분</label>
                    </div>
                </div>
            </div>
            <div className="flex-basic">
                <div className="center-half">
                    <h2 className="center">Radio Frequency</h2>
                    <div className="center-bg-gray flex">
                        <div className="center-bg-blue">1회차</div>
                        <input
                            className="center-input-gray center-w50"
                            type="text"
                            name="conditioningPrescription"
                            id="radioFrq_0"
                            onChange={handler}
                            value={conditioning.radioFrq[0]}
                        />
                        <label className="center-label">분</label>
                    </div>
                </div>
                <div className="center-half">
                    <h2 className="center">ESWT</h2>
                    <div className="center-bg-gray flex">
                        <div className="center-bg-blue">1회차</div>
                        <input
                            className="center-input-gray center-w50"
                            type="text"
                            name="conditioningPrescription"
                            id="eswt_0"
                            onChange={handler}
                            value={conditioning.eswt[0]}
                        />
                        <label className="center-label">분</label>
                    </div>
                </div>
            </div>
            <div className="flex-basic">
                <div className="center-half">
                    <h2 className="center">Vibro</h2>
                    <div className="center-bg-gray flex">
                        <div className="center-bg-blue">1회차</div>
                        <input
                            className="center-input-gray center-w50"
                            type="text"
                            name="conditioningPrescription"
                            id="vibro_0"
                            onChange={handler}
                            value={conditioning.vibro[0]}
                        />
                        <label className="center-label">분</label>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ConditioningFormComponent;
