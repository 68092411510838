import React from 'react';
import { ACCESS_TOKEN } from '../../config/Constant';
import { Redirect } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { registeUserSessionAction } from '../../moldules/UserSession';

interface OAuth2HandlerComponentProps {
    location: any;
}

function getUrlParameter(name: any, location: any) {
	name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
	var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');

	var results = regex.exec(location.search);
	return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

function OAuth2HandlerComponent(props: OAuth2HandlerComponentProps) {
	const dispatch = useDispatch();
	const token = getUrlParameter('token', props.location);
	const error = getUrlParameter('error', props.location);
	const userState = getUrlParameter('userState', props.location);
	if(userState == 'signupNotApprove') {
		return (
			<Redirect
				to={{
					pathname: '/login',
					state: { from: props.location, message: '관리자의 승인 후 사용 가능합니다.' },
				}}
			/>
		)
	} else if (token) {
		localStorage.setItem(ACCESS_TOKEN, token);
		dispatch(registeUserSessionAction())
		return (
			<Redirect
				to={{
					pathname: '/',
					state: { from: props.location },
				}}
			/>
		);
	} else {
		console.log("go sns signup");
		const provider = getUrlParameter('provider', props.location);
		const providerId = getUrlParameter('providerId', props.location);
		const email = getUrlParameter('email', props.location);
		const userName = getUrlParameter('userName', props.location);
		const imageUrl = getUrlParameter('imageUrl', props.location);

		return (
			<Redirect
				to={{
					pathname: '/signup/sns',
					state: {
						from: props.location,
						provider: provider,
						providerId: providerId,
						email: email,
						userName: userName,
						error: error,
						imageUrl: imageUrl
					},
				}}
			/>
		);
	}
}


export default OAuth2HandlerComponent;