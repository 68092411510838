import AttachFile from '../file/AttachFile';
import { JsonObject, JsonProperty } from 'json2typescript';
import Code from '../code/Code';
import Charge from '../chage/Charge';
import InfraCategory from './InfraCategory';
import UUIDUtils from '../../utils/UUIDUtils';
import UserLocation from '../user/UserLocation';
import ReservationTime from '../reservation/ReservationTime';
import { INIT_LATITUDE, INIT_LONGITUDE } from '../../config/Constant';
import { InfraCategoryType } from './InfraCategoryType';
@JsonObject('infra')
export default class Infra {
    @JsonProperty('infraNo')
    infraNo?: string;
    @JsonProperty('infraCategoryNo')
    infraCategoryNo?: string;
    @JsonProperty('infraCategory')
    infraCategory?: InfraCategory;
    @JsonProperty('parentInfraNo')
    parentInfraNo?: string;
    @JsonProperty('sportCodeId')
    sportCodeId?: number;
    @JsonProperty('childInfras')
    childInfras?: Array<Infra>;
    @JsonProperty('attachFiles')
    attachFiles?: Array<AttachFile>;
    @JsonProperty('name')
    name?: string;
    @JsonProperty('phoneNumber')
    phoneNumber?: string;
    @JsonProperty('address')
    address?: string;
    @JsonProperty('homepageUrl')
    homepageUrl?: string;
    @JsonProperty('facilityDescription')
    facilityDescription?: string;
    @JsonProperty('equipmentDescription')
    equipmentDescription?: string;
    @JsonProperty('etcDescription')
    etcDescription?: string;
    @JsonProperty('useRuleDescription')
    useRuleDescription?: string;
    @JsonProperty('refundPolicyDescription')
    refundPolicyDescription?: string;
    @JsonProperty('latitude')
    latitude?: number;
    @JsonProperty('longitude')
    longitude?: number;
    @JsonProperty('reservationCnt')
    reservationCnt?: string;
    @JsonProperty('reservationTimeUnit')
    reservationTimeUnit?: number;
    @JsonProperty('reservationStartTime')
    reservationStartTime?: string;
    @JsonProperty('reservationEndTime')
    reservationEndTime?: string;
    @JsonProperty('sportCode')
    sportCode?: Code;
    @JsonProperty('regionCodeId')
    regionCodeId?: number;
    @JsonProperty('regionCode')
    regionCode?: Code;
    @JsonProperty('charges')
    charges?: Charge[];
    @JsonProperty('recommandation')
    recommandation?: Boolean;
    @JsonProperty('registeDate')
    registeDate?: string;
    @JsonProperty('modifyDate')
    modifyDate?: string;
    @JsonProperty('eventCnt')
    eventCnt?: number;
    @JsonProperty('firstPrVideoUrl')
    firstPrVideoUrl?: string;
    @JsonProperty('secondPrVideoUrl')
    secondPrVideoUrl?: string;
    @JsonProperty('thirdPrVideoUrl')
    thirdPrVideoUrl?: string;

    getDescription(description: string | undefined) {
        if (description == null || description === undefined) return '내용없음';
        else return description;
    }

    getMainAttachFile() {
        if (this.attachFiles) {
            if (this.attachFiles.length == 0) {
                return '/assets/image/list-1.png';
            } else {
                return `${process.env.REACT_APP_RESOURCE_API_HOST}/static-file${this.attachFiles[0].saveFilePath}`;
            }
        } else {
            return '/assets/image/list-1.png';
        }
    }

    getMainAttachFileReturnUndfeined() {
        if (this.attachFiles) {
            if (this.attachFiles.length == 0) {
                // return '/assets/image/list-1.png';
            } else {
                // return `${process.env.REACT_APP_RESOURCE_API_HOST}/static-file${this.attachFiles[0].saveFilePath}`;
                return `${process.env.REACT_APP_RESOURCE_API_HOST}/static-file${this.attachFiles[0].saveFilePath}`;
            }
        } else {
            // return '/assets/image/list-1.png';
        }
    }

    getReservationTimeTable(): string[] {
        let timeTable: string[] = [];

        let startHour = Number(this.reservationStartTime?.split(':')[0]);
        let startMin = this.reservationStartTime?.split(':')[1];
        let endHour = Number(this.reservationEndTime?.split(':')[0]);

        if (this.reservationTimeUnit) {
            for (var hour = startHour; hour < endHour; hour += this.reservationTimeUnit) {
                const timeTableStartHour = hour < 10 ? `0${hour}` : hour;
                let timeTableEndHour = hour + this.reservationTimeUnit;
                if (endHour < timeTableEndHour) timeTableEndHour = endHour;
                const timeTableStartTime = timeTableStartHour + ':' + startMin;
                const timeTableEndTime = timeTableEndHour + ':' + startMin;

                timeTable.push(timeTableStartTime + '~' + timeTableEndTime);
            }
        } else {
            throw 'undefined reservationTimeUnit';
        }

        return timeTable;
    }

    getReservationTimeTableInstance(): ReservationTime[] {
        let reservationTimes: ReservationTime[] = new Array();

        let startHour = Number(this.reservationStartTime?.split(':')[0]);
        let startMin = this.reservationStartTime?.split(':')[1];
        let endHour = Number(this.reservationEndTime?.split(':')[0]);

        if (this.reservationTimeUnit) {
            for (var hour = startHour; hour < endHour; hour += this.reservationTimeUnit) {
                const timeTableStartHour = hour < 10 ? `0${hour}` : hour;
                let timeTableEndHour = hour + this.reservationTimeUnit;
                if (endHour < timeTableEndHour) timeTableEndHour = endHour;
                const timeTableStartTime = timeTableStartHour + ':' + startMin;
                const timeTableEndTime = timeTableEndHour + ':' + startMin;

                const reservationTime = new ReservationTime(timeTableStartTime, timeTableEndTime);
                reservationTimes.push(reservationTime);
            }
        } else {
            throw 'undefined reservationTimeUnit';
        }

        return reservationTimes;
    }

    static makeChildInfraInfo(parentInfra?: Infra, infraName?: string, reservationTimeUnit?: number): Infra {
        if (parentInfra && infraName && reservationTimeUnit) {
            const newChildInfra: Infra = new Infra();
            newChildInfra.parentInfraNo = parentInfra.infraNo;
            newChildInfra.infraNo = UUIDUtils.getUUID();
            newChildInfra.name = infraName;
            newChildInfra.infraCategoryNo = parentInfra.infraCategoryNo;
            newChildInfra.reservationTimeUnit = reservationTimeUnit;
            newChildInfra.sportCodeId = parentInfra.sportCodeId;
            newChildInfra.regionCodeId = parentInfra.regionCodeId;
            newChildInfra.recommandation = parentInfra.recommandation;
            return newChildInfra;
        } else {
            throw '정보 부족입니다.';
        }
    }

    getLocation(userLocation: UserLocation) {}

    getLatitude(): number {
        return this.latitude ? this.latitude : INIT_LATITUDE;
    }

    getLongitude(): number {
        return this.longitude ? this.longitude : INIT_LONGITUDE;
    }

    getDetailLink(): string {
        if (this.infraCategory?.parentInfraCategoryNo == InfraCategoryType.SPORT_INFRA_NO) {
            return `/infra/sport/${this.infraNo}`;
        } else {
            return `/infra/sub/${this.infraNo}`;
        }
    }
}
