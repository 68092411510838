import useBoardInfoOfTeam from '../../../../hook/board/useBoardInfoOfTeam';
import useReservationOfTeam from '../../../../hook/useReservationOfTeam';
import useTeamInfoHook from '../../../../hook/useTeamInfoHook';
import { RouteComponentProps } from 'react-router-dom';
import AttachFile from '../../../../domain/file/AttachFile';
import Reservation from '../../../../domain/reservation/Reservation';
import SiteDepth from '../../../../component/nav/SiteDepth';
import React, { useState } from 'react';
import TrainningSubHeader from '../../layout/TrainningSubHeader';
import ImgSlider from '../../../../component/img/ImgSlider';
import Infra from '../../../../domain/infra/Infra';
import BoardCard from '../../../../component/board/BoardCard';
import InfraCard from '../../../../component/infra/InfraCard';
import ImgFeedBoardContentCard from '../../../../component/board/ImgFeedBoardContentCard';
import LeftSearcherMiniSearchBar from '../../../../component/search/filter/LeftSearcherMiniSearchBar';
import InfraRecommandContainer from '../../../../container/infra/InfraRecommandContainer';
import { InfraCategoryType } from '../../../../domain/infra/InfraCategoryType';
import useTeamStoryContainerHook from './hook/useTeamStoryContainerHook';
import TestAuth from './TestAuth';
import SearchLeft from '../../../../component/layout/SearchLeft';
import SearchIn from '../../../../component/layout/SearchIn';
import MiniSearchBar from '../../../../component/search/MiniSearchBar';
import TeamStoryHomeTab from './teamStoryHook/TeamStoryHomTab';
import TeamStoryScheduleTab from './teamStoryHook/TeamStoryScheduleTab';
import TeamStoryBoardTab from './teamStoryHook/TeamStoryBoardTab';
import TeamStorySchedulesTab from './teamStoryHook/TeamStorySchedulesTab';
import TeamStoryPictcureTab from './teamStoryHook/TeamStoryPictureTab';

function getTrainningInfra(): Infra[] {
    let infra = new Infra();
    infra.name = '창원';
    return [infra];
}

interface TeamStoryContainerProps {
    teamNo: string;
}

const TeamStoryContainer = ({ match }: RouteComponentProps<TeamStoryContainerProps>) => {
    let teamNo = match.params.teamNo;

    const [tabSeq, setTabSeq] = useState<string>('tab1');
    const { team } = useTeamInfoHook(teamNo);
    // const { reservations } = useReservationOfTeam(teamNo);
    const { boardsOfTeam } = useBoardInfoOfTeam(teamNo);

    const tabs: any = {
        tab1: <TeamStoryHomeTab teamNo={teamNo} boardOfTeam={boardsOfTeam}></TeamStoryHomeTab>,
        tab2: <TeamStorySchedulesTab teamNo={teamNo}></TeamStorySchedulesTab>,
        tab3: <TeamStoryBoardTab teamNo={teamNo} boardOfTeam={boardsOfTeam}></TeamStoryBoardTab>,
        tab4: <TeamStoryPictcureTab teamNo={teamNo} boardOfTeam={boardsOfTeam}></TeamStoryPictcureTab>,
    };

    return (
        <>
            <TestAuth></TestAuth>
            <TrainningSubHeader></TrainningSubHeader>
            <div className="content-wrap">
                <SearchLeft>
                    <SearchIn>
                        <MiniSearchBar placeholder="시설 혹은 팀 검색하세용."></MiniSearchBar>
                        <div className="h30"></div>
                        <div className="filter-box">
                            <InfraRecommandContainer
                                recommandTitle={'주변 스포츠 시설'}
                                parentInfraCategoryNo={InfraCategoryType.SPORT_INFRA_NO}
                            ></InfraRecommandContainer>
                            <InfraRecommandContainer
                                recommandTitle={'주변 숙소'}
                                parentInfraCategoryNo={InfraCategoryType.LODGMENT_NO}
                            ></InfraRecommandContainer>
                            <InfraRecommandContainer
                                recommandTitle={'주변 맛집'}
                                parentInfraCategoryNo={InfraCategoryType.FOOD_NO}
                            ></InfraRecommandContainer>
                        </div>
                    </SearchIn>
                </SearchLeft>
                <section className="search-wrap search-right">
                    <div className="search-in">
                        <SiteDepth pageTitle={'팀 상세'} depth1={'팀 상세'}></SiteDepth>
                        <ImgSlider attachFiles={new Array<AttachFile>()}></ImgSlider>
                        <div className="room-area">
                            <div>
                                <p>
                                    {team?.name} {team?.belongCode?.name} {team?.regionCode?.name}
                                </p>
                                {/* <p>멤버 {team?.memberCnt} | 감독 {'조우현'}</p> */}
                                <p>멤버 {team?.memberCnt}명</p>
                            </div>
                        </div>
                        <div className="container2">
                            <ul className="tabs">
                                <li className={tabSeq == 'tab1' ? 'active' : ''} onClick={() => setTabSeq('tab1')}>
                                    홈
                                </li>
                                <li className={tabSeq == 'tab2' ? 'active' : ''} onClick={() => setTabSeq('tab2')}>
                                    팀일정
                                </li>
                                {/* <li className={tabSeq == 'tab3' ? 'active' : ''} onClick={() => setTabSeq('tab3')}>팀스토리</li>
                            <li className={tabSeq == 'tab4' ? 'active' : ''} onClick={() => setTabSeq('tab4')}>사진</li> */}
                            </ul>
                            <div className="tab_container">
                                <div className="tab_content">{tabs[tabSeq]}</div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
};

export default TeamStoryContainer;
