import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import ActionComponent from '../../ActionComponent';
import TargetPerson from '../../../../domain/TargetPerson';
import Prescription from '../../../../domain/Prescription';
import BasalExerciseFormComponent from './prescriptionForms/BasalExerciseFormComponent';
import ConditioningFormComponent from './prescriptionForms/ConditioningFormComponent';
import ByTypePrescriptionFormComponent from './prescriptionForms/ByTypeFormComponent';
import ByPartPrescriptionFormComponent from './prescriptionForms/ByPartFormComponent';
import OpinionFormComponent from './prescriptionForms/OpinionFormComponent';
import { BasalPrescription } from '../../../../domain/prescription/BasalPrescription';
import ConditioningPrescription from '../../../../domain/prescription/ConditioningPrescription';
import Opinions from '../../../../domain/prescription/Opinions';
import ItemsPrescription from '../../../../domain/prescription/ItemsPrescription';
import RehabTargetHistory from '../../../../domain/RehabTargetHistory';
import { confirmAlert } from 'react-confirm-alert';
import { JsonConvert } from 'json2typescript';

interface IProps {
    targetPerson: TargetPerson;
    recordingItem?: RehabTargetHistory;
    isModifying: boolean;
    handler: (evt: any) => void;
}

const jsonConvert = new JsonConvert();

function usePrescriptionHook(callback: any) {
    const [prescription, setPrescription] = useState<Prescription>(new Prescription());
    useEffect(() => {
        let evt = { prescription: prescription };
        callback(evt);
    }, [prescription]);
    return { prescription, setPrescription };
}

function useRecordingItemHook(callback: any, recording?: RehabTargetHistory) {
    const { prescription, setPrescription } = usePrescriptionHook(callback);

    useEffect(() => {
        let recordingScription = new Prescription();

        if (recording) {
            recordingScription.basalExercisePrescription =
                recording.basalExercisePrescription != '' && recording.basalExercisePrescription != null
                    ? jsonConvert.deserializeObject(JSON.parse(recording.basalExercisePrescription), BasalPrescription)
                    : new BasalPrescription();
            recordingScription.conditioningPrescription =
                recording.conditioningPrescription != '' && recording.conditioningPrescription != null
                    ? jsonConvert.deserializeObject(JSON.parse(recording.conditioningPrescription), ConditioningPrescription)
                    : new ConditioningPrescription();
            recordingScription.itemPrescription =
                recording.itemPrescription != '' && recording.itemPrescription != null
                    ? jsonConvert.deserializeObject(JSON.parse(recording.itemPrescription), ItemsPrescription)
                    : new ItemsPrescription();
            recordingScription.bodyPrescription =
                recording.bodyPrescription != '' && recording.bodyPrescription != null
                    ? jsonConvert.deserializeObject(JSON.parse(recording.bodyPrescription), ItemsPrescription)
                    : new ItemsPrescription();
            recordingScription.opinions =
                recording.opinions != '' && recording.opinions != null
                    ? jsonConvert.deserializeObject(JSON.parse(recording.opinions), Opinions)
                    : new Opinions();

            setPrescription(recordingScription);
        }
    }, [recording]);

    return { prescription, setPrescription };
}

export default function PrescriptionFormComponent(props: IProps) {
    const { prescription, setPrescription } = useRecordingItemHook(props.handler, props.recordingItem);
    let isModifying = props.isModifying;
    //input change 이벤트 관리
    const handler = (evt: any) => {
        var property = evt.target.name;
        var target = evt.target.id;
        var inputVal = evt.target.value;
        if (!isModifying) {
            if (property === 'basalExercisePrescription') {
                setBasalExercise(property, target, inputVal);
            } else if (property === 'conditioningPrescription') {
                setConditioning(property, target, inputVal);
            } else if (property === 'opinions') {
                setOpinions(property, target, inputVal);
            } else if (property === 'itemPrescription') {
                setItemPrescription(property, target, inputVal);
            } else if (property === 'bodyPrescription') {
                setBodyPrescription(property, target, inputVal);
            }
        } else {
            confirmAlert({
                title: '경고',
                message: '이력 확인 중에는 수정할 수 없습니다.',
                buttons: [
                    {
                        label: '확인',
                        onClick: () => {},
                    },
                ],
            });
        }
    };

    const setBasalExercise = (property: any, target: any, inputVal: any) => {
        setPrescription((prevState) => {
            let newObj: BasalPrescription = {
                ...prevState.basalExercisePrescription,
                [target]: inputVal,
            };

            return {
                ...prevState,
                [property]: newObj,
            };
        });
    };

    const setConditioning = (property: any, target: any, inputVal: any) => {
        setPrescription((prevState) => {
            let targetArr = target.split('_');
            let newArr: string[] = ['', '', ''];
            Object.entries(prevState.conditioningPrescription).map(([key, value]) => {
                if (key === targetArr[0]) newArr = value;
            });
            newArr[targetArr[1]] = inputVal;

            let tmp: ConditioningPrescription = {
                ...prevState.conditioningPrescription,
                [targetArr[0]]: newArr,
            };

            return {
                ...prevState,
                [property]: tmp,
            };
        });
    };

    const setOpinions = (property: any, target: any, inputVal: any) => {
        setPrescription((prevState) => {
            let newObj: Opinions = {
                ...prevState.opinions,
                [target]: inputVal,
            };

            return {
                ...prevState,
                [property]: newObj,
            };
        });
    };

    const setItemPrescription = (property: any, target: any, inputVal: any) => {
        setPrescription((prevState) => {
            let newObj: ItemsPrescription;
            if (target !== 'opinion' && target !== 'exercises') {
                newObj = inputVal;
            } else {
                newObj = {
                    ...prevState.itemPrescription,
                    [target]: inputVal,
                };
            }

            return {
                ...prevState,
                [property]: newObj,
            };
        });
    };
    const setBodyPrescription = (property: any, target: any, inputVal: any) => {
        setPrescription((prevState) => {
            let newObj: ItemsPrescription;
            if (target !== 'opinion' && target !== 'exercises') {
                newObj = inputVal;
            } else {
                newObj = {
                    ...prevState.bodyPrescription,
                    [target]: inputVal,
                };
            }

            return {
                ...prevState,
                [property]: newObj,
            };
        });
    };

    const tabHandler = (evt: any) => {
        let target = evt.target.id;
        let actions = ActionComponent();

        if (target === 'basisPrescription') {
            actions.tab1();
        } else if (target === 'conditioning') {
            actions.tab2();
        } else if (target === 'byType') {
            actions.tab3();
        } else if (target === 'byPart') {
            actions.tab4();
        } else if (target === 'opinions') {
            actions.tab5();
        }
    };

    return (
        <>
            <div className="center-popup-list-box2">
                <div className="tab-menu">
                    <ul>
                        <li id="tabon1" className="tabon">
                            <button id="basisPrescription" onClick={tabHandler}>
                                기본운동처방
                            </button>
                        </li>
                        <li id="tabon2">
                            <button id="conditioning" onClick={tabHandler}>
                                컨디셔닝
                            </button>
                        </li>
                        <li id="tabon3">
                            <button id="byType" onClick={tabHandler}>
                                운동종목별
                                <br />
                                운동처방
                            </button>
                        </li>
                        <li id="tabon4">
                            <button id="byPart" onClick={tabHandler}>
                                손상부위별
                                <br />
                                운동처방
                            </button>
                        </li>
                        <li id="tabon5">
                            <button id="opinions" onClick={tabHandler}>
                                소견 내용
                            </button>
                        </li>
                    </ul>
                </div>

                <div className="tab-story">
                    {/* <div id="tabstory1">
                        {/* 기본 운동 처방 작성 Form
                        <BasalExerciseFormComponent handler={handler} prescription={prescription}></BasalExerciseFormComponent>
                    </div>
                    <div id="tabstory2">
                        {/* 컨디셔닝 처방 작성 Form 
                        <ConditioningFormComponent handler={handler} prescription={prescription}></ConditioningFormComponent>
                    </div>
                    <div id="tabstory3">
                        {/* 운동 종목 별 처방 작성 Form 
                        <ByTypePrescriptionFormComponent handler={handler} prescription={prescription}></ByTypePrescriptionFormComponent>
                    </div>
                    <div id="tabstory4">
                        {/* 신체 부위 별 처방 작성 Form 
                        <ByPartPrescriptionFormComponent handler={handler} prescription={prescription}></ByPartPrescriptionFormComponent>
                    </div>
                    <div id="tabstory5">
                        {/* 처방 소견 작성 Form 
                        <OpinionFormComponent handler={handler} prescription={prescription}></OpinionFormComponent>
                    </div> */}
                </div>
            </div>
        </>
    );
}
