import React, { useEffect, useState } from 'react';
import AdminMainTitle from '../../../../component/admin/AdminMainTitle';
import ReservationServiceImpl from '../../../../service/reservation/impl/ReservationServiceImpl';
import Reservation from '../../../../domain/reservation/Reservation';
import { InfraCategoryType } from '../../../../domain/infra/InfraCategoryType';
import AdminReservationsTable from '../../../../component/admin/AdminReservationsTable';
import useAdminPaging from '../../hook/useAdminPaging';
import AdminRetrieveItemPaging from '../../component/AdminRetrieveItemPaging';
import useReservationSearchCondition from '../../../../hook/reservation/useReservationOfSearchCondition';
import searchCondition from '../../../../moldules/SearchCondition';
import ReservationSearchCondition from '../../../../domain/reservation/param/ReservationSearchCondition';
import AdminSportInfraReservationInfosFilter from '../../component/filter/AdminSportInfraReservationInfosFilter';
import useChildCodeRetrieve from '../../../../hook/code/useChildCodeRetrieve';
import { defaultParentRegionCodeId } from '../../../../config/Constant';
import useCodeRetrieve from '../../../../hook/code/useCodeRetrieve';
import { CodeType } from '../../../../domain/code/CodeType';

const reservationService = new ReservationServiceImpl();
function AdminSportInfraScheduleListPage() {
    const regionCodes = useChildCodeRetrieve(defaultParentRegionCodeId);
    const {codes: reservationCodes} = useCodeRetrieve(CodeType.RESERVATION_STATE);
    const {
        page, setPage, 
        pagingSize, setPagingSize,
        pagingCnt, paging,
        currentPageGroupIdx, setCurrentPageGroupIdx
    } = useAdminPaging();
    
    const [reservationSearchCondition, setReservationSearchCondition] = useState<ReservationSearchCondition>();
    const {reservations, reservationsCnt} = useReservationSearchCondition(reservationSearchCondition, paging)
    const [reservationSearchConditionByFilter, setReservationSearchConditionByFilter] = useState<ReservationSearchCondition>();
    useEffect(() => {
        const reservationSearchCondition = new ReservationSearchCondition();
        reservationSearchCondition.parentInfraCategoryNo = String(InfraCategoryType.SPORT_INFRA_NO);
        setReservationSearchCondition(reservationSearchCondition);
    }, []);

    useEffect(() => {
        console.log(reservationSearchConditionByFilter);
        if(reservationSearchConditionByFilter) {
            reservationSearchConditionByFilter.parentInfraCategoryNo = String(InfraCategoryType.SPORT_INFRA_NO);
            setReservationSearchCondition(reservationSearchConditionByFilter);
        }
    }, [reservationSearchConditionByFilter]);

    useEffect(() => {
        setCurrentPageGroupIdx(0);
        setPage(0);
    }, [reservationSearchCondition])

    return (
        <>
            <AdminMainTitle title="스포츠 시설 예약 일정 목록">
                <AdminSportInfraReservationInfosFilter
                    reservationCodes={reservationCodes}
                    regionCodes={regionCodes}
                    setReservationSearchCondition={setReservationSearchConditionByFilter}
                ></AdminSportInfraReservationInfosFilter>
            </AdminMainTitle>
            <AdminReservationsTable
                page={page}
                pagingSize={pagingSize}
                reservations={reservations}
            ></AdminReservationsTable>
            <AdminRetrieveItemPaging
                totalCnt={reservationsCnt}
                pagingCnt={pagingCnt}
                pagingSize={pagingSize}
                setPagingSize={setPagingSize}
                setPage={setPage}
                currentPageNum={page}
                currentPageGroupIdx={currentPageGroupIdx}
            ></AdminRetrieveItemPaging>
        </>
    )
}

export default AdminSportInfraScheduleListPage;