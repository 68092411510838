import React, { useState } from 'react';
import InfraCategory from '../../domain/infra/InfraCategory';
import InfraCategoryService from '../../service/infra/InfraCategoryService';
import InfraCategoryServiceImpl from '../../service/infra/impl/InfraCategoryServiceImpl';

export interface InfraCategoryProps {
    infraCategories?: InfraCategory[];
    clickedCategoryNo?: number | undefined;
    infraCategoryEventHandler: (categoryNo: number | undefined, categoryName: string) => void;
}

function InfraCategoryComponent(props: InfraCategoryProps) {
    const [activeIdx, setActiveIdx] = useState<number>();
    return (
        <div className="cate">
            {props.infraCategories ? (
                <ul>
                    <li
                        className={activeIdx === undefined ? 'on' : ''}
                        onClick={() => {
                            props.infraCategoryEventHandler(undefined, '전체');
                            setActiveIdx(undefined);
                        }}
                    >
                        <img src={`/assets/image/icon/icon-all.png`}></img>
                        <br></br>
                        <span>전체</span>
                    </li>
                    {props.infraCategories.map((category, index) => {
                        let icon = category.iconClassName;
                        let cateType = category.parentInfraCategory.infraCategoryNo;
                        if (!icon) {
                            if (cateType == 1) {
                                // 스포츠 시설
                                icon = 'facilityicon03';
                            } else if (cateType == 3) {
                                //관광시설
                                icon = 'facilityicon06';
                            } else if (cateType == 16) {
                                // 숙박시설
                                icon = 'houseicon05';
                            } else if (cateType == 17) {
                                //음식
                                icon = 'foodicon01';
                            } else {
                                icon = 'houseicon02';
                            }
                        }

                        return (
                            <li
                                key={index + 1}
                                className={activeIdx === index ? `on ` : ``}
                                onClick={() => {
                                    props.infraCategoryEventHandler(category.infraCategoryNo, category.name);
                                    setActiveIdx(index);
                                }}
                            >
                                <img src={`/assets/image/icon/${icon}.png`}></img>
                                <br></br>
                                <span>{category.name}</span>
                            </li>
                        );
                    })}
                </ul>
            ) : (
                <></>
            )}
        </div>
    );
}

export default InfraCategoryComponent;
