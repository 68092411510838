import React, { useEffect, useState } from 'react';
import DateUtils from '../../../../utils/DateUtils';
import ReservationCenterComponent from '../../components/ReservationCenterComponent';

function RehabilitationCenterContainer() {
    return (
        <div className="content-wrap">
            <div className="sub_contents">
                <div className="snb">
                    <h2>스포츠재활센터</h2>
                    <ul>
                        <li className="menu_on">
                            <a href="/rehabilitation/re-center">재활센터</a>
                        </li>
                    </ul>
                </div>

                <div className="contents_ad">
                    <div className="page_tle">
                        <h3>재활센터</h3>
                        <div className="page_nav">
                            <ul>
                                <li>
                                    <a href="/iis/web/main.do">홈</a>
                                </li>
                                <li>스포츠재활센터</li>
                                <li>
                                    <a href="/rehabilitation/re-center">재활센터</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="wrap-h">
                        <div className="wrap-h-left">
                            <div>
                                <p>
                                    스포츠의학을 기반으로 기초 및 전문체력 측정 데이터를 분석하여, 전문체육선수들에게 운동처방과 운동손상예방을 위한
                                    스포츠재활서비스를 제공하는 센터입니다.
                                </p>
                            </div>
                            <div>
                                <br />
                                <p>
                                    전문체육선수들이 신체 컨디션을 최상으로 유지하며 스포츠 현장에 복귀할 수 있도록 올바른 운동법과 다양한 장비로 재활
                                    훈련을 도와 손상된 기능을 회복시키는 것이 목표입니다.
                                </p>
                            </div>
                            <div>
                                <h4>
                                    <span>· </span>예약을 위해서 로그인이 필요하며, 팀 관리자 권한을 가진 사용자가 예약을 진행 할 수 있습니다.
                                </h4>
                            </div>
                        </div>
                        <div className="wrap-h-right">
                            <ReservationCenterComponent></ReservationCenterComponent>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RehabilitationCenterContainer;
