import React from 'react';

interface MyPageRightProps {
    children?: React.ReactNode;
}

function MyPageRight(props: MyPageRightProps) {
    return (
        <div className="my-right">
            {props.children}
        </div>
    )
}

export default MyPageRight;