import React from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import UserModifyParam from '../../domain/user/param/UserModifyParam';
import UserSearchCondition from '../../domain/user/param/UserSearchCondition';
import RetrieveUser from '../../domain/user/RetrieveUser';
import UserServiceImpl from '../../service/user/impl/UserServiceImpl';
import RoleNameUtils from '../../utils/RoleNameUtils';

interface AdminUsersTableProps {
    page: number;
    pagingCnt: number;
    pagingSize: number;
    users?: RetrieveUser[];
    searchCondition: UserSearchCondition;
}
const userService = new UserServiceImpl();
function AdminUsersTable(props: AdminUsersTableProps) {
    const history = useHistory();

    const goDetailHandler = (evt: any) => {
        var userNo = evt.currentTarget.id;
        var condition: UserSearchCondition = props.searchCondition;
        let groupIdx = Math.floor(props.page / props.pagingCnt);
        condition.page = props.page;
        condition.groupIdx = groupIdx;

        history?.replace({
            pathname: `/admin/members/${userNo}`,
            state: props.searchCondition,
        });
    };

    const approveHandler = (user: RetrieveUser, approve: boolean) => {
        user.signupApprove = approve;
        let modifyParam = UserModifyParam.getInstance(user, undefined);
        let message = `${approve ? '승인' : '승인취소'} 하시겠습니까?`;
        let confirmBool = window.confirm(message);

        if (confirmBool) {
            userService
                .updateUserInfo(modifyParam)
                .then((response) => {
                    if (approve) {
                        alert('승인 되었습니다.');
                    } else {
                        alert('승인취소 되었습니다.');
                    }
                    window.location.reload();
                })
                .catch((err) => {
                    alert('오류');
                });
        }
    };

    let userCnt = props.users ? props.users.length : 0;
    return (
        <>
            <div className="member-list">
                <table cellPadding="0" cellSpacing="0">
                    <thead>
                        <tr>
                            {/* <td><input type="checkbox"></input></td> */}
                            <td>No.</td>
                            <td>회원등급</td>
                            {/* <td>계정(이메일)</td> */}
                            <td>이름</td>
                            <td>소속팀</td>
                            <td>소속</td>
                            <td>종목</td>
                            <td>가입일</td>
                            <td>가입상태</td>
                            <td>가입경로</td>
                            <td>관리</td>
                        </tr>
                    </thead>
                    <tbody>
                        {userCnt > 0 ? (
                            props.users?.map((user: RetrieveUser, index: number) => {
                                return (
                                    <tr key={index}>
                                        <td>{props.pagingSize * props.page + (index + 1)}</td>
                                        <td>{RoleNameUtils.getRoleName(user.authorities)}</td>
                                        {/* <td>{user.email}</td> */}
                                        <td>
                                            <a href={`/admin/members/${user.userNo}`}>{user.name}</a>
                                        </td>
                                        <td>{user.teamName}</td>
                                        <td>{user.regionCode?.name}</td>
                                        <td>{user.sportCode?.name}</td>
                                        <td>{user.registeDate}</td>
                                        <td>
                                            {user.signupApprove ? (
                                                <span style={{ color: 'green' }}>가입승인</span>
                                            ) : (
                                                <span style={{ color: 'red' }}>승인대기</span>
                                            )}
                                        </td>
                                        <td>{user.getProvider()}</td>
                                        <td>
                                            <button className="bg-blue white" onClick={() => approveHandler(user, true)}>
                                                <i className="fas fa-check-circle"></i> 승인
                                            </button>
                                            <button className="bg-gray white" onClick={() => approveHandler(user, false)}>
                                                <i className="fas fa-ban"></i> 비승인
                                            </button>

                                            <button className="bg-white" id={user.userNo} onClick={goDetailHandler}>
                                                <i className="fas fa-search"></i>
                                                보기
                                            </button>
                                        </td>
                                    </tr>
                                );
                            })
                        ) : (
                            <tr>
                                <td colSpan={10}>조회 된 회원이 없습니다.</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </>
    );
}

export default AdminUsersTable;
