import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('visitedTeamInfo')
export default class VisitedTeamInfo {
    @JsonProperty('detailId')
    detailId?: string;
    @JsonProperty('visitedTeamInfoNo')
    visitedTeamInfoNo?: string;
    @JsonProperty('visitedDate')
    visitedDate?: string;
    @JsonProperty('visitedMemberCnt')
    visitedMemberCnt?: number;
}
