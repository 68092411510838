import React, {useEffect} from 'react';

declare global {
    interface Window {
        kakao: any;
    }
}

const { kakao } = window;

interface MapComponentProps {
    longitude: number;
    latitude: number;
}

const MapComponent = (props: MapComponentProps) => {
    useEffect( () => {
        const container = document.getElementById('myMap');
        const options = {
            center: new kakao.maps.LatLng(props.latitude, props.longitude),
            level: 3
        };
        const map = new kakao.maps.Map(container, options);
        var marker = new kakao.maps.Marker({
            position: new kakao.maps.LatLng(props.latitude, props.longitude)
        });
        
        marker.setMap(map);
    }, [props.latitude, props.longitude])

    return (
        <div style={{margin: 'auto 0'}}>
            <div id = 'myMap' style = {{
                width: '100%',
                height: '50vh'
            }}></div>
        </div>
    )
}

export default MapComponent;