import React, { useEffect, useState } from 'react';
import Infra from '../../../domain/infra/Infra';
import InfraServiceImpl from '../../../service/infra/impl/InfraServiceImpl';

interface MatchParams {
    openable: boolean;
    centerName: string;
    setCenterOpenable: (openable: boolean) => void;
    setCenterName: (centerName: string) => void;
    // infraNo: string;
    // setInfraNo : (infraNo:string) => void;
    setSelectedInfra: (infra: Infra) => void;
}

const infraService = new InfraServiceImpl();
function RehabilitationListComponent(props: MatchParams) {
    const [lists, setLists] = useState<Infra[]>();

    useEffect(() => {
        // infraService.getInfraOfParentCategoryNo(1)
        infraService.getInfraOfChildCategoryNo(2, 28).then((tmpLists) => {
            setLists(tmpLists);
        });
    }, []);

    function centerNameSelectFunc(e: React.ChangeEvent<HTMLInputElement>) {
        const { name, value } = e.target;
        // props.setInfraNo(value);
        props.setCenterName(name);
    }

    return (
        <>
            <div id="ccho" className="sportsman-cho" style={{ display: `${props.openable ? 'block' : 'none'}` }}>
                {lists && lists['length'] > 0 ? (
                    lists.map((infra: Infra, index) => {
                        return (
                            <p key={index} style={{ marginBottom: '5px' }}>
                                <label>
                                    <input
                                        type="radio"
                                        value={infra.infraNo}
                                        name={infra.name}
                                        onChange={centerNameSelectFunc}
                                        checked={props.centerName === infra.name}
                                        onClick={() => {
                                            props.setSelectedInfra(infra);
                                            props.setCenterOpenable(false);
                                        }}
                                    />
                                    {infra.name}
                                </label>
                            </p>
                        );
                    })
                ) : (
                    <p>시설이 없습니다.</p>
                )}
            </div>
        </>
    );
}

export default RehabilitationListComponent;
