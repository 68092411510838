import React from 'react';
import ReactDOM from 'react-dom';

function ActionComponent() {
    const centerPopupWrap = document.getElementById('center-popup-wrap');
    const centerCheckWrap = document.getElementById('center-check-wrap');

    const tabstory1 = document.getElementById('tabstory1');
    const tabstory2 = document.getElementById('tabstory2');
    const tabstory3 = document.getElementById('tabstory3');
    const tabstory4 = document.getElementById('tabstory4');
    const tabstory5 = document.getElementById('tabstory5');
    const tabstory6 = document.getElementById('tabstory6');
    const tabstory7 = document.getElementById('tabstory7');
    const tabstory8 = document.getElementById('tabstory8');
    const tabstory9 = document.getElementById('tabstory9');
    const tabon1 = document.getElementById('tabon1');
    const tabon2 = document.getElementById('tabon2');
    const tabon3 = document.getElementById('tabon3');
    const tabon4 = document.getElementById('tabon4');
    const tabon5 = document.getElementById('tabon5');
    const tabon6 = document.getElementById('tabon6');
    const tabon7 = document.getElementById('tabon7');
    const tabon8 = document.getElementById('tabon8');
    const tabon9 = document.getElementById('tabon9');

    const centerPopupList = document.getElementById('center-popup-list');
    const listTab = document.getElementById('list-tab');
    const listFrom = document.getElementById('list-from');
    const tabBtn1 = document.getElementById('tab-btn1');
    const tabBtn2 = document.getElementById('tab-btn2');
    const tabBtn3 = document.getElementById('tab-btn3');
    const tabBtn4 = document.getElementById('tab-btn4');

    const step1 = document.getElementById('step1');
    const step2 = document.getElementById('step2');
    const step3 = document.getElementById('step3');
    const tabStep1 = document.getElementById('tab-step1');
    const tabStep2 = document.getElementById('tab-step2');
    const tabStep3 = document.getElementById('tab-step3');
    const part1 = document.getElementById('part1');
    const part2 = document.getElementById('part2');
    const part3 = document.getElementById('part3');
    const tabPart1 = document.getElementById('tab-part1');
    const tabPart2 = document.getElementById('tab-part2');
    const tabPart3 = document.getElementById('tab-part3');

    const centerPopupList2 = document.getElementById('center-popup-list2');
    const centerPopupList3 = document.getElementById('center-popup-list3');
    const centerPopupList4 = document.getElementById('center-popup-list4');

    const checkDotGroup = document.getElementById('check-dot-group');

    let actions = {
        popupOpen: function () {
            let centerPopupWrap = document.getElementById('center-popup-wrap');
            if (centerPopupWrap) centerPopupWrap.style.display = 'block';
            /* (document.getElementById('center-popup-box') as HTMLElement).style.opacity = '1'; */
        },
        checkOpen: function () {
            if (centerCheckWrap) centerCheckWrap.style.display = 'block';
            /* (document.getElementById('center-check-box') as HTMLElement).style.opacity = '1'; */
        },
        popupClose: function popupClose() {
            let centerPopupWrap = document.getElementById('center-popup-wrap');
            if (centerPopupWrap) {
                centerPopupWrap.style.display = 'none';
                ReactDOM.render(<></>, centerPopupWrap);
            }
        },
        checkClose: function () {
            let checkPopupWrap = document.getElementById('center-check-wrap');
            if (checkPopupWrap) {
                checkPopupWrap.style.display = 'none';
                ReactDOM.render(<></>, checkPopupWrap);
            }
        },
        dotClose: function () {
            if (checkDotGroup) checkDotGroup.style.display = 'none';
        },
        researchSend: function () {
            if (centerPopupList) centerPopupList.style.display = 'block';
            if (listTab) listTab.style.display = 'none';
            if (listFrom) listFrom.style.display = 'none';
            if (tabBtn1) {
                tabBtn1.classList.remove('on');
                tabBtn1.classList.add('on');
            }
            if (tabBtn2) tabBtn2.classList.remove('on');
            if (tabBtn3) tabBtn3.classList.remove('on');
        },
        researchView: function () {
            if (centerPopupList) centerPopupList.style.display = 'none';
            if (listTab) listTab.style.display = 'block';
            if (listFrom) listFrom.style.display = 'none';
            if (tabBtn1) tabBtn1.classList.remove('on');
            if (tabBtn2) {
                tabBtn2.classList.remove('on');
                tabBtn2.classList.add('on');
            }
            if (tabBtn3) tabBtn3.classList.remove('on');
        },
        researchYes: function () {
            if (centerPopupList) centerPopupList.style.display = 'none';
            if (listTab) listTab.style.display = 'none';
            if (listFrom) listFrom.style.display = 'block';
            if (tabBtn1) tabBtn1.classList.remove('on');
            if (tabBtn2) tabBtn2.classList.remove('on');
            if (tabBtn3) {
                tabBtn3.classList.remove('on');
                tabBtn3.classList.add('on');
            }
        },
        tab1: function () {
            if (tabstory1) tabstory1.style.display = 'block';
            if (tabstory2) tabstory2.style.display = 'none';
            if (tabstory3) tabstory3.style.display = 'none';
            if (tabstory4) tabstory4.style.display = 'none';
            if (tabstory5) tabstory5.style.display = 'none';
            if (tabstory6) tabstory6.style.display = 'none';
            if (tabstory7) tabstory7.style.display = 'none';
            if (tabstory8) tabstory8.style.display = 'none';
            if (tabstory9) tabstory9.style.display = 'none';
            if (tabon1) {
                tabon1.classList.remove('tabon');
                tabon1.classList.add('tabon');
            }
            if (tabon2) tabon2.classList.remove('tabon');
            if (tabon3) tabon3.classList.remove('tabon');
            if (tabon4) tabon4.classList.remove('tabon');
            if (tabon5) tabon5.classList.remove('tabon');
            if (tabon6) tabon6.classList.remove('tabon');
            if (tabon7) tabon7.classList.remove('tabon');
            if (tabon8) tabon8.classList.remove('tabon');
            if (tabon9) tabon9.classList.remove('tabon');
        },
        tab2: function () {
            if (tabstory1) tabstory1.style.display = 'none';
            if (tabstory2) tabstory2.style.display = 'block';
            if (tabstory3) tabstory3.style.display = 'none';
            if (tabstory4) tabstory4.style.display = 'none';
            if (tabstory5) tabstory5.style.display = 'none';
            if (tabstory6) tabstory6.style.display = 'none';
            if (tabstory7) tabstory7.style.display = 'none';
            if (tabstory8) tabstory8.style.display = 'none';
            if (tabstory9) tabstory9.style.display = 'none';
            if (tabon1) tabon1.classList.remove('tabon');
            if (tabon2) {
                tabon2.classList.remove('tabon');
                tabon2.classList.add('tabon');
            }
            if (tabon3) tabon3.classList.remove('tabon');
            if (tabon4) tabon4.classList.remove('tabon');
            if (tabon5) tabon5.classList.remove('tabon');
            if (tabon6) tabon6.classList.remove('tabon');
            if (tabon7) tabon7.classList.remove('tabon');
            if (tabon8) tabon8.classList.remove('tabon');
            if (tabon9) tabon9.classList.remove('tabon');
        },
        tab3: function () {
            if (tabstory1) tabstory1.style.display = 'none';
            if (tabstory2) tabstory2.style.display = 'none';
            if (tabstory3) tabstory3.style.display = 'block';
            if (tabstory4) tabstory4.style.display = 'none';
            if (tabstory5) tabstory5.style.display = 'none';
            if (tabstory6) tabstory6.style.display = 'none';
            if (tabstory7) tabstory7.style.display = 'none';
            if (tabstory8) tabstory8.style.display = 'none';
            if (tabstory9) tabstory9.style.display = 'none';
            if (tabon1) tabon1.classList.remove('tabon');
            if (tabon2) tabon2.classList.remove('tabon');
            if (tabon3) {
                tabon3.classList.remove('tabon');
                tabon3.classList.add('tabon');
            }
            if (tabon4) tabon4.classList.remove('tabon');
            if (tabon5) tabon5.classList.remove('tabon');
            if (tabon6) tabon6.classList.remove('tabon');
            if (tabon7) tabon7.classList.remove('tabon');
            if (tabon8) tabon8.classList.remove('tabon');
            if (tabon9) tabon9.classList.remove('tabon');
        },
        tab4: function () {
            if (tabstory1) tabstory1.style.display = 'none';
            if (tabstory2) tabstory2.style.display = 'none';
            if (tabstory3) tabstory3.style.display = 'none';
            if (tabstory4) tabstory4.style.display = 'block';
            if (tabstory5) tabstory5.style.display = 'none';
            if (tabstory6) tabstory6.style.display = 'none';
            if (tabstory7) tabstory7.style.display = 'none';
            if (tabstory8) tabstory8.style.display = 'none';
            if (tabstory9) tabstory9.style.display = 'none';
            if (tabon1) tabon1.classList.remove('tabon');
            if (tabon2) tabon2.classList.remove('tabon');
            if (tabon4) {
                tabon4.classList.remove('tabon');
                tabon4.classList.add('tabon');
            }
            if (tabon3) tabon3.classList.remove('tabon');
            if (tabon5) tabon5.classList.remove('tabon');
            if (tabon6) tabon6.classList.remove('tabon');
            if (tabon7) tabon7.classList.remove('tabon');
            if (tabon8) tabon8.classList.remove('tabon');
            if (tabon9) tabon9.classList.remove('tabon');
        },
        tab5: function () {
            if (tabstory1) tabstory1.style.display = 'none';
            if (tabstory2) tabstory2.style.display = 'none';
            if (tabstory3) tabstory3.style.display = 'none';
            if (tabstory4) tabstory4.style.display = 'none';
            if (tabstory5) tabstory5.style.display = 'block';
            if (tabstory6) tabstory6.style.display = 'none';
            if (tabstory7) tabstory7.style.display = 'none';
            if (tabstory8) tabstory8.style.display = 'none';
            if (tabstory9) tabstory9.style.display = 'none';
            if (tabon1) tabon1.classList.remove('tabon');
            if (tabon2) tabon2.classList.remove('tabon');
            if (tabon3) tabon3.classList.remove('tabon');
            if (tabon4) tabon4.classList.remove('tabon');
            if (tabon5) {
                tabon5.classList.remove('tabon');
                tabon5.classList.add('tabon');
            }
            if (tabon6) tabon6.classList.remove('tabon');
            if (tabon7) tabon7.classList.remove('tabon');
            if (tabon8) tabon8.classList.remove('tabon');
            if (tabon9) tabon9.classList.remove('tabon');
        },
        tab6: function () {
            if (tabstory1) tabstory1.style.display = 'none';
            if (tabstory2) tabstory2.style.display = 'none';
            if (tabstory3) tabstory3.style.display = 'none';
            if (tabstory4) tabstory4.style.display = 'none';
            if (tabstory5) tabstory5.style.display = 'none';
            if (tabstory6) tabstory6.style.display = 'block';
            if (tabstory7) tabstory7.style.display = 'none';
            if (tabstory8) tabstory8.style.display = 'none';
            if (tabstory9) tabstory9.style.display = 'none';
            if (tabon1) tabon1.classList.remove('tabon');
            if (tabon2) tabon2.classList.remove('tabon');
            if (tabon3) tabon3.classList.remove('tabon');
            if (tabon4) tabon4.classList.remove('tabon');
            if (tabon5) tabon5.classList.remove('tabon');
            if (tabon6) {
                tabon6.classList.remove('tabon');
                tabon6.classList.add('tabon');
            }
            if (tabon7) tabon7.classList.remove('tabon');
            if (tabon8) tabon8.classList.remove('tabon');
            if (tabon9) tabon9.classList.remove('tabon');
        },
        tab7: function () {
            if (tabstory1) tabstory1.style.display = 'none';
            if (tabstory2) tabstory2.style.display = 'none';
            if (tabstory3) tabstory3.style.display = 'none';
            if (tabstory4) tabstory4.style.display = 'none';
            if (tabstory5) tabstory5.style.display = 'none';
            if (tabstory6) tabstory6.style.display = 'none';
            if (tabstory7) tabstory7.style.display = 'block';
            if (tabstory8) tabstory8.style.display = 'none';
            if (tabstory9) tabstory9.style.display = 'none';
            if (tabon1) tabon1.classList.remove('tabon');
            if (tabon2) tabon2.classList.remove('tabon');
            if (tabon3) tabon3.classList.remove('tabon');
            if (tabon4) tabon4.classList.remove('tabon');
            if (tabon5) tabon5.classList.remove('tabon');
            if (tabon6) tabon6.classList.remove('tabon');
            if (tabon7) {
                tabon7.classList.remove('tabon');
                tabon7.classList.add('tabon');
            }
            if (tabon8) tabon8.classList.remove('tabon');
            if (tabon9) tabon9.classList.remove('tabon');
        },
        tab8: function () {
            if (tabstory1) tabstory1.style.display = 'none';
            if (tabstory2) tabstory2.style.display = 'none';
            if (tabstory3) tabstory3.style.display = 'none';
            if (tabstory4) tabstory4.style.display = 'none';
            if (tabstory5) tabstory5.style.display = 'none';
            if (tabstory6) tabstory6.style.display = 'none';
            if (tabstory7) tabstory7.style.display = 'none';
            if (tabstory8) tabstory8.style.display = 'block';
            if (tabstory9) tabstory9.style.display = 'none';
            if (tabon1) tabon1.classList.remove('tabon');
            if (tabon2) tabon2.classList.remove('tabon');
            if (tabon3) tabon3.classList.remove('tabon');
            if (tabon4) tabon4.classList.remove('tabon');
            if (tabon5) tabon5.classList.remove('tabon');
            if (tabon6) tabon6.classList.remove('tabon');
            if (tabon7) tabon7.classList.remove('tabon');
            if (tabon8) {
                tabon8.classList.remove('tabon');
                tabon8.classList.add('tabon');
            }
            if (tabon9) tabon9.classList.remove('tabon');
        },
        tab9: function () {
            if (tabstory1) tabstory1.style.display = 'none';
            if (tabstory2) tabstory2.style.display = 'none';
            if (tabstory3) tabstory3.style.display = 'none';
            if (tabstory4) tabstory4.style.display = 'none';
            if (tabstory5) tabstory5.style.display = 'none';
            if (tabstory6) tabstory6.style.display = 'none';
            if (tabstory7) tabstory7.style.display = 'none';
            if (tabstory8) tabstory8.style.display = 'none';
            if (tabstory9) tabstory9.style.display = 'block';
            if (tabon1) tabon1.classList.remove('tabon');
            if (tabon2) tabon2.classList.remove('tabon');
            if (tabon3) tabon3.classList.remove('tabon');
            if (tabon4) tabon4.classList.remove('tabon');
            if (tabon5) tabon5.classList.remove('tabon');
            if (tabon6) tabon6.classList.remove('tabon');
            if (tabon7) tabon7.classList.remove('tabon');
            if (tabon8) tabon8.classList.remove('tabon');
            if (tabon9) {
                tabon9.classList.remove('tabon');
                tabon9.classList.add('tabon');
            }
        },
        tab1Open: function () {
            if (centerPopupList) centerPopupList.style.display = 'block';
            if (centerPopupList2) {
                centerPopupList2.style.display = 'none';
                //ReactDOM.render(<></>, centerPopupList2);
            }
            if (centerPopupList3) {
                centerPopupList3.style.display = 'none';
                //ReactDOM.render(<></>, centerPopupList3);
            }
            if (centerPopupList4) {
                centerPopupList4.style.display = 'none';
                //ReactDOM.render(<></>, centerPopupList4);
            }
            if (tabBtn1) {
                tabBtn1.classList.remove('on');
                tabBtn1.classList.add('on');
            }
            if (tabBtn2) tabBtn2.classList.remove('on');
            if (tabBtn3) tabBtn3.classList.remove('on');
            if (tabBtn4) tabBtn4.classList.remove('on');
        },
        tab2Open: function () {
            if (centerPopupList2) centerPopupList2.style.display = 'block';
            if (centerPopupList) {
                centerPopupList.style.display = 'none';
                //ReactDOM.render(<></>, centerPopupList);
            }
            if (centerPopupList3) {
                centerPopupList3.style.display = 'none';
                //ReactDOM.render(<></>, centerPopupList3);
            }
            if (centerPopupList4) {
                centerPopupList4.style.display = 'none';
                //ReactDOM.render(<></>, centerPopupList4);
            }
            if (tabBtn1) tabBtn1.classList.remove('on');
            if (tabBtn2) {
                tabBtn2.classList.remove('on');
                tabBtn2.classList.add('on');
            }
            if (tabBtn3) tabBtn3.classList.remove('on');
            if (tabBtn4) tabBtn4.classList.remove('on');
        },
        tab3Open: function () {
            if (centerPopupList3) centerPopupList3.style.display = 'block';
            if (centerPopupList2) {
                centerPopupList2.style.display = 'none';
                //ReactDOM.render(<></>, centerPopupList2);
            }
            if (centerPopupList) {
                centerPopupList.style.display = 'none';
                //ReactDOM.render(<></>, centerPopupList);
            }
            if (centerPopupList4) {
                centerPopupList4.style.display = 'none';
                //ReactDOM.render(<></>, centerPopupList4);
            }
            if (tabBtn1) tabBtn1.classList.remove('on');
            if (tabBtn2) tabBtn2.classList.remove('on');
            if (tabBtn3) {
                tabBtn3.classList.remove('on');
                tabBtn3.classList.add('on');
            }

            if (tabBtn4) tabBtn4.classList.remove('on');
        },
        tab4Open: function () {
            if (centerPopupList4) centerPopupList4.style.display = 'block';
            if (centerPopupList) {
                centerPopupList.style.display = 'none';
                ReactDOM.render(<></>, centerPopupList);
            }
            if (centerPopupList2) {
                centerPopupList2.style.display = 'none';
                ReactDOM.render(<></>, centerPopupList2);
            }
            if (centerPopupList3) {
                centerPopupList3.style.display = 'none';
                ReactDOM.render(<></>, centerPopupList3);
            }
            if (tabBtn1) tabBtn1.classList.remove('on');
            if (tabBtn2) tabBtn2.classList.remove('on');
            if (tabBtn3) tabBtn3.classList.remove('on');
            if (tabBtn4) {
                tabBtn4.classList.remove('on');
                tabBtn4.classList.add('on');
            }
        },
        partOpen: function () {
            var neck = document.getElementById('body-part');
            if (neck) neck.style.display = 'block';
        },
        ballOpen: function () {
            var ball = document.getElementById('ball');
            if (ball) ball.style.display = 'block';
        },
        step1Open: function () {
            if (step1) step1.style.display = 'block';
            if (step2) step2.style.display = 'none';
            if (step3) step3.style.display = 'none';
            if (tabStep1) {
                tabStep1.classList.remove('on');
                tabStep1.classList.add('on');
            }
            if (tabStep2) tabStep2.classList.remove('on');
            if (tabStep3) tabStep3.classList.remove('on');
        },

        step2Open: function () {
            if (step1) step1.style.display = 'none';
            if (step2) step2.style.display = 'block';
            if (step3) step3.style.display = 'none';
            if (tabStep1) tabStep1.classList.remove('on');
            if (tabStep2) {
                tabStep2.classList.remove('on');
                tabStep2.classList.add('on');
            }
            if (tabStep3) tabStep3.classList.remove('on');
        },

        step3Open: function () {
            if (step1) step1.style.display = 'none';
            if (step2) step2.style.display = 'none';
            if (step3) step3.style.display = 'block';
            if (tabStep1) tabStep1.classList.remove('on');
            if (tabStep2) tabStep2.classList.remove('on');
            if (tabStep3) {
                tabStep3.classList.remove('on');
                tabStep3.classList.add('on');
            }
        },
        part1Open: function () {
            if (part1) part1.style.display = 'block';
            if (part2) part2.style.display = 'none';
            if (part3) part3.style.display = 'none';
            if (tabPart1) {
                tabPart1.classList.remove('on');
                tabPart1.classList.add('on');
            }
            if (tabPart2) tabPart2.classList.remove('on');
            if (tabPart3) tabPart3.classList.remove('on');
        },

        part2Open: function () {
            if (part1) part1.style.display = 'none';
            if (part2) part2.style.display = 'block';
            if (part3) part3.style.display = 'none';
            if (tabPart1) tabPart1.classList.remove('on');
            if (tabPart2) {
                tabPart2.classList.remove('on');
                tabPart2.classList.add('on');
            }
            if (tabStep3) tabStep3.classList.remove('on');
        },

        part3Open: function () {
            if (part1) part1.style.display = 'none';
            if (part2) part2.style.display = 'none';
            if (part3) part3.style.display = 'block';
            if (tabPart1) tabPart1.classList.remove('on');
            if (tabPart2) tabPart2.classList.remove('on');
            if (tabPart3) {
                tabPart3.classList.remove('on');
                tabPart3.classList.add('on');
            }
        },
    };

    return actions;
}

export default ActionComponent;
