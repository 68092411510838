import React from 'react';
import { Switch, Route } from 'react-router-dom';
import PrivateRoute from '../../PrivateRouter';
import UserMyPageGuideContainer from '../page/user/guide/UserMyPageGuideContainer';
import UserMyPageSettingContainer from '../page/user/setting/UserMyPageSettingContainer';
import UserMyPageNotiContainer from '../page/user/noti/UserMyPageNotiContainer';
import UserMyPageQnAContainer from '../page/user/qna/UserMyPageQnAContainer';
import UserMyPageUsePolicyContainer from '../page/user/usePolicy/UserMyPageUsePolicyContainer';
import UserMyPagePlayerListOfTeamAdmin from '../page/teamAdmin/playerList/UserMyPagePlayerListOfTeamAdmin';
import TrainningRouter from '../../trainning/router/TrainningRouter';
import UserMyPageFavorities from '../page/user/favorities/UserMyPageFavoriteis';
import MainLayout from '../../MainLayout';
import IndexRouter from '../../IndexRouter';
import MyPageReservationIndexPage from '../page/teamAdmin/reservation/MyPageReservationIndexPage';
import MyPagePlayerReservationIndexPage from '../page/teamAdmin/reservation/MyPagePlayerReservationIndexPage';
import MyPageScheduleIndexPage from '../page/teamAdmin/schedule/MyPageScheduleIndexPage';
import MyPageScheduleAllViewPage from '../page/teamAdmin/schedule/MyPageScheduleAllViewPage';
import MyPageHeader from '../layout/MyPageHeader';
import MyPageBody from '../layout/MyPageBody';
import MyPageLeft from '../layout/MyPageLeft';
import MyPageUserInfoHeader from '../../../component/header/MyPageUserInfoHeader';
import UserPageSubMenuNav from '../page/user/nav/UserPageSubMenuNav';
import MyPageTeamAdminNav from '../page/teamAdmin/nav/MyPageTeamAdminNav';
import MyPageScheduleDetailViewPage from '../page/teamAdmin/schedule/MyPageScheduleDetailViewPage';
import MyPageReservationDetailViewPage from '../page/teamAdmin/reservation/MyPageReservationDetailViewPage';
import MypageScheduleModifyPage from '../page/teamAdmin/schedule/MyPageScheduleModifyPage';
import UserMyPagePwPage from '../page/user/pw/UserMyPagePwPage';
import UserMyPageQuestionnairePage from '../page/user/questionnaire/UserMyPageQuestionnairePage';
import UserMyPageQuestionnaireViewPage from '../page/user/questionnaire/UserMyPageQuestionnaireViewPage';
import MyPageReservationListPage from '../page/teamAdmin/reservation/MyPageReservationListPage';
import MyPageCouponListPage from '../page/teamAdmin/reservation/MyPageCouponListPage';
import PrivateReporting from '../../rehabcenter/pages/PrivateReportingComponent';

function MyPageRouter() {
    return (
        <>
            <MyPageHeader></MyPageHeader>
            <MyPageBody>
                <MyPageUserInfoHeader></MyPageUserInfoHeader>
                {/* <MyPageLeft>
                    <Switch>
                        <Route path="/mypage/user" component={UserPageSubMenuNav}></Route>
                        <Route path="/mypage/team-admin" component={MyPageTeamAdminNav}></Route>
                    </Switch>
                </MyPageLeft> */}
                <Switch>
                    <Route exact path="/" component={MainLayout}></Route>
                    <Route path="/trainning" component={TrainningRouter}></Route>
                    <PrivateRoute exact={true} path="/mypage/user/guide" component={UserMyPageGuideContainer}></PrivateRoute>
                    <PrivateRoute exact={true} path="/mypage/user/noti" component={UserMyPageNotiContainer}></PrivateRoute>
                    <PrivateRoute exact={true} path="/mypage/user/setting" component={UserMyPageSettingContainer}></PrivateRoute>
                    <PrivateRoute exact={true} path="/mypage/user/pw" component={UserMyPagePwPage}></PrivateRoute>
                    <PrivateRoute exact={true} path="/mypage/user/qna" component={UserMyPageQnAContainer}></PrivateRoute>
                    <PrivateRoute exact={true} path="/mypage/user/use-policy" component={UserMyPageUsePolicyContainer}></PrivateRoute>
                    <Route exact={true} path="/mypage/user/favorities" component={UserMyPageFavorities}></Route>
                    <PrivateRoute exact={true} path="/mypage/user/questionnaire" component={UserMyPageQuestionnairePage}></PrivateRoute>
                    <PrivateRoute
                        exact={true}
                        path="/mypage/user/questionnaire/:answerNo/:targetNo/:quesNo"
                        component={UserMyPageQuestionnaireViewPage}
                    ></PrivateRoute>
                    <PrivateRoute path="/mypage/team-admin/player-list" component={UserMyPagePlayerListOfTeamAdmin}></PrivateRoute>
                    <PrivateRoute
                        exact
                        path="/mypage/team-admin/reservation/:reservationNo/detail"
                        component={MyPageReservationDetailViewPage}
                    ></PrivateRoute>
                    <PrivateRoute
                        exact
                        path="/mypage/team-admin/reservation/:reservationGroupNo"
                        component={MyPageReservationListPage}
                    ></PrivateRoute>
                    <PrivateRoute exact={true} path="/mypage/team-admin/setting" component={UserMyPageSettingContainer}></PrivateRoute>
                    <PrivateRoute exact={true} path="/mypage/team-admin/qna" component={UserMyPageQnAContainer}></PrivateRoute>
                    <PrivateRoute exact path="/mypage/team-admin/reservation" component={MyPageReservationIndexPage}></PrivateRoute>
                    <PrivateRoute exact path="/mypage/player/reservation" component={MyPagePlayerReservationIndexPage}></PrivateRoute>
                    <PrivateRoute exact path="/mypage/team-admin/schedule" component={MyPageScheduleIndexPage}></PrivateRoute>
                    <PrivateRoute exact path="/mypage/team-admin/coupon" component={MyPageCouponListPage}></PrivateRoute>
                    <PrivateRoute exact path="/mypage/team-admin/schedule/all-view" component={MyPageScheduleAllViewPage}></PrivateRoute>
                    <PrivateRoute exact path="/mypage/team-admin/schedule/:scheduleNo/detail" component={MyPageScheduleDetailViewPage}></PrivateRoute>
                    <PrivateRoute exact path="/mypage/team-admin/schedule/:scheduleNo/modify" component={MypageScheduleModifyPage}></PrivateRoute>
                    <PrivateRoute exact path="/mypage/reservation/:reservationNo" component={PrivateReporting}></PrivateRoute>
                </Switch>
            </MyPageBody>
        </>
    );
}

export default MyPageRouter;
