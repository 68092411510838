import AbstractService from '../../AbstractService';
import BoardService from '../BoardService';
import Board from '../../../domain/board/Board';
import APIServerConfig from '../../../config/APIServerConfig';
class BoardServiceImpl extends AbstractService implements BoardService {
    getTeamBoardInfos(teamNo: string): Promise<Board[]> {
        return new Promise((resolve) => {
            APIServerConfig.recordAPIAxios.get(`/board/team/${teamNo}`)
            .then(response => {
                const board: Board[] = this.jsonConvert.deserializeArray(response.data, Board);
                resolve(board);  
            });
        });
    }
}

export default BoardServiceImpl;