import React from 'react';
import Infra from '../../../../../../domain/infra/Infra';

interface SportInfraFeeTabProps {
    infra: Infra;
}

function SportInfraFeeTab(props: SportInfraFeeTabProps) {
    return (
        <div className="home-wrap">
            <div className="home-title">
                <span>요금</span>						
            </div>				
            <div className="home-box bg-gay clear">
                {
                    
                    props.infra.childInfras ? 
                        props.infra.childInfras.length > 0 ?
                            props.infra.childInfras.map((childInfra, index) => {
                                
                            })
                            :
                            <h5 className="alert-h5">요금 정보가 없습니다.</h5>
                        :
                        <></>
                }

                {
                    props.infra.childInfras?.map((childInfra, index) => {
                        return (
                            <div key={index} className="price-table">
                                <div className="price-table-area"><p>{childInfra.name}({props.infra.reservationTimeUnit}시간)</p></div>
                                <div className="price-table-price">
                                    {
                                        childInfra.charges?.map((charge, index) => {
                                        return <p key={index} className="output-txt">{charge.chargeTypeCode?.name} <span>{charge.cost}원</span></p>
                                        })
                                    }
                                </div>
                            </div>
                        )
                    })
                }
                <div className="clear"></div>
            </div>					
        </div>
    )
}

export default SportInfraFeeTab;