import { useEffect, useState } from 'react';
import AnswerItem from '../../view/rehabcenter/domain/AnswerItem';
function useReportQuestionnarieAnswerMake(answerItemsMap?: Map<number, AnswerItem>) {
    const [reportData, setReportData] = useState<any>();

    useEffect(() => {
        if(answerItemsMap) {
            var reportData: any = {};
            answerItemsMap.forEach((answerItem: AnswerItem, index: number) => {
                reportData[`questionNum${answerItem.questionNum}`] = String(answerItem.itemNoOfAnswer);
            });

            setReportData(reportData);
        }
    }, [answerItemsMap]);

    return {
        reportData, setReportData
    }
}

export default useReportQuestionnarieAnswerMake;