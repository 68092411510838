import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject("rehabCenterMeasureByYear")
class RehabCenterMeasureByYear {
    @JsonProperty("year")
    year?: number;
    @JsonProperty("measureCnt")
    measureCnt?: number;
}

export default RehabCenterMeasureByYear;