import React from 'react';

export const bookingStatus = (status: any, statusId: any) => {
    let className: string = '';
    let name: string = '';

    if (statusId == 1001) {
        // 예약신청
        className = 'center-span-ask';
        name = '예약신청';
    } else if (statusId == 1002) {
        // 예약승인
        className = 'center-span-yes';
        name = '예약승인';
    } else if (statusId == 1003) {
        //방문예정
        name = '방문예정';
    } else if (statusId == 1004) {
        // 방문중
        name = '방문중';
    } else if (statusId == 1005) {
        // 예약종료
        className = 'center-span-no';
        name = '예약종료';
    } else if (statusId == 1006) {
        // 예약취소
        className = 'center-span-cancel';
        name = '예약취소';
    }

    return <span className={className}>{name}</span>;
};
