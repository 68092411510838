import React from 'react';
import Infra from '../../../../../../domain/infra/Infra';

interface SubInfraDetailInfoTabProps {
    infra: Infra;
}

function SubInfraDetailInfoTab(props: SubInfraDetailInfoTabProps) {
    let facDesc = props.infra.getDescription(props.infra.facilityDescription);
    let equDesc = props.infra.getDescription(props.infra.equipmentDescription);
    let etcDesc = props.infra.getDescription(props.infra.etcDescription);
    return (
        <>
            <div className="school">
                주소 : {props.infra.address}
                <br />
                홈페이지 :{' '}
                <a className="blue-line" href={props.infra.homepageUrl}>
                    {props.infra.homepageUrl}
                </a>
                <br />
                전화 : <span className="blue-line">{props.infra.phoneNumber}</span>
            </div>

            <div className="home-wrap">
                <div className="home-title">
                    <span>설명</span>
                </div>
                <div className="home-box clear">
                    <p className="output-txt" dangerouslySetInnerHTML={{ __html: facDesc }}></p>
                </div>
            </div>

            <div className="home-wrap">
                <div className="home-title">
                    <span>부속장비</span>
                </div>
                <div className="home-box clear">
                    <p className="output-txt" dangerouslySetInnerHTML={{ __html: equDesc }}></p>
                </div>
            </div>

            <div className="home-wrap">
                <div className="home-title">
                    <span>기타편의시설</span>
                </div>
                <div className="home-box clear">
                    <p className="output-txt" dangerouslySetInnerHTML={{ __html: etcDesc }}></p>
                </div>
            </div>
        </>
    );
}

export default SubInfraDetailInfoTab;
