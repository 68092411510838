import APIServerConfig from '../../../config/APIServerConfig';
import AttachFile from '../../../domain/file/AttachFile';
import AbstractService from '../../AbstractService';
import FileService from '../FileService';
class InfraFileServiceImpl extends AbstractService implements FileService {
    upload(attachFileTargetNo: string, file: File, mainFileYn: boolean): Promise<AttachFile> {
        console.log(mainFileYn);
        return new Promise((resolve, reject) => {
            const form = new FormData();
            form.append('file', file);
            form.append('mainFileYn', String(mainFileYn));
            APIServerConfig.recordAPIAxiosForFile.post(`/file/infra/${attachFileTargetNo}`, form)
            .then(response => {
                alert("업로드 완료")
                const attachFile: AttachFile = this.jsonConvert.deserializeObject(response.data, AttachFile);
                resolve(attachFile);
            })
            .catch(err => {
                alert("저장 실패");
                reject(err);
            })
        });
    }

    delete(attachFileTargetNo: string, savePathFile: string): Promise<string> {
        return new Promise((resolve, reject) => {
            const deletedAttachFile = new AttachFile();
            deletedAttachFile.saveFilePath = savePathFile;
            APIServerConfig.recordAPIAxios.post(`/file/infra/${attachFileTargetNo}/delete`, JSON.stringify(deletedAttachFile))   
            .then(response => {
                alert("삭제 완료")
                // const attachFile: AttachFile = this.jsonConvert.deserializeObject(response.data, AttachFile);

                resolve('delete');
            })
            .catch(err => {
                alert("저장 실패")
                reject(err);
            })
        })
    }
}

export default InfraFileServiceImpl;