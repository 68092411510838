import React from 'react';
import SearchWord from '../../domain/search/SearchWord';

interface SearchWordResultProps {
    setLastedSearchWord: (searchWord: string | undefined) => void;
    searchWords: SearchWord[] | undefined;
    title: string;
    setSearchWordVisible?: (searchWordVisible: boolean) => void;
}

function SearchWordResult(props: SearchWordResultProps) {
    let clickButtonHandler = () => {
        if (props.setSearchWordVisible) {
            props.setSearchWordVisible(false);
        }
    };

    return (
        // <div className="search_result_box">
        //     <h5 style={{marginRight: '3px'}}>{props.title}</h5>
        //     {
        //         props.searchWords?
        //         props.searchWords.map((searchWord: SearchWord, index) => {
        //             return (
        //                 <span style={{marginRight: '3px'}} key={index} onClick={() => {
        //                     props.setLastedSearchWord(searchWord.searchWord);
        //                 }}>{searchWord.searchWord}</span>
        //             )
        //         })
        //         :
        //         <span>{props.title} 없습니다.</span>
        //     }
        // </div>
        <>
            <div className="s-hot" style={{ color: 'black' }}>
                <span style={{ fontSize: '15px', fontWeight: 'bold' }}>{props.title}</span>&nbsp;&nbsp;
                <button
                    style={{ float: 'right', backgroundColor: 'red', border: '0px', borderRadius: '10px', textAlign: 'center', color: '#fff' }}
                    onClick={() => clickButtonHandler()}
                >
                    x
                </button>
                <br />
                {props.searchWords ? (
                    props.searchWords.map((searchWord: SearchWord, index: number) => {
                        return (
                            <span
                                className="searchword-result-span"
                                key={index}
                                style={{ padding: '0px 3px', cursor: 'pointer', float: 'left' }}
                                onClick={() => {
                                    props.setLastedSearchWord(searchWord.searchWord);
                                }}
                            >
                                {searchWord.searchWord}
                            </span>
                        );
                    })
                ) : (
                    <></>
                )}
            </div>
        </>
    );
}

export default SearchWordResult;
