import React, { useState } from 'react';
import TeamServiceImpl from '../../service/team/impl/TeamServiceImpl';
import Team from '../../domain/team/Team';
import { useHistory } from 'react-router-dom';
import TeamSearchCondition from '../../domain/team/param/TeamSearchCondition';
import VisitedTeamInfoSearchCondition from '../../domain/visitedInfo/pagam/VisitedTeamInfoSearchCondition';

interface AdminTeamTableProps {
    page: number;
    pageCnt: number;
    pagingSize: number;
    teams: Team[];
    searchCondition: TeamSearchCondition;
}

function AdminTeamTable(props: AdminTeamTableProps) {
    const history = useHistory();

    const detailViewHandler = (evt: any) => {
        var teamNo = evt.currentTarget.id;
        var condition: TeamSearchCondition = props.searchCondition;
        let groupIdx = Math.floor(props.page / props.pageCnt);
        condition.page = props.page;
        condition.groupIdx = groupIdx;

        history?.push({
            pathname: `/admin/team/detail/${teamNo}`,
            state: condition,
        });
    };

    return (
        <>
            <div className="member-list">
                <table>
                    <colgroup>
                        <col width="5%" />
                        <col width="10%" />
                        <col width="15%" />
                        <col width="10%" />
                        <col width="5%" />
                        <col width="10%" />
                        <col width="25%" />
                        <col width="20%" />
                    </colgroup>
                    <thead>
                        <tr>
                            <td>No.</td>
                            <td>지 역</td>
                            <td>분류그룹</td>
                            <td>분 류</td>
                            <td>성 별</td>
                            <td>종 목</td>
                            <td style={{ textAlign: 'left' }}>팀 명</td>
                            <td>관 리</td>
                        </tr>
                    </thead>
                    <tbody>
                        {props.teams ? (
                            props.teams.length > 0 ? (
                                props.teams.map((team: Team, index: number) => {
                                    return (
                                        <tr key={index}>
                                            <td>{props.page * props.pagingSize + (index + 1)}</td>
                                            <td>{`${team.regionCode?.name}`}</td>
                                            <td>{team.belongCode?.name}</td>
                                            <td>{team.classificationCode?.name}</td>
                                            <td>{team.genderCode?.name}</td>
                                            <td>{team.sportCode?.name}</td>
                                            <td style={{ textAlign: 'left' }}>{team.name}</td>
                                            <td>
                                                <button id={team.teamNo} className="bg-white" onClick={detailViewHandler}>
                                                    <i className="fas fa-search"></i>
                                                    보기
                                                </button>
                                            </td>
                                        </tr>
                                    );
                                })
                            ) : (
                                <tr>
                                    <td colSpan={7}>
                                        <h5>조회 조건에 맞는 내용이 없습니다.</h5>
                                    </td>
                                </tr>
                            )
                        ) : (
                            <></>
                        )}
                    </tbody>
                </table>
            </div>
        </>
    );
}

export default AdminTeamTable;
