import React, { useEffect } from 'react';
import AdminTable from './AdminTable';
import Infra from '../../domain/infra/Infra';
import { Link } from 'react-router-dom';
import useInfraRetrieve from '../../hook/infra/useInfraRetrieve';
import { InfraCategoryType } from '../../domain/infra/InfraCategoryType';

function AdminTouringGuideTable() {
    const {infras} = useInfraRetrieve(InfraCategoryType.TOURISM_NO);
    useEffect(() => {
        
    }, [infras])

    return (
        <>
        <AdminTable>
            <thead>
                <tr>
                    <td>No.</td>
                    <td>구분</td>
                    <td>시설이름</td>
                    <td>주소</td>
                    <td>전화번호</td>
                    <td>사이트</td>
                    <td>관리</td>
                </tr>
            </thead>
            <tbody>
                {
                    infras?.map((infra: Infra, index: number) => {
                        return (
                            <tr key={index}>
                                <td>{infra.infraNo?.substring(0,5)}</td>
                                <td>{infra.infraCategory?.name}</td>
                                <td>{infra.name}</td>
                                <td>{infra.address}</td>
                                <td>{infra.phoneNumber}</td>
                                <td>{infra.homepageUrl}</td>
                                <td></td>
                            </tr>
                        )
                    })
                }
            </tbody>
        </AdminTable>
        </>
    )
}

export default AdminTouringGuideTable;