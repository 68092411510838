import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import MyPageMainLayout from '../../../layout/MyPageMainLayout';
import MyPageRightContent from '../../../layout/MyPageRightContent';
import { RootState } from '../../../../../moldules/index';
import UserDetailInfo from '../../../../../domain/user/UserDetailInfo';
import { USER_SERVICE } from '../../../../../service/index';
import { SUCCESS_MESSAGE } from '../../../../../config/Constant';

function UserMyPagePwPage() {
    const {user} = useSelector((state: RootState) => state.userSessionReducer);
    const [originalPw, setOriginalPw] = useState<string>();
    const [newPw, setNwePw] = useState<string>();
    const [newConfirmedPw, setNewConfirmPw] = useState<string>();

    const [updateUser, setUpdateUser] = useState<UserDetailInfo>();

    
    useEffect(() => {
        if(updateUser) {
            USER_SERVICE.updateUserPw(updateUser)
            .then(() => {
                alert(SUCCESS_MESSAGE);
            })
            .catch(err => {
                alert("기존 비밀번호가 일치하지 않습니다.");
            });
        }
    }, [updateUser]);

    return (
        <MyPageMainLayout>
            <MyPageRightContent contentTitle={'비밀번호 설정'}>
                <nav>
                    <div className="menu-item menu-item-name">
                        <div className="notice-open">
                            <p className="mapage_tle2">기존 비밀번호</p>
                            <input className="basic-gray"
                                type="password"
                                onChange={(event) => {
                                    setOriginalPw(event.currentTarget.value);
                                }}
                            ></input>
                        </div>
                    </div>
                    <div className="menu-item">
                        <div className="notice-open">
                            <p className="mapage_tle2">새 비밀번호</p>
                            <input className="basic-gray"
                                type="password"
                                onChange={(event) => {
                                    setNwePw(event.currentTarget.value);
                                }}
                            ></input>
                        </div>
                    </div>
                    <div className="menu-item">
                        <div className="notice-open">
                            <p className="mapage_tle2">새 비밀번호 확인</p>
                            <input className="basic-gray"
                                type="password"
                                onChange={(event) => {
                                    setNewConfirmPw(event.currentTarget.value);
                                }}
                            ></input>
                        </div>
                    </div>
                </nav>
                <a className="bt-blue-2"
                    onClick={() => {
                        if(newPw == newConfirmedPw) {
                            user.originalPassword = originalPw;
                            user.password = newPw;
                            console.log(user);
                            setUpdateUser(Object.assign({}, user));
                        } else {
                            alert("변경 할 비밀번호가 일치 하지 않습니다.");
                        }
                    }}
                >수정</a>
            </MyPageRightContent>
        </MyPageMainLayout>
    )
}

export default UserMyPagePwPage;