import React, { useEffect } from 'react';
import { groupBy } from 'lodash';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../moldules/index';
import useQuestionnarieOfUser from '../../../../../hook/questionnaire/useQuestionnaireOfUser';

import QnA from '../../../../rehabcenter/domain/QnA';
import QnACard from '../../../components/QnACard';
import MyPageMainLayout from '../../../layout/MyPageMainLayout';
import MyPageRightContent from '../../../layout/MyPageRightContent';

function UserMyPageQuestionnairePage() {
    const { user } = useSelector((state: RootState) => state.userSessionReducer);
    const { questionnaireListOfUser } = useQuestionnarieOfUser(user.userNo);

    useEffect(() => {
        //console.log(questionnaireListOfUser);
        let grouped = groupBy(questionnaireListOfUser, (item: QnA) => {
            return item.reservationNo;
        });
        //console.log(grouped);
    }, [questionnaireListOfUser]);

    return (
        <MyPageMainLayout>
            <MyPageRightContent contentTitle="내 설문조사">
                {questionnaireListOfUser ? (
                    questionnaireListOfUser.length > 0 ? (
                        questionnaireListOfUser?.map((questionnaire: QnA, index: number) => {
                            return <QnACard idx={index} qna={questionnaire}></QnACard>;
                        })
                    ) : (
                        <h5>요청된 설문이 없습니다.</h5>
                    )
                ) : (
                    <h5>요청된 설문이 없습니다.</h5>
                )}
            </MyPageRightContent>
        </MyPageMainLayout>
    );
}

export default UserMyPageQuestionnairePage;
