import { Link } from "react-router-dom";
import SignUpHeader from "../layout/SignUpHeader";

function SearchPwUserIdConfirmPage() {
    return (
        <>
            <SignUpHeader title="비밀번호 찾기"></SignUpHeader>
            <div className="mgt10"></div>
            <div className="content-wrap">
                <div className="signup-content-wrap">
                    <h2>비밀번호 찾기</h2>
                    <div className="use-confirm-wrap-title">
                        <p>* 회원님의 비밀번호를 찾기를 위해 회원님의 아이디를 입력 바랍니다.</p>
                        <p>* 아이디를 잊어버리셨나요? <Link to="/idfinder/user-checker">아이디 찾기</Link></p>
                    </div>
                    <div className="mgt30"></div>
                    <input type="text" placeholder="아이디를 입력하세요."></input>
                    <button className="button-login">확인</button>
                    <div className="mgt30"></div>
                </div>
            </div>
        </>
    )
}

export default SearchPwUserIdConfirmPage;