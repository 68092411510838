import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import StatisticsMainHeader from '../layout/StatisticsMainHeader';
import StatisticsContainer from '../page/StatisticsContainer';
import StatusComponent from '../page/StatusComponent';
import SupportDetailComponent from '../page/SupportDetailComponent';
import '../css/common.css';

function StatisticsRouter({ match }: RouteComponentProps) {
    return (
        <>
            <StatisticsMainHeader></StatisticsMainHeader>
            <Switch>
                {/* <Route exact={true} path={match.path} component={RehabilitationMainContainer}></Route> */}
                {/* <Route path={`${match.path}/fitness`} component={RehabilitationFitnessContainer}></Route>
            <Route path={`${match.path}/sport-center`} component={RehabilitationSportCenterContainer}></Route> */}
                {/* <Route exact={true} path={match.path} component={StatisticsContainer}></Route> */}
                <Route exact={true} path={match.path} component={StatusComponent}></Route>
                <Route path={`${match.path}/support-detail`} component={SupportDetailComponent}></Route>
            </Switch>
        </>
    );
}

export default StatisticsRouter;
