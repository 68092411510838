import { useEffect, useState } from 'react';
import InfraServiceImpl from '../../service/infra/impl/InfraServiceImpl';
import Infra from '../../domain/infra/Infra';
const infraService = new InfraServiceImpl();
function useInfraRetrieveInfraNo(infraNo: string) {
    const [infra, setInfra] = useState<Infra>();

    useEffect(() => {
        if(infraNo) {
            infraService.getInfra(infraNo)
            .then(infra => {
                setInfra(infra);
            })
        }
    }, [infraNo]);

    return {infra, setInfra};
}

export default useInfraRetrieveInfraNo