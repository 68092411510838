import React, { useEffect, useState } from 'react';
import VisitedMemberAccumInfo from '../../../domain/visitedInfo/statistics/VisitedMemberAccumInfo';
import Code from '../../../domain/code/Code';
import AdminTable from '../../../component/admin/AdminTable';
import TotalVisitedTeamInfoSearchCondition from '../../../domain/visitedInfo/statistics/TotalVisitedTeamInfoSearchCondition';
import VisitedTeamInfoStatisticsServiceImpl from '../../../service/visitedInfo/impl/VisitedTeamInfoStatisticsServiceImpl';
import ChartDataUtils from '../../../utils/ChartDataUtils';
import DateUtils from '../../../utils/DateUtils';

interface VisitedTeamStatusByPeriodTableProps {
    searchCondition?: TotalVisitedTeamInfoSearchCondition;
    teamTypeCodes?: Code[];
    visitedTotalStatusByPeriod?: any;
    visitedStatusByPeriod?: any[];
}

const statisticsService: VisitedTeamInfoStatisticsServiceImpl = new VisitedTeamInfoStatisticsServiceImpl();

function VisitedTeamStatusByPeriodTables(props: VisitedTeamStatusByPeriodTableProps) {
    const [typeDetailTable, setTypeDetailTable] = useState<any>(<></>);

    let typeStatus: any[] | undefined = props.visitedStatusByPeriod;
    const [selectedCodeId, setSelectedCodeId] = useState<string>();

    let selectedlHandler = (selectedMonth: any) => {
        let condition = props.searchCondition ? props.searchCondition : new TotalVisitedTeamInfoSearchCondition();
        condition.visitedDate = selectedMonth;

        statisticsService.getVisitedTeamStatusDetail(condition).then((data) => {
            let detailTypeTable = (
                <>
                    <table>
                        <thead>
                            <tr>
                                <td colSpan={6} style={{ textAlign: 'left', paddingLeft: '5px', background: '#474747' }}>
                                    <i className="fa fa-check"></i>&nbsp;&nbsp; 일별 방문정보 상세 (선택날짜: [{selectedMonth}] )
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="txt-center" style={{ background: '#d9d9d9', width: '15%', height: '30px' }}>
                                    방문 일
                                </td>
                                <td className="txt-center" style={{ background: '#d9d9d9', width: '15%', height: '30px' }}>
                                    방문 팀명
                                </td>
                                <td className="txt-right" style={{ background: '#d9d9d9', width: '15%' }}>
                                    방문 지역
                                </td>
                                <td className="txt-right" style={{ background: '#d9d9d9', width: '15%', height: '30px' }}>
                                    방문 팀 유형
                                </td>
                                <td className="txt-right" style={{ background: '#d9d9d9', width: '15%', height: '30px' }}>
                                    방문 팀 종목
                                </td>
                                <td className="txt-right" style={{ background: '#d9d9d9', width: '15%' }}>
                                    방문 연 인원
                                </td>
                            </tr>
                            {data?.length > 0 ? (
                                data.map((state: any, index: number) => {
                                    console.log(state);
                                    return (
                                        <tr key={index}>
                                            <td>{state?.visitedDate}</td>
                                            <td>{state?.teamName}</td>
                                            <td className="txt-right">{state?.regionCodeName}</td>
                                            <td className="txt-right">{state?.teamTypeCodeName}</td>
                                            <td className="txt-right">{state?.sportsCodeName}</td>
                                            <td className="txt-right">{state.visitedMemberCnt}명</td>
                                        </tr>
                                    );
                                })
                            ) : (
                                <tr>
                                    <td colSpan={6}>
                                        <h5>조회 조건에 맞는 내용이 없습니다.</h5>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </>
            );
            setTypeDetailTable(detailTypeTable);
        });
    };

    useEffect(() => {
        setTypeDetailTable(
            <table>
                <thead>
                    <tr>
                        <td colSpan={6} style={{ textAlign: 'left', paddingLeft: '5px', background: '#474747' }}>
                            <i className="fa fa-check"></i>&nbsp;&nbsp; 일별 방문정보 상세 (선택 날짜: )
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="txt-center" style={{ background: '#d9d9d9', width: '15%', height: '30px' }}>
                            방문 일
                        </td>
                        <td className="txt-center" style={{ background: '#d9d9d9', width: '15%', height: '30px' }}>
                            방문 팀명
                        </td>
                        <td className="txt-right" style={{ background: '#d9d9d9', width: '15%' }}>
                            방문 지역
                        </td>
                        <td className="txt-right" style={{ background: '#d9d9d9', width: '15%', height: '30px' }}>
                            방문 팀 유형
                        </td>
                        <td className="txt-right" style={{ background: '#d9d9d9', width: '15%', height: '30px' }}>
                            방문 팀 종목
                        </td>
                        <td className="txt-right" style={{ background: '#d9d9d9', width: '15%' }}>
                            방문 연 인원
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={6} className="txt-center blue" style={{ height: '30px' }}>
                            날짜를 선택 하여 주십시오.
                        </td>
                    </tr>
                </tbody>
            </table>,
        );
    }, [typeStatus]);

    let typeTotal = props.visitedTotalStatusByPeriod;
    let dateGap =
        typeTotal?.visitedStartDate && typeTotal?.visitedEndDate
            ? DateUtils.getStrDayGap(typeTotal?.visitedStartDate, typeTotal?.visitedEndDate) + 1
            : 0;
    const typeTotalTable = (
        <>
            <table>
                <thead>
                    <tr>
                        <td colSpan={10} style={{ textAlign: 'left', paddingLeft: '5px', background: '#474747' }}>
                            <i className="fa fa-check"></i>&nbsp;&nbsp; 유치 팀 정보 집계 ({typeTotal?.visitedStartDate} ~ {typeTotal?.visitedEndDate}{' '}
                            &lt;{dateGap} 일간&gt;)
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="txt-center" style={{ background: '#d9d9d9', width: '8%', height: '30px' }}>
                            총 유치 팀 분류 수
                        </td>
                        <td className="txt-left" style={{ width: '8%' }}>
                            {typeTotal ? typeTotal.visitedTeamTypeCnt : 0} 분류
                        </td>
                        <td className="txt-center" style={{ background: '#d9d9d9', width: '10%', height: '30px' }}>
                            총 유치 팀 종목 수
                        </td>
                        <td className="txt-left" style={{ width: '10%' }}>
                            {typeTotal ? ChartDataUtils.getNumberFormat(Number(typeTotal.visitedSportsCnt)) : 0} 종목
                        </td>
                        <td className="txt-center" style={{ background: '#d9d9d9', width: '8%', height: '30px' }}>
                            총 유치 팀 수
                        </td>
                        <td className="txt-left" style={{ width: '8%' }}>
                            {typeTotal ? ChartDataUtils.getNumberFormat(Number(typeTotal.visitedTeamCnt)) : 0} 팀
                        </td>
                        {/* <td className="txt-center" style={{ background: '#d9d9d9', width: '8%', height: '30px' }}>
                            총 팀 방문 횟수
                        </td>
                        <td className="txt-left" style={{ width: '8%' }}>
                            {typeTotal ? ChartDataUtils.getNumberFormat(Number(typeTotal.teamVisitedCnt)) : 0} 회
                        </td> */}

                        <td className="txt-center" style={{ background: '#d9d9d9', width: '8%', height: '30px' }}>
                            총 유치 연 일수
                        </td>
                        <td className="txt-left" style={{ width: '8%' }}>
                            {typeTotal ? ChartDataUtils.getNumberFormat(Number(typeTotal.visitedDayCnt)) : 0} 일
                        </td>
                        <td className="txt-center" style={{ background: '#d9d9d9', width: '8%', height: '30px' }}>
                            총 유치 연 인원
                        </td>
                        <td className="txt-left" style={{ width: '14%' }}>
                            {typeTotal ? ChartDataUtils.getNumberFormat(Number(typeTotal.visitedMemberCnt)) : 0} 명
                        </td>
                    </tr>
                </tbody>
            </table>
        </>
    );

    let typeTable = (
        <>
            <table>
                <thead>
                    <tr>
                        <td colSpan={7} style={{ textAlign: 'left', paddingLeft: '5px', background: '#474747' }}>
                            <i className="fa fa-check"></i>&nbsp;&nbsp;일별 팀 방문정보 요약
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="txt-center" style={{ background: '#d9d9d9', width: '15%', height: '30px' }}>
                            방문 일
                        </td>
                        <td className="txt-right" style={{ background: '#d9d9d9', width: '10%' }}>
                            팀 수
                        </td>
                        <td className="txt-right" style={{ background: '#d9d9d9', width: '15%', height: '30px' }}>
                            방문지역수
                        </td>
                        <td className="txt-right" style={{ background: '#d9d9d9', width: '15%', height: '30px' }}>
                            유치종목수
                        </td>
                        <td className="txt-right" style={{ background: '#d9d9d9', width: '18%' }}>
                            방문 연 인원
                        </td>
                        <td className="txt-center" style={{ background: '#d9d9d9', width: '15%' }}>
                            상세정보
                        </td>
                    </tr>
                    {typeStatus && typeStatus.length > 0 ? (
                        typeStatus.map((state: any, index: number) => {
                            return (
                                <tr key={index}>
                                    <td>{state.visitedDate}</td>
                                    <td className="txt-right">{state?.visitedTeamCnt} 팀</td>
                                    <td className="txt-right">{ChartDataUtils.getNumberFormat(Number(state.visitedRegionCnt))} 지역</td>
                                    <td className="txt-right">{ChartDataUtils.getNumberFormat(Number(state.visitedSportsCnt))} 종목</td>
                                    <td className="txt-right">{ChartDataUtils.getNumberFormat(Number(state.visitedMemberCntForYear))} 명</td>
                                    <td>
                                        <button
                                            id={state.yearMonth}
                                            className="bg-light-blue"
                                            style={{ fontWeight: 'bold', borderRadius: '4px', padding: '3px' }}
                                            onClick={() => selectedlHandler(state.visitedDate)}
                                        >
                                            <i className="fas fs-search"></i>
                                            상세확인
                                        </button>
                                    </td>
                                </tr>
                            );
                        })
                    ) : (
                        <tr>
                            <td colSpan={7}>
                                <h5>조회 조건에 맞는 내용이 없습니다.</h5>
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </>
    );

    return (
        <>
            <div className="flex-basic">
                <div className="member-list" style={{ width: '100%' }}>
                    {typeTotalTable}
                </div>
            </div>
            <div className="two-wrap">
                <div className="half" style={{ width: '40%' }}>
                    <div className="member-list" style={{ width: '98%' }}>
                        {typeTable}
                    </div>
                </div>
                <div className="half" style={{ width: '60%' }}>
                    <div className="member-list" style={{ width: '100%' }}>
                        {typeDetailTable}
                    </div>
                </div>
            </div>
        </>
    );
}

export default VisitedTeamStatusByPeriodTables;
