import React, { useEffect, useState } from 'react';
import InBodyForm from './recording/forms/tabs/InBodyForm';
import FormCheckerForm from './recording/forms/tabs/FormCheckerForm';
import FootCheckerForm from './recording/forms/tabs/FootCheckForm';
import BioDexForm from './recording/forms/tabs/BioDexForm';
import TreadMillTest from './recording/forms/tabs/TreadMillTestForm';
import SpecialPhysical from '../../domain/recording/SpecialPhysical';
import RehabTargetHistory from '../../domain/RehabTargetHistory';
import RehabHistoryServiceImpl from '../../service/impl/RehabHistoryServiceImpl';
import RehabHistoryService from '../../service/RehabHistoryService';
import { confirmAlert } from 'react-confirm-alert';

interface IProps {
    status: SpecialPhysical;
    ocr?: { inbody: string; pomChecker: string; footChecker: string; biodex: string; treadMill: string };
    history: RehabTargetHistory;
}
const rehabHistoryService: RehabHistoryService = new RehabHistoryServiceImpl();

export default function SpecialPhysicalForms(props: IProps) {
    const ocr = props.ocr;
    let isDisplay = { display: 'block' };
    let isNotDisplay = { display: 'none' };
    let cursorStyl = { cursor: 'pointer' };
    let tabOn = 'tabon';
    let tabOff = 'taboff';

    const [tabInbody, setTabInbody] = useState<any>(tabOn);
    const [tabFormChecker, setTabFormChecker] = useState<any>(tabOff);
    const [tabFootChecker, setTabFootChecker] = useState<any>(tabOff);
    const [tabBioDex, setTabBioDex] = useState<any>(tabOff);
    const [tabTreadMill, setTabTreadMill] = useState<any>(tabOff);

    const [tabInbodyStyle, setTabInbodyStyle] = useState<any>(isDisplay);
    const [tabFormCheckerStyle, setTabFormCheckerStyle] = useState<any>(isNotDisplay);
    const [tabFootCheckerStyle, setTabFootCheckerStyle] = useState<any>(isNotDisplay);
    const [tabBioDexStyle, setTabBioDexStyle] = useState<any>(isNotDisplay);
    const [tabTreadMillStyle, setTabTreadMillStyle] = useState<any>(isNotDisplay);

    const [specialPhysical, setSpecialPhysical] = useState<SpecialPhysical>();

    useEffect(() => {
        if (props.status) setSpecialPhysical(props.status);
        else setSpecialPhysical(new SpecialPhysical());
    }, [props.status]);

    const tabHandler = (evt: any) => {
        let target = evt.target.id;

        setTabInbody(tabOff);
        setTabFormChecker(tabOff);
        setTabFootChecker(tabOff);
        setTabBioDex(tabOff);
        setTabTreadMill(tabOff);

        setTabInbodyStyle(isNotDisplay);
        setTabFormCheckerStyle(isNotDisplay);
        setTabFootCheckerStyle(isNotDisplay);
        setTabBioDexStyle(isNotDisplay);
        setTabTreadMillStyle(isNotDisplay);

        if (target === 'inbody') {
            setTabInbodyStyle(isDisplay);
            setTabInbody(tabOn);
        } else if (target === 'formChecker') {
            setTabFormCheckerStyle(isDisplay);
            setTabFormChecker(tabOn);
        } else if (target === 'footChecker') {
            setTabFootCheckerStyle(isDisplay);
            setTabFootChecker(tabOn);
        } else if (target === 'bioDex') {
            setTabBioDexStyle(isDisplay);
            setTabBioDex(tabOn);
        } else if (target === 'treadMillTest') {
            setTabTreadMillStyle(isDisplay);
            setTabTreadMill(tabOn);
        }
    };

    const inputHandler = (evt: any) => {
        let subProp = evt.target.id;
        let val = evt.target.value;
        let target = evt.target.name;
        if (isNaN(val)) {
            confirmAlert({
                title: '경고',
                message: '숫자만 입력 가능 합니다.',
                buttons: [{ label: '확인', onClick: () => {} }],
            });
        } else {
            setSpecialPhysical((prevState) => {
                if (prevState) {
                    if (target === 'inBody') {
                        return { ...prevState, inBody: { ...prevState.inBody, [subProp]: [val, '', ''] } };
                    } else if (target === 'formChecker') {
                        return { ...prevState, formChecker: { ...prevState.formChecker, [subProp]: val } };
                    } else if (target === 'footChecker') {
                        return { ...prevState, footChecker: { ...prevState.footChecker, [subProp]: val } };
                    } else if (target === 'bioDex') {
                        return { ...prevState, bioDex: { ...prevState.bioDex, [subProp]: val } };
                    } else if (target === 'treadMillTest') {
                        return { ...prevState, treadMillTest: { ...prevState.treadMillTest, [subProp]: val } };
                    }
                }
            });
        }
    };

    const storeHandler = (evt: any) => {
        let history: RehabTargetHistory = props.history;
        history.specialPhysical = JSON.stringify(specialPhysical);

        rehabHistoryService.manageSpecialPhysicalForTarget(history).then((result: RehabTargetHistory) => {
            confirmAlert({
                title: '저장 성공',
                message: '전문체력 측정 정보를 저장 하였습니다.',
                buttons: [{ label: '확인', onClick: () => {} }],
            });
        });
    };

    return (
        <>
            <div className="center-popup-title">
                <h1 className="center">
                    <i className="fa fa-check"></i> 전문체력측정
                </h1>

                <div>
                    <button id="complete" className="center-button-complete" onClick={storeHandler}>
                        저 장 <i className="fas fa-file-download"></i>
                    </button>
                </div>
            </div>

            <div className="center-popup-list-tab">
                <div className="tab-menu">
                    <ul>
                        <li id="inbody" className={tabInbody} onClick={tabHandler} style={cursorStyl}>
                            인바디
                            <br />
                            (체성분검사)
                        </li>
                        <li id="formChecker" className={tabFormChecker} onClick={tabHandler} style={cursorStyl}>
                            폼체커
                        </li>
                        <li id="footChecker" className={tabFootChecker} onClick={tabHandler} style={cursorStyl}>
                            풋체커
                            <br />
                            (족저압)
                        </li>
                        <li id="bioDex" className={tabBioDex} onClick={tabHandler} style={cursorStyl}>
                            바이오덱스
                            <br />
                            (등속성운동)
                        </li>
                        <li id="treadMillTest" className={tabTreadMill} onClick={tabHandler} style={cursorStyl}>
                            가스분석기
                            <br />
                            (온동부하검사)
                        </li>
                    </ul>
                </div>
                <div className="tab-story">
                    <div id="tabstory1" style={tabInbodyStyle}>
                        <InBodyForm handler={inputHandler} status={specialPhysical}></InBodyForm>
                    </div>
                    <div id="tabstory2" style={tabFormCheckerStyle}>
                        <FormCheckerForm handler={inputHandler} status={specialPhysical}></FormCheckerForm>
                    </div>
                    <div id="tabstory3" style={tabFootCheckerStyle}>
                        <FootCheckerForm handler={inputHandler} status={specialPhysical}></FootCheckerForm>
                    </div>
                    <div id="tabstory4" style={tabBioDexStyle}>
                        <BioDexForm handler={inputHandler} status={specialPhysical} ocr={''}></BioDexForm>
                    </div>
                    <div id="tabstory5" style={tabTreadMillStyle}>
                        <TreadMillTest handler={inputHandler} status={specialPhysical} ocr={''}></TreadMillTest>
                    </div>
                </div>
            </div>
        </>
    );
}
