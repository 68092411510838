import React, { useState, useEffect } from 'react';
import { confirmAlert } from 'react-confirm-alert';

function PaginationComponent(
    handler: (evt: any) => void | undefined,
    totalPageNum: number | undefined,
    currentPage: number | undefined,
    displaySize: number | undefined,
) {
    const [totalPages, setTotalPages] = useState<number>(totalPageNum ? totalPageNum : 1);
    const [currPage, setCurrPage] = useState<number>(currentPage ? currentPage : 0);
    const [lastPageNum, setLastPageNum] = useState<number>(1);
    //let currPage = currentPage ? currentPage : 0;
    const [pageSize, setPageSize] = useState<number>(displaySize ? displaySize : 10);
    //console.log(totalPageNum);
    //console.log(displaySize);

    useEffect(() => {
        if (totalPageNum) {
            let lastPageNum = Math.floor(totalPageNum / pageSize);
            let isRest = totalPageNum % pageSize;
            if (isRest > 0) lastPageNum++;
            setLastPageNum(lastPageNum);
        }
    }, [totalPageNum]);

    useEffect(() => {
        movingPage();
    }, [currPage]);

    const movingPage = () => {
        let pagination: { size: number; page: number } = { size: pageSize, page: currPage };
        handler(pagination);
    };

    const changePageSizeHandler = (evt: any) => {
        let pageElem = document.getElementById('pageIdx');
        setCurrPage(0);
        setPageSize(Number(evt.target.value));
        //if (pageElem) pageElem.innerHTML = (currPage + 1).toString();
        movingPage();
    };

    const pagingHandler = (evt: any) => {
        let direct = evt.target.value;

        if (direct === 'left') {
            if (currPage !== 0) {
                setCurrPage(currPage - 1);
                //if (pageElem) pageElem.innerHTML = (currPage + 1).toString();
                // movingPage();
            } else {
                confirmAlert({
                    title: '경고',
                    message: '첫 페이지 입니다.',
                    buttons: [
                        {
                            label: '확인',
                            onClick: () => {},
                        },
                    ],
                });
            }
        } else if (direct === 'right') {
            if (currPage + 1 < lastPageNum) {
                setCurrPage(currPage + 1);
                //if (pageElem) pageElem.innerHTML = (currPage + 1).toString();

                //movingPage();
            } else {
                confirmAlert({
                    title: '경고',
                    message: '마지막 페이지 입니다.',
                    buttons: [
                        {
                            label: '확인',
                            onClick: () => {},
                        },
                    ],
                });
            }
        } else if (direct === 'begin') {
            setCurrPage(0);
            //if (pageElem) pageElem.innerHTML = (currPage + 1).toString();
            //movingPage();
        } else if (direct === 'last') {
            setCurrPage(lastPageNum - 1);
            //if (pageElem) pageElem.innerHTML = (currPage + 1).toString();
            //movingPage();
        }
    };

    return (
        <>
            <div>{/* <a className="center-a-no">
                    <i className="fas fa-trash-alt"></i> 삭제
                </a> */}</div>
            <div>
                <ul className="center-pagenumbering">
                    <li>
                        <button value="begin" onClick={pagingHandler}>
                            &lt;&lt;
                        </button>
                    </li>
                    <li>
                        <button value="left" onClick={pagingHandler}>
                            &lt;
                        </button>
                    </li>
                    <li style={{ width: '90px' }}>
                        <button id="pageIdx" className="page-on" style={{ width: '100%' }}>
                            {currPage + 1}P - 총{lastPageNum}P
                        </button>
                    </li>
                    <li>
                        <button value="right" onClick={pagingHandler}>
                            &gt;
                        </button>
                    </li>
                    <li>
                        <button value="last" onClick={pagingHandler}>
                            &gt;&gt;
                        </button>
                    </li>
                </ul>
            </div>
            <div className="flex-basic">
                <select id="configPages" value={pageSize} className="center-select" onChange={changePageSizeHandler}>
                    <option value={10}>10개</option>
                    <option value={20}>20개</option>
                </select>
                <label> 씩 보기</label>
            </div>
        </>
    );
}

export default PaginationComponent;
