import React, { useEffect, useState } from 'react';
import RehabServiceImpl from '../../../service/impl/RehabServiceImpl';
import { JsonConvert } from 'json2typescript';
import Button from '../utils/ElementsComponent';
import GeneraterQuestionnaires from './utils/GenerateQuestionnaires';
import QnA from '../../../domain/QnA';
import TargetPerson from '../../../domain/TargetPerson';
import { confirmAlert } from 'react-confirm-alert';

const rehabService = new RehabServiceImpl();
const jsonConvert: JsonConvert = new JsonConvert();
interface popupParam {
    domain?: string;
    targetPerson?: TargetPerson;
    closingHandler: () => void;
}

export default function MngQuestionnaireSheetComponent(props: popupParam) {
    const [questionnaires, setQuestionnaires] = useState<QnA[]>();
    const [answers, setAnswers] = useState<QnA[]>();
    const [isQuestTab, setIsQuestTab] = useState<any>(true);
    const [isResultTab, setIsResultTab] = useState<any>(false);
    const [isQuestContent, setIsQuestContent] = useState<any>({ display: 'block' });
    const [isResultContent, setIsResultContent] = useState<any>({ display: 'none' });

    const [targetPerson, setTargetPerson] = useState<TargetPerson>();

    let answerList: QnA[] = new Array();

    useEffect(() => {
        if (props.targetPerson) {
            setTargetPerson(props.targetPerson);
            let targetNo = props.targetPerson.targetNo;
            let reservationNo = props.targetPerson.reservationNo;

            rehabService.getQuestionnairesNAnswer(targetNo, reservationNo, props.domain).then((data: any) => {
                let qnaList = data.qnaList;
                setQuestionnaireStatus(qnaList);
            });
        }
    }, [props.targetPerson]);

    const sendQuestionnaireHandler = (quesNo: number, targetNo?: number, reservationNo?: string, domain?: string) => {
        if (targetNo) {
            rehabService.sendQuestionnaireToTargetNo(targetNo, quesNo, reservationNo, domain).then((data: any) => {
                console.log(data);
                let qnaList = data.qnaList;
                let question = questionnaires?.find((item) => {
                    return item.quesNo == quesNo;
                });
                let message = targetPerson?.reservater?.name + '님 에게 설문 "' + question?.displayTitle + '"을 발송하였습니다.';
                confirmAlert({
                    title: '발송성공',
                    message: message,
                    buttons: [
                        {
                            label: '확인',
                            onClick: () => {
                                console.log(qnaList);
                                setQuestionnaireStatus(qnaList);
                            },
                        },
                    ],
                });
            });
        }
    };
    const cancelQuestionnaireHandler = (answerNo: number, targetNo?: number, reservationNo?: string, domain?: string) => {
        rehabService.cancelQuestionnaireToTargetNo(answerNo, targetNo, reservationNo, domain).then((data: any) => {
            let qnaList = data.qnaList;
            confirmAlert({
                title: '발송취소성공',
                message: '설문 발송이 취소 되었습니다. 감사합니다.',
                buttons: [
                    {
                        label: '확인',
                        onClick: () => {
                            console.log(qnaList);
                            setQuestionnaireStatus(qnaList);
                        },
                    },
                ],
            });
        });
    };
    const openQuestionnaireListHandler = (e: any) => {
        e.preventDefault();
        setIsQuestTab(true);
        setIsResultTab(false);
        setIsQuestContent({ display: 'block' });
        setIsResultContent({ display: 'none' });
    };
    const openResultHandler = (e: any) => {
        e.preventDefault();
        setIsQuestTab(false);
        setIsResultTab(true);
        setIsQuestContent({ display: 'none' });
        setIsResultContent({ display: 'block' });
    };

    function setQuestionnaireStatus(qnaList: QnA[]) {
        qnaList.map((questionnaire, idx) => {
            let answerNo = questionnaire.answerNo;
            if (answerNo !== 0) {
                answerList.push(questionnaire);
                questionnaire.isSend = true;
            }
        });

        setQuestionnaires(qnaList);
        setAnswers(answerList);
    }

    const sendState = (questionnaire: QnA) => {
        let isSend = questionnaire.isSend;
        let className = 'fas fa-share-square';
        let name = '발송하기';
        if (isSend) {
            className = 'fas fa-check-circle';
            name = '발송취소';
        }

        return (
            <>
                <i className={className}></i> {name}
            </>
        );
    };
    const contents = (list: QnA[] | undefined) => {
        return (
            <>
                {list?.map((questionnaire: QnA, idx) => {
                    let isSend = questionnaire.isSend;
                    let className = 'center-button-no';
                    let btn = (
                        <button
                            className={className}
                            value={questionnaire.quesNo}
                            onClick={(e: any) => {
                                let quesNo = e.target.value;
                                sendQuestionnaireHandler(
                                    quesNo,
                                    targetPerson?.targetNo,
                                    targetPerson?.reservationNo,
                                    props.domain ? props.domain : 'REHAB',
                                );
                            }}
                        >
                            {sendState(questionnaire)}
                        </button>
                    );
                    if (isSend) {
                        className = 'center-button-cancle';
                        btn = (
                            <button
                                value={questionnaire.answerNo}
                                className={className}
                                onClick={(e: any) => {
                                    let answerNo = e.target.value;
                                    cancelQuestionnaireHandler(
                                        answerNo,
                                        targetPerson?.targetNo,
                                        targetPerson?.reservationNo,
                                        props.domain ? props.domain : 'REHAB',
                                    );
                                }}
                            >
                                {sendState(questionnaire)}
                            </button>
                        );
                    }

                    return (
                        <div className="center-popup-list" key={idx}>
                            <span>{questionnaire.displayTitle}</span>
                            {btn}
                        </div>
                    );
                })}
            </>
        );
    };

    const popupContents = (
        <>
            <div className="center-popup-title">
                <h1 className="center">설문지 발송 및 확인</h1>
                <div className="flex">
                    <h1 className="center">
                        <i className="fas fa-user-tie"></i> {targetPerson?.reservater?.name}
                    </h1>
                    &nbsp;
                    <span className="center-birth" style={{ fontWeight: 700, fontSize: '14px' }}>
                        검진일자: {targetPerson?.reservation?.startDate} / 생년월일 : {targetPerson?.reservater?.birthdate} / 성별:{' '}
                        {targetPerson?.reservater?.genderCode?.name}
                    </span>
                </div>
                <button className="center-button-close" onClick={props.closingHandler}>
                    닫기 <i className="fas fa-times-circle"></i>
                </button>
            </div>
            <div className="quest-popup-cont">
                <div className="center-popup-bnt">
                    <a href="" id="questTab" className={isQuestTab ? 'on' : ''} onClick={openQuestionnaireListHandler}>
                        <i className="fas fa-file-import"></i>설문지 발송하기
                    </a>
                    <a href="" id="resultTab" className={isResultTab ? 'on' : ''} onClick={openResultHandler}>
                        <i className="fas fa-file-alt"></i>설문결과 보기
                    </a>
                </div>
                <div className="center-popup-list-box" style={isQuestContent}>
                    {contents(questionnaires)}
                </div>

                <div className="quest-anwser-tab" style={isResultContent}>
                    <GeneraterQuestionnaires answers={answers} handler={openResultHandler}></GeneraterQuestionnaires>
                </div>
            </div>
        </>
    );

    return popupContents;
}
