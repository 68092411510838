import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router';

import UserServiceImpl from '../../../../service/user/impl/UserServiceImpl';
import useRetrieveUserInfo from '../../../../hook/user/useRetrieveUserInfo';
import UserDetailInfo from '../../../../domain/user/UserDetailInfo';
import RoleNameUtils from '../../../../utils/RoleNameUtils';
import AdminTable from '../../../../component/admin/AdminTable';
import AdminFormTitle from '../../../../component/admin/AdminFormTitle';
import RoleSearchCondition from '../../../../domain/code/param/RoleSearchCondition';
import useReoleRetrieve from '../../../../hook/code/useRoleRetrieve';
import Roles from '../../../../domain/user/Roles';
import Code from '../../../../domain/code/Code';
import useCodeRetrieve from '../../../../hook/code/useCodeRetrieve';
import { CodeType } from '../../../../domain/code/CodeType';
import useChildCodeRetrieve from '../../../../hook/code/useChildCodeRetrieve';
import useAdminManagerInfo from '../../hook/useAdminManagerInfo';
import UserModifyParam from '../../../../domain/user/param/UserModifyParam';
import { confirmAlert } from 'react-confirm-alert';
import TeamSearchInput from '../../../../component/input/TeamSearchInput';
import UserSearchCondition from '../../../../domain/user/param/UserSearchCondition';
import BelongTeam from '../../../../domain/user/BelongTeam';
import _ from 'underscore';
import Team from '../../../../domain/team/Team';

interface MatchParams {
    memberNo: string;
}

const userService = new UserServiceImpl();

function MembersInfoContainer(props: MatchParams) {
    const history = useHistory();
    const location = useLocation();

    const [emailChangeConfirm, setEmailChangeConfirm] = useState<boolean>(false);
    const {
        userDetailInfo: user,
        userBelongedTeams: retrievedBelongedTeams,
        userRoleGroupNo,
        setUserRoleGroupNo,
        userRole,
        userRoleNo,
        setUserRoleNo,
        userName,
        setUserName,
        userPhoneNumber,
        setUserPhoneNumber,
        userEmail,
        setUserEmail,
        userPassword,
        setUserPassword,
        userAuthority,
        setUserAuthority,
        setRoleSearchCondition,
        userParentRegionCodeId,
        setUserParentRegionCodeId,
        userRegionCodeId: userChildRegionCodeId,
        setUserRegionCodeId: setUserChildRegionCodeId,
        roles,
        userClassificationCodeId,
        setUserClassificationCodeId,
        userSportCodeId,
        setUserSportCodeId,
        userBelongedCodeId,
        setUserBelongedCodeId,
    } = useAdminManagerInfo(props.memberNo);

    const { codes: sportCodes, setCodes: setSportCodes } = useCodeRetrieve(CodeType.SPORT);
    const { codes: regionCodes, setCodes: setRegionCodes } = useCodeRetrieve(CodeType.REGION);
    const { codes: classificationCodes, setCodes: setClassificationCodes } = useCodeRetrieve(CodeType.CLASSIFICATION);
    const childRegionCodes = useChildCodeRetrieve(userParentRegionCodeId);

    const [userModifyParam, setUserModifyParam] = useState<UserModifyParam>();
    const [belongedTeams, setBelongedTeams] = useState<BelongTeam[] | undefined>();
    const [belongedTeamNo, setBelongedTeamNo] = useState<string | undefined>();
    const [belongTeam, setBelongTeam] = useState<BelongTeam | undefined>();
    const [belongTeamInfo, setBelongTeamInfo] = useState<Team | undefined>();

    const goBack = () => {
        let condition: UserSearchCondition | any = location.state;
        history?.replace({
            pathname: '/admin/members',
            state: condition,
        });
    };

    const getUpdateUserDetailInfo = (evt: any) => {
        if (user && userPhoneNumber && userChildRegionCodeId && userRoleNo && user.authorities) {
            user.name = userName;
            user.regionCodeId = userChildRegionCodeId;
            user.authorities[0].roleNo = userRoleNo;

            if (belongTeamInfo) {
                let team: Team = belongTeamInfo;
                user.belongedCodeId = team.belongedCodeId;
                user.classificationCodeId = team.classificationCodeId;
                user.sportCodeId = team.sportCodeId;
            }
        }

        const userModifyParam = UserModifyParam.getInstance(user, belongTeam);
        setUserModifyParam(userModifyParam);
    };

    const addBelongTeamHandler = (evt: any) => {
        let newArr: BelongTeam[] = [];
        belongedTeams?.map((team) => newArr.push(team));
        let addObj = new BelongTeam();
        addObj.belongedUserNo = user?.userNo;

        newArr.push(addObj);

        setBelongedTeams(newArr);
    };

    const deleteBelongTeamHandler = (evt: any) => {
        let delTarget = evt.currentTarget.id;
        let newObj: BelongTeam[] = [];
        belongedTeams?.map((team, idx) => {
            if (idx.toString() !== delTarget) newObj.push(team);
        });
        setBelongedTeams(newObj);
    };

    const changeBelongTeamHandler = (evt: any) => {
        let chgTarget = evt.currentTarget.id;
        let chgVal = evt.target.value === '1' ? true : false;
        let newObj: BelongTeam[] = [];

        belongedTeams?.map((team, idx) => {
            if ('be_' + idx === chgTarget) {
                team.deleteYn = chgVal;
            } else {
                team.deleteYn = true;
            }
            newObj.push(team);
        });
        setBelongedTeams(newObj);
    };

    useEffect(() => {
        if (userModifyParam) {
            userService.updateUserInfo(userModifyParam).then(() => {
                confirmAlert({
                    title: '수정 성공',
                    message: '정상적으로 수정 되었습니다. 목록 화면으로 이동합니다.',
                    buttons: [
                        {
                            label: '확인',
                            onClick: () => {
                                goBack();
                            },
                        },
                    ],
                });
            });
        }
    }, [userModifyParam]);

    useEffect(() => {
        setBelongedTeams(retrievedBelongedTeams); // 조회 된 소속팀 정보 설정
        retrievedBelongedTeams?.map((team, idx) => {
            if (!team.deleteYn) {
                setBelongTeam(team); //현재 소속 정보
                setBelongTeamInfo(team.belongedTeam); //현재 소속 팀 정보 설정
            }
        });
    }, [retrievedBelongedTeams]);

    useEffect(() => {
        setBelongTeam((prevState) => {
            if (prevState) {
                return { ...prevState, ['belongedTeamNo']: belongTeamInfo?.teamNo ? belongTeamInfo?.teamNo : '' };
            } else {
                let newBelongTeam = new BelongTeam();
                newBelongTeam.belongedTeamNo = belongTeamInfo?.teamNo ? belongTeamInfo?.teamNo : '';
                newBelongTeam.belongedUserNo = user?.userNo;
                return newBelongTeam;
            }
        });
    }, [belongTeamInfo]);

    useEffect(() => {
        setRoleSearchCondition(RoleSearchCondition.getTeamRoleGroupCondition());
    }, []);

    let title = (
        <>
            회원상세{' '}
            <span
                className="navy white roundbox"
                style={{ fontWeight: 'normal', borderRadius: '5px', fontSize: '12px', padding: '2px', margin: '0 auto' }}
            >
                {userRole?.roleDesc}
            </span>
        </>
    );
    const btnStyle: any = {
        float: 'right',
        padding: '5px 10px',
        marginBottom: '5px',
        marginTop: '5px',
        fontWeight: 700,
    };
    const tdStyle: any = {
        padding: '5px 0',
    };
    const bg_gr: any = { backgroundColor: '#ffc107', color: '#3a4652', fontWeight: 'bold' };

    let belongedTeamTb =
        belongedTeams?.length === 0 ? (
            <tr>
                <td colSpan={2}>소속 된 팀이 없습니다.</td>
            </tr>
        ) : (
            belongedTeams?.map((team: BelongTeam, idx: number) => {
                let belongedTeamName = team.belongedTeam ? team.belongedTeam.name : '';
                return (
                    <tr>
                        <td>
                            {
                                <TeamSearchInput
                                    teamName={belongedTeamName}
                                    setTeamNo={setBelongedTeamNo}
                                    setSelectedTeam={setBelongTeamInfo}
                                ></TeamSearchInput>
                            }
                        </td>
                        <td>{team.registeDate}</td>
                        {/* <td>
                            <select id={`be_` + idx} value={team.deleteYn ? '1' : '0'} onChange={changeBelongTeamHandler}>
                                <option value={'0'}>현재소속</option>
                                <option value={'1'}>이전소속</option>
                            </select>
                        </td> */}
                        {/* <td>
                            <button id={idx.toString()} className="bg-light-blue" onClick={deleteBelongTeamHandler}>
                                삭 제
                            </button>
                        </td> */}
                    </tr>
                );
            })
        );

    return (
        <>
            <AdminFormTitle title={title} />
            <div className="two-wrap">
                <div className="half">
                    <div className="member-list">
                        <table cellPadding="0" cellSpacing="0">
                            <colgroup>
                                <col style={{ width: '150px', backgroundColor: '#f2f2f2' }} />
                                <col />
                            </colgroup>
                            <thead>
                                <tr>
                                    <td colSpan={2} style={tdStyle}>
                                        <button style={btnStyle} className="bg-blue white" onClick={getUpdateUserDetailInfo}>
                                            수 정
                                        </button>
                                        <button onClick={goBack} style={{ ...btnStyle, ...bg_gr }}>
                                            돌아가기
                                        </button>
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="txt-left">
                                        <div className=" pdl30 blue">아이디(이메일)</div>
                                    </td>
                                    <td className="txt-left">
                                        <input
                                            disabled
                                            type="text"
                                            value={userEmail}
                                            onChange={(event) => {
                                                if (emailChangeConfirm) {
                                                    setUserEmail(event.currentTarget.value);
                                                } else {
                                                    window.confirm('이메일 변경 시 추후 이메일 인증이 필요합니다. 그래도 수정 하시겠습니까?');
                                                    // if(emailChangeConfirm) {
                                                    //     setEmailChangeConfirm(true);
                                                    // }
                                                    alert('?');
                                                }
                                            }}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="txt-left">
                                        <div className=" pdl30 blue">이름</div>
                                    </td>
                                    <td className="txt-left">
                                        <input
                                            type="text"
                                            value={userName}
                                            onChange={(event) => {
                                                setUserName(event.currentTarget.value);
                                            }}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="txt-left">
                                        <div className=" pdl30 blue">회원등급</div>
                                    </td>
                                    <td className="txt-left">
                                        <select
                                            onChange={(event) => {
                                                setUserRoleNo(Number(event.currentTarget.value));
                                            }}
                                        >
                                            {roles?.map((role: Roles, index: number) => {
                                                return (
                                                    <option key={index} selected={role.roleNo == userRoleNo} value={role.roleNo}>
                                                        {role.roleDesc}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="txt-left">
                                        <div className=" pdl30 blue">거주지역</div>
                                    </td>
                                    <td className="txt-left">
                                        <select
                                            onChange={(event) => {
                                                setUserParentRegionCodeId(Number(event.currentTarget.value));
                                            }}
                                        >
                                            <option>도 단위 지역 선택</option>
                                            {regionCodes?.map((regionCode: Code, index: number) => {
                                                return (
                                                    <option selected={regionCode.codeId == userParentRegionCodeId} value={regionCode.codeId}>
                                                        {regionCode.name}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                        <select
                                            onChange={(event) => {
                                                setUserChildRegionCodeId(Number(event.currentTarget.value));
                                            }}
                                        >
                                            <option>시.군단위 지역선택</option>
                                            {childRegionCodes?.map((childRegionCode: Code, index: number) => {
                                                return (
                                                    <option selected={childRegionCode.codeId == userChildRegionCodeId} value={childRegionCode.codeId}>
                                                        {childRegionCode.name}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="txt-left">
                                        <div className="pdl30 blue">
                                            현 소속 팀 명<br />
                                            &lt;분류그룹/분류&gt;
                                        </div>
                                    </td>
                                    <td className="txt-left">
                                        <input type="text" value={belongTeamInfo?.name} onChange={(event) => {}} disabled />
                                        &lt;{belongTeamInfo?.belongCode?.name}/{belongTeamInfo?.classificationCode?.name}&gt;
                                    </td>
                                </tr>

                                <tr>
                                    <td className="txt-left" style={{ height: '35px' }}>
                                        <div className=" pdl30 blue">휴대폰번호</div>
                                    </td>
                                    <td className="txt-left">
                                        <input disabled type="text" value={userPhoneNumber} onChange={(event) => {}} />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="txt-left">
                                        <div className=" pdl30 blue">가입일</div>
                                    </td>
                                    <td className="txt-left">
                                        <input disabled type="text" value={user?.registeDate} onChange={(event) => {}} />
                                    </td>
                                </tr>
                                {/* <tr>
                                <td className="txt-left"><div className=" pdl30 blue">최근로그인</div></td>
                                <td className="txt-left">2020-12-12 00:00</td>
                            </tr> */}
                                <tr>
                                    <td colSpan={2}></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="member-list">
                        <table cellPadding="0" cellSpacing="0">
                            <colgroup>
                                <col style={{ textAlign: 'center', width: '70%', backgroundColor: '#f2f2f2' }} />
                                <col style={{ width: '30%' }} />
                                {/* <col style={{ width: '20%' }} /> */}
                                {/* <col style={{ width: '10%' }} /> */}
                            </colgroup>
                            <thead>
                                {/* <tr>
                                    <td colSpan={4} style={tdStyle}>
                                        <button style={btnStyle} className="bg-red white" onClick={addBelongTeamHandler}>
                                            추가
                                        </button>
                                        <button style={btnStyle} className="bg-blue white" onClick={updateBelongTeamsHandler}>
                                            변경저장
                                        </button>
                                    </td>
                                </tr> */}
                                <tr>
                                    <td>현재 소속 팀</td>
                                    <td>팀 등록 일자</td>
                                    {/* <td>소속 여부</td> */}
                                    {/* <td>관 리</td> */}
                                </tr>
                            </thead>
                            <tbody>{belongedTeamTb}</tbody>
                        </table>
                    </div>
                </div>
                {/* <div className="half">
                    <div className="member-list">
                        <table>
                            <colgroup>
                                <col style={{ width: '200px' }} />
                                <col style={{ width: '*' }} />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <td className="bg-light-gray txt-left">
                                        <span className="navy white roundbox">
                                            <i className="fas fa-sticky-note"></i>&nbsp;&nbsp;메모
                                        </span>
                                    </td>
                                    <td className="bg-light-gray txt-right">
                                        <input type="textarea" className="meas" placeholder="메모입력"></input>
                                        <br />
                                        <br />
                                        <button className="bg-blue white">수정</button>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="txt-left">
                                        <span className="navy white roundbox">
                                            <i className="fas fa-bell"></i>&nbsp;&nbsp;알림 메시지 발송
                                        </span>
                                    </td>
                                    <td className="txt-right">
                                        <input className="bg-light-gray" type="textarea" placeholder="제목을 입력하세요"></input>
                                        <br />
                                        <br />
                                        <input className="bg-light-gray" type="textarea" placeholder="내용을 입력해주세요"></input>
                                        <br />
                                        <br />
                                        <button className="bg-blue white">전송</button>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="bg-light-gray txt-left">
                                        <span className="navy white roundbox">
                                            <i className="fas fa-sms"></i>&nbsp;&nbsp;문자 메시지 발송
                                        </span>
                                    </td>
                                    <td className="bg-light-gray txt-right">
                                        <input type="textarea" className="meas" placeholder="내용"></input>
                                        <br />
                                        <br />0 / 80 bytes <button className="bg-blue white">전송</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div> */}
            </div>
        </>
    );
}

export default MembersInfoContainer;
