import { JsonObject, JsonProperty } from 'json2typescript';
import CommonRehabCenterMeasure from './CommonRehabCenterMeasure';
import Code from '../../../../domain/code/Code';

@JsonObject("rehabCenterMeasureByGender")
class RehabCenterMeasureByGender extends CommonRehabCenterMeasure {
    @JsonProperty("genderCode")
    genderCode?: Code;
}

export default RehabCenterMeasureByGender;