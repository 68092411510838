import { useEffect, useState } from "react";
import { REHAB_CENTER_STATISTICS_SERVICE } from '../service/impl/index';
import RehabCenterMeasureByYear from '../domain/statistics/RehabCenterMeasureByYear';
import { COLOR_ARR } from '../../../config/Constant';
import RehabCenterMeasureByMonthOfYear from '../domain/statistics/RehabCenterMeasureByMonthOfYear';
import RehabCenterMeasureByQuarter from '../domain/statistics/RehabCenterMeasureByQuarter';

function useRehabStatisticsTimeInfo() {
    const [yearDataSet, setYearDataSet] = useState<any>();
    const [monthOfYearDataSet, setMonthOfYearDataSet] = useState<any>();
    const [quarterOfYearDataSet, setQuarterOfYearDataSet] = useState<any>();

    useEffect(() => {
        REHAB_CENTER_STATISTICS_SERVICE.getMeasureByYear()
        .then((rehabCenterMeasureByYears: RehabCenterMeasureByYear[]) => {
            setYearDataSet(makeChartDateByYear(rehabCenterMeasureByYears));
        });

        REHAB_CENTER_STATISTICS_SERVICE.getMeasureByMonthOfYear()
        .then((rehabCenterMeasureByMonthOfYears: RehabCenterMeasureByMonthOfYear[]) => {
            setMonthOfYearDataSet(makeChartDataByMonthOfYear(rehabCenterMeasureByMonthOfYears));
        });

        REHAB_CENTER_STATISTICS_SERVICE.getMeasureByQuarterOfYear()
        .then((data: RehabCenterMeasureByQuarter[]) => {
            setQuarterOfYearDataSet(makeChartDataByQuarterOfYear(data));
        })
    }, []);

    return {
        yearDataSet, monthOfYearDataSet, quarterOfYearDataSet
    }
}

function makeChartDateByYear(rehabCenterMeasureByYears: RehabCenterMeasureByYear[]) {
    const dataOfDataSets: Array<number|undefined> = [];
    const backgroundColor: Array<string|undefined> = [];
    const labels: Array<string|undefined> = [];

    rehabCenterMeasureByYears.forEach((rehabCenterMeasureByYear: RehabCenterMeasureByYear, index: number) => {
        dataOfDataSets.push(rehabCenterMeasureByYear.measureCnt);
        backgroundColor.push(COLOR_ARR[index]);
        labels.push(String(rehabCenterMeasureByYear.year));
    });

     const data = {
        datasets: [{
            label: '연도별 측정 인원',
            data: dataOfDataSets,
            backgroundColor: backgroundColor
        }],
        labels: labels
    }
    
    return data;
}

function makeChartDataByMonthOfYear(rehabCenterMeasureByMonthOfYears: RehabCenterMeasureByMonthOfYear[]) {
    const dataOfDataSets: Array<number|undefined> = [];
    const backgroundColor: Array<string|undefined> = [];
    const labels: Array<string|undefined> = [];

    rehabCenterMeasureByMonthOfYears.forEach((rehabCenterMeasureByMonthOfYear, index) => {
        dataOfDataSets.push(rehabCenterMeasureByMonthOfYear.measureCnt);
        backgroundColor.push(COLOR_ARR[index]);
        labels.push(String(rehabCenterMeasureByMonthOfYear.yearOfMonth));
    })  ;

    const data = {
        datasets: [{
            label: '월별 측정 인원',
            data: dataOfDataSets,
            backgroundColor: backgroundColor
        }],
        labels: labels
    }
    
    return data;
}

function makeChartDataByQuarterOfYear(datas: RehabCenterMeasureByQuarter[]) {
    const dataOfDataSets: Array<number|undefined> = [];
    const backgroundColor: Array<string|undefined> = [];
    const labels: Array<string|undefined> = [];

    datas.forEach((data, index) => {
        dataOfDataSets.push(data.measureCnt);
        backgroundColor.push(COLOR_ARR[index]);
        labels.push(String(data.quarterOfYear));
    })  ;

    const data = {
        datasets: [{
            label: '분기별 측정 인원',
            data: dataOfDataSets,
            backgroundColor: backgroundColor
        }],
        labels: labels
    }
    
    return data;
}

export default useRehabStatisticsTimeInfo;