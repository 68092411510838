import InfraSearchCondition from '../../domain/infra/param/InfraSearchCondition';
import Paging from '../../domain/common/Paging';
import { useEffect, useState } from 'react';
import Infra from '../../domain/infra/Infra';
import InfraServiceImpl from '../../service/infra/impl/InfraServiceImpl';
import { confirmAlert } from 'react-confirm-alert';

const infraService = new InfraServiceImpl();

function useInfraRetrieveBySearchCondition(infraSearchCondition: InfraSearchCondition, paging: Paging) {
    const [infras, setInfras] = useState<Infra[]>();
    const [infrasCnt, setInfrasCnt] = useState<number>(0);
    const [actionFlag, setActionFlag] = useState<String>('');

    useEffect(() => {
        //조건에 맞는 인프라 페이징 조회
        infraService.getInfraBySearchCondition(infraSearchCondition, paging).then((infras: Infra[]) => {
            setInfras(infras);
        });

        //조건에 맞는 인프라의 총 갯수
        infraService.getInfraCntBySearchCondition(infraSearchCondition).then((infrasCnt: number) => {
            setInfrasCnt(infrasCnt);
        });
    }, [infraSearchCondition, paging]);

    useEffect(() => {
        if (actionFlag === 'RECOMM' || actionFlag === 'UNRECOMM') {
            if (infras)
                infraService.updateInfras(infras).then((result: Infra[]) => {
                    confirmAlert({
                        title: '성공',
                        message: '추천 여부가 정상 반영 되었습니다.',
                        buttons: [{ label: '확인', onClick: () => setInfras(result) }],
                    });
                });
        }
        setActionFlag('');
    }, [actionFlag]);

    return { infras, infrasCnt, setInfras, setActionFlag };
}

export default useInfraRetrieveBySearchCondition;
