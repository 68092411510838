import { ThunkAction } from 'redux-thunk';
import { RootState } from './index';
import { Action } from 'redux';
import User from '../domain/user/User';
import UserServiceImpl from '../service/user/impl/UserServiceImpl';
import BelongTeam from '../domain/user/BelongTeam';
import TeamServiceImpl from '../service/team/impl/TeamServiceImpl';
import Team from '../domain/team/Team';
import { ACCESS_TOKEN, USER_INFO_KEY } from '../config/Constant';
import JsonConvertUtils from '../utils/JsonConvertUtils';

const userService = new UserServiceImpl();
const teamService = new TeamServiceImpl();
// const LOGIN = 'userSession/LOGIN' as const;
const REGISTE_USER_SESSION = 'userSession/REGISTE_USER_SESSION' as const;
const REGISTE_LOGIN_INFO = 'userSession/REGISTE_LOGIN_INFO' as const;
const UN_REGISTE_USER_SESSION = 'useSession/UN_REGISTE_USER_SESSION' as const;

/**
 * 사용자 정보를 가져온 뒤,
 * 팀 등록까지 진행 해보자.
 */
export const registeUserSessionAction: any = (): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch) => {
    //console.log("1")
    // 사용자 조회
    userService
        .getUserInfo()
        .then((user: User) => {
            //사용자 소속 팀 체크
            if (user.userNo) {
                userService.getBelongTeamInfo(user.userNo).then((belongTeams: BelongTeam[]) => {
                    if (belongTeams.length > 0) {
                        const belongedTeamNo = belongTeams[0].belongedTeamNo;
                        //사용자 소속 팀 정보 조회
                        teamService.getTeamInfo(belongedTeamNo).then((team: Team) => {
                            //myteam
                            dispatch({
                                type: REGISTE_USER_SESSION,
                                isLogin: true,
                                user: user,
                                myTeam: team,
                                hasTeamAuth: user.checkTeamAdmin(),
                                hasSystemAuth: user.checkSystemAdmin(),
                                hasMunicipalityAuth: user.checkMunicipalityOper(),
                                hasCenterOperAuth: user.checkCenterOperAuth(),
                                hasCenterRecorderAuth: user.checkCenterRecorderAuth(),
                            });
                            window.localStorage.setItem('userInfo', JSON.stringify({ isLogin: true, user: user, myTeam: team }));
                        });
                    } else {
                        dispatch({
                            type: REGISTE_USER_SESSION,
                            isLogin: true,
                            user: user,
                            myTeam: undefined,
                            hasTeamAuth: user.checkTeamAdmin(),
                            hasSystemAuth: user.checkSystemAdmin(),
                            hasMunicipalityAuth: user.checkMunicipalityOper(),
                            hasCenterOperAuth: user.checkCenterOperAuth(),
                            hasCenterRecorderAuth: user.checkCenterRecorderAuth(),
                        });
                        window.localStorage.setItem('userInfo', JSON.stringify({ isLogin: true, user: user, myTeam: undefined }));
                    }
                });
            }
        })
        .catch((err) => {
            window.localStorage.removeItem('userInfo');
            console.error(err);
        });
};

export const unRegisteUserSessionAction = () => {
    window.localStorage.removeItem(ACCESS_TOKEN);
    window.localStorage.removeItem(USER_INFO_KEY);
    // MUNICIPALITY_OPER
    return {
        type: UN_REGISTE_USER_SESSION,
        user: undefined,
        isLogin: false,
        myTeam: undefined,
        hasTeamAuth: false,
        hasSystemAuth: false,
        hasMunicipalityAuth: false,
        hasCenterOperAuth: false,
        hasCenterRecorderAuth: false,
    };
};

export const registeLoginInfo = () => {
    //console.log('2');
    let userInfo = window.localStorage.getItem('userInfo');
    //console.log(userInfo);
    if (userInfo != null) {
        const paredUserInfo = JSON.parse(userInfo);
        let user: User | undefined = JsonConvertUtils.parseToObj(paredUserInfo.user, User);
        let myTeam: Team | undefined = JsonConvertUtils.parseToObj(paredUserInfo.myTeam, Team);

        if (user) {
            return Object.assign(
                { type: REGISTE_LOGIN_INFO },
                {
                    user: user,
                    myTeam: myTeam,
                    hasTeamAuth: user.checkTeamAdmin(),
                    hasSystemAuth: user.checkSystemAdmin(),
                    hasMunicipalityAuth: user.checkMunicipalityOper(),
                },
            );
        }
    } else {
        return { type: UN_REGISTE_USER_SESSION };
    }
};

export type UserSessionAction =
    | ReturnType<typeof registeUserSessionAction>
    | ReturnType<typeof unRegisteUserSessionAction>
    | ReturnType<typeof registeLoginInfo>;

type UserSessionState = {
    user: User | undefined;
    isLogin: boolean;
    myTeam: Team | undefined;
    hasTeamAuth: boolean;
    hasSystemAuth: boolean;
    hasMunicipalityAuth: boolean;
    hasCenterOperAuth: boolean;
    hasCenterRecorderAuth: boolean;
};

const initialState: UserSessionState = {
    user: undefined,
    isLogin: false,
    myTeam: undefined,
    hasTeamAuth: false,
    hasSystemAuth: false,
    hasMunicipalityAuth: false,
    hasCenterOperAuth: false,
    hasCenterRecorderAuth: false,
};

function userSessionReducer(state: UserSessionState = initialState, action: UserSessionAction) {
    switch (action.type) {
        case REGISTE_USER_SESSION:
        // return { user: action.user,
        //             isLogin: action.isLogin,
        //             myTeam: action.myTeam,
        //             hasTeamAuth: action.hasTeamAuth,
        //             hasSystemAuth: action.hasSystemAuth,
        //             hasMunicipalityAuth: action.hasMunicipalityAuth,
        //             hasCenterOperAuth: action.hasCenterOperAuth,
        //             hasCenterRecorderAuth: action.hasCenterRecorderAuth
        //         };
        case UN_REGISTE_USER_SESSION:
        // return { user: action.user,
        //             isLogin: action.isLogin,
        //             myTeam: action.myTeam,
        //             hasTeamAuth: action.hasTeamAuth,
        //             hasSystemAuth: action.hasSystemAuth,
        //             hasMunicipalityAuth: action.hasMunicipalityAuth
        //         };
        case REGISTE_LOGIN_INFO:
            return {
                user: action.user,
                isLogin: action.isLogin,
                myTeam: action.myTeam,
                hasTeamAuth: action.hasTeamAuth,
                hasSystemAuth: action.hasSystemAuth,
                hasMunicipalityAuth: action.hasMunicipalityAuth,
                hasCenterOperAuth: action.hasCenterOperAuth,
                hasCenterRecorderAuth: action.hasCenterRecorderAuth,
            };
        default:
            return state;
    }
}

export default userSessionReducer;
