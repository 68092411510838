import InfraCategoryService from '../InfraCategoryService';
import InfraCategory from '../../../domain/infra/InfraCategory';
import APIServerConfig from '../../../config/APIServerConfig';
import AbstractService from '../../AbstractService';
class InfraCategoryServiceImpl extends AbstractService implements InfraCategoryService {
    retrieveParentInfraCategory(): Promise<InfraCategory[]> {
        return new Promise((resolve) => {
            APIServerConfig.recordAPIAxios.get('/infra-category')
            .then((response) => {
                let infraCategories: InfraCategory[] = this.jsonConvert.deserializeArray(response.data, InfraCategory);
                resolve(infraCategories);
            })
        });
    }

    retrieveChildInfraCategory(infraCategoryNo: number): Promise<InfraCategory[]> {
        return new Promise((resolve) => {
            APIServerConfig.recordAPIAxios.get(`/infra-category/${infraCategoryNo}`)
            .then((response) => {
                let infraCategories: InfraCategory[] = this.jsonConvert.deserializeArray(response.data, InfraCategory);
                resolve(infraCategories);
            })
        })
    }
}

export default InfraCategoryServiceImpl;