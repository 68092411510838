import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import ActionComponent from '../ActionComponent';
import Exercise from '../../../domain/Exercise';
import Code from '../../../../../domain/code/Code';
import PrescriptionServiceImpl from '../../../service/impl/PrescriptionServiceImpl';
import ExerContents from './comps/ExerContents';

interface IProps {
    openType: string;
    exercise: Exercise;
    bodyParts: Code[];
    exerTypes: Code[];
    sportTypes: Code[];
    closingHandler: (evt: any) => void;
}
const prescriptionService = new PrescriptionServiceImpl();

let isStored: boolean = true;

const ExercisePopupComponent = (props: IProps) => {
    const [exercise, setExercise] = useState<Exercise>(props.exercise);

    useEffect(() => {
        var actions = ActionComponent();

        var exerType = exercise.exerType;
        let title = '';
        let typeTit = '';
        let elem;

        if (exerType === '41') {
            title = '운동종목 별 운동처방관리';
            typeTit = '운동종목';
            ReactDOM.render(<></>, document.getElementById('list-tab'));
            elem = document.getElementById('center-popup-list');
            actions.researchSend();
        } else if (exerType === '42') {
            title = '손상부위 별 운동처방관리';
            typeTit = '손상부위';
            actions.researchView();
            ReactDOM.render(<></>, document.getElementById('center-popup-list'));
            elem = document.getElementById('list-tab');
        }

        if (elem) ReactDOM.render(contents(exercise, title, typeTit), elem);
    }, [exercise]);

    const closingPopupHandler = (evt: any) => {
        var actions = ActionComponent();
        actions.popupClose();

        props.closingHandler(evt);
    };

    const storeDataHandler = (evt: any) => {
        let action = evt.target.id;

        isStored = true;

        if (action === 'saveExer' || action === 'modifyExer') {
            let storeForm = new FormData();
            Object.entries(exercise).map(([key, value]) => {
                if (value && key !== 'exerItemCode' && key != 'exerTypeCode') storeForm.append(key, value);
            });

            prescriptionService.manageExercise(storeForm).then((data) => {
                closingPopupHandler(null);
            });
        } else if (action === 'deleteExer') {
            prescriptionService.deleteExercise(exercise.exerNo).then((data) => {
                closingPopupHandler(null);
            });
        }
    };
    const changePropHandler = (evt: any) => {
        isStored = false;
        var target = evt.target.name;
        var inputVal = evt.target.value;

        if (target === 'applyedLevel') {
            var elems = document.getElementsByName(target);
            elems.forEach((elem) => {
                elem.className = 'center-button-cancle';
            });
            evt.target.className = 'center-button-yes';
        } else if (target === 'refImgPath') {
            let image: File = evt.target.files[0];
            let photoStatus = document.getElementById('photoStatus');
            if (photoStatus) photoStatus.innerHTML = image.name;

            target = 'uploadImage';
            inputVal = image;
        } else if (target === 'refClipPath') {
            let clips: File = evt.target.files[0];
            let clipStatus = document.getElementById('clipStatus');
            if (clipStatus) clipStatus.innerHTML = clips.name;

            target = 'uploadClip';
            inputVal = clips;
        }

        setExercise((prevState) => {
            return {
                ...prevState,
                [target]: inputVal,
            };
        });
    };

    const bodyParts: Code[] = props.bodyParts ? props.bodyParts : [new Code()];
    const sportTypes: Code[] = props.sportTypes ? props.sportTypes : [new Code()];

    const tabHandler = (evt: any) => {
        evt.preventDefault();
        var target = evt.target.id;
        var actions = ActionComponent();

        let initObj: Exercise = new Exercise();

        if (props.openType != 'previewExer') {
            if (target === 'tab-btn1') {
                actions.researchSend();
                initObj.exerType = '41';
            } else if (target === 'tab-btn2') {
                actions.researchView();
                initObj.exerType = '42';
            }
            setExercise(initObj);
        }
    };

    const storeBtns = (openType: string) => {
        if (openType === 'createExer') {
            return (
                <>
                    <button id="saveExer" className="center-button-save" onClick={storeDataHandler}>
                        저장 <i className="fas fa-file-download"></i>
                    </button>
                    <button id="closingPopup" className="center-button-close" onClick={closingPopupHandler}>
                        닫기 <i className="fas fa-times-circle"></i>
                    </button>
                </>
            );
        } else if (openType === 'previewExer') {
            return (
                <>
                    <button id="deleteExer" className="center-button-delete" onClick={storeDataHandler}>
                        삭제 <i className="fas fa-trash"></i>
                    </button>
                    <button id="modifyExer" className="center-button-save" onClick={storeDataHandler}>
                        수정 <i className="fas fa-retweet"></i>
                    </button>
                    <button id="closingPopup" className="center-button-close" onClick={closingPopupHandler}>
                        닫기 <i className="fas fa-times-circle"></i>
                    </button>
                </>
            );
        }
    };

    const contents = (exercise: Exercise, title: string, itemTit: string) => {
        return (
            <ExerContents
                exercise={exercise}
                title={title}
                itemTit={itemTit}
                handler={changePropHandler}
                sportTypes={sportTypes}
                bodyParts={bodyParts}
            ></ExerContents>
        );
    };

    return (
        <>
            <div className="center-popup wow  animate__animated animate__fadeInUp" data-wow-delay="0.2s">
                <div className="center-popup-title">
                    <h1 className="center">운동처방 등록 / 수정</h1>
                    <div>{storeBtns(props.openType)}</div>
                </div>
                {/* <!--//center-popup-title--> */}
                <div className="center-popup-cont">
                    <div className="center-popup-bnt">
                        <a href="#" id="tab-btn1" onClick={tabHandler}>
                            <i className="fas fa-running"></i> 운동종목별 운동처방
                        </a>
                        <a href="#" id="tab-btn2" onClick={tabHandler}>
                            <i className="fas fa-comment-medical"></i> 손상부위별 운동처방
                        </a>
                    </div>
                    <div id="center-popup-list" className="center-popup-list-tab">
                        {/*운동종목 별 내용 */}
                    </div>
                    <div id="list-tab" className="center-popup-list-tab">
                        {/*손상부위 별 내용 */}
                    </div>
                </div>
                {/* <!--//.center-popup-cont--> */}
            </div>
            {/* <!--//center-popup--> */}
        </>
    );
};

export default ExercisePopupComponent;
