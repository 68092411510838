import { JsonProperty, JsonObject } from 'json2typescript';
import User from '../../../domain/user/User';
import UserDetailInfo from '../../../domain/user/UserDetailInfo';
import Code from '../../../domain/code/Code';
import Infra from '../../../domain/infra/Infra';
import Team from '../../../domain/team/Team';
import Booking from './Booking';
import Reservation from '../../../domain/reservation/Reservation';
import RehabTargetHistory from './RehabTargetHistory';

@JsonObject('targetPerson')
class TargetPerson {
    @JsonProperty('targetNo')
    targetNo: number = 0;
    @JsonProperty('reservationNo')
    reservationNo: string = '';
    @JsonProperty('reservation')
    reservation?: Booking;

    @JsonProperty('reservaterNo')
    reservaterNo: string = '';
    @JsonProperty('reservater')
    reservater?: UserDetailInfo;
    @JsonProperty('targetState')
    targetState: string = '';
    @JsonProperty('targetStateCode')
    targetStateCode?: Code;
    @JsonProperty('rehabCnt')
    rehabCnt: number = 0;
    @JsonProperty('infraNo')
    infraNo: string = '';
    @JsonProperty('infra')
    infra?: Infra;
    @JsonProperty('teamNo')
    teamNo: string = '';
    @JsonProperty('team')
    team?: Team;
    @JsonProperty('questionnaireState')
    questionnaireState: string = '';
    @JsonProperty('questionnaireStateCode')
    questionnaireStateCode?: Code;
    @JsonProperty('conditionState')
    conditionState: string = '';
    @JsonProperty('conditionStateCode')
    conditionStateCode?: Code;
    @JsonProperty('recordingStep')
    recordingStep: string = '';
    @JsonProperty('recordingStepCode')
    recordingStepCode?: Code;
    @JsonProperty('latestRehab')
    latestRehab: string = '';
    @JsonProperty('latestReservation')
    latestReservation?: Reservation;
    @JsonProperty('regDate')
    regDate: string = '';
    @JsonProperty('modDate')
    modDate: string = '';
}

export default TargetPerson;
