import { useEffect, useState } from "react";
import Paging from '../../../domain/common/Paging';
function useRehabCenterPaging() {
    const [page, setPage] = useState<number>(0);
    const [pagingSize, setPagingSize] = useState<number>(10);
    const [pagingCnt, setPagingCnt] = useState<number>(5);
    const [paging, setPaging] = useState(new Paging(page, pagingSize));
    useEffect(() => {
        console.log(pagingSize);
    }, [pagingSize]);
    useEffect(() => {
        console.log(pagingSize);
        const newPaging = new Paging(page, pagingSize);
        setPaging(newPaging);
    }, [page, pagingSize]);

    return {
        page, setPage, pagingSize, setPagingSize,
        pagingCnt, setPagingCnt,
        paging, setPaging
    }
}

export default useRehabCenterPaging;