import { JsonObject, JsonProperty } from 'json2typescript';
import UserDetailInfo from '../../../domain/user/UserDetailInfo';
import Infra from '../../../domain/infra/Infra';
import Team from '../../../domain/team/Team';
import User from '../../../domain/user/User';

@JsonObject('booking')
class Booking {
    isSelected: boolean = false;
    @JsonProperty('reservationGroupNo')
    reservationGroupNo: string = '';
    @JsonProperty('reservationNo')
    reservationNo: string = '';
    @JsonProperty('reservaterNo')
    reservaterNo: string = '';
    @JsonProperty('reservater')
    reservater?: UserDetailInfo;
    @JsonProperty('registerNo')
    registerNo: string = '';
    @JsonProperty('register')
    register?: UserDetailInfo;
    @JsonProperty('teamNo')
    teamNo: string = '';
    @JsonProperty('team')
    team?: Team;
    @JsonProperty('infraNo')
    infraNo: string = '';
    @JsonProperty('infra')
    infra?: Infra;
    @JsonProperty('approve')
    approve: boolean = false;
    @JsonProperty('registeDate')
    registeDate: string = '';
    @JsonProperty('startDate')
    startDate: string = '';
    @JsonProperty('endDate')
    endDate: string = '';
    @JsonProperty('reservationStateCodeId')
    reservationStateCodeId: number = 0;
    @JsonProperty('reservationStateCode')
    reservationStateCode: object = {};
    @JsonProperty('comment')
    comment: string = '';
}

export default Booking;
