import React from 'react';
import { Link } from 'react-router-dom';
import SideMenus from './components/MenuComponent';
import { RootState } from '../../../moldules/index';
import { useSelector } from 'react-redux';

interface SelectedMenu {
    name: string;
    centerId?: string;
}

function LayoutSideBar(props: SelectedMenu) {
    const { user } = useSelector((state: RootState) => state.userSessionReducer);
    //let to = props.name.lastIndexOf('/');
    //let selected = props.name.substring(0, to);
    let selected = props.name;

    const uris: {
        uri: string;
        name: string;
        iconclass: string;
        hasChild: boolean;
        subMenus: string;
        children: { uri: string; name: string }[];
    }[] = [
        {
            uri: '/rehab/reserved',
            name: '예약관리',
            iconclass: 'far fa-calendar-alt',
            hasChild: false,
            children: [],
            subMenus: '',
        },
        { uri: '/rehab/checkedup', name: '검진관리', iconclass: 'fas fa-user-nurse', hasChild: false, children: [], subMenus: '' },
        { uri: '/rehab/targetperson', name: '센터회원관리', iconclass: 'fas fa-diagnoses', hasChild: false, children: [], subMenus: '' },
        // { uri: '/rehab/completerehab', name: '센터회원관리', iconclass: 'fas fa-diagnoses', hasChild: false, children: [], subMenus: '' },
        //{ uri: '/rehab/questionnaires', name: '상담', iconclass: 'fas fa-user-nurse', hasChild: false, children: [], subMenus: '' },
        // { uri: '/rehab/recording', name: '측정', iconclass: 'fas fa-stopwatch', hasChild: false, children: [], subMenus: '' },
        // { uri: '/rehab/prescription', name: '운동처방', iconclass: 'fas fa-biking', hasChild: false, children: [], subMenus: '' },
        // {
        //     uri: '/rehab/completerehab',
        //     name: '재활관리',
        //     iconclass: 'fas fa-diagnoses',
        //     hasChild: false,
        //     children: [],
        //     subMenus: '',
        // },
        {
            uri: '/rehab/manage/user',
            name: '관리',
            iconclass: 'fas fa-child',
            hasChild: true,
            subMenus: '/rehab/manage/user, /rehab/manage/exercise, /rehab/manage/questsheet',
            children: [
                { uri: '/rehab/manage/user', name: '계정관리' },
                { uri: '/rehab/manage/exercise', name: '운동처방관리' },
                { uri: '/rehab/manage/questsheet', name: '설문관리' },
            ],
        },
        { uri: '/rehab/statistics', name: '센터통계', iconclass: 'fas fa-chart-line', hasChild: false, children: [], subMenus: '' },
        { uri: '/rehab/configuration', name: '설정', iconclass: 'fas fa-user-cog', hasChild: false, children: [], subMenus: '' },
        { uri: '/rehab/logout', name: '로그아웃', iconclass: 'fas fa-sign-out-alt', hasChild: false, children: [], subMenus: '' },
    ];

    const ulElement = (
        <ul className="center-left-menu">
            {uris.map((item, idx) => (
                <SideMenus
                    key={idx}
                    selectedMenu={selected}
                    uri={item.uri}
                    name={item.name}
                    children={item.children}
                    iconclass={item.iconclass}
                    hasChild={item.hasChild}
                    subMenus={item.subMenus}
                />
            ))}
        </ul>
    );

    return ulElement;
}

export default LayoutSideBar;
