import { useEffect, useState } from 'react';
import Paging from '../../../domain/common/Paging';
import InfraSearchCondition from '../../../domain/infra/param/InfraSearchCondition';

function usePagingInfos(condition: InfraSearchCondition) {
    const [page, setPage] = useState<number>(condition.page ? condition.page : 0);
    const [pagingSize, setPagingSize] = useState<number>(10);
    const [pagingCnt, setPagingCnt] = useState<number>(5);
    const [paging, setPaging] = useState(new Paging(condition.page ? condition.page : 0, pagingSize));
    const [currentPageGroupIdx, setCurrentPageGroupIdx] = useState<number>(condition.groupIdx ? condition.groupIdx : 0);

    useEffect(() => {}, [pagingSize]);
    useEffect(() => {
        const newPaging = new Paging(page, pagingSize);
        setPaging(newPaging);
    }, [page, pagingSize]);

    return {
        page,
        setPage,
        pagingSize,
        setPagingSize,
        pagingCnt,
        setPagingCnt,
        paging,
        setPaging,
        currentPageGroupIdx,
        setCurrentPageGroupIdx,
    };
}

export default usePagingInfos;
