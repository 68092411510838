import React from 'react';
import { JsonObject, JsonProperty } from 'json2typescript';
import Vas from './Vas';

@JsonObject('checkCondition')
export default class CheckCondition {
    @JsonProperty('ss')
    ss: string = '';
    @JsonProperty('mh')
    mh: string = '';
    @JsonProperty('fh')
    fh: string = '';
    @JsonProperty('ha')
    ha: string = '';
    @JsonProperty('rs')
    rs: string = '';
    @JsonProperty('vas', [Vas])
    vas?: Vas[] = [];

    status: string = '';
}
