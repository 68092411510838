import React, { useEffect, useState } from 'react';
import Infra from '../../domain/infra/Infra';
import SiteDepth from '../../component/nav/SiteDepth';
import ImgSlider from '../../component/img/ImgSlider';
import ReservationPopupContainer from '../reservation/ReservationPopupContainer';
import InfraLocationMap from '../../component/map/InfraLocationMap';
import SearchRight from '../../component/layout/SearchRight';
import SearchIn from '../../component/layout/SearchIn';
import EventSearchCondition from '../../domain/param/EventSearchCondition';
import useRetrieveEventsByCondition from '../../hook/event/useRetrieveEventsByCondition';
import Paging from '../../domain/common/Paging';
import Event from '../../domain/event/Event';
import User from '../../domain/user/User';
import UserFavoritesInfra from '../../domain/favorities/UserFavoritesInfra';
import useFavoritesInfraRegiste from '../../hook/favorites/useFavoritesInfraRegiste';

interface InfraDetailViewContainerProps {
    user?: User;
    infra: Infra;
}

function InfraDetailInfoTab(props: InfraDetailViewContainerProps) {
    return (
        <>
            <div className="school">
                주소 : {props.infra.address}
                <br />
                홈페이지 :{' '}
                <a className="blue-line" href={props.infra.homepageUrl}>
                    {props.infra.homepageUrl}
                </a>
                <br />
                전화 : <span className="blue-line">{props.infra.phoneNumber}</span>
            </div>

            <div className="home-wrap">
                <div className="home-title">
                    <span>설명</span>
                </div>
                <div className="home-box clear">
                    <p className="output-txt">{props.infra.getDescription(props.infra.facilityDescription)}</p>
                </div>
            </div>

            <div className="home-wrap">
                <div className="home-title">
                    <span>부속장비</span>
                </div>
                <div className="home-box clear">
                    <p className="output-txt">{props.infra.getDescription(props.infra.equipmentDescription)}</p>
                </div>
            </div>

            <div className="home-wrap">
                <div className="home-title">
                    <span>기타편의시설</span>
                </div>
                <div className="home-box clear">
                    <p className="output-txt">{props.infra.getDescription(props.infra.etcDescription)}</p>
                </div>
            </div>

            <div className="home-wrap">
                <div className="home-title">
                    <span>시설 이용 규칙</span>
                </div>
                <div className="home-box clear">
                    <p className="output-txt">{props.infra.getDescription(props.infra.useRuleDescription)}</p>
                </div>
            </div>

            <div className="home-wrap">
                <div className="home-title">
                    <span>환불 규칙</span>
                </div>
                <div className="home-box clear">
                    <p className="output-txt">{props.infra.getDescription(props.infra.refundPolicyDescription)}</p>
                </div>
            </div>
        </>
    );
}

function InfraDetailLocationTab(props: InfraDetailViewContainerProps) {
    return (
        <div className="home-wrap">
            <div className="home-title">
                <span>위치</span>
            </div>
            <div className="home-box clear">
                <p className="output-txt">{props.infra.address}</p>
                <>
                    {props.infra.longitude && props.infra.latitude ? (
                        <InfraLocationMap mapLevel={3} infras={[props.infra]}></InfraLocationMap>
                    ) : (
                        <h5>위치 정보가 등록되지 않은 시설입니다.</h5>
                    )}
                </>
            </div>
        </div>
    );
}

function InfraDetailFeeTab(props: InfraDetailViewContainerProps) {
    return (
        <div className="home-wrap">
            <div className="home-title">
                <span>요금</span>
            </div>
            <div className="home-box bg-gay clear">
                {props.infra.childInfras?.map((childInfra, index) => {
                    return (
                        <div key={index} className="price-table">
                            <div className="price-table-area">
                                <p>
                                    {childInfra.name}({props.infra.reservationTimeUnit}시간)
                                </p>
                            </div>
                            <div className="price-table-price">
                                {childInfra.charges?.map((charge, index) => {
                                    return (
                                        <p key={index} className="output-txt">
                                            {charge.chargeTypeCode?.name} <span>{charge.cost}원</span>
                                        </p>
                                    );
                                })}
                            </div>
                        </div>
                    );
                })}
                <div className="clear"></div>
            </div>
        </div>
    );
}

function InfraEventTab(props: InfraDetailViewContainerProps) {
    const [eventSearchCondition, setEventSearchCondition] = useState<EventSearchCondition>();
    const [paging, setPaging] = useState(new Paging(0, 10));
    const { events } = useRetrieveEventsByCondition(eventSearchCondition, paging);
    useEffect(() => {
        const eventSearchCondition = new EventSearchCondition();
        eventSearchCondition.regionCodeIds = String(props.infra.regionCodeId);
        eventSearchCondition.parentInfraCategoryNos = String(props.infra.infraCategory?.parentInfraCategoryNo);
        setEventSearchCondition(eventSearchCondition);
    }, [props.infra]);

    return (
        <div className="home-wrap">
            <div className="home-title">
                <span>이벤트</span>
            </div>
            <div className="home-box bg-gay clear">
                {events?.map((event: Event, index) => {
                    return (
                        <div key={index} className="price-table">
                            <div className="price-table-area">
                                <p>{event.content}</p>
                                <p>
                                    행사 기간 {event.startTime} ~ {event.expiredTime}
                                </p>
                            </div>
                            <div className="price-table-price">
                                <p>{event.eventCode?.name}</p>
                                <p>
                                    사용률 {event.usedCouponCnt}/{event.issuedCouponCnt}
                                </p>
                            </div>
                        </div>
                    );
                })}
                <div className="clear"></div>
            </div>
        </div>
    );
}

function InfraDetailPrTab(props: InfraDetailViewContainerProps) {
    return (
        <div>
            <iframe
                id="player"
                width="100%"
                height="500px"
                src="http://www.youtube.com/embed/M7lc1UVf-VE?enablejsapi=1&origin=http://localhost:3000"
            ></iframe>
        </div>
    );
}

function InfraDetailViewContainer(props: InfraDetailViewContainerProps) {
    const [userFavoritesInfra, setUserFavoritesInfra] = useState<UserFavoritesInfra>();
    const [tabSeq, setTabSeq] = useState<string>('tab1');
    const [reservationPopupOpenable, setReservationPopupOpenable] = useState(false);
    const changeReservationPopupOpenable = (openable: boolean) => {
        setReservationPopupOpenable(openable);
    };
    useFavoritesInfraRegiste(userFavoritesInfra);
    //추가 해야 하는 부분
    const favoritesEventHandler = () => {
        //여기서 어떻게 훅을 사용하게 하지...
        //즐겨찾기 여부 확인 되야 하는데.. 현재는 로직이 없어서 일단 추가만 되도록
        if (props.user) {
            if (props.user.userNo && props.infra.infraNo) {
                const userFavoritesInfra = UserFavoritesInfra.getUserFavoritesInfra(props.user.userNo, props.infra.infraNo);
                setUserFavoritesInfra(userFavoritesInfra);
            }
        } else {
            alert('회원만 즐겨찾기가 가능합니다.');
        }
    };

    const tabs: any = {
        tab1: <InfraDetailInfoTab infra={props.infra}></InfraDetailInfoTab>,
        tab2: <InfraDetailFeeTab infra={props.infra}></InfraDetailFeeTab>,
        tab3: <InfraDetailLocationTab infra={props.infra}></InfraDetailLocationTab>,
        tab4: <InfraEventTab infra={props.infra}></InfraEventTab>,
        tab5: <InfraDetailPrTab infra={props.infra}></InfraDetailPrTab>,
    };

    return (
        <>
            <SearchRight>
                {/* <div className="search-in"> */}
                <SearchIn>
                    <SiteDepth pageTitle={'스포츠 시설'} depth1={'스포츠 시설'}></SiteDepth>

                    {props.infra.attachFiles ? <ImgSlider attachFiles={props.infra.attachFiles}></ImgSlider> : <></>}
                    <div className="room-area">
                        <div>
                            <p>
                                {props.infra.name}
                                <button
                                    onClick={() => {
                                        setReservationPopupOpenable(true);
                                    }}
                                ></button>
                                {props.user ? <button onClick={favoritesEventHandler}></button> : <></>}
                            </p>
                            <p>
                                {props.infra.infraCategory?.parentInfraCategory.name} &gt; {props.infra.infraCategory?.name}
                            </p>
                        </div>
                    </div>
                    <ul className="tabs sports">
                        <li className={tabSeq === 'tab1' ? 'active' : ''} onClick={() => setTabSeq('tab1')}>
                            <p>시설정보</p>
                        </li>
                        <li className={tabSeq === 'tab2' ? 'active' : ''} onClick={() => setTabSeq('tab2')}>
                            <p>요금</p>
                        </li>
                        <li className={tabSeq === 'tab3' ? 'active' : ''} onClick={() => setTabSeq('tab3')}>
                            <p>위치</p>
                        </li>
                        <li className={tabSeq === 'tab4' ? 'active' : ''} onClick={() => setTabSeq('tab4')}>
                            <p>행사정보</p>
                        </li>
                        <li className={tabSeq === 'tab5' ? 'active' : ''} onClick={() => setTabSeq('tab5')}>
                            <p>홍보영상</p>
                        </li>
                    </ul>
                    <div className="tab_container">
                        <div className="tab_content">{tabs[tabSeq]}</div>
                    </div>
                </SearchIn>
            </SearchRight>
            <ReservationPopupContainer
                mainInfra={props.infra}
                childInfras={props.infra.childInfras}
                openable={reservationPopupOpenable}
                changeReservationPopupOpenable={changeReservationPopupOpenable}
            ></ReservationPopupContainer>
        </>
    );
}

export default InfraDetailViewContainer;
