import { confirmAlert } from 'react-confirm-alert';
import ChoiceLocationRenderer from './ChoiceLocationRenderer';
class KaKaoMapForChoiceLocationRenderer extends ChoiceLocationRenderer {
    address: string | undefined;

    getMapInstance() {
        const mapContainer: HTMLElement | null = document.getElementById(this.mapId);
        const mapOptions = {
            center: new window.kakao.maps.LatLng(this.latitude, this.longitude),
            level: this.mapLevel,
        };

        return new window.kakao.maps.Map(mapContainer, mapOptions);
    }

    addMapTypeControl(): void {
        const mapTypeControl = new window.kakao.maps.MapTypeControl();
        this.map.addControl(mapTypeControl, window.kakao.maps.ControlPosition.TOPRIGHT);
    }

    addZoomControl(): void {
        const zoomControl = new window.kakao.maps.ZoomControl();
        this.map.addControl(zoomControl, window.kakao.maps.ControlPosition.RIGHT);
    }

    setCoordiByAddress(addr: string, setAddress: any, setLatitude: any, setLongitude: any): any {
        let geocorder = new window.kakao.maps.services.Geocoder();

        geocorder.addressSearch(addr, (result: any, status: any) => {
            if (status === 'OK') {
                this.latitude = result[0].y;
                this.longitude = result[0].x;

                setAddress(result[0].address_name);
                setLatitude(this.latitude);
                setLongitude(this.longitude);

                var imageSrc = '/assets/image/map-marker-icon.png', // 마커이미지의 주소입니다
                    imageSize = new window.kakao.maps.Size(32, 35), // 마커이미지의 크기입니다
                    imageOption = { offset: new window.kakao.maps.Point(14, 35) }; // 마커이미지의 옵션입니다. 마커의 좌표와 일치시킬 이미지 안에서의 좌표를 설정합니다.
                var markerImage = new window.kakao.maps.MarkerImage(imageSrc, imageSize, imageOption),
                    markerPosition = new window.kakao.maps.LatLng(this.latitude, this.longitude); // 마커가 표시될 위치입니다

                var marker = new window.kakao.maps.Marker({
                    position: markerPosition,
                    image: markerImage,
                    title: '내위치',
                });
                marker.setMap(this.map);

                this.map.setCenter(markerPosition);
            } else {
                confirmAlert({
                    title: '경고',
                    message: '올바른 주소 형식이 아니여서 지도를 검색할 수 없습니다. 다시 입력해 주십시오',
                    buttons: [{ label: '확인', onClick: () => console.log('') }],
                });
            }
        });
    }

    myLocationMarkerRender(): void {
        var imageSrc = '/assets/image/map-marker-icon.png', // 마커이미지의 주소입니다
            imageSize = new window.kakao.maps.Size(32, 35), // 마커이미지의 크기입니다
            imageOption = { offset: new window.kakao.maps.Point(14, 35) }; // 마커이미지의 옵션입니다. 마커의 좌표와 일치시킬 이미지 안에서의 좌표를 설정합니다.
        var markerImage = new window.kakao.maps.MarkerImage(imageSrc, imageSize, imageOption),
            markerPosition = new window.kakao.maps.LatLng(this.latitude, this.longitude); // 마커가 표시될 위치입니다

        var marker = new window.kakao.maps.Marker({
            position: markerPosition,
            image: markerImage,
            title: '내위치',
        });

        marker.setMap(this.map);

        this.map.setCenter(markerPosition);

        window.kakao.maps.event.addListener(this.map, 'click', (mouseEvent: any) => {
            // 클릭한 위도, 경도 정보를 가져옵니다
            var latlng = mouseEvent.latLng;

            // 마커 위치를 클릭한 위치로 옮깁니다
            marker.setPosition(latlng);

            this.latitude = latlng.getLat();
            this.longitude = latlng.getLng();
        });
    }
}

export default KaKaoMapForChoiceLocationRenderer;
