import ReportDataParam from '../../../domain/report/param/ReportDataParam';
import AbstractService from '../../AbstractService';
import ReportService from '../ReportService';
import APIServerConfig from '../../../config/APIServerConfig';
import Report from '../../../domain/report/Report';
class ReportServiceImpl extends AbstractService implements ReportService {

    saveReport(param: ReportDataParam): Promise<Report> {
        return new Promise((resolve, reject) => {
            APIServerConfig.recordAPIAxios.post("/report", JSON.stringify(param))
            .then(response => {
                alert("리포트 생성 완료");
                // resolve(response.data);
                const report: Report = this.jsonConvert.deserializeObject(response.data, Report);
                resolve(report);
            })
            .catch(err => {
                reject(err);
            })
        })    
    }

    getReport(targetNo: number, ownerNo: string): Promise<Report> {
        return new Promise((resolve, reject) => {
            APIServerConfig.recordAPIAxios.get(`/report/${targetNo}/${ownerNo}`)
            .then(response => {
                console.log(response);
                const report: Report = this.jsonConvert.deserializeObject(response.data, Report);
                resolve(report);
            })
            .catch(err => {
                reject(err);
            })
        });
    }

    updateReport(param: Report): Promise<Report> {
        return new Promise((resolve, reject) => {
            APIServerConfig.recordAPIAxios.put(`/report`, JSON.stringify(param))
            .then(response => {
                const report: Report = this.jsonConvert.deserializeObject(response.data, Report);
                resolve(report);
            })
            .catch(err => {
                reject(err);
            })
        })
    }

}

export default ReportServiceImpl;