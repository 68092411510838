import React, { useEffect, useRef, useState } from 'react';
import TmpRegistedTeamUser from '../../../../domain/team/TmpRegistedTeamUser';
import useTmpRegistedTeamUserModify from '../../../../hook/team/useTmpRegistedTeamUserModify';
import useTmpRegistedTeamUserDelete from '../../../../hook/team/useTmpRegistedTeamUserDelete';
import { confirmAlert } from 'react-confirm-alert';
interface UserMyPagePlayerListItemProps {
    tmpRegistedTeamUser: TmpRegistedTeamUser;
}

function UserMyPagePlayerListItem(props: UserMyPagePlayerListItemProps) {
    const [userName, setUserName] = useState<string>();
    const [phoneNumber, setPhoneNumber] = useState<string>();

    const { modifyHandler, modifyTmpRegistedTeamUser } = useTmpRegistedTeamUserModify(props.tmpRegistedTeamUser);
    const { deleteHandler, deleteDate } = useTmpRegistedTeamUserDelete();

    const divElementRef = useRef<HTMLTableRowElement>(null);

    useEffect(() => {
        if (deleteDate) {
            confirmAlert({
                title: '삭제성공',
                message: '팀 소속원 삭제가 정상적으로 되었습니다.',
                buttons: [
                    {
                        label: '확인',
                        onClick: () => {
                            if (divElementRef.current) {
                                divElementRef.current.remove();
                                window.location.reload();
                            }
                        },
                    },
                ],
            });
        }
    }, [deleteDate]);

    useEffect(() => {
        if (modifyTmpRegistedTeamUser) {
            confirmAlert({
                title: '수정성공',
                message: '팀 소속원 정보가 정상적으로 수정 되었습니다.',
                buttons: [
                    {
                        label: '확인',
                        onClick: () => {
                            window.location.reload();
                        },
                    },
                ],
            });
        }
    });

    useEffect(() => {
        setUserName(props.tmpRegistedTeamUser.userName);
        setPhoneNumber(props.tmpRegistedTeamUser.phoneNumber);
    }, [props.tmpRegistedTeamUser]);

    return (
        <>
            <tr ref={divElementRef}>
                <td>
                    {props.tmpRegistedTeamUser.user ? (
                        <img width={27} height={27} style={{ borderRadius: '100px' }} src={props.tmpRegistedTeamUser.user.imageUrl} />
                    ) : (
                        <></>
                    )}
                </td>
                <td>
                    <input onChange={(event) => setUserName(event.currentTarget.value)} type="text" value={userName} />
                </td>
                <td>
                    <input onChange={(event) => setPhoneNumber(event.currentTarget.value)} type="text" value={phoneNumber} />
                </td>
                <td>{props.tmpRegistedTeamUser.user ? <span className="blue">가입완료</span> : <span className="red">미가입</span>}</td>
                <td>
                    <button
                        className="blue"
                        onClick={() => {
                            deleteHandler(props.tmpRegistedTeamUser.tmpRegistedNo);
                        }}
                    >
                        삭제
                    </button>
                    &nbsp;&nbsp;
                    <button
                        className="blue"
                        onClick={() => {
                            modifyHandler(userName, phoneNumber);
                        }}
                    >
                        수정
                    </button>
                </td>
            </tr>
        </>
    );
}

export default UserMyPagePlayerListItem;
