import React from 'react';
import ActionComponent from '../../ActionComponent';
import InBodyForm from './tabs/InBodyForm';
import FormCheckerForm from './tabs/FormCheckerForm';
import FootCheckerForm from './tabs/FootCheckForm';
import BioDexForm from './tabs/BioDexForm';
import TreadMillTest from './tabs/TreadMillTestForm';
import SpecialPhysical from '../../../../domain/recording/SpecialPhysical';

interface IProps {
    status: SpecialPhysical;
    ocr: { inbody: string; pomChecker: string; footChecker: string; biodex: string; treadMill: string };
    handler: (evt: any) => void;
}

export default function SpecialPhysicalForms(props: IProps) {
    const ocr = props.ocr;
    const tabHandler = (evt: any) => {
        let actions = ActionComponent();
        let target = evt.target.id;

        if (target === 'inbody') {
            actions.tab1();
        } else if (target === 'formChecker') {
            actions.tab2();
        } else if (target === 'footChecker') {
            actions.tab3();
        } else if (target === 'bioDex') {
            actions.tab4();
        } else if (target === 'treadMillTest') {
            actions.tab5();
        }
    };

    return (
        <>
            <div id="list-from" className="center-popup-list-tab">
                <div className="tab-menu">
                    <ul>
                        <li id="tabon1" className="tabon">
                            <button id="inbody" onClick={tabHandler}>
                                인바디
                                <br />
                                (체성분검사)
                            </button>
                        </li>
                        <li id="tabon2" onClick={tabHandler}>
                            <button id="formChecker">폼체커</button>
                        </li>
                        <li id="tabon3">
                            <button id="footChecker" onClick={tabHandler}>
                                풋체커
                                <br />
                                (족저압)
                            </button>
                        </li>
                        <li id="tabon4">
                            <button id="bioDex" onClick={tabHandler}>
                                바이오덱스
                                <br />
                                (등속성운동)
                            </button>
                        </li>
                        <li id="tabon5">
                            <button id="treadMillTest" onClick={tabHandler}>
                                가스분석기
                                <br />
                                (온동부하검사)
                            </button>
                        </li>
                    </ul>
                </div>
                <div className="tab-story">
                    <div id="tabstory1">
                        <InBodyForm handler={props.handler} status={props.status}></InBodyForm>
                    </div>
                    <div id="tabstory2">
                        <FormCheckerForm handler={props.handler} status={props.status}></FormCheckerForm>
                    </div>
                    <div id="tabstory3">
                        <FootCheckerForm handler={props.handler} status={props.status}></FootCheckerForm>
                    </div>
                    <div id="tabstory4">
                        <BioDexForm handler={props.handler} status={props.status} ocr={ocr.biodex}></BioDexForm>
                    </div>
                    <div id="tabstory5">
                        <TreadMillTest handler={props.handler} status={props.status} ocr={ocr.treadMill}></TreadMillTest>
                    </div>
                </div>
            </div>
        </>
    );
}
