import React, { useEffect, useState } from 'react';
import Infra from '../../../domain/infra/Infra';
import Reservation from '../../../domain/reservation/Reservation';
import ReservationTime from '../../../domain/reservation/ReservationTime';
import DateUtils from '../../../utils/DateUtils';

interface infraNoContainer {
    reservationDay?: string;
    infra?: Infra;
    timeTableOpenable: boolean;
    setTimeTableOpenable: (openable: boolean) => void;
    reservations?: Reservation[];
    setReservationStartDate: (startDate: string) => void;
    setReservationEndDate: (endDate: string) => void;
}

function RehabilitationUnitTimeComponent(props: infraNoContainer) {
    let timeTable: any = [];
    if (props.infra?.reservationStartTime) {
        timeTable = props.infra?.getReservationTimeTable();
    } else {
        timeTable.push('시간 정보가 없습니다.');
    }

    const [reservationTimes, setReservationTimes] = useState<ReservationTime[]>();

    useEffect(() => {
        if (props.infra) {
            setReservationTimes(props.infra.getReservationTimeTableInstance());
        }
    }, [props.infra]);
    let reservationDate: Date = new Date();
    if (props.reservationDay) {
        reservationDate = new Date(props.reservationDay);
    }
    return (
        <div id="time" className="sportsman-cho" style={{ display: `${props.timeTableOpenable ? 'block' : 'none'}`, maxHeight: '150px' }}>
            {props.infra ? (
                reservationTimes?.map((reservationTime: ReservationTime, index: number) => {
                    const isAlreadyReservationTime = reservationTime.isAlreadyReservationTime(props.reservationDay, props.reservations);
                    return (
                        <p key={index}>
                            <label>
                                <input
                                    name="reservation-time"
                                    disabled={isAlreadyReservationTime}
                                    type="radio"
                                    onClick={() => {
                                        if (props.reservationDay) {
                                            const reservationStartDate = reservationTime.getStartDate(DateUtils.getDateForm(reservationDate));
                                            const reservationEndDate = reservationTime.getEndDate(DateUtils.getDateForm(reservationDate));

                                            props.setReservationStartDate(reservationStartDate);
                                            props.setReservationEndDate(reservationEndDate);
                                            props.setTimeTableOpenable(false);
                                        }
                                    }}
                                />
                                {`${reservationTime.startTime}~${reservationTime.endTime}`}
                                {`${isAlreadyReservationTime ? '(예약마감)' : ''}`}
                            </label>
                        </p>
                    );
                })
            ) : (
                <span>예약 할 시설을 선택하세요.</span>
            )}
        </div>
    );
}

export default RehabilitationUnitTimeComponent;
