import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject("answerItem")
class AnswerItem {
    @JsonProperty("questionNum")
    questionNum?: number;
    @JsonProperty("itemNoOfAnswer")
    itemNoOfAnswer?: number;

    static getAnswerItemsOfMap(answerItemsMap: Map<number, AnswerItem>) {
        const newAnswerItems: AnswerItem[] = [];
        answerItemsMap?.forEach((value, key) => {
            newAnswerItems.push(value);
        })
        return newAnswerItems;
    }

    static getNewAnswerItem(questionNum: number, itemNoOfAnswer: number): AnswerItem {
        const answerItem = new AnswerItem();
        answerItem.questionNum = questionNum;
        answerItem.itemNoOfAnswer =itemNoOfAnswer;
        return answerItem;
    }
}

export default AnswerItem;