import React, { useState, useEffect } from 'react';
import ActionComponent from '../ActionComponent';
import TargetPerson from '../../../domain/TargetPerson';
import { confirmAlert } from 'react-confirm-alert';
import TrackingServiceImpl from '../../../service/impl/TrackingServiceImpl';
import TargetExercises from '../../../domain/tracking/TargetExercises';
import ExercisesTracking from '../../../domain/tracking/ExercisesTracking';
import moment, { Moment, Moment as MomentTypes } from 'moment';

interface IProps {
    targetNo: number;
    selectedTarget: TargetPerson;
    setSatisfyOpenable: (satisfyOpenable: boolean) => void;
}

const trackingService = new TrackingServiceImpl();

export default function TrackingHistoryPopupComponent(props: IProps) {
    const actions = ActionComponent();
    const [targetExercises, setTargetExercises] = useState<TargetExercises[]>();
    const [exercisesTracking, setExercisesTracking] = useState<ExercisesTracking[]>();

    let targetNo: number = props.targetNo;
    let targetPerson = props.selectedTarget;

    useEffect(() => {
        trackingService.getTargetExercises(Number(targetNo)).then((data: any) => {
            setTargetExercises(data);
        });
    }, []);

    const openQuestionnaireListHandler = (e: any) => {
        actions.researchSend();
    };
    const openResultHandler = (e: any) => {
        actions.researchView();
    };

    const selectExerciseHandler = (e: any) => {
        let target = e.target;
        let targetExerNo = target.value;

        trackingService.getExercisesTracking(targetExerNo).then((data: any) => {
            setExercisesTracking(data);
        });
    };

    const contents = (targetExercises: TargetExercises[] | undefined) => {
        let page = 0;
        let pageSize = 0;
        let rowNum = page * pageSize;
        let tbCont = (
            <>
                <tr>
                    <td colSpan={5}>관리 대상자가 존재하지 않습니다.</td>
                </tr>
            </>
        );

        if (targetExercises && targetExercises.length > 0) {
            tbCont = (
                <>
                    {targetExercises?.map((exercisesInfo: TargetExercises, idx) => {
                        return (
                            <tr key={idx}>
                                <td>{rowNum + idx + 1}</td>
                                <td>{exercisesInfo?.exerType}</td>
                                <td>{exercisesInfo.getBeginTime()}</td>
                                <td>{exercisesInfo.getEndTime()}</td>
                                <td>
                                    <button onClick={selectExerciseHandler} value={exercisesInfo.targetExerNo}>
                                        확인
                                    </button>
                                </td>
                            </tr>
                        );
                    })}
                </>
            );
        }
        return (
            <table className="center-list-table" cellPadding="0" cellSpacing="0">
                <thead>
                    <tr>
                        <td>No.</td>
                        <td>운동종류</td>
                        <td>운동시작시간</td>
                        <td>운동종료시간</td>
                        <td>운동내역</td>
                    </tr>
                </thead>
                <tbody>{tbCont}</tbody>
            </table>
        );
    };

    const checkingTracking = (e: any) => {
        let target = e.target;
        let tracking = JSON.parse(target.value);
        let message = (
            <>
                <table className="center-information-table">
                    <thead>
                        <tr>
                            <td colSpan={4}>수집 데이터</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>수집시간:</td>
                            <td colSpan={3}> {tracking.timestamp}</td>
                        </tr>
                        <tr>
                            <td>걸음수:</td> <td>{tracking.walk}걸음</td>
                            <td>뜀걸음수:</td> <td> {tracking.run} 뛴걸음</td>
                        </tr>
                        <tr>
                            <td>걸은시간:</td> <td>{tracking.walkTime}분</td>
                            <td>뛴시간:</td>
                            <td>{tracking.runTime}분</td>
                        </tr>
                        <tr>
                            <td>걸은거리:</td> <td>{tracking.walkDistance}M</td>
                            <td>뛴거리:</td>
                            <td>{tracking.runDistance}M</td>
                        </tr>
                        <tr>
                            <td>소모칼로리:</td> <td colSpan={3}>{tracking.walkCalories + tracking.runCalories} Kcal</td>
                        </tr>
                    </tbody>
                </table>
            </>
        );

        confirmAlert({
            title: '운동정보',
            message: '운동정보',
            buttons: [{ label: '확인', onClick: () => {} }],
            childrenElement: () => <div />,
            customUI: ({ onClose }) => <div>{message}</div>,
            overlayClassName: 'overlay-custom-class-name',
        });
    };
    const checkHeartRate = (e: any) => {
        let target = e.target;
        let heartRate = JSON.parse(target.value);
        let message = (
            <>
                <table className="center-information-table">
                    <thead>
                        <tr>
                            <td colSpan={4}>심박수 데이터</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>수집시간:</td>
                            <td> {heartRate.timestamp}</td>
                        </tr>
                        <tr>
                            <td>심박수</td> <td>{heartRate.hr} HR</td>
                        </tr>
                    </tbody>
                </table>
            </>
        );
        confirmAlert({
            title: '운동정보',
            message: '운동정보',
            buttons: [{ label: '확인', onClick: () => {} }],
            childrenElement: () => <div />,
            customUI: ({ onClose }) => <div>{message}</div>,
            overlayClassName: 'overlay-custom-class-name',
        });
    };

    const trackingContents = (exercisesTracking: ExercisesTracking[] | undefined) => {
        let page = 0;
        let pageSize = 0;
        let rowNum = page * pageSize;
        let tbCont = (
            <>
                <tr>
                    <td colSpan={4}>운동내역을 선택해 주십시오</td>
                </tr>
            </>
        );

        if (exercisesTracking && exercisesTracking.length > 0) {
            tbCont = (
                <>
                    {exercisesTracking?.map((trackingInfo: ExercisesTracking, idx) => {
                        let trackingStr = trackingInfo?.trackingInfo;
                        let heartRateStr = trackingInfo?.heartRateInfo;
                        let tracking = <>수집데이터 없음</>;
                        let heartRate = <>심박없음</>;

                        if (trackingStr != null) {
                            tracking = (
                                <>
                                    <button value={trackingStr} onClick={checkingTracking}>
                                        수집데이터확인
                                    </button>
                                </>
                            );
                        }
                        if (heartRateStr != null) {
                            heartRate = (
                                <>
                                    <button value={heartRateStr} onClick={checkHeartRate}>
                                        심박확인
                                    </button>
                                </>
                            );
                        }
                        return (
                            <tr key={idx}>
                                <td>{rowNum + idx + 1}</td>
                                <td>{trackingInfo?.getTrackingTime()}</td>
                                <td>{tracking}</td>
                                <td>{heartRate}</td>
                            </tr>
                        );
                    })}
                </>
            );
        }
        return (
            <table className="center-list-table" cellPadding="0" cellSpacing="0">
                <thead>
                    <tr>
                        <td>No.</td>
                        <td>수집시간</td>
                        <td>운동내역</td>
                        <td>심박정보</td>
                    </tr>
                </thead>
                <tbody>{tbCont}</tbody>
            </table>
        );
    };

    const popupContents = (
        <div className="center-popup wow  animate__animated animate__fadeInUp" data-wow-delay="0.2s">
            <div className="center-popup-title">
                <h1 className="center">운동 내역 확인 (인바디밴드)</h1>
                <div className="flex">
                    <h1 className="center">
                        <i className="fas fa-user-tie"></i> {targetPerson?.reservater?.name}
                    </h1>{' '}
                    <span className="center-birth">
                        {targetPerson?.reservater?.genderCode?.name} / {targetPerson?.reservater?.birthdate}
                    </span>
                </div>
                <button className="center-button-close" onClick={actions.popupClose}>
                    닫기 <i className="fas fa-times-circle"></i>
                </button>
            </div>
            <div className="center-popup-cont">
                <div className="center-popup-title">
                    <h1 className="center">운동내역</h1>
                </div>
                <div id="center-popup-list" className="center-popup-list-box">
                    {contents(targetExercises)}
                </div>
                <div className="center-popup-title">
                    <h1 className="center">운동Tracking 목록</h1>
                </div>
                <div id="center-popup-list" className="center-popup-list-box">
                    {trackingContents(exercisesTracking)}
                </div>
            </div>
        </div>
    );

    return popupContents;
}
