import React from 'react';
import Infra from '../../../../../domain/infra/Infra';
import InfraLocationMap from '../../../../../component/map/InfraLocationMap';
import UserLocation from '../../../../../domain/user/UserLocation';

interface CommonInfraLocationTabProps {
    infra: Infra;
}

function CommonInfraLocationTab(props: CommonInfraLocationTabProps) {
    console.log(props.infra);
    return (
        <div className="home-wrap">
            <div className="home-title">
                <span>위치</span>
            </div>
            <div className="home-box bg-gay clear">
                <>
                    {props.infra.longitude && props.infra.latitude ? (
                        <>
                            <p className="output-txt">{props.infra.address}</p>
                            <InfraLocationMap mapLevel={3} infras={[props.infra]}></InfraLocationMap>
                        </>
                    ) : (
                        <h5 className="alert-h5">위치 정보가 등록되지 않은 시설입니다.</h5>
                    )}
                </>
            </div>
        </div>
    );
}

export default CommonInfraLocationTab;
