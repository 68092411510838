import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import AdminMainTitle from '../../../../component/admin/AdminMainTitle';
import useChildCodeRetrieve from '../../../../hook/code/useChildCodeRetrieve';
import useCodeRetrieve from '../../../../hook/code/useCodeRetrieve';
import { CodeType } from '../../../../domain/code/CodeType';

import AdminVisitedTeamInfoFilter from '../../component/filter/AdminVisitedTeamInfoFilter';
import VisitedTeamStatusBySportTypeTables from '../../component/VisitedTeamStatusBySportTypeTables';

import { defaultParentRegionCodeId } from '../../../../config/Constant';

import ClubTeamInfoStatisticsServiceImpl from '../../../../service/clubteam/impl/ClubTeamInfoStatisticsServiceImpl';
import ClubTeamInfoSearchCondition from '../../../../domain/clubteam/param/ClubTeamInfoSearchCondition';
import ClubTeamInfoStatistics from '../../../../domain/clubteam/ClubTeamInfoStatistics';
import AdminClubStatusFilter from '../../component/filter/AdminClubStatusFilter';
import ClubStatusByRegionTables from '../../component/ClubStatusByRegionTables';
import AdminClubTeamInfosFilter from '../../component/filter/AdminClubTeamInfosFilter';

const statisticsService = new ClubTeamInfoStatisticsServiceImpl();

function AdminClubTeamStatusByRegion() {
    const location = useLocation<ClubTeamInfoSearchCondition>();

    const { codes: regionCodes } = useCodeRetrieve(CodeType.REGION);

    let condition = location.state ? location.state : new ClubTeamInfoSearchCondition();

    const [searchCondition, setSearchCondition] = useState<ClubTeamInfoSearchCondition>(condition);
    const [clubStatusByRegion, setClubStatusByRegion] = useState<ClubTeamInfoStatistics[]>();
    const [clubTotalStatusByRegion, setClubTotalStatusByRegion] = useState<ClubTeamInfoStatistics>();

    useEffect(() => {
        setSearchCondition(condition);
    }, []);

    useEffect(() => {
        statisticsService.getClubStatusByRegion(searchCondition).then((data) => {
            setClubStatusByRegion(data);
            let typeTotal: any = new ClubTeamInfoStatistics();

            typeTotal.cnt = data.length;
            data.map((typeInfo) => {
                typeTotal.clubTeamCount ? (typeTotal.clubTeamCount += typeInfo.clubTeamCount) : (typeTotal.clubTeamCount = typeInfo.clubTeamCount);
                typeTotal.clubTeamMemberCount
                    ? (typeTotal.clubTeamMemberCount += typeInfo.clubTeamMemberCount)
                    : (typeTotal.clubTeamMemberCount = typeInfo.clubTeamMemberCount);
            });

            setClubTotalStatusByRegion(typeTotal);
        });
    }, [searchCondition]);

    let title = '지역 별 동호회 팀 현황';
    return (
        <>
            <AdminMainTitle title={title}>
                <AdminClubTeamInfosFilter setClubTeamInfoSearchCondition={setSearchCondition}></AdminClubTeamInfosFilter>
            </AdminMainTitle>
            <ClubStatusByRegionTables
                regionCodes={regionCodes}
                searchCondition={searchCondition}
                clubTotalStatusByRegion={clubTotalStatusByRegion}
                clubStatusByRegion={clubStatusByRegion}
            ></ClubStatusByRegionTables>
        </>
    );
}

export default AdminClubTeamStatusByRegion;
