import React from 'react';

interface IElements {
    children?: JSX.Element;
    className?: string;
    value?: any;
    rel?: any;
    handler?: (e: any) => void;
}

const Button = function (props: IElements) {
    let clazz: string | undefined = props.className;
    let value: any | undefined = props.value;

    return (
        <button className={clazz} onClick={props.handler} value={value}>
            {props.children}
        </button>
    );
};

export default Button;
