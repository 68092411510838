import React from 'react';
import AnswerItem from '../../../../domain/AnswerItem';
import QuestionnariesFormProps from './QuestionnariesFormsProps';
import useReportQuestionnarieAnswerMake from '../../../../../../hook/report/useReportQuestionnarieAnswerMake';
import APIServerConfig from '../../../../../../config/APIServerConfig';
import ReportTypeEnum from '../../../../../../domain/report/param/ReportTypeEnum';

export default function FABQ(props: QuestionnariesFormProps) {
    const putAnswerItem = (qNum: number, answerNumber: number) => {
        if(props.answerItems) {
            const newAnswerItem = AnswerItem.getNewAnswerItem(qNum, answerNumber);
            // props.answerItems.set(qNum, newAnswerItem);
            if(props.setAnswer)
                props.setAnswer(qNum, newAnswerItem);
        }
    }

    const getCheckOnClassName = (qNum: number, answerNumber: number): string => {
        if(props.answerItems) {
            if(props.answerItems.get(qNum)?.itemNoOfAnswer == answerNumber) {
                return 'check-on'
            } else {
                return ''; 
            }
        } else {
            return '';
        }
    }

    const isCheck = (qNum: number, answerNumber: number): boolean => {
        if(props.answerItems) {
            if(props.answerItems.get(qNum)?.itemNoOfAnswer == answerNumber) {
                return true;
            } else {
                return false; 
            }
        } else {
            return false;
        }
    }

    const {reportData} = useReportQuestionnarieAnswerMake(props.answerItems);

    return (
        <>
            <div className="center-center">FABQ
                {
                    props.resultPrintable ?
                    <>
                        <form action={`${APIServerConfig.resourceAPIURL}/report/pdf`} method="POST">
                            <input type="hidden" name="reportType" value={ReportTypeEnum.FABQ}></input>
                            <input type="hidden" name="reportData" value={JSON.stringify(reportData)}></input>
                            <input type="submit" value="설문 결과 PDF 내려받기"/>
                        </form>
                        <form action={`${APIServerConfig.resourceAPIURL}/report/xlsx`} method="POST">
                            <input type="hidden" name="reportType" value={ReportTypeEnum.FABQ}></input>
                            <input type="hidden" name="reportData" value={JSON.stringify(reportData)}></input>
                            <input type="submit" value="설문 결과 엑셀 내려받기"/>
                        </form>
                    </>
                    :
                    <></>
                }
            </div>
            <h4>
                Fear-Avoidance Beliefs Questionnaire (FABQ)
                <br />
                Waddell et al (1993) Pain , 52 (1993) 157 - 168
            </h4>
            <p>
                Here are some of the things which other patients have told us about their pain. For each statement please circle any number from 0 to
                6 to say how much physical activities such as bending, lifting, walking or driving affect or would affect your back pain.
            </p>
            <div className="tab-story-number">
                <table className="center-check-table-basic" cellPadding="0" cellSpacing="0">
                    <thead>
                        <tr>
                            <th></th>
                            <td colSpan={2}>Completely disagree</td>
                            <td colSpan={3}>Unsure</td>
                            <td colSpan={2}>Completely agree </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th>1. My pain was caused by physical activity</th>
                            <td className={getCheckOnClassName(1, 1)} onClick={() => {putAnswerItem(1, 1)}}>1</td>
                            <td className={getCheckOnClassName(1, 2)} onClick={() => {putAnswerItem(1, 2)}}>2</td>
                            <td className={getCheckOnClassName(1, 3)} onClick={() => {putAnswerItem(1, 3)}}>3</td>
                            <td className={getCheckOnClassName(1, 4)} onClick={() => {putAnswerItem(1, 4)}}>4</td>
                            <td className={getCheckOnClassName(1, 5)} onClick={() => {putAnswerItem(1, 5)}}>5</td>
                            <td className={getCheckOnClassName(1, 6)} onClick={() => {putAnswerItem(1, 6)}}>6</td>
                        </tr>
                        <tr>
                            <th>2. Physical activity makes my pain worse</th>
                            <td className={getCheckOnClassName(2, 1)} onClick={() => {putAnswerItem(2, 1)}}>1</td>
                            <td className={getCheckOnClassName(2, 2)} onClick={() => {putAnswerItem(2, 2)}}>2</td>
                            <td className={getCheckOnClassName(2, 3)} onClick={() => {putAnswerItem(2, 3)}}>3</td>
                            <td className={getCheckOnClassName(2, 4)} onClick={() => {putAnswerItem(2, 4)}}>4</td>
                            <td className={getCheckOnClassName(2, 5)} onClick={() => {putAnswerItem(2, 5)}}>5</td>
                            <td className={getCheckOnClassName(2, 6)} onClick={() => {putAnswerItem(2, 6)}}>6</td>
                        </tr>
                        <tr>
                            <th>3. Physical activity might harm my back</th>
                            <td className={getCheckOnClassName(3, 1)} onClick={() => {putAnswerItem(3, 1)}}>1</td>
                            <td className={getCheckOnClassName(3, 2)} onClick={() => {putAnswerItem(3, 2)}}>2</td>
                            <td className={getCheckOnClassName(3, 3)} onClick={() => {putAnswerItem(3, 3)}}>3</td>
                            <td className={getCheckOnClassName(3, 4)} onClick={() => {putAnswerItem(3, 4)}}>4</td>
                            <td className={getCheckOnClassName(3, 5)} onClick={() => {putAnswerItem(3, 5)}}>5</td>
                            <td className={getCheckOnClassName(3, 6)} onClick={() => {putAnswerItem(3, 6)}}>6</td>
                        </tr>
                        <tr>
                            <th>4. I should not do physical activities which (might) make my pain worse </th>
                            <td className={getCheckOnClassName(4, 1)} onClick={() => {putAnswerItem(4, 1)}}>1</td>
                            <td className={getCheckOnClassName(4, 2)} onClick={() => {putAnswerItem(4, 2)}}>2</td>
                            <td className={getCheckOnClassName(4, 3)} onClick={() => {putAnswerItem(4, 3)}}>3</td>
                            <td className={getCheckOnClassName(4, 4)} onClick={() => {putAnswerItem(4, 4)}}>4</td>
                            <td className={getCheckOnClassName(4, 5)} onClick={() => {putAnswerItem(4, 5)}}>5</td>
                            <td className={getCheckOnClassName(4, 6)} onClick={() => {putAnswerItem(4, 6)}}>6</td>
                        </tr>
                        <tr>
                            <th>5. I cannot do physical activities which (might) make my pain worse</th>
                            <td className={getCheckOnClassName(5, 1)} onClick={() => {putAnswerItem(5, 1)}}>1</td>
                            <td className={getCheckOnClassName(5, 2)} onClick={() => {putAnswerItem(5, 2)}}>2</td>
                            <td className={getCheckOnClassName(5, 3)} onClick={() => {putAnswerItem(5, 3)}}>3</td>
                            <td className={getCheckOnClassName(5, 4)} onClick={() => {putAnswerItem(5, 4)}}>4</td>
                            <td className={getCheckOnClassName(5, 5)} onClick={() => {putAnswerItem(5, 5)}}>5</td>
                            <td className={getCheckOnClassName(5, 6)} onClick={() => {putAnswerItem(5, 6)}}>6</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <p>The following statements are about how your normal work affects or would affect your back pain</p>
            <div className="tab-story-number">
                <table className="center-check-table-basic" cellPadding="0" cellSpacing="0">
                    <thead>
                        <tr>
                            <th></th>
                            <td colSpan={2}>Completely disagree</td>
                            <td colSpan={3}>Unsure</td>
                            <td colSpan={2}>Completely agree </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th>6. My pain was caused by my work or by an accident at work…</th>
                            <td className={getCheckOnClassName(6, 1)} onClick={() => {putAnswerItem(6, 1)}}>1</td>
                            <td className={getCheckOnClassName(6, 2)} onClick={() => {putAnswerItem(6, 2)}}>2</td>
                            <td className={getCheckOnClassName(6, 3)} onClick={() => {putAnswerItem(6, 3)}}>3</td>
                            <td className={getCheckOnClassName(6, 4)} onClick={() => {putAnswerItem(6, 4)}}>4</td>
                            <td className={getCheckOnClassName(6, 5)} onClick={() => {putAnswerItem(6, 5)}}>5</td>
                            <td className={getCheckOnClassName(6, 6)} onClick={() => {putAnswerItem(6, 6)}}>6</td>
                        </tr>
                        <tr>
                            <th>7. My work aggravated my pain</th>
                            <td className={getCheckOnClassName(7, 1)} onClick={() => {putAnswerItem(7, 1)}}>1</td>
                            <td className={getCheckOnClassName(7, 2)} onClick={() => {putAnswerItem(7, 2)}}>2</td>
                            <td className={getCheckOnClassName(7, 3)} onClick={() => {putAnswerItem(7, 3)}}>3</td>
                            <td className={getCheckOnClassName(7, 4)} onClick={() => {putAnswerItem(7, 4)}}>4</td>
                            <td className={getCheckOnClassName(7, 5)} onClick={() => {putAnswerItem(7, 5)}}>5</td>
                            <td className={getCheckOnClassName(7, 6)} onClick={() => {putAnswerItem(7, 6)}}>6</td>
                        </tr>
                        <tr>
                            <th>8. I have a claim for compensation for my pain</th>
                            <td className={getCheckOnClassName(8, 1)} onClick={() => {putAnswerItem(8, 1)}}>1</td>
                            <td className={getCheckOnClassName(8, 2)} onClick={() => {putAnswerItem(8, 2)}}>2</td>
                            <td className={getCheckOnClassName(8, 3)} onClick={() => {putAnswerItem(8, 3)}}>3</td>
                            <td className={getCheckOnClassName(8, 4)} onClick={() => {putAnswerItem(8, 4)}}>4</td>
                            <td className={getCheckOnClassName(8, 5)} onClick={() => {putAnswerItem(8, 5)}}>5</td>
                            <td className={getCheckOnClassName(8, 6)} onClick={() => {putAnswerItem(8, 6)}}>6</td>
                        </tr>
                        <tr>
                            <th>9. My work is too heavy for me…</th>
                            <td className={getCheckOnClassName(9, 1)} onClick={() => {putAnswerItem(9, 1)}}>1</td>
                            <td className={getCheckOnClassName(9, 2)} onClick={() => {putAnswerItem(9, 2)}}>2</td>
                            <td className={getCheckOnClassName(9, 3)} onClick={() => {putAnswerItem(9, 3)}}>3</td>
                            <td className={getCheckOnClassName(9, 4)} onClick={() => {putAnswerItem(9, 4)}}>4</td>
                            <td className={getCheckOnClassName(9, 5)} onClick={() => {putAnswerItem(9, 5)}}>5</td>
                            <td className={getCheckOnClassName(9, 6)} onClick={() => {putAnswerItem(9, 6)}}>6</td>
                        </tr>
                        <tr>
                            <th>10. My work makes or would make my pain worse</th>
                            <td className={getCheckOnClassName(10, 1)} onClick={() => {putAnswerItem(10, 1)}}>1</td>
                            <td className={getCheckOnClassName(10, 2)} onClick={() => {putAnswerItem(10, 2)}}>2</td>
                            <td className={getCheckOnClassName(10, 3)} onClick={() => {putAnswerItem(10, 3)}}>3</td>
                            <td className={getCheckOnClassName(10, 4)} onClick={() => {putAnswerItem(10, 4)}}>4</td>
                            <td className={getCheckOnClassName(10, 5)} onClick={() => {putAnswerItem(10, 5)}}>5</td>
                            <td className={getCheckOnClassName(10, 6)} onClick={() => {putAnswerItem(10, 6)}}>6</td>
                        </tr>
                        <tr>
                            <th>11. My work might harm my back</th>
                            <td className={getCheckOnClassName(11, 1)} onClick={() => {putAnswerItem(11, 1)}}>1</td>
                            <td className={getCheckOnClassName(11, 2)} onClick={() => {putAnswerItem(11, 2)}}>2</td>
                            <td className={getCheckOnClassName(11, 3)} onClick={() => {putAnswerItem(11, 3)}}>3</td>
                            <td className={getCheckOnClassName(11, 4)} onClick={() => {putAnswerItem(11, 4)}}>4</td>
                            <td className={getCheckOnClassName(11, 5)} onClick={() => {putAnswerItem(11, 5)}}>5</td>
                            <td className={getCheckOnClassName(11, 6)} onClick={() => {putAnswerItem(11, 6)}}>6</td>
                        </tr>
                        <tr>
                            <th>12. I should not do my normal work with my present pain</th>
                            <td className={getCheckOnClassName(12, 1)} onClick={() => {putAnswerItem(12, 1)}}>1</td>
                            <td className={getCheckOnClassName(12, 2)} onClick={() => {putAnswerItem(12, 2)}}>2</td>
                            <td className={getCheckOnClassName(12, 3)} onClick={() => {putAnswerItem(12, 3)}}>3</td>
                            <td className={getCheckOnClassName(12, 4)} onClick={() => {putAnswerItem(12, 4)}}>4</td>
                            <td className={getCheckOnClassName(12, 5)} onClick={() => {putAnswerItem(12, 5)}}>5</td>
                            <td className={getCheckOnClassName(12, 6)} onClick={() => {putAnswerItem(12, 6)}}>6</td>
                        </tr>
                        <tr>
                            <th>13. I cannot do my normal work with my present pain</th>
                            <td className={getCheckOnClassName(13, 1)} onClick={() => {putAnswerItem(13, 1)}}>1</td>
                            <td className={getCheckOnClassName(13, 2)} onClick={() => {putAnswerItem(13, 2)}}>2</td>
                            <td className={getCheckOnClassName(13, 3)} onClick={() => {putAnswerItem(13, 3)}}>3</td>
                            <td className={getCheckOnClassName(13, 4)} onClick={() => {putAnswerItem(13, 4)}}>4</td>
                            <td className={getCheckOnClassName(13, 5)} onClick={() => {putAnswerItem(13, 5)}}>5</td>
                            <td className={getCheckOnClassName(13, 6)} onClick={() => {putAnswerItem(13, 6)}}>6</td>
                        </tr>
                        <tr>
                            <th>14. I cannot do my normal work till my pain is treated</th>
                            <td className={getCheckOnClassName(14, 1)} onClick={() => {putAnswerItem(14, 1)}}>1</td>
                            <td className={getCheckOnClassName(14, 2)} onClick={() => {putAnswerItem(14, 2)}}>2</td>
                            <td className={getCheckOnClassName(14, 3)} onClick={() => {putAnswerItem(14, 3)}}>3</td>
                            <td className={getCheckOnClassName(14, 4)} onClick={() => {putAnswerItem(14, 4)}}>4</td>
                            <td className={getCheckOnClassName(14, 5)} onClick={() => {putAnswerItem(14, 5)}}>5</td>
                            <td className={getCheckOnClassName(14, 6)} onClick={() => {putAnswerItem(14, 6)}}>6</td>
                        </tr>
                        <tr>
                            <th>15. I do not think that I will be back to my normal work within 3 months.</th>
                            <td className={getCheckOnClassName(15, 1)} onClick={() => {putAnswerItem(15, 1)}}>1</td>
                            <td className={getCheckOnClassName(15, 2)} onClick={() => {putAnswerItem(15, 2)}}>2</td>
                            <td className={getCheckOnClassName(15, 3)} onClick={() => {putAnswerItem(15, 3)}}>3</td>
                            <td className={getCheckOnClassName(15, 4)} onClick={() => {putAnswerItem(15, 4)}}>4</td>
                            <td className={getCheckOnClassName(15, 5)} onClick={() => {putAnswerItem(15, 5)}}>5</td>
                            <td className={getCheckOnClassName(15, 6)} onClick={() => {putAnswerItem(15, 6)}}>6</td>
                        </tr>
                        <tr className="center-bottom-black">
                            <th>16. I do not think that I will ever be able to go back to that work</th>
                            <td className={getCheckOnClassName(16, 1)} onClick={() => {putAnswerItem(16, 1)}}>1</td>
                            <td className={getCheckOnClassName(16, 2)} onClick={() => {putAnswerItem(16, 2)}}>2</td>
                            <td className={getCheckOnClassName(16, 3)} onClick={() => {putAnswerItem(16, 3)}}>3</td>
                            <td className={getCheckOnClassName(16, 4)} onClick={() => {putAnswerItem(16, 4)}}>4</td>
                            <td className={getCheckOnClassName(16, 5)} onClick={() => {putAnswerItem(16, 5)}}>5</td>
                            <td className={getCheckOnClassName(16, 6)} onClick={() => {putAnswerItem(16, 6)}}>6</td>
                        </tr>
                    </tbody>
                </table>
                <h4>
                    Scoring
                    <br />
                    Scale 1: fear-avoidance beliefs about work – items 6, 7, 9, 10, 11, 12, 15.
                    <br />
                    Scale 2: fear-avoidance beliefs about physical activity – items 2, 3, 4, 5.
                </h4>
                <p>
                    Source: Gordon Waddell, Mary Newton, Iain Henderson, Douglas Somerville and Chris J. Main, A Fear-Avoidance Beliefs
                    <br />
                    Questionnaire (FABQ) and the role of fear-avoidance beliefs in chronic low back pain and disability, Pain, 52 (1993) 157 –
                    168,166.
                </p>
            </div>
        </>
    );
}
