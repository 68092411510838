import axios from 'axios';
import { AxiosRequestConfig } from 'axios';
import { ACCESS_TOKEN } from './Constant';

class APIServerConfig {
    static readonly authAPIURl = process.env.REACT_APP_AUTH_API_HOST;
    static readonly resourceAPIURL = process.env.REACT_APP_RESOURCE_API_HOST;
    static readonly coreAPIURL = process.env.REACT_APP_IIS_URL;

    static reqHeaderConfig: AxiosRequestConfig = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem(ACCESS_TOKEN),
        },
    };

    static readonly recordAPIAxios = axios.create({
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem(ACCESS_TOKEN),
        },
        baseURL: APIServerConfig.resourceAPIURL,
        timeout: 10000000,
    });

    static readonly recordAPIAxiosForFile = axios.create({
        headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + localStorage.getItem(ACCESS_TOKEN),
        },
        baseURL: APIServerConfig.resourceAPIURL,
        timeout: 10000000000,
    });

    static readonly authAPIAxios = axios.create({
        headers: {
            'Content-Type': 'application/json',
        },
        baseURL: APIServerConfig.authAPIURl,
        timeout: 10000,
    });

    static readonly coreAPIAxios = axios.create({
        headers: {
            'Content-Type': 'application/json',
        },
        baseURL: APIServerConfig.coreAPIURL,
        timeout: 10000,
    });

    static getReqHeaderConfig() {
        return {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.getItem(ACCESS_TOKEN),
            },
        };
    }
}

export default APIServerConfig;
