import React, { useEffect, useState } from 'react';
import useRetrieveUserInfo from '../../../../../hook/user/useRetrieveUserInfo';
import useCodeRetrieve from '../../../../../hook/code/useCodeRetrieve';
import { CodeType } from '../../../../../domain/code/CodeType';
import TeamServiceImpl from '../../../../../service/team/impl/TeamServiceImpl';
import Team from '../../../../../domain/team/Team';
import MyPageMainLayout from '../../../layout/MyPageMainLayout';
import UserPageSubMenuNav from '../nav/UserPageSubMenuNav';
import MyPageRightContent from '../../../layout/MyPageRightContent';
import BelongTeam from '../../../../../domain/user/BelongTeam';
import UserModifyParam from '../../../../../domain/user/param/UserModifyParam';
import RetrieveUser from '../../../../../domain/user/RetrieveUser';
import UserServiceImpl from '../../../../../service/user/impl/UserServiceImpl';
import Paging from '../../../../../domain/common/Paging';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../moldules/index';
import TeamSearchInput from '../../../../../component/input/TeamSearchInput';
import { confirmAlert } from 'react-confirm-alert';

const teamService = new TeamServiceImpl();
const userService = new UserServiceImpl();
function UserMyPageSettingContainer() {
    const { user, myTeam } = useSelector((state: RootState) => state.userSessionReducer);

    // const {user, belongTeam} = useRetrieveUserInfo();
    const [teams, setTeams] = useState<Team[]>();
    const [phoneNumber, setPhoneNumber] = useState<string>();
    const [userName, setUserName] = useState<string>(user.name);
    const [teamNo, setTeamNo] = useState<string | undefined>(myTeam.teamNo);
    const [sportCodeId, setSportCodeId] = useState<number | undefined>();
    const [email, setEmail] = useState<string>(user.email);

    const [teamInfo, setTeamInfo] = useState<Team | undefined>();
    const [teamSports, setTeamSports] = useState<string | undefined>();
    const [teamBelongedCode, setTeamBelongedCode] = useState<string | undefined>();
    const [teamClassificationCode, setTeamClassificationCode] = useState<string | undefined>();

    const [userModifyParam, setUserModifyParam] = useState<UserModifyParam>();

    const updateBtnHandler = (evt: any) => {
        // let modifyUser = new RetrieveUser();
        const belongTeam = new BelongTeam();

        // if(user?.userNo) modifyUser.userNo = user.userNo
        // if(id) modifyUser.email = id;
        // if(phoneNumber) modifyUser.phoneNumber = phoneNumber;
        // if(teamNo && user) {
        //     belongTeam.belongedUserNo = user.userNo;
        //     belongTeam.belongedTeamNo = teamNo;
        // }
        // if(sportCodeId) modifyUser.sportCodeId = Number(sportCodeId);

        // let userModifyParam = UserModifyParam.getInstance(modifyUser, belongTeam);
        // setUserModifyParam(userModifyParam);
        user.email = email;
        user.phoneNumber = phoneNumber;
        user.name = userName;
        user.sportCodeId = sportCodeId;
        belongTeam.belongedUserNo = user.userNo;
        belongTeam.belongedTeamNo = teamNo ? teamNo : '';
        user.signupApprove = true;

        let userModifyParam = UserModifyParam.getInstance(user, belongTeam);
        setUserModifyParam(userModifyParam);
    };
    useEffect(() => {
        setPhoneNumber(user.phoneNumber);
        setUserName(user.name);
        setSportCodeId(user.sportCodeId);
        if (myTeam) {
            console.log(myTeam);
            setTeamInfo(myTeam);
            setTeamNo(myTeam.teamNo);
            setTeamSports(myTeam.sportCode.name);
            setTeamBelongedCode(myTeam.belongCode?.name);
            setTeamClassificationCode(myTeam.classificationCode?.name);
        }
    }, []);

    useEffect(() => {
        if (teamInfo) {
            setTeamNo(teamInfo.teamNo);
            setTeamSports(teamInfo.sportCode?.name);
            setSportCodeId(teamInfo.sportCodeId);
        }
    }, [teamInfo]);

    useEffect(() => {
        if (userModifyParam) {
            userService.updateUserInfo(userModifyParam).then((userModifyParam) => {
                confirmAlert({
                    title: '수정 성공',
                    message: '사용자 정보가 수정 되었습니다.',
                    buttons: [
                        {
                            label: '확인',
                            onClick: () => {
                                window.location.reload();
                            },
                        },
                    ],
                });
            });
        }
    }, [userModifyParam]);

    return (
        <MyPageMainLayout>
            <MyPageRightContent contentTitle={'내 정보 수정'}>
                <nav>
                    {user && user.provider == 'hiball' ? (
                        <>
                            <div className="menu-item">
                                <div className="lay-basic w100">
                                    <h4>아이디</h4> <span> </span>
                                    <a className="blue" href="#">
                                        수정
                                    </a>
                                </div>
                                <div className="notice-open">
                                    <p>아이디</p>
                                    <input className="basic-gray" placeholder="아이디 입력" />
                                </div>
                            </div>
                            <div className="menu-item">
                                <div className="lay-basic w100">
                                    <h4>비빌번호</h4> <span>**** </span>
                                    <a className="blue" href="#">
                                        수정
                                    </a>
                                </div>
                                <div className="notice-open">
                                    <p>비밀번호</p>
                                    <input className="basic-gray" placeholder="비밀번호 입력" />
                                </div>
                            </div>
                        </>
                    ) : (
                        <></>
                    )}

                    <div className="menu-item menu-item-name">
                        <div className="notice-open">
                            <p className="mapage_tle2">이름</p>
                            <input className="basic-gray" type="text" onChange={(event) => setUserName(event.currentTarget.value)} value={userName} />
                        </div>
                    </div>
                    <div className="menu-item">
                        <div className="notice-open">
                            <p className="mapage_tle2">E-mail</p>
                            <input
                                className="basic-gray"
                                type="text"
                                onChange={(event) => setPhoneNumber(event.currentTarget.value)}
                                placeholder="휴대폰 번호 입력"
                                value={email}
                                disabled
                            />
                        </div>
                    </div>
                    <div className="menu-item">
                        <div className="notice-open">
                            <p className="mapage_tle2">휴대폰 번호</p>
                            <input
                                className="basic-gray"
                                type="text"
                                onChange={(event) => setPhoneNumber(event.currentTarget.value)}
                                placeholder="휴대폰 번호 입력"
                                value={phoneNumber}
                                disabled
                            />
                        </div>
                    </div>

                    <div className="menu-item">
                        <div className="notice-open">
                            <p className="mapage_tle2">소속</p>
                            <TeamSearchInput teamName={myTeam.name} setTeamNo={setTeamNo} setSelectedTeam={setTeamInfo}></TeamSearchInput>
                        </div>
                    </div>
                    <div className="menu-item">
                        <div className="notice-open">
                            <p className="mapage_tle2">팀 분류 그룹</p>
                            <input className="basic-gray" type="text" value={teamBelongedCode} disabled />
                        </div>
                    </div>
                    <div className="menu-item">
                        <div className="notice-open">
                            <p className="mapage_tle2">팀 분류</p>
                            <input className="basic-gray" type="text" value={teamClassificationCode} disabled />
                        </div>
                    </div>
                    <div className="menu-item">
                        <div className="notice-open">
                            <p className="mapage_tle2">종목</p>
                            <input className="basic-gray" type="text" value={teamSports} disabled />
                        </div>
                    </div>
                    <a onClick={updateBtnHandler} className="bt-blue-2">
                        수정
                    </a>
                </nav>
            </MyPageRightContent>
        </MyPageMainLayout>
    );
}

export default UserMyPageSettingContainer;
