import React from 'react';
import Exercise from '../../../../domain/Exercise';
import Code from '../../../../../../domain/code/Code';

interface IProps {
    exercise: Exercise;
    title: string;
    itemTit: string;
    sportTypes: Code[];
    bodyParts: Code[];
    handler: (evt: any) => void;
}
const ExerContents = (props: IProps) => {
    let exercise = props.exercise;

    let title = props.title;
    let itemTit = props.itemTit;
    let sportTypes = props.sportTypes;
    let bodyParts = props.bodyParts;
    let changePropHandler = props.handler;

    const btnVal = ['1', '2', '3'];
    let types = sportTypes;
    if (exercise.exerType === '42') {
        types = bodyParts;
    }
    const applyedBtns = btnVal.map((btn) => {
        let className = 'center-button-cancle';
        if (exercise.applyedLevel === btn) {
            className = 'center-button-yes';
        }
        return (
            <button className={className} name="applyedLevel" onClick={changePropHandler} value={btn} key={btn}>
                {btn}단계
            </button>
        );
    });

    const exerItem = (
        <select className="center-select-sports" name="exerItem" onChange={changePropHandler} value={exercise.exerItem}>
            <option value="-1">선택하세요</option>
            {types?.map((type, idx) => {
                return (
                    <option value={type.codeId} key={idx}>
                        {type.name}
                    </option>
                );
            })}
        </select>
    );

    const isReleased = (
        <select
            name="isReleased"
            className="center-select-sports"
            onChange={changePropHandler}
            value={exercise.isReleased === 'true' ? 'true' : 'false'}
        >
            <option value={'false'}>비공개</option>
            <option value={'true'}>공개</option>
        </select>
    );
    let refImgPath = `${process.env.REACT_APP_RESOURCE_API_HOST}${exercise.refImgPath}`;
    let existClip =
        exercise.refClipPath !== '' ? (
            <button>
                <i className="fa fa-file-video-o" aria-hidden="true"></i>영상보기
            </button>
        ) : (
            <></>
        );
    return (
        <>
            <div className="center-popup-list-box prescription-box">
                <div className="flex-basic prescription-exercises-tit">
                    <i className="fa fa-cube"> {title}</i>
                </div>
                <div className="flex-basic">
                    <div className="flex">
                        <div className="center-label-sports">
                            <i className="fa fa-caret-right"> {itemTit}</i>
                        </div>
                        {exerItem}
                    </div>
                    <div className="flex">
                        <div className="center-label-sports">
                            <i className="fa fa-caret-right"> 공개여부</i>
                        </div>
                        {isReleased}
                    </div>
                </div>
                {/* <!--//flex-basic--> */}
                <div className="flex center-tpd20">
                    <div className="center-label-sports">
                        <i className="fa fa-caret-right"> 적용단계</i>
                    </div>
                    {applyedBtns}
                </div>

                <div className="flex center-tpd20">
                    <div className="center-label-sports">
                        <i className="fa fa-caret-right"> 운동명</i>
                    </div>
                    <input type="text" className="center-input-sports" name="title" onChange={changePropHandler} value={exercise.title} />
                </div>
                {/* <!--//flex-basic--> */}

                <div className="flex center-tpd20">
                    <div className="center-label-sports">
                        <i className="fa fa-caret-right"> 운동방법</i>
                    </div>
                    <input type="text" className="center-input-sports" name="exerciseWay" onChange={changePropHandler} value={exercise.exerciseWay} />
                </div>
                {/* <!--//flex-basic--> */}
                <div className="flex center-tpd20">
                    <div className="center-label-sports">
                        <i className="fa fa-caret-right"> 운동설명</i>
                    </div>
                    <textarea className="center-textarea" name="exerciseDesc" onChange={changePropHandler} value={exercise.exerciseDesc}></textarea>
                </div>
                {/* <!--//flex-basic--> */}

                <div className="flex center-tbpd20">
                    <div className="center-label-sports">
                        <i className="fa fa-caret-right"> 이미지</i>
                    </div>
                    <div className="center-sports-photo">
                        <label htmlFor={'uploadImg'}>파일선택...</label>
                        <div id="photoStatus">
                            <img src={refImgPath} />
                        </div>
                        <input type="file" id="uploadImg" name="refImgPath" onChange={changePropHandler} style={{ display: 'none' }} />
                    </div>
                    &nbsp;
                    <div className="center-label-sports">
                        <i className="fa fa-caret-right"> 영상</i>
                    </div>
                    <div className="center-sports-video">
                        <label htmlFor={'uploadClip'}>파일선택...</label>
                        <div id="clipStatus" className="flex">
                            {existClip}
                        </div>
                        <input type="file" id="uploadClip" name="refClipPath" onChange={changePropHandler} style={{ display: 'none' }} />
                    </div>
                </div>
                {/* <!--//flex-basic--> */}
            </div>
            {/* <!--//center-popup-list--> */}
        </>
    );
};

export default ExerContents;
