import React from 'react';
import AdminTable from './AdminTable';
import Infra from '../../domain/infra/Infra';
import AdminFacilityTableTr from './AdminFacilityTableTr';
import InfraSearchCondition from '../../domain/infra/param/InfraSearchCondition';

interface AdminFacilityTableProps {
    page: number;
    pageCnt: number;
    pagingSize: number;
    infras?: Infra[];
    searchCondition?: InfraSearchCondition;
}
function AdminFacilityTable(props: AdminFacilityTableProps) {
    return (
        <>
            <AdminTable>
                <colgroup>
                    <col style={{ width: '5%' }} />
                    <col style={{ width: '20%' }} />
                    <col style={{ width: '5%' }} />
                    <col style={{ width: '40%' }} />
                    <col style={{ width: '5%' }} />
                    <col style={{ width: '25%' }} />
                </colgroup>
                <thead>
                    <tr>
                        <td>No.</td>
                        <td>시설명</td>
                        <td>분류</td>
                        <td>주소</td>
                        <td>추천</td>
                        <td>관리</td>
                    </tr>
                </thead>
                <tbody>
                    {props.infras ? (
                        props.infras.length > 0 ? (
                            props.infras?.map((infra: Infra, index: number) => {
                                return (
                                    <AdminFacilityTableTr
                                        key={index}
                                        page={props.page}
                                        seq={props.page * props.pagingSize + (index + 1)}
                                        infra={infra}
                                        pageCnt={props.pageCnt}
                                        searchCondition={props.searchCondition}
                                    ></AdminFacilityTableTr>
                                );
                            })
                        ) : (
                            <tr>
                                <td colSpan={6}>
                                    <h5>검색된 부대시설이 없습니다.</h5>
                                </td>
                            </tr>
                        )
                    ) : (
                        <></>
                    )}
                </tbody>
            </AdminTable>
        </>
    );
}

export default AdminFacilityTable;
