import LeftSearcher from '../../../component/search/LeftSearcher';
import CommonSearchCondition from '../../common/param/CommonSearchCondition';
class VisitedTeamInfoSearchCondition extends CommonSearchCondition {
    belongCodeIds?: string;
    classficationCodeIds?: string;
    sportCodeIds?: string;
    parentRegionCodeIds?: string;
    regionCodeIds?: string;
    visitedStartDate?: string;
    visitedEndDate?: string;
    visitedTeamNameSearchWord?: string;
    visitedTeamInfoNo?: string;
    visitedTeamNo?: string;
    returnPath?: string;
    page?: number;
    groupIdx?: number;

    static makeVisitedteamInfoSearchCondition(
        belongCodeIds?: string,
        classficationCodeIds?: string,
        sportCodeIds?: string,
        regionCodeIds?: string,
        visitedStartDate?: string,
        visitedEndDate?: string,
        visitedTeamNameSearchWord?: string,
    ): VisitedTeamInfoSearchCondition {
        let condition = new VisitedTeamInfoSearchCondition();
        condition.belongCodeIds = belongCodeIds;
        condition.classficationCodeIds = classficationCodeIds;
        condition.sportCodeIds = sportCodeIds;
        condition.regionCodeIds = regionCodeIds;
        condition.visitedStartDate = visitedStartDate;
        condition.visitedEndDate = visitedEndDate;
        condition.visitedTeamNameSearchWord = visitedTeamNameSearchWord;

        return condition;
    }
}

export default VisitedTeamInfoSearchCondition;
