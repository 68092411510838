import React from 'react';
import { JsonProperty, JsonObject } from 'json2typescript';

@JsonObject('basalPrescription')
export class BasalPrescription {
    @JsonProperty('aeroFreq')
    aeroFreq: string = '';
    @JsonProperty('aeroWarmUpStatic')
    aeroWarmUpStatic: string = '';
    @JsonProperty('aeroWarmUpDyna')
    aeroWarmUpDyna: string = '';
    @JsonProperty('aeroWarmUpFoam')
    aeroWarmUpFoam: string = '';
    @JsonProperty('aeroIntensity')
    aeroIntensity: string = '';
    @JsonProperty('aeroIntensityVo2')
    aeroIntensityVo2: string = '';
    @JsonProperty('aeroIntensityHr')
    aeroIntensityHr: string = '';
    @JsonProperty('aeroTime')
    aeroTime: string = '';
    @JsonProperty('aeroType')
    aeroType: string = '';
    @JsonProperty('weightFreq')
    weightFreq: string = '';
    @JsonProperty('weightWarmUpStatic')
    weightWarmUpStatic: string = '';
    @JsonProperty('weightWarmUpDyna')
    weightWarmUpDyna: string = '';
    @JsonProperty('weightWarmUpFoam')
    weightWarmUpFoam: string = '';
    @JsonProperty('weightIntensity')
    weightIntensity: string = '';
    @JsonProperty('weightIntensityVo2')
    weightIntensityVo2: string = '';
    @JsonProperty('weightIntensityHr')
    weightIntensityHr: string = '';
    @JsonProperty('weightTime')
    weightTime: string = '';
    @JsonProperty('weightType')
    weightType: string = '';
}
