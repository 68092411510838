import React, { useEffect, useState } from 'react';
import Reservation from '../../../../domain/reservation/Reservation';
import { Link } from 'react-router-dom';
import CommonSearchBar from '../../../../component/search/CommonSearchBar';
import InfraMainViewer from '../../../../component/infra/InfraMainViewer';
import { InfraCategoryType } from '../../../../domain/infra/InfraCategoryType';
import useInfraOfNearDistanceRetrieve from '../../../../hook/infra/useInfraOfNearDistanceRetrieve';

interface TrainningMainContainerOfExistScheduleProps {
    reservation: Reservation;
}

function TrainningMainContainerOfExistSchedule(props: TrainningMainContainerOfExistScheduleProps) {
    let latitude = props.reservation.infra?.latitude;
    let longitude = props.reservation.infra?.longitude;

    const {
        infras: infrasOfSubInfa,
        infraCategories: infraCategoriesOfSubInfra,
        setChildInfraCategoryNo: setChildInfraCategoryNoOfSubInfa,
    } = useInfraOfNearDistanceRetrieve(InfraCategoryType.SUB_INFRA_NO, latitude, longitude);
    const {
        infras: infrasOfLodgment,
        infraCategories: infraCategoriesOfLodgment,
        setChildInfraCategoryNo: setChildInfraCategoryNoOfLodgment,
    } = useInfraOfNearDistanceRetrieve(InfraCategoryType.LODGMENT_NO, latitude, longitude);
    const {
        infras: infrasOfFood,
        infraCategories: infraCategoriesOfFood,
        setChildInfraCategoryNo: setChildInfraCategoryNoofFood,
    } = useInfraOfNearDistanceRetrieve(InfraCategoryType.FOOD_NO, latitude, longitude);

    return (
        <div>
            <>
                {/* 예약 일정 정보 표시 */}
                {/* <div className="lasted-reservation-info">
                    <h4>최근 예약 일정 시설</h4>
                    <h5>{props.reservation.infra?.name}</h5>
                    <span>{props.reservation.startDate?.split(" ")[0]}</span>
                    ~
                    <span>{props.reservation.endDate?.split(" ")[0]}</span>
                    <br></br>
                    <Link to={`/near-search`}>주변 검색</Link>
                </div> */}
                <InfraMainViewer
                    infras={infrasOfSubInfa}
                    infraCategories={infraCategoriesOfSubInfra}
                    setChildInfraCategoryNo={setChildInfraCategoryNoOfSubInfa}
                    infraType={'sub'}
                    searchingType="nearBy"
                    latitude={latitude}
                    longitude={longitude}
                    title={'주변 부대 시설'}
                ></InfraMainViewer>
                <InfraMainViewer
                    infras={infrasOfLodgment}
                    infraCategories={infraCategoriesOfLodgment}
                    setChildInfraCategoryNo={setChildInfraCategoryNoOfLodgment}
                    infraType={'lodgment'}
                    searchingType="nearBy"
                    latitude={latitude}
                    longitude={longitude}
                    title={'주변 숙소'}
                ></InfraMainViewer>
                <InfraMainViewer
                    infras={infrasOfFood}
                    infraCategories={infraCategoriesOfFood}
                    setChildInfraCategoryNo={setChildInfraCategoryNoofFood}
                    infraType={'food'}
                    searchingType="nearBy"
                    latitude={latitude}
                    longitude={longitude}
                    title={'주변 맛집'}
                ></InfraMainViewer>
            </>
        </div>
    );
}

export default TrainningMainContainerOfExistSchedule;
