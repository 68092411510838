import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../moldules/index';
import Reservation from '../domain/reservation/Reservation';
import ReservationServiceImpl from '../service/reservation/impl/ReservationServiceImpl';

/**
 * 사용자 팀의 예약 일정 및 기타 일정 관련 조회 훅
 */
const reservationService = new ReservationServiceImpl();
function useMyTeamScheduleHook() {
    const [reservation, setReservation] = useState<Reservation>();
    const [reservations, setReservations] = useState<Reservation[]>();
    const {myTeam} = useSelector((state: RootState) => state.userSessionReducer);

    useEffect(() => {
        if(myTeam) {
            reservationService.getReservationOfTeamAndState(myTeam.teamNo, 1002)
            .then((reservations: Reservation[]) => {
                if(reservations) {
                    if(reservations.length > 0) {
                        setReservation(reservations[0]);
                    }
                }
            });

            reservationService.getReservationOfTeam(myTeam.teamNo) 
            .then((reservations: Reservation[]) => {
                if(reservations) {
                    if(reservations.length > 0) {
                        setReservations(reservations);
                    }
                }
            })
        }
    }, [myTeam]);

    return {reservation, reservations};
}

export default useMyTeamScheduleHook;