import React from 'react';

interface MyPageBodyProps {
    children?: React.ReactNode;
}

function MyPageBody({ children }: MyPageBodyProps) {
    return (
        <div id="mypage">
            <div className="mypage_ad">{children}</div>
        </div>
    );
}

export default MyPageBody;
