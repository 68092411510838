import { CodeType } from '../domain/code/CodeType';
const ADD_CONDITION = 'searchCondition/ADD_CONDITION' as const;
const PUT_CONDITION = 'searchCondition/PUT_CONDITION' as const;
const CLEAR_CONDITION = 'searchCondition/CLEAR_CONDITION' as const;
const INIT_CONDITION = 'searchCondition/INIT_CONDITION' as const;

export const changeConditionAction = (conditionNo: number, codeType: CodeType) => ({type: ADD_CONDITION, conditionNo: conditionNo, codeType: codeType});
export const putConditionAction = (conditionNo: number, codeType?: CodeType) => ({type: PUT_CONDITION, conditionNo: conditionNo, codeType: codeType});
export const clearConditionAction = (codeType: CodeType) => ({type: CLEAR_CONDITION, codeType: codeType, conditionNo: 0});
export const initConditionAction = () => ({type: INIT_CONDITION, codeType: undefined, conditionNo: 0});

type SearchConditionAction = 
    | ReturnType<typeof changeConditionAction>
    | ReturnType<typeof putConditionAction>
    | ReturnType<typeof clearConditionAction>
    | ReturnType<typeof initConditionAction>;

type SearchConditionState = {
    searchConditionMap: any;
}

/**
 * searchConditionMap 형태
 * {
 *  region: {1001: 1001}, 
 *  sport: {2001: 2001}
 * }
 */
const initialState: SearchConditionState = {
    searchConditionMap: new Object()
}

function changeConditionActionProccess(beforeState: SearchConditionState, action: SearchConditionAction) {
    const beforeSearchConditionMap = beforeState.searchConditionMap;
    if(action.codeType) {
        const codeType: CodeType = action.codeType;
        const conditionNo = action.conditionNo;
        let conditionMap: any = undefined;

        const newSearchConditionMap = Object.assign({}, beforeSearchConditionMap);
        
        if(newSearchConditionMap.hasOwnProperty(codeType)) {
            conditionMap = newSearchConditionMap[codeType];
            if(conditionMap.hasOwnProperty(conditionNo)) delete conditionMap[conditionNo]
            else conditionMap[conditionNo] = conditionNo;
            
        } else {
            conditionMap = new Object();
            conditionMap[conditionNo] = conditionNo;
        }

        newSearchConditionMap[codeType] = conditionMap;

        return {
            searchConditionMap: newSearchConditionMap
        }
    } else {
        return {
            searchConditionMap: beforeSearchConditionMap
        }
    }
    
}

function putConditionActionProcess(beforeState: SearchConditionState, action: SearchConditionAction) {
    const beforeSearchConditionMap = beforeState.searchConditionMap;
    if(action.codeType) {
        const codeType: CodeType = action.codeType;
        const conditionNo = action.conditionNo;
        let conditionMap: any = new Object();
        const newSearchConditionMap = Object.assign({}, beforeSearchConditionMap);
        conditionMap[conditionNo] = conditionNo;
        
        newSearchConditionMap[codeType] = conditionMap;

        return {
            searchConditionMap: newSearchConditionMap
        }
    } else {
        return {
            searchConditionMap: beforeSearchConditionMap
        }
    }
}

function clearConditionActionProcess(beforeState: SearchConditionState, action: SearchConditionAction) {
    const beforeSearchConditionMap = beforeState.searchConditionMap;
    if(action.codeType) {
        const newSearchConditionMap = Object.assign({}, beforeSearchConditionMap);
        const codeType = action.codeType;
        delete newSearchConditionMap[codeType];

        return {
            searchConditionMap: newSearchConditionMap
        }
    } else {
        return {
            searchConditionMap: beforeSearchConditionMap
        }
    }
}

function searchCondition(state: SearchConditionState = initialState, action: SearchConditionAction) {
    switch(action.type) {
        case ADD_CONDITION:
            return changeConditionActionProccess(state, action);
        case PUT_CONDITION:
            return putConditionActionProcess(state, action);
        case CLEAR_CONDITION:
            return clearConditionActionProcess(state, action);
        case INIT_CONDITION:
            return initialState;
        default:
            return state;
    }
}

export default searchCondition;