import React, { useEffect, useState } from 'react';
import { Link, RouteComponentProps, useParams, useLocation } from 'react-router-dom';
import CommonSearchBar from '../../component/search/CommonSearchBar';
import LoginState from '../../component/header/LoginState';

function CommonHeader(prop: any) {
    const hostURL = process.env.REACT_APP_IIS_URL;
    const location = useLocation();
    const [isShowSearchBard, setIsShowSearchBar] = useState<boolean>(false);

    useEffect(() => {
        let pathname = location.pathname;
        if (pathname.indexOf('trainning') > 0 || pathname.indexOf('infra') > 0) setIsShowSearchBar(true);
        else setIsShowSearchBar(false);
    }, []);

    return (
        <>
            <header className="new_header">
                <div className="dim-layer"></div>
                <div className="header-wrap">
                    <div className="header-wrap-ad">
                        <a href="/" className="logo"></a>
                        <nav className="pc-ver">
                            <ul className="nav">
                                <li>
                                    <span>
                                        <a href={`${hostURL}/web/introduce01.do`}>센터소개</a>
                                    </span>
                                    <ul className="sub-nav">
                                        <li>
                                            <a href={`${hostURL}/web/introduce01.do`}>인사말</a>
                                        </li>
                                        <li>
                                            <a href={`${hostURL}/web/introduce02.do`}>비전 및 목표</a>
                                        </li>
                                        <li>
                                            <a href={`${hostURL}/web/introduce03.do`}>조직도</a>
                                        </li>
                                        <li>
                                            <a href={`${hostURL}/web/introduce04.do`}>연혁</a>
                                        </li>
                                        <li>
                                            <a href={`${hostURL}/web/introduce05.do`}>CI 소개</a>
                                        </li>
                                        <li>
                                            <a href={`${hostURL}/web/introduce06.do`}>찾아오시는 길</a>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <span>
                                        <a href={`${hostURL}/web/news01.do`}>센터소식</a>
                                    </span>
                                    <ul className="sub-nav">
                                        <li>
                                            <a href={`${hostURL}/web/news01.do`}>행사안내</a>
                                        </li>
                                        <li>
                                            <a href={`${hostURL}/web/news02.do`}>보도자료</a>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <span>
                                        <a href={`${hostURL}/web/business01.do`}>사업안내</a>
                                    </span>
                                    <ul className="sub-nav">
                                        <li>
                                            <a href={`${hostURL}/web/business01.do`}>사업소개</a>
                                        </li>
                                        <li>
                                            <a href={`${hostURL}/web/business02.do`}>사업공고</a>
                                        </li>
                                        <li>
                                            <a href={`${hostURL}/web/business03.do`}>사업 FAQ</a>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <span>
                                        <a href="/trainning">전지훈련시설</a>
                                    </span>
                                </li>
                                {/* <li>
                                    <span>
                                        <a href="/touring">투어링</a>
                                    </span>
                                </li> */}
                                <li>
                                    <span>
                                        {/* <a href={`${hostURL}/web/rehabilitation01.do`}>재활센터</a> */}
                                        <a href="/rehabilitation/re-center">재활센터</a>
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        {/* <a href={`${hostURL}/web/rehabilitation01.do`}>재활센터</a> */}
                                        <a href="/status">방문스포츠팀현황</a>
                                    </span>
                                    <ul className="sub-nav">
                                        <li>
                                            <a href="/status">지역별 현황</a>
                                        </li>
                                        <li>
                                            <a href="/status/support-detail">지역별 지원내용</a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </nav>
                        <div className="login pc-ver">
                            <LoginState></LoginState>
                        </div>
                        {isShowSearchBard ? <CommonSearchBar></CommonSearchBar> : ''}
                        <button type="button" className="btn-menu-open mobile-ver">
                            전체메뉴
                        </button>
                    </div>

                    <nav className="mobile-ver">
                        <div className="nav-wrap">
                            <div className="nav-top">
                                <div className="nav-header">
                                    <p className="header">어서오세요. 환영합니다.</p>
                                    <button type="button" className="btn-menu-close">
                                        닫기
                                    </button>
                                </div>
                                <LoginState></LoginState>
                            </div>
                            <div className="nav-bottom">
                                <div className="nav-bottom-wrap">
                                    <div className="nav-bottom-left">
                                        <div className="tabs">
                                            <a href="#" className="tab selected">
                                                <span>센터 소개</span>
                                            </a>
                                            <a href="#" className="tab">
                                                <span>센터 소식</span>
                                            </a>
                                            <a href="#" className="tab">
                                                <span>사업 안내</span>
                                            </a>
                                            <a href="/trainning" className="tab">
                                                <span>전지훈련시설</span>
                                            </a>
                                            {/* <a href="/touring" className="tab">
                                                <span>투어링</span>
                                            </a> */}
                                            {/* <a href="/rehabilitation" className="tab">
                                                <span>스포츠재활센터</span>
                                            </a> */}
                                            <a href="/rehabilitation/re-center" className="tab">
                                                <span>재활센터</span>
                                            </a>
                                            <a href="/status" className="tab">
                                                <span>방문스포츠팀현황</span>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="nav-bottom-right">
                                        <div className="panel on">
                                            <a href={`${hostURL}/web/introduce01.do`}>인사말</a>
                                            <a href={`${hostURL}/web/introduce02.do`}>비전 및 목표</a>
                                            <a href={`${hostURL}/web/introduce03.do`}>조직도</a>
                                            <a href={`${hostURL}/web/introduce04.do`}>연혁</a>
                                            <a href={`${hostURL}/web/introduce05.do`}>CI 소개</a>
                                            <a href={`${hostURL}/web/introduce06.do`}>찾아오시는 길</a>
                                        </div>
                                        <div className="panel">
                                            <a href={`${hostURL}/web/news01.do`}>행사 안내</a>
                                            <a href={`${hostURL}/web/news02.do`}>보도자료</a>
                                        </div>
                                        <div className="panel">
                                            <a href={`${hostURL}/web/business01.do`}>사업 소개</a>
                                            <a href={`${hostURL}/web/business02.do`}>사업공고</a>
                                            <a href={`${hostURL}/web/business03.do`}>사업 FAQ</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </nav>
                </div>

                <a href="http://pf.kakao.com/_ZLxnzb" target="_blank" className="drop-msg">
                    <img src="/assets/image/ask-kakao.png" width={50} />
                </a>
            </header>
        </>
    );
}

export default CommonHeader;
