import TeamSearchCondition from '../../domain/team/param/TeamSearchCondition';
import Paging from '../../domain/common/Paging';
import { useEffect, useState } from 'react';
import Team from '../../domain/team/Team';
import TeamServiceImpl from '../../service/team/impl/TeamServiceImpl';

const teamService = new TeamServiceImpl();

function useTeamRetrieveBySearchCondition(teamSearchCondition?: TeamSearchCondition, paging?: Paging) {
    const [teams, setTeams] = useState(new Array<Team>());
    const [teamsCnt, setTeamsCnt] = useState<number>(0);

    useEffect(() => {
        //조건에 맞는 팀 페이징 조회
        if (teamSearchCondition && paging) {
            teamService.getTeamBySearchCondition(teamSearchCondition, paging).then((teams: Team[]) => {
                setTeams(teams);
            });

            //조건에 맞는 팀 총 갯수
            teamService.getTeamCntBySearchCondition(teamSearchCondition).then((teamsCnt: number) => {
                setTeamsCnt(teamsCnt);
            });
        }
    }, [teamSearchCondition, paging]);

    return {
        teams,
        teamsCnt,
    };
}

export default useTeamRetrieveBySearchCondition;
