import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import CommonHeader from '../../common/CommonHeader';
import LoginState from '../../../component/header/LoginState';
import { useSelector } from 'react-redux';
import { RootState } from '../../../moldules/index';
import { confirmAlert } from 'react-confirm-alert';

function MyPageHeader() {
    const { user } = useSelector((state: RootState) => state.userSessionReducer);

    const [menuOpenable, setMenuOpenable] = useState(false);
    const [mobileOpenable, setMobileOpenable] = useState(false);
    const hostURL = process.env.REACT_APP_IIS_URL;

    const clickEventHandler = () => {
        setMobileOpenable(!mobileOpenable);
    };

    const linkClickHandler = (evt: any) => {
        evt.preventDefault();
        //history.replace("/mypage/team-admin/schedule");
        confirmAlert({
            title: '알림',
            message: '서비스 준비 중입니다. 감사합니다.',
            buttons: [{ label: '확인', onClick: () => {} }],
        });
    };

    console.log(user);
    return (
        <>
            <header className="new_header new_header_mypage">
                <div className="dim-layer"></div>
                <div className="header-wrap">
                    <div className="header-wrap-ad">
                        <a href="/" className="logo"></a>
                        <span className="mypage_tle">마이페이지</span>
                        <nav className="pc-ver mypage-nav">
                            <ul className="nav">
                                <li>
                                    <span>
                                        <Link to="/mypage/team-admin/player-list">팀 정보</Link>
                                    </span>
                                    <ul className="sub-nav">
                                        {user.checkTeamAdmin() ? (
                                            <>
                                                <li>
                                                    <Link to="/mypage/team-admin/reservation">팀예약관리</Link>
                                                </li>
                                                <li>
                                                    <Link to="/mypage/team-admin/player-list">소속선수관리</Link>
                                                </li>
                                                <li>
                                                    <Link to="/mypage/team-admin/coupon">발급쿠폰</Link>
                                                </li>
                                            </>
                                        ) : (
                                            <></>
                                        )}
                                        {user.checkTeamCrew() ? (
                                            <>
                                                <li>
                                                    <Link to="/mypage/player/reservation">선수예약관리</Link>
                                                </li>
                                            </>
                                        ) : (
                                            <></>
                                        )}
                                        <li>
                                            <Link to={'#'} onClick={linkClickHandler}>
                                                팀 일정
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <span>
                                        <Link to="/mypage/team-admin/setting">내 정보 설정</Link>
                                    </span>
                                    <ul className="sub-nav">
                                        <li>
                                            <Link to="/mypage/user/favorities">즐겨찾기</Link>
                                        </li>
                                        <li>
                                            <Link to="/mypage/team-admin/setting">내 정보 설정</Link>
                                        </li>
                                        {user.provider == 'local' ? (
                                            <li>
                                                <Link to="/mypage/user/pw">비밀번호 설정</Link>
                                            </li>
                                        ) : (
                                            <></>
                                        )}
                                        {!user.checkTeamAdmin() ? (
                                            <li>
                                                <Link to="/mypage/user/questionnaire">설문조사</Link>
                                            </li>
                                        ) : (
                                            <></>
                                        )}
                                    </ul>
                                </li>
                                <li>
                                    <span>
                                        <Link to="/mypage/team-admin/qna">문의하기</Link>
                                    </span>
                                    <ul className="sub-nav">
                                        <li>
                                            <Link to="/mypage/team-admin/qna">문의하기</Link>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </nav>
                        <div className="login pc-ver">
                            <LoginState></LoginState>
                        </div>
                        <button type="button" className="btn-menu-open mobile-ver">
                            전체메뉴
                        </button>
                    </div>

                    <nav className="mobile-ver">
                        <div className="nav-wrap">
                            <div className="nav-top">
                                <div className="nav-header">
                                    <p className="header">어서오세요. 환영합니다.</p>
                                    <button type="button" className="btn-menu-close">
                                        닫기
                                    </button>
                                </div>
                                <LoginState></LoginState>
                            </div>
                            <div className="nav-bottom">
                                <div className="nav-bottom-wrap">
                                    <div className="nav-bottom-left">
                                        <div className="tabs">
                                            <a href="#" className="tab selected">
                                                <span>팀 정보</span>
                                            </a>
                                            <a href="#" className="tab">
                                                <span>내 정보 설정</span>
                                            </a>
                                            <a href="#" className="tab">
                                                <span>문의하기</span>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="nav-bottom-right">
                                        <div className="panel on">
                                            <Link to="/mypage/team-admin/reservation">팀예약관리</Link>
                                            {user.checkTeamAdmin() ? <Link to="/mypage/team-admin/player-list">소속선수관리</Link> : <></>}
                                            <Link to={'#'} onClick={linkClickHandler}>
                                                팀 일정
                                            </Link>
                                        </div>
                                        <div className="panel">
                                            <Link to="/mypage/team-admin/setting">내 정보 설정</Link>
                                            {user.provider == 'local' ? <Link to="/mypage/user/pw">비밀번호 설정</Link> : <></>}
                                            <Link to="/mypage/user/favorities">즐겨찾기</Link>
                                            {!user.checkTeamAdmin() ? <Link to="/mypage/user/questionnaire">설문조사</Link> : <></>}
                                        </div>
                                        <div className="panel">
                                            <Link to="/mypage/team-admin/qna">문의하기</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </nav>
                </div>
            </header>
        </>
    );
}

export default MyPageHeader;
