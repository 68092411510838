import React, { useEffect } from 'react';
import Questionnaire from '../../rehabcenter/domain/Questionnaire';
import QnA from '../../rehabcenter/domain/QnA';
import { Link } from 'react-router-dom';

interface QnACardProps {
    idx: number;
    qna: QnA;
}

export default function QnACard(props: QnACardProps) {
    return (
        <div className="my-in my_research">
            <div className="my-in-info">
                <div className="my-in-info-box">
                    <div style={{ width: '80%' }}>
                        <p className="r_txt1">
                            설문 {props.idx + 1} {props.qna.displayTitle}
                        </p>
                        <p className="r_txt2">설문요청일: {props.qna.answerDate}</p>
                        {props.qna.answerModDate != null ? (
                            <p className="r_txt2" style={{ color: 'blue' }}>
                                설문응답일: {props.qna.answerModDate}
                            </p>
                        ) : (
                            <p className="r_txt2" style={{ color: 'red' }}>
                                응답전
                            </p>
                        )}
                    </div>
                    <div className="my-in-img my_research_btn" style={{ width: '15%' }}>
                        {props.qna.answer ? (
                            <>
                                <div className="blue">제출 완료</div>
                                <Link
                                    to={`/mypage/user/questionnaire/${props.qna.answerNo}/${props.qna.targetNo}/${props.qna.quesNo}`}
                                    className="gray_btn"
                                >
                                    <button>수정하기</button>
                                </Link>
                            </>
                        ) : (
                            <>
                                <div className="red">미제출</div>
                                <Link
                                    to={`/mypage/user/questionnaire/${props.qna.answerNo}/${props.qna.targetNo}/${props.qna.quesNo}`}
                                    className="red_btn"
                                >
                                    <button>제출하기</button>
                                </Link>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
