import AbstractService from '../../AbstractService';
import UserService from '../UserService';
import User from '../../../domain/user/User';
import APIServerConfig from '../../../config/APIServerConfig';
import { AxiosRequestConfig } from 'axios';
import { ACCESS_TOKEN } from '../../../config/Constant';
import BelongTeam from '../../../domain/user/BelongTeam';
import SignupInfo from '../../../domain/user/SignupInfo';
import RetrieveUser from '../../../domain/user/RetrieveUser';
import UserModifyParam from '../../../domain/user/param/UserModifyParam';
import UserDetailInfo from '../../../domain/user/UserDetailInfo';
import UserSearchCondition from '../../../domain/user/param/UserSearchCondition';
import Paging from '../../../domain/common/Paging';
import LoginToken from '../../../domain/LoginToken';
import UserLoginParam from '../../../domain/user/param/UserLoginParam';
import BatchData from '../../../view/rehabcenter/domain/BatchData';

class UserServiceImpl extends AbstractService implements UserService {
    updateUserPwForMobileCheck(email: string, changedPassword: string): Promise<boolean> {
        return new Promise((resolve, reject) => {
            APIServerConfig.recordAPIAxios
                .get(`/change-pw`, {
                    params: {
                        email: email,
                        password: changedPassword,
                    },
                })
                .then((response) => {
                    resolve(true);
                })
                .catch((err) => {
                    resolve(false);
                });
        });
    }
    getUserDetailInfo(userNo: string): Promise<UserDetailInfo> {
        return new Promise((resolve, reject) => {
            APIServerConfig.recordAPIAxios.get(`/user/${userNo}`).then((response) => {
                const userInfo = this.jsonConvert.deserializeObject(response.data, UserDetailInfo);
                resolve(userInfo);
            });
        });
    }

    getUserInfos(userSearchCondition: UserSearchCondition, paging: Paging): Promise<RetrieveUser[]> {
        return new Promise((resolve, reject) => {
            APIServerConfig.recordAPIAxios
                .get('/user', {
                    params: Object.assign(userSearchCondition, paging),
                })
                .then((response) => {
                    const users = this.jsonConvert.deserializeArray(response.data, RetrieveUser);
                    resolve(users);
                });
        });
    }

    getUserInfosCnt(userSearchCondition: UserSearchCondition): Promise<number> {
        return new Promise((resolve, reject) => {
            APIServerConfig.recordAPIAxios
                .get(`/user/cnt`, {
                    params: userSearchCondition,
                })
                .then((response) => {
                    const usersCnt = response.data;
                    resolve(usersCnt);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    getUserInfo(): Promise<User> {
        return new Promise((resolve, reject) => {
            APIServerConfig.authAPIAxios
                .get('/user/me', APIServerConfig.getReqHeaderConfig())
                .then((response) => {
                    const user = this.jsonConvert.deserializeObject(response.data, User);
                    resolve(user);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    deleteUser(userNo: string): Promise<string> {
        return new Promise((resolve, reject) => {
            APIServerConfig.recordAPIAxios.delete(`/user/${userNo}`).then((response) => {
                resolve(response.toString());
            });
        });
    }

    getBelongTeamInfo(userNo: string): Promise<BelongTeam[]> {
        return new Promise((resolve, reject) => {
            APIServerConfig.recordAPIAxios.get(`/user/${userNo}/belonged-team`).then((response) => {
                let belongTeam: BelongTeam[] = this.jsonConvert.deserializeArray(response.data, BelongTeam);
                resolve(belongTeam);
            });
        });
    }

    updateBelongTeamInfo(userNo: string, belongTeams: BelongTeam[]): Promise<BelongTeam[]> {
        return new Promise((resolve, reject) => {
            APIServerConfig.recordAPIAxios.put(`/user/${userNo}/belonged-team`, JSON.stringify(belongTeams)).then((response) => {
                let belongTeams: BelongTeam[] = this.jsonConvert.deserializeArray(response.data, BelongTeam);
                resolve(belongTeams);
            });
        });
    }

    async getUserInfoSync() {
        const config: AxiosRequestConfig = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.getItem(ACCESS_TOKEN),
            },
        };
        let promise = APIServerConfig.authAPIAxios.get('/user/me', config);
        let response = await promise;

        return response.data;
    }

    signUpBySNS(signupInfo: SignupInfo): Promise<SignupInfo> {
        return new Promise((resolve, reject) => {
            APIServerConfig.authAPIAxios
                .post('/auth/signup/sns', JSON.stringify(signupInfo), {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                .then((response) => {
                    resolve(response.data);
                });
        });
    }

    signUp(signupInfo: SignupInfo): Promise<SignupInfo> {
        return new Promise((resolve, reject) => {
            APIServerConfig.authAPIAxios
                .post('/auth/signup', JSON.stringify(signupInfo), {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                .then((response) => {
                    console.log(response);
                    resolve(response.data);
                });
        });
    }

    getMobileCheckSessionCheck(): Promise<Boolean> {
        return new Promise((resolve) => {
            APIServerConfig.recordAPIAxios.get('/mobileCheck/session/check').then((response) => {
                resolve(response.data);
            });
        });
    }

    updateUserInfo(modifyParam: UserModifyParam): Promise<UserModifyParam> {
        return new Promise((resolve, reject) => {
            APIServerConfig.recordAPIAxios
                .put(`/user/${modifyParam.user?.userNo}`, JSON.stringify(modifyParam), {
                    headers: { 'Content-Type': 'application/json' },
                })
                .then((response) => {
                    let user = this.jsonConvert.deserializeObject(response.data, UserModifyParam);
                    resolve(user);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    updateUserPw(user: UserDetailInfo): Promise<void> {
        return new Promise((resolve, reject) => {
            APIServerConfig.recordAPIAxios
                .put(`/user/${user.userNo}/pw`, JSON.stringify(user))
                .then((response) => {
                    resolve();
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    registeUserInfo(user: UserDetailInfo): Promise<any> {
        return new Promise((resolve, reject) => {
            APIServerConfig.recordAPIAxios.post(`/user`, JSON.stringify(user)).then((response) => {
                resolve(response.data);
            });
        });
    }

    login(userLoginParam: UserLoginParam): Promise<LoginToken> {
        return new Promise((resolve, reject) => {
            APIServerConfig.authAPIAxios
                .post(`/auth/login`, JSON.stringify(userLoginParam))
                .then((response) => {
                    let loginToken = this.jsonConvert.deserializeObject(response.data, LoginToken);
                    resolve(loginToken);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    batchRegistrationUser(users: UserDetailInfo[]): Promise<any> {
        return new Promise((resolve, reject) => {
            APIServerConfig.recordAPIAxios.post(`/user/batch`, JSON.stringify(users)).then((response) => {
                resolve(response.data);
            });
        });
    }

    batchConditioningData(datas: BatchData[]): Promise<any> {
        return new Promise((resolve, reject) => {
            APIServerConfig.recordAPIAxios.post(`/user/conditioningBatch`, JSON.stringify(datas)).then((response) => {
                resolve(response.data);
            });
        });
    }

    batchRehabProgramData(datas: BatchData[]): Promise<any> {
        return new Promise((resolve, reject) => {
            APIServerConfig.recordAPIAxios.post(`/user/rehabProgramBatch`, JSON.stringify(datas)).then((response) => {
                resolve(response.data);
            });
        });
    }

    batchBasalPhysicalData(datas: BatchData[]): Promise<any> {
        return new Promise((resolve, reject) => {
            APIServerConfig.recordAPIAxios.post(`/user/basalPhysicalBatch`, JSON.stringify(datas)).then((response) => {
                resolve(response.data);
            });
        });
    }

    batchPhysicalStatusData(datas: BatchData[]): Promise<any> {
        return new Promise((resolve, reject) => {
            APIServerConfig.recordAPIAxios.post(`/user/physicalStatusBatch`, JSON.stringify(datas)).then((response) => {
                resolve(response.data);
            });
        });
    }

    batchSpecialPhysicalData(datas: BatchData[]): Promise<any> {
        return new Promise((resolve, reject) => {
            APIServerConfig.recordAPIAxios.post(`/user/specialPhysicalBatch`, JSON.stringify(datas)).then((response) => {
                resolve(response.data);
            });
        });
    }
}

export default UserServiceImpl;
