import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import MyPageDetailRight from '../../../layout/MyPageDetailRight';
import MyPageDetailRightContent from '../../../layout/MyPageDetailRightContent';
import useReservationOfReservationNo from '../../../../../hook/reservation/useReservationOfReservationNo';
import ReservationDetailComponent from '../../../components/ReservationDetailComponent';
import ScheduleTitleComponent from '../../../components/ScheduleTitleComponent';

function MyPageReservationDetailViewPage() {
    const {path, url, params} = useRouteMatch();
    const reParams: any = params;
    const {reservation, reservationParentInfra, reservationInfra} = useReservationOfReservationNo(reParams.reservationNo);
    
    return (
        <MyPageDetailRight>
            <MyPageDetailRightContent>
                <ScheduleTitleComponent
                    timeInfoOfInstance={reservation}
                ></ScheduleTitleComponent>
                {
                    reservation && reservationInfra ?
                    <ReservationDetailComponent
                        reservation={reservation}
                        reservationParentInfra={reservationParentInfra}
                        reservationInfra={reservationInfra}
                    ></ReservationDetailComponent>
                    :
                    <></>
                }
                
            </MyPageDetailRightContent>
        </MyPageDetailRight>
    )
}

export default MyPageReservationDetailViewPage;