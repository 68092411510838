import ClubTeamInfo from '../../../domain/clubteam/ClubTeamInfo';
import AbstractService from '../../AbstractService';
import ClubTeamInfoService from '../ClubTeamInfoService';
import ClubTeamInfoSearchCondition from '../../../domain/clubteam/param/ClubTeamInfoSearchCondition';
import APIServerConfig from '../../../config/APIServerConfig';
import Paging from '../../../domain/common/Paging';
class ClubTeamInfoServiceImpl extends AbstractService implements ClubTeamInfoService {
    getClubInfosBy(searchCondition: ClubTeamInfoSearchCondition, paging: Paging): Promise<ClubTeamInfo[]> {
        return new Promise((resolve) => {
            APIServerConfig.recordAPIAxios
                .get(`/club-team-info`, {
                    params: Object.assign(searchCondition, paging),
                })
                .then((response) => {
                    let clubTeamInfos = this.jsonConvert.deserializeArray(response.data, ClubTeamInfo);
                    resolve(clubTeamInfos);
                });
        });
    }

    getClubInfosCntBy(searchCondition: ClubTeamInfoSearchCondition): Promise<number> {
        return new Promise((resolve) => {
            APIServerConfig.recordAPIAxios
                .get(`/club-team-info/cnt`, {
                    params: searchCondition,
                })
                .then((response) => {
                    let cnt = response.data;
                    resolve(cnt);
                });
        });
    }

    registeClubInfo(info: ClubTeamInfo): Promise<ClubTeamInfo> {
        return new Promise((resolve) => {
            APIServerConfig.recordAPIAxios.put(`/club-team-info`, JSON.stringify(info)).then((response) => {
                let clubTeamInfo = response.data ? this.jsonConvert.deserializeObject(response.data, ClubTeamInfo) : new ClubTeamInfo();
                resolve(clubTeamInfo);
            });
        });
    }
    deleteClubInfo(infoNo: string): Promise<ClubTeamInfo> {
        return new Promise((resolve) => {
            APIServerConfig.recordAPIAxios.delete(`/club-team-info/${infoNo}`).then((response) => {
                let clubTeamInfo = response.data ? this.jsonConvert.deserializeObject(response.data, ClubTeamInfo) : new ClubTeamInfo();
                resolve(clubTeamInfo);
            });
        });
    }
}

export default ClubTeamInfoServiceImpl;
