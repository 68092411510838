import { combineReducers } from 'redux';
import counter from './Counter';
import filterItemAction from './FilterItem';
import infraList from './InfraList';
import userSessionReducer from './UserSession';
import searchCondition from './SearchCondition';
import searchWordReduce from './SearchWordReduce';

const rootReducer = combineReducers({
  //not used
  counter,
  //not used
  filterItemAction,
  //not used
  infraList,
  //아래 부터는 사용하는 리듀스
  userSessionReducer,
  searchCondition,
  searchWordReduce
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;