import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Code from '../../../../domain/code/Code';
import TeamSearchCondition from '../../../../domain/team/param/TeamSearchCondition';
import useChildCodeRetrieve from '../../../../hook/code/useChildCodeRetrieve';
import CodeSelectFilter from './element/CodeSelectFilter';

interface AdminTeamInfoFilterProps {
    belongedCodes?: Code[];
    classficationCodes?: Code[];
    regionCodes?: Code[];
    genderCodes?: Code[];
    sportCodes?: Code[];
    changeFilterHandler: (evt: any) => void;
    initCondition?: TeamSearchCondition;
    children?: React.ReactNode;
}

function AdminTeamInfoFilter(props: AdminTeamInfoFilterProps) {
    let searchingWord: string = props.initCondition?.searchWord ? props.initCondition?.searchWord : '';
    let initRegion: number | undefined = props.initCondition?.regionCodeIds ? Number(props.initCondition?.regionCodeIds) : undefined;
    let initBelonged: number | undefined = props.initCondition?.belongedCodeIds ? Number(props.initCondition?.belongedCodeIds) : undefined;
    let initClassify: number | undefined = props.initCondition?.classficationCodeIds ? Number(props.initCondition?.classficationCodeIds) : undefined;
    let initSports: number | undefined = props.initCondition?.sportCodeIds ? Number(props.initCondition?.sportCodeIds) : undefined;
    let initGender: number | undefined = props.initCondition?.genderCodeIds ? Number(props.initCondition?.genderCodeIds) : undefined;

    const [regionCodeIds, setRegionCodeIds] = useState<number>();
    const [belongedCodeId, setBelongedCodeId] = useState<number>();
    const [classficationCodeId, setClassficationCodeId] = useState<number>();
    const [sportCodeId, setSportCodeId] = useState<number>();
    const [genderCodeId, setGenderCodeId] = useState<number>();

    //const [parentRegionCodeIds, setParentRegionCodeIds] = useState<number>();
    // const regionCodes = useChildCodeRetrieve(parentRegionCodeIds);
    const [searchWord, setSearchWord] = useState<string>(searchingWord);

    const keyEventHandler = (e: any) => {
        if (e.key == 'Enter') {
            searchClickHandler();
        }
    };

    const searchClickHandler = () => {
        let filterValSet = {
            regionId: regionCodeIds,
            belongedId: belongedCodeId,
            classifyId: classficationCodeId,
            sportsId: sportCodeId,
            genderId: genderCodeId,
            searchWord: searchWord,
        };
        props.changeFilterHandler(filterValSet);
    };

    const changeEventHandler = (evt: any) => {
        document.getElementById('keyHandler')?.focus();
    };

    useEffect(() => {
        setRegionCodeIds(initRegion);
        setBelongedCodeId(initBelonged);
        setClassficationCodeId(initClassify);
        setSportCodeId(initSports);
        setGenderCodeId(initGender);
    }, []);

    return (
        <>
            <div className="member-join">
                <CodeSelectFilter
                    initCodeId={regionCodeIds}
                    codes={props.regionCodes}
                    allOptionTitle={'도.광역 단위 지역전체'}
                    setCodeIdOfSearchTypeNum={setRegionCodeIds}
                    changeEventHandler={changeEventHandler}
                ></CodeSelectFilter>
                {/* <CodeSelectFilter
                    codes={regionCodes}
                    allOptionTitle={'시.군 단위 지역전체'}
                    setCodeIdOfSearchTypeNum={setRegionCodeIds}
                    changeEventHandler={changeEventHandler}
                ></CodeSelectFilter> */}
                <CodeSelectFilter
                    initCodeId={belongedCodeId}
                    codes={props.belongedCodes}
                    allOptionTitle={'분류그룹전체'}
                    setCodeIdOfSearchTypeNum={setBelongedCodeId}
                    changeEventHandler={changeEventHandler}
                ></CodeSelectFilter>
                <CodeSelectFilter
                    initCodeId={classficationCodeId}
                    codes={props.classficationCodes}
                    allOptionTitle={'분류전체'}
                    setCodeIdOfSearchTypeNum={setClassficationCodeId}
                    changeEventHandler={changeEventHandler}
                ></CodeSelectFilter>
                <CodeSelectFilter
                    initCodeId={sportCodeId}
                    codes={props.sportCodes}
                    allOptionTitle={'종목전체'}
                    setCodeIdOfSearchTypeNum={setSportCodeId}
                    changeEventHandler={changeEventHandler}
                ></CodeSelectFilter>
                <CodeSelectFilter
                    initCodeId={genderCodeId}
                    codes={props.genderCodes}
                    allOptionTitle={'성별전체'}
                    setCodeIdOfSearchTypeNum={setGenderCodeId}
                    changeEventHandler={changeEventHandler}
                ></CodeSelectFilter>
            </div>
            <div className="member-search">
                <input id="keyHandler" onKeyPress={keyEventHandler} style={{ width: '0px', border: '0px' }} />
                <input
                    type="text"
                    placeholder="팀명을 입력하세요."
                    onKeyPress={keyEventHandler}
                    value={searchWord}
                    onChange={(event) => setSearchWord(event.currentTarget.value)}
                ></input>
                <button className="bg-blue white round" onClick={searchClickHandler}>
                    <i className="fas fa-search"></i>팀 검색
                </button>
                {props.children}
            </div>
        </>
    );
}

export default AdminTeamInfoFilter;
