import React from 'react';
import Reservation from '../../../domain/reservation/Reservation';
import Schedule from '../../../domain/schedule/Schedule';

interface ScheduleTitleComponentProps {
    timeInfoOfInstance: Schedule | Reservation | undefined;
}

function ScheduleTitleComponent(props: ScheduleTitleComponentProps) {
    let reserDate =
        props.timeInfoOfInstance?.getStartDate() === props.timeInfoOfInstance?.getEndDate()
            ? props.timeInfoOfInstance?.getStartDate() + '(' + props.timeInfoOfInstance?.getStartDateWeekDay() + ')'
            : props.timeInfoOfInstance?.getStartDate() +
              '(' +
              props.timeInfoOfInstance?.getStartDateWeekDay() +
              ') ~ ' +
              props.timeInfoOfInstance?.getEndDate() +
              '(' +
              props.timeInfoOfInstance?.getEndDateWeekDay() +
              ')';
    return (
        <div className="my-in-title bg-blue">
            <h4>{reserDate}</h4>
            <span>
                {props.timeInfoOfInstance?.getStartDateTime()} - {props.timeInfoOfInstance?.getEndDateTime()}
            </span>
        </div>
    );
}

export default ScheduleTitleComponent;
