import React from 'react';
import LoginState from '../../../component/header/LoginState';
import TrainningNav from '../../../component/nav/TrainningNav';
import SiteMainNav from '../../../component/nav/SiteMainNav';
import { Link } from 'react-router-dom';
import MobileMenuContainer from '../../../component/header/MobileMenuContainer';
export default class TrainningSubHeader extends React.Component {
    render() {
        return (

            <section className="main-visual sub-visual-bg">
                <div className="main-top">
                    <div className="logozone">
                        <Link to="/">
                            <div>
                                <img src="/assets/image/logo_without_name.png" alt="logo" />
                            </div>
                        </Link>
                        <Link to="#">
                            <div>&nbsp;|&nbsp;전지훈련시설</div>
                        </Link>
                    </div>
                    <div className="navi">
                        <SiteMainNav></SiteMainNav>
                    </div>
                    <div className="main-login">
                        <LoginState></LoginState>
                    </div>
                    <div className="clear"></div>
                    <div className="mo-menu">
                        <MobileMenuContainer></MobileMenuContainer>
                    </div>
                </div>

                <div className="sub-copy">
                    <p className="wow  animate__ animate__fadeInDown animated" data-wow-delay="0.4s">
                        스포츠시설
                    </p>
                    <p className="wow  animate__ animate__fadeInDown animated" data-wow-delay="0.6s">
                        쉽게 찾고 편하게 예약
                    </p>
                </div>
            </section>
        );
    }
}
