import { useEffect, useState } from 'react';
import Reservation from '../../domain/reservation/Reservation';
import ReservationServiceImpl from '../../service/reservation/impl/ReservationServiceImpl';
import Infra from '../../domain/infra/Infra';

const reservationService = new ReservationServiceImpl();

function useReservationOfReservationNo(reservationNo: string) {
    const [reservation, setReservation] = useState<Reservation>();
    const [reservationParentInfra, setReservationParentInfra] = useState<Infra>();
    const [reservationInfra, setReservationInfra] = useState<Infra>();

    useEffect(() => {
        if(reservationNo) {
            reservationService.getReservationOf(reservationNo)
                .then((reservation: Reservation) => {
                    setReservation(reservation);
                    if(reservation.parentInfra) {
                        setReservationParentInfra(Object.setPrototypeOf(reservation.parentInfra, Infra.prototype));
                    }

                    setReservationInfra(Object.setPrototypeOf(reservation.infra, Infra.prototype));
                });
        }
    }, [reservationNo]);

    return {reservation, setReservation
            , reservationParentInfra, setReservationParentInfra
            , reservationInfra, setReservationInfra};
}

export default useReservationOfReservationNo;