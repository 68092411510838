import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import ReactDOM from 'react-dom';
import '../assets/css/center.css';
import '../assets/css/animate.min.css';
import Layout from '../layouts/Layout';
import Header from '../layouts/Header';
import SideBar from '../layouts/SideBar';
import RehabServiceImpl from '../service/impl/RehabServiceImpl';
import Booking from '../domain/Booking';

import { bookingStatus } from './components/StatusUtils';
import pagination from './components/PaginationComponent';

import UserDetailInfo from '../../../domain/user/UserDetailInfo';
import TargetPerson from '../domain/TargetPerson';
import { confirmAlert } from 'react-confirm-alert';
import Reservation from '../../../domain/reservation/Reservation';

const rehabService = new RehabServiceImpl();

export default function ReservedManagingList(props: any) {
    const hitsotry = useHistory();

    const [bookingInfos, setBookingInfos] = useState<Booking[]>();
    const [reservState, setReservState] = useState<string>('0');
    const [retrieveKeyword, setRetrieveKeyword] = useState<{ type: string; keyword: string }>({ type: 'name', keyword: '' });
    const [wholeSelected, setWholeSelected] = useState<boolean>(false);

    const [totalSize, setTotalSize] = useState(1);
    const [displaySize, setDisplaySize] = useState(10);
    const [currPage, setCurrPage] = useState(0);

    let menuTitle: string = '* 예약자 관리 <' + totalSize + '명 예약중>';
    let title: string = '예약관리';

    useEffect(() => {
        let pagination = { size: 10, page: 0 };
        rehabService.getBookingList(retrieveKeyword, pagination).then((bookingInfo: { data: Booking[]; totalSize: number }) => {
            setBookingInfos(bookingInfo.data);
            setTotalSize(bookingInfo.totalSize);
        });
    }, []);

    const changeKeywordHandler = (e: any) => {
        let key = e.target.id;
        let inputVal = e.target.value;

        if (key === 'selectKeywordType') {
        } else if (key === 'inputKeyword') {
            setRetrieveKeyword((prevState) => {
                return { ...prevState, ['keyword']: inputVal };
            });
        } else if (key === 'selectKeyword') {
            setRetrieveKeyword((prevState) => {
                let selecting = { ...prevState, ['keyword']: inputVal };
                selectingHandler(selecting);
                return selecting;
            });
        } else if (key === 'selectCenter') {
            setRetrieveKeyword((prevState) => {
                let selecting = { ...prevState, ['keyword']: inputVal };
                selectingHandler(selecting);
                return selecting;
            });
        }
    };

    const alertHandler = (title: string, message: string, handler: () => void) => {
        confirmAlert({
            title: title,
            message: message,
            buttons: [
                {
                    label: '확인',
                    onClick: handler,
                },
            ],
        });
    };
    const approvalHandler = (reservationNo: string, comment: string) => {
        rehabService.bookApproval(reservationNo, comment).then((reservedResult: TargetPerson) => {
            if (reservedResult != null) {
                let reservaterName = reservedResult.reservater?.name;
                let reservationStateId = reservedResult.reservation?.reservationStateCodeId;
                let title = '승인 성공';
                let message = '승인이 완료되었습니다.';
                if (reservaterName) message += ' <검진관리> 메뉴에서 [' + reservaterName + '] 님 확인 가능합니다.';

                alertHandler(title, message, retrieveHandler);
            } else {
                alertHandler('승인 실패', '검진 진행 중인 동일 계정 사용자가 있습니다. 확인하여 주십시오', () => {});
            }
        });
    };

    const cancellationHandler = (reservationNo: string, comment: string) => {
        rehabService.bookCancellation(reservationNo, comment).then((reservedResult: Reservation) => {
            if (reservedResult != null) {
                alertHandler('예약 취소 성공', '예약이 취소 되었습니다.', retrieveHandler);
            } else {
                alertHandler('예약 취소 실패', '검진 진행 중인 계정입니다. 확인하여 주십시오.', () => {});
            }
        });
    };

    const goDetailHandler = (booking: any) => {
        hitsotry.push({
            pathname: '/rehab/reserved/detail',
            state: booking,
        });
    };

    const pagingHandler = (pagination: { size: number; page: number }) => {
        setCurrPage(pagination.page);
        setDisplaySize(pagination.size);
        rehabService.getBookingList(retrieveKeyword, pagination).then((bookingInfo: { data: Booking[]; totalSize: number }) => {
            setBookingInfos(bookingInfo.data);
            setTotalSize(bookingInfo.totalSize);
        });
    };

    const retrieveHandler = () => {
        setCurrPage(0);
        setDisplaySize(10);
        let pagination = { size: 10, page: 0 };
        rehabService.getBookingList(retrieveKeyword, pagination).then((bookingInfo: { data: Booking[]; totalSize: number }) => {
            setBookingInfos(bookingInfo.data);
            setTotalSize(bookingInfo.totalSize);
        });
    };
    const selectingHandler = (keywordInfo: { type: string; keyword: string }) => {
        setCurrPage(0);
        setDisplaySize(10);
        let pagination = { size: 10, page: 0 };
        rehabService.getBookingList(keywordInfo, pagination).then((bookingInfo: { data: Booking[]; totalSize: number }) => {
            setBookingInfos(bookingInfo.data);
            setTotalSize(bookingInfo.totalSize);
        });
    };

    let path = props.location.pathname;

    const keyEventHandler = (e: any) => {
        if (e.key == 'Enter') {
            retrieveHandler();
        }
    };

    const filters = () => {
        return (
            <div className="center-btn-right350">
                <div className="center-select-box">
                    {/* <label># 예약상태: </label>
                    <select className="center-select" onChange={() => {}} value={reservState} style={{ width: '200px' }}>
                        <option value="0">전체</option>
                        <option value="applyAll">예약신청</option>
                        <option value="selectedApply">선택승인</option>
                        <option value="deleteAll">모두취소&삭제</option>
                        <option value="selectedDelete">선택취소&삭제</option>
                    </select> */}

                    <label># 검색: </label>
                    <select id="selectKeywordType" className="center-select" value={retrieveKeyword.type} onChange={changeKeywordHandler}>
                        <option value="name">이름</option>
                        <option value="belongTeam">소속팀</option>
                        <option value="type">운동종목</option>
                        <option value="status">예약상태</option>
                        <option value="centers">예약센터</option>
                    </select>
                    <div id="keyword-input" className="center-search">
                        <input
                            id="inputKeyword"
                            className="center-input"
                            placeholder="검색어를 입력하세요"
                            onChange={changeKeywordHandler}
                            onKeyPress={keyEventHandler}
                            value={retrieveKeyword.keyword}
                            style={{ border: '1px soild #e3e3e3' }}
                        />
                        <button className="btn" onClick={retrieveHandler} style={{ marginLeft: '5px', border: '1px solid #e3e3e3' }}>
                            <i className="fas fa-search"></i>
                        </button>
                    </div>
                    <div id="keyword-select" className="center-search" style={{ display: 'none' }}>
                        <select id="selectKeyword" className="center-reserved-select" value={retrieveKeyword.keyword} onChange={changeKeywordHandler}>
                            <option value="0">예약상태를선택해주세요</option>
                            <option value="1001">예약신청</option>
                            <option value="1002">예약승인</option>
                            <option value="1006">예약취소</option>
                        </select>
                    </div>
                    <div id="centers-select" className="center-search" style={{ display: 'none' }}>
                        <select id="selectCenter" className="center-reserved-select" value={retrieveKeyword.keyword} onChange={changeKeywordHandler}>
                            <option value="0">예약센터를선택해주세요</option>
                            <option value="center_1">마산대센터</option>
                            <option value="center_2">의령센터</option>
                            <option value="center_3">고성센터</option>
                        </select>
                    </div>
                </div>
            </div>
        );
    };

    const contents = (bookingInfo?: Booking[], currPage?: number, displaySize?: number) => {
        let page = currPage ? currPage : 0;
        let pageSize = displaySize ? displaySize : 0;
        let rowNum = page * pageSize;

        let table = bookingInfo ? (
            bookingInfo.map((booking: Booking, idx) => {
                let reservater: UserDetailInfo | undefined = booking.reservater;
                let bookingTime: string = booking.startDate;
                let status: any = booking?.reservationStateCode;
                let regionCode: any = reservater?.regionCode;
                let infraInfo: any = booking.infra;
                let timeArr: string[] = bookingTime != null ? bookingTime.split(' ') : ['-', '-'];

                return (
                    <tr key={idx}>
                        <td>{rowNum + idx + 1}</td>
                        <td
                            onClick={() => {
                                goDetailHandler(booking);
                            }}
                            key={idx}
                            style={{ cursor: 'pointer' }}
                        >
                            {reservater?.name}
                        </td>
                        <td>
                            {reservater?.teamName}/{regionCode.name}
                        </td>
                        <td>{timeArr[0]}</td>
                        <td>{timeArr[1]}</td>
                        <td>{infraInfo?.name}</td>
                        <td>
                            {bookingStatus(status, booking.reservationStateCodeId)} ({booking.reservationStateCodeId})
                        </td>
                        <td>
                            {booking.reservationStateCodeId === 1001 ? (
                                <>
                                    <button
                                        className="btn"
                                        onClick={() => {
                                            approvalHandler(booking.reservationNo, '예약 승인 되었습니다.');
                                        }}
                                    >
                                        예약승인
                                    </button>
                                    <button
                                        className="btn btn-danger"
                                        onClick={() => {
                                            cancellationHandler(booking.reservationNo, '예약 취소 되었습니다.');
                                        }}
                                    >
                                        예약취소
                                    </button>
                                </>
                            ) : (
                                <></>
                            )}
                        </td>
                    </tr>
                );
            })
        ) : (
            <tr>
                <td colSpan={8}>예약자 조회 중입니다......</td>
            </tr>
        );
        return table;
    };

    return (
        <Layout
            header={<Header></Header>}
            sideBar={<SideBar name={path}></SideBar>}
            path={path}
            naviTitle={title}
            menuTitle={menuTitle}
            filter={filters()}
            pagination={pagination(pagingHandler, totalSize, currPage, displaySize)}
        >
            <table className="center-list-table" cellPadding="0" cellSpacing="0">
                <colgroup>
                    <col style={{ width: '5%' }} />
                    <col style={{ width: '10%' }} />
                    <col style={{ width: '10%' }} />
                    <col style={{ width: '10%' }} />
                    <col style={{ width: '15%' }} />
                    <col style={{ width: '15%' }} />
                    <col style={{ width: '15%' }} />
                    <col style={{ width: '20%' }} />
                </colgroup>
                <thead>
                    <tr>
                        <td>No.</td>
                        <td>이름</td>
                        <td>소속팀</td>
                        <td>예약일</td>
                        <td>예약시간</td>
                        <td>예약센터</td>
                        <td>예약승인상태</td>
                        <td>예약관리</td>
                    </tr>
                </thead>
                <tbody>{contents(bookingInfos, currPage, displaySize)}</tbody>
            </table>
        </Layout>
    );
}
