import React, { useState, useEffect } from 'react';
import SpecialPhysical from '../../../../../domain/recording/SpecialPhysical';

interface IProps {
    handler: (evt: any) => void;
    status?: SpecialPhysical;
}
export default function FootCheckerForm(props: IProps) {
    //let footChecker = props.status.footChecker;
    const resourceAPIURL = process.env.REACT_APP_RESOURCE_API_HOST;
    const [footChecker, setFootChecker] = useState<any>(new SpecialPhysical().footChecker);

    useEffect(() => {
        if (props.status) setFootChecker(props.status.footChecker);
    }, [props.status]);

    let imageElem = <></>;
    if (footChecker.uploadedFootFile != '') imageElem = <img id="uploadedImages" src={resourceAPIURL + footChecker.uploadedFootFile} width="580" />;
    return (
        <>
            <div className="center-popup-title">
                <h1 className="center">풋체커(족저압)</h1>
            </div>

            {/* <div className="center-special-physical-btn">
                <label htmlFor={'uploadedFootFile'} className="center-label-up">
                    결과지 업로드
                </label>
                <input
                    type="file"
                    id="uploadedFootFile"
                    name="footChecker"
                    onChange={props.handler}
                    style={{ display: 'none' }}
                    accept=".gif, .jpg, .png"
                />
            </div> */}
            <div className="flex-basic">
                <div className="center-half">
                    <div className="center-bg-gray flex">
                        <div className="center-bg-blue">Front</div>
                        <input
                            className="center-input-gray center-w200"
                            type="text"
                            id="front"
                            value={footChecker.front}
                            onChange={props.handler}
                            name="footChecker"
                        />
                        <label className="center-label">˚</label>
                    </div>
                </div>
                <div className="center-half">
                    <div className="center-bg-gray flex">
                        <div className="center-bg-blue">Back</div>
                        <input
                            className="center-input-gray center-w200"
                            type="text"
                            id="back"
                            value={footChecker.back}
                            onChange={props.handler}
                            name="footChecker"
                        />
                        <label className="center-label">˚</label>
                    </div>
                </div>
            </div>
            <div className="flex-basic">
                <div className="center-half">
                    <div className="center-bg-gray flex">
                        <div className="center-bg-blue">Left</div>
                        <input
                            className="center-input-gray center-w200"
                            type="text"
                            id="left"
                            value={footChecker.left}
                            onChange={props.handler}
                            name="footChecker"
                        />
                        <label className="center-label">˚</label>
                    </div>
                </div>
                <div className="center-half">
                    <div className="center-bg-gray flex">
                        <div className="center-bg-blue">Right</div>
                        <input
                            className="center-input-gray center-w200"
                            type="text"
                            id="right"
                            value={footChecker.right}
                            onChange={props.handler}
                            name="footChecker"
                        />
                        <label className="center-label">˚</label>
                    </div>
                </div>
            </div>

            <div id="uploadedFootImages" className="center-mauto">
                {imageElem}
            </div>
        </>
    );
}
