import { useEffect, useState } from 'react';
import Questionnaire from '../../view/rehabcenter/domain/Questionnaire';
import RehabQuestionnairesServiceImpl from '../../view/rehabcenter/service/impl/RehabQuestionnairesServiceImpl';
import QnA from '../../view/rehabcenter/domain/QnA';
const rehabQuestionnairesService = new RehabQuestionnairesServiceImpl;
function useQuestionnarieOfUser(userNo: string) {
    const [questionnaireListOfUser, setQuestionnaireListOfUser] = useState<QnA[]>();
    
    useEffect(() => {
        if(userNo) {
            rehabQuestionnairesService.getQuestionnairesListByUser(userNo)
            .then((questionnaireList: QnA[]) => {
                setQuestionnaireListOfUser(questionnaireList);
            })
        }
    }, [userNo]);

    return {
        questionnaireListOfUser,
        setQuestionnaireListOfUser
    }
}

export default useQuestionnarieOfUser;