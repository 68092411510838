import APIServerConfig from '../../../../config/APIServerConfig';
import AbstractService from '../../../../service/AbstractService';
import ExercisesTracking from '../../domain/tracking/ExercisesTracking';
import TargetExercises from '../../domain/tracking/TargetExercises';
import TrackingService from '../TrackingService';

export default class TrackingServiceImpl extends AbstractService implements TrackingService {
    getTargetExercises(targetNo: number): Promise<TargetExercises[]> {
        return new Promise((handler, reject) => {
            APIServerConfig.recordAPIAxios
                .post('/tracking/target/exercises', JSON.stringify({ targetNo: targetNo }), {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                .then((response) => {
                    let history: TargetExercises[] = this.jsonConvert.deserializeArray(response.data.targetHistory, TargetExercises);
                    handler(history);
                });
        });
    }
    getExercisesTracking(targetExerNo: number): Promise<ExercisesTracking[]> {
        return new Promise((handler, reject) => {
            APIServerConfig.recordAPIAxios
                .post('/tracking/target/exercises/history', JSON.stringify({ targetExerNo: targetExerNo }), {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                .then((response) => {
                    let tracking: ExercisesTracking[] = this.jsonConvert.deserializeArray(response.data.targetHistory, ExercisesTracking);
                    handler(tracking);
                });
        });
    }
}
