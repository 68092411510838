import React, { useEffect, useState } from 'react';
import { Calendar, momentLocalizer} from 'react-big-calendar';
import moment from 'moment';
import { RouteComponentProps } from 'react-router-dom';
import AdminMainTitle from '../../../../component/admin/AdminMainTitle';
import AdminFormTitle from '../../../../component/admin/AdminFormTitle';
import AdminTeamNav from '../../nav/AdminTeamNav';

import useTeamScheduleByMonth from '../../../../hook/schedule/useTeamScheduleByMonth';
import Schedule from '../../../../domain/schedule/Schedule';
import EventUtils from '../../../../utils/EventUtils';

const event = [
    {
        id: 0,
        title: '창원 축구센터 방문',
        allDay: true,
        start: new Date(2020, 11, 1),
        end: new Date(2020, 11, 20),
    }
]

const localizer = momentLocalizer(moment);

interface MatchParams {
    teamNo: string;
    url: string;
}

function AdminTeamScheduleContainer({match}: RouteComponentProps<MatchParams>) {
    const { teamNo } = match.params;
    const { url } = match;
    const [month, setMonth] = useState<string>(String(new Date().getMonth() + 1));
    const {teamSchedules} = useTeamScheduleByMonth(teamNo, month);
    const [events, setEvents] = useState(new Array());

    // useEffect(() => {
    //     if(reservations && reservations.length !== 0) {
    //         const newEvents: any[] = [];
    //         reservations.forEach((reservation: Reservation, index: number) => {
    //             //2020-11-22
    //             const dateForm = reservation.startDate?.split(" ")[0];
    //             const year = Number(dateForm?.split("-")[0]);
    //             const month = Number(dateForm?.split("-")[1]) - 1;
    //             const date = Number(dateForm?.split("-")[2]);

    //             const endDateForm = reservation.endDate?.split(" ")[0];
    //             const endYear = Number(endDateForm?.split("-")[0]);
    //             const endMonth = Number(endDateForm?.split("-")[1]) - 1;
    //             const endDate = Number(endDateForm?.split("-")[2]);

    //             if (year && month && date) {
    //                 const newEvent = {
    //                     id: index,
    //                     title: `${reservation.team?.name}-${reservation.reservationStateCode?.name}`,
    //                     allDay: false,
    //                     start: new Date(year, month, date, 12, 30),
    //                     end: new Date(endYear, endMonth, endDate, 14, 30),
    //                     link: 'test'
    //                 }

    //                 newEvents.push(newEvent);
    //             }
    //         });
    //         setEvents(newEvents);
    //     }
    // }, [reservations])

    useEffect(() => {
        if(teamSchedules) {
            const newEvents: any[] = [];
            teamSchedules.forEach((teamSchedule: Schedule, eventId: number) => {
                const startDate = teamSchedule.reservation ? teamSchedule.reservation.startDate : teamSchedule.startDate;
                const endDate = teamSchedule.reservation ? teamSchedule.reservation.endDate : teamSchedule.endDate;
                const eventTitle = teamSchedule.title;
                const eventLink = '212';
                
                if(startDate && endDate && eventTitle && eventLink) {
                    const newEvent = EventUtils.makeEvent(eventId, startDate, endDate, eventTitle, eventLink);
                    newEvents.push(newEvent);
                }
            });

            setEvents(newEvents);
        }
    }, [teamSchedules]);

    return (
        <>
        <AdminFormTitle
            title="일정"
            tab={<AdminTeamNav teamNo={teamNo} url={url}></AdminTeamNav>}
        />
        <Calendar
            localizer={localizer}
            events={events}
            onSelectEvent={(event) => console.log(event)}
            style={{ height: '700px' }}
            onNavigate={date => {
                setMonth(String(date.getMonth() + 1));
            }}
        >
        </Calendar>
        </>
        
    )
}

export default AdminTeamScheduleContainer;