import SignUpHeader from '../layout/SignUpHeader';
import SignUpStepBarComponent from '../components/SignUpStepBarComponent';
import PrivacyPolicy from '../../policies/components/PrivacyPolicy';
import { useState } from 'react';
import { Link, Route } from 'react-router-dom';

function SignUpAgreementPage() {
    const [usePolicyAgreement, setUsePolicyAgreement] = useState<boolean>();
    const [privacyPolicyAgreement, setPrivacyPolicyAgrement] = useState<boolean>();

    // const [usePolicyAgreementAlert, setUsePolicyAgreementAlert] = useState<boolean>(false);
    // const [privacyPolicyAgreementAlert, setPrivacyPolicyAgreementAlert] = useState<boolean>(false);

    return (
        <>
            <SignUpHeader title="약관동의"></SignUpHeader>
            <div className="mgt10"></div>
            <div className="content-wrap">
                <div className="signup-content-wrap">
                    <SignUpStepBarComponent step={'step1'}></SignUpStepBarComponent>
                    <div className="mgt30"></div>
                    <h2 className="aggrement-h2">약관동의</h2>
                    <div className="policy-wrap-container">
                        <div className="policy-wrap-header">
                            <h5 className="header-h5">GSSC 이용약관</h5>
                        </div>
                        <div className="policy-wrap-body">
                            <PrivacyPolicy></PrivacyPolicy>
                        </div>
                        <div className="policy-wrap-footer">
                            <label style={{cursor: 'pointer'}}>
                                <p>이용약관에 동의합니다.
                                    <input type="checkbox"
                                        onClick={(event) => {
                                            if(usePolicyAgreement) {
                                                setUsePolicyAgreement(false);
                                            } else {
                                                setUsePolicyAgreement(true);
                                            }
                                        }}
                                    ></input>
                                </p>
                            </label>
                            {
                                usePolicyAgreement || usePolicyAgreement === undefined ?
                                <></>
                                :
                                <p className="alert-p">이용약관에 동의합니다.</p>
                            }
                        </div>
                    </div>
                    <div className="mgt10"></div>
                    <div className="policy-wrap-container">
                        <div className="policy-wrap-header">
                        <h5 className="header-h5">개인정보 취급방침</h5>
                        </div>
                        <div className="policy-wrap-body">
                            <PrivacyPolicy></PrivacyPolicy>
                        </div>
                        <div className="policy-wrap-footer">
                            <label style={{cursor: 'pointer'}}>
                                <p>개인정보 수집 및 이용에 동의합니다.
                                    <input type="checkbox"
                                        onClick={(event) => {
                                            if(privacyPolicyAgreement) {
                                                setPrivacyPolicyAgrement(false);
                                            } else {
                                                setPrivacyPolicyAgrement(true);
                                            }
                                            
                                        }}
                                    ></input>
                                </p>
                            </label>
                            {
                                privacyPolicyAgreement || privacyPolicyAgreement === undefined ? 
                                <></>
                                :
                                <p className="alert-p">개인정보 수집 및 이용에 동의 바랍니다.</p>
                            }
                            
                        </div>
                    </div>
                </div>
                <div className="signup-content-button-zone">
                    <Link to="/">
                        <input className="cancel_btn" value="동의하지 않습니다."></input>
                    </Link>
                    <input className="agree_btn" value="동의합니다."
                        onClick={(event) => {
                            if(usePolicyAgreement && privacyPolicyAgreement) {
                                window.location.href = "/signup/user-checker";
                            } else {
                                alert("이용약관 및 개인정보 수집 및 이용에 동의해주세요.");
                                if(privacyPolicyAgreement === undefined) {
                                    setPrivacyPolicyAgrement(false);
                                }

                                if(usePolicyAgreement === undefined) {
                                    setUsePolicyAgreement(false);
                                }
                            }
                        }}
                    ></input>
                </div>
            </div>
        </>
    )
}

export default SignUpAgreementPage;