import React, { useEffect, useState } from 'react';
import usePagingHook from '../../hook/usePagingHook';
//14, 2, 5
interface SearchIteamPagingProps {
    totalCnt: number;
    pagingSize: number;
    pagingCnt: number;
    setPage: (pageNum: number) => void;
    currentPageNum: number;
}
function SearchIteamPaging(props: SearchIteamPagingProps) {
    const {
        currentPageGroupIdx, setCurrentPageGroupIdx,
        pageCnt, setPageCnt,
        pageGroupCnt, setPageGroupCnt,
        pageGroupArr, setPageGroupArr,
        onPrevEventHandler, onNextEventHandler
    } = usePagingHook(props.totalCnt, props.pagingSize, props.pagingCnt, props.setPage);
    
    return (
        <div className="infra-paging">
            {
                currentPageGroupIdx != 0 ?
                <button onClick={() => onPrevEventHandler(currentPageGroupIdx)}>&lt;</button> : <></>
            }
            {
                pageGroupArr.map((value: number, index: number) => {
                    let pageNum = ((props.pagingCnt * currentPageGroupIdx) + (value + 1));
                    return (
                        pageNum <= pageCnt ? 
                        <button className={(pageNum - 1 == props.currentPageNum) ? 'on': ''} onClick={() => props.setPage(pageNum-1)}>{pageNum}</button>
                        : <></>
                    )
                })
            }
            {   
                currentPageGroupIdx < pageGroupCnt - 1 ? 
                <button onClick={() => onNextEventHandler(currentPageGroupIdx)}>&gt;</button> : <></>
            }
        </div>
    )
}

export default SearchIteamPaging;