import { JsonObject, JsonProperty } from 'json2typescript';
import User from './User';
import Code from '../code/Code';

@JsonObject("userDetailInfo")
class UserDetailInfo extends User{
    @JsonProperty("teamName")
    teamName?: string;
    @JsonProperty("registeDate")
    registeDate?: string;
    @JsonProperty("classificationCode")
    classificationCode?: Code;
    @JsonProperty('belongCode')
    belongCode?: Code;
    @JsonProperty('regionCode')
    regionCode?: Code;
    @JsonProperty('sportCode')
    sportCode?: Code;
    @JsonProperty('genderCode')
    genderCode?: Code;
    @JsonProperty('birthdate')
    birthdate?: string = '';
    @JsonProperty("signupApprove")
    signupApprove?: boolean;
}

export default UserDetailInfo;
