import React from 'react';
import { Link } from 'react-router-dom';
import Team from '../../domain/team/Team';
interface TeamSearchListItemProps {
    searchedTeam: Team;
}
function TeamSearchListItem(props: TeamSearchListItemProps) {
    return (
        <tr>
            <td>
                <Link to={`/trainning/team-story/${props.searchedTeam.teamNo}`}>
                    <img className="list-thum" src={props.searchedTeam.getMainAttachFile()}/>
                </Link>
            </td>
            <td>
                <div>
                    <p>{props.searchedTeam.name}</p>
                    <p>{props.searchedTeam.regionCode?.name}</p>
                </div>
            </td>
        </tr>
    )
}

export default TeamSearchListItem;