import { findAllByPlaceholderText } from "@testing-library/react";
import { JsonObject, JsonProperty } from "json2typescript";
import InfraCategory from '../infra/InfraCategory';

@JsonObject("code")
export default class Code {
    @JsonProperty("codeTypeName")
    codeTypeName: string = '';
    @JsonProperty("parentCodeId")
    parentCodeId: number = 0;
    @JsonProperty("codeId")
    codeId: number = 0;
    @JsonProperty("name")
    name: string = '';

    static convertTo(infraCategories?: InfraCategory[]): Code[] {
        let codes: Code[] = new Array<Code>();
        
        if(infraCategories) {
            infraCategories.map((infraCategory: InfraCategory) => {
                let code = new Code();
                code.codeId = infraCategory.infraCategoryNo;
                code.name = infraCategory.name;
                codes.push(code);
            });
        }

        return codes;
    }

    static concatTotalCode(codes: Code[]) {
        const totalCode = new Code();
        totalCode.codeId = 0;
        totalCode.name = '합계';
        return [totalCode].concat(codes);
    }
}