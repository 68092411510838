import APIServerConfig from '../../../../config/APIServerConfig';
import AbstractService from '../../../../service/AbstractService';
import SubBoard from '../../domain/SubBoard';
import SubBoardService from '../SubBoardService';

export default class SubBoardServiceImpl extends AbstractService implements SubBoardService {
    getSubBoards(targetNo: number): Promise<SubBoard[]> {
        return new Promise((handler, reject) => {
            APIServerConfig.coreAPIAxios
                .post('/board/subBoards.do', JSON.stringify({ targetNo: targetNo }), {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                .then((response) => {
                    let subBoards = response.data;
                    handler(subBoards);
                });
        });
    }
}
