import React from 'react';
import { Link } from 'react-router-dom';
import CommonHeader from '../../common/CommonHeader';
import SiteMainNav from '../../../component/nav/SiteMainNav';
import LoginState from '../../../component/header/LoginState';
import MobileMenuContainer from '../../../component/header/MobileMenuContainer';
import CommonSearchBar from '../../../component/search/CommonSearchBar';

function SubInfraPageHeader() {
    return (
        <section className="main-visual">
            <CommonHeader></CommonHeader>
            <div className="sub_visual sub_visual4">
                <div className="subTop">
                    <h2>전지훈련시설</h2>
                    <span className="line"></span>
                    <p>경남스포츠산업육성지원센터 전지훈련시설입니다.</p>
                </div>
            </div>
        </section>
    );
}

export default SubInfraPageHeader;
