import React, { useState, useEffect } from 'react';
import ActionComponent from '../ActionComponent';
import { JsonConvert } from 'json2typescript';
import PhysicalStatusForm from './forms/PhysicalStatusForm';
import BasalPhysicalForm from './forms/BasalPhysicalForm';
import SpecialPhysicalForms from './forms/SpecialPhysicalForms';
import TargetPerson from '../../../domain/TargetPerson';
import PhysicalStatus from '../../../domain/recording/PhysicalStatus';
import BasalPhysical from '../../../domain/recording/BasalPhysical';
import SpecialPhysical from '../../../domain/recording/SpecialPhysical';
import { confirmAlert } from 'react-confirm-alert';
import RehabServiceImpl from '../../../service/impl/RehabServiceImpl';
import RehabTargetHistory from '../../../domain/RehabTargetHistory';
import RehabHistoryServiceImpl from '../../../service/impl/RehabHistoryServiceImpl';
import Loader from '../LoaderComponent';

interface IProps {
    data: TargetPerson;
    recordingData: RehabTargetHistory;
    closingHandler: () => void;
}

const jsonConvert = new JsonConvert();
const rehabService = new RehabServiceImpl();
const rehabHistoryService = new RehabHistoryServiceImpl();

let targetData: TargetPerson;
let recordedData: RehabTargetHistory;

function useRecordingHook(physicalStatus?: PhysicalStatus, basalPhysical?: BasalPhysical, specialPhysical?: SpecialPhysical) {
    const [recording, setRecording] = useState<RehabTargetHistory>(new RehabTargetHistory());

    useEffect(() => {
        recording.physicalStatus = JSON.stringify(physicalStatus ? physicalStatus : new PhysicalStatus());
        recording.basalPhysical = JSON.stringify(basalPhysical ? basalPhysical : new BasalPhysical());
        recording.specialPhysical = JSON.stringify(specialPhysical ? specialPhysical : new SpecialPhysical());
    }, [physicalStatus, basalPhysical, specialPhysical]);

    return { recording, setRecording };
}

function RecordingPopupComponent(props: IProps) {
    const [loading, setLoading] = useState(false);
    const [ocrResult, setOcrResult] = useState<{ inbody: string; pomChecker: string; footChecker: string; biodex: string; treadMill: string }>({
        inbody: '',
        pomChecker: '',
        footChecker: '',
        biodex: '',
        treadMill: '',
    });
    targetData = props.data;
    recordedData = props.recordingData;
    const closingHandler = props.closingHandler;

    let recordedStatus: PhysicalStatus = recordedData?.physicalStatus
        ? jsonConvert.deserializeObject(JSON.parse(recordedData.physicalStatus), PhysicalStatus)
        : new PhysicalStatus();
    let recordedBasal: BasalPhysical = recordedData?.basalPhysical
        ? jsonConvert.deserializeObject(JSON.parse(recordedData.basalPhysical), BasalPhysical)
        : new BasalPhysical();
    let recordedSpecial: SpecialPhysical = recordedData?.specialPhysical
        ? jsonConvert.deserializeObject(JSON.parse(recordedData?.specialPhysical), SpecialPhysical)
        : new SpecialPhysical();

    const [physicalStatus, setPhysicalStatus] = useState<PhysicalStatus>(recordedStatus);
    const [basalPhysical, setBasalPhysical] = useState<BasalPhysical>(recordedBasal);
    const [specialPhysical, setSpecialPhysical] = useState<SpecialPhysical>(recordedSpecial);

    const { recording, setRecording } = useRecordingHook(physicalStatus, basalPhysical, specialPhysical);

    useEffect(() => {
        setRecording(props.recordingData ? props.recordingData : new RehabTargetHistory());
    }, []);

    const tabHandler = (evt: any) => {
        evt.preventDefault();
        const actions = ActionComponent();
        let targetId = evt.target.id;

        if (targetId === 'tab-btn1') {
            rehabService.manageRecordingStepForTarget(targetData.targetNo, 'physicalStatus').then((targetPersons: TargetPerson) => {
                actions.researchSend();
            });
        } else if (targetId === 'tab-btn2') {
            rehabService.manageRecordingStepForTarget(targetData.targetNo, 'basalPhysical').then((targetPersons: TargetPerson) => {
                actions.researchView();
            });
        } else if (targetId === 'tab-btn3') {
            rehabService.manageRecordingStepForTarget(targetData.targetNo, 'specialPhysical').then((targetPersons: TargetPerson) => {
                actions.researchYes();
            });
        }
    };

    const psChangeHandler = (evt: any) => {
        let target = evt.target.id;
        let value = evt.target.value;

        setPhysicalStatus((prevState) => ({
            ...prevState,
            [target]: value,
        }));
    };

    const bpChangeHandler = (evt: any) => {
        let target = evt.target.id;
        let inputVal = evt.target.value;

        setBasalPhysical((prevState) => {
            let targetIdx = target.split('_');
            let preArr: string[] = ['', '', ''];

            Object.entries(prevState).map(([key, value]) => {
                if (key === targetIdx[0]) preArr = value;
            });

            preArr[targetIdx[1]] = inputVal;

            return {
                ...prevState,
                [targetIdx[0]]: preArr,
            };
        });
    };

    const spChangeHandler = (evt: any) => {
        let target = evt.target.name;
        let propInfo = evt.target.id;
        let inputVal = evt.target.value;

        if (target === 'inBody') {
            if (propInfo === 'uploadIBFile') {
                let file: File = evt.target.files[0];
                setInBody(propInfo, inputVal, file);
            } else {
                setInBody(propInfo, inputVal);
            }
        } else if (target === 'formChecker') {
            if (propInfo === 'uploadedPomFile') {
                let file: File = evt.target.files[0];
                setFormChecker(propInfo, inputVal, file);
            } else {
                setFormChecker(propInfo, inputVal);
            }
        } else if (target === 'footChecker') {
            if (propInfo === 'uploadedFootFile') {
                let file: File = evt.target.files[0];
                setFootChecker(propInfo, inputVal, file);
            } else {
                setFootChecker(propInfo, inputVal);
            }
        } else if (target === 'bioDex') {
            if (propInfo === 'uploadedBDFile') {
                let file: File = evt.target.files[0];
                setBioDex(propInfo, inputVal, file);
            } else {
                setBioDex(propInfo, inputVal);
            }
        } else if (target === 'treadMillTest') {
            if (propInfo === 'uploadedTMFile') {
                let file: File = evt.target.files[0];
                setTreadMillTest(propInfo, inputVal, file);
            } else {
                setTreadMillTest(propInfo, inputVal);
            }
        } else if (target === 'ocrBiodex') {
            setOcrResult((prevState) => {
                // OCR Data 초기화
                return {
                    ...prevState,
                    ['biodex']: '',
                };
            });
            setSpecialPhysical((prevState) => {
                return {
                    ...prevState,
                    ['bioDex']: inputVal,
                };
            });
        } else if (target === 'ocrTreadMillTest') {
            setOcrResult((prevState) => {
                // OCR Data 초기화
                return {
                    ...prevState,
                    ['treadMill']: '',
                };
            });
            setSpecialPhysical((prevState) => {
                return {
                    ...prevState,
                    ['treadMillTest']: inputVal,
                };
            });
        }
    };

    const setInBody = (propInfo: any, inputVal: any, file?: File) => {
        if (propInfo === 'uploadIBFile') {
            setSpecialPhysical((prevState) => {
                let uploadFolder = '/uploads/recording/inbody/';
                let d = new Date();
                let currTime = d.getTime();
                let extension = '.jpg'; // image/jpeg 기본 값

                if (file?.type === 'image/gif') {
                    extension = '.gif';
                } else if (file?.type === 'image/png') {
                    extension = '.png';
                }

                let fileName = currTime + extension;
                let fullPath = uploadFolder + fileName;

                let tmp: {
                    skeletalMuscle: string[];
                    bmi: string[];
                    bodyFat: string;
                    bodyFatRate: string[];
                    visceralFatLev: string[];
                    abdominalFatRate: string[];
                    restingMetabolicRate: string[];
                    bmr: string;
                    leanBodyMass: string;
                    leanBodyRate: string;
                    uploadIBFile: string;
                } = {
                    ...prevState.inBody,
                    ['uploadIBFile']: fullPath,
                };
                prevState = { ...prevState, ['inBody']: tmp };
                fileUploadHandler('inBody', fileName, file, prevState);
                return prevState;
            });
        } else {
            setSpecialPhysical((prevState) => {
                let propArr = propInfo.split('_');
                let newArr: string[] = ['', '', ''];

                Object.entries(prevState.inBody).map(([key, value]) => {
                    if (key === propArr[0] && Array.isArray(value)) newArr = value;
                });
                newArr[propArr[1]] = inputVal;

                let tmp: {
                    skeletalMuscle: string[];
                    bmi: string[];
                    bodyFat: string;
                    bodyFatRate: string[];
                    visceralFatLev: string[];
                    abdominalFatRate: string[];
                    restingMetabolicRate: string[];
                    bmr: string;
                    leanBodyMass: string;
                    leanBodyRate: string;
                    uploadIBFile: string;
                } = {
                    ...prevState.inBody,
                    [propArr[0]]: newArr,
                };

                prevState = { ...prevState, ['inBody']: tmp };

                return prevState;
            });
        }
    };

    const setFormChecker = (propKey: any, inputVal: any, file?: File) => {
        if (propKey === 'uploadedPomFile') {
            setSpecialPhysical((prevState) => {
                let uploadFolder = '/uploads/recording/pom/';
                let d = new Date();
                let currTime = d.getTime();
                let extension = '.jpg'; // image/jpeg 기본 값

                if (file?.type === 'image/gif') {
                    extension = '.gif';
                } else if (file?.type === 'image/png') {
                    extension = '.png';
                } else if (file?.type === 'application/pdf') {
                    extension = '.pdf';
                }

                let fileName = currTime + extension;
                let fullPath = uploadFolder + fileName;
                let newObj: {
                    shuolderL: string;
                    shuolderR: string;
                    pelvisL: string;
                    pelvisR: string;
                    kneeL: string;
                    kneeR: string;
                    ankleL: string;
                    ankleR: string;
                    loinFlexion: string;
                    loinExtension: string;
                    loinLaterFL: string;
                    loinLaterFR: string;
                    uploadedPomFile: string;
                } = {
                    ...prevState.formChecker,
                    [propKey]: fullPath,
                };
                prevState = { ...prevState, ['formChecker']: newObj };
                fileUploadHandler('pom', fileName, file, prevState);
                return prevState;
            });
        } else {
            setSpecialPhysical((prevState) => {
                let newObj: {
                    shuolderL: string;
                    shuolderR: string;
                    pelvisL: string;
                    pelvisR: string;
                    kneeL: string;
                    kneeR: string;
                    ankleL: string;
                    ankleR: string;
                    loinFlexion: string;
                    loinExtension: string;
                    loinLaterFL: string;
                    loinLaterFR: string;
                    uploadedPomFile: string;
                } = {
                    ...prevState.formChecker,
                    [propKey]: inputVal,
                };
                return { ...prevState, ['formChecker']: newObj };
            });
        }
    };

    const setFootChecker = (propKey: any, inputVal: any, file?: File) => {
        if (propKey === 'uploadedFootFile') {
            setSpecialPhysical((prevState) => {
                let uploadFolder = '/uploads/recording/foot/';
                let d = new Date();
                let currTime = d.getTime();
                let extension = '.jpg'; // image/jpeg 기본 값

                if (file?.type === 'image/gif') {
                    extension = '.gif';
                } else if (file?.type === 'image/png') {
                    extension = '.png';
                }
                let fileName = currTime + extension;
                let fullPath = uploadFolder + fileName;
                let newObj: {
                    front: string;
                    back: string;
                    left: string;
                    right: string;
                    uploadedFootFile: string;
                } = {
                    ...prevState.footChecker,
                    [propKey]: fullPath,
                };
                prevState = { ...prevState, ['footChecker']: newObj };

                fileUploadHandler('foot', fileName, file, prevState);
                return prevState;
            });
        } else {
            setSpecialPhysical((prevState) => {
                let newObj: {
                    front: string;
                    back: string;
                    left: string;
                    right: string;
                    uploadedFootFile: string;
                } = {
                    ...prevState.footChecker,
                    [propKey]: inputVal,
                };
                return { ...prevState, ['footChecker']: newObj };
            });
        }
    };

    const setBioDex = (propKey: any, inputVal: any, file?: File) => {
        if (propKey === 'uploadedBDFile') {
            setSpecialPhysical((prevState) => {
                let uploadFolder = '/uploads/recording/biodex/';
                let d = new Date();
                let currTime = d.getTime();
                let extension = '.jpg'; // image/jpeg 기본 값

                if (file?.type === 'image/gif') {
                    extension = '.gif';
                } else if (file?.type === 'image/png') {
                    extension = '.png';
                }
                let fileName = currTime + extension;
                let fullPath = uploadFolder + fileName;
                let newObj: {
                    pt60ExtL: string;
                    pt60ExtR: string;
                    pt60FlxL: string;
                    pt60FlxR: string;
                    pt180ExtL: string;
                    pt180ExtR: string;
                    pt180FlxL: string;
                    pt180FlxR: string;
                    pt300ExtL: string;
                    pt300ExtR: string;
                    pt300FlxL: string;
                    pt300FlxR: string;
                    pt15ExtL: string;
                    pt15ExtR: string;
                    pt15FlxL: string;
                    pt15FlxR: string;

                    ptb60ExtL: string;
                    ptb60ExtR: string;
                    ptb60FlxL: string;
                    ptb60FlxR: string;
                    ptb180ExtL: string;
                    ptb180ExtR: string;
                    ptb180FlxL: string;
                    ptb180FlxR: string;
                    ptb300ExtL: string;
                    ptb300ExtR: string;
                    ptb300FlxL: string;
                    ptb300FlxR: string;
                    ptb15ExtL: string;
                    ptb15ExtR: string;
                    ptb15FlxL: string;
                    ptb15FlxR: string;

                    torq30_60ExtL: string;
                    torq30_60ExtR: string;
                    torq30_60FlxL: string;
                    torq30_60FlxR: string;
                    torq30_180ExtL: string;
                    torq30_180ExtR: string;
                    torq30_180FlxL: string;
                    torq30_180FlxR: string;
                    torq30_300ExtL: string;
                    torq30_300ExtR: string;
                    torq30_300FlxL: string;
                    torq30_300FlxR: string;
                    torq30_15ExtL: string;
                    torq30_15ExtR: string;
                    torq30_15FlxL: string;
                    torq30_15FlxR: string;

                    torq18_60ExtL: string;
                    torq18_60ExtR: string;
                    torq18_60FlxL: string;
                    torq18_60FlxR: string;
                    torq18_180ExtL: string;
                    torq18_180ExtR: string;
                    torq18_180FlxL: string;
                    torq18_180FlxR: string;
                    torq18_300ExtL: string;
                    torq18_300ExtR: string;
                    torq18_300FlxL: string;
                    torq18_300FlxR: string;
                    torq18_15ExtL: string;
                    torq18_15ExtR: string;
                    torq18_15FlxL: string;
                    torq18_15FlxR: string;

                    cofe60ExtL: string;
                    cofe60ExtR: string;
                    cofe60FlxL: string;
                    cofe60FlxR: string;
                    cofe180ExtL: string;
                    cofe180ExtR: string;
                    cofe180FlxL: string;
                    cofe180FlxR: string;
                    cofe300ExtL: string;
                    cofe300ExtR: string;
                    cofe300FlxL: string;
                    cofe300FlxR: string;
                    cofe15ExtL: string;
                    cofe15ExtR: string;
                    cofe15FlxL: string;
                    cofe15FlxR: string;

                    totWo60ExtL: string;
                    totWo60ExtR: string;
                    totWo60FlxL: string;
                    totWo60FlxR: string;
                    totWo180ExtL: string;
                    totWo180ExtR: string;
                    totWo180FlxL: string;
                    totWo180FlxR: string;
                    totWo300ExtL: string;
                    totWo300ExtR: string;
                    totWo300FlxL: string;
                    totWo300FlxR: string;
                    totWo15ExtL: string;
                    totWo15ExtR: string;
                    totWo15FlxL: string;
                    totWo15FlxR: string;

                    workFat60ExtL: string;
                    workFat60ExtR: string;
                    workFat60FlxL: string;
                    workFat60FlxR: string;
                    workFat180ExtL: string;
                    workFat180ExtR: string;
                    workFat180FlxL: string;
                    workFat180FlxR: string;
                    workFat300ExtL: string;
                    workFat300ExtR: string;
                    workFat300FlxL: string;
                    workFat300FlxR: string;
                    workFat15ExtL: string;
                    workFat15ExtR: string;
                    workFat15FlxL: string;
                    workFat15FlxR: string;

                    avgPow60ExtL: string;
                    avgPow60ExtR: string;
                    avgPow60FlxL: string;
                    avgPow60FlxR: string;
                    avgPow180ExtL: string;
                    avgPow180ExtR: string;
                    avgPow180FlxL: string;
                    avgPow180FlxR: string;
                    avgPow300ExtL: string;
                    avgPow300ExtR: string;
                    avgPow300FlxL: string;
                    avgPow300FlxR: string;
                    avgPow15ExtL: string;
                    avgPow15ExtR: string;
                    avgPow15FlxL: string;
                    avgPow15FlxR: string;

                    agan60ExtL: string;
                    agan60ExtR: string;
                    agan60FlxL: string;
                    agan60FlxR: string;
                    agan180ExtL: string;
                    agan180ExtR: string;
                    agan180FlxL: string;
                    agan180FlxR: string;
                    agan300ExtL: string;
                    agan300ExtR: string;
                    agan300FlxL: string;
                    agan300FlxR: string;
                    agan15ExtL: string;
                    agan15ExtR: string;
                    agan15FlxL: string;
                    agan15FlxR: string;
                    uploadBDFile: string;
                } = {
                    ...prevState.bioDex,
                    ['uploadBDFile']: fullPath,
                };
                prevState = { ...prevState, ['bioDex']: newObj };
                fileUploadHandler('biodex', fileName, file, prevState);

                return prevState;
            });
        } else {
            setSpecialPhysical((prevState) => {
                let newObj: {
                    pt60ExtL: string;
                    pt60ExtR: string;
                    pt60FlxL: string;
                    pt60FlxR: string;
                    pt180ExtL: string;
                    pt180ExtR: string;
                    pt180FlxL: string;
                    pt180FlxR: string;
                    pt300ExtL: string;
                    pt300ExtR: string;
                    pt300FlxL: string;
                    pt300FlxR: string;
                    pt15ExtL: string;
                    pt15ExtR: string;
                    pt15FlxL: string;
                    pt15FlxR: string;

                    ptb60ExtL: string;
                    ptb60ExtR: string;
                    ptb60FlxL: string;
                    ptb60FlxR: string;
                    ptb180ExtL: string;
                    ptb180ExtR: string;
                    ptb180FlxL: string;
                    ptb180FlxR: string;
                    ptb300ExtL: string;
                    ptb300ExtR: string;
                    ptb300FlxL: string;
                    ptb300FlxR: string;
                    ptb15ExtL: string;
                    ptb15ExtR: string;
                    ptb15FlxL: string;
                    ptb15FlxR: string;

                    torq30_60ExtL: string;
                    torq30_60ExtR: string;
                    torq30_60FlxL: string;
                    torq30_60FlxR: string;
                    torq30_180ExtL: string;
                    torq30_180ExtR: string;
                    torq30_180FlxL: string;
                    torq30_180FlxR: string;
                    torq30_300ExtL: string;
                    torq30_300ExtR: string;
                    torq30_300FlxL: string;
                    torq30_300FlxR: string;
                    torq30_15ExtL: string;
                    torq30_15ExtR: string;
                    torq30_15FlxL: string;
                    torq30_15FlxR: string;

                    torq18_60ExtL: string;
                    torq18_60ExtR: string;
                    torq18_60FlxL: string;
                    torq18_60FlxR: string;
                    torq18_180ExtL: string;
                    torq18_180ExtR: string;
                    torq18_180FlxL: string;
                    torq18_180FlxR: string;
                    torq18_300ExtL: string;
                    torq18_300ExtR: string;
                    torq18_300FlxL: string;
                    torq18_300FlxR: string;
                    torq18_15ExtL: string;
                    torq18_15ExtR: string;
                    torq18_15FlxL: string;
                    torq18_15FlxR: string;

                    cofe60ExtL: string;
                    cofe60ExtR: string;
                    cofe60FlxL: string;
                    cofe60FlxR: string;
                    cofe180ExtL: string;
                    cofe180ExtR: string;
                    cofe180FlxL: string;
                    cofe180FlxR: string;
                    cofe300ExtL: string;
                    cofe300ExtR: string;
                    cofe300FlxL: string;
                    cofe300FlxR: string;
                    cofe15ExtL: string;
                    cofe15ExtR: string;
                    cofe15FlxL: string;
                    cofe15FlxR: string;

                    totWo60ExtL: string;
                    totWo60ExtR: string;
                    totWo60FlxL: string;
                    totWo60FlxR: string;
                    totWo180ExtL: string;
                    totWo180ExtR: string;
                    totWo180FlxL: string;
                    totWo180FlxR: string;
                    totWo300ExtL: string;
                    totWo300ExtR: string;
                    totWo300FlxL: string;
                    totWo300FlxR: string;
                    totWo15ExtL: string;
                    totWo15ExtR: string;
                    totWo15FlxL: string;
                    totWo15FlxR: string;

                    workFat60ExtL: string;
                    workFat60ExtR: string;
                    workFat60FlxL: string;
                    workFat60FlxR: string;
                    workFat180ExtL: string;
                    workFat180ExtR: string;
                    workFat180FlxL: string;
                    workFat180FlxR: string;
                    workFat300ExtL: string;
                    workFat300ExtR: string;
                    workFat300FlxL: string;
                    workFat300FlxR: string;
                    workFat15ExtL: string;
                    workFat15ExtR: string;
                    workFat15FlxL: string;
                    workFat15FlxR: string;

                    avgPow60ExtL: string;
                    avgPow60ExtR: string;
                    avgPow60FlxL: string;
                    avgPow60FlxR: string;
                    avgPow180ExtL: string;
                    avgPow180ExtR: string;
                    avgPow180FlxL: string;
                    avgPow180FlxR: string;
                    avgPow300ExtL: string;
                    avgPow300ExtR: string;
                    avgPow300FlxL: string;
                    avgPow300FlxR: string;
                    avgPow15ExtL: string;
                    avgPow15ExtR: string;
                    avgPow15FlxL: string;
                    avgPow15FlxR: string;

                    agan60ExtL: string;
                    agan60ExtR: string;
                    agan60FlxL: string;
                    agan60FlxR: string;
                    agan180ExtL: string;
                    agan180ExtR: string;
                    agan180FlxL: string;
                    agan180FlxR: string;
                    agan300ExtL: string;
                    agan300ExtR: string;
                    agan300FlxL: string;
                    agan300FlxR: string;
                    agan15ExtL: string;
                    agan15ExtR: string;
                    agan15FlxL: string;
                    agan15FlxR: string;
                    uploadBDFile: string;
                } = {
                    ...prevState.bioDex,
                    [propKey]: inputVal,
                };
                return { ...prevState, ['bioDex']: newObj };
            });
        }
    };

    const setTreadMillTest = (propKey: any, inputVal: any, file?: File) => {
        if (propKey === 'uploadedTMFile') {
            setSpecialPhysical((prevState) => {
                let uploadFolder = '/uploads/recording/treadMillTest/';
                let d = new Date();
                let currTime = d.getTime();
                let extension = '.jpg'; // image/jpeg 기본 값

                if (file?.type === 'image/gif') {
                    extension = '.gif';
                } else if (file?.type === 'image/png') {
                    extension = '.png';
                }
                let fileName = currTime + extension;
                let fullPath = uploadFolder + fileName;
                let newObj: {
                    vo2: string;
                    mets: string;
                    ve: string;
                    vt: string;
                    rq: string;
                    uploadTMFile: string;
                } = {
                    ...prevState.treadMillTest,
                    ['uploadTMFile']: fullPath,
                };

                prevState = { ...prevState, ['treadMillTest']: newObj };

                fileUploadHandler('treadMillTest', fileName, file, prevState);
                return prevState;
            });
        } else {
            setSpecialPhysical((prevState) => {
                let newObj: {
                    vo2: string;
                    mets: string;
                    ve: string;
                    vt: string;
                    rq: string;
                    uploadTMFile: string;
                } = {
                    ...prevState.treadMillTest,
                    [propKey]: inputVal,
                };
                return { ...prevState, ['treadMillTest']: newObj };
            });
        }
    };

    const fileUploadHandler = (fileType: string, fileName: string, file?: File, specialPhysical?: SpecialPhysical) => {
        setLoading(true);
        let targetForm = new FormData();

        let physical: string = JSON.stringify(specialPhysical);
        targetForm.append('targetNo', recordedData.targetNo.toString());
        targetForm.append('historyNo', recordedData.historyNo.toString());
        targetForm.append('fileType', fileType);
        targetForm.append('fileName', fileName);
        targetForm.append('specialPhysical', physical);

        if (file) {
            targetForm.append('uploadingFile', file);

            let imageElemId = 'uploadedPomImages';
            if (fileType === 'inBody') {
                imageElemId = 'uploadedIBImages';
            } else if (fileType === 'foot') {
                imageElemId = 'uploadedFootImages';
            } else if (fileType === 'biodex') {
                imageElemId = 'uploadedBDImages';
            } else if (fileType === 'treadMillTest') {
                imageElemId = 'uploadedTMImages';
            }

            // 파일 업로드 전 미리 보기 진행
            const previewContainer = document.getElementById(imageElemId);
            const preview = document.createElement('img');
            preview.id = 'uploadedImages';
            preview.width = 580;
            let children = previewContainer?.childNodes;

            for (let i = 0; children?.length; i++) {
                previewContainer?.removeChild(previewContainer?.childNodes[i]);
            }
            previewContainer?.appendChild(preview);

            const reader = new FileReader();
            reader.onload = function (evt) {
                preview.src = reader.result ? reader.result.toString() : '';
            };

            reader.readAsDataURL(file);
        }

        rehabHistoryService.manageRecordingTargetWithFile(targetForm).then((history: RehabTargetHistory) => {
            confirmAlert({
                title: '파일업로드성공',
                message: '파일이 성공적으로 업로드 되었습니다. 적용 된 기록을 확인해 주십시오.',
                buttons: [
                    {
                        label: '확인',
                        onClick: () => {
                            setLoading(false);

                            setOcrResult((prevState) => {
                                //{ inbody: string; pomChecker: string; footChecker: string; biodex: string; treadMill: string }
                                let prop = 'pomChecker';
                                if (fileType === 'treadMillTest') prop = 'treadMill';
                                else if (fileType === 'inBody') prop = 'inbody';
                                else if (fileType === 'foot') prop = 'footChecker';
                                else if (fileType === 'biodex') prop = 'biodex';

                                return {
                                    ...prevState,
                                    [prop]: history.ocrJson,
                                };
                            });
                        },
                    },
                ],
            });
        });
    };

    const storeHandler = (evt: any) => {
        let pageHandler = ActionComponent();
        var action = evt.target.id;
        let alertOptions = {};

        if (action === 'close') {
            targetData = props.data;
            closingHandler();
            pageHandler.popupClose();
        } else {
            let message = '';
            recording.action = action;
            if (recordedData) {
                recording.historyNo = recordedData.historyNo;
                recording.targetNo = recordedData.targetNo;
            }
            if (action === 'complete') {
                message =
                    '측정이 완료 되었습니다. <결과확인> 으로 입력한 내용을 확인하실 수 있습니다. <처방>에서 측정완료 대상자를 확인할 수 있습니다.';
            } else if (action === 'store') {
                message = '입력한 데이터를 <임시저장>하였습니다.';
            }

            // rehabHistoryService.manageRecordingForTarget(recording).then((history: RehabTargetHistory) => {
            //     confirmAlert({
            //         title: '저장성공',
            //         message: message,
            //         buttons: [
            //             {
            //                 label: '확인',
            //                 onClick: () => {
            //                     recordedData = history;
            //                     closingHandler();
            //                     if (action === 'complete') pageHandler.popupClose();
            //                 },
            //             },
            //         ],
            //     });
            // });
        }
    };

    return (
        <>
            <div className="center-recording-popup wow  animate__animated animate__fadeInUp" data-wow-delay="0.2s">
                <div className="center-popup-title">
                    <h1 className="center">
                        <i className="fa fa-id-badge"></i> 측정하기
                    </h1>
                    <div className="flex">
                        <h1 className="center">
                            <i className="fas fa-user-tie"></i> {targetData?.reservater?.name}
                        </h1>
                        &nbsp;
                        <span className="center-birth">
                            {targetData?.reservater?.genderCode?.name} / {targetData?.reservater?.birthdate}
                        </span>
                    </div>
                    <div>
                        <button id="store" className="center-button-save" onClick={storeHandler}>
                            임시저장 <i className="fas fa-file-download"></i>
                        </button>
                        &nbsp;
                        <button id="complete" className="center-button-complete" onClick={storeHandler}>
                            완료 <i className="fas fa-file-download"></i>
                        </button>
                        &nbsp;
                        <button id="close" className="center-button-close" onClick={storeHandler}>
                            닫기 <i className="fas fa-times-circle"></i>
                        </button>
                    </div>
                </div>

                <div className="center-popup-cont">
                    <div className="center-popup-bnt">
                        <a href="#" id="tab-btn1" className="on" onClick={tabHandler}>
                            <i className="fas fa-weight"></i>신체측정
                        </a>
                        <a href="#" id="tab-btn2" onClick={tabHandler}>
                            <i className="fas fa-running"></i>기초체력 측정
                        </a>
                        <a href="#" id="tab-btn3" onClick={tabHandler}>
                            <i className="fas fa-skating"></i>전문체력 측정
                        </a>
                    </div>

                    <PhysicalStatusForm personData={targetData} status={physicalStatus} handler={psChangeHandler}></PhysicalStatusForm>

                    <BasalPhysicalForm status={basalPhysical} handler={bpChangeHandler}></BasalPhysicalForm>

                    <SpecialPhysicalForms status={specialPhysical} handler={spChangeHandler} ocr={ocrResult}></SpecialPhysicalForms>
                </div>
            </div>
            <Loader loading={loading} />
        </>
    );
}

export default RecordingPopupComponent;
