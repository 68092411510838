import React, { useEffect, useRef, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import useRehabStatisticsTimeInfo from '../../hook/useRehabStatisticsTimeInfo';
function RehabStatisticsMeasureInfoByTimeTab() {
    const [tabSeq, setTabSeq] = useState<string>('tab1');
    const yearDivElementRef = useRef<HTMLDivElement>(null);
    const { yearDataSet, quarterOfYearDataSet, monthOfYearDataSet } = useRehabStatisticsTimeInfo();

    useEffect(() => {
        console.log(tabSeq);
    }, [tabSeq]);

    const tabs: any = {
        tab1: <Bar data={yearDataSet}></Bar>,
        tab2: <Bar data={quarterOfYearDataSet}></Bar>,
        tab3: <Bar data={monthOfYearDataSet}></Bar>,
    };

    return (
        <>
            <div id="center-popup-list">
                <div>
                    <div className="flex-basic" style={{ marginTop: '8px' }}>
                        <div className="center-tabbt-s flex">
                            <a
                                href="javascript:void(0)"
                                className={tabSeq == 'tab1' ? 'on' : ''}
                                onClick={() => {
                                    setTabSeq('tab1');
                                }}
                            >
                                년도별
                            </a>
                            <a
                                href="javascript:void(0)"
                                className={tabSeq == 'tab2' ? 'on' : ''}
                                onClick={() => {
                                    setTabSeq('tab2');
                                }}
                            >
                                분기별
                            </a>
                            <a
                                href="javascript:void(0)"
                                className={tabSeq == 'tab3' ? 'on' : ''}
                                onClick={() => {
                                    setTabSeq('tab3');
                                }}
                            >
                                월별
                            </a>
                        </div>
                        <div>
                            <button className="center-button-esave">
                                <i className="fas fa-file-excel"></i> 엑셀파일 저장
                            </button>
                        </div>
                    </div>
                </div>
                <div ref={yearDivElementRef} className="chart-area" style={{ height: '600px' }}>
                    {tabs[tabSeq]}
                </div>
            </div>
        </>
    );
}

export default RehabStatisticsMeasureInfoByTimeTab;
