import { useEffect, useState } from "react";
import { USER_SERVICE } from '../../service/index';
import UserSearchCondition from '../../domain/user/param/UserSearchCondition';


function useUserEmailOverlapCheck(email?: string) {
    const [isValidation, setIsValidation] = useState<boolean>();
    
    useEffect(() => {
        if(email) {
            console.log(email);
            const searchCondition = new UserSearchCondition();
            searchCondition.email = email;
    
            USER_SERVICE.getUserInfosCnt(searchCondition)
            .then(response => {
                if(response > 0) {
                    //존재하는 조건
                    setIsValidation(false);
                } else {
                    setIsValidation(true);
                }
            });
        }
    }, [email])

    return isValidation;
}

export default useUserEmailOverlapCheck;