import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { find } from 'lodash';
import ReactToPrint, { useReactToPrint } from 'react-to-print';

import '../assets/css/center.css';
import '../assets/css/animate.min.css';
import '../assets/css/reporting.css';
import Layout from '../layouts/Layout';
import Header from '../layouts/Header';

import RehabHistoryService from '../service/RehabHistoryService';
import RehabHistoryServiceImpl from '../service/impl/RehabHistoryServiceImpl';
import RehabTargetHistory from '../domain/RehabTargetHistory';
import { useSelector } from 'react-redux';
import { RootState } from '../../../moldules';
import BasalReporting from './reporting/BasalReporting';
import FootCheckerForm from './components/recording/forms/tabs/FootCheckForm';
import FootScanReporting from './reporting/FootScanReporting';
import FormCheckerReporting from './reporting/FormCheckerReporting';
import BioDexReporting from './reporting/BioDexReporting';
import TreadMillReporting from './reporting/TreadMillReporting';
import BasalPhysical from '../domain/recording/BasalPhysical';
import SpecialPhysical from '../domain/recording/SpecialPhysical';
import { JsonConvert } from 'json2typescript';
import PhysicalStatus from '../../../domain/report/PhysicalStatus';

const rehabHistoryService: RehabHistoryService = new RehabHistoryServiceImpl();
const menuTitle: string = '* 리포팅 관리';
const title: string = '리포팅 관리';
const jsonConverter = new JsonConvert();

export default function ReportingComponent(props: any) {
    let path = props.location.pathname;
    const params = useParams<any>();
    const { user } = useSelector((state: RootState) => state.userSessionReducer);
    const location = useLocation();
    const history = useHistory();

    const [reservationNo, setReservationNo] = useState<string>(params.reservationNo ? params.reservationNo : '');
    const [historyNo, setHistoryNo] = useState<number>(params.historyNo ? params.historyNo : 0);

    const [target, setTarget] = useState<RehabTargetHistory>();
    const [basal, setBasal] = useState<BasalPhysical>();
    const [special, setSpecial] = useState<SpecialPhysical>();
    const [physicalStatus, setPhysicalStatus] = useState<PhysicalStatus>();
    const [profile, setProfile] = useState<any>();

    const componentRef = useRef<any>();

    const onBeforeGetContentResolve = useRef();

    const handleAfterPrint = useCallback(() => {
        console.log('onAfterPrint called');
    }, []);

    const handleBeforePrint = useCallback(() => {
        console.log('before print called');
    }, []);
    const handleOnBeforeGetContent = useCallback(() => {
        console.log('on before get Content called');
    }, []);

    let locationState = location.state as any;
    const goBack = () => {
        let returnPath = locationState.returnPath;
        history.replace({
            pathname: returnPath,
        });
    };

    const reactToPrintContent = () => {
        return componentRef.current;
    };

    const printHandler = useReactToPrint({
        content: () => componentRef.current,
    });

    const reactToPrintTrigger = () => {
        return (
            <>
                <button className="btn btn-danger" style={{ float: 'right', margin: '5px 2px', lineHeight: '1vw' }} onClick={printHandler}>
                    출력하기
                </button>
                <button className="btn btn-info" style={{ float: 'right', margin: '5px 2px', lineHeight: '1vw' }} onClick={goBack}>
                    돌아가기
                </button>
            </>
        );
    };

    useEffect(() => {
        if (reservationNo) {
            rehabHistoryService.getRehabHistoryForReservation(reservationNo).then((history: RehabTargetHistory[]) => {
                let targetHistory = history[0];
                let basal = targetHistory?.basalPhysical;
                let special = targetHistory?.specialPhysical;
                let physicalStatus = targetHistory?.physicalStatus;
                let profile = targetHistory?.reservation?.reservater;

                if (targetHistory) {
                    setTarget(targetHistory);
                }

                if (profile) {
                    setProfile(profile);
                }

                if (physicalStatus) {
                    //console.log(JSON.parse(physicalStatus));
                    setPhysicalStatus(jsonConverter.deserializeObject(JSON.parse(physicalStatus), PhysicalStatus));
                }
                if (basal) {
                    //console.log(JSON.parse(basal));
                    setBasal(jsonConverter.deserializeObject(JSON.parse(basal), BasalPhysical));
                }
                if (special) {
                    //console.log(JSON.parse(special));
                    setSpecial(jsonConverter.deserializeObject(JSON.parse(special), SpecialPhysical));
                }
            });
        }
    }, [reservationNo]);

    return (
        <>
            <ReactToPrint
                content={reactToPrintContent}
                documentTitle="RecordingReport"
                onAfterPrint={handleAfterPrint}
                onBeforeGetContent={handleOnBeforeGetContent}
                onBeforePrint={handleBeforePrint}
                removeAfterPrint
                trigger={reactToPrintTrigger}
            />
            <div className="reporting-body" ref={componentRef}>
                <div className="title">기초체력 • 전문체력 측정평가 데이터 결과</div>

                <table
                    className="center-list-table"
                    cellPadding={0}
                    cellSpacing={0}
                    style={{ fontWeight: 700, fontSize: '20px', textAlign: 'center', paddingBottom: '15px' }}
                >
                    <colgroup>
                        <col style={{ width: '35%', textAlign: 'left' }} />
                        <col style={{ width: '30%' }} />
                        <col style={{ width: '35%' }} />
                    </colgroup>
                    <tbody>
                        <tr>
                            <td style={{ textAlign: 'left', paddingLeft: '25px' }}>측정 회차 번호: {target?.historyNo} </td>
                            <td>
                                측정 날짜 : {target?.regDate[0]}년 {target?.regDate[1]}월 {target?.regDate[2]}일
                            </td>
                            <td style={{ textAlign: 'right', paddingRight: '25px' }}>측정자 : {user.name}</td>
                        </tr>
                    </tbody>
                </table>
                <div style={{ paddingTop: '15px', paddingBottom: '10px' }}>
                    <table className="reporting-table" cellPadding={0} cellSpacing={0}>
                        <colgroup>
                            <col style={{ width: '5%' }} />
                            <col style={{ width: '15%' }} />
                            <col style={{ width: '15%' }} />
                            <col style={{ width: '15%' }} />
                            <col style={{ width: '15%' }} />
                            <col style={{ width: '15%' }} />
                            <col style={{ width: '20%' }} />
                        </colgroup>
                        <tbody>
                            <tr>
                                <td rowSpan={2} style={{ fontWeight: 700, fontSize: '1em' }}>
                                    개인정보
                                </td>
                                <th>성명</th>
                                <td>{profile?.name}</td>
                                <th>종목</th>
                                <td>{profile?.sportCode?.name}</td>
                                <th>소 속 팀</th>
                                <td>{profile?.teamName}</td>
                            </tr>
                            <tr>
                                <th>성별</th>
                                <td>{profile?.genderCode?.name}</td>
                                <th>생년월일</th>
                                <td>{profile?.birthdate}</td>
                                <th>나이</th>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                    <BasalReporting status={physicalStatus} basal={basal}></BasalReporting>
                </div>
                <div style={{ width: '100%', paddingTop: '10px', fontSize: '15px', fontWeight: 700 }}>전문체력</div>
                <div style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                    <FootScanReporting data={special}></FootScanReporting>
                </div>
                <table className="reporting-table" cellPadding={0} cellSpacing={0}>
                    <colgroup>
                        <col style={{ width: '5%' }} />
                        <col style={{ width: '15%', backgroundColor: '#fff2cc' }} />
                        <col style={{ width: '15%', backgroundColor: '#c6e0b4' }} />
                        <col style={{ width: '15%', backgroundColor: '#fff2cc' }} />
                        <col style={{ width: '15%', backgroundColor: '#c6e0b4' }} />
                        <col style={{ width: '15%', backgroundColor: '#fff2cc' }} />
                        <col style={{ width: '15%', backgroundColor: '#c6e0b4' }} />
                    </colgroup>
                    <tbody>
                        <tr>
                            <td rowSpan={7}>
                                부위별 <br />
                                각도
                                <br /> 기준표
                            </td>
                            <td colSpan={2} style={{ fontWeight: 900 }}>
                                어깨/골반/무릎
                            </td>
                            <td colSpan={2} style={{ fontWeight: 900 }}>
                                거북목
                            </td>
                            <td colSpan={2} style={{ fontWeight: 900 }}>
                                목이 받는 추가 하중
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2}>각도범위(X)</td>
                            <td colSpan={2}>각도범위(X)</td>
                            <td>각도범위(X)</td>
                            <td>무게구간(kg)</td>
                        </tr>
                        <tr>
                            <td>양호</td>
                            <td>0˚ ≦ X⟨1.6˚</td>
                            <td>양호</td>
                            <td>0˚ ≦ X⟨20˚</td>
                            <td>0˚ ≦ X⟨15˚</td>
                            <td>5.4 – 12.2</td>
                        </tr>
                        <tr>
                            <td>보통</td>
                            <td>1.6˚ ≦ X⟨3˚</td>
                            <td>보통</td>
                            <td>20˚ ≦ X⟨25˚</td>
                            <td>15˚ ≦ X⟨30˚</td>
                            <td>12.2 – 18.1</td>
                        </tr>
                        <tr>
                            <td>경미</td>
                            <td>3˚ ≦ X⟨6˚ </td>
                            <td>경미</td>
                            <td>25˚ ≦ X⟨30˚</td>
                            <td>30˚ ≦ X⟨45˚</td>
                            <td>18.1 – 22.2</td>
                        </tr>
                        <tr>
                            <td>진행</td>
                            <td>6˚≦ X⟨8˚</td>
                            <td>진행</td>
                            <td>30˚ ≦ X⟨45˚</td>
                            <td>45˚ ≦ X⟨60˚</td>
                            <td>22.2 – 22.7</td>
                        </tr>
                        <tr>
                            <td>심각</td>
                            <td>8˚ ≦ X⟨12˚ </td>
                            <td>심각</td>
                            <td>45˚ X⟨60˚ </td>
                            <td colSpan={2}>　</td>
                        </tr>
                    </tbody>
                </table>
                <div style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                    <FormCheckerReporting data={special}></FormCheckerReporting>
                </div>
                <div style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                    <BioDexReporting data={special}></BioDexReporting>
                </div>
                <div style={{ width: '100%', paddingTop: '10px', paddingBottom: '2px', fontSize: '15px', fontWeight: 700 }}>
                    운동부하검사
                    <span style={{ float: 'right', fontSize: '0.5em', fontWeight: 500, paddingRight: '15px' }}>(단위: ml/kg/min)</span>
                </div>
                <table className="reporting-table" cellPadding={0} cellSpacing={0}>
                    <colgroup>
                        <col style={{ width: '5%' }} />
                        <col style={{ width: '5%' }} />
                        <col style={{ width: '9%', backgroundColor: '#fff2cc' }} />
                        <col style={{ width: '9%', backgroundColor: '#c6e0b4' }} />
                        <col style={{ width: '9%', backgroundColor: '#fff2cc' }} />
                        <col style={{ width: '9%', backgroundColor: '#c6e0b4' }} />
                        <col style={{ width: '9%', backgroundColor: '#fff2cc' }} />
                        <col style={{ width: '9%', backgroundColor: '#c6e0b4' }} />
                        <col style={{ width: '9%', backgroundColor: '#fff2cc' }} />
                        <col style={{ width: '9%', backgroundColor: '#c6e0b4' }} />
                        <col style={{ width: '9%', backgroundColor: '#fff2cc' }} />
                        <col style={{ width: '9%', backgroundColor: '#c6e0b4' }} />
                    </colgroup>
                    <tbody>
                        <tr>
                            <td rowSpan={9}>
                                최대산소섭취량
                                <br />
                                (Vo2 Max)
                                <br />
                                기준표
                                <br />
                            </td>
                            <td rowSpan={2}>나이</td>
                            <td colSpan={2} style={{ backgroundColor: '#fff' }}>
                                매우 나쁨
                            </td>
                            <td colSpan={2} style={{ backgroundColor: '#fff' }}>
                                나쁨
                            </td>
                            <td colSpan={2} style={{ backgroundColor: '#fff' }}>
                                보통
                            </td>
                            <td colSpan={2} style={{ backgroundColor: '#fff' }}>
                                좋음
                            </td>
                            <td colSpan={2} style={{ backgroundColor: '#fff' }}>
                                매우 좋음
                            </td>
                        </tr>
                        <tr>
                            <td>남</td>
                            <td>여</td>
                            <td>남</td>
                            <td>여</td>
                            <td>남</td>
                            <td>여</td>
                            <td>남</td>
                            <td>여</td>
                            <td>남</td>
                            <td>여</td>
                        </tr>
                        <tr>
                            <td>~19세</td>
                            <td>36.1 이하</td>
                            <td>24.9 이하</td>
                            <td>36.2-39.7</td>
                            <td>25.0-30.4</td>
                            <td>39.8-46.7</td>
                            <td>30.5-35.5</td>
                            <td>46.8-54.2</td>
                            <td>35.6-40.9</td>
                            <td>54.3 이상</td>
                            <td>41.0 이상 </td>
                        </tr>
                        <tr>
                            <td>20대</td>
                            <td>35.5 이하</td>
                            <td> 29.5이하</td>
                            <td> 35.6-42.0</td>
                            <td> 29.6-33.1</td>
                            <td> 42.1-49.3</td>
                            <td> 32.2-38.2</td>
                            <td> 49.4-56.4</td>
                            <td> 38.3-44.4</td>
                            <td> 56.4 이상</td>
                            <td> 44.5 이상 </td>
                        </tr>
                        <tr>
                            <td>30대</td>
                            <td>33.8 이하</td>
                            <td> 27.7이하</td>
                            <td> 33.9-38.5</td>
                            <td> 27.8-31.4</td>
                            <td> 38.6-44.0</td>
                            <td> 31.5-35.6</td>
                            <td> 44.1-49.7</td>
                            <td> 35.7-40.1</td>
                            <td> 49.8 이상</td>
                            <td> 40.2 이상 </td>
                        </tr>
                        <tr>
                            <td>40대</td>
                            <td>32.1 이하</td>
                            <td> 25.8이하</td>
                            <td> 32.3-36.7</td>
                            <td> 25.9-29.6</td>
                            <td> 36.8-42.3</td>
                            <td> 29.7-33.8</td>
                            <td> 42.4-47.3</td>
                            <td> 33.9-37.9</td>
                            <td> 47.4 이상</td>
                            <td> 38.0 이상 </td>
                        </tr>
                        <tr>
                            <td>50대</td>
                            <td>28.8 이하</td>
                            <td> 23.4이하</td>
                            <td> 28.9-33.8</td>
                            <td> 23.5-27.3</td>
                            <td> 33.9-39.8</td>
                            <td> 27.4-31.2</td>
                            <td> 39.9-44.7</td>
                            <td> 31.3-35.5</td>
                            <td> 44.8 이상</td>
                            <td> 35.6 이상 </td>
                        </tr>
                        <tr>
                            <td>60대</td>
                            <td>23.1 이하</td>
                            <td> 20.5이하</td>
                            <td> 23.2-27.8</td>
                            <td> 20.6-23.4</td>
                            <td> 27.9-33.3</td>
                            <td> 23.5-28.6</td>
                            <td> 33.4-35.4</td>
                            <td> 28.7-30.7</td>
                            <td> 35.5 이상</td>
                            <td> 30.8 이상 </td>
                        </tr>
                    </tbody>
                </table>
                <div style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                    <TreadMillReporting data={special}></TreadMillReporting>
                </div>
            </div>
        </>
    );
}
