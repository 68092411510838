import { useEffect, useState } from "react";
import Team from '../../domain/team/Team';
import { TEAM_SERVICE } from '../../service/index';

function useTeamRegiste(newTeam?: Team) {
    const [team, setTeam] = useState<Team>();
    useEffect(() => {
        if(newTeam) {
            TEAM_SERVICE.registeTeam(newTeam)
            .then(team => setTeam(team));
        }
    }, [newTeam]);

    return team;
}

export default useTeamRegiste;