import { useEffect, useState } from 'react';
import { CodeType } from '../../../../domain/code/CodeType';
import { useDispatch } from 'react-redux';
import { initConditionAction, putConditionAction } from '../../../../moldules/SearchCondition';
/**
 * radio 버튼은 선택 되면 조회 조건을 다 초기화 하고 선택 된 항목을 조회 조건으로 넣는다.
 */
function useRadioSelectHook(initChoiceId: number, codeType?: CodeType) {
    const dispatch = useDispatch();
    const [selectedCodeId, setSelectedCodeId] = useState(initChoiceId);
    useEffect(() => {
        if(codeType) {
            dispatch(initConditionAction());
            dispatch(putConditionAction(selectedCodeId, codeType));
        }
    }, [selectedCodeId]);

    return {
        selectedCodeId, setSelectedCodeId
    }
}

export default useRadioSelectHook;