import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import AdminMainTitle from '../../../../component/admin/AdminMainTitle';
//import useVisitedTeamInfoRetrieve from '../../../../hook/visitedInfo/useVisitedTeamInfoRetrieve';
import useGroupingVisitedTeamInfoRetrieve from '../../../../hook/visitedInfo/useGroupingVisitedTeamInfoRetrieve';
import AdminVisitedTeamInfosTable from '../../component/AdminVisitedTeamInfosTable';
import VisitedTeamInfoSearchCondition from '../../../../domain/visitedInfo/pagam/VisitedTeamInfoSearchCondition';
import AdminRetrieveItemPaging from '../../component/AdminRetrieveItemPaging';
import AdminVisitedTeamInfoListFilter from '../../component/filter/AdminVisitedTeamInfoListFilter';
import useVisitedTeamPagingInfos from '../../hook/useVisitedTeamPagingInfos';
import ChartDataUtils from '../../../../utils/ChartDataUtils';

function AdminVisitedTeamInfoIndexPage() {
    const history = useHistory();
    const location = useLocation<VisitedTeamInfoSearchCondition>();

    let condition: VisitedTeamInfoSearchCondition = location.state ? location.state : new VisitedTeamInfoSearchCondition();

    const [visitedTeamInfoSearchCondition, setVisitedTeamInfoSearchCondition] = useState<VisitedTeamInfoSearchCondition>(condition);
    const { page, setPage, pagingSize, setPagingSize, pagingCnt, paging, currentPageGroupIdx, setCurrentPageGroupIdx } = useVisitedTeamPagingInfos(
        visitedTeamInfoSearchCondition,
    );
    const { visitedTeamInfos, visitedTeamInfosCnt } = useGroupingVisitedTeamInfoRetrieve(visitedTeamInfoSearchCondition, paging);

    let title = '방문 팀 정보 <' + ChartDataUtils.getNumberFormat(visitedTeamInfosCnt) + '팀>';

    return (
        <>
            <AdminMainTitle title={title}>
                <AdminVisitedTeamInfoListFilter
                    searchCondition={visitedTeamInfoSearchCondition}
                    setVisitedTeamInfoSearchCondition={setVisitedTeamInfoSearchCondition}
                ></AdminVisitedTeamInfoListFilter>
            </AdminMainTitle>
            <AdminVisitedTeamInfosTable
                page={page}
                pagingCnt={pagingCnt}
                pagingSize={pagingSize}
                visitedTeamInfos={visitedTeamInfos}
                searchCondition={visitedTeamInfoSearchCondition}
            ></AdminVisitedTeamInfosTable>
            <AdminRetrieveItemPaging
                totalCnt={visitedTeamInfosCnt}
                pagingCnt={pagingCnt}
                pagingSize={pagingSize}
                setPagingSize={setPagingSize}
                setPage={setPage}
                currentPageNum={page}
                currentPageGroupIdx={currentPageGroupIdx}
            ></AdminRetrieveItemPaging>
        </>
    );
}

export default AdminVisitedTeamInfoIndexPage;
