import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../../moldules/index';
import logo from '../assets/img/logo.png';

function LayoutHeader() {
    const { user, isLogin, hasCenterOperAuth, hasCenterRecorderAuth } = useSelector((state: RootState) => state.userSessionReducer);
    var tourElem = document.getElementsByName('tour-element');
    document.getElementById('footer-wrap')?.remove();

    var tourSize = tourElem.length;
    var isEmpty = 0;
    if (tourSize != 0) {
        do {
            tourElem[0].remove();
            isEmpty = tourElem.length;
        } while (isEmpty != 0);
    }

    const inlineStyle = {
        fontSize: '1em',
    };

    return (
        <div className="center-top">
            <a href="/">
                <img src={logo} />
            </a>
            &nbsp;&nbsp;&nbsp;
            {isLogin && (hasCenterOperAuth || hasCenterRecorderAuth) ? <p>운동재활센터 관리자-{user?.name}</p> : <p>운동재활센터 관리자</p>}
        </div>
    );
}

export default LayoutHeader;
