import { JsonObject, JsonProperty } from "json2typescript";
import Reservation from "../reservation/Reservation";
import DateUtils from '../../utils/DateUtils';
import ScheduleAble from '../common/ScheduleAble';

@JsonObject("schedule")
class Schedule implements ScheduleAble{
    @JsonProperty("scheduleNo")
    scheduleNo?: string;
    @JsonProperty("scheduleTargetNo")
    scheduleTargetNo?: string;
    @JsonProperty("reservationNo")
    reservationNo?: string;
    @JsonProperty("reservation")
    reservation?: Reservation;
    @JsonProperty("title")
    title?: string;
    @JsonProperty("startDate")
    startDate?: string;
    @JsonProperty("endDate")
    endDate?: string;
    @JsonProperty("registeDate")
    registeDate?: string;
    @JsonProperty("modifyDate")
    modifyDate?: string;

    static getNewSchedule(scheduleTargetNo: string, reservation: Reservation|undefined, scheduleStartDay: string|undefined, scheduleEndDay: string|undefined, title: string|undefined): Schedule {
        if(reservation) {
            return this.getNewScheduleOfRelatedReservation(scheduleTargetNo, reservation, title);
        } else if(scheduleStartDay && scheduleEndDay){
            return this.getNewOnlySchedule(scheduleTargetNo, scheduleStartDay, scheduleEndDay, title);
        } else {
            throw new Error("reservation 또는 예약 시작일 종료일이 필수입니다.");
        }
    }

    private static getNewScheduleOfRelatedReservation(scheduleTargetNo: string, reservation: Reservation, title: string|undefined): Schedule {
        let newSchedule = new Schedule();
        newSchedule.scheduleTargetNo = scheduleTargetNo;
        newSchedule.reservationNo = reservation.reservationNo;
        newSchedule.title = title;
        return newSchedule;
    }

    private static getNewOnlySchedule(scheduleTargetNo: string, scheduleStartDay: string, scheduleEndDay: string, title: string|undefined): Schedule {
        let newSchedule = new Schedule();
        newSchedule.scheduleTargetNo = scheduleTargetNo;
        newSchedule.startDate = scheduleStartDay;
        newSchedule.endDate = scheduleEndDay;
        newSchedule.title = title;
        return newSchedule;
    }

    static getModifySchedule(scheduleNo: string, scheduleTargetNo: string, reservation: Reservation|undefined, scheduleStartDay: string|undefined, scheduleEndDay: string|undefined, title: string|undefined): Schedule {
        let newModifySchedule:Schedule = Schedule.getNewSchedule(scheduleTargetNo, reservation, scheduleStartDay, scheduleEndDay, title);
        newModifySchedule.scheduleNo = scheduleNo;

        return newModifySchedule;
    }

    getStartDate() {
        if(this.startDate) {
            return this.startDate.split(" ")[0];
        } else {
            return '';
        }
    }

    getStartDateTime() {
        if(this.startDate) {
            return this.startDate.split(" ")[1];
        } else {
            return '';
        }
    }

    getEndDate() {
        if(this.endDate) {
            return this.endDate?.split(" ")[0];
        } else {
            return '';
        }
    }

    getEndDateTime() {
        if(this.endDate) {
            return this.endDate?.split(" ")[1];
        } else {
            return '';
        }
    }

    getStartDateWeekDay() {
        return DateUtils.getDayOfWeek(this.startDate);
    }

    getEndDateWeekDay() {
        return DateUtils.getDayOfWeek(this.endDate);
    }
}

export default Schedule;