import React from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import AdminTable from '../../../component/admin/AdminTable';
import VisitedTeamInfoSearchCondition from '../../../domain/visitedInfo/pagam/VisitedTeamInfoSearchCondition';
import VisitedTeamInfo from '../../../domain/visitedInfo/VisitedTeamInfo';

interface AdminVisitedTeamInfosTableProps {
    page: number;
    pagingSize: number;
    pagingCnt: number;
    visitedTeamInfos?: VisitedTeamInfo[];
    searchCondition?: VisitedTeamInfoSearchCondition;
}

function AdminVisitedTeamInfosTable(props: AdminVisitedTeamInfosTableProps) {
    const location = useLocation<VisitedTeamInfoSearchCondition>();
    const history = useHistory();

    const goDetailHandler = (evt: any) => {
        let teamNo = evt.currentTarget.id;
        let stateCondition: VisitedTeamInfoSearchCondition = props.searchCondition ? props.searchCondition : new VisitedTeamInfoSearchCondition();
        let groupIdx = Math.floor(props.page / props.pagingCnt);
        stateCondition.page = props.page;
        stateCondition.groupIdx = groupIdx;

        history?.replace({
            pathname: `/admin/visited-team-info/detail/${teamNo}`,
            state: stateCondition,
        });
    };

    return (
        <AdminTable>
            <thead>
                <tr>
                    <td>No.</td>
                    <td>방문지역</td>
                    <td>팀 분류그룹</td>
                    <td>팀 분류</td>
                    <td>종목</td>
                    <td>연고지</td>
                    <td style={{ textAlign: 'left' }}>방문팀명</td>
                    {/* <td>방문지역</td>
                    <td>방문일</td>
                    <td>방문종료일</td>
                    <td>참가인원</td>
                    <td>체류기간</td> */}
                    <td>등록자</td>
                    <td>관리</td>
                </tr>
            </thead>
            <tbody>
                {props.visitedTeamInfos ? (
                    props.visitedTeamInfos.length > 0 ? (
                        props.visitedTeamInfos.map((visitedTeamInfo: VisitedTeamInfo, index: number) => {
                            return (
                                <tr key={index}>
                                    <td>{props.page * props.pagingSize + (index + 1)}</td>
                                    <td>{visitedTeamInfo.regionCode?.name}</td>
                                    <td>{visitedTeamInfo.visitedTeam?.belongCode?.name}</td>
                                    <td>{visitedTeamInfo.visitedTeam?.classificationCode?.name}</td>
                                    <td style={{ width: '150px' }}>{visitedTeamInfo.visitedTeam?.sportCode?.name}</td>
                                    <td>{visitedTeamInfo.visitedTeam?.regionCode?.name}</td>
                                    <td style={{ textAlign: 'left', width: '200px' }}>{visitedTeamInfo.visitedTeam?.name}</td>
                                    {/* <td>
                                        {visitedTeamInfo.parentRegionCode?.name}-{visitedTeamInfo.regionCode?.name}
                                    </td>
                                    <td>{visitedTeamInfo.visitedStartDate}</td>
                                    <td>{visitedTeamInfo.visitedEndDate}</td>
                                    <td>{visitedTeamInfo.visitedMemberCnt}명</td>
                                    <td>{visitedTeamInfo.visitedDayCnt}일</td> */}
                                    <td>{visitedTeamInfo.register?.name}</td>
                                    <td>
                                        <button className="bg-light-blue" onClick={goDetailHandler} id={visitedTeamInfo.visitedTeamNo}>
                                            <i className="fas fs-search"></i>상세보기
                                        </button>
                                    </td>
                                </tr>
                            );
                        })
                    ) : (
                        <tr>
                            <td colSpan={10}>
                                <h5>조회 조건에 맞는 내용이 없습니다.</h5>
                            </td>
                        </tr>
                    )
                ) : (
                    <></>
                )}
            </tbody>
        </AdminTable>
    );
}

export default AdminVisitedTeamInfosTable;
