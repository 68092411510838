import React, { useState, useEffect } from 'react';
import QnA from '../../../../../domain/QnA';
import TargetPerson from '../../../../../domain/TargetPerson';
import NDI from '../../../questionnaires/forms/1NDI';
import TopsForm from '../../../questionnaires/forms/2TopsForm';
import SpadiForm from '../../../questionnaires/forms/3SpadiForm';
import StressCheckForm from '../../../questionnaires/forms/4StressCheckForm';
import WHOQOL from '../../../questionnaires/forms/5WHOQQOL';
import FABQ from '../../../questionnaires/forms/6FABQ';
import IESRK from '../../../questionnaires/forms/7IESRK';
import { JsonConvert } from 'json2typescript';
import AnswerItem from '../../../../../domain/AnswerItem';
import FillInQuestionnarie from '../../../../../domain/FillInQuestionnarie';
import RehabTargetHistory from '../../../../../domain/RehabTargetHistory';
import CheckCondition from '../../../../../domain/recording/CheckCondition';

interface IProps {
    qnaList: QnA[];
    targetPerson: TargetPerson;
    selectedItem: RehabTargetHistory;
}

const QuestionnaireResult = (props: IProps) => {
    let qnaTitles = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J'];
    let itemNumbers = ['24EA', '2460', '2461', '2462', '2463', '2464', '2465', '2466', '2467', '2468', '2469', '246A'];

    const qnaList = props.qnaList;
    const conditions = props.selectedItem.conditions ? JSON.parse(props.selectedItem.conditions) : new CheckCondition();
    const vases = conditions?.vas;

    const generateForms = (questions: any, answerStr: string | undefined, key: string) => {
        let forms = <></>;
        let answers: [] | undefined;
        let answer: { questionNum: number; itemNoOfAnswer: number } = { questionNum: -1, itemNoOfAnswer: -1 };
        let selectedAnswer: string = '';

        let answerItems;
        if (answerStr) {
            const fillInQuestionnarie = new FillInQuestionnarie();
            fillInQuestionnarie.answer = answerStr;
            answerItems = fillInQuestionnarie.getAnswerItemsMap();
            answers = JSON.parse(answerStr);
        }

        if (key === 'NDI') {
            forms = <NDI resultPrintable={true} answerItems={answerItems}></NDI>;
        } else if (key === 'Spadi') {
            forms = <SpadiForm resultPrintable={true} answerItems={answerItems}></SpadiForm>;
        } else if (key === 'StressCheckForm') {
            forms = <StressCheckForm resultPrintable={true} answerItems={answerItems}></StressCheckForm>;
        } else if (key === 'WhoQ') {
            forms = <WHOQOL resultPrintable={true} answerItems={answerItems}></WHOQOL>;
        } else if (key === 'FABQ') {
            forms = <FABQ resultPrintable={true} answerItems={answerItems}></FABQ>;
        } else if (key === 'IESRK') {
            forms = <IESRK resultPrintable={true} answerItems={answerItems}></IESRK>;
        } else if (key === 'Tops') {
            forms = <TopsForm resultPrintable={true} answerItems={answerItems}></TopsForm>;
        }

        return forms;
    };

    return (
        <>
            <div id="questionnaire" key={'quest'}>
                <h1 className="center">1. 설문결과</h1>
                {qnaList.map((item, idx) => {
                    let questions;
                    let answerStr = item.answer;
                    let questionnaireKey = item.questionnaireKey;

                    if (item.questions) questions = JSON.parse(item.questions);
                    if (item.answerNo != 0) {
                        // 답안이 포함된 Form 만 생성
                        return (
                            <div id={'questionnaire_' + idx} key={idx}>
                                <h2 className="center2">
                                    {qnaTitles[idx]}. {item.displayTitle}
                                </h2>
                                <div className="center-center">{item.title}</div>
                                <p>{item.description}</p>
                                <div className="tab-story-number">{generateForms(questions, answerStr, questionnaireKey)}</div>
                                {/* <!--//tab-story-number--> */}
                            </div>
                        );
                    }
                })}
            </div>
            {/* <!--//sec01--> */}

            <div id="checkConditions" key={'check'}>
                <h1 className="center">1. VAS 통증 부위 / 강도체크</h1>
                <table className="center-check-resulttable" cellPadding="0px" cellSpacing="0px">
                    <tbody>
                        {vases?.map((vas: any, idx: number) => {
                            let recording = vas.recording;
                            let vasPart = vas.partName;
                            return (
                                <tr key={idx}>
                                    <td>{vas.seq}</td>
                                    <td>
                                        <input type="text" defaultValue={vasPart} placeholder="부위선택" disabled />
                                    </td>
                                    <td className="center-bg-gray">1회차</td>
                                    <td>
                                        <input type="text" defaultValue={recording[0]} />
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                <h1 className="center">2. 상태확인 정보</h1>
                <div id="checkConditions_1">
                    <div className="flex-basic">
                        <div className="center-half">
                            <div className="center-popup-check-in">
                                <h2 className="center">자각증상</h2>
                                <textarea rows={3} value={conditions.ss} onChange={() => {}} />
                            </div>
                        </div>
                        <div className="center-half">
                            <div className="center-popup-check-in">
                                <h2 className="center">병력</h2>
                                <textarea rows={3} value={conditions.mh} onChange={() => {}} />
                            </div>
                        </div>
                    </div>
                    <div className="flex-basic">
                        <div className="center-half">
                            <div className="center-popup-check-in">
                                <h2 className="center">가족력</h2>
                                <textarea rows={3} value={conditions.fh} onChange={() => {}} />
                            </div>
                        </div>
                        <div className="center-half">
                            <div className="center-popup-check-in">
                                <h2 className="center">생활습관</h2>
                                <textarea rows={3} value={conditions.ha} onChange={() => {}} />
                            </div>
                        </div>
                    </div>
                    <div className="flex-basic">
                        <div className="center-half">
                            <div className="center-popup-check-in">
                                <h2 className="center">선수반응상태</h2>
                                <textarea rows={3} value={conditions.rs} onChange={() => {}} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default QuestionnaireResult;
