import React, { useEffect, useState } from 'react';
import Code from '../../../domain/code/Code';
import { CodeType } from '../../../domain/code/CodeType';
import useMultiSelectHook from './hook/useMuliSelectHook';

interface MultiSelectProps {
    codeType: CodeType;
    title: string;
    codes: Code[];
}

function MultiSelect(props: MultiSelectProps) {
    const initDisplayCnt = 5;
    const [codes, setCodes] = useState(new Array<Code>());
    const {
        selectedCnt,
        allConditionCheck, conditionCheck,
        selectedEventHandler, selectedClearEventHandler
    } = useMultiSelectHook(props.codeType);

    useEffect(() => {
        setCodes(props.codes.slice(0, initDisplayCnt));
    }, []);

    return (
        <div className="type">
            <p className="search-tit">{props.title}</p>
            <ul className="two">
                <li>
                    <label>
                        <input checked={allConditionCheck()} onChange={() => selectedClearEventHandler()} type="checkbox" name="nonId" id="nonIdY"/>전체
                    </label>
                </li>
                {
                    codes ?
                        codes.map((code, index) => {
                            return (
                                <li key={index}>
                                    <label>
                                        <input checked={conditionCheck(code.codeId)} onChange={() => selectedEventHandler(code.codeId)} type="checkbox" value={code.codeId}/>{code.name}
                                    </label>
                                </li>
                            )
                        })
                        :
                        <></>
                }
            </ul>
            <div className="clear"></div>
            <p onClick={() => {
                if(codes?.length > initDisplayCnt) {
                    setCodes(props.codes.slice(0,initDisplayCnt));
                } else {
                    setCodes(props.codes);
                }
            }}><a><span className="blue-line">{props.title} 모두보기 ({selectedCnt})</span></a> <strong>∨</strong></p>
        </div>
    )
}

export default MultiSelect;

// interface MultiSelectByInfraCategoryProps {
//     codeType: CodeType;
//     title: string;
//     infraCategories: InfraCategory[];
//     addFilter: () => void;
//     deleteFilter: () => void;
//     setInfraCategoryNo: (no: number) => void;
// }

// export function MultiSelectByInfraCategory(props: MultiSelectByInfraCategoryProps) {
//     const dispatch = useDispatch();
//     const searchConditionMap = useSelector((state: RootState) => state.searchCondition.searchConditionMap);
//     const conditionMap:any = searchConditionMap[props.codeType];

//     const selectedEventHandler = (codeId: number) => {
//         dispatch(changeConditionAction(codeId, props.codeType));
//     }

//     const selectedClearEventHandler = () => {
//         dispatch(clearConditionAction(props.codeType));
//     }

//     return (
//         <div className="type">
//             <p className="search-tit">{props.title}</p>
//             <ul className="two">
//                 <li>
//                     <label>
//                         <input onChange={selectedClearEventHandler} type="checkbox" value='all' checked={allConditionCheck(conditionMap)}/>전체
//                     </label>
//                 </li>
//                 {
//                     props.infraCategories.map((infraCategory, index) => {
//                         return (
//                             <li key={index}>
//                                 <label>
//                                     <input onChange={() => selectedEventHandler(infraCategory.infraCategoryNo)} 
//                                             type="checkbox" 
//                                             checked={conditionCheck(conditionMap, infraCategory.infraCategoryNo)}/>{infraCategory.name}
//                                 </label>
//                             </li>
//                         )
//                     })
//                 }
//             </ul>
//             <div className="clear"></div>
//         </div>
//     )
// }
