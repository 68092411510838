import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import ActionComponent from '../../ActionComponent';
import TargetPerson from '../../../../domain/TargetPerson';
import Prescription from '../../../../domain/Prescription';
import BasalExerciseFormComponent from './prescriptionForms/BasalExerciseFormComponent';
import ConditioningFormComponent from './prescriptionForms/ConditioningFormComponent';
import ByTypePrescriptionFormComponent from './prescriptionForms/ByTypeFormComponent';
import ByPartPrescriptionFormComponent from './prescriptionForms/ByPartFormComponent';
import OpinionFormComponent from './prescriptionForms/OpinionFormComponent';
import { BasalPrescription } from '../../../../domain/prescription/BasalPrescription';
import ConditioningPrescription from '../../../../domain/prescription/ConditioningPrescription';
import Opinions from '../../../../domain/prescription/Opinions';
import PrescriptionServiceImpl from '../../../../service/impl/PrescriptionServiceImpl';
import ItemsPrescription from '../../../../domain/prescription/ItemsPrescription';
import RehabTargetHistory from '../../../../domain/RehabTargetHistory';

interface IProps {
    targetPerson: TargetPerson;
    selectedItem: RehabTargetHistory;
}
const prescriptionService = new PrescriptionServiceImpl();

export default function PrescriptionFormComponent(props: IProps) {
    const targetPerson: TargetPerson = props.targetPerson ? props.targetPerson : new TargetPerson();
    const selectedItem: RehabTargetHistory = props.selectedItem;
    const [prescription, setPrescription] = useState<Prescription>(new Prescription());

    useEffect(() => {
        if (selectedItem) {
            let basalPrescription = selectedItem.basalExercisePrescription
                ? JSON.parse(selectedItem.basalExercisePrescription)
                : new BasalPrescription();
            let conditioning = selectedItem.conditioningPrescription
                ? JSON.parse(selectedItem.conditioningPrescription)
                : new ConditioningPrescription();
            let itemPrescription = selectedItem.itemPrescription ? JSON.parse(selectedItem.itemPrescription) : new ItemsPrescription();
            let bodyPrescription = selectedItem.bodyPrescription ? JSON.parse(selectedItem.bodyPrescription) : new ItemsPrescription();
            let opinions = selectedItem.opinions ? JSON.parse(selectedItem.opinions) : new Opinions();

            let prescription = new Prescription();

            prescription.basalExercisePrescription = basalPrescription;
            prescription.conditioningPrescription = conditioning;
            prescription.itemPrescription = itemPrescription;
            prescription.bodyPrescription = bodyPrescription;
            prescription.opinions = opinions;

            setPrescription(prescription);
        }
    }, [selectedItem]);

    const tabHandler = (evt: any) => {
        let target = evt.target.id;
        let actions = ActionComponent();

        if (target === 'basisPrescription') {
            actions.tab1();
        } else if (target === 'conditioning') {
            actions.tab2();
        } else if (target === 'byType') {
            actions.tab3();
        } else if (target === 'byPart') {
            actions.tab4();
        } else if (target === 'opinions') {
            actions.tab5();
        }
    };

    return (
        <>
            <div className="center-popup-list-box2">
                <div className="tab-menu">
                    <ul>
                        <li id="tabon1" className="tabon">
                            <button id="basisPrescription" onClick={tabHandler}>
                                기본운동처방
                            </button>
                        </li>
                        <li id="tabon2">
                            <button id="conditioning" onClick={tabHandler}>
                                컨디셔닝
                            </button>
                        </li>
                        <li id="tabon3">
                            <button id="byType" onClick={tabHandler}>
                                운동종목별
                                <br />
                                운동처방
                            </button>
                        </li>
                        <li id="tabon4">
                            <button id="byPart" onClick={tabHandler}>
                                손상부위별
                                <br />
                                운동처방
                            </button>
                        </li>
                        <li id="tabon5">
                            <button id="opinions" onClick={tabHandler}>
                                소견 내용
                            </button>
                        </li>
                    </ul>
                </div>

                <div className="tab-complete-story">
                    <div id="tabstory1">
                        {/* 기본운동 처방 작성 Form */}
                        <BasalExerciseFormComponent prescription={prescription}></BasalExerciseFormComponent>
                    </div>

                    <div id="tabstory2">
                        {/* 컨디셔닝 처방 작성 Form */}
                        <ConditioningFormComponent prescription={prescription}></ConditioningFormComponent>
                    </div>

                    <div id="tabstory3">
                        {/* 운동종목 별 처방 작성 Form */}
                        <ByTypePrescriptionFormComponent prescription={prescription}></ByTypePrescriptionFormComponent>
                    </div>

                    <div id="tabstory4">
                        {/* 신체 부위 별 처방 작성 Form */}
                        <ByPartPrescriptionFormComponent prescription={prescription}></ByPartPrescriptionFormComponent>
                    </div>

                    <div id="tabstory5">
                        {/* 처방 소견 작성 Form */}
                        <OpinionFormComponent prescription={prescription}></OpinionFormComponent>
                    </div>
                </div>
            </div>
        </>
    );
}
