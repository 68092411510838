import React from 'react';
import { Link } from 'react-router-dom';
import CommonHeader from '../../common/CommonHeader';
import SiteMainNav from '../../../component/nav/SiteMainNav';
import LoginState from '../../../component/header/LoginState';
import MobileMenuContainer from '../../../component/header/MobileMenuContainer';
import CommonSearchBar from '../../../component/search/CommonSearchBar';

function LoginHeader() {
    return (
        <section className="main-visual">
            <CommonHeader></CommonHeader>
            <div className="sub_visual sub_visual1">
                <div className="subTop">
                    <h2>로그인</h2>
                    <span className="line"></span>
                    <p>경남스포츠산업육성지원센터 로그인</p>
                </div>
            </div>
        </section>
    );
}

export default LoginHeader;
