import { useEffect, useState } from "react";

function useConfirmedPasswordValidation(confirmedPassword?: string, password?: string) {
    const [confirmedPasswordValidation, setConfirmedPasswordValidation] = useState<boolean>();
    
    useEffect(() => {
        if(confirmedPassword) {
            if(password == confirmedPassword) {
                setConfirmedPasswordValidation(true);
            } else {
                setConfirmedPasswordValidation(false);
            }
        }   
    }, [confirmedPassword]);

    return confirmedPasswordValidation;
}

export default useConfirmedPasswordValidation;