import React from 'react';

export default class TestComponent2 extends React.Component {
    constructor(props: Object) {
        console.log("???")
        super(props);
    }

    render() {
        return (
            <div>
                <h1>Test Component 2</h1>
            </div>
        )
    }
}