import Code from '../../../../domain/code/Code';
import CodeSelectFilter from './element/CodeSelectFilter';
import ReservationSearchCondition from '../../../../domain/reservation/param/ReservationSearchCondition';
import { useState } from 'react';
interface AdminSportInfraReservationInfosFilterProps {
    reservationCodes?: Code[];
    regionCodes?: Code[];
    setReservationSearchCondition: (searchCondition?: ReservationSearchCondition) => void;
}

function AdminSportInfraReservationInfosFilter(props: AdminSportInfraReservationInfosFilterProps) {
    const [searchWord, setSearchWord] = useState<string>();
    const [regionCodeIdOfSearch, setRegionCodeIdOfSearch] = useState<number>();
    const [reservationCodeIdOfSearch, setReservationCodeOfSearch] = useState<number>();
    return (
        <div className="flex-basic">
            <div className="member-join">
                <CodeSelectFilter
                    codes={props.reservationCodes}
                    allOptionTitle={'예약상태전체'}
                    setCodeIdOfSearchTypeNum={setReservationCodeOfSearch}
                ></CodeSelectFilter>
                <CodeSelectFilter
                    codes={props.regionCodes}
                    allOptionTitle={'지역전체'}
                    setCodeIdOfSearchTypeNum={setRegionCodeIdOfSearch}
                ></CodeSelectFilter>
            </div>
            <div className="member-search">
                <input 
                    onChange={(event) => {
                        setSearchWord(event.currentTarget.value);
                    }}
                    type="text" placeholder="예약시설명을 입력하세요." value={searchWord}/>
                <button className="bg-blue white round"
                    onClick={() => {
                        const reservationSearchCondition = new ReservationSearchCondition();
                        if(regionCodeIdOfSearch)
                            reservationSearchCondition.regionCodeIds = String(regionCodeIdOfSearch);
                        if(reservationCodeIdOfSearch) {
                            reservationSearchCondition.reservationCodeIds = String(reservationCodeIdOfSearch);
                        }
                        if(searchWord) {
                            reservationSearchCondition.searchWord = searchWord;
                        }

                        props.setReservationSearchCondition(reservationSearchCondition);
                    }}
                >
                    <i className="fas fa-search"></i>검색
                </button>
            </div>
        </div>
    )
}

export default AdminSportInfraReservationInfosFilter;