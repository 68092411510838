import React, { useEffect, useState } from 'react';
import SpecialPhysical from '../../../../../domain/recording/SpecialPhysical';
interface IProps {
    handler: (evt: any) => void;
    status?: SpecialPhysical;
}

export default function FormCheckerForm(props: IProps) {
    const resourceAPIURL = process.env.REACT_APP_RESOURCE_API_HOST;
    const [formChecker, setFormChecker] = useState<any>(new SpecialPhysical().formChecker);

    useEffect(() => {
        if (props.status) setFormChecker(props.status.formChecker);
    }, [props.status]);

    let imageElem = <></>;

    if (formChecker && formChecker.uploadedPomFile != '')
        imageElem = <img id="uploadedImages" src={resourceAPIURL + formChecker.uploadedPomFile} width="580" />;

    return (
        <>
            <div className="center-popup-title">
                <h1 className="center">폼체커</h1>
            </div>

            {/* <div className="center-special-physical-btn">
                <label htmlFor={'uploadedPomFile'} className="center-label-up">
                    결과지업로드
                </label>
                <input
                    type="file"
                    id="uploadedPomFile"
                    name="formChecker"
                    onChange={props.handler}
                    style={{ display: 'none' }}
                    accept=".gif, .jpg, .png"
                />
            </div> */}
            <div className="flex-basic">
                <div className="center-half">
                    <h2 className="center2">Shoulder</h2>
                    <div className="center-bg-gray flex">
                        <div className="center-bg-blue">Left</div>
                        <input
                            className="center-input-gray center-w200"
                            type="text"
                            id="shuolderL"
                            value={formChecker.shuolderL}
                            onChange={props.handler}
                            name="formChecker"
                        />
                        <label className="center-label">˚</label>
                    </div>
                </div>
                <div className="center-half">
                    <h2 className="center2">&nbsp;</h2>
                    <div className="center-bg-gray flex">
                        <div className="center-bg-blue">Right</div>
                        <input
                            className="center-input-gray center-w200"
                            type="text"
                            id="shuolderR"
                            value={formChecker.shuolderR}
                            onChange={props.handler}
                            name="formChecker"
                        />
                        <label className="center-label">˚</label>
                    </div>
                </div>
            </div>
            <div className="flex-basic">
                <div className="center-half">
                    <h2 className="center2">Pelvis</h2>
                    <div className="center-bg-gray flex">
                        <div className="center-bg-blue">Left</div>
                        <input
                            className="center-input-gray center-w200"
                            type="text"
                            id="pelvisL"
                            value={formChecker.pelvisL}
                            onChange={props.handler}
                            name="formChecker"
                        />
                        <label className="center-label">˚</label>
                    </div>
                </div>
                <div className="center-half">
                    <h2 className="center2">&nbsp;</h2>
                    <div className="center-bg-gray flex">
                        <div className="center-bg-blue">Right</div>
                        <input
                            className="center-input-gray center-w200"
                            type="text"
                            id="pelvisR"
                            value={formChecker.pelvisR}
                            onChange={props.handler}
                            name="formChecker"
                        />
                        <label className="center-label">˚</label>
                    </div>
                </div>
            </div>
            <div className="flex-basic">
                <div className="center-half">
                    <h2 className="center2">Knee</h2>
                    <div className="center-bg-gray flex">
                        <div className="center-bg-blue">Left</div>
                        <input
                            className="center-input-gray center-w200"
                            type="text"
                            id="kneeL"
                            value={formChecker.kneeL}
                            onChange={props.handler}
                            name="formChecker"
                        />
                        <label className="center-label">˚</label>
                    </div>
                </div>
                <div className="center-half">
                    <h2 className="center2">&nbsp;</h2>
                    <div className="center-bg-gray flex">
                        <div className="center-bg-blue">Right</div>
                        <input
                            className="center-input-gray center-w200"
                            type="text"
                            id="kneeR"
                            value={formChecker.kneeR}
                            onChange={props.handler}
                            name="formChecker"
                        />
                        <label className="center-label">˚</label>
                    </div>
                </div>
            </div>
            <div className="flex-basic">
                <div className="center-half">
                    <h2 className="center2">Ankle</h2>
                    <div className="center-bg-gray flex">
                        <div className="center-bg-blue">Left</div>
                        <input
                            className="center-input-gray center-w200"
                            type="text"
                            id="ankleL"
                            value={formChecker.ankleL}
                            onChange={props.handler}
                            name="formChecker"
                        />
                        <label className="center-label">˚</label>
                    </div>
                </div>
                <div className="center-half">
                    <h2 className="center2">&nbsp;</h2>
                    <div className="center-bg-gray flex">
                        <div className="center-bg-blue">Right</div>
                        <input
                            className="center-input-gray center-w200"
                            type="text"
                            id="ankleR"
                            value={formChecker.ankleR}
                            onChange={props.handler}
                            name="formChecker"
                        />
                        <label className="center-label">˚</label>
                    </div>
                </div>
            </div>
            <div className="flex-basic">
                <div className="center-half">
                    <h2 className="center2">Lowback</h2>
                    <div className="center-bg-gray flex">
                        <div className="center-bg-blue-big">Flexion</div>
                        <input
                            className="center-input-gray center-w50"
                            type="text"
                            id="loinFlexion"
                            value={formChecker.loinFlexion}
                            onChange={props.handler}
                            name="formChecker"
                        />
                        <label className="center-label">˚</label>
                    </div>
                </div>
                <div className="center-half">
                    <h2 className="center2">&nbsp;</h2>
                    <div className="center-bg-gray flex">
                        <div className="center-bg-blue-big">Extension</div>
                        <input
                            className="center-input-gray center-w50"
                            type="text"
                            id="loinExtension"
                            value={formChecker.loinExtension}
                            onChange={props.handler}
                            name="formChecker"
                        />
                        <label className="center-label">˚</label>
                    </div>
                </div>
            </div>

            <div className="flex-basic">
                <div className="center-half">
                    <div className="center-bg-gray flex">
                        <div className="center-bg-blue-big">Lateral Flexion Left</div>
                        <input
                            className="center-input-gray center-w50"
                            type="text"
                            id="loinLaterFL"
                            value={formChecker.loinLaterFL}
                            onChange={props.handler}
                            name="formChecker"
                        />
                        <label className="center-label">˚</label>
                    </div>
                </div>
                <div className="center-half">
                    <div className="center-bg-gray flex">
                        <div className="center-bg-blue-big">Lateral Flexion Right</div>
                        <input
                            className="center-input-gray center-w50"
                            type="text"
                            id="loinLaterFR"
                            value={formChecker.loinLaterFR}
                            onChange={props.handler}
                            name="formChecker"
                        />
                        <label className="center-label">˚</label>
                    </div>
                </div>
            </div>

            <div id="uploadedPomImages" className="center-mauto">
                {imageElem}
            </div>
        </>
    );
}
