import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Schedule from '../../../domain/schedule/Schedule';
import useTeamScheduleDelete from '../../../hook/schedule/useTeamScheduleDelete';
import Infra from '../../../domain/infra/Infra';
import Reservation from '../../../domain/reservation/Reservation';

interface ScheduleCardProps {
    schedule: Schedule;
}
function ScheduleCard(props: ScheduleCardProps) {
    const [startDate, setStartDate] = useState<string>();
    const [endDate, setEndDate] = useState<string>();
    const [deleteScheduleNo, setDeleteScheduleNo] = useState<string>();
    const deleteSuccess = useTeamScheduleDelete(deleteScheduleNo);
    const deleteScheduleDivElement = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if(props.schedule) {
            if(props.schedule.reservation) {
                let reservation = Object.setPrototypeOf(props.schedule.reservation, Reservation.prototype);
                setStartDate(reservation.getStartDate());
                setEndDate(reservation.getEndDate());
            } else {
                setStartDate(props.schedule.getStartDate());
                setEndDate(props.schedule.getEndDate());
            }
        }
    }, [props.schedule]);

    useEffect(() => {
        if(deleteSuccess) {
            if(deleteScheduleDivElement.current != null) {
                deleteScheduleDivElement.current.remove();
            }   
        }
    }, [deleteSuccess]);
    return (
        <div ref={deleteScheduleDivElement} className="my-in">
            <div className="my-in-info">
                <div className="my-in-info-box">
                    <Link to={`/mypage/team-admin/schedule/${props.schedule.scheduleNo}/detail`}>
                        <p>
                            <span className="blue">{props.schedule.reservation?.reservationStateCode?.name}</span>
                        </p>
                        <p>{props.schedule.title}</p>
                        <p>
                            일정 
                            <span>{startDate}</span>
                            ~
                            <span>{endDate}</span>
                            <br></br>
                            <span>등록일 {props.schedule.registeDate}</span>
                        </p>
                    </Link>
                    <button 
                        onClick={() => {
                            setDeleteScheduleNo(props.schedule.scheduleNo);
                        }}
                    >일정삭제</button>
                    &nbsp;
                    <Link to={`/mypage/team-admin/schedule/${props.schedule.scheduleNo}/modify`}>
                        <button>일정수정</button>
                    </Link>
                </div>
            </div>
            {
                props.schedule.reservation ?
                (
                    <div className="my-in-img">
                        {
                            props.schedule.reservation?.parentInfra?
                            <img width={73} height={75} src={Object.setPrototypeOf(props.schedule.reservation.parentInfra, Infra.prototype).getMainAttachFile()}/>
                            :
                            <img width={73} height={75} src={Object.setPrototypeOf(props.schedule.reservation.infra, Infra.prototype).getMainAttachFile()}/>
                        }
                    </div>
                ):
                <></>
            }
        </div>
    )
}

export default ScheduleCard;