import React from 'react';
function RehabStatisticsRehabInfoByTimeTab() {
    return (
        <div id="list-from">
            <div className="flex-basic">
                <div className="center-tabbt-s flex">
                    <a
                        href="avascript:void(0)"
                        id="tab-btn7"
                        className="on"
                        /* onclick="yearOpen2()" */
                    >
                        년도별
                    </a>
                    <a
                        href="avascript:void(0)"
                        id="tab-btn8"
                        /* onclick="quarterOpen2()" */
                    >
                        분기별
                    </a>
                    <a
                        href="avascript:void(0)"
                        id="tab-btn9"
                        /* onclick="monthOpen2()" */
                    >
                        월별
                    </a>
                </div>
                {/* <!--//center-tabbt-s--> */}
                <div>
                    <button className="center-button-esave">
                        <i className="fas fa-file-excel"></i> 엑셀파일 저장
                    </button>
                </div>
            </div>
            {/* <!--//flex-basic--> */}

            <div id="list-tab-in4" className="list-tab-in">
                년도별 운동재활 인원
            </div>
            {/* <!--//list-tab-in1--> */}
            <div id="list-tab-in5" className="list-tab-in">
                분기별 운동재활 인원
            </div>
            {/* <!--//list-tab-in2--> */}
            <div id="list-tab-in6" className="list-tab-in">
                월별 운동재활 인원
            </div>
            {/* <!--//list-tab-in3--> */}
        </div>
    )
}

export default RehabStatisticsRehabInfoByTimeTab;