import React from 'react';
import SearchCategoryDisplay from '../../component/search/SearchCategoryDisplay';
import SearchCnt from '../../component/search/SearchCnt';
import TeamSearchList from '../../component/team/TeamSearchList';
import Team from '../../domain/team/Team';

interface SearchOfTeamContainerProps {
    searchedTeams?: Team[];
    searchCnt: number;
}


function SearchOfTeamContainer(props: SearchOfTeamContainerProps) {

    return (
        <>
            <div className="output-cate">
                <div className="cate_left">
                    <SearchCategoryDisplay></SearchCategoryDisplay>
                </div>
            </div>
            <div className="clear"></div>
            <div className="output-left">
                <TeamSearchList searchedTeams={props.searchedTeams}></TeamSearchList>
            </div>
        </>
    )
}

export default SearchOfTeamContainer;