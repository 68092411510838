import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import AdminFormTitle from '../../../../component/admin/AdminFormTitle';
import AdminMainTitle from '../../../../component/admin/AdminMainTitle';
import AdminTeamNav from '../../nav/AdminTeamNav';

interface MatchParams {
    teamNo: string;
    url: string;
}

function AdminTeamStoryContainer({match} : RouteComponentProps<MatchParams>) {
    const { teamNo } = match.params;
    const { url } = match;

    return (
        <>
        <AdminFormTitle
            title="팀 스토리"
            tab={<AdminTeamNav teamNo={teamNo} url={url}></AdminTeamNav>}
        />
        </>
    )
}

export default AdminTeamStoryContainer;