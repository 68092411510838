import React, { useState, useEffect } from 'react';
import Infra from '../../domain/infra/Infra';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../moldules/index';
import InfraServiceImpl from '../../service/infra/impl/InfraServiceImpl';
import { InfraSearchList } from '../infra/InfraSearchList';
import { renewInfraAction } from '../../moldules/InfraList';

import InfraSearchMap from '../infra/InfraSearchMap';

const infraService = new InfraServiceImpl();

interface RightSearcherProps {
    title: string;
    parentInfraCategoryNo: number;
}

function infraSearchRender(infras: Infra[], viewType: string) {
    if (viewType === 'list') {
        return <InfraSearchList searchedInfras={infras}></InfraSearchList>;
    } else if (viewType === 'map') {
        return <InfraSearchMap searchedInfras={infras}></InfraSearchMap>;
    }
}

function RightSearcher(props: RightSearcherProps) {
    // const filterItem = useSelector((state: RootState) => state.filterItemAction);
    const dispatch = useDispatch();
    const infras: Infra[] = useSelector((state: RootState) => state.infraList.infras);
    const filterTest: any = useSelector((state: RootState) => state.filterItemAction);
    const [viewType, setViewType] = useState('list');

    useEffect(() => {
        infraService.getInfraOfParentCategoryNo(props.parentInfraCategoryNo).then((infras) => {
            dispatch(renewInfraAction(infras));
        });
    }, []);

    return (
        <>
            <section className="search-wrap search-right">
                <div className="search-in">
                    <div className="output-tit">
                        <span>추천 {props.title}</span>({infras.length})
                    </div>
                    <div className="output-cate">
                        <div className="cate-left"></div>

                        <div className="cate-right">
                            <ul className="select02">
                                <li
                                    onClick={() => {
                                        setViewType('list');
                                    }}
                                    className={viewType == 'list' ? 'on' : ''}
                                >
                                    <div className={viewType == 'list' ? 'on' : ''}>
                                        <img src={viewType == 'list' ? '/assets/image/icon-list-on.png' : '/assets/image/icon-list.png'}></img>
                                        <a>목록</a>
                                    </div>
                                </li>
                                <li onClick={() => setViewType('map')} className={viewType == 'map' ? 'on' : ''}>
                                    <div className={viewType == 'map' ? 'on' : ''}>
                                        <a>지도</a>
                                        <img src={viewType == 'map' ? '/assets/image/icon-map-on.png' : '/assets/image/icon-map.png'}></img>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="clear"></div>
                    <div className="output-left">{infraSearchRender(infras, viewType)}</div>
                </div>
            </section>
        </>
    );
}

export default RightSearcher;
