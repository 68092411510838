import React, { useEffect, useState } from 'react';
import { useParams, useHistory, useLocation, Link } from 'react-router-dom';

import Layout from '../layouts/Layout';
import Header from '../layouts/Header';
import SideBar from '../layouts/SideBar';

import pagination from './components/PaginationComponent';

import '../assets/css/center.css';
import '../assets/css/animate.min.css';
import { useSelector } from 'react-redux';
import { RootState } from '../../../moldules/index';
import UserSearchCondition from '../../../domain/user/param/UserSearchCondition';
import RoleGroupType from '../../../domain/user/RoleGroupType';
import useUserRetrieveBySearchCondition from '../../../hook/user/useUserRetrieveBySearchCondition';
import useRehabCenterPaging from '../hook/useRehabCenterPaging';
import useReoleRetrieve from '../../../hook/code/useRoleRetrieve';
import RoleSearchCondition from '../../../domain/code/param/RoleSearchCondition';
import RehabManageUserTable from '../components/manageUser/RehabManageUserTable';

const menuTitle: string = '* 센터 계정 관리';
const title: string = '계정관리';

function RehabManageUserComponent(props: any) {
    const { hasCenterOperAuth } = useSelector((state: RootState) => state.userSessionReducer);
    const params: any = useParams();
    const location = useLocation();
    const history = useHistory();

    let path = location.pathname;
    const centerKey = params.centerId;

    const { page, setPage, pagingSize, setPagingSize, pagingCnt, paging } = useRehabCenterPaging();

    const [userSearchCondition, setUserSearchCondition] = useState<UserSearchCondition>();
    const [roleSearchConditon, setRoleSearchCondition] = useState<RoleSearchCondition>();
    const { users, usersCnt } = useUserRetrieveBySearchCondition(userSearchCondition, paging);
    const { roles } = useReoleRetrieve(roleSearchConditon);

    useEffect(() => {
        const initUserSearchCondition = new UserSearchCondition();
        initUserSearchCondition.roleGroupNames = `${RoleGroupType.CENTER}`;
        const initRoleSearchCondition = new RoleSearchCondition();
        initRoleSearchCondition.roleGroupNos = String(3);

        setUserSearchCondition(initUserSearchCondition);
        setRoleSearchCondition(initRoleSearchCondition);
    }, []);

    // const approveHandler = useSignupApprove()

    const addItemElem = () => {
        if (hasCenterOperAuth) {
            return (
                <Link to={`/rehab/manage/user/add-user/${centerKey}`}>
                    <button className="center-button-adminplus">
                        <i className="fas fa-user-plus"></i> 관리자추가
                    </button>
                </Link>
            );
        } else {
            return <></>;
        }
    };
    const filters = () => {
        return (
            <>
                <button
                    className="btn"
                    style={{ marginTop: '5px' }}
                    onClick={() => {
                        history.push({
                            pathname: '/rehab/manage/user/batch',
                        });
                    }}
                >
                    사용자 일괄 관리
                </button>
                <div className="center-bnt-right3 flex">
                    <div className="center-select-box">
                        <select className="center-select">
                            <option>전체</option>
                            <option>이름</option>
                            <option>생년월일</option>
                            <option>소속팀</option>
                            <option>운동종목</option>
                        </select>
                        <div className="center-search">
                            <input className="center-input" placeholder="검색어를 입력하세요" />
                            <button className="btn">
                                <i className="fas fa-search"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </>
        );
    };

    return (
        <Layout
            header={<Header></Header>}
            sideBar={<SideBar name={path}></SideBar>}
            path={path}
            naviTitle={title}
            menuTitle={menuTitle}
            filter={filters()}
            pagination={pagination((evt: any) => {}, 0, 0, 0)}
            addItemElem={addItemElem()}
        >
            <RehabManageUserTable hasCenterOperAuth={hasCenterOperAuth} users={users} roles={roles}></RehabManageUserTable>
        </Layout>
    );
}

export default RehabManageUserComponent;
