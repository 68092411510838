import { JsonObject, JsonProperty } from 'json2typescript';
import CommonRehabCenterMeasure from './CommonRehabCenterMeasure';
import Code from '../../../../domain/code/Code';

@JsonObject("rehabCenterMeasureBySport")
class RehabCenterMeasureBySport extends CommonRehabCenterMeasure {
    @JsonProperty("sportCode")
    sportCode?: Code;
}

export default RehabCenterMeasureBySport;