import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import MyPageMainLayout from '../../../layout/MyPageMainLayout';
import MyPageRightContent from '../../../layout/MyPageRightContent';
import { RootState } from '../../../../../moldules/index';
import Schedule from '../../../../../domain/schedule/Schedule';

import useTeamSchedules from '../../../../../hook/schedule/useTeamSchedules';
import ScheduleCard from '../../../components/ScheduleCard';
import MyPageScheduleAllViewCalendarTypeTab from './tab/MyPageScheduleAllViewCalendarTypeTab';

function MyPageScheduleIndexPage() {
    const {myTeam} = useSelector((state: RootState) => state.userSessionReducer);
    const {teamSchedules} = useTeamSchedules(myTeam.teamNo);
    const userState = useSelector((state: RootState) => state.userSessionReducer);
    const [allDisplayPath, setAllDisplayPath] = useState<string>();
    useEffect(() => {
        if(userState.hasTeamAuth || userState.hasSystemAuth) {
            setAllDisplayPath("/mypage/team-admin/schedule/all-view");
        }
    }, [userState]);
    return (
        <MyPageMainLayout>
            <MyPageRightContent contentTitle="팀 일정" allDisplayTitle={"일정 등록"} allDisplayPath={allDisplayPath}>
                {/* {
                    teamSchedules?.map((teamSchedule: Schedule, index: number) => {
                        return (
                            <ScheduleCard key={index} schedule={teamSchedule}></ScheduleCard>
                        )
                    })

                } */}
                <MyPageScheduleAllViewCalendarTypeTab></MyPageScheduleAllViewCalendarTypeTab>
            </MyPageRightContent>
        </MyPageMainLayout>
    )
}

export default MyPageScheduleIndexPage;