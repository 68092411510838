import KMCTRInfo from '../../../domain/user/KMCTRInfo';
import AbstractService from '../../AbstractService';
import KMCUserIdentifyService from '../KMCUserIdentifyService';
import APIServerConfig from '../../../config/APIServerConfig';
class KMCUserIdentifyServiceImpl extends AbstractService implements KMCUserIdentifyService {
    getKCMTRInfo(): Promise<KMCTRInfo> {
        return new Promise((resolve) => {
            console.log('KMCUserIdentify');
            APIServerConfig.recordAPIAxios.get('/user-identify').then((response) => {
                const kmctrInfo: KMCTRInfo = this.jsonConvert.deserializeObject(response.data, KMCTRInfo);
                resolve(kmctrInfo);
            });
        });
    }
}

export default KMCUserIdentifyServiceImpl;
