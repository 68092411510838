const ADD_SEARCH_WORD = 'searchWord/ADD_SEARCH_WORD' as const;
const INIT_SEARCH_WORD = 'searchWord/INIT_SEARCH_WORD' as const;

export const addSearchWordAction = (searchWord: string|undefined) => ({type: ADD_SEARCH_WORD, searchWord: searchWord});
export const initSearchWordAction = () => ({type: INIT_SEARCH_WORD, searchWord: undefined});


type SearchWordAction = 
    | ReturnType<typeof addSearchWordAction>
    | ReturnType<typeof initSearchWordAction>;

type SearchWordState = {
    searchWord: string|undefined;
}

const initialState: SearchWordState = {
    searchWord: undefined
}

function searchWordReduce(state: SearchWordState = initialState, action: SearchWordAction) {
    switch(action.type) {
        case ADD_SEARCH_WORD:
            const searchWord = action.searchWord == undefined ? '' : action.searchWord
            return {searchWord: searchWord};
        case INIT_SEARCH_WORD:
            return {searchWord: action.searchWord};
        default: 
            return state;
    }
}

export default searchWordReduce;