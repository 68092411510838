export const OAUTH2_REDIRECT_URI = `${process.env.REACT_APP_SITE_HOST}/oauth2/redirect`;

export const GOOGLE_AUTH_URL =
	process.env.REACT_APP_AUTH_API_HOST + '/oauth2/authorize/google?redirect_uri=' + OAUTH2_REDIRECT_URI;
export const FACEBOOK_AUTH_URL =
	process.env.REACT_APP_AUTH_API_HOST + '/oauth2/authorize/facebook?redirect_uri=' + OAUTH2_REDIRECT_URI;
export const GITHUB_AUTH_URL =
	process.env.REACT_APP_AUTH_API_HOST + '/oauth2/authorize/github?redirect_uri=' + OAUTH2_REDIRECT_URI;
export const KAKAO_AUTH_URL =
	process.env.REACT_APP_AUTH_API_HOST + '/oauth2/authorize/kakao?redirect_uri=' + OAUTH2_REDIRECT_URI;
export const NAVER_AUTH_URL = 
	process.env.REACT_APP_AUTH_API_HOST + '/oauth2/authorize/naver?redirect_uri=' + OAUTH2_REDIRECT_URI;