import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Reservation from '../../domain/reservation/Reservation';
import ReservationServiceImpl from '../../service/reservation/impl/ReservationServiceImpl';
import ReservationStateButton from './ReservationStateButton';

interface ReservationStateTdProps {
    reservation: Reservation;
}
const reservationService = new ReservationServiceImpl();
function ReservationStateTd(props: ReservationStateTdProps) {
    const [reservation, setReservation] = useState<Reservation>();
    useEffect(() => {
        if (props.reservation) {
            setReservation(props.reservation);
        }
    }, [props.reservation]);
    const reservationStateButtonHandler = (reservation: Reservation, changedReservationStateCodeId: number) => {
        reservation.reservationStateCodeId = changedReservationStateCodeId;
        if (reservation.reservationNo) {
            reservationService.updateReservation(reservation.reservationNo, reservation).then((reservation: Reservation) => {
                setReservation(reservation);
            });
        }
    };
    return (
        <>
            <td>{reservation?.reservationStateCode?.name}</td>
            <td>
                {reservation?.reservationStateCodeId ? (
                    <ReservationStateButton
                        reservation={reservation}
                        reservationStateCodeIdChangeHandler={reservationStateButtonHandler}
                    ></ReservationStateButton>
                ) : (
                    <></>
                )}
                <Link to={`/admin/infra/${reservation?.parentInfra?.infraNo}/schedule?reservationStartDate=${reservation?.getStartDate()}`}>
                    <button className="bg-white round">
                        <i className="fas fa-search">보기</i>
                    </button>
                </Link>
            </td>
        </>
    );
}
export default ReservationStateTd;
