import React, { ChangeEvent, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import AdminMainTitle from '../../../../component/admin/AdminMainTitle';
import useParentInfraCategoriesRetrieve from '../../../../hook/infra/useParentInfraCategoriesRetrieve';
import useCodeRetrieve from '../../../../hook/code/useCodeRetrieve';
import { CodeType } from '../../../../domain/code/CodeType';
import useChildCodeRetrieve from '../../../../hook/code/useChildCodeRetrieve';
import CalendarComponent from '../../../../component/calendar/CalendarComponent';
import Event from '../../../../domain/event/Event';
import DateUtils from '../../../../utils/DateUtils';
import useEventRegiste from '../../../../hook/event/useEventRegiste';
import { RootState } from '../../../../moldules/index';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ko } from 'date-fns/esm/locale';
import TeamSearchInput from '../../../../component/input/TeamSearchInput';
import { confirmAlert } from 'react-confirm-alert';

function AdminEventFormPage() {
    const { user } = useSelector((state: RootState) => state.userSessionReducer);
    const history = useHistory();

    const { codes: regionCodes } = useCodeRetrieve(CodeType.REGION);
    const { codes: eventTypeCodes, initParentCodeId, setInitParentCodeId } = useCodeRetrieve(CodeType.EVENT_TYPE);
    const { parentInfraCategories } = useParentInfraCategoriesRetrieve();
    const eventCouponTypeCodes = useChildCodeRetrieve(initParentCodeId);

    const [updateParentRegionCodeId, setUpdateParentRegionCodeId] = useState<number>();
    const [updateRegionCodeId, setUpdateRegionCodeId] = useState<number>();
    const [updateInfraCategoryNo, setUpdateInfraCategoryNo] = useState<string>();
    const [updateEventTypeCodeId, setUpdateEventTypeCodeId] = useState<number>();
    const [updateEventCouponTypeCodeId, setUpdateEventCouponTypeCodeId] = useState<number>();
    const [updateIssuedCouponCnt, setUpdateIssuedCouponCnt] = useState<string>();
    const [updateEventContent, setUpdateEventContent] = useState<string>();

    const [targetTeam, setTargetTeam] = useState<string>();
    const [targetTeamNo, setTargetTeamNo] = useState<string>();

    const [updateStartDay, setUpdateStartDay] = useState<any>(new Date());
    const [updateStartHour, setUpdateStartHour] = useState<string>('00');
    const [updateStartMinute, setUpdateStartMinute] = useState<string>('00');

    const [updateExpiredDay, setUpdateExpiredDay] = useState<any>(new Date());
    const [updateExpiredHour, setUpdateExpiredHour] = useState<string>('00');
    const [updateExpiredMinute, setUpdateExpiredMinute] = useState<string>('00');
    const [updateCouponValue, setUpdateCouponValue] = useState<number>();
    const [updateEvent, setUpdateEvent] = useState<Event>();

    const childRegionCodes = useChildCodeRetrieve(updateParentRegionCodeId);

    const setCodeIdValue = (event: ChangeEvent<HTMLSelectElement>, setFunc: (value: number | undefined) => void) => {
        if (event.currentTarget.value) {
            setFunc(Number(event.currentTarget.value));
        } else {
            setFunc(undefined);
        }
    };

    const { registedEvent } = useEventRegiste(updateEvent);
    const manStyle = {
        fontSize: '5px',
        color: '#f00',
    };

    useEffect(() => {
        setUpdateEventCouponTypeCodeId(undefined);
        setUpdateCouponValue(undefined);
    }, [eventCouponTypeCodes]);

    useEffect(() => {
        if (registedEvent) {
            confirmAlert({
                title: '등록 성공',
                message: '쿠폰이 정상적으로 등록 되었습니다. 감사합니다.',
                buttons: [
                    {
                        label: '확인',
                        onClick: () => {
                            goBack();
                        },
                    },
                ],
            });
        }
    }, [registedEvent]);

    const registureBtnHandler = () => {
        const updateEvent = new Event();
        if (updateParentRegionCodeId && updateRegionCodeId && updateInfraCategoryNo && updateEventTypeCodeId && updateEventCouponTypeCodeId) {
            updateEvent.parentRegionCodeId = updateParentRegionCodeId;
            updateEvent.regionCodeId = updateRegionCodeId;
            updateEvent.eventTargetInfraCategoryNo = updateInfraCategoryNo;
            updateEvent.targetTeam = targetTeamNo;
            updateEvent.eventCodeId = updateEventTypeCodeId;
            updateEvent.couponCodeId = updateEventCouponTypeCodeId;
            updateEvent.startTime = DateUtils.getDateForm(updateStartDay) + ' ' + updateStartHour + ':' + updateStartMinute;
            updateEvent.expiredTime = DateUtils.getDateForm(updateExpiredDay) + ' ' + updateExpiredHour + ':' + updateExpiredMinute;
            updateEvent.issuedCouponCnt = Number(updateIssuedCouponCnt);
            updateEvent.content = updateEventContent;
            updateEvent.registerNo = user.userNo;
            updateEvent.couponValue = updateCouponValue;

            setUpdateEvent(updateEvent);
        } else {
            confirmAlert({
                title: '경고',
                message: '필수입력 값이 누락 되었습니다. 확인하여 주십시오',
                buttons: [{ label: '', onClick: () => {} }],
            });
        }
    };

    const goBack = () => {
        history.replace('/admin/event');
    };

    const initTeam = () => {
        setTargetTeam('init');
        setTargetTeamNo(undefined);
    };

    return (
        <>
            <AdminMainTitle title="쿠폰등록"></AdminMainTitle>
            <div className="two-wrap">
                <div className="half">
                    <div className="member-list">
                        <table>
                            <colgroup>
                                <col style={{ width: '18%', backgroundColor: '#f2f2f2' }} />
                                <col style={{ width: '*' }} />
                            </colgroup>
                            <thead>
                                <tr>
                                    <td colSpan={2} style={{ textAlign: 'right', paddingTop: '7px', paddingBottom: '7px' }}>
                                        <button style={{ backgroundColor: '#ffc107', color: '#3a4652', fontWeight: 700 }} onClick={goBack}>
                                            돌아가기
                                        </button>
                                        <button className="bg-blue white" style={{ fontWeight: 700 }} onClick={registureBtnHandler}>
                                            쿠폰등록
                                        </button>
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="txt-left">
                                        <div className=" pdl30 blue">
                                            <i className="fas fa-star-of-life" style={manStyle}></i>&nbsp; 쿠폰사용지역
                                        </div>
                                    </td>
                                    <td className="txt-left">
                                        <select
                                            className="bg-light-gray"
                                            onChange={(event) => {
                                                const regionCodeId = Number(event.currentTarget.value);
                                                setUpdateParentRegionCodeId(regionCodeId);
                                            }}
                                        >
                                            <option>발급 도 단위 지역 선택</option>
                                            {regionCodes?.map((regionCode, index) => {
                                                return (
                                                    <option key={index} value={regionCode.codeId}>
                                                        {regionCode.name}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                        <select onChange={(event) => setCodeIdValue(event, setUpdateRegionCodeId)} style={{ width: '250px' }}>
                                            <option>발급 시.군 단위 지역 선택</option>
                                            {childRegionCodes?.map((regionCode, index) => {
                                                return (
                                                    <option key={index} value={regionCode.codeId}>
                                                        {regionCode.name}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="txt-left">
                                        <div className=" pdl30 blue">
                                            <i className="fas fa-star-of-life" style={manStyle}></i>&nbsp; 쿠폰사용대상
                                        </div>
                                    </td>
                                    <td className="txt-left">
                                        <select
                                            style={{ width: '250px' }}
                                            className="bg-light-gray"
                                            onChange={(event) => {
                                                const infraCategoryNo = event.currentTarget.value;
                                                setUpdateInfraCategoryNo(infraCategoryNo);
                                            }}
                                        >
                                            <option>발급 시설 대상 선택</option>
                                            {parentInfraCategories?.map((infraCategory, index) => {
                                                return (
                                                    <option key={index} value={infraCategory.infraCategoryNo}>
                                                        {infraCategory.name}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="txt-left">
                                        <div className="pdl30 blue">
                                            <i className="fas fa-star-of-life" style={manStyle}></i>&nbsp; 쿠폰사용 팀
                                        </div>
                                    </td>
                                    <td className="txt-left">
                                        <div className="flex-basic">
                                            <TeamSearchInput
                                                teamName={targetTeam}
                                                setTeamName={setTargetTeam}
                                                setTeamNo={setTargetTeamNo}
                                            ></TeamSearchInput>
                                            <button className="bg-light-blue" onClick={initTeam}>
                                                팀초기화
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="txt-left">
                                        <div className=" pdl30 blue">
                                            <i className="fas fa-star-of-life" style={manStyle}></i>&nbsp; 쿠폰유형
                                        </div>
                                    </td>
                                    <td className="txt-left">
                                        <select
                                            className="bg-light-gray"
                                            onChange={(event) => {
                                                const eventTypeCodeId = Number(event.currentTarget.value);
                                                setInitParentCodeId(eventTypeCodeId);
                                                setUpdateEventTypeCodeId(eventTypeCodeId);
                                            }}
                                        >
                                            <option>쿠폰유형 선택</option>
                                            {eventTypeCodes?.map((eventTypeCode, index) => {
                                                return (
                                                    <option key={index} value={eventTypeCode.codeId}>
                                                        {eventTypeCode.name}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </td>
                                </tr>
                                {updateEventTypeCodeId != 30001 && updateEventTypeCodeId != 30002 ? (
                                    <tr>
                                        <td className="txt-left">
                                            <div className=" pdl30 blue">
                                                <i className="fas fa-star-of-life" style={manStyle}></i>&nbsp; 제공서비스
                                            </div>
                                        </td>
                                        <td className="txt-left">
                                            <select
                                                className="bg-light-gray"
                                                onChange={(event) => {
                                                    if (event.currentTarget.value !== 'undefined') {
                                                        const couponCodeId = Number(event.currentTarget.value);
                                                        setUpdateEventCouponTypeCodeId(couponCodeId);
                                                    } else {
                                                        setUpdateEventCouponTypeCodeId(undefined);
                                                    }
                                                }}
                                            >
                                                <option value={'undefined'}>제공서비스선택</option>
                                                {eventCouponTypeCodes?.map((eventCouponTypeCode, index) => {
                                                    return (
                                                        <option key={index} value={eventCouponTypeCode.codeId}>
                                                            {eventCouponTypeCode.name}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                        </td>
                                    </tr>
                                ) : (
                                    <></>
                                )}
                                {updateEventTypeCodeId == 30001 ? (
                                    <tr>
                                        <td className="txt-left">
                                            <div className=" pdl30 blue">할인금액</div>
                                        </td>
                                        <td className="txt-left">
                                            <input
                                                onChange={(event) => {
                                                    setUpdateCouponValue(Number(event.currentTarget.value));
                                                }}
                                                className="bg-light-gray"
                                                type="text"
                                                placeholder="할인 금액을 입력하세요."
                                                value={updateCouponValue}
                                            />
                                            원
                                        </td>
                                    </tr>
                                ) : (
                                    <></>
                                )}
                                {updateEventTypeCodeId == 30002 ? (
                                    <tr>
                                        <td className="txt-left">
                                            <div className=" pdl30 blue">할인율</div>
                                        </td>
                                        <td className="txt-left">
                                            <input
                                                onChange={(event) => {
                                                    setUpdateCouponValue(Number(event.currentTarget.value));
                                                }}
                                                className="bg-light-gray"
                                                type="text"
                                                placeholder="할인율을 입력하세요."
                                                value={updateCouponValue}
                                            />
                                            %
                                        </td>
                                    </tr>
                                ) : (
                                    <></>
                                )}
                                <tr>
                                    <td className="txt-left">
                                        <div className=" pdl30 blue">발급 수량</div>
                                    </td>
                                    <td className="txt-left flex-basic">
                                        <input
                                            onChange={(event) => {
                                                setUpdateIssuedCouponCnt(event.currentTarget.value);
                                            }}
                                            className="bg-light-gray"
                                            type="text"
                                            placeholder="발급 수량을 입력하세요."
                                            value={updateIssuedCouponCnt}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="txt-left">
                                        <div className=" pdl30 blue">쿠폰내용</div>
                                    </td>
                                    <td className="txt-left">
                                        <textarea
                                            cols={5}
                                            rows={5}
                                            onChange={(event) => {
                                                setUpdateEventContent(event.currentTarget.value);
                                            }}
                                            className="bg-light-gray"
                                            style={{ padding: '5px' }}
                                            placeholder="쿠폰내용을 입력하세요."
                                            value={updateEventContent}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="txt-left">
                                        <div className=" pdl30 blue">쿠폰시작일</div>
                                    </td>
                                    <td className="txt-left">
                                        <DatePicker
                                            locale={ko}
                                            dateFormat="yyyy-MM-dd"
                                            closeOnScroll={true}
                                            selected={updateStartDay}
                                            onChange={(date) => {
                                                setUpdateStartDay(date);
                                                document.getElementById('keyHandler')?.focus();
                                            }}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="txt-left">
                                        <div className=" pdl30 blue">쿠폰시작시간</div>
                                    </td>
                                    <td className="txt-left">
                                        <select
                                            className="bg-light-gray"
                                            onChange={(event) => {
                                                const hour = event.currentTarget.value;
                                                setUpdateStartHour(hour);
                                            }}
                                        >
                                            {DateUtils.getHours().map((hour: string, index: number) => {
                                                return <option key={index}>{hour}</option>;
                                            })}
                                        </select>
                                        시 &nbsp;
                                        <select
                                            className="bg-light-gray"
                                            onChange={(event) => {
                                                const minute = event.currentTarget.value;
                                                setUpdateStartMinute(minute);
                                            }}
                                        >
                                            {DateUtils.getMinutes().map((min: string, index: number) => {
                                                return <option key={index}>{min}</option>;
                                            })}
                                        </select>
                                        분
                                    </td>
                                </tr>
                                <tr>
                                    <td className="txt-left">
                                        <div className=" pdl30 blue">쿠폰만료일</div>
                                    </td>
                                    <td className="txt-left">
                                        <DatePicker
                                            locale={ko}
                                            dateFormat="yyyy-MM-dd"
                                            closeOnScroll={true}
                                            selected={updateExpiredDay}
                                            onChange={(date) => {
                                                setUpdateExpiredDay(date);
                                            }}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="txt-left">
                                        <div className=" pdl30 blue">쿠폰만료시간</div>
                                    </td>
                                    <td className="txt-left">
                                        <select
                                            className="bg-light-gray"
                                            onChange={(event) => {
                                                const hour = event.currentTarget.value;
                                                setUpdateExpiredHour(hour);
                                            }}
                                        >
                                            {DateUtils.getHours().map((hour: string, index: number) => {
                                                return <option key={index}>{hour}</option>;
                                            })}
                                        </select>
                                        시 &nbsp;
                                        <select
                                            className="bg-light-gray"
                                            onChange={(event) => {
                                                const minute = event.currentTarget.value;
                                                setUpdateExpiredMinute(minute);
                                            }}
                                        >
                                            {DateUtils.getMinutes().map((min: string, index: number) => {
                                                return <option key={index}>{min}</option>;
                                            })}
                                        </select>
                                        분
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={2}></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AdminEventFormPage;
