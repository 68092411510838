import APIServerConfig from '../../../config/APIServerConfig';
import EventSearchCondition from '../../../domain/param/EventSearchCondition';
import AbstractService from '../../AbstractService';
import EventService from '../EventService';
import Event from '../../../domain/event/Event';
import Paging from '../../../domain/common/Paging';
class EventServiceImpl extends AbstractService implements EventService {
    getCouponByTeamNo(teamNo: string): Promise<Event[]> {
        return new Promise((resolve, reject) => {
            APIServerConfig.recordAPIAxios
                .get(`/event/team/${teamNo}`)
                .then((response) => {
                    let events: Event[] = this.jsonConvert.deserializeArray(response.data, Event);
                    console.log(events);
                    resolve(events);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }
    getEventServiceBy(eventSearchCondition: EventSearchCondition, paging: Paging): Promise<Event[]> {
        return new Promise((resolve, reject) => {
            APIServerConfig.recordAPIAxios
                .get(`/event`, {
                    params: Object.assign(eventSearchCondition, paging),
                })
                .then((response) => {
                    let events: Event[] = this.jsonConvert.deserializeArray(response.data, Event);
                    resolve(events);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    getEventServiceCntBy(eventSearchCondition: EventSearchCondition): Promise<number> {
        return new Promise((resolve, reject) => {
            APIServerConfig.recordAPIAxios
                .get(`/event/cnt`, {
                    params: eventSearchCondition,
                })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((err) => reject(err));
        });
    }

    registeEvent(event: Event): Promise<Event> {
        return new Promise((resolve, reject) => {
            APIServerConfig.recordAPIAxios
                .post(`/event`, JSON.stringify(event))
                .then((response) => {
                    let event: Event = this.jsonConvert.deserializeObject(response.data, Event);
                    resolve(event);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    deleteEvent(eventNo: string): Promise<Event> {
        return new Promise((resolve, reject) => {
            APIServerConfig.recordAPIAxios
                .delete(`/event/${eventNo}`)
                .then((response) => {
                    let deletedEvent: Event = this.jsonConvert.deserializeObject(response.data, Event);
                    resolve(deletedEvent);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    useCoupon(eventNo: string, event: Event): Promise<Event> {
        return new Promise((resolve, reject) => {
            APIServerConfig.recordAPIAxios
                .put(`/event/${eventNo}/using`, JSON.stringify(event))
                .then((response) => {
                    let usingCoupon: Event = this.jsonConvert.deserializeObject(response.data, Event);
                    resolve(usingCoupon);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }
}

export default EventServiceImpl;
