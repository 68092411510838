import SearchService from '../SearchService';
import AbstractService from '../../AbstractService';

import APIServerConfig from '../../../config/APIServerConfig';
import SearchItem from '../../../domain/search/SearchItem';
import SearchWord from '../../../domain/search/SearchWord';
class SearchServiceImpl extends AbstractService implements SearchService {

    getSearchItems(searchWord: string): Promise<SearchItem[]> {
        return new Promise((resolve, reject) => {
            APIServerConfig.recordAPIAxios.get('/search', {
                params: {searchWord: searchWord}
            }).then((response) => {
                let searchItems = this.jsonConvert.deserializeArray(response.data, SearchItem);
                resolve(searchItems);
            });
        });
    }

    getSearchWordsOf(): Promise<SearchWord[]> {
        return new Promise((resolve, reject) => {
            APIServerConfig.recordAPIAxios.get('/search/word')
            .then((response) => {
                let searchWords = this.jsonConvert.deserializeArray(response.data, SearchWord);
                resolve(searchWords);
            })
        });
    }

    getSearchWordsOfMostFavorites(): Promise<SearchWord[]> {
        return new Promise((resolve, reject) => {
            APIServerConfig.recordAPIAxios.get('/search/word/most-favorites')
            .then((response) => {
                let searchWords = this.jsonConvert.deserializeArray(response.data, SearchWord);
                resolve(searchWords);
            })
        });
    }

    saveSearchWord(searchWord: string|undefined): Promise<void> {
        if(searchWord) {
            return new Promise((resolve) => {
                APIServerConfig.recordAPIAxios.post('/search', JSON.stringify({"searchWord": searchWord}), APIServerConfig.reqHeaderConfig);
                
            });
        } else {
            return new Promise((resolve) => {

            })
        }
    }
}

export default SearchServiceImpl;