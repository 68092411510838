import React, { useEffect, useState } from 'react';
import usePagingHook from '../../../hook/usePagingHook';

interface AdminItemPaging {
    totalCnt: number;
    pagingSize: number;
    setPagingSize: (pagingSize: number) => void;
    pagingCnt: number;
    setPage: (pageNum: number) => void;
    currentPageNum: number;
    currentPageGroupIdx?: number;
}

function AdminItemPaging(props: AdminItemPaging) {
    const {
        currentPageGroupIdx,
        setCurrentPageGroupIdx,
        pageCnt,
        pageGroupCnt,
        pageGroupArr,
        onPrevEventHandler,
        onNextEventHandler,
    } = usePagingHook(props.totalCnt, props.pagingSize, props.pagingCnt, props.setPage, props.currentPageGroupIdx);

    const groupPrevHandler = (evt: any) => {
        var id = evt.currentTarget.id;
        var idx = 1;
        if (id === 'first') {
            idx = 1;
        } else {
            idx = currentPageGroupIdx;
        }

        setCurrentPageGroupIdx(idx);
        onPrevEventHandler(idx);
    };

    const groupNextHandler = (evt: any) => {
        var id = evt.currentTarget.id;
        var idx = currentPageGroupIdx;
        if (id === 'next') {
            idx = currentPageGroupIdx;
        } else {
            idx = pageGroupCnt - 2;
        }

        setCurrentPageGroupIdx(idx);
        onNextEventHandler(idx);
    };

    let size = pageGroupCnt ? pageGroupCnt : 0;

    return (
        <div className="member-btn flex-basic">
            <div className="btn-del"></div>
            <div className="pageno">
                {currentPageGroupIdx != 0 ? (
                    <>
                        <button id="first" key={size + 1} className="bg-btn-white" onClick={groupPrevHandler}>
                            <b> &lt;&lt; </b>
                        </button>
                        <button id="prev" key={size + 2} className="bg-btn-white" onClick={groupPrevHandler}>
                            <b> &lt; </b>
                        </button>
                    </>
                ) : (
                    <></>
                )}
                {pageGroupArr.map((value: number, index: number) => {
                    let pageNum = props.pagingCnt * currentPageGroupIdx + (value + 1);
                    return pageNum <= pageCnt ? (
                        <button
                            key={index}
                            className={`${pageNum - 1 == props.currentPageNum ? 'bg-blue' : 'bg-gray'} white`}
                            onClick={() => {
                                props.setPage(pageNum - 1);
                            }}
                        >
                            &nbsp;{pageNum}&nbsp;
                        </button>
                    ) : (
                        <></>
                    );
                })}
                {currentPageGroupIdx < pageGroupCnt - 1 ? (
                    <>
                        <button id="next" className="bg-btn-white" onClick={groupNextHandler} key={size + 3}>
                            <b> &gt; </b>
                        </button>
                        <button id="last" className="bg-btn-white" onClick={groupNextHandler} key={size + 4}>
                            <b> &gt;&gt; </b>
                        </button>
                    </>
                ) : (
                    <></>
                )}
            </div>
            <div className="list-ea">
                <select
                    onChange={(event) => {
                        const pagingSize = Number(event.currentTarget.value);
                        props.setPagingSize(pagingSize);
                        props.setPage(0);
                        setCurrentPageGroupIdx(0);
                    }}
                >
                    <option value={10}>10개씩 보기</option>
                    <option value={15}>15개씩 보기</option>
                    <option value={20}>20개씩 보기</option>
                </select>
            </div>
        </div>
    );
}

export default AdminItemPaging;
