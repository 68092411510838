import React, { useEffect, useRef, useState } from 'react';
import AttachFile from '../../../domain/file/AttachFile';

interface AdminUpdateImageOfCardTypeProps {
    attachFileTargetNo: string;
    attachFiles?: AttachFile[];
    upload: (file: any, mainFileYn: boolean) => Promise<AttachFile>;
    fileDelete: (saveFilePath: string) => Promise<string>;
}

function AdminUpdateImageOfCardType(props: AdminUpdateImageOfCardTypeProps) {
    const defaultImage = '/assets/image/list-1.png';
    const [attachFiles, setAttachFiles] = useState(new Array<AttachFile>());
    const hiddenFileInput = useRef<HTMLInputElement>(null);
    const mainHiddenFileInput = useRef<HTMLInputElement>(null);
    const [mainAttachFile, setMainAttachFile] = useState<AttachFile>();
    const [noMainAttachFiles, setNoMainAttachFiles] = useState<AttachFile[]>();

    const mainHandleClick = (event: any) => {
        if (mainHiddenFileInput.current != null) {
            mainHiddenFileInput.current.click();
        }
    };

    const handleClick = (event: any) => {
        if (hiddenFileInput.current != null) {
            hiddenFileInput.current.click();
        }
    };

    //file upload후 반환 결과를 업로드 당시 상태 값의 attachFile에 붙여서 리셋 해준다.
    const handleChange = (event: any, mainFileYn: boolean) => {
        const fileUploaded = event.target.files[0];
        props.upload(fileUploaded, mainFileYn).then((attachFile) => {
            let newAttachFiles = [attachFile].concat(attachFiles);
            setAttachFiles(newAttachFiles);
        });
    };

    useEffect(() => {
        const reloadedAttachFiles = new Array<AttachFile>();
        props.attachFiles?.forEach((attachFile: AttachFile) => {
            const reloadAttachFile = Object.setPrototypeOf(attachFile, AttachFile.prototype);
            reloadedAttachFiles.push(reloadAttachFile);
        });
        setAttachFiles(reloadedAttachFiles);
    }, [props.attachFiles]);

    useEffect(() => {
        const mainAttachFile = AttachFile.getMainFile(attachFiles);
        const noMainAttachFiles = AttachFile.getNoMainFiles(attachFiles);
        setMainAttachFile(mainAttachFile);
        setNoMainAttachFiles(noMainAttachFiles);
    }, [attachFiles]);

    // useEffect(() => {
    //     //console.log(mainAttachFile);
    // }, [mainAttachFile]);

    const fileDeleteButton = (saveFilePath: string) => {
        return (
            <button
                onClick={() => {
                    props.fileDelete(saveFilePath).then(() => {
                        const newAttachFiles = attachFiles.filter((attachFile: AttachFile, index: number) => {
                            return attachFile.saveFilePath != saveFilePath;
                        });

                        setAttachFiles(newAttachFiles);
                    });
                }}
                className="thume-bt"
            >
                <i className="fas fa-window-close"></i>
            </button>
        );
    };

    return (
        <>
            <table>
                <tbody>
                    <tr>
                        <td>
                            <h5>메인 사진</h5>

                            {
                                // 첨부 파일이 존재하고 길이가 0보다 클 때
                                attachFiles.length > 0 ? (
                                    <>
                                        {mainAttachFile ? (
                                            <div className="bg-thume-s" style={{ backgroundImage: `url(${mainAttachFile.getPath()})` }}>
                                                {fileDeleteButton(mainAttachFile.saveFilePath)}
                                            </div>
                                        ) : (
                                            <div className="bg-thume-gray">
                                                <button
                                                    className="thume-bt"
                                                    onClick={(event) => {
                                                        mainHandleClick(event);
                                                    }}
                                                >
                                                    <input
                                                        ref={mainHiddenFileInput}
                                                        onChange={(event) => {
                                                            handleChange(event, true);
                                                        }}
                                                        type="file"
                                                        style={{ display: 'none' }}
                                                    ></input>
                                                    <i className="fas fa-plus-circle"></i>
                                                </button>
                                            </div>
                                        )}
                                    </>
                                ) : (
                                    //attachFile이 없을때도 등록 가능해야지
                                    <div className="bg-thume-s bg-thume-gray">
                                        <button
                                            className="thume-bt"
                                            onClick={(event) => {
                                                mainHandleClick(event);
                                            }}
                                        >
                                            <input
                                                ref={mainHiddenFileInput}
                                                onChange={(event) => {
                                                    handleChange(event, true);
                                                }}
                                                type="file"
                                                style={{ display: 'none' }}
                                            ></input>
                                            <i className="fas fa-plus-circle"></i>
                                        </button>
                                    </div>
                                )
                            }
                        </td>
                        <td style={{ paddingLeft: '5px' }}>
                            <h5>상세 사진</h5>

                            <div className="flex-basic">
                                {noMainAttachFiles?.map((attachFile: AttachFile, index: number) => {
                                    return (
                                        <div
                                            key={index}
                                            className="bg-thume-s"
                                            style={{ backgroundImage: `url(${attachFile.getPath()})`, backgroundSize: 'cover' }}
                                        >
                                            {fileDeleteButton(attachFile.saveFilePath)}
                                        </div>
                                    );
                                })}
                                <div className="bg-thume-s bg-thume-gray">
                                    <button
                                        className="thume-bt"
                                        onClick={(event) => {
                                            handleClick(event);
                                        }}
                                    >
                                        <input
                                            ref={hiddenFileInput}
                                            onChange={(event) => {
                                                handleChange(event, false);
                                            }}
                                            type="file"
                                            style={{ display: 'none' }}
                                        ></input>
                                        <i className="fas fa-plus-circle"></i>
                                    </button>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </>
    );
}

export default AdminUpdateImageOfCardType;
