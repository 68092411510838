import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('basalPhysical')
class BasalPhysical {
    @JsonProperty('graspingPower')
    graspingPower?: string[] = ['', '', ''];
    @JsonProperty('relativeGrip')
    relativeGrip?: string[] = ['', '', ''];
    @JsonProperty('sitUp')
    sitUp?: string[] = ['', '', ''];
    @JsonProperty('crossSitUp')
    crossSitUp?: string[] = ['', '', ''];
    @JsonProperty('repeatJump')
    repeatJump?: string[] = ['', '', ''];
    @JsonProperty('buckling')
    buckling?: string[] = ['', '', ''];
    @JsonProperty('shuttleRun10')
    shuttleRun10?: string[] = ['', '', ''];
    @JsonProperty('shuttleRun15')
    shuttleRun15?: string[] = ['', '', ''];
    @JsonProperty('shuttleRun20')
    shuttleRun20?: string[] = ['', '', ''];
    @JsonProperty('jumpInPlace')
    jumpInPlace?: string[] = ['', '', ''];
    @JsonProperty('repeatSideJump')
    repeatSideJump?: string[] = ['', '', ''];
    @JsonProperty('flightTime')
    flightTime?: string[] = ['', '', ''];
    @JsonProperty('reactionTime')
    reactionTime?: string[] = ['', '', ''];
    @JsonProperty('agilityTest')
    agilityTest?: string[] = ['', '', ''];
    @JsonProperty('coordination')
    coordination?: string[] = ['', '', ''];
    @JsonProperty('passWall')
    passWall?: string[] = ['', '', ''];
}

export default BasalPhysical;
