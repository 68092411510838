import { JsonProperty, JsonObject } from 'json2typescript';
import User from './User';
import Code from '../code/Code';
@JsonObject("retrieveUser")
class RetrieveUser extends User {
    @JsonProperty("genderCode")
    genderCode?: Code;
    @JsonProperty("classificationCode")
    classificationCode?: Code;
    @JsonProperty("regionCode")
    regionCode?: Code;
    @JsonProperty("signupApprove")
    signupApprove: boolean =false;
    @JsonProperty("registeDate")
    registeDate: string = '';
    @JsonProperty("teamName")
    teamName: string = '';
    @JsonProperty("sportCode")
    sportCode?: Code;
}

export default RetrieveUser;