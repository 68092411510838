import User from "../user/User";
import { JsonProperty, JsonObject } from "json2typescript";
import AttachFile from '../file/AttachFile';

@JsonObject("boardContent")
export default class BoardContent {
    @JsonProperty("writer")
    writer: User = new User();
    @JsonProperty("contentNo")
    contentNo: string = '';
    @JsonProperty("title")
    title: string = '';
    @JsonProperty("content")
    content: string = '';
    @JsonProperty("attachFiles")
    attachFiles: AttachFile[] = [];
    @JsonProperty("registeDate")
    registeDate: Date = new Date();

    getYYYYMMDD(date: Date) {
        return date;
    }

    getMainAttachFile(index: number) {
        if(this.attachFiles.length == 0 || this.attachFiles.length <= index) {
            return `${process.env.REACT_APP_RESOURCE_API_HOST}/assets/image/list-1.png`;
        } else {
            return `${process.env.REACT_APP_RESOURCE_API_HOST}/static-file${this.attachFiles[index].saveFilePath}`;
        }
    }
}