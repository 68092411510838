import React, { useEffect, useState } from 'react';
import RoleGroupsSearchCondition from '../../../../domain/code/param/RoleGroupsSearchCondition';
import RoleGroupType from '../../../../domain/user/RoleGroupType';
import useAdminManagerInfo from '../../../admin/hook/useAdminManagerInfo';
import useCodeRetrieve from '../../../../hook/code/useCodeRetrieve';
import { CodeType } from '../../../../domain/code/CodeType';
import useChildCodeRetrieve from '../../../../hook/code/useChildCodeRetrieve';
import useRoleGroupRetrieveByCondition from '../../../../hook/code/useRoleGroupRetrieveByCondition';
import UserModifyParam from '../../../../domain/user/param/UserModifyParam';
import UserDetailInfo from '../../../../domain/user/UserDetailInfo';
import RoleSearchCondition from '../../../../domain/code/param/RoleSearchCondition';
import { USER_SERVICE } from '../../../../service/index';
import Authority from '../../../../domain/user/Authority';
import RoleGroups from '../../../../domain/user/RoleGroups';
import Roles from '../../../../domain/user/Roles';
import Code from '../../../../domain/code/Code';

interface RehabManageUserInfoFormProps {
    userNo?: string;
}

function getRoleGroupsSearchConditionOfManager(): RoleGroupsSearchCondition {
    const roleGroupsSearchCondition: RoleGroupsSearchCondition = new RoleGroupsSearchCondition();
    roleGroupsSearchCondition.roleGroupNames = `${RoleGroupType.CENTER}`;

    return roleGroupsSearchCondition;
}

function RehabManageUserInfoForm(props: RehabManageUserInfoFormProps) {
    const {
        userDetailInfo,
        userRoleGroupNo, setUserRoleGroupNo,
        userRoleNo, setUserRoleNo,
        userName, setUserName,
        userPhoneNumber, setUserPhoneNumber,
        userEmail, setUserEmail,
        userPassword, setUserPassword,
        userAuthority, setUserAuthority,
        setRoleSearchCondition,
        roles,
        userParentRegionCodeId, setUserParentRegionCodeId,
        userRegionCodeId, setUserRegionCodeId
    } = useAdminManagerInfo(props.userNo);

    const {codes: parentRegionCodes} = useCodeRetrieve(CodeType.REGION);
    const childRegionCodes = useChildCodeRetrieve(userParentRegionCodeId);
    const roleGroupsSearchCondition: RoleGroupsSearchCondition = getRoleGroupsSearchConditionOfManager();
    const {roleGroups} = useRoleGroupRetrieveByCondition(roleGroupsSearchCondition);
    const [userModifyParam, setUserModifyParam] = useState<UserModifyParam>();
    const [newUserDetailInfo, setNewUserDetailInfo] = useState<UserDetailInfo>();

    useEffect(() => {
        if(userAuthority) {
            userAuthority.roleNo = userRoleNo;
        }
    }, [userRoleNo]);

    useEffect(() => {
        if(userRoleGroupNo) {
            const newRoleSearchCondition = new RoleSearchCondition();
            newRoleSearchCondition.roleGroupNos = String(userRoleGroupNo);
            setRoleSearchCondition(newRoleSearchCondition);
        }
    }, [userRoleGroupNo]);

    useEffect(() => {
        if(userModifyParam) {
            USER_SERVICE.updateUserInfo(userModifyParam)
        }
    }, [userModifyParam]);

    useEffect(() => {
        if(newUserDetailInfo) {
            USER_SERVICE.registeUserInfo(newUserDetailInfo);
        }
    }, [newUserDetailInfo]);

    const getUpdateUserDetailInfo = function() {
        if(props.userNo && userDetailInfo && userAuthority && userName) {
            userDetailInfo.userNo = props.userNo;
            userDetailInfo.authorities[0] = userAuthority;
            userDetailInfo.name = userName;
            userDetailInfo.phoneNumber = userPhoneNumber;
            userDetailInfo.email = userEmail;
            userDetailInfo.password = userPassword;
            userDetailInfo.regionCodeId = userRegionCodeId;
        }

        const userModifyParam = UserModifyParam.getInstance(userDetailInfo);
        return userModifyParam;
    }

    const getNewUserDetailInfo = (): UserDetailInfo => {
        const newUserDetailInfo = new UserDetailInfo();
        if(userName) {
            newUserDetailInfo.name = userName;
            newUserDetailInfo.phoneNumber = userPhoneNumber;
            newUserDetailInfo.email = userEmail;
            newUserDetailInfo.authorities = new Array<Authority>();
            const newAuthority = new Authority();
            newAuthority.roleNo = userRoleNo;
            newUserDetailInfo.authorities.push(newAuthority);
            newUserDetailInfo.password = userPassword;
            newUserDetailInfo.signupApprove = true;
            newUserDetailInfo.regionCodeId = userRegionCodeId;
        }
        return newUserDetailInfo;
    }

    return (
        <table cellPadding="0" cellSpacing="0">
            <tr>
                <td className="txt-left"><div className=" pdl30 blue">구분</div></td>
                <td className="txt-left">
                        {
                        roleGroups?.map((roleGroup: RoleGroups, index: number) => {
                            return (
                                <>
                                    <label>
                                        <input onClick={(event) => {
                                            setUserRoleGroupNo(Number(event.currentTarget.value))
                                        }} 
                                        checked={roleGroup.roleGroupNo == userRoleGroupNo} key={index} type="radio" name="role-group" value={roleGroup.roleGroupNo}/>{roleGroup.groupDesc}
                                    </label>
                                </>
                            )
                        })
                    }
                </td>
            </tr>
            <tr>
                <td className="txt-left">
                    <div className=" pdl30 blue">사용자 권한</div>
                </td>
                <td className="txt-left">
                    <select
                        onChange={(event) => {
                            const roleNo = Number(event.currentTarget.value);
                            setUserRoleNo(roleNo);
                        }}
                    >
                        <option>권한선택</option>
                        {
                            roles?.map((role: Roles, index: number) => {
                                return (
                                    <option selected={role.roleNo == userRoleNo} key={index} value={role.roleNo}>{role.roleDesc}</option>
                                )
                            })
                        }
                    </select>
                </td>
            </tr>
            <tr>
                <td className="txt-left">
                    <div className=" pdl30 blue">소속 지역</div>
                </td>
                <td className="txt-left">
                    <select
                        onChange={(event) => {
                            setUserParentRegionCodeId(Number(event.currentTarget.value));
                        }}
                    >
                        <option>지역 선택</option>
                        {
                            parentRegionCodes?.map((regionCode: Code, index: number) => {
                                return (
                                    <option selected={regionCode.codeId == userParentRegionCodeId} key={index} value={regionCode.codeId}>{regionCode.name}</option>
                                )
                            })
                        }
                    </select>
                    <select
                        onChange={(event) => {
                            setUserRegionCodeId(Number(event.currentTarget.value));
                        }}
                    >
                        <option>상세 지역 선택</option>
                        {
                            childRegionCodes?.map((regionCode: Code, index: number) => {
                                return (
                                    <option selected={regionCode.codeId == userRegionCodeId} key={index} value={regionCode.codeId}>{regionCode.name}</option>
                                )
                            })
                        }
                    </select>
                </td>
            </tr>
            <tr>
                <td className="txt-left">
                    <div className=" pdl30 blue">이름</div>
                </td>
                <td className="txt-left">
                    <input type="text" value={userName}
                        onChange={(event) => {
                            setUserName(event.currentTarget.value);
                        }}
                    ></input>
                </td>
            </tr>
            <tr>
                <td className="txt-left"><div className=" pdl30 blue">연락처</div></td>
                <td className="txt-left">
                    <input type='text' value={userPhoneNumber}
                        onChange={(event) => {
                            setUserPhoneNumber(event.currentTarget.value);
                        }}
                    ></input>
                </td>
            </tr>

            <tr>
                <td className="txt-left"><div className=" pdl30 blue">이메일</div></td>
                <td className="txt-left">
                    <input type='text' value={userEmail}
                        onChange={(event) => {
                            setUserEmail(event.currentTarget.value);
                        }}
                    ></input>
                </td>
            </tr>
            <tr>
                <td className="txt-left"><div className=" pdl30 blue">비밀번호</div></td>
                <td className="txt-left">
                    <input type='password' value={userPassword}
                        onChange={(event) => {
                            setUserPassword(event.currentTarget.value);
                        }}
                    ></input>
                </td>
            </tr>
            <tr>
                <td colSpan={2}>
                    <button className="bg-blue white round"
                        onClick={() => {
                            let eventParam: UserDetailInfo | UserModifyParam;
                            if(userDetailInfo) {
                                eventParam = getUpdateUserDetailInfo();
                                setUserModifyParam(eventParam);
                            } else {
                                eventParam = getNewUserDetailInfo();
                                setNewUserDetailInfo(eventParam);
                            }
                            console.log(eventParam);
                        }}
                    >센터 관리자 추가</button>
                </td>
            </tr>
        </table>
    )
}

export default RehabManageUserInfoForm;