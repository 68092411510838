import AbstractService from '../../AbstractService';
import ChargeOfInfraService from '../ChargeOfInfraService';
import Charge from '../../../domain/chage/Charge';
import APIServerConfig from '../../../config/APIServerConfig';
class ChargeOfInfraServiceImpl extends AbstractService implements ChargeOfInfraService {

    registe(charge: Charge): Promise<Charge> {
        return new Promise((resolve, reject) => {
            APIServerConfig.recordAPIAxios.post('/charge/infra', JSON.stringify(charge))
            .then(response => {
                let charge = this.jsonConvert.deserializeObject(response.data, Charge);
                alert("정상 등록");
                resolve(charge);
            })
            .catch(err => {
                alert("등록 실패");
                reject(err);
            })
        });
    }

}

export default ChargeOfInfraServiceImpl;