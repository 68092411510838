import React from 'react';
import BasalPhysical from '../../../../../domain/recording/BasalPhysical';
import PhysicalStatus from '../../../../../domain/recording/PhysicalStatus';
import SpecialPhysical from '../../../../../domain/recording/SpecialPhysical';
import RehabTargetHistory from '../../../../../domain/RehabTargetHistory';
import TargetPerson from '../../../../../domain/TargetPerson';

interface IProps {
    targetPerson: TargetPerson;
    recordingItem?: RehabTargetHistory;
}

export default function RecordingResultComponent(props: IProps) {
    const targetPerson = props.targetPerson;
    const recordingItem = props.recordingItem;

    const physicalStatus = recordingItem?.physicalStatus ? JSON.parse(recordingItem.physicalStatus) : new PhysicalStatus();
    const basalPhysical = recordingItem?.basalPhysical ? JSON.parse(recordingItem.basalPhysical) : new BasalPhysical();
    const specialPhysical = recordingItem?.specialPhysical ? JSON.parse(recordingItem.specialPhysical) : new SpecialPhysical();

    const inBody = specialPhysical.inBody;
    const formChecker = specialPhysical.formChecker;
    const footChecker = specialPhysical.footChecker;
    const bioDex = specialPhysical.bioDex;
    const tmt = specialPhysical.treadMillTest;

    return (
        <>
            <div id="recording">
                <h1 className="center">3. 신체측정정보</h1>
                <div id="recording_status">
                    <h2 className="center2">A. 신체측정</h2>
                    <div className="flex-basic">
                        <div className="center-half">
                            <h2 className="center">신장</h2>
                            <input className="center-input-gray center-w100" type="text" value={physicalStatus.height} onChange={() => {}} />
                            <label>cm</label>
                        </div>
                        <div className="center-half">
                            <h2 className="center">체중</h2>
                            <input className="center-input-gray center-w100" type="text" value={physicalStatus.weight} onChange={() => {}} />
                            <label>kg</label>
                        </div>
                    </div>
                    <h2 className="center">혈압</h2>
                    <div className="center-bg-gray flex">
                        <div className="center-bg-blue">최고</div>
                        <input className="center-input-gray center-w50" type="text" value={physicalStatus.hBloodPressure} onChange={() => {}} />
                        <label className="center-label">mmHg</label>
                        <div className="center-bg-blue">최저</div>
                        <input className="center-input-gray center-w50" type="text" value={physicalStatus.lBloodPressure} onChange={() => {}} />
                        <label className="center-label">mmHg</label>
                        <div className="center-bg-blue">맥박</div>
                        <input className="center-input-gray  center-w50" type="text" value={physicalStatus.pluse} onChange={() => {}} />
                        <label className="center-label">/분</label>
                    </div>
                </div>

                <div id="recording_basal">
                    <h2 className="center2">B. 기초체력 측정</h2>
                    <div className="flex-basic">
                        <div className="center-half">
                            <h2 className="center">악력</h2>
                            <div className="center-bg-gray flex">
                                <div className="center-bg-blue">1회차</div>
                                <input
                                    className="center-input-gray center-w50"
                                    type="text"
                                    value={basalPhysical.graspingPower[0]}
                                    onChange={() => {}}
                                />
                                <label className="center-label">kg</label>
                            </div>
                        </div>
                        <div className="center-half">
                            <h2 className="center">상대악력</h2>
                            <div className="center-bg-gray flex">
                                <div className="center-bg-blue">1회차</div>
                                <input
                                    className="center-input-gray center-w50"
                                    type="text"
                                    value={basalPhysical.relativeGrip[0]}
                                    onChange={() => {}}
                                />
                                <label className="center-label">%</label>
                            </div>
                        </div>
                    </div>
                    <div className="flex-basic">
                        <div className="center-half">
                            <h2 className="center">윗몸말아올리기</h2>
                            <div className="center-bg-gray flex">
                                <div className="center-bg-blue">1회차</div>
                                <input className="center-input-gray center-w50" type="text" value={basalPhysical.sitUp[0]} onChange={() => {}} />
                                <label className="center-label">회</label>
                            </div>
                        </div>
                        <div className="center-half">
                            <h2 className="center">교차윗몸일으키기</h2>
                            <div className="center-bg-gray flex">
                                <div className="center-bg-blue">1회차</div>
                                <input className="center-input-gray center-w50" type="text" value={basalPhysical.crossSitUp[0]} onChange={() => {}} />
                                <label className="center-label">회</label>
                            </div>
                        </div>
                    </div>
                    <div className="flex-basic">
                        <div className="center-half">
                            <h2 className="center">반복점프</h2>
                            <div className="center-bg-gray flex">
                                <div className="center-bg-blue">1회차</div>
                                <input className="center-input-gray center-w50" type="text" value={basalPhysical.repeatJump[0]} onChange={() => {}} />
                                <label className="center-label">회</label>
                            </div>
                        </div>
                        <div className="center-half">
                            <h2 className="center">좌전굴(앉아윗몸앞으로굽히기)</h2>
                            <div className="center-bg-gray flex">
                                <div className="center-bg-blue">1회차</div>
                                <input className="center-input-gray center-w50" type="text" value={basalPhysical.buckling[0]} onChange={() => {}} />
                                <label className="center-label">cm</label>
                            </div>
                        </div>
                    </div>
                    <div className="flex-basic">
                        <div className="center-half">
                            <h2 className="center">15m 왕복오래달리기</h2>
                            <div className="center-bg-gray flex">
                                <div className="center-bg-blue">1회차</div>
                                <input
                                    className="center-input-gray center-w50"
                                    type="text"
                                    value={basalPhysical.shuttleRun15[0]}
                                    onChange={() => {}}
                                />
                                <label className="center-label">초</label>
                            </div>
                        </div>
                        <div className="center-half">
                            <h2 className="center">20m 왕복오래달리기</h2>
                            <div className="center-bg-gray flex">
                                <div className="center-bg-blue">1회차</div>
                                <input
                                    className="center-input-gray center-w50"
                                    type="text"
                                    value={basalPhysical.shuttleRun20[0]}
                                    onChange={() => {}}
                                />
                                <label className="center-label">초</label>
                            </div>
                        </div>
                    </div>
                    <div className="flex-basic">
                        <div className="center-half">
                            <h2 className="center">제자리멀리뛰기</h2>
                            <div className="center-bg-gray flex">
                                <div className="center-bg-blue">1회차</div>
                                <input
                                    className="center-input-gray center-w50"
                                    type="text"
                                    value={basalPhysical.jumpInPlace[0]}
                                    onChange={() => {}}
                                />
                                <label className="center-label">cm</label>
                            </div>
                        </div>
                        <div className="center-half">
                            <h2 className="center">반복옆뛰기</h2>
                            <div className="center-bg-gray flex">
                                <div className="center-bg-blue">1회차</div>
                                <input
                                    className="center-input-gray center-w50"
                                    type="text"
                                    value={basalPhysical.repeatSideJump[0]}
                                    onChange={() => {}}
                                />
                                <label className="center-label">회</label>
                            </div>
                        </div>
                    </div>
                    <div className="flex-basic">
                        <div className="center-half">
                            <h2 className="center">체공시간</h2>
                            <div className="center-bg-gray flex">
                                <div className="center-bg-blue">1회차</div>
                                <input className="center-input-gray center-w50" type="text" value={basalPhysical.flightTime[0]} onChange={() => {}} />
                                <label className="center-label">초</label>
                            </div>
                        </div>
                        <div className="center-half">
                            <h2 className="center">반응시간</h2>
                            <div className="center-bg-gray flex">
                                <div className="center-bg-blue">1회차</div>
                                <input
                                    className="center-input-gray center-w50"
                                    type="text"
                                    value={basalPhysical.reactionTime[0]}
                                    onChange={() => {}}
                                />
                                <label className="center-label">초</label>
                            </div>
                        </div>
                    </div>
                    <div className="flex-basic">
                        <div className="center-half">
                            <h2 className="center">일리노이민청성검사</h2>
                            <div className="center-bg-gray flex">
                                <div className="center-bg-blue">1회차</div>
                                <input
                                    className="center-input-gray center-w50"
                                    type="text"
                                    value={basalPhysical.agilityTest[0]}
                                    onChange={() => {}}
                                />
                                <label className="center-label">초</label>
                            </div>
                        </div>
                        <div className="center-half">
                            <h2 className="center">눈/손형응력</h2>
                            <div className="center-bg-gray flex">
                                <div className="center-bg-blue">1회차</div>
                                <input
                                    className="center-input-gray center-w50"
                                    type="text"
                                    value={basalPhysical.coordination[0]}
                                    onChange={() => {}}
                                />
                                <label className="center-label">초</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="recording_special">
                    <h2 className="center2">C. 전문체력 측정</h2>

                    <div id="special_inBody">
                        <p>1.인바디(체성분검사)</p>
                        <div className="flex-basic">
                            <div className="center-half">
                                <h2 className="center">골격근량</h2>
                                <div className="center-bg-gray flex">
                                    <div className="center-bg-blue">1회차</div>
                                    <input
                                        className="center-input-gray center-w50"
                                        type="text"
                                        value={inBody.skeletalMuscle[0]}
                                        onChange={() => {}}
                                    />
                                    <label className="center-label">kg</label>
                                </div>
                            </div>
                            <div className="center-half">
                                <h2 className="center">BMI</h2>
                                <div className="center-bg-gray flex">
                                    <div className="center-bg-blue">1회차</div>
                                    <input className="center-input-gray center-w50" type="text" value={inBody.bmi[0]} onChange={() => {}} />
                                    <label className="center-label">kg/㎥</label>
                                </div>
                            </div>
                        </div>
                        <div className="flex-basic">
                            <div className="center-half">
                                <h2 className="center">체지방률</h2>
                                <div className="center-bg-gray flex">
                                    <div className="center-bg-blue">1회차</div>
                                    <input className="center-input-gray center-w50" type="text" value={inBody.bodyFatRate[0]} onChange={() => {}} />
                                    <label className="center-label">%</label>
                                </div>
                            </div>
                            <div className="center-half">
                                <h2 className="center">내장지방지수</h2>
                                <div className="center-bg-gray flex">
                                    <div className="center-bg-blue">1회차</div>
                                    <input
                                        className="center-input-gray center-w50"
                                        type="text"
                                        value={inBody.visceralFatLev[0]}
                                        onChange={() => {}}
                                    />
                                    <label className="center-label">레벨</label>
                                </div>
                            </div>
                        </div>
                        <div className="flex-basic">
                            <div className="center-half">
                                <h2 className="center">복부지방률</h2>
                                <div className="center-bg-gray flex">
                                    <div className="center-bg-blue">1회차</div>
                                    <input
                                        className="center-input-gray center-w50"
                                        type="text"
                                        value={inBody.abdominalFatRate[0]}
                                        onChange={() => {}}
                                    />
                                    <label className="center-label">%</label>
                                </div>
                            </div>
                            <div className="center-half">
                                <h2 className="center">기초대사량</h2>
                                <div className="center-bg-gray flex">
                                    <div className="center-bg-blue">1회차</div>
                                    <input
                                        className="center-input-gray center-w50"
                                        type="text"
                                        value={inBody.restingMetabolicRate[0]}
                                        onChange={() => {}}
                                    />
                                    <label className="center-label">Kcal</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="special_formChecker">
                        <p>2.폼체커</p>
                        <h2 className="center">Shoulder</h2>
                        <div className="center-bg-gray flex">
                            <div className="center-bg-blue">Left</div>
                            <input className="center-input-gray center-w200" type="text" value={formChecker.shuolderL} onChange={() => {}} />
                            <label className="center-label">˚</label>
                            <div className="center-bg-blue">Right</div>
                            <input className="center-input-gray center-w200" type="text" value={formChecker.shuolderR} onChange={() => {}} />
                            <label className="center-label">˚</label>
                        </div>

                        <h2 className="center">Pelvis</h2>
                        <div className="center-bg-gray flex">
                            <div className="center-bg-blue">Left</div>
                            <input className="center-input-gray center-w200" type="text" value={formChecker.pelvisL} onChange={() => {}} />
                            <label className="center-label">˚</label>
                            <div className="center-bg-blue">Right</div>
                            <input className="center-input-gray center-w200" type="text" value={formChecker.pelvisR} onChange={() => {}} />
                            <label className="center-label">˚</label>
                        </div>

                        <h2 className="center">Knee</h2>
                        <div className="center-bg-gray flex">
                            <div className="center-bg-blue">Left</div>
                            <input className="center-input-gray center-w200" type="text" value={formChecker.kneeL} onChange={() => {}} />
                            <label className="center-label">˚</label>
                            <div className="center-bg-blue">Right</div>
                            <input className="center-input-gray center-w200" type="text" value={formChecker.kneeR} onChange={() => {}} />
                            <label className="center-label">˚</label>
                        </div>

                        <h2 className="center">Ankle</h2>
                        <div className="center-bg-gray flex">
                            <div className="center-bg-blue">Left</div>
                            <input className="center-input-gray center-w200" type="text" value={formChecker.ankleL} onChange={() => {}} />
                            <label className="center-label">˚</label>
                            <div className="center-bg-blue">Right</div>
                            <input className="center-input-gray center-w200" type="text" value={formChecker.ankleR} onChange={() => {}} />
                            <label className="center-label">˚</label>
                        </div>

                        <h2 className="center">Loin</h2>
                        <div className="center-bg-gray flex">
                            <div className="center-bg-blue-big">Flexion</div>
                            <input className="center-input-gray center-w50" type="text" value={formChecker.loinFlexion} onChange={() => {}} />
                            <label className="center-label">˚</label>
                            <div className="center-bg-blue-big">Extension</div>
                            <input className="center-input-gray center-w50" type="text" value={formChecker.loinExtension} onChange={() => {}} />
                            <label className="center-label">˚</label>
                        </div>

                        <br />
                        <div className="center-bg-gray flex">
                            <div className="center-bg-blue-big">Lateral Flexion Left</div>
                            <input className="center-input-gray center-w50" type="text" value={formChecker.loinLaterFL} onChange={() => {}} />
                            <label className="center-label">˚</label>
                            <div className="center-bg-blue-big">Lateral Flexion Right</div>
                            <input className="center-input-gray center-w50" type="text" value={formChecker.loinLaterFR} onChange={() => {}} />
                            <label className="center-label">˚</label>
                        </div>
                    </div>

                    <div id="special_footChecker">
                        <p>3.풋체커(족저압)</p>
                        <h2 className="center">Left</h2>
                        <div className="center-bg-gray flex">
                            <div className="center-bg-blue">Front</div>
                            <input className="center-input-gray center-w200" type="text" value={footChecker.leftFront} onChange={() => {}} />
                            <label className="center-label">˚</label>
                            <div className="center-bg-blue">Rear</div>
                            <input className="center-input-gray center-w200" type="text" value={footChecker.leftRear} onChange={() => {}} />
                            <label className="center-label">˚</label>
                        </div>

                        <h2 className="center">Right</h2>
                        <div className="center-bg-gray flex">
                            <div className="center-bg-blue">Front</div>
                            <input className="center-input-gray center-w200" type="text" value={footChecker.rightFront} onChange={() => {}} />
                            <label className="center-label">˚</label>
                            <div className="center-bg-blue">Rear</div>
                            <input className="center-input-gray center-w200" type="text" value={footChecker.rightRear} onChange={() => {}} />
                            <label className="center-label">˚</label>
                        </div>
                    </div>

                    <div id="special_bioDex">
                        <p>4.바이오덱스(등속성운동)</p>
                        <div className="center-table-bio-box">
                            <table cellPadding="0" cellSpacing="0" className="center-table-bio2">
                                <thead>
                                    <tr>
                                        <td colSpan={2} rowSpan={2} className="center-bgcolor-mint in">
                                            # OF REPS : 5 / 10 / 15
                                        </td>
                                        <td colSpan={4} className="center-bgcolor-navy in">
                                            60 DEG/SEC
                                        </td>
                                        <td colSpan={4} className="center-bgcolor-navy in">
                                            180 DEG/SEC
                                        </td>
                                        <td colSpan={4} className="center-bgcolor-navy in">
                                            300 DEG/SEC
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2} className="center-bgcolor-gray in">
                                            EXTENSION
                                        </td>
                                        <td colSpan={2} className="center-bgcolor-blue in">
                                            FLEXION
                                        </td>
                                        <td colSpan={2} className="center-bgcolor-gray in">
                                            EXTENSION
                                        </td>
                                        <td colSpan={2} className="center-bgcolor-blue in">
                                            FLEXION
                                        </td>
                                        <td colSpan={2} className="center-bgcolor-gray in">
                                            EXTENSION
                                        </td>
                                        <td colSpan={2} className="center-bgcolor-blue in">
                                            FLEXION
                                        </td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="center-bgcolor-lgray"></td>
                                        <td className="in">Unit</td>
                                        <td className="center-bgcolor-lgray in">Left</td>
                                        <td className="in">Right</td>
                                        <td className="center-bgcolor-lgray in">Left</td>
                                        <td className="in">Right</td>
                                        <td className="center-bgcolor-lgray in">Left</td>
                                        <td className="in">Right</td>
                                        <td className="center-bgcolor-lgray in">Left</td>
                                        <td className="in">Right</td>
                                        <td className="center-bgcolor-lgray in">Left</td>
                                        <td className="in">Right</td>
                                        <td className="center-bgcolor-lgray in">Left</td>
                                        <td className="in">Right</td>
                                    </tr>
                                    <tr>
                                        <td className="center-bgcolor-lgray">PEAK TORQUE</td>
                                        <td className="in">N-M</td>
                                        <td className="center-bgcolor-lgray in">
                                            <input className="center-input-bio" type="text" value={bioDex.pt60ExtL} onChange={() => {}} />
                                        </td>
                                        <td className="in">
                                            <input className="center-input-bio" type="text" value={bioDex.pt60ExtR} onChange={() => {}} />
                                        </td>
                                        <td className="center-bgcolor-lgray in">
                                            <input className="center-input-bio" type="text" value={bioDex.pt60FlxL} onChange={() => {}} />
                                        </td>
                                        <td className="in">
                                            <input className="center-input-bio" type="text" value={bioDex.pt60FlxR} onChange={() => {}} />
                                        </td>
                                        <td className="center-bgcolor-lgray in">
                                            <input className="center-input-bio" type="text" value={bioDex.pt180ExtL} onChange={() => {}} />
                                        </td>
                                        <td className="in">
                                            <input className="center-input-bio" type="text" value={bioDex.pt180ExtR} onChange={() => {}} />
                                        </td>
                                        <td className="center-bgcolor-lgray in">
                                            <input className="center-input-bio" type="text" value={bioDex.pt180FlxL} onChange={() => {}} />
                                        </td>
                                        <td className="in">
                                            <input className="center-input-bio" type="text" value={bioDex.pt180FlxR} onChange={() => {}} />
                                        </td>
                                        <td className="center-bgcolor-lgray in">
                                            <input className="center-input-bio" type="text" value={bioDex.pt300ExtL} onChange={() => {}} />
                                        </td>
                                        <td className="in">
                                            <input className="center-input-bio" type="text" value={bioDex.pt300ExtR} onChange={() => {}} />
                                        </td>
                                        <td className="center-bgcolor-lgray in">
                                            <input className="center-input-bio" type="text" value={bioDex.pt300FlxL} onChange={() => {}} />
                                        </td>
                                        <td className="in">
                                            <input className="center-input-bio" type="text" value={bioDex.pt300FlxR} onChange={() => {}} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="center-bgcolor-lgray">PEAK TQ/BW</td>
                                        <td className="in">%</td>
                                        <td className="center-bgcolor-lgray in">
                                            <input className="center-input-bio" type="text" value={bioDex.ptb60ExtL} onChange={() => {}} />
                                        </td>
                                        <td className="in">
                                            <input className="center-input-bio" type="text" value={bioDex.ptb60ExtR} onChange={() => {}} />
                                        </td>
                                        <td className="center-bgcolor-lgray in">
                                            <input className="center-input-bio" type="text" value={bioDex.ptb60FlxL} onChange={() => {}} />
                                        </td>
                                        <td className="in">
                                            <input className="center-input-bio" type="text" value={bioDex.ptb60FlxR} onChange={() => {}} />
                                        </td>
                                        <td className="center-bgcolor-lgray in">
                                            <input className="center-input-bio" type="text" value={bioDex.ptb180ExtL} onChange={() => {}} />
                                        </td>
                                        <td className="in">
                                            <input className="center-input-bio" type="text" value={bioDex.ptb180ExtR} onChange={() => {}} />
                                        </td>
                                        <td className="center-bgcolor-lgray in">
                                            <input className="center-input-bio" type="text" value={bioDex.ptb180FlxL} onChange={() => {}} />
                                        </td>
                                        <td className="in">
                                            <input className="center-input-bio" type="text" value={bioDex.ptb180FlxR} onChange={() => {}} />
                                        </td>
                                        <td className="center-bgcolor-lgray in">
                                            <input className="center-input-bio" type="text" value={bioDex.ptb300ExtL} onChange={() => {}} />
                                        </td>
                                        <td className="in">
                                            <input className="center-input-bio" type="text" value={bioDex.ptb300ExtR} onChange={() => {}} />
                                        </td>
                                        <td className="center-bgcolor-lgray in">
                                            <input className="center-input-bio" type="text" value={bioDex.ptb300FlxL} onChange={() => {}} />
                                        </td>
                                        <td className="in">
                                            <input className="center-input-bio" type="text" value={bioDex.ptb300FlxR} onChange={() => {}} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="center-bgcolor-lgray">TORQ @30.0 DEG</td>
                                        <td className="in">N-M</td>
                                        <td className="center-bgcolor-lgray in">
                                            <input className="center-input-bio" type="text" value={bioDex.torq30_60ExtL} onChange={() => {}} />
                                        </td>
                                        <td className="in">
                                            <input className="center-input-bio" type="text" value={bioDex.torq30_60ExtR} onChange={() => {}} />
                                        </td>
                                        <td className="center-bgcolor-lgray in">
                                            <input className="center-input-bio" type="text" value={bioDex.torq30_60FlxL} onChange={() => {}} />
                                        </td>
                                        <td className="in">
                                            <input className="center-input-bio" type="text" value={bioDex.torq30_60FlxR} onChange={() => {}} />
                                        </td>
                                        <td className="center-bgcolor-lgray in">
                                            <input className="center-input-bio" type="text" value={bioDex.torq30_180ExtL} onChange={() => {}} />
                                        </td>
                                        <td className="in">
                                            <input className="center-input-bio" type="text" value={bioDex.torq30_180ExtR} onChange={() => {}} />
                                        </td>
                                        <td className="center-bgcolor-lgray in">
                                            <input className="center-input-bio" type="text" value={bioDex.torq30_180FlxL} onChange={() => {}} />
                                        </td>
                                        <td className="in">
                                            <input className="center-input-bio" type="text" value={bioDex.torq30_180FlxR} onChange={() => {}} />
                                        </td>
                                        <td className="center-bgcolor-lgray in">
                                            <input className="center-input-bio" type="text" value={bioDex.torq30_300ExtL} onChange={() => {}} />
                                        </td>
                                        <td className="in">
                                            <input className="center-input-bio" type="text" value={bioDex.torq30_300ExtR} onChange={() => {}} />
                                        </td>
                                        <td className="center-bgcolor-lgray in">
                                            <input className="center-input-bio" type="text" value={bioDex.torq30_300FlxL} onChange={() => {}} />
                                        </td>
                                        <td className="in">
                                            <input className="center-input-bio" type="text" value={bioDex.torq30_300FlxR} onChange={() => {}} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="center-bgcolor-lgray">TORQ @0.18 SEG</td>
                                        <td className="in">N-M</td>
                                        <td className="center-bgcolor-lgray in">
                                            <input className="center-input-bio" type="text" value={bioDex.torq18_60ExtL} onChange={() => {}} />
                                        </td>
                                        <td className="in">
                                            <input className="center-input-bio" type="text" value={bioDex.torq18_60ExtR} onChange={() => {}} />
                                        </td>
                                        <td className="center-bgcolor-lgray in">
                                            <input className="center-input-bio" type="text" value={bioDex.torq18_60FlxL} onChange={() => {}} />
                                        </td>
                                        <td className="in">
                                            <input className="center-input-bio" type="text" value={bioDex.torq18_60FlxR} onChange={() => {}} />
                                        </td>
                                        <td className="center-bgcolor-lgray in">
                                            <input className="center-input-bio" type="text" value={bioDex.torq18_180ExtL} onChange={() => {}} />
                                        </td>
                                        <td className="in">
                                            <input className="center-input-bio" type="text" value={bioDex.torq18_180ExtR} onChange={() => {}} />
                                        </td>
                                        <td className="center-bgcolor-lgray in">
                                            <input className="center-input-bio" type="text" value={bioDex.torq18_180FlxL} onChange={() => {}} />
                                        </td>
                                        <td className="in">
                                            <input className="center-input-bio" type="text" value={bioDex.torq18_180FlxR} onChange={() => {}} />
                                        </td>
                                        <td className="center-bgcolor-lgray in">
                                            <input className="center-input-bio" type="text" value={bioDex.torq18_300ExtL} onChange={() => {}} />
                                        </td>
                                        <td className="in">
                                            <input className="center-input-bio" type="text" value={bioDex.torq18_300ExtR} onChange={() => {}} />
                                        </td>
                                        <td className="center-bgcolor-lgray in">
                                            <input className="center-input-bio" type="text" value={bioDex.torq18_300FlxL} onChange={() => {}} />
                                        </td>
                                        <td className="in">
                                            <input className="center-input-bio" type="text" value={bioDex.torq18_300FlxR} onChange={() => {}} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="center-bgcolor-lgray">COFEE. OF VAR.</td>
                                        <td className="in">%</td>
                                        <td className="center-bgcolor-lgray in">
                                            <input className="center-input-bio" type="text" value={bioDex.cofe60ExtL} onChange={() => {}} />
                                        </td>
                                        <td className="in">
                                            <input className="center-input-bio" type="text" value={bioDex.cofe60ExtR} onChange={() => {}} />
                                        </td>
                                        <td className="center-bgcolor-lgray in">
                                            <input className="center-input-bio" type="text" value={bioDex.cofe60FlxL} onChange={() => {}} />
                                        </td>
                                        <td className="in">
                                            <input className="center-input-bio" type="text" value={bioDex.cofe60FlxR} onChange={() => {}} />
                                        </td>
                                        <td className="center-bgcolor-lgray in">
                                            <input className="center-input-bio" type="text" value={bioDex.cofe180ExtL} onChange={() => {}} />
                                        </td>
                                        <td className="in">
                                            <input className="center-input-bio" type="text" value={bioDex.cofe180ExtR} onChange={() => {}} />
                                        </td>
                                        <td className="center-bgcolor-lgray in">
                                            <input className="center-input-bio" type="text" value={bioDex.cofe180FlxL} onChange={() => {}} />
                                        </td>
                                        <td className="in">
                                            <input className="center-input-bio" type="text" value={bioDex.cofe180FlxR} onChange={() => {}} />
                                        </td>
                                        <td className="center-bgcolor-lgray in">
                                            <input className="center-input-bio" type="text" value={bioDex.cofe300ExtL} onChange={() => {}} />
                                        </td>
                                        <td className="in">
                                            <input className="center-input-bio" type="text" value={bioDex.cofe300ExtR} onChange={() => {}} />
                                        </td>
                                        <td className="center-bgcolor-lgray in">
                                            <input className="center-input-bio" type="text" value={bioDex.cofe300FlxL} onChange={() => {}} />
                                        </td>
                                        <td className="in">
                                            <input className="center-input-bio" type="text" value={bioDex.cofe300FlxR} onChange={() => {}} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="center-bgcolor-lgray">TOTAL WORK</td>
                                        <td className="in">J</td>
                                        <td className="center-bgcolor-lgray in">
                                            <input className="center-input-bio" type="text" value={bioDex.totWo60ExtL} onChange={() => {}} />
                                        </td>
                                        <td className="in">
                                            <input className="center-input-bio" type="text" value={bioDex.totWo60ExtR} onChange={() => {}} />
                                        </td>
                                        <td className="center-bgcolor-lgray in">
                                            <input className="center-input-bio" type="text" value={bioDex.totWo60FlxL} onChange={() => {}} />
                                        </td>
                                        <td className="in">
                                            <input className="center-input-bio" type="text" value={bioDex.totWo60FlxR} onChange={() => {}} />
                                        </td>
                                        <td className="center-bgcolor-lgray in">
                                            <input className="center-input-bio" type="text" value={bioDex.totWo180ExtL} onChange={() => {}} />
                                        </td>
                                        <td className="in">
                                            <input className="center-input-bio" type="text" value={bioDex.totWo180ExtR} onChange={() => {}} />
                                        </td>
                                        <td className="center-bgcolor-lgray in">
                                            <input className="center-input-bio" type="text" value={bioDex.totWo180FlxL} onChange={() => {}} />
                                        </td>
                                        <td className="in">
                                            <input className="center-input-bio" type="text" value={bioDex.totWo180FlxR} onChange={() => {}} />
                                        </td>
                                        <td className="center-bgcolor-lgray in">
                                            <input className="center-input-bio" type="text" value={bioDex.totWo300ExtL} onChange={() => {}} />
                                        </td>
                                        <td className="in">
                                            <input className="center-input-bio" type="text" value={bioDex.totWo300ExtR} onChange={() => {}} />
                                        </td>
                                        <td className="center-bgcolor-lgray in">
                                            <input className="center-input-bio" type="text" value={bioDex.totWo300FlxL} onChange={() => {}} />
                                        </td>
                                        <td className="in">
                                            <input className="center-input-bio" type="text" value={bioDex.totWo300FlxR} onChange={() => {}} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="center-bgcolor-lgray">WORK FATIGUE</td>
                                        <td className="in">%</td>
                                        <td className="center-bgcolor-lgray in">
                                            <input className="center-input-bio" type="text" value={bioDex.workFat60ExtL} onChange={() => {}} />
                                        </td>
                                        <td className="in">
                                            <input className="center-input-bio" type="text" value={bioDex.workFat60ExtR} onChange={() => {}} />
                                        </td>
                                        <td className="center-bgcolor-lgray in">
                                            <input className="center-input-bio" type="text" value={bioDex.workFat60FlxL} onChange={() => {}} />
                                        </td>
                                        <td className="in">
                                            <input className="center-input-bio" type="text" value={bioDex.workFat60FlxR} onChange={() => {}} />
                                        </td>
                                        <td className="center-bgcolor-lgray in">
                                            <input className="center-input-bio" type="text" value={bioDex.workFat180ExtL} onChange={() => {}} />
                                        </td>
                                        <td className="in">
                                            <input className="center-input-bio" type="text" value={bioDex.workFat180ExtR} onChange={() => {}} />
                                        </td>
                                        <td className="center-bgcolor-lgray in">
                                            <input className="center-input-bio" type="text" value={bioDex.workFat180FlxL} onChange={() => {}} />
                                        </td>
                                        <td className="in">
                                            <input className="center-input-bio" type="text" value={bioDex.workFat180FlxR} onChange={() => {}} />
                                        </td>
                                        <td className="center-bgcolor-lgray in">
                                            <input className="center-input-bio" type="text" value={bioDex.workFat300ExtL} onChange={() => {}} />
                                        </td>
                                        <td className="in">
                                            <input className="center-input-bio" type="text" value={bioDex.workFat300ExtR} onChange={() => {}} />
                                        </td>
                                        <td className="center-bgcolor-lgray in">
                                            <input className="center-input-bio" type="text" value={bioDex.workFat300FlxL} onChange={() => {}} />
                                        </td>
                                        <td className="in">
                                            <input className="center-input-bio" type="text" value={bioDex.workFat300FlxR} onChange={() => {}} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="center-bgcolor-lgray">AVG. POWER</td>
                                        <td className="in">WATTS</td>
                                        <td className="center-bgcolor-lgray in">
                                            <input className="center-input-bio" type="text" value={bioDex.avgPow60ExtL} onChange={() => {}} />
                                        </td>
                                        <td className="in">
                                            <input className="center-input-bio" type="text" value={bioDex.avgPow60ExtR} onChange={() => {}} />
                                        </td>
                                        <td className="center-bgcolor-lgray in">
                                            <input className="center-input-bio" type="text" value={bioDex.avgPow60FlxL} onChange={() => {}} />
                                        </td>
                                        <td className="in">
                                            <input className="center-input-bio" type="text" value={bioDex.avgPow60FlxR} onChange={() => {}} />
                                        </td>
                                        <td className="center-bgcolor-lgray in">
                                            <input className="center-input-bio" type="text" value={bioDex.avgPow180ExtL} onChange={() => {}} />
                                        </td>
                                        <td className="in">
                                            <input className="center-input-bio" type="text" value={bioDex.avgPow180ExtR} onChange={() => {}} />
                                        </td>
                                        <td className="center-bgcolor-lgray in">
                                            <input className="center-input-bio" type="text" value={bioDex.avgPow180FlxL} onChange={() => {}} />
                                        </td>
                                        <td className="in">
                                            <input className="center-input-bio" type="text" value={bioDex.avgPow180FlxR} onChange={() => {}} />
                                        </td>
                                        <td className="center-bgcolor-lgray in">
                                            <input className="center-input-bio" type="text" value={bioDex.avgPow300ExtL} onChange={() => {}} />
                                        </td>
                                        <td className="in">
                                            <input className="center-input-bio" type="text" value={bioDex.avgPow300ExtR} onChange={() => {}} />
                                        </td>
                                        <td className="center-bgcolor-lgray in">
                                            <input className="center-input-bio" type="text" value={bioDex.avgPow300FlxL} onChange={() => {}} />
                                        </td>
                                        <td className="in">
                                            <input className="center-input-bio" type="text" value={bioDex.avgPow300FlxR} onChange={() => {}} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="center-bgcolor-lgray">AGON/ANTAG RATIO</td>
                                        <td className="in">%</td>
                                        <td className="center-bgcolor-lgray in">
                                            <input className="center-input-bio" type="text" value={bioDex.agan60ExtL} onChange={() => {}} />
                                        </td>
                                        <td className="in">
                                            <input className="center-input-bio" type="text" value={bioDex.agan60ExtR} onChange={() => {}} />
                                        </td>
                                        <td className="center-bgcolor-lgray in">
                                            <input className="center-input-bio" type="text" value={bioDex.agan60FlxL} onChange={() => {}} />
                                        </td>
                                        <td className="in">
                                            <input className="center-input-bio" type="text" value={bioDex.agan60FlxR} onChange={() => {}} />
                                        </td>
                                        <td className="center-bgcolor-lgray in">
                                            <input className="center-input-bio" type="text" value={bioDex.agan180ExtL} onChange={() => {}} />
                                        </td>
                                        <td className="in">
                                            <input className="center-input-bio" type="text" value={bioDex.agan180ExtR} onChange={() => {}} />
                                        </td>
                                        <td className="center-bgcolor-lgray in">
                                            <input className="center-input-bio" type="text" value={bioDex.agan180FlxL} onChange={() => {}} />
                                        </td>
                                        <td className="in">
                                            <input className="center-input-bio" type="text" value={bioDex.agan180FlxR} onChange={() => {}} />
                                        </td>
                                        <td className="center-bgcolor-lgray in">
                                            <input className="center-input-bio" type="text" value={bioDex.agan300ExtL} onChange={() => {}} />
                                        </td>
                                        <td className="in">
                                            <input className="center-input-bio" type="text" value={bioDex.agan300ExtR} onChange={() => {}} />
                                        </td>
                                        <td className="center-bgcolor-lgray in">
                                            <input className="center-input-bio" type="text" value={bioDex.agan300FlxL} onChange={() => {}} />
                                        </td>
                                        <td className="in">
                                            <input className="center-input-bio" type="text" value={bioDex.agan300FlxR} onChange={() => {}} />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div id="special_treadMillTest">
                        <p>5.가스분석기(운동부하검사)</p>
                        <div className="flex-basic">
                            <div className="center-half">
                                <h2 className="center">VO2/KG</h2>
                                <div className="center-bg-gray flex">
                                    <input className="center-input-gray center-w600" type="text" value={tmt.vo2} onChange={() => {}} />
                                </div>
                            </div>
                            <div className="center-half">
                                <h2 className="center">METS</h2>
                                <div className="center-bg-gray flex">
                                    <input className="center-input-gray center-w600" type="text" value={tmt.mets} onChange={() => {}} />
                                </div>
                            </div>
                        </div>
                        <div className="flex-basic">
                            <div className="center-half">
                                <h2 className="center">VE(L/min)</h2>
                                <div className="center-bg-gray flex">
                                    <input className="center-input-gray center-w600" type="text" value={tmt.ve} onChange={() => {}} />
                                </div>
                            </div>
                            <div className="center-half">
                                <h2 className="center">VT(btps)</h2>
                                <div className="center-bg-gray flex">
                                    <input className="center-input-gray center-w600" type="text" value={tmt.vt} onChange={() => {}} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
