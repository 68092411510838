import AdminTable from '../../../../component/admin/AdminTable';
import ClubTeamInfo from '../../../../domain/clubteam/ClubTeamInfo';
import AdminClubTeamInfoTableTr from './AdminClubTeamInfoTableTr';
interface AdminClubTeamInfosTableProps {
    page: number;
    pagingSize: number;
    clubTeamInfos?: ClubTeamInfo[];
}

function AdminClubTeamInfosTable(props: AdminClubTeamInfosTableProps) {
    let infos = props.clubTeamInfos;
    return (
        <>
            <AdminTable>
                <thead>
                    <tr>
                        <td>No.</td>
                        <td>등록기준</td>
                        <td>동호회 지역</td>
                        <td>동호회 종목</td>
                        <td>동호회 소속</td>
                        <td>동호회 팀수</td>
                        <td>동호회 인원수</td>
                        <td>등록일</td>
                        <td>관리</td>
                    </tr>
                </thead>
                <tbody>
                    {infos && infos.length > 0 ? (
                        infos?.map((clubTeamInfo: ClubTeamInfo, index: number) => {
                            return (
                                <AdminClubTeamInfoTableTr
                                    key={index}
                                    seq={props.page * props.pagingSize + (index + 1)}
                                    clubTeamInfo={clubTeamInfo}
                                ></AdminClubTeamInfoTableTr>
                            );
                        })
                    ) : (
                        <tr>
                            <td colSpan={9} style={{ color: '#00f' }}>
                                등록 된 정보가 없습니다.
                            </td>
                        </tr>
                    )}
                </tbody>
            </AdminTable>
        </>
    );
}

export default AdminClubTeamInfosTable;
