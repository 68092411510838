import RetrieveUser from '../RetrieveUser';
import BelongTeam from '../BelongTeam';
import { JsonObject, JsonProperty } from 'json2typescript';
import UserDetailInfo from '../UserDetailInfo';

@JsonObject('userModifyParam')
class UserModifyParam {
    @JsonProperty('user')
    user?: UserDetailInfo;
    @JsonProperty('belongTeam')
    belongTeam?: BelongTeam;

    static getInstance(user?: UserDetailInfo, belongTeam?: BelongTeam) {
        let userModifyParam = new UserModifyParam();

        userModifyParam.user = user;
        userModifyParam.belongTeam = belongTeam;

        return userModifyParam;
    }
}

export default UserModifyParam;
