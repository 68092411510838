import { useEffect, useState } from 'react';
import UserFavoritesInfra from '../../domain/favorities/UserFavoritesInfra';
import { FAVORITES_INFRA_SERVICE } from '../../service/index';
function useFavoritesInfraDelete(userFavoritesInfra?: UserFavoritesInfra) {
    const [deleteDate, setDeleteDate] = useState<Date>(new Date());

    useEffect(() => {
        if(userFavoritesInfra) {
            FAVORITES_INFRA_SERVICE.deleteFavoritesInfra(userFavoritesInfra)
            .then(() => {
                setDeleteDate(new Date());
            })
        }
    }, [userFavoritesInfra]);

    return {
        deleteDate
    }
}

export default useFavoritesInfraDelete;