import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import MyPageDetailRight from '../../../layout/MyPageDetailRight';
import { RootState } from '../../../../../moldules/index';
import useTeamSchedule from '../../../../../hook/schedule/useTeamSchedule';
import InfraLocationMap from '../../../../../component/map/InfraLocationMap';
import Infra from '../../../../../domain/infra/Infra';
import MyPageDetailRightContent from '../../../layout/MyPageDetailRightContent';
import ReservationDetailComponent from '../../../components/ReservationDetailComponent';
import Schedule from '../../../../../domain/schedule/Schedule';
import Reservation from '../../../../../domain/reservation/Reservation';
import ScheduleTitleComponent from '../../../components/ScheduleTitleComponent';

function MyPageScheduleDetailViewPage() {
    const {path, url, params} = useRouteMatch();
    const reParams: any = params;
    const {myTeam} = useSelector((state: RootState) => state.userSessionReducer);
    const {teamSchedule
            , reservation
            , reservationParentInfra
            , reservationInfra} = useTeamSchedule(myTeam.teamNo, reParams.scheduleNo);
    const timeInfoOfInstance: Schedule|Reservation|undefined = teamSchedule?.reservation? reservation : teamSchedule;

    return (
        <MyPageDetailRight>
            <MyPageDetailRightContent>
                    <ScheduleTitleComponent
                        timeInfoOfInstance={timeInfoOfInstance}
                    ></ScheduleTitleComponent>
                    {
                        reservation && reservationInfra?
                        <ReservationDetailComponent
                            reservation={reservation}
                            reservationParentInfra={reservationParentInfra}
                            reservationInfra={reservationInfra}
                        ></ReservationDetailComponent>
                        :
                        <></>
                    }
                    <div className="my-in-story">
                        <h4>일정 내용</h4>
                        <p><span className="sub bg-blue">내용</span> {teamSchedule?.title}</p>
                    </div>
                    <div className="my-in-story">
                        <button>일정 삭제</button>
                        <button>일정 수정</button>
                    </div>
            </MyPageDetailRightContent>
        </MyPageDetailRight>
    )
}

export default MyPageScheduleDetailViewPage;