import React from 'react';
import useTeamSchedules from '../../../../../hook/schedule/useTeamSchedules';
interface TeamStorySchedulesTabProps {
    teamNo: string;
}
function TeamStorySchedulesTab(props: TeamStorySchedulesTabProps) {
    const {teamSchedules} = useTeamSchedules(props.teamNo);
    return (
        <>
            <div className="home-wrap">
                <div className="home-title">
                    <span>팀 일정</span>
                    {/* <span>전체보기</span> */}
                </div>
                <div className="clear"></div>
                <div className="home-box">
                    <ul className="schedule-list"> 
                        
                        {
                            teamSchedules?
                                teamSchedules.length > 0 ?
                                    teamSchedules?.map((teamSchedule, index) => {
                                        return (
                                            <li key={index}>
                                                <div className="">{teamSchedule.title}</div>
                                                <div className="">
                                                    <span> {`${teamSchedule.getStartDate()}~${teamSchedule.getEndDate()}`}</span> 
                                                    {
                                                        teamSchedule.reservation?.parentInfra ? 
                                                        <span>| {teamSchedule.reservation?.parentInfra?.name}&gt;{teamSchedule.reservation?.infra?.name}</span>
                                                        : 
                                                        <></>
                                                    }
                                                </div>								
                                            </li>
                                        )
                                    })
                                    :
                                    <h5>해당 팀의 예약된 일정 정보가 없습니다.</h5>
                                :
                                <></>
                        }
                        <div className="clear"></div>
                    </ul>
                </div>
            </div>
        </>
    )   
}

export default TeamStorySchedulesTab;