import { useEffect, useState } from 'react';
import FillInQuestionnarie from '../../view/rehabcenter/domain/FillInQuestionnarie';
import { REHAB_FILL_IN_QUEST_SERVICE } from '../../view/rehabcenter/service/impl/index';
function useFillInQuestionAnswerRegiste(fillInQuestionnarie?: FillInQuestionnarie) {
    const [answerDate, setAnswerDate] = useState<Date>();
    useEffect(() => {
        if(fillInQuestionnarie) {
            if(fillInQuestionnarie.answer != null) {
                console.log(fillInQuestionnarie);
                REHAB_FILL_IN_QUEST_SERVICE.answerFillInQuestionnarie(fillInQuestionnarie)
                .then(() => {
                    setAnswerDate(new Date());
                });
            }
        }
    }, [fillInQuestionnarie]);

    return {
        answerDate
    }
}

export default useFillInQuestionAnswerRegiste;