import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import useTeamSchedule from '../../../../../hook/schedule/useTeamSchedule';
import { RootState } from '../../../../../moldules';
import MyPageMainLayout from '../../../layout/MyPageMainLayout';
import MyPageRightContent from '../../../layout/MyPageRightContent';
import useReservationOfTeam from '../../../../../hook/useReservationOfTeam';
import Reservation from '../../../../../domain/reservation/Reservation';
import ReservationInfraSelect from '../../../components/ReservationInfrasSelect';
import DateChoice from '../../../components/DateChoice';
import Schedule from '../../../../../domain/schedule/Schedule';
import useTeamScheduleModify from '../../../../../hook/schedule/useTeamScheduleModify';
import Paging from '../../../../../domain/common/Paging';
import ReservationSearchCondition from '../../../../../domain/reservation/param/ReservationSearchCondition';

function MypageScheduleModifyPage() {
    const {params} = useRouteMatch();
    const reParams: any = params;
    const scheduleNo: string = reParams.scheduleNo;
    const {myTeam} = useSelector((state: RootState) => state.userSessionReducer);
    const paging = new Paging(0, 30);
    const searchCondition = new ReservationSearchCondition();
    searchCondition.teamNos = myTeam.teamNo;
    const {reservations} = useReservationOfTeam(myTeam.teamNo, searchCondition, paging);
    const {teamSchedule, reservation} = useTeamSchedule(myTeam.teamNo, scheduleNo);
    const [selectedReservation, setSelectedReservation] = useState<Reservation>();
    const [scheduleStartDay, setScheduleStartDay] = useState<string>();
    const [scheduleEndDay, setScheduleEndDay] = useState<string>();
    const [scheduleTitle, setScheduleTitle] = useState<string>();
    const [updateSchedule, setUpdateSchedule] = useState<Schedule>();
    const updateDate = useTeamScheduleModify(updateSchedule);

    useEffect(() => {
        if(selectedReservation) {
            let reservationStartDate = selectedReservation.startDate;
            let reservationEndDate = selectedReservation.endDate;
            if(reservationStartDate && reservationEndDate) {
                setScheduleStartDay(reservationStartDate.split(" ")[0]);
                setScheduleEndDay(reservationEndDate.split(" ")[0]);
            }
        } else {
            setScheduleStartDay('선택하세요.');
            setScheduleEndDay('선택하세요.');
        }
    }, [selectedReservation]);

    useEffect(() => {
        if(reservation) {
            setScheduleStartDay(reservation.getStartDate());
            setScheduleEndDay(reservation.getEndDate());
        } else if(teamSchedule){
            setScheduleStartDay(teamSchedule.startDate);
            setScheduleEndDay(teamSchedule.endDate);
        }
        setScheduleTitle(teamSchedule?.title);
    }, [reservation]);

    return (
        <MyPageMainLayout>
            {
                teamSchedule?
                (
                    <MyPageRightContent contentTitle={`${teamSchedule?.title} 일정 수정`}>
                        <nav>
                            <ReservationInfraSelect 
                                initReservation={reservation}
                                reservations={reservations}
                                setSelectedReservation={setSelectedReservation}
                            ></ReservationInfraSelect>
                            <DateChoice
                                scheduleStartDay={scheduleStartDay}
                                setScheduleStartDay={setScheduleStartDay}
                                scheduleEndDay={scheduleEndDay}
                                setScheduleEndDay={setScheduleEndDay}
                            ></DateChoice>
                            <div className="menu-item">
                                <div className="notice-open">
                                    <h5>일정 내용 등록</h5>
                                    <input onChange={(event) => setScheduleTitle(event.currentTarget.value)} className="basic-gray" type="text"  placeholder="일정 내용 등록" value={scheduleTitle}/>
                                </div>
                            </div>
                            <a className="bt-blue-2"
                                onClick={() => {
                                    if(teamSchedule.scheduleNo && teamSchedule.scheduleTargetNo) {
                                        const modifySchedule = Schedule.getModifySchedule(teamSchedule.scheduleNo
                                                                                , teamSchedule.scheduleTargetNo
                                                                                , selectedReservation
                                                                                , scheduleStartDay + " 00:00"
                                                                                , scheduleEndDay + " 00:00"
                                                                                , scheduleTitle);
                                        setUpdateSchedule(modifySchedule);
                                    }
                                    
                                }}
                            >일정 수정</a>
                        </nav>
                    </MyPageRightContent>
                ):
                <></>
            }
            
        </MyPageMainLayout>
    )
}

export default MypageScheduleModifyPage;