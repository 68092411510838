import { useEffect, useState } from 'react';
import TempRegistedTeamUserSearchCondition from '../../domain/team/param/TempRegistedTeamUserSearchCondition';
import { TMP_REGISTED_TEAM_USER_SERVICE } from '../../service/index';
import TmpRegistedTeamUser from '../../domain/team/TmpRegistedTeamUser';
function useTmpRegistedTeamUserRetrieve(teamNo?: string, searchCondition?: TempRegistedTeamUserSearchCondition) {
    const [users, setUsers] = useState<TmpRegistedTeamUser[]>();

    useEffect(() => {
        if(teamNo && searchCondition) {
            TMP_REGISTED_TEAM_USER_SERVICE.getTmpUsers(teamNo, searchCondition)
            .then(tmpRegistedTeamUser => {
                setUsers(tmpRegistedTeamUser); 
            });
        }
    }, [teamNo, searchCondition]);

    return {users};
}

export default useTmpRegistedTeamUserRetrieve;