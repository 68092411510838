import React, { useEffect, useState } from 'react';
import AdminMainTitle from '../../../../component/admin/AdminMainTitle';

import ClubTeamInfoSearchCondition from '../../../../domain/clubteam/param/ClubTeamInfoSearchCondition';
import useAdminPaging from '../../hook/useAdminPaging';
import useClubTeamInfosRetrieve from '../../../../hook/clubteam/useClubTeamInfosRetrieve';
import AdminRetrieveItemPaging from '../../component/AdminRetrieveItemPaging';
import AdminClubTeamInfosTable from '../../component/table/AdminClubTeamInfosTable';
import AdminClubTeamInfosFilter from '../../component/filter/AdminClubTeamInfosFilter';

function AdminClubTeamInfoIndexPage() {
    const initSearchCondition = new ClubTeamInfoSearchCondition();
    const [clubTeamInfoSearchCondition, setClubTeamInfoSearchCondition] = useState<ClubTeamInfoSearchCondition>(initSearchCondition);

    const { page, setPage, pagingSize, setPagingSize, pagingCnt, paging, currentPageGroupIdx, setCurrentPageGroupIdx } = useAdminPaging();

    const { clubTeamInfos, clubTeamInfosCnt } = useClubTeamInfosRetrieve(clubTeamInfoSearchCondition, paging);

    useEffect(() => {
        setCurrentPageGroupIdx(0);
        setPage(0);
    }, [clubTeamInfoSearchCondition]);

    let title = '시즌 별 동호회 종목 현황 <' + clubTeamInfosCnt + '건>';
    return (
        <>
            <AdminMainTitle title={title}>
                <AdminClubTeamInfosFilter setClubTeamInfoSearchCondition={setClubTeamInfoSearchCondition}></AdminClubTeamInfosFilter>
            </AdminMainTitle>
            <AdminClubTeamInfosTable page={page} pagingSize={pagingSize} clubTeamInfos={clubTeamInfos}></AdminClubTeamInfosTable>
            <AdminRetrieveItemPaging
                totalCnt={clubTeamInfosCnt}
                pagingCnt={pagingCnt}
                pagingSize={pagingSize}
                setPagingSize={setPagingSize}
                setPage={setPage}
                currentPageNum={page}
                currentPageGroupIdx={currentPageGroupIdx}
            ></AdminRetrieveItemPaging>
        </>
    );
}

export default AdminClubTeamInfoIndexPage;
