import React, { useState, useEffect } from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { map } from 'lodash';
import Layout from '../layouts/Layout';
import Header from '../layouts/Header';
import SideBar from '../layouts/SideBar';
import AdminManagerInfoForm from '../../admin/component/manager/AdminManagerInfoForm';
import RehabManageUserInfoForm from '../components/manageUser/RehabManageUserInfoForm';
import UserDetailInfo from '../../../domain/user/UserDetailInfo';
import Authority from '../../../domain/user/Authority';
import { useSelector } from 'react-redux';
import { RootState } from '../../../moldules';
import DateUtils from '../../../utils/DateUtils';
import UserServiceImpl from '../../../service/user/impl/UserServiceImpl';
import UserService from '../../../service/user/UserService';
import { confirmAlert } from 'react-confirm-alert';
import ConditioningPrescription from '../domain/prescription/ConditioningPrescription';
import VisitedTeamStatusByTeamTypeTables from '../../admin/component/VisitedTeamStatusByTeamTypeTables';
import { RehabProgramPrescription } from '../domain/prescription/RehabProgramPrescription';
import BatchData from '../domain/BatchData';
import BasalPhysical from '../../../domain/report/BasalPhysical';
import PhysicalStatus from '../../../domain/report/PhysicalStatus';
import SpecialPhysical from '../domain/recording/SpecialPhysical';

const menuTitle: string = '* 센터 일괄 계정 관리';
const title: string = '계정일괄관리';
const userService: UserService = new UserServiceImpl();

export default function RehabBatchManageUserComponent() {
    const params: any = useParams();
    const location = useLocation();
    const history = useHistory();
    const userState = useSelector((state: RootState) => state.userSessionReducer);
    let userNo = userState.user.userNo;

    let path = location.pathname;
    const centerKey = params.centerId;

    const [openPopup, setOpenPopup] = useState<any>({ display: 'none' });
    const [contrPopup, setContrPopup] = useState<string>('center-question-popup wow  animate__animated animate__fadeInUp');

    const [batchTitle, setBatchTitle] = useState<string>();
    const [batchDataView, setBatchDataView] = useState<any>();
    const [batchData, setBatchData] = useState<any>();
    const [batchType, setBatchType] = useState<string>();

    const openBatchPopup = () => {
        let isDisplay = { display: 'block' };
        let fadeInStyle = 'center-question-popup wow  animate__animated animate__fadeInUp';
        setOpenPopup(isDisplay);
        setContrPopup(fadeInStyle);
    };
    const closeBatchPopup = () => {
        let isNotDisplay = { display: 'none' };
        let fadeOutStyle = 'center-question-popup wow  animate__animated animate__fadeOutDown';
        setContrPopup(fadeOutStyle);
        setOpenPopup(isNotDisplay);
    };

    const changeFileHandler = (evt: any) => {
        let file = evt.target.files[0];
        let target = evt.target.id;
        setBatchType(target);

        if (file) {
            let fileReader = new FileReader();
            fileReader.readAsText(file);

            fileReader.onload = (fileEvt: any) => {
                let result: string | undefined = fileReader.result?.toString();
                if (target === 'uploadUser') batchUserDataHandler(result);
                else if (target === 'uploadCondi') batchCondiDataHandler(result);
                else if (target === 'uploadRehab') batchRehabProDataHandler(result);
                else if (target === 'uploadBasalPhysical') batchBasalPhysicalDataHandler(result);
                else if (target === 'uploadPhysicalStatus') batchPhysicalStatusDataHandler(result);
                else if (target === 'uploadSpecialPhysical') batchSpecialPhysicalDataHandler(result);
            };
        }
    };

    const batchUserDataHandler = (result: string | undefined) => {
        let date = new Date();
        let userData: UserDetailInfo[] = new Array();
        let allRows = result?.split(/\r?\n|\r/);
        console.log(allRows);
        let size: number = allRows ? allRows.length : 0;
        openBatchPopup();
        if (allRows) {
            let batchUserTable = (
                <table cellSpacing={0} cellPadding={0}>
                    <colgroup>
                        <col style={{ width: '5%' }} />
                        <col style={{ width: '10%' }} />
                        <col style={{ width: '40%' }} />
                        <col style={{ width: '10%' }} />
                        <col style={{ width: '15%' }} />
                        <col style={{ width: '10%' }} />
                        <col style={{ width: '10%' }} />
                    </colgroup>
                    <thead>
                        <tr>
                            <th>No.</th>
                            <th>가입유형</th>
                            <th>이메일</th>
                            <th>휴대폰번호</th>
                            <th>이름</th>
                            <th>생년월일</th>
                            <th>성별</th>
                        </tr>
                    </thead>
                    <tbody>
                        {allRows.map((rowStr, i) => {
                            let row = rowStr.split(',');
                            let rowLength = row.length;
                            console.log(rowLength);
                            if (i > 0) {
                                if (rowLength == 12) {
                                    let data = new UserDetailInfo();
                                    let userKey = date.getTime();

                                    let userAuth = new Authority();
                                    userAuth.authority = 'TEAM_PLAYER';
                                    userAuth.roleNo = 2;

                                    data.authorities = new Array();
                                    data.authorities.push(userAuth);

                                    data.email = row[2] ? row[2] : 'player' + (userKey + i) + '@kbostat.co.kr';
                                    data.phoneNumber = row[3].replace(/-/gi, '');
                                    data.password = row[4] ? row[4] : 'test1234!!';
                                    data.name = row[6] ? row[6] : 'player' + (userKey + i);

                                    let birthdate = DateUtils.getDateForm(new Date(row[7]));
                                    data.birthdate = birthdate;

                                    data.regionCodeId = 2000113;
                                    data.sportCodeId = 10043;

                                    data.genderCodeId = row[8] === '남자' ? 11 : 12;

                                    data.signupApprove = true;

                                    data.provider = 'local';
                                    userData.push(data);

                                    let content = (
                                        <tr key={i}>
                                            <td>{row[0]}</td>
                                            <td>{row[1]}</td>
                                            <td>{data.email}</td>
                                            <td>{data.phoneNumber}</td>
                                            <td>{row[6]}</td>
                                            <td>{data.birthdate}</td>
                                            <td>{row[8]}</td>
                                        </tr>
                                    );

                                    return content;
                                } else {
                                    confirmAlert({
                                        title: '경고',
                                        message: '등록하시는 파일의 데이터를 확인해 주십시오',
                                        buttons: [
                                            {
                                                label: '확인',
                                                onClick: () => {
                                                    closeBatchPopup();
                                                },
                                            },
                                        ],
                                    });
                                }
                            }
                        })}
                    </tbody>
                </table>
            );
            setBatchTitle('사용자 일괄 등록 처리');
            setBatchDataView(batchUserTable);
            setBatchData(userData);
        }
    };

    const batchCondiDataHandler = (result: string | undefined) => {
        let allRows = result?.split(/\r?\n|\r/);
        let condiData = new Array<BatchData>();
        let size: number = allRows ? allRows.length : 0;
        openBatchPopup();
        if (allRows) {
            let batchUserTable = (
                <table cellSpacing={0} cellPadding={0}>
                    <colgroup>
                        <col style={{ width: '5%' }} />
                        <col style={{ width: '10%' }} />
                        <col style={{ width: '10%' }} />
                        <col style={{ width: '12%' }} />
                        <col style={{ width: '9%' }} />
                        <col style={{ width: '9%' }} />
                        <col style={{ width: '9%' }} />
                        <col style={{ width: '9%' }} />
                        <col style={{ width: '9%' }} />
                        <col style={{ width: '9%' }} />
                        <col style={{ width: '9%' }} />
                    </colgroup>
                    <thead>
                        <tr>
                            <th>No.</th>
                            <th>방문날짜</th>
                            <th>성명</th>
                            <th>휴대폰번호</th>
                            <th>얼음찜질(icing)</th>
                            <th>재활마사지(massage)</th>
                            <th>통증완화기(painDoctor)</th>
                            <th>고주파기(radioFrq)</th>
                            <th>체외충격파(eswt)</th>
                            <th>진동마사기지(vibro)</th>
                            <th>그라스톤(sling)</th>
                        </tr>
                    </thead>
                    <tbody>
                        {allRows.map((rowStr, i) => {
                            let row = rowStr.split(',');
                            let rowLength = row.length;

                            if (i > 0) {
                                if (rowLength == 13) {
                                    let data = new ConditioningPrescription();

                                    data.icing = [row[6], '', ''];
                                    data.massage = [row[7], '', ''];
                                    data.painDoctor = [row[8], '', ''];
                                    data.radioFrq = [row[9], '', ''];
                                    data.eswt = [row[10], '', ''];
                                    data.vibro = [row[11], '', ''];
                                    data.sling = [row[12], '', ''];
                                    let visitDate = DateUtils.getDateForm(new Date(row[1]));
                                    let phoneNumber = row[5].replace(/-/gi, '');

                                    let condiJson = JSON.stringify(data);

                                    let uploadingData = new BatchData();

                                    uploadingData.visiteDate = visitDate;
                                    uploadingData.name = row[4];
                                    uploadingData.phoneNumber = phoneNumber;
                                    uploadingData.batchData = condiJson;

                                    condiData.push(uploadingData);

                                    let content = (
                                        <tr key={i}>
                                            <td>{row[0]}</td>
                                            <td>{visitDate}</td>
                                            <td>{row[4]}</td>
                                            <td>{phoneNumber}</td>
                                            <td>{data.icing[0]}</td>
                                            <td>{data.massage[0]}</td>
                                            <td>{data.painDoctor[0]}</td>
                                            <td>{data.radioFrq[0]}</td>
                                            <td>{data.eswt[0]}</td>
                                            <td>{data.vibro[0]}</td>
                                            <td>{data.sling[0]}</td>
                                        </tr>
                                    );

                                    return content;
                                } else {
                                    confirmAlert({
                                        title: '경고',
                                        message: '등록하시는 파일의 데이터를 확인해 주십시오',
                                        buttons: [
                                            {
                                                label: '확인',
                                                onClick: () => {
                                                    closeBatchPopup();
                                                },
                                            },
                                        ],
                                    });
                                }
                            }
                        })}
                    </tbody>
                </table>
            );
            setBatchTitle('컨디셔닝 데이터 일괄 등록 처리');
            setBatchDataView(batchUserTable);
            setBatchData(condiData);
        }
    };

    const batchRehabProDataHandler = (result: string | undefined) => {
        let allRows = result?.split(/\r?\n|\r/);
        let rehabData = new Array<BatchData>();
        let size: number = allRows ? allRows.length : 0;
        openBatchPopup();
        if (allRows) {
            let batchUserTable = (
                <table cellSpacing={0} cellPadding={0}>
                    <colgroup>
                        <col style={{ width: '5%' }} />
                        <col style={{ width: '10%' }} />
                        <col style={{ width: '10%' }} />
                        <col style={{ width: '10%' }} />
                        <col style={{ width: '4%' }} />
                        <col style={{ width: '4%' }} />
                        <col style={{ width: '4%' }} />
                        <col style={{ width: '4%' }} />
                        <col style={{ width: '4%' }} />
                        <col style={{ width: '4%' }} />
                        <col style={{ width: '4%' }} />
                        <col style={{ width: '4%' }} />
                        <col style={{ width: '4%' }} />
                        <col style={{ width: '4%' }} />
                        <col style={{ width: '4%' }} />
                        <col style={{ width: '4%' }} />
                        <col style={{ width: '4%' }} />
                        <col style={{ width: '4%' }} />
                    </colgroup>
                    <thead>
                        <tr>
                            <th>No.</th>
                            <th>방문날짜</th>
                            <th>성명</th>
                            <th>휴대폰번호</th>
                            <th>왼 팔꿈치</th>
                            <th>오른 팔꿈치</th>
                            <th>왼 손목</th>
                            <th>오른 손목</th>
                            <th>왼 발목</th>
                            <th>오른 발목</th>
                            <th>왼 어깨</th>
                            <th>오른 어깨</th>
                            <th>왼 골반</th>
                            <th>오른 골반</th>
                            <th>왼 무릎</th>
                            <th>오른 무릎</th>
                            <th>목</th>
                            <th>허리</th>
                        </tr>
                    </thead>
                    <tbody>
                        {allRows.map((rowStr, i) => {
                            let row = rowStr.split(',');
                            let rowLength = row.length;
                            if (i > 0) {
                                if (rowLength == 20) {
                                    let data = new RehabProgramPrescription();

                                    data.leftElbow = row[6];
                                    data.rightElbow = row[15];
                                    data.leftWrist = row[7];
                                    data.rightWrist = row[16];
                                    data.leftAnkle = row[8];
                                    data.rightAnkle = row[17];
                                    data.leftShoulder = row[10];
                                    data.rightShoulder = row[9];
                                    data.leftPelvis = row[12];
                                    data.rightPelvis = row[11];
                                    data.leftKnee = row[14];
                                    data.rightKnee = row[13];
                                    data.neck = row[18];
                                    data.waist = row[19];

                                    let visitDate = DateUtils.getDateForm(new Date(row[1]));
                                    let phoneNumber = row[5].replace(/-/gi, '');

                                    let rehabJson = JSON.stringify(data);

                                    let uploadingData = new BatchData();

                                    uploadingData.visiteDate = visitDate;
                                    uploadingData.name = row[4];
                                    uploadingData.phoneNumber = phoneNumber;
                                    uploadingData.batchData = rehabJson;

                                    rehabData.push(uploadingData);

                                    let content = (
                                        <tr key={i}>
                                            <td>{row[0]}</td>
                                            <td>{visitDate}</td>
                                            <td>{row[4]}</td>
                                            <td>{phoneNumber}</td>
                                            <td>{data.leftElbow}</td>
                                            <td>{data.rightElbow}</td>
                                            <td>{data.leftWrist}</td>
                                            <td>{data.rightWrist}</td>
                                            <td>{data.leftAnkle}</td>
                                            <td>{data.rightAnkle}</td>
                                            <td>{data.leftShoulder}</td>
                                            <td>{data.rightShoulder}</td>
                                            <td>{data.leftPelvis}</td>
                                            <td>{data.rightPelvis}</td>
                                            <td>{data.leftKnee}</td>
                                            <td>{data.rightKnee}</td>
                                            <td>{data.neck}</td>
                                            <td>{data.waist}</td>
                                        </tr>
                                    );

                                    return content;
                                } else {
                                    confirmAlert({
                                        title: '경고',
                                        message: '등록하시는 파일의 데이터를 확인해 주십시오',
                                        buttons: [
                                            {
                                                label: '확인',
                                                onClick: () => {
                                                    closeBatchPopup();
                                                },
                                            },
                                        ],
                                    });
                                }
                            }
                        })}
                    </tbody>
                </table>
            );
            setBatchTitle('재활프로그램 데이터 일괄 등록 처리');
            setBatchDataView(batchUserTable);
            setBatchData(rehabData);
        }
    };

    const batchBasalPhysicalDataHandler = (result: string | undefined) => {
        let allRows = result?.split(/\r?\n|\r/);
        let rehabData = new Array<BatchData>();
        let size: number = allRows ? allRows.length : 0;
        openBatchPopup();
        if (allRows) {
            let batchUserTable = (
                <table cellSpacing={0} cellPadding={0}>
                    <colgroup>
                        <col style={{ width: '9%' }} />
                        <col style={{ width: '5%' }} />
                        <col style={{ width: '9%' }} />
                        <col style={{ width: '4%' }} />
                        <col style={{ width: '4%' }} />
                        <col style={{ width: '4%' }} />
                        <col style={{ width: '4%' }} />
                        <col style={{ width: '4%' }} />
                        <col style={{ width: '4%' }} />
                        <col style={{ width: '4%' }} />
                        <col style={{ width: '4%' }} />
                        <col style={{ width: '4%' }} />
                        <col style={{ width: '4%' }} />
                        <col style={{ width: '4%' }} />
                        <col style={{ width: '4%' }} />
                        <col style={{ width: '4%' }} />
                        <col style={{ width: '4%' }} />
                        <col style={{ width: '4%' }} />
                        <col style={{ width: '4%' }} />
                    </colgroup>
                    <thead>
                        <tr>
                            <th>방문날짜</th>
                            <th>성명</th>
                            <th>휴대폰번호</th>
                            <th>악력</th>
                            <th>상대악력</th>
                            <th>윗몸말아올리기</th>
                            <th>교차윗몸일으키기</th>
                            <th>앉아윗몸앞으로굽히기</th>
                            <th>반복점프</th>
                            <th>15m 왕복오래달리기</th>
                            <th>20m 왕복오래달리기</th>
                            <th>제자리멀리뛰기</th>
                            <th>반복옆뛰기</th>
                            <th>반응시간</th>
                            <th>10m 왕복달리기</th>
                            <th>일리노이민첩성검사</th>
                            <th>체공시간</th>
                            <th>눈.손협응</th>
                            <th>벽패스</th>
                        </tr>
                    </thead>
                    <tbody>
                        {allRows.map((rowStr, i) => {
                            let row = rowStr.split(',');
                            let rowLength = row.length;
                            if (i > 0) {
                                if (rowLength == 22) {
                                    let dataBasal = new BasalPhysical();

                                    dataBasal.graspingPower = [row[6], '', ''];
                                    dataBasal.relativeGrip = [row[7], '', ''];
                                    dataBasal.sitUp = [row[8], '', ''];
                                    dataBasal.crossSitUp = [row[9], '', ''];
                                    dataBasal.buckling = [row[10], '', ''];
                                    dataBasal.repeatJump = [row[11], '', ''];
                                    dataBasal.shuttleRun15 = [row[12], '', ''];
                                    dataBasal.shuttleRun20 = [row[13], '', ''];
                                    dataBasal.jumpInPlace = [row[14], '', ''];
                                    dataBasal.repeatSideJump = [row[15], '', ''];
                                    dataBasal.reactionTime = [row[16], '', ''];
                                    dataBasal.shuttleRun10 = [row[17], '', ''];
                                    dataBasal.agilityTest = [row[18], '', ''];
                                    dataBasal.flightTime = [row[19], '', ''];
                                    dataBasal.coordination = [row[20], '', ''];
                                    dataBasal.passWall = [row[21], '', ''];

                                    let visitDate = DateUtils.getDateForm(new Date(row[0]));
                                    let phoneNumber = row[4].replace(/-/gi, '');

                                    let rehabJson = JSON.stringify(dataBasal);

                                    let uploadingData = new BatchData();

                                    uploadingData.visiteDate = visitDate;
                                    uploadingData.name = row[3];
                                    uploadingData.phoneNumber = phoneNumber;
                                    uploadingData.batchData = rehabJson;

                                    rehabData.push(uploadingData);

                                    let content = (
                                        <tr key={i}>
                                            <td>{visitDate}</td>
                                            <td>{row[3]}</td>
                                            <td>{phoneNumber}</td>
                                            <td>{dataBasal.graspingPower}</td>
                                            <td>{dataBasal.relativeGrip}</td>
                                            <td>{dataBasal.sitUp}</td>
                                            <td>{dataBasal.crossSitUp}</td>
                                            <td>{dataBasal.buckling}</td>
                                            <td>{dataBasal.repeatJump}</td>
                                            <td>{dataBasal.shuttleRun15}</td>
                                            <td>{dataBasal.shuttleRun20}</td>
                                            <td>{dataBasal.jumpInPlace}</td>
                                            <td>{dataBasal.repeatSideJump}</td>
                                            <td>{dataBasal.reactionTime}</td>
                                            <td>{dataBasal.shuttleRun10}</td>
                                            <td>{dataBasal.agilityTest}</td>
                                            <td>{dataBasal.flightTime}</td>
                                            <td>{dataBasal.coordination}</td>
                                            <td>{dataBasal.passWall}</td>
                                        </tr>
                                    );

                                    return content;
                                } else {
                                    confirmAlert({
                                        title: '경고',
                                        message: '등록하시는 파일의 데이터를 확인해 주십시오',
                                        buttons: [
                                            {
                                                label: '확인',
                                                onClick: () => {
                                                    closeBatchPopup();
                                                },
                                            },
                                        ],
                                    });
                                }
                            }
                        })}
                    </tbody>
                </table>
            );
            setBatchTitle('기초체력측정기록 일괄 등록 처리');
            setBatchDataView(batchUserTable);
            setBatchData(rehabData);
        }
    };

    const batchPhysicalStatusDataHandler = (result: string | undefined) => {
        let allRows = result?.split(/\r?\n|\r/);
        let rehabData = new Array<BatchData>();
        let size: number = allRows ? allRows.length : 0;
        openBatchPopup();
        if (allRows) {
            let batchUserTable = (
                <table cellSpacing={0} cellPadding={0}>
                    <colgroup>
                        <col style={{ width: '9%' }} />
                        <col style={{ width: '5%' }} />
                        <col style={{ width: '9%' }} />
                        <col style={{ width: '19%' }} />
                        <col style={{ width: '19%' }} />
                        <col style={{ width: '19%' }} />
                        <col style={{ width: '19%' }} />
                    </colgroup>
                    <thead>
                        <tr>
                            <th>방문날짜</th>
                            <th>성명</th>
                            <th>휴대폰번호</th>
                            <th>신장</th>
                            <th>체중</th>
                            <th>최저혈압</th>
                            <th>최고혈압</th>
                        </tr>
                    </thead>
                    <tbody>
                        {allRows.map((rowStr, i) => {
                            let row = rowStr.split(',');
                            let rowLength = row.length;
                            if (i > 0) {
                                if (rowLength == 10) {
                                    let data = new PhysicalStatus();

                                    data.height = row[6];
                                    data.weight = row[7];
                                    data.lBloodPressure = row[8];
                                    data.hBloodPressure = row[9];

                                    let visitDate = DateUtils.getDateForm(new Date(row[0]));
                                    let phoneNumber = row[4].replace(/-/gi, '');

                                    let rehabJson = JSON.stringify(data);

                                    let uploadingData = new BatchData();

                                    uploadingData.visiteDate = visitDate;
                                    uploadingData.name = row[3];
                                    uploadingData.phoneNumber = phoneNumber;
                                    uploadingData.batchData = rehabJson;

                                    rehabData.push(uploadingData);

                                    let content = (
                                        <tr key={i}>
                                            <td>{visitDate}</td>
                                            <td>{row[3]}</td>
                                            <td>{phoneNumber}</td>
                                            <td>{data.height}</td>
                                            <td>{data.weight}</td>
                                            <td>{data.lBloodPressure}</td>
                                            <td>{data.hBloodPressure}</td>
                                        </tr>
                                    );

                                    return content;
                                } else {
                                    confirmAlert({
                                        title: '경고',
                                        message: '등록하시는 파일의 데이터를 확인해 주십시오',
                                        buttons: [
                                            {
                                                label: '확인',
                                                onClick: () => {
                                                    closeBatchPopup();
                                                },
                                            },
                                        ],
                                    });
                                }
                            }
                        })}
                    </tbody>
                </table>
            );
            setBatchTitle('신체정보 일괄 등록 처리');
            setBatchDataView(batchUserTable);
            setBatchData(rehabData);
        }
    };

    const batchSpecialPhysicalDataHandler = (result: string | undefined) => {
        let allRows = result?.split(/\r?\n|\r/);
        let rehabData = new Array<BatchData>();
        let size: number = allRows ? allRows.length : 0;
        openBatchPopup();
        if (allRows) {
            let batchUserTable = (
                <table cellSpacing={0} cellPadding={0}>
                    <colgroup>
                        <col style={{ width: '9%' }} />
                        <col style={{ width: '5%' }} />
                        <col style={{ width: '9%' }} />
                    </colgroup>
                    <thead>
                        <tr>
                            <th>방문날짜</th>
                            <th>성명</th>
                            <th>휴대폰번호</th>
                        </tr>
                    </thead>
                    <tbody>
                        {allRows.map((rowStr, i) => {
                            let row = rowStr.split(',');
                            let rowLength = row.length;
                            console.log(rowLength);
                            if (i > 0) {
                                if (rowLength == 130) {
                                    let data = new SpecialPhysical();

                                    data.bioDex.pt60ExtL = row[3];
                                    data.bioDex.pt60FlxL = row[4];
                                    data.bioDex.pt180ExtL = row[5];
                                    data.bioDex.pt180FlxL = row[6];
                                    data.bioDex.pt300ExtL = row[7];
                                    data.bioDex.pt300FlxL = row[8];
                                    data.bioDex.pt60ExtR = row[9];
                                    data.bioDex.pt60FlxR = row[10];
                                    data.bioDex.pt180ExtR = row[11];
                                    data.bioDex.pt180FlxR = row[12];
                                    data.bioDex.pt300ExtR = row[13];
                                    data.bioDex.pt300FlxR = row[14];

                                    data.bioDex.ptb60ExtL = row[15];
                                    data.bioDex.ptb60FlxL = row[16];
                                    data.bioDex.ptb180ExtL = row[17];
                                    data.bioDex.ptb180FlxL = row[18];
                                    data.bioDex.ptb300ExtL = row[19];
                                    data.bioDex.ptb300FlxL = row[20];
                                    data.bioDex.ptb60ExtR = row[21];
                                    data.bioDex.ptb60FlxR = row[22];
                                    data.bioDex.ptb180ExtR = row[23];
                                    data.bioDex.ptb180FlxR = row[24];
                                    data.bioDex.ptb300ExtR = row[25];
                                    data.bioDex.ptb300FlxR = row[26];

                                    data.bioDex.torq30_60ExtL = row[27];
                                    data.bioDex.torq30_60FlxL = row[28];
                                    data.bioDex.torq30_180ExtL = row[29];
                                    data.bioDex.torq30_180FlxL = row[30];
                                    data.bioDex.torq30_300ExtL = row[31];
                                    data.bioDex.torq30_300FlxL = row[32];
                                    data.bioDex.torq30_60ExtR = row[33];
                                    data.bioDex.torq30_60FlxR = row[34];
                                    data.bioDex.torq30_180ExtR = row[35];
                                    data.bioDex.torq30_180FlxR = row[36];
                                    data.bioDex.torq30_300ExtR = row[37];
                                    data.bioDex.torq30_300FlxR = row[38];

                                    data.bioDex.torq18_60ExtL = row[39];
                                    data.bioDex.torq18_60FlxL = row[40];
                                    data.bioDex.torq18_180ExtL = row[41];
                                    data.bioDex.torq18_180FlxL = row[42];
                                    data.bioDex.torq18_300ExtL = row[43];
                                    data.bioDex.torq18_300FlxL = row[44];
                                    data.bioDex.torq18_60ExtR = row[45];
                                    data.bioDex.torq18_60FlxR = row[46];
                                    data.bioDex.torq18_180ExtR = row[47];
                                    data.bioDex.torq18_180FlxR = row[48];
                                    data.bioDex.torq18_300ExtR = row[49];
                                    data.bioDex.torq18_300FlxR = row[50];

                                    data.bioDex.cofe60ExtL = row[51];
                                    data.bioDex.cofe60FlxL = row[52];
                                    data.bioDex.cofe180ExtL = row[53];
                                    data.bioDex.cofe180FlxL = row[54];
                                    data.bioDex.cofe300ExtL = row[55];
                                    data.bioDex.cofe300FlxL = row[56];
                                    data.bioDex.cofe60ExtR = row[57];
                                    data.bioDex.cofe60FlxR = row[58];
                                    data.bioDex.cofe180ExtR = row[59];
                                    data.bioDex.cofe180FlxR = row[60];
                                    data.bioDex.cofe300ExtR = row[61];
                                    data.bioDex.cofe300FlxR = row[62];

                                    data.bioDex.totWo60ExtL = row[63];
                                    data.bioDex.totWo60FlxL = row[64];
                                    data.bioDex.totWo180ExtL = row[65];
                                    data.bioDex.totWo180FlxL = row[66];
                                    data.bioDex.totWo300ExtL = row[67];
                                    data.bioDex.totWo300FlxL = row[68];
                                    data.bioDex.totWo60ExtR = row[69];
                                    data.bioDex.totWo60FlxR = row[70];
                                    data.bioDex.totWo180ExtR = row[71];
                                    data.bioDex.totWo180FlxR = row[72];
                                    data.bioDex.totWo300ExtR = row[73];
                                    data.bioDex.totWo300FlxR = row[74];

                                    data.bioDex.workFat60ExtL = row[75];
                                    data.bioDex.workFat60FlxL = row[76];
                                    data.bioDex.workFat180ExtL = row[77];
                                    data.bioDex.workFat180FlxL = row[78];
                                    data.bioDex.workFat300ExtL = row[79];
                                    data.bioDex.workFat300FlxL = row[80];
                                    data.bioDex.workFat60ExtR = row[81];
                                    data.bioDex.workFat60FlxR = row[82];
                                    data.bioDex.workFat180ExtR = row[83];
                                    data.bioDex.workFat180FlxR = row[84];
                                    data.bioDex.workFat300ExtR = row[85];
                                    data.bioDex.workFat300FlxR = row[86];

                                    data.bioDex.avgPow60ExtL = row[87];
                                    data.bioDex.avgPow60FlxL = row[88];
                                    data.bioDex.avgPow180ExtL = row[89];
                                    data.bioDex.avgPow180FlxL = row[90];
                                    data.bioDex.avgPow300ExtL = row[91];
                                    data.bioDex.avgPow300FlxL = row[92];
                                    data.bioDex.avgPow60ExtR = row[93];
                                    data.bioDex.avgPow60FlxR = row[94];
                                    data.bioDex.avgPow180ExtR = row[95];
                                    data.bioDex.avgPow180FlxR = row[96];
                                    data.bioDex.avgPow300ExtR = row[97];
                                    data.bioDex.avgPow300FlxR = row[98];

                                    data.bioDex.agan60ExtL = row[99];
                                    data.bioDex.agan60FlxL = row[100];
                                    data.bioDex.agan180ExtL = row[101];
                                    data.bioDex.agan180FlxL = row[102];
                                    data.bioDex.agan300ExtL = row[103];
                                    data.bioDex.agan300FlxL = row[104];
                                    data.bioDex.agan60ExtR = row[105];
                                    data.bioDex.agan60FlxR = row[106];
                                    data.bioDex.agan180ExtR = row[107];
                                    data.bioDex.agan180FlxR = row[108];
                                    data.bioDex.agan300ExtR = row[109];
                                    data.bioDex.agan300FlxR = row[110];

                                    data.inBody.bmi = [row[111], '', ''];
                                    data.inBody.bodyFat = row[112];
                                    data.inBody.bodyFatRate = [row[113], '', ''];
                                    data.inBody.leanBodyMass = row[114];
                                    data.inBody.abdominalFatRate = [row[115], '', ''];
                                    data.inBody.bmr = row[116];

                                    data.treadMillTest.vo2 = row[117];
                                    data.treadMillTest.mets = row[118];
                                    data.treadMillTest.rq = row[119];
                                    data.treadMillTest.ve = row[120];
                                    data.treadMillTest.vt = row[121];

                                    data.footChecker.left = row[122];
                                    data.footChecker.right = row[123];
                                    data.footChecker.front = row[124];
                                    data.footChecker.back = row[125];

                                    data.formChecker.loinFlexion = row[126];
                                    data.formChecker.loinExtension = row[127];
                                    data.formChecker.loinLaterFR = row[128];
                                    data.formChecker.loinLaterFL = row[129];

                                    let visitDate = DateUtils.getDateForm(new Date(row[0]));
                                    let phoneNumber = row[2].replace(/-/gi, '');

                                    let rehabJson = JSON.stringify(data);

                                    let uploadingData = new BatchData();

                                    uploadingData.visiteDate = visitDate;
                                    uploadingData.name = row[1];
                                    uploadingData.phoneNumber = phoneNumber;
                                    uploadingData.batchData = rehabJson;

                                    rehabData.push(uploadingData);

                                    let content = (
                                        <tr key={i}>
                                            <td>{visitDate}</td>
                                            <td>{row[1]}</td>
                                            <td>{phoneNumber}</td>
                                        </tr>
                                    );

                                    return content;
                                } else {
                                    confirmAlert({
                                        title: '경고',
                                        message: '등록하시는 파일의 데이터를 확인해 주십시오',
                                        buttons: [
                                            {
                                                label: '확인',
                                                onClick: () => {
                                                    closeBatchPopup();
                                                },
                                            },
                                        ],
                                    });
                                }
                            }
                        })}
                    </tbody>
                </table>
            );
            setBatchTitle('전문체력측정기록 일괄 등록 처리');
            setBatchDataView(batchUserTable);
            setBatchData(rehabData);
        }
    };

    const batchDataHandler = () => {
        if (batchType === 'uploadUser') {
            userService.batchRegistrationUser(batchData as UserDetailInfo[]).then((response: any) => {
                confirmPopup('등록 성공', '정보가 성공적으로 등록 되었습니다.');
            });
        } else if (batchType === 'uploadCondi') {
            userService.batchConditioningData(batchData as BatchData[]).then((response: any) => {
                confirmPopup('등록 성공', '컨디셔닝 정보가 일괄 등록 성공하였습니다.');
            });
        } else if (batchType === 'uploadRehab') {
            userService.batchRehabProgramData(batchData as BatchData[]).then((response: any) => {
                confirmPopup('등록성공', '재활프로그램 정보가 일괄 등록 성공하였습니다.');
            });
        } else if (batchType === 'uploadBasalPhysical') {
            userService.batchBasalPhysicalData(batchData as BatchData[]).then((response: any) => {
                confirmPopup('등록성공', '기초체력측정 정보가 일괄 등록 성공하였습니다.');
            });
        } else if (batchType === 'uploadPhysicalStatus') {
            userService.batchPhysicalStatusData(batchData as BatchData[]).then((response: any) => {
                confirmPopup('등록성공', '신체상태 정보가 일괄 등록 성공하였습니다.');
            });
        } else if (batchType === 'uploadSpecialPhysical') {
            userService.batchSpecialPhysicalData(batchData as BatchData[]).then((response: any) => {
                confirmPopup('등록성공', '전문체력측정 정보가 일괄 등록 성공하였습니다.');
            });
        }
    };

    const confirmPopup = (title: string, message: string) => {
        confirmAlert({
            title: title,
            message: message,
            buttons: [
                {
                    label: '확인',
                    onClick: () => {
                        closeBatchPopup();
                    },
                },
            ],
        });
    };

    return (
        <Layout path={path} naviTitle={title} menuTitle={menuTitle}>
            <div className="divTable">
                <div className="divTr">
                    <div className="divTd">
                        <button
                            className="btn btn-warning"
                            onClick={() => {
                                history.replace({ pathname: '/rehab/manage/user' });
                            }}
                            style={{ float: 'right' }}
                        >
                            돌아가기
                        </button>
                    </div>
                </div>
                <div className="divTr">
                    <div className="divTd">
                        <div>
                            <span style={{ fontSize: '15px', fontWeight: 700 }}>* 사용자 정보 일괄 등록 : </span>
                        </div>
                        <input
                            id="uploadUser"
                            type="file"
                            accept=".csv"
                            onChange={changeFileHandler}
                            className="btn"
                            style={{ marginLeft: '50px', marginTop: '5px' }}
                        />
                    </div>
                </div>

                <div className="divTr">
                    <div className="divTd">
                        <div style={{ marginTop: '45px' }}>
                            <span>주의!</span>
                        </div>
                        <div>
                            <span>
                                &lt;컨디셔닝, 재활프로그램&gt; 정보는 사용자가 등록 되어 있는 경우에 데이터가 정상적으로 등록 될 수 있습니다. <br />
                                사용자를 먼저 확인하시고 데이터를 등록 하시기 바랍니다.
                                <br />
                                그리고 동일한 날짜에 동일 재활 정보가 저장 될 경우 기존 입력 하신 데이터를 덮어 쓸 수 있습니다. 주의 하시기 바랍니다.
                            </span>
                        </div>
                    </div>
                </div>
                <div className="divTr">
                    <div className="divTd">
                        <div style={{ marginTop: '15px' }}>
                            <span style={{ fontSize: '15px', fontWeight: 700 }}>* 신체정보 측정 기록 일괄 등록: </span>
                        </div>
                        <input
                            id="uploadPhysicalStatus"
                            type="file"
                            accept=".csv"
                            onChange={changeFileHandler}
                            className="btn"
                            style={{ marginLeft: '50px', marginTop: '5px' }}
                        />
                    </div>
                </div>
                <div className="divTr">
                    <div className="divTd">
                        <div style={{ marginTop: '15px' }}>
                            <span style={{ fontSize: '15px', fontWeight: 700 }}>* 기초체력 측정 정보 일괄 등록: </span>
                        </div>
                        <input
                            id="uploadBasalPhysical"
                            type="file"
                            accept=".csv"
                            onChange={changeFileHandler}
                            className="btn"
                            style={{ marginLeft: '50px', marginTop: '5px' }}
                        />
                    </div>
                </div>
                <div className="divTr">
                    <div className="divTd">
                        <div style={{ marginTop: '5px' }}>
                            <span style={{ fontSize: '15px', fontWeight: 700 }}>* 전문체력 측정 정보 일괄 등록: </span>
                        </div>
                        <input
                            id="uploadSpecialPhysical"
                            type="file"
                            accept=".csv"
                            onChange={changeFileHandler}
                            className="btn"
                            style={{ marginLeft: '50px', marginTop: '5px' }}
                            multiple
                        />
                    </div>
                </div>
                <div className="divTr">
                    <div className="divTd">
                        <div style={{ marginTop: '15px' }}>
                            <span style={{ fontSize: '15px', fontWeight: 700 }}>* 사용자 컨디셔닝 정보 일괄 등록: </span>
                        </div>
                        <input
                            id="uploadCondi"
                            type="file"
                            accept=".csv"
                            onChange={changeFileHandler}
                            className="btn"
                            style={{ marginLeft: '50px', marginTop: '5px' }}
                        />
                    </div>
                </div>
                <div className="divTr">
                    <div className="divTd">
                        <div style={{ marginTop: '15px' }}>
                            <span style={{ fontSize: '15px', fontWeight: 700 }}>* 사용자 재활프로그램 정보 일괄 등록: </span>
                        </div>
                        <input
                            id="uploadRehab"
                            type="file"
                            accept=".csv"
                            onChange={changeFileHandler}
                            className="btn"
                            style={{ marginLeft: '50px', marginTop: '5px' }}
                        />
                    </div>
                </div>
            </div>
            <div id="center-popup-wrap" style={openPopup}>
                <div className={contrPopup} data-wow-delay="0.2s">
                    <div className="divTable">
                        <div className="divTr">
                            <div style={{ display: 'table-cell' }}>
                                <button
                                    className="btn"
                                    onClick={() => {
                                        closeBatchPopup();
                                    }}
                                    style={{ float: 'right', fontSize: '15px' }}
                                >
                                    취소
                                </button>
                                <button className="btn btn-danger" onClick={batchDataHandler} style={{ float: 'right', fontSize: '15px' }}>
                                    등록
                                </button>
                            </div>
                        </div>
                        <div className="divTr">
                            <div className="divTd" style={{ padding: '15px 0px 5px 0px', fontSize: '25px', fontWeight: 700 }}>
                                {batchTitle}
                            </div>
                        </div>
                        <div className="divTr">
                            <div className="divTd" style={{ padding: '15px 0px 15px 0px' }}>
                                {batchDataView}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}
