import React, { useEffect, useState } from 'react';
import VisitedMemberAccumInfo from '../../../domain/visitedInfo/statistics/VisitedMemberAccumInfo';
import Code from '../../../domain/code/Code';
import AdminTable from '../../../component/admin/AdminTable';
import TotalVisitedTeamInfoSearchCondition from '../../../domain/visitedInfo/statistics/TotalVisitedTeamInfoSearchCondition';
import VisitedTeamInfoStatisticsServiceImpl from '../../../service/visitedInfo/impl/VisitedTeamInfoStatisticsServiceImpl';
import ChartDataUtils from '../../../utils/ChartDataUtils';
import DateUtils from '../../../utils/DateUtils';

interface VisitedTeamStatusBySportTypeTableProps {
    searchCondition?: TotalVisitedTeamInfoSearchCondition;
    sportsCodes?: Code[];
    visitedTotalStatusBySportType?: VisitedMemberAccumInfo;
    visitedStatusBySportType?: VisitedMemberAccumInfo[];
}
const statisticsService: VisitedTeamInfoStatisticsServiceImpl = new VisitedTeamInfoStatisticsServiceImpl();

function VisitedTeamTotalBySportTypeTables(props: VisitedTeamStatusBySportTypeTableProps) {
    const [typeDetailTable, setTypeDetailTable] = useState<any>(<></>);

    const typeStatus: VisitedMemberAccumInfo[] | undefined = props.visitedStatusBySportType;
    const [selectedCodeId, setSelectedCodeId] = useState<string>();

    let selectedlHandler = (evt: any) => {
        let selectedCodeId = evt.currentTarget.id;
        let condition = props.searchCondition ? props.searchCondition : new TotalVisitedTeamInfoSearchCondition();
        condition.sportCodeIds = selectedCodeId;
        let codes = props.sportsCodes;
        let sportsName = '';

        codes?.map((code, idx) => {
            if (code.codeId === Number(selectedCodeId)) {
                sportsName = code.name;
            }
        });

        statisticsService.getVisitedTeamSportTypeStatusByRegion(condition).then((data) => {
            let detailTypeTable = (
                <>
                    <table>
                        <thead>
                            <tr>
                                <td colSpan={5} style={{ textAlign: 'left', paddingLeft: '5px', background: '#474747' }}>
                                    <i className="fa fa-check"></i>&nbsp;&nbsp; 종목 별 방문 지역 정보 요약 (선택종목: {sportsName})
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="txt-center" style={{ textAlign: 'right', background: '#d9d9d9', width: '15%', height: '30px' }}>
                                    방문 지역
                                </td>
                                <td className="txt-right" style={{ textAlign: 'right', background: '#d9d9d9', width: '15%' }}>
                                    팀 수
                                </td>
                                <td className="txt-right" style={{ textAlign: 'right', background: '#d9d9d9', width: '15%' }}>
                                    방문 횟수
                                </td>
                                <td className="txt-right" style={{ textAlign: 'right', background: '#d9d9d9', width: '15%', height: '30px' }}>
                                    평균 방문 인원
                                </td>
                                <td className="txt-right" style={{ textAlign: 'right', background: '#d9d9d9', width: '15%' }}>
                                    평균 방문 일수
                                </td>
                                {/* <td className="txt-center" style={{ background: '#d9d9d9', width: '40%' }}>
                                    방문 기간
                                </td> */}
                            </tr>
                            {data?.length > 0 ? (
                                data.map((state: VisitedMemberAccumInfo, index: number) => {
                                    const { startDate, endDate } =
                                        state?.firstVisitedDate && state?.lastVisitedDate
                                            ? DateUtils.getDate(state?.firstVisitedDate, state?.lastVisitedDate)
                                            : DateUtils.getDate('', '');
                                    return (
                                        <tr key={index}>
                                            <td>{state?.regionCode?.name}</td>
                                            <td style={{ textAlign: 'right' }}>{state?.teamCnt} 팀</td>
                                            <td style={{ textAlign: 'right' }}>{state?.teamVisitedCnt} 회</td>
                                            <td style={{ textAlign: 'right' }}>{state.visitedMemberAvg}명</td>
                                            <td style={{ textAlign: 'right' }}>{state.visitedDayAvg}일</td>
                                            {/* <td>
                                                {state?.firstVisitedDate} ~ {state?.lastVisitedDate}&nbsp; &lt;
                                                {DateUtils.getDayGap(startDate, endDate)} 일간&gt;
                                            </td> */}
                                        </tr>
                                    );
                                })
                            ) : (
                                <tr>
                                    <td colSpan={4}>
                                        <h5>조회 조건에 맞는 내용이 없습니다.</h5>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </>
            );
            setTypeDetailTable(detailTypeTable);
        });
    };

    useEffect(() => {
        setTypeDetailTable(
            <table>
                <thead>
                    <tr>
                        <td colSpan={5} style={{ textAlign: 'left', paddingLeft: '5px', background: '#474747' }}>
                            <i className="fa fa-check"></i>&nbsp;&nbsp; 종목 별 방문 지역 정보 요약 (선택종목: )
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="txt-center" style={{ textAlign: 'right', background: '#d9d9d9', width: '15%', height: '30px' }}>
                            방문 지역
                        </td>
                        <td className="txt-right" style={{ textAlign: 'right', background: '#d9d9d9', width: '15%' }}>
                            팀 수
                        </td>
                        <td className="txt-right" style={{ textAlign: 'right', background: '#d9d9d9', width: '15%' }}>
                            방문 횟수
                        </td>
                        <td className="txt-right" style={{ textAlign: 'right', background: '#d9d9d9', width: '15%', height: '30px' }}>
                            평균 방문 인원
                        </td>
                        <td className="txt-right" style={{ textAlign: 'right', background: '#d9d9d9', width: '15%' }}>
                            평균 방문 일수
                        </td>
                        {/* <td className="txt-center" style={{ background: '#d9d9d9', width: '40%' }}>
                            방문 기간
                        </td> */}
                    </tr>
                    <tr>
                        <td colSpan={5} className="txt-center blue" style={{ height: '30px' }}>
                            종목을 선택 하여 주십시오.
                        </td>
                    </tr>
                </tbody>
            </table>,
        );
    }, [typeStatus]);

    let typeTotal = props.visitedTotalStatusBySportType;
    let dateGap =
        typeTotal?.firstVisitedDate && typeTotal?.lastVisitedDate
            ? DateUtils.getStrDayGap(typeTotal?.firstVisitedDate, typeTotal?.lastVisitedDate) + 1
            : 0;
    const typeTotalTable = (
        <>
            <table>
                <thead>
                    <tr>
                        <td colSpan={10} style={{ textAlign: 'left', paddingLeft: '5px', background: '#474747' }}>
                            <i className="fa fa-check"></i>&nbsp;&nbsp;유치 팀 정보 종목 별 집계 ({typeTotal?.firstVisitedDate} ~{' '}
                            {typeTotal?.lastVisitedDate} &lt;{dateGap} 일간&gt;)
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="txt-center" style={{ background: '#d9d9d9', width: '8%', height: '30px' }}>
                            총 유치 팀 종목 수
                        </td>
                        <td className="txt-left" style={{ width: '8%' }}>
                            {typeTotal ? ChartDataUtils.getNumberFormat(Number(typeTotal.sportTypeCnt)) : 0} 종목
                        </td>
                        <td className="txt-center" style={{ background: '#d9d9d9', width: '8%', height: '30px' }}>
                            총 유치 팀 수
                        </td>
                        <td className="txt-left" style={{ width: '8%' }}>
                            {typeTotal ? ChartDataUtils.getNumberFormat(Number(typeTotal.teamCnt)) : 0} 팀
                        </td>
                        <td className="txt-center" style={{ background: '#d9d9d9', width: '8%', height: '30px' }}>
                            총 방문 횟수
                        </td>
                        <td className="txt-left" style={{ width: '8%' }}>
                            {typeTotal ? ChartDataUtils.getNumberFormat(Number(typeTotal.teamVisitedCnt)) : 0} 회
                        </td>
                        {/* <td className="txt-center" style={{ background: '#d9d9d9', width: '8%', height: '30px' }}>
                            총 유치 일별 인원
                        </td>
                        <td className="txt-left" style={{ width: '8%' }}>
                            {typeTotal ? ChartDataUtils.getNumberFormat(Number(typeTotal.visitedMemberCnt)) : 0} 명
                        </td> */}
                        <td className="txt-center" style={{ background: '#d9d9d9', width: '8%', height: '30px' }}>
                            총 유치 연 일수
                        </td>
                        <td className="txt-left" style={{ width: '8%' }}>
                            {typeTotal ? ChartDataUtils.getNumberFormat(Number(typeTotal.visitedDay)) : 0} 일
                        </td>
                        <td className="txt-center" style={{ background: '#d9d9d9', width: '8%', height: '30px' }}>
                            총 유치 연 인원
                        </td>
                        <td className="txt-left" style={{ width: '14%' }}>
                            {typeTotal ? ChartDataUtils.getNumberFormat(Number(typeTotal.visitedMemberCntOfYear)) : 0} 명
                        </td>
                    </tr>
                </tbody>
            </table>
        </>
    );

    let typeTable = (
        <>
            <table>
                <thead>
                    <tr>
                        <td colSpan={7} style={{ textAlign: 'left', paddingLeft: '5px', background: '#474747' }}>
                            <i className="fa fa-check"></i>&nbsp;&nbsp;종목 별 정보 요약
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="txt-center" style={{ background: '#d9d9d9', width: '10%', height: '30px' }}>
                            종목
                        </td>
                        <td className="txt-right" style={{ background: '#d9d9d9', width: '12%' }}>
                            팀 수
                        </td>
                        <td className="txt-right" style={{ background: '#d9d9d9', width: '15%' }}>
                            방문 횟수
                        </td>
                        <td className="txt-right" style={{ background: '#d9d9d9', width: '15%' }}>
                            방문 일수
                        </td>
                        <td className="txt-right" style={{ background: '#d9d9d9', width: '15%' }}>
                            방문 인원
                        </td>
                        <td className="txt-right" style={{ background: '#d9d9d9', width: '18%', height: '30px' }}>
                            방문 연 인원
                        </td>
                        <td className="txt-center" style={{ background: '#d9d9d9', width: '15%' }}>
                            상세정보
                        </td>
                    </tr>
                    {typeStatus && typeStatus.length > 0 ? (
                        typeStatus.map((state: VisitedMemberAccumInfo, index: number) => {
                            let sportCodeId: string = state?.sportCode?.codeId ? state?.sportCode?.codeId.toString() : 'Empty';
                            return (
                                <tr key={index}>
                                    <td>{state?.sportCode?.name}</td>
                                    <td style={{ textAlign: 'right' }}>{ChartDataUtils.getNumberFormat(Number(state?.teamCnt))} 팀</td>
                                    <td style={{ textAlign: 'right' }}>{ChartDataUtils.getNumberFormat(Number(state?.teamVisitedCnt))} 회</td>
                                    <td style={{ textAlign: 'right' }}>{ChartDataUtils.getNumberFormat(Number(state.visitedDay))} 일</td>
                                    <td style={{ textAlign: 'right' }}>{ChartDataUtils.getNumberFormat(Number(state.visitedMemberCnt))} 명</td>
                                    <td style={{ textAlign: 'right' }}>{ChartDataUtils.getNumberFormat(Number(state.visitedMemberCntOfYear))} 명</td>
                                    <td>
                                        <button
                                            id={sportCodeId}
                                            className="bg-light-blue"
                                            style={{ fontWeight: 'bold', borderRadius: '4px', padding: '3px' }}
                                            onClick={selectedlHandler}
                                        >
                                            <i className="fas fs-search"></i>
                                            상세확인
                                        </button>
                                    </td>
                                </tr>
                            );
                        })
                    ) : (
                        <tr>
                            <td colSpan={7}>
                                <h5>조회 조건에 맞는 내용이 없습니다.</h5>
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </>
    );

    return (
        <>
            <div className="flex-basic">
                <div className="member-list" style={{ width: '100%' }}>
                    {typeTotalTable}
                </div>
            </div>
            <div className="two-wrap">
                <div className="half" style={{ width: '35%' }}>
                    <div className="member-list" style={{ width: '98%' }}>
                        {typeTable}
                    </div>
                </div>
                <div className="half" style={{ width: '65%' }}>
                    <div className="member-list" style={{ width: '100%' }}>
                        {typeDetailTable}
                    </div>
                </div>
            </div>
        </>
    );
}
export default VisitedTeamTotalBySportTypeTables;
