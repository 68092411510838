import { JsonObject, JsonProperty } from "json2typescript";
import Roles from './Roles';

@JsonObject("authority")
class Authority {
    @JsonProperty("userNo")
    userNo?: string;
    @JsonProperty("userRoleNo")
    userRoleNo?: number;
    @JsonProperty("authority")
    authority: string = '';
    @JsonProperty("roleNo")
    roleNo?: number;
    @JsonProperty("roles")
    roles?: Roles;
}

export default Authority;