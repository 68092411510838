import { JsonConvert } from 'json2typescript';
import React, { useState, useEffect } from 'react';
import AnswerItem from '../../../../../domain/AnswerItem';
import CheckCondition from '../../../../../domain/recording/CheckCondition';
import QnA from '../../../../../domain/QnA';
import RehabTargetHistory from '../../../../../domain/RehabTargetHistory';
import TargetPerson from '../../../../../domain/TargetPerson';
import TopsForm from '../../../questionnaires/forms/2TopsForm';
import SpadiForm from '../../../questionnaires/forms/3SpadiForm';
import StressCheckForm from '../../../questionnaires/forms/4StressCheckForm';
import WHOQOL from '../../../questionnaires/forms/5WHOQQOL';
import FABQ from '../../../questionnaires/forms/6FABQ';
import IESRK from '../../../questionnaires/forms/7IESRK';

interface IProps {
    qnaList: QnA[];
    targetPerson: TargetPerson;
    recordingItem?: RehabTargetHistory;
}

const QuestionnaireResultComponent = (props: IProps) => {
    let qnaTitles = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J'];
    let itemNumbers = ['24EA', '2460', '2461', '2462', '2463', '2464', '2465', '2466', '2467', '2468', '2469', '246A'];

    const qnaList = props.qnaList;
    const recordingItem = props.recordingItem;

    const conditions = recordingItem ? JSON.parse(recordingItem.conditions) : new CheckCondition();
    const vases = conditions.vas;

    const generateForms = (questions: any, answerStr: string | undefined, key: string) => {
        let forms = <></>;
        let answers: [] | undefined;
        let answer: { questionNum: number; itemNoOfAnswer: number } = { questionNum: -1, itemNoOfAnswer: -1 };
        let selectedAnswer: string = '';

        const answerItemsMap = new Map<number, AnswerItem>();
        if (answerStr) {
            answers = JSON.parse(answerStr);
            const jsonConvert: JsonConvert = new JsonConvert();
            const answerItems = jsonConvert.deserializeArray(JSON.parse(answerStr), AnswerItem);

            answerItems.forEach((value, index) => {
                if (value.questionNum) answerItemsMap.set(value.questionNum, value);
            });
        }

        if (key === 'NDI') {
            forms = questions.map((question: any, num: number) => {
                let items;
                if (question.items) items = question.items;
                if (answers) answer = answers[num];
                return (
                    <>
                        <h2 className="center2" key={num + '_question'}>
                            제 {question.questionNum}항목 - {question.question}
                        </h2>
                        <ul>
                            {items.map((itm: any, n: number) => {
                                let numb = String.fromCharCode(parseInt(itemNumbers[n], 16));
                                selectedAnswer = answer.itemNoOfAnswer === itm.itemNo ? 'selected' : '';
                                return (
                                    <>
                                        <li key={n + '_list'} className={selectedAnswer}>
                                            {numb}. {itm.content}
                                        </li>
                                    </>
                                );
                            })}
                        </ul>
                    </>
                );
            });
        } else if (key === 'Tops') {
            forms = <TopsForm answerItems={answerItemsMap}></TopsForm>;
        } else if (key === 'Spadi') {
            forms = <SpadiForm answerItems={answerItemsMap}></SpadiForm>;
        } else if (key === 'StressCheckForm') {
            forms = <StressCheckForm answerItems={answerItemsMap}></StressCheckForm>;
        } else if (key === 'WhoQ') {
            forms = <WHOQOL answerItems={answerItemsMap}></WHOQOL>;
        } else if (key === 'FABQ') {
            forms = <FABQ answerItems={answerItemsMap}></FABQ>;
        } else if (key === 'IESRK') {
            forms = <IESRK answerItems={answerItemsMap}></IESRK>;
        }

        return forms;
    };

    return (
        <>
            <div id="questionnaire" key={'quest'}>
                <h1 className="center">1. 설문결과</h1>
                {qnaList.map((item, idx) => {
                    let questions;
                    let answerStr = item.answer;
                    let answerNo = item.answerNo;
                    let questionnaireKey = item.questionnaireKey;

                    if (item.questions) questions = JSON.parse(item.questions);
                    if (answerNo != 0) {
                        // 답안이 포함된 Form 만 생성
                        return (
                            <div id={'questionnaire_' + idx} key={idx + '_ques'}>
                                <h2 className="center2">
                                    {qnaTitles[idx]}. {item.displayTitle}
                                </h2>
                                <div className="center-center">{item.title}</div>
                                <p>{item.description}</p>
                                <div className="tab-story-number">{generateForms(questions, answerStr, questionnaireKey)}</div>
                                {/* <!--//tab-story-number--> */}
                            </div>
                        );
                    }
                })}
            </div>
            {/* <!--//sec01--> */}

            <div id="checkConditions">
                <h1 className="center">1. VAS 통증 부위 / 강도체크</h1>
                <div className="flex-basic">
                    <div className="center-half">
                        <table className="center-check-resulttable" cellPadding="0px" cellSpacing="0px">
                            <tbody>
                                {vases?.map((vas: any, idx: number) => {
                                    let recording = vas.recording;
                                    let vasPart = vas.partName;
                                    return (
                                        <tr key={idx + '_tr'}>
                                            <td>{vas.seq}</td>
                                            <td>
                                                <input type="text" value={vasPart} placeholder="부위선택" disabled />
                                            </td>
                                            <td className="center-bg-gray">1회차</td>
                                            <td>
                                                <input type="text" value={recording[0]} onChange={() => {}} />
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
                <h1 className="center">2. 상태확인 정보</h1>
                <div id="checkConditions_1">
                    <div className="flex-basic">
                        <div className="center-half">
                            <div className="center-popup-check-in">
                                <h2 className="center">자각증상</h2>
                                <textarea rows={3} value={conditions.ss} onChange={() => {}} />
                            </div>
                        </div>
                        <div className="center-half">
                            <div className="center-popup-check-in">
                                <h2 className="center">병력</h2>
                                <textarea rows={3} value={conditions.mh} onChange={() => {}} />
                            </div>
                        </div>
                    </div>
                    <div className="flex-basic">
                        <div className="center-half">
                            <div className="center-popup-check-in">
                                <h2 className="center">가족력</h2>
                                <textarea rows={3} value={conditions.fh} onChange={() => {}} />
                            </div>
                        </div>
                        <div className="center-half">
                            <div className="center-popup-check-in">
                                <h2 className="center">생활습관</h2>
                                <textarea rows={3} value={conditions.ha} onChange={() => {}} />
                            </div>
                        </div>
                    </div>
                    <div className="flex-basic">
                        <div className="center-half">
                            <div className="center-popup-check-in">
                                <h2 className="center">선수반응상태</h2>
                                <textarea rows={3} value={conditions.rs} onChange={() => {}} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default QuestionnaireResultComponent;
