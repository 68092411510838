import { JsonObject, JsonProperty } from 'json2typescript';
import moment, { Moment, Moment as MomentTypes } from 'moment';

@JsonObject('exercisesTracking')
export default class ExercisesTracking {
    @JsonProperty('trackingNo')
    trackingNo?: number;

    @JsonProperty('targetExerNo')
    targetExerNo?: number;

    @JsonProperty('targetNo')
    targetNo?: number;

    @JsonProperty('trackingTime')
    trackingTime?: Date;

    @JsonProperty('trackingInfo')
    trackingInfo?: string;

    @JsonProperty('heartRateInfo')
    heartRateInfo?: string;

    getTrackingTime() {
        let tempDate = moment(this.trackingTime);
        let oriMonth = tempDate.month();
        tempDate.month(oriMonth - 1);
        return tempDate.format('YYYY-MM-DD hh:mm:ss');
    }
}
