class ExercisePrescriptionReportData {
    constructor(basalExercisePrescription: any, conditioningPrescription: any) {
        this.setBasalExercisePrescription(basalExercisePrescription);
        this.setConditionigPrescription(conditioningPrescription);
    }

    exercisePrescriptionSheetDate?: string;
    exercisePrescriptionSheetClinicalDiagnosisSoap?: string;
    exercisePrescriptionSheetSubjective?: string;
    exercisePrescriptionSheetObjective?: string;
    exercisePrescriptionSheetAssessment?: string;
    exercisePrescriptionSheetPlan?: string;
    exercisePrescriptionAerobicTrainingFrequency?: string;
    exercisePrescriptionAerobicTrainingWarmUp?: string; //delete
    exercisePrescriptionAerobicTrainingWarmUpStatic?: string; //new
    exercisePrescriptionAerobicTrainingWarmUpDynamic?: string; //new
    exercisePrescriptionAerobicTrainingWarmUpFoam?: string; //new
    exercisePrescriptionAerobicTrainingIntensity?: string; //new
    exercisePrescriptionAerobicTrainingIntensityVo2Peak?: string;
    exercisePrescriptionAerobicTrainingIntensityHrPeak?: string;
    exercisePrescriptionAerobicTrainingTime?: string;
    exercisePrescriptionAerobicTrainingRehabilitation?: string;

    exercisePrescriptionWeightTrainingFrequency?: string;
    exercisePrescriptionWeightTrainingWarmUp?: string; //delete
    exercisePrescriptionWeightTrainingWarmUpStatic?: string;
    exercisePrescriptionWeightTrainingWarmUpDynamic?: string;
    exercisePrescriptionWeightTrainingIntensity1rm?: string;
    exercisePrescriptionWeightTrainingTime?: string;
    exercisePrescriptionWeightTrainingRehabilitation?: string;

    setBasalExercisePrescription(basalExercisePrescription: any) {
        this.exercisePrescriptionAerobicTrainingFrequency = basalExercisePrescription.aeroFreq;
        this.exercisePrescriptionAerobicTrainingWarmUpStatic = basalExercisePrescription.aeroWarmUpStatic;
        this.exercisePrescriptionAerobicTrainingWarmUpDynamic = basalExercisePrescription.aeroWarmUpDyna;
        this.exercisePrescriptionAerobicTrainingWarmUpFoam = basalExercisePrescription.aeroWarmUpFoam;
        // aero_intensity
        this.exercisePrescriptionAerobicTrainingIntensity = basalExercisePrescription.aeroIntensity;
        this.exercisePrescriptionAerobicTrainingIntensityVo2Peak = basalExercisePrescription.aeroIntensityVo2;
        this.exercisePrescriptionAerobicTrainingIntensityHrPeak = basalExercisePrescription.aeroIntensityHr;
        this.exercisePrescriptionAerobicTrainingTime = basalExercisePrescription.aeroTime;
        this.exercisePrescriptionAerobicTrainingRehabilitation = basalExercisePrescription.aeroType;

        this.exercisePrescriptionWeightTrainingFrequency = basalExercisePrescription.weightFreq;
        this.exercisePrescriptionWeightTrainingWarmUpStatic = basalExercisePrescription.weightWarmUpStatic;
        this.exercisePrescriptionWeightTrainingWarmUpDynamic = basalExercisePrescription.weightWarmUpDyna;

        // FIXME: 이거 수치 일치 하지 않음. 리포팅에는
        // exercisePrescriptionWeightTrainingIntensity1rm 있으나 데이터에는 없음
        this.exercisePrescriptionWeightTrainingIntensity1rm = basalExercisePrescription.weightIntensityHr;
        this.exercisePrescriptionWeightTrainingTime = basalExercisePrescription.weightTime;
        this.exercisePrescriptionWeightTrainingRehabilitation = basalExercisePrescription.weightType;
    }

    // bodyConditionChangesDate ?: number;
    bodyConditionChangesHopsSectionOne?: number;
    bodyConditionChangesHopsSectionTwo?: number;
    bodyConditionChangesHopsSectionThree?: number;
    bodyConditionChangesHistorySectionOne?: number;
    bodyConditionChangesHistorySectionTwo?: number;
    bodyConditionChangesHistorySectionThree?: number;
    bodyConditionChangesObservationSectionOne?: number;
    bodyConditionChangesObservationSectionTwo?: number;
    bodyConditionChangesObservationSectionThree?: number;
    bodyConditionChangesPalpationSectionOne?: number;
    bodyConditionChangesPalpationSectionTwo?: number;
    bodyConditionChangesPalpationSectionThree?: number;
    bodyConditionChangesSpecialTestSectionOne?: number;
    bodyConditionChangesSpecialTestSectionTwo?: number;
    bodyConditionChangesSpecialTestSectionThree?: number;

    setBodyConditionPrescription(bodyPrescription: any) {
        // bodyConditionChangesHopsSectionOne = bodyPrescription.
    }

    conditioningIceSectionOne?: string;
    conditioningIceSectionTwo?: string;
    conditioningIceSectionThree?: string;
    conditioningMassageSectionOne?: string;
    conditioningMassageSectionTwo?: string;
    conditioningMassageSectionThree?: string;
    conditioningSlingSectionOne?: string;
    conditioningSlingSectionTwo?: string;
    conditioningSlingSectionThree?: string;
    conditioningPainDoctorSectionOne?: string;
    conditioningPainDoctorSectionTwo?: string;
    conditioningPainDoctorSectionThree?: string;
    conditioningRadioFrequencySectionOne?: string;
    conditioningRadioFrequencySectionTwo?: string;
    conditioningRadioFrequencySectionThree?: string;
    conditioningESWTSectionOne?: string;
    conditioningESWTSectionTwo?: string;
    conditioningESWTSectionThree?: string;
    conditioningVibroSectionOne?: string;
    conditioningVibroSectionTwo?: string;
    conditioningVibroSectionThree?: string;
    clinicalManifestationDate?: string;
    clinicalManifestationOneDate?: string;
    clinicalManifestationTwoDate?: string;
    clinicalManifestationThreeDate?: string;
    clinicalManifestationSubjectiveSymptomsOne?: string;
    clinicalManifestationSubjectiveSymptomsTwo?: string;
    clinicalManifestationSubjectiveSymptomsThree?: string;
    clinicalManifestationReactionStateOne?: string;
    clinicalManifestationReactionStateTwo?: string;
    clinicalManifestationReactionStateThree?: string;
    clinicalManifestationPsychologicalReactionOne?: string;
    clinicalManifestationPsychologicalReactionTwo?: string;
    clinicalManifestationPsychologicalReactionThree?: string;
    clinicalManifestationComprehensiveOpinionOne?: string;
    clinicalManifestationComprehensiveOpinionTwo?: string;
    clinicalManifestationComprehensiveOpinionThree?: string;

    setConditionigPrescription(conditioningPrescription: any) {
        console.log(conditioningPrescription);
        this.conditioningIceSectionOne = conditioningPrescription.icing[0];
        this.conditioningIceSectionTwo = conditioningPrescription.icing[1];
        this.conditioningIceSectionThree = conditioningPrescription.icing[2];

        this.conditioningMassageSectionOne = conditioningPrescription.massage[0];
        this.conditioningMassageSectionTwo = conditioningPrescription.massage[1];
        this.conditioningMassageSectionThree = conditioningPrescription.massage[2];

        this.conditioningSlingSectionOne = conditioningPrescription.sling[0];
        this.conditioningSlingSectionTwo = conditioningPrescription.sling[1];
        this.conditioningSlingSectionThree = conditioningPrescription.sling[2];

        this.conditioningPainDoctorSectionOne = conditioningPrescription.painDoctor[0];
        this.conditioningPainDoctorSectionTwo = conditioningPrescription.painDoctor[1];
        this.conditioningPainDoctorSectionThree = conditioningPrescription.painDoctor[2];

        this.conditioningRadioFrequencySectionOne = conditioningPrescription.radioFrq[0];
        this.conditioningRadioFrequencySectionTwo = conditioningPrescription.radioFrq[1];
        this.conditioningRadioFrequencySectionThree = conditioningPrescription.radioFrq[2];

        this.conditioningESWTSectionOne = conditioningPrescription.eswt[0];
        this.conditioningESWTSectionTwo = conditioningPrescription.eswt[1];
        this.conditioningESWTSectionThree = conditioningPrescription.eswt[2];

        this.conditioningVibroSectionOne = conditioningPrescription.vibro[0];
        this.conditioningVibroSectionTwo = conditioningPrescription.vibro[1];
        this.conditioningVibroSectionThree = conditioningPrescription.vibro[2];
    }
}

export default ExercisePrescriptionReportData;
