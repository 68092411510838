import React from 'react';

interface MyPageDetailRight {
    children?: React.ReactNode;
}

function MyPageDetailRight(props: MyPageDetailRight) {
    return (
        <div className="my-right-detail">
            {props.children}
        </div>
    )
}

export default MyPageDetailRight;