import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import MainLayout from './MainLayout';
import TrainningRouter from './trainning/router/TrainningRouter';
import TouringRouter from './touring/router/TouringRouter';
import LoginContainer from './login/page/LoginContainer';
import OAuth2HandlerComponent from '../component/oAuth/OAuth2HandlerComponent';
import MyPageRouter from './mypage/router/MyPageRouter';
import SearchPageContainer from './search/page/SearchPageContainer';
import SearchListContainer from './search/page/SearchListContainer';
import NearSearchPageContainer from './search/page/NearSearchPageContainer';
import CurrPositionOfNearSearchPageContainer from './search/page/CurrPositionOfNearSearchPageContainer';
import SignupPageForSNSContainer from './login/page/SignupPageForSNSContainer';
import AdminRouter from './admin/router/AdminRouter';
import RehabilitateRouter from './rehabilitation/router/RehabilitationRouter';
import RehabCenterRouter from './rehabcenter/router/RehabCenterRouter';
import InfraRouter from './infra/router/InfraRouter';
import PrivateRoute from './PrivateRouter';
import SitePrRouter from './sitePr/router/SitePrRouter';
import CommonFooter from './common/CommonFooter';
import PoliciesRouter from './policies/router/PoliciesRouter';
import SignUpAgreementPage from './login/page/SignUpAgreementPage';
import SignUpUserValidationCheckPage from './login/page/SignUpUserValidationCheckPage';
import SignUpPage from './login/page/SignUpPage';
import SignUpRedirectPage from './login/page/SignUpRedirectPage';
import SearchIdUserValidationCheckPage from './login/page/SearchIdUserValidationCheckPage';
import SearchPwUserIdConfirmPage from './login/page/SearchPwUserIdConfirmPage';
import SearchIdRedirectPage from './login/page/SearchIdRedirectPage';
import SearchIdPage from './login/page/SearchIdPage';
import SearchPwUserValidationCheckPage from './login/page/SearchPwUserValidationCheckPage';
import SearchPwRedirectPage from './login/page/SearchPwRedirectPage';
import SearchPwPage from './login/page/SearchPwPage';

import UserMyPageFavorities from './mypage/page/user/favorities/UserMyPageFavoriteis';
import StatisticsRouter from './statistics/router/StatisticsRouter';

export default () => (
    <BrowserRouter>
        <Switch>
            <Route exact path="/" component={MainLayout}></Route>
            {/* <Route path="/pr" component={SitePrRouter}></Route> */}
            <Route path="/pr" component={MainLayout}></Route>
            <Route path="/trainning" component={TrainningRouter}></Route>
            <Route path="/touring" component={TouringRouter}></Route>
            {/* <PrivateRoute path="/mypage" component={MyPageRouter}></PrivateRoute> */}
            <Route path="/mypage" component={MyPageRouter}></Route>
            <Route path="/admin" component={AdminRouter}></Route>
            <Route path="/rehabilitation" component={RehabilitateRouter}></Route>
            <Route path="/status" component={StatisticsRouter}></Route>
            <Route exact path="/signup/sns" component={SignupPageForSNSContainer}></Route>
            <Route exact path="/signup/agreement" component={SignUpAgreementPage}></Route>
            <Route exact path="/signup/user-checker" component={SignUpUserValidationCheckPage}></Route>
            <Route exact path="/signup" component={SignUpPage}></Route>
            <Route exact path="/signup/redirect" component={SignUpRedirectPage}></Route>
            <Route exact path="/idfinder/redirect" component={SearchIdRedirectPage}></Route>
            <Route exact path="/idfinder/user-checker" component={SearchIdUserValidationCheckPage}></Route>
            <Route exact path="/idfinder" component={SearchIdPage}></Route>
            <Route exact path="/pwfinder/id-checker" component={SearchPwUserIdConfirmPage}></Route>
            <Route exact path="/pwfinder/user-checker" component={SearchPwUserValidationCheckPage}></Route>
            <Route exact path="/pwfinder/redirect" component={SearchPwRedirectPage}></Route>
            <Route exact path="/pwfinder" component={SearchPwPage}></Route>
            <Route path="/login" component={LoginContainer}></Route>
            <Route path="/search" component={SearchPageContainer}></Route>
            <Route path="/search-list/:searchType/:parentCategory/:categoryNo/:latitude/:longitude" component={SearchListContainer}></Route>
            <Route path="/near-search" component={NearSearchPageContainer}></Route>
            <Route path="/search-by-myposition" component={CurrPositionOfNearSearchPageContainer}></Route>
            <Route path="/rehab" component={RehabCenterRouter}></Route>
            <Route path="/oauth2/redirect" component={OAuth2HandlerComponent}></Route>
            <Route path="/infra" component={InfraRouter}></Route>
            <Route path="/policies" component={PoliciesRouter}></Route>
            <Route path="/*" component={() => <h1>없는페이지!!</h1>}></Route>
        </Switch>
        <CommonFooter></CommonFooter>
    </BrowserRouter>
);
