import React from 'react';
import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('specialPhysical')
export default class SpecialPhysical {
    @JsonProperty('inBody')
    inBody: {
        skeletalMuscle: string[];
        bmi: string[];
        bodyFat: string;
        bodyFatRate: string[];
        visceralFatLev: string[];
        abdominalFatRate: string[];
        restingMetabolicRate: string[];
        bmr: string;
        leanBodyMass: string;
        leanBodyRate: string;
        uploadIBFile: string;
    } = {
        skeletalMuscle: ['', '', ''],
        bmi: ['', '', ''],
        bodyFat: '',
        bodyFatRate: ['', '', ''],
        visceralFatLev: ['', '', ''],
        abdominalFatRate: ['', '', ''],
        restingMetabolicRate: ['', '', ''],
        bmr: '',
        leanBodyMass: '',
        leanBodyRate: '',
        uploadIBFile: '',
    };

    @JsonProperty('formChecker')
    formChecker: {
        shuolderL: string;
        shuolderR: string;
        pelvisL: string;
        pelvisR: string;
        kneeL: string;
        kneeR: string;
        ankleL: string;
        ankleR: string;
        loinFlexion: string;
        loinExtension: string;
        loinLaterFL: string;
        loinLaterFR: string;
        uploadedPomFile: string;
    } = {
        shuolderL: '',
        shuolderR: '',
        pelvisL: '',
        pelvisR: '',
        kneeL: '',
        kneeR: '',
        ankleL: '',
        ankleR: '',
        loinFlexion: '',
        loinExtension: '',
        loinLaterFL: '',
        loinLaterFR: '',
        uploadedPomFile: '',
    };

    @JsonProperty('footChecker')
    footChecker: {
        front: string;
        back: string;
        left: string;
        right: string;
        uploadedFootFile: string;
    } = {
        front: '',
        back: '',
        left: '',
        right: '',
        uploadedFootFile: '',
    };

    @JsonProperty('bioDex')
    bioDex: {
        pt60ExtL: string;
        pt60ExtR: string;
        pt60FlxL: string;
        pt60FlxR: string;
        pt180ExtL: string;
        pt180ExtR: string;
        pt180FlxL: string;
        pt180FlxR: string;
        pt300ExtL: string;
        pt300ExtR: string;
        pt300FlxL: string;
        pt300FlxR: string;
        pt15ExtL: string;
        pt15ExtR: string;
        pt15FlxL: string;
        pt15FlxR: string;

        ptb60ExtL: string;
        ptb60ExtR: string;
        ptb60FlxL: string;
        ptb60FlxR: string;
        ptb180ExtL: string;
        ptb180ExtR: string;
        ptb180FlxL: string;
        ptb180FlxR: string;
        ptb300ExtL: string;
        ptb300ExtR: string;
        ptb300FlxL: string;
        ptb300FlxR: string;
        ptb15ExtL: string;
        ptb15ExtR: string;
        ptb15FlxL: string;
        ptb15FlxR: string;

        torq30_60ExtL: string;
        torq30_60ExtR: string;
        torq30_60FlxL: string;
        torq30_60FlxR: string;
        torq30_180ExtL: string;
        torq30_180ExtR: string;
        torq30_180FlxL: string;
        torq30_180FlxR: string;
        torq30_300ExtL: string;
        torq30_300ExtR: string;
        torq30_300FlxL: string;
        torq30_300FlxR: string;
        torq30_15ExtL: string;
        torq30_15ExtR: string;
        torq30_15FlxL: string;
        torq30_15FlxR: string;

        torq18_60ExtL: string;
        torq18_60ExtR: string;
        torq18_60FlxL: string;
        torq18_60FlxR: string;
        torq18_180ExtL: string;
        torq18_180ExtR: string;
        torq18_180FlxL: string;
        torq18_180FlxR: string;
        torq18_300ExtL: string;
        torq18_300ExtR: string;
        torq18_300FlxL: string;
        torq18_300FlxR: string;
        torq18_15ExtL: string;
        torq18_15ExtR: string;
        torq18_15FlxL: string;
        torq18_15FlxR: string;

        cofe60ExtL: string;
        cofe60ExtR: string;
        cofe60FlxL: string;
        cofe60FlxR: string;
        cofe180ExtL: string;
        cofe180ExtR: string;
        cofe180FlxL: string;
        cofe180FlxR: string;
        cofe300ExtL: string;
        cofe300ExtR: string;
        cofe300FlxL: string;
        cofe300FlxR: string;
        cofe15ExtL: string;
        cofe15ExtR: string;
        cofe15FlxL: string;
        cofe15FlxR: string;

        totWo60ExtL: string;
        totWo60ExtR: string;
        totWo60FlxL: string;
        totWo60FlxR: string;
        totWo180ExtL: string;
        totWo180ExtR: string;
        totWo180FlxL: string;
        totWo180FlxR: string;
        totWo300ExtL: string;
        totWo300ExtR: string;
        totWo300FlxL: string;
        totWo300FlxR: string;
        totWo15ExtL: string;
        totWo15ExtR: string;
        totWo15FlxL: string;
        totWo15FlxR: string;

        workFat60ExtL: string;
        workFat60ExtR: string;
        workFat60FlxL: string;
        workFat60FlxR: string;
        workFat180ExtL: string;
        workFat180ExtR: string;
        workFat180FlxL: string;
        workFat180FlxR: string;
        workFat300ExtL: string;
        workFat300ExtR: string;
        workFat300FlxL: string;
        workFat300FlxR: string;
        workFat15ExtL: string;
        workFat15ExtR: string;
        workFat15FlxL: string;
        workFat15FlxR: string;

        avgPow60ExtL: string;
        avgPow60ExtR: string;
        avgPow60FlxL: string;
        avgPow60FlxR: string;
        avgPow180ExtL: string;
        avgPow180ExtR: string;
        avgPow180FlxL: string;
        avgPow180FlxR: string;
        avgPow300ExtL: string;
        avgPow300ExtR: string;
        avgPow300FlxL: string;
        avgPow300FlxR: string;
        avgPow15ExtL: string;
        avgPow15ExtR: string;
        avgPow15FlxL: string;
        avgPow15FlxR: string;

        agan60ExtL: string;
        agan60ExtR: string;
        agan60FlxL: string;
        agan60FlxR: string;
        agan180ExtL: string;
        agan180ExtR: string;
        agan180FlxL: string;
        agan180FlxR: string;
        agan300ExtL: string;
        agan300ExtR: string;
        agan300FlxL: string;
        agan300FlxR: string;
        agan15ExtL: string;
        agan15ExtR: string;
        agan15FlxL: string;
        agan15FlxR: string;

        uploadBDFile: string;
    } = {
        pt60ExtL: '',
        pt60ExtR: '',
        pt60FlxL: '',
        pt60FlxR: '',
        pt180ExtL: '',
        pt180ExtR: '',
        pt180FlxL: '',
        pt180FlxR: '',
        pt300ExtL: '',
        pt300ExtR: '',
        pt300FlxL: '',
        pt300FlxR: '',
        pt15ExtL: '',
        pt15ExtR: '',
        pt15FlxL: '',
        pt15FlxR: '',

        ptb60ExtL: '',
        ptb60ExtR: '',
        ptb60FlxL: '',
        ptb60FlxR: '',
        ptb180ExtL: '',
        ptb180ExtR: '',
        ptb180FlxL: '',
        ptb180FlxR: '',
        ptb300ExtL: '',
        ptb300ExtR: '',
        ptb300FlxL: '',
        ptb300FlxR: '',
        ptb15ExtL: '',
        ptb15ExtR: '',
        ptb15FlxL: '',
        ptb15FlxR: '',

        torq30_60ExtL: '',
        torq30_60ExtR: '',
        torq30_60FlxL: '',
        torq30_60FlxR: '',
        torq30_180ExtL: '',
        torq30_180ExtR: '',
        torq30_180FlxL: '',
        torq30_180FlxR: '',
        torq30_300ExtL: '',
        torq30_300ExtR: '',
        torq30_300FlxL: '',
        torq30_300FlxR: '',
        torq30_15ExtL: '',
        torq30_15ExtR: '',
        torq30_15FlxL: '',
        torq30_15FlxR: '',

        torq18_60ExtL: '',
        torq18_60ExtR: '',
        torq18_60FlxL: '',
        torq18_60FlxR: '',
        torq18_180ExtL: '',
        torq18_180ExtR: '',
        torq18_180FlxL: '',
        torq18_180FlxR: '',
        torq18_300ExtL: '',
        torq18_300ExtR: '',
        torq18_300FlxL: '',
        torq18_300FlxR: '',
        torq18_15ExtL: '',
        torq18_15ExtR: '',
        torq18_15FlxL: '',
        torq18_15FlxR: '',

        cofe60ExtL: '',
        cofe60ExtR: '',
        cofe60FlxL: '',
        cofe60FlxR: '',
        cofe180ExtL: '',
        cofe180ExtR: '',
        cofe180FlxL: '',
        cofe180FlxR: '',
        cofe300ExtL: '',
        cofe300ExtR: '',
        cofe300FlxL: '',
        cofe300FlxR: '',
        cofe15ExtL: '',
        cofe15ExtR: '',
        cofe15FlxL: '',
        cofe15FlxR: '',

        totWo60ExtL: '',
        totWo60ExtR: '',
        totWo60FlxL: '',
        totWo60FlxR: '',
        totWo180ExtL: '',
        totWo180ExtR: '',
        totWo180FlxL: '',
        totWo180FlxR: '',
        totWo300ExtL: '',
        totWo300ExtR: '',
        totWo300FlxL: '',
        totWo300FlxR: '',
        totWo15ExtL: '',
        totWo15ExtR: '',
        totWo15FlxL: '',
        totWo15FlxR: '',

        workFat60ExtL: '',
        workFat60ExtR: '',
        workFat60FlxL: '',
        workFat60FlxR: '',
        workFat180ExtL: '',
        workFat180ExtR: '',
        workFat180FlxL: '',
        workFat180FlxR: '',
        workFat300ExtL: '',
        workFat300ExtR: '',
        workFat300FlxL: '',
        workFat300FlxR: '',
        workFat15ExtL: '',
        workFat15ExtR: '',
        workFat15FlxL: '',
        workFat15FlxR: '',

        avgPow60ExtL: '',
        avgPow60ExtR: '',
        avgPow60FlxL: '',
        avgPow60FlxR: '',
        avgPow180ExtL: '',
        avgPow180ExtR: '',
        avgPow180FlxL: '',
        avgPow180FlxR: '',
        avgPow300ExtL: '',
        avgPow300ExtR: '',
        avgPow300FlxL: '',
        avgPow300FlxR: '',
        avgPow15ExtL: '',
        avgPow15ExtR: '',
        avgPow15FlxL: '',
        avgPow15FlxR: '',

        agan60ExtL: '',
        agan60ExtR: '',
        agan60FlxL: '',
        agan60FlxR: '',
        agan180ExtL: '',
        agan180ExtR: '',
        agan180FlxL: '',
        agan180FlxR: '',
        agan300ExtL: '',
        agan300ExtR: '',
        agan300FlxL: '',
        agan300FlxR: '',
        agan15ExtL: '',
        agan15ExtR: '',
        agan15FlxL: '',
        agan15FlxR: '',
        uploadBDFile: '',
    };

    @JsonProperty('treadMillTest')
    treadMillTest: {
        vo2: string;
        mets: string;
        ve: string;
        vt: string;
        rq: string;
        uploadTMFile: string;
    } = {
        vo2: '',
        mets: '',
        ve: '',
        vt: '',
        rq: '',
        uploadTMFile: '',
    };
}
