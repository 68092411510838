import BoardContentService from '../BoardContentService';
import AbstractService from '../../AbstractService';
import BoardContent from '../../../domain/board/BoardContent';
import APIServerConfig from '../../../config/APIServerConfig';
class BoardContentServiceImpl extends AbstractService implements BoardContentService {
    getAllBoardContent(boardTargetNo: string, boardNo: number): Promise<BoardContent[]> {
        return new Promise((resolve, rejext) => {
            APIServerConfig.recordAPIAxios.get(`/board/team/${boardTargetNo}/${boardNo}/content`)
            .then((response) => {
                let boardContents: BoardContent[] = this.jsonConvert.deserializeArray(response.data, BoardContent);
                resolve(boardContents);
            })
        });
    }
}

export default BoardContentServiceImpl;