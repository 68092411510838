import AbstractService from '../../common/service/AbstractRehabService';
import TargetPerson from '../../domain/TargetPerson';
import APIServerConfig from '../../../../config/APIServerConfig';
import RehabHistoryService from '../RehabHistoryService';
import RehabTargetHistory from '../../domain/RehabTargetHistory';

class RehabHistoryServiceImpl extends AbstractService implements RehabHistoryService {
    getRehabHistoryForTarget(targetNo: number): Promise<RehabTargetHistory[]> {
        return new Promise((handler, reject) => {
            APIServerConfig.recordAPIAxios
                .post('/rehab/history', JSON.stringify({ targetNo: targetNo }), {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                .then((response) => {
                    let targetHistory: RehabTargetHistory[] = this.jsonConvert.deserializeArray(response.data.targetHistory, RehabTargetHistory);
                    handler(targetHistory);
                });
        });
    }

    getRehabHistoryForReservation(reservationNo: string): Promise<RehabTargetHistory[]> {
        return new Promise((handler, reject) => {
            APIServerConfig.recordAPIAxios
                .post('/rehab/history', JSON.stringify({ reservationNo: reservationNo }), {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                .then((response) => {
                    let targetHistory: RehabTargetHistory[] = this.jsonConvert.deserializeArray(response.data.targetHistory, RehabTargetHistory);
                    handler(targetHistory);
                });
        });
    }

    getRecordingRehabHistoryForTarget(targetNo: number, reservationNo: string): Promise<RehabTargetHistory> {
        return new Promise((handler, reject) => {
            APIServerConfig.recordAPIAxios
                .post(
                    '/rehab/recording',
                    JSON.stringify({
                        targetNo: targetNo,
                        reservationNo: reservationNo,
                    }),
                    {
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    },
                )
                .then((response) => {
                    let targetPerson: RehabTargetHistory = this.jsonConvert.deserializeObject(response.data.targetHistory, RehabTargetHistory);
                    handler(targetPerson);
                });
        });
    }
    getRehabHistoryForUserno(userNo: string): Promise<RehabTargetHistory[]> {
        return new Promise((handler, reject) => {
            APIServerConfig.recordAPIAxios
                .post('/rehab/history/' + userNo, JSON.stringify({}), {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                .then((response) => {
                    let targetHistory: RehabTargetHistory[] = this.jsonConvert.deserializeArray(response.data.targetHistory, RehabTargetHistory);
                    handler(targetHistory);
                });
        });
    }
    manageConditionForTarget(history: RehabTargetHistory): Promise<RehabTargetHistory> {
        return new Promise((handler, reject) => {
            APIServerConfig.recordAPIAxios
                .post('/rehab/manage/checkconditions', JSON.stringify(history), {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                .then((response) => {
                    let targetPerson: RehabTargetHistory = this.jsonConvert.deserializeObject(response.data.targetHistory, RehabTargetHistory);
                    handler(targetPerson);
                });
        });
    }

    managePhysicalStatusForTarget(recording: RehabTargetHistory): Promise<RehabTargetHistory> {
        return new Promise((handler, reject) => {
            let targetNo = recording.targetNo;
            let historyNo = recording.historyNo;
            let physicalStatus = recording.physicalStatus;

            APIServerConfig.recordAPIAxios
                .post(
                    '/rehab/manage/physicalStatus',
                    JSON.stringify({
                        targetNo: targetNo,
                        historyNo: historyNo,
                        physicalStatus: physicalStatus,
                    }),
                    {
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    },
                )
                .then((response) => {
                    let targetHistory: RehabTargetHistory = this.jsonConvert.deserializeObject(response.data.targetHistory, RehabTargetHistory);
                    handler(targetHistory);
                });
        });
    }

    manageBasalPhysicalForTarget(recording: RehabTargetHistory): Promise<RehabTargetHistory> {
        return new Promise((handler, reject) => {
            let targetNo = recording.targetNo;
            let historyNo = recording.historyNo;

            let basalPhysical = recording.basalPhysical;

            APIServerConfig.recordAPIAxios
                .post(
                    '/rehab/manage/basalPhysical',
                    JSON.stringify({
                        targetNo: targetNo,
                        historyNo: historyNo,

                        basalPhysical: basalPhysical,
                    }),
                    {
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    },
                )
                .then((response) => {
                    let targetPerson: RehabTargetHistory = this.jsonConvert.deserializeObject(response.data.targetHistory, RehabTargetHistory);
                    handler(targetPerson);
                });
        });
    }

    manageSpecialPhysicalForTarget(recording: RehabTargetHistory): Promise<RehabTargetHistory> {
        return new Promise((handler, reject) => {
            let targetNo = recording.targetNo;
            let historyNo = recording.historyNo;
            let specialPhysical = recording.specialPhysical;

            APIServerConfig.recordAPIAxios
                .post(
                    '/rehab/manage/specialPhysical',
                    JSON.stringify({
                        targetNo: targetNo,
                        historyNo: historyNo,
                        specialPhysical: specialPhysical,
                    }),
                    {
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    },
                )
                .then((response) => {
                    let targetPerson: RehabTargetHistory = this.jsonConvert.deserializeObject(response.data.targetHistory, RehabTargetHistory);
                    handler(targetPerson);
                });
        });
    }

    manageRecordingTargetWithFile(targetForm: FormData): Promise<RehabTargetHistory> {
        return new Promise((handler, reject) => {
            APIServerConfig.recordAPIAxiosForFile.post('/rehab/recording/files', targetForm).then((response) => {
                let data: RehabTargetHistory = this.jsonConvert.deserializeObject(response.data.targetHistory, RehabTargetHistory);
                data.ocrJson = response.data.ocrJson;
                handler(data);
            });
        });
    }

    managePrescriptionForTarget(history: RehabTargetHistory): Promise<RehabTargetHistory> {
        return new Promise((handler, reject) => {
            let targetNo = history.targetNo;
            let historyNo = history.historyNo;
            let basalPrescription = history.basalExercisePrescription;
            let conditioningPrescription = history.conditioningPrescription;
            let itemPrescription = history.itemPrescription;
            let bodyPrescription = history.bodyPrescription;
            let rehabProgramPrescription = history.rehabProgramPrescription;
            let opinions = history.opinions;
            let action = history.action;

            APIServerConfig.recordAPIAxios
                .post(
                    '/rehab/manage/prescription',
                    JSON.stringify({
                        targetNo: targetNo,
                        historyNo: historyNo,
                        basalPrescription: basalPrescription,
                        conditioningPrescription: conditioningPrescription,
                        rehabProgramPrescription: rehabProgramPrescription,
                        itemPrescription: itemPrescription,
                        bodyPrescription: bodyPrescription,
                        opinions: opinions,
                        actions: action,
                    }),
                    {
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    },
                )
                .then((response) => {
                    let targetPerson: RehabTargetHistory = this.jsonConvert.deserializeObject(response.data.targetHistory, RehabTargetHistory);
                    handler(targetPerson);
                });
        });
    }

    complateCheckupForTarget(historyNo: number, targetNo: number): Promise<RehabTargetHistory> {
        return new Promise((handler, reject) => {
            APIServerConfig.recordAPIAxios
                .post(
                    '/rehab/complete',
                    JSON.stringify({
                        targetNo: targetNo,
                        historyNo: historyNo,
                    }),
                    {
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    },
                )
                .then((response) => {
                    let targetPerson: RehabTargetHistory = this.jsonConvert.deserializeObject(response.data.targetHistory, RehabTargetHistory);
                    handler(targetPerson);
                });
        });
    }
}

export default RehabHistoryServiceImpl;
