import { JsonObject, JsonProperty } from 'json2typescript';
import Code from '../code/Code';
@JsonObject("clubTeamInfo")
class ClubTeamInfo {
    @JsonProperty("clubTeamInfoNo")
    clubTeamInfoNo?: string;
    @JsonProperty("clubSportCodeId")
    clubSportCodeId?: number;
    @JsonProperty("clubSportCode")
    clubSportCode?: Code;
    @JsonProperty("regionCodeId")
    regionCodeId?: number;
    @JsonProperty("regionCode")
    regionCode?: Code;
    @JsonProperty("clubTeamType")
    clubTeamType?: string;
    @JsonProperty("clubTeamCount")
    clubTeamCount?: number;
    @JsonProperty("clubTeamMemberCount")
    clubTeamMemberCount?: number;
    @JsonProperty("clubTeamSeason")
    clubTeamSeason?: number;
    @JsonProperty("clubTeamSeasonPart")
    clubTeamSeasonPart?: number;
    @JsonProperty("registeDate")
    registeDate?: string;
    @JsonProperty("modifyDate")
    modifyDate?: string;
}

export default ClubTeamInfo;