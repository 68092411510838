import React, { useEffect, useState } from 'react';
import Reservation from '../../domain/reservation/Reservation';

interface ReservationStateButtonProps {
    reservation: Reservation;
    // reservationStateCodeId: number;
    reservationStateCodeIdChangeHandler: (reservation: Reservation, codeId: number) => void;
}

function ReservationStateButton(props: ReservationStateButtonProps) {
    let button = <></>;
    let cancelButton = <button onClick={() => eventHandler(1006)} className="bg-red white">예약취소</button>;
    
    const [reservationStateCodeId, setReservationStateCodeId] = useState<number>(0);
    const eventHandler = (nextReservationStateCodeId: number) => {
        props.reservationStateCodeIdChangeHandler(props.reservation, nextReservationStateCodeId);
        setReservationStateCodeId(nextReservationStateCodeId)
    }

    useEffect(() => {
        if(props.reservation?.reservationStateCodeId) {
            setReservationStateCodeId(props.reservation.reservationStateCodeId);
        }
    }, [props.reservation]);


    if(reservationStateCodeId == 1001) {
        button = (
            <>
                <button onClick={() => eventHandler(reservationStateCodeId+1)} className="bg-gray white">승인하기</button>
                {cancelButton}
            </>
        )
    } else if(reservationStateCodeId == 1002) {
        button = (
            <>
                <button onClick={() => eventHandler(reservationStateCodeId+1)} className="bg-blue white">방문예정</button>
                {cancelButton}
            </>
        )
    } else if(reservationStateCodeId == 1003){    
        button = (
            <>
                <button onClick={() => eventHandler(reservationStateCodeId+1)} className="bg-blue white">방문중</button>
                {cancelButton}
            </>
        )
    } else if(reservationStateCodeId == 1004) {
        button = (
            <>
                <button onClick={() => eventHandler(reservationStateCodeId+1)} className="bg-blue white">예약 종료</button>
                {cancelButton}
            </>
        )
    } else if(reservationStateCodeId == 1006) {
        button = (
            <>
                <button onClick={() => eventHandler(1002)} className="bg-blue white">예약 승인</button>
            </>
        )
    }
    
    return button;
}

export default ReservationStateButton;