import React from 'react';

interface AdminTableProps {
    children?: React.ReactNode;
}

function AdminTable(props: AdminTableProps) {
    return (
        <>
            <div className="member-list" style={{ overflowX: 'auto', maxHeight: '1000px' }}>
                <table cellPadding="0" cellSpacing="0" className="fixed-table admin-table">
                    {props.children}
                </table>
            </div>
        </>
    );
}

export default AdminTable;
