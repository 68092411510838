import { JsonObject, JsonProperty, JsonConvert } from 'json2typescript';
import Questionnaire from './Questionnaire';
import AnswerItem from './AnswerItem';
@JsonObject('fillInQuestionnarie')
class FillInQuestionnarie {
    @JsonProperty('answerNo')
    answerNo?: number;
    @JsonProperty('targetNo')
    targetNo?: number;
    @JsonProperty('quesNo')
    quesNo?: number;
    @JsonProperty('reservationNo')
    reservationNo?: string;
    @JsonProperty('questionnaire')
    questionnaire?: Questionnaire;
    @JsonProperty('answer')
    answer?: string;
    @JsonProperty('answerModDate')
    answerModDate?: string;
    @JsonProperty('answerRegDate')
    answerRegDate?: string;

    getAnswerItemsMap(): Map<number, AnswerItem> | undefined {
        if (this.answer) {
            const answerItemsMap = new Map<number, AnswerItem>();
            const jsonConvert: JsonConvert = new JsonConvert();
            const answerItems = jsonConvert.deserializeArray(JSON.parse(this.answer), AnswerItem);

            answerItems.forEach((value, index) => {
                if (value.questionNum) answerItemsMap.set(value.questionNum, value);
            });

            return answerItemsMap;
        }
    }

    getAnswerItems(): AnswerItem[] | undefined {
        if (this.answer) {
            const jsonConvert: JsonConvert = new JsonConvert();
            const answerItems = jsonConvert.deserializeArray(JSON.parse(this.answer), AnswerItem);
            return answerItems;
        }
    }

    getQuestionnaire(): Questionnaire {
        const questionnaire = Object.setPrototypeOf(this?.questionnaire, Questionnaire.prototype);
        return questionnaire;
    }
}

export default FillInQuestionnarie;
