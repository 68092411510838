import { CodeType } from '../../domain/code/CodeType';
import { useEffect, useState } from 'react';
import CodeServiceImpl from '../../service/code/impl/CodeServiceImpl';
import Code from '../../domain/code/Code';
const codeService = new CodeServiceImpl();
function useCodeRetrieve(codeType: CodeType) {
    const [codes, setCodes] = useState<Code[]>();
    const [initParentCodeId, setInitParentCodeId] = useState<number>();

    useEffect(() => {
        codeService.getCode(codeType).then((codes: Code[]) => {
            setCodes(codes);
            if (codes.length > 0) {
                setInitParentCodeId(codes[0].codeId);
            }
        });
    }, []);

    return { codes, setCodes, initParentCodeId, setInitParentCodeId };
}

export default useCodeRetrieve;
