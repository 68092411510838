import React from 'react';
import { Switch, Route, RouteComponentProps, Redirect, useParams, useLocation } from 'react-router-dom';
import RehabManageUserRegistePage from '../pages/RehabManageUserRegistePage';
import {
    Login,
    ReservedManagingList,
    ReservedDetail,
    CheckedUpTargetList,
    CheckedUpTargetDetail,
    CheckedUpTargetMngData,
    MngTargetPerson,
    MngTargetPersonDetail,
    Recording,
    Prescription,
    CompleteRehab,
    ManagementUser,
    ManagementExercise,
    ManagementQuestSheet,
    Configuration,
    Statistics,
    Logout,
    Reporting,
    BatchManagementUser,
} from '../pages';
import Header from '../layouts/Header';
import RehabPrivateRouter from '../../RehabPrivateRouter';

function RehabCenterRouter({ match }: RouteComponentProps) {
    return (
        <div className="center-body">
            <div className="center-wrap">
                <Header></Header>
                <Switch>
                    <Route path={`${match.path}/login`} component={Login}></Route>
                    {/* <RehabPrivateRouter path={`${match.path}/reserved`} component={ReservedList}></RehabPrivateRouter> */}
                    <RehabPrivateRouter path={`${match.path}/reserved/detail`} component={ReservedDetail}></RehabPrivateRouter>
                    <RehabPrivateRouter path={`${match.path}/reserved`} component={ReservedManagingList}></RehabPrivateRouter>
                    <RehabPrivateRouter path={`${match.path}/checkedup/mngdata/:tab`} component={CheckedUpTargetMngData}></RehabPrivateRouter>
                    <RehabPrivateRouter path={`${match.path}/checkedup/detail`} component={CheckedUpTargetDetail}></RehabPrivateRouter>
                    <RehabPrivateRouter path={`${match.path}/checkedup`} component={CheckedUpTargetList}></RehabPrivateRouter>
                    <RehabPrivateRouter path={`${match.path}/targetperson/detail`} component={MngTargetPersonDetail}></RehabPrivateRouter>
                    <RehabPrivateRouter path={`${match.path}/targetperson`} component={MngTargetPerson}></RehabPrivateRouter>
                    {/* <RehabPrivateRouter path={`${match.path}/questionnaires`} component={Questionnaires}></RehabPrivateRouter> */}
                    <RehabPrivateRouter path={`${match.path}/recording`} component={Recording}></RehabPrivateRouter>
                    <RehabPrivateRouter path={`${match.path}/prescription`} component={Prescription}></RehabPrivateRouter>
                    <RehabPrivateRouter path={`${match.path}/completerehab`} component={CompleteRehab}></RehabPrivateRouter>
                    <RehabPrivateRouter exact path={`${match.path}/manage/user`} component={ManagementUser}></RehabPrivateRouter>
                    <RehabPrivateRouter exact path={`${match.path}/manage/user/add-user`} component={RehabManageUserRegistePage}></RehabPrivateRouter>
                    <RehabPrivateRouter exact path={`${match.path}/manage/user/batch`} component={BatchManagementUser}></RehabPrivateRouter>
                    <RehabPrivateRouter path={`${match.path}/manage/exercise`} component={ManagementExercise}></RehabPrivateRouter>
                    <RehabPrivateRouter path={`${match.path}/manage/questsheet`} component={ManagementQuestSheet}></RehabPrivateRouter>
                    <RehabPrivateRouter path={`${match.path}/configuration`} component={Configuration}></RehabPrivateRouter>
                    <RehabPrivateRouter path={`${match.path}/statistics`} component={Statistics}></RehabPrivateRouter>
                    <RehabPrivateRouter path={`${match.path}/logout`} component={Logout}></RehabPrivateRouter>
                    <RehabPrivateRouter path={`${match.path}/reporting/:targetNo/:historyNo`} component={Reporting}></RehabPrivateRouter>
                </Switch>
            </div>
        </div>
    );
}

export default RehabCenterRouter;
