import React, { useState, SetStateAction, Dispatch, useEffect } from 'react';
import TrainningMainHeader from '../../layout/TrainningMainHeader';
import InfraQuickViewer from '../../../../component/infra/InfraQuickViewer';
import { InfraCategoryType } from '../../../../domain/infra/InfraCategoryType';
import CommonSearchBar from '../../../../component/search/CommonSearchBar';
import useMyTeamScheduleHook from '../../../../hook/useMyTeamScheduleHook';

import TrainningMainContainerOfNotExistSchedule from './TrainningMainContainerOfNotExistSchedule';
import TrainningMainContainerOfExistSchedule from './TrainningMainContainerOfExistSchedule';

function TrainningMainContainer() {
    //여기서 처리 대야겟지?
    const { reservation } = useMyTeamScheduleHook();
    
    return (
        <>
            <TrainningMainHeader></TrainningMainHeader>
            {/* <CommonSearchBar></CommonSearchBar> */}
            {
                reservation ? 
                <TrainningMainContainerOfExistSchedule reservation={reservation}></TrainningMainContainerOfExistSchedule>
                :
                <></>
            }
            <TrainningMainContainerOfNotExistSchedule></TrainningMainContainerOfNotExistSchedule>
        </>
    )
}

export default TrainningMainContainer;