import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import ActionComponent from '../ActionComponent';
import ResultFormComponent from './forms/ResultFormComponent';
import PrescriptionFormComponent from './forms/PrescriptionFormComponent';
import StatusGraphFormComponent from './forms/StatusGraphFormComponent';
import ReportingComponent from './forms/ReportingComponent';
import TargetPerson from '../../../domain/TargetPerson';
import Prescription from '../../../domain/Prescription';
import PrescriptionServiceImpl from '../../../service/impl/PrescriptionServiceImpl';
import { confirmAlert } from 'react-confirm-alert';
import RehabTargetHistory from '../../../domain/RehabTargetHistory';
import RehabHistoryServiceImpl from '../../../service/impl/RehabHistoryServiceImpl';
import { JsonConvert } from 'json2typescript';

interface IProps {
    selectedTarget: TargetPerson;
    closingHandler: () => void;
}

const rehabHistoryService = new RehabHistoryServiceImpl();

let isStored: boolean = true;
let isCheckedPrescription: boolean = false;

const PrescriptionPopupComponent = (props: IProps) => {
    const targetPerson = props.selectedTarget;
    const [rehabHistory, setRehabHistory] = useState<RehabTargetHistory[]>([]);
    const [recordingItem, setRecordingItem] = useState<RehabTargetHistory>();
    const [selectedHistory, setSelectedHistory] = useState<number>(0);
    const [historyOptions, setHistoryOptions] = useState(<></>);
    const [isModifying, setIsModifying] = useState(false);

    useEffect(() => {
        rehabHistoryService.getRehabHistoryForTarget(targetPerson.targetNo).then((results: RehabTargetHistory[]) => {
            const findTarget = results.find((item, idx) => {
                return item.targetState == 8003;
            });
            setRecordingItem(findTarget ? findTarget : new RehabTargetHistory());
            setRehabHistory(results);
        });
    }, []);

    useEffect(() => {
        if (rehabHistory) {
            let options = <></>;
            options = (
                <>
                    {rehabHistory?.map((item, idx) => {
                        let displayTit = '';
                        let targetState = item.targetState;

                        if (targetState == 8003) {
                            displayTit = '입력 중...';
                        } else {
                            displayTit = item.getRegDate();
                        }
                        return (
                            <option key={idx} value={item.historyNo}>
                                {displayTit}
                            </option>
                        );
                    })}
                </>
            );

            setHistoryOptions(options);
        } else {
            setHistoryOptions(
                <>
                    <option>이력 정보가 없습니다.</option>
                </>,
            );
        }
    }, [rehabHistory]);

    const tabHandler = (evt: any) => {
        var actions = ActionComponent();
        let target = evt.target.id;

        if (target === 'tab-btn1') {
            /* 상담/ 측정 정보 */
            actions.tab1Open();
        } else if (target === 'tab-btn2') {
            /* 운동처방 */
            actions.tab2Open();
        } else if (target === 'tab-btn3') {
            /*재활 회차별 상태 그래프 */
            actions.tab3Open();
            ReactDOM.render(
                <StatusGraphFormComponent targetPerson={targetPerson}></StatusGraphFormComponent>,
                document.getElementById('center-popup-list3'),
            );
        }
    };

    const inputChangeHandler = (evt: any) => {
        isStored = false;
        let prescription: Prescription = evt.prescription;
        if (recordingItem) {
            recordingItem.basalExercisePrescription = JSON.stringify(prescription.basalExercisePrescription);
            recordingItem.basalExercisePrescription = JSON.stringify(prescription.basalExercisePrescription);
            recordingItem.conditioningPrescription = JSON.stringify(prescription.conditioningPrescription);
            recordingItem.itemPrescription = JSON.stringify(prescription.itemPrescription);
            recordingItem.bodyPrescription = JSON.stringify(prescription.bodyPrescription);
            recordingItem.opinions = JSON.stringify(prescription.opinions);
        }
    };

    const storedHandler = (evt: any) => {
        var pageHandler = ActionComponent();
        let action = evt.target.id;
        let managingItem: RehabTargetHistory | undefined;

        if (action === 'closing') {
            pageHandler.popupClose();
        } else {
            if (isModifying) {
                if (!managingItem) {
                    managingItem = rehabHistory.find((item) => {
                        return item.targetState == 8003;
                    });
                    setRecordingItem(managingItem);
                }
            } else {
                managingItem = recordingItem;
            }

            if (managingItem) {
                managingItem.action = action;
                let message = '처방이 완료 되었습니다. 재활 관리에서 내용을 확인 할 수 있습니다.';
                if (action === 'store') message = '처방 정보가 <임시저장> 되었습니다.';

                rehabHistoryService.managePrescriptionForTarget(managingItem).then((response) => {
                    confirmAlert({
                        title: '알림',
                        message: message,
                        buttons: [
                            {
                                label: '확인',
                                onClick: () => {
                                    if (action === 'complete') {
                                        pageHandler.popupClose();
                                        props.closingHandler();
                                    }
                                },
                            },
                        ],
                    });
                });
            }
        }
    };

    const changeHistoryHandler = function (evt: any) {
        let target = evt.currentTarget;
        target.classList.remove('active');
        setIsModifying(true);
        let selectedVal = target.value;

        let history = rehabHistory.find((item) => {
            return item.historyNo == selectedVal;
        });
        let targetState = history?.targetState;

        if (targetState == 8003) {
            setIsModifying(false);
            target.classList.add('active');
        }

        setSelectedHistory(selectedVal);
        if (history) setRecordingItem(history);
    };

    return (
        <>
            <div className="center-popup wow  animate__animated animate__fadeInUp" data-wow-delay="0.2s">
                <div className="center-popup-title">
                    <h1 className="center">스포츠재활 처방</h1>
                    <div className="flex">
                        <h1 className="center">
                            <i className="fas fa-user-tie"></i> {targetPerson?.reservater?.name}
                        </h1>
                        &nbsp;
                        <span className="center-birth">
                            {targetPerson?.reservater?.genderCode?.name} / {targetPerson?.reservater?.birthdate}
                        </span>
                    </div>
                    <div>
                        <button className="center-button-complete" onClick={storedHandler} id="complete">
                            처방완료 <i className="fas fa-times-circle"></i>
                        </button>
                        &nbsp;
                        <button className="center-button-save" onClick={storedHandler} id="store">
                            임시저장 <i className="fas fa-times-circle"></i>
                        </button>
                        &nbsp;
                        <button className="center-button-close" onClick={storedHandler} id="closing">
                            닫기 <i className="fas fa-times-circle"></i>
                        </button>
                    </div>
                </div>
                <div className="center-popup-check">
                    <p className="center-popup-tit">
                        <i className="fas fa-history"></i> &nbsp;상태확인 이전 내역 &nbsp;
                        <select value={selectedHistory} onChange={changeHistoryHandler} className="active">
                            {historyOptions}
                        </select>
                    </p>
                </div>
                <div className="center-popup-cont">
                    <div className="center-popup-bnt">
                        <a href="#" id="tab-btn1" className="on" onClick={tabHandler}>
                            상담 / 측정 정보
                        </a>
                        <a href="#" id="tab-btn2" onClick={tabHandler}>
                            운동처방
                        </a>
                        <a href="#" id="tab-btn3" onClick={tabHandler}>
                            재활 회차별 상태 그래프
                        </a>
                    </div>

                    <div id="center-popup-list" className="center-popup-list-tab  center-bg-gray">
                        {/* 상담/측정 정보영역 */}
                        <ResultFormComponent targetPerson={targetPerson} recordingItem={recordingItem}></ResultFormComponent>
                    </div>
                    <div id="center-popup-list2" className="center-popup-list-tab">
                        {/* 운동처방 영역 */}
                        <PrescriptionFormComponent
                            targetPerson={targetPerson}
                            handler={inputChangeHandler}
                            isModifying={isModifying}
                            recordingItem={recordingItem}
                        ></PrescriptionFormComponent>
                    </div>

                    <div id="center-popup-list3" className="center-popup-list-tab center-bg-gray">
                        {/*재활 회차별 상태 그래프 영역 */}
                    </div>
                </div>
            </div>
        </>
    );
};

export default PrescriptionPopupComponent;
