import { useEffect, useState } from 'react';
import Code from '../../domain/code/Code';
import CodeService from '../../service/code/CodeService';
import CodeServiceImpl from '../../service/code/impl/CodeServiceImpl';
const codeService: CodeService = new CodeServiceImpl();
function useChildCodeRetrieve(parentCodeId?: number) {
    const [codes, setCodes] = useState<Code[]>();

    useEffect(() => {
        if (parentCodeId) {
            codeService.getCodeByParentCodeId(parentCodeId).then((codes: Code[]) => {
                setCodes(codes);
            });
        } else {
            setCodes(undefined);
        }
    }, [parentCodeId]);

    return codes;
}

export default useChildCodeRetrieve;
