import React from 'react';
import Board from '../../../../../domain/board/Board';
import useTeamStoryStoryBoard from '../hook/useTeamStoryStoryBoard';
import BoardContent from '../../../../../domain/board/BoardContent';

interface TeamStoryPictcureTabProps {
    teamNo: string;
    boardOfTeam?: Board[];
}

function TeamStoryPictcureTab(props: TeamStoryPictcureTabProps) {
    const storyBoardContents = useTeamStoryStoryBoard(props.teamNo, props.boardOfTeam);
    //http://localhost:7081/static-file/file/board/content/d22a6f653f/853fec59c938d713448237d77d8f72f3.jpg
    return (
        <div className="team-gallery">
            <ul>
                {
                    storyBoardContents.map((value: BoardContent, index: number) => {
                        return (
                            <li>
                                <img id="myImg" className="team-thum" src={value.getMainAttachFile(0)} alt={value.title}></img>
                                <div id="myModal" className="modal">
                                    <span className="close">×</span>
                                    <img className="modal-content" id="img01"></img>
                                    <div id="caption">The Woods</div>
                                </div>
                            </li>		
                        )
                    })
                }			
            </ul>
        </div>
    )
}

export default TeamStoryPictcureTab;