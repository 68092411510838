import React, { useEffect, useState } from 'react';
import SignUpStepBarComponent from '../components/SignUpStepBarComponent';
import SignUpHeader from '../layout/SignUpHeader';
import { KMC_USE_IDENTIFY_SERVICE } from '../../../service/index';
import KMCTRInfo from '../../../domain/user/KMCTRInfo';
import { mobileConfirmCompanyHost } from '../../../config/Constant';
import SignUpUserValidationCheckComponent from '../components/SignUpUserValidationCheckComponent';
interface SignUpUserValidationCheckPageProps {}

function SignUpUserValidationCheckPage(props: SignUpUserValidationCheckPageProps) {
    const [kmctrInfo, setKmctrInfo] = useState<KMCTRInfo>();
    useEffect(() => {
        KMC_USE_IDENTIFY_SERVICE.getKCMTRInfo().then((kmctrInfo) => {
            console.log(kmctrInfo);
            setKmctrInfo(kmctrInfo);
        });
    }, []);

    const mobileCheckStartEvent = (event: any) => {
        const data: FormData = new FormData();
        if (kmctrInfo?.trCert && kmctrInfo?.trUrl && kmctrInfo?.trAdd) {
            var UserAgent = navigator.userAgent;
            var winX = window.screenX || window.screenLeft || 0;
            var winY = window.screenY || window.screenTop || 0;
            var popupX = window.innerWidth / 2 - 425 / 2;
            var popupY = window.innerHeight / 2 - 550 / 2;
            popupX += winX;
            popupY += winY + 20;
            var KMCIS_window: any = window.open(
                '',
                'KMCISWindow',
                `width=425, height=550, resizable=0, scrollbars=no, status=0, titlebar=0, toolbar=0, left=${popupX}, top=${popupY}`,
            );
            if (KMCIS_window == null) {
                alert('팝업 해제해주세요.');
            }
            window.name = 'kmcis_web_sample';
            var formTag: any = document.getElementById('test');
            formTag.target = 'KMCISWindow';
            formTag.action = mobileConfirmCompanyHost;

            formTag.submit();
        }
    };

    return (
        <>
            <SignUpHeader title="본인 인증"></SignUpHeader>
            <div className="mgt10"></div>
            <div className="content-wrap">
                <div className="signup-content-wrap">
                    <SignUpStepBarComponent step="step2"></SignUpStepBarComponent>
                    <div className="mgt30"></div>
                    <h2>본인인증</h2>
                    <div className="mgt10"></div>
                    <SignUpUserValidationCheckComponent
                        trCert={kmctrInfo?.trCert}
                        trUrl={`${process.env.REACT_APP_RESOURCE_API_HOST + '/kmcis-result-redirect-page/signup'}`}
                        trAdd={kmctrInfo?.trAdd}
                    ></SignUpUserValidationCheckComponent>
                    <div className="mgt30"></div>

                    <p>※주의 : 휴대폰 미소지자는 고객센터를 통해 별도의 회원가입 신청을 바랍니다.</p>

                    <div className="mgt10"></div>
                    <div className="user-confirm-wrap-use-case">
                        <p className="first">※ 이용안내</p>
                        <p>
                            * GSSC에서는 개정된 정보통신망법 시행령(제 23조 2호, 주민등록번호 수집 및 이용제한)에 따라 주민등록번호를 수집하지
                            않습니다.
                        </p>
                        <p>* 본인인증은 한국모바일인증(주)에 위탁하여 휴대폰 인증 방식으로 시행하고 있습니다.</p>
                        <p>* 본인확인이 안되는 경우, 한국모바일인증(주)에 문의 바랍니다.</p>
                    </div>
                    <div className="mgt10"></div>
                </div>
            </div>
        </>
    );
}

export default SignUpUserValidationCheckPage;
