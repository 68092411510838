import React, { ChangeEvent, useEffect, useState } from 'react';
import { Link, useParams, useLocation, useHistory } from 'react-router-dom';

import AdminMainTitle from '../../../../component/admin/AdminMainTitle';
import CalendarComponent from '../../../../component/calendar/CalendarComponent';
import { CodeType } from '../../../../domain/code/CodeType';
import useCodeRetrieve from '../../../../hook/code/useCodeRetrieve';
import Code from '../../../../domain/code/Code';
import useChildCodeRetrieve from '../../../../hook/code/useChildCodeRetrieve';
import useTeamRetrieve from '../../../../hook/team/useTeamRetrieve';
import Team from '../../../../domain/team/Team';
import VisitedTeamInfo from '../../../../domain/visitedInfo/VisitedTeamInfo';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../moldules/index';
import useVisitedTeamInfoRegiste from '../../../../hook/visitedInfo/useVisitedTeamInfoRegiste';
import DateUtils from '../../../../utils/DateUtils';

import useVisitedTeamInfo from '../../../../hook/visitedInfo/useVisitedTeamInfo';
import AdminVisitedTeamInfosDetailTable from '../../component/AdminVisitedTeamInfosDetailTable';
import VisitedTeamInfoSearchCondition from '../../../../domain/visitedInfo/pagam/VisitedTeamInfoSearchCondition';
import useAdminPaging from '../../hook/useAdminPaging';
import AdminRetrieveItemPaging from '../../component/AdminRetrieveItemPaging';
import VisitedTeamInfoServiceImpl from '../../../../service/visitedInfo/impl/VisitedTeamInfoServiceImpl';
import TeamServiceImpl from '../../../../service/team/impl/TeamServiceImpl';

const visitedTeamInfoService = new VisitedTeamInfoServiceImpl();
const teamService = new TeamServiceImpl();

function AdminVisitedTeamInfoDetailPage() {
    const { user } = useSelector((state: RootState) => state.userSessionReducer);
    const params: any = useParams();

    const history = useHistory();
    const location = useLocation<VisitedTeamInfoSearchCondition>();

    let condition = location.state ? location.state : new VisitedTeamInfoSearchCondition();
    const [searchCondition, setSearchCondition] = useState(condition);

    const { page, setPage, pagingSize, setPagingSize, pagingCnt, paging, currentPageGroupIdx, setCurrentPageGroupIdx } = useAdminPaging();

    //const { vistiedTeamInfo: originalVisitedTeamInfo } = useVisitedTeamInfo(params.visitedTeamNo);
    const [visitedTeamInfo, setVisitedTeamInfo] = useState<Team>();
    const [visitedTeamInfos, setVisitedTeamInfos] = useState<VisitedTeamInfo[]>();
    const [visitedTeamInfosCnt, setVisitedTeamInfosCnt] = useState<number>(0);

    const { codes: regionCodes, initParentCodeId } = useCodeRetrieve(CodeType.REGION);

    const [teamNo, setTeamNo] = useState<string>(params.visitedTeamNo);
    const [visitedStartDate, setVisitedStartDate] = useState<string>();
    const [visitedEndDate, setVisitedEndDate] = useState<string>();

    useEffect(() => {
        var teamInfoNo = params.visitedTeamNo;
        teamService.getTeamInfo(teamInfoNo).then((teamInfo: Team) => {
            setVisitedTeamInfo(teamInfo);
        });
    }, []);

    useEffect(() => {
        if (visitedTeamInfo?.teamNo) {
            let condition = new VisitedTeamInfoSearchCondition();
            condition.visitedTeamNo = visitedTeamInfo.teamNo;

            visitedTeamInfoService.getVisitedTeamInfos(condition, paging).then((visitedTeamInfos: VisitedTeamInfo[]) => {
                setVisitedTeamInfos(visitedTeamInfos);
            });

            visitedTeamInfoService.getvisitedTeamInfosCnt(condition).then((visitedTeamInfosCnt: number) => {
                setVisitedTeamInfosCnt(visitedTeamInfosCnt);
            });
        }
    }, [visitedTeamInfo]);

    useEffect(() => {
        let paging = { page: page, size: 10 };
        if (visitedTeamInfo?.teamNo) {
            let condition = new VisitedTeamInfoSearchCondition();
            condition.visitedTeamNo = visitedTeamInfo.teamNo;

            visitedTeamInfoService.getVisitedTeamInfos(condition, paging).then((visitedTeamInfos: VisitedTeamInfo[]) => {
                setVisitedTeamInfos(visitedTeamInfos);
            });

            visitedTeamInfoService.getvisitedTeamInfosCnt(condition).then((visitedTeamInfosCnt: number) => {
                setVisitedTeamInfosCnt(visitedTeamInfosCnt);
            });
        }
    }, [page]);

    const goRegistryVisitedInfo = (evt: any) => {
        let stateCondition: VisitedTeamInfoSearchCondition = new VisitedTeamInfoSearchCondition();
        stateCondition.visitedTeamNo = visitedTeamInfo?.teamNo;
        stateCondition.returnPath = '/admin/visited-team-info/detail/' + params.visitedTeamNo;

        history?.push({
            pathname: '/admin/visited-team-info/registry',
            state: stateCondition,
        });
    };

    const goBack = (evt: any) => {
        history?.replace({
            pathname: '/admin/visited-team-info',
            state: searchCondition,
        });
    };

    return (
        <>
            <AdminMainTitle title="방문 팀 상세 정보">
                <div className="member-search"></div>
                <div className="flex-basic">
                    <button
                        style={{ float: 'right', fontWeight: 'bold', marginBottom: '10px', backgroundColor: '#ffc107', color: '#3a4652' }}
                        onClick={goBack}
                    >
                        돌아가기
                    </button>
                    <button
                        className="bg-white"
                        style={{ float: 'right', color: '#fff', fontWeight: 'bold', marginBottom: '10px', background: '#474747' }}
                        onClick={goRegistryVisitedInfo}
                    >
                        방문 팀 정보 등록
                    </button>
                </div>
            </AdminMainTitle>
            {/* <div className="two-wrap"> */}
            <div className="flex-basic">
                <div className="member-list" style={{ width: '100%' }}>
                    <table>
                        <thead>
                            <tr>
                                <td colSpan={4} style={{ textAlign: 'left', paddingLeft: '5px', background: '#474747' }}>
                                    <i className="fa fa-check"></i>&nbsp;&nbsp;팀 정보 요약
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="txt-center" style={{ background: '#d9d9d9', width: '10%', height: '30px' }}>
                                    팀명
                                </td>
                                <td className="txt-left" style={{ width: '40%' }}>
                                    {visitedTeamInfo?.name}
                                </td>
                                <td className="txt-center" style={{ background: '#d9d9d9', width: '10%', height: '30px' }}>
                                    팀분류
                                </td>
                                <td className="txt-left" style={{ width: '40%' }}>
                                    {visitedTeamInfo?.classificationCode?.name}
                                </td>
                            </tr>
                            <tr>
                                <td className="txt-center" style={{ background: '#d9d9d9', height: '30px' }}>
                                    팀연고지
                                </td>
                                <td className="txt-left">{visitedTeamInfo?.regionCode?.name}</td>
                                <td className="txt-center" style={{ background: '#d9d9d9', height: '30px' }}>
                                    팀 종목
                                </td>
                                <td className="txt-left">{visitedTeamInfo?.sportCode?.name}</td>
                            </tr>
                            <tr>
                                <td className="txt-center" style={{ background: '#d9d9d9', height: '30px' }}>
                                    최근방문지역
                                </td>
                                <td className="txt-left"></td>
                                <td className="txt-center" style={{ background: '#d9d9d9', height: '30px' }}>
                                    최근방문일정
                                </td>
                                <td className="txt-left"></td>
                            </tr>
                            <tr>
                                <td className="txt-center" style={{ background: '#d9d9d9', height: '30px' }}>
                                    팀 소속인원
                                </td>
                                <td className="txt-left">{visitedTeamInfo?.memberCnt}&nbsp;</td>
                                <td className="txt-center" style={{ background: '#d9d9d9', height: '30px' }}>
                                    팀 홈페이지
                                </td>
                                <td>{visitedTeamInfo?.homepageUrl}&nbsp;</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="flex-basic">
                <div className="member-list" style={{ width: '100%' }}>
                    <table>
                        <thead>
                            <tr>
                                <td
                                    style={{
                                        textAlign: 'left',
                                        paddingLeft: '5px',
                                        background: '#474747',
                                    }}
                                >
                                    <i className="fa fa-check"></i>&nbsp;&nbsp;전지훈련 이력 ({visitedTeamInfosCnt ? visitedTeamInfosCnt : 0} 건)
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="txt-left">
                                    <AdminVisitedTeamInfosDetailTable
                                        page={page}
                                        pagingSize={pagingSize}
                                        visitedTeamNo={params.visitedTeamNo}
                                        visitedTeamInfos={visitedTeamInfos}
                                    ></AdminVisitedTeamInfosDetailTable>
                                    <AdminRetrieveItemPaging
                                        totalCnt={visitedTeamInfosCnt}
                                        pagingCnt={pagingCnt}
                                        pagingSize={pagingSize}
                                        setPagingSize={setPagingSize}
                                        setPage={setPage}
                                        currentPageNum={page}
                                        currentPageGroupIdx={currentPageGroupIdx}
                                    ></AdminRetrieveItemPaging>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            {/* </div> */}
        </>
    );
}

export default AdminVisitedTeamInfoDetailPage;
