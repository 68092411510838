import React from 'react';
import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('vas')
export default class Vas {
    @JsonProperty('seq')
    seq?: number;
    @JsonProperty('part')
    part?: string;
    @JsonProperty('partName')
    partName?: string;
    @JsonProperty('recording')
    recording: string[] = ['', '', ''];
}
