import React, { useEffect, useState } from 'react';
import useInfraOfCategoryRetrieve from '../../hook/infra/useInfraCategoryRetrieve';
import useChildCodeRetrieve from '../../hook/code/useChildCodeRetrieve';
import { InfraCategoryType } from '../../domain/infra/InfraCategoryType';
import InfraCategory from '../../domain/infra/InfraCategory';
import Code from '../../domain/code/Code';
import InfraSearchCondition from '../../domain/infra/param/InfraSearchCondition';

interface SearchViewTypeProps {
    setSearchViewType: (viewType: string) => void;
    listViewTypeActive: boolean;
    mapViewTypeActive: boolean;
    searchingType: string;
    infraSearchCondition: InfraSearchCondition;
    setInfraSearchCondition: React.Dispatch<React.SetStateAction<InfraSearchCondition>>;
}

function SearchViewTypeWithCategory(props: SearchViewTypeProps) {
    const [viewType, setViewType] = useState('list');
    const [infraCategoryNoOfSearch, setInfraCategoryNoOfSearch] = useState<number>();
    const [searchByRegionCode, setSearchByRegionCode] = useState<number>();

    let infraSearchCondition: InfraSearchCondition = props.infraSearchCondition;
    let parentInfraCategory: number = infraSearchCondition.parentInfraCategory ? Number(infraSearchCondition.parentInfraCategory) : 0;
    let childInfraCategory: number = infraSearchCondition.childInfraCategory ? Number(infraSearchCondition.childInfraCategory) : 0;
    let parentRegionCodeId: string = infraSearchCondition.parentRegionCodeIds ? infraSearchCondition.parentRegionCodeIds : '20001';
    let isRecommendation: boolean | undefined = infraSearchCondition.recommandation;

    const infraCategoriesOfSubInfraInfra = useInfraOfCategoryRetrieve(parentInfraCategory);
    const regionCodes = useChildCodeRetrieve(Number(parentRegionCodeId));

    useEffect(() => {
        setInfraCategoryNoOfSearch(childInfraCategory);
    }, []);

    const changeInfraHandler = (evt: any) => {
        let infraElem: any = document.getElementById('infraCate');
        let regionElem: any = document.getElementById('regionCode');

        let chooseInfra = infraElem.value == 0 ? undefined : Number(infraElem.value);
        let chooseRegion = regionElem.value == 0 ? undefined : Number(regionElem.value);

        setInfraCategoryNoOfSearch(chooseInfra);
        setSearchByRegionCode(chooseRegion);

        props.setInfraSearchCondition((prevState) => {
            return {
                ...prevState,
                ['childInfraCategory']: chooseInfra?.toString(),
                ['regionCodeIds']: chooseRegion?.toString(),
            };
        });
    };
    let optionTit = '전체 시설';

    if (props.searchingType === 'recomm') {
        optionTit = '전체 추천 시설';
    } else if (props.searchingType === 'nearby') {
        optionTit = '전체 주변 시설';
    }

    return (
        <>
            <label className="cate-select-tit">지역 별 종목선택:</label>
            <select id="infraCate" className="cate-select" value={infraCategoryNoOfSearch} onChange={changeInfraHandler}>
                <option value="0">{optionTit}</option>
                {infraCategoriesOfSubInfraInfra?.map((infraCategory: InfraCategory, index: number) => {
                    return (
                        <option key={index} value={infraCategory.infraCategoryNo}>
                            {infraCategory.name}
                        </option>
                    );
                })}
            </select>
            <select id="regionCode" className="cate-select" value={searchByRegionCode} onChange={changeInfraHandler}>
                <option value="0">전지역</option>
                {regionCodes?.map((infraCategory: Code, index: number) => {
                    return (
                        <option key={index} value={infraCategory.codeId}>
                            {infraCategory.name}
                        </option>
                    );
                })}
            </select>
            <ul className="select02">
                {props.listViewTypeActive ? (
                    <li
                        onClick={() => {
                            setViewType('list');
                            props.setSearchViewType('list');
                        }}
                        className={viewType == 'list' ? 'on' : ''}
                    >
                        <div className={viewType == 'list' ? 'on' : ''}>
                            <img src={viewType == 'list' ? '/assets/image/icon-list-on.png' : '/assets/image/icon-list.png'}></img>
                            <a>목록</a>
                        </div>
                    </li>
                ) : (
                    <></>
                )}
                {props.mapViewTypeActive ? (
                    <li
                        onClick={() => {
                            setViewType('map');
                            props.setSearchViewType('map');
                        }}
                        className={viewType == 'map' ? 'on' : ''}
                    >
                        <div className={viewType == 'map' ? 'on' : ''}>
                            <a>지도</a>
                            <img src={viewType == 'map' ? '/assets/image/icon-map-on.png' : '/assets/image/icon-map.png'}></img>
                        </div>
                    </li>
                ) : (
                    <></>
                )}
            </ul>
        </>
    );
}

export default SearchViewTypeWithCategory;
