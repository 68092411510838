import { JsonObject, JsonProperty } from 'json2typescript';
import Code from '../../code/Code';

@JsonObject('visitedMemberAccumInfo')
class VisitedMemberAccumInfo {
    @JsonProperty('parentRegionCode')
    parentRegionCode?: Code;
    @JsonProperty('regionCode')
    regionCode?: Code;
    @JsonProperty('sportCode')
    sportCode?: Code;
    @JsonProperty('teamTypeCode')
    teamTypeCode?: Code;
    @JsonProperty('visitedDay')
    visitedDay?: number;
    @JsonProperty('visitedDayOfYear')
    visitedDayOfYear?: number;
    @JsonProperty('visitedMemberCnt')
    visitedMemberCnt?: number;
    @JsonProperty('visitedMemberCntOfYear')
    visitedMemberCntOfYear?: number;
    @JsonProperty('totalVisitedMemberCnt')
    totalVisitedMemberCnt?: number;
    @JsonProperty('totalVisitedMemberCntOfYear')
    totalVisitedMemberCntOfYear?: number;
    @JsonProperty('teamCnt')
    teamCnt?: number;
    @JsonProperty('teamVisitedCnt')
    teamVisitedCnt?: number;
    @JsonProperty('teamVisitedCntOfYear')
    teamVisitedCntOfYear?: number;
    @JsonProperty('teamTypeCnt')
    teamTypeCnt?: number;
    @JsonProperty('sportTypeCnt')
    sportTypeCnt?: number;
    @JsonProperty('visitedMemberAvg')
    visitedMemberAvg?: string;
    @JsonProperty('visitedDayAvg')
    visitedDayAvg?: string;
    @JsonProperty('firstVisitedDate')
    firstVisitedDate?: string;
    @JsonProperty('lastVisitedDate')
    lastVisitedDate?: string;

    static getMapByRegionCodeId(visitedMemberAccumInfos: VisitedMemberAccumInfo[]): Map<number, VisitedMemberAccumInfo> {
        const map = new Map<number, VisitedMemberAccumInfo>();

        visitedMemberAccumInfos.forEach((visitedMemberAccumInfo: VisitedMemberAccumInfo) => {
            const regionCode = visitedMemberAccumInfo.regionCode;
            if (regionCode?.codeId) {
                map.set(regionCode.codeId, visitedMemberAccumInfo);
            } else {
                map.set(0, visitedMemberAccumInfo);
            }
        });

        return map;
    }

    static getMapByRegionCodeIdNSportCodeId(
        visitedMemberAccumInfosBySportOfRegion: VisitedMemberAccumInfo[],
        visitedMemberAccumInfosBySport: VisitedMemberAccumInfo[],
    ) {
        const map = new Map<string, VisitedMemberAccumInfo>();
        visitedMemberAccumInfosBySportOfRegion.forEach((value: VisitedMemberAccumInfo) => {
            const regionCodeId = value.regionCode?.codeId;
            const sportCodeId = value.sportCode?.codeId;
            const key = regionCodeId + '/' + sportCodeId;
            map.set(key, value);
        });

        visitedMemberAccumInfosBySport.forEach((value: VisitedMemberAccumInfo) => {
            const totalRegionCodeId = '0';
            const sportCodeId = value.sportCode?.codeId;
            const key = totalRegionCodeId + '/' + sportCodeId;
            map.set(key, value);
        });

        return map;
    }

    static getMapRegionCodeIdNTeamTypeCodeId(
        visitedMemberAccumInfosByTeamTypeOfRegion: VisitedMemberAccumInfo[],
        visitedMemberAccumInfosByTeamType: VisitedMemberAccumInfo[],
    ) {
        const map = new Map<string, VisitedMemberAccumInfo>();

        visitedMemberAccumInfosByTeamTypeOfRegion.forEach((value: VisitedMemberAccumInfo) => {
            const regionCodeId = value.regionCode?.codeId;
            const teamTypeCodeId = value.teamTypeCode?.codeId;
            const key = regionCodeId + '/' + teamTypeCodeId;
            map.set(key, value);
        });

        visitedMemberAccumInfosByTeamType.forEach((value: VisitedMemberAccumInfo) => {
            const totalRegionCodeId = '0';
            const teamTypeCodeId = value.teamTypeCode?.codeId;
            const key = totalRegionCodeId + '/' + teamTypeCodeId;
            map.set(key, value);
        });

        return map;
    }
}

export default VisitedMemberAccumInfo;
