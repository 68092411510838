enum ReportTypeEnum {
    CLINICAL_CHART  = "CLINICAL_CHART",
    NDI = "NDI",
    TOPS = "TOPS",
    SPADI_FORM = "SPADI_FORM",
    WHO = "WHO",
    VAS = "VAS",
    FABQ = "FABQ",
    IESRK = "IESRK",
    BEPSI_K = "BEPSI_K"
}

export default ReportTypeEnum;