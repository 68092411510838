import React, { useState, useEffect } from 'react';
import RehabServiceImpl from '../../service/impl/RehabServiceImpl';
import RehabService from '../../service/RehabService';
import TargetPerson from '../../domain/TargetPerson';
import DivBtnComponent from './utils/DivBtnComponent';
import { JsonConvert } from 'json2typescript';
import CheckCondition from '../../domain/recording/CheckCondition';
import Vas from '../../domain/recording/Vas';
import VasTbComponent from './utils/VasTbComponent';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import RehabHistoryService from '../../service/RehabHistoryService';
import RehabHistoryServiceImpl from '../../service/impl/RehabHistoryServiceImpl';
import RehabTargetHistory from '../../domain/RehabTargetHistory';

const rehabService: RehabService = new RehabServiceImpl();
const rehabHistoryService: RehabHistoryService = new RehabHistoryServiceImpl();

const jsonConvert: JsonConvert = new JsonConvert();

const vasPart = [
    { id: 'lflank', className: 'check-dot check-dot-lflank', value: '1.왼옆구리' },
    { id: 'lwrist', className: 'check-dot check-dot-lwrist', value: '2.왼손목' },
    { id: 'lthigh', className: 'check-dot check-dot-lthigh', value: '3.왼허벅지' },
    { id: 'lankle', className: 'check-dot check-dot-lankle', value: '4.왼발목' },
    { id: 'lfoot', className: 'check-dot check-dot-lfoot', value: '5.왼발' },
    { id: 'neck', className: 'check-dot check-dot-neck', value: '6.목' },
    { id: 'rshoul', className: 'check-dot check-dot-rshoul', value: '7.오른어깨' },
    { id: 'lshoul', className: 'check-dot check-dot-lshoul', value: '8.왼어깨' },
    { id: 'chest', className: 'check-dot check-dot-chest', value: '9.가슴' },
    { id: 'stomach', className: 'check-dot check-dot-stomach', value: '10.복부' },
    { id: 'rknee', className: 'check-dot check-dot-rknee', value: '11.오른무릎' },
    { id: 'lknee', className: 'check-dot check-dot-lknee', value: '12.왼무릎' },
    { id: 'rflank', className: 'check-dot check-dot-rflank', value: '13.오른옆구리' },
    { id: 'rwrist', className: 'check-dot check-dot-rwrist', value: '14.오른손목' },
    { id: 'rthigh', className: 'check-dot check-dot-rthigh', value: '15.오른허벅지' },
    { id: 'rankle', className: 'check-dot check-dot-rankle', value: '16.오른발목' },
    { id: 'rfoot', className: 'check-dot check-dot-rfoot', value: '17.오른발' },
    { id: 'back', className: 'check-dot check-dot-back', value: '18.등' },
    { id: 'waist', className: 'check-dot check-dot-waist', value: '19.허리' },
    { id: 'hips', className: 'check-dot check-dot-hips', value: '20.엉덩이' },
    { id: 'lclaf', className: 'check-dot check-dot-lclaf', value: '21.왼종아리' },
    { id: 'rclaf', className: 'check-dot check-dot-rclaf', value: '22.오른종아리' },
];

interface IPopup {
    status: CheckCondition;
    history: RehabTargetHistory;
    // targetPerson: TargetPerson;
    // closingHandler: () => void;
}

export default function CheckPopupComponent(props: IPopup) {
    const [vas, setVas] = useState<Vas[]>();
    const [conditions, setConditions] = useState<CheckCondition>(new CheckCondition());

    useEffect(() => {
        if (props.status) {
            setConditions(props.status);
            setVas(props.status.vas);
        }
    }, [props.status]);

    useEffect(() => {
        setConditions((prevState) => {
            return { ...prevState, ['vas']: vas };
        });
    }, [vas]);

    const storeHandler = (evt: any) => {
        let history: RehabTargetHistory = props.history;
        history.conditions = JSON.stringify(conditions);

        rehabHistoryService.manageConditionForTarget(history).then((result: RehabTargetHistory) => {
            confirmAlert({
                title: '저장 성공',
                message: '상태 확인 정보를 저장 하였습니다.',
                buttons: [{ label: '확인', onClick: () => {} }],
            });
        });
    };

    const inputChangeHandler = (evt: any) => {
        let targetKey = evt.target.id;
        let val = evt.target.value;
        let name = evt.target.name;

        if (name === 'vas') {
            setConditions((prevState) => {
                let vases: Vas[] = prevState.vas ? prevState.vas : [];
                for (let i = 0; i < vases.length; i++) {
                    let vas = vases[i];
                    if (vas.part === targetKey) vas.recording[0] = val;
                }
                return { ...prevState, vas: vas };
            });
        } else {
            setConditions((prevState) => {
                return { ...prevState, [targetKey]: val };
            });
        }
    };

    const vasInputHandler = (evt: any) => {
        let target = evt.target;
        let selectedClass: string = target.className;
        let targetId: string = target.id;
        let arr = new Array<Vas>();

        if (conditions?.vas) {
            arr = arr.concat(conditions?.vas);
        }

        if (selectedClass.indexOf('check-dot-select') > -1) {
            target.className = selectedClass.replace(' check-dot-select', '');
            let spliceIdx = -1;
            for (let i = 0; i < arr.length; i++) {
                let temp = arr[i];
                if (temp.part == targetId) {
                    spliceIdx = i;
                }
            }
            if (spliceIdx != -1) {
                arr.splice(spliceIdx, 1);

                for (let i = 0; i < arr.length; i++) {
                    let temp = arr[i];
                    temp.seq = i + 1; // 순서정보 재설정
                }
            }
        } else {
            if (arr.length <= 5) {
                target.className = selectedClass + ' check-dot-select';

                let targetData;
                for (let i = 0; i < vasPart.length; i++) {
                    let temp = vasPart[i];
                    if (temp.id == targetId) targetData = vasPart[i];
                }
                var newVas = new Vas();

                if (targetData) {
                    let seq = arr.length + 1;
                    newVas.part = targetData.id;
                    newVas.partName = targetData.value;
                    newVas.seq = seq;
                }

                arr.push(newVas);
            }
        }
        setVas(arr);
    };

    const deleteVasHandler = () => {
        let newVasArr: Vas[] = [];
        setVas(newVasArr);
    };

    return (
        <>
            <div className="center-popup-title">
                <h1 className="center">
                    <i className="fa fa-check"></i> 상태측정
                </h1>

                <div>
                    <button id="complete" className="center-button-complete" onClick={storeHandler}>
                        저 장 <i className="fas fa-file-download"></i>
                    </button>
                </div>
            </div>

            <div className="center-popup-list-tab">
                <div className="center-popup-list-box" style={{ width: '100%' }}>
                    <div className="flex-basic" style={{ alignItems: 'top' }}>
                        <div className="center-half" style={{ width: '50%' }}>
                            <h2 className="center2">1. VAS 통증 부위 / 강도체크</h2>
                            <button className="center-button-no" onClick={deleteVasHandler}>
                                <i className="fas fa-trash-alt"></i> 삭제
                            </button>
                            <div className="center-vas-check">
                                <div className="center-vas-check-bg">
                                    <div id="check-dot-group">
                                        <DivBtnComponent properties={vasPart} handler={vasInputHandler} defaultValue={vas}></DivBtnComponent>
                                    </div>
                                </div>
                                <div className="center-vas-check-right">
                                    <VasTbComponent recording={vas} handler={inputChangeHandler}></VasTbComponent>
                                </div>
                            </div>
                        </div>
                        <div className="center-half" style={{ width: '50%' }}>
                            <div className="center-popup-check-in">
                                <p className="center-popup-tit">2. 자각증상</p>
                                <textarea
                                    id="ss"
                                    name="conditions"
                                    className="center-input-text"
                                    rows={2}
                                    value={conditions?.ss}
                                    placeholder="자각 증상을 입력해 주십시오."
                                    onChange={inputChangeHandler}
                                />
                            </div>

                            <div className="center-popup-check-in">
                                <p className="center-popup-tit">3. 병력</p>
                                <textarea
                                    id="mh"
                                    name="conditions"
                                    className="center-input-text"
                                    rows={2}
                                    value={conditions?.mh}
                                    placeholder="병력을 입력해 주십시오."
                                    onChange={inputChangeHandler}
                                />
                            </div>

                            <div className="center-popup-check-in">
                                <p className="center-popup-tit">4. 가족력</p>
                                <textarea
                                    id="fh"
                                    name="conditions"
                                    className="center-input-text"
                                    rows={2}
                                    value={conditions?.fh}
                                    placeholder="가족력을 입력해 주십시오."
                                    onChange={inputChangeHandler}
                                />
                            </div>

                            <div className="center-popup-check-in">
                                <p className="center-popup-tit">5. 생활습관</p>
                                <textarea
                                    id="ha"
                                    name="conditions"
                                    className="center-input-text"
                                    rows={2}
                                    value={conditions?.ha}
                                    placeholder="생활 습관을 입력해 주십시오."
                                    onChange={inputChangeHandler}
                                />
                            </div>

                            <div className="center-popup-check-in">
                                <p className="center-popup-tit">6. 선수반응상태</p>
                                <textarea
                                    id="rs"
                                    name="conditions"
                                    className="center-input-text"
                                    rows={2}
                                    value={conditions?.rs}
                                    placeholder="선수반응 상태를 입력해 주십시오."
                                    onChange={inputChangeHandler}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
