import AbstractService from '../../AbstractService';
import TeamService from '../TeamService';
import Team from '../../../domain/team/Team';
import APIServerConfig from '../../../config/APIServerConfig';
import URLParamMakerUtils from '../../../utils/URLParamMakeUtils';
import BelongTeam from '../../../domain/user/BelongTeam';
import TeamSearchCondition from '../../../domain/team/param/TeamSearchCondition';
import Paging from '../../../domain/common/Paging';
class TeamServiceImpl extends AbstractService implements TeamService {
    registeTeam(team: Team): Promise<Team> {
        return new Promise((resolve) => {
            APIServerConfig.recordAPIAxios.post(`/team`, JSON.stringify(team)).then((response) => {
                let newTeam: Team = this.jsonConvert.deserializeObject(response.data, Team);
                resolve(newTeam);
            });
        });
    }

    updateTeam(teamNo: string, team: Team): Promise<Team> {
        return new Promise((resolve, reject) => {
            APIServerConfig.recordAPIAxios
                .put(`/team/${teamNo}`, JSON.stringify(team))
                .then((response) => {
                    let updatedTeam: Team = this.jsonConvert.deserializeObject(response.data, Team);
                    resolve(updatedTeam);
                })
                .catch((err) => {
                    alert('반영 실패');
                });
        });
    }

    deleteTeam(teamNo: string): Promise<Team> {
        return new Promise((resolve, reject) => {
            APIServerConfig.recordAPIAxios
                .delete(`/team/${teamNo}`)
                .then((response) => {
                    //let deletedTeam: Team = this.jsonConvert.deserializeObject(response.data, Team);
                    let deletedTeam: any = response.data;
                    resolve(deletedTeam);
                })
                .catch((err) => {
                    alert('반영 실패');
                });
        });
    }

    getTeamList(): Promise<Team[]> {
        return new Promise((resolve, reject) => {
            APIServerConfig.recordAPIAxios.get('/team').then((response) => {
                const teams: Team[] = this.jsonConvert.deserializeArray(response.data, Team);
                resolve(teams);
            });
        });
    }

    getTeamInfo(teamNo: string): Promise<Team> {
        return new Promise((resolve, reject) => {
            APIServerConfig.recordAPIAxios.get(`/team/${teamNo}`).then((response) => {
                const team: Team = this.jsonConvert.deserializeObject(response.data, Team);
                resolve(team);
            });
        });
    }

    getTeams(parsedSearchConditionMap: any, searchWord: string | undefined): Promise<Team[]> {
        return new Promise((resolve, reject) => {
            let queryParam = URLParamMakerUtils.getParam(parsedSearchConditionMap, searchWord);
            let url = queryParam ? `/team${queryParam}` : `/team`;
            APIServerConfig.recordAPIAxios.get(url).then((response) => {
                let teams: Team[] = this.jsonConvert.deserializeArray(response.data, Team);
                resolve(teams);
            });
        });
    }

    getTeamsCnt(parsedSearchConditionMap: any, searchWord: string | undefined): Promise<number> {
        return new Promise((resolve, reject) => {
            let queryParam = URLParamMakerUtils.getParam(parsedSearchConditionMap, searchWord);
            let url = queryParam ? `/team/cnt${queryParam}` : `/team/cnt`;
            APIServerConfig.recordAPIAxios.get(url).then((response) => {
                let teamsCnt: number = response.data;
                resolve(teamsCnt);
            });
        });
    }

    getBelongedUsers(teamNo: string): Promise<BelongTeam[]> {
        return new Promise((resolve) => {
            APIServerConfig.recordAPIAxios
                .get(`/team/users`, {
                    params: { teamNo: teamNo },
                })
                .then((response) => {
                    let belongTeams: BelongTeam[] = this.jsonConvert.deserializeArray(response.data, BelongTeam);
                    resolve(belongTeams);
                });
        });
    }

    getTeamBySearchCondition(teamSearchCondition: TeamSearchCondition, paging: Paging): Promise<Team[]> {
        return new Promise((resolve, reject) => {
            APIServerConfig.recordAPIAxios
                .get(`/team`, {
                    params: Object.assign(teamSearchCondition, paging),
                })
                .then((response) => {
                    let teams: Team[] = this.jsonConvert.deserializeArray(response.data, Team);
                    resolve(teams);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    getTeamCntBySearchCondition(teamSearchCondition: TeamSearchCondition): Promise<number> {
        return new Promise((resolve, reject) => {
            APIServerConfig.recordAPIAxios
                .get(`/team/cnt`, {
                    params: teamSearchCondition,
                })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }
}

export default TeamServiceImpl;
