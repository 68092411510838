import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject("roleGroups")
class RoleGroups {
    @JsonProperty("roleGroupNo")
    roleGroupNo?: number;
    @JsonProperty("groupName")
    groupName?: string;
    @JsonProperty("groupDesc")
    groupDesc?: string;
}

export default RoleGroups;