import React, { useState } from 'react';
import { GOOGLE_AUTH_URL, KAKAO_AUTH_URL, NAVER_AUTH_URL } from '../../../config/SocialServerConfig';
import TrainningSubHeader from '../../trainning/layout/TrainningSubHeader';
import { Link, RouteComponentProps } from 'react-router-dom';
import useLogin from '../../../hook/user/useLogin';
import LoginHeader from '../layout/LoginHeader';

interface SocialLoginProps {
    message?: string;
}
function SocialLogin(props: SocialLoginProps) {
    const [id, setId] = useState<string>();
    const [pw, setPw] = useState<string>();
    const { loginProccess } = useLogin('/', id, pw);

    const styled = {
        display: 'block',
    };

    const popup_styled = {
        paddingTop: '20px',
    };

    const imageSize = {
        height: '60px',
        width: '60px',
    };

    return (
        <>
            <LoginHeader></LoginHeader>
            <div className="popup-opened">
                <div className="join">
                    <div id="sportsman" className="mypage-login" style={styled}>
                        <p className="intro-title">
                            <img src="/assets/image/logo_with_name.png" width={300} />
                        </p>
                        <div className="sns-login">
                            {/* <div className="google">
                                <a className="" href={GOOGLE_AUTH_URL}>
                                    <img src="/assets/image/5.google-logo.png" />
                                    &nbsp;&nbsp;&nbsp;| 구글로그인
                                </a>
                            </div> */}
                            {/* <a href="#" onClick={() => {alert("기능 준비 중입니다.")}}><img src="/assets/image/facebook.png" /></a> */}
                            <div className="naver">
                                <a className="" href={NAVER_AUTH_URL}>
                                    <img src="/assets/image/6.naver.png" />
                                    &nbsp;&nbsp;&nbsp;| 네이버로그인
                                </a>
                            </div>
                            <div className="kakao">
                                <a className="" href={KAKAO_AUTH_URL}>
                                    <img src="/assets/image/4.kakaotalk.png" />
                                    &nbsp;&nbsp;&nbsp;| 카카오로그인
                                </a>
                            </div>
                        </div>
                        <div className="login-form txt-center">
                            <input
                                className="basic-gray"
                                placeholder="아이디 입력"
                                type="text"
                                value={id}
                                onChange={(event) => {
                                    setId(event.currentTarget.value);
                                }}
                                onKeyPress={(event) => {
                                    if (event.key === 'Enter') {
                                        loginProccess();
                                    }
                                }}
                            ></input>

                            <input
                                className="basic-gray"
                                placeholder="비밀번호 입력"
                                type="password"
                                value={pw}
                                onChange={(event) => {
                                    setPw(event.currentTarget.value);
                                }}
                                onKeyPress={(event) => {
                                    if (event.key === 'Enter') {
                                        loginProccess();
                                    }
                                }}
                            ></input>
                            <br></br>
                            <button className="center-button-login" onClick={loginProccess}>
                                로그인
                            </button>
                        </div>
                        {/* <div className="dan"><span className="dan-red">!</span>아이디를 찾을 수 없습니다.</div> */}
                        <div style={{ color: 'red' }}>{props.message}</div>
                        <div className="lay-basic mgt10">
                            <span>
                                <Link to="/idfinder/user-checker">아이디 찾기</Link>
                                &nbsp;|&nbsp;
                                <Link to="/pwfinder/user-checker">비밀번호 찾기</Link>
                            </span>
                            {/* <span><a className="bt-gray" href="#">다음</a></span> */}
                        </div>
                        <div className="mgt10"></div>
                    </div>
                </div>
            </div>
        </>
    );
}

interface LoginContainerProps {}

function LoginContainer(props: RouteComponentProps<LoginContainerProps>) {
    let state: any = props.location.state;
    return <div>{state ? <SocialLogin message={state.message}></SocialLogin> : <SocialLogin></SocialLogin>}</div>;
}

export default LoginContainer;
