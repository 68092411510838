import React, { useEffect, useState } from 'react';
import Infra from '../../domain/infra/Infra';
import Charge from '../../domain/chage/Charge';
import ChargeOfInfraServiceImpl from '../../service/charge/impl/ChargeOfInfraServiceImpl';

interface AdminInfraChargeTrProps {
    infra: Infra;
    key: number;
}
const chargeOfInfraservice = new ChargeOfInfraServiceImpl();
function AdminInfraChargeTr(props: AdminInfraChargeTrProps) {
    const [charges, setCharges] = useState(new Array<Charge>());
    const [chargeTypeNo, setChargeTypeNo] = useState<number>();
    const [cost, setCost] = useState<number>();
    const [costs, setCosts] = useState<number[]>();

    useEffect(() => {
        const minCost = 10000;
        const maxCost = 200000;
        const costs = [];
        for (let initCost = minCost; initCost <= maxCost; initCost += 5000) {
            costs.push(initCost);
        }
        setCosts(costs);
        if (props.infra.charges) {
            setCharges(props.infra.charges);
        }
    }, []);

    useEffect(() => {}, [chargeTypeNo, cost]);

    return (
        <>
            <tr key={props.key}>
                <td className="txt-left">
                    {props.infra.name} ({props.infra.reservationTimeUnit}시간 기준)
                </td>
                <td className="txt-right">
                    {charges?.map((charge: Charge, index: number) => {
                        return (
                            <>
                                {charge.chargeTypeCode?.name}
                                <span className="red">{charge.cost}원</span>
                                <br></br>
                            </>
                        );
                    })}
                </td>
            </tr>
            <tr>
                <td></td>
                <td className="txt-right">
                    <select
                        onChange={(event) => {
                            setChargeTypeNo(Number(event.currentTarget.value));
                        }}
                    >
                        <option>요금유형</option>
                        <option value={31}>주중</option>
                        <option value={32}>주말 및 공휴일</option>
                    </select>
                    <select
                        onChange={(event) => {
                            setCost(Number(event.currentTarget.value));
                        }}
                    >
                        <option>금액</option>
                        {costs?.map((cost: number) => {
                            return <option value={cost}>{cost}원</option>;
                        })}
                    </select>
                    <button
                        onClick={() => {
                            if (props.infra.infraNo && chargeTypeNo && cost) {
                                let newCharge = Charge.makeCharge(props.infra.infraNo, chargeTypeNo, cost);
                                chargeOfInfraservice.registe(newCharge).then((registedNewCharge: Charge) => {
                                    let newCharges: Charge[] | undefined = charges?.filter((item) => {
                                        return item.chargeTypeCodeId !== registedNewCharge.chargeTypeCodeId;
                                    });
                                    newCharges = newCharges?.concat(registedNewCharge);

                                    setCharges(newCharges);
                                });
                            }
                        }}
                        className="bg-blue white"
                    >
                        <i className="fas fa-plus-circle"></i>요금 추가
                    </button>
                </td>
            </tr>
        </>
    );
}

export default AdminInfraChargeTr;
