import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import SubBoardServiceImpl from './service/impl/SubBoardServiceImpl';

function SubBoardComponent(prop: any) {
    let hostURL = process.env.REACT_APP_IIS_URL;
    let resourceHostURL = process.env.REACT_APP_RESOURCE_API_HOST;
    const [notice, setNotice] = useState<any[]>();
    const [news, setNews] = useState<any[]>();
    const [banners, setBanners] = useState<any[]>();

    const [control, setControl] = useState('_0');

    const subBoardService = new SubBoardServiceImpl();
    const coreAPIURL = process.env.REACT_APP_IIS_URL;
    let seq = 0;
    useEffect(() => {
        subBoardService.getSubBoards(0).then((data: any) => {
            setNotice(data.subNotices);
            setNews(data.subNews);
            setBanners(data.subBanners);
            const idx = _.findIndex(data.subBanners, (item: any) => {
                return item.reflctAt === 'Y';
            });
            setControl(`_${idx}`);
        });
    }, []);

    const noticeContents = (notice?: any[]) => {
        let list = <></>;
        if (notice?.length != 0) {
            list = (
                <>
                    {notice?.map((content, idx) => {
                        let id = 'notice/' + content.bbsId + '/' + content.nttId;
                        return (
                            <li id={id} key={idx} onClick={selectContentHandler} style={{ cursor: 'pointer' }}>
                                &nbsp;&nbsp;{content.nttSj}
                            </li>
                        );
                    })}
                </>
            );
        } else {
            list = <li style={{ textAlign: 'center' }}>등록 된 소식이 없습니다.</li>;
        }

        return list;
    };

    const newsContents = (news?: any[]) => {
        let list = <> </>;

        if (news?.length != 0) {
            list = (
                <>
                    {news?.map((content, idx) => {
                        let id = 'news/' + content.newsId;
                        return (
                            <li id={id} key={idx} onClick={selectContentHandler} style={{ cursor: 'pointer' }}>
                                &nbsp;&nbsp;{content.newsSj}
                            </li>
                        );
                    })}
                </>
            );
        } else {
            list = <li style={{ textAlign: 'center' }}>등록 된 보도 자료가 없습니다.</li>;
        }

        return list;
    };

    const bannerHandler = (evt: any) => {
        let controlId = evt.currentTarget.id;
        setControl(controlId);
    };

    const bannerController = (banners?: any[]) => {
        let controller = <></>;
        if (banners) {
            let isContainReflct = _.find(banners, function (item) {
                return item.reflctAt === 'Y';
            });
            if (isContainReflct) {
                controller = (
                    <>
                        {banners.map((content, idx) => {
                            if (content.reflctAt === 'Y') {
                                return (
                                    <span
                                        id={`_` + idx}
                                        key={idx}
                                        className={control === `_` + idx ? 'blue_dot blue_activee' : 'blue_dot'}
                                        onClick={bannerHandler}
                                    ></span>
                                );
                            }
                        })}
                    </>
                );
            } else {
                return <span className={'blue_dot'}></span>;
            }
        }

        return controller;
    };

    const bannerContents = (banners?: any[]) => {
        let bannerContents = <></>;
        if (banners) {
            let isContainReflct = _.find(banners, function (item) {
                return item.reflctAt === 'Y';
            });

            if (isContainReflct) {
                bannerContents = (
                    <>
                        {banners.map((content, idx) => {
                            let imageUrl = resourceHostURL + '/static-file/' + content.streFileNm;
                            if (content.reflctAt === 'Y')
                                return (
                                    <>
                                        <div
                                            id={`em_banner0_` + idx}
                                            style={`em_banner0` + control === `em_banner0_` + idx ? { display: 'block' } : { display: 'none' }}
                                            key={`b` + idx}
                                        >
                                            <a href={content.linkUrl}>
                                                <img src={imageUrl} style={{ width: '358px', height: '250px', border: '1px solid #e3e3e3' }} />
                                            </a>
                                            <div className="txt" style={{ width: '330px', wordBreak: 'normal', marginLeft: '1px' }}>
                                                <a href={content.linkUrl}>{content.bannerDc}</a>
                                            </div>
                                        </div>
                                    </>
                                );
                        })}
                    </>
                );
            } else {
                bannerContents = (
                    <div id={`em_banner0_` + 0}>
                        <div className="txt" style={{ width: '330px', wordBreak: 'normal', marginLeft: '1px' }}>
                            등록 된 배너가 없습니다.
                        </div>
                    </div>
                );
            }
        } else {
            bannerContents = (
                <div id={`em_banner0_` + 0}>
                    <div className="txt" style={{ width: '330px', wordBreak: 'normal', marginLeft: '1px' }}>
                        등록 된 배너가 없습니다.
                    </div>
                </div>
            );
        }

        return bannerContents;
    };

    const selectContentHandler = (evt: any) => {
        let target = evt.target;
        let destination = target.id;
        let arr = destination?.split('/');
        let url: string | undefined = coreAPIURL;

        if (typeof arr != 'undefined' && arr.length > 0) {
            let type = arr[0];
            if (type === 'news' && typeof url != 'undefined') {
                url += '/web/news02Detail.do?newsId=' + arr[1];
                window.location.href = url;
            } else if (type === 'notice' && typeof url != 'undefined') {
                url += '/web/news01Detail.do?bbsId=' + arr[1] + '&nttId=' + arr[2];
                window.location.href = url;
            }
        }
    };

    const subBoardHandler = (evt: any) => {
        evt.preventDefault();

        let id = evt.target.id;
        let noticeList = document.getElementById('noticeList')?.classList;
        let newsList = document.getElementById('newsList')?.classList;

        noticeList?.remove('tabOn');
        noticeList?.remove('tabOff');
        newsList?.remove('tabOn');
        newsList?.remove('tabOff');

        if (id === 'news') {
            newsList?.add('tabOn');
            noticeList?.add('tabOff');
        } else if (id === 'notice') {
            newsList?.add('tabOff');
            noticeList?.add('tabOn');
        }
    };

    let noticeUrl = coreAPIURL + '/web/news01.do';
    let newsUrl = coreAPIURL + '/web/news02.do';

    return (
        <>
            <div className="main_contents">
                <div className="board">
                    <div className="board_ad">
                        <ul className="board_list">
                            <li id="noticeList" className="tabOn">
                                <h3>
                                    <a id="notice" href="#" onClick={subBoardHandler}>
                                        센터뉴스
                                    </a>
                                </h3>
                                <ul>{noticeContents(notice)}</ul>
                                <a href={noticeUrl} className="more">
                                    센터뉴스 더보기
                                </a>
                            </li>
                            <li id="newsList" className="tabOff">
                                <h3>
                                    <a id="news" href="#" onClick={subBoardHandler}>
                                        보도자료
                                    </a>
                                </h3>
                                <ul>{newsContents(news)}</ul>
                                <a href={newsUrl} className="more">
                                    보도자료 더보기
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="em_link">
                    <h3>바로가기</h3>
                    <div className="link_box link_box01">
                        <a href={`${hostURL}/web/business01.do`}>
                            <p className="link_txt1">사업소개</p>
                            <p className="link_txt2">
                                경남스포츠산업육성지원센터
                                <br />
                                사업을 소개합니다
                            </p>
                        </a>
                    </div>
                    <div className="link_box link_box02">
                        <a href={`${hostURL}/web/business03.do`}>
                            <p className="link_txt1">사업 FAQ</p>
                            <p className="link_txt2">
                                경남스포츠산업육성지원센터
                                <br />
                                사업 FAQ입니다
                            </p>
                        </a>
                    </div>
                </div>
                <div className="em_banner">
                    <div className="em_banner_ad">
                        <h3>배너존</h3>
                        <div className="dot-box">{bannerController(banners)}</div>
                        {bannerContents(banners)}
                    </div>
                </div>
            </div>
        </>
    );
}

export default SubBoardComponent;
