import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import '../assets/css/center.css';
import '../assets/css/animate.min.css';
import Layout from '../layouts/Layout';
import Header from '../layouts/Header';
import SideBar from '../layouts/SideBar';
import RehabServiceImpl from '../service/impl/RehabServiceImpl';
import Booking from '../domain/Booking';

import { bookingStatus } from './components/StatusUtils';
import pagination from './components/PaginationComponent';

import UserDetailInfo from '../../../domain/user/UserDetailInfo';
import TargetPerson from '../domain/TargetPerson';
import { confirmAlert } from 'react-confirm-alert';

const rehabService = new RehabServiceImpl();
const menuTitle: string = '* 예약자 관리';
const title: string = '예약';

export default function ReservedListComponent(props: any) {
    const [bookingInfos, setBookingInfos] = useState<Booking[]>();
    const [reservState, setReservState] = useState<string>('0');
    const [retrieveKeyword, setRetrieveKeyword] = useState<{ type: string; keyword: string }>({ type: 'name', keyword: '' });
    const reservedItemHandler = (e: any) => {
        e.preventDefault();
        let action = e.target.id;
        let reservationNo: string = e.target.value;

        if (action === 'approveReser') {
            rehabService.bookApproval(reservationNo, '').then((reservedResult: TargetPerson) => {
                if (reservedResult != null) {
                    let reservaterName = reservedResult.reservater?.name;
                    let message = '승인이 완료되었습니다.';
                    if (reservaterName) message += ' <상담> 메뉴에서 [' + reservaterName + '] 님 확인 가능합니다.';

                    confirmAlert({
                        title: '승인 성공',
                        message: message,
                        buttons: [
                            {
                                label: '확인',
                                onClick: () => {
                                    let updatedNo = reservedResult.reservationNo;
                                    let newState: Booking[] = [];

                                    bookingInfos?.forEach((item, idx) => {
                                        if (item.reservationNo === updatedNo) item.reservationStateCodeId = 1002;
                                        newState[idx] = item;
                                    });

                                    setBookingInfos(newState);
                                },
                            },
                        ],
                    });
                } else {
                    confirmAlert({
                        customUI: ({ onClose }) => (
                            <div>
                                <table className="center-information-table">
                                    <thead>
                                        <tr>
                                            <td>경고</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                예약자는 검진 중입니다. <br /> 검진이 완료 된 후 예약을 진행해 주십시오.
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        ),
                        overlayClassName: 'overlay-custom-class-name',
                    });
                }
            });
        } else if (action === 'cancelReser') {
            // rehabService.bookCancellation(reservationNo, '').then((reservedResult: TargetPerson) => {
            //     confirmAlert({
            //         title: '검진 취소 성공',
            //         message: '예약이 취소 되었습니다.',
            //         buttons: [
            //             {
            //                 label: '확인',
            //                 onClick: () => {
            //                     let newState: Booking[] = [];
            //                     bookingInfos?.forEach((item, idx) => {
            //                         if (item.reservationNo === reservationNo) item.reservationStateCodeId = 1006;
            //                         newState[idx] = item;
            //                     });
            //                     setBookingInfos(newState);
            //                 },
            //             },
            //         ],
            //     });
            // });
        } else if (action === 'deleteReser') {
            rehabService.deletingBook(reservationNo, '').then((reservedResult: TargetPerson) => {
                confirmAlert({
                    title: '예약 삭제 성공',
                    message: '예약이 삭제 되었습니다.',
                    buttons: [
                        {
                            label: '확인',
                            onClick: () => {
                                let newState: Booking[] = [];

                                bookingInfos?.forEach((item, idx) => {
                                    if (item.reservationNo !== reservationNo) newState[idx] = item;
                                });

                                setBookingInfos(newState);
                            },
                        },
                    ],
                });
            });
        }
    };
    const approvingItemsHandler = (action: string, reservationNos: string[]) => {
        if (action === 'approveReser') {
            rehabService.bookApprovals(reservationNos).then((reservedResult: TargetPerson[]) => {
                let newState: Booking[] = [];
                bookingInfos?.forEach((item, idx) => {
                    reservationNos.map((reservNo) => {
                        if (reservNo === item.reservationNo) {
                            item.isSelected = false;
                            item.reservationStateCodeId = 1002;
                        }
                    });
                    newState[idx] = item;
                });
                setReservState('0');
                setBookingInfos(newState);
            });
        } else if (action === 'cancelReser') {
            // rehabService.bookCancellations(reservationNos).then((reservedResult: TargetPerson[]) => {
            //     let newState: Booking[] = [];
            //     bookingInfos?.forEach((item, idx) => {
            //         reservationNos.map((reservNo) => {
            //             if (reservNo === item.reservationNo) {
            //                 item.isSelected = false;
            //                 item.reservationStateCodeId = 1006;
            //             }
            //         });
            //         newState[idx] = item;
            //     });
            //     setReservState('0');
            //     setBookingInfos(newState);
            // });
        }
    };

    const statusHandler = (toggle: string) => {
        let input = document.getElementById('keyword-input');
        let select = document.getElementById('keyword-select');
        let centers = document.getElementById('centers-select');

        if (toggle === 'input') {
            if (input) input.style.display = 'block';
            if (select) select.style.display = 'none';
            if (centers) centers.style.display = 'none';
        } else if (toggle === 'centers') {
            if (input) input.style.display = 'none';
            if (select) select.style.display = 'none';
            if (centers) centers.style.display = 'block';
        } else {
            if (input) input.style.display = 'none';
            if (select) select.style.display = 'block';
            if (centers) centers.style.display = 'none';
        }
    };
    const changeKeywordHandler = (e: any) => {
        let key = e.target.id;
        let inputVal = e.target.value;

        if (key === 'selectKeywordType') {
            if (inputVal === 'status') {
                statusHandler('select');
                setRetrieveKeyword((prevState) => {
                    return { ...prevState, ['type']: inputVal, ['keyword']: '0' };
                });
            } else if (inputVal === 'centers') {
                statusHandler('centers');
                setRetrieveKeyword((prevState) => {
                    return { ...prevState, ['type']: inputVal, ['keyword']: '0' };
                });
            } else {
                statusHandler('input');
                setRetrieveKeyword((prevState) => {
                    return { ...prevState, ['type']: inputVal, ['keyword']: '' };
                });
            }
        } else if (key === 'inputKeyword') {
            setRetrieveKeyword((prevState) => {
                return { ...prevState, ['keyword']: inputVal };
            });
        } else if (key === 'selectKeyword') {
            setRetrieveKeyword((prevState) => {
                let selecting = { ...prevState, ['keyword']: inputVal };
                selectingHandler(selecting);
                return selecting;
            });
        } else if (key === 'selectCenter') {
            setRetrieveKeyword((prevState) => {
                let selecting = { ...prevState, ['keyword']: inputVal };
                selectingHandler(selecting);
                return selecting;
            });
        }
    };

    const filters = () => {
        return (
            <div className="center-btn-right635">
                <div className="center-select-box">
                    <label># 예약상태: </label>
                    <select className="center-reserved-select" onChange={changeStatusHandler} value={reservState}>
                        <option value="0">예약상태관리</option>
                        <option value="applyAll">모두승인</option>
                        <option value="selectedApply">선택승인</option>
                        <option value="deleteAll">모두취소&삭제</option>
                        <option value="selectedDelete">선택취소&삭제</option>
                    </select>

                    <label># 검색: </label>
                    <select id="selectKeywordType" className="center-select" value={retrieveKeyword.type} onChange={changeKeywordHandler}>
                        <option value="name">이름</option>
                        <option value="belongTeam">소속팀</option>
                        <option value="type">운동종목</option>
                        <option value="status">예약상태</option>
                        <option value="centers">예약센터</option>
                    </select>
                    <div id="keyword-input" className="center-search">
                        <input
                            id="inputKeyword"
                            className="center-input"
                            placeholder="검색어를 입력하세요"
                            onChange={changeKeywordHandler}
                            value={retrieveKeyword.keyword}
                        />
                        <button className="center-button" onClick={retrieveHandler}>
                            <i className="fas fa-search"></i>
                        </button>
                    </div>
                    <div id="keyword-select" className="center-search" style={{ display: 'none' }}>
                        <select id="selectKeyword" className="center-reserved-select" value={retrieveKeyword.keyword} onChange={changeKeywordHandler}>
                            <option value="0">예약상태를선택해주세요</option>
                            <option value="1001">예약신청</option>
                            <option value="1002">예약승인</option>
                            <option value="1006">예약취소</option>
                        </select>
                    </div>
                    <div id="centers-select" className="center-search" style={{ display: 'none' }}>
                        <select id="selectCenter" className="center-reserved-select" value={retrieveKeyword.keyword} onChange={changeKeywordHandler}>
                            <option value="0">예약센터를선택해주세요</option>
                            <option value="center_1">마산대센터</option>
                            <option value="center_2">의령센터</option>
                            <option value="center_3">고성센터</option>
                        </select>
                    </div>
                    {/* <button className="center-button">
                        <i className="fas fa-filter"></i> 필터링
                    </button> */}
                </div>
            </div>
        );
    };

    const confirmBtns = (stateCodeId: number, reservationNo: string) => {
        let btns = (
            <>
                검진중...&nbsp;&nbsp;
                <button id="cancelReser" className="center-button-condition" onClick={reservedItemHandler} value={reservationNo}>
                    <i className="fas fa-ban"></i> 검진취소
                </button>
            </>
        );
        if (stateCodeId != 1002) {
            btns = (
                <>
                    <button id="approveReser" className="center-button-yes" onClick={reservedItemHandler} value={reservationNo}>
                        <i className="fas fa-check-circle"></i> 승인
                    </button>
                    &nbsp;
                    <button id="cancelReser" className="center-button-cancle" onClick={reservedItemHandler} value={reservationNo}>
                        <i className="fas fa-ban"></i> 취소
                    </button>
                    &nbsp;
                    <button id="deleteReser" className="center-button-del" onClick={reservedItemHandler} value={reservationNo}>
                        <i className="fas fa-trash-alt"></i> 삭제
                    </button>
                </>
            );
        }
        return btns;
    };
    const [wholeSelected, setWholeSelected] = useState<boolean>(false);

    const changeStatusHandler = (e: any) => {
        let target = e.target;
        let inputVal = target.value;
        let boogingTargets: string[] = [];
        let action;

        if (inputVal !== '0') {
            bookingInfos?.map((item) => {
                if (
                    inputVal === 'selectedApply' &&
                    item.isSelected &&
                    (item.reservationStateCodeId === 1001 || item.reservationStateCodeId === 1006)
                ) {
                    action = 'approveReser';
                    boogingTargets.push(item.reservationNo);
                } else if (inputVal === 'selectedDelete' && item.isSelected && item.reservationStateCodeId === 1002) {
                    action = 'cancelReser';
                    boogingTargets.push(item.reservationNo);
                } else if (inputVal === 'applyAll' && (item.reservationStateCodeId === 1001 || item.reservationStateCodeId === 1006)) {
                    action = 'approveReser';
                    boogingTargets.push(item.reservationNo);
                } else if (inputVal === 'deleteAll' && item.reservationStateCodeId === 1002) {
                    action = 'cancelReser';
                    boogingTargets.push(item.reservationNo);
                }
            });

            if (action) {
                approvingItemsHandler(action, boogingTargets);
            } else {
                confirmAlert({
                    title: '경고',
                    message: '선택 된 내용이 없습니다. 먼저 대상을 선택해 주세요!',
                    buttons: [
                        {
                            label: '확인',
                            onClick: () => {
                                setReservState('0');
                            },
                        },
                    ],
                });
            }
        }
        setReservState(inputVal);
    };

    const changeRowStateHandler = (e: any) => {
        let target = e.target;
        let targetId = target.id;
        let checked = target.checked;
        let reservationNo = target.id;

        let newBookingInfo: Booking[] = [];

        if (targetId === 'wholeSelect') {
            bookingInfos?.map((booking: Booking) => {
                booking.isSelected = checked;
                newBookingInfo.push(booking);
            });
            setBookingInfos(newBookingInfo);
            setWholeSelected(checked);
        } else {
            setWholeSelected(false);
            bookingInfos?.map((booking: Booking) => {
                if (booking.reservationNo == reservationNo) booking.isSelected = checked;
                newBookingInfo.push(booking);
            });
            setBookingInfos(newBookingInfo);
        }
    };

    const contents = (bookingInfo?: Booking[], currPage?: number, displaySize?: number) => {
        let page = currPage ? currPage : 0;
        let pageSize = displaySize ? displaySize : 0;
        let rowNum = page * pageSize;

        let table = (
            <table className="center-list-table" cellPadding="0" cellSpacing="0">
                <thead>
                    <tr>
                        <td>
                            <input id="wholeSelect" type="checkbox" value="whole" checked={wholeSelected} onChange={changeRowStateHandler}></input>
                        </td>
                        <td>No.</td>
                        <td>이름</td>
                        <td>성별</td>
                        <td>생년월일</td>
                        <td>소속팀</td>
                        <td>운동종목</td>
                        <td>예약일</td>
                        <td>예약시간</td>
                        <td>예약승인상태</td>
                        <td>예약센터</td>
                        <td>예약관리</td>
                    </tr>
                </thead>
                <tbody>
                    {bookingInfo?.map((booking: Booking, idx) => {
                        let reservater: UserDetailInfo | undefined = booking.reservater;
                        let bookingTime: string = booking.startDate;
                        let status: any = booking?.reservationStateCode;
                        let genderCode: any = reservater?.genderCode;
                        let regionCode: any = reservater?.regionCode;
                        let sportCode: any = reservater?.sportCode;
                        let infraInfo: any = booking.infra;
                        let timeArr: string[] = bookingTime != null ? bookingTime.split(' ') : ['-', '-'];

                        return (
                            <tr key={idx}>
                                <td>
                                    <input
                                        type="checkbox"
                                        id={booking.reservationNo}
                                        checked={booking.isSelected}
                                        onChange={changeRowStateHandler}
                                    ></input>
                                </td>
                                <td>{rowNum + idx + 1}</td>
                                <td>{reservater?.name}</td>
                                <td>{genderCode?.name}</td>
                                <td>{reservater?.birthdate}</td>
                                <td>
                                    {reservater?.teamName}/{regionCode.name}
                                </td>
                                <td>{sportCode.name}</td>
                                <td>{timeArr[0]}</td>
                                <td>{timeArr[1]}</td>
                                <td>{bookingStatus(status, booking.reservationStateCodeId)}</td>
                                <td>{infraInfo?.regionCode.name}</td>
                                <td>{confirmBtns(booking.reservationStateCodeId, booking.reservationNo)}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        );

        return table;
    };

    useEffect(() => {
        let pagination = { size: 10, page: 0 };
        rehabService.getBookingList(retrieveKeyword, pagination).then((bookingInfo: { data: Booking[]; totalSize: number }) => {
            setBookingInfos(bookingInfo.data);
            setTotalSize(bookingInfo.totalSize);
        });
    }, []);

    const [totalSize, setTotalSize] = useState(1);
    const [displaySize, setDisplaySize] = useState(10);
    const [currPage, setCurrPage] = useState(0);

    const pagingHandler = (pagination: { size: number; page: number }) => {
        setCurrPage(pagination.page);
        setDisplaySize(pagination.size);
        rehabService.getBookingList(retrieveKeyword, pagination).then((bookingInfo: { data: Booking[]; totalSize: number }) => {
            setBookingInfos(bookingInfo.data);
            setTotalSize(bookingInfo.totalSize);
        });
    };

    const retrieveHandler = () => {
        setCurrPage(0);
        setDisplaySize(10);
        let pagination = { size: 10, page: 0 };
        rehabService.getBookingList(retrieveKeyword, pagination).then((bookingInfo: { data: Booking[]; totalSize: number }) => {
            setBookingInfos(bookingInfo.data);
            setTotalSize(bookingInfo.totalSize);
        });
    };
    const selectingHandler = (keywordInfo: { type: string; keyword: string }) => {
        setCurrPage(0);
        setDisplaySize(10);
        let pagination = { size: 10, page: 0 };
        rehabService.getBookingList(keywordInfo, pagination).then((bookingInfo: { data: Booking[]; totalSize: number }) => {
            setBookingInfos(bookingInfo.data);
            setTotalSize(bookingInfo.totalSize);
        });
    };

    let path = props.location.pathname;
    let reservedList = (
        <Layout
            header={<Header></Header>}
            sideBar={<SideBar name={path}></SideBar>}
            path={path}
            naviTitle={title}
            menuTitle={menuTitle}
            filter={filters()}
            pagination={pagination(pagingHandler, totalSize, currPage, displaySize)}
        >
            {contents(bookingInfos, currPage, displaySize)}
        </Layout>
    );

    return reservedList;
}
