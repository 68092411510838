import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject("signupInfo")
class SignupInfo {
    @JsonProperty("name")
    name?: string;
    @JsonProperty("provider")
    provider?: string;
    @JsonProperty("providerId")
    providerId?: string;
    @JsonProperty("email")
    email?: string;
    @JsonProperty("belongedTeamNo")
    belongedTeamNo?: string;
    @JsonProperty("userTypeCodeId")
    userTypeCodeId?: number;
    @JsonProperty("genderCodeId")
    genderCodeId?: number;
    @JsonProperty("sportCodeId")
    sportCodeId?: number;
    @JsonProperty("regionCodeId")
    regionCodeId?: number;
    @JsonProperty("classificationCodeId")
    classificationCodeId?: number;
    @JsonProperty("imageUrl")
    imageUrl?: string;
    @JsonProperty("phoneNumber")
    phoneNumber?: string;
    @JsonProperty("birthDate")
    birthDate?: string;
    password?: string;
}

export default SignupInfo;