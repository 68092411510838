import { useEffect, useState } from 'react';
import Reservation from '../../domain/reservation/Reservation';
import ReservationServiceImpl from '../../service/reservation/impl/ReservationServiceImpl';
const reservationService = new ReservationServiceImpl();

function useReservationUpdate(reservation?: Reservation) {
    const [updatedReservation, setUpdatedReservation] = useState<Reservation>();
    useEffect(() => {
        if(reservation) {
            if(reservation.reservationNo) {
                reservationService.updateReservation(reservation.reservationNo, reservation)
                .then((updatedReservation: Reservation) => {
                    setUpdatedReservation(updatedReservation);
                });
            }
        }
    }, [reservation]);

    return updatedReservation;
}

export default useReservationUpdate;