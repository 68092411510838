import React from 'react';
import AnswerItem from '../../../../domain/AnswerItem';
import QuestionnariesFormProps from './QuestionnariesFormsProps';
import useReportQuestionnarieAnswerMake from '../../../../../../hook/report/useReportQuestionnarieAnswerMake';
import APIServerConfig from '../../../../../../config/APIServerConfig';
import ReportTypeEnum from '../../../../../../domain/report/param/ReportTypeEnum';

export default function WHOQQOL(props: QuestionnariesFormProps) {
    const putAnswerItem = (qNum: number, answerNumber: number) => {
        if(props.answerItems) {
            const newAnswerItem = AnswerItem.getNewAnswerItem(qNum, answerNumber);
            // props.answerItems.set(qNum, newAnswerItem);
            if(props.setAnswer)
                props.setAnswer(qNum, newAnswerItem);
        }
    }

    const getCheckOnClassName = (qNum: number, answerNumber: number): string => {
        if(props.answerItems) {
            if(props.answerItems.get(qNum)?.itemNoOfAnswer == answerNumber) {
                return 'check-on'
            } else {
                return ''; 
            }
        } else {
            return '';
        }
    }

    const isCheck = (qNum: number, answerNumber: number): boolean => {
        if(props.answerItems) {
            if(props.answerItems.get(qNum)?.itemNoOfAnswer == answerNumber) {
                return true;
            } else {
                return false; 
            }
        } else {
            return false;
        }
    }

    const {reportData} = useReportQuestionnarieAnswerMake(props.answerItems);

    return (
        <>
            <div className="center-center">세계보건기구 삶의질 측정
                {
                    props.resultPrintable ?
                    <>
                        <form action={`${APIServerConfig.resourceAPIURL}/report/pdf`} method="POST">
                            <input type="hidden" name="reportType" value={ReportTypeEnum.WHO}></input>
                            <input type="hidden" name="reportData" value={JSON.stringify(reportData)}></input>
                            <input type="submit" value="설문 결과 PDF 내려받기"/>
                        </form>
                        <form action={`${APIServerConfig.resourceAPIURL}/report/xlsx`} method="POST">
                            <input type="hidden" name="reportType" value={ReportTypeEnum.WHO}></input>
                            <input type="hidden" name="reportData" value={JSON.stringify(reportData)}></input>
                            <input type="submit" value="설문 결과 엑셀 내려받기"/>
                        </form>
                    </>
                    :
                    <></>
                }
            </div>
            <h4>한국판 세계보건기구 삶의 질 측정도구 (Korea version WHOQOL-BREF)</h4>
            <div className="tab-story-number">
                <table className="center-check-table-basic" cellPadding="0" cellSpacing="0">
                    <thead>
                        <tr>
                            <th colSpan={6}>
                                각 질문을 읽은 후, 지난 2주 동안 당신의 느낌을 평가하고 각 문항마다 귀하에게 가장 적합한 1개의 번호를 골라 체크해
                                주십시오.
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th className="center-bgcolor-blue">문항</th>
                            <td>
                                매우
                                <br />
                                나쁨
                            </td>
                            <td>나쁨</td>
                            <td>보통</td>
                            <td>좋음</td>
                            <td>
                                매우
                                <br />
                                좋음
                            </td>
                        </tr>
                        <tr>
                            <th>1. 당신은 당신의 삶의 질을 어떻게 평가하십니까?</th>
                            <td className={getCheckOnClassName(1, 1)} onClick={() => {putAnswerItem(1, 1)}}>1</td>
                            <td className={getCheckOnClassName(1, 2)} onClick={() => {putAnswerItem(1, 2)}}>2</td>
                            <td className={getCheckOnClassName(1, 3)} onClick={() => {putAnswerItem(1, 3)}}>3</td>
                            <td className={getCheckOnClassName(1, 4)} onClick={() => {putAnswerItem(1, 4)}}>4</td>
                            <td className={getCheckOnClassName(1, 5)} onClick={() => {putAnswerItem(1, 5)}}>5</td>
                        </tr>
                        <tr>
                            <th>2. 당신은 자신의 건강상태를 어ᄄᅠᇂ게 평가하십니까?</th>
                            <td className={getCheckOnClassName(2, 1)} onClick={() => {putAnswerItem(2, 1)}}>1</td>
                            <td className={getCheckOnClassName(2, 2)} onClick={() => {putAnswerItem(2, 2)}}>2</td>
                            <td className={getCheckOnClassName(2, 3)} onClick={() => {putAnswerItem(2, 3)}}>3</td>
                            <td className={getCheckOnClassName(2, 4)} onClick={() => {putAnswerItem(2, 4)}}>4</td>
                            <td className={getCheckOnClassName(2, 5)} onClick={() => {putAnswerItem(2, 5)}}>5</td>
                        </tr>
                        <tr>
                            <th>3. 당신은 신체적 통증으로 인해 해야할 일을 방해 받는다고 느낍니까?</th>
                            <td className={getCheckOnClassName(3, 1)} onClick={() => {putAnswerItem(3, 1)}}>1</td>
                            <td className={getCheckOnClassName(3, 2)} onClick={() => {putAnswerItem(3, 2)}}>2</td>
                            <td className={getCheckOnClassName(3, 3)} onClick={() => {putAnswerItem(3, 3)}}>3</td>
                            <td className={getCheckOnClassName(3, 4)} onClick={() => {putAnswerItem(3, 4)}}>4</td>
                            <td className={getCheckOnClassName(3, 5)} onClick={() => {putAnswerItem(3, 5)}}>5</td>
                        </tr>
                        <tr>
                            <th>4. 당신은 일상생활을 잘하기 위해 치료가 필요합니까?</th>
                            <td className={getCheckOnClassName(4, 1)} onClick={() => {putAnswerItem(4, 1)}}>1</td>
                            <td className={getCheckOnClassName(4, 2)} onClick={() => {putAnswerItem(4, 2)}}>2</td>
                            <td className={getCheckOnClassName(4, 3)} onClick={() => {putAnswerItem(4, 3)}}>3</td>
                            <td className={getCheckOnClassName(4, 4)} onClick={() => {putAnswerItem(4, 4)}}>4</td>
                            <td className={getCheckOnClassName(4, 5)} onClick={() => {putAnswerItem(4, 5)}}>5</td>
                        </tr>
                        <tr>
                            <th>5. 당신은 인생을 얼마나 즐기십니까?</th>
                            <td className={getCheckOnClassName(5, 1)} onClick={() => {putAnswerItem(5, 1)}}>1</td>
                            <td className={getCheckOnClassName(5, 2)} onClick={() => {putAnswerItem(5, 2)}}>2</td>
                            <td className={getCheckOnClassName(5, 3)} onClick={() => {putAnswerItem(5, 3)}}>3</td>
                            <td className={getCheckOnClassName(5, 4)} onClick={() => {putAnswerItem(5, 4)}}>4</td>
                            <td className={getCheckOnClassName(5, 5)} onClick={() => {putAnswerItem(5, 5)}}>5</td>
                        </tr>
                        <tr>
                            <th>6. 당신은 당신의 삶이 의미 있다고 느끼십니까?</th>
                            <td className={getCheckOnClassName(6, 1)} onClick={() => {putAnswerItem(6, 1)}}>1</td>
                            <td className={getCheckOnClassName(6, 2)} onClick={() => {putAnswerItem(6, 2)}}>2</td>
                            <td className={getCheckOnClassName(6, 3)} onClick={() => {putAnswerItem(6, 3)}}>3</td>
                            <td className={getCheckOnClassName(6, 4)} onClick={() => {putAnswerItem(6, 4)}}>4</td>
                            <td className={getCheckOnClassName(6, 5)} onClick={() => {putAnswerItem(6, 5)}}>5</td>
                        </tr>
                        <tr>
                            <th>7. 당신은 얼마나 정신을 잘 집중할 수 있습니까?</th>
                            <td className={getCheckOnClassName(7, 1)} onClick={() => {putAnswerItem(7, 1)}}>1</td>
                            <td className={getCheckOnClassName(7, 2)} onClick={() => {putAnswerItem(7, 2)}}>2</td>
                            <td className={getCheckOnClassName(7, 3)} onClick={() => {putAnswerItem(7, 3)}}>3</td>
                            <td className={getCheckOnClassName(7, 4)} onClick={() => {putAnswerItem(7, 4)}}>4</td>
                            <td className={getCheckOnClassName(7, 5)} onClick={() => {putAnswerItem(7, 5)}}>5</td>
                        </tr>
                        <tr>
                            <th>8. 당신은 일상생활에서 안전하다고 느낍니까?</th>
                            <td className={getCheckOnClassName(8, 1)} onClick={() => {putAnswerItem(8, 1)}}>1</td>
                            <td className={getCheckOnClassName(8, 2)} onClick={() => {putAnswerItem(8, 2)}}>2</td>
                            <td className={getCheckOnClassName(8, 3)} onClick={() => {putAnswerItem(8, 3)}}>3</td>
                            <td className={getCheckOnClassName(8, 4)} onClick={() => {putAnswerItem(8, 4)}}>4</td>
                            <td className={getCheckOnClassName(8, 5)} onClick={() => {putAnswerItem(8, 5)}}>5</td>
                        </tr>
                        <tr>
                            <th>9. 당신은 건강에 좋은 주거환경에 살고 있습니까?</th>
                            <td className={getCheckOnClassName(9, 1)} onClick={() => {putAnswerItem(9, 1)}}>1</td>
                            <td className={getCheckOnClassName(9, 2)} onClick={() => {putAnswerItem(9, 2)}}>2</td>
                            <td className={getCheckOnClassName(9, 3)} onClick={() => {putAnswerItem(9, 3)}}>3</td>
                            <td className={getCheckOnClassName(9, 4)} onClick={() => {putAnswerItem(9, 4)}}>4</td>
                            <td className={getCheckOnClassName(9, 5)} onClick={() => {putAnswerItem(9, 5)}}>5</td>
                        </tr>
                        <tr>
                            <th>10 당신은 일상생활을 하기에 충분한 기력(에너지)이 있습니까?</th>
                            <td className={getCheckOnClassName(10, 1)} onClick={() => {putAnswerItem(10, 1)}}>1</td>
                            <td className={getCheckOnClassName(10, 2)} onClick={() => {putAnswerItem(10, 2)}}>2</td>
                            <td className={getCheckOnClassName(10, 3)} onClick={() => {putAnswerItem(10, 3)}}>3</td>
                            <td className={getCheckOnClassName(10, 4)} onClick={() => {putAnswerItem(10, 4)}}>4</td>
                            <td className={getCheckOnClassName(10, 5)} onClick={() => {putAnswerItem(10, 5)}}>5</td>
                        </tr>
                        <tr>
                            <th>11 당신은 자신의 신체적 외모에 만족하십니까?</th>
                            <td className={getCheckOnClassName(11, 1)} onClick={() => {putAnswerItem(11, 1)}}>1</td>
                            <td className={getCheckOnClassName(11, 2)} onClick={() => {putAnswerItem(11, 2)}}>2</td>
                            <td className={getCheckOnClassName(11, 3)} onClick={() => {putAnswerItem(11, 3)}}>3</td>
                            <td className={getCheckOnClassName(11, 4)} onClick={() => {putAnswerItem(11, 4)}}>4</td>
                            <td className={getCheckOnClassName(11, 5)} onClick={() => {putAnswerItem(11, 5)}}>5</td>
                        </tr>
                        <tr>
                            <th>12 당신은 필요한 만큼 충분한 돈을 가지고 있습니까?</th>
                            <td className={getCheckOnClassName(12, 1)} onClick={() => {putAnswerItem(12, 1)}}>1</td>
                            <td className={getCheckOnClassName(12, 2)} onClick={() => {putAnswerItem(12, 2)}}>2</td>
                            <td className={getCheckOnClassName(12, 3)} onClick={() => {putAnswerItem(12, 3)}}>3</td>
                            <td className={getCheckOnClassName(12, 4)} onClick={() => {putAnswerItem(12, 4)}}>4</td>
                            <td className={getCheckOnClassName(12, 5)} onClick={() => {putAnswerItem(12, 5)}}>5</td>
                        </tr>
                        <tr>
                            <th>13 당신은 매일의 생활에 필요한 정보를 쉽게 얻을 수 있습니까?</th>
                            <td className={getCheckOnClassName(13, 1)} onClick={() => {putAnswerItem(13, 1)}}>1</td>
                            <td className={getCheckOnClassName(13, 2)} onClick={() => {putAnswerItem(13, 2)}}>2</td>
                            <td className={getCheckOnClassName(13, 3)} onClick={() => {putAnswerItem(13, 3)}}>3</td>
                            <td className={getCheckOnClassName(13, 4)} onClick={() => {putAnswerItem(13, 4)}}>4</td>
                            <td className={getCheckOnClassName(13, 5)} onClick={() => {putAnswerItem(13, 5)}}>5</td>
                        </tr>
                        <tr>
                            <th>14 당신은 여가를 즐길 기회를 가지고 있습니까?</th>
                            <td className={getCheckOnClassName(14, 1)} onClick={() => {putAnswerItem(14, 1)}}>1</td>
                            <td className={getCheckOnClassName(14, 2)} onClick={() => {putAnswerItem(14, 2)}}>2</td>
                            <td className={getCheckOnClassName(14, 3)} onClick={() => {putAnswerItem(14, 3)}}>3</td>
                            <td className={getCheckOnClassName(14, 4)} onClick={() => {putAnswerItem(14, 4)}}>4</td>
                            <td className={getCheckOnClassName(14, 5)} onClick={() => {putAnswerItem(14, 5)}}>5</td>
                        </tr>
                        <tr>
                            <th>15 당신은 자신의 수면에 대해 만족하고 있습니까?</th>
                            <td className={getCheckOnClassName(15, 1)} onClick={() => {putAnswerItem(15, 1)}}>1</td>
                            <td className={getCheckOnClassName(15, 2)} onClick={() => {putAnswerItem(15, 2)}}>2</td>
                            <td className={getCheckOnClassName(15, 3)} onClick={() => {putAnswerItem(15, 3)}}>3</td>
                            <td className={getCheckOnClassName(15, 4)} onClick={() => {putAnswerItem(15, 4)}}>4</td>
                            <td className={getCheckOnClassName(15, 5)} onClick={() => {putAnswerItem(15, 5)}}>5</td>
                        </tr>
                        <tr>
                            <th>16 당신은 자신의 일상생활수행 능력에 대해 만족하십니까?</th>
                            <td className={getCheckOnClassName(16, 1)} onClick={() => {putAnswerItem(16, 1)}}>1</td>
                            <td className={getCheckOnClassName(16, 2)} onClick={() => {putAnswerItem(16, 2)}}>2</td>
                            <td className={getCheckOnClassName(16, 3)} onClick={() => {putAnswerItem(16, 3)}}>3</td>
                            <td className={getCheckOnClassName(16, 4)} onClick={() => {putAnswerItem(16, 4)}}>4</td>
                            <td className={getCheckOnClassName(16, 5)} onClick={() => {putAnswerItem(16, 5)}}>5</td>
                        </tr>
                        <tr>
                            <th>17 당신은 자신의 일하는 능력에 대해 만족하십니까?</th>
                            <td className={getCheckOnClassName(17, 1)} onClick={() => {putAnswerItem(17, 1)}}>1</td>
                            <td className={getCheckOnClassName(17, 2)} onClick={() => {putAnswerItem(17, 2)}}>2</td>
                            <td className={getCheckOnClassName(17, 3)} onClick={() => {putAnswerItem(17, 3)}}>3</td>
                            <td className={getCheckOnClassName(17, 4)} onClick={() => {putAnswerItem(17, 4)}}>4</td>
                            <td className={getCheckOnClassName(17, 5)} onClick={() => {putAnswerItem(17, 5)}}>5</td>
                        </tr>
                        <tr>
                            <th>18 당신은 자신에 대해 얼마나 만족합니까?</th>
                            <td className={getCheckOnClassName(18, 1)} onClick={() => {putAnswerItem(18, 1)}}>1</td>
                            <td className={getCheckOnClassName(18, 2)} onClick={() => {putAnswerItem(18, 2)}}>2</td>
                            <td className={getCheckOnClassName(18, 3)} onClick={() => {putAnswerItem(18, 3)}}>3</td>
                            <td className={getCheckOnClassName(18, 4)} onClick={() => {putAnswerItem(18, 4)}}>4</td>
                            <td className={getCheckOnClassName(18, 5)} onClick={() => {putAnswerItem(18, 5)}}>5</td>
                        </tr>
                        <tr>
                            <th>19 당신은 자신의 대인관계에 대해 만족하십니까?</th>
                            <td className={getCheckOnClassName(19, 1)} onClick={() => {putAnswerItem(19, 1)}}>1</td>
                            <td className={getCheckOnClassName(19, 2)} onClick={() => {putAnswerItem(19, 2)}}>2</td>
                            <td className={getCheckOnClassName(19, 3)} onClick={() => {putAnswerItem(19, 3)}}>3</td>
                            <td className={getCheckOnClassName(19, 4)} onClick={() => {putAnswerItem(19, 4)}}>4</td>
                            <td className={getCheckOnClassName(19, 5)} onClick={() => {putAnswerItem(19, 5)}}>5</td>
                        </tr>
                        <tr>
                            <th>20 당신은 자신의 성생활에 대해 만족하십니까?</th>
                            <td className={getCheckOnClassName(20, 1)} onClick={() => {putAnswerItem(20, 1)}}>1</td>
                            <td className={getCheckOnClassName(20, 2)} onClick={() => {putAnswerItem(20, 2)}}>2</td>
                            <td className={getCheckOnClassName(20, 3)} onClick={() => {putAnswerItem(20, 3)}}>3</td>
                            <td className={getCheckOnClassName(20, 4)} onClick={() => {putAnswerItem(20, 4)}}>4</td>
                            <td className={getCheckOnClassName(20, 5)} onClick={() => {putAnswerItem(20, 5)}}>5</td>
                        </tr>
                        <tr>
                            <th>21 당신은 친구의 도움에 만족하십니까?</th>
                            <td className={getCheckOnClassName(21, 1)} onClick={() => {putAnswerItem(21, 1)}}>1</td>
                            <td className={getCheckOnClassName(21, 2)} onClick={() => {putAnswerItem(21, 2)}}>2</td>
                            <td className={getCheckOnClassName(21, 3)} onClick={() => {putAnswerItem(21, 3)}}>3</td>
                            <td className={getCheckOnClassName(21, 4)} onClick={() => {putAnswerItem(21, 4)}}>4</td>
                            <td className={getCheckOnClassName(21, 5)} onClick={() => {putAnswerItem(21, 5)}}>5</td>
                        </tr>
                        <tr>
                            <th>22 당신이 살고 있는 집과 주변환경에 대해 만족하십니까?</th>
                            <td className={getCheckOnClassName(22, 1)} onClick={() => {putAnswerItem(22, 1)}}>1</td>
                            <td className={getCheckOnClassName(22, 2)} onClick={() => {putAnswerItem(22, 2)}}>2</td>
                            <td className={getCheckOnClassName(22, 3)} onClick={() => {putAnswerItem(22, 3)}}>3</td>
                            <td className={getCheckOnClassName(22, 4)} onClick={() => {putAnswerItem(22, 4)}}>4</td>
                            <td className={getCheckOnClassName(22, 5)} onClick={() => {putAnswerItem(22, 5)}}>5</td>
                        </tr>
                        <tr>
                            <th>23 당신은 의료서비스를 쉽게 받을 수 있다는 점(접근도)에 만족하십니까?</th>
                            <td className={getCheckOnClassName(23, 1)} onClick={() => {putAnswerItem(23, 1)}}>1</td>
                            <td className={getCheckOnClassName(23, 2)} onClick={() => {putAnswerItem(23, 2)}}>2</td>
                            <td className={getCheckOnClassName(23, 3)} onClick={() => {putAnswerItem(23, 3)}}>3</td>
                            <td className={getCheckOnClassName(23, 4)} onClick={() => {putAnswerItem(23, 4)}}>4</td>
                            <td className={getCheckOnClassName(23, 5)} onClick={() => {putAnswerItem(23, 5)}}>5</td>
                        </tr>
                        <tr>
                            <th>24 당신은 당신이 이용하는 교통수단에 만족하십니까?</th>
                            <td className={getCheckOnClassName(24, 1)} onClick={() => {putAnswerItem(24, 1)}}>1</td>
                            <td className={getCheckOnClassName(24, 2)} onClick={() => {putAnswerItem(24, 2)}}>2</td>
                            <td className={getCheckOnClassName(24, 3)} onClick={() => {putAnswerItem(24, 3)}}>3</td>
                            <td className={getCheckOnClassName(24, 4)} onClick={() => {putAnswerItem(24, 4)}}>4</td>
                            <td className={getCheckOnClassName(24, 5)} onClick={() => {putAnswerItem(24, 5)}}>5</td>
                        </tr>
                        <tr>
                            <th>25 당신은 잘 돌아다닐 수 있습니까?</th>
                            <td className={getCheckOnClassName(25, 1)} onClick={() => {putAnswerItem(25, 1)}}>1</td>
                            <td className={getCheckOnClassName(25, 2)} onClick={() => {putAnswerItem(25, 2)}}>2</td>
                            <td className={getCheckOnClassName(25, 3)} onClick={() => {putAnswerItem(25, 3)}}>3</td>
                            <td className={getCheckOnClassName(25, 4)} onClick={() => {putAnswerItem(25, 4)}}>4</td>
                            <td className={getCheckOnClassName(25, 5)} onClick={() => {putAnswerItem(25, 5)}}>5</td>
                        </tr>
                        <tr className="center-bottom-black">
                            <th>26 당신은 우울감, 절망감, 불안감, 의기소침 등과 같은 부정적인 감정을 자주 느낍니까?</th>
                            <td className={getCheckOnClassName(26, 1)} onClick={() => {putAnswerItem(26, 1)}}>1</td>
                            <td className={getCheckOnClassName(26, 2)} onClick={() => {putAnswerItem(26, 2)}}>2</td>
                            <td className={getCheckOnClassName(26, 3)} onClick={() => {putAnswerItem(26, 3)}}>3</td>
                            <td className={getCheckOnClassName(26, 4)} onClick={() => {putAnswerItem(26, 4)}}>4</td>
                            <td className={getCheckOnClassName(26, 5)} onClick={() => {putAnswerItem(26, 5)}}>5</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    );
}
