import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import AdminMainTabTitle from '../../../../component/admin/AdminMainTabTitle';
import AdminRegistedUserTable from '../../../../component/admin/AdminRegistedUserTable';
import TmpRegistedTeamUser from '../../../../domain/team/TmpRegistedTeamUser';
import TmpRegistedTeamUserServiceImpl from '../../../../service/team/impl/TmpRegistedTeamUserServiceImpl';
import AdminTeamNav from '../../nav/AdminTeamNav';
import AdminTmpTeamUserInfoFilter from '../../component/filter/AdminTmpTeamUserInfoFilter';
import TempRegistedTeamUserSearchCondition from '../../../../domain/team/param/TempRegistedTeamUserSearchCondition';
import useTmpRegistedTeamUserRetrieve from '../../../../hook/team/useTmpRegistedTeamUserRetrieve';

interface MatchParams {
    teamNo: string;
    url: string;
}

const tmpTeamService = new TmpRegistedTeamUserServiceImpl();
function AdminTeamBelongContainer({match}: RouteComponentProps<MatchParams>){
    const [searchCondition, setSearchCondition] = useState(new TempRegistedTeamUserSearchCondition());
    const {teamNo} = match.params;
    const {url} = match;
    
    const {users} = useTmpRegistedTeamUserRetrieve(teamNo, searchCondition);

    return (
        <>
        <AdminMainTabTitle
        title="선수 목록"
        tab={<AdminTeamNav teamNo={teamNo} url={url}></AdminTeamNav>}
        >
            <AdminTmpTeamUserInfoFilter
                setTempRegistedTeamUserSearchCondition={setSearchCondition}
            ></AdminTmpTeamUserInfoFilter>
        </AdminMainTabTitle>
        {
            <AdminRegistedUserTable users={users}></AdminRegistedUserTable>
        }
        </>
    )
}

export default AdminTeamBelongContainer;