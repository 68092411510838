import React from 'react';
import { Link } from 'react-router-dom';
import AdminInfraNavTabEnum from './enum/AdminInfraNavTabEnum';

interface AdminInfraNavProps {
    infraNo: string;
    tab: AdminInfraNavTabEnum;
}

function AdminInfraNav(props: AdminInfraNavProps) {
    const tabActiveSwitch = (check: boolean): string => {
        if (check) return 'bg-gray white';
        else return '';
    };
    //FIXME: 예약 가능 일정 기능 추가 해야댐.
    return (
        <div className="admin-tab" style={{ paddingBottom: '0px' }}>
            <Link to={`/admin/infra/${props.infraNo}`}>
                <button className={tabActiveSwitch(props.tab == AdminInfraNavTabEnum.DETAIL)}>시설 정보</button>
            </Link>
            <Link to={`/admin/infra/${props.infraNo}/schedule`}>
                <button className={tabActiveSwitch(props.tab == AdminInfraNavTabEnum.SCHEDULE)}>방문 일정</button>
            </Link>
            {/* <Link to={`/admin/infra/${props.infraNo}/possible-schedule`}>
                <button className={tabActiveSwitch(props.tab == AdminInfraNavTabEnum.POSSIBLE_SCHEDULE)}>
                    예약가능일정
                </button>
            </Link> */}
        </div>
    );
}

export default AdminInfraNav;
