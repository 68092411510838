import React from 'react';
import LoginState from '../../../component/header/LoginState';
import SiteMainNav from '../../../component/nav/SiteMainNav';
import { Link } from 'react-router-dom';

function TouringSubHeader() {
    return (
        <section className="main-visual touring-sub-visual-bg2">
            <div className="top">
                <div className="logozone">
                    <Link to="/">
                        <div>
                            <img src="/assets/image/logo_without_name.png" />
                        </div>
                    </Link>
                    <Link to="#">
                        <div>&nbsp;|&nbsp;투어링</div>
                    </Link>
                </div>
                <div className="navi">
                    <SiteMainNav></SiteMainNav>
                </div>
                <div className="main-login">
                    <LoginState></LoginState>
                </div>
                <div className="clear"></div>
                <div className="mo-menu">
                    <div className="mobile-container">
                        <div className="topnav">
                            {/* <a href="#home" className="active"></a> */}
                            <div id="myLinks">
                                <SiteMainNav></SiteMainNav>
                            </div>
                            <a className="icon">
                                <i className="fa fa-bars"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div className="sub-copy">
                <p className="wow  animate__ animate__fadeInDown animated" data-wow-delay="0.4s">
                    스포츠시설
                </p>
                <p className="wow  animate__ animate__fadeInDown animated" data-wow-delay="0.6s">
                    쉽게 찾고 편하게 예약
                </p>
            </div>
        </section>
    );
}

export default TouringSubHeader;
