import React from 'react';
import Infra from '../../domain/infra/Infra';
import { useHistory } from 'react-router-dom';
import InfraSearchCondition from '../../domain/infra/param/InfraSearchCondition';

interface AdminFacilityTableTrProps {
    page: number;
    seq: number;
    infra: Infra;
    searchCondition?: InfraSearchCondition;
    pageCnt: number;
}
function AdminFacilityTableTr(props: AdminFacilityTableTrProps) {
    let history = useHistory();

    //const [infra, setInfra] = useState<Infra>();
    const recommandationHandler = (infra: Infra, recommandation: boolean) => {
        infra.recommandation = recommandation;
        //setInfra(Object.setPrototypeOf(Object.assign({}, infra), Infra.prototype));
    };

    function goDetail() {
        let searchCondition: InfraSearchCondition | undefined = props.searchCondition;

        if (searchCondition) {
            searchCondition.page = props.page;
            let groupIdx = Math.floor(props.page / props.pageCnt);
            searchCondition.groupIdx = groupIdx;
            searchCondition.flag = 'detail';
        }

        history.push({
            pathname: '/admin/facility/' + props.infra.infraNo,
            state: searchCondition,
        });
    }

    return (
        <tr>
            <td>{props.seq}</td>
            <td onClick={goDetail} style={{ textAlign: 'center', cursor: 'pointer' }}>
                {props.infra.name}
            </td>
            <td>{props.infra.infraCategory?.name}</td>
            <td style={{ textAlign: 'center' }}>{props.infra.address}</td>
            <td>{props.infra.recommandation ? <span style={{ color: 'green' }}>추천</span> : <span style={{ color: 'red' }}>비추천</span>}</td>
            <td>
                <button
                    className="bg-blue white"
                    onClick={() => {
                        recommandationHandler(props.infra, true);
                    }}
                >
                    <i className="fas fa-check-circle"></i> 추천
                </button>
                <button
                    className="bg-gray white"
                    onClick={() => {
                        recommandationHandler(props.infra, false);
                    }}
                >
                    <i className="fas fa-ban"></i> 비추천
                </button>
                {/* <Link to={`/admin/facility/${props.infra.infraNo}`}> */}
                {/* <button className="bg-white" onClick={goDetail}>
                    <i className="fas fa-search">보기</i>
                </button> */}
                {/* </Link> */}
            </td>
        </tr>
    );
}

export default AdminFacilityTableTr;
