import { useEffect, useState } from "react";
import Schedule from "../../domain/schedule/Schedule";
import TeamScheduleServiceImpl from '../../service/schedule/impl/TeamScheduleServiceImpl';

const scheduleService = new TeamScheduleServiceImpl();
function useTeamSchedules(teamNo: string) {
    const [teamSchedules, setTeamSchedules] = useState<Schedule[]>();

    useEffect(() => {
        scheduleService.getSchedules(teamNo)
            .then((schedules: Schedule[]) => {
                setTeamSchedules(schedules);
            });  
    }, [teamNo]);

    return {teamSchedules, setTeamSchedules};
}

export default useTeamSchedules;