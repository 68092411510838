import React from 'react';
import { JsonObject, JsonProperty } from 'json2typescript';
import Exercise from './Exercise';
import { BasalPrescription } from './prescription/BasalPrescription';
import ConditioningPrescription from './prescription/ConditioningPrescription';
import ItemsPrescription from './prescription/ItemsPrescription';
import Opinions from './prescription/Opinions';

@JsonObject('prescription')
export default class Prescription {
    @JsonProperty('historyNo')
    historyNo: number = 0;
    @JsonProperty('targetNo')
    targetNo: number = 0;
    @JsonProperty('basalExercisePrescription')
    basalExercisePrescription: BasalPrescription = new BasalPrescription();
    @JsonProperty('conditioningPrescription')
    conditioningPrescription: ConditioningPrescription = new ConditioningPrescription();
    @JsonProperty('itemPrescription')
    itemPrescription: ItemsPrescription = new ItemsPrescription();
    @JsonProperty('bodyPrescription')
    bodyPrescription: ItemsPrescription = new ItemsPrescription();
    @JsonProperty('opinions')
    opinions: Opinions = new Opinions();
    regDate: string = '';
    modDate: string = '';
    action: string = '';
}
