import APIServerConfig from '../../config/APIServerConfig';
import Schedule from '../../domain/schedule/Schedule';
import AbstractService from '../AbstractService';
import ScheduleService from './ScheduleService';
abstract class AbstractScheduleService extends AbstractService implements ScheduleService {
    getSchedule(scheduleTargetNo: string, scheduleNo: string): Promise<Schedule> {
        return new Promise((resolve, reject) =>
        {
            APIServerConfig.recordAPIAxios.get(this.getScheduleUrl(scheduleTargetNo, scheduleNo))
            .then((response) => {
                let schedule = this.jsonConvert.deserializeObject(response.data, Schedule);
                resolve(schedule);
            })
            .catch(err => {
                console.error(err);
                reject(err);
            })
        });
    }

    getSchedules(scheduleTargetNo: string): Promise<Schedule[]> {
        return new Promise((resolve, reject) => {
            APIServerConfig.recordAPIAxios.get(this.getSchedulesUrl(scheduleTargetNo))
            .then((response) => {
                let schedules = this.jsonConvert.deserializeArray(response.data, Schedule);
                resolve(schedules);
            })
            .catch(err => {
                console.error(err);
                reject(err);
            })
        });
    }

    getSchedulesByMonth(scheduleTargetNo: string, month: string): Promise<Schedule[]> {
        return new Promise((resolve, reject) => {
            APIServerConfig.recordAPIAxios.get(this.getSchedulesUrl(scheduleTargetNo), {
                params: {month: month}
            })
            .then((response) => {
                let schedules = this.jsonConvert.deserializeArray(response.data, Schedule);  
                resolve(schedules);
            })
            .catch(err => {
                console.error(err);
                reject(err);
            })
        });
    }

    registeSchedule(schedule: Schedule): Promise<Schedule> {
        return new Promise((resolve, reject) => {
            APIServerConfig.recordAPIAxios.post(this.registeScheduleUrl(), JSON.stringify(schedule))
            .then(response => {
                let registedSchedule = this.jsonConvert.deserializeObject(response.data, Schedule);
                alert("정상 등록")
                resolve(registedSchedule);
            })
            .catch(err => {
                alert("등록 실패");
                reject(err);
            })
        })
    }
    updateSchedule(schedule: Schedule): Promise<Schedule> {
        return new Promise((resolve, reject) => {
            APIServerConfig.recordAPIAxios.put(this.updateScheduleUrl(), JSON.stringify(schedule))
            .then(response => {
                let updatedSchedule = this.jsonConvert.deserializeObject(response.data, Schedule);
                alert("정상 등록");
                resolve(updatedSchedule);
            })
            .catch(err => {
                alert("등록 실패");
                reject(err);
            })
        });
    }

    deleteSchedule(scheduleNo: string): Promise<void> {
        return new Promise((resolve, reject) => {
            APIServerConfig.recordAPIAxios.delete(`/schedule/team/${scheduleNo}`)
            .then(response => {
                resolve();
                alert("정상 삭제");
            })
            .catch(err => {
                alert("삭제 실패");
                reject(err);
            })
        })
    }
    abstract getScheduleUrl(scheduleTargetNo: string, scheduleNo: string): string;
    abstract getSchedulesUrl(scheduleTargetNo: string): string;
    abstract registeScheduleUrl(): string;
    abstract updateScheduleUrl(): string;
}

export default AbstractScheduleService;