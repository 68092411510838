import React, { useEffect, useState } from 'react';
import MyPageMainLayout from '../../../layout/MyPageMainLayout';
import MyPageRightContent from '../../../layout/MyPageRightContent';
import MyPageScheduleAllViewCalendarTypeTab from './tab/MyPageScheduleAllViewCalendarTypeTab';
import MyPageScheduleAllViewListTypeTab from './tab/MyPageScheduleAllViewListTypeTab';
import useReservationOfTeam from '../../../../../hook/useReservationOfTeam';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../moldules/index';
import Reservation from '../../../../../domain/reservation/Reservation';
import Schedule from '../../../../../domain/schedule/Schedule';
import DateChoice from '../../../components/DateChoice';
import useTeamScheduleRegiste from '../../../../../hook/schedule/useTeamScheduleRegiste';
import ReservationInfraSelect from '../../../components/ReservationInfrasSelect';
import Paging from '../../../../../domain/common/Paging';
import ReservationSearchCondition from '../../../../../domain/reservation/param/ReservationSearchCondition';

const tabPage: any = {
    'list': <MyPageScheduleAllViewListTypeTab/>,
    'calendar': <MyPageScheduleAllViewCalendarTypeTab/>
}

function MyPageScheduleAllViewPage() {
    const {user, myTeam} = useSelector((state: RootState) => state.userSessionReducer);
    const [tabSeq, setTabSeq] = useState('list');
    const paging = new Paging(0, 30);
    const searchCondition = new ReservationSearchCondition();
    searchCondition.teamNos = myTeam.teamNo;
    const {reservations} = useReservationOfTeam(myTeam.teamNo, searchCondition, paging);
    const [scheduleStartDay, setScheduleStartDay] = useState<string>('선택하세요.');
    const [scheduleEndDay, setScheduleEndDay] = useState<string>('선택하세요.');
    const [selectedReservation, setSelectedReservation] = useState<Reservation>();
    const [scheduleTitle, setScheduleTitle] = useState<string>();
    const [newSchedule, setNewSchedule] = useState<Schedule>();
    const [registeDate] = useTeamScheduleRegiste(newSchedule);

    useEffect(() => {
        if(selectedReservation) {
            let reservationStartDate = selectedReservation.startDate;
            let reservationEndDate = selectedReservation.endDate;
            if(reservationStartDate && reservationEndDate) {
                setScheduleStartDay(reservationStartDate.split(" ")[0]);
                setScheduleEndDay(reservationEndDate.split(" ")[0]);
            }
        } else {
            setScheduleStartDay('선택하세요.');
            setScheduleEndDay('선택하세요.');
        }
    }, [selectedReservation]);

    useEffect(() => {
        
    }, [registeDate]);
    
    return (
        <MyPageMainLayout>
            {
                user.checkTeamAdmin() ?
                <MyPageRightContent contentTitle="팀 일정 추가">
                    <nav>
                        <ReservationInfraSelect 
                            reservations={reservations}
                            setSelectedReservation={setSelectedReservation}
                        ></ReservationInfraSelect>
                        <DateChoice
                            scheduleStartDay={scheduleStartDay}
                            setScheduleStartDay={setScheduleStartDay}
                            scheduleEndDay={scheduleEndDay}
                            setScheduleEndDay={setScheduleEndDay}
                        ></DateChoice>
                        {
                            selectedReservation ?
                            <div className="menu-item">
                                <div className="notice-open">
                                    <h5>예약 대상자</h5>
                                    <input readOnly={true} className="basic-gray" type="text" value={selectedReservation.reservater?.name}></input>
                                </div>
                            </div>
                            :
                            <></>
                        }
                        <div className="menu-item">
                            <div className="notice-open">
                                <h5>일정 내용 등록</h5>
                                <input onChange={(event) => setScheduleTitle(event.currentTarget.value)} className="basic-gray" type="text"  placeholder="일정 내용 등록" value={scheduleTitle}/>
                            </div>
                        </div>
                        <a className="bt-blue-2"
                            onClick={() => {
                                const newSchedule = Schedule.getNewSchedule(myTeam.teamNo, selectedReservation, scheduleStartDay+ " 00:00", scheduleEndDay + " 00:00", scheduleTitle);
                                setNewSchedule(newSchedule);
                            }}
                        >일정 등록</a>
                    </nav>
                </MyPageRightContent>
                :
                <></>
            }
            <MyPageRightContent contentTitle="팀 일정">
                <div className="my-in">
                    <div className="container3">
                        <ul className="tabs two">
                            <li onClick={() => setTabSeq('list')} className={tabSeq == 'list'? 'active': ''}><a>목록형</a></li>
                            <li onClick={() => setTabSeq('calendar')} className={tabSeq == 'calendar'? 'active': ''}><a>달력형</a></li>
                        </ul>
                        <div className="tab_container">
                            {tabPage[tabSeq]}
                        </div>
                    </div>
                </div>
            </MyPageRightContent>
        </MyPageMainLayout>
    )
}

export default MyPageScheduleAllViewPage;