import React, { useEffect, useState } from 'react';
import Layout from '../layouts/Layout';
import Header from '../layouts/Header';
import SideBar from '../layouts/SideBar';
import '../assets/css/center.css';
import '../assets/css/animate.min.css';
let centerKey: string = '';
const menuTitle: string = '* 센터 관리시스템 설정';
const title: string = '설정';

function RehabConfigurationComponent(props: any) {
    let path = props.location.pathname;
    centerKey = props.match.params.centerId;

    const filters = () => {
        return <></>;
    };

    const contents = () => {
        let configuration = (
            <>
                <div className="center-loginset flex">
                    <div className="center-loginset-text">
                        <h2 className="center2 flex">
                            <i className="fas fa-unlock" id="unlock"></i> <i className="fas fa-lock" id="lock"></i> 자동로그인 설정
                        </h2>
                    </div>
                    <div className="center-loginset-bt-bg" id="bt-sethome" /* onclick="btSet()" */>
                        <button className="center-loginset-bt"></button>
                    </div>
                </div>
                {/* <!--//center-loginset--> */}
                <div className="center-passwordset">
                    <div className="center-loginset-text">
                        <h2 className="center2">
                            <i className="fas fa-key"></i> 패스워드 변경
                        </h2>
                    </div>
                    <div className="flex-basic">
                        <span>· 기존 패스워드</span>{' '}
                        <div>
                            <input type="password" className="center-input" /> <button className="center-button">확인</button>
                        </div>
                    </div>
                    <div className="flex-basic">
                        <span>· 변경 패스워드</span>{' '}
                        <div>
                            <input type="password" className="center-input" /> <button className="center-button">변경</button>
                        </div>
                    </div>
                </div>
                {/* <!--//center-loginset--> */}
            </>
        );

        return configuration;
    };

    let configurationLayout = (
        <Layout
            header={<Header></Header>}
            sideBar={<SideBar name={path}></SideBar>}
            path={path}
            naviTitle={title}
            menuTitle={menuTitle}
            filter={filters()}
        >
            {contents()}
        </Layout>
    );

    return configurationLayout;
}

export default RehabConfigurationComponent;
