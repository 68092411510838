import React, { useState } from 'react';
import { useEffect } from 'react';
import Infra from '../../domain/infra/Infra';
import ReservationPopupContainer from '../../container/reservation/ReservationPopupContainer';
import { Link } from 'react-router-dom';
import ThirdPartyMapRenderable from './thirdParty/ThirdPartyMapRenderable';
import KaKaoMapRenderer from './thirdParty/KaKaoMapRenderer';
import useGeographic from '../../hook/useGeographic';
import UserLocation from '../../domain/user/UserLocation';

const { kakao } = window;

interface InfraLocationMapProps {
    infras: Infra[];
    mapLevel: number;
    userLocation?: UserLocation;
}

function InfraLocationMap(props: InfraLocationMapProps) {
    const mapId = 'location_map';
    let [reservationInfra, setReservationInfra] = useState(new Infra());
    let [openable, setOpenable] = useState(false);

    useEffect(() => {
        const kakaoMapRenderer: ThirdPartyMapRenderable = new KaKaoMapRenderer(mapId, props.mapLevel, props.infras, setReservationInfra, setOpenable);
        kakaoMapRenderer.render(props.userLocation);
    }, [props.infras]);

    return (
        <>
            <ReservationPopupContainer
                mainInfra={reservationInfra}
                childInfras={reservationInfra.childInfras}
                openable={openable}
                changeReservationPopupOpenable={() => setOpenable(!openable)}
            ></ReservationPopupContainer>
            <div style={{ margin: 'auto 0' }}>
                <div
                    id={mapId}
                    style={{
                        width: '100%',
                        height: '100vh',
                    }}
                ></div>
            </div>
        </>
    );
}

export default InfraLocationMap;
