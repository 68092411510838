import { Console } from 'console';
import { useEffect, useState } from 'react';
import TeamScheduleServiceImpl from '../../service/schedule/impl/TeamScheduleServiceImpl';

const teamScheduleServie = new TeamScheduleServiceImpl();
function useTeamScheduleDelete(scheduleNo: string|undefined) {
    const [deleteSuccess, setDeleteSuccess] = useState<Date>();
    useEffect(() => {
        if(scheduleNo) {
            teamScheduleServie.deleteSchedule(scheduleNo)
            .then(response => {
                setDeleteSuccess(new Date());
            });
        }
    }, [scheduleNo]);

    return deleteSuccess;
}

export default useTeamScheduleDelete;