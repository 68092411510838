import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { find } from 'lodash';
import CalendarComponent from '../../../component/calendar/CalendarComponent';
import { RootState } from '../../../moldules';
import UserComponent from './UsersComponent';
import RehabilitationListComponent from './RehabilitationListComponent';
import Infra from '../../../domain/infra/Infra';
import RehabilitationUnitTimeComponent from './RehabilitationUnitTimeComponent';
import User from '../../../domain/user/User';
import useReservationOfInfra from '../../../hook/reservation/useReservationsOfInfra';
import ReservationSearchCondition from '../../../domain/reservation/param/ReservationSearchCondition';
import Reservation from '../../../domain/reservation/Reservation';
import useReservationsRegiste from '../../../hook/reservation/useReservationsRegiste';
import DateUtils from '../../../utils/DateUtils';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ko } from 'date-fns/esm/locale';
import ReservationServiceImpl from '../../../service/reservation/impl/ReservationServiceImpl';
import { confirmAlert } from 'react-confirm-alert';
import ReservationTime from '../../../domain/reservation/ReservationTime';
import InfraServiceImpl from '../../../service/infra/impl/InfraServiceImpl';
import ReservationService from '../../../service/reservation/ReservationService';
import TargetPerson from '../domain/TargetPerson';

interface ReservationPopupContainer {
    reservation?: Reservation;
    targetPerson?: TargetPerson;
    actionType: string;
    closingHandler: (evt?: any) => void;
}

const reservationService: ReservationService = new ReservationServiceImpl();
const infraService = new InfraServiceImpl();

function MngReservationComponent(props: ReservationPopupContainer) {
    const history = useHistory();
    let reservation: Reservation = props.reservation ? props.reservation : new Reservation();

    const [reservationSearchCondition, setReservationSearchCondition] = useState<ReservationSearchCondition>();
    const { user, isLogin, hasTeamAuth, hasSystemAuth, myTeam } = useSelector((state: RootState) => state.userSessionReducer);

    const [centers, setCenters] = useState<Infra[]>();

    const [timeTable, setTimeTable] = useState<ReservationTime[]>();

    const [selectedInfra, setSelectedInfra] = useState<Infra>();
    const [selectedInfraNo, setSelectedInfraNo] = useState<any>();
    const [selectedTime, setSelectedTime] = useState<any>();
    const [selectedDay, setSelectedDay] = useState<any>();
    const [comment, setComment] = useState<string>();

    const { reservations } = useReservationOfInfra(selectedInfra?.infraNo, reservationSearchCondition);
    const [reservationTimes, setReservationTimes] = useState<ReservationTime[]>();

    const getReservationTimes = (reservation: Reservation) => {
        let date = reservation?.startDate?.split(' ')[0];
        let startTime = reservation?.startDate?.split(' ')[1];
        let endTime = reservation?.endDate?.split(' ')[1];
        return { date: date, times: startTime + '~' + endTime };
    };

    useEffect(() => {
        if (reservation) {
            let reservedInfra: Infra | undefined = reservation.infra;
            infraService.getInfraOfChildCategoryNo(2, 28).then((centers) => {
                setCenters(centers);
                console.log(reservedInfra);
                if (reservedInfra) {
                    setSelectedInfra(reservedInfra);
                    setSelectedInfraNo(reservedInfra.infraNo);
                }
            });
        }
    }, [reservation]);

    useEffect(() => {
        if (selectedInfra) {
            let beginTime = selectedInfra.reservationStartTime;
            let endTime = selectedInfra.reservationEndTime;
            let timeUnit = selectedInfra.reservationTimeUnit;
            if (beginTime) {
                let tTables: ReservationTime[] = makeTimeTable(beginTime, endTime, timeUnit);
                setTimeTable(tTables);
            }

            let reservedDay = getReservationTimes(reservation);
            setSelectedDay(reservedDay.date);
            setSelectedTime(reservedDay.times);
        }
    }, [selectedInfra]);

    useEffect(() => {
        if (selectedInfraNo && selectedInfraNo != 0) {
            let findInfra = find(centers, (center) => {
                return center.infraNo === selectedInfraNo;
            });
            console.log(findInfra);
            setSelectedInfra(findInfra);
        }
    }, [selectedInfraNo]);

    useEffect(() => {
        // 선택 일의 예약 정보를 받아 오는 기능
        if (selectedDay) {
            const reservationSearchCondition = new ReservationSearchCondition();
            reservationSearchCondition.startDay = selectedDay;
            setReservationSearchCondition(reservationSearchCondition);
        }
    }, [selectedDay]);

    const registureBtnHandler = () => {
        console.log(selectedDay);

        if (selectedDay && selectedTime && selectedInfraNo) {
            reservation.infraNo = selectedInfra?.infraNo;
            let times = selectedTime.split('~');

            reservation.startDate = selectedDay + ' ' + times[0];
            reservation.endDate = selectedDay + ' ' + times[1];
            reservation.reservationStateCodeId = 1001;
            reservation.comment = comment;

            if (props.actionType === 'register') {
                reservation.registerNo = user.userNo;
                reservation.reservaterNo = props.targetPerson?.reservaterNo;
                reservation.reservationGroupNo = new Date().getTime().toString();
                reservation.teamNo = props.targetPerson?.teamNo;
            }

            if (reservation.reservationNo) {
                reservationService.updateReservation(reservation.reservationNo, reservation).then((modified: Reservation) => {
                    let message = '예약 변경이 완료 되었습니다.';
                    confirmAlert({
                        title: '예약변경성공',
                        message: message,
                        buttons: [
                            {
                                label: '확인',
                                onClick: () => {
                                    props.closingHandler('complete');
                                },
                            },
                        ],
                    });
                });
            } else {
                console.log(reservation);
                if (reservation.infraNo && reservation.startDate && reservation.endDate) {
                    reservationService.registeReservation(reservation).then((isRegisted: Boolean) => {
                        let message = '예약이 추가 되었습니다.';
                        console.log(isRegisted);
                        confirmAlert({
                            title: '예약추가성공',
                            message: message,
                            buttons: [
                                {
                                    label: '확인',
                                    onClick: () => {
                                        props.closingHandler('complete');
                                    },
                                },
                            ],
                        });
                    });
                } else {
                    confirmAlert({
                        title: '입력 값 확인',
                        message: '필수 입력값이 비었습니다. 확인 해 주십시오',
                        buttons: [{ label: '확인', onClick: () => {} }],
                    });
                }
            }
        } else {
            confirmAlert({
                title: '입력 경고',
                message: '입력 값을 확인해 주십시오.',
                buttons: [{ label: '확인', onClick: () => {} }],
            });
        }
    };

    const byteCheck = (txt: string) => {
        var codeByte = 0;
        let maxByte = 2000;
        let isValid = true;
        for (var i = 0; i < txt.length; i++) {
            var oneChar: any = txt.charAt(i);
            if (oneChar.length == 1) {
                codeByte++;
            } else if (oneChar.indexOf('%u') != -1) {
                codeByte += 2;
            } else if (oneChar.indexOf('%') != -1) {
                codeByte++;
            }
        }
        if (codeByte > maxByte) {
            confirmAlert({
                title: '경고',
                message: '제한 길이보다 깁니다. 글자수를 확인해 주십시오',
                buttons: [{ label: '확인', onClick: () => {} }],
            });
            isValid = false;
        }

        return isValid;
    };

    const makeTimeTable = (startTime?: string, endTime?: string, timeUnit?: number): ReservationTime[] => {
        let timeTable: ReservationTime[] = new Array();

        let startHour = Number(startTime?.split(':')[0]);
        let startMin = startTime?.split(':')[1];
        let endHour = Number(endTime?.split(':')[0]);

        if (timeUnit) {
            for (var hour = startHour; hour < endHour; hour += timeUnit) {
                const timeTableStartHour = hour < 10 ? `0${hour}` : hour;
                let timeTableEndHour = hour + timeUnit;
                if (endHour < timeTableEndHour) timeTableEndHour = endHour;
                let timeTableStartTime = timeTableStartHour + ':' + startMin;
                let timeTableEndTime = timeTableEndHour + ':' + startMin;

                const reservationTime = new ReservationTime(timeTableStartTime, timeTableEndTime);
                timeTable.push(reservationTime);
                //timeTable.push(timeTableStartTime + '~' + timeTableEndTime);
            }
        } else {
            throw 'undefined reservationTimeUnit';
        }

        return timeTable;
    };

    let title = props.actionType === 'modify' ? '* 예약 변경' : '* 예약 추가';

    return (
        <>
            <div className="divTable">
                <div className="divTr">
                    <div style={{ display: 'table-cell' }}>
                        <button onClick={props.closingHandler} className="btn btn-info" style={{ float: 'right', width: '90px', fontSize: '15px' }}>
                            닫기
                        </button>
                    </div>
                </div>
                <div className="divTr" style={{}}>
                    <div style={{ display: 'table-cell', padding: '25px 0px 5px 0px', fontSize: '25px', fontWeight: 700 }}>{title}</div>
                </div>
                <div className="divTr">
                    <div className="divTd" style={{ paddingTop: '15px', paddingBottom: '3px' }}>
                        <span style={{ paddingLeft: '5px', fontWeight: 700, fontSize: '15px' }}>* 측정센터를 선택해 주세요!</span>
                    </div>
                </div>
                <div className="divTr">
                    <div className="divTd">
                        <select
                            value={selectedInfraNo}
                            className="sportsman-wrap"
                            onChange={(evt: any) => {
                                let infraNo = evt.target.value;
                                setSelectedInfraNo(infraNo);
                            }}
                        >
                            <option value="0">센터 선택</option>
                            {centers?.map((item, idx) => {
                                return (
                                    <option key={idx} value={item.infraNo}>
                                        {item.name}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                </div>
                <div className="divTr">
                    <div className="divTd" style={{ paddingTop: '15px', paddingBottom: '3px' }}>
                        <span style={{ paddingLeft: '5px', fontWeight: 700, fontSize: '15px' }}>* 희망측정일과 시간을 선택해 주세요</span>
                    </div>
                </div>
                <div className="divTr">
                    <div className="divTd">
                        {/* <div className="sportsman-wrap" style={{ width: '98%', padding: '0px 0px 0px 10px' }}> */}
                        <DatePicker
                            className="sportsman-datepicker-wrap"
                            locale={ko}
                            dateFormat="yyyy-MM-dd"
                            closeOnScroll={true}
                            selected={DateUtils.convertStrToDate(selectedDay)}
                            onChange={(date: Date) => {
                                let formedDate = DateUtils.getDateForm(date);
                                setSelectedDay(formedDate);
                            }}
                        />
                        {/* </div> */}
                    </div>
                </div>
                <div className="divTr">
                    <div className="divTd">
                        <select
                            value={selectedTime}
                            className="sportsman-wrap"
                            style={{ marginTop: '3px' }}
                            onChange={(evt: any) => {
                                let value = evt.target.value;
                                setSelectedTime(value);
                            }}
                        >
                            <option value="0">센터를 먼저선택해 주십시오.</option>

                            {timeTable?.map((item, idx) => {
                                return (
                                    <option key={idx} value={item.startTime + '~' + item.endTime}>
                                        {item.startTime + '~' + item.endTime}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                </div>
                <div className="divTr">
                    <div className="divTd" style={{ paddingTop: '15px', paddingBottom: '3px' }}>
                        <span style={{ paddingLeft: '5px', fontWeight: 700, fontSize: '15px' }}>
                            {props.actionType === 'register' ? <>* 추가 Comment</> : <>* 변경 Comment</>}
                        </span>
                        <span style={{ color: '#f00', fontSize: '8px', fontWeight: 700 }}> (최대 2000자)</span>
                    </div>
                </div>
                <div className="divTr">
                    <div className="divTd">
                        <textarea
                            rows={5}
                            style={{ width: '98%' }}
                            value={comment}
                            className="meas"
                            onChange={(e: any) => {
                                let value = e.target.value;
                                if (byteCheck(value)) setComment(value);
                            }}
                            placeholder={props.actionType === 'modify' ? '변경 이유를 남겨 주세요' : '추가 이유를 남겨 주세요'}
                        ></textarea>
                    </div>
                </div>
            </div>
            <div className="reser-bt-blue mouse-pointer" onClick={registureBtnHandler} style={{ fontSize: '20px' }}>
                {props.actionType === 'modify' ? <>예약변경하기</> : <>예약추가하기</>}
            </div>
        </>
    );
}

export default MngReservationComponent;
