import { Link } from 'react-router-dom';
import LoginState from '../../../component/header/LoginState';
import SiteMainNav from '../../../component/nav/SiteMainNav';
import MobileMenuContainer from '../../../component/header/MobileMenuContainer';

function PoliciesHeader() {
    return (
        <section className="main-visual sub-visual-bg" style={{ height: 'auto' }}>
            <div className="main-top">
                <div className="logozone">
                    <Link to="/">
                        <div>
                            <img src="/assets/image/logo_without_name.png" alt="logo" />
                        </div>
                    </Link>
                    <Link to="#">
                        <div> | 개인정보취급방침</div>
                    </Link>
                </div>
                <div className="navi">
                    <SiteMainNav></SiteMainNav>
                </div>
                <div className="main-login">
                    <LoginState></LoginState>
                </div>
                <div className="clear"></div>
                <div className="mo-menu">
                    <MobileMenuContainer></MobileMenuContainer>
                </div>
            </div>
        </section>
    );
}

export default PoliciesHeader;
