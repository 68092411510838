import React from 'react';
import SpecialPhysical from '../../domain/recording/SpecialPhysical';

interface IProps {
    data?: SpecialPhysical;
}
export default function FootScanReporting(props: IProps) {
    return (
        <>
            <table className="reporting-table" cellPadding={0} cellSpacing={0}>
                <colgroup>
                    <col style={{ width: '5%' }} />
                    <col style={{ width: '20%' }} />
                    <col style={{ width: '25%' }} />
                    <col style={{ width: '25%' }} />
                    <col style={{ width: '25%' }} />
                </colgroup>
                <tbody>
                    <tr>
                        <td rowSpan={2} style={{ fontWeight: 700, fontSize: '1em' }}>
                            풋 스캔
                            <br /> (족저압)
                        </td>
                        <th>F.S(Left)</th>
                        <th>F.S(Right)</th>
                        <th>F.S(forward)</th>
                        <th>F.S(back)</th>
                    </tr>
                    <tr>
                        <td>{props.data?.footChecker.left}</td>
                        <td>{props.data?.footChecker.right}</td>
                        <td>{props.data?.footChecker.front}</td>
                        <td>{props.data?.footChecker.back}</td>
                    </tr>
                </tbody>
            </table>
            <table className="eval-table">
                <tbody>
                    <tr>
                        <td colSpan={4}>
                            <span style={{ fontWeight: 700, fontSize: '15px' }}>* 평가</span>
                            <br />
                            {''}
                        </td>
                    </tr>
                </tbody>
            </table>
        </>
    );
}
