import React, { useState, useEffect } from 'react';
import Loader from 'react-spinners/BeatLoader';
import { css } from '@emotion/core';

interface IProps {
    loading: boolean;
}
export default function LoaderComponent(props: IProps) {
    let loading = props.loading;

    const [color, setColor] = useState('#166ec7');

    const override = css`
        display: block;
        margin: 0 auto;
        border-color: red;
    `;

    if (loading) {
        return (
            <div className="center-modal-show">
                <Loader color={color} loading={loading} css={override} size={50} />
            </div>
        );
    } else {
        return (
            <div className="center-modal-hide">
                <Loader color={color} loading={loading} css={override} size={50} />
            </div>
        );
    }
}
