import EventStatisticsServiceImpl from '../../service/event/impl/EventStatisticsServiceImpl';
import RegionIssuedEvent from '../../domain/event/RegionIssuedEvent';
import { useEffect, useState } from 'react';
const eventStatisticsService = new EventStatisticsServiceImpl();
function useRegionIssueEvent() {
    const [regionIssuedEvents, setRegionIssuedEvents] = useState<RegionIssuedEvent[]>();
    useEffect(() => {
        eventStatisticsService.getYearIssuedCoupon()
        .then((regionIssuedEvents: RegionIssuedEvent[]) => {
            setRegionIssuedEvents(regionIssuedEvents);
        });
    }, []);

    return regionIssuedEvents
}

export default useRegionIssueEvent;