import * as React from 'react';
import Code from '../../../domain/code/Code';
import { CodeType } from '../../../domain/code/CodeType';
import useRadioSelectHook from './hook/useRadioSelectHook';

interface RadioSelectProps {
    title: string;
    codes?: Code[];
    setType: (id: number) => void;
    codeType?: CodeType;
    initChoiceId: number;
}

function RadioSelect(props: RadioSelectProps) {
    const {selectedCodeId, setSelectedCodeId} = useRadioSelectHook(props.initChoiceId, props.codeType);

    return (
        <div className="type">
            <p className="search-tit">{props.title}</p>
            <ul className="two">
                {
                    props.codes?
                    props.codes.map((code, index) => {
                        return (
                            <li onChange={() => {
                                props.setType(code.codeId);
                                setSelectedCodeId(code.codeId);
                            }} key={index}>
                                <label><input checked={selectedCodeId == code.codeId} type="radio"/>{code.name}</label>
                            </li>
                        )
                    })
                    :
                    <></>
                }
            </ul>
            <div className="clear"></div>
        </div>
    )
}

export default RadioSelect;
