import { JsonObject, JsonProperty } from 'json2typescript';
import Code from '../code/Code';
import User from '../user/User';
import Team from '../team/Team';
import VisitedTeamInfoDetail from './VisitedTeamInfoDetail';

@JsonObject('visitedTeamInfo')
class VisitedTeamInfo {
    @JsonProperty('visitedTeamInfoNo')
    visitedTeamInfoNo?: string;
    @JsonProperty('teamTypeCodeId')
    teamTypeCodeId?: number;
    @JsonProperty('teamTypeCode')
    teamTypeCode?: Code;
    @JsonProperty('sportCodeId')
    sportCodeId?: number;
    @JsonProperty('sportCode')
    sportCode?: Code;
    @JsonProperty('parentRegionCodeId')
    parentRegionCodeId?: number;
    @JsonProperty('parentRegionCode')
    parentRegionCode?: Code;
    @JsonProperty('regionCodeId')
    regionCodeId?: number;
    @JsonProperty('regionCode')
    regionCode?: Code;
    @JsonProperty('visitedTeamNo')
    visitedTeamNo?: string;
    @JsonProperty('visitedTeam')
    visitedTeam?: Team;
    @JsonProperty('registerNo')
    registerNo?: string;
    @JsonProperty('register')
    register?: User;
    @JsonProperty('visitedStartDate')
    visitedStartDate?: string;
    @JsonProperty('visitedEndDate')
    visitedEndDate?: string;
    @JsonProperty('visitedMemberCnt')
    visitedMemberCnt?: number;
    @JsonProperty('visitedDayCnt')
    visitedDayCnt?: number;
    @JsonProperty('visitedMembersForYear')
    visitedMembersForYear?: number;
    @JsonProperty('infoDetail')
    infoDetail?: VisitedTeamInfoDetail[];

    static makeNewVisitedTeamInfo(
        parentRegionCodeId?: number,
        regionCodeId?: number,
        visitedTeamNo?: string,
        registerNo?: string,
        visitedStartDate?: string,
        visitedEndDate?: string,
        visitedMemberCnt?: number,
        visitedDayCnt?: number,
        visitedMembersForYear?: number,
        classificationCodeId?: number,
        sportCodeId?: number,
        infoDetail?: VisitedTeamInfoDetail[],
    ): VisitedTeamInfo | undefined {
        const visitedTeamInfo = new VisitedTeamInfo();
        visitedTeamInfo.parentRegionCodeId = parentRegionCodeId;
        visitedTeamInfo.regionCodeId = regionCodeId;
        visitedTeamInfo.visitedTeamNo = visitedTeamNo;
        visitedTeamInfo.registerNo = registerNo;
        visitedTeamInfo.visitedStartDate = `${visitedStartDate}`;
        visitedTeamInfo.visitedEndDate = `${visitedEndDate}`;
        visitedTeamInfo.visitedMemberCnt = visitedMemberCnt;
        visitedTeamInfo.visitedDayCnt = visitedDayCnt;
        visitedTeamInfo.visitedMembersForYear = visitedMembersForYear;
        visitedTeamInfo.teamTypeCodeId = classificationCodeId;
        visitedTeamInfo.sportCodeId = sportCodeId;
        visitedTeamInfo.infoDetail = infoDetail;

        return visitedTeamInfo;
    }

    changeVisitedTeamInfo(
        parentRegionCodeId?: number,
        regionCodeId?: number,
        visitedTeamNo?: string,
        registerNo?: string,
        visitedStartDate?: string,
        visitedEndDate?: string,
        visitedMemberCnt?: number,
        visitedMembersForYear?: number,
        visitedDayCnt?: number,
        infoDetail?: VisitedTeamInfoDetail[],
    ): VisitedTeamInfo {
        const teamInfo = new VisitedTeamInfo();
        teamInfo.visitedTeamInfoNo = this.visitedTeamInfoNo;
        teamInfo.parentRegionCodeId = parentRegionCodeId;
        teamInfo.regionCodeId = regionCodeId;
        teamInfo.visitedTeamNo = this.visitedTeamNo;
        teamInfo.registerNo = registerNo;
        teamInfo.visitedStartDate = `${visitedStartDate}`;
        teamInfo.visitedEndDate = `${visitedEndDate}`;
        teamInfo.visitedMemberCnt = visitedMemberCnt;
        teamInfo.visitedMembersForYear = visitedMembersForYear;
        teamInfo.visitedDayCnt = visitedDayCnt;
        teamInfo.infoDetail = infoDetail;
        return teamInfo;
    }
}

export default VisitedTeamInfo;
