import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ko } from 'date-fns/esm/locale';

import AdminMainTitle from '../../../../component/admin/AdminMainTitle';

import { CodeType } from '../../../../domain/code/CodeType';
import useCodeRetrieve from '../../../../hook/code/useCodeRetrieve';
import Code from '../../../../domain/code/Code';
import useChildCodeRetrieve from '../../../../hook/code/useChildCodeRetrieve';
import Team from '../../../../domain/team/Team';
import VisitedTeamInfo from '../../../../domain/visitedInfo/VisitedTeamInfo';
import VisitedTeamInfoDetail from '../../../../domain/visitedInfo/VisitedTeamInfoDetail';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../moldules/index';
import DateUtils from '../../../../utils/DateUtils';
import { useParams } from 'react-router-dom';
import { defaultParentRegionCodeId } from '../../../../config/Constant';
import VisitedTeamInfoSearchCondition from '../../../../domain/visitedInfo/pagam/VisitedTeamInfoSearchCondition';
import VisitedTeamInfoServiceImpl from '../../../../service/visitedInfo/impl/VisitedTeamInfoServiceImpl';
import TeamServiceImpl from '../../../../service/team/impl/TeamServiceImpl';
import { confirmAlert } from 'react-confirm-alert';

const visitedTeamInfoService = new VisitedTeamInfoServiceImpl();
const teamService = new TeamServiceImpl();
function AdminVisitedTeamInfoInputForm() {
    const { user } = useSelector((state: RootState) => state.userSessionReducer);
    const location = useLocation<VisitedTeamInfoSearchCondition>();
    const history = useHistory();
    const params: any = useParams();

    const condition: VisitedTeamInfoSearchCondition = location.state ? location.state : new VisitedTeamInfoSearchCondition();

    //const { vistiedTeamInfo: originalVisitedTeamInfo } = useVisitedTeamInfo(condition.visitedTeamInfoNo);
    const [visitedTeamInfo, setVisitedTeamInfo] = useState<VisitedTeamInfo>();
    const [visitedTeamInfoDetail, setVisitedTeamInfoDetail] = useState<VisitedTeamInfoDetail[]>([]);
    const [teamInfo, setTeamInfo] = useState<Team>();

    const [visitedHistory, setVisitedHistory] = useState<VisitedTeamInfo[]>();
    const { codes: regionCodes, initParentCodeId } = useCodeRetrieve(CodeType.REGION);

    const [teamNo, setTeamNo] = useState<string>();
    const [sportCodeId, setSportCodeId] = useState<number | undefined>(0);

    const [visitedMemberCnt, setVisitedMemberCnt] = useState<number | undefined>(0);
    const [visitedStartDate, setVisitedStartDate] = useState<any>(new Date());
    const [visitedEndDate, setVisitedEndDate] = useState<any>(new Date());
    const [visitedPeriod, setVisitedPeriod] = useState<number>();
    const [visitedMembersForYear, setVisitedMembersForYear] = useState<number>();

    const [parentRegionCodeId, setParentRegionCodeId] = useState<number | undefined>(defaultParentRegionCodeId);
    const [regionCodeId, setRegionCodeId] = useState<number>();
    const childRegionCodes = useChildCodeRetrieve(parentRegionCodeId);

    const [newVisitedTeamInfo, setNewVisitedTeamInfo] = useState<VisitedTeamInfo>();
    const [updateVisitedTeamInfo, setUpdateVisitedTeamInfo] = useState<VisitedTeamInfo>();

    const [isModifyDetail, setIsModifyingDetail] = useState(false);

    //useVisitedTeamInfoRegiste(newVisitedTeamInfo);
    //useVisitedTeamInfoUpdate(updateVisitedTeamInfo);

    const sortArrByDate = (arr: VisitedTeamInfoDetail[]) => {
        arr.sort(function (a, b) {
            const d = new Date(a.visitedDate ? a.visitedDate : '');
            const f = new Date(b.visitedDate ? b.visitedDate : '');
            return d > f ? 1 : d < f ? -1 : 0;
        });

        return arr;
    };

    useEffect(() => {
        let visitedTeamNo = condition.visitedTeamNo;
        const visitedTeamInfoNo = params.visitedTeamInfoNo;

        if (visitedTeamInfoNo) {
            visitedTeamInfoService.getVisitedTeamInfo(visitedTeamInfoNo).then((visitedTeamInfo: VisitedTeamInfo) => {
                setVisitedTeamInfo(visitedTeamInfo);
                setTeamInfo(visitedTeamInfo?.visitedTeam);
                setVisitedTeamInfoDetail(visitedTeamInfo.infoDetail ? visitedTeamInfo.infoDetail : []);

                setVisitedMemberCnt(visitedTeamInfo?.visitedMemberCnt);
                setVisitedStartDate(visitedTeamInfo?.visitedStartDate ? new Date(visitedTeamInfo?.visitedStartDate) : new Date());
                setVisitedEndDate(visitedTeamInfo.visitedEndDate ? new Date(visitedTeamInfo.visitedEndDate) : new Date());
                setSportCodeId(visitedTeamInfo?.visitedTeam?.sportCodeId);

                setTeamNo(visitedTeamInfo.visitedTeamNo);
                setVisitedPeriod(visitedTeamInfo.visitedDayCnt);
                setVisitedMemberCnt(visitedTeamInfo.visitedMemberCnt);
                setVisitedMembersForYear(visitedTeamInfo.visitedMembersForYear);
                setParentRegionCodeId(visitedTeamInfo?.parentRegionCodeId);
                setRegionCodeId(visitedTeamInfo.regionCodeId);

                setVisitedTeamInfoDetail(visitedTeamInfo.infoDetail ? sortArrByDate(visitedTeamInfo.infoDetail) : []);
            });

            visitedTeamInfoService.getVisitedTeamHistoryByTeamNo(condition).then((visitedTeamInfos: VisitedTeamInfo[]) => {
                setVisitedHistory(visitedTeamInfos);
            });
        }

        if (visitedTeamNo) {
            teamService.getTeamInfo(visitedTeamNo).then((team: Team) => {
                setTeamInfo(team);
            });
        }
    }, []);

    useEffect(() => {
        const period = DateUtils.getDayGap(visitedStartDate, visitedEndDate) + 1;
        setVisitedPeriod(period);
        setVisitedMembersForYear((visitedMemberCnt ? visitedMemberCnt : 0) * period);
    }, [visitedEndDate, visitedStartDate]);

    const handleInfoDetail = () => {
        const visitedDetail: VisitedTeamInfoDetail[] = [];
        let size = visitedPeriod ? visitedPeriod : 0;
        let yearCnt = 0;
        for (let i = 0; i < size; i++) {
            const regacy = visitedTeamInfoDetail[i];
            let item = null;
            if (regacy) {
                item = { ...regacy };
            } else {
                item = new VisitedTeamInfoDetail();
                item.visitedDate = DateUtils.getDateForm(DateUtils.addDay(visitedStartDate, i));
                item.visitedTeamInfoNo = visitedTeamInfo?.visitedTeamInfoNo;
                item.visitedMemberCnt = visitedMemberCnt;
            }

            yearCnt += item.visitedMemberCnt ? item.visitedMemberCnt : 0;
            visitedDetail.push(item);
        }
        setVisitedMembersForYear(yearCnt);
        setVisitedTeamInfoDetail(visitedDetail);
    };

    const clickRegisterHandler = () => {
        const registureInfo = VisitedTeamInfo.makeNewVisitedTeamInfo(
            parentRegionCodeId,
            regionCodeId,
            teamInfo?.teamNo,
            user.userNo,
            DateUtils.getDateForm(visitedStartDate),
            DateUtils.getDateForm(visitedEndDate),
            visitedMemberCnt,
            visitedPeriod,
            visitedMembersForYear,
            teamInfo?.classificationCodeId,
            teamInfo?.sportCodeId,
            visitedTeamInfoDetail,
        );

        if (
            registureInfo?.parentRegionCodeId &&
            registureInfo.regionCodeId &&
            registureInfo?.visitedTeamNo &&
            registureInfo?.visitedMemberCnt &&
            new Date(visitedStartDate) <= new Date(visitedEndDate)
        ) {
            setNewVisitedTeamInfo(registureInfo);
        } else {
            confirmAlert({
                title: '데이터 확인',
                message: '입력한 값을 확인해 주십시오. 날짜설정이나 누락된 값이 있습니다.',
                buttons: [{ label: '확인', onClick: () => {} }],
            });
        }
    };
    useEffect(() => {
        if (newVisitedTeamInfo) {
            visitedTeamInfoService.visitedTeamInfoRegiste(newVisitedTeamInfo).then((result: VisitedTeamInfo) => {
                let message =
                    '<' +
                    result.visitedStartDate +
                    '~' +
                    result.visitedEndDate +
                    ' / ' +
                    result.visitedMemberCnt +
                    '명 > 방문 일정이 생성 되었습니다. 상세화면에서 확인해 주십시오.';
                confirmAlert({
                    title: '등록 성공',
                    message: message,
                    buttons: [
                        {
                            label: '확인',
                            onClick: () => {
                                history.replace({
                                    pathname: '/admin/visited-team-info/detail/' + result.visitedTeamNo,
                                    state: condition,
                                });
                            },
                        },
                    ],
                });
            });
        }
    }, [newVisitedTeamInfo]);

    const clickUpdateHandler = () => {
        if (visitedTeamInfo) {
            const updateVisitedTeamInfo = visitedTeamInfo.changeVisitedTeamInfo(
                parentRegionCodeId,
                regionCodeId,
                teamNo,
                user.userNo,
                DateUtils.getDateForm(visitedStartDate),
                DateUtils.getDateForm(visitedEndDate),
                visitedMemberCnt,
                visitedMembersForYear,
                visitedPeriod,
                visitedTeamInfoDetail,
            );

            if (updateVisitedTeamInfo) {
                if (visitedStartDate <= visitedEndDate) {
                    setUpdateVisitedTeamInfo(updateVisitedTeamInfo);
                } else {
                    alert('날짜 설정이 잘못되었습니다.');
                }
            }
        }
    };

    useEffect(() => {
        if (updateVisitedTeamInfo) {
            visitedTeamInfoService.visitedTeamInfoUpdate(updateVisitedTeamInfo).then((result: VisitedTeamInfo) => {
                let message =
                    '<' +
                    result.visitedStartDate +
                    '~' +
                    result.visitedEndDate +
                    ' / ' +
                    result.visitedMemberCnt +
                    '명 > 방문 일정이 수정 되었습니다. 상세화면에서 확인해 주십시오.';
                confirmAlert({
                    title: '수정 성공',
                    message: message,
                    buttons: [
                        {
                            label: '확인',
                            onClick: () => {
                                history.replace({
                                    pathname: '/admin/visited-team-info/detail/' + result.visitedTeamNo,
                                    state: condition,
                                });
                            },
                        },
                    ],
                });
            });
        }
    }, [updateVisitedTeamInfo]);

    const goBack = () => {
        let returnPath = condition.returnPath ? condition.returnPath : '/admin/visited-team-info';
        history.replace({
            pathname: returnPath,
            state: condition,
        });
    };

    return (
        <>
            {!visitedTeamInfo ? (
                <AdminMainTitle title="방문 팀 정보 등록"></AdminMainTitle>
            ) : (
                <AdminMainTitle title="방문 팀 정보 수정"></AdminMainTitle>
            )}
            <div className="two-wrap">
                <div className="half">
                    <div className="member-list">
                        <table>
                            <colgroup>
                                <col style={{ width: '10%', backgroundColor: '#f2f2f2' }} />
                                <col style={{ width: '20%' }} />
                                <col style={{ width: '10%', backgroundColor: '#f2f2f2' }} />
                                <col style={{ width: '20%' }} />
                                <col style={{ width: '10%', backgroundColor: '#f2f2f2' }} />
                                <col style={{ width: '30%' }} />
                            </colgroup>
                            <thead>
                                <tr>
                                    <td colSpan={6} style={{ padding: '2px 0' }}>
                                        {visitedTeamInfo ? (
                                            <button
                                                className="bg-blue white"
                                                onClick={() => clickUpdateHandler()}
                                                style={{ float: 'right', marginBottom: '5px', marginTop: '5px', fontWeight: 700 }}
                                            >
                                                방문 팀 정보 수정
                                            </button>
                                        ) : (
                                            <button
                                                className="bg-blue white"
                                                onClick={() => clickRegisterHandler()}
                                                style={{ float: 'right', marginBottom: '5px', marginTop: '5px', fontWeight: 700 }}
                                            >
                                                방문 팀 정보 등록
                                            </button>
                                        )}
                                        <button
                                            onClick={goBack}
                                            style={{
                                                backgroundColor: '#ffc107',
                                                color: '#3a4652 !important',
                                                float: 'right',
                                                marginBottom: '5px',
                                                marginTop: '5px',
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            돌아가기
                                        </button>
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="txt-left pdl30 blue" style={{ height: '35px' }}>
                                        팀명
                                    </td>
                                    <td className="txt-left" colSpan={5}>
                                        {teamInfo?.name}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="txt-left pdl30 blue">방문지역</td>
                                    <td className="txt-left" colSpan={5}>
                                        <select
                                            style={{ width: '100px' }}
                                            value={parentRegionCodeId}
                                            onChange={(event) => {
                                                setParentRegionCodeId(Number(event.currentTarget.value));
                                            }}
                                        >
                                            <option>도 단위 지역 선택</option>
                                            {regionCodes?.map((regionCode: Code, index: number) => {
                                                return (
                                                    <option key={index} value={regionCode.codeId}>
                                                        {regionCode.name}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                        <select
                                            style={{ width: '200px' }}
                                            value={regionCodeId}
                                            onChange={(event) => {
                                                setRegionCodeId(Number(event.currentTarget.value));
                                            }}
                                        >
                                            <option>시.군단위 지역선택</option>
                                            {childRegionCodes?.map((childRegionCode: Code, index: number) => {
                                                return (
                                                    <option key={index} value={childRegionCode.codeId}>
                                                        {childRegionCode.name}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="txt-left pdl30 blue">방문일정</td>
                                    <td className="txt-left" colSpan={5}>
                                        <table>
                                            <colgroup>
                                                <col style={{ width: '5%' }} />
                                                <col style={{ width: '30%' }} />
                                                <col style={{ width: '4%' }} />
                                                <col style={{ width: '5%' }} />
                                                <col style={{ width: '*' }} />
                                            </colgroup>
                                            <tbody>
                                                <tr>
                                                    <td>시작일:</td>
                                                    <td>
                                                        <DatePicker
                                                            locale={ko}
                                                            dateFormat="yyyy-MM-dd"
                                                            closeOnScroll={true}
                                                            selected={visitedStartDate}
                                                            onChange={(date) => setVisitedStartDate(date)}
                                                        />
                                                    </td>
                                                    <td>~</td>
                                                    <td>종료일:</td>
                                                    <td>
                                                        <DatePicker
                                                            locale={ko}
                                                            dateFormat="yyyy-MM-dd"
                                                            closeOnScroll={true}
                                                            selected={visitedEndDate}
                                                            onChange={(date) => setVisitedEndDate(date)}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={5}>
                                                        <button
                                                            onClick={() => {
                                                                handleInfoDetail();
                                                            }}
                                                            style={{
                                                                backgroundColor: '#ffc107',
                                                                color: '#3a4652 !important',
                                                                float: 'right',
                                                                marginBottom: '5px',
                                                                marginTop: '5px',
                                                                fontWeight: 'bold',
                                                            }}
                                                        >
                                                            상세 내역 입력
                                                        </button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="txt-left pdl30 blue">참가인원(평균)</td>
                                    <td className="txt-left">
                                        <input
                                            onChange={(event) => {
                                                const val = event.currentTarget.value;
                                                if (!isNaN(Number(val))) {
                                                    setVisitedMemberCnt(Number(val));
                                                    const newObj = [...visitedTeamInfoDetail];
                                                    newObj.forEach((item) => (item.visitedMemberCnt = Number(val)));
                                                    setVisitedTeamInfoDetail(newObj);
                                                    setVisitedMembersForYear(Number(val) * (visitedPeriod ? visitedPeriod : 0));
                                                } else {
                                                    alert('숫자만 입력가능합니다.');
                                                }
                                            }}
                                            type="text"
                                            value={visitedMemberCnt}
                                        ></input>
                                    </td>
                                    <td className="txt-left pdl30 blue">방문기간</td>
                                    <td className="txt-left">{visitedPeriod} 일간</td>
                                    <td className="text-left pdl30 blue">연 인원</td>
                                    <td className="text-left">
                                        <div className="flex-basic">
                                            연
                                            <input
                                                onChange={(event) => {
                                                    if (!isNaN(Number(event.currentTarget.value))) {
                                                        setVisitedMembersForYear(Number(event.currentTarget.value));
                                                    } else {
                                                        alert('숫자만 입력가능합니다.');
                                                    }
                                                }}
                                                type="text"
                                                value={visitedMembersForYear}
                                                style={{ width: '100px' }}
                                            ></input>
                                            명 방문
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="half">
                    <div className="member-list">
                        <table>
                            <colgroup>
                                <col style={{ width: '30%', backgroundColor: '#f2f2f2' }} />
                                <col style={{ width: '70%' }} />
                            </colgroup>
                            <thead>
                                <tr>
                                    <td colSpan={3} style={{ fontSize: '20px', padding: '9px 0' }}>
                                        방문 상세 내역
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ padding: '5px 0', fontSize: '16px' }}>일자</td>
                                    <td style={{ textAlign: 'left', padding: '5px 0', fontSize: '16px' }}>&nbsp;&nbsp;&nbsp;인원 (명)</td>
                                </tr>
                            </thead>
                            <tbody>
                                {visitedTeamInfoDetail && visitedTeamInfoDetail.length !== 0 ? (
                                    visitedTeamInfoDetail.map((item, idx) => {
                                        return (
                                            <tr key={idx}>
                                                <td className="txt-left pdl30 blue" style={{ height: '35px', fontSize: '14px' }}>
                                                    {`< ${item.visitedDate} >  일자 방문 인원:`}
                                                </td>
                                                <td className="txt-left">
                                                    <input
                                                        type="text"
                                                        name="visitedMember"
                                                        value={item.visitedMemberCnt}
                                                        onChange={(evt) => {
                                                            const val = evt.currentTarget.value;

                                                            if (!isNaN(Number(val))) {
                                                                const newObj = [...visitedTeamInfoDetail];
                                                                const detail = newObj[idx];
                                                                detail.visitedMemberCnt = val ? Number(val) : 0;
                                                                let yearCnt = 0;
                                                                let avgMember = 0;
                                                                newObj.forEach((item) => {
                                                                    yearCnt += item.visitedMemberCnt ? item.visitedMemberCnt : 0;
                                                                });
                                                                avgMember = Number((yearCnt / (visitedPeriod ? visitedPeriod : 1)).toFixed(0));

                                                                setVisitedMemberCnt(avgMember);
                                                                setVisitedMembersForYear(yearCnt);

                                                                setVisitedTeamInfoDetail(newObj);
                                                            } else {
                                                                alert('숫자만 입력 가능 합니다.');
                                                            }
                                                        }}
                                                    />
                                                </td>
                                            </tr>
                                        );
                                    })
                                ) : (
                                    <tr>
                                        <td colSpan={2}> 등록 된 상세 일정이 없습니다.</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AdminVisitedTeamInfoInputForm;
