import React, { useEffect, useState } from 'react';
import RetrieveUser from '../../../../domain/user/RetrieveUser';
import Roles from '../../../../domain/user/Roles';
import useSignupApprove from '../../../../hook/user/useSignupApprove';
import useRoleUpdate from '../../../../hook/user/useRoleUpdate';
interface RehabManageUserTableTrProps {
    hasCenterOperAuth?: boolean;
    user: RetrieveUser;
    roles?: Roles[];
    idx?: number;
}

function RehabManageUserTableTr(props: RehabManageUserTableTrProps) {
    const [user, setUser] = useState<RetrieveUser>();
    const { approveHandler, signUpStateUser } = useSignupApprove(props.user);
    const { roleUpdateHandler, roleStateUser } = useRoleUpdate(props.user);

    useEffect(() => {
        setUser(props.user);
    }, [props.user]);

    useEffect(() => {
        if (signUpStateUser) {
            setUser(signUpStateUser);
        }
    }, [signUpStateUser]);

    useEffect(() => {
        if (roleStateUser) {
            setUser(roleStateUser);
        }
    }, [roleStateUser]);

    return (
        <tr key={props.idx}>
            <td>{(props.idx ? props.idx : 0) + 1}.</td>
            <td>{user?.authorities[0].roles?.roleDesc}</td>
            <td>{user?.email}</td>
            <td>{user?.name}</td>
            <td>{user?.registeDate}</td>

            {props.hasCenterOperAuth ? (
                <td>
                    <select
                        className="center-select"
                        onChange={(event) => {
                            roleUpdateHandler(Number(event.currentTarget.value));
                        }}
                    >
                        {props.roles?.map((role: Roles, index: number) => {
                            return (
                                <option selected={user?.authorities[0].roles?.roleNo == role.roleNo} key={index} value={role.roleNo}>
                                    {role.roleDesc}
                                </option>
                            );
                        })}
                    </select>
                </td>
            ) : (
                <td>{user?.authorities[0].roles?.roleDesc}</td>
            )}
            {props.hasCenterOperAuth ? (
                <td>
                    {user?.signupApprove ? (
                        <button className="center-button-no" onClick={() => approveHandler(false)}>
                            <i className="fas fa-user-times"></i> 가입승인취소
                        </button>
                    ) : (
                        <button className="center-button-no" onClick={() => approveHandler(true)}>
                            <i className="fas fa-user-times"></i> 가입승인
                        </button>
                    )}
                </td>
            ) : (
                <></>
            )}
        </tr>
    );
}

export default RehabManageUserTableTr;
