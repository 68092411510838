import React, { useEffect } from 'react';
import MyPageBody from './MyPageBody';
import MyPageHeader from './MyPageHeader';
import MyPageLeft from './MyPageLeft';
import MyPageUserInfoHeader from '../../../component/header/MyPageUserInfoHeader';
import MyPageRight from './MyPageRight';
import MyPageRightContent from './MyPageRightContent';

interface MyPageMainLayoutProps {
    children?: React.ReactNode;
}

function MyPageMainLayout(props: MyPageMainLayoutProps) {
    return (
        <>
            <MyPageRight>
                {props.children}
            </MyPageRight>
        </>
    )
}

export default MyPageMainLayout;