import { useEffect, useState } from "react";
import Code from '../../../../domain/code/Code';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../moldules/index';
import { CodeType } from '../../../../domain/code/CodeType';
import { changeConditionAction, clearConditionAction } from '../../../../moldules/SearchCondition';

function useMultiSelectHook(codeType: CodeType) {
    const dispatch = useDispatch();
    
    const searchConditionMap = useSelector((state: RootState) => state.searchCondition.searchConditionMap);
    const [selectedCnt, setSelectedCnt] = useState(0);
    const conditionMap: any = searchConditionMap[codeType];
    const selectedEventHandler = (codeId: number) => {
        dispatch(changeConditionAction(codeId, codeType));
    }
    const selectedClearEventHandler = () => {
        dispatch(clearConditionAction(codeType));
    }

    const allConditionCheck = () => {
        if(!conditionMap) return true;
        else return false;
    }

    const conditionCheck = (codeId: number) => {
        if(conditionMap) {
            return conditionMap.hasOwnProperty(codeId);
        } else {
            return false;
        }
    }


    useEffect(() => {
        const conditionMap: any = searchConditionMap[codeType];
        if(conditionMap) {
            setSelectedCnt(Object.keys(conditionMap).length);
        } else {
            setSelectedCnt(0);
        }
    }, [searchConditionMap]);

    return {
        selectedCnt,
        conditionMap,
        selectedEventHandler,
        selectedClearEventHandler,
        allConditionCheck,
        conditionCheck
    }
}

export default useMultiSelectHook;