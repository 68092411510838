import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { RootState } from '../moldules/index';

interface PrivateRouteProps extends RouteProps {
    component: any;
}

/**
 * ...rest에는 Router일 경우 React에서 내부적으로 넘겨주는
 * computedMatch, location, path의 값이 저장되어 있다.
 * @param props 
 */
const PrivateRoute = (props: PrivateRouteProps) => {
    const { component: Component, ...rest } = props;
    const {isLogin} = useSelector((state: RootState) => state.userSessionReducer);
    
    return (
        <Route
            {...rest}
            render={(props) => 
                isLogin != undefined ? 
                    isLogin ? (
                        <Component {...rest} {...props} />
                    ) : (
                        <Redirect
                            to={{
                                pathname: '/login',
                                state: { from: props.location },
                            }}
                        />
                    )
                    :
                    <></>
            }
        />
    );
};

export default PrivateRoute;

