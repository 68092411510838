import React, { useEffect, useState } from 'react';
import SearchBarResult from './SearchBarResult';
import SearchBarProps from './SearchBarProps';
import SearchWordResult from './SearchWordResult';
import useSearchBarHook from './hook/useSearchBarHook';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { addSearchWordAction, initSearchWordAction } from '../../moldules/SearchWordReduce';
import SearchServiceImpl from '../../service/search/impl/SearchServiceImpl';

/**
 * FIXME: HOOK 위치 조정
 * @param props
 */
const initState: SearchBarProps = {
    placeholder: '시설 혹은 팀 검색',
};
const searchService = new SearchServiceImpl();
function CommonSearchBar(props: SearchBarProps = initState) {
    const dispatch = useDispatch();
    const {
        searchWord,
        setSearchWord,
        searchItems,
        searchWordVisible,
        setSearchWordVisible,
        setLastedSearchWord,
        mySearchWords,
        mostFavoritesSearchWords,
    } = useSearchBarHook();

    useEffect(() => {
        if (props.searchWord) {
            setSearchWord(props.searchWord);
        }
    }, [props.searchWord]);

    const onClickHandler = () => {
        //setSearchWordVisible(!searchWordVisible);
    };

    return (
        <div className="search " data-wow-delay="1.2s">
            <div className="search-box" /*  style={{ borderRadius: searchWordVisible ? '30px 30px 0px 0px / 30px 30px' : ''}} */>
                <div className="flex-basic" /* style={{ height: '45px', padding: '0px 10px' }} */>
                    <input
                        autoComplete={'off'}
                        onChange={(event) => {
                            setSearchWord(event.target.value);
                        }}
                        onClick={() => onClickHandler()}
                        value={searchWord}
                        type="text"
                        name="search"
                        placeholder={'시설 혹은 팀 검색'}
                    />
                    {
                        <Link
                            to={props.hrefPath ? props.hrefPath : '/search'}
                            onClick={() => {
                                dispatch(addSearchWordAction(searchWord));
                                searchService.saveSearchWord(searchWord);
                            }}
                        >
                            <button className="bt-basic white">
                                <i className="fas fa-search"></i>
                            </button>
                        </Link>
                    }

                    {searchWordVisible ? (
                        <div
                            className="search-latest"
                            /* style={{ display: 'block', padding: '0px 10px', backgroundColor: 'rgba(255,255,255,0.8)', height: 'auto' }} */
                            style={{ display: 'block' }}
                        >
                            <SearchWordResult
                                title="인기 검색어"
                                searchWords={mostFavoritesSearchWords}
                                setLastedSearchWord={setSearchWord}
                                setSearchWordVisible={setSearchWordVisible}
                            ></SearchWordResult>
                            {/* <SearchWordResult title="최근 검색어" searchWords={mySearchWords} setLastedSearchWord={setSearchWord}></SearchWordResult> */}
                            {searchItems ? <SearchBarResult searchItems={searchItems}></SearchBarResult> : <></>}
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
            </div>
        </div>
    );
}

export default CommonSearchBar;
