import React, { useEffect, useState } from 'react';
import Opinions from '../../../../../domain/prescription/Opinions';

interface IProps {
    handler: (evt: any) => void;
    opinions: Opinions;
}
const OpinionFormComponent = (props: IProps) => {
    const handler = props.handler;

    return (
        <>
            <div className="center-popup-check-in">
                <p className="center-popup-tit">자각증상</p>
                <input
                    className="center-input-text center-w550"
                    type="text"
                    name="opinions"
                    id="opinion_1"
                    onChange={handler}
                    value={props.opinions.opinion_1}
                />
            </div>
            {/* <!--//center-popup-check--> */}

            <div className="center-popup-check-in">
                <p className="center-popup-tit">선수반응상태</p>
                <input
                    className="center-input-text center-w550"
                    type="text"
                    name="opinions"
                    id="opinion_2"
                    onChange={handler}
                    value={props.opinions.opinion_2}
                />
            </div>
            {/* <!--//center-popup-check--> */}

            <div className="center-popup-check-in">
                <p className="center-popup-tit">심리반응의 행동 발현상태</p>
                <input
                    className="center-input-text center-w550"
                    type="text"
                    name="opinions"
                    id="opinion_3"
                    onChange={handler}
                    value={props.opinions.opinion_3}
                />
            </div>
            {/* <!--//center-popup-check-->
             */}
            <div className="center-popup-check-in">
                <p className="center-popup-tit">기타</p>
                <input className="center-input-text center-w550" type="text" name="opinions" id="etc" onChange={handler} value={props.opinions.etc} />
            </div>
            {/* <!--//center-popup-check--> */}
        </>
    );
};

export default OpinionFormComponent;
