import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject("rehabCenterQnaRation")
class RehabCenterQnaRation {
    @JsonProperty("totalCnt")
    totalCnt?: number;
    @JsonProperty("qnaCnt")
    qnaCnt?: number;
    @JsonProperty("notQnaCnt")
    notQnaCnt?: number;
    @JsonProperty("qnaCompleteRate")
    qnaCompleteRate?: number;
    @JsonProperty("qnaNotCompleteRate")
    qnaNotCompleteRate?: number;
}

export default RehabCenterQnaRation;