import { JsonProperty, JsonObject } from 'json2typescript';

@JsonObject('batchData')
class BatchData {
    @JsonProperty('visiteDate')
    visiteDate: string = '';
    @JsonProperty('name')
    name: string = '';
    @JsonProperty('phoneNumber')
    phoneNumber: string = '';
    @JsonProperty('batchData')
    batchData: string = '';
}

export default BatchData;
