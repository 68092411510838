import React, { useEffect, useState } from 'react';
import SearchCnt from '../../component/search/SearchCnt';
import SearchCategoryDisplay from '../../component/search/SearchCategoryDisplay';
import SearchViewType from '../../component/search/SearchViewType';
import Infra from '../../domain/infra/Infra';
import { InfraSearchList } from '../../component/infra/InfraSearchList';
import InfraSearchMap from '../../component/infra/InfraSearchMap';
import InfraServiceImpl from '../../service/infra/impl/InfraServiceImpl';
import UserLocation from '../../domain/user/UserLocation';
import SearchIteamPaging from '../../component/search/SearchItemPaging';
interface SearchOfInfraContainerProps {
    searchedInfras?: Infra[];
    searchCnt: number;
    userLocation?: UserLocation;
}

function SearchOfInfraContainer(props: SearchOfInfraContainerProps) {
    const [searchViewType, setSearchViewType] = useState('list');

    return (
        <>
            {/* <SearchCnt searchCnt={props.searchCnt}></SearchCnt> */}
            <div className="output-cate">
                <div className="cate-left">
                    <SearchCategoryDisplay></SearchCategoryDisplay>
                </div>
                <div className="cate-right">
                    <SearchViewType setSearchViewType={setSearchViewType} listViewTypeActive={true} mapViewTypeActive={true}></SearchViewType>
                </div>
            </div>
            <div className="clear"></div>
            <div className="output-left">{infraSearchRender(props.searchedInfras, props.userLocation, searchViewType)}</div>
        </>
    );
}

function infraSearchRender(infras: Infra[] | undefined, userLocation: UserLocation | undefined, viewType: string) {
    if (infras) {
        if (infras.length > 0 && viewType === 'list') {
            return <InfraSearchList searchedInfras={infras}></InfraSearchList>;
        } else if (infras.length > 0 && viewType === 'map') {
            return <InfraSearchMap userLocation={userLocation} searchedInfras={infras}></InfraSearchMap>;
        } else {
            return <h5>검색 된 시설이 없습니다.</h5>;
        }
    }
}

export default SearchOfInfraContainer;
