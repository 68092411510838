import { useEffect, useState } from 'react';
import ReportDataParam from '../../domain/report/param/ReportDataParam';
import ReportServiceImpl from '../../service/report/impl/ReportServiceImpl';
import Report from '../../domain/report/Report';

const reportService = new ReportServiceImpl();

function useReportSave(reportDataParam?: ReportDataParam) {
    // const [savedFilePath, setSavedFilePath] = useState<string>();
    const [savedReport, setSavedReport] = useState<Report>();

    useEffect(() => {
        if(reportDataParam) {
            reportService.saveReport(reportDataParam)
            .then(savedReport => {
                setSavedReport(savedReport);
            });
        }
    }, [reportDataParam]);

    return {savedReport, setSavedReport};
}

export default useReportSave;