import React from 'react';
import { Switch, Route, RouteComponentProps, useHistory, useParams } from 'react-router-dom';

import AdminMembersContainer from '../page/common/AdminMembersContainer';
import MembersDetailContainer from '../page/members/MembersDetailContainer';

import AdminManagerListContainer from '../page/manager/AdminManagerListContainer';
import AdminManagerDetailContainer from '../page/manager/AdminManagerDetailContainer';

import AdminSurveyContainer from '../page/survey/AdminSurveyContainer';

import AdminInfraListContainer from '../page/infras/AdminSportInfraListContainer';
import AdminSportInfraFormContainer from '../page/infras/AdminSportInfraFormContainer';

import AdminSubInfraListContainer from '../page/facility/AdminSubInfraListContainer';
import AdminSubInfraFormContainer from '../page/facility/AdminSubInfraFormContainer';

import AdminTeamListContainer from '../page/team/AdminTeamListContainer';
import AdminTeamInfoContainer from '../page/team/AdminTeamInfoContainer';
import AdminTeamScheduleContainer from '../page/team/AdminTeamScheduleContainer';
import AdminTeamStoryContrainer from '../page/team/AdminTeamStoryContainer';

import AdminSportInfraScheduleDetailContainer from '../page/infras/AdminSportInfraScheduleDetailContainer';
import AdminSportInfraScheduleListPage from '../page/infras/AdminSportInfraScheduleListPage';
import AdminEventIndexPage from '../page/event/AdminEventIndexPage';

import AdminTouringGuideListComponent from '../page/touring/AdminTouringGuideListContainer';
import AdminCommonTemplate from '../layout/AdminCommonTemplate';
import AdminTeamBelongContainer from '../page/team/AdminTeamBelongContainer';
import AdminEventFormPage from '../page/event/AdminEventFormPage';
import AdminEventStatusDashboardPage from '../page/event/AdminEventStatusDashboardPage';
import AdminVisitedTeamInfoIndexPage from '../page/visitedInfo/AdminVisitedTeamInfoIndexPage';
import AdminVisitedTeamInfoDetailPage from '../page/visitedInfo/AdminVisitedTeamInfoDetailPage';
//import AdminVisitedTeamInfoStatusIndexPage from '../page/visitedInfo/AdminVisitedTeamInfoStatusIndexPage';
//import AdminVisitedTeamInfoTeamTypeStatusPage from '../page/visitedInfo/AdminVisitedTeamInfoTeamTypeStatusPage';
import AdminVisitedTeamStatusBySportsType from '../page/visitedInfo/AdminVisitedTeamStatusBySportsType';
import AdminVisitedTeamStatusByTeamType from '../page/visitedInfo/AdminVisitedTeamStatusByTeamType';
import AdminVisitedTeamStatusByPeriod from '../page/visitedInfo/AdminVisitedTeamStatusByPeriod';
import AdminManagerRegistePage from '../page/manager/AdminManagerRegistePage';
import SystemPrivateRoute from '../../SystemPrivateRoute';
import AdminTeamFormPage from '../page/team/AdminTeamFormPage';
import AdminClubTeamInfoIndexPage from '../page/clubTeam/AdminClubTeamInfoIndexPage';
import AdminClubTeamStatisticsBySportPage from '../page/clubTeam/AdminClubTeamStatisticsBySportPage';
import AdminVisitedTeamInfoInputForm from '../page/visitedInfo/AdminVisitedTeamInfoInputForm';
import AdminClubTeamFormPage from '../page/clubTeam/AdminClubTeamFormPage';
import AdminClubTeamStatus from '../page/clubTeam/AdminClubTeamStatusBySports';
import AdminClubTeamStatusBySports from '../page/clubTeam/AdminClubTeamStatusBySports';
import AdminClubTeamStatusByRegion from '../page/clubTeam/AdminClubTeamStatusByRegion';

function AdminRouter({ match }: RouteComponentProps) {
    return (
        <AdminCommonTemplate>
            <Switch>
                <SystemPrivateRoute exact={true} path={match.path} component={AdminMembersContainer}></SystemPrivateRoute>
                <SystemPrivateRoute path={`${match.path}/members/:memberNo`} component={MembersDetailContainer}></SystemPrivateRoute>
                <SystemPrivateRoute path={`${match.path}/members`} component={AdminMembersContainer}></SystemPrivateRoute>
                <SystemPrivateRoute exact path={`${match.path}/manager/list`} component={AdminManagerListContainer}></SystemPrivateRoute>
                <SystemPrivateRoute exact path={`${match.path}/manager/registry`} component={AdminManagerRegistePage}></SystemPrivateRoute>
                <SystemPrivateRoute path={`${match.path}/manager/:userNo`} component={AdminManagerDetailContainer}></SystemPrivateRoute>
                <Route path={`${match.path}/survey/info`} component={AdminSurveyContainer}></Route>
                <Route path={`${match.path}/survey/list`} component={AdminSurveyContainer}></Route>
                <Route path={`${match.path}/team/detail/:teamNo`} component={AdminTeamInfoContainer}></Route>
                <Route path={`${match.path}/team/belong/:teamNo`} component={AdminTeamBelongContainer}></Route>
                <Route path={`${match.path}/team/schedule/:teamNo`} component={AdminTeamScheduleContainer}></Route>
                <Route path={`${match.path}/team/story/:teamNo`} component={AdminTeamStoryContrainer}></Route>
                <Route exact path={`${match.path}/team`} component={AdminTeamListContainer}></Route>
                <Route exact path={`${match.path}/team/registry`} component={AdminTeamFormPage}></Route>
                <Route path={`${match.path}/infra/list`} component={AdminInfraListContainer}></Route>
                <Route exact path={`${match.path}/infra/:infraNo`} component={AdminSportInfraFormContainer}></Route>
                <SystemPrivateRoute
                    exact
                    path={`${match.path}/infra/:infraNo/schedule`}
                    component={AdminSportInfraScheduleDetailContainer}
                ></SystemPrivateRoute>
                <Route exact={true} path={`${match.path}/infra/schedule/list`} component={AdminSportInfraScheduleListPage}></Route>
                <Route path={`${match.path}/facility/list`} component={AdminSubInfraListContainer}></Route>
                <Route path={`${match.path}/facility/:facilityNo`} component={AdminSubInfraFormContainer}></Route>
                <Route path={`${match.path}/touring/guide`} component={AdminTouringGuideListComponent}></Route>
                <Route path={`${match.path}/touring/info`} component={AdminTouringGuideListComponent}></Route>
                <Route exact path={`${match.path}/event`} component={AdminEventIndexPage}></Route>
                <Route exact path={`${match.path}/event/registry`} component={AdminEventFormPage}></Route>
                <Route exact path={`${match.path}/event/status-dashboard`} component={AdminEventStatusDashboardPage}></Route>
                <Route exact path={`${match.path}/visited-team-info`} component={AdminVisitedTeamInfoIndexPage}></Route>
                <Route exact path={`${match.path}/visited-team-info/registry`} component={AdminVisitedTeamInfoInputForm}></Route>
                <SystemPrivateRoute
                    exact
                    path={`${match.path}/visited-team-info/status-dashboard`}
                    component={AdminVisitedTeamStatusBySportsType}
                ></SystemPrivateRoute>
                <SystemPrivateRoute
                    exact
                    path={`${match.path}/visited-team-info/team-type/status-dashboard`}
                    component={AdminVisitedTeamStatusByTeamType}
                ></SystemPrivateRoute>
                <SystemPrivateRoute
                    exact
                    path={`${match.path}/visited-team-info/period/status-dashboard`}
                    component={AdminVisitedTeamStatusByPeriod}
                ></SystemPrivateRoute>
                <Route exact path={`${match.path}/visited-team-info/:visitedTeamInfoNo`} component={AdminVisitedTeamInfoInputForm}></Route>
                <Route exact path={`${match.path}/visited-team-info/detail/:visitedTeamNo`} component={AdminVisitedTeamInfoDetailPage}></Route>
                <Route exact path={`${match.path}/club-team-info`} component={AdminClubTeamInfoIndexPage}></Route>
                <Route exact path={`${match.path}/club-team-info/registry`} component={AdminClubTeamFormPage}></Route>
                <SystemPrivateRoute
                    exact
                    path={`${match.path}/club-team-info/sports/status-dashboard`}
                    //component={AdminClubTeamStatisticsBySportPage}
                    component={AdminClubTeamStatusBySports}
                ></SystemPrivateRoute>
                <SystemPrivateRoute
                    exact
                    path={`${match.path}/club-team-info/region/status-dashboard`}
                    //component={AdminClubTeamStatisticsBySportPage}
                    component={AdminClubTeamStatusByRegion}
                ></SystemPrivateRoute>
            </Switch>
        </AdminCommonTemplate>
    );
}

export default AdminRouter;
