import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import Layout from '../layouts/Layout';
import Header from '../layouts/Header';
import SideBar from '../layouts/SideBar';
import '../assets/css/center.css';
import '../assets/css/animate.min.css';
import RehabServiceImpl from '../service/impl/RehabServiceImpl';
import ActionComponent from './components/ActionComponent';
import PrescriptionPopupComponent from './components/prescription/PrescriptionPopupComponent';
import PaginationComponent from './components/PaginationComponent';
import TargetPerson from '../domain/TargetPerson';
import UserDetailInfo from '../../../domain/user/UserDetailInfo';
import Team from '../../../domain/team/Team';

const menuTitle: string = '* 운동처방내역 관리';
const title: string = '운동처방';

const rehabService = new RehabServiceImpl();

const filters = () => {
    return (
        <div className="center-bnt-right2">
            <div className="center-select-box"></div>
        </div>
    );
};

const popups = () => {
    return (
        <>
            <div id="center-popup-wrap"></div>
        </>
    );
};

export default function PrescriptionComponent(props: any) {
    let path = props.location.pathname;
    let centerKey = props.match.params.centerId;

    const [targetPersons, setTargetPersons] = useState<TargetPerson[]>([]);

    const popupHandler = (e: any) => {
        let id: string = e.target.id;
        let dataIdx = e.target.value;
        let target: TargetPerson = targetPersons[dataIdx];

        if (id === 'prescript') {
            let centerPopupWrap = document.getElementById('center-popup-wrap');
            if (centerPopupWrap) centerPopupWrap.style.display = 'block';

            ReactDOM.render(
                <PrescriptionPopupComponent selectedTarget={target} closingHandler={closingPopupHandler}></PrescriptionPopupComponent>,
                document.getElementById('center-popup-wrap'),
            );
        } else if (id === 'checkResult') {
        }
    };

    const closingPopupHandler = () => {
        let pagination = { size: displaySize, page: currPage };
        rehabService.getTargetForPrescription(pagination).then((targetPersons: { data: TargetPerson[]; totalSize: number }) => {
            setTargetPersons(targetPersons.data);
            setTotalSize(targetPersons.totalSize);
        });
    };

    const contents = (list: TargetPerson[] | undefined) => {
        let table;
        let page = currPage ? currPage : 0;
        let pageSize = displaySize ? displaySize : 0;
        let rowNum = page * pageSize;

        if (typeof list != 'undefined' && list.length > 0) {
            table = list.map((targetPerson, idx) => {
                let reservater: UserDetailInfo | undefined = targetPerson.reservater;
                let infraInfo: any = targetPerson.infra;
                let team: Team | undefined = targetPerson.team;
                let genderCode: any = reservater?.genderCode;
                let regionCode: any = reservater?.regionCode;
                let sportCode: any = reservater?.sportCode;
                let btnElem;

                if (targetPerson.targetState != '8004') {
                    btnElem = (
                        <button id="prescript" className="center-button-yes" onClick={popupHandler} value={idx}>
                            <i className="fas fa-pen"></i> 운동처방 작성
                        </button>
                    );
                } else {
                    btnElem = (
                        <button id="checkPrescription" className="center-button-no" onClick={popupHandler} value={idx}>
                            <i className="fas fa-search-plus"></i> 운동처방 보기
                        </button>
                    );
                }

                return (
                    <tr key={idx}>
                        {/* <td>
                            <input type="checkbox" />{' '}
                        </td> */}
                        <td>{rowNum + idx + 1}</td>
                        <td>{reservater?.name}</td>
                        <td>{genderCode.name}</td>
                        <td>{reservater?.birthdate}</td>
                        <td>
                            {team?.name}/{regionCode.name}
                        </td>
                        <td>{sportCode.name}</td>
                        <td>{btnElem}</td>
                        <td>{targetPerson.rehabCnt} 회차</td>
                        <td>{targetPerson.regDate}</td>
                        <td>{targetPerson.modDate}</td>
                        <td>{infraInfo.regionCode.name}</td>
                    </tr>
                );
            });
        } else {
            table = (
                <tr>
                    <td colSpan={11}>처방 대상자가 존재하지 않습니다.</td>
                </tr>
            );
        }

        return (
            <table className="center-list-table" cellPadding="0" cellSpacing="0">
                <thead>
                    <tr>
                        {/* <td>
                            <input type="checkbox" />
                        </td> */}
                        <td>No.</td>
                        <td>이름</td>
                        <td>성별</td>
                        <td>생년월일</td>
                        <td>소속팀</td>
                        <td>운동종목</td>
                        <td>운동처방</td>
                        <td>처방회차</td>
                        <td>최초 등록일</td>
                        <td>마지막 저장일</td>
                        <td>처방진행 센터</td>
                    </tr>
                </thead>
                <tbody>{table}</tbody>
            </table>
        );
    };

    useEffect(() => {
        let pagination = { size: 10, page: 0 };
        rehabService.getTargetForPrescription(pagination).then((targetPersons: { data: TargetPerson[]; totalSize: number }) => {
            setTargetPersons(targetPersons.data);
            setTotalSize(targetPersons.totalSize);
        });
    }, []);

    const [totalSize, setTotalSize] = useState(1);
    const [displaySize, setDisplaySize] = useState(10);
    const [currPage, setCurrPage] = useState(0);

    const pagingHandler = (pagination: { size: number; page: number }) => {
        setCurrPage(pagination.page);
        setDisplaySize(pagination.size);
        rehabService.getTargetForPrescription(pagination).then((targetPersons: { data: TargetPerson[]; totalSize: number }) => {
            setTargetPersons(targetPersons.data);
            setTotalSize(targetPersons.totalSize);
        });
    };

    let prescriptionList = (
        <Layout
            header={<Header></Header>}
            sideBar={<SideBar name={path}></SideBar>}
            path={path}
            naviTitle={title}
            menuTitle={menuTitle}
            filter={filters()}
            pagination={PaginationComponent(pagingHandler, totalSize, currPage, displaySize)}
            popups={popups()}
        >
            {contents(targetPersons)}
        </Layout>
    );
    return prescriptionList;
}
