import { useEffect, useState } from 'react';
import BoardServiceImpl from '../../service/board/impl/BoardServiceImpl';
import Board from '../../domain/board/Board';

const boardService = new BoardServiceImpl();

function useBoardInfoOfTeam(teamNo: string) {
    const [boardsOfTeam, setBoardsOfTeam] = useState<Board[]>();

    useEffect(() => {
        boardService.getTeamBoardInfos(teamNo)
        .then((boardsOfTeam: Board[]) => {
            setBoardsOfTeam(boardsOfTeam);
        });
    }, []);

    return { boardsOfTeam };
}

export default useBoardInfoOfTeam;