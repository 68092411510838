import { useEffect, useState } from "react";
import UserFavoritesInfra from '../../domain/favorities/UserFavoritesInfra';
import { FAVORITES_INFRA_SERVICE } from '../../service/index';

function useFavoritesInfraRegiste(userFavoritesInfra?: UserFavoritesInfra) {
    const [registedUserFavoritesInfra, setRegistedUserFavoritesInfra] = useState<UserFavoritesInfra>();

    useEffect(() => {
        if(userFavoritesInfra) {
            FAVORITES_INFRA_SERVICE.registeFavoritesInfra(userFavoritesInfra)
            .then((registedUserFavoritesInfra: UserFavoritesInfra) => {
                setRegistedUserFavoritesInfra(registedUserFavoritesInfra);
            })
            .catch(err => {
                console.error(err);
            });
        }
    }, [userFavoritesInfra]);

    return {
        registedUserFavoritesInfra
    }
}

export default useFavoritesInfraRegiste;