import React, { useEffect, useState } from 'react';
import SpecialPhysical from '../../../../../domain/recording/SpecialPhysical';
import { truncateSync } from 'fs';
import RoleSearchCondition from '../../../../../../../domain/code/param/RoleSearchCondition';
interface IProps {
    handler: (evt: any) => void;
    ocr: string;
    status?: SpecialPhysical;
}

export default function TreadMillTest(props: IProps) {
    const resourceAPIURL = process.env.REACT_APP_RESOURCE_API_HOST;
    const [treadMillTest, setTreadMillTest] = useState<any>(new SpecialPhysical().treadMillTest);

    useEffect(() => {
        if (props.status) setTreadMillTest(props.status.treadMillTest);
    }, [props.status]);

    let uploadImage = treadMillTest.uploadTMFile;
    // OCR 이차원 배열로 변경
    if (props.ocr) {
        let matrix = makeOcrMatrix(JSON.parse(props.ocr));
        // vo2, mets, ve, vt;
        let vo2 = contractValue(['vo2/kg', 'v02/kg'], matrix);
        let mets = contractValue(['mets'], matrix);
        let ve = contractValue(['ve'], matrix);
        let vt = contractValue(['vt'], matrix);

        treadMillTest.vo2 = vo2 ? vo2 : '';
        treadMillTest.mets = mets ? mets : '';
        treadMillTest.ve = ve ? ve : '';
        treadMillTest.vt = vt ? vt : '';

        let evt = {
            target: {
                name: 'ocrTreadMillTest',
                value: treadMillTest,
            },
        };

        props.handler(evt);
    }

    let imageElem = <></>;
    if (typeof uploadImage != 'undefined' && uploadImage != '')
        imageElem = <img id="uploadedImages" src={resourceAPIURL + uploadImage} width="580" />;
    return (
        <>
            <div className="center-popup-title">
                <h1 className="center">가스분석기(운동부하검사)</h1>
            </div>

            {/* <div className="center-special-physical-btn">
                <label htmlFor={'uploadedTMFile'} className="center-label-up">
                    검사결과적용
                </label>
                <input
                    type="file"
                    id="uploadedTMFile"
                    name="treadMillTest"
                    onChange={props.handler}
                    style={{ display: 'none' }}
                    accept=".gif, .jpg, .png"
                />
            </div> */}

            <h2 className="center2">VO2/KG</h2>
            <div className="center-bg-gray flex">
                <input
                    className="center-input-gray center-w600"
                    type="text"
                    id="vo2"
                    value={treadMillTest.vo2}
                    onChange={props.handler}
                    name="treadMillTest"
                />
            </div>

            <h2 className="center2">METS</h2>
            <div className="center-bg-gray flex">
                <input
                    className="center-input-gray center-w600"
                    type="text"
                    id="mets"
                    value={treadMillTest.mets}
                    onChange={props.handler}
                    name="treadMillTest"
                />
            </div>

            <h2 className="center2">RQ</h2>
            <div className="center-bg-gray flex">
                <input
                    className="center-input-gray center-w600"
                    type="text"
                    id="rq"
                    value={treadMillTest.rq}
                    onChange={props.handler}
                    name="treadMillTest"
                />
            </div>

            <h2 className="center2">VE(L/min)</h2>
            <div className="center-bg-gray flex">
                <input
                    className="center-input-gray center-w600"
                    type="text"
                    id="ve"
                    value={treadMillTest.ve}
                    onChange={props.handler}
                    name="treadMillTest"
                />
            </div>

            <h2 className="center2">VT(btps)</h2>
            <div className="center-bg-gray flex">
                <input
                    className="center-input-gray center-w600"
                    type="text"
                    id="vt"
                    value={treadMillTest.vt}
                    onChange={props.handler}
                    name="treadMillTest"
                />
            </div>
            <div id="uploadedTMImages" className="center-mauto">
                {imageElem}
            </div>
        </>
    );
}

function checkOcr(ocrStr: any) {
    let ocr = JSON.parse(ocrStr);

    let result = '';

    for (let i = 0; i < ocr?.length; i++) {
        let elem = ocr[i];
        result += 'zoneNumber:' + elem.zoneNumber + ', Words: [';
        for (let x = 0; x < elem.words.length; x++) {
            let word = elem.words[x];
            result += word._value + '(x:' + word._bounds.x + ', y:' + word._bounds.y + ')';
        }
        result += ']\n';
    }
}

function makeOcrMatrix(ocr: any) {
    let maxX = 0;
    let maxY = 0;
    let words: { [k: string]: string } = {};
    let row: [string[]];
    for (let i = 0; i < ocr?.length; i++) {
        let elem = ocr[i];
        for (let j = 0; j < elem.words.length; j++) {
            let word = elem.words[j];
            if (maxX < word._bounds.x) maxX = Math.floor(word._bounds.x / 30);
            if (maxY < word._bounds.y) maxY = Math.floor(word._bounds.y / 20);
            words[Math.floor(word._bounds.x / 30) + '_' + Math.floor(word._bounds.y / 20)] = word._value;
        }
    }
    let result = '';
    let table = new Array();
    for (let y = 0; y < maxY; y++) {
        let row = new Array();
        for (let x = 0; x < maxX; x++) {
            let val = words[x + '_' + y];
            if (val) {
                result += val + '_';
                row.push(val);
            } /*  else {
                row.push(' ');
            } */
        }
        if (row.length > 0) table.push(row);
        result += '\n';
    }
    //console.log(table);
    //console.log(result);
    return table;
}

function contractValue(seeds: string[], matrix: any) {
    let contractedVal = '';
    if (matrix) {
        let targetRows = new Array();
        let size = matrix.length;
        let seedSize = seeds.length;

        // contract targetRows
        for (let i = 0; i < size; i++) {
            let source = matrix[i][0];
            let row = matrix[i];
            if (source) {
                source = source.toLowerCase();
                for (let j = 0; j < seedSize; j++) {
                    let seed = seeds[j];
                    if (source.indexOf(seed) != -1) {
                        targetRows.push(row);
                        if (row.length > 2) {
                            if (seed === 'mets') contractedVal = row[1];
                            else contractedVal = row[2];
                        } else if (row.length < 2) {
                            contractedVal = row[1];
                        }
                    }
                }
            }
        }
        //console.log(targetRows);
        //console.log(contractedVal);
        return contractedVal;
    }
}
