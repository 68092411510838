import React, { useEffect, useState } from 'react';
import RadioSelect from '../../component/search/filter/RadioSelect';
import Code from '../../domain/code/Code';
import { CodeType } from '../../domain/code/CodeType';
import MultiSelectByInfraCategory from '../../component/search/filter/MultiSelectByInfraCategory';
import MultiSelect from '../../component/search/filter/MultiSelect';
import MiniSearchBar from '../../component/search/MiniSearchBar';
import useParentInfraCategoriesRetrieve from '../../hook/infra/useParentInfraCategoriesRetrieve';
import useCodeRetrieve from '../../hook/code/useCodeRetrieve';
import CommonSearchBar from '../../component/search/CommonSearchBar';
import useChildCodeRetrieve from '../../hook/code/useChildCodeRetrieve';

interface SearchConditionContainerProps {
    setSearchType: (no: number) => void;
    receivedSearchWord?: string;
}

function getTmpSearchTypeCodes() {
    const sportInfraCode = new Code();
    sportInfraCode.codeId = 4001;
    sportInfraCode.name = '시설';
    const teamInfraCode = new Code();
    teamInfraCode.codeId = 4002;
    teamInfraCode.name = '팀';

    const codes: Code[] = [sportInfraCode, teamInfraCode];

    return codes;
}

function SearchConditionContainer(props: SearchConditionContainerProps) {
    const [searchType, setSearchType] = useState(4001);
    const {parentInfraCategories} = useParentInfraCategoriesRetrieve();
    const regionCodes = useChildCodeRetrieve(20001);
    const {codes: classificationCodes} = useCodeRetrieve(CodeType.CLASSIFICATION);
    const {codes: sportCodes} = useCodeRetrieve(CodeType.SPORT);
    
    useEffect(() => {
        props.setSearchType(searchType);
    }, [searchType]);
    
    let codes = getTmpSearchTypeCodes();

    return (
        <>
            <MiniSearchBar searchWord={props.receivedSearchWord} placeholder="시설 혹은 팀 검색하세용."></MiniSearchBar>
            {/* <CommonSearchBar searchWord={props.receivedSearchWord}></CommonSearchBar> */}
            <RadioSelect initChoiceId={4001} codeType={undefined} setType={setSearchType} title={'유형'} codes={codes}></RadioSelect>
            {
                parentInfraCategories && searchType == 4001?
                    <MultiSelectByInfraCategory codeType={CodeType.PARENT_INFRA_CATEGORY} title="시설 분류" infraCategories={parentInfraCategories}></MultiSelectByInfraCategory>
                    : 
                    <></>
            }
            {
                searchType == 4002 && sportCodes?
                    <MultiSelect codeType={CodeType.SPORT} title="종목" codes={sportCodes}></MultiSelect>
                    :
                    <></>
            }
            {
                regionCodes?
                    <MultiSelect codeType={CodeType.REGION} title="지역" codes={regionCodes}></MultiSelect>
                    :
                    <></>
            }
            {
                classificationCodes?
                    <MultiSelect codeType={CodeType.CLASSIFICATION} title="소속" codes={classificationCodes}></MultiSelect>
                    :
                    <></>
            }
        </>
    )
}

export default SearchConditionContainer;