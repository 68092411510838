import React from 'react';
import Infra from '../../../../../../domain/infra/Infra';

interface SportInfraDetailInfoTabProps {
    infra: Infra;
}

function SportInfraDetailInfoTab(props: SportInfraDetailInfoTabProps) {
    console.log(props.infra.getDescription(props.infra.etcDescription));
    var facDesc = props.infra.getDescription(props.infra.facilityDescription);
    var equDesc = props.infra.getDescription(props.infra.equipmentDescription);
    var etcDesc = props.infra.getDescription(props.infra.etcDescription);
    var useRule = props.infra.getDescription(props.infra.useRuleDescription);
    var refundDesc = props.infra.getDescription(props.infra.refundPolicyDescription);

    return (
        <>
            <div className="school">
                주소 : {props.infra.address}
                <br />
                홈페이지 :{' '}
                <a className="blue-line" href={props.infra.homepageUrl}>
                    {props.infra.homepageUrl}
                </a>
                <br />
                전화 : <span className="blue-line">{props.infra.phoneNumber}</span>
            </div>

            <div className="home-wrap">
                <div className="home-title">
                    <span>설명</span>
                </div>
                <div className="home-box clear">
                    <p className="output-txt" dangerouslySetInnerHTML={{ __html: facDesc }}></p>
                </div>
            </div>

            <div className="home-wrap">
                <div className="home-title">
                    <span>부속장비</span>
                </div>
                <div className="home-box clear">
                    <p className="output-txt" dangerouslySetInnerHTML={{ __html: equDesc }}></p>
                </div>
            </div>

            <div className="home-wrap">
                <div className="home-title">
                    <span>기타편의시설</span>
                </div>
                <div className="home-box clear">
                    <p className="output-txt" dangerouslySetInnerHTML={{ __html: etcDesc }}></p>
                </div>
            </div>

            <div className="home-wrap">
                <div className="home-title">
                    <span>시설 이용 규칙</span>
                </div>
                <div className="home-box clear">
                    <p className="output-txt" dangerouslySetInnerHTML={{ __html: useRule }}></p>
                </div>
            </div>

            <div className="home-wrap">
                <div className="home-title">
                    <span>환불 규칙</span>
                </div>
                <div className="home-box clear">
                    <p className="output-txt" dangerouslySetInnerHTML={{ __html: refundDesc }}></p>
                </div>
            </div>
        </>
    );
}

export default SportInfraDetailInfoTab;
