import React, { Dispatch, useEffect, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import PhysicalStatus from '../../domain/recording/PhysicalStatus';
import RehabTargetHistory from '../../domain/RehabTargetHistory';
import RehabHistoryServiceImpl from '../../service/impl/RehabHistoryServiceImpl';

interface IProps {
    status?: PhysicalStatus;
    history: RehabTargetHistory;
}

const rehabHistoryService = new RehabHistoryServiceImpl();
export default function MngPhysicalStatusComponent(props: IProps) {
    const [physicalStatus, setPhysicalStatus] = useState<PhysicalStatus>(new PhysicalStatus());

    const inputChangeHandler = (evt: any) => {
        let target = evt.target.id;
        let val = evt.target.value;
        if (isNaN(val)) {
            confirmAlert({
                title: '경고',
                message: '숫자만 입력 가능 합니다.',
                buttons: [{ label: '확인', onClick: () => {} }],
            });
        } else {
            setPhysicalStatus((prevState) => {
                return { ...prevState, [target]: val };
            });
        }
    };

    useEffect(() => {
        if (props.status) setPhysicalStatus(props.status);
    }, [props.status]);

    const storeHandler = (evt: any) => {
        let history: RehabTargetHistory = props.history;
        history.physicalStatus = JSON.stringify(physicalStatus);
        rehabHistoryService.managePhysicalStatusForTarget(history).then((result: RehabTargetHistory) => {
            confirmAlert({
                title: '저장 성공',
                message: '신체정보를 저장 하였습니다.',
                buttons: [{ label: '확인', onClick: () => {} }],
            });
        });
    };

    return (
        <>
            <div className="center-popup-title">
                <h1 className="center">
                    <i className="fa fa-check"></i> 신체정보
                </h1>

                <div>
                    <button id="complete" className="center-button-complete" onClick={storeHandler}>
                        저 장 <i className="fas fa-file-download"></i>
                    </button>
                </div>
            </div>

            <div id="center-popup-list" className="center-popup-list-tab">
                <div className="center-popup-list-box">
                    <div className="flex-basic">
                        <div className="center-half">
                            <h2 className="center2">신장</h2>
                            <div className="center-bg-gray flex">
                                <div className="center-bg-blue">&nbsp;</div>
                                <input
                                    id="height"
                                    className="center-input-gray"
                                    type="text"
                                    value={physicalStatus.height}
                                    onChange={inputChangeHandler}
                                    placeholder="측정자의 신장을 입력해 주세요"
                                />
                                <label className="center-label">cm</label>
                            </div>
                        </div>
                        <div className="center-half">
                            <h2 className="center2">체중</h2>
                            <div className="center-bg-gray flex">
                                <div className="center-bg-blue">&nbsp;</div>
                                <input
                                    id="weight"
                                    className="center-input-gray"
                                    type="text"
                                    value={physicalStatus.weight}
                                    onChange={inputChangeHandler}
                                    placeholder="측정자의 체중을 입력해 주세요"
                                />
                                <label className="center-label">kg</label>
                            </div>
                        </div>
                    </div>
                    <div className="flex-basic">
                        <div className="center-half">
                            <h2 className="center2">혈압</h2>
                            <div className="center-bg-gray flex">
                                <div className="center-bg-blue">최고</div>
                                <input
                                    id="hBloodPressure"
                                    className="center-input-gray center-w100"
                                    type="text"
                                    value={physicalStatus.hBloodPressure}
                                    onChange={inputChangeHandler}
                                    placeholder="측정자의 최고혈압을 입력해 주세요"
                                />
                                <label className="center-label">mmHg</label>
                            </div>
                        </div>
                        <div className="center-half">
                            <h2 className="center2">&nbsp;</h2>
                            <div className="center-bg-gray flex">
                                <div className="center-bg-blue">최저</div>
                                <input
                                    id="lBloodPressure"
                                    className="center-input-gray center-w100"
                                    type="text"
                                    value={physicalStatus.lBloodPressure}
                                    onChange={inputChangeHandler}
                                    placeholder="측정자의 최저혈압을 입력해 주세요"
                                />
                                <label className="center-label">mmHg</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
