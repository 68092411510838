import { useEffect, useState } from 'react';
import Infra from '../../../domain/infra/Infra';
import User from '../../../domain/user/User';
import useFavoritesInfraRetrieve from '../../../hook/favorites/useFavoritesInfraRetrieve';
import UserFavoritesInfra from '../../../domain/favorities/UserFavoritesInfra';
import useFavoritesInfraDelete from '../../../hook/favorites/useFavoritesInfraDelete';
import useFavoritesInfraRegiste from '../../../hook/favorites/useFavoritesInfraRegiste';
function useInfraFavoritesHook(user?: User, infra?: Infra) {
    const {userFavoritesInfra: immediatlyUserFavoritesInfra
            , setUserFavoritesInfra: setImmediatlyUserFavoritesInfra} = useFavoritesInfraRetrieve(user?.userNo, infra?.infraNo);
    const [registedUserFavoritesInfra, setRegistedUserFavoritesInfra] = useState<UserFavoritesInfra>();
    const [deletedUserFavoritesInfra, setDeletedUserFavoritesInfra] = useState<UserFavoritesInfra>();    

    const favoritesRegisteEventHandler = () => {
        if(user && infra) {
            if(user.userNo && infra.infraNo) {
                const registedUserFavoritesInfra = UserFavoritesInfra.getUserFavoritesInfra(user.userNo, infra.infraNo);
                setRegistedUserFavoritesInfra(registedUserFavoritesInfra);
                setImmediatlyUserFavoritesInfra(registedUserFavoritesInfra);
            }
        }
    }

    useFavoritesInfraRegiste(registedUserFavoritesInfra);

    const favoritesDeleteEventHandler = () => {
        if(user && infra) {
            if(user.userNo && infra.infraNo) {
                const deletedUserFavoritesInfra = UserFavoritesInfra.getUserFavoritesInfra(user.userNo, infra.infraNo);
                setDeletedUserFavoritesInfra(deletedUserFavoritesInfra);
                setImmediatlyUserFavoritesInfra(undefined);
            }
        }
    }

    useFavoritesInfraDelete(deletedUserFavoritesInfra);

    return {
        immediatlyUserFavoritesInfra,
        favoritesRegisteEventHandler,
        favoritesDeleteEventHandler
    }
}

export default useInfraFavoritesHook;