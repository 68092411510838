import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { RootState } from '../../moldules/index';
import { unRegisteUserSessionAction } from '../../moldules/UserSession';

function LoginState() {
    const dispatch = useDispatch();
    const userState = useSelector((state: RootState) => state.userSessionReducer);
    const history = useHistory();

    const logoutEvent = () => {
        dispatch(unRegisteUserSessionAction());
        window.location.replace('/');
    };

    const myPageLinkHandler = (evt: any) => {
        evt.preventDefault();
        let link = '/mypage/user/favorities';
        if (userState.hasTeamAuth) {
            link = '/mypage/team-admin/player-list';
        }
        //history.push(link);
        window.location.replace(link);
    };

    const authPageLink = () => {
        let jsx;

        if (userState.isLogin && userState.hasSystemAuth) {
            jsx = <a href="/admin">{userState.user.name}&nbsp;|&nbsp;관리페이지&nbsp;</a>;
        } else if (userState.isLogin && userState.hasMunicipalityAuth) {
            jsx = <a href="/admin/infra/list">{userState.user.name}&nbsp;|&nbsp;지자체관리페이지&nbsp;</a>;
        } else if (userState.isLogin && (userState.hasCenterOperAuth || userState.hasCenterRecorderAuth)) {
            jsx = <a href="/rehab/reserved">{userState.user.name}&nbsp;|&nbsp;재활센터&nbsp;</a>;
        } else {
            jsx = (
                <Link to="#" className="qm_myinfo" onClick={myPageLinkHandler}>
                    {userState.user.name}&nbsp;|&nbsp;마이페이지
                </Link>
            );
        }

        return jsx;
    };

    return (
        <>
            {/* <div className="nav-top"> */}
            {userState.isLogin ? (
                <>
                    <ul className="quick-menu">
                        <li>
                            <a onClick={() => logoutEvent()} className="qm_logout" style={{ cursor: 'pointer' }}>
                                로그아웃
                            </a>
                        </li>
                        <li>{authPageLink()}</li>
                    </ul>
                </>
            ) : (
                <>
                    <ul className="quick-menu">
                        <li>
                            <Link to="/signup/agreement" className="qm_join">
                                {/* <img src="/assets/image/icon-join.png" alt="join" /> */}
                                회원가입
                            </Link>
                        </li>
                        <li>
                            <Link to="/login" className="qm_login">
                                {/* <img src="/assets/image/icon-login.png" alt="login" /> */}
                                로그인
                            </Link>
                        </li>
                    </ul>
                </>
            )}
            {/* </div> */}
        </>
    );
}

export default LoginState;
