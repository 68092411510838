import React, { useEffect } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import useCalendarHook from './hook/useCalendarHook';
import DateUtils from '../../utils/DateUtils';
interface calendar{
    width?: string;
    height?: string;
    onDateChange: (date: any) => void;
    receivedDate?: string;
}

function CalendarComponent(props: calendar) {
    const {date, setDate} = useCalendarHook();
    useEffect(() => {
        if(props.receivedDate) {
            setDate(new Date(props.receivedDate));
        }
    }, [props.receivedDate]);
    return (
        <>
            <Calendar
                calendarType = {"US"}
                onChange={(date: any) => {
                    let dateInstance = new Date(date);
                    const dateFormat = DateUtils.getDateForm(dateInstance);
                    // const dateFormat = dateInstance.getFullYear() + "-" + (dateInstance.getMonth() + 1) + "." + dateInstance.getDate();
                    props.onDateChange(dateFormat)
                    setDate(date);
                }}
                value={date}
            />
        </>
    )
}

export default CalendarComponent;