import APIServerConfig from '../../../config/APIServerConfig';
import VisitedTeamInfo from '../../../domain/visitedInfo/VisitedTeamInfo';
import AbstractService from '../../AbstractService';
import VisitedTeamInfoService from '../VisitedTeamInfoService';
import Paging from '../../../domain/common/Paging';
import VisitedTeamInfoSearchCondition from '../../../domain/visitedInfo/pagam/VisitedTeamInfoSearchCondition';

import axios from 'axios';

class VisitedTeamInfoServiceImpl extends AbstractService implements VisitedTeamInfoService {
    getVisitedTeamInfo(visitedTeamInfoNo: string): Promise<VisitedTeamInfo> {
        return new Promise((resolve, reject) => {
            APIServerConfig.recordAPIAxios.get(`/visited-team-info/${visitedTeamInfoNo}`).then((response) => {
                let visitedTeamInfo = this.jsonConvert.deserializeObject(response.data, VisitedTeamInfo);
                resolve(visitedTeamInfo);
            });
        });
    }

    visitedTeamInfoRegiste(visitedTeamInfo: VisitedTeamInfo): Promise<VisitedTeamInfo> {
        return new Promise((resolve, reject) => {
            APIServerConfig.recordAPIAxios
                .post(`/visited-team-info`, JSON.stringify(visitedTeamInfo))
                .then((response) => {
                    const registedVisitedTeamInfo = this.jsonConvert.deserializeObject(response.data, VisitedTeamInfo);
                    resolve(registedVisitedTeamInfo);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    visitedTeamInfoUpdate(visitedTeamInfo: VisitedTeamInfo): Promise<VisitedTeamInfo> {
        return new Promise((resolve, reject) => {
            APIServerConfig.recordAPIAxios
                .put(`/visited-team-info`, JSON.stringify(visitedTeamInfo))
                .then((response) => {
                    const updatedVisitedTeamInfo = this.jsonConvert.deserializeObject(response.data, VisitedTeamInfo);
                    resolve(updatedVisitedTeamInfo);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    deleteVisitedTeamInfo(visitedTeamInfoNo: string): Promise<VisitedTeamInfo> {
        return new Promise((resolve, reject) => {
            APIServerConfig.recordAPIAxios
                .delete(`/visited-team-info/${visitedTeamInfoNo}`)
                .then((response) => {
                    const visitedTeamInfo: VisitedTeamInfo = this.jsonConvert.deserializeObject(response.data, VisitedTeamInfo);
                    resolve(visitedTeamInfo);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    getVisitedTeamInfos(visitedTeamInfoSearchCondition: VisitedTeamInfoSearchCondition, paging: Paging): Promise<VisitedTeamInfo[]> {
        return new Promise((resolve, reject) => {
            APIServerConfig.recordAPIAxios
                .get(`/visited-team-info`, {
                    params: Object.assign(visitedTeamInfoSearchCondition, paging),
                })
                .then((response) => {
                    const visitedTeamInfos = this.jsonConvert.deserializeArray(response.data, VisitedTeamInfo);
                    resolve(visitedTeamInfos);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    getVisitedTeamHistoryByTeamNo(visitedTeamInfoSearchCondition: VisitedTeamInfoSearchCondition): Promise<VisitedTeamInfo[]> {
        return new Promise((resolve, reject) => {
            APIServerConfig.recordAPIAxios
                .get(`/visited-team-info/history`, {
                    params: Object.assign(visitedTeamInfoSearchCondition),
                })
                .then((response) => {
                    const visitedTeamInfos = this.jsonConvert.deserializeArray(response.data, VisitedTeamInfo);
                    resolve(visitedTeamInfos);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    getvisitedTeamInfosCnt(visitedTeamInfoSearchCondition: VisitedTeamInfoSearchCondition): Promise<number> {
        return new Promise((resolve, reject) => {
            APIServerConfig.recordAPIAxios
                .get(`/visited-team-info/cnt`, {
                    params: visitedTeamInfoSearchCondition,
                })
                .then((response) => {
                    const visitedTeamInfosCnt: number = response.data;
                    resolve(visitedTeamInfosCnt);
                });
        });
    }

    getGroupingVisitedTeamInfos(visitedTeamInfoSearchCondition: VisitedTeamInfoSearchCondition, paging: Paging): Promise<VisitedTeamInfo[]> {
        return new Promise((resolve, reject) => {
            APIServerConfig.recordAPIAxios
                .get(`/visited-team-info/grouped`, {
                    params: Object.assign(visitedTeamInfoSearchCondition, paging),
                })
                .then((response) => {
                    const visitedTeamInfos = this.jsonConvert.deserializeArray(response.data, VisitedTeamInfo);
                    resolve(visitedTeamInfos);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    getGroupingVisitedTeamInfosCnt(visitedTeamInfoSearchCondition: VisitedTeamInfoSearchCondition): Promise<number> {
        return new Promise((resolve, reject) => {
            APIServerConfig.recordAPIAxios
                .get(`/visited-team-info/grouped/cnt`, {
                    params: visitedTeamInfoSearchCondition,
                })
                .then((response) => {
                    const visitedTeamInfosCnt: number = response.data;
                    resolve(visitedTeamInfosCnt);
                });
        });
    }

    downloadExcelDataForVisitedTeamInfo(visitedTeamInfoSearchCondition: VisitedTeamInfoSearchCondition): void {
        axios({
            method: 'POST',
            url: APIServerConfig.resourceAPIURL + '/visited-team-info/excel_data',
            responseType: 'blob',
            headers: {
                'Content-Type': 'application/json',
            },
            params: visitedTeamInfoSearchCondition,
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));
            const contentDisposition = response.headers['content-disposition'];

            let fileName = 'unknown';

            if (contentDisposition) {
                const [fileNameMatch] = contentDisposition.split(':').filter((str: any) => str.includes('filename'));
                if (fileNameMatch) [, fileName] = fileNameMatch.split('=');
            }

            const link = document.createElement('a');

            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);

            link.click();
        });
    }
}

export default VisitedTeamInfoServiceImpl;
