import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject("loginToken")
class LoginToken {
    @JsonProperty("accessToken")
    accessToken?: string;
    @JsonProperty("tokenType")
    tokenType?: string;
}

export default LoginToken;