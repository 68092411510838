import SignUpHeader from "../layout/SignUpHeader";
import { Link, Redirect, useLocation } from 'react-router-dom';
import { useEffect, useState } from "react";
import usePasswordValidation from '../../../hook/validation/usePasswordValidation';
import useConfirmedPasswordValidation from '../../../hook/validation/useConfirmedPasswordValidation';
import { USER_SERVICE } from '../../../service/index';

function SearchPwPage() {
    const [mobileCheckSessionCheck, setMobileCheckSessionCheck] = useState<Boolean>();
    const [password, setPassword] = useState<string>();
    const [confirmedPassword, setConfirmedPassword] = useState<string>();
    const location: any = useLocation();

    const passwordValidation = usePasswordValidation(password);
    const confirmedPasswordValidation = useConfirmedPasswordValidation(confirmedPassword, password);

    useEffect(() => {
        USER_SERVICE.getMobileCheckSessionCheck()
        .then(response => {
            if(response) {
                setMobileCheckSessionCheck(response);
            }
        })
    }, []);

    return (
        <>
            <SignUpHeader title="아이디 찾기"></SignUpHeader>
            <div className="mgt10"></div>
            {
                mobileCheckSessionCheck ? 
                <>
                    <div className="content-wrap">
                        <div className="signup-content-wrap">
                            <h2>비밀번호 찾기</h2>
                            <div className="use-confirm-wrap-title">
                                <p>* 회원님의 비밀번호를 찾기를 위해 회원님의 아이디를 입력 바랍니다.</p>
                                <p>* 아이디를 잊어버리셨나요? <Link to="/idfinder/user-checker">아이디 찾기</Link></p>
                            </div>
                            <div className="mgt30"></div>
                            {
                                location.state.id == 'null' ? 
                                <>
                                    <h2>회원가입이 되지 않으셨습니다.</h2>
                                </>
                                : 
                                <>
                                    <h2>비밀번호 변경</h2>
                                    <h5>{location.state.id}</h5>
                                    <input className="basic-gray signup-input" placeholder="비밀번호를 입력하세요."
                                        onBlur={(event) => {
                                            setPassword(event.currentTarget.value);
                                        }}
                                    ></input>
                                    {
                                        passwordValidation == false ? 
                                        <>
                                            <span className="dan-red">!</span>8~16자 영문 대 소문자, 숫자, 특수문자를 사용하세요.
                                        </>
                                        :
                                        <></>
                                    }
                                    <div className="mgt10"></div>
                                    <input className="basic-gray signup-input" placeholder="비밀번호를 입력하세요."
                                        onBlur={(event) => {
                                            setConfirmedPassword(event.currentTarget.value);
                                        }}
                                    ></input>
                                    {
                                        confirmedPasswordValidation == false ? 
                                        <>
                                            <span className="dan-red">!</span>비밀번호가 일치하지 않습니다.
                                        </>
                                        :
                                        <>
                                        </>
                                    }
                                    <div className="mgt10"></div>
                                    <button className="center-button-login"
                                        onClick={() => {
                                            if(password && confirmedPassword) {
                                                if(passwordValidation && confirmedPasswordValidation) {
                                                    USER_SERVICE.updateUserPwForMobileCheck(location.state.id, password)
                                                    .then((bool) => {
                                                        if(bool) {
                                                            alert("정상적으로 변경되었습니다.");
                                                            <Redirect to="/login"></Redirect>
                                                        } else {
                                                            alert("수정 오류입니다. 관리자에게 문의해주세요");
                                                            <Redirect to="/"></Redirect>
                                                        }
                                                    })
                                                }
                                            }
                                        }}
                                    >변경하기</button>
                                </>
                            }
                            <div className="mgt30"></div>
                        </div>
                    </div>
                </>
                :
                <h5>비정상적인 접근입니다.</h5>
            }
        </>
    )
}

export default SearchPwPage;