import React from 'react';
import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('opinions')
export default class Opinions {
    @JsonProperty('opinion_1')
    opinion_1: string = '';
    @JsonProperty('opinion_2')
    opinion_2: string = '';
    @JsonProperty('opinion_3')
    opinion_3: string = '';
    @JsonProperty('etc')
    etc: string = '';
}
