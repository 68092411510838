import { useEffect, useState } from 'react';
import Reservation from '../../domain/reservation/Reservation';
import Schedule from '../../domain/schedule/Schedule';
import TeamScheduleServiceImpl from '../../service/schedule/impl/TeamScheduleServiceImpl';
import Infra from '../../domain/infra/Infra';
const scheduleService = new TeamScheduleServiceImpl();
function useTeamSchedule(teamNo: string, scheduleNo: string) {
    const [teamSchedule, setTeamSchedule] = useState<Schedule>();
    const [reservation, setReservation] = useState<Reservation>();
    const [reservationParentInfra, setReservationParentInfra] = useState<Infra>();
    const [reservationInfra, setReservationInfra] = useState<Infra>();

    useEffect(() => {
        scheduleService.getSchedule(teamNo, scheduleNo)
            .then((schedule: Schedule) => {
                setTeamSchedule(schedule);
                if(schedule.reservation) {
                    setReservation(Object.setPrototypeOf(schedule.reservation, Reservation.prototype));
                    if(schedule.reservation.parentInfra) {
                        setReservationParentInfra(Object.setPrototypeOf(schedule.reservation.parentInfra, Infra.prototype));
                    }

                    setReservationInfra(Object.setPrototypeOf(schedule.reservation.infra, Infra.prototype));
                }
            });
    }, []);

    return {
        teamSchedule, setTeamSchedule
        , reservation, setReservation
        , reservationParentInfra, setReservationParentInfra
        , reservationInfra, setReservationInfra
    };
}

export default useTeamSchedule;