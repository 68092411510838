import { useEffect, useState } from "react";
import Event from "../../domain/event/Event";
import EventServiceImpl from '../../service/event/impl/EventServiceImpl';

const eventService = new EventServiceImpl();
function useEventDelete(eventNo?: string) {
    const [deletedEvent, setDeleteEvent] = useState<Event>();

    useEffect(() => {
        if(eventNo) {
            eventService.deleteEvent(eventNo)
            .then((event: Event) => {
                setDeleteEvent(event);
            });
        }
    }, [eventNo]);

    return deletedEvent;
}

export default useEventDelete;