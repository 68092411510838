import { useEffect, useState } from 'react';
import Team from '../domain/team/Team';
import TeamServiceImpl from '../service/team/impl/TeamServiceImpl';

const teamService = new TeamServiceImpl();
export default function useTeamInfoHook(teamNo: string) {
    const [team, setTeam] = useState<Team>();

    useEffect(() => {
        teamService.getTeamInfo(teamNo).then((team: Team) => setTeam(team));
    }, []);

    return {
        team,
        setTeam,
    };
}
