import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';

import AdminMainTitle from '../../../../component/admin/AdminMainTitle';
import AdminTeamTable from '../../../../component/admin/AdminTeamTable';
import TeamServiceImpl from '../../../../service/team/impl/TeamServiceImpl';
import TeamSearchCondition from '../../../../domain/team/param/TeamSearchCondition';
import useTeamPagingInfos from '../../hook/useTeamPagingInfos';
import useTeamRetrieveBySearchCondition from '../../../../hook/team/useTeamRetrieveBySearchCondition';
import AdminItemPaging from '../../component/AdminItemPaging';
import useCodeRetrieve from '../../../../hook/code/useCodeRetrieve';
import { CodeType } from '../../../../domain/code/CodeType';
import AdminTeamInfoFilter from '../../component/filter/AdminTeamInfoFilter';
import { Link } from 'react-router-dom';
import ChartDataUtils from '../../../../utils/ChartDataUtils';

const teamService = new TeamServiceImpl();
function AdminTeamListContainer() {
    const location = useLocation();
    const history = useHistory();

    let condition: TeamSearchCondition | any = location.state ? location.state : new TeamSearchCondition();

    const [teamSearchCondition, setTeamSearchCondition] = useState(condition);
    const { page, setPage, pagingSize, setPagingSize, pagingCnt, paging, currentPageGroupIdx, setCurrentPageGroupIdx } = useTeamPagingInfos(
        teamSearchCondition,
    );

    const { teams, teamsCnt } = useTeamRetrieveBySearchCondition(teamSearchCondition, paging);
    const { codes: regionCodes } = useCodeRetrieve(CodeType.REGION);
    const { codes: genderCodes } = useCodeRetrieve(CodeType.GENDER);
    const { codes: belongedCodes } = useCodeRetrieve(CodeType.BELONGED);
    const { codes: classficationCodes } = useCodeRetrieve(CodeType.TEAM_TYPE);
    const { codes: sportCodes } = useCodeRetrieve(CodeType.SPORT);

    let title = '방문스포츠팀 정보 < ' + ChartDataUtils.getNumberFormat(teamsCnt) + ' 개 >';

    const changeFilterHandler = (filterValSet: any) => {
        const newCondition = new TeamSearchCondition();

        if (filterValSet.regionId) {
            newCondition.regionCodeIds = String(filterValSet.regionId);
        }
        if (filterValSet.belongedId) {
            newCondition.belongedCodeIds = String(filterValSet.belongedId);
        }
        if (filterValSet.classifyId) {
            newCondition.classficationCodeIds = String(filterValSet.classifyId);
        }
        if (filterValSet.sportsId) {
            newCondition.sportCodeIds = String(filterValSet.sportsId);
        }

        if (filterValSet.genderId) {
            newCondition.genderCodeIds = String(filterValSet.genderId);
        }

        newCondition.searchWord = filterValSet.searchWord;

        setPage(0);
        setCurrentPageGroupIdx(0);
        setTeamSearchCondition(newCondition);
    };

    const goRegistry = () => {
        history?.push({
            pathname: `/admin/team/registry`,
            state: condition,
        });
    };

    return (
        <>
            <AdminMainTitle title={title}>
                <AdminTeamInfoFilter
                    regionCodes={regionCodes}
                    genderCodes={genderCodes}
                    belongedCodes={belongedCodes}
                    classficationCodes={classficationCodes}
                    sportCodes={sportCodes}
                    initCondition={teamSearchCondition}
                    changeFilterHandler={changeFilterHandler}
                >
                    <button
                        className="bg-white round"
                        onClick={goRegistry}
                        style={{ height: '35px', backgroundColor: '#ff5050', color: '#fff', fontWeight: 700 }}
                    >
                        팀 등록
                    </button>
                </AdminTeamInfoFilter>
            </AdminMainTitle>
            {teams ? (
                <>
                    <AdminTeamTable
                        page={page}
                        pageCnt={pagingCnt}
                        pagingSize={pagingSize}
                        teams={teams}
                        searchCondition={teamSearchCondition}
                    ></AdminTeamTable>
                    <AdminItemPaging
                        totalCnt={teamsCnt}
                        pagingCnt={pagingCnt}
                        pagingSize={pagingSize}
                        setPagingSize={setPagingSize}
                        setPage={setPage}
                        currentPageNum={page}
                        currentPageGroupIdx={currentPageGroupIdx}
                    ></AdminItemPaging>
                </>
            ) : (
                <></>
            )}
        </>
    );
}

export default AdminTeamListContainer;
