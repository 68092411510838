import React from 'react';
import { JsonObject, JsonProperty } from 'json2typescript';
import Code from '../../../domain/code/Code';

@JsonObject('exercise')
export default class Exercise {
    @JsonProperty('exerNo')
    exerNo: number = 0;
    @JsonProperty('exerType')
    exerType: string = '41';
    @JsonProperty('exerTypeCode')
    exerTypeCode?: Code;
    @JsonProperty('exerItem')
    exerItem: string = '';
    @JsonProperty('exerItemCode')
    exerItemCode?: Code;
    @JsonProperty('applyedLevel')
    applyedLevel: string = '1';
    @JsonProperty('title')
    title: string = '';
    @JsonProperty('exerciseWay')
    exerciseWay: string = '';
    @JsonProperty('exerciseDesc')
    exerciseDesc: string = '';
    @JsonProperty('refImgPath')
    refImgPath: string = '';
    uploadImage?: FormData;
    @JsonProperty('refClipPath')
    refClipPath: string = '';
    uploadClip?: FormData;
    @JsonProperty('isReleased')
    isReleased: string = 'true';
    @JsonProperty('regDate')
    regDate: string = '';
    @JsonProperty('modDate')
    modDate: string = '';
}
