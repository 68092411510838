import InfraService from '../InfraService';
import Infra from '../../../domain/infra/Infra';
import APIServerConfig from '../../../config/APIServerConfig';
import AbstractService from '../../AbstractService';
import URLParamMakerUtils from '../../../utils/URLParamMakeUtils';
import InfraSearchCondition from '../../../domain/infra/param/InfraSearchCondition';
import Paging from '../../../domain/common/Paging';
import { confirmAlert } from 'react-confirm-alert';

export default class InfraServiceImpl extends AbstractService implements InfraService {
    getInfraOfParentCategoryNo(parentInfraCategoryNo: number): Promise<Infra[]> {
        return new Promise((resolve) => {
            APIServerConfig.recordAPIAxios
                .get(`/infra`, {
                    params: {
                        parentInfraCategory: parentInfraCategoryNo,
                    },
                })
                .then((response) => {
                    let infras: Infra[] = this.jsonConvert.deserializeArray(response.data, Infra);
                    resolve(infras);
                });
        });
    }

    getInfraOfChildCategoryNo(parentInfraCategoryNo: number, childInfraCategoryNo: number): Promise<Infra[]> {
        return new Promise((resolve) => {
            APIServerConfig.recordAPIAxios
                .get(`/infra`, {
                    params: {
                        parentInfraCategory: parentInfraCategoryNo,
                        childInfraCategory: childInfraCategoryNo,
                    },
                })
                .then((response) => {
                    let infras: Infra[] = this.jsonConvert.deserializeArray(response.data, Infra);
                    resolve(infras);
                });
        });
    }

    getInfra(infraNo: string): Promise<Infra> {
        return new Promise((resolve) => {
            APIServerConfig.recordAPIAxios.get(`/infra/${infraNo}`).then((response) => {
                let infra: Infra = this.jsonConvert.deserializeObject(response.data, Infra);
                resolve(infra);
            });
        });
    }

    getInfraOfParentCategoryNoAndNearDistance(
        parentInfraCategoryNo: number,
        latitude: number,
        longitude: number,
        distance: number,
    ): Promise<Infra[]> {
        return new Promise((resolve) => {
            // APIServerConfig.recordAPIAxios.get(`/infra/category/${parentInfraCategoryNo}/latitude/${latitude}/longitude/${longitude}/distance/${distance}`)
            APIServerConfig.recordAPIAxios
                .get(`/infra`, {
                    params: {
                        parentInfraCategory: parentInfraCategoryNo,
                        latitude: latitude,
                        longitude: longitude,
                        distance: distance,
                    },
                })
                .then((response) => {
                    let infras: Infra[] = this.jsonConvert.deserializeArray(response.data, Infra);
                    resolve(infras);
                });
        });
    }

    getInfraOfChildCategoryNoAndNearDistance(
        parentInfraCategoryNo: number,
        childInfraCategoryNo: number,
        latitude: number,
        longitude: number,
        distance: number,
    ): Promise<Infra[]> {
        return new Promise((resolve) => {
            // APIServerConfig.recordAPIAxios.get(`/infra/category/${parentInfraCategoryNo}/${childInfraCategoryNo}/latitude/${latitude}/longitude/${longitude}/distance/${distance}`)
            APIServerConfig.recordAPIAxios
                .get(`/infra`, {
                    params: {
                        parentInfraCategory: parentInfraCategoryNo,
                        childInfraCategory: childInfraCategoryNo,
                        latitude: latitude,
                        longitude: longitude,
                        distance: distance,
                    },
                })
                .then((response) => {
                    let infras: Infra[] = this.jsonConvert.deserializeArray(response.data, Infra);
                    resolve(infras);
                });
        });
    }

    getInfraOfSearchCondition(parsedSearchConditionMap: any, searchWord: string | undefined): Promise<Infra[]> {
        return new Promise((resolve) => {
            let queryParam = URLParamMakerUtils.getParam(parsedSearchConditionMap, searchWord);
            let url = queryParam ? `/infra${queryParam}` : `/infra`;
            APIServerConfig.recordAPIAxios.get(url).then((response) => {
                let infras: Infra[] = this.jsonConvert.deserializeArray(response.data, Infra);
                resolve(infras);
            });
        });
    }

    getInfraCntOfSearchCondition(parsedSearchConditionMap: any, searchWord: string | undefined): Promise<number> {
        return new Promise((resolve) => {
            let queryParam = URLParamMakerUtils.getParam(parsedSearchConditionMap, searchWord);
            let url = queryParam ? `/infra/cnt${queryParam}` : `/infra/cnt`;
            APIServerConfig.recordAPIAxios.get(url).then((response) => {
                let infraCnt: number = response.data;
                resolve(infraCnt);
            });
        });
    }

    getInfraOfParentCategoryNoAndRecommandation(parentInfraCategoryNo: number): Promise<Infra[]> {
        return new Promise((resolve) => {
            // APIServerConfig.recordAPIAxios.get(`/infra/category/${parentInfraCategoryNo}`)
            APIServerConfig.recordAPIAxios
                .get(`/infra`, {
                    params: {
                        parentInfraCategory: parentInfraCategoryNo,
                        recommandation: true,
                    },
                })
                .then((response) => {
                    let infras: Infra[] = this.jsonConvert.deserializeArray(response.data, Infra);
                    resolve(infras);
                });
        });
    }
    getInfraOfChildCaregoryNoAndRecommandation(parentInfraCategoryNo: number, childInfraCategoryNo: number): Promise<Infra[]> {
        return new Promise((resolve) => {
            // APIServerConfig.recordAPIAxios.get(`/infra/category/${parentInfraCategoryNo}/${childInfraCategoryNo}`)
            APIServerConfig.recordAPIAxios
                .get(`/infra`, {
                    params: {
                        parentInfraCategory: parentInfraCategoryNo,
                        childInfraCategory: childInfraCategoryNo,
                        recommandation: true,
                    },
                })
                .then((response) => {
                    let infras: Infra[] = this.jsonConvert.deserializeArray(response.data, Infra);
                    resolve(infras);
                });
        });
    }

    getInfraOfSearchConditionAndLocation(
        parsedSearchConditionMap: any,
        searchWord: string | undefined,
        latitude?: number,
        longitude?: number,
        distance?: number,
    ): Promise<Infra[]> {
        if (latitude && longitude && distance && parsedSearchConditionMap) {
            parsedSearchConditionMap['latitude'] = latitude;
            parsedSearchConditionMap['longitude'] = longitude;
            parsedSearchConditionMap['distance'] = distance;
        }

        if (latitude && longitude && distance && !parsedSearchConditionMap) {
            parsedSearchConditionMap = {};
            parsedSearchConditionMap['latitude'] = latitude;
            parsedSearchConditionMap['longitude'] = longitude;
            parsedSearchConditionMap['distance'] = distance;
        }

        return new Promise((resolve) => {
            let queryParam = URLParamMakerUtils.getParam(parsedSearchConditionMap, searchWord);
            let url = queryParam ? `/infra${queryParam}` : `/infra`;
            APIServerConfig.recordAPIAxios.get(url).then((response) => {
                let infras: Infra[] = this.jsonConvert.deserializeArray(response.data, Infra);
                resolve(infras);
            });
        });
    }

    getInfraCntOfSearchConditionAndLocation(
        parsedSearchConditionMap: any,
        searchWord: string | undefined,
        latitude?: number,
        longitude?: number,
        distance?: number,
    ): Promise<number> {
        if (latitude && longitude && distance && parsedSearchConditionMap) {
            parsedSearchConditionMap['latitude'] = latitude;
            parsedSearchConditionMap['longitude'] = longitude;
            parsedSearchConditionMap['distance'] = distance;
        }

        if (latitude && longitude && distance && !parsedSearchConditionMap) {
            parsedSearchConditionMap = {};
            parsedSearchConditionMap['latitude'] = latitude;
            parsedSearchConditionMap['longitude'] = longitude;
            parsedSearchConditionMap['distance'] = distance;
        }

        return new Promise((resolve) => {
            let queryParam = URLParamMakerUtils.getParam(parsedSearchConditionMap, searchWord);
            let url = queryParam ? `/infra/cnt${queryParam}` : `/infra/cnt`;
            APIServerConfig.recordAPIAxios.get(url).then((response) => {
                let infraCnt: number = response.data;
                resolve(infraCnt);
            });
        });
    }

    registeInfra(infra: Infra): Promise<Infra> {
        return new Promise((resolve) => {
            APIServerConfig.recordAPIAxios.post('/infra', JSON.stringify(infra)).then((response) => {
                let newInfra = this.jsonConvert.deserializeObject(response.data, Infra);
                resolve(newInfra);
            });
        });
    }

    updateInfra(infraNo: string, infra: Infra): Promise<Infra> {
        return new Promise((resolve, reject) => {
            APIServerConfig.recordAPIAxios
                .put(`/infra/${infraNo}`, JSON.stringify(infra))
                .then((response) => {
                    let updatedInfra = this.jsonConvert.deserializeObject(response.data, Infra);
                    resolve(updatedInfra);
                })
                .catch((err) => {
                    confirmAlert({
                        title: '경고',
                        message: '업로드 되지 않았습니다. 관리자에게 문의 하십시오.',
                        buttons: [{ label: '확인', onClick: () => console.log(err) }],
                    });
                });
        });
    }

    deleteInfra(infraNo: string): Promise<Infra> {
        return new Promise((resolve, reject) => {
            APIServerConfig.recordAPIAxios
                .delete(`/infra/${infraNo}`)
                .then((response) => {
                    //let updatedInfra = this.jsonConvert.deserializeObject(response.data, Infra);
                    //resolve(updatedInfra);
                    resolve(new Infra());
                })
                .catch((err) => {
                    confirmAlert({
                        title: '경고',
                        message: '삭제 되지 않았습니다. 관리자에게 문의 하십시오.',
                        buttons: [{ label: '확인', onClick: () => console.log(err) }],
                    });
                });
        });
    }

    updateInfras(infras: Infra[]): Promise<Infra[]> {
        return new Promise((resolve, reject) => {
            APIServerConfig.recordAPIAxios
                .put(`/infra/batch/update`, JSON.stringify(infras))
                .then((response) => {
                    let updatedInfra = this.jsonConvert.deserializeArray(response.data, Infra);
                    resolve(updatedInfra);
                })
                .catch((err) => {
                    confirmAlert({
                        title: '경고',
                        message: '반영 실패하였습니다. 관리자에게 문의해 주십시오.',
                        buttons: [{ label: '확인', onClick: () => console.log(err) }],
                    });
                });
        });
    }

    getInfraBySearchCondition(infraSearchCondition: InfraSearchCondition, paging: Paging): Promise<Infra[]> {
        return new Promise((resolve, reject) => {
            APIServerConfig.recordAPIAxios
                .get(`/infra`, {
                    params: Object.assign(infraSearchCondition, paging),
                })
                .then((response) => {
                    let infras = this.jsonConvert.deserializeArray(response.data, Infra);
                    resolve(infras);
                })
                .catch((err) => {
                    confirmAlert({
                        title: '경고',
                        message: '호출에 실패하였습니다. 관리자에게 문의해 주십시오.',
                        buttons: [{ label: '확인', onClick: () => console.log(err) }],
                    });
                });
        });
    }

    getInfraCntBySearchCondition(InfraSearchCondition: InfraSearchCondition): Promise<number> {
        return new Promise((resolve, reject) => {
            APIServerConfig.recordAPIAxios
                .get(`/infra/cnt`, {
                    params: Object.assign(InfraSearchCondition),
                })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }
}
