import React from 'react';
import TargetPerson from '../../../../domain/TargetPerson';
import PhysicalStatus from '../../../../domain/recording/PhysicalStatus';
import UserDetailInfo from '../../../../../../domain/user/UserDetailInfo';
import Code from '../../../../../../domain/code/Code';

interface IProps {
    status: PhysicalStatus;
    personData: TargetPerson;
    handler: (evt: any) => void;
}

function parseDate(dateStr?: string) {
    let dateArr: string[] = [];

    if (dateStr) {
        dateArr = dateStr.split('-');
        let today = new Date();
        let birthdate = new Date(parseInt(dateArr[0]), parseInt(dateArr[1]) - 1, parseInt(dateArr[2]));
        let age = today.getFullYear() - birthdate.getFullYear();

        return '만' + age + '세 (' + dateArr[0] + '년 ' + dateArr[1] + '월 ' + dateArr[2] + '일 생)';
    } else {
        return '';
    }
}

export default function PhysicalStatusForm(props: IProps) {
    let physicalStatus: PhysicalStatus = props.status;
    let inputChangeHandler = props.handler;
    let targetData: TargetPerson = props.personData;
    let reservater: UserDetailInfo | undefined = targetData.reservater;
    let gender: Code | undefined = reservater?.genderCode;
    let birthDate = parseDate(reservater?.birthdate);

    return (
        <>
            <div id="center-popup-list" className="center-popup-list-tab">
                <div className="center-popup-list-box">
                    <div className="flex">
                        <h1 className="center">
                            <i className="fas fa-user-tie"></i> {reservater?.name}
                        </h1>
                        &nbsp;
                        <span className="center-birth">
                            {gender?.name} / {birthDate}
                        </span>
                    </div>

                    <div className="flex-basic">
                        <div className="center-half">
                            <h2 className="center2">신장</h2>
                            <div className="center-bg-gray">
                                <input
                                    id="height"
                                    className="center-input-gray"
                                    type="text"
                                    value={physicalStatus.height}
                                    onChange={inputChangeHandler}
                                />
                                <label className="center-label">cm</label>
                            </div>
                        </div>
                        <div className="center-half">
                            <h2 className="center2">체중</h2>
                            <div className="center-bg-gray">
                                <input
                                    id="weight"
                                    className="center-input-gray"
                                    type="text"
                                    value={physicalStatus.weight}
                                    onChange={inputChangeHandler}
                                />
                                <label className="center-label">kg</label>
                            </div>
                        </div>
                    </div>

                    <h2 className="center2">혈압</h2>
                    <div className="center-bg-gray flex">
                        <div className="center-bg-blue">최고</div>
                        <input
                            id="hBloodPressure"
                            className="center-input-gray center-w100"
                            type="text"
                            value={physicalStatus.hBloodPressure}
                            onChange={inputChangeHandler}
                        />
                        <label className="center-label">mmHg</label>
                        <div className="center-bg-blue">최저</div>
                        <input
                            id="lBloodPressure"
                            className="center-input-gray center-w100"
                            type="text"
                            value={physicalStatus.lBloodPressure}
                            onChange={inputChangeHandler}
                        />
                        <label className="center-label">mmHg</label>
                        <div className="center-bg-blue">맥박</div>
                        <input
                            id="pluse"
                            className="center-input-gray  center-w180"
                            type="text"
                            value={physicalStatus.pluse}
                            onChange={inputChangeHandler}
                        />
                        <label className="center-label">p/min</label>
                    </div>
                    {/* {/*<!--//center-bg-gray flex-->*/}
                    <br />
                    <br />
                </div>
                {/* <!--//center-popup-list-box--> */}
            </div>
            {/* <!--//center-popup-list-tab--> */}
        </>
    );
}
