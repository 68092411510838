import { confirmAlert } from 'react-confirm-alert';
import ClubTeamInfo from '../../../../domain/clubteam/ClubTeamInfo';
import ClubTeamInfoServiceImpl from '../../../../service/clubteam/impl/ClubTeamInfoServiceImpl';
interface AdminClubTeamInfoTableTrProps {
    seq: number;
    clubTeamInfo: ClubTeamInfo;
}

let clubTeamInfoService = new ClubTeamInfoServiceImpl();

function AdminClubTeamInfoTableTr(props: AdminClubTeamInfoTableTrProps) {
    const deleteBtnHandler = () => {
        let infoNo: string | undefined = props.clubTeamInfo.clubTeamInfoNo;

        confirmAlert({
            title: '알림',
            message: '선택하신 정보를 삭제 하시겠습니까?',
            buttons: [
                {
                    label: '확인',
                    onClick: () => {
                        if (infoNo)
                            clubTeamInfoService.deleteClubInfo(infoNo).then((result: ClubTeamInfo) => {
                                confirmAlert({
                                    title: '삭제성공',
                                    message: '정보가 정상적으로 삭제 되었습니다. 감사합니다.',
                                    buttons: [
                                        {
                                            label: '확인',
                                            onClick: () => {
                                                window.location.reload();
                                            },
                                        },
                                    ],
                                });
                            });
                    },
                },
                { label: '취소', onClick: () => {} },
            ],
        });
    };

    return (
        <tr>
            <td>{props.seq}</td>
            <td>
                {props.clubTeamInfo.clubTeamSeason} {props.clubTeamInfo.clubTeamSeasonPart == 1 ? '상반기' : '하반기'}
            </td>
            <td>{props.clubTeamInfo.regionCode?.name}</td>
            <td>{props.clubTeamInfo.clubSportCode?.name}</td>
            <td>{props.clubTeamInfo.clubTeamType === 'region' ? '지역' : '직장'}</td>
            <td>{props.clubTeamInfo.clubTeamCount} 팀 </td>
            <td>{props.clubTeamInfo.clubTeamMemberCount} 명</td>
            <td>{props.clubTeamInfo.registeDate}</td>
            <td>
                <button className="bg-red white" onClick={deleteBtnHandler} style={{ fontWeight: 700 }}>
                    삭제
                </button>
            </td>
        </tr>
    );
}

export default AdminClubTeamInfoTableTr;
