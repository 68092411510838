import React from 'react';

interface SearchLeftProps {
    children?: React.ReactNode;
}

function SearchLeft({children}: SearchLeftProps) {
    return (
        <section className="search-wrap search-left">
            {children}
        </section>
    )
}

export default SearchLeft;