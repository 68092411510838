import React, { useEffect, useState } from 'react';
import SearchIn from '../../../component/layout/SearchIn';
import SearchLeft from '../../../component/layout/SearchLeft';
import useGeographic from '../../../hook/useGeographic';
import SearchPageHeader from '../layout/SearchPageHeader';
import CurrPositionInfraSearchConditionContainer from '../../../container/search/CurrPositionInfraSearchConditionContainer';
import useSearchPageHook from './hook/useSearchPageHook';
import { InfraCategoryType } from '../../../domain/infra/InfraCategoryType';
import SearchRight from '../../../component/layout/SearchRight';
import SearchOfInfraContainer from '../../../container/search/SearchOfInfraContainer';
import Infra from '../../../domain/infra/Infra';
import InfraServiceImpl from '../../../service/infra/impl/InfraServiceImpl';
import { NEAR_DISTAINCE } from '../../../config/Constant';
import SearchCnt from '../../../component/search/SearchCnt';
import SearchIteamPaging from '../../../component/search/SearchItemPaging';

const infraService = new InfraServiceImpl();
function CurrPositionOfNearSearchPageContainer() {
    const userLocation = useGeographic();
    const [infras, setInfras] = useState<Infra[]>();
    const [searchCnt, setSearchCnt] = useState(0);

    const {
        searchType,
        setSearchType,
        searchConditionMapParsing,
        parsedSearchConditionMap,
        pagingSize,
        pagingCnt,
        setPage,
        page,
    } = useSearchPageHook(InfraCategoryType.SPORT_INFRA_NO);

    useEffect(() => {
        if (userLocation && parsedSearchConditionMap) {
            parsedSearchConditionMap['parentInfraCategory'] = searchType;
            infraService
                .getInfraOfSearchConditionAndLocation(
                    parsedSearchConditionMap,
                    undefined,
                    userLocation?.latitude,
                    userLocation?.longitude,
                    NEAR_DISTAINCE,
                )
                .then((infras: Infra[]) => {
                    setInfras(infras);
                    setSearchCnt(infras.length);
                });
        }
    }, [parsedSearchConditionMap, userLocation]);

    return (
        <>
            <SearchPageHeader></SearchPageHeader>
            <div className="content-wrap">
                <SearchLeft>
                    <SearchIn>
                        <CurrPositionInfraSearchConditionContainer
                            setSearchType={setSearchType}
                            userLocation={userLocation}
                        ></CurrPositionInfraSearchConditionContainer>
                        <div className="apply-box">
                            <button onClick={() => searchConditionMapParsing(0)}>조회</button>
                        </div>
                    </SearchIn>
                </SearchLeft>
                <SearchRight>
                    <SearchIn>
                        <SearchCnt title={`내 위치 주변 검색 `} searchCnt={searchCnt}></SearchCnt>
                        <SearchOfInfraContainer userLocation={userLocation} searchCnt={searchCnt} searchedInfras={infras}></SearchOfInfraContainer>
                        <SearchIteamPaging
                            totalCnt={searchCnt}
                            pagingSize={pagingSize}
                            pagingCnt={pagingCnt}
                            setPage={setPage}
                            currentPageNum={page}
                        ></SearchIteamPaging>
                    </SearchIn>
                </SearchRight>
            </div>
        </>
    );
}

export default CurrPositionOfNearSearchPageContainer;
