import { Redirect } from "react-router";
import URLParamMakerUtils from '../../../utils/URLParamMakeUtils';

interface SearchPwRedirectPageProps {
    location: any;
}

function SearchPwRedirectPage(props: SearchPwRedirectPageProps) {
    const id = URLParamMakerUtils.getUrlParameter('id', props.location);
    return (
        <Redirect
            to={{
                pathname: '/pwfinder',
                state: {
                    id: id
                }
            }}
        />
    )
}

export default SearchPwRedirectPage;