import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useLocation, useHistory } from 'react-router-dom';

import '../assets/css/center.css';
import '../assets/css/animate.min.css';
import Layout from '../layouts/Layout';
import Header from '../layouts/Header';
import SideBar from '../layouts/SideBar';
import RehabServiceImpl from '../service/impl/RehabServiceImpl';
import PaginationComponent from './components/PaginationComponent';
import SatisfyingPopupComponent from './components/completerehab/SatisfyingPopupComponent';
import TrackingHistoryPopupComponent from './components/completerehab/TrackingHistoryPopupComponent';
import CompletePopupComponent from './components/completerehab/CompletePopupComponent';
import ActionComponent from './components/ActionComponent';
import TargetPerson from '../domain/TargetPerson';
import UserDetailInfo from '../../../domain/user/UserDetailInfo';
import Code from '../../../domain/code/Code';
import { confirmAlert } from 'react-confirm-alert';
import RehabTargetHistory from '../domain/RehabTargetHistory';
import RehabHistoryServiceImpl from '../service/impl/RehabHistoryServiceImpl';
import Team from '../../../domain/team/Team';

const rehabService = new RehabServiceImpl();

export default function MngTargetPersonComponent(props: any) {
    let content: any = '';
    const [managingTargets, setManagingTargets] = useState<TargetPerson[]>([]);
    const [retrieveKeyword, setRetrieveKeyword] = useState<{ type: string; keyword: string }>({ type: 'name', keyword: '' });

    const [totalSize, setTotalSize] = useState(1);
    const [displaySize, setDisplaySize] = useState(10);
    const [currPage, setCurrPage] = useState(0);

    const location = useLocation();
    const history = useHistory();

    useEffect(() => {
        let pagination = { size: displaySize, page: currPage };
        rehabService.getManagingTargetPersons(retrieveKeyword, pagination).then((managingList: { data: TargetPerson[]; totalSize: number }) => {
            setManagingTargets(managingList.data);
            setTotalSize(managingList.totalSize);
        });
    }, []);

    const retrieveHandler = () => {
        setCurrPage(0);
        setDisplaySize(10);
        let pagination = { size: 10, page: 0 };
        rehabService.getManagingTargetPersons(retrieveKeyword, pagination).then((managingList: { data: TargetPerson[]; totalSize: number }) => {
            setManagingTargets(managingList.data);
            setTotalSize(managingList.totalSize);
        });
    };

    const pagingHandler = (pagination: { size: number; page: number }) => {
        setCurrPage(pagination.page);
        setDisplaySize(pagination.size);
        rehabService.getManagingTargetPersons(retrieveKeyword, pagination).then((managingList: { data: TargetPerson[]; totalSize: number }) => {
            setManagingTargets(managingList.data);
            setTotalSize(managingList.totalSize);
        });
    };

    // const selectingHandler = (keywordInfo: { type: string; keyword: string }) => {
    //     setCurrPage(0);
    //     setDisplaySize(10);
    //     let pagination = { size: 10, page: 0 };
    //     rehabService.getManagingTargetPersons(keywordInfo, pagination).then((targetPersonInfo: { data: TargetPerson[]; totalSize: number }) => {
    //         setManagingTargets(targetPersonInfo.data);
    //         setTotalSize(targetPersonInfo.totalSize);
    //     });
    // };

    const statusHandler = (toggle: string) => {
        let input = document.getElementById('keyword-input');
        let select = document.getElementById('keyword-select');
        let centers = document.getElementById('centers-select');

        if (toggle === 'input') {
            if (input) input.style.display = 'block';
            if (select) select.style.display = 'none';
            if (centers) centers.style.display = 'none';
        } else if (toggle === 'centers') {
            if (input) input.style.display = 'none';
            if (select) select.style.display = 'none';
            if (centers) centers.style.display = 'block';
        } else {
            if (input) input.style.display = 'none';
            if (select) select.style.display = 'block';
            if (centers) centers.style.display = 'none';
        }
    };
    const changeKeywordHandler = (e: any) => {
        let key = e.target.id;
        let inputVal = e.target.value;

        if (key === 'selectKeywordType') {
            if (inputVal === 'status') {
                statusHandler('select');
                setRetrieveKeyword((prevState) => {
                    return { ...prevState, ['type']: inputVal, ['keyword']: '0' };
                });
            } else if (inputVal === 'centers') {
                statusHandler('centers');
                setRetrieveKeyword((prevState) => {
                    return { ...prevState, ['type']: inputVal, ['keyword']: '0' };
                });
            } else {
                statusHandler('input');
                setRetrieveKeyword((prevState) => {
                    return { ...prevState, ['type']: inputVal, ['keyword']: '' };
                });
            }
        } else if (key === 'inputKeyword') {
            setRetrieveKeyword((prevState) => {
                return { ...prevState, ['keyword']: inputVal };
            });
        } else if (key === 'selectKeyword') {
            // setRetrieveKeyword((prevState) => {
            //     let selecting = { ...prevState, ['keyword']: inputVal };
            //     selectingHandler(selecting);
            //     return selecting;
            // });
        } else if (key === 'selectCenter') {
            // setRetrieveKeyword((prevState) => {
            //     let selecting = { ...prevState, ['keyword']: inputVal };
            //     selectingHandler(selecting);
            //     return selecting;
            // });
        }
    };
    const keyEventHandler = (e: any) => {
        if (e.key == 'Enter') {
            retrieveHandler();
        }
    };
    const filters = () => {
        return (
            <div className="center-btn-right350">
                <div className="center-select-box">
                    <label># 검색: </label>
                    <select id="selectKeywordType" className="center-select" value={retrieveKeyword.type} onChange={changeKeywordHandler}>
                        <option value="0">전체</option>
                        <option value="name">이름</option>
                        <option value="belongTeam">소속팀</option>
                        <option value="type">운동종목</option>
                        {/* <option value="status">대상자상태</option>
                        <option value="centers">검진센터</option> */}
                    </select>
                    <div id="keyword-input" className="center-search">
                        <input
                            id="inputKeyword"
                            className="center-input"
                            placeholder="검색어를 입력하세요"
                            onChange={changeKeywordHandler}
                            onKeyPress={keyEventHandler}
                            value={retrieveKeyword.keyword}
                            style={{ border: '1px solid #e3e3e3' }}
                        />
                        <button className="btn" onClick={retrieveHandler} style={{ marginLeft: '5px', border: '1px solid #e3e3e3' }}>
                            <i className="fas fa-search"></i>
                        </button>
                    </div>
                    <div id="keyword-select" className="center-search" style={{ display: 'none' }}>
                        <select id="selectKeyword" className="center-reserved-select" value={retrieveKeyword.keyword} onChange={changeKeywordHandler}>
                            <option value="0">대상자상태를 선택해주세요</option>
                            <option value="8001">상담대상자</option>
                            <option value="8002">측정대상자</option>
                            <option value="8003">처방대상자</option>
                            <option value="8004">처방완료자</option>
                        </select>
                    </div>
                    <div id="centers-select" className="center-search" style={{ display: 'none' }}>
                        <select id="selectCenter" className="center-reserved-select" value={retrieveKeyword.keyword} onChange={changeKeywordHandler}>
                            <option value="0">예약센터를선택해주세요</option>
                            <option value="center_1">마산대센터</option>
                            <option value="center_2">의령센터</option>
                            <option value="center_3">고성센터</option>
                        </select>
                    </div>
                </div>
            </div>
        );
    };

    const popupHandler = (evt: any) => {
        let pageHandler = ActionComponent();

        let target = evt.target;
        let action = target.id;
        let targetNo: number = target.value;

        let targetPerson = managingTargets.find((item) => {
            return item.targetNo === Number(targetNo);
        });

        let targetState = targetPerson?.targetState;

        if (action === 'checkExerHistory') {
            pageHandler.popupOpen();
            ReactDOM.render(
                <TrackingHistoryPopupComponent
                    setSatisfyOpenable={openablePopup}
                    targetNo={targetNo}
                    selectedTarget={targetPerson ? targetPerson : new TargetPerson()}
                ></TrackingHistoryPopupComponent>,
                document.getElementById('center-popup-wrap'),
            );
        } else if (targetState) {
            //처방전확인 팝업
            if (action === 'checkSheet') {
                pageHandler.checkOpen();
                ReactDOM.render(
                    <CompletePopupComponent
                        selectedTarget={targetPerson ? targetPerson : new TargetPerson()}
                        closingHandler={closingHandler}
                    ></CompletePopupComponent>,
                    document.getElementById('center-check-wrap'),
                );
            } else if (action === 'sendQuestion') {
                pageHandler.popupOpen();
                ReactDOM.render(
                    <SatisfyingPopupComponent
                        setSatisfyOpenable={openablePopup}
                        targetNo={targetNo}
                        selectedTarget={targetPerson ? targetPerson : new TargetPerson()}
                    ></SatisfyingPopupComponent>,
                    document.getElementById('center-popup-wrap'),
                );
            }
        } else {
            confirmAlert({
                title: '사용자 알림',
                message: '대상자는 처방지를 확인할 수 없는 상태입니다. 대상자의 <측정상태>를 확인해주세요',
                buttons: [{ label: '확인', onClick: () => {} }],
            });
        }
    };

    const openablePopup = (openable: boolean) => {
        let actions = ActionComponent();
        actions.popupClose();
        actions.checkClose();
    };

    const closingHandler = () => {
        let actions = ActionComponent();
        actions.popupClose();
        actions.checkClose();
    };

    const goDetailHandler = (targetInfo: any) => {
        history.push({
            pathname: 'targetperson/detail',
            state: targetInfo,
        });
    };

    const popups = () => {
        return (
            <>
                <div id="center-popup-wrap">{/* 만족도 조사 팝업*/}</div>
                <div id="center-check-wrap">{/* 설문지 확인 팝업*/}</div>
            </>
        );
    };
    const menuTitle: string = '* 재활센터 대상자 관리 <' + totalSize + '명 관리중>';
    const title: string = '대상자관리';

    const contents = (managingTargets: TargetPerson[]) => {
        let page = currPage ? currPage : 0;
        let pageSize = displaySize ? displaySize : 0;
        let rowNum = page * pageSize;
        let tbCont = (
            <>
                <tr>
                    <td colSpan={15}>관리 대상자가 존재하지 않습니다.</td>
                </tr>
            </>
        );

        if (managingTargets && managingTargets.length > 0) {
            tbCont = (
                <>
                    {managingTargets?.map((targetInfo: TargetPerson, idx) => {
                        let reservater: UserDetailInfo | undefined = targetInfo.reservater;
                        let team: Team | undefined = targetInfo.team;
                        let infraInfo: any = targetInfo.infra;

                        let regionCode: any = reservater?.regionCode;

                        let targetState: Code | undefined = targetInfo.targetStateCode;
                        let satisfyingBtn = <></>;

                        if (targetInfo.targetState.toString() === '8004') {
                            satisfyingBtn = (
                                <button id="sendQuestion" className="center-button-letter" onClick={popupHandler} value={targetInfo.targetNo}>
                                    <i className="fas fa-sticky-note"></i> 설문지 발송
                                </button>
                            );
                        } else {
                            satisfyingBtn = (
                                <button id="sendQuestion" className="center-button-cancle">
                                    <i className="fas fa-sticky-note"></i> 설문지 발송
                                </button>
                            );
                        }

                        return (
                            <tr key={idx}>
                                <td>{rowNum + idx + 1}</td>
                                <td
                                    onClick={() => {
                                        goDetailHandler(targetInfo);
                                    }}
                                    style={{ cursor: 'pointer' }}
                                >
                                    {reservater?.name}
                                </td>

                                <td>{reservater?.birthdate}</td>
                                <td>
                                    {team?.name} / {regionCode.name}
                                </td>
                                <td>{targetState?.name}</td>
                                <td>{infraInfo.name}</td>
                                <td>{satisfyingBtn}</td>
                                <td>
                                    <button id="checkExerHistory" className="center-button-letter" onClick={popupHandler} value={targetInfo.targetNo}>
                                        <i className="fas fa-history"></i> 확인
                                    </button>
                                </td>
                            </tr>
                        );
                    })}
                </>
            );
        }

        return (
            <table className="center-list-table" cellPadding="0" cellSpacing="0">
                <colgroup>
                    <col style={{ width: '5%' }} />
                    <col style={{ width: '10%' }} />
                    <col style={{ width: '10%' }} />
                    <col style={{ width: '15%' }} />
                    <col style={{ width: '10%' }} />
                    <col style={{ width: '30%' }} />
                    <col style={{ width: '10%' }} />
                    <col style={{ width: '10%' }} />
                </colgroup>
                <thead>
                    <tr>
                        <td>No.</td>
                        <td>이름</td>
                        <td>생년월일</td>
                        <td>소속팀</td>
                        <td>측정단계</td>
                        <td>관리센터</td>
                        <td>만족도조사</td>
                        <td>밴드정보</td>
                    </tr>
                </thead>
                <tbody>{tbCont}</tbody>
            </table>
        );
    };

    let path = props.location.pathname;
    let completeRehabList = (
        <Layout
            header={<Header></Header>}
            sideBar={<SideBar name={path}></SideBar>}
            path={path}
            naviTitle={title}
            menuTitle={menuTitle}
            filter={filters()}
            pagination={PaginationComponent(pagingHandler, totalSize, currPage, displaySize)}
            popups={popups()}
        >
            {contents(managingTargets)}
        </Layout>
    );
    return completeRehabList;
}
