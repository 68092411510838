import { JsonObject, JsonProperty } from 'json2typescript';
import moment, { Moment, Moment as MomentTypes } from 'moment';

@JsonObject('targetExercises')
export default class TargetExercises {
    @JsonProperty('targetExerNo')
    targetExerNo?: number;

    @JsonProperty('targetNo')
    targetNo?: number;

    @JsonProperty('exerType')
    exerType?: string;

    @JsonProperty('exerBeginTime')
    exerBeginTime?: Date;

    @JsonProperty('exerEndTime')
    exerEndTime?: Date;

    @JsonProperty('bodyCompositionInfo')
    bodyCompositionInfo?: string;

    @JsonProperty('exerSummary')
    exerSummary?: string;

    @JsonProperty('regDate')
    regDate?: Date;

    getBeginTime() {
        let tempDate = moment(this.exerBeginTime);
        let oriMonth = tempDate.month();
        tempDate.month(oriMonth - 1);

        return tempDate.format('YYYY-MM-DD hh:mm:ss');
    }

    getEndTime() {
        let tempDate = moment(this.exerEndTime);
        let oriMonth = tempDate.month();
        tempDate.month(oriMonth - 1);
        let endTime = tempDate.format('YYYY-MM-DD hh:mm:ss');

        if (endTime === 'Invalid date') endTime = '진행중....';

        return endTime;
    }
}
