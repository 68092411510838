import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const images = [
    { id: 1, src: '/assets/image/spons/footer_spon_01.png', alt: '경상남도', width: '150px', height: '50px', url: 'https://www.gyeongnam.go.kr' },
    { id: 2, src: '/assets/image/spons/footer_spon_02.png', alt: '경상남도체육회', width: '150px', height: '', url: 'http://www.gnsports.or.kr' },
    {
        id: 3,
        src: '/assets/image/spons/footer_spon_03.png',
        alt: '의령군체육회',
        width: '90px',
        height: '90px',
        url: 'https://band.us/band/74165946?invitation_url_id=aca266v7e8p2R',
    },
    {
        id: 4,
        src: '/assets/image/spons/footer_spon_04.png',
        alt: '의령거점센터',
        width: '130px',
        height: '50px',
        url: 'https://band.us/band/80782965?invitation_url_id=a8a962v9eap4F',
    },
    { id: 5, src: '/assets/image/spons/footer_spon_05.png', alt: '고성거점센터', width: '140px', height: '50px', url: '' },
    {
        id: 6,
        src: '/assets/image/spons/footer_spon_06.png',
        alt: '경남지방중소벤처기업청',
        width: '150px',
        height: '50px',
        url: 'https://www.mss.go.kr/gyeongnam',
    },
    {
        id: 7,
        src: '/assets/image/spons/footer_spon_07.png',
        alt: '경남창조경제혁신센터',
        width: '150px',
        height: '50px',
        url: 'https://ccei.creativekorea.or.kr/gyeongnam/',
    },
    { id: 8, src: '/assets/image/spons/footer_spon_08.png', alt: '경남테크노파크', width: '180px', height: '50px', url: 'https://www.gntp.or.kr' },
    { id: 9, src: '/assets/image/spons/footer_spon_09.png', alt: '창원산업진흥원', width: '150px', height: '50px', url: 'http://www.cwip.or.kr' },
    {
        id: 10,
        src: '/assets/image/spons/footer_spon_10.png',
        alt: '창원상공회의소',
        width: '150px',
        height: '50px',
        url: 'https://www.ripc.org/changwon/index.jsp',
    },
    {
        id: 11,
        src: '/assets/image/spons/footer_spon_11.png',
        alt: '진주바이오산업진흥원',
        width: '150px',
        height: '50px',
        url: 'http://www.jbio.or.kr',
    },
    { id: 12, src: '/assets/image/spons/footer_spon_12.png', alt: '경남태권도협희', width: '150px', height: '50px', url: '' },
    {
        id: 13,
        src: '/assets/image/spons/footer_spon_13.png',
        alt: '진해노인종합복지관',
        width: '150px',
        height: '50px',
        url: 'http://www.jhsw.or.kr/',
    },
    {
        id: 14,
        src: '/assets/image/spons/footer_spon_14.png',
        alt: '마산회원노인종합복지관',
        width: '150px',
        height: '40px',
        url: 'http://www.mhswc.or.kr/',
    },
    {
        id: 15,
        src: '/assets/image/spons/footer_spon_15.png',
        alt: '(사)대한노인회',
        width: '150px',
        height: '50px',
        url: 'https://www.knoldman.or.kr',
    },
    { id: 16, src: '/assets/image/spons/footer_spon_16.png', alt: 'NC다이노스', width: '100px', height: '90px', url: 'https://www.ncdinos.com' },
    { id: 17, src: '/assets/image/spons/footer_spon_17.png', alt: '경남FC', width: '80px', height: '90px', url: 'https://www.gyeongnamfc.com' },
    {
        id: 18,
        src: '/assets/image/spons/footer_spon_18.png',
        alt: '창원LG세이커스',
        width: '100px',
        height: '90px',
        url: 'https://sakers.kbl.or.kr/',
    },
    {
        id: 19,
        src: '/assets/image/spons/footer_spon_19.png',
        alt: '국민체력100창원마산회원 체력인증 센터',
        width: '90px',
        height: '90',
        url: 'https://blog.naver.com/masankm',
    },
    {
        id: 20,
        src: '/assets/image/spons/footer_spon_20.png',
        alt: '경남스포츠클럽',
        width: '150px',
        height: '50px',
        url: 'http://gnsportsclub.or.kr/',
    },
    { id: 21, src: '/assets/image/spons/footer_spon_21.png', alt: '마이스포', width: '120px', height: '50px', url: '' },
    { id: 22, src: '/assets/image/spons/footer_spon_22.png', alt: 'Dr.ENA', width: '100px', height: '90px', url: 'http://www.drena.co.kr' },
    { id: 23, src: '/assets/image/spons/footer_spon_23.png', alt: '나오도담', width: '120px', height: '90px', url: '' },
    { id: 24, src: '/assets/image/spons/footer_spon_24.png', alt: 'Core Ground', width: '150px', height: '80px', url: 'https://www.coreground.kr/' },
    { id: 25, src: '/assets/image/spons/footer_spon_25.png', alt: '스포츠인사이트', width: '170px', height: '90px', url: 'https://sportsinsite.kr/' },
    { id: 26, src: '/assets/image/spons/footer_spon_26.png', alt: '72크래프트', width: '90px', height: '90px', url: '' },
    { id: 27, src: '/assets/image/spons/footer_spon_27.png', alt: '농업회사법인(주)진주', width: '110px', height: '90px', url: '' },
    { id: 28, src: '/assets/image/spons/footer_spon_28.png', alt: 'INS', width: '150px', height: '80px', url: 'https://www.baduk-in.com/' },
    { id: 29, src: '/assets/image/spons/footer_spon_29.png', alt: '멀티플라이', width: '160px', height: '80px', url: '' },
    { id: 30, src: '/assets/image/spons/footer_spon_30.png', alt: 'Besnob', width: '170px', height: '50px', url: '' },
    { id: 31, src: '/assets/image/spons/footer_spon_31.png', alt: '케이 퍼포먼스', width: '170px', height: '50px', url: '' },
    {
        id: 32,
        src: '/assets/image/spons/footer_spon_32.png',
        alt: '커넥팅라이프',
        width: '180px',
        height: '80px',
        url: 'http://le-ports.com/main/index',
    },
    { id: 33, src: '/assets/image/spons/footer_spon_33.png', alt: '하나문화', width: '90px', height: '90px', url: '' },
];

function SampleNextArrow(props: any) {
    const { className, style, onClick } = props;
    return <div className={className} style={{ ...style, display: 'block' }} onClick={onClick} />;
}

function SamplePrevArrow(props: any) {
    const { className, style, onClick } = props;
    return <div className={className} style={{ ...style, display: 'block' }} onClick={onClick} />;
}

class MainSliderComponent extends Component {
    render() {
        const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 5,
            slidesToScroll: 5,
            autoplay: true,
            loop: true,
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        arrow: false,
                        slidesToShow: 3,
                        slidesToScroll: 3,
                    },
                },
                {
                    breakpoint: 720,
                    settings: {
                        arrow: false,
                        slidesToShow: 2,
                        slidesToScroll: 2,
                    },
                },
                {
                    breakpoint: 480,
                    settings: {
                        arrow: false,
                        slidesToShow: 2,
                        slidesToScroll: 2,
                    },
                },
                {
                    breakpoint: 320,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    },
                },
            ],
        };
        return (
            <>
                <h4>함께하는 이들</h4>
                <div style={{ paddingTop: '15px' }}>
                    <Slider {...settings}>
                        {images.map((item: any) => {
                            return (
                                <div key={item.id}>
                                    <a href={item.url} target={'_blank'}>
                                        <img src={item.src} width={item.width} />
                                    </a>
                                </div>
                            );
                        })}
                    </Slider>
                </div>
            </>
        );
    }
}

export default MainSliderComponent;
