import { JsonObject, JsonProperty } from 'json2typescript';
import QuestionItem from './QuestionItem';

@JsonObject('question')
class Question {
    @JsonProperty('questionNum')
    questionNum: number = 1;
    @JsonProperty('questionTitle')
    questionTitle: string = '';
    @JsonProperty('question')
    question: string = '';
    @JsonProperty('questionnaireType')
    questionnaireType: string = '';
    @JsonProperty('selectType')
    selectType?: string = 'single';
    @JsonProperty('children')
    children?: Question[] = [];
    @JsonProperty('items')
    items: QuestionItem[] = [
        { itemNo: 1, content: '' },
        { itemNo: 2, content: '' },
        { itemNo: 3, content: '' },
        { itemNo: 4, content: '' },
        { itemNo: 5, content: '' },
    ];
}

export default Question;
