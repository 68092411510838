import React from 'react';
import Vas from '../../../domain/recording/Vas';

interface TbProps {
    recording?: Vas[];
    handler?: (e: any) => void;
}

function VasTbComponent(props: TbProps) {
    if (!props.recording || props.recording?.length == 0) {
        return (
            <table className="center-check-table" cellPadding="0px" cellSpacing="0px">
                <thead>
                    <tr>
                        <td>Seq.</td>
                        <td>통증부위</td>
                        <td colSpan={6}>통증강도</td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>1</td>
                        <td>
                            <input type="text" placeholder="부위선택" disabled />
                        </td>
                        <td className="center-bg-gray" width="70">
                            측정값
                        </td>
                        <td>
                            <input type="text" onChange={props.handler} />
                        </td>
                    </tr>
                </tbody>
            </table>
        );
    } else {
        return (
            <table className="center-check-table" cellPadding="0px" cellSpacing="0px">
                <thead>
                    <tr>
                        <td>Seq.</td>
                        <td>통증부위</td>
                        <td colSpan={6}>통증강도</td>
                    </tr>
                </thead>
                <tbody>
                    {props.recording?.map((vas, idx) => {
                        let recording = vas.recording;
                        let vasPart = vas.partName;
                        return (
                            <tr key={idx}>
                                <td>{vas.seq}</td>
                                <td>
                                    <input id={vas.part} type="text" value={vasPart} placeholder="부위선택" disabled />
                                </td>
                                <td className="center-bg-gray" width="70">
                                    측정값
                                </td>
                                <td>
                                    <input id={vas.part} name="vas" type="text" onChange={props.handler} value={recording[0]} />
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        );
    }
}

export default VasTbComponent;
