export enum InfraCategoryType {
    //스포츠 시설 번호
    SPORT_INFRA_NO = 1,
    //부대 시설 번호
    SUB_INFRA_NO = 2,
    //관광 시설 번호
    TOURISM_NO = 3,
    //숙박 시설 번호
    LODGMENT_NO = 16,
    //음식 시설 번호
    FOOD_NO = 17,
}
