import React, { useEffect, useState } from 'react';
import RoleGroupType from '../../../../domain/user/RoleGroupType';
import UserSearchCondition from '../../../../domain/user/param/UserSearchCondition';

interface AdminManagerInfoFilterProps {
    userSearchCondition: UserSearchCondition;
    setUserSearchCondition: (condition: UserSearchCondition) => void;
}

function AdminManagerInfoFilter(props: AdminManagerInfoFilterProps) {
    const [searchCont, setSearchCont] = useState<string>();
    const [searchWord, setSearchWord] = useState<string>();
    const [signUpApprove, setSignUpApprove] = useState<boolean>();
    const [roleName, setRoleName] = useState<string>();

    const keyEventHandler = (e: any) => {
        if (e.key == 'Enter') {
            searchClickHandler();
        }
    };
    //('${RoleGroupType.SYSTEMS},${RoleGroupType.MUNICIPALITY},${RoleGroupType.CENTER}');
    const searchClickHandler = () => {
        const userSearchCondition = new UserSearchCondition(signUpApprove, searchCont, searchWord, roleName);
        props.setUserSearchCondition(userSearchCondition);
    };

    useEffect(() => {
        console.log(props.userSearchCondition);
        setSearchWord(props.userSearchCondition.searchWord);
        setRoleName(props.userSearchCondition.roleGroupNames);
    }, []);

    return (
        // <div className="flex-basic">
        <>
            <div className="member-join" style={{ display: 'flex', padding: '5px' }}>
                <select
                    value={roleName}
                    onChange={(event) => {
                        let val = event.target.value;
                        if (val === 'all') {
                            val = `${RoleGroupType.SYSTEMS},${RoleGroupType.MUNICIPALITY},${RoleGroupType.CENTER}`;
                        }

                        setRoleName(val);

                        document.getElementById('keyHandler')?.focus();
                    }}
                >
                    <option value={'all'}>전체</option>
                    <option value={`${RoleGroupType.SYSTEMS}`}>시스템 관리자그룹</option>
                    <option value={`${RoleGroupType.MUNICIPALITY}`}>지자체 관리자그룹</option>
                    <option value={`${RoleGroupType.CENTER}`}>재활센터 관리자그룹</option>
                </select>
                {/* </div>
            <div className="member-search"> */}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {/* <select
                    id="searchCont"
                    onChange={(evt) => {
                        let cont = evt.currentTarget.value;
                        setSearchCont(cont);
                    }}
                >
                    <option value={''}>이름</option>
                    <option value={'email'}>이메일</option>
                    <option value={'mobile'}>모바일번호</option>
                </select> */}
                <input
                    onChange={(event) => {
                        const searchWord = event.currentTarget.value;
                        setSearchWord(searchWord);
                    }}
                    onKeyPress={keyEventHandler}
                    type="text"
                    placeholder="이름이나 팀 명을 입력하세요!"
                    value={searchWord}
                    style={{ width: '240px' }}
                ></input>
                <button className="bg-blue white round" onClick={searchClickHandler}>
                    <i className="fas fa-search"></i>검색
                </button>
                <input id="keyHandler" value="" onKeyPress={keyEventHandler} style={{ width: '0px', border: '0px' }} />
            </div>
            {/*member-info*/}
        </>
        // </div>
    );
}

export default AdminManagerInfoFilter;
