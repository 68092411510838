import { useEffect, useState } from 'react';
import Event from '../../domain/event/Event';
import EventSearchCondition from '../../domain/param/EventSearchCondition';
import EventService from '../../service/event/EventService';
import EventServiceImpl from '../../service/event/impl/EventServiceImpl';
import Paging from '../../domain/common/Paging';
const eventService: EventService = new EventServiceImpl();
function useRetrieveEventsByCondition(eventSearchCondition?: EventSearchCondition, paging?: Paging) {
    const [events, setEvents] = useState<Event[]>();
    const [eventsCnt, setEventsCnt] = useState<number>(0);

    useEffect(() => {
        if(eventSearchCondition && paging) {
            eventService.getEventServiceBy(eventSearchCondition, paging)
            .then((events: Event[]) => {
                setEvents(events);
            });

            eventService.getEventServiceCntBy(eventSearchCondition)
            .then((eventsCnt: number) => {
                setEventsCnt(eventsCnt);
            });
        }
    }, [eventSearchCondition, paging]);

    return {events, eventsCnt};
}

export default useRetrieveEventsByCondition;