import React from 'react';
import AdminMainHeader from '../../layout/AdminMainHeader';
import AdminMainSider from '../../layout/AdminMainSider';

interface MatchParams {
    url: string;
}

function AdminSurveyContainer(props: MatchParams) {
    return (
        <div className="main-content">
            <div className="swipe-area"></div>
            <a data-toggle=".admin-container" id="sidebar-toggle">
                {' '}
                <span className="bar"></span> <span className="bar"></span> <span className="bar"></span>{' '}
            </a>
            <div className="content">
                <div className="admin-board">
                    <div className="member-tit">
                        <h3>설문조사 만들기</h3>
                    </div>
                    <div className="member-list">
                        <table cellPadding="0" cellSpacing="0">
                            <tr>
                                <td className="navy white w5">구분</td>
                                <td className="left-line txt-left">
                                    <input type="radio" name="surveyType" checked={true}></input>운동재활
                                    <br />
                                    <input type="radio" name="surveyType"></input>만족도검사
                                </td>
                            </tr>
                            <tr>
                                <td className="navy white">수신자</td>
                                <td className="bg-light-gray left-line txt-left">
                                    <select className="bg-blue white">
                                        <option>선수</option>
                                        <option>관리자</option>
                                        <option>이용자</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td className="navy  white">활성화</td>
                                <td className="left-line txt-left">
                                    <select className="bg-blue white">
                                        <option>비활성화</option>
                                        <option>활성화</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td className="navy  white">
                                    설문조사
                                    <br />
                                    제목
                                </td>
                                <td className="bg-light-gray left-line txt-left">
                                    <input className="s" type="text" placeholder="설문조사 제목 입력"></input>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div className="member-list">
                        <table cellPadding="0" cellSpacing="0">
                            <tr>
                                <td className="navy  white w5">항목제목</td>
                                <td className="left-line txt-left">
                                    <input className="s" type="text" placeholder="1.귀하의 최근 수면의 질은 어떠신가요?"></input>
                                </td>
                                <td className="w10 txt-right">
                                    <button className="bg-white round">
                                        <i className="fas fa-sort-up"></i>
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td className="navy  white">유형</td>
                                <td className="bg-light-gray left-line txt-left">
                                    <select className="bg-blue white">
                                        <option>선호도형</option>
                                    </select>
                                </td>
                                <td className="bg-light-gray w5 txt-right">
                                    <button className="bg-white round">
                                        <i className="fas fa-sort-down"></i>
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td className="navy  white">점수범위</td>
                                <td className="left-line txt-left">
                                    <input type="text" placeholder="매우나쁘다"></input>
                                    <select className="border-blue blue">
                                        <option>1</option>
                                        <option>2</option>
                                    </select>
                                    -
                                    <select className="border-blue blue">
                                        <option>5</option>
                                        <option>4</option>
                                    </select>
                                    <input type="text" placeholder="매우좋다"></input>
                                </td>
                                <td className="w10 txt-right">
                                    <button className="bg-blue white">
                                        <i className="fas fa-minus-circle"></i>삭제
                                    </button>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div className="member-list">
                        <table cellPadding="0" cellSpacing="0">
                            <tr>
                                <td className="navy  white w5">항목제목</td>
                                <td className="bg-light-gray left-line txt-left">
                                    <input className="s" type="text" placeholder="설문제목입력"></input>
                                </td>
                                <td className="bg-light-gray w10 txt-right">
                                    <button className="bg-white round">
                                        <i className="fas fa-sort-up"></i>
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td className="navy  white">유형</td>
                                <td className="left-line txt-left">
                                    <select className="bg-blue white">
                                        <option>선호도형</option>
                                    </select>
                                </td>
                                <td className="w10 txt-right">
                                    <button className="bg-white round">
                                        <i className="fas fa-sort-down"></i>
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td className="navy  white">점수범위</td>
                                <td className="bg-light-gray left-line txt-left">
                                    <input type="text" placeholder="전혀 그렇지 않다"></input>
                                    <select className="border-blue blue">
                                        <option>1</option>
                                        <option>2</option>
                                    </select>
                                    -
                                    <select className="border-blue blue">
                                        <option>5</option>
                                        <option>4</option>
                                    </select>
                                    <input type="text" placeholder="매우 그렇다"></input>
                                </td>
                                <td className="bg-light-gray w10 txt-right">
                                    <button className="bg-blue white">
                                        <i className="fas fa-minus-circle"></i>삭제
                                    </button>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div className="member-btn flex-basic">
                        <div className="btn-del">
                            <button className="bg-blue white">
                                <i className="fas fa-plus-circle"></i>항목추가하기
                            </button>
                        </div>
                        <div className="list-ea">
                            <button className="bg-gray white">
                                <i className="fas fa-save"></i>저장하기
                            </button>
                            <button className="bg-white round">
                                <i className="fas fa-folder-plus"></i>설문조사등록하기
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AdminSurveyContainer;
