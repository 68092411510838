import React, { useEffect, useState } from 'react';
import MiniSearchBar from '../../component/search/MiniSearchBar';
import UserLocation from '../../domain/user/UserLocation';
import { InfraCategoryType } from '../../domain/infra/InfraCategoryType';
import useCodeRetrieve from '../../hook/code/useCodeRetrieve';
import { CodeType } from '../../domain/code/CodeType';
import Code from '../../domain/code/Code';
import useParentInfraCategoriesRetrieve from '../../hook/infra/useParentInfraCategoriesRetrieve';
import RadioSelect from '../../component/search/filter/RadioSelect';
import MultiSelect from '../../component/search/filter/MultiSelect';
import useInfraOfCategoryRetrieve from '../../hook/infra/useInfraCategoryRetrieve';
import MultiSelectByInfraCategory from '../../component/search/filter/MultiSelectByInfraCategory';
import useChildCodeRetrieve from '../../hook/code/useChildCodeRetrieve';
interface CurrPositionInfraSearchConditionContainerProps {
    setSearchType: (no: number) => void;
    userLocation?: UserLocation;
}

function getOrderCodes() {
    const distanceCode = new Code();
    distanceCode.codeId = 8000;
    distanceCode.name = '거리순';
    const regiCode = new Code();
    regiCode.codeId = 8001;
    regiCode.name = '등록순';

    const codes: Code[] = [regiCode, distanceCode];

    return codes;
}

function CurrPositionInfraSearchConditionContainer(props: CurrPositionInfraSearchConditionContainerProps) {
    const {parentInfraCategories} = useParentInfraCategoriesRetrieve();
    const [searchType, setSearchType] = useState<number>(InfraCategoryType.SPORT_INFRA_NO);
    const [sortType, setSortType] = useState<number>(8001);
    const regionCodes = useChildCodeRetrieve(20001);
    const childInfraCategories = useInfraOfCategoryRetrieve(searchType);
    const regiOrDiCodes = getOrderCodes();

    useEffect(() => {
        props.setSearchType(searchType);
    }, [searchType])

    return (
        <>
            <MiniSearchBar></MiniSearchBar>
            <RadioSelect initChoiceId={InfraCategoryType.SPORT_INFRA_NO} codeType={CodeType.PARENT_INFRA_CATEGORY} setType={setSearchType} title={"구분"} codes={Code.convertTo(parentInfraCategories)}></RadioSelect> 
            {
                childInfraCategories?
                <MultiSelectByInfraCategory codeType={CodeType.CHILD_INFRA_CATEGORY} title="시설 분류" infraCategories={childInfraCategories}></MultiSelectByInfraCategory>
                :
                <></>
            }
            {/* {
                <RadioSelect initChoiceId={8001} setType={setSortType} title={"정렬"} codes={regiOrDiCodes}></RadioSelect>
            } */}
            {
                regionCodes?
                <MultiSelect codeType={CodeType.REGION} title="지역" codes={regionCodes}></MultiSelect>
                :
                <></>
            }
        </>
    )
}

export default CurrPositionInfraSearchConditionContainer;