import React, { useState, useEffect } from 'react';
import Actions from '../ActionComponent';
import RehabServiceImpl from '../../../service/impl/RehabServiceImpl';
import RehabService from '../../../service/RehabService';
import TargetPerson from '../../../domain/TargetPerson';
import DivBtnComponent from './utils/DivBtnComponent';
import { JsonConvert } from 'json2typescript';
import CheckCondition from '../../../domain/recording/CheckCondition';
import Vas from '../../../domain/recording/Vas';
import VasTbComponent from './utils/VasTbComponent';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import RehabHistoryService from '../../../service/RehabHistoryService';
import RehabHistoryServiceImpl from '../../../service/impl/RehabHistoryServiceImpl';
import RehabTargetHistory from '../../../domain/RehabTargetHistory';

const rehabService: RehabService = new RehabServiceImpl();
const rehabHistoryService: RehabHistoryService = new RehabHistoryServiceImpl();

const jsonConvert: JsonConvert = new JsonConvert();

const vasPart = [
    { id: 'lflank', className: 'check-dot check-dot-lflank', value: '1.왼옆구리' },
    { id: 'lwrist', className: 'check-dot check-dot-lwrist', value: '2.왼손목' },
    { id: 'lthigh', className: 'check-dot check-dot-lthigh', value: '3.왼허벅지' },
    { id: 'lankle', className: 'check-dot check-dot-lankle', value: '4.왼발목' },
    { id: 'lfoot', className: 'check-dot check-dot-lfoot', value: '5.왼발' },
    { id: 'neck', className: 'check-dot check-dot-neck', value: '6.목' },
    { id: 'rshoul', className: 'check-dot check-dot-rshoul', value: '7.오른어깨' },
    { id: 'lshoul', className: 'check-dot check-dot-lshoul', value: '8.왼어깨' },
    { id: 'chest', className: 'check-dot check-dot-chest', value: '9.가슴' },
    { id: 'stomach', className: 'check-dot check-dot-stomach', value: '10.복부' },
    { id: 'rknee', className: 'check-dot check-dot-rknee', value: '11.오른무릎' },
    { id: 'lknee', className: 'check-dot check-dot-lknee', value: '12.왼무릎' },
    { id: 'rflank', className: 'check-dot check-dot-rflank', value: '13.오른옆구리' },
    { id: 'rwrist', className: 'check-dot check-dot-rwrist', value: '14.오른손목' },
    { id: 'rthigh', className: 'check-dot check-dot-rthigh', value: '15.오른허벅지' },
    { id: 'rankle', className: 'check-dot check-dot-rankle', value: '16.오른발목' },
    { id: 'rfoot', className: 'check-dot check-dot-rfoot', value: '17.오른발' },
    { id: 'back', className: 'check-dot check-dot-back', value: '18.등' },
    { id: 'waist', className: 'check-dot check-dot-waist', value: '19.허리' },
    { id: 'hips', className: 'check-dot check-dot-hips', value: '20.엉덩이' },
    { id: 'lclaf', className: 'check-dot check-dot-lclaf', value: '21.왼종아리' },
    { id: 'rclaf', className: 'check-dot check-dot-rclaf', value: '22.오른종아리' },
];

interface IPopup {
    targetNo: number;
    targetPerson: TargetPerson;
    closingHandler: () => void;
}

function useConditionsHook(recording?: RehabTargetHistory) {
    const [conditions, setConditions] = useState<CheckCondition>(new CheckCondition());
    useEffect(() => {
        if (recording) recording.conditions = JSON.stringify(conditions);
    }, [conditions]);
    return { conditions, setConditions };
}

function useVasHook(vas?: Vas[], recording?: RehabTargetHistory) {
    const { conditions, setConditions } = useConditionsHook(recording);
    useEffect(() => {
        if (vas && conditions) {
            conditions.vas = vas;
            setConditions((prevState) => {
                return {
                    ...prevState,
                    ['conditions']: conditions,
                };
            });
        }
    }, [vas]);

    return { conditions, setConditions };
}

function CheckPopupComponent(props: IPopup) {
    const actions = Actions();

    const [vas, setVas] = useState<Vas[]>();
    const [recordingItem, setRecordingItem] = useState<RehabTargetHistory>(new RehabTargetHistory());

    const { conditions, setConditions } = useVasHook(vas, recordingItem);

    const [history, setHistory] = useState<RehabTargetHistory[]>();
    const [hOptions, setHOptions] = useState(<></>);
    const [selectedHistory, setSelectedHistory] = useState<number>(0);

    const closingHandler = props.closingHandler;
    const targetPerson = props.targetPerson;
    const targetNo: number = props.targetNo;

    const [isInputting, setIsInputting] = useState(true);

    useEffect(() => {
        rehabHistoryService.getRehabHistoryForTarget(targetNo).then((history: RehabTargetHistory[]) => {
            let recording = history.find((item, idx) => {
                return item.targetState == 8001;
            });

            if (recording) {
                setRecordingItem(recording);
                let conditions = recording.conditions;

                if (conditions) {
                    let checkConditions: CheckCondition = jsonConvert.deserializeObject(JSON.parse(conditions), CheckCondition);
                    setConditions(checkConditions);
                    if (checkConditions.vas) {
                        setVas(checkConditions.vas);
                    }
                } else {
                    setVas(new Array());
                }
            } else {
                let newHistory = new RehabTargetHistory();
                newHistory.historyNo = -1;
                newHistory.targetState = 8001;
                newHistory.conditions = JSON.stringify(new CheckCondition());
                // 신규 이력 추가
                history.push(newHistory);
                setRecordingItem(newHistory);
                setVas(new Array());
            }

            setHistory(history);
            generateHistoryOption(history);
        });
    }, []);

    const generateHistoryOption = (history: RehabTargetHistory[]) => {
        if (history) {
            let size = history.length;
            let options = (
                <>
                    {history?.map((item, idx) => {
                        let displayTit = item.getRegDate();
                        if (item.targetState == 8001) {
                            displayTit = '저장 된 상태확인 수정 중...';
                            if (item.historyNo == -1) displayTit = '상태확인 입력 중 ...';
                            setSelectedHistory(item.historyNo);
                        }

                        return (
                            <option key={idx} value={item.historyNo}>
                                {displayTit}
                            </option>
                        );
                    })}
                </>
            );

            setHOptions(options);
        }
    };

    const inputChangeHandler = (evt: any) => {
        if (isInputting) {
            let inputElem = evt.target;
            let target = inputElem.name;
            let targetKey = inputElem.id;

            let inputVal = inputElem.value;

            if (target == 'conditions') {
                let tempCondition = new CheckCondition();

                if (conditions) {
                    tempCondition.ss = conditions.ss;
                    tempCondition.mh = conditions.mh;
                    tempCondition.fh = conditions.fh;
                    tempCondition.ha = conditions.ha;
                    tempCondition.rs = conditions.rs;

                    if (targetKey == 'ss') tempCondition.ss = inputVal;
                    if (targetKey == 'mh') tempCondition.mh = inputVal;
                    if (targetKey == 'fh') tempCondition.fh = inputVal;
                    if (targetKey == 'ha') tempCondition.ha = inputVal;
                    if (targetKey == 'rs') tempCondition.rs = inputVal;
                }

                tempCondition.vas = vas;
                setConditions(tempCondition);
            } else {
                let vas = conditions?.vas;
                let arr = new Array<Vas>();

                if (vas) arr = arr.concat(vas);

                arr.forEach((item) => {
                    if (item.part == target) {
                        targetKey = targetKey.replace(target + '_', '');
                        item.recording[targetKey] = inputVal;
                    }
                });

                setVas(arr);
            }
        } else {
            confirmAlert({
                title: '경고',
                message: '이력확인 중에는 입력 할 수 없습니다.',
                buttons: [{ label: '확인', onClick: () => {} }],
            });
        }
    };

    const vasInputHandler = (evt: any) => {
        if (isInputting) {
            let target = evt.target;
            let selectedClass: string = target.className;
            let targetId: string = target.id;
            let arr = new Array<Vas>();

            if (conditions?.vas) {
                arr = arr.concat(conditions?.vas);
            }

            if (selectedClass.indexOf('check-dot-select') > -1) {
                target.className = selectedClass.replace(' check-dot-select', '');
                let spliceIdx = -1;
                for (let i = 0; i < arr.length; i++) {
                    let temp = arr[i];
                    if (temp.part == targetId) {
                        spliceIdx = i;
                    }
                }
                if (spliceIdx != -1) {
                    arr.splice(spliceIdx, 1);

                    for (let i = 0; i < arr.length; i++) {
                        let temp = arr[i];
                        temp.seq = i + 1; // 순서정보 재설정
                    }
                }
            } else {
                if (arr.length <= 5) {
                    target.className = selectedClass + ' check-dot-select';

                    let targetData;
                    for (let i = 0; i < vasPart.length; i++) {
                        let temp = vasPart[i];
                        if (temp.id == targetId) targetData = vasPart[i];
                    }
                    var newVas = new Vas();

                    if (targetData) {
                        let seq = arr.length + 1;
                        newVas.part = targetData.id;
                        newVas.partName = targetData.value;
                        newVas.seq = seq;
                    }

                    arr.push(newVas);
                }
            }
            setVas(arr);
        } else {
            confirmAlert({
                title: '경고',
                message: '이력확인 중에는 입력 할 수 없습니다.',
                buttons: [{ label: '확인', onClick: () => {} }],
            });
        }
    };

    const completeBtnHandler = (evt: any) => {
        let target = evt.target;
        let action = evt.target.id;

        if (action == 'close') {
            action = 'store';
            closeWindow();
        } else {
            if (isInputting) {
                storeChecking(action);
            } else {
                confirmAlert({
                    title: '경고',
                    message: '이력 확인 중에서는 저장할 수 없습니다.',
                    buttons: [{ label: '확인', onClick: () => {} }],
                });
            }
        }
    };

    const storeChecking = function (action: string) {
        let storingHistory = new RehabTargetHistory();
        if (typeof action === 'undefined') action = 'store';

        // 현재 상태 만 저장 진행 (이력 정보는 저장 되지 않도록!)
        let inputCondition = recordingItem?.conditions;

        storingHistory.historyNo = recordingItem.historyNo;
        storingHistory.conditions = inputCondition;
        storingHistory.targetNo = targetNo;
        storingHistory.action = action;

        rehabHistoryService.manageConditionForTarget(storingHistory).then((data: RehabTargetHistory) => {
            let reservater = targetPerson.reservater;
            let message = '<' + reservater?.name + '> 님의 상태확인 정보가 저장 되었습니다.';
            if (action === 'complete') {
                message = '<' + reservater?.name + '> 님의 상태확인이 완료 되었습니다. 측정 단계로 진행합니다.';
            }
            let conditionStr = data.conditions;
            let storedConditions: CheckCondition = jsonConvert.deserializeObject(JSON.parse(conditionStr), CheckCondition);

            confirmAlert({
                title: '저장완료',
                message: message,
                buttons: [
                    {
                        label: '확인',
                        onClick: () => {
                            setSelectedHistory(data.historyNo);
                            setRecordingItem(data);
                            setConditions(storedConditions);
                            if (action === 'complete') closeWindow();
                        },
                    },
                ],
            });
        });
    };

    const changeHistoryHandler = function (evt: any) {
        let target = evt.currentTarget;
        target.classList.remove('active');

        let selectedVal = target.value;

        setIsInputting(false);
        setSelectedHistory(selectedVal);

        let findTarget = history?.find((item) => {
            return item.historyNo == selectedVal;
        });

        if (findTarget) {
            if (findTarget.targetState == 8001) {
                setIsInputting(true);
                target.classList.add('active');
            }

            setRecordingItem(findTarget);

            let condition = jsonConvert.deserializeObject(JSON.parse(findTarget.conditions), CheckCondition);
            setConditions(condition);
            setVas(condition.vas);
        }
    };

    const closeWindow = function () {
        if (closingHandler) closingHandler();
        actions.checkClose();
    };

    const deleteVasHandler = () => {
        let newVasArr: Vas[] = [];
        setVas(newVasArr);
    };

    const popupContents = (
        <div className="center-popup wow  animate__animated animate__fadeInUp" data-wow-delay="0.2s">
            <div className="center-popup-title">
                <h1 className="center">
                    <i className="fa fa-check"></i> 상태측정
                </h1>

                <div className="flex">
                    <h1 className="center">
                        <i className="fas fa-user-tie"></i> {targetPerson?.reservater?.name}
                    </h1>
                    &nbsp;
                    <span className="center-birth">
                        {targetPerson?.reservater?.genderCode?.name} / {targetPerson?.reservater?.birthdate}
                    </span>
                </div>
                <div>
                    <button id="complete" className="center-button-complete" onClick={completeBtnHandler}>
                        확인완료 <i className="fas fa-file-download"></i>
                    </button>
                    &nbsp;
                    <button id="store" className="center-button-save" onClick={completeBtnHandler}>
                        임시저장 <i className="fas fa-file-download"></i>
                    </button>
                    &nbsp;
                    <button id="close" className="center-button-close" onClick={completeBtnHandler}>
                        닫기 <i className="fas fa-times-circle"></i>
                    </button>
                </div>
            </div>

            <div className="center-popup-cont">
                <div id="center-popup-list" className="center-popup-checking-box">
                    <div className="center-popup-check">
                        <p className="center-popup-tit">
                            <i className="fas fa-history"></i> &nbsp;상태확인 이전 내역 &nbsp;
                            <select id="historyBox" value={selectedHistory} onChange={changeHistoryHandler} className="active">
                                {hOptions}
                            </select>
                            {/* &nbsp;&nbsp;
                            <button>테이블로 이력 확인하기</button> */}
                        </p>
                    </div>
                    <div className="center-popup-check">
                        <p className="center-popup-tit">1. VAS 통증 부위 / 강도체크</p>
                        <button className="center-button-no" onClick={deleteVasHandler}>
                            <i className="fas fa-trash-alt"></i> 삭제
                        </button>
                    </div>
                    <div className="center-vas-check">
                        <div className="center-vas-check-bg">
                            <div id="check-dot-group">
                                <DivBtnComponent properties={vasPart} handler={vasInputHandler} defaultValue={vas}></DivBtnComponent>
                            </div>
                        </div>
                        <div className="center-vas-check-right">
                            <VasTbComponent recording={vas} handler={inputChangeHandler}></VasTbComponent>
                        </div>
                    </div>

                    <div className="center-popup-check-in">
                        <p className="center-popup-tit">2. 자각증상</p>
                        <textarea
                            id="ss"
                            name="conditions"
                            className="center-input-text"
                            rows={2}
                            value={conditions?.ss}
                            placeholder="내용입력"
                            onChange={inputChangeHandler}
                        />
                    </div>

                    <div className="center-popup-check-in">
                        <p className="center-popup-tit">3. 병력</p>
                        <textarea
                            id="mh"
                            name="conditions"
                            className="center-input-text"
                            rows={2}
                            value={conditions?.mh}
                            placeholder="내용입력"
                            onChange={inputChangeHandler}
                        />
                    </div>

                    <div className="center-popup-check-in">
                        <p className="center-popup-tit">4. 가족력</p>
                        <textarea
                            id="fh"
                            name="conditions"
                            className="center-input-text"
                            rows={2}
                            value={conditions?.fh}
                            placeholder="내용입력"
                            onChange={inputChangeHandler}
                        />
                    </div>

                    <div className="center-popup-check-in">
                        <p className="center-popup-tit">5. 생활습관</p>
                        <textarea
                            id="ha"
                            name="conditions"
                            className="center-input-text"
                            rows={2}
                            value={conditions?.ha}
                            placeholder="내용입력"
                            onChange={inputChangeHandler}
                        />
                    </div>

                    <div className="center-popup-check-in">
                        <p className="center-popup-tit">6. 선수반응상태</p>
                        <textarea
                            id="rs"
                            name="conditions"
                            className="center-input-text"
                            rows={2}
                            value={conditions?.rs}
                            placeholder="내용입력"
                            onChange={inputChangeHandler}
                        />
                    </div>
                </div>
            </div>
        </div>
    );

    return popupContents;
}

export default CheckPopupComponent;
