import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import Layout from '../layouts/Layout';
import Header from '../layouts/Header';
import SideBar from '../layouts/SideBar';

import pagination from './components/PaginationComponent';

import '../assets/css/center.css';
import '../assets/css/animate.min.css';
import ActionComponent from './components/ActionComponent';
import ExercisePopupComponent from './components/managements/ExercisePopupComponent';
import Exercise from '../domain/Exercise';
import PrescriptionServiceImpl from '../service/impl/PrescriptionServiceImpl';
import { JsonConvert } from 'json2typescript';
let centerKey: string = '';
const menuTitle: string = '* 운동처방관리';
const title: string = '운동처방관리';

const prescriptionService = new PrescriptionServiceImpl();
const jsonConverter = new JsonConvert();

function RehabManageExerciseComponent(props: any) {
    let path = props.location.pathname;
    centerKey = props.match.params.centerId;

    const [exercises, setExercises] = useState<Exercise[]>([]);
    let exercise: Exercise = new Exercise();

    const popupHandler = (evt: any) => {
        let action = evt.target.name;
        let idx = evt.target.value;
        let actions = ActionComponent();

        if (action) {
            if (action === 'createExer') {
                exercise = new Exercise();
            } else if (action === 'previewExer') {
                exercise = exercises[idx];
            }

            prescriptionService.getExerciseLOVs().then((data) => {
                actions.popupOpen();
                ReactDOM.render(
                    <ExercisePopupComponent
                        openType={action}
                        exercise={exercise}
                        closingHandler={closingPopupHandler}
                        bodyParts={data.bodyParts}
                        exerTypes={data.exerTypes}
                        sportTypes={data.sportTypes}
                    ></ExercisePopupComponent>,
                    document.getElementById('center-popup-wrap'),
                );
            });
        }
    };

    const closingPopupHandler = (evt: any) => {
        let pagination = { size: displaySize, page: currPage };
        prescriptionService.getExerciseListByTypes('', pagination).then((result: { data: Exercise[]; totalSize: number }) => {
            setExercises(result.data);
            setTotalSize(result.totalSize);
        });
    };

    const changeHandler = (evt: any) => {};

    const addItemElem = () => {
        return (
            <button className="center-button-adminplus" onClick={popupHandler} name="createExer">
                <i className="fas fa-notes-medical"></i> 운동처방 추가하기
            </button>
        );
    };
    const filters = () => {
        return (
            <>
                <div className="center-bnt-right3">
                    <div className="center-select-box">
                        <select className="center-select">
                            <option>전체</option>
                            <option>이름</option>
                            <option>생년월일</option>
                            <option>소속팀</option>
                            <option>운동종목</option>
                        </select>
                        <div className="center-search">
                            <input className="center-input" placeholder="검색어를 입력하세요" />
                            <button className="btn">
                                <i className="fas fa-search"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </>
        );
    };

    const popups = () => {
        return (
            <>
                <div id="center-popup-wrap"></div>
            </>
        );
    };

    const contents = (list: Exercise[]) => {
        let trs;
        let page = currPage ? currPage : 0;
        let pageSize = displaySize ? displaySize : 0;
        let rowNum = page * pageSize;

        if (list.length === 0) {
            trs = (
                <tr>
                    <td colSpan={9}>등록 된 처방이 없습니다.</td>
                </tr>
            );
        } else {
            trs = list.map((item, idx) => {
                let exerTypeCode = item.exerTypeCode;
                let exerItemCode = item.exerItemCode;
                return (
                    <tr key={item.exerNo}>
                        <td>
                            <input type="checkbox" value={item.exerNo} />{' '}
                        </td>
                        <td>{rowNum + idx + 1}</td>
                        <td>{exerTypeCode?.name}</td>
                        <td>{exerItemCode?.name}</td>
                        <td>{item.applyedLevel}단계</td>
                        <td>{item.title}</td>
                        <td>{item.exerciseWay}</td>
                        <td>
                            <select className="center-select" value={item.isReleased === 'true' ? 'true' : 'false'} onChange={changeHandler}>
                                <option value="true">공개</option>
                                <option value="false">비공개</option>
                            </select>
                        </td>
                        <td>
                            <button name="previewExer" className="center-button-yes" value={idx} onClick={popupHandler}>
                                <i className="fas fa-search-plus"></i> 보기
                            </button>
                        </td>
                    </tr>
                );
            });
        }

        let exerciseList = (
            <>
                <table className="center-list-table" cellPadding="0" cellSpacing="0">
                    <thead>
                        <tr>
                            <td>
                                <input type="checkbox" id="whole" />
                            </td>
                            <td>No.</td>
                            <td>구분</td>
                            <td>항목</td>
                            <td>단계</td>
                            <td>운동명</td>
                            <td>운동방법</td>
                            <td>공개여부</td>
                            <td>관리</td>
                        </tr>
                    </thead>
                    <tbody>{trs}</tbody>
                </table>
            </>
        );

        return exerciseList;
    };

    useEffect(() => {
        let pagination = { size: 10, page: 0 };
        prescriptionService.getExerciseListByTypes('', pagination).then((result: { data: Exercise[]; totalSize: number }) => {
            setExercises(result.data);
            setTotalSize(result.totalSize);
        });
    }, []);

    const [totalSize, setTotalSize] = useState(1);
    const [displaySize, setDisplaySize] = useState(10);
    const [currPage, setCurrPage] = useState(0);

    const pagingHandler = (pagination: { size: number; page: number }) => {
        setCurrPage(pagination.page);
        setDisplaySize(pagination.size);
        prescriptionService.getExerciseListByTypes('', pagination).then((result: { data: Exercise[]; totalSize: number }) => {
            setExercises(result.data);
            setTotalSize(result.totalSize);
        });
    };

    let exerciseLayout = (
        <Layout
            header={<Header></Header>}
            sideBar={<SideBar name={path}></SideBar>}
            path={path}
            naviTitle={title}
            menuTitle={menuTitle}
            popups={popups()}
            filter={filters()}
            pagination={pagination(pagingHandler, totalSize, currPage, displaySize)}
            addItemElem={addItemElem()}
        >
            {contents(exercises)}
        </Layout>
    );

    return exerciseLayout;
}

export default RehabManageExerciseComponent;
