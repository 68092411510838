import React from 'react';
import Actions from '../../ActionComponent';
import QnA from '../../../../domain/QnA';

let qnaTitles = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J'];
let itemNumbers = ['24EA', '2460', '2461', '2462', '2463', '2464', '2465', '2466', '2467', '2468', '2469', '246A'];

const tabHandler = (evt: any) => {
    let actions = Actions();
    let target = evt.target;
    let inputVal = target.value;
    let tabName: string = 'tab' + inputVal;

    Object.entries(actions).map(([key, value]) => {
        if (key === tabName) {
            value();
        }
    });
};

const questionnaireTitles = (results: QnA[]) => {
    return (
        <>
            <ul>
                {results?.map((li, idx) => {
                    let id = 'tabon' + (idx + 1);
                    let title = li.displayTitle;
                    let className = '';
                    if (idx === 0) {
                        className = 'tabon';
                    }
                    return (
                        <li id={id} className={className} key={idx}>
                            <button onClick={tabHandler} value={idx + 1}>
                                {title}
                            </button>
                        </li>
                    );
                })}
            </ul>
        </>
    );
};

const makeQuestion = (item: QnA, answerStr: string | undefined) => {
    let questionnaires;
    let questStr: string | undefined = item.questions;
    let answers: [] | undefined = [];
    let answer: {} | undefined;
    let questions;
    if (questStr) {
        questionnaires = JSON.parse(questStr);

        if (answerStr) {
            answers = JSON.parse(answerStr);
        }

        questions = questionnaires.map((q: any, num: number) => {
            let contents = q.items;
            let question = q.question;
            let questNum = q.questionNum;
            let answer: { questionNum: number; itemNoOfAnswer: number } = { questionNum: -1, itemNoOfAnswer: -1 };
            let selectedAnswer: string = '';

            if (answers) answer = answers[num];

            return (
                <div className="tab-story-number" key={num}>
                    <h2 className="center2">
                        제 {questNum}항목 - {question}
                    </h2>
                    <ul>
                        {contents.map((content: any, i: number) => {
                            let numb = String.fromCharCode(parseInt(itemNumbers[content.itemNo], 16));
                            selectedAnswer = answer?.itemNoOfAnswer === content.itemNo ? 'selected' : '';
                            return (
                                <li key={i} className={selectedAnswer}>
                                    {numb}. {content.content}
                                </li>
                            );
                        })}
                    </ul>
                </div>
            );
        });
    }

    return questions;
};

const questionnaireContents = (results: QnA[]) => {
    let questionnaireForm = results?.map((item, idx) => {
        let key = item.questionnaireKey;
        let answerStr: string | undefined = item.answer;

        let id = 'tabstory' + (idx + 1);
        let questionnaire = (
            <>
                <div className="center-center">{item.title}</div>
                <p>{item.description}</p>
                {makeQuestion(item, answerStr)}
            </>
        );

        let style = { display: 'none' };
        if (idx === 0) {
            style.display = 'block';
        }
        let frame = (
            <div id={id} key={id} style={style}>
                {questionnaire}
            </div>
        );

        return frame;
    });

    return questionnaireForm;
};

export default function GenerateQuestionnaireComponent(props: any) {
    let results = props.answers;

    return (
        <>
            <div className="tab-menu">{questionnaireTitles(results)}</div>
            <div className="tab-story">{questionnaireContents(results)}</div>
        </>
    );
}
