import React from 'react';
import { RootState } from '../../../../../../moldules/index';
import { useSelector } from 'react-redux';
import Schedule from '../../../../../../domain/schedule/Schedule';
import useTeamSchedules from '../../../../../../hook/schedule/useTeamSchedules';
import ScheduleCard from '../../../../components/ScheduleCard';

function MyPageScheduleAllViewListTypeTab() {
    const {myTeam} = useSelector((state: RootState) => state.userSessionReducer);
    const {teamSchedules} = useTeamSchedules(myTeam.teamNo);

    return (
        <>
            {
                teamSchedules?.map((teamSchedule: Schedule, index: number) => {
                    return (
                        <ScheduleCard key={index} schedule={teamSchedule}></ScheduleCard>
                    )
                })
            }
        </>
    )
}

export default MyPageScheduleAllViewListTypeTab;