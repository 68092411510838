import TotalVisitedTeamInfo from '../../../domain/visitedInfo/statistics/TotalVisitedTeamInfo';
import AbstractService from '../../AbstractService';
import VisitedTeamInfoStatisticsService from '../VisitedTeamInfoStatisticsService';
import TotalVisitedTeamInfoBySport from '../../../domain/visitedInfo/statistics/TotalVisitedTeamInfoBySport';
import APIServerConfig from '../../../config/APIServerConfig';
import TotalVisitedTeamInfoByTeamType from '../../../domain/visitedInfo/statistics/TotalVisitedTeamInfoByTeamType';
import TotalVisitedTeamInfoSearchCondition from '../../../domain/visitedInfo/statistics/TotalVisitedTeamInfoSearchCondition';
import VisitedMemberAccumInfo from '../../../domain/visitedInfo/statistics/VisitedMemberAccumInfo';
import searchCondition from '../../../moldules/SearchCondition';
class VisitedTeamInfoStatisticsServiceImpl extends AbstractService implements VisitedTeamInfoStatisticsService {
    getTotalVisitedTeamInfo(totalVisitedteamInfoSearchCondition: TotalVisitedTeamInfoSearchCondition): Promise<TotalVisitedTeamInfo[]> {
        return new Promise((resolve, reject) => {
            APIServerConfig.recordAPIAxios
                .get(`/visited-team-info/statistics/total-by-region`, {
                    params: totalVisitedteamInfoSearchCondition,
                })
                .then((response) => {
                    const totalVisitedTeamInfos: TotalVisitedTeamInfo[] = this.jsonConvert.deserializeArray(response.data, TotalVisitedTeamInfo);
                    resolve(totalVisitedTeamInfos);
                });
        });
    }

    getTotalVisitedTeamInfoBySport(totalVisitedteamInfoSearchCondition: TotalVisitedTeamInfoSearchCondition): Promise<TotalVisitedTeamInfoBySport[]> {
        return new Promise((resolve, reject) => {
            APIServerConfig.recordAPIAxios
                .get(`/visited-team-info/statistics/total-by-region-sport`, {
                    params: totalVisitedteamInfoSearchCondition,
                })
                .then((response) => {
                    const totalVisitedTeamInfos: TotalVisitedTeamInfoBySport[] = this.jsonConvert.deserializeArray(
                        response.data,
                        TotalVisitedTeamInfoBySport,
                    );
                    resolve(totalVisitedTeamInfos);
                });
        });
    }

    getTotalVisitedTeamInfoBySportTotal(
        totalVisitedteamInfoSearchCondition: TotalVisitedTeamInfoSearchCondition,
    ): Promise<TotalVisitedTeamInfoBySport[]> {
        return new Promise((resolve, reject) => {
            APIServerConfig.recordAPIAxios
                .get(`/visited-team-info/statistics/total-by-region-sport/total`, {
                    params: totalVisitedteamInfoSearchCondition,
                })
                .then((response) => {
                    const totalVisitedTeamInfos: TotalVisitedTeamInfoBySport[] = this.jsonConvert.deserializeArray(
                        response.data,
                        TotalVisitedTeamInfoBySport,
                    );
                    resolve(totalVisitedTeamInfos);
                });
        });
    }

    getTotalVisitedTeamInfosByTeamType(
        totalVisitedteamInfoSearchCondition: TotalVisitedTeamInfoSearchCondition,
    ): Promise<TotalVisitedTeamInfoByTeamType[]> {
        return new Promise((resolve, reject) => {
            APIServerConfig.recordAPIAxios
                .get(`/visited-team-info/statistics/total-by-region-teamtype`, {
                    params: totalVisitedteamInfoSearchCondition,
                })
                .then((response) => {
                    console.log(response.data);
                    const totalVisitedTeamInfos: TotalVisitedTeamInfoByTeamType[] = this.jsonConvert.deserializeArray(
                        response.data,
                        TotalVisitedTeamInfoByTeamType,
                    );
                    resolve(totalVisitedTeamInfos);
                });
        });
    }

    getTotalVisitedTeamInfosByTeamTypeTotal(
        totalVisitedteamInfoSearchCondition: TotalVisitedTeamInfoSearchCondition,
    ): Promise<TotalVisitedTeamInfoByTeamType[]> {
        return new Promise((resolve, reject) => {
            APIServerConfig.recordAPIAxios
                .get(`/visited-team-info/statistics/total-by-region-teamtype/total`, {
                    params: totalVisitedteamInfoSearchCondition,
                })
                .then((response) => {
                    const totalVisitedTeamInfos: TotalVisitedTeamInfoByTeamType[] = this.jsonConvert.deserializeArray(
                        response.data,
                        TotalVisitedTeamInfoByTeamType,
                    );
                    resolve(totalVisitedTeamInfos);
                });
        });
    }

    getVisitedMemberAccumInfoByRegion(searchCondition: TotalVisitedTeamInfoSearchCondition): Promise<VisitedMemberAccumInfo[]> {
        return new Promise((resolve) => {
            APIServerConfig.recordAPIAxios
                .get(`/visited-team-info/statistics/accum-info-by-region`, {
                    params: searchCondition,
                })
                .then((response) => {
                    const accumInfos: VisitedMemberAccumInfo[] = this.jsonConvert.deserializeArray(response.data, VisitedMemberAccumInfo);
                    resolve(accumInfos);
                });
        });
    }

    getVisitedMemberAccumInfoBySportOfRegion(searchCondition: TotalVisitedTeamInfoSearchCondition): Promise<VisitedMemberAccumInfo[]> {
        return new Promise((resolve) => {
            APIServerConfig.recordAPIAxios
                .get(`/visited-team-info/statistics/accum-info-by-region-sport`, {
                    params: searchCondition,
                })
                .then((response) => {
                    console.log(response.data);
                    const accumInfos: VisitedMemberAccumInfo[] = this.jsonConvert.deserializeArray(response.data, VisitedMemberAccumInfo);
                    resolve(accumInfos);
                });
        });
    }
    getVisitedMemberAccumInfoBySport(searchCondition: TotalVisitedTeamInfoSearchCondition): Promise<VisitedMemberAccumInfo[]> {
        return new Promise((resolve) => {
            APIServerConfig.recordAPIAxios
                .get(`/visited-team-info/statistics/accum-info-by-sport`, {
                    params: searchCondition,
                })
                .then((response) => {
                    const accumInfos: VisitedMemberAccumInfo[] = this.jsonConvert.deserializeArray(response.data, VisitedMemberAccumInfo);
                    resolve(accumInfos);
                });
        });
    }

    getVisitedMemberAccumInfoByTeamTypeOfRegion(searchCondition: TotalVisitedTeamInfoSearchCondition): Promise<VisitedMemberAccumInfo[]> {
        return new Promise((resolve) => {
            APIServerConfig.recordAPIAxios
                .get(`/visited-team-info/statistics/accum-info-by-region-teamtype`, {
                    params: searchCondition,
                })
                .then((response) => {
                    const accumInfos: VisitedMemberAccumInfo[] = this.jsonConvert.deserializeArray(response.data, VisitedMemberAccumInfo);
                    resolve(accumInfos);
                });
        });
    }
    getVisitedMemberAccumInfoByTeamType(searchCondition: TotalVisitedTeamInfoSearchCondition): Promise<VisitedMemberAccumInfo[]> {
        return new Promise((resolve) => {
            APIServerConfig.recordAPIAxios
                .get(`/visited-team-info/statistics/accum-info-by-teamtype`, {
                    params: searchCondition,
                })
                .then((response) => {
                    const accumInfos: VisitedMemberAccumInfo[] = this.jsonConvert.deserializeArray(response.data, VisitedMemberAccumInfo);
                    resolve(accumInfos);
                });
        });
    }

    // Services for Team type
    getVisitedTeamTotalForTeamType(searchCondition: TotalVisitedTeamInfoSearchCondition): Promise<VisitedMemberAccumInfo[]> {
        return new Promise((resolve) => {
            APIServerConfig.recordAPIAxios
                .get(`/visited-team-info/statistics/teamtypetotal`, {
                    params: searchCondition,
                })
                .then((response) => {
                    const accumInfos: VisitedMemberAccumInfo[] = this.jsonConvert.deserializeArray(response.data, VisitedMemberAccumInfo);
                    resolve(accumInfos);
                });
        });
    }
    getVisitedTeamStatusByTeamType(searchCondition: TotalVisitedTeamInfoSearchCondition): Promise<VisitedMemberAccumInfo[]> {
        return new Promise((resolve) => {
            APIServerConfig.recordAPIAxios
                .get(`/visited-team-info/statistics/teamtypestatus`, {
                    params: searchCondition,
                })
                .then((response) => {
                    const accumInfos: VisitedMemberAccumInfo[] = this.jsonConvert.deserializeArray(response.data, VisitedMemberAccumInfo);
                    resolve(accumInfos);
                });
        });
    }
    getVisitedTeamTypeStatusByRegion(searchCondition: TotalVisitedTeamInfoSearchCondition): Promise<VisitedMemberAccumInfo[]> {
        return new Promise((resolve) => {
            APIServerConfig.recordAPIAxios
                .get(`/visited-team-info/statistics/regionstatus`, {
                    params: searchCondition,
                })
                .then((response) => {
                    const accumInfos: VisitedMemberAccumInfo[] = this.jsonConvert.deserializeArray(response.data, VisitedMemberAccumInfo);
                    resolve(accumInfos);
                });
        });
    }
    // Services for sport type
    getVisitedTeamTotalForSportType(searchCondition: TotalVisitedTeamInfoSearchCondition): Promise<VisitedMemberAccumInfo[]> {
        return new Promise((resolve) => {
            APIServerConfig.recordAPIAxios
                .get(`/visited-team-info/statistics/sporttypetotal`, {
                    params: searchCondition,
                })
                .then((response) => {
                    const accumInfos: VisitedMemberAccumInfo[] = this.jsonConvert.deserializeArray(response.data, VisitedMemberAccumInfo);
                    resolve(accumInfos);
                });
        });
    }
    getVisitedTeamStatusBySportType(searchCondition: TotalVisitedTeamInfoSearchCondition): Promise<VisitedMemberAccumInfo[]> {
        return new Promise((resolve) => {
            APIServerConfig.recordAPIAxios
                .get(`/visited-team-info/statistics/sporttypestatus`, {
                    params: searchCondition,
                })
                .then((response) => {
                    const accumInfos: VisitedMemberAccumInfo[] = this.jsonConvert.deserializeArray(response.data, VisitedMemberAccumInfo);
                    resolve(accumInfos);
                });
        });
    }
    getVisitedTeamSportTypeStatusByRegion(searchCondition: TotalVisitedTeamInfoSearchCondition): Promise<VisitedMemberAccumInfo[]> {
        return new Promise((resolve) => {
            APIServerConfig.recordAPIAxios
                .get(`/visited-team-info/statistics/regionsportstatus`, {
                    params: searchCondition,
                })
                .then((response) => {
                    const accumInfos: VisitedMemberAccumInfo[] = this.jsonConvert.deserializeArray(response.data, VisitedMemberAccumInfo);
                    resolve(accumInfos);
                });
        });
    }

    getVisitedStatusByRegion(searchCondition: TotalVisitedTeamInfoSearchCondition): Promise<VisitedMemberAccumInfo[]> {
        return new Promise((resolve) => {
            APIServerConfig.recordAPIAxios
                .get(`/visited-team-info/statistics/visitedStatus`, {
                    params: searchCondition,
                })
                .then((response) => {
                    const accumInfos: VisitedMemberAccumInfo[] = this.jsonConvert.deserializeArray(response.data, VisitedMemberAccumInfo);
                    resolve(accumInfos);
                });
        });
    }

    getVisitedTeamStatusByPeriod(searchCondition: TotalVisitedTeamInfoSearchCondition): Promise<any> {
        return new Promise((resolve) => {
            APIServerConfig.recordAPIAxios.post(`/visited-team-info/period/summary`, searchCondition).then((response) => {
                resolve(response.data);
            });
        });
    }

    getVisitedTeamStatusByDay(searchCondition: TotalVisitedTeamInfoSearchCondition): Promise<any> {
        return new Promise((resolve) => {
            APIServerConfig.recordAPIAxios.post(`/visited-team-info/period/day`, searchCondition).then((response) => {
                resolve(response.data.detailForMonth);
            });
        });
    }

    getVisitedTeamStatusDetail(searchCondition: TotalVisitedTeamInfoSearchCondition): Promise<any> {
        return new Promise((resolve) => {
            APIServerConfig.recordAPIAxios.post(`/visited-team-info/period/detail`, searchCondition).then((response) => {
                console.log(response);
                resolve(response.data.detailForDay);
            });
        });
    }
}

export default VisitedTeamInfoStatisticsServiceImpl;
