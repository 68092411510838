import { JsonObject, JsonProperty } from "json2typescript";
@JsonObject("report")
class Report {
    @JsonProperty("targetNo")
    targetNo?: number;
    @JsonProperty("reportOwnerNo")
    reportOwnerNo?: string;
    @JsonProperty("reportXlsxFilePath")
    reportXlsxFilePath?: string;
    @JsonProperty("reportPdfFilePath")
    reportPdfFilePath?: string;
    @JsonProperty("reportRelease")
    reportRelease?: boolean;
}

export default Report;