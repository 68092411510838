import React, { useEffect, useState } from 'react';
import MyPageMainLayout from '../../../layout/MyPageMainLayout';
import MyPageRightContent from '../../../layout/MyPageRightContent';
import Infra from '../../../../../domain/infra/Infra';
import { InfraSearchList } from '../../../../../component/infra/InfraSearchList';
import FavoritesInfraServiceImpl from '../../../../../service/favorities/impl/FavoritesInfraServiceImpl';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../moldules/index';
import { InfraCategoryType } from '../../../../../domain/infra/InfraCategoryType';

const favoritesInfraService = new FavoritesInfraServiceImpl();

function UserMyPageFavorities() {
    const { user } = useSelector((state: RootState) => state.userSessionReducer);
    const [infraCategoryNo, setInfraCategoryNo] = useState<string>('1');
    const [tabSeq, setTabSeq] = useState<string>('sport');
    const [favoritiesInfas, setFavoritiesInfras] = useState<Infra[]>();

    useEffect(() => {
        favoritesInfraService.getUserFavoritiesInfras(user.userNo, infraCategoryNo).then((userFavoritesInfras) => {
            let infras = new Array<Infra>();
            userFavoritesInfras.map((userFavoritesInfra) => {
                if (userFavoritesInfra.favorityInfra) infras.push(Object.setPrototypeOf(userFavoritesInfra.favorityInfra, Infra.prototype));
            });

            setFavoritiesInfras(infras);
        });
    }, [infraCategoryNo]);

    return (
        <>
            <MyPageMainLayout>
                <MyPageRightContent contentTitle={'즐겨찾기'}>
                    <div className="my-in">
                        <div className="container3">
                            <ul className="tabs">
                                <li
                                    className={tabSeq == 'sport' ? 'active' : ''}
                                    onClick={() => {
                                        setTabSeq('sport');
                                        setInfraCategoryNo(String(InfraCategoryType.SPORT_INFRA_NO));
                                    }}
                                >
                                    <a>스포츠시설</a>
                                </li>
                                <li
                                    className={tabSeq == 'subInfra' ? 'active' : ''}
                                    onClick={() => {
                                        setTabSeq('subInfra');
                                        setInfraCategoryNo(String(InfraCategoryType.SUB_INFRA_NO));
                                    }}
                                >
                                    <a>부대시설</a>
                                </li>
                                <li
                                    className={tabSeq == 'lodgment' ? 'active' : ''}
                                    onClick={() => {
                                        setTabSeq('lodgment');
                                        setInfraCategoryNo(String(InfraCategoryType.LODGMENT_NO));
                                    }}
                                >
                                    <a>숙소</a>
                                </li>
                                <li
                                    className={tabSeq == 'food' ? 'active' : ''}
                                    onClick={() => {
                                        setTabSeq('food');
                                        setInfraCategoryNo(String(InfraCategoryType.FOOD_NO));
                                    }}
                                >
                                    <a>맛집</a>
                                </li>
                                {/* <li
                                    className={tabSeq == 'touring' ? 'active' : ''}
                                    onClick={() => {
                                        setTabSeq('touring');
                                        setInfraCategoryNo(String(InfraCategoryType.TOURISM_NO));
                                    }}
                                >
                                    <a>투어링</a>
                                </li> */}
                            </ul>
                            <div className="tab_container">
                                <div className="tab_content">
                                    {/* {tabPages[tabSeq]} */}
                                    {favoritiesInfas ? (
                                        favoritiesInfas.length > 0 ? (
                                            <InfraSearchList searchedInfras={favoritiesInfas}></InfraSearchList>
                                        ) : (
                                            <p>즐겨 찾기로 추가한 항목이 없습니다.</p>
                                        )
                                    ) : (
                                        <></>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </MyPageRightContent>
            </MyPageMainLayout>
        </>
    );
}

export default UserMyPageFavorities;
