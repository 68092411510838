import React from 'react';
import Event from '../../domain/event/Event';
import AdminEventsTableTr from './AdminEventsTableTr';
import AdminTable from './AdminTable';

interface AdminEventsTableProps {
    page: number;
    pagingSize: number;
    events?: Event[];
}

function AdminEventsTable(props: AdminEventsTableProps) {
    return (
        <AdminTable>
            <thead>
                <tr>
                    <td>No.</td>
                    <td>쿠폰코드</td>
                    <td>등록자</td>
                    <td>사용지역</td>
                    <td>사용대상시설</td>
                    <td>서비스형태</td>
                    <td>서비스</td>
                    <td>내용</td>
                    <td>쿠폰 시작일</td>
                    <td>쿠폰 만료일</td>
                    <td>사용량 / 수량</td>
                    <td>쿠폰 등록일</td>
                    <td>관리</td>
                </tr>
            </thead>
            <tbody>
                {props.events?.map((event: Event, index: number) => {
                    return <AdminEventsTableTr no={props.page * props.pagingSize + (index + 1)} key={index} event={event}></AdminEventsTableTr>;
                })}
            </tbody>
        </AdminTable>
    );
}

export default AdminEventsTable;
