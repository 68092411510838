import React, { useState, useEffect } from 'react';
import Prescription from '../../../../../domain/Prescription';
import { BasalPrescription } from '../../../../../domain/prescription/BasalPrescription';

interface IProps {
    handler: (evt: any) => void;
    prescription: BasalPrescription;
}
const BasalExerciseFormComponent = (props: IProps) => {
    const handler = props.handler;
    //let basalEp = props.prescription.basalExercisePrescription;
    const [basalEp, setBasalPrescription] = useState<BasalPrescription>(new BasalPrescription());

    useEffect(() => {
        if (props.prescription) setBasalPrescription(props.prescription);
    }, [props.prescription]);

    return (
        <>
            {/* Aerobic Training Area */}
            <div className="flex-basic">
                <div className="center-half">
                    <div className="prescription-basis">
                        <h2 className="center2">
                            <i className="fa fa-check"></i> Aerobic training
                        </h2>

                        <div className="center-bg-shadow-gray center-border-bottom">
                            <div className="center-pd10  flex">
                                <label className="center-w200 tit">1. Frequency </label>
                                <select
                                    value={basalEp.aeroFreq}
                                    name="basalExercisePrescription"
                                    className="center-select-scription"
                                    id="aeroFreq"
                                    onChange={handler}
                                >
                                    <option value="-1" disabled>
                                        선택해주세요
                                    </option>
                                    <option value="1">주1회</option>
                                    <option value="2">주2회</option>
                                    <option value="3">주3회</option>
                                    <option value="4">주4회</option>
                                    <option value="5">주5회</option>
                                    <option value="8">주8회</option>
                                    <option value="12">주12회</option>
                                    <option value="16">주16회</option>
                                </select>
                            </div>
                        </div>

                        <div className="center-bg-shadow-gray center-border-bottom">
                            <div className="center-pd10">
                                <label className="center-w200 tit">2. Warm up </label>
                                <div className="flex border-ba">
                                    <label className="center-w200 sub-tit">-Static stretching</label>{' '}
                                    <input
                                        name="basalExercisePrescription"
                                        className="center-input-gray-script"
                                        id="aeroWarmUpStatic"
                                        onChange={handler}
                                        value={basalEp.aeroWarmUpStatic}
                                    />
                                    <label>분</label>
                                </div>
                                <div className="flex border-ba">
                                    <label className="center-w200 sub-tit">-Dynamic stretching</label>{' '}
                                    <input
                                        name="basalExercisePrescription"
                                        className="center-input-gray-script"
                                        id="aeroWarmUpDyna"
                                        onChange={handler}
                                        value={basalEp.aeroWarmUpDyna}
                                    />
                                    <label>분</label>
                                </div>
                                <div className="flex border-ba">
                                    <label className="center-w200 sub-tit">-Foam Roller Stretching</label>{' '}
                                    <input
                                        name="basalExercisePrescription"
                                        className="center-input-gray-script"
                                        id="aeroWarmUpFoam"
                                        onChange={handler}
                                        value={basalEp.aeroWarmUpFoam}
                                    />
                                    <label>분</label>
                                </div>
                            </div>
                        </div>

                        <div className="center-bg-shadow-gray center-border-bottom">
                            <div className="center-pd10">
                                <div className="flex border-ba">
                                    <label className="center-w200 tit">3. Intensity </label>
                                    <input
                                        name="basalExercisePrescription"
                                        className="center-input-gray-script"
                                        id="aeroIntensity"
                                        onChange={handler}
                                        value={basalEp.aeroIntensity}
                                    />
                                    <label>%</label>
                                </div>
                                <div className="flex border-ba">
                                    <label className="center-w200 sub-tit">-VO2max</label>
                                    <input
                                        name="basalExercisePrescription"
                                        className="center-input-gray-script"
                                        id="aeroIntensityVo2"
                                        onChange={handler}
                                        value={basalEp.aeroIntensityVo2}
                                    />
                                    <label>㎖/㎏/min</label>
                                </div>
                            </div>
                        </div>

                        <div className="center-bg-shadow-gray center-border-bottom">
                            <div className="center-pd10  flex">
                                <label className="center-w200 tit">4. Time </label>
                                <select
                                    value={basalEp.aeroTime}
                                    name="basalExercisePrescription"
                                    className="center-select-scription"
                                    id="aeroTime"
                                    onChange={handler}
                                >
                                    <option value="-1" disabled>
                                        선택해주세요
                                    </option>
                                    <option value="10">10분</option>
                                    <option value="20">20분</option>
                                    <option value="30">30분</option>
                                    <option value="40">40분</option>
                                    <option value="50">50분</option>
                                    <option value="60">60분</option>
                                    <option value="70">70분</option>
                                    <option value="80">80분</option>
                                    <option value="90">90분</option>
                                    <option value="100">100분</option>
                                    <option value="110">110분</option>
                                    <option value="120">120분</option>
                                </select>
                            </div>
                        </div>

                        <div className="center-bg-shadow-gray ">
                            <div className="center-pd10">
                                <div className="flex border-ba">
                                    <label className="center-w200 tit">5. Type : </label>{' '}
                                    <input
                                        name="basalExercisePrescription"
                                        className="center-input-gray-script"
                                        id="aeroType"
                                        onChange={handler}
                                        value={basalEp.aeroType}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Weight tracking Area */}
                <div className="center-half">
                    <div className="prescription-basis">
                        <h2 className="center2">
                            <i className="fa fa-check"></i> Weight Training
                        </h2>

                        <div className="center-bg-shadow-gray center-border-bottom">
                            <div className="center-pd10  flex">
                                <label className="center-w200 tit">1. Frequency </label>
                                <select
                                    value={basalEp.weightFreq}
                                    name="basalExercisePrescription"
                                    className="center-select-scription"
                                    id="weightFreq"
                                    onChange={handler}
                                >
                                    <option value="-1" disabled>
                                        선택해주세요
                                    </option>
                                    <option value="1">주1회</option>
                                    <option value="2">주2회</option>
                                    <option value="3">주3회</option>
                                    <option value="4">주4회</option>
                                    <option value="5">주5회</option>
                                    <option value="8">주8회</option>
                                    <option value="12">주12회</option>
                                    <option value="16">주16회</option>
                                </select>
                            </div>
                        </div>

                        <div className="center-bg-shadow-gray center-border-bottom">
                            <div className="center-pd10">
                                <label className="center-w200 tit">2. Warm up </label>
                                <div className="flex border-ba">
                                    <label className="center-w200 sub-tit">-Static stretching</label>{' '}
                                    <input
                                        name="basalExercisePrescription"
                                        className="center-input-gray-script"
                                        id="weightWarmUpStatic"
                                        onChange={handler}
                                        value={basalEp.weightWarmUpStatic}
                                    />
                                    <label>분</label>
                                </div>
                                <div className="flex border-ba">
                                    <label className="center-w200 sub-tit">-Dynamic stretching</label>{' '}
                                    <input
                                        name="basalExercisePrescription"
                                        className="center-input-gray-script"
                                        id="weightWarmUpDyna"
                                        onChange={handler}
                                        value={basalEp.weightWarmUpDyna}
                                    />
                                    <label>분</label>
                                </div>
                                <div className="flex border-ba">
                                    <label className="center-w200 sub-tit">-Foam Roller Stretching</label>{' '}
                                    <input
                                        name="basalExercisePrescription"
                                        className="center-input-gray-script"
                                        id="weightWarmUpFoam"
                                        onChange={handler}
                                        value={basalEp.weightWarmUpFoam}
                                    />
                                    <label>분</label>
                                </div>
                            </div>
                        </div>

                        <div className="center-bg-shadow-gray center-border-bottom">
                            <div className="center-pd10">
                                <div className="flex border-ba">
                                    <label className="center-w200 tit">3. Intensity </label>
                                    <input
                                        name="basalExercisePrescription"
                                        className="center-input-gray-script"
                                        id="weightIntensity"
                                        onChange={handler}
                                        value={basalEp.weightIntensity}
                                    />
                                    <label>%</label>
                                </div>
                                <div className="flex border-ba">
                                    <label className="center-w200 sub-tit">-1RM</label>
                                    <input
                                        name="basalExercisePrescription"
                                        className="center-input-gray-script"
                                        id="weightIntensityVo2"
                                        onChange={handler}
                                        value={basalEp.weightIntensityVo2}
                                    />
                                    <label>㎏</label>
                                </div>
                            </div>
                        </div>

                        <div className="center-bg-shadow-gray center-border-bottom">
                            <div className="center-pd10  flex">
                                <label className="center-w200 tit">4. Time </label>
                                <select
                                    value={basalEp.weightTime}
                                    name="basalExercisePrescription"
                                    className="center-select-scription"
                                    id="weightTime"
                                    onChange={handler}
                                >
                                    <option value="-1" disabled>
                                        선택해주세요
                                    </option>
                                    <option value="10">10분</option>
                                    <option value="20">20분</option>
                                    <option value="30">30분</option>
                                    <option value="40">40분</option>
                                    <option value="50">50분</option>
                                    <option value="60">60분</option>
                                    <option value="70">70분</option>
                                    <option value="80">80분</option>
                                    <option value="90">90분</option>
                                    <option value="100">100분</option>
                                    <option value="110">110분</option>
                                    <option value="120">120분</option>
                                </select>
                            </div>
                        </div>

                        <div className="center-bg-shadow-gray ">
                            <div className="center-pd10">
                                <div className="flex border-ba">
                                    <label className="center-w200 tit">5. Type : </label>{' '}
                                    <input
                                        name="basalExercisePrescription"
                                        className="center-input-gray-script"
                                        id="weightType"
                                        onChange={handler}
                                        value={basalEp.weightType}
                                    />
                                    <label></label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default BasalExerciseFormComponent;
