import React, { useEffect, useState } from 'react';
import SearchPageHeader from '../layout/SearchPageHeader';

import SearchIn from '../../../component/layout/SearchIn';
import SearchRight from '../../../component/layout/SearchRight';

import SearchOfInfraWithCategoryContainer from '../../../container/search/SearchOfInfraWithCategoryContainer';

import { useParams } from 'react-router-dom';

import SearchCnt from '../../../component/search/SearchCnt';
import SearchIteamPaging from '../../../component/search/SearchItemPaging';

import useSearchPaging from './hook/useSearchPaging';
import { InfraCategoryType } from '../../../domain/infra/InfraCategoryType';
import InfraSearchCondition from '../../../domain/infra/param/InfraSearchCondition';
import useInfraRetrieveBySearchCondition from '../../../hook/infra/useInfraRetrieveBySearchCondition';

import { NEAR_DISTAINCE } from '../../../config/Constant';

/**
 * searchType은 검색 유형, 팀 검색인지 시설 조회 인지를 관리하는 플래그 타입
 */

function SearchListContainer() {
    //const subInfraNos = `${InfraCategoryType.SUB_INFRA_NO},${InfraCategoryType.LODGMENT_NO},${InfraCategoryType.TOURISM_NO},${InfraCategoryType.FOOD_NO}`;
    let parentInfraCategoryNo = 0;
    let listTitle = '';

    const params: any = useParams();

    let isRecommendation: boolean;
    let latitude: number = params.latitude;
    let longitude: number = params.longitude;
    let childInfraCategoryNo = params.categoryNo;
    let searchingType = params.searchType;
    let parentRegionCodeId: string = '20001';

    if (params.parentCategory === 'sub') {
        //부대 시설 번호
        parentInfraCategoryNo = InfraCategoryType.SUB_INFRA_NO;
        listTitle += '부대 시설 목록';
    } else if (params.parentCategory === 'touring') {
        //관광 시설 번호
        parentInfraCategoryNo = InfraCategoryType.TOURISM_NO;
        listTitle += '관광 시설 목록';
    } else if (params.parentCategory === 'lodgment') {
        //숙박 시설 번호
        parentInfraCategoryNo = InfraCategoryType.LODGMENT_NO;
        listTitle += '숙박 시설 목록';
    } else if (params.parentCategory === 'food') {
        // 음식 번호
        parentInfraCategoryNo = InfraCategoryType.FOOD_NO;
        listTitle += '맛집 목록';
    } else {
        // 스포츠 시설 번호
        parentInfraCategoryNo = InfraCategoryType.SPORT_INFRA_NO;
        listTitle += '스포츠 시설 목록';
    }

    let condition = new InfraSearchCondition(parentInfraCategoryNo.toString(), parentRegionCodeId);

    if (typeof childInfraCategoryNo != 'undefined' && childInfraCategoryNo != 0) {
        condition.childInfraCategory = childInfraCategoryNo;
    }

    if (searchingType === 'recomm') {
        isRecommendation = true;
        listTitle = '추천 ' + listTitle;
        condition.recommandation = isRecommendation;
    } else if (searchingType === 'nearby') {
        listTitle = '주변 ' + listTitle;
        condition.latitude = latitude;
        condition.longitude = longitude;
        condition.distance = NEAR_DISTAINCE;
    }

    const [infraSearchCondition, setInfraSearchCondition] = useState(condition);
    const { page, setPage, pagingSize, setPagingSize, pagingCnt, paging, currentPageGroupIdx, setCurrentPageGroupIdx } = useSearchPaging();
    const { infras, infrasCnt } = useInfraRetrieveBySearchCondition(infraSearchCondition, paging);

    return (
        <>
            <SearchPageHeader></SearchPageHeader>
            <div className="list-wrap list-wrap_m">
                <SearchRight>
                    <SearchIn>
                        <SearchCnt title={listTitle} searchCnt={infrasCnt}></SearchCnt>
                        <SearchOfInfraWithCategoryContainer
                            infraSearchCondition={condition}
                            searchCnt={infrasCnt}
                            searchedInfras={infras}
                            searchingType={searchingType}
                            setInfraSearchCondition={setInfraSearchCondition}
                        ></SearchOfInfraWithCategoryContainer>
                        <SearchIteamPaging
                            totalCnt={infrasCnt}
                            pagingSize={pagingSize}
                            pagingCnt={pagingCnt}
                            setPage={setPage}
                            currentPageNum={page}
                        ></SearchIteamPaging>
                    </SearchIn>
                </SearchRight>
            </div>
        </>
    );
}

export default SearchListContainer;
