import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import SearchItem from '../../domain/search/SearchItem';

interface SearchBarResultProps {
    searchItems: SearchItem[];
}

function SearchBarResult(props: SearchBarResultProps) {
    const history = useHistory();
    const [searchItems, setSearchItems] = useState(props.searchItems);
    const [tabSeqType, setTabSeqType] = useState('ALL');

    const btnStyle = {
        padding: '0px 6px 0px 0px',
    };

    const aStyle = {
        color: 'black',
    };

    useEffect(() => {
        setSearchItems(props.searchItems);
    }, [props.searchItems]);

    return (
        <>
            <div className="tab" style={{ padding: '5px 10px' }}>
                <button
                    style={btnStyle}
                    onClick={() => {
                        setSearchItems(props.searchItems);
                        setTabSeqType('ALL');
                    }}
                    className={`tablinks bt-basic ${tabSeqType == 'ALL' ? 'active' : ''}`}
                >
                    전체
                </button>
                <button
                    style={btnStyle}
                    onClick={() => {
                        setSearchItems(SearchItem.filter(props.searchItems, 'INFRA'));
                        setTabSeqType('INFRA');
                    }}
                    className={`tablinks bt-basic ${tabSeqType == 'INFRA' ? 'active' : ''}`}
                >
                    시설
                </button>
                <button
                    style={btnStyle}
                    onClick={() => {
                        setSearchItems(SearchItem.filter(props.searchItems, 'TEAM'));
                        setTabSeqType('TEAM');
                    }}
                    className={`tablinks bt-basic ${tabSeqType == 'TEAM' ? 'active' : ''}`}
                >
                    팀
                </button>
            </div>

            <div
                className="tabcontent"
                style={{
                    height: '100px',
                    overflowY: 'auto',
                    // margin: '0px 10px 0px 10px',
                    // padding: '0px 4px',
                    width: '95%',
                    // padding: '5px 5% !important'
                    // border: '1px solid black',
                }}
            >
                {searchItems.length > 0 ? (
                    searchItems.map((searchItem: SearchItem, index: number) => {
                        let linkPrefix = searchItem.searchType == 'INFRA' ? '/infra/sport' : '/trainning/team-story';
                        let iconClassName = searchItem.searchType == 'INFRA' ? 'fa-map-marker-alt' : 'fa-users';
                        return (
                            <p key={index}>
                                <i className={`fas ${iconClassName}`}></i>
                                <Link to={`${linkPrefix}/${searchItem.searchItemNo}`} style={aStyle}>
                                    {searchItem.name}
                                </Link>
                            </p>
                        );
                    })
                ) : (
                    <p>
                        <Link to="#" style={aStyle}>
                            검색 내용이 없습니다.
                        </Link>
                    </p>
                )}
            </div>
        </>
    );
}

export default SearchBarResult;
