import { useState } from 'react';
import { SUCCESS_MESSAGE } from '../../config/Constant';
import TmpRegistedTeamUser from '../../domain/team/TmpRegistedTeamUser';
import { TMP_REGISTED_TEAM_USER_SERVICE } from '../../service';

function useTmpRegistedTeamUserModify(tmpRegistedTeamUser?: TmpRegistedTeamUser) {
    const [modifyTmpRegistedTeamUser, setModifyTmpRegistedTeamUser] = useState<TmpRegistedTeamUser>();

    const modifyHandler = (userName?: string, phoneNumber?: string) => {
        if (tmpRegistedTeamUser && userName && phoneNumber) {
            tmpRegistedTeamUser.userName = userName;
            tmpRegistedTeamUser.phoneNumber = phoneNumber;

            TMP_REGISTED_TEAM_USER_SERVICE.userModify(tmpRegistedTeamUser).then((modifyTmpRegistedTeamuser) => {
                setModifyTmpRegistedTeamUser(modifyTmpRegistedTeamuser);
            });
        }
    };

    return {
        modifyTmpRegistedTeamUser,
        modifyHandler,
    };
}

export default useTmpRegistedTeamUserModify;
