import React from 'react';

interface MyPageDetailRightContentProps {
    children: React.ReactNode;
}

function MyPageDetailRightContent(props: MyPageDetailRightContentProps) {
    return (
        <div className="my-detaile">
            <div className="my-in-detail">
                {props.children}
            </div>
        </div>
    )
}

export default MyPageDetailRightContent;