import React from 'react';
interface AdminMainTitleProps {
    title: string;
    children?: React.ReactNode;
}

function AdminMainTitle(props: AdminMainTitleProps) {
    return (
        <>
            <div className="flex-basic">
                <div className="member-tit">
                    <h3>{props.title}</h3>
                </div>
            </div>
            <div className="flex-basic" style={{ marginBottom: '5px' }}>
                {props.children}
            </div>
        </>
    );
}

export default AdminMainTitle;
