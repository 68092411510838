import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { last } from 'lodash';
import ReactDOM from 'react-dom';
import '../assets/css/center.css';
import '../assets/css/animate.min.css';
import Layout from '../layouts/Layout';
import Header from '../layouts/Header';
import SideBar from '../layouts/SideBar';
import RehabServiceImpl from '../service/impl/RehabServiceImpl';
import QuestSheetPopup from './components/questionnaires/MngQuestionnaireSheetComponent';

import TargetPerson from '../domain/TargetPerson';
import { confirmAlert } from 'react-confirm-alert';
import RehabHistoryServiceImpl from '../service/impl/RehabHistoryServiceImpl';
import RehabTargetHistory from '../domain/RehabTargetHistory';
import MngReservationComponent from '../components/MngReservationComponent';
import Reservation from '../../../domain/reservation/Reservation';

const rehabService = new RehabServiceImpl();
const rehabHistoryService = new RehabHistoryServiceImpl();
const menuTitle: string = '* 관리대상자 상세정보';
const title: string = '관리대상자 상세정보';

export default function CheckedUpTargetDetail(props: any) {
    let path = props.location.pathname;

    const history = useHistory();
    const location = useLocation();
    let targetInfo: any = location.state ? location.state : new TargetPerson();

    // 추가될 예약 정보
    let reservation: Reservation = new Reservation();

    //설문 팝업 제어
    const [openPopup, setOpenPopup] = useState<any>({ display: 'none' });
    const [contrPopup, setContrPopup] = useState<string>('center-question-popup wow  animate__animated animate__fadeInUp');
    //예약변경 팝업 제어
    const [openReservationPopup, setOpenReservationPopup] = useState<any>({ display: 'none' });
    const [contrReservationPopup, setContrReservationPopup] = useState<string>('center-question-popup wow  animate__animated animate__fadeInUp');

    const [checkupHistory, setCheckupHistory] = useState<RehabTargetHistory[]>();
    const [latestCheckup, setLatestCheckup] = useState<Reservation>();
    const [latestPhyStatus, setLatestPhyStatus] = useState<any>();

    const [selectedHistoryTarget, setSelectedHistoryTarget] = useState<TargetPerson>();

    useEffect(() => {
        if (targetInfo.reservater) {
            let userNo: string = targetInfo.reservater.userNo;
            rehabHistoryService.getRehabHistoryForUserno(userNo).then((history: RehabTargetHistory[]) => {
                if (history.length > 0) {
                    let latest = targetInfo.latestReservation;
                    let latestPhy = findLatestPhyStatus(history);

                    setLatestCheckup(latest);
                    setLatestPhyStatus(latestPhy);
                    setCheckupHistory(history);
                }
            });
        }
    }, []);

    const goBack = () => {
        history.replace('/rehab/targetperson');
    };

    const goInputHistoryHandler = (reservationNo: string) => {
        let state = {
            checkedupTarget: targetInfo,
            targetReservation: reservationNo,
            historyTarget: checkupHistory,
            returnPath: '/rehab/targetperson/detail',
        };
        history.push({
            pathname: '/rehab/checkedup/mngdata/physical',
            state: state,
        });
    };

    const goMakingReport = (targetNo: number, historyNo: number) => {
        let state = { checkedupTarget: targetInfo, historyTarget: null, returnPath: '/rehab/targetperson/detail' };
        history.push({
            pathname: '/rehab/reporting/' + targetNo + '/' + historyNo,
            state: state,
        });
    };

    const findLatestPhyStatus = (history: RehabTargetHistory[]) => {
        let latest = null;
        history?.map((item, idx) => {
            let physicalStatus = item.physicalStatus;
            if (physicalStatus != null && physicalStatus != '') {
                let obj = JSON.parse(physicalStatus);
                if (obj.height != '') {
                    obj.date = item.reservation?.startDate;
                    latest = obj;
                }
            }
        });
        return latest;
    };

    const openGeneralQ = () => {
        let isDisplay = { display: 'block' };
        let fadeInStyle = 'center-question-popup wow  animate__animated animate__fadeInUp';
        setOpenPopup(isDisplay);
        setContrPopup(fadeInStyle);
    };
    const closeGeneralQ = () => {
        let isNotDisplay = { display: 'none' };
        let fadeOutStyle = 'center-question-popup wow  animate__animated animate__fadeOutDown';
        setContrPopup(fadeOutStyle);
        setOpenPopup(isNotDisplay);
    };

    const openPopupMngReservation = () => {
        let isDisplay = { display: 'block' };
        let fadeInStyle = 'center-question-popup wow  animate__animated animate__fadeInUp';
        setOpenReservationPopup(isDisplay);
        setContrReservationPopup(fadeInStyle);
    };
    const closePopupForMngReservation = (evt: any) => {
        let isNotDisplay = { display: 'none' };
        let fadeOutStyle = 'center-question-popup wow  animate__animated animate__fadeOutDown';
        setContrReservationPopup(fadeOutStyle);
        setOpenReservationPopup(isNotDisplay);

        if (evt === 'complete') {
            history.push({
                pathname: '/rehab/reserved',
            });
        }
    };

    let btnSet = (
        <>
            <button className="btn" style={{ float: 'right' }} onClick={openPopupMngReservation}>
                예약추가
            </button>
            <button className="btn btn-info" style={{ float: 'right' }} onClick={goBack}>
                돌아가기
            </button>
        </>
    );

    let targetHistory = checkupHistory ? (
        checkupHistory?.map((item, idx) => {
            return (
                <tr key={idx}>
                    <td>{item.historyNo}</td>
                    <td>
                        {/* {year}-{month}-{day} */}
                        {item.reservation?.startDate} ~ {item.reservation?.endDate}
                    </td>
                    <td>{item.reservation?.infra?.name}</td>
                    <td>{item.targetStateCode?.name}</td>
                    <td>
                        {item.targetState === 8004 ? (
                            <>
                                <button
                                    className="btn btn-letter"
                                    onClick={() => {
                                        goMakingReport(item.targetNo, item.historyNo);
                                    }}
                                >
                                    리포트확인
                                </button>
                                <button
                                    className="btn btn-warning"
                                    onClick={() => {
                                        goInputHistoryHandler(item.reservationNo);
                                    }}
                                >
                                    수정
                                </button>
                            </>
                        ) : (
                            <>검진 진행 중...</>
                        )}
                    </td>
                </tr>
            );
        })
    ) : (
        <tr>
            <td colSpan={5}>검진 이력이 존재하지 않습니다.</td>
        </tr>
    );

    return (
        <>
            <Layout
                header={<Header></Header>}
                sideBar={<SideBar name={path}></SideBar>}
                path={path}
                naviTitle={'센터 관리 대상자 상세정보화면'}
                menuTitle={'* 대상자 상세정보'}
            >
                <div style={{ width: '100%' }}>{btnSet}</div>
                {/* 대상자 기본 이력 정보 */}
                <div style={{ display: 'flex', padding: '10px 5px', width: '100%' }}>* 대상자 기본 정보</div>
                <div>
                    <table className="center-list-table" cellPadding="0" cellSpacing="0">
                        <colgroup>
                            <col style={{ width: '25%' }} />
                            <col style={{ width: '25%' }} />
                            <col style={{ width: '25%' }} />
                            <col style={{ width: '25%' }} />
                        </colgroup>
                        <thead>
                            <tr>
                                <td>이름</td>
                                <td>생년월일</td>
                                <td>소속/종목</td>
                                <td>최근 검진 정보</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{targetInfo.reservater?.name}</td>
                                <td>{targetInfo.reservater?.birthdate}</td>
                                <td>
                                    {targetInfo.team?.name}/ {targetInfo.team?.sportCode.name}
                                </td>
                                <td>{latestCheckup?.startDate}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                {/* 최근 검진 이력 */}
                <div style={{ display: 'flex', padding: '10px 5px', width: '100%' }}>* 최근 검진 이력</div>
                <div>
                    <table className="center-list-table" cellPadding="0" cellSpacing="0">
                        <colgroup>
                            <col style={{ width: '10%' }} />
                            <col style={{ width: '40%' }} />
                            <col style={{ width: '10%' }} />
                            <col style={{ width: '40%' }} />
                        </colgroup>
                        <thead>
                            <tr>
                                <td>측정일자</td>
                                <td>신장</td>
                                <td>체중</td>
                                <td>혈압</td>
                            </tr>
                        </thead>
                        <tbody>
                            {latestCheckup ? (
                                <tr>
                                    {latestPhyStatus ? (
                                        <>
                                            <td>{latestPhyStatus.date}</td>
                                            <td>{latestPhyStatus.height} Cm</td>
                                            <td>{latestPhyStatus.weight} Kg</td>
                                            <td>
                                                {latestPhyStatus.lBloodPressure}/{latestPhyStatus.hBloodPressure}
                                            </td>
                                        </>
                                    ) : (
                                        <td colSpan={4}>신체정보를 측정 이력이 없습니다.</td>
                                    )}
                                </tr>
                            ) : (
                                <tr>
                                    <td colSpan={4}>최근 검진 기록이 없습니다.</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
                {/* 센터 방문 이력 및 현재 상황 */}
                <div style={{ display: 'flex', padding: '10px 5px', width: '100%' }}>* 재활 센터 방문 이력</div>
                <div>
                    <table className="center-list-table" cellPadding="0" cellSpacing="0">
                        <colgroup>
                            <col style={{ width: '10%' }} />
                            <col style={{ width: '25%' }} />
                            <col style={{ width: '25%' }} />
                            <col style={{ width: '25%' }} />
                            <col style={{ width: '15%' }} />
                        </colgroup>
                        <thead>
                            <tr>
                                <td>검진번호</td>
                                <td>방문일자</td>
                                <td>방문센터</td>
                                <td>검진상태</td>
                                <td>리포트관리</td>
                            </tr>
                        </thead>
                        <tbody> {targetHistory}</tbody>
                    </table>
                </div>
                <div id="center-popup-wrap" style={openPopup}>
                    <div className={contrPopup} data-wow-delay="0.2s">
                        <QuestSheetPopup targetPerson={selectedHistoryTarget} closingHandler={closeGeneralQ}></QuestSheetPopup>
                    </div>
                </div>
                <div id="center-popup-wrap" style={openReservationPopup}>
                    <div className={contrReservationPopup} data-wow-delay="0.2s" style={{ minHeight: '400px', height: '500px' }}>
                        <MngReservationComponent
                            actionType="register"
                            reservation={reservation}
                            targetPerson={targetInfo}
                            closingHandler={closePopupForMngReservation}
                        ></MngReservationComponent>
                    </div>
                </div>
            </Layout>
        </>
    );
}
