import React, { useEffect, useState } from 'react';
import AnswerItem from '../../../../domain/AnswerItem';
import QuestionnariesFormProps from './QuestionnariesFormsProps';
import useReportQuestionnarieAnswerMake from '../../../../../../hook/report/useReportQuestionnarieAnswerMake';
import APIServerConfig from '../../../../../../config/APIServerConfig';
import ReportTypeEnum from '../../../../../../domain/report/param/ReportTypeEnum';

export default function TopsForm(props: QuestionnariesFormProps) {
    const putAnswerItem = (qNum: number, answerNumber: number) => {
        if (props.answerItems) {
            const newAnswerItem = AnswerItem.getNewAnswerItem(qNum, answerNumber);
            if (props.setAnswer) props.setAnswer(qNum, newAnswerItem);
        }
    };

    const getCheckOnClassName = (qNum: number, answerNumber: number): string => {
        if (props.answerItems) {
            if (props.answerItems.get(qNum)?.itemNoOfAnswer == answerNumber) {
                return 'check-on';
            } else {
                return '';
            }
        } else {
            return '';
        }
    };

    const [aloneTalkScore, setAloneTalkScore] = useState<number>(0);
    const [conditionManageScore, setConditionManageScore] = useState<number>(0);
    const [mindAndGoalSettingScore, setMindAndGoalSettingScore] = useState<number>(0);
    const [relexScore, setRelexScore] = useState<number>(0);
    const [mindControlScore, setMindControlScore] = useState<number>(0);
    const [statelessScore, setStatelessScore] = useState<number>(0);

    useEffect(() => {
        if (props.answerItems) {
            const answerItems = props.answerItems;
            setAloneTalkScore(
                getAnswer(answerItems.get(1)) + getAnswer(answerItems.get(6)) + getAnswer(answerItems.get(13)) + getAnswer(answerItems.get(20)),
            );

            setConditionManageScore(
                getAnswer(answerItems.get(10)) +
                    getAnswer(answerItems.get(12)) +
                    getAnswer(answerItems.get(15)) +
                    getAnswer(answerItems.get(17)) +
                    getAnswer(answerItems.get(19)) +
                    getAnswer(answerItems.get(22)) +
                    getAnswer(answerItems.get(24)),
            );

            setMindAndGoalSettingScore(
                getAnswer(answerItems.get(3)) +
                    getAnswer(answerItems.get(4)) +
                    getAnswer(answerItems.get(8)) +
                    getAnswer(answerItems.get(9)) +
                    getAnswer(answerItems.get(16)),
            );

            setRelexScore(
                getAnswer(answerItems.get(5)) - getAnswer(answerItems.get(11)) + getAnswer(answerItems.get(18)) + getAnswer(answerItems.get(23)),
            );

            setMindControlScore(
                -getAnswer(answerItems.get(2)) - getAnswer(answerItems.get(7)) - getAnswer(answerItems.get(14)) - getAnswer(answerItems.get(21)),
            );

            setStatelessScore(
                getAnswer(answerItems.get(25)) +
                    getAnswer(answerItems.get(26)) +
                    getAnswer(answerItems.get(27)) +
                    getAnswer(answerItems.get(28)) +
                    getAnswer(answerItems.get(29)) +
                    getAnswer(answerItems.get(30)) +
                    getAnswer(answerItems.get(31)),
            );
        }
    }, [props.answerItems]);

    const getAnswer = (answerItem?: AnswerItem): number => {
        if (answerItem) {
            if (answerItem.itemNoOfAnswer) {
                return answerItem.itemNoOfAnswer;
            } else {
                return 0;
            }
        } else {
            return 0;
        }
    };
    const { reportData } = useReportQuestionnarieAnswerMake(props.answerItems);
    return (
        <>
            <div className="center-center">
                스포츠심리검사
                {props.resultPrintable ? (
                    <>
                        <form action={`${APIServerConfig.resourceAPIURL}/report/pdf`} method="POST">
                            <input type="hidden" name="reportType" value={ReportTypeEnum.TOPS}></input>
                            <input type="hidden" name="reportData" value={JSON.stringify(reportData)}></input>
                            <input type="submit" value="설문 결과 PDF 내려받기" />
                        </form>
                        <form action={`${APIServerConfig.resourceAPIURL}/report/xlsx`} method="POST">
                            <input type="hidden" name="reportType" value={ReportTypeEnum.TOPS}></input>
                            <input type="hidden" name="reportData" value={JSON.stringify(reportData)}></input>
                            <input type="submit" value="설문 결과 엑셀 내려받기" />
                        </form>
                    </>
                ) : (
                    <></>
                )}
            </div>
            {/* <div className="center-check-input-name">
                <label>종목 : </label>
                <input type="text" />
                <label>선수명 : </label>
                <input type="text" />
                <label>작성일 : </label>20
                <input type="text" className="center-day" />년 <input type="text" className="center-day" />월{' '}
                <input type="text" className="center-day" />일
            </div> */}

            <h2 className="center2">1. 한국판 수행전략검사(TOPS)</h2>
            <div className="tab-story-number">
                <table className="center-check-table-basic" cellPadding="0" cellSpacing="0">
                    <thead>
                        <tr>
                            <th colSpan={6}>
                                ※ 최근의 "가장 좋았던 시합"을 회상하면서 다음 각 문항을 읽고 여러분 자신의 생각과
                                <br />
                                가장 가까운 것에 표시를 해 주십시오.
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th className="center-bggray">문항</th>
                            <td>
                                전혀
                                <br />안<br />
                                그렇다
                            </td>
                            <td>
                                조금
                                <br />
                                그렇다
                            </td>
                            <td>
                                다소
                                <br />
                                그런
                                <br />
                                편이다
                            </td>
                            <td>
                                많이
                                <br />
                                그렇다
                            </td>
                            <td>
                                아주
                                <br />
                                많이
                                <br />
                                그렇다
                            </td>
                        </tr>

                        <tr>
                            <th>1. 최선을 다하자고 긍정적인 혼잣말을 한다.</th>
                            <td className={getCheckOnClassName(1, 1)} onClick={() => putAnswerItem(1, 1)}>
                                ①
                            </td>
                            <td className={getCheckOnClassName(1, 2)} onClick={() => putAnswerItem(1, 2)}>
                                ②
                            </td>
                            <td className={getCheckOnClassName(1, 3)} onClick={() => putAnswerItem(1, 3)}>
                                ③
                            </td>
                            <td className={getCheckOnClassName(1, 4)} onClick={() => putAnswerItem(1, 4)}>
                                ④
                            </td>
                            <td className={getCheckOnClassName(1, 5)} onClick={() => putAnswerItem(1, 5)}>
                                ⑤
                            </td>
                        </tr>

                        <tr>
                            <th>2. 감정 때문에 제 실력을 발휘하지 못한다. (R)</th>
                            <td className={getCheckOnClassName(2, 1)} onClick={() => putAnswerItem(2, 1)}>
                                ①
                            </td>
                            <td className={getCheckOnClassName(2, 2)} onClick={() => putAnswerItem(2, 2)}>
                                ②
                            </td>
                            <td className={getCheckOnClassName(2, 3)} onClick={() => putAnswerItem(2, 3)}>
                                ③
                            </td>
                            <td className={getCheckOnClassName(2, 4)} onClick={() => putAnswerItem(2, 4)}>
                                ④
                            </td>
                            <td className={getCheckOnClassName(2, 5)} onClick={() => putAnswerItem(2, 5)}>
                                ⑤
                            </td>
                        </tr>

                        <tr>
                            <th>3. 동작에 대한 나만의 달성목표를 설정한다. </th>
                            <td className={getCheckOnClassName(3, 1)} onClick={() => putAnswerItem(3, 1)}>
                                ①
                            </td>
                            <td className={getCheckOnClassName(3, 2)} onClick={() => putAnswerItem(3, 2)}>
                                ②
                            </td>
                            <td className={getCheckOnClassName(3, 3)} onClick={() => putAnswerItem(3, 3)}>
                                ③
                            </td>
                            <td className={getCheckOnClassName(3, 4)} onClick={() => putAnswerItem(3, 4)}>
                                ④
                            </td>
                            <td className={getCheckOnClassName(3, 5)} onClick={() => putAnswerItem(3, 5)}>
                                ⑤
                            </td>
                        </tr>

                        <tr>
                            <th>4. 시합에 앞서 시합의 절차를 상상해 본다. </th>
                            <td className={getCheckOnClassName(4, 1)} onClick={() => putAnswerItem(4, 1)}>
                                ①
                            </td>
                            <td className={getCheckOnClassName(4, 2)} onClick={() => putAnswerItem(4, 2)}>
                                ②
                            </td>
                            <td className={getCheckOnClassName(4, 3)} onClick={() => putAnswerItem(4, 3)}>
                                ③
                            </td>
                            <td className={getCheckOnClassName(4, 4)} onClick={() => putAnswerItem(4, 4)}>
                                ④
                            </td>
                            <td className={getCheckOnClassName(4, 5)} onClick={() => putAnswerItem(4, 5)}>
                                ⑤
                            </td>
                        </tr>

                        <tr className="center-bottom-black">
                            <th>5. 너무 긴장될 때 긴장을 풀 수 있다. </th>
                            <td className={getCheckOnClassName(5, 1)} onClick={() => putAnswerItem(5, 1)}>
                                ①
                            </td>
                            <td className={getCheckOnClassName(5, 2)} onClick={() => putAnswerItem(5, 2)}>
                                ②
                            </td>
                            <td className={getCheckOnClassName(5, 3)} onClick={() => putAnswerItem(5, 3)}>
                                ③
                            </td>
                            <td className={getCheckOnClassName(5, 4)} onClick={() => putAnswerItem(5, 4)}>
                                ④
                            </td>
                            <td className={getCheckOnClassName(5, 5)} onClick={() => putAnswerItem(5, 5)}>
                                ⑤
                            </td>
                        </tr>

                        <tr>
                            <th>6. 시합에 도움이 되는 말을 마음속으로 한다. </th>
                            <td className={getCheckOnClassName(6, 1)} onClick={() => putAnswerItem(6, 1)}>
                                ①
                            </td>
                            <td className={getCheckOnClassName(6, 2)} onClick={() => putAnswerItem(6, 2)}>
                                ②
                            </td>
                            <td className={getCheckOnClassName(6, 3)} onClick={() => putAnswerItem(6, 3)}>
                                ③
                            </td>
                            <td className={getCheckOnClassName(6, 4)} onClick={() => putAnswerItem(6, 4)}>
                                ④
                            </td>
                            <td className={getCheckOnClassName(6, 5)} onClick={() => putAnswerItem(6, 5)}>
                                ⑤
                            </td>
                        </tr>

                        <tr>
                            <th>7. 경기 중 부담이 커지면 감정조절이 안 된다. (R)</th>
                            <td className={getCheckOnClassName(7, 1)} onClick={() => putAnswerItem(7, 1)}>
                                ①
                            </td>
                            <td className={getCheckOnClassName(7, 2)} onClick={() => putAnswerItem(7, 2)}>
                                ②
                            </td>
                            <td className={getCheckOnClassName(7, 3)} onClick={() => putAnswerItem(7, 3)}>
                                ③
                            </td>
                            <td className={getCheckOnClassName(7, 4)} onClick={() => putAnswerItem(7, 4)}>
                                ④
                            </td>
                            <td className={getCheckOnClassName(7, 5)} onClick={() => putAnswerItem(7, 5)}>
                                ⑤
                            </td>
                        </tr>

                        <tr>
                            <th>8. 매우 세부적인 목표를 설정한다.</th>
                            <td className={getCheckOnClassName(8, 1)} onClick={() => putAnswerItem(8, 1)}>
                                ①
                            </td>
                            <td className={getCheckOnClassName(8, 2)} onClick={() => putAnswerItem(8, 2)}>
                                ②
                            </td>
                            <td className={getCheckOnClassName(8, 3)} onClick={() => putAnswerItem(8, 3)}>
                                ③
                            </td>
                            <td className={getCheckOnClassName(8, 4)} onClick={() => putAnswerItem(8, 4)}>
                                ④
                            </td>
                            <td className={getCheckOnClassName(8, 5)} onClick={() => putAnswerItem(8, 5)}>
                                ⑤
                            </td>
                        </tr>

                        <tr>
                            <th>9. 동작의 느낌을 상상 속에서 떠올려 본다. </th>
                            <td className={getCheckOnClassName(9, 1)} onClick={() => putAnswerItem(9, 1)}>
                                ①
                            </td>
                            <td className={getCheckOnClassName(9, 2)} onClick={() => putAnswerItem(9, 2)}>
                                ②
                            </td>
                            <td className={getCheckOnClassName(9, 3)} onClick={() => putAnswerItem(9, 3)}>
                                ③
                            </td>
                            <td className={getCheckOnClassName(9, 4)} onClick={() => putAnswerItem(9, 4)}>
                                ④
                            </td>
                            <td className={getCheckOnClassName(9, 5)} onClick={() => putAnswerItem(9, 5)}>
                                ⑤
                            </td>
                        </tr>

                        <tr className="center-bottom-black">
                            <th>10. 몸과 마음이 분발되도록 준비한다. </th>
                            <td className={getCheckOnClassName(10, 1)} onClick={() => putAnswerItem(10, 1)}>
                                ①
                            </td>
                            <td className={getCheckOnClassName(10, 2)} onClick={() => putAnswerItem(10, 2)}>
                                ②
                            </td>
                            <td className={getCheckOnClassName(10, 3)} onClick={() => putAnswerItem(10, 3)}>
                                ③
                            </td>
                            <td className={getCheckOnClassName(10, 4)} onClick={() => putAnswerItem(10, 4)}>
                                ④
                            </td>
                            <td className={getCheckOnClassName(10, 5)} onClick={() => putAnswerItem(10, 5)}>
                                ⑤
                            </td>
                        </tr>

                        <tr>
                            <th>11. 긴장이 많이 될 때 긴장을 풀기가 어렵다. (R)</th>
                            <td className={getCheckOnClassName(11, 1)} onClick={() => putAnswerItem(11, 1)}>
                                ①
                            </td>
                            <td className={getCheckOnClassName(11, 2)} onClick={() => putAnswerItem(11, 2)}>
                                ②
                            </td>
                            <td className={getCheckOnClassName(11, 3)} onClick={() => putAnswerItem(11, 3)}>
                                ③
                            </td>
                            <td className={getCheckOnClassName(11, 4)} onClick={() => putAnswerItem(11, 4)}>
                                ④
                            </td>
                            <td className={getCheckOnClassName(11, 5)} onClick={() => putAnswerItem(11, 5)}>
                                ⑤
                            </td>
                        </tr>

                        <tr>
                            <th>12. 컨디션이 좋도록 마음상태를 준비한다. </th>
                            <td className={getCheckOnClassName(12, 1)} onClick={() => putAnswerItem(12, 1)}>
                                ①
                            </td>
                            <td className={getCheckOnClassName(12, 2)} onClick={() => putAnswerItem(12, 2)}>
                                ②
                            </td>
                            <td className={getCheckOnClassName(12, 3)} onClick={() => putAnswerItem(12, 3)}>
                                ③
                            </td>
                            <td className={getCheckOnClassName(12, 4)} onClick={() => putAnswerItem(12, 4)}>
                                ④
                            </td>
                            <td className={getCheckOnClassName(12, 5)} onClick={() => putAnswerItem(12, 5)}>
                                ⑤
                            </td>
                        </tr>

                        <tr>
                            <th>13. 적절하게 혼잣말을 한다. </th>
                            <td className={getCheckOnClassName(13, 1)} onClick={() => putAnswerItem(13, 1)}>
                                ①
                            </td>
                            <td className={getCheckOnClassName(13, 2)} onClick={() => putAnswerItem(13, 2)}>
                                ②
                            </td>
                            <td className={getCheckOnClassName(13, 3)} onClick={() => putAnswerItem(13, 3)}>
                                ③
                            </td>
                            <td className={getCheckOnClassName(13, 4)} onClick={() => putAnswerItem(13, 4)}>
                                ④
                            </td>
                            <td className={getCheckOnClassName(13, 5)} onClick={() => putAnswerItem(13, 5)}>
                                ⑤
                            </td>
                        </tr>

                        <tr>
                            <th>14. 화가 나거나 기분이 나빠지면 제 실력이 나오지 않는다. (R)</th>
                            <td className={getCheckOnClassName(14, 1)} onClick={() => putAnswerItem(14, 1)}>
                                ①
                            </td>
                            <td className={getCheckOnClassName(14, 2)} onClick={() => putAnswerItem(14, 2)}>
                                ②
                            </td>
                            <td className={getCheckOnClassName(14, 3)} onClick={() => putAnswerItem(14, 3)}>
                                ③
                            </td>
                            <td className={getCheckOnClassName(14, 4)} onClick={() => putAnswerItem(14, 4)}>
                                ④
                            </td>
                            <td className={getCheckOnClassName(14, 5)} onClick={() => putAnswerItem(14, 5)}>
                                ⑤
                            </td>
                        </tr>

                        <tr className="center-bottom-black">
                            <th>15. 시합할 때 본능적으로 동작이 이루어지게 한다. </th>
                            <td className={getCheckOnClassName(15, 1)} onClick={() => putAnswerItem(15, 1)}>
                                ①
                            </td>
                            <td className={getCheckOnClassName(15, 2)} onClick={() => putAnswerItem(15, 2)}>
                                ②
                            </td>
                            <td className={getCheckOnClassName(15, 3)} onClick={() => putAnswerItem(15, 3)}>
                                ③
                            </td>
                            <td className={getCheckOnClassName(15, 4)} onClick={() => putAnswerItem(15, 4)}>
                                ④
                            </td>
                            <td className={getCheckOnClassName(15, 5)} onClick={() => putAnswerItem(15, 5)}>
                                ⑤
                            </td>
                        </tr>

                        <tr>
                            <th>16. 시합결과에 대해 세부적인 목표를 세운다. </th>
                            <td className={getCheckOnClassName(16, 1)} onClick={() => putAnswerItem(16, 1)}>
                                ①
                            </td>
                            <td className={getCheckOnClassName(16, 2)} onClick={() => putAnswerItem(16, 2)}>
                                ②
                            </td>
                            <td className={getCheckOnClassName(16, 3)} onClick={() => putAnswerItem(16, 3)}>
                                ③
                            </td>
                            <td className={getCheckOnClassName(16, 4)} onClick={() => putAnswerItem(16, 4)}>
                                ④
                            </td>
                            <td className={getCheckOnClassName(16, 5)} onClick={() => putAnswerItem(16, 5)}>
                                ⑤
                            </td>
                        </tr>

                        <tr>
                            <th>17. 시합에 준비가 되도록 나 자신을 분발시킨다. </th>
                            <td className={getCheckOnClassName(17, 1)} onClick={() => putAnswerItem(17, 1)}>
                                ①
                            </td>
                            <td className={getCheckOnClassName(17, 2)} onClick={() => putAnswerItem(17, 2)}>
                                ②
                            </td>
                            <td className={getCheckOnClassName(17, 3)} onClick={() => putAnswerItem(17, 3)}>
                                ③
                            </td>
                            <td className={getCheckOnClassName(17, 4)} onClick={() => putAnswerItem(17, 4)}>
                                ④
                            </td>
                            <td className={getCheckOnClassName(17, 5)} onClick={() => putAnswerItem(17, 5)}>
                                ⑤
                            </td>
                        </tr>

                        <tr>
                            <th>18. 부담되고 긴장 될 때 긴장을 푸는 방법을 안다. </th>
                            <td className={getCheckOnClassName(18, 1)} onClick={() => putAnswerItem(18, 1)}>
                                ①
                            </td>
                            <td className={getCheckOnClassName(18, 2)} onClick={() => putAnswerItem(18, 2)}>
                                ②
                            </td>
                            <td className={getCheckOnClassName(18, 3)} onClick={() => putAnswerItem(18, 3)}>
                                ③
                            </td>
                            <td className={getCheckOnClassName(18, 4)} onClick={() => putAnswerItem(18, 4)}>
                                ④
                            </td>
                            <td className={getCheckOnClassName(18, 5)} onClick={() => putAnswerItem(18, 5)}>
                                ⑤
                            </td>
                        </tr>

                        <tr>
                            <th>19. 컨디션을 조절하는 나만의 방법이 있다. (R)</th>
                            <td className={getCheckOnClassName(19, 1)} onClick={() => putAnswerItem(19, 1)}>
                                ①
                            </td>
                            <td className={getCheckOnClassName(19, 2)} onClick={() => putAnswerItem(19, 2)}>
                                ②
                            </td>
                            <td className={getCheckOnClassName(19, 3)} onClick={() => putAnswerItem(19, 3)}>
                                ③
                            </td>
                            <td className={getCheckOnClassName(19, 4)} onClick={() => putAnswerItem(19, 4)}>
                                ④
                            </td>
                            <td className={getCheckOnClassName(19, 5)} onClick={() => putAnswerItem(19, 5)}>
                                ⑤
                            </td>
                        </tr>

                        <tr className="center-bottom-black">
                            <th>20. 경기에 도움이 되도록 나만의 말을 한다. </th>
                            <td className={getCheckOnClassName(20, 1)} onClick={() => putAnswerItem(20, 1)}>
                                ①
                            </td>
                            <td className={getCheckOnClassName(20, 2)} onClick={() => putAnswerItem(20, 2)}>
                                ②
                            </td>
                            <td className={getCheckOnClassName(20, 3)} onClick={() => putAnswerItem(20, 3)}>
                                ③
                            </td>
                            <td className={getCheckOnClassName(20, 4)} onClick={() => putAnswerItem(20, 4)}>
                                ④
                            </td>
                            <td className={getCheckOnClassName(20, 5)} onClick={() => putAnswerItem(20, 5)}>
                                ⑤
                            </td>
                        </tr>

                        <tr>
                            <th>21. 실수를 한 후에는 집중하기가 어렵다. (R)</th>
                            <td className={getCheckOnClassName(21, 1)} onClick={() => putAnswerItem(21, 1)}>
                                ①
                            </td>
                            <td className={getCheckOnClassName(21, 2)} onClick={() => putAnswerItem(21, 2)}>
                                ②
                            </td>
                            <td className={getCheckOnClassName(21, 3)} onClick={() => putAnswerItem(21, 3)}>
                                ③
                            </td>
                            <td className={getCheckOnClassName(21, 4)} onClick={() => putAnswerItem(21, 4)}>
                                ④
                            </td>
                            <td className={getCheckOnClassName(21, 5)} onClick={() => putAnswerItem(21, 5)}>
                                ⑤
                            </td>
                        </tr>

                        <tr>
                            <th>22. 필요하다고 생각되면 나의 활력(에너지)수준을 높인다. </th>
                            <td className={getCheckOnClassName(22, 1)} onClick={() => putAnswerItem(22, 1)}>
                                ①
                            </td>
                            <td className={getCheckOnClassName(22, 2)} onClick={() => putAnswerItem(22, 2)}>
                                ②
                            </td>
                            <td className={getCheckOnClassName(22, 3)} onClick={() => putAnswerItem(22, 3)}>
                                ③
                            </td>
                            <td className={getCheckOnClassName(22, 4)} onClick={() => putAnswerItem(22, 4)}>
                                ④
                            </td>
                            <td className={getCheckOnClassName(22, 5)} onClick={() => putAnswerItem(22, 5)}>
                                ⑤
                            </td>
                        </tr>

                        <tr>
                            <th>23. 시합 직전에 필요하다면 긴장을 풀 수 있다. </th>
                            <td className={getCheckOnClassName(23, 1)} onClick={() => putAnswerItem(23, 1)}>
                                ①
                            </td>
                            <td className={getCheckOnClassName(23, 2)} onClick={() => putAnswerItem(23, 2)}>
                                ②
                            </td>
                            <td className={getCheckOnClassName(23, 3)} onClick={() => putAnswerItem(23, 3)}>
                                ③
                            </td>
                            <td className={getCheckOnClassName(23, 4)} onClick={() => putAnswerItem(23, 4)}>
                                ④
                            </td>
                            <td className={getCheckOnClassName(23, 5)} onClick={() => putAnswerItem(23, 5)}>
                                ⑤
                            </td>
                        </tr>

                        <tr className="center-bottom-black">
                            <th>24. 시합에 앞서 최고의 컨디션을 찾으려고 노력한다. </th>
                            <td className={getCheckOnClassName(24, 1)} onClick={() => putAnswerItem(24, 1)}>
                                ①
                            </td>
                            <td className={getCheckOnClassName(24, 2)} onClick={() => putAnswerItem(24, 2)}>
                                ②
                            </td>
                            <td className={getCheckOnClassName(24, 3)} onClick={() => putAnswerItem(24, 3)}>
                                ③
                            </td>
                            <td className={getCheckOnClassName(24, 4)} onClick={() => putAnswerItem(24, 4)}>
                                ④
                            </td>
                            <td className={getCheckOnClassName(24, 5)} onClick={() => putAnswerItem(24, 5)}>
                                ⑤
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <h2 className="center2">2. 스테일네스(staleness: 맥이 빠지는 상태) 검사 </h2>
            <div className="tab-story-number">
                <table className="center-check-table-basic" cellPadding="0" cellSpacing="0">
                    <thead>
                        <tr>
                            <th colSpan={6}>스테일네스 : 번 아웃 전에 오는 기간을 말하며, 오버트레이닝 후에 나타나는 것이 일반적이다.</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th>1. 연습과 시합이 재미없다. </th>
                            <td className={getCheckOnClassName(25, 1)} onClick={() => putAnswerItem(25, 1)}>
                                ①
                            </td>
                            <td className={getCheckOnClassName(25, 2)} onClick={() => putAnswerItem(25, 2)}>
                                ②
                            </td>
                            <td className={getCheckOnClassName(25, 3)} onClick={() => putAnswerItem(25, 3)}>
                                ③
                            </td>
                            <td className={getCheckOnClassName(25, 4)} onClick={() => putAnswerItem(25, 4)}>
                                ④
                            </td>
                            <td className={getCheckOnClassName(25, 5)} onClick={() => putAnswerItem(25, 5)}>
                                ⑤
                            </td>
                        </tr>

                        <tr>
                            <th>2. 평상시의 수행 수준이 나오지 않는다.(R)</th>
                            <td className={getCheckOnClassName(26, 1)} onClick={() => putAnswerItem(26, 1)}>
                                ①
                            </td>
                            <td className={getCheckOnClassName(26, 2)} onClick={() => putAnswerItem(26, 2)}>
                                ②
                            </td>
                            <td className={getCheckOnClassName(26, 3)} onClick={() => putAnswerItem(26, 3)}>
                                ③
                            </td>
                            <td className={getCheckOnClassName(26, 4)} onClick={() => putAnswerItem(26, 4)}>
                                ④
                            </td>
                            <td className={getCheckOnClassName(26, 5)} onClick={() => putAnswerItem(26, 5)}>
                                ⑤
                            </td>
                        </tr>

                        <tr>
                            <th>3. 정신적으로나 육체적으로 몹시 피로하다. </th>
                            <td className={getCheckOnClassName(27, 1)} onClick={() => putAnswerItem(27, 1)}>
                                ①
                            </td>
                            <td className={getCheckOnClassName(27, 2)} onClick={() => putAnswerItem(27, 2)}>
                                ②
                            </td>
                            <td className={getCheckOnClassName(27, 3)} onClick={() => putAnswerItem(27, 3)}>
                                ③
                            </td>
                            <td className={getCheckOnClassName(27, 4)} onClick={() => putAnswerItem(27, 4)}>
                                ④
                            </td>
                            <td className={getCheckOnClassName(27, 5)} onClick={() => putAnswerItem(27, 5)}>
                                ⑤
                            </td>
                        </tr>

                        <tr>
                            <th>4. 훈련과 시합 후에 평소처럼 신속하게 회복할 수 없다. </th>
                            <td className={getCheckOnClassName(28, 1)} onClick={() => putAnswerItem(28, 1)}>
                                ①
                            </td>
                            <td className={getCheckOnClassName(28, 2)} onClick={() => putAnswerItem(28, 2)}>
                                ②
                            </td>
                            <td className={getCheckOnClassName(28, 3)} onClick={() => putAnswerItem(28, 3)}>
                                ③
                            </td>
                            <td className={getCheckOnClassName(28, 4)} onClick={() => putAnswerItem(28, 4)}>
                                ④
                            </td>
                            <td className={getCheckOnClassName(28, 5)} onClick={() => putAnswerItem(28, 5)}>
                                ⑤
                            </td>
                        </tr>

                        <tr>
                            <th>5. 자꾸 짜증이 나고 불평이 생긴다.</th>
                            <td className={getCheckOnClassName(29, 1)} onClick={() => putAnswerItem(29, 1)}>
                                ①
                            </td>
                            <td className={getCheckOnClassName(29, 2)} onClick={() => putAnswerItem(29, 2)}>
                                ②
                            </td>
                            <td className={getCheckOnClassName(29, 3)} onClick={() => putAnswerItem(29, 3)}>
                                ③
                            </td>
                            <td className={getCheckOnClassName(29, 4)} onClick={() => putAnswerItem(29, 4)}>
                                ④
                            </td>
                            <td className={getCheckOnClassName(29, 5)} onClick={() => putAnswerItem(29, 5)}>
                                ⑤
                            </td>
                        </tr>

                        <tr>
                            <th>6. 숙면을 취할 수 없다. </th>
                            <td className={getCheckOnClassName(30, 1)} onClick={() => putAnswerItem(30, 1)}>
                                ①
                            </td>
                            <td className={getCheckOnClassName(30, 2)} onClick={() => putAnswerItem(30, 2)}>
                                ②
                            </td>
                            <td className={getCheckOnClassName(30, 3)} onClick={() => putAnswerItem(30, 3)}>
                                ③
                            </td>
                            <td className={getCheckOnClassName(30, 4)} onClick={() => putAnswerItem(30, 4)}>
                                ④
                            </td>
                            <td className={getCheckOnClassName(30, 5)} onClick={() => putAnswerItem(30, 5)}>
                                ⑤
                            </td>
                        </tr>

                        <tr className="center-bottom-black">
                            <th>7. 자신의 기량에 무관심해진다. </th>
                            <td className={getCheckOnClassName(31, 1)} onClick={() => putAnswerItem(31, 1)}>
                                ①
                            </td>
                            <td className={getCheckOnClassName(31, 2)} onClick={() => putAnswerItem(31, 2)}>
                                ②
                            </td>
                            <td className={getCheckOnClassName(31, 3)} onClick={() => putAnswerItem(31, 3)}>
                                ③
                            </td>
                            <td className={getCheckOnClassName(31, 4)} onClick={() => putAnswerItem(31, 4)}>
                                ④
                            </td>
                            <td className={getCheckOnClassName(31, 5)} onClick={() => putAnswerItem(31, 5)}>
                                ⑤
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <h2 className="center2">검사지 분석결과 </h2>
            <div className="tab-story-number">
                <table className="center-check-table-basic" cellPadding="0" cellSpacing="0">
                    <thead>
                        <tr>
                            <td colSpan={2} className="center-td-blue center-text-center">
                                1. 수행전략검사 하위요인 (1-24문항) (R)은 역채점 문항임.
                            </td>
                            <td className="center-td-blue center-text-center">
                                총합점수
                                <br />
                                (각 문항 체크값 총합)
                            </td>
                            <td colSpan={3} className="center-td-blue center-text-center">
                                수준
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>혼잣말</td>
                            <td>1, 6, 13, 20번</td>
                            <td>{aloneTalkScore}</td>
                            <td className="center-w30">하</td>
                            <td className="center-w30">중</td>
                            <td className="center-w30">상</td>
                        </tr>
                        <tr>
                            <td>컨디션 조절</td>
                            <td>10, 12,15, 17, 19, 22, 24번</td>
                            <td>{conditionManageScore}</td>
                            <td>하</td>
                            <td>중</td>
                            <td>상</td>
                        </tr>
                        <tr>
                            <td>심상과 목표설정</td>
                            <td>3, 4, 8, 9, 16번</td>
                            <td>{mindAndGoalSettingScore}</td>
                            <td>하</td>
                            <td>중</td>
                            <td>상</td>
                        </tr>
                        <tr>
                            <td>긴장풀기</td>
                            <td>5, 11(R), 18, 23번</td>
                            <td>{relexScore}</td>
                            <td>하</td>
                            <td>중</td>
                            <td>상</td>
                        </tr>
                        <tr>
                            <td>감정조절</td>
                            <td>2(R), 7(R), 14(R), 21(R)번</td>
                            <td>{mindControlScore}</td>
                            <td>하</td>
                            <td>중</td>
                            <td>상</td>
                        </tr>
                        <tr>
                            <td colSpan={2} className="center-td-blue">
                                2. 스테일네스 검사지 (1-7문항)
                                <br />
                                활력(7-17), 보통(18-27), 심각(28점 이상)
                            </td>
                            <td>{statelessScore}</td>
                            <td className={`${statelessScore >= 7 && statelessScore <= 17 ? 'red' : ''}`}>활력</td>
                            <td className={`${statelessScore >= 18 && statelessScore <= 27 ? 'red' : ''}`}>보통</td>
                            <td className={`${statelessScore >= 28 ? 'red' : ''}`}>심각</td>
                        </tr>
                    </tbody>
                </table>
                {/* <div className="tbpd20">
                    <h4>1. 수행전략검사 총평</h4>
                    <textarea className="center-textarea-s"></textarea>

                    <h4>2. 스테일네스 분석 총평</h4>
                    <textarea className="center-textarea-s"></textarea>
                </div> */}
            </div>
        </>
    );
}
