import { JsonProperty, JsonObject } from "json2typescript";
import ChargeType from './ChargeType';
import Code from '../code/Code';

@JsonObject("charge")
class Charge {
    @JsonProperty("infraNo")
    infraNo?: string;
    @JsonProperty("cost")
    cost?: number;
    @JsonProperty("chargeTypeCode")
    chargeTypeCode?: Code;
    @JsonProperty("chargeTypeCodeId")
    chargeTypeCodeId?: number;
    
    static makeCharge(infraNo: string, chargeTypeCodeId: number, cost: number): Charge {
        let charge = new Charge();
        charge.infraNo = infraNo;
        charge.chargeTypeCodeId = chargeTypeCodeId;
        charge.cost = cost;

        return charge;
    }
}

export default Charge;