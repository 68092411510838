interface SignUpStepBarComponentProps {
    step?: string;
}

function SignUpStepBarComponent(props: SignUpStepBarComponentProps) {
    return (
        <div className="signup-step-bar-container">
            <div className={`${props.step == 'step1' ? 'active' : ''}`}>STEP 01 약관동의</div>
            <div className={props.step == 'step2' ? 'active' : ''}>STEP 02 본인인증</div>
            <div className={props.step == 'step3' ? 'active' : ''}>STEP 03 회원정보입력</div>
        </div>
    )
}

export default SignUpStepBarComponent;