import React, { useEffect, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { RootState } from '../../moldules/index';

function MyPageUserInfoHeader() {
    const { user, myTeam, isLogin } = useSelector((state: RootState) => state.userSessionReducer);
    const [menuName, setMenuName] = useState<string>();
    const location = useLocation();

    useEffect(() => {
        let path = location.pathname;
        if (path === '/mypage/team-admin/setting') {
            setMenuName('내 정보 설정');
        } else if (path === '/mypage/team-admin/reservation') {
            setMenuName('팀 예약 관리');
        } else if (path === '/mypage/team-admin/player-list') {
            setMenuName('팀소속 선수');
        } else if (path === '/mypage/team-admin/schedule') {
            setMenuName('팀 일정');
        } else if (path === '/mypage/team-admin/qna') {
            setMenuName('문의하기');
        }
    }, [location]);
    if (myTeam) {
        return (
            <>
                <div className="my-info">
                    <div className="profile">
                        <Link to="/">
                            <img style={{ width: '27px', height: '27px' }} src={user.imageUrl} />
                        </Link>
                    </div>
                    <div className="name">
                        <span>
                            {myTeam.name} - {user.name}
                        </span>
                        <br />
                        {user.email}
                    </div>
                </div>
                <div className="my-location" style={{ width: '100%', textAlign: 'right' }}>
                    <span>{menuName}</span>
                </div>
            </>
        );
    } else {
        return <></>;
    }
}

export default MyPageUserInfoHeader;
