import * as React from 'react';
import Code from '../../domain/code/Code';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../moldules/index';
import { addFilterAction, deleteFilterAction } from '../../moldules/FilterItem';
import { retrieveInfraAction } from '../../moldules/InfraList';
import InfraService from '../../service/infra/InfraService';
import InfraServiceImpl from '../../service/infra/impl/InfraServiceImpl';
import InfraCategory from '../../domain/infra/InfraCategory';
import MiniSearchBar from './MiniSearchBar';
import SearchLeft from '../layout/SearchLeft';
import SearchIn from '../layout/SearchIn';
import { CodeType } from '../../domain/code/CodeType';
import MultiSelectByInfraCategory from './filter/MultiSelectByInfraCategory';
import MultiSelect from './filter/MultiSelect';

const infraService: InfraService = new InfraServiceImpl();

export enum SearchItemType {
    INFRA = '시설',
    REGION='지역',
    SPORT='종목',
    TEAM='소속',
}

export enum SearchType {
    MULTI,
    READIO
}

export class SearchItemCodeValue {
    searchItemType: SearchItemType;
    codes: Code[];

    constructor(searchItemType:SearchItemType, codes: Code[]) {
        this.searchItemType= searchItemType;
        this.codes = codes;
    }
}

export class SearchItemInfraCategoryValue {
    searchItemType: SearchItemType;
    infraCategories: InfraCategory[];

    constructor(searchItemType:SearchItemType, infraCategories: InfraCategory[]) {
        this.searchItemType= searchItemType;
        this.infraCategories = infraCategories;
    }
}

interface LeftSearcherProps {
    parentInfraCategoryNo: number;
    searchItemCodeValues: SearchItemCodeValue[];
    searchItemInfraCategoryValues: SearchItemInfraCategoryValue[]
}

function LeftSearcher(props: LeftSearcherProps) {
    const filterItem = useSelector((state: RootState) => state.filterItemAction);
    
    const dispatch = useDispatch();
    
    const renewInfra = () => {   
        infraService.getInfraOfParentCategoryNo(props.parentInfraCategoryNo)
        .then(infras => {
            dispatch(retrieveInfraAction(1, filterItem))   
        });
    }

    return (
        <SearchLeft>
            <SearchIn>
                <MiniSearchBar placeholder="시설 혹은 팀 검색하세용."></MiniSearchBar>
                <div className="h30"></div>
                <div className="filter-box">
                {
                    props.searchItemInfraCategoryValues.map((searchItemInfraCategoryValues, index) => {
                        return <MultiSelectByInfraCategory codeType={CodeType.PARENT_INFRA_CATEGORY} key={index} title={searchItemInfraCategoryValues.searchItemType} infraCategories={searchItemInfraCategoryValues.infraCategories}></MultiSelectByInfraCategory>
                    })
                }
                {
                    props.searchItemCodeValues.map((searchItemValue, index) => {
                        return <MultiSelect codeType={CodeType.REGION} key={index} title={searchItemValue.searchItemType} codes={searchItemValue.codes}></MultiSelect>
                    })
                 }
                    <div className="apply-box">
                        <button onClick={renewInfra}>조회</button>
                    </div>
                </div>
            </SearchIn>
        </SearchLeft>
    )
}

export default LeftSearcher;