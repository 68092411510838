import React, { useEffect, useState } from 'react';
import QuestionnaireResultComponent from './resultForms/QuestionnaireResult';
import RecordingResultComponent from './resultForms/RecordingResult';
import QnA from '../../../../domain/QnA';
import TargetPerson from '../../../../domain/TargetPerson';
import RehabServiceImpl from '../../../../service/impl/RehabServiceImpl';
import RehabTargetHistory from '../../../../domain/RehabTargetHistory';

interface IProps {
    targetPerson: TargetPerson;
    selectedItem: RehabTargetHistory;
}

const rehabSerevice = new RehabServiceImpl();

export default function ResultFormComponent(props: IProps) {
    let qnaTitles = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J'];
    let targetPerson = props.targetPerson;
    let selectedItem = props.selectedItem;
    const [qnaList, setQnaList] = useState<QnA[]>([]);

    useEffect(() => {
        rehabSerevice.getQuestionnairesNAnswer(targetPerson.targetNo).then((results: any) => {
            setQnaList(results.qnaList);
        });
    }, []);

    return (
        <>
            <div className="center-popup-reporting-box">
                <div className="flex-top">
                    <div className="tabonleft">
                        <h2 className="center2">
                            <a href="#questionnaire">1.설문정보</a>
                        </h2>
                        <ul>
                            {qnaList.map((item, idx) => {
                                if (item.answerNo !== 0)
                                    return (
                                        <li key={idx}>
                                            <a href={'#questionnaire_' + idx}>
                                                {qnaTitles[idx]}. {item.displayTitle}
                                            </a>
                                        </li>
                                    );
                            })}
                        </ul>
                        <h2 className="center2">
                            <a href="#checkConditions">2.상태확인정보</a>
                        </h2>
                        <h2 className="center2">
                            <a href="#recording">3.신체측정정보</a>
                        </h2>
                        <ul>
                            <li>
                                <a href="#recording_status">A. 신체측정</a>
                            </li>
                            <li>
                                <a href="#recording_basal">B. 기초체력 측정</a>
                            </li>
                            <li>
                                <a href="#recording_special">C. 전문체력 측정</a>
                                <ul>
                                    <li>
                                        <a href="#special_inBody">1. 인바디</a>
                                    </li>
                                    <li>
                                        <a href="#special_formChecker">2. 폼체커</a>
                                    </li>
                                    <li>
                                        <a href="#special_footChecker">3. 풋체커</a>
                                    </li>
                                    <li>
                                        <a href="#special_bioDex">4. 바이오덱스</a>
                                    </li>
                                    <li>
                                        <a href="#special_treadMillTest">5. 가스분석기</a>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                    {/* <!--//tabonleft--> */}
                    <div className="tabonright">
                        <QuestionnaireResultComponent
                            qnaList={qnaList}
                            targetPerson={targetPerson}
                            selectedItem={selectedItem}
                        ></QuestionnaireResultComponent>
                        <RecordingResultComponent targetPerson={targetPerson} selectedItem={selectedItem}></RecordingResultComponent>
                    </div>
                    {/* <!--//tabonright--> */}
                </div>
                {/* <!--//flex-top--> */}
            </div>
            {/* <!--//center-popup-list-box--> */}
        </>
    );
}
