import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useInfraRetrieveInfraNo from '../../../hook/infra/useInfraRetrieveByInfraNo';
import SearchLeft from '../../../component/layout/SearchLeft';
import SearchIn from '../../../component/layout/SearchIn';
import MiniSearchBar from '../../../component/search/MiniSearchBar';
import InfraNearDistanceContainer from '../../../container/infra/InfraNearDistanceContainer';
import { InfraCategoryType } from '../../../domain/infra/InfraCategoryType';
import SportInfraDetailComponent from '../component/detail/sportInfra/SportInfraDetailComponent';
import SearchRight from '../../../component/layout/SearchRight';
import SiteDepth from '../../../component/nav/SiteDepth';
import ReservationPopupContainer from '../../../container/reservation/ReservationPopupContainer';
import SportInfraHeader from '../layout/SportInfraHeader';
import { useSelector } from 'react-redux';
import { RootState } from '../../../moldules/index';
import useFavoritesInfraRetrieve from '../../../hook/favorites/useFavoritesInfraRetrieve';

function SportInfraDetailPage() {
    const { user } = useSelector((rootState: RootState) => rootState.userSessionReducer);
    const params: any = useParams();
    const { infra } = useInfraRetrieveInfraNo(params.infraNo);
    const [reservationPopupOpenable, setReservationPopupOpenable] = useState(false);
    const changeReservationPopupOpenable = (openable: boolean) => {
        setReservationPopupOpenable(openable);
    };

    const { userFavoritesInfra, setUserFavoritesInfra } = useFavoritesInfraRetrieve(user?.userNo, infra?.infraNo);

    return (
        <>
            <SportInfraHeader></SportInfraHeader>
            <div className="content-wrap">
                <div className="sub_contents">
                    <div className="snb">
                        <h2>전지훈련시설</h2>
                        <ul>
                            <li className="menu_on">
                                <a href="/search-list/default/sport/0/0/0">스포츠 시설</a>
                            </li>
                            <li>
                                <a href="/search-list/default/sub/0/0/0">부대 시설</a>
                            </li>
                        </ul>
                    </div>

                    <div className="contents_ad">
                        <div className="page_tle">
                            <h3>스포츠 시설</h3>
                            <div className="page_nav">
                                <ul>
                                    <li>
                                        <a href="/iis/web/main.do">홈</a>
                                    </li>
                                    <li>
                                        <a href="/trainning">전지훈련시설</a>
                                    </li>
                                    <li>
                                        <a href="/search-list/default/sport/0/0/0">스포츠 시설</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="new_InfraDetail">
                            <SearchRight>
                                <SearchIn>
                                    {infra ? (
                                        <SportInfraDetailComponent
                                            infra={infra}
                                            user={user}
                                            immediatlyUserFavoritesInfra={userFavoritesInfra}
                                            setImmediatlyUserFavoritesInfra={setUserFavoritesInfra}
                                            setReservationPopupOpenable={setReservationPopupOpenable}
                                        ></SportInfraDetailComponent>
                                    ) : (
                                        <></>
                                    )}
                                </SearchIn>
                            </SearchRight>
                            <SearchLeft>
                                <SearchIn>
                                    <MiniSearchBar placeholder="시설 및 팀 검색하세요."></MiniSearchBar>
                                    <div className="h30"></div>
                                    <div className="filter-box">
                                        <InfraNearDistanceContainer
                                            title={'주변 스포츠 시설'}
                                            parentInfraCategoryNo={InfraCategoryType.SPORT_INFRA_NO}
                                            infra={infra}
                                        ></InfraNearDistanceContainer>
                                        <InfraNearDistanceContainer
                                            title={'주변 숙소'}
                                            parentInfraCategoryNo={InfraCategoryType.LODGMENT_NO}
                                            infra={infra}
                                        ></InfraNearDistanceContainer>
                                        <InfraNearDistanceContainer
                                            title={'주변 맛집'}
                                            parentInfraCategoryNo={InfraCategoryType.FOOD_NO}
                                            infra={infra}
                                        ></InfraNearDistanceContainer>
                                    </div>
                                </SearchIn>
                            </SearchLeft>
                        </div>
                    </div>
                </div>
            </div>

            {infra ? (
                <ReservationPopupContainer
                    mainInfra={infra}
                    childInfras={infra.childInfras}
                    openable={reservationPopupOpenable}
                    changeReservationPopupOpenable={changeReservationPopupOpenable}
                ></ReservationPopupContainer>
            ) : (
                <></>
            )}
        </>
    );
}

export default SportInfraDetailPage;
