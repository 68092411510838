import React, { useState, useEffect } from 'react';
import Prescription from '../../../../../domain/Prescription';
import { JsonConvert } from 'json2typescript';
import { RehabProgramPrescription } from '../../../../../domain/prescription/RehabProgramPrescription';

interface IProps {
    handler: (evt: any) => void;
    prescription: RehabProgramPrescription;
}

const jsonConvert = new JsonConvert();

const RehabProgramFormComponent = (props: IProps) => {
    const handler = props.handler;
    //let conditioning: ConditioningPrescription = props.prescription.conditioningPrescription;
    const [conditioning, setConditioning] = useState<RehabProgramPrescription>(new RehabProgramPrescription());

    useEffect(() => {
        if (props.prescription) setConditioning(props.prescription);
    }, [props.prescription]);

    return (
        <>
            <div className="flex-basic">
                <div className="center-half">
                    <h2 className="center">왼어깨</h2>
                    <div className="center-bg-gray flex">
                        <div className="center-bg-blue">왼어깨</div>
                        <input
                            className="center-input-gray center-w50"
                            type="text"
                            name="rehabProgramPrescription"
                            id="leftShoulder"
                            onChange={handler}
                            value={conditioning.leftShoulder}
                        />
                        <label className="center-label">회</label>
                    </div>
                </div>
                <div className="center-half">
                    <h2 className="center">오른어깨</h2>
                    <div className="center-bg-gray flex">
                        <div className="center-bg-blue">오른어깨</div>
                        <input
                            className="center-input-gray center-w50"
                            type="text"
                            name="rehabProgramPrescription"
                            id="rightShoulder"
                            onChange={handler}
                            value={conditioning.rightShoulder}
                        />
                        <label className="center-label">회</label>
                    </div>
                </div>
            </div>
            <div className="flex-basic">
                <div className="center-half">
                    <h2 className="center">왼골반</h2>
                    <div className="center-bg-gray flex">
                        <div className="center-bg-blue">왼골반</div>
                        <input
                            className="center-input-gray center-w50"
                            type="text"
                            name="rehabProgramPrescription"
                            id="leftPelvis"
                            onChange={handler}
                            value={conditioning.leftPelvis}
                        />
                        <label className="center-label">회</label>
                    </div>
                </div>
                <div className="center-half">
                    <h2 className="center">오른골반</h2>
                    <div className="center-bg-gray flex">
                        <div className="center-bg-blue">오른골반</div>
                        <input
                            className="center-input-gray center-w50"
                            type="text"
                            name="rehabProgramPrescription"
                            id="rightPelvis"
                            onChange={handler}
                            value={conditioning.rightPelvis}
                        />
                        <label className="center-label">회</label>
                    </div>
                </div>
            </div>
            <div className="flex-basic">
                <div className="center-half">
                    <h2 className="center">왼무릎</h2>
                    <div className="center-bg-gray flex">
                        <div className="center-bg-blue">왼무릎</div>
                        <input
                            className="center-input-gray center-w50"
                            type="text"
                            name="rehabProgramPrescription"
                            id="leftKnee"
                            onChange={handler}
                            value={conditioning.leftKnee}
                        />
                        <label className="center-label">회</label>
                    </div>
                </div>
                <div className="center-half">
                    <h2 className="center">오른무릎</h2>
                    <div className="center-bg-gray flex">
                        <div className="center-bg-blue">오른무릎</div>
                        <input
                            className="center-input-gray center-w50"
                            type="text"
                            name="rehabProgramPrescription"
                            id="rightKnee"
                            onChange={handler}
                            value={conditioning.rightKnee}
                        />
                        <label className="center-label">회</label>
                    </div>
                </div>
            </div>
            <div className="flex-basic">
                <div className="center-half">
                    <h2 className="center">왼팔꿈치</h2>
                    <div className="center-bg-gray flex">
                        <div className="center-bg-blue">왼팔꿈치</div>
                        <input
                            className="center-input-gray center-w50"
                            type="text"
                            name="rehabProgramPrescription"
                            id="leftElbow"
                            onChange={handler}
                            value={conditioning.leftElbow}
                        />
                        <label className="center-label">회</label>
                    </div>
                </div>
                <div className="center-half">
                    <h2 className="center">오른팔꿈치</h2>
                    <div className="center-bg-gray flex">
                        <div className="center-bg-blue">오른팔꿈치</div>
                        <input
                            className="center-input-gray center-w50"
                            type="text"
                            name="rehabProgramPrescription"
                            id="rightElbow"
                            onChange={handler}
                            value={conditioning.rightElbow}
                        />
                        <label className="center-label">회</label>
                    </div>
                </div>
            </div>

            <div className="flex-basic">
                <div className="center-half">
                    <h2 className="center">왼손목</h2>
                    <div className="center-bg-gray flex">
                        <div className="center-bg-blue">왼손목</div>
                        <input
                            className="center-input-gray center-w50"
                            type="text"
                            name="rehabProgramPrescription"
                            id="leftWrist"
                            onChange={handler}
                            value={conditioning.leftWrist}
                        />
                        <label className="center-label">회</label>
                    </div>
                </div>
                <div className="center-half">
                    <h2 className="center">오른손목</h2>
                    <div className="center-bg-gray flex">
                        <div className="center-bg-blue">오른손목</div>
                        <input
                            className="center-input-gray center-w50"
                            type="text"
                            name="rehabProgramPrescription"
                            id="rightWrist"
                            onChange={handler}
                            value={conditioning.rightWrist}
                        />
                        <label className="center-label">회</label>
                    </div>
                </div>
            </div>
            <div className="flex-basic">
                <div className="center-half">
                    <h2 className="center">왼발목</h2>
                    <div className="center-bg-gray flex">
                        <div className="center-bg-blue">왼발목</div>
                        <input
                            className="center-input-gray center-w50"
                            type="text"
                            name="rehabProgramPrescription"
                            id="leftAnkle"
                            onChange={handler}
                            value={conditioning.leftAnkle}
                        />
                        <label className="center-label">회</label>
                    </div>
                </div>
                <div className="center-half">
                    <h2 className="center">오른발목</h2>
                    <div className="center-bg-gray flex">
                        <div className="center-bg-blue">오른발목</div>
                        <input
                            className="center-input-gray center-w50"
                            type="text"
                            name="rehabProgramPrescription"
                            id="rightAnkle"
                            onChange={handler}
                            value={conditioning.rightAnkle}
                        />
                        <label className="center-label">회</label>
                    </div>
                </div>
            </div>
            <div className="flex-basic">
                <div className="center-half">
                    <h2 className="center">목</h2>
                    <div className="center-bg-gray flex">
                        <div className="center-bg-blue">목</div>
                        <input
                            className="center-input-gray center-w50"
                            type="text"
                            name="rehabProgramPrescription"
                            id="neck"
                            onChange={handler}
                            value={conditioning.neck}
                        />
                        <label className="center-label">회</label>
                    </div>
                </div>
                <div className="center-half">
                    <h2 className="center">허리</h2>
                    <div className="center-bg-gray flex">
                        <div className="center-bg-blue">허리</div>
                        <input
                            className="center-input-gray center-w50"
                            type="text"
                            name="rehabProgramPrescription"
                            id="waist"
                            onChange={handler}
                            value={conditioning.waist}
                        />
                        <label className="center-label">회</label>
                    </div>
                </div>
            </div>
        </>
    );
};

export default RehabProgramFormComponent;
