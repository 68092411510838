import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import MyPageMainLayout from '../../../layout/MyPageMainLayout';
import MyPageRightContent from '../../../layout/MyPageRightContent';
import Reservation from '../../../../../domain/reservation/Reservation';
import ReservationGroupInfraCard from '../../../../../component/infra/ReservationGroupInfraCard';

import { useSelector } from 'react-redux';
import { RootState } from '../../../../../moldules/index';
import Paging from '../../../../../domain/common/Paging';
import ReservationSearchCondition from '../../../../../domain/reservation/param/ReservationSearchCondition';
import useReservationGroupOfTeam from '../../../../../hook/useReservationGroupOfTeam';
import ReservationServiceImpl from '../../../../../service/reservation/impl/ReservationServiceImpl';
import ReservationService from '../../../../../service/reservation/ReservationService';
import { confirmAlert } from 'react-confirm-alert';
import Infra from '../../../../../domain/infra/Infra';
import ReservationStateType from '../../../../../domain/reservation/ReservationStateType';

const reservationService: ReservationService = new ReservationServiceImpl();
function MyPageReservationIndexPage() {
    const { user, myTeam, isLogin, hasTeamAuth, hasSystemAuth } = useSelector((state: RootState) => state.userSessionReducer);
    const history = useHistory();

    //const {myTeam} = useSelector((state: RootState) => state.userSessionReducer);
    //const paging = new Paging(0, 5);
    const [paging, setPaging] = useState<Paging>(new Paging(0, 5));
    const [teamNo, setTeamNo] = useState<string>();
    const searchCondition = new ReservationSearchCondition();
    searchCondition.teamNos = user.userNo;

    if (!hasTeamAuth) {
        searchCondition.reservaterNos = user.userNo;
    }

    //const { reservations } = useReservationGroupOfTeam(myTeam.teamNo, searchCondition, paging);
    const [reservations, setReservations] = useState<Reservation[]>();
    const [totCnt, setTotCnt] = useState<number>();
    const [current, setCurrent] = useState<number>(-1);
    const [pageSize, setPageSize] = useState<number>(5);
    const [pageCnt, setPageCnt] = useState<number>(0);
    const [pagingInfo, setPagingInfo] = useState<string>('예약 현황 조회 중...');

    const pagingHandler = (evt: any) => {
        let action = evt.target.id;
        let nextPage = 0;
        console.log(action);
        if (action === 'pre') {
            if (current !== 0) {
                nextPage = current - 1;
            } else {
                confirmAlert({ title: '알림', message: '첫 페이지 입니다.', buttons: [{ label: '확인', onClick: () => {} }] });
            }
        } else if (action === 'next') {
            if (current !== pageCnt) {
                nextPage = current + 1;
            } else {
                confirmAlert({ title: '알림', message: '마지막 페이지 입니다.', buttons: [{ label: '확인', onClick: () => {} }] });
            }
        }
        let nextPageInfo = new Paging(nextPage, pageSize);
        setCurrent(nextPage);
        setPaging(nextPageInfo);
    };

    useEffect(() => {
        if (teamNo) {
            setCurrent(0);
        }
    }, [teamNo]);

    useEffect(() => {
        if (teamNo)
            reservationService.getReservationGroupForUser(teamNo, paging).then((reservationInfo: any) => {
                let reservations = reservationInfo.reservations;
                let totCnt = reservationInfo.reservationCnt;
                setReservations(reservations);
                setTotCnt(totCnt);
                setPagingInfo('현재: ' + (current + 1) + ' / 총페이지: ' + pageCnt + ' / 총 예약횟수: ' + totCnt);
            });
    }, [current]);

    useEffect(() => {
        if (totCnt) {
            let cnt = Math.floor(totCnt / pageSize);
            let isRest = totCnt % pageSize;
            if (isRest > 0) {
                cnt += 1;
            }

            setPageCnt(cnt);
            setPagingInfo('현재: ' + (current + 1) + ' / 총페이지: ' + cnt + ' / 총 예약횟수: ' + totCnt);
        }
    }, [totCnt]);

    useEffect(() => {
        if (user.userNo) setTeamNo(user.userNo);
    }, []);

    let title = '<' + user.name + '> 예약 현황';

    const detailLinkHandler = (evt: any) => {
        evt.preventDefault();

        let groupNo = evt.target.id;
        history.push('/mypage/team-admin/reservation/' + groupNo);
    };

    const reservatonCancelEventHandler = () => {};

    return (
        <MyPageMainLayout>
            <MyPageRightContent contentTitle={title}>
                <div className="flex-basic">
                    <i
                        id="pre"
                        className="fas fa-angle-left"
                        style={{ cursor: 'pointer', fontSize: '20px', fontWeight: 900, paddingLeft: '25px' }}
                        onClick={pagingHandler}
                    ></i>
                    <span style={{ fontSize: '18px', fontWeight: 900 }}>
                        {pagingInfo}
                        {/*현재: 1 / 총페이지: 50 / 총 예약횟수: 250*/}
                    </span>
                    <i
                        id="next"
                        className="fas fa-angle-right"
                        style={{ cursor: 'pointer', fontSize: '20px', fontWeight: 900, paddingRight: '25px' }}
                        onClick={pagingHandler}
                    ></i>
                </div>
                {reservations ? (
                    reservations.length > 0 ? (
                        reservations.map((reservation: Reservation, index: number) => {
                            return (
                                <>
                                    <div className="my-in" key={index}>
                                        <div className="my-in-info">
                                            <div className="my-in-info-box">
                                                <div style={{ width: '5%' }}>
                                                    {reservation.parentInfra ? (
                                                        <img
                                                            width={73}
                                                            height={75}
                                                            src={Object.setPrototypeOf(reservation.parentInfra, Infra.prototype).getMainAttachFile()}
                                                        />
                                                    ) : (
                                                        <img
                                                            width={73}
                                                            height={75}
                                                            src={Object.setPrototypeOf(reservation.infra, Infra.prototype).getMainAttachFile()}
                                                        />
                                                    )}
                                                </div>
                                                <div style={{ width: '10%' }}>
                                                    <span style={{ fontWeight: 900 }}>예약상태</span> <br />
                                                    <p className="blue" style={{ padding: '5px' }}>
                                                        {reservation.reservationStateCode?.name}
                                                    </p>
                                                </div>
                                                <div style={{ width: '25%' }}>
                                                    <span style={{ fontWeight: 900 }}>예약센터</span>
                                                    <br />
                                                    <p style={{ padding: '5px' }}>
                                                        <Link to={'#'} onClick={detailLinkHandler} id={reservation.reservationGroupNo}>
                                                            {reservation.infra?.name}
                                                        </Link>
                                                    </p>
                                                </div>
                                                <div style={{ width: '20%' }}>
                                                    <span style={{ fontWeight: 900 }}>예약일정</span>
                                                    <br />
                                                    <span style={{ padding: '5px' }}>{reservation.startDate?.split(' ')[0]}</span>
                                                    <br />
                                                    <span style={{ padding: '5px' }}>
                                                        {reservation.startDate?.split(' ')[1]} ~ {reservation.endDate?.split(' ')[1]}
                                                    </span>
                                                </div>
                                                <div style={{ width: '20%' }}>
                                                    <span style={{ fontWeight: 900 }}>예약인원</span>
                                                    <br />
                                                    <p style={{ padding: '5px' }}>{reservation.cnt} 명</p>
                                                </div>
                                                <div style={{ width: '20%', textAlign: 'right', marginTop: '15px', marginRight: '15px' }}>
                                                    {reservation.reservationStateCodeId !== 1006 && hasTeamAuth ? (
                                                        <button
                                                            className="bg-light-blue"
                                                            onClick={() => {
                                                                const reservationCancelType: number = ReservationStateType.RESERVATION_CANCEL;
                                                                reservation.reservationStateCodeId = reservationCancelType;
                                                                if (reservation.reservationGroupNo) {
                                                                    reservationService
                                                                        .updateGroupReservation(reservation.reservationGroupNo, reservation)
                                                                        .then((reservations: Reservation[]) => {
                                                                            //console.log(reservations);
                                                                            //setReservation(updatedReservation);
                                                                        });
                                                                } else {
                                                                }
                                                            }}
                                                            style={{
                                                                border: '1px solid #e3e3e3',
                                                                padding: '15px',
                                                                marginRight: '15px',
                                                                borderRadius: '5px',
                                                                fontWeight: 700,
                                                                fontSize: '15px',
                                                            }}
                                                        >
                                                            전체예약취소
                                                        </button>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            );
                        })
                    ) : (
                        <div className="my-in">
                            <div className="my-in-info">
                                <div className="my-in-info-box">
                                    <p className="txt-center">
                                        방문 예약이 없습니다.<br></br>스포츠시서 쉽고 편하게 예약해보세요.
                                    </p>
                                    {/* <Link className="bt-blue" to={'/trainning'}>
                                        스포츠 시설 찾기
                                    </Link> */}
                                </div>
                            </div>
                        </div>
                    )
                ) : (
                    <></>
                )}
            </MyPageRightContent>
        </MyPageMainLayout>
    );
}

export default MyPageReservationIndexPage;
