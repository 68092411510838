import APIServerConfig from '../../../config/APIServerConfig';
import AbstractService from '../../AbstractService';
import UserFavoritesInfra from '../../../domain/favorities/UserFavoritesInfra';
import FavoritesInfraService from '../FavoritesInfraService';

class FavoritesInfraServiceImpl extends AbstractService implements FavoritesInfraService {
    getUserFavoritiesInfras(userNo: string, infraCategoryNo: string): Promise<UserFavoritesInfra[]> {
        let params: any = {};
        if(infraCategoryNo) {
            params['infraCategoryNo'] = infraCategoryNo;
        }
        return new Promise((resolve, reject) => {
            APIServerConfig.recordAPIAxios.get(`/user/favorites/infra/${userNo}`, {
                params: params
            })
            .then(response => {
                let userFavoritiesInfras = this.jsonConvert.deserializeArray(response.data, UserFavoritesInfra);
                resolve(userFavoritiesInfras);
            });
        });
    }

    registeFavoritesInfra(userFavoritesInfra: UserFavoritesInfra): Promise<UserFavoritesInfra> {       
        return new Promise((resolve, reject) => {
            APIServerConfig.recordAPIAxios.post(`/user/favorites/infra`, JSON.stringify(userFavoritesInfra))
            .then((response) => {
                let registedUserFavoritesInfa = this.jsonConvert.deserializeObject(response.data, UserFavoritesInfra);
                resolve(registedUserFavoritesInfa);
            })
            .catch(err => {
                console.error(err);
                reject(err);
            });
        })
    }

    getUserFavoritesInfra(userNo: string, infraNo: string): Promise<UserFavoritesInfra> {
        return new Promise((resolve, reject) => {
            APIServerConfig.recordAPIAxios.get(`/user/favorites/infra/${userNo}/${infraNo}`)
            .then((response) => {
                let userFavoritesInfra = this.jsonConvert.deserializeObject(response.data, UserFavoritesInfra);
                resolve(userFavoritesInfra);
            })
            .catch(err => {
                console.error(err);
                reject(err);
            });
        });
    }

    deleteFavoritesInfra(userFavoritesInfra: UserFavoritesInfra): Promise<void> {
        return new Promise((resolve, reject) => {
            APIServerConfig.recordAPIAxios.delete(`/user/favorites/infra/${userFavoritesInfra.favoriterNo}/${userFavoritesInfra.favoritesTargetNo}`)
            .then((response) => {
                resolve(response.data);
            })
            .catch(err => {
                console.error(err);
                reject(err);
            }); 
        });
    }
}

export default FavoritesInfraServiceImpl;