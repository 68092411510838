import { JsonObject, JsonProperty } from 'json2typescript';
import User from '../user/User';
import Team from '../team/Team';
import Infra from '../infra/Infra';
import Code from '../code/Code';
import DateUtils from '../../utils/DateUtils';
import ScheduleAble from '../common/ScheduleAble';
@JsonObject('reservation')
class Reservation implements ScheduleAble {
    @JsonProperty('reservationGroupNo')
    reservationGroupNo?: string;
    @JsonProperty('reservationNo')
    reservationNo?: string;
    @JsonProperty('registerNo')
    registerNo?: string;
    @JsonProperty('reservaterNo')
    reservaterNo?: string;
    @JsonProperty('teamNo')
    teamNo?: string;
    @JsonProperty('parentInfraNo')
    parentInfraNo?: string;
    @JsonProperty('infraNo')
    infraNo?: string;
    @JsonProperty('reservationStateCodeId')
    reservationStateCodeId?: number;

    @JsonProperty('reservater')
    reservater?: User;
    @JsonProperty('register')
    register?: User;
    @JsonProperty('team')
    team?: Team;
    @JsonProperty('parentInfra')
    parentInfra?: Infra;
    @JsonProperty('infra')
    infra?: Infra;
    @JsonProperty('reservationStateCode')
    reservationStateCode?: Code;
    @JsonProperty('approve')
    approve?: boolean;
    @JsonProperty('startDate')
    startDate?: string;
    @JsonProperty('endDate')
    endDate?: string;
    @JsonProperty('registeDate')
    registeDate?: string;
    @JsonProperty('comment')
    comment?: string;

    cnt?: string;

    static makeReservation(
        register: User,
        reservater: User,
        team: Team,
        parentInfraNo: string,
        infra: Infra,
        reservationStartDay: string,
        reservationEndDay: string,
    ): Reservation {
        let reservation = new Reservation();

        reservation.registerNo = register.userNo;
        reservation.reservaterNo = reservater.userNo;
        reservation.teamNo = team.teamNo;
        reservation.reservationStateCodeId = 1001;
        reservation.parentInfraNo = parentInfraNo;
        reservation.infraNo = infra.infraNo;
        reservation.startDate = reservationStartDay + ' 00:00';
        reservation.endDate = reservationEndDay + ' 00:00';

        return reservation;
    }

    getStartDate() {
        if (this.startDate) {
            return this.startDate.split(' ')[0];
        }
    }

    getStartDateTime() {
        if (this.startDate) {
            return this.startDate.split(' ')[1];
        }
    }

    getEndDate() {
        if (this.endDate) {
            return this.endDate?.split(' ')[0];
        }
    }

    getEndDateTime() {
        if (this.endDate) {
            return this.endDate?.split(' ')[1];
        }
    }

    getStartDateWeekDay() {
        return DateUtils.getDayOfWeek(this.startDate);
    }

    getEndDateWeekDay() {
        return DateUtils.getDayOfWeek(this.endDate);
    }
}

export default Reservation;
