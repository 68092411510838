import { useEffect, useState } from 'react';
import Board from '../../../../../domain/board/Board';
import BoardContent from '../../../../../domain/board/BoardContent';
import BoardContentServiceImpl from '../../../../../service/board/impl/BoardContentServiceImpl';
const boardContentService = new BoardContentServiceImpl();
function useTeamStoryStoryBoard(teamNo: string, boardsOfTeam: Board[]|undefined) {
    const [storyBoardContents, setStoryBoardContents] = useState(new Array<BoardContent>());

    useEffect(() => {
        if(boardsOfTeam) {
            boardsOfTeam.map(board => {
                if(board.boardSubType == 'STORY') {
                    boardContentService.getAllBoardContent(teamNo, board.boardNo)
                    .then((storyBoardContents: BoardContent[]) => {
                        setStoryBoardContents(storyBoardContents);
                    });
                }
            });
        }
    }, [boardsOfTeam]);

    return storyBoardContents;
}

export default useTeamStoryStoryBoard;