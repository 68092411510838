import React, { useEffect, useState } from 'react';
import Infra from '../../domain/infra/Infra';
import InfraCard from '../../component/infra/InfraCard';
import InfraServiceImpl from '../../service/infra/impl/InfraServiceImpl';
const infraService = new InfraServiceImpl();
interface InfraRecommandContainerProps  {
    recommandTitle: string;
    parentInfraCategoryNo: number;
}
function InfraRecommandContainer(props: InfraRecommandContainerProps) {
    const [infras, setInfras] = useState(new Array<Infra>());
    useEffect(() => {
        infraService.getInfraOfParentCategoryNo(props.parentInfraCategoryNo)
        .then(infras => {
            setInfras(infras.slice(0,3));
        })
    }, []);
    
    return (
        <div className="type">
            <p className="search-tit">{props.recommandTitle}</p>
            <ul className="area-room">
                {
                    infras.map((infra, index) => {
                        return <InfraCard key={index} infra={infra}></InfraCard>
                    })   
                }
            </ul>
            <div className="clear"></div>
        </div>
    )
}

export default InfraRecommandContainer;