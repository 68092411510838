import React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';

interface urlInter {
    teamNo: string;
    url: string;
}

function AdminTeamNav(props: urlInter) {
    const { teamNo, url } = props;

    let teamClass = 'bg-white round';
    let infoClass = 'bg-white round';
    let scheduleClass = 'bg-white round';
    let storyClass = ' bg-white round';

    if (url.indexOf('detail') > -1) {
        teamClass = 'bg-gray white';
    } else if (url.indexOf('belong') > -1) {
        infoClass = 'bg-gray white';
    } else if (url.indexOf('schedule') > -1) {
        scheduleClass = 'bg-gray white';
    } else if (url.indexOf('story') > -1) {
        storyClass = 'bg-gray white';
    }

    return (
        <div className="admin-tab" style={{ padding: '15px' }}>
            <Link to={`/admin/team/detail/${teamNo}`}>
                <button className={teamClass}>팀정보</button>
            </Link>
            <Link to={`/admin/team/belong/${teamNo}`}>
                <button className={infoClass}>소속선수</button>
            </Link>
            <Link to={`/admin/team/schedule/${teamNo}`}>
                <button className={scheduleClass}>팀일정</button>
            </Link>
            {/* <Link to={`/admin/team/story/${teamNo}`}>
                <button className={storyClass}>팀스토리</button>    
            </Link> */}
        </div>
    );
}

export default AdminTeamNav;
