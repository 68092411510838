import { useCallback, useEffect, useState } from "react";
import Infra from '../../../domain/infra/Infra';

export default function useReservationPopupContainerHook(openable: boolean, initReservationTimeVisible=false, 
                                                            initReservationInfraVisible=false,
                                                            initReservationStartDayVisible=false,
                                                            initReservationEndDayVisible=false,
                                                            initReservationTimeTable=undefined,
                                                            initReservationTime=undefined,
                                                            initReservationStartDay=undefined,
                                                            initReservationEndDay=undefined,
                                                            initReservationInfra=undefined) {
    const [reservationTimeVisilble, setReservationTimeVisible] = useState(false);
    const [reservationInfraVisible, setReservationInfraVisible] = useState(false);
    const [reservationStartDayVisible, setReservationStartDayVisible]= useState(false);
    const [reservationEndDayVisible, setReservationEndDayVisible] = useState(false);
    const [reservationTimeTable, setReservationTimeTable] = useState<Array<string>>();
    const [reservationTime, setReservationTime] = useState<string>();
    const [reservationStartDay, setReservationStartDay] = useState<string>();
    const [reservationEndDay, setReservationEndDay] = useState<string>();
    const [reservationInfra, setReservationInfra] = useState<Infra>();

    const rest = useCallback(() => {
        setReservationTimeVisible(initReservationTimeVisible);
        setReservationInfraVisible(initReservationInfraVisible);
        setReservationStartDayVisible(initReservationStartDayVisible);
        setReservationEndDayVisible(initReservationEndDayVisible);
        setReservationTimeTable(initReservationTimeTable);
        setReservationTime(initReservationTime);
        setReservationStartDay(initReservationStartDay);
        setReservationEndDay(initReservationEndDay);
        setReservationInfra(initReservationInfra);
    }, []);

    useEffect(() => {
        if(reservationStartDay && reservationEndDay) {
            if(reservationEndDay < reservationStartDay) {
                alert("예약 종료일이 시작일 보다 전입니다.");
            }
        }
    }, [reservationStartDay, reservationEndDay]);

    useEffect(() => {
        if(!openable) rest()
    }, [openable])


    return {
        reservationTimeVisilble, setReservationTimeVisible,
        reservationInfraVisible, setReservationInfraVisible,
        reservationTimeTable, setReservationTimeTable,
        reservationTime, setReservationTime,
        reservationStartDay, setReservationStartDay,
        reservationEndDay, setReservationEndDay,
        reservationInfra, setReservationInfra,
        reservationStartDayVisible, setReservationStartDayVisible,
        reservationEndDayVisible, setReservationEndDayVisible,
        rest
    }
}