import { useEffect, useState } from 'react';
import RehabCenterMeasureByGender from '../domain/statistics/RehabCenterMeasureByGender';
import { REHAB_CENTER_STATISTICS_SERVICE } from '../service/impl/index';
import RehabCenterMeasureBySport from '../domain/statistics/RehabCenterMeasureBySport';
import RehabCenterQnaRation from '../domain/statistics/RehabCenterQnaRation';
import { COLOR_ARR } from '../../../config/Constant';
import ChartDataUtils from '../../../utils/ChartDataUtils';

function useRehabStatisticsBasicInfo() {
    const [rehabCenterMeasureByGenders, setRehabCenterMeasureByGenders] = useState<RehabCenterMeasureByGender[]>();
    const [rehabCenterMeasureBySports, setRehabCenterMeasureBySports] = useState<RehabCenterMeasureBySport[]>();
    const [rehabCenterQnaRation, setRehabCenterQnaRation] = useState<RehabCenterQnaRation>();
    const [genderDataSet, setGenderDataSet] = useState<any>();
    const [sportDataSet, setSportDataSet] = useState<any>();
    const [qnaDataSet, setQnaDataSet] = useState<any>();

    useEffect(() => {
        REHAB_CENTER_STATISTICS_SERVICE.getMeasureByGender().then((rehabCenterMeasureByGenders) => {
            setRehabCenterMeasureByGenders(rehabCenterMeasureByGenders);
        });

        REHAB_CENTER_STATISTICS_SERVICE.getMeasureBySport().then((rehabCenterMeasureBySports) => {
            setRehabCenterMeasureBySports(rehabCenterMeasureBySports);
        });

        REHAB_CENTER_STATISTICS_SERVICE.getMeasureByQna().then((rehabCenterQnaRation) => {
            setRehabCenterQnaRation(rehabCenterQnaRation);
        });
    }, []);

    useEffect(() => {
        if (rehabCenterMeasureByGenders) setGenderDataSet(chartDataByGenderMake(rehabCenterMeasureByGenders));
    }, [rehabCenterMeasureByGenders]);

    useEffect(() => {
        if (rehabCenterMeasureBySports) setSportDataSet(chartDataBySportMake(rehabCenterMeasureBySports));
    }, [rehabCenterMeasureBySports]);

    useEffect(() => {
        if (rehabCenterQnaRation) setQnaDataSet(chartDataByQna(rehabCenterQnaRation));
    }, [rehabCenterQnaRation]);

    return {
        genderDataSet,
        sportDataSet,
        qnaDataSet,
    };
}

function chartDataByGenderMake(rehabCenterMeasureByGenders: RehabCenterMeasureByGender[]) {
    const data = {
        datasets: [
            {
                data: [rehabCenterMeasureByGenders[0]?.measureRate, rehabCenterMeasureByGenders[1]?.measureRate],
                backgroundColor: [COLOR_ARR[0], COLOR_ARR[1]],
            },
        ],
        labels: [rehabCenterMeasureByGenders[0]?.genderCode?.name, rehabCenterMeasureByGenders[1]?.genderCode?.name],
    };

    return data;
}

function chartDataBySportMake(rehabCenterMeasureBySports: RehabCenterMeasureBySport[]) {
    const dataOfDataSets: Array<number | undefined> = [];
    const backgoundColor: Array<string | undefined> = [];
    const labels: Array<string | undefined> = [];

    rehabCenterMeasureBySports.forEach((rehabCenterMeasureBySport: RehabCenterMeasureBySport, index: number) => {
        dataOfDataSets.push(rehabCenterMeasureBySport.measureRate);
        backgoundColor.push(COLOR_ARR[index]);
        labels.push(rehabCenterMeasureBySport.sportCode?.name);
    });

    // const data = {
    //     datasets: [{
    //         data: dataOfDataSets,
    //         backgroundColor: backgoundColor
    //     }],
    //     labels: labels
    // }

    // return data;
    return ChartDataUtils.getChartData(dataOfDataSets, backgoundColor, labels);
}

function chartDataByQna(rehabCenterQnaRation: RehabCenterQnaRation) {
    const dataOfDataSets: Array<number | undefined> = [rehabCenterQnaRation.qnaCompleteRate, rehabCenterQnaRation.qnaNotCompleteRate];
    const backgoundColor: Array<string | undefined> = [COLOR_ARR[0], COLOR_ARR[1]];
    const labels: Array<string | undefined> = ['설문응답', '설문미응답'];

    return ChartDataUtils.getChartData(dataOfDataSets, backgoundColor, labels);
}

export default useRehabStatisticsBasicInfo;
