import UserLocation from '../../../../domain/user/UserLocation';
abstract class ChoiceLocationRenderer {
    mapId: string;
    mapLevel: number;
    latitude?: number;
    longitude?: number;
    readonly map: any;

    constructor(mapId: string, mapLevel: number, latitude?: number, longitude?: number, userLocation?: UserLocation) {
        this.mapId = mapId;
        this.mapLevel = mapLevel;

        if (!latitude && !longitude && userLocation) {
            this.latitude = userLocation.latitude;
            this.longitude = userLocation.longitude;
        } else {
            this.latitude = latitude;
            this.longitude = longitude;
        }

        this.map = this.getMapInstance();
    }

    render() {
        //this.addMapTypeControl();
        //this.addZoomControl();
        this.myLocationMarkerRender();
    }

    abstract getMapInstance(): any;
    abstract addMapTypeControl(): void;
    abstract addZoomControl(): void;
    abstract myLocationMarkerRender(): void;
}

export default ChoiceLocationRenderer;
