import EventStatisticsService from '../EventStatisticsService';
import AbstractService from '../../AbstractService';
import RegionIssuedEvent from '../../../domain/event/RegionIssuedEvent';
import APIServerConfig from '../../../config/APIServerConfig';
import CouponStatusOfEvent from '../../../domain/event/CouponStatusOfEvent';
class EventStatisticsServiceImpl extends AbstractService implements EventStatisticsService {
    getYearIssuedCoupon(): Promise<RegionIssuedEvent[]> {
        return new Promise((resolve, reject) => {
            APIServerConfig.recordAPIAxios.get(`/event-statistic/year-issued-coupon`)
            .then((response) => {
                let regionIssuedEvents = this.jsonConvert.deserializeArray(response.data, RegionIssuedEvent);
                resolve(regionIssuedEvents);
            })
            .catch(err => {
                reject(err);
            })
        });
    }

    getCouponeByEvent(): Promise<CouponStatusOfEvent[]> {
        return new Promise((resolve, reject) => {
            APIServerConfig.recordAPIAxios.get(`/event-statistic/coupon-status-by-event`)
            .then((response) => {
                let couponStatusEvent = this.jsonConvert.deserializeArray(response.data, CouponStatusOfEvent);
                resolve(couponStatusEvent);
            })
            .catch(err => {
                reject(err);
            })
        })
    }
    
}

export default EventStatisticsServiceImpl;