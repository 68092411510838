import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import AdminMainTitle from '../../../../component/admin/AdminMainTitle';
import useChildCodeRetrieve from '../../../../hook/code/useChildCodeRetrieve';
import useCodeRetrieve from '../../../../hook/code/useCodeRetrieve';
import { CodeType } from '../../../../domain/code/CodeType';

import AdminVisitedTeamInfoFilter from '../../component/filter/AdminVisitedTeamInfoFilter';
import TotalVisitedTeamInfoSearchCondition from '../../../../domain/visitedInfo/statistics/TotalVisitedTeamInfoSearchCondition';
import VisitedTeamStatusByPeriodTables from '../../component/VisitedTeamStatusByPeriodTables';

import { defaultParentRegionCodeId } from '../../../../config/Constant';
import VisitedMemberAccumInfo from '../../../../domain/visitedInfo/statistics/VisitedMemberAccumInfo';
import VisitedTeamInfoStatisticsServiceImpl from '../../../../service/visitedInfo/impl/VisitedTeamInfoStatisticsServiceImpl';
import DateUtils from '../../../../utils/DateUtils';

const statisticsService: VisitedTeamInfoStatisticsServiceImpl = new VisitedTeamInfoStatisticsServiceImpl();

function AdminVisitedTeamStatusByPeriod() {
    const location = useLocation<TotalVisitedTeamInfoSearchCondition>();

    const { codes: regionCodes } = useCodeRetrieve(CodeType.REGION);
    const childRegionCodes = useChildCodeRetrieve(defaultParentRegionCodeId);
    const { codes: belongCodes } = useCodeRetrieve(CodeType.BELONGED);
    const { codes: sportsCodes } = useCodeRetrieve(CodeType.SPORT);
    const { codes: teamTypeCodes } = useCodeRetrieve(CodeType.TEAM_TYPE);

    let condition = location.state ? location.state : new TotalVisitedTeamInfoSearchCondition();

    const [searchCondition, setSearchCondition] = useState<TotalVisitedTeamInfoSearchCondition | undefined>();
    const [vistedStatusByPeriod, setVisitedStatusByPeriod] = useState<VisitedMemberAccumInfo[]>();
    const [visitedTotalStatusByPeriod, setVisitedTotalStatusByPeriod] = useState<VisitedMemberAccumInfo>();

    useEffect(() => {
        setSearchCondition(condition);
    }, []);
    useEffect(() => {
        if (searchCondition) {
            statisticsService.getVisitedTeamStatusByPeriod(searchCondition).then((data) => {
                setVisitedStatusByPeriod(data.summaryForDay);
                setVisitedTotalStatusByPeriod(data.totalSummary);
            });
        }
    }, [searchCondition]);

    return (
        <>
            <AdminMainTitle title="팀 기간 별 유치 현황">
                <AdminVisitedTeamInfoFilter
                    regionCodes={regionCodes}
                    childRegionCodes={childRegionCodes}
                    belongCodes={belongCodes}
                    classificationCodes={teamTypeCodes}
                    sportCodes={sportsCodes}
                    setSearchCondition={setSearchCondition}
                ></AdminVisitedTeamInfoFilter>
            </AdminMainTitle>
            {
                <VisitedTeamStatusByPeriodTables
                    teamTypeCodes={teamTypeCodes}
                    searchCondition={searchCondition}
                    visitedTotalStatusByPeriod={visitedTotalStatusByPeriod}
                    visitedStatusByPeriod={vistedStatusByPeriod}
                ></VisitedTeamStatusByPeriodTables>
            }
        </>
    );
}

export default AdminVisitedTeamStatusByPeriod;
