import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject("parentInfraCategory")
class ParentInfraCategory {
    @JsonProperty("infraCategoryNo")
    infraCategoryNo: number = 0;
    @JsonProperty("name")
    name: string = '';
}

export default ParentInfraCategory;