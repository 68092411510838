import { useState } from "react";

function useCalendarHook() {
    const [date, setDate] = useState(new Date());

    return {
        date, setDate
    }
}

export default useCalendarHook;