import { useEffect, useState } from 'react';
import Roles from '../../domain/user/Roles';
import CodeServiceImpl from '../../service/code/impl/CodeServiceImpl';
import RoleSearchCondition from '../../domain/code/param/RoleSearchCondition';
const codeService = new CodeServiceImpl();
function useReoleRetrieve(searchCondition?: RoleSearchCondition) {
    const [roles, setRoles] = useState<Roles[]>();
    useEffect(() => {
        if(searchCondition) {
            codeService.getRoleCode(searchCondition)
            .then((roles: Roles[]) => {
                setRoles(roles); 
            });
        }
    }, [searchCondition]);

    return {
        roles, setRoles
    }
}

export default useReoleRetrieve;