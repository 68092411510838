import React from 'react';
import AdminMainHeader from '../../layout/AdminMainHeader';
import AdminMainSider from '../../layout/AdminMainSider';
import AdminInfrasTable from '../../../../component/admin/AdminInfrasTable';
import { useHistory } from 'react-router-dom';

interface MatchParams {
    url: string;
}

function AdminSurveyDetailContainer(props: MatchParams) {
    let history = useHistory();

    const surveyHandler = () => {
        history.push('/admin/survey/info');
    };

    return (
        <div className="main-content">
            <div className="swipe-area"></div>
            <a data-toggle=".admin-container" id="sidebar-toggle">
                {' '}
                <span className="bar"></span> <span className="bar"></span> <span className="bar"></span>{' '}
            </a>
            <div className="content">
                <div className="admin-board">
                    <div className="flex-basic">
                        <div className="member-tit">
                            <h3>설문조사 목록</h3>
                        </div>
                        {/* <button className="bg-white round" onClick={surveyHandler}>설문조사 만들기</button> */}
                        <button className="bg-white round">
                            <a href="/admin/survey/info">설문조사 만들기</a>
                        </button>
                    </div>
                    {/* <AdminInfrasTable></AdminInfrasTable> */}
                </div>
            </div>
        </div>
    );
}

export default AdminSurveyDetailContainer;
