import React, { useEffect, useRef, useState } from 'react';
import TmpRegistedTeamUser from '../../../domain/team/TmpRegistedTeamUser';
import TmpRegistedTeamUserServiceImpl from '../../../service/team/impl/TmpRegistedTeamUserServiceImpl';
import TempRegistedTeamUserSearchCondition from '../../../domain/team/param/TempRegistedTeamUserSearchCondition';

interface MatchParams {
    teamId: string;
    openable: boolean;
    setCheckedUserNos: (checkedUserNos: string[]) => void;
}

const tmpTeamService = new TmpRegistedTeamUserServiceImpl();
function UserComponent(props: MatchParams) {
    const [checkedUserNos] = useState<any>({});
    const [users, setUsers] = useState<TmpRegistedTeamUser[]>();

    useEffect(() => {
        tmpTeamService.getTmpUsers(props.teamId, new TempRegistedTeamUserSearchCondition())
        .then(tmpRegistedTeamUsers => {
            setUsers(tmpRegistedTeamUsers);
        })
    }, []);

    return (
        <div id="scho" className="sportsman-cho" style={{ display: `${props.openable ? 'block' : 'none'}` }}>
            {
                users && users['length'] > 0 ?
                users.map((user: TmpRegistedTeamUser, index: number) => {
                    return (
                        <>
                            {
                                <p key={index}>
                                    <input disabled={!user.user ? true: false} type="checkbox" value={user.user?.userNo}
                                        onClick={(event) => {
                                            const checkedUserNo = event.currentTarget.value;
                                            if(checkedUserNos.hasOwnProperty(checkedUserNo)) {
                                                delete checkedUserNos[checkedUserNo];
                                            } else {
                                                checkedUserNos[checkedUserNo] = checkedUserNo;
                                            }
                                            const checkedUsers = Object.keys(checkedUserNos);
                                            props.setCheckedUserNos(checkedUsers);
                                        }}
                                    />{user.userName} {user.user? '' : '(미가입 팀원)'}
                                </p>    
                            }
                        </>
                    )
                })
                :
                <p>소속된 팀 또는 팀에 소속된 선수가 없습니다.</p>
            }
        </div>
    )
}

export default UserComponent;