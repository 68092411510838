import { useEffect, useState } from 'react';
import LoginToken from '../../domain/LoginToken';
import UserLoginParam from '../../domain/user/param/UserLoginParam';
import { USER_SERVICE } from '../../service/index';
import { ACCESS_TOKEN } from '../../config/Constant';
import { registeUserSessionAction } from '../../moldules/UserSession';
import { useDispatch } from 'react-redux';

function useLogin(redirectPath: string, id?: string, pw?: string) {
    const [userLoginParam, setUserLoginParam] = useState<UserLoginParam>();
    const dispatch = useDispatch();

    const loginProccess = () => {
        if (id && pw) {
            let userLoginParam = new UserLoginParam(id, pw);
            console.log(userLoginParam);
            setUserLoginParam(userLoginParam);
        }
    };

    useEffect(() => {
        if (userLoginParam) {
            USER_SERVICE.login(userLoginParam)
                .then((loginToken: LoginToken) => {
                    if (loginToken.accessToken) {
                        localStorage.setItem(ACCESS_TOKEN, loginToken.accessToken);
                        dispatch(registeUserSessionAction());
                        window.location.replace(redirectPath);
                    }
                })
                .catch((err) => {
                    alert('사용자 정보를 확인해주세요.');
                });
        }
    }, [userLoginParam]);

    return { loginProccess };
}

export default useLogin;
