import CommonSearchCondition from '../../common/param/CommonSearchCondition';
class TeamSearchCondition extends CommonSearchCondition {
    genderCodeIds?: string;
    belongedCodeIds?: string;
    classficationCodeIds?: string;
    sportCodeIds?: string;
    visitedTeamNo?: string;
    page?: number;
    groupIdx?: number;
}

export default TeamSearchCondition;
