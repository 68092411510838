import React from 'react';
import { Link } from 'react-router-dom';
interface LayoutProps {
    children?: React.ReactNode;
    header?: React.ReactNode;
    sideBar?: React.ReactNode;
    path: string;
    naviTitle?: string;
    menuTitle?: string;
    filter?: any;
    pagination?: any;
    popups?: any;
    addItemElem?: any;
}
function Layout(props: LayoutProps) {
    return (
        <>
            <div className="center-cont">
                <div className="center-left">
                    {props.sideBar}
                </div>
                <div className="center-box">
                    <div className="center-navi">
                        <a href="#">HOME</a>
                        <span> &gt; </span>
                        <Link to={props.path} className="center-blue">
                            <i className="far fa-calendar-alt"></i>
                            {props.naviTitle}
                        </Link>
                    </div>
                    <div className="center-board">
                        <div className="flex-basic">
                            <h2 className="center2">{props.menuTitle}</h2>
                            {props.addItemElem}
                        </div>
                        {props.filter}
                        <div id="contents-wrap" className="center-list">
                            {props.children}
                        </div>
                        <div className="flex-basic center-pagenumber">{props.pagination}</div>
                    </div>
                    {props.popups}
                </div>
            </div>
        </>
    );
}

export default Layout;
