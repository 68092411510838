class UserLocation {
    readonly latitude: number;
    readonly longitude: number;
    
    constructor(latitude: number, longitude: number) {
        this.latitude = latitude;
        this.longitude = longitude;
    }
}

export default UserLocation;