import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import RoleGroups from '../../../../domain/user/RoleGroups';
import Roles from '../../../../domain/user/Roles';
import Code from '../../../../domain/code/Code';
import useCodeRetrieve from '../../../../hook/code/useCodeRetrieve';
import { CodeType } from '../../../../domain/code/CodeType';
import useChildCodeRetrieve from '../../../../hook/code/useChildCodeRetrieve';
import RoleGroupsSearchCondition from '../../../../domain/code/param/RoleGroupsSearchCondition';
import RoleGroupType from '../../../../domain/user/RoleGroupType';
import useRoleGroupRetrieveByCondition from '../../../../hook/code/useRoleGroupRetrieveByCondition';
import useAdminManagerInfo from '../../hook/useAdminManagerInfo';
import RoleSearchCondition from '../../../../domain/code/param/RoleSearchCondition';
import UserDetailInfo from '../../../../domain/user/UserDetailInfo';
import Authority from '../../../../domain/user/Authority';
import UserModifyParam from '../../../../domain/user/param/UserModifyParam';
import UserServiceImpl from '../../../../service/user/impl/UserServiceImpl';
import { SUCCESS_MESSAGE } from '../../../../config/Constant';
import UserSearchCondition from '../../../../domain/user/param/UserSearchCondition';
import { confirmAlert } from 'react-confirm-alert';

interface AdminManagerInfoFormProps {
    userNo?: string;
    buttonTitle?: string;
    flag: string;
}

function getRoleGroupsSearchConditionOfManager(): RoleGroupsSearchCondition {
    const roleGroupsSearchCondition: RoleGroupsSearchCondition = new RoleGroupsSearchCondition();
    roleGroupsSearchCondition.roleGroupNames = `${RoleGroupType.SYSTEMS},${RoleGroupType.MUNICIPALITY},${RoleGroupType.CENTER}`;

    return roleGroupsSearchCondition;
}
const userService = new UserServiceImpl();
function AdminManagerInfoForm(props: AdminManagerInfoFormProps) {
    const history = useHistory();
    const location = useLocation();

    const {
        userDetailInfo,
        userRoleGroupNo,
        setUserRoleGroupNo,
        userRoleNo,
        setUserRoleNo,
        userName,
        setUserName,
        userPhoneNumber,
        setUserPhoneNumber,
        userEmail,
        setUserEmail,
        userPassword,
        setUserPassword,
        userAuthority,
        setUserAuthority,
        setRoleSearchCondition,
        roles,
        userParentRegionCodeId,
        setUserParentRegionCodeId,
        userRegionCodeId,
        setUserRegionCodeId,
    } = useAdminManagerInfo(props.userNo);
    const { codes: parentRegionCodes } = useCodeRetrieve(CodeType.REGION);
    const childRegionCodes = useChildCodeRetrieve(userParentRegionCodeId);
    const roleGroupsSearchCondition: RoleGroupsSearchCondition = getRoleGroupsSearchConditionOfManager();
    const { roleGroups } = useRoleGroupRetrieveByCondition(roleGroupsSearchCondition);
    const [userModifyParam, setUserModifyParam] = useState<UserModifyParam>();
    const [newUserDetailInfo, setNewUserDetailInfo] = useState<UserDetailInfo>();

    useEffect(() => {
        if (userAuthority) {
            userAuthority.roleNo = userRoleNo;
        }
    }, [userRoleNo]);

    useEffect(() => {
        if (userRoleGroupNo) {
            const newRoleSearchCondition = new RoleSearchCondition();
            newRoleSearchCondition.roleGroupNos = String(userRoleGroupNo);
            setRoleSearchCondition(newRoleSearchCondition);
        }
    }, [userRoleGroupNo]);

    useEffect(() => {
        if (userModifyParam) {
            userService.updateUserInfo(userModifyParam).then(() => {
                confirmAlert({
                    title: '수정 성공',
                    message: SUCCESS_MESSAGE + ' 목록으로 돌아 갑니다.',
                    buttons: [
                        {
                            label: '확인',
                            onClick: () => {
                                goBack();
                            },
                        },
                    ],
                });
            });
        }
    }, [userModifyParam]);

    useEffect(() => {
        if (newUserDetailInfo) {
            userService.registeUserInfo(newUserDetailInfo).then((result) => {
                if (result === 'success') {
                    confirmAlert({
                        title: '등록 성공',
                        message: SUCCESS_MESSAGE + ' 목록으로 돌아 갑니다.',
                        buttons: [
                            {
                                label: '확인',
                                onClick: () => {
                                    goBack();
                                },
                            },
                        ],
                    });
                } else if (result === 'isAlready') {
                    confirmAlert({
                        title: '이메일 중복 확인',
                        message: '작성하신 이메일이 이미 존재 합니다. 확인 후 다시 가입해 주십시오.',
                        buttons: [
                            {
                                label: '확인',
                                onClick: () => {},
                            },
                        ],
                    });
                } else {
                    confirmAlert({
                        title: '등록 실패',
                        message: '등록에 실패하였습니다. 관리자에게 문의해 주십시오.',
                        buttons: [
                            {
                                label: '확인',
                                onClick: () => {},
                            },
                        ],
                    });
                }
            });
        }
    }, [newUserDetailInfo]);

    const getUpdateUserDetailInfo = function () {
        if (props.userNo && userDetailInfo && userAuthority && userName) {
            userDetailInfo.userNo = props.userNo;
            userDetailInfo.authorities[0] = userAuthority;
            userDetailInfo.name = userName;
            userDetailInfo.phoneNumber = userPhoneNumber;
            userDetailInfo.email = userEmail;
            userDetailInfo.password = userPassword;
            userDetailInfo.regionCodeId = userRegionCodeId;
        }

        const userModifyParam = UserModifyParam.getInstance(userDetailInfo);
        return userModifyParam;
    };

    const getNewUserDetailInfo = (): UserDetailInfo => {
        const newUserDetailInfo = new UserDetailInfo();
        if (userName) {
            newUserDetailInfo.name = userName;
            newUserDetailInfo.phoneNumber = userPhoneNumber;
            newUserDetailInfo.email = userEmail;
            newUserDetailInfo.authorities = new Array<Authority>();
            const newAuthority = new Authority();
            newAuthority.roleNo = userRoleNo;
            newUserDetailInfo.authorities.push(newAuthority);
            newUserDetailInfo.password = userPassword;
            newUserDetailInfo.signupApprove = true;
            newUserDetailInfo.regionCodeId = userRegionCodeId;
        }
        return newUserDetailInfo;
    };

    const registureHandler = () => {
        let eventParam: UserDetailInfo | UserModifyParam;

        if (userDetailInfo) {
            eventParam = getUpdateUserDetailInfo();
            setUserModifyParam(eventParam);
        } else {
            eventParam = getNewUserDetailInfo();
            setNewUserDetailInfo(eventParam);
        }
    };

    const deleteHandler = () => {
        if (props.userNo)
            userService.deleteUser(props.userNo).then(() => {
                confirmAlert({
                    title: '삭제 성공',
                    message: SUCCESS_MESSAGE + ' 목록으로 돌아 갑니다.',
                    buttons: [
                        {
                            label: '확인',
                            onClick: () => {
                                goBack();
                            },
                        },
                    ],
                });
            });
    };

    const goBack = () => {
        let condition: UserSearchCondition | any = location.state;
        history?.replace({
            pathname: '/admin/manager/list',
            state: condition,
        });
    };

    let deleteBtn = <></>;

    if (props.flag === 'detail') {
        deleteBtn = (
            <button
                id="deleteInfoBtn"
                className="bg-red  white"
                style={{ float: 'right', marginBottom: '5px', marginTop: '5px', fontWeight: 700 }}
                onClick={deleteHandler}
            >
                운영자 정보 삭제
            </button>
        );
    }
    return (
        <table>
            <colgroup>
                <col style={{ width: '15%', backgroundColor: '#f2f2f2' }} />
                <col style={{ width: '85%' }} />
            </colgroup>
            <thead>
                <tr>
                    <td colSpan={2} style={{ padding: '2px 0' }}>
                        <button
                            id="modifyInfoBtn"
                            className="bg-blue white"
                            style={{ float: 'right', marginBottom: '5px', marginTop: '5px', fontWeight: 700 }}
                            onClick={registureHandler}
                        >
                            {props.buttonTitle}
                        </button>
                        {deleteBtn}
                        <button
                            onClick={goBack}
                            style={{
                                backgroundColor: '#ffc107',
                                color: '#3a4652',
                                float: 'right',
                                marginBottom: '5px',
                                marginTop: '5px',
                                fontWeight: 'bold',
                            }}
                        >
                            돌아가기
                        </button>
                    </td>
                </tr>
            </thead>
            <tr>
                <td className="txt-left">
                    <div className=" pdl30 blue">구분</div>
                </td>
                <td className="txt-left" style={{ height: '35px' }}>
                    {roleGroups?.map((roleGroup: RoleGroups, index: number) => {
                        return (
                            <>
                                <label style={{ margin: '5px', padding: '5px 5px', fontSize: '15px' }}>
                                    <input
                                        onClick={(event) => {
                                            setUserRoleGroupNo(Number(event.currentTarget.value));
                                        }}
                                        checked={roleGroup.roleGroupNo == userRoleGroupNo}
                                        key={index}
                                        type="radio"
                                        name="role-group"
                                        value={roleGroup.roleGroupNo}
                                    />
                                    &nbsp;&nbsp;
                                    {roleGroup.groupDesc}
                                </label>
                            </>
                        );
                    })}
                </td>
            </tr>
            <tr>
                <td className="txt-left">
                    <div className=" pdl30 blue">사용자 권한</div>
                </td>
                <td className="txt-left">
                    <select
                        style={{ width: '250px' }}
                        onChange={(event) => {
                            const roleNo = Number(event.currentTarget.value);
                            setUserRoleNo(roleNo);
                        }}
                    >
                        <option>권한선택</option>
                        {roles?.map((role: Roles, index: number) => {
                            return (
                                <option selected={role.roleNo == userRoleNo} key={index} value={role.roleNo}>
                                    {role.roleDesc}
                                </option>
                            );
                        })}
                    </select>
                </td>
            </tr>
            <tr>
                <td className="txt-left">
                    <div className=" pdl30 blue">소속 지역</div>
                </td>
                <td className="txt-left">
                    <select
                        onChange={(event) => {
                            setUserParentRegionCodeId(Number(event.currentTarget.value));
                        }}
                    >
                        <option>지역 선택</option>
                        {parentRegionCodes?.map((regionCode: Code, index: number) => {
                            return (
                                <option selected={regionCode.codeId == userParentRegionCodeId} key={index} value={regionCode.codeId}>
                                    {regionCode.name}
                                </option>
                            );
                        })}
                    </select>
                    <select
                        onChange={(event) => {
                            setUserRegionCodeId(Number(event.currentTarget.value));
                        }}
                    >
                        <option>상세 지역 선택</option>
                        {childRegionCodes?.map((regionCode: Code, index: number) => {
                            return (
                                <option selected={regionCode.codeId == userRegionCodeId} key={index} value={regionCode.codeId}>
                                    {regionCode.name}
                                </option>
                            );
                        })}
                    </select>
                </td>
            </tr>
            <tr>
                <td className="txt-left">
                    <div className=" pdl30 blue">이름</div>
                </td>
                <td className="txt-left">
                    <input
                        type="text"
                        value={userName}
                        onChange={(event) => {
                            setUserName(event.currentTarget.value);
                        }}
                    ></input>
                </td>
            </tr>
            <tr>
                <td className="txt-left">
                    <div className=" pdl30 blue">연락처</div>
                </td>
                <td className="txt-left">
                    <input
                        type="text"
                        value={userPhoneNumber}
                        onChange={(event) => {
                            setUserPhoneNumber(event.currentTarget.value);
                        }}
                    ></input>
                </td>
            </tr>

            <tr>
                <td className="txt-left">
                    <div className=" pdl30 blue">이메일</div>
                </td>
                <td className="txt-left">
                    <input
                        style={{ width: '300px' }}
                        type="text"
                        value={userEmail}
                        onChange={(event) => {
                            setUserEmail(event.currentTarget.value);
                        }}
                    ></input>
                </td>
            </tr>
            <tr>
                <td className="txt-left">
                    <div className=" pdl30 blue">비밀번호</div>
                </td>
                <td className="txt-left">
                    <input
                        type="password"
                        style={{ height: '35px' }}
                        value={userPassword}
                        onChange={(event) => {
                            setUserPassword(event.currentTarget.value);
                        }}
                    ></input>
                </td>
            </tr>
            <tr>
                <td colSpan={2}></td>
            </tr>
        </table>
    );
}

export default AdminManagerInfoForm;
