import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject("searchItem")
class SearchItem {
    @JsonProperty("searchItemNo")
    searchItemNo?: string;
    @JsonProperty("name")
    name?: string;
    @JsonProperty("searchType")
    searchType?: string;

    static filter(searchItems: SearchItem[], searchType: string): SearchItem[] {
        if(searchType) {
            return searchItems.filter((searchItem: SearchItem) => {
                return searchItem.searchType == searchType;
            })
        } else {
            return searchItems;
        }
    }
}

export default SearchItem;