import React from 'react';
import Test from './Test';
export default class TestComponent extends React.Component implements Test{
    constructor(props: Object) {
        super(props);
        console.log(props);
    }
    render() {
        return (
            <div>
                <h1>TestComponent</h1>
            </div>
        )
    }
}