export const ACCESS_TOKEN: string = 'accessToken';
export const USER_INFO_KEY: string = 'userInfo';
export const NEAR_DISTAINCE: number = 3;
//도청 좌표
export const INIT_LATITUDE: number = 35.237698776035394;
export const INIT_LONGITUDE: number = 128.70115450373726;

export const SUCCESS_MESSAGE: string = '정상 반영 되었습니다.';

export const COLOR_ARR: Array<string> = [
    '#166ec7', '#166c', '#23c',
    '#966ec7', '#736ec7', '#23c009',
    'red', 'blue', 'green',
    'red', 'blue', 'green',
    'red', 'blue', 'green',
    'red', 'blue', 'green',
    'red', 'blue', 'green',
]

export const defaultParentRegionCodeId = 20001;

export const serviceStartYear = 2019;

export const mobileConfirmCompanyHost = 'https://www.kmcert.com/kmcis/web/kmcisReq.jsp';