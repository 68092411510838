import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Infra from '../../domain/infra/Infra';
import Reservation from '../../domain/reservation/Reservation';
import useReservationUpdate from '../../hook/reservation/useReservationUpdate';
import ReservationStateType from '../../domain/reservation/ReservationStateType';
import { useSelector } from 'react-redux';
import { RootState } from '../../moldules/index';
import ReservationTime from '../../domain/reservation/ReservationTime';
import { confirmAlert } from 'react-confirm-alert';

interface ReservationInfraCardProps {
    reservation: Reservation;
    link: string;
}

function ReservationInfraCard(props: ReservationInfraCardProps) {
    const [reservation, setReservation] = useState<Reservation>(props.reservation);
    const [updateReservation, setUpdateReservation] = useState<Reservation>();
    const updatedReservation = useReservationUpdate(updateReservation);
    const { user, myTeam, hasTeamAuth } = useSelector((state: RootState) => state.userSessionReducer);
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        if (updatedReservation) setReservation(updatedReservation);
    }, [updatedReservation]);

    const reservatonCancelEventHandler = () => {
        const reservationCancelType: number = ReservationStateType.RESERVATION_CANCEL;
        reservation.reservationStateCodeId = reservationCancelType;
        setUpdateReservation(reservation);
    };

    const checkReportBtnHandler = (evt: any) => {
        let reservationNo = evt.target.id;
        let state = { returnPath: location.pathname };
        console.log(reservationNo);
        history.push({
            pathname: '/mypage/reservation/' + reservationNo,
            state: state,
        });
        // confirmAlert({
        //     title: '알림',
        //     message: '서비스 준비 중입니다. 감사합니다.',
        //     buttons: [{ label: '확인', onClick: () => {} }],
        // });
    };

    return (
        <div className="my-in">
            <div className="my-in-info">
                <div className="my-in-info-box">
                    <div style={{ width: '15%' }}>
                        <p>
                            <span style={{ fontWeight: 900 }}>예약대상자</span> <br />
                            <span style={{ margin: '0px 10px' }}>&lt;{reservation.team?.name}&gt;</span>
                            <br />
                            <span style={{ margin: '0px 10px' }}>{reservation.reservater?.name}</span>
                        </p>
                    </div>
                    <div style={{ width: '25%' }}>
                        <Link to={props.link}>
                            <span style={{ fontWeight: 900 }}>예약센터</span>
                            <p style={{ fontSize: '21px', margin: '0px 10px' }}>{reservation.infra?.name}</p>
                        </Link>
                    </div>
                    <div style={{ width: '10%' }}>
                        <p>
                            <span style={{ fontWeight: 900 }}>예약상태</span> <br />
                            <span className="blue" style={{ margin: '0px 10px' }}>
                                {reservation.reservationStateCode?.name}
                            </span>
                        </p>
                    </div>
                    <div style={{ width: '10%' }}>
                        <p>
                            <span style={{ fontWeight: 900 }}>예약신청자</span>
                            <br />
                            <span style={{ margin: '0px 10px' }}> {reservation.register?.name}</span>
                        </p>
                    </div>
                    <div style={{ width: '15%' }}>
                        <p>
                            <span style={{ fontWeight: 900 }}>예약일정</span> <br />
                            <span style={{ fontWeight: 500, margin: '0px 10px' }}>{reservation.startDate?.split(' ')[0]}</span>
                            <br />
                            <span style={{ color: '#00f', margin: '0px 10px' }}>
                                &lt;{reservation.startDate?.split(' ')[1]} ~{reservation.endDate?.split(' ')[1]}&gt;
                            </span>
                        </p>
                    </div>
                    <div style={{ width: '15%', textAlign: 'right', marginTop: '15px', marginRight: '15px' }}>
                        {reservation.reservaterNo === user.userNo || hasTeamAuth ? (
                            <button
                                id={reservation.reservationNo}
                                onClick={checkReportBtnHandler}
                                className="bg-blue white"
                                style={{
                                    border: '1px solid #e3e3e3',
                                    padding: '10px',
                                    marginRight: '5px',
                                    borderRadius: '5px',
                                    fontWeight: 700,
                                    fontSize: '15px',
                                }}
                            >
                                리포트 확인
                            </button>
                        ) : (
                            <></>
                        )}
                        {reservation.reservationStateCodeId !== 1006 && hasTeamAuth ? (
                            <button
                                className="bg-light-blue"
                                onClick={reservatonCancelEventHandler}
                                style={{
                                    border: '1px solid #e3e3e3',
                                    padding: '10px',
                                    marginRight: '5px',
                                    borderRadius: '5px',
                                    fontWeight: 700,
                                    fontSize: '15px',
                                }}
                            >
                                예약취소
                            </button>
                        ) : (
                            <></>
                        )}
                    </div>
                </div>
            </div>
            {/* <div className="my-in-img">
                {reservation.parentInfra ? (
                    <img width={73} height={75} src={Object.setPrototypeOf(reservation.parentInfra, Infra.prototype).getMainAttachFile()} />
                ) : (
                    <img width={73} height={75} src={Object.setPrototypeOf(reservation.infra, Infra.prototype).getMainAttachFile()} />
                )}
            </div> */}
        </div>
    );
}

export default ReservationInfraCard;
