import React from 'react';
import { Switch, Route } from 'react-router-dom';
import TeamStoryContainer from '../page/teamStory/TeamStoryContainer';
import TrainningMainContainer from '../page/main/TrainningMainContainer';
import TrainningInfraDetailContainer from '../page/infra/TrainningInfraDetailContainer';
import SystemPrivateRoute from '../../SystemPrivateRoute';
import TestComponent from '../../../component/TestComponent';
import IndexRouter from '../../IndexRouter';

function TrainningRouter() {
    return (
        <>
            <Switch>
                <Route exact={true} path="/trainning" component={TrainningMainContainer}></Route>
                <Route exact={true} path="/trainning/infra/:infraNo" component={TrainningInfraDetailContainer}></Route>
                <Route path="/trainning/team-story/:teamNo" component={TeamStoryContainer}></Route>
                <SystemPrivateRoute path="/trainning/teams" component={TestComponent}></SystemPrivateRoute>
                <IndexRouter></IndexRouter>
            </Switch>
        </>
    );
}

export default TrainningRouter;
