import { useEffect, useState } from "react";
import InfraCategory from '../../domain/infra/InfraCategory';
import InfraCategoryServiceImpl from '../../service/infra/impl/InfraCategoryServiceImpl';

const infraCategoryService = new InfraCategoryServiceImpl();

function useParentInfraCategoriesRetrieve() {
    const [parentInfraCategories, setParentInfraCategories] = useState<InfraCategory[]>();

    useEffect(() => {
        infraCategoryService.retrieveParentInfraCategory()
        .then((infraCategories: InfraCategory[]) => {
            setParentInfraCategories(infraCategories);
        });
    }, []);

    return {parentInfraCategories, setParentInfraCategories};
}

export default useParentInfraCategoriesRetrieve;