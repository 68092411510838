import { useEffect, useState } from "react";
import Infra from '../../domain/infra/Infra';
import InfraServiceImpl from '../../service/infra/impl/InfraServiceImpl';
import useInfraOfCategoryRetrieve from './useInfraCategoryRetrieve';

const infraService = new InfraServiceImpl();
function useInfraRetrieve(parentInfraCategoryNo: number) {
    const infraCategories = useInfraOfCategoryRetrieve(parentInfraCategoryNo);
    const [infras, setInfras] = useState<Infra[]>();
    const [childInfraCategoryNo, setChildInfraCategoryNo] = useState<number|undefined>();

    useEffect(() => {
        if(childInfraCategoryNo) {
            infraService.getInfraOfChildCategoryNo(parentInfraCategoryNo, childInfraCategoryNo)
            .then((infras: Infra[]) => {
                setInfras(infras);
            });
        } else {
            infraService.getInfraOfParentCategoryNo(parentInfraCategoryNo)
            .then((infras: Infra[]) => {
                setInfras(infras);
            });
        }
    }, [childInfraCategoryNo]);

    return {infras, infraCategories, setChildInfraCategoryNo};
}

export default useInfraRetrieve;