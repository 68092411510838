import { useEffect, useState } from "react";
import FillInQuestionnarie from "../../view/rehabcenter/domain/FillInQuestionnarie";
import { REHAB_FILL_IN_QUEST_SERVICE } from '../../view/rehabcenter/service/impl/index';

function useFillInQuestionnaireOfUser(answerNo: number, targetNo: number, quesNo: number) {
    console.log(answerNo, targetNo, quesNo);
    const [fillInQuestionnarie, setFillInQuestionnarie] = useState<FillInQuestionnarie>();
    useEffect(() => {
        if(answerNo && targetNo && quesNo) {
            REHAB_FILL_IN_QUEST_SERVICE.getFillInQuestionnarie(answerNo, targetNo, quesNo)
            .then((fillInQuestionnarie: FillInQuestionnarie) => {
                setFillInQuestionnarie(fillInQuestionnarie);
            });
        }
    }, [answerNo, targetNo, quesNo]);

    return {
        fillInQuestionnarie, setFillInQuestionnarie
    }
}

export default useFillInQuestionnaireOfUser;