import TargetPerson from '../../view/rehabcenter/domain/TargetPerson';
import RehabTargetHistory from '../../view/rehabcenter/domain/RehabTargetHistory';
import DateUtils from '../../utils/DateUtils';
class BaseReportData {
    name?: string;
    measureDate?: string;
    participantsNo?: string;
    inspector?: string = '-';
    teamName?: string;
    age?: string;
    gender?: string;
    personId?: string;
    
    usingHands?: string = 'R';
    usingLegs?: string = 'R';

    constructor(targetPerson?: TargetPerson, selectedHistory?: RehabTargetHistory) {
        if(targetPerson) {
            const reservater = targetPerson.reservater;
            if(reservater) {
                this.name = reservater.name;
                this.participantsNo = reservater.userNo;
                this.personId = reservater.userNo;
                this.gender = reservater.genderCode?.name;
                if(reservater.birthdate) {
                    let birthYear:number = Number(reservater.birthdate.split("-")[0]);
                    this.age = BaseReportData.calcAge(birthYear);
                }
                this.teamName = reservater.teamName;
                this.inspector = reservater.teamName;
            }
        }

        if(selectedHistory) {
            this.measureDate = selectedHistory.getRegDate();
        }
    }

    static calcAge(birthYear: number): string {
        const curYear = DateUtils.getCurrentYear();
        
        return String(curYear - birthYear);
    }
}

export default BaseReportData;