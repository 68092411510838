import React from 'react';
import TouringMainHeader from '../../layout/TouringMainHeader';
import { InfraCategoryType } from '../../../../domain/infra/InfraCategoryType';
import CommonSearchBar from '../../../../component/search/CommonSearchBar';
import InfraMainViewer from '../../../../component/infra/InfraMainViewer';
import useInfraOfRecommandRetrieve from '../../../../hook/infra/useInfraOfRecommandRetrieve';

function TouringMainContainer() {
    const {
        infras: infrasOfRecommandSport,
        infraCategories: infraCategoriesOfReCommandSport,
        setChildInfraCategoryNo: setChildInfraCategoryNoOfRecommandSport,
    } = useInfraOfRecommandRetrieve(InfraCategoryType.SPORT_INFRA_NO);
    const {
        infras: infrasOfRecommandTourism,
        infraCategories: infraCategoriesOfRecommandTourism,
        setChildInfraCategoryNo: setChildInfraCategoryNoOfRecommandTourism,
    } = useInfraOfRecommandRetrieve(InfraCategoryType.TOURISM_NO);
    const {
        infras: infrasOfRecommandLodgment,
        infraCategories: infraCategoriesOfRecommandLodgment,
        setChildInfraCategoryNo: setChildInfraCategoryNoOfRecommandLodgment,
    } = useInfraOfRecommandRetrieve(InfraCategoryType.LODGMENT_NO);
    const {
        infras: infrasOfRecommandFood,
        infraCategories: infraCategoriesOfRecommandFood,
        setChildInfraCategoryNo: setChildInfraCategoryNoOfRecommandFood,
    } = useInfraOfRecommandRetrieve(InfraCategoryType.FOOD_NO);

    return (
        <div>
            <TouringMainHeader></TouringMainHeader>
            <InfraMainViewer
                infras={infrasOfRecommandSport}
                infraCategories={infraCategoriesOfReCommandSport}
                setChildInfraCategoryNo={setChildInfraCategoryNoOfRecommandSport}
                infraType={'sport'}
                searchingType="recommendation"
                title="추천 스포츠 시설"
            ></InfraMainViewer>
            <InfraMainViewer
                infras={infrasOfRecommandTourism}
                infraCategories={infraCategoriesOfRecommandTourism}
                setChildInfraCategoryNo={setChildInfraCategoryNoOfRecommandTourism}
                infraType={'touring'}
                searchingType="recommendation"
                title="추천 관광"
            ></InfraMainViewer>
            <InfraMainViewer
                infras={infrasOfRecommandLodgment}
                infraCategories={infraCategoriesOfRecommandLodgment}
                setChildInfraCategoryNo={setChildInfraCategoryNoOfRecommandLodgment}
                infraType={'lodgment'}
                searchingType="recommendation"
                title="추천 숙박"
            ></InfraMainViewer>
            <InfraMainViewer
                infras={infrasOfRecommandFood}
                infraCategories={infraCategoriesOfRecommandFood}
                setChildInfraCategoryNo={setChildInfraCategoryNoOfRecommandFood}
                infraType={'food'}
                searchingType="recommendation"
                title="추천 음식"
            ></InfraMainViewer>
        </div>
    );
}

export default TouringMainContainer;
