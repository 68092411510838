import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject("commonRehabCenterMeasure")
class CommonRehabCenterMeasure {
    @JsonProperty("measureCnt")
    measureCnt?: number;
    @JsonProperty("totalCnt")
    totalCnt?: number;
    @JsonProperty("measureRate")
    measureRate?: number;
}

export default CommonRehabCenterMeasure;