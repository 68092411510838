import { JsonObject, JsonProperty } from 'json2typescript';
import Code from '../code/Code';
@JsonObject('clubTeamInfoStatistics')
class ClubTeamInfoStatistics {
    @JsonProperty('regionCodeId')
    regionCodeId?: number;
    @JsonProperty('regionCode')
    regionCode?: Code;
    @JsonProperty('clubSportCodeId')
    clubSportCodeId?: number;
    @JsonProperty('clubSportCode')
    clubSportCode?: Code;
    @JsonProperty('clubTeamType')
    clubTeamType?: string;
    @JsonProperty('clubTeamMemberCount')
    clubTeamMemberCount?: number;
    @JsonProperty('clubTeamCount')
    clubTeamCount?: number;
    @JsonProperty('cnt')
    cnt?: number;

    static getMapByRegionCodeId(clubTeamInfoStatistics: ClubTeamInfoStatistics[]): Map<number, ClubTeamInfoStatistics> {
        const map = new Map<number, ClubTeamInfoStatistics>();

        clubTeamInfoStatistics.forEach((value: ClubTeamInfoStatistics) => {
            if (value.regionCodeId) {
                map.set(value.regionCodeId, value);
            }
        });

        return map;
    }

    static getSportCodes(clubTeamInfoStatistics: ClubTeamInfoStatistics[]): Code[] {
        const sportCodes: Code[] = [];
        const sportCodesMap = new Map<number, Code>();

        clubTeamInfoStatistics.forEach((value) => {
            const sportCode = value.clubSportCode;
            if (sportCode) {
                if (!sportCodesMap.has(sportCode.codeId)) {
                    sportCodesMap.set(sportCode.codeId, sportCode);
                }
            }
        });

        sportCodesMap.forEach((value) => {
            sportCodes.push(value);
        });

        return sportCodes;
    }

    static getRegionCodes(clubTeamInfoStatistics: ClubTeamInfoStatistics[]): Code[] {
        const regionCodes: Code[] = [];
        const regionCodesMap = new Map<number, Code>();

        clubTeamInfoStatistics.forEach((value) => {
            const regionCode = value.regionCode;
            if (regionCode) {
                if (!regionCodesMap.has(regionCode.codeId)) {
                    regionCodesMap.set(regionCode.codeId, regionCode);
                }
            }
        });

        regionCodesMap.forEach((value) => {
            regionCodes.push(value);
        });

        return regionCodes;
    }

    static getMapBySportCodeId(clubTeamInfoStatistics: ClubTeamInfoStatistics[]) {
        const map = new Map<string, ClubTeamInfoStatistics>();

        clubTeamInfoStatistics.forEach((value: ClubTeamInfoStatistics) => {
            const sportCodeId = value.clubSportCodeId;
            const key = String(sportCodeId);
            map.set(key, value);
        });

        return map;
    }

    static getMapByRegionCodeIdAndSportCodeId(clubTeamInfoStatistics: ClubTeamInfoStatistics[]) {
        const map = new Map<string, ClubTeamInfoStatistics>();

        clubTeamInfoStatistics.forEach((value: ClubTeamInfoStatistics) => {
            const regionCodeId = value.regionCodeId;
            const sportCodeId = value.clubSportCodeId;
            const key = regionCodeId + '/' + sportCodeId;
            map.set(key, value);
        });

        return map;
    }
}

export default ClubTeamInfoStatistics;
