import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import '../assets/css/center.css';
import '../assets/css/animate.min.css';
import Layout from '../layouts/Layout';
import Header from '../layouts/Header';
import SideBar from '../layouts/SideBar';
import RecordingPopup from './components/recording/RecordingPopupComponent';
import RehabServiceImpl from '../service/impl/RehabServiceImpl';
import PaginationComponent from './components/PaginationComponent';
import TargetPerson from '../domain/TargetPerson';
import UserDetailInfo from '../../../domain/user/UserDetailInfo';
import RehabHistoryServiceImpl from '../service/impl/RehabHistoryServiceImpl';
import RehabTargetHistory from '../domain/RehabTargetHistory';
import Team from '../../../domain/team/Team';

const menuTitle: string = '* 측정자 관리';
const title: string = '측정';

const rehabService = new RehabServiceImpl();
const rehabHistoryService = new RehabHistoryServiceImpl();

function RecorodingComponent(props: any) {
    let path = props.location.pathname;
    let centerKey = props.match.params.centerId;

    const [targetPersons, setTargetPersons] = useState<TargetPerson[]>(new Array<TargetPerson>());

    const filters = () => {
        return (
            <div className="center-bnt-right2">
                <div className="center-select-box"></div>
            </div>
        );
    };

    const popups = () => {
        return (
            <>
                <div id="center-popup-wrap"></div>
            </>
        );
    };

    const closingHandler = () => {
        let pagination = { size: displaySize, page: currPage };
        rehabService.getTargetForRecording(pagination).then((targetPersons: { data: TargetPerson[]; totalSize: number }) => {
            setTargetPersons(targetPersons.data);
            setTotalSize(targetPersons.totalSize);
        });
    };

    const popupHandler = (e: any) => {
        let id: string = e.target.id;
        let dataIdx = e.target.value;
        let targetPerson = targetPersons[dataIdx];

        rehabHistoryService.getRehabHistoryForTarget(targetPerson.targetNo).then((history: RehabTargetHistory[]) => {
            // 가장 최근 이력을 가져와서 편집을 진행

            let recordingTarget = history.find((item) => {
                return item.targetState == 8002;
            });

            let centerPopupWrap = document.getElementById('center-popup-wrap');
            if (centerPopupWrap) centerPopupWrap.style.display = 'block';
            ReactDOM.render(
                <RecordingPopup
                    data={targetPerson}
                    recordingData={recordingTarget ? recordingTarget : new RehabTargetHistory()}
                    closingHandler={closingHandler}
                ></RecordingPopup>,
                document.getElementById('center-popup-wrap'),
            );
        });
    };
    const trElems = (list: TargetPerson[] | undefined) => {
        let trElem = <></>;
        let page = currPage ? currPage : 0;
        let pageSize = displaySize ? displaySize : 0;
        let rowNum = page * pageSize;

        if (typeof list != 'undefined' && list.length > 0) {
            trElem = (
                <>
                    {targetPersons?.map((targetInfo: TargetPerson, idx) => {
                        let reservater: UserDetailInfo | undefined = targetInfo.reservater;
                        let infraInfo: any = targetInfo.infra;
                        let team: Team | undefined = targetInfo.team;
                        let genderCode: any = reservater?.genderCode;
                        let regionCode: any = reservater?.regionCode;
                        let sportCode: any = reservater?.sportCode;
                        let btnElem;

                        if (targetInfo.recordingStep == '7001') {
                            btnElem = (
                                <button id="recordingPhysical" className="center-button-letter" onClick={popupHandler} value={idx}>
                                    <i className="fas fa-stopwatch"></i> 측정하기
                                </button>
                            );
                        } else {
                            btnElem = (
                                <button id="checkResult" className="center-button-result" onClick={popupHandler} value={idx}>
                                    <i className="fab fa-wpforms"></i> 결과확인&수정
                                </button>
                            );
                        }
                        return (
                            <tr key={idx}>
                                {/* <td>
                                    <input type="checkbox" />
                                </td> */}
                                <td>{rowNum + idx + 1}</td>
                                <td>{reservater?.name}</td>
                                <td>{genderCode.name}</td>
                                <td>{reservater?.birthdate}</td>
                                <td>
                                    {team?.name} / {regionCode.name}
                                </td>
                                <td>{sportCode.name}</td>
                                <td>{btnElem}</td>
                                <td>{targetInfo.modDate}</td>
                                <td>{infraInfo.regionCode.name}</td>
                            </tr>
                        );
                    })}
                </>
            );
        } else {
            trElem = (
                <tr>
                    <td colSpan={10}>측정 대상자가 없습니다.</td>
                </tr>
            );
        }

        return trElem;
    };
    const contents = (targetPersons?: TargetPerson[]) => {
        let table = (
            <table className="center-list-table" cellPadding="0" cellSpacing="0">
                <thead>
                    <tr>
                        {/* <td>
                            <input type="checkbox" />
                        </td> */}
                        <td>No.</td>
                        <td>이름</td>
                        <td>성별</td>
                        <td>생년월일</td>
                        <td>소속팀</td>
                        <td>운동종목</td>
                        <td>측정</td>
                        <td>마지막 저장일</td>
                        <td>측정진행 센터</td>
                    </tr>
                </thead>
                <tbody>{trElems(targetPersons)}</tbody>
            </table>
        );

        return table;
    };

    useEffect(() => {
        let pagination = { size: 10, page: 0 };
        rehabService.getTargetForRecording(pagination).then((targetPersons: { data: TargetPerson[]; totalSize: number }) => {
            setTargetPersons(targetPersons.data);
            setTotalSize(targetPersons.totalSize);
        });
    }, []);

    const [totalSize, setTotalSize] = useState(1);
    const [displaySize, setDisplaySize] = useState(10);
    const [currPage, setCurrPage] = useState(0);

    const pagingHandler = (pagination: { size: number; page: number }) => {
        setCurrPage(pagination.page);
        setDisplaySize(pagination.size);
        rehabService.getTargetForRecording(pagination).then((targetPersons: { data: TargetPerson[]; totalSize: number }) => {
            setTargetPersons(targetPersons.data);
            setTotalSize(targetPersons.totalSize);
        });
    };

    let recordingList = (
        <Layout
            header={<Header></Header>}
            sideBar={<SideBar name={path}></SideBar>}
            path={path}
            naviTitle={title}
            menuTitle={menuTitle}
            filter={filters()}
            pagination={PaginationComponent(pagingHandler, totalSize, currPage, displaySize)}
            popups={popups()}
        >
            {contents(targetPersons)}
        </Layout>
    );
    return recordingList;
}

export default RecorodingComponent;
