import React from 'react';
import AdminMainTitle from '../../../../component/admin/AdminMainTitle';
import UUIDUtils from '../../../../utils/UUIDUtils';
import Infra from '../../../../domain/infra/Infra';
import InfraServiceImpl from '../../../../service/infra/impl/InfraServiceImpl';
import AdminTouringGuideTable from '../../../../component/admin/AdminTouringGuideTable';

const infraService = new InfraServiceImpl();
function AdminTouringListContainer() {
    const infraRegisteEvent = () => {
        const uuid = UUIDUtils.getUUID();
        const newTmpInfra = new Infra();
        newTmpInfra.infraNo = uuid;
        newTmpInfra.infraCategoryNo = '4';
        infraService.registeInfra(newTmpInfra).then((registedInfra) => {
            window.location.href = `/admin/infra/${uuid}`;
        });
    };

    return (
        <>
            <AdminMainTitle title="경남도 투어링 가이드">
                <button className="bg-white round">관광안내소 등록</button>
            </AdminMainTitle>
            <AdminTouringGuideTable></AdminTouringGuideTable>
        </>
    );
}

export default AdminTouringListContainer;
