import React, { useEffect, useState } from 'react';
import { Link, useRouteMatch, useParams } from 'react-router-dom';
import MyPageMainLayout from '../../../layout/MyPageMainLayout';
import MyPageRightContent from '../../../layout/MyPageRightContent';

import { useSelector } from 'react-redux';
import { RootState } from '../../../../../moldules/index';
import Paging from '../../../../../domain/common/Paging';
import EventServiceImpl from '../../../../../service/event/impl/EventServiceImpl';
import Event from '../../../../../domain/event/Event';
import CouponCard from '../../../../../component/infra/CouponCard';

const eventService = new EventServiceImpl();

function MyPageCouponListPage() {
    const { path, url } = useRouteMatch();
    const params: any = useParams();
    const { user, myTeam, isLogin, hasTeamAuth, hasSystemAuth } = useSelector((state: RootState) => state.userSessionReducer);
    const [coupons, setCoupons] = useState<Event[]>();
    const paging = new Paging(0, 50);

    useEffect(() => {
        let teamNo = myTeam.teamNo;
        eventService.getCouponByTeamNo(teamNo).then((result: Event[]) => {
            setCoupons(result);
        });
    }, []);

    let title = '발급 쿠폰 정보';

    return (
        <MyPageMainLayout>
            <MyPageRightContent contentTitle={title}>
                {coupons ? (
                    coupons.length > 0 ? (
                        coupons.map((coupon: Event, index: number) => {
                            return <CouponCard coupon={coupon} link={``}></CouponCard>;
                        })
                    ) : (
                        <div className="my-in">
                            <div className="my-in-info">
                                <div className="my-in-info-box">
                                    <p className="txt-center">
                                        방문 예정인 시설이 없습니다.<br></br>스포츠시서 쉽고 편하게 예약해보세요.
                                    </p>
                                    <Link className="bt-blue" to={'/trainning'}>
                                        스포츠 시설 찾기
                                    </Link>
                                </div>
                            </div>
                        </div>
                    )
                ) : (
                    <></>
                )}
            </MyPageRightContent>
        </MyPageMainLayout>
    );
}

export default MyPageCouponListPage;
