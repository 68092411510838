import Infra from '../../../domain/infra/Infra';
import ThirdPartyMapRenderer from './ThirdPartyMapRenderer';
import UserLocation from '../../../domain/user/UserLocation';
const { kakao } = window;
class KaKaoMapRenderer extends ThirdPartyMapRenderer {
    bounds: any = null;
    getMapInstance(infra: Infra) {
        const container: any = document.getElementById(this.mapId);
        const options = {
            // center: new kakao.maps.LatLng(infra.latitude, infra.longitude),
            center: new kakao.maps.LatLng(infra.getLatitude(), infra.getLongitude()),
            level: this.mapLevel,
        };

        return new kakao.maps.Map(container, options);
    }

    addMapTypeControl(): void {
        const mapTypeControl = new kakao.maps.MapTypeControl();
        this.map.addControl(mapTypeControl, kakao.maps.ControlPosition.TOPRIGHT);
    }

    addZoomControl(): void {
        const zoomControl = new kakao.maps.ZoomControl();
        this.map.addControl(zoomControl, kakao.maps.ControlPosition.RIGHT);
    }

    myLocationMarkerRender(userLocation: UserLocation) {
        var imageSrc = '/assets/image/map-marker-icon.png', // 마커이미지의 주소입니다
            imageSize = new kakao.maps.Size(64, 69), // 마커이미지의 크기입니다
            imageOption = { offset: new kakao.maps.Point(27, 69) }; // 마커이미지의 옵션입니다. 마커의 좌표와 일치시킬 이미지 안에서의 좌표를 설정합니다.

        var markerImage = new kakao.maps.MarkerImage(imageSrc, imageSize, imageOption),
            markerPosition = new kakao.maps.LatLng(userLocation.latitude, userLocation.longitude); // 마커가 표시될 위치입니다

        var marker = new kakao.maps.Marker({
            position: markerPosition,
            image: markerImage,
            title: '내위치',
        });

        marker.setMap(this.map);
    }

    infraInfoMakeAt(): void {
        //console.log(this.infras);
        this.bounds = new kakao.maps.LatLngBounds();

        this.infras.map((infra: Infra) => {
            if (infra.infraNo) this.renderedInfraMemoMap[infra.infraNo] = infra;
            else throw new Error('인프라 번호 없음');
            //console.log(infra.getLatitude(), infra.getLongitude());
            let placePosition = new kakao.maps.LatLng(infra.latitude, infra.longitude);
            const marker = new kakao.maps.Marker({
                map: this.map,
                position: placePosition,
                title: infra.name,
            });

            this.bounds.extend(placePosition);

            const content =
                `<div class="kakao_map_overlay_wrap">` +
                `    <div class="info">` +
                `        <div class="title">` +
                `            ${infra.name}` +
                `            <div class="close" onclick="kakao.closeOverlay()" title="닫기"></div>` +
                `        </div>` +
                `        <div class="body">` +
                `            <div class="img">` +
                `                <img src="${infra.getMainAttachFile()}" width="73" height="70">` +
                `           </div>` +
                `            <div class="desc">` +
                `                <div class="ellipsis">${infra.address}</div>` +
                `                <div><a href='${'/trainning/infra/' + infra.infraNo}' target="_blank" class="link">상세보기</a></div>` +
                // `                <div><a onclick="kakao.reservationModalOpen('${infra.infraNo}')" target="_blank" class="link">예약하기</a></div>` +
                `            </div>` +
                `        </div>` +
                `    </div>` +
                `</div>`;

            const overlay = new kakao.maps.CustomOverlay({
                content: content,
                map: this.map,
                position: marker.getPosition(),
            });

            this.renderedOverlaysMemoArr.push(overlay);

            kakao.maps.event.addListener(marker, 'click', () => {
                kakao.closeOverlay();
                overlay.setMap(this.map);
            });

            kakao.maps.event.addListener(this.map, 'center_changed', () => {
                let latlng = this.map.getCenter();
            });

            kakao.reservationModalOpen = (infraNo: string) => {
                let infra = this.renderedInfraMemoMap[infraNo];

                this.setReservationInfra(infra);
                this.setReservationModalOpenable(true);
            };

            kakao.closeOverlay = () => {
                overlay.setMap(null);
                this.renderedOverlaysMemoArr.map((overlay) => {
                    overlay.setMap(null);
                });
            };

            kakao.closeOverlay();
            this.map.setBounds(this.bounds);
        });
    }
}

export default KaKaoMapRenderer;
