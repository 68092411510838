import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject("attachFile")
export default class AttachFile {
    @JsonProperty("attachFileTargetNo")
    attachFileTargetNo: string = '';
    @JsonProperty("attachType")
    attachType?: string;
    @JsonProperty("deleteDate")
    deleteDate?: Date; 
    @JsonProperty("extension")
    extension?: string;
    @JsonProperty("originalName")
    originalName?: string;
    @JsonProperty("saveFilePath")
    saveFilePath: string = '';
    @JsonProperty("saveName")
    saveName?: string;
    @JsonProperty("savePath")
    savePath?: string;
    @JsonProperty("size")
    size?: number;
    @JsonProperty("mainFileYn")
    mainFileYn?: boolean;

    getPath(): string {
        return `${process.env.REACT_APP_RESOURCE_API_HOST}/static-file${this.saveFilePath}`;
    }

    static getMainFile(attachFiles: AttachFile[]): AttachFile|undefined {
        const mainAttachFiles = attachFiles.filter((value: AttachFile, index: number) => {
            if(value.mainFileYn) {
                return value;
            }
        });

        if(mainAttachFiles.length > 0) {
            return mainAttachFiles[0];
        }
    }

    static getNoMainFiles(attachFiles: AttachFile[]): AttachFile[] {
        return attachFiles.filter((value: AttachFile, index: number) => {
            if(!value.mainFileYn) {
                return value;
            }
        })
    }

    static getDefaulInfraImg() {
        const attachFile = new AttachFile();
        attachFile.attachFileTargetNo = '';
        attachFile.attachType = 'FAKE';
        attachFile.deleteDate = new Date();
        attachFile.extension = '';
        attachFile.originalName = '';
        attachFile.saveFilePath = '/file/infra/sidfsdkikmndd/bcfeeb9dbdbc652f435673a94c393e86.jpg';
        attachFile.saveName = '';
        attachFile.savePath = '/file/infra/sidfsdkikmndd/bcfeeb9dbdbc652f435673a94c393e86.jpg';
        attachFile.size = 0;
        
        return attachFile;
    }

    static getDefaulFoodImg() {
        const attachFile = new AttachFile();
        attachFile.attachFileTargetNo = '';
        attachFile.attachType = 'FAKE';
        attachFile.deleteDate = new Date();
        attachFile.extension = '';
        attachFile.originalName = '';
        attachFile.saveFilePath = '/file/infra/sdfsdfsdf22232/b6c9fbd372762e9eb593e85fcf268b83.png';
        attachFile.saveName = '';
        attachFile.savePath = '/file/infra/sdfsdfsdf22232/b6c9fbd372762e9eb593e85fcf268b83.png';
        attachFile.size = 0;
        
        return attachFile;
    }
}