import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../moldules/index';
import Schedule from '../../../../../../domain/schedule/Schedule';
import EventUtils from '../../../../../../utils/EventUtils';
import moment from 'moment';
import { momentLocalizer, Calendar  } from 'react-big-calendar';
import useTeamScheduleByMonth from '../../../../../../hook/schedule/useTeamScheduleByMonth';

function MyPageScheduleAllViewCalendarTypeTab() {
    const localizer = momentLocalizer(moment);
    const {myTeam} = useSelector((state: RootState) => state.userSessionReducer);
    const [events, setEvents] = useState(new Array());
    const [month, setMonth] = useState<string>(String(new Date().getMonth() + 1));
    const {teamSchedules} = useTeamScheduleByMonth(myTeam.teamNo, month);
    
    useEffect(() => {
        if(teamSchedules) {
            const newEvents: any[] = [];
            teamSchedules.forEach((teamSchedule: Schedule, eventId: number) => {
                const startDate = teamSchedule.reservation ? teamSchedule.reservation.startDate : teamSchedule.startDate;
                const endDate = teamSchedule.reservation ? teamSchedule.reservation.endDate : teamSchedule.endDate;
                const eventTitle = teamSchedule.title;
                const eventLink = '212';
                
                if(startDate && endDate && eventTitle && eventLink) {
                    const newEvent = EventUtils.makeEvent(eventId, startDate, endDate, eventTitle, eventLink);
                    newEvents.push(newEvent);
                }
            });

            setEvents(newEvents);
        }
    }, [teamSchedules]);

    useEffect(() => {
        console.log(events);
    }, [events])

    return (
        <Calendar
            localizer={localizer}
            events={events}
            onSelectEvent={(event) => console.log(event)}
            style={{height: '700px'}}
            onNavigate={date => {
                setMonth(String(date.getMonth() + 1));
            }}
        >
        </Calendar>
    )
}



export default MyPageScheduleAllViewCalendarTypeTab;