import React, { useEffect, useState } from 'react';
import Infra from '../../domain/infra/Infra';
import InfraServiceImpl from '../../service/infra/impl/InfraServiceImpl';
import InfraCard from '../../component/infra/InfraCard';
import { NEAR_DISTAINCE } from '../../config/Constant';

const infraService = new InfraServiceImpl();
interface InfraNearDistanceContainerProps {
    infra: Infra|undefined;
    title: string;
    parentInfraCategoryNo: number;
}

function InfraNearDistanceContainer(props: InfraNearDistanceContainerProps) {
    const [infraName, setInfraName] = useState('');
    const [infras, setInfras] = useState(new Array<Infra>());
    useEffect(() => {
        if(props.infra) {
            if(props.infra.latitude && props.infra.longitude) {
                infraService.getInfraOfParentCategoryNoAndNearDistance(props.parentInfraCategoryNo, props.infra.latitude, props.infra.longitude, NEAR_DISTAINCE)
                .then((infras: Infra[]) => {
                    setInfras(infras.slice(0,3));
                });
            }
            
            if(props.infra.name) {
                setInfraName(props.infra.name);
            }
        }
    }, [props.infra]);

    return (
        <div className="type">
            <p className="search-tit">{`${infraName} ${props.title}`}</p>
            <ul className="area-room">
                {
                    props.infra?
                        props.infra?.latitude?
                            infras.length > 0? 
                            infras.map((infra, index) => {
                                return <InfraCard key={index} infra={infra}></InfraCard>
                            })
                            : 
                            <h5>관련 시설이 없습니다.</h5>
                        :
                        <h5>관련 시설이 없습니다.</h5>
                    :
                    <></>  
                }
            </ul>
            <div className="clear"></div>
        </div>
    )
}

export default InfraNearDistanceContainer;