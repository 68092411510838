import { JsonObject, JsonProperty } from "json2typescript";
import Code from '../../code/Code';

@JsonObject("totalVisitedTeamInfoByTeamType")
class TotalVisitedTeamInfoByTeamType {
    @JsonProperty("parentRegionCode")
    parentRegionCode?: Code;
    @JsonProperty("regionCode")
    regionCode?: Code;
    @JsonProperty("teamTypeCode")
    teamTypeCode?: Code;
    @JsonProperty("teamVisitedCnt")
    teamVisitedCnt?: number;
    @JsonProperty("totalTeamVisitedCnt")
    totalVisitedTeamCnt?: number;
    @JsonProperty("visitedMemberCnt")
    visitedMemberCnt?: number;
    @JsonProperty("totalVisitedMemberCnt")
    totalVisitedMemberCnt?: number;

    static getTotalVisitedTeamInfoByTeamTypeMapByRegionCodeIdAndTeamTypeCodeId(totalVisitedTeamInfos: TotalVisitedTeamInfoByTeamType[], totalVisitedTeamInfosTotal: TotalVisitedTeamInfoByTeamType[]) {
        const map = new Map<string, TotalVisitedTeamInfoByTeamType>();
        totalVisitedTeamInfos.forEach((totalVisitedTeamInfo: TotalVisitedTeamInfoByTeamType) => {
            const key = totalVisitedTeamInfo.regionCode?.codeId + "/" + totalVisitedTeamInfo.teamTypeCode?.codeId;
            map.set(key, totalVisitedTeamInfo);
        });

        totalVisitedTeamInfosTotal.forEach((totalVisitedTeamInfo: TotalVisitedTeamInfoByTeamType) => {
            const key = "0/" + totalVisitedTeamInfo.teamTypeCode?.codeId;
            map.set(key, totalVisitedTeamInfo);
        });

        return map;
    }
}

export default TotalVisitedTeamInfoByTeamType;