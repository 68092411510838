import { JsonObject, JsonProperty } from 'json2typescript';
import Infra from '../infra/Infra';
@JsonObject("userFavoritesInfra")
class UserFavoritesInfra {
    @JsonProperty("favorityInfra")
    favorityInfra?: Infra;

    favoriterNo?: string;
    favoritesTargetNo?: string;

    static getUserFavoritesInfra(favoriterNo: string, favoritesTargetNo: string): UserFavoritesInfra {
        const userFavoritesInfra = new UserFavoritesInfra();
        userFavoritesInfra.favoriterNo = favoriterNo;
        userFavoritesInfra.favoritesTargetNo = favoritesTargetNo;

        return userFavoritesInfra;
    }
}

export default UserFavoritesInfra;