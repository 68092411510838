import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import CalendarComponent from '../../../component/calendar/CalendarComponent';
import { CodeType } from '../../../domain/code/CodeType';
import useCodeRetrieve from '../../../hook/code/useCodeRetrieve';
import SignUpStepBarComponent from '../components/SignUpStepBarComponent';
import SignUpHeader from '../layout/SignUpHeader';
import Team from '../../../domain/team/Team';
import Code from '../../../domain/code/Code';
import useChildCodeRetrieve from '../../../hook/code/useChildCodeRetrieve';
import useUserEmailOverlapCheck from '../../../hook/user/useUserEmailOverlapCheck';
import TeamSearchInput from '../../../component/input/TeamSearchInput';
import { USER_SERVICE } from '../../../service';
import SignupInfo from '../../../domain/user/SignupInfo';
import { confirmAlert } from 'react-confirm-alert';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ko } from 'date-fns/esm/locale';
import DateUtils from '../../../utils/DateUtils';

function SignUpPage() {
    const [mobileCheckSessionCheck, setMobileCheckSessionCheck] = useState<Boolean>();
    const location: any = useLocation();

    const [belongedTeamNo, setBelongedTeamNo] = useState<string>();
    const [sportCodeId, setSportCodeId] = useState<number>();
    const [phoneNumber, setPhoneNumber] = useState<string>();
    const [teams, setTeams] = useState<Team[]>();
    const [parentRegionCodeId, setParentRegionCodeId] = useState<number>();
    const [regionCodeId, setRegionCodeId] = useState<number>();
    const [userTypeCodeId, setUserTypeCodeId] = useState<number>();
    const [userName, setUserName] = useState<string>();
    const [email, setEmail] = useState<string>();
    const [birthDate, setBirthDate] = useState<any>();
    const [selectedBDate, setSelectedBDate] = useState<Date | [Date | null, Date | null] | null>();
    const [genderCodeId, setGenderCodeId] = useState<number>();
    const [classificationCodeId, setClassificationCodeId] = useState<number>();
    const { codes: sportCodes } = useCodeRetrieve(CodeType.SPORT);
    const { codes: classificationCodes } = useCodeRetrieve(CodeType.TEAM_TYPE);
    const { codes: parentRegionCodes } = useCodeRetrieve(CodeType.REGION);
    const regionCodes = useChildCodeRetrieve(parentRegionCodeId);
    const [password, setPassword] = useState<string>();
    const [passowrdValidation, setPasswordValidation] = useState<boolean>();
    const [confirmedPassword, setConfirmedPassword] = useState<string>();
    const [confirmedPassowrdValidation, setConfirmedpasswordValidation] = useState<boolean>();

    const emailValidation = useUserEmailOverlapCheck(email);

    const singUpBtnHandler = () => {
        if (
            belongedTeamNo &&
            userTypeCodeId &&
            genderCodeId &&
            email &&
            password &&
            regionCodeId &&
            phoneNumber &&
            classificationCodeId &&
            birthDate
        ) {
            let signupInfo = new SignupInfo();
            signupInfo.name = userName;
            signupInfo.email = email;
            signupInfo.phoneNumber = phoneNumber;
            signupInfo.birthDate = DateUtils.getDateForm(birthDate);
            signupInfo.password = password;
            signupInfo.belongedTeamNo = belongedTeamNo;
            signupInfo.userTypeCodeId = userTypeCodeId;
            signupInfo.genderCodeId = genderCodeId;
            signupInfo.regionCodeId = regionCodeId;

            USER_SERVICE.signUp(signupInfo).then((response: any) => {
                let isComplete = response.signupComplete;
                if (isComplete) {
                    confirmAlert({
                        title: '가입성공',
                        message: '가입 신청이 완료되었습니다. 로그인 페이지로 이동합니다.',
                        buttons: [{ label: '확인', onClick: () => (window.location.href = '/login') }],
                    });
                } else {
                    confirmAlert({
                        title: '가입 실패',
                        message: 'Email 이나 전화번호가 이미 존재합니다. 확인 후 다시 가입해 주십시오.',
                        buttons: [{ label: '확인', onClick: () => {} }],
                    });
                }
            });
        } else {
            confirmAlert({
                title: '경고',
                message: '입력 정보를 확인해 주십시오.',
                buttons: [{ label: '확인', onClick: () => {} }],
            });
            // console.log(
            //     belongedTeamNo +
            //         '/' +
            //         userTypeCodeId +
            //         '/' +
            //         genderCodeId +
            //         '/' +
            //         email +
            //         '/' +
            //         password +
            //         '/' +
            //         regionCodeId +
            //         '/' +
            //         phoneNumber +
            //         '/' +
            //         classificationCodeId +
            //         '/' +
            //         birthDate,
            // );
        }
    };

    useEffect(() => {
        USER_SERVICE.getMobileCheckSessionCheck().then((response) => {
            // 사용자 전화 번호 인증 후 Session 확인 결과를 반환 받는 부분
            setMobileCheckSessionCheck(true);
            setUserName(location.state?.userName);
            setPhoneNumber(location.state?.phoneNumber);
            // if (response) {
            //     setMobileCheckSessionCheck(response);
            // }
        });
    }, []);

    useEffect(() => {
        if (password) {
            var smalAlpaRex = new RegExp('[a-z]+');
            var bigAlpaRex = new RegExp('[A-Z]+');
            var numberRex = new RegExp('[0-9]+');
            var specialWordRex = new RegExp('[~,!,@,#,$,%,^,&,*,(,)]+');
            const lengthCheck = password.length > 7 && password.length < 17;
            const wordCheck = [smalAlpaRex.test(password), bigAlpaRex.test(password), numberRex.test(password), specialWordRex.test(password)].filter(
                (value) => {
                    return value;
                },
            ).length;
            if (lengthCheck && wordCheck) {
                setPasswordValidation(true);
            } else {
                setPasswordValidation(false);
            }
        }
    }, [password]);

    useEffect(() => {
        if (confirmedPassword) {
            if (password == confirmedPassword) setConfirmedpasswordValidation(true);
            else setConfirmedpasswordValidation(false);
        }
    }, [confirmedPassword]);

    return (
        <>
            <SignUpHeader title="회원 가입"></SignUpHeader>
            <div className="mgt10"></div>
            {mobileCheckSessionCheck ? (
                <>
                    <div className="content-wrap" style={{ minHeight: 'auto' }}>
                        <div className="signup-content-wrap">
                            <SignUpStepBarComponent step={'step3'}></SignUpStepBarComponent>
                        </div>
                        <div className="popup-opened">
                            <div className="join">
                                <div id="sportman" className="signup-form">
                                    <div className="txt-left mgt10">
                                        <h5 className="alert-h5">가입 유형</h5>
                                    </div>
                                    <select
                                        onChange={(event) => {
                                            var value = Number(event.currentTarget.value);
                                            if (value == 0) {
                                                setUserTypeCodeId(undefined);
                                            } else {
                                                setUserTypeCodeId(value);
                                            }
                                        }}
                                        className="basic-gray"
                                    >
                                        <option value={0}>가입유형</option>
                                        <option value={21}>선수</option>
                                        <option value={21}>감독</option>
                                    </select>
                                    {!userTypeCodeId ? (
                                        <div className="dan">
                                            <span className="dan-red">!</span>가입 유형을 선택하세요.
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                    <div className="txt-left mgt10">
                                        <h5 className="alert-h5">이메일(사이트에서 계정으로 활용됩니다.)</h5>
                                    </div>
                                    <input
                                        type="text"
                                        className="basic-gray signup-input"
                                        placeholder="이메일 입력하세요."
                                        onBlur={(event) => {
                                            setEmail(event.currentTarget.value);
                                        }}
                                    ></input>
                                    {!email ? (
                                        <div className="dan">
                                            <span className="dan-red">!</span>이메일을 정확하게 입력 하셨나요?
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                    {emailValidation == false ? (
                                        <div className="dan">
                                            <span className="dan-red">!</span>존재하는 이메일입니다.
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                    <div className="txt-left mgt10">
                                        <h5 className="alert-h5">휴대폰번호</h5>
                                    </div>
                                    <input
                                        type="text"
                                        onChange={(event) => setPhoneNumber(event.currentTarget.value)}
                                        className="basic-gray signup-input"
                                        placeholder="휴대폰번호"
                                    />
                                    {!phoneNumber ? (
                                        <div className="dan">
                                            <span className="dan-red">!</span>본인 명의 휴대폰 번호를 등록하세요.
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                    {/* 비밀번호 */}
                                    <div className="txt-left mgt10">
                                        <h5 className="alert-h5">비밀번호</h5>
                                    </div>
                                    <input
                                        type="password"
                                        className="basic-gray signup-input"
                                        placeholder="비밀번호를 입력하세요."
                                        onBlur={(event) => {
                                            setPassword(event.currentTarget.value);
                                        }}
                                    ></input>
                                    {passowrdValidation == false ? (
                                        <div className="dan">
                                            <span className="dan-red">!</span>8~16자 영문 대 소문자, 숫자, 특수문자를 사용하세요.
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                    <div className="txt-left mgt10">
                                        <h5 className="alert-h5">비밀번호 확인</h5>
                                    </div>
                                    <input
                                        type="password"
                                        className="basic-gray signup-input"
                                        placeholder="비밀번호를 입력하세요."
                                        onBlur={(event) => {
                                            setConfirmedPassword(event.currentTarget.value);
                                        }}
                                    ></input>
                                    {confirmedPassowrdValidation == false ? (
                                        <div className="dan">
                                            <span className="dan-red">!</span>비밀번호가 일치하지 않습니다.
                                        </div>
                                    ) : (
                                        <></>
                                    )}

                                    {/* 비밀번호 */}

                                    <div className="txt-left mgt10">
                                        <h5 className="alert-h5">이름</h5>
                                    </div>
                                    <input
                                        type="text"
                                        className="basic-gray signup-input"
                                        placeholder="이름을 입력하세요."
                                        onChange={(evt: any) => {
                                            setUserName(evt.currentTarget.value);
                                        }}
                                    ></input>
                                    {!userName ? (
                                        <div className="text-left dan">
                                            <span className="dan-red">!</span>이름을 정확하게 입력 하셨나요?
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                    <div className="txt-left mgt10">
                                        <h5 className="alert-h5">생년월일</h5>
                                    </div>
                                    <DatePicker
                                        locale={ko}
                                        dateFormat="yyyy-MM-dd"
                                        className="basic-gray"
                                        closeOnScroll={true}
                                        selected={birthDate}
                                        onChange={(date) => {
                                            setBirthDate(date);
                                        }}
                                    ></DatePicker>
                                    {/* <CalendarComponent onDateChange={setBirthDate}></CalendarComponent> */}
                                    {!birthDate ? (
                                        <div className="dan">
                                            <span className="dan-red">!</span>생년월일을 입력하세요.
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                    <div className="txt-left mgt10">
                                        <h5 className="alert-h5">성별</h5>
                                    </div>
                                    <select
                                        className="basic-gray w100"
                                        onChange={(event) => {
                                            const value = Number(event.currentTarget.value);
                                            if (value == 0) {
                                                setGenderCodeId(undefined);
                                            } else {
                                                setGenderCodeId(value);
                                            }
                                        }}
                                    >
                                        <option value={0}>성별</option>
                                        <option value={11}>남자</option>
                                        <option value={12}>여자</option>
                                    </select>
                                    {!genderCodeId ? (
                                        <div className="dan">
                                            <span className="dan-red">!</span>성별을 선택하세요.
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                    <div className="txt-left mgt10">
                                        <h5 className="alert-h5">거주지역</h5>
                                    </div>
                                    <select
                                        onChange={(event) => {
                                            setParentRegionCodeId(Number(event.currentTarget.value));
                                        }}
                                        className="basic-gray w100"
                                    >
                                        <option value={0}>지역선택</option>
                                        {parentRegionCodes?.map((code, index) => {
                                            return (
                                                <option key={index} value={code.codeId}>
                                                    {code.name}
                                                </option>
                                            );
                                        })}
                                    </select>
                                    <select
                                        onChange={(event) => {
                                            setRegionCodeId(Number(event.currentTarget.value));
                                        }}
                                        className="basic-gray w100"
                                        style={{ marginTop: '4px' }}
                                    >
                                        <option value={0}>세부지역선택</option>
                                        {regionCodes?.map((regionCodeId: Code, index: number) => {
                                            return <option value={regionCodeId.codeId}>{regionCodeId.name}</option>;
                                        })}
                                    </select>
                                    {!regionCodeId ? (
                                        <div className="dan">
                                            <span className="dan-red">!</span>지역을 선택 해주세요.
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                    <div className="txt-left mgt10">
                                        <h5 className="alert-h5">팀</h5>
                                    </div>
                                    <TeamSearchInput
                                        setTeamNo={setBelongedTeamNo}
                                        setClassficationCode={setClassificationCodeId}
                                        setSportsCode={setSportCodeId}
                                        className="basic-gray signup-input"
                                    ></TeamSearchInput>
                                    {!belongedTeamNo ? (
                                        <div className="dan">
                                            <span className="dan-red">!</span>팀을 선택 해주세요. 소속과 종목이 자동 입력 됩니다. 확인해 주세요.
                                        </div>
                                    ) : (
                                        <></>
                                    )}

                                    <div className="txt-left mg10">
                                        <h5 className="alert-h5">소속</h5>
                                    </div>
                                    <select
                                        value={classificationCodeId}
                                        onChange={(event) => {
                                            setClassificationCodeId(Number(event.currentTarget.value));
                                        }}
                                        className="basic-gray w100"
                                    >
                                        <option value={0}>소속 선택</option>
                                        {classificationCodes?.map((classificationCode: Code, index: number) => {
                                            return (
                                                <option key={index} value={classificationCode.codeId}>
                                                    {classificationCode.name}
                                                </option>
                                            );
                                        })}
                                    </select>
                                    {!classificationCodeId ? (
                                        <div className="dan">
                                            <span className="dan-red">!</span>
                                            소속을 선택하세요.
                                        </div>
                                    ) : (
                                        <></>
                                    )}

                                    <div className="txt-left mgt10">
                                        <h5 className="alert-h5">종목</h5>
                                    </div>
                                    <select
                                        value={sportCodeId}
                                        onChange={(event) => {
                                            setSportCodeId(Number(event.currentTarget.value));
                                        }}
                                        className="basic-gray w100"
                                    >
                                        <option value={0}>종목선택</option>
                                        {sportCodes?.map((code, index) => {
                                            return (
                                                <option key={index} value={code.codeId}>
                                                    {code.name}
                                                </option>
                                            );
                                        })}
                                    </select>
                                    {!sportCodeId ? (
                                        <div className="dan">
                                            <span className="dan-red">!</span>종목을 선택 해주세요.
                                        </div>
                                    ) : (
                                        <></>
                                    )}

                                    <div className="lay-basic mgt10">
                                        <button className="center-button-login" onClick={singUpBtnHandler}>
                                            가입
                                        </button>
                                        <div className="mgt10"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <h5>비정상적인 접근입니다.</h5>
            )}
        </>
    );
}

export default SignUpPage;
