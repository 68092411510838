import ReportTypeEnum from "./ReportTypeEnum";

class ReportDataParam {
    reportOwnerNo?: string;
    targetNo?: number;
    reportType?: ReportTypeEnum;
    reportData?: Object;

    constructor(reportOwnerNo?: string, targetNo?: number, reportType?: ReportTypeEnum, reportData?: Object) {
        this.reportOwnerNo = reportOwnerNo;
        this.targetNo = targetNo;   
        this.reportType = reportType;
        this.reportData = reportData;
    }
}

export default ReportDataParam;