import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import RehabilitationMainHeader from '../layout/RehabilitationMainHeader';
import RehabilitationMainContainer from '../page/main/RehabilitationMainContainer';
import RehabilitationFitnessContainer from '../page/fitness/RehabilitationFitnessContainer';
import RehabilitationSportCenterContainer from '../page/sport/RehabilitationSportCenterContainer';
import RehabilitationCenterContainer from '../page/center/RehabilitationCenterContainer';

function RehabilitationRouter({ match }: RouteComponentProps) {
    return (
        <>
            <RehabilitationMainHeader></RehabilitationMainHeader>
            <Switch>
                <Route exact={true} path={match.path} component={RehabilitationMainContainer}></Route>
                {/* <Route path={`${match.path}/fitness`} component={RehabilitationFitnessContainer}></Route>
            <Route path={`${match.path}/sport-center`} component={RehabilitationSportCenterContainer}></Route> */}
                <Route path={`${match.path}/re-center`} component={RehabilitationCenterContainer}></Route>
            </Switch>
        </>
    );
}

export default RehabilitationRouter;
