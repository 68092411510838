import { JsonObject, JsonProperty } from "json2typescript";
import User from '../user/User';

@JsonObject("tmpRegistedTeamUser")
class TmpRegistedTeamUser {
    @JsonProperty("tmpRegistedNo")
    tmpRegistedNo?: string;
    @JsonProperty("teamNo")
    teamNo?: string;
    @JsonProperty("userName")
    userName?: string;
    @JsonProperty("phoneNumber")
    phoneNumber?: string;
    @JsonProperty("user")
    user?: User;

    makeInstance(teamNo: string, userName: string, phoneNumber: string) {
        this.teamNo = teamNo;
        this.userName = userName;
        this.phoneNumber = phoneNumber;
    }
}

export default TmpRegistedTeamUser;