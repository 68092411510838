import React from 'react';
import { JsonConvert } from 'json2typescript';
import Vas from '../../../../domain/recording/Vas';

interface BtnProp {
    properties?: { className: string; id: string }[];
    defaultValue?: Vas[];
    handler: (e: any) => void;
}

const jsonConvert = new JsonConvert();
const DivBtnComponent = function (props: BtnProp) {
    const names: { className: string; id: string }[] | undefined = props.properties;
    const handler = props.handler;
    const defaultVal = props.defaultValue;
    let checkedVas = '';

    if (defaultVal) {
        defaultVal.forEach((rec) => {
            checkedVas += rec.part + ',';
        });
    }

    return (
        <>
            {names?.map((item, idx) => {
                let id = item.id;
                let className = checkedVas.includes(id) ? item.className + ' check-dot-select' : item.className;
                return (
                    <div id={id} className={className} key={idx} onClick={handler}>
                        {idx + 1}
                    </div>
                );
            })}
        </>
    );
};

export default DivBtnComponent;
