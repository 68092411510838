import React from 'react';
import AdminFormTitle from '../../../../component/admin/AdminFormTitle';
import { useParams } from 'react-router-dom';
import AdminManagerInfoForm from '../../component/manager/AdminManagerInfoForm';

function AdminManagerDetailContainer() {
    const params: any = useParams();

    return (
        <>
            <AdminFormTitle title={<>운영자 상세</>} />
            <div className="two-wrap">
                <div className="half">
                    <div className="member-list">
                        {<AdminManagerInfoForm flag={'detail'} userNo={params.userNo} buttonTitle={'운영자 정보 수정'}></AdminManagerInfoForm>}
                    </div>
                </div>
            </div>
        </>
    );
}

export default AdminManagerDetailContainer;
