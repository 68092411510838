import React from 'react';
import Team from '../../domain/team/Team';
import TeamSearchListItem from './TeamSearchListItem';

interface TeamSerchListProps {
    searchedTeams?: Team[];
}

function TeamSearchList(props: TeamSerchListProps) {
    return (
        <table className="output-list">
            <tbody>
                {
                    props.searchedTeams?
                    props.searchedTeams.map((searchedTeam, index) => {
                        return <TeamSearchListItem key={index} searchedTeam={searchedTeam}></TeamSearchListItem>
                    })
                    :
                    <></>
                }
            </tbody>
        </table>
    )
}

export default TeamSearchList;