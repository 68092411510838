import React from 'react';
import { Link } from 'react-router-dom';

function RehabilitateMainContainer() {
    return (
        <>
            
                <div className="banner-in3">
                    <ul>
                        <li className="banner-list wow animate__animated animate__fadeInUp" data-wow-delay="0.2s">
                            <Link to="/rehabilitation/fitness">
                                <div className="banner-001">
                                    <p>국민체력 100</p>
                                </div>
                                <div className="banner-001_txt">국민체력 100으로 100세까지 건강하게</div>
                            </Link>
                        </li>
                        <li className="banner-list wow animate__animated animate__fadeInUp" data-wow-delay="0.4s">
                            <Link to="/rehabilitation/sport-center">
                                <div className="banner-002">
                                    <p>스포츠과학센터</p>
                                </div>
                                <div className="banner-002_txt"> 체력상태를 과학적 방법에 의해 측정/평가하여<br/>
                                    운동 상담 및 처방을 해주는 대한민국 스포츠 복지 서비스</div>
                            </Link>
                        </li>
                        <li className="banner-list wow animate__animated animate__fadeInUp" data-wow-delay="0.6s">
                            <Link to="/rehabilitation/re-center">
                                <div className="banner-003">
                                    <p className="last">재활센터<br/>
                                        (스포츠과학재활센터)</p>
                                </div>
                                <div className="banner-002_txt">선수들의 경기력을 향상하고<br/>
                                    스포츠 과학을 지원합니다.</div>
                            </Link>
                        </li>
                    </ul>
                    <div className="clear"></div>
                </div>
        
        </>
    )
}

export default RehabilitateMainContainer;