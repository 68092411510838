import React from 'react';
import { Route, Switch } from 'react-router-dom';
import TestComponent2 from '../../../component/TestComponent2';
import SportInfraDetailPage from '../page/SportInfraDetailPage';
import SubInfraDetailPage from '../page/SubInfraDetailPage';

function InfraRouter() {
    return (
        <>
            <Switch>
                <Route exact path="/infra/sport/:infraNo" component={SportInfraDetailPage}></Route>
                <Route exact path="/infra/sub/:infraNo" component={SubInfraDetailPage}></Route>
                <Route exact path="/infra/food/:infraNo" component={TestComponent2}></Route>
            </Switch>
        </>
    );
}

export default InfraRouter;
