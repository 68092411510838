import React, { useEffect } from 'react';
import useRehabStatisticsBasicInfo from '../../hook/useRehabStatisticsBasicInfo';
import {Doughnut} from 'react-chartjs-2';

function RehabStatisticsBasicInfoTab() {
    const {
        genderDataSet, sportDataSet, qnaDataSet
    }  = useRehabStatisticsBasicInfo();

    return (
        <div id="center-popup-list">
            <div className="flex-basic">
                <h2 className="center2">총 측정자수 100,000명</h2>
                <button className="center-button-esave">
                    <i className="fas fa-file-excel"></i> 엑셀파일 저장
                </button>
            </div>
            <div className="chart-area">
                <div className="chart-container width33Rate">
                    <h5 className="chart-title">성별 측정 현황</h5>
                    <Doughnut
                        data={genderDataSet}
                    >
                    </Doughnut>
                </div>
                <div className="chart-container width33Rate">
                    <h5 className="chart-title">종목별 측정 현황</h5>
                    <Doughnut
                        data={sportDataSet}
                    >
                    </Doughnut>
                </div>
                <div className="chart-container width33Rate">
                    <h5 className="chart-title">설문조사 응답 현황</h5>
                    <Doughnut
                        data={qnaDataSet}
                    >
                    </Doughnut>
                </div>
            </div>
        </div>
    )    
}

export default RehabStatisticsBasicInfoTab;