import React, { useEffect, useState } from 'react';
import TeamServiceImpl from '../../../../service/team/impl/TeamServiceImpl';
import Team from '../../../../domain/team/Team';
import useTeamInfoHook from '../../../../hook/useTeamInfoHook';
import AdminFormTitle from '../../../../component/admin/AdminFormTitle';
import { useLocation } from 'react-router';
import { useHistory, RouteComponentProps } from 'react-router-dom';
import AdminTeamNav from '../../nav/AdminTeamNav';
import useCodeRetrieve from '../../../../hook/code/useCodeRetrieve';
import { CodeType } from '../../../../domain/code/CodeType';

import TeamSearchCondition from '../../../../domain/team/param/TeamSearchCondition';

import CodeSelectFilter from '../../component/filter/element/CodeSelectFilter';
import { confirmAlert } from 'react-confirm-alert';
import VisitedTeamInfoSearchCondition from '../../../../domain/visitedInfo/pagam/VisitedTeamInfoSearchCondition';

interface MatchParams {
    teamNo: string;
    url: string;
}

const teamService = new TeamServiceImpl();
function AdminTeamInfoContainer({ match }: RouteComponentProps<MatchParams>) {
    const history = useHistory();
    const location = useLocation();

    const { teamNo } = match.params;
    const { url } = match;
    //const { team: teamInfo, setTeam } = useTeamInfoHook(teamNo);
    const [team, setTeam] = useState<Team>();

    const [teamName, setTeamName] = useState<string | undefined>();
    const [phoneNumber, setPhoneNumber] = useState<string | undefined>();

    const [regionCode, setRegionCode] = useState<number | undefined>();
    const [belongedCode, setBelongedCode] = useState<number | undefined>();
    const [genderCode, setGenderCode] = useState<number | undefined>();
    const [classificationCode, setClassificationCode] = useState<number | undefined>();
    const [sportCode, setSportCode] = useState<number | undefined>();
    const [homepageUrl, setHomepageUrl] = useState<string | undefined>();

    const { codes: regionCodes } = useCodeRetrieve(CodeType.REGION);
    const { codes: genderCodes } = useCodeRetrieve(CodeType.GENDER);
    const { codes: belongedCodes } = useCodeRetrieve(CodeType.BELONGED);
    const { codes: classificationCodes } = useCodeRetrieve(CodeType.TEAM_TYPE);
    //const { codes: classificationCodes } = useCodeRetrieve(CodeType.CLASSIFICATION);
    const { codes: sportCodes } = useCodeRetrieve(CodeType.SPORT);

    useEffect(() => {
        teamService.getTeamInfo(teamNo).then((team: Team) => {
            setTeam(team);
            setTeamName(team.name);
            setRegionCode(team.regionCodeId);
            setBelongedCode(team.belongedCodeId);
            setClassificationCode(team.classificationCodeId);
            setSportCode(team.sportCodeId);
            setGenderCode(team.genderCodeId);
            setPhoneNumber(team.phoneNumber);
            setHomepageUrl(team.homepageUrl);
        });
    }, []);

    const updateEventHandler = (evt: any) => {
        let act = evt.currentTarget.id;

        if (act === 'modifyInfoBtn') {
            let team: Team = new Team();
            team.name = teamName;
            team.regionCodeId = regionCode;
            team.belongedCodeId = belongedCode;
            team.classificationCodeId = classificationCode;
            team.sportCodeId = sportCode;
            team.genderCodeId = genderCode;
            team.phoneNumber = phoneNumber;
            team.homepageUrl = homepageUrl;

            teamService.updateTeam(teamNo, team).then((data: Team) => {
                confirmAlert({
                    title: '정보 수정 성공',
                    message: '<' + data.name + '> 정보 수정에 성공 하였습니다. \n 목록으로 돌아 갑니다.',
                    buttons: [
                        {
                            label: '확인',
                            onClick: () => goBack(),
                        },
                    ],
                });
            });
        } else if (act === 'deleteInfoBtn') {
            teamService.deleteTeam(teamNo).then((data: any) => {
                if (data === 'delete') {
                    confirmAlert({
                        title: '정보 삭제 성공',
                        message: '<' + teamName + '> 정보가 삭제 되었습니다.\n 목록으로 돌아 갑니다.',
                        buttons: [
                            {
                                label: '확인',
                                onClick: () => goBack(),
                            },
                        ],
                    });
                }
            });
        }
    };

    const goBack = () => {
        let condition: TeamSearchCondition | any = location.state;
        history?.replace({
            pathname: '/admin/team',
            state: condition,
        });
    };

    const changeEventHandler = (evt: any) => {
        let id = evt.currentTarget.id;
        let value = evt.currentTarget.value;

        if (id === 'inputTeamName') {
            setTeamName(value);
        } else if (id === 'inputPhoneNumber') {
            setPhoneNumber(value);
        } else if (id === 'inputHomepageUrl') {
            setHomepageUrl(value);
        }
    };

    const goRegistryVisitedInfo = (evt: any) => {
        let stateCondition: VisitedTeamInfoSearchCondition = new VisitedTeamInfoSearchCondition();
        stateCondition.visitedTeamNo = teamNo;
        stateCondition.returnPath = '/admin/team/detail/' + teamNo;

        history?.push({
            pathname: '/admin/visited-team-info/registry',
            state: stateCondition,
        });
    };

    let title = '<' + teamName + '> 상세정보';
    return (
        <>
            <div className="flex-basic">
                {teamName ? <AdminFormTitle title={title} /> : <></>}
                <AdminTeamNav teamNo={teamNo} url={url}></AdminTeamNav>
                <button className="btn" onClick={goRegistryVisitedInfo} style={{ height: '35px', margin: '15px 5px', lineHeight: '0px' }}>
                    방문정보등록
                </button>
            </div>
            <div className="two-wrap">
                <div className="half">
                    <div className="member-list">
                        <table>
                            <colgroup>
                                <col style={{ width: '15%', backgroundColor: '#f2f2f2' }} />
                                <col style={{ width: '85%' }} />
                            </colgroup>
                            <thead>
                                <tr>
                                    <td colSpan={2} style={{ padding: '2px 0' }}>
                                        <button
                                            id="deleteInfoBtn"
                                            className="bg-red white"
                                            style={{ float: 'right', marginBottom: '5px', marginTop: '5px', fontWeight: 700 }}
                                            onClick={updateEventHandler}
                                        >
                                            삭 제
                                        </button>
                                        <button
                                            id="modifyInfoBtn"
                                            className="bg-blue white"
                                            style={{ float: 'right', marginBottom: '5px', marginTop: '5px', fontWeight: 700 }}
                                            onClick={updateEventHandler}
                                        >
                                            수 정
                                        </button>
                                        <button
                                            onClick={goBack}
                                            style={{
                                                backgroundColor: '#ffc107',
                                                color: '#3a4652',
                                                float: 'right',
                                                marginBottom: '5px',
                                                marginTop: '5px',
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            돌아가기
                                        </button>
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="txt-left blue" style={{ paddingLeft: '20px', height: '35px' }}>
                                        팀 명
                                    </td>
                                    <td className="txt-left">
                                        <input id="inputTeamName" type="text" value={teamName} onChange={changeEventHandler} />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="txt-left blue" style={{ paddingLeft: '20px', height: '35px' }}>
                                        연고지역
                                    </td>
                                    <td className="txt-left">
                                        <CodeSelectFilter
                                            initCodeId={regionCode}
                                            codes={regionCodes}
                                            allOptionTitle={'도.광역 단위 지역전체'}
                                            setCodeIdOfSearchTypeNum={setRegionCode}
                                            changeEventHandler={changeEventHandler}
                                        ></CodeSelectFilter>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="txt-left blue" style={{ paddingLeft: '20px', height: '35px' }}>
                                        분류그룹
                                    </td>
                                    <td className="txt-left">
                                        {/* {team?.belongCode?.name} */}
                                        <CodeSelectFilter
                                            initCodeId={belongedCode}
                                            codes={belongedCodes}
                                            allOptionTitle={'분류그룹 전체'}
                                            setCodeIdOfSearchTypeNum={setBelongedCode}
                                            changeEventHandler={changeEventHandler}
                                        ></CodeSelectFilter>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="txt-left blue" style={{ paddingLeft: '20px', height: '35px' }}>
                                        분 류
                                    </td>
                                    <td className="txt-left">
                                        {/* {team?.classificationCode?.name} */}
                                        <CodeSelectFilter
                                            initCodeId={classificationCode}
                                            codes={classificationCodes}
                                            allOptionTitle={'분류 전체'}
                                            setCodeIdOfSearchTypeNum={setClassificationCode}
                                            changeEventHandler={changeEventHandler}
                                        ></CodeSelectFilter>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="txt-left blue" style={{ paddingLeft: '20px', height: '35px' }}>
                                        종 목
                                    </td>
                                    <td className="txt-left">
                                        {/* {team?.sportCode?.name} */}
                                        <CodeSelectFilter
                                            initCodeId={sportCode}
                                            codes={sportCodes}
                                            allOptionTitle={'종목 전체'}
                                            setCodeIdOfSearchTypeNum={setSportCode}
                                            changeEventHandler={changeEventHandler}
                                        ></CodeSelectFilter>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="txt-left blue" style={{ paddingLeft: '20px', height: '35px' }}>
                                        팀 포함 성별
                                    </td>
                                    <td className="txt-left">
                                        {/* {team?.genderCode?.name} */}
                                        <CodeSelectFilter
                                            initCodeId={genderCode}
                                            codes={genderCodes}
                                            allOptionTitle={'팀 성별 전체'}
                                            setCodeIdOfSearchTypeNum={setGenderCode}
                                            changeEventHandler={changeEventHandler}
                                        ></CodeSelectFilter>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="txt-left blue" style={{ paddingLeft: '20px', height: '35px' }}>
                                        팀 대표번호
                                    </td>
                                    <td className="txt-left">
                                        {/* {updatePhoneNumber} */}
                                        <input id="inputPhoneNumber" type="text" value={phoneNumber} onChange={changeEventHandler} />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="txt-left blue" style={{ paddingLeft: '20px', height: '35px' }}>
                                        팀 홈페이지
                                    </td>
                                    <td className="txt-left">
                                        {/* {team?.homepageUrl} */}
                                        <input id="inputHomepageUrl" type="text" value={homepageUrl} onChange={changeEventHandler} />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                {/* <div className="half">
                    <div className="member-list">
                        <table>
                            <tbody>
                                <tr>
                                    <td className="bg-light-gray txt-left" style={{ paddingLeft: '20px' }}>
                                        <i className="fas fa-sticky-note"></i>&nbsp;&nbsp;메모
                                    </td>
                                    <td className="bg-light-gray txt-right">
                                        <input type="textarea" className="meas" placeholder="메모입력" style={{ marginBottom: '5px' }}></input>
                                        <button className="bg-blue white">수정</button>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="txt-left" style={{ paddingLeft: '20px' }}>
                                        <i className="fas fa-bell"></i>&nbsp;&nbsp;알림 메시지 발송
                                    </td>
                                    <td className="txt-right">
                                        <input
                                            style={{ marginBottom: '5px' }}
                                            className="bg-light-gray"
                                            type="text"
                                            placeholder="제목을 입력하세요"
                                        ></input>
                                        <input
                                            className="bg-light-gray"
                                            type="textarea"
                                            placeholder="내용을 입력해주세요"
                                            style={{ marginBottom: '5px' }}
                                        ></input>
                                        <button className="bg-blue white">전송</button>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="bg-light-gray txt-left" style={{ paddingLeft: '20px' }}>
                                        <i className="fas fa-sms"></i>&nbsp;&nbsp;문자 메시지 발송
                                    </td>
                                    <td className="bg-light-gray txt-right">
                                        <input type="textarea" className="meas" placeholder="내용" style={{ marginBottom: '5px' }}></input>
                                        <br />0 / 80 bytes <button className="bg-blue white">전송</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div> */}
            </div>
        </>
    );
}

export default AdminTeamInfoContainer;
