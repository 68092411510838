import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { unRegisteUserSessionAction } from '../../../moldules/UserSession';

function LogoutComponent(props: any) {
    const dispatch = useDispatch();
    dispatch(unRegisteUserSessionAction());
    window.location.replace('/rehab/login');
}

export default LogoutComponent;
