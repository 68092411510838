import Reservation from './Reservation';

class ReservationTime {
    startTime: string;
    endTime: string;

    constructor(startTime: string, endTime: string) {
        this.startTime = startTime;
        this.endTime = endTime;
    }

    isAlreadyReservationTime(prefixDay?: string, reservations?: Reservation[]): boolean {
        let isAlreadyReservation: boolean = false;

        if (prefixDay && reservations) {
            reservations.forEach((reservation: Reservation) => {
                const reservatingStartDate = `${prefixDay} ${this.startTime}`;
                const reservatingEndDate = `${prefixDay} ${this.endTime}`;

                if (reservatingStartDate == reservation.startDate && reservatingEndDate == reservation.endDate) {
                    isAlreadyReservation = true;
                }
            });
        }

        return isAlreadyReservation;
    }

    getStartDate(prefixDay: string): string {
        return `${prefixDay} ${this.startTime}`;
    }

    getEndDate(prefixDay: string): string {
        return `${prefixDay} ${this.endTime}`;
    }
}

export default ReservationTime;
