import { JsonObject, JsonProperty } from "json2typescript";
import Code from '../../code/Code';

@JsonObject("totalVisitedTeamInfo")
class TotalVisitedTeamInfoBySport {
    @JsonProperty("parentRegionCode")
    parentRegionCode?: Code;
    @JsonProperty("regionCode")
    regionCode?: Code;
    @JsonProperty("sportCode")
    sportCode?: Code;
    @JsonProperty("totalTeamVisitedCnt")
    totalTeamVisitedCnt?: number;
    @JsonProperty("teamVisitedCnt")
    teamVisitedCnt?: number;
    @JsonProperty("visitedMemberCnt")
    visitedMemberCnt?: number;
    @JsonProperty("totalVisitedMemberCnt")
    totalVisitedMemberCnt?: number;

    static getTotalVisitedTeamInfoBySportMapByRegionCodeIdAndSportCodeId(totalVisitedTeamInfos: TotalVisitedTeamInfoBySport[], totalVisitedTeamInfosTotal: TotalVisitedTeamInfoBySport[]) : Map<string, TotalVisitedTeamInfoBySport> {
        const map = new Map<string, TotalVisitedTeamInfoBySport>();
        totalVisitedTeamInfos.forEach((totalVisitedTeamInfo: TotalVisitedTeamInfoBySport) => {
            const key = totalVisitedTeamInfo.regionCode?.codeId + "/" + totalVisitedTeamInfo.sportCode?.codeId;
            map.set(key, totalVisitedTeamInfo);
        });

        totalVisitedTeamInfosTotal.forEach((totalVisitedTeamInfo: TotalVisitedTeamInfoBySport) => {
            const key = "0/" + totalVisitedTeamInfo.sportCode?.codeId;
            map.set(key, totalVisitedTeamInfo);
        })

        return map;
    }
}

export default TotalVisitedTeamInfoBySport;