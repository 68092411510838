import React from 'react';
import AdminMainTitle from '../../../../component/admin/AdminMainTitle';
import useRegionIssueEvent from '../../../../hook/event/useRegionIssueEvent';
import useStatisticCouponByEvent from '../../../../hook/event/useStatisticCouponByEvent';

function AdminEventStatusDashboardPage() {
    const regionIssuedEvents = useRegionIssueEvent();
    const couponStatusOfEvents = useStatisticCouponByEvent();
    return (
        <>
            <AdminMainTitle title="쿠폰 발급 현황"></AdminMainTitle>
            <div className="two-wrap">
                <div className="half">
                    <h5>쿠폰 사용지역별 발급 현황</h5>
                    <div className="member-list">
                        <table>
                            <colgroup>
                                <col style={{ width: '20%' }} />
                                <col style={{ width: '20%' }} />
                                <col style={{ width: '*' }} />
                            </colgroup>
                            <thead>
                                <tr>
                                    <td>쿠폰사용지역</td>
                                    <td>쿠폰발급 횟수</td>
                                    <td>총 발급쿠폰수 / 사용 쿠폰 수</td>
                                </tr>
                            </thead>
                            <tbody>
                                {regionIssuedEvents?.map((regionIssuedEvent, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{regionIssuedEvent.regionCode?.name}</td>
                                            <td>{regionIssuedEvent.eventCnt}</td>
                                            <td>
                                                {regionIssuedEvent.totalIssuedCouponCnt} 장 중/{' '}
                                                {regionIssuedEvent.usedCouponCnt ? regionIssuedEvent.usedCouponCnt : 0} 장 사용{' '}
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="half">
                    <h5>쿠폰서비스 유형 별 발급 현황</h5>
                    <div className="member-list">
                        <table>
                            <colgroup>
                                <col style={{ width: '20%' }} />
                                <col style={{ width: '20%' }} />
                                <col style={{ width: '*' }} />
                            </colgroup>
                            <thead>
                                <tr>
                                    <td>사용지역</td>
                                    <td>쿠폰서비스유형</td>
                                    <td>쿠폰서비스</td>
                                    <td>발급횟수</td>
                                </tr>
                            </thead>
                            <tbody>
                                {couponStatusOfEvents?.map((couponStatusOfEvent, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{couponStatusOfEvent.regionCode?.name}</td>
                                            <td>{couponStatusOfEvent.eventCode?.name}</td>
                                            <td>
                                                {couponStatusOfEvent.couponCode
                                                    ? couponStatusOfEvent.couponCode.name
                                                    : couponStatusOfEvent.eventCode?.name}
                                            </td>
                                            <td>{couponStatusOfEvent.cnt} 번</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AdminEventStatusDashboardPage;
