import React from 'react';

interface SearchCntProps {
    title?: string;
    searchCnt: number;
}

function SearchCnt(props: SearchCntProps = {searchCnt:0, title: '검색 결과'}) {
    return (
        <div className="output-tit">
            {/* <span>검색 결과 {props.searchCnt}개</span> */}
            <span>{props.title} {props.searchCnt}건</span>
        </div>
    )
}

export default SearchCnt;