import React, { useEffect, useState } from 'react';
import UserSearchCondition from '../../../../domain/user/param/UserSearchCondition';

interface AdminUserInfoFilterProps {
    userSearchCondition: UserSearchCondition;
    setUserSearchCondition: (condition: UserSearchCondition) => void;
}

function AdminUserInfoFilter(props: AdminUserInfoFilterProps) {
    const [searchCont, setSearchCont] = useState<string>();
    const [searchWord, setSearchWord] = useState<string>();
    const [signUpApprove, setSignUpApprove] = useState<boolean>();

    const keyEventHandler = (e: any) => {
        if (e.key == 'Enter') {
            searchClickHandler();
        }
    };

    const searchClickHandler = () => {
        const userSearchCondition = new UserSearchCondition(signUpApprove, searchCont, searchWord, props.userSearchCondition.roleGroupNames);
        props.setUserSearchCondition(userSearchCondition);
    };

    useEffect(() => {
        setSearchWord(props.userSearchCondition.searchWord);
        setSignUpApprove(props.userSearchCondition.signUpApprove);
    }, []);

    return (
        // <div className="flex-basic">
        <>
            <div className="member-join" style={{ display: 'flex', padding: '5px' }}>
                <select
                    value={signUpApprove ? 'true' : typeof signUpApprove === 'undefined' ? 'undefined' : 'false'}
                    onChange={(event) => {
                        let signUpApprove = undefined;
                        if (event.currentTarget.value !== 'undefined') {
                            signUpApprove = Boolean(event.currentTarget.value);
                        }

                        if (event.currentTarget.value === 'true' || event.currentTarget.value == 'false') {
                            signUpApprove = JSON.parse(event.currentTarget.value);
                        }
                        setSignUpApprove(signUpApprove);
                        document.getElementById('keyHandler')?.focus();
                    }}
                >
                    <option value={'undefined'}>승인상태</option>
                    <option value={'true'}>승인</option>
                    <option value={'false'}>승인대기</option>
                </select>
                {/* </div>
            <div className="member-search"> */}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {/* <select
                    id="searchCont"
                    onChange={(evt) => {
                        let cont = evt.currentTarget.value;
                        setSearchCont(cont);
                    }}
                >
                    <option value={''}>이름</option>
                    <option value={'email'}>이메일</option>
                    <option value={'mobile'}>모바일번호</option>
                </select> */}
                <input
                    onChange={(event) => {
                        const searchWord = event.currentTarget.value;
                        setSearchWord(searchWord);
                    }}
                    onKeyPress={keyEventHandler}
                    type="text"
                    placeholder="이름이나 팀 명을 입력하세요!"
                    value={searchWord}
                    style={{ width: '240px' }}
                ></input>
                <button className="bg-blue white round" onClick={searchClickHandler}>
                    <i className="fas fa-search"></i>검색
                </button>
                <input id="keyHandler" value="" onKeyPress={keyEventHandler} style={{ width: '0px', border: '0px' }} />
            </div>
            {/*member-info*/}
        </>
        // </div>
    );
}

export default AdminUserInfoFilter;
