import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import AdminMainTitle from '../../../../component/admin/AdminMainTitle';
import useChildCodeRetrieve from '../../../../hook/code/useChildCodeRetrieve';
import useCodeRetrieve from '../../../../hook/code/useCodeRetrieve';
import { CodeType } from '../../../../domain/code/CodeType';

import AdminVisitedTeamInfoFilter from '../../component/filter/AdminVisitedTeamInfoFilter';
import TotalVisitedTeamInfoSearchCondition from '../../../../domain/visitedInfo/statistics/TotalVisitedTeamInfoSearchCondition';
import VisitedTeamStatusBySportTypeTables from '../../component/VisitedTeamStatusBySportTypeTables';

import { defaultParentRegionCodeId } from '../../../../config/Constant';
import VisitedTeamInfoStatisticsServiceImpl from '../../../../service/visitedInfo/impl/VisitedTeamInfoStatisticsServiceImpl';
import VisitedMemberAccumInfo from '../../../../domain/visitedInfo/statistics/VisitedMemberAccumInfo';
import DateUtils from '../../../../utils/DateUtils';

const statisticsService: VisitedTeamInfoStatisticsServiceImpl = new VisitedTeamInfoStatisticsServiceImpl();

function AdminVisitedTeamStatusByTeamType() {
    const location = useLocation<TotalVisitedTeamInfoSearchCondition>();

    const { codes: regionCodes } = useCodeRetrieve(CodeType.REGION);
    const childRegionCodes = useChildCodeRetrieve(defaultParentRegionCodeId);
    const { codes: belongCodes } = useCodeRetrieve(CodeType.BELONGED);
    const { codes: sportsCodes } = useCodeRetrieve(CodeType.SPORT);
    const { codes: teamTypeCodes } = useCodeRetrieve(CodeType.TEAM_TYPE);

    let condition = location.state ? location.state : new TotalVisitedTeamInfoSearchCondition();

    const [searchCondition, setSearchCondition] = useState<TotalVisitedTeamInfoSearchCondition>(condition);
    const [vistedStatusBySportType, setVisitedStatusBySportType] = useState<VisitedMemberAccumInfo[]>();
    const [visitedTotalStatusBySportType, setVisitedTotalStatusBySportType] = useState<VisitedMemberAccumInfo>();
    useEffect(() => {
        setSearchCondition(condition);
    }, []);

    useEffect(() => {
        statisticsService.getVisitedTeamStatusBySportType(searchCondition).then((data) => {
            setVisitedStatusBySportType(data);
            let typeTotal: any = new VisitedMemberAccumInfo();

            typeTotal.sportTypeCnt = data.length;
            data.map((typeInfo) => {
                typeTotal.teamCnt ? (typeTotal.teamCnt += typeInfo.teamCnt) : (typeTotal.teamCnt = typeInfo.teamCnt);
                typeTotal.teamVisitedCnt
                    ? (typeTotal.teamVisitedCnt += typeInfo.teamVisitedCnt)
                    : (typeTotal.teamVisitedCnt = typeInfo.teamVisitedCnt);

                typeTotal.visitedMemberCnt
                    ? (typeTotal.visitedMemberCnt += typeInfo.visitedMemberCnt)
                    : (typeTotal.visitedMemberCnt = typeInfo.visitedMemberCnt);
                typeTotal.visitedDay ? (typeTotal.visitedDay += typeInfo.visitedDay) : (typeTotal.visitedDay = typeInfo.visitedDay);

                typeTotal.visitedMemberCntOfYear
                    ? (typeTotal.visitedMemberCntOfYear += typeInfo.visitedMemberCntOfYear)
                    : (typeTotal.visitedMemberCntOfYear = typeInfo.visitedMemberCntOfYear);

                typeTotal.firstVisitedDate = typeTotal.firstVisitedDate
                    ? DateUtils.getCompareDate(typeTotal.firstVisitedDate, typeInfo.firstVisitedDate) === 1
                        ? typeInfo.firstVisitedDate
                        : typeTotal.firstVisitedDate
                    : typeInfo.firstVisitedDate;
                typeTotal.lastVisitedDate = typeTotal.lastVisitedDate
                    ? DateUtils.getCompareDate(typeTotal.lastVisitedDate, typeInfo.lastVisitedDate) === 2
                        ? typeInfo.lastVisitedDate
                        : typeTotal.lastVisitedDate
                    : typeInfo.lastVisitedDate;
            });

            setVisitedTotalStatusBySportType(typeTotal);
        });
    }, [searchCondition]);

    return (
        <>
            <AdminMainTitle title="종목 별 유치 현황">
                <AdminVisitedTeamInfoFilter
                    regionCodes={regionCodes}
                    childRegionCodes={childRegionCodes}
                    belongCodes={belongCodes}
                    classificationCodes={teamTypeCodes}
                    sportCodes={sportsCodes}
                    setSearchCondition={setSearchCondition}
                ></AdminVisitedTeamInfoFilter>
            </AdminMainTitle>
            <VisitedTeamStatusBySportTypeTables
                sportsCodes={sportsCodes}
                searchCondition={searchCondition}
                visitedTotalStatusBySportType={visitedTotalStatusBySportType}
                visitedStatusBySportType={vistedStatusBySportType}
            ></VisitedTeamStatusBySportTypeTables>
        </>
    );
}

export default AdminVisitedTeamStatusByTeamType;
