import Authority from '../domain/user/Authority';
class RoleNameUtils {
    static getRoleName(auths: Authority[]): string {
        let map: any = {};
        auths.map((auth: Authority) => {
            let role: string = auth.roles?.roleName ? auth.roles?.roleName : 'TEAM_PLAYER';
            //map[auth.authority] = auth.roles?.roleName;
            map[role] = role;
        });

        let roleName = '';

        if (map.hasOwnProperty('ROLE_USER')) {
            roleName = '일반 사용자';
        }

        if (map.hasOwnProperty('TEAM_PLAYER')) {
            roleName = '팀 선수';
        }

        if (map.hasOwnProperty('TEAM_OPER')) {
            roleName = '팀 관리자';
        }
        if (map.hasOwnProperty('SUPER_ADMIN')) {
            roleName = '최고 운영자';
        }

        return roleName;
    }
}

export default RoleNameUtils;
