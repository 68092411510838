import Code from '../domain/code/Code';
import { useDispatch } from 'react-redux';
import InfraServiceImpl from '../service/infra/impl/InfraServiceImpl';
import { renewInfraAction } from './InfraList';

const ADD_FILTER = 'filter/ADD' as const;
const ADD_DETAIL_FILTER = 'filter/DETAIL_ADD' as const;
const DELETE_FILTER = 'filter/DELETE' as const;

export const addFilterAction = (filterItem: number) => ({type: ADD_FILTER, payload: filterItem});
export const addDetailFilterACtion = (code: Code) => ({type: ADD_DETAIL_FILTER});
export const deleteFilterAction = (filterItem: number) => ({type: DELETE_FILTER, payload: filterItem});

type FilterItemAction = 
    | ReturnType<typeof addFilterAction>
    | ReturnType<typeof addDetailFilterACtion>
    | ReturnType<typeof deleteFilterAction>;

type FilterItemState = {
    filterItems: Array<number>;
    detailFilterItems: Object|any;
}

/**
 * 'sports': {
 *      '6': false
 *  }
 */
const initialState: FilterItemState = {
    filterItems: [],
    detailFilterItems: {}
}

function filterItemAction(state: FilterItemState = initialState, action: FilterItemAction) {
    switch(action.type) {
        case ADD_FILTER:
            return {filterItems: state.filterItems.concat(action.payload)}
        case DELETE_FILTER:
            return state;
        case ADD_DETAIL_FILTER:
            let curState = state.detailFilterItems;
            
            return state;
        default: 
            return state;
    }
}


function detailInfoAdd(detailFilterItems: any, code: Code) {
    let codeId = code.codeId;
    
}

export default filterItemAction;