import React, { useEffect, useState } from 'react';
import Authority from '../../../domain/user/Authority';

function StatusComponent() {
    return (
        <div className="content-wrap">
            <div className="sub_contents">
                <div className="snb">
                    <h2>방문스포츠 팀 현황</h2>
                    <ul>
                        <li className="menu_on">
                            <a href="/status">지역별 현황</a>
                        </li>
                        <li>
                            <a href="/status/support-detail">지역별 지원내용</a>
                        </li>
                    </ul>
                </div>

                <div className="contents_ad">
                    <div className="page_tle" style={{ marginBottom: '35px' }}>
                        <h3>지역별 현황</h3>
                        <div className="page_nav">
                            <ul>
                                <li>
                                    <a href="/iis/web/main.do">홈</a>
                                </li>
                                <li>
                                    <a href="/status">방문스포츠팀 현황</a>
                                </li>
                                <li>
                                    <a href="/status">지역별 현황</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div id="board_list">
                        <div className="board" style={{ paddingTop: '5px', textAlign: 'center', overflowX: 'auto' }}>
                            <img src="/assets/image/status/2022_Status.png"></img>
                            <img src="/assets/image/status/2021_Status.png"></img>
                            <img src="/assets/image/status/2020_Status.png"></img>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default StatusComponent;
