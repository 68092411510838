import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { RootState } from '../../../moldules/index';

function AdminMainSider() {
    const { user, hasSystemAuth, hasMunicipalityAuth } = useSelector((state: RootState) => state.userSessionReducer);
    const [teamActive, setTeamActive] = useState<string>();
    const [memberActive, setMemberActive] = useState<string>();
    const [managerActive, setManagerActive] = useState<string>();
    const [infraActive, setInfraActive] = useState<string>();
    const [facilityActive, setFacilityActive] = useState<string>();
    const [visitedActive, setVisitedActive] = useState<string>();
    const [statusActive, setStatusActive] = useState<string>();
    const [periodActive, setPeriodActive] = useState<string>();
    const [typeActive, setTypeActive] = useState<string>();
    const [clubActive, setClubActive] = useState<string>();
    const [cStatusActive, setCStatusActive] = useState<string>();
    const [rStatusActive, setRStatusActive] = useState<string>();
    const [eventActive, setEventActive] = useState<string>();
    const [eStatusActive, setEStatusActive] = useState<string>();

    const location = useLocation();

    useEffect(() => {
        let path = location.pathname;

        setTeamActive(path.indexOf('/admin/team') >= 0 ? 'active' : '');
        setMemberActive(path.indexOf('/admin/member') >= 0 ? 'active' : '');
        setManagerActive(path.indexOf('/admin/manager') >= 0 ? 'active' : '');
        setInfraActive(path.indexOf('/admin/infra') >= 0 ? 'active' : '');
        setFacilityActive(path.indexOf('/admin/facility') >= 0 ? 'active' : '');

        setVisitedActive(
            path.indexOf('/admin/visited-team-info/team-type/status-dashboard') == -1 &&
                path.indexOf('/admin/visited-team-info/period/status-dashboard') == -1 &&
                path.indexOf('/admin/visited-team-info/status-dashboard') == -1 &&
                path.indexOf('/admin/visited-team-info') >= 0
                ? 'active'
                : '',
        );
        setStatusActive(path.indexOf('/admin/visited-team-info/status-dashboard') >= 0 ? 'active' : '');
        setTypeActive(path.indexOf('/admin/visited-team-info/team-type/status-dashboard') >= 0 ? 'active' : '');
        setPeriodActive(path.indexOf('/admin/visited-team-info/period/status-dashboard') >= 0 ? 'active' : '');

        setClubActive(
            path.indexOf('/admin/club-team-info/sports/status-dashboard') == -1 &&
                path.indexOf('/admin/club-team-info/region/status-dashboard') == -1 &&
                path.indexOf('/admin/club-team-info') >= 0
                ? 'active'
                : '',
        );
        setCStatusActive(path.indexOf('/admin/club-team-info/sports/status-dashboard') >= 0 ? 'active' : '');
        setRStatusActive(path.indexOf('/admin/club-team-info/region/status-dashboard') >= 0 ? 'active' : '');

        setEventActive(path.indexOf('/admin/event/status-dashboard') == -1 && path.indexOf('/admin/event') >= 0 ? 'active' : '');
        setEStatusActive(path.indexOf('/admin/event/status-dashboard') >= 0 ? 'active' : '');
    }, [location]);
    return (
        <div id="sidebar" className="admin-sidebar">
            <div className="nav" role="navigation">
                <ul className="nav__list">
                    {hasSystemAuth ? (
                        <li>
                            <input id="group-1" type="checkbox" hidden />
                            <label htmlFor="group-1">
                                <span className="fa fa-angle-right"></span>
                                <i className="fas fa-hockey-puck"></i>사용자
                            </label>
                            <ul className="group-list">
                                <li>
                                    <Link to="/admin/team" className={teamActive}>
                                        <i className="fas fa-angle-double-right"></i>&nbsp;&nbsp;팀 관리
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/admin/members" className={memberActive}>
                                        <i className="fas fa-angle-double-right"></i>&nbsp;&nbsp;회원관리
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/admin/manager/list" className={managerActive}>
                                        <i className="fas fa-angle-double-right"></i>&nbsp;&nbsp;운영자관리
                                    </Link>
                                </li>
                            </ul>
                        </li>
                    ) : (
                        <></>
                    )}
                    <li>
                        <input id="group-2" type="checkbox" hidden />
                        <label htmlFor="group-2">
                            <i className="fas fa-hockey-puck"></i>
                            <span className="fa fa-angle-right"></span>시설관리
                        </label>
                        <ul className="group-list">
                            <li>
                                <Link to="/admin/infra/list" replace={true} className={infraActive}>
                                    <i className="fas fa-angle-double-right"></i>&nbsp;&nbsp;스포츠시설 관리
                                </Link>
                            </li>
                            <li>
                                <Link to="/admin/facility/list" replace={true} className={facilityActive}>
                                    <i className="fas fa-angle-double-right"></i>&nbsp;&nbsp;부대시설 관리
                                </Link>
                            </li>
                        </ul>
                    </li>

                    <li>
                        <input id="group-5" type="checkbox" hidden />
                        <label htmlFor="group-5">
                            <i className="fas fa-hockey-puck"></i>
                            <span className="fa fa-angle-right"></span>
                            방문 팀 정보 관리
                        </label>
                        <ul className="group-list">
                            <li>
                                <Link to="/admin/visited-team-info" className={visitedActive}>
                                    <i className="fas fa-angle-double-right"></i>&nbsp;&nbsp;방문 팀 정보
                                </Link>
                                {hasSystemAuth || hasMunicipalityAuth ? (
                                    <>
                                        <Link to="/admin/visited-team-info/period/status-dashboard" className={periodActive}>
                                            <i className="fas fa-angle-double-right"></i>&nbsp;&nbsp;기간 별 유치 현황
                                        </Link>
                                        <Link to="/admin/visited-team-info/status-dashboard" className={statusActive}>
                                            <i className="fas fa-angle-double-right"></i>&nbsp;&nbsp;종목 별 유치 현황
                                        </Link>
                                        <Link to="/admin/visited-team-info/team-type/status-dashboard" className={typeActive}>
                                            <i className="fas fa-angle-double-right"></i>&nbsp;&nbsp;팀 유형 별 유치 현황
                                        </Link>
                                    </>
                                ) : (
                                    <></>
                                )}
                            </li>
                        </ul>
                    </li>
                    <li>
                        <input id="group-6" type="checkbox" hidden></input>
                        <label htmlFor="group-6">
                            <i className="fas fa-hockey-puck"></i>
                            <span className="fa fa-angle-right"></span>
                            동호회 정보 관리
                        </label>
                        <ul className="group-list">
                            <li>
                                <Link to="/admin/club-team-info" className={clubActive}>
                                    <i className="fas fa-angle-double-right"></i>&nbsp;&nbsp;동호회 정보 현황
                                </Link>
                                {hasSystemAuth || hasMunicipalityAuth ? (
                                    <>
                                        <Link to="/admin/club-team-info/sports/status-dashboard" className={cStatusActive}>
                                            <i className="fas fa-angle-double-right"></i>&nbsp;&nbsp;종목별 동호회 유치 현황
                                        </Link>
                                        <Link to="/admin/club-team-info/region/status-dashboard" className={rStatusActive}>
                                            <i className="fas fa-angle-double-right"></i>&nbsp;&nbsp;지역별 동호회 유치 현황
                                        </Link>
                                    </>
                                ) : (
                                    <></>
                                )}
                            </li>
                        </ul>
                    </li>
                    {hasSystemAuth ? (
                        <li>
                            <input id="group-4" type="checkbox" hidden />
                            <label htmlFor="group-4">
                                <i className="fas fa-hockey-puck"></i>
                                <span className="fa fa-angle-right"></span>
                                행사관리
                            </label>
                            <ul className="group-list">
                                <li>
                                    <Link to="/admin/event" className={eventActive}>
                                        <i className="fas fa-angle-double-right"></i>&nbsp;&nbsp;쿠폰관리
                                    </Link>
                                    <Link to="/admin/event/status-dashboard" className={eStatusActive}>
                                        <i className="fas fa-angle-double-right"></i>&nbsp;&nbsp;쿠폰발급현황
                                    </Link>
                                </li>
                            </ul>
                        </li>
                    ) : (
                        <></>
                    )}
                </ul>{' '}
                {/*nav__list */}
            </div>
            {/* sidebar */}
        </div>
    );
}

export default AdminMainSider;
