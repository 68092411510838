import ClubTeamInfoStatisticsService from '../ClubTeamInfoStatisticsService';
import AbstractService from '../../AbstractService';
import ClubTeamInfoStatistics from '../../../domain/clubteam/ClubTeamInfoStatistics';
import ClubTeamInfoSearchCondition from '../../../domain/clubteam/param/ClubTeamInfoSearchCondition';
import APIServerConfig from '../../../config/APIServerConfig';

class ClubTeamInfoStatisticsServiceImpl extends AbstractService implements ClubTeamInfoStatisticsService {
    getAccumInfoBySport(searchCondition: ClubTeamInfoSearchCondition): Promise<ClubTeamInfoStatistics[]> {
        return new Promise((resolve, reject) => {
            APIServerConfig.recordAPIAxios
                .get(`/club-team-info/statistics/accum-info-by-sport`, {
                    params: searchCondition,
                })
                .then((response) => {
                    const result = this.jsonConvert.deserializeArray(response.data, ClubTeamInfoStatistics);
                    resolve(result);
                });
        });
    }
    getAccumInfoByRegionAndSport(searchCondition: ClubTeamInfoSearchCondition): Promise<ClubTeamInfoStatistics[]> {
        return new Promise((resolve, reject) => {
            APIServerConfig.recordAPIAxios
                .get(`/club-team-info/statistics/accum-info-by-region-sport`, {
                    params: searchCondition,
                })
                .then((response) => {
                    const result = this.jsonConvert.deserializeArray(response.data, ClubTeamInfoStatistics);
                    resolve(result);
                });
        });
    }

    getClubStatusByRegion(searchCondition: ClubTeamInfoSearchCondition): Promise<ClubTeamInfoStatistics[]> {
        return new Promise((resolve, reject) => {
            APIServerConfig.recordAPIAxios
                .get(`/club-team-info/statistics/byRegion`, {
                    params: searchCondition,
                })
                .then((response) => {
                    const result = this.jsonConvert.deserializeArray(response.data, ClubTeamInfoStatistics);
                    resolve(result);
                });
        });
    }

    getClubDetailStatusByRegion(searchCondition: ClubTeamInfoSearchCondition): Promise<ClubTeamInfoStatistics[]> {
        return new Promise((resolve, reject) => {
            APIServerConfig.recordAPIAxios
                .get(`/club-team-info/statistics/detail/byRegion`, {
                    params: searchCondition,
                })
                .then((response) => {
                    const result = this.jsonConvert.deserializeArray(response.data, ClubTeamInfoStatistics);
                    resolve(result);
                });
        });
    }
    getClubStatusBySport(searchCondition: ClubTeamInfoSearchCondition): Promise<ClubTeamInfoStatistics[]> {
        return new Promise((resolve, reject) => {
            APIServerConfig.recordAPIAxios
                .get(`/club-team-info/statistics/bySports`, {
                    params: searchCondition,
                })
                .then((response) => {
                    const result = this.jsonConvert.deserializeArray(response.data, ClubTeamInfoStatistics);
                    resolve(result);
                });
        });
    }
    getClubDetailStatusBySport(searchCondition: ClubTeamInfoSearchCondition): Promise<ClubTeamInfoStatistics[]> {
        return new Promise((resolve, reject) => {
            APIServerConfig.recordAPIAxios
                .get(`/club-team-info/statistics/detail/bySports`, {
                    params: searchCondition,
                })
                .then((response) => {
                    const result = this.jsonConvert.deserializeArray(response.data, ClubTeamInfoStatistics);
                    resolve(result);
                });
        });
    }
}

export default ClubTeamInfoStatisticsServiceImpl;
