import { useState } from 'react';
import RetrieveUser from '../../domain/user/RetrieveUser';
import UserModifyParam from '../../domain/user/param/UserModifyParam';
import { USER_SERVICE } from '../../service/index';
function useRoleUpdate(user?: RetrieveUser) {
    const [roleStateUser, setRoleStateUser] = useState<RetrieveUser>();
    
    const roleUpdateHandler = (roleNo: number) => {
        if(user) {
            user.authorities[0].roleNo = roleNo;
            let modifyParam = UserModifyParam.getInstance(user, undefined);
            USER_SERVICE.updateUserInfo(modifyParam)
            .then(() => {
                const newState = Object.setPrototypeOf(Object.assign({}, user), RetrieveUser.prototype);
                setRoleStateUser(newState);
            });
        }
        console.log(user);
    }

    return {
        roleUpdateHandler, roleStateUser
    }
}

export default useRoleUpdate;