import React from 'react';
import Infra from '../../../../../domain/infra/Infra';
import BoardCard from '../../../../../component/board/BoardCard';
import useTeamStoryStoryBoard from '../hook/useTeamStoryStoryBoard';
import Board from '../../../../../domain/board/Board';
import useReservationOfTeam from '../../../../../hook/useReservationOfTeam';
import { Link } from 'react-router-dom';
import Paging from '../../../../../domain/common/Paging';
import ReservationSearchCondition from '../../../../../domain/reservation/param/ReservationSearchCondition';
import { InfraCategoryType } from '../../../../../domain/infra/InfraCategoryType';
interface TeamStoryHomeTabProps {
    teamNo: string; 
    boardOfTeam?: Board[];
}
function TeamStoryHomeTab(props: TeamStoryHomeTabProps) {
    const notiBoardContents = useTeamStoryStoryBoard(props.teamNo, props.boardOfTeam);
    const paging = new Paging(0, 9);
    const searchCondition = new ReservationSearchCondition();
    searchCondition.teamNos = props.teamNo;
    searchCondition.parentInfraCategoryNo = String(InfraCategoryType.SPORT_INFRA_NO);
    const {reservations} = useReservationOfTeam(props.teamNo, searchCondition, paging);
    
    return (
        <>
            <div className="notice-zone">
                <ul>
                    {
                        notiBoardContents.map((boardContent, index) => {
                            return <BoardCard key={index} notiYn={true} content={boardContent.content} regiDate={boardContent.getYYYYMMDD(boardContent.registeDate)}></BoardCard>
                        })
                    }
                    {
                        notiBoardContents.map((boardContent, index) => {
                            return <BoardCard key={index} notiYn={true} content={boardContent.content} regiDate={boardContent.getYYYYMMDD(boardContent.registeDate)}></BoardCard>
                        })
                    }
                </ul>
                <div className="clear"></div>
            </div>
            <div className="clear"></div>
            <div className="home-wrap">
                <div className="home-title">
                    <span>전지훈련시설</span>
                    {/* <span>전체보기</span> */}
                </div>
                <div className="clear"></div>
                <div className="home-box">
                    <ul className="search-room"> 
                        {
                            reservations?
                                reservations.length > 0 ? 
                                    reservations?.map((reservation, index) => {
                                        if(reservation.infra) {
                                            const infra: Infra = Object.setPrototypeOf(reservation.infra, Infra.prototype);
                                            return (
                                                <li>
                                                    <Link to={infra.getDetailLink()}>
                                                        <div className="">
                                                            <img src={infra.getMainAttachFile()}></img>
                                                        </div>
                                                        <div className="">
                                                            <span>{infra.name}</span><br></br>
                                                            {infra.address}
                                                        </div>								
                                                    </Link>
                                                </li>
                                            )
                                        }
                                    })
                                    :
                                    <h5>현재 팀의 예약된 전지훈련 시설 정보가 없습니다.</h5>
                                :
                                <></>
                        }
                    </ul>
                    <div className="clear"></div>
                </div>
            </div>
            <div className="clear"></div>
        </>
    )
}

export default TeamStoryHomeTab;