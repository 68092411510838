import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import ActionComponent from '../ActionComponent';
import ResultFormComponent from './forms/ResultFormComponent';
import PrescriptionFormComponent from './forms/PrescriptionFormComponent';
import ReportingComponent from './forms/ReportingFormComponent';
import TargetPerson from '../../../domain/TargetPerson';
import Prescription from '../../../domain/Prescription';
import PrescriptionServiceImpl from '../../../service/impl/PrescriptionServiceImpl';
import { confirmAlert } from 'react-confirm-alert';
import ClinicalChartReportData from '../../../../../domain/report/ClinicalChartReportData';
import ExercisePrescriptionReportData from '../../../../../domain/report/ExercisePrescriptionReportData';
import ReportDataParam from '../../../../../domain/report/param/ReportDataParam';
import ReportTypeEnum from '../../../../../domain/report/param/ReportTypeEnum';
import useReportSave from '../../../../../hook/report/useReportSave';
import useReportOfOwner from '../../../../../hook/report/useReportOfOwner';
import useReportUpdate from '../../../../../hook/report/useReportUpdate';
import ReportServiceImpl from '../../../../../service/report/impl/ReportServiceImpl';
import RehabTargetHistory from '../../../domain/RehabTargetHistory';
import RehabHistoryServiceImpl from '../../../service/impl/RehabHistoryServiceImpl';
import BaseReportData from '../../../../../domain/report/BaseReportData';
import DateUtils from '../../../../../utils/DateUtils';

interface IProps {
    selectedTarget: TargetPerson;
    closingHandler: () => void;
}

const rehabHistoryService = new RehabHistoryServiceImpl();
const reportService = new ReportServiceImpl();

const CompletePopupComponent = (props: IProps) => {
    const targetPerson = props.selectedTarget;
    const [rehabHistory, setRehabHistory] = useState<RehabTargetHistory[]>();
    const [selectedHistory, setSelectedHistory] = useState<RehabTargetHistory>(new RehabTargetHistory());
    const [selectedHistoryNo, setSelectedHistoryNo] = useState<number>(0);
    const [historyOptions, setHistoryOptions] = useState(<></>);

    const [reportDataParam, setReportDataParam] = useState<ReportDataParam>();
    const { savedReport, setSavedReport } = useReportSave(reportDataParam);
    const { report } = useReportOfOwner(targetPerson.targetNo, targetPerson.reservaterNo);

    useEffect(() => {
        rehabHistoryService.getRehabHistoryForTarget(targetPerson.targetNo).then((results: RehabTargetHistory[]) => {
            let size = results.length;
            const findTarget = results[0];
            setSelectedHistory(findTarget ? findTarget : new RehabTargetHistory());
            if (findTarget) setSelectedHistoryNo(findTarget.historyNo);
            setRehabHistory(results);
        });
    }, []);

    useEffect(() => {
        if (rehabHistory) {
            let size = rehabHistory.length;
            let options = <></>;
            if (size > 0) {
                options = (
                    <>
                        {rehabHistory?.map((item, idx) => {
                            let displayTit = item.getRegDate();
                            return (
                                <option key={idx} value={item.historyNo}>
                                    {displayTit}
                                </option>
                            );
                        })}
                    </>
                );
            } else {
                options = (
                    <>
                        <option>이력 정보가 없습니다.</option>
                    </>
                );
            }
            setHistoryOptions(options);
        }
    }, [rehabHistory]);

    const tabHandler = (evt: any) => {
        var actions = ActionComponent();
        let target = evt.target.id;

        if (target === 'tab-btn1') {
            actions.tab1Open();
        } else if (target === 'tab-btn2') {
            actions.tab2Open();
        } else if (target === 'tab-btn3') {
            if (savedReport) {
                actions.tab3Open();
            } else {
                alert('생성된 리포팅 파일이 없습니다.');
            }
        }
    };

    //기존 리포팅 가져오면 saved에 채워줘야함
    useEffect(() => {
        if (report) {
            if (report.reportPdfFilePath) {
                setSavedReport(report);
            }
        }
    }, [report]);

    const repotingDataMaker = () => {
        if (!selectedHistory) alert('운동 처방 내용을 확인해주세요.');
        else {
            const physicalStatus = selectedHistory.physicalStatus;
            const basalPhysical = selectedHistory.basalPhysical;
            const specialPhysical = selectedHistory.specialPhysical;
            console.log(selectedHistory.basalExercisePrescription);
            const basalExercisePrescription = JSON.parse(selectedHistory.basalExercisePrescription);
            const bodyPrescription = JSON.parse(selectedHistory.bodyPrescription);
            const conditioningPrescription = JSON.parse(selectedHistory.conditioningPrescription);
            const baseReportData = new BaseReportData(targetPerson, selectedHistory);
            
            const clinicalChartReportData: ClinicalChartReportData = new ClinicalChartReportData(physicalStatus, basalPhysical, specialPhysical);
            const exercisePrescriptionData: ExercisePrescriptionReportData = new ExercisePrescriptionReportData(
                basalExercisePrescription,
                conditioningPrescription,
            );
            
            if (targetPerson.reservaterNo && targetPerson.targetNo) {
                const reportDataParam: ReportDataParam = new ReportDataParam(
                    targetPerson.reservaterNo,
                    targetPerson.targetNo,
                    ReportTypeEnum.CLINICAL_CHART,
                    Object.assign(clinicalChartReportData, exercisePrescriptionData, baseReportData),
                );

                setReportDataParam(reportDataParam);
            }
        }
    };

    const reportReleaseHandler = (release: boolean) => {
        if (savedReport) {
            //전송 처리 로직
            savedReport.reportRelease = release;
            reportService.updateReport(savedReport).then((report) => {
                setSavedReport(report);
            });
        } else {
            alert('전송 할 처방지가 생성되지 않았습니다.');
        }
    };

    const changeHistoryHandler = function (evt: any) {
        let target = evt.currentTarget;

        let selectedVal = target.value;

        let selected = rehabHistory?.find((item) => {
            return item.historyNo == selectedVal;
        });

        if (selected) {
            setSelectedHistoryNo(selected.historyNo);
            setSelectedHistory(selected);
        }
    };

    return (
        <>
            <div className="center-popup wow  animate__animated animate__fadeInUp" data-wow-delay="0.2s">
                <div className="center-popup-title">
                    <h1 className="center">재활 관리</h1>
                    <div className="flex">
                        <h1 className="center">
                            <i className="fas fa-user-tie"></i> {targetPerson?.reservater?.name}
                        </h1>
                        &nbsp;
                        <span className="center-birth">
                            {targetPerson?.reservater?.genderCode?.name} / {targetPerson?.reservater?.birthdate}
                        </span>
                    </div>
                    <div>
                        <button className="center-button-close" onClick={props.closingHandler} id="closingPrePopup">
                            닫기 <i className="fas fa-times-circle"></i>
                        </button>
                    </div>
                </div>
                <div className="center-popup-check">
                    <p className="center-popup-tit">
                        <i className="fas fa-history"></i> &nbsp;상태확인 이전 내역 &nbsp;
                        <select value={selectedHistoryNo} onChange={changeHistoryHandler}>
                            {historyOptions}
                        </select>
                    </p>
                </div>
                <div className="center-popup-cont">
                    <div className="center-popup-bnt">
                        <a href="#" id="tab-btn1" className="on" onClick={tabHandler}>
                            상담 / 측정 정보
                        </a>
                        <a href="#" id="tab-btn2" onClick={tabHandler}>
                            운동처방 정보
                        </a>
                        <a href="#" id="tab-btn3" onClick={tabHandler}>
                            리포트 관리
                        </a>
                    </div>
                    <div id="center-popup-list" className="center-popup-complete-list-tab  center-bg-gray">
                        <ResultFormComponent targetPerson={targetPerson} selectedItem={selectedHistory}></ResultFormComponent>
                    </div>

                    <div id="center-popup-list2" className="center-popup-complete-list-tab">
                        {/*운동 처방 정보 확인 영역 */}
                        <PrescriptionFormComponent targetPerson={targetPerson} selectedItem={selectedHistory}></PrescriptionFormComponent>
                    </div>

                    <div id="center-popup-list3" className="center-popup-complete-list-tab">
                        {/*리포트 관리 영역 */}
                        <ReportingComponent savedFilePath={savedReport?.reportPdfFilePath}></ReportingComponent>
                    </div>
                    <div className="center-controller flex-basic">
                        <div className="flex">
                            <button className="center-button-print" onClick={repotingDataMaker}>
                                <i className="fas fa-pring"></i>리포트 생성
                            </button>
                            {/* {savedReport ? (
                                <button className="center-button-esave">
                                    <i className="fas fa-file-excel"></i> 엑셀파일 저장
                                </button>
                            ) : (
                                <></>
                            )} */}
                        </div>
                        <div>
                            {savedReport ? (
                                savedReport.reportRelease ? (
                                    <button className="center-button-resultsend" onClick={() => reportReleaseHandler(false)}>
                                        <i className="fas fa-paper-plane"></i> 처방지 전송 취소
                                    </button>
                                ) : (
                                    <button className="center-button-print" onClick={() => reportReleaseHandler(true)}>
                                        <i className="fas fa-paper-plane"></i> 처방지 전송
                                    </button>
                                )
                            ) : (
                                <></>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CompletePopupComponent;
