import { JsonObject, JsonProperty } from 'json2typescript';
import Code from '../code/Code';
import InfraCategory from '../infra/InfraCategory';
import Team from '../team/Team';
import UserDetailInfo from '../user/UserDetailInfo';

@JsonObject('event')
class Event {
    @JsonProperty('registerNo')
    registerNo?: string;
    @JsonProperty('register')
    register?: UserDetailInfo;
    @JsonProperty('eventNo')
    eventNo?: string;
    @JsonProperty('targetTeam')
    targetTeam?: string;
    @JsonProperty('targetTeamInfo')
    targetTeamInfo?: Team;
    @JsonProperty('content')
    content?: string;
    @JsonProperty('parentRegionCodeId')
    parentRegionCodeId?: number;
    @JsonProperty('parentRegionCode')
    parentRegionCode?: Code;
    @JsonProperty('regionCodeId')
    regionCodeId?: number;
    @JsonProperty('regionCode')
    regionCode?: Code;
    @JsonProperty('eventCodeId')
    eventCodeId?: number;
    @JsonProperty('eventCode')
    eventCode?: Code;
    @JsonProperty('couponCodeId')
    couponCodeId?: number;
    @JsonProperty('couponCode')
    couponCode?: Code;
    @JsonProperty('eventTargetInfraCategoryNo')
    eventTargetInfraCategoryNo?: string;
    @JsonProperty('infraCategory')
    infraCategory?: InfraCategory;
    @JsonProperty('registeDate')
    registeDate?: string;
    @JsonProperty('startTime')
    startTime?: string;
    @JsonProperty('expiredTime')
    expiredTime?: string;
    @JsonProperty('issuedCouponCnt')
    issuedCouponCnt?: number;
    @JsonProperty('usedCouponCnt')
    usedCouponCnt?: number;
    @JsonProperty('couponValue')
    couponValue?: number;
    @JsonProperty('deleteYn')
    deleteYn?: boolean;
}

export default Event;
