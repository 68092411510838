import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('physicalStatus')
class PhysicalStatus {
    @JsonProperty('height')
    height?: string;
    @JsonProperty('weight')
    weight?: string;
    @JsonProperty('hBloodPressure')
    hBloodPressure?: string;
    @JsonProperty('lBloodPressure')
    lBloodPressure?: string;
    // @JsonProperty("pluse")
    // pluse?: string;
}

export default PhysicalStatus;
