import React, { useState, useEffect } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import BasalPhysical from '../../domain/recording/BasalPhysical';
import RehabTargetHistory from '../../domain/RehabTargetHistory';
import RehabHistoryServiceImpl from '../../service/impl/RehabHistoryServiceImpl';

interface IProps {
    status: BasalPhysical;
    history: RehabTargetHistory;
    // handler: (evt: any) => void;
}
const rehabHistoryService = new RehabHistoryServiceImpl();
export default function BasalPhysicalForm(props: IProps) {
    const [basalPhysical, setBasalPhysical] = useState<BasalPhysical>(new BasalPhysical());

    useEffect(() => {
        if (props.status) {
            setBasalPhysical(props.status);
        }
    }, [props.status]);

    let changeHandler = (evt: any) => {
        let target = evt.target.id;
        let val = evt.target.value;
        if (isNaN(val)) {
            confirmAlert({
                title: '경고',
                message: '숫자만 입력 가능 합니다.',
                buttons: [{ label: '확인', onClick: () => {} }],
            });
        } else {
            setBasalPhysical((prevState) => {
                let recording: [any] = [val];
                return { ...prevState, [target]: recording };
            });
        }
    };

    const storeHandler = (evt: any) => {
        let history: RehabTargetHistory = props.history;
        history.basalPhysical = JSON.stringify(basalPhysical);
        rehabHistoryService.manageBasalPhysicalForTarget(history).then((result: RehabTargetHistory) => {
            confirmAlert({
                title: '저장 성공',
                message: '기초체력 정보를 저장 하였습니다.',
                buttons: [{ label: '확인', onClick: () => {} }],
            });
        });
    };
    return (
        <>
            {/* <div id="list-tab" className="center-popup-list-tab"> */}
            <div className="center-popup-title">
                <h1 className="center">
                    <i className="fa fa-check"></i> 기초체력측정
                </h1>

                <div>
                    <button id="complete" className="center-button-complete" onClick={storeHandler}>
                        저 장 <i className="fas fa-file-download"></i>
                    </button>
                </div>
            </div>
            <div id="center-popup-list" className="center-popup-list-tab">
                <div className="center-popup-list-box">
                    <div className="flex-basic">
                        <div className="center-half">
                            <h2 className="center2">악력</h2>
                            <div className="center-bg-gray flex">
                                <div className="center-bg-blue">1회차</div>
                                <input
                                    className="center-input-gray center-w120"
                                    type="text"
                                    id="graspingPower"
                                    value={basalPhysical.graspingPower[0]}
                                    onChange={changeHandler}
                                />
                                <label className="center-label">kg</label>
                            </div>
                        </div>
                        <div className="center-half">
                            <h2 className="center2">상대악력</h2>
                            <div className="center-bg-gray flex">
                                <div className="center-bg-blue">1회차</div>
                                <input
                                    className="center-input-gray center-w120"
                                    type="text"
                                    id="relativeGrip"
                                    value={basalPhysical.relativeGrip[0]}
                                    onChange={changeHandler}
                                />
                                <label className="center-label">%</label>
                            </div>
                        </div>
                    </div>
                    <div className="flex-basic">
                        <div className="center-half">
                            <h2 className="center2">윗몸말아올리기</h2>
                            <div className="center-bg-gray flex">
                                <div className="center-bg-blue">1회차</div>
                                <input
                                    className="center-input-gray center-w120"
                                    type="text"
                                    id="sitUp"
                                    value={basalPhysical.sitUp[0]}
                                    onChange={changeHandler}
                                />
                                <label className="center-label">회</label>
                            </div>
                        </div>
                        <div className="center-half">
                            <h2 className="center2">교차윗몸일으키기</h2>
                            <div className="center-bg-gray flex">
                                <div className="center-bg-blue">1회차</div>
                                <input
                                    className="center-input-gray center-w120"
                                    type="text"
                                    id="crossSitUp"
                                    value={basalPhysical.crossSitUp[0]}
                                    onChange={changeHandler}
                                />
                                <label className="center-label">회</label>
                            </div>
                        </div>
                    </div>
                    <div className="flex-basic">
                        <div className="center-half">
                            <h2 className="center2">반복점프</h2>
                            <div className="center-bg-gray flex">
                                <div className="center-bg-blue">1회차</div>
                                <input
                                    className="center-input-gray center-w120"
                                    type="text"
                                    id="repeatJump"
                                    value={basalPhysical.repeatJump[0]}
                                    onChange={changeHandler}
                                />
                                <label className="center-label">회</label>
                            </div>
                        </div>
                        <div className="center-half">
                            <h2 className="center2">좌전굴(앉아윗몸앞으로굽히기)</h2>
                            <div className="center-bg-gray flex">
                                <div className="center-bg-blue">1회차</div>
                                <input
                                    className="center-input-gray center-w120"
                                    type="text"
                                    id="buckling"
                                    value={basalPhysical.buckling[0]}
                                    onChange={changeHandler}
                                />
                                <label className="center-label">cm</label>
                            </div>
                        </div>
                    </div>

                    <div className="flex-basic">
                        <div className="center-half">
                            <h2 className="center2">10m 왕복오래달리기</h2>
                            <div className="center-bg-gray flex">
                                <div className="center-bg-blue">1회차</div>
                                <input
                                    className="center-input-gray center-w120"
                                    type="text"
                                    id="shuttleRun10"
                                    value={basalPhysical.shuttleRun10[0]}
                                    onChange={changeHandler}
                                />
                                <label className="center-label">초</label>
                            </div>
                        </div>
                        <div className="center-half">
                            <h2 className="center2">15m 왕복오래달리기</h2>
                            <div className="center-bg-gray flex">
                                <div className="center-bg-blue">1회차</div>
                                <input
                                    className="center-input-gray center-w120"
                                    type="text"
                                    id="shuttleRun15"
                                    value={basalPhysical.shuttleRun15[0]}
                                    onChange={changeHandler}
                                />
                                <label className="center-label">초</label>
                            </div>
                        </div>
                    </div>
                    <div className="flex-basic">
                        <div className="center-half">
                            <h2 className="center2">20m 왕복오래달리기</h2>
                            <div className="center-bg-gray flex">
                                <div className="center-bg-blue">1회차</div>
                                <input
                                    className="center-input-gray center-w120"
                                    type="text"
                                    id="shuttleRun20"
                                    value={basalPhysical.shuttleRun20[0]}
                                    onChange={changeHandler}
                                />
                                <label className="center-label">초</label>
                            </div>
                        </div>
                    </div>

                    <div className="flex-basic">
                        <div className="center-half">
                            <h2 className="center2">제자리멀리뛰기</h2>
                            <div className="center-bg-gray flex">
                                <div className="center-bg-blue">1회차</div>
                                <input
                                    className="center-input-gray center-w120"
                                    type="text"
                                    id="jumpInPlace"
                                    value={basalPhysical.jumpInPlace[0]}
                                    onChange={changeHandler}
                                />
                                <label className="center-label">cm</label>
                            </div>
                        </div>
                        <div className="center-half">
                            <h2 className="center2">반복옆뛰기</h2>
                            <div className="center-bg-gray flex">
                                <div className="center-bg-blue">1회차</div>
                                <input
                                    className="center-input-gray center-w120"
                                    type="text"
                                    id="repeatSideJump"
                                    value={basalPhysical.repeatSideJump[0]}
                                    onChange={changeHandler}
                                />
                                <label className="center-label">회</label>
                            </div>
                        </div>
                    </div>
                    <div className="flex-basic">
                        <div className="center-half">
                            <h2 className="center2">체공시간</h2>
                            <div className="center-bg-gray flex">
                                <div className="center-bg-blue">1회차</div>
                                <input
                                    className="center-input-gray center-w120"
                                    type="text"
                                    id="flightTime"
                                    value={basalPhysical.flightTime[0]}
                                    onChange={changeHandler}
                                />
                                <label className="center-label">초</label>
                            </div>
                        </div>
                        <div className="center-half">
                            <h2 className="center2">반응시간</h2>
                            <div className="center-bg-gray flex">
                                <div className="center-bg-blue">1회차</div>
                                <input
                                    className="center-input-gray center-w120"
                                    type="text"
                                    id="reactionTime"
                                    value={basalPhysical.reactionTime[0]}
                                    onChange={changeHandler}
                                />
                                <label className="center-label">초</label>
                            </div>
                        </div>
                    </div>

                    <div className="flex-basic">
                        <div className="center-half">
                            <h2 className="center2">일리노이민청성검사</h2>
                            <div className="center-bg-gray flex">
                                <div className="center-bg-blue">1회차</div>
                                <input
                                    className="center-input-gray center-w120"
                                    type="text"
                                    id="agilityTest"
                                    value={basalPhysical.agilityTest[0]}
                                    onChange={changeHandler}
                                />
                                <label className="center-label">초</label>
                            </div>
                        </div>
                        <div className="center-half">
                            <h2 className="center2">눈/손 협응력</h2>
                            <div className="center-bg-gray flex">
                                <div className="center-bg-blue">1회차</div>
                                <input
                                    className="center-input-gray center-w120"
                                    type="text"
                                    id="coordination"
                                    value={basalPhysical.coordination[0]}
                                    onChange={changeHandler}
                                />
                                <label className="center-label">초</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
