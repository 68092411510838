import React, { useEffect, useState } from 'react';
import AdminTable from './AdminTable';
import Reservation from '../../domain/reservation/Reservation';
import { Link } from 'react-router-dom';
import ReservationStateButton from '../reservation/ReservationStateButton';
import ReservationServiceImpl from '../../service/reservation/impl/ReservationServiceImpl';
import ReservationStateTd from '../reservation/ResrvationStateTd';

interface AdminReservationTableProps {
    page: number;
    pagingSize: number;
    reservations?: Reservation[];
}
const reservationService = new ReservationServiceImpl();
function AdminReservationsTable(props: AdminReservationTableProps) {
    return (
        <AdminTable>
            <thead>
                <tr>
                    <td>No.</td>
                    <td>시설명</td>
                    <td>예약자</td>
                    <td>소속팀</td>
                    <td>신청일</td>
                    <td>방문일정</td>
                    <td>예약상태</td>
                    <td>관리</td>
                </tr>
            </thead>
            <tbody>
                {
                    props.reservations?
                        props.reservations.length > 0 ?
                            props.reservations?.map((reservation: Reservation, index: number) => {
                                return (
                                    <tr key={index}>
                                        <td>{props.pagingSize * props.page + (index+1)}</td>
                                        {
                                            reservation.parentInfra?.name ?
                                            <td>{`${reservation.parentInfra?.name}-${reservation.infra?.name}`}</td>
                                            :
                                            <td>{`${reservation.infra?.name}`}</td>
                                        }
                                        <td>{reservation.reservater?.name}</td>
                                        <td>{reservation.register?.teamName}</td>
                                        <td>{reservation.registeDate}</td>
                                        <td>
                                            {
                                                reservation.startDate && reservation.endDate ?
                                                `${reservation.startDate} ~ ${reservation.endDate}`
                                                :
                                                ''
                                            }
                                        </td>
                                        <ReservationStateTd reservation={reservation}></ReservationStateTd>
                                    </tr>
                                )
                            })
                            :
                            <tr>
                                <td colSpan={8}>
                                    <h5>검색 된 예약 일정이 없습니다.</h5>
                                </td>
                            </tr>
                        :
                        <></>
                }
            </tbody>
        </AdminTable>
    )
}

export default AdminReservationsTable;