import InfraCategoryServiceImpl from '../../service/infra/impl/InfraCategoryServiceImpl';
import InfraCategory from '../../domain/infra/InfraCategory';
import { useEffect, useState } from 'react';

const infraCategoryService = new InfraCategoryServiceImpl();

function useInfraOfCategoryRetrieve(parentInfraCategoryNo: number) {
    const [infraCategories, setInfraCategories] = useState<InfraCategory[]>();

    useEffect(() => {
        infraCategoryService.retrieveChildInfraCategory(parentInfraCategoryNo).then((infraCategories: InfraCategory[]) => {
            setInfraCategories(infraCategories);
        });
    }, [parentInfraCategoryNo]);

    return infraCategories;
}

export default useInfraOfCategoryRetrieve;
