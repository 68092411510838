import React from 'react';
import { Route, Switch } from "react-router-dom";
import PoliciesPrivacyPage from '../page/PoliciesPrivacyPage';

function PoliciesRouter() {
    return (
        <Switch>
            <Route exact path="/policies/privacy" component={PoliciesPrivacyPage}></Route>
        </Switch>
    )
}

export default PoliciesRouter;