import React, { useEffect } from 'react';
import SearchBarProps from './SearchBarProps';
import SearchBarResult from './SearchBarResult';
import SearchWordResult from './SearchWordResult';
import useSearchBarHook from './hook/useSearchBarHook';
import { useDispatch } from 'react-redux';
import { addSearchWordAction } from '../../moldules/SearchWordReduce';

function MiniSearchBar(props: SearchBarProps) {
    const dispatch = useDispatch();
    const {
        searchWord,
        setSearchWord,
        searchItems,
        searchWordVisible,
        setSearchWordVisible,
        setLastedSearchWord,
        mySearchWords,
        mostFavoritesSearchWords,
    } = useSearchBarHook();

    useEffect(() => {
        if (props.searchWord) setSearchWord(props.searchWord);
    }, [props.searchWord]);

    return (
        <div>
            <div className="search-search">
                <div>
                    <input
                        type="text"
                        id="dd"
                        placeholder={props.placeholder}
                        value={searchWord}
                        onClick={() => {
                            setSearchWordVisible(!searchWordVisible);
                        }}
                        onChange={(event) => {
                            setSearchWord(event.target.value);
                        }}
                        autoComplete="off"
                    />
                </div>
                <div>
                    <img
                        onClick={() => {
                            dispatch(addSearchWordAction(searchWord));
                        }}
                        src="/assets/image/search.png"
                        alt="search"
                    />
                </div>
            </div>
            <div className="search-list">
                {searchWordVisible ? (
                    <div>
                        <SearchWordResult
                            title="인기 검색어"
                            searchWords={mostFavoritesSearchWords}
                            setLastedSearchWord={setLastedSearchWord}
                            setSearchWordVisible={setSearchWordVisible}
                        ></SearchWordResult>
                        {searchItems ? <SearchBarResult searchItems={searchItems}></SearchBarResult> : <></>}
                    </div>
                ) : (
                    <></>
                )}
            </div>
        </div>
    );
}

export default MiniSearchBar;
