import { useEffect, useState } from 'react';
import Infra from '../../domain/infra/Infra';
import InfraServiceImpl from '../../service/infra/impl/InfraServiceImpl';

const infraService = new InfraServiceImpl();
function useInfraUpdate(infraNo?: string, infra?: Infra) {
    const [updateInfra, setUpdateInfra] = useState<Infra>();
    const [updateDate, setUpdateDate] = useState<Date>(new Date());
    useEffect(() => {
        if (infraNo && infra) {
            infraService.updateInfra(infraNo, infra).then((updatedInfra: Infra) => {
                setUpdateDate(new Date());
                setUpdateInfra(updatedInfra);
            });
        }
    }, [infra]);

    return {
        updateInfra,
        updateDate,
    };
}

export default useInfraUpdate;
