import { JsonObject, JsonProperty } from 'json2typescript';
import Team from '../team/Team';
import User from './User';

@JsonObject('belongTeam')
class BelongTeam {
    @JsonProperty('belongedTeamNo')
    belongedTeamNo: string = '';
    @JsonProperty('belongedTeam')
    belongedTeam?: Team;
    @JsonProperty('belongedUserNo')
    belongedUserNo?: string;
    @JsonProperty('belongedUser')
    belongedUser?: User;
    @JsonProperty('registerNo')
    registerNo?: string = '';
    @JsonProperty('register')
    register?: User;
    @JsonProperty('registeDate')
    registeDate?: string = '';
    @JsonProperty('deleteYn')
    deleteYn?: boolean = true;
}

export default BelongTeam;
