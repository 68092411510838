import React, { useEffect, useState } from 'react';
import { propertyOf } from 'underscore';
import BasalPhysical from '../../../../domain/report/BasalPhysical';
import PhysicalStatus from '../../../../domain/report/PhysicalStatus';
import CheckCondition from '../../domain/recording/CheckCondition';

interface IProps {
    basal?: BasalPhysical;
    status?: PhysicalStatus;
}
export default function BasalReporting(props: IProps) {
    return (
        <>
            <table className="reporting-table" cellPadding={0} cellSpacing={0}>
                <colgroup>
                    <col style={{ width: '5%' }} />
                    <col style={{ width: '15%' }} />
                    <col style={{ width: '35%' }} />
                    <col style={{ width: '15%' }} />
                    <col style={{ width: '30%' }} />
                </colgroup>
                <tbody>
                    <tr>
                        <td rowSpan={8} style={{ fontWeight: 700, fontSize: '1em' }}>
                            기초체력
                            <br />
                            측정항목
                        </td>
                        <th>신장(cm)</th>
                        <th>체중(kg)</th>
                        <th>체지방률(%)</th>
                        <th>허리둘레(cm)</th>
                    </tr>
                    <tr>
                        <td>{props.status?.height}</td>
                        <td>{props.status?.weight}</td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <th style={{ backgroundColor: '#8497b0' }}>근력</th>
                        <th style={{ backgroundColor: '#8497b0' }}>근지구력</th>
                        <th style={{ backgroundColor: '#8497b0' }}>유연성</th>
                        <th style={{ backgroundColor: '#8497b0' }}>심폐지구력</th>
                    </tr>
                    <tr>
                        <td style={{ padding: '0px' }}>
                            <table className="inner-table" cellPadding={0} cellSpacing={0} style={{ margin: '0px' }}>
                                <colgroup>
                                    <col style={{ width: '100%' }} />
                                </colgroup>
                                <tbody>
                                    <tr>
                                        <th>악력(kg)</th>
                                    </tr>
                                    <tr>
                                        <td>{props.basal?.graspingPower}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        <td>
                            <table className="inner-table" cellPadding={0} cellSpacing={0}>
                                <colgroup>
                                    <col style={{ width: '30%' }} />
                                    <col style={{ width: '30%' }} />
                                    <col style={{ width: '30%' }} />
                                </colgroup>
                                <tbody>
                                    <tr>
                                        <th>
                                            반복점프
                                            <br /> (회/초)
                                        </th>
                                        <th>
                                            윗몸
                                            <br /> 말아올리기(회)
                                        </th>
                                        <th>
                                            교차 윗몸
                                            <br /> 일으키기(회)
                                        </th>
                                    </tr>
                                    <tr>
                                        <td>{props.basal?.repeatJump}</td>
                                        <td>{props.basal?.sitUp}</td>
                                        <td>{props.basal?.crossSitUp}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        <td>
                            <table className="inner-table" cellPadding={0} cellSpacing={0}>
                                <colgroup>
                                    <col style={{ width: '100%' }} />
                                </colgroup>
                                <tbody>
                                    <tr>
                                        <th>앉아 윗몸 앞으로 굽히기(cm)</th>
                                    </tr>
                                    <tr>
                                        <td>{props.basal?.buckling}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        <td>
                            <table className="inner-table" cellPadding={0} cellSpacing={0}>
                                <colgroup>
                                    <col style={{ width: '25%' }} />
                                    <col style={{ width: '25%' }} />
                                    <col style={{ width: '25%' }} />
                                    <col style={{ width: '25%' }} />
                                </colgroup>
                                <tbody>
                                    <tr>
                                        <th>스템검사(bpm)</th>
                                        <th>
                                            15m 왕복
                                            <br /> 달리기(회)
                                        </th>
                                        <th>
                                            20m 왕복
                                            <br /> 달리기(회)
                                        </th>
                                        <th>
                                            2분 제자리
                                            <br />
                                            걷기(회)
                                        </th>
                                    </tr>
                                    <tr>
                                        <td>{props.basal?.reactionTime}</td>
                                        <td>{props.basal?.shuttleRun15}</td>
                                        <td>{props.basal?.shuttleRun20}</td>
                                        <td>{props.basal?.jumpInPlace}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <th style={{ backgroundColor: '#8497b0' }}>순발력</th>
                        <th style={{ backgroundColor: '#8497b0' }}>민첩성</th>
                        <th style={{ backgroundColor: '#8497b0' }}>협응성</th>
                        <th style={{ backgroundColor: '#8497b0' }}>평형성</th>
                    </tr>
                    <tr>
                        <td>
                            <table className="inner-table" cellPadding={0} cellSpacing={0}>
                                <colgroup>
                                    <col style={{ width: '50%' }} />
                                    <col style={{ width: '50%' }} />
                                </colgroup>
                                <tbody>
                                    <tr>
                                        <th>체공시간(초)</th>
                                        <th>제자리멀리뛰기(m)</th>
                                    </tr>
                                    <tr>
                                        <td>{props.basal?.flightTime}</td>
                                        <td>{props.basal?.jumpInPlace}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        <td>
                            <table className="inner-table" cellPadding={0} cellSpacing={0}>
                                <colgroup>
                                    <col style={{ width: '30%' }} />
                                    <col style={{ width: '30%' }} />
                                    <col style={{ width: '30%' }} />
                                </colgroup>
                                <tbody>
                                    <tr>
                                        <th>
                                            반복 옆뛰기
                                            <br /> (회)
                                        </th>
                                        <th>
                                            10m 왕복
                                            <br /> 달리기(초)
                                        </th>
                                        <th>일리노이 민첩성 검사(초)</th>
                                    </tr>
                                    <tr>
                                        <td>{props.basal?.repeatSideJump}</td>
                                        <td>{props.basal?.shuttleRun10}</td>
                                        <td>{props.basal?.agilityTest}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        <td>
                            <table className="inner-table" cellPadding={0} cellSpacing={0}>
                                <colgroup>
                                    <col style={{ width: '50%' }} />
                                    <col style={{ width: '50%' }} />
                                </colgroup>
                                <tbody>
                                    <tr>
                                        <th>
                                            손 협응력
                                            <br /> (회, 초)
                                        </th>
                                        <th>8자 보행(초)</th>
                                    </tr>
                                    <tr>
                                        <td>{props.basal?.agilityTest}</td>
                                        <td>{''}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        <td>
                            <table className="inner-table" cellPadding={0} cellSpacing={0}>
                                <colgroup>
                                    <col style={{ width: '100%' }} />
                                </colgroup>
                                <tbody>
                                    <tr>
                                        <th>3m 표적 돌아오기(초)</th>
                                    </tr>
                                    <tr>
                                        <td>{''}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </tbody>
            </table>
            <table className="eval-table">
                <tbody>
                    <tr>
                        <td>
                            <span style={{ fontWeight: 700, fontSize: '15px' }}>* 평가</span>
                            <br />{' '}
                        </td>
                    </tr>
                </tbody>
            </table>
        </>
    );
}
