import Infra from '../domain/infra/Infra';
import { RootState } from './index';
import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import InfraServiceImpl from '../service/infra/impl/InfraServiceImpl';

const infraService = new InfraServiceImpl();
const ADD = 'infra/ADD' as const;
const RENEW = 'infra/RENEW' as const;
const RETRIEVE = 'infra/RETRIEVE' as const;


export const addInfraAction = (infras: Infra[]) => ({type: ADD, payload: infras});
export const renewInfraAction = (infras: Infra[]) => ({type: RENEW, payload: infras});
/**
 * 인프라 목록 조회
 * @param parentInfraCategoryNo
 * @param filterItem 
 */
export const retrieveInfraAction: any = (parentInfraCategoryNo: number, filterItem: any): ThunkAction<void, RootState, unknown, Action<string>> => async dispatch => {
    infraService.getInfraOfParentCategoryNo(parentInfraCategoryNo)
        .then(infras => {
            dispatch({type: RETRIEVE, payload: infras})
        })
}

export type InfraAction = 
    | ReturnType<typeof addInfraAction>
    | ReturnType<typeof renewInfraAction>
    | ReturnType<typeof retrieveInfraAction>;

type InfraState = {
    infras: Infra[];
}

/**
 * empty infras 
 */
const initialState: InfraState = {
    infras: []
}

function infraList(state: InfraState = initialState, action: InfraAction) {
    switch(action.type) {
        case ADD:
            return { infras: state.infras.concat(action.payload) }
        case RENEW: 
            return { infras: action.payload }
        case RETRIEVE:
            return { infras: action.payload }
        default:
            return state;
    }
}

export default infraList;