import React from 'react';
interface AdminMainTitleProps {
    title: React.ReactNode;
    tab?: React.ReactNode;
}

function AdminFormTitle(props: AdminMainTitleProps) {
    return (
        <>
        <div className="member-tit">
            <h3>{props.title}</h3>
        </div>
        {
            props.tab
            ?
            <>{props.tab}</>
            :
            <></>
        }
        </>
    )
}

export default AdminFormTitle;