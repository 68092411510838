import { useEffect, useState } from "react";
import Reservation from "../../domain/reservation/Reservation";
import Schedule from "../../domain/schedule/Schedule";
import TeamScheduleServiceImpl from '../../service/schedule/impl/TeamScheduleServiceImpl';
import ValidationUtils from '../../utils/ValidationUtils';

const teamScheduleService = new TeamScheduleServiceImpl();

function useTeamScheduleRegiste(newSchedule?: Schedule) {
    const [registeDate, setRegisteDate] = useState<Date>();
    
    useEffect(() => {
        if(newSchedule) {
            teamScheduleService.registeSchedule(newSchedule)
            .then(() => {
                setRegisteDate(new Date());
            });
        }
    }, [newSchedule]);

    return [registeDate];
}

export default useTeamScheduleRegiste;