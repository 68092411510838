import React from 'react';
import NDI from './1NDI';
import AnswerItem from '../../../../domain/AnswerItem';
import TopsForm from './2TopsForm';
import QuestionnariesFormProps from './QuestionnariesFormsProps';
import StressCheckForm from './4StressCheckForm';
import SpadiForm from './3SpadiForm';
import WHOQQOL from './5WHOQQOL';
import FABQ from './6FABQ';
import IESRK from './7IESRK';
import Questionnaire from '../../../../domain/Questionnaire';
import SatisfyForm from './SatisfyForm';
import QuestionCard from '../../../../../mypage/components/QuestionCard';

interface QuestionnariesFormFactoryProps {
    formType: string;
    questionnaire?: Questionnaire;
    answerItems?: Map<number, AnswerItem>;
    setAnswer?: (quesIdex: number, answerItem: AnswerItem) => void;
}

function QuestionnariesFormFactory(props: QuestionnariesFormFactoryProps) {
    console.log('QuestionnairesFormFactory');
    let questionnaire = props.questionnaire;

    const renderObj = () => {
        if (props.formType == 'NDI') {
            return <NDI answerItems={props.answerItems} setAnswer={props.setAnswer}></NDI>;
        } else if (props.formType == 'Tops') {
            return <TopsForm answerItems={props.answerItems} setAnswer={props.setAnswer}></TopsForm>;
        } else if (props.formType == 'StressCheckForm') {
            return <StressCheckForm answerItems={props.answerItems} setAnswer={props.setAnswer}></StressCheckForm>;
        } else if (props.formType == 'Spadi') {
            return <SpadiForm answerItems={props.answerItems} setAnswer={props.setAnswer}></SpadiForm>;
        } else if (props.formType == 'WhoQ') {
            return <WHOQQOL answerItems={props.answerItems} setAnswer={props.setAnswer}></WHOQQOL>;
        } else if (props.formType == 'FABQ') {
            return <FABQ answerItems={props.answerItems} setAnswer={props.setAnswer}></FABQ>;
        } else if (props.formType == 'IESRK') {
            return <IESRK answerItems={props.answerItems} setAnswer={props.setAnswer}></IESRK>;
        } else if (props.formType.indexOf('SATISFY') >= 0) {
            return <SatisfyForm questionnaire={questionnaire} answerItems={props.answerItems} setAnswer={props.setAnswer}></SatisfyForm>;
            //return <QuestionCard answerItemsMap={props.answerItems} setAnswer={props.setAnswer}></QuestionCard>;
        } else {
            return <h1>설문이 정상적이지 않습니다. 관리자에게 문의하셔요</h1>;
        }
    };

    return renderObj();
}

export default QuestionnariesFormFactory;
