import AbstractScheduleService from '../AbstractScheduleService';

class TeamScheduleServiceImpl extends AbstractScheduleService {
    getScheduleUrl(scheduleTargetNo: string, scheduleNo: string): string {
        return `/schedule/team/${scheduleTargetNo}/${scheduleNo}`;
    }

    getSchedulesUrl(scheduleTargetNo: string): string {
        return `/schedule/team/${scheduleTargetNo}`;
    }
    registeScheduleUrl(): string {
        return `/schedule/team`;
    }
    updateScheduleUrl(): string {
        return `/schedule/team`;
    }
}

export default TeamScheduleServiceImpl;