import { useEffect, useState } from 'react';
import ReportServiceImpl from '../../service/report/impl/ReportServiceImpl';
import Report from '../../domain/report/Report';

const reportService = new ReportServiceImpl();
function useReportOfOwner(targetNo?: number, ownerNo?: string) {
    const [report, setReport] = useState<Report>();
    useEffect(() => {
        if(targetNo && ownerNo) {
            reportService.getReport(targetNo, ownerNo)
            .then(report => {
                setReport(report);
            })
        }
    }, [targetNo, ownerNo]);

    return {report, setReport};
}

export default useReportOfOwner;