import React, { useState, useEffect } from 'react';
import SpecialPhysical from '../../../../../domain/recording/SpecialPhysical';
interface IProps {
    handler: (evt: any) => void;
    ocr: string;
    status?: SpecialPhysical;
}

export default function BioDexForm(props: IProps) {
    const resourceAPIURL = process.env.REACT_APP_RESOURCE_API_HOST;
    const [bioDex, setBioDex] = useState<any>(new SpecialPhysical().bioDex);

    useEffect(() => {
        if (props.status) setBioDex(props.status.bioDex);
    }, [props.status]);
    let uploadImage = bioDex.uploadBDFile;

    if (props.ocr) {
        let matrix = makeOcrMatrix(JSON.parse(props.ocr));
        // vo2, mets, ve, vt;
        let pt60ExtR: string | undefined = contractValue(['toroue', 'torque'], matrix, 1);
        let ptb60ExtR: string | undefined = contractValue(['tq/bw'], matrix, 1);
        let torq30_60ExtR: string | undefined = contractValue(['toro,@,30.0', 'torq,@,30.0'], matrix, 1);
        let torq18_60ExtR: string | undefined = contractValue(['toro,@,0.18', 'torq,@,0.18'], matrix, 1);
        let cofe60ExtR: string | undefined = contractValue(['var.'], matrix, 1);
        let totWo60ExtR: string | undefined = contractValue(['totwork'], matrix, 1);
        let workFat60ExtR: string | undefined = contractValue(['fatigue'], matrix, 1);
        let avgPow60ExtR: string | undefined = contractValue(['avg.', 'power'], matrix, 1);
        let agan60ExtR: string | undefined = contractValue(['agony/antag'], matrix, 1);

        let pt60FlxR: string | undefined = contractValue(['toroue', 'torque'], matrix, 2);
        let ptb60FlxR: string | undefined = contractValue(['tq/bw'], matrix, 2);
        let torq30_60FlxR: string | undefined = contractValue(['toro,@,30.0', 'torq,@,30.0'], matrix, 2);
        let torq18_60FlxR: string | undefined = contractValue(['toro,@,0.18', 'torq,@,0.18'], matrix, 2);
        let cofe60FlxR: string | undefined = contractValue(['var.'], matrix, 2);
        let totWo60FlxR: string | undefined = contractValue(['totwork'], matrix, 2);
        let workFat60FlxR: string | undefined = contractValue(['fatigue'], matrix, 2);
        let avgPow60FlxR: string | undefined = contractValue(['avg.', 'power'], matrix, 2);
        let agan60FlxR: string | undefined = contractValue(['agony/antag'], matrix, 2);

        bioDex.pt60ExtR = pt60ExtR ? pt60ExtR : '';
        bioDex.ptb60ExtR = ptb60ExtR ? ptb60ExtR : '';
        bioDex.torq30_60ExtR = torq30_60ExtR ? torq30_60ExtR : '';
        bioDex.torq18_60ExtR = torq18_60ExtR ? torq18_60ExtR : '';
        bioDex.cofe60ExtR = cofe60ExtR ? cofe60ExtR : '';
        bioDex.totWo60ExtR = totWo60ExtR ? totWo60ExtR : '';
        bioDex.workFat60ExtR = workFat60ExtR ? workFat60ExtR : '';
        bioDex.avgPow60ExtR = avgPow60ExtR ? avgPow60ExtR : '';
        bioDex.agan60ExtR = agan60ExtR ? agan60ExtR : '';

        bioDex.pt60FlxR = pt60FlxR ? pt60FlxR : '';
        bioDex.ptb60FlxR = ptb60FlxR ? ptb60FlxR : '';
        bioDex.torq30_60FlxR = torq30_60FlxR ? torq30_60FlxR : '';
        bioDex.torq18_60FlxR = torq18_60FlxR ? torq18_60FlxR : '';
        bioDex.cofe60FlxR = cofe60FlxR ? cofe60FlxR : '';
        bioDex.totWo60FlxR = totWo60FlxR ? totWo60FlxR : '';
        bioDex.workFat60FlxR = workFat60FlxR ? workFat60FlxR : '';
        bioDex.avgPow60FlxR = avgPow60FlxR ? avgPow60FlxR : '';
        bioDex.agan60FlxR = agan60FlxR ? agan60FlxR : '';

        /* let pt60ExtL: string | undefined = contractValue(['vo2/kg', 'v02/kg'], matrix);
            let ptb60ExtL: string | undefined = contractValue(['vo2/kg', 'v02/kg'], matrix);
            let torq30_60ExtL: string | undefined = contractValue(['vo2/kg', 'v02/kg'], matrix);
            let torq18_60ExtL: string | undefined = contractValue(['vo2/kg', 'v02/kg'], matrix);
            let cofe60ExtL: string | undefined = contractValue(['vo2/kg', 'v02/kg'], matrix);
            let totWo60ExtL: string | undefined = contractValue(['vo2/kg', 'v02/kg'], matrix);
            let workFat60ExtL: string | undefined = contractValue(['vo2/kg', 'v02/kg'], matrix);
            let avgPow60ExtL: string | undefined = contractValue(['vo2/kg', 'v02/kg'], matrix);
            let agan60ExtL: string | undefined = contractValue(['vo2/kg', 'v02/kg'], matrix);
    
            let pt60FlxL: string | undefined = contractValue(['vo2/kg', 'v02/kg'], matrix);
            let ptb60FlxL: string | undefined = contractValue(['vo2/kg', 'v02/kg'], matrix);
            let torq30_60FlxL: string | undefined = contractValue(['vo2/kg', 'v02/kg'], matrix);
            let torq18_60FlxL: string | undefined = contractValue(['vo2/kg', 'v02/kg'], matrix);
            let cofe60FlxL: string | undefined = contractValue(['vo2/kg', 'v02/kg'], matrix);
            let totWo60FlxL: string | undefined = contractValue(['vo2/kg', 'v02/kg'], matrix);
            let workFat60FlxL: string | undefined = contractValue(['vo2/kg', 'v02/kg'], matrix);
            let avgPow60FlxL: string | undefined = contractValue(['vo2/kg', 'v02/kg'], matrix);
            let agan60FlxL: string | undefined = contractValue(['vo2/kg', 'v02/kg'], matrix); */
        let evt = {
            target: {
                name: 'ocrBiodex',
                value: bioDex,
            },
        };

        props.handler(evt);
    }

    let imageElem = <></>;
    if (typeof uploadImage != 'undefined' && uploadImage != '')
        imageElem = <img id="uploadedImages" src={resourceAPIURL + uploadImage} width="580" />;
    return (
        <>
            <div className="center-popup-title">
                <h1 className="center">바이오덱스(등속성운동)</h1>
            </div>

            <div className="center-special-physical-btn">
                <label htmlFor={'uploadedBDFile'} className="center-label-up">
                    검사결과적용
                </label>
                <input type="file" id="uploadedBDFile" name="bioDex" onChange={props.handler} style={{ display: 'none' }} accept=".gif, .jpg, .png" />
            </div>

            <div className="center-table-bio-box">
                <table cellPadding="0" cellSpacing="0" className="center-table-bio">
                    <thead>
                        <tr>
                            <td colSpan={2} rowSpan={2} className="center-bgcolor-mint in">
                                # OF REPS : 5 / 10 / 15
                            </td>
                            <td colSpan={4} className="center-bgcolor-navy in">
                                60 DEG/SEC
                            </td>
                            <td colSpan={4} className="center-bgcolor-navy in">
                                180 DEG/SEC
                            </td>
                            <td colSpan={4} className="center-bgcolor-navy in">
                                300 DEG/SEC
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2} className="center-bgcolor-gray in">
                                EXTENSION
                            </td>
                            <td colSpan={2} className="center-bgcolor-blue in">
                                FLEXION
                            </td>
                            <td colSpan={2} className="center-bgcolor-gray in">
                                EXTENSION
                            </td>
                            <td colSpan={2} className="center-bgcolor-blue in">
                                FLEXION
                            </td>
                            <td colSpan={2} className="center-bgcolor-gray in">
                                EXTENSION
                            </td>
                            <td colSpan={2} className="center-bgcolor-blue in">
                                FLEXION
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="center-bgcolor-lgray"></td>
                            <td className="in">Unit</td>
                            <td className="center-bgcolor-lgray in">Left</td>
                            <td className="in">Right</td>
                            <td className="center-bgcolor-lgray in">Left</td>
                            <td className="in">Right</td>
                            <td className="center-bgcolor-lgray in">Left</td>
                            <td className="in">Right</td>
                            <td className="center-bgcolor-lgray in">Left</td>
                            <td className="in">Right</td>
                            <td className="center-bgcolor-lgray in">Left</td>
                            <td className="in">Right</td>
                            <td className="center-bgcolor-lgray in">Left</td>
                            <td className="in">Right</td>
                        </tr>
                        <tr>
                            <td className="center-bgcolor-lgray">PEAK TORQUE</td>
                            <td className="in">N-M</td>
                            <td className="center-bgcolor-lgray in">
                                <input
                                    className="center-input-bio"
                                    type="text"
                                    id="pt60ExtL"
                                    value={bioDex.pt60ExtL}
                                    onChange={props.handler}
                                    name="bioDex"
                                />
                            </td>
                            <td className="in">
                                <input
                                    className="center-input-bio"
                                    type="text"
                                    id="pt60ExtR"
                                    value={bioDex.pt60ExtR}
                                    onChange={props.handler}
                                    name="bioDex"
                                />
                            </td>
                            <td className="center-bgcolor-lgray in">
                                <input
                                    className="center-input-bio"
                                    type="text"
                                    id="pt60FlxL"
                                    value={bioDex.pt60FlxL}
                                    onChange={props.handler}
                                    name="bioDex"
                                />
                            </td>
                            <td className="in">
                                <input
                                    className="center-input-bio"
                                    type="text"
                                    id="pt60FlxR"
                                    value={bioDex.pt60FlxR}
                                    onChange={props.handler}
                                    name="bioDex"
                                />
                            </td>
                            <td className="center-bgcolor-lgray in">
                                <input
                                    className="center-input-bio"
                                    type="text"
                                    id="pt180ExtL"
                                    value={bioDex.pt180ExtL}
                                    onChange={props.handler}
                                    name="bioDex"
                                />
                            </td>
                            <td className="in">
                                <input
                                    className="center-input-bio"
                                    type="text"
                                    id="pt180ExtR"
                                    value={bioDex.pt180ExtR}
                                    onChange={props.handler}
                                    name="bioDex"
                                />
                            </td>
                            <td className="center-bgcolor-lgray in">
                                <input
                                    className="center-input-bio"
                                    type="text"
                                    id="pt180FlxL"
                                    value={bioDex.pt180FlxL}
                                    onChange={props.handler}
                                    name="bioDex"
                                />
                            </td>
                            <td className="in">
                                <input
                                    className="center-input-bio"
                                    type="text"
                                    id="pt180FlxR"
                                    value={bioDex.pt180FlxR}
                                    onChange={props.handler}
                                    name="bioDex"
                                />
                            </td>
                            <td className="center-bgcolor-lgray in">
                                <input
                                    className="center-input-bio"
                                    type="text"
                                    id="pt300ExtL"
                                    value={bioDex.pt300ExtL}
                                    onChange={props.handler}
                                    name="bioDex"
                                />
                            </td>
                            <td className="in">
                                <input
                                    className="center-input-bio"
                                    type="text"
                                    id="pt300ExtR"
                                    value={bioDex.pt300ExtR}
                                    onChange={props.handler}
                                    name="bioDex"
                                />
                            </td>
                            <td className="center-bgcolor-lgray in">
                                <input
                                    className="center-input-bio"
                                    type="text"
                                    id="pt300FlxL"
                                    value={bioDex.pt300FlxL}
                                    onChange={props.handler}
                                    name="bioDex"
                                />
                            </td>
                            <td className="in">
                                <input
                                    className="center-input-bio"
                                    type="text"
                                    id="pt300FlxR"
                                    value={bioDex.pt300FlxR}
                                    onChange={props.handler}
                                    name="bioDex"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="center-bgcolor-lgray">PEAK TQ/BW</td>
                            <td className="in">%</td>
                            <td className="center-bgcolor-lgray in">
                                <input
                                    className="center-input-bio"
                                    type="text"
                                    id="ptb60ExtL"
                                    value={bioDex.ptb60ExtL}
                                    onChange={props.handler}
                                    name="bioDex"
                                />
                            </td>
                            <td className="in">
                                <input
                                    className="center-input-bio"
                                    type="text"
                                    id="ptb60ExtR"
                                    value={bioDex.ptb60ExtR}
                                    onChange={props.handler}
                                    name="bioDex"
                                />
                            </td>
                            <td className="center-bgcolor-lgray in">
                                <input
                                    className="center-input-bio"
                                    type="text"
                                    id="ptb60FlxL"
                                    value={bioDex.ptb60FlxL}
                                    onChange={props.handler}
                                    name="bioDex"
                                />
                            </td>
                            <td className="in">
                                <input
                                    className="center-input-bio"
                                    type="text"
                                    id="ptb60FlxR"
                                    value={bioDex.ptb60FlxR}
                                    onChange={props.handler}
                                    name="bioDex"
                                />
                            </td>
                            <td className="center-bgcolor-lgray in">
                                <input
                                    className="center-input-bio"
                                    type="text"
                                    id="ptb180ExtL"
                                    value={bioDex.ptb180ExtL}
                                    onChange={props.handler}
                                    name="bioDex"
                                />
                            </td>
                            <td className="in">
                                <input
                                    className="center-input-bio"
                                    type="text"
                                    id="ptb180ExtR"
                                    value={bioDex.ptb180ExtR}
                                    onChange={props.handler}
                                    name="bioDex"
                                />
                            </td>
                            <td className="center-bgcolor-lgray in">
                                <input
                                    className="center-input-bio"
                                    type="text"
                                    id="ptb180FlxL"
                                    value={bioDex.ptb180FlxL}
                                    onChange={props.handler}
                                    name="bioDex"
                                />
                            </td>
                            <td className="in">
                                <input
                                    className="center-input-bio"
                                    type="text"
                                    id="ptb180FlxR"
                                    value={bioDex.ptb180FlxR}
                                    onChange={props.handler}
                                    name="bioDex"
                                />
                            </td>
                            <td className="center-bgcolor-lgray in">
                                <input
                                    className="center-input-bio"
                                    type="text"
                                    id="ptb300ExtL"
                                    value={bioDex.ptb300ExtL}
                                    onChange={props.handler}
                                    name="bioDex"
                                />
                            </td>
                            <td className="in">
                                <input
                                    className="center-input-bio"
                                    type="text"
                                    id="ptb300ExtR"
                                    value={bioDex.ptb300ExtR}
                                    onChange={props.handler}
                                    name="bioDex"
                                />
                            </td>
                            <td className="center-bgcolor-lgray in">
                                <input
                                    className="center-input-bio"
                                    type="text"
                                    id="ptb300FlxL"
                                    value={bioDex.ptb300FlxL}
                                    onChange={props.handler}
                                    name="bioDex"
                                />
                            </td>
                            <td className="in">
                                <input
                                    className="center-input-bio"
                                    type="text"
                                    id="ptb300FlxR"
                                    value={bioDex.ptb300FlxR}
                                    onChange={props.handler}
                                    name="bioDex"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="center-bgcolor-lgray">TORQ @30.0 DEG</td>
                            <td className="in">N-M</td>
                            <td className="center-bgcolor-lgray in">
                                <input
                                    className="center-input-bio"
                                    type="text"
                                    id="torq30_60ExtL"
                                    value={bioDex.torq30_60ExtL}
                                    onChange={props.handler}
                                    name="bioDex"
                                />
                            </td>
                            <td className="in">
                                <input
                                    className="center-input-bio"
                                    type="text"
                                    id="torq30_60ExtR"
                                    value={bioDex.torq30_60ExtR}
                                    onChange={props.handler}
                                    name="bioDex"
                                />
                            </td>
                            <td className="center-bgcolor-lgray in">
                                <input
                                    className="center-input-bio"
                                    type="text"
                                    id="torq30_60FlxL"
                                    value={bioDex.torq30_60FlxL}
                                    onChange={props.handler}
                                    name="bioDex"
                                />
                            </td>
                            <td className="in">
                                <input
                                    className="center-input-bio"
                                    type="text"
                                    id="torq30_60FlxR"
                                    value={bioDex.torq30_60FlxR}
                                    onChange={props.handler}
                                    name="bioDex"
                                />
                            </td>
                            <td className="center-bgcolor-lgray in">
                                <input
                                    className="center-input-bio"
                                    type="text"
                                    id="torq30_180ExtL"
                                    value={bioDex.torq30_180ExtL}
                                    onChange={props.handler}
                                    name="bioDex"
                                />
                            </td>
                            <td className="in">
                                <input
                                    className="center-input-bio"
                                    type="text"
                                    id="torq30_180ExtR"
                                    value={bioDex.torq30_180ExtR}
                                    onChange={props.handler}
                                    name="bioDex"
                                />
                            </td>
                            <td className="center-bgcolor-lgray in">
                                <input
                                    className="center-input-bio"
                                    type="text"
                                    id="torq30_180FlxL"
                                    value={bioDex.torq30_180FlxL}
                                    onChange={props.handler}
                                    name="bioDex"
                                />
                            </td>
                            <td className="in">
                                <input
                                    className="center-input-bio"
                                    type="text"
                                    id="torq30_180FlxR"
                                    value={bioDex.torq30_180FlxR}
                                    onChange={props.handler}
                                    name="bioDex"
                                />
                            </td>
                            <td className="center-bgcolor-lgray in">
                                <input
                                    className="center-input-bio"
                                    type="text"
                                    id="torq30_300ExtL"
                                    value={bioDex.torq30_300ExtL}
                                    onChange={props.handler}
                                    name="bioDex"
                                />
                            </td>
                            <td className="in">
                                <input
                                    className="center-input-bio"
                                    type="text"
                                    id="torq30_300ExtR"
                                    value={bioDex.torq30_300ExtR}
                                    onChange={props.handler}
                                    name="bioDex"
                                />
                            </td>
                            <td className="center-bgcolor-lgray in">
                                <input
                                    className="center-input-bio"
                                    type="text"
                                    id="torq30_300FlxL"
                                    value={bioDex.torq30_300FlxL}
                                    onChange={props.handler}
                                    name="bioDex"
                                />
                            </td>
                            <td className="in">
                                <input
                                    className="center-input-bio"
                                    type="text"
                                    id="torq30_300FlxR"
                                    value={bioDex.torq30_300FlxR}
                                    onChange={props.handler}
                                    name="bioDex"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="center-bgcolor-lgray">TORQ @0.18 SEG</td>
                            <td className="in">N-M</td>
                            <td className="center-bgcolor-lgray in">
                                <input
                                    className="center-input-bio"
                                    type="text"
                                    id="torq18_60ExtL"
                                    value={bioDex.torq18_60ExtL}
                                    onChange={props.handler}
                                    name="bioDex"
                                />
                            </td>
                            <td className="in">
                                <input
                                    className="center-input-bio"
                                    type="text"
                                    id="torq18_60ExtR"
                                    value={bioDex.torq18_60ExtR}
                                    onChange={props.handler}
                                    name="bioDex"
                                />
                            </td>
                            <td className="center-bgcolor-lgray in">
                                <input
                                    className="center-input-bio"
                                    type="text"
                                    id="torq18_60FlxL"
                                    value={bioDex.torq18_60FlxL}
                                    onChange={props.handler}
                                    name="bioDex"
                                />
                            </td>
                            <td className="in">
                                <input
                                    className="center-input-bio"
                                    type="text"
                                    id="torq18_60FlxR"
                                    value={bioDex.torq18_60FlxR}
                                    onChange={props.handler}
                                    name="bioDex"
                                />
                            </td>
                            <td className="center-bgcolor-lgray in">
                                <input
                                    className="center-input-bio"
                                    type="text"
                                    id="torq18_180ExtL"
                                    value={bioDex.torq18_180ExtL}
                                    onChange={props.handler}
                                    name="bioDex"
                                />
                            </td>
                            <td className="in">
                                <input
                                    className="center-input-bio"
                                    type="text"
                                    id="torq18_180ExtR"
                                    value={bioDex.torq18_180ExtR}
                                    onChange={props.handler}
                                    name="bioDex"
                                />
                            </td>
                            <td className="center-bgcolor-lgray in">
                                <input
                                    className="center-input-bio"
                                    type="text"
                                    id="torq18_180FlxL"
                                    value={bioDex.torq18_180FlxL}
                                    onChange={props.handler}
                                    name="bioDex"
                                />
                            </td>
                            <td className="in">
                                <input
                                    className="center-input-bio"
                                    type="text"
                                    id="torq18_180FlxR"
                                    value={bioDex.torq18_180FlxR}
                                    onChange={props.handler}
                                    name="bioDex"
                                />
                            </td>
                            <td className="center-bgcolor-lgray in">
                                <input
                                    className="center-input-bio"
                                    type="text"
                                    id="torq18_300ExtL"
                                    value={bioDex.torq18_300ExtL}
                                    onChange={props.handler}
                                    name="bioDex"
                                />
                            </td>
                            <td className="in">
                                <input
                                    className="center-input-bio"
                                    type="text"
                                    id="torq18_300ExtR"
                                    value={bioDex.torq18_300ExtR}
                                    onChange={props.handler}
                                    name="bioDex"
                                />
                            </td>
                            <td className="center-bgcolor-lgray in">
                                <input
                                    className="center-input-bio"
                                    type="text"
                                    id="torq18_300FlxL"
                                    value={bioDex.torq18_300FlxL}
                                    onChange={props.handler}
                                    name="bioDex"
                                />
                            </td>
                            <td className="in">
                                <input
                                    className="center-input-bio"
                                    type="text"
                                    id="torq18_300FlxR"
                                    value={bioDex.torq18_300FlxR}
                                    onChange={props.handler}
                                    name="bioDex"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="center-bgcolor-lgray">COFEE. OF VAR.</td>
                            <td className="in">%</td>
                            <td className="center-bgcolor-lgray in">
                                <input
                                    className="center-input-bio"
                                    type="text"
                                    id="cofe60ExtL"
                                    value={bioDex.cofe60ExtL}
                                    onChange={props.handler}
                                    name="bioDex"
                                />
                            </td>
                            <td className="in">
                                <input
                                    className="center-input-bio"
                                    type="text"
                                    id="cofe60ExtR"
                                    value={bioDex.cofe60ExtR}
                                    onChange={props.handler}
                                    name="bioDex"
                                />
                            </td>
                            <td className="center-bgcolor-lgray in">
                                <input
                                    className="center-input-bio"
                                    type="text"
                                    id="cofe60FlxL"
                                    value={bioDex.cofe60FlxL}
                                    onChange={props.handler}
                                    name="bioDex"
                                />
                            </td>
                            <td className="in">
                                <input
                                    className="center-input-bio"
                                    type="text"
                                    id="cofe60FlxR"
                                    value={bioDex.cofe60FlxR}
                                    onChange={props.handler}
                                    name="bioDex"
                                />
                            </td>
                            <td className="center-bgcolor-lgray in">
                                <input
                                    className="center-input-bio"
                                    type="text"
                                    id="cofe180ExtL"
                                    value={bioDex.cofe180ExtL}
                                    onChange={props.handler}
                                    name="bioDex"
                                />
                            </td>
                            <td className="in">
                                <input
                                    className="center-input-bio"
                                    type="text"
                                    id="cofe180ExtR"
                                    value={bioDex.cofe180ExtR}
                                    onChange={props.handler}
                                    name="bioDex"
                                />
                            </td>
                            <td className="center-bgcolor-lgray in">
                                <input
                                    className="center-input-bio"
                                    type="text"
                                    id="cofe180FlxL"
                                    value={bioDex.cofe180FlxL}
                                    onChange={props.handler}
                                    name="bioDex"
                                />
                            </td>
                            <td className="in">
                                <input
                                    className="center-input-bio"
                                    type="text"
                                    id="cofe180FlxR"
                                    value={bioDex.cofe180FlxR}
                                    onChange={props.handler}
                                    name="bioDex"
                                />
                            </td>
                            <td className="center-bgcolor-lgray in">
                                <input
                                    className="center-input-bio"
                                    type="text"
                                    id="cofe300ExtL"
                                    value={bioDex.cofe300ExtL}
                                    onChange={props.handler}
                                    name="bioDex"
                                />
                            </td>
                            <td className="in">
                                <input
                                    className="center-input-bio"
                                    type="text"
                                    id="cofe300ExtR"
                                    value={bioDex.cofe300ExtR}
                                    onChange={props.handler}
                                    name="bioDex"
                                />
                            </td>
                            <td className="center-bgcolor-lgray in">
                                <input
                                    className="center-input-bio"
                                    type="text"
                                    id="cofe300FlxL"
                                    value={bioDex.cofe300FlxL}
                                    onChange={props.handler}
                                    name="bioDex"
                                />
                            </td>
                            <td className="in">
                                <input
                                    className="center-input-bio"
                                    type="text"
                                    id="cofe300FlxR"
                                    value={bioDex.cofe300FlxR}
                                    onChange={props.handler}
                                    name="bioDex"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="center-bgcolor-lgray">TOTAL WORK</td>
                            <td className="in">J</td>
                            <td className="center-bgcolor-lgray in">
                                <input
                                    className="center-input-bio"
                                    type="text"
                                    id="totWo60ExtL"
                                    value={bioDex.totWo60ExtL}
                                    onChange={props.handler}
                                    name="bioDex"
                                />
                            </td>
                            <td className="in">
                                <input
                                    className="center-input-bio"
                                    type="text"
                                    id="totWo60ExtR"
                                    value={bioDex.totWo60ExtR}
                                    onChange={props.handler}
                                    name="bioDex"
                                />
                            </td>
                            <td className="center-bgcolor-lgray in">
                                <input
                                    className="center-input-bio"
                                    type="text"
                                    id="totWo60FlxL"
                                    value={bioDex.totWo60FlxL}
                                    onChange={props.handler}
                                    name="bioDex"
                                />
                            </td>
                            <td className="in">
                                <input
                                    className="center-input-bio"
                                    type="text"
                                    id="totWo60FlxR"
                                    value={bioDex.totWo60FlxR}
                                    onChange={props.handler}
                                    name="bioDex"
                                />
                            </td>
                            <td className="center-bgcolor-lgray in">
                                <input
                                    className="center-input-bio"
                                    type="text"
                                    id="totWo180ExtL"
                                    value={bioDex.totWo180ExtL}
                                    onChange={props.handler}
                                    name="bioDex"
                                />
                            </td>
                            <td className="in">
                                <input
                                    className="center-input-bio"
                                    type="text"
                                    id="totWo180ExtR"
                                    value={bioDex.totWo180ExtR}
                                    onChange={props.handler}
                                    name="bioDex"
                                />
                            </td>
                            <td className="center-bgcolor-lgray in">
                                <input
                                    className="center-input-bio"
                                    type="text"
                                    id="totWo180FlxL"
                                    value={bioDex.totWo180FlxL}
                                    onChange={props.handler}
                                    name="bioDex"
                                />
                            </td>
                            <td className="in">
                                <input
                                    className="center-input-bio"
                                    type="text"
                                    id="totWo180FlxR"
                                    value={bioDex.totWo180FlxR}
                                    onChange={props.handler}
                                    name="bioDex"
                                />
                            </td>
                            <td className="center-bgcolor-lgray in">
                                <input
                                    className="center-input-bio"
                                    type="text"
                                    id="totWo300ExtL"
                                    value={bioDex.totWo300ExtL}
                                    onChange={props.handler}
                                    name="bioDex"
                                />
                            </td>
                            <td className="in">
                                <input
                                    className="center-input-bio"
                                    type="text"
                                    id="totWo300ExtR"
                                    value={bioDex.totWo300ExtR}
                                    onChange={props.handler}
                                    name="bioDex"
                                />
                            </td>
                            <td className="center-bgcolor-lgray in">
                                <input
                                    className="center-input-bio"
                                    type="text"
                                    id="totWo300FlxL"
                                    value={bioDex.totWo300FlxL}
                                    onChange={props.handler}
                                    name="bioDex"
                                />
                            </td>
                            <td className="in">
                                <input
                                    className="center-input-bio"
                                    type="text"
                                    id="totWo300FlxR"
                                    value={bioDex.totWo300FlxR}
                                    onChange={props.handler}
                                    name="bioDex"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="center-bgcolor-lgray">WORK FATIGUE</td>
                            <td className="in">%</td>
                            <td className="center-bgcolor-lgray in">
                                <input
                                    className="center-input-bio"
                                    type="text"
                                    id="workFat60ExtL"
                                    value={bioDex.workFat60ExtL}
                                    onChange={props.handler}
                                    name="bioDex"
                                />
                            </td>
                            <td className="in">
                                <input
                                    className="center-input-bio"
                                    type="text"
                                    id="workFat60ExtR"
                                    value={bioDex.workFat60ExtR}
                                    onChange={props.handler}
                                    name="bioDex"
                                />
                            </td>
                            <td className="center-bgcolor-lgray in">
                                <input
                                    className="center-input-bio"
                                    type="text"
                                    id="workFat60FlxL"
                                    value={bioDex.workFat60FlxL}
                                    onChange={props.handler}
                                    name="bioDex"
                                />
                            </td>
                            <td className="in">
                                <input
                                    className="center-input-bio"
                                    type="text"
                                    id="workFat60FlxR"
                                    value={bioDex.workFat60FlxR}
                                    onChange={props.handler}
                                    name="bioDex"
                                />
                            </td>
                            <td className="center-bgcolor-lgray in">
                                <input
                                    className="center-input-bio"
                                    type="text"
                                    id="workFat180ExtL"
                                    value={bioDex.workFat180ExtL}
                                    onChange={props.handler}
                                    name="bioDex"
                                />
                            </td>
                            <td className="in">
                                <input
                                    className="center-input-bio"
                                    type="text"
                                    id="workFat180ExtR"
                                    value={bioDex.workFat180ExtR}
                                    onChange={props.handler}
                                    name="bioDex"
                                />
                            </td>
                            <td className="center-bgcolor-lgray in">
                                <input
                                    className="center-input-bio"
                                    type="text"
                                    id="workFat180FlxL"
                                    value={bioDex.workFat180FlxL}
                                    onChange={props.handler}
                                    name="bioDex"
                                />
                            </td>
                            <td className="in">
                                <input
                                    className="center-input-bio"
                                    type="text"
                                    id="workFat180FlxR"
                                    value={bioDex.workFat180FlxR}
                                    onChange={props.handler}
                                    name="bioDex"
                                />
                            </td>
                            <td className="center-bgcolor-lgray in">
                                <input
                                    className="center-input-bio"
                                    type="text"
                                    id="workFat300ExtL"
                                    value={bioDex.workFat300ExtL}
                                    onChange={props.handler}
                                    name="bioDex"
                                />
                            </td>
                            <td className="in">
                                <input
                                    className="center-input-bio"
                                    type="text"
                                    id="workFat300ExtR"
                                    value={bioDex.workFat300ExtR}
                                    onChange={props.handler}
                                    name="bioDex"
                                />
                            </td>
                            <td className="center-bgcolor-lgray in">
                                <input
                                    className="center-input-bio"
                                    type="text"
                                    id="workFat300FlxL"
                                    value={bioDex.workFat300FlxL}
                                    onChange={props.handler}
                                    name="bioDex"
                                />
                            </td>
                            <td className="in">
                                <input
                                    className="center-input-bio"
                                    type="text"
                                    id="workFat300FlxR"
                                    value={bioDex.workFat300FlxR}
                                    onChange={props.handler}
                                    name="bioDex"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="center-bgcolor-lgray">AVG. POWER</td>
                            <td className="in">WATTS</td>
                            <td className="center-bgcolor-lgray in">
                                <input
                                    className="center-input-bio"
                                    type="text"
                                    id="avgPow60ExtL"
                                    value={bioDex.avgPow60ExtL}
                                    onChange={props.handler}
                                    name="bioDex"
                                />
                            </td>
                            <td className="in">
                                <input
                                    className="center-input-bio"
                                    type="text"
                                    id="avgPow60ExtR"
                                    value={bioDex.avgPow60ExtR}
                                    onChange={props.handler}
                                    name="bioDex"
                                />
                            </td>
                            <td className="center-bgcolor-lgray in">
                                <input
                                    className="center-input-bio"
                                    type="text"
                                    id="avgPow60FlxL"
                                    value={bioDex.avgPow60FlxL}
                                    onChange={props.handler}
                                    name="bioDex"
                                />
                            </td>
                            <td className="in">
                                <input
                                    className="center-input-bio"
                                    type="text"
                                    id="avgPow60FlxR"
                                    value={bioDex.avgPow60FlxR}
                                    onChange={props.handler}
                                    name="bioDex"
                                />
                            </td>
                            <td className="center-bgcolor-lgray in">
                                <input
                                    className="center-input-bio"
                                    type="text"
                                    id="avgPow180ExtL"
                                    value={bioDex.avgPow180ExtL}
                                    onChange={props.handler}
                                    name="bioDex"
                                />
                            </td>
                            <td className="in">
                                <input
                                    className="center-input-bio"
                                    type="text"
                                    id="avgPow180ExtR"
                                    value={bioDex.avgPow180ExtR}
                                    onChange={props.handler}
                                    name="bioDex"
                                />
                            </td>
                            <td className="center-bgcolor-lgray in">
                                <input
                                    className="center-input-bio"
                                    type="text"
                                    id="avgPow180FlxL"
                                    value={bioDex.avgPow180FlxL}
                                    onChange={props.handler}
                                    name="bioDex"
                                />
                            </td>
                            <td className="in">
                                <input
                                    className="center-input-bio"
                                    type="text"
                                    id="avgPow180FlxR"
                                    value={bioDex.avgPow180FlxR}
                                    onChange={props.handler}
                                    name="bioDex"
                                />
                            </td>
                            <td className="center-bgcolor-lgray in">
                                <input
                                    className="center-input-bio"
                                    type="text"
                                    id="avgPow300ExtL"
                                    value={bioDex.avgPow300ExtL}
                                    onChange={props.handler}
                                    name="bioDex"
                                />
                            </td>
                            <td className="in">
                                <input
                                    className="center-input-bio"
                                    type="text"
                                    id="avgPow300ExtR"
                                    value={bioDex.avgPow300ExtR}
                                    onChange={props.handler}
                                    name="bioDex"
                                />
                            </td>
                            <td className="center-bgcolor-lgray in">
                                <input
                                    className="center-input-bio"
                                    type="text"
                                    id="avgPow300FlxL"
                                    value={bioDex.avgPow300FlxL}
                                    onChange={props.handler}
                                    name="bioDex"
                                />
                            </td>
                            <td className="in">
                                <input
                                    className="center-input-bio"
                                    type="text"
                                    id="avgPow300FlxR"
                                    value={bioDex.avgPow300FlxR}
                                    onChange={props.handler}
                                    name="bioDex"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="center-bgcolor-lgray">AGON/ANTAG RATIO</td>
                            <td className="in">%</td>
                            <td className="center-bgcolor-lgray in">
                                <input
                                    className="center-input-bio"
                                    type="text"
                                    id="agan60ExtL"
                                    value={bioDex.agan60ExtL}
                                    onChange={props.handler}
                                    name="bioDex"
                                />
                            </td>
                            <td className="in">
                                <input
                                    className="center-input-bio"
                                    type="text"
                                    id="agan60ExtR"
                                    value={bioDex.agan60ExtR}
                                    onChange={props.handler}
                                    name="bioDex"
                                />
                            </td>
                            <td className="center-bgcolor-lgray in">
                                <input
                                    className="center-input-bio"
                                    type="text"
                                    id="agan60FlxL"
                                    value={bioDex.agan60FlxL}
                                    onChange={props.handler}
                                    name="bioDex"
                                />
                            </td>
                            <td className="in">
                                <input
                                    className="center-input-bio"
                                    type="text"
                                    id="agan60FlxR"
                                    value={bioDex.agan60FlxR}
                                    onChange={props.handler}
                                    name="bioDex"
                                />
                            </td>
                            <td className="center-bgcolor-lgray in">
                                <input
                                    className="center-input-bio"
                                    type="text"
                                    id="agan180ExtL"
                                    value={bioDex.agan180ExtL}
                                    onChange={props.handler}
                                    name="bioDex"
                                />
                            </td>
                            <td className="in">
                                <input
                                    className="center-input-bio"
                                    type="text"
                                    id="agan180ExtR"
                                    value={bioDex.agan180ExtR}
                                    onChange={props.handler}
                                    name="bioDex"
                                />
                            </td>
                            <td className="center-bgcolor-lgray in">
                                <input
                                    className="center-input-bio"
                                    type="text"
                                    id="agan180FlxL"
                                    value={bioDex.agan180FlxL}
                                    onChange={props.handler}
                                    name="bioDex"
                                />
                            </td>
                            <td className="in">
                                <input
                                    className="center-input-bio"
                                    type="text"
                                    id="agan180FlxR"
                                    value={bioDex.agan180FlxR}
                                    onChange={props.handler}
                                    name="bioDex"
                                />
                            </td>
                            <td className="center-bgcolor-lgray in">
                                <input
                                    className="center-input-bio"
                                    type="text"
                                    id="agan300ExtL"
                                    value={bioDex.agan300ExtL}
                                    onChange={props.handler}
                                    name="bioDex"
                                />
                            </td>
                            <td className="in">
                                <input
                                    className="center-input-bio"
                                    type="text"
                                    id="agan300ExtR"
                                    value={bioDex.agan300ExtR}
                                    onChange={props.handler}
                                    name="bioDex"
                                />
                            </td>
                            <td className="center-bgcolor-lgray in">
                                <input
                                    className="center-input-bio"
                                    type="text"
                                    id="agan300FlxL"
                                    value={bioDex.agan300FlxL}
                                    onChange={props.handler}
                                    name="bioDex"
                                />
                            </td>
                            <td className="in">
                                <input
                                    className="center-input-bio"
                                    type="text"
                                    id="agan300FlxR"
                                    value={bioDex.agan300FlxR}
                                    onChange={props.handler}
                                    name="bioDex"
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div id="uploadedBDImages" className="center-mauto">
                {imageElem}
            </div>
        </>
    );
}

function makeOcrMatrix(ocr: any) {
    let maxX = 0;
    let maxY = 0;
    let words: { [k: string]: string } = {};
    let row: [string[]];
    for (let i = 0; i < ocr?.length; i++) {
        let elem = ocr[i];
        for (let j = 0; j < elem.words.length; j++) {
            let word = elem.words[j];
            if (maxX < word._bounds.x) maxX = Math.floor(word._bounds.x);
            if (maxY < word._bounds.y) maxY = Math.floor(word._bounds.y / 10);
            words[Math.floor(word._bounds.x) + '_' + Math.floor(word._bounds.y / 10)] = word._value;
        }
    }
    let result = '';
    let table = new Array();
    for (let y = 0; y < maxY; y++) {
        let row = new Array();
        for (let x = 0; x < maxX; x++) {
            let val = words[x + '_' + y];
            if (val) {
                result += val + '_';
                row.push(val);
            } /*  else {
                row.push(' ');
            } */
        }
        if (row.length > 0) table.push(row);
        result += '\n';
    }
    //console.log(table);
    //console.log(result);
    return table;
}

function contractValue(seeds: string[], matrix: any, contractSeq: number) {
    let contractedVal = '';
    if (matrix) {
        let targetRows = new Array();
        let size = matrix.length;
        let seedSize = seeds.length;

        // contract targetRows
        for (let i = 0; i < size; i++) {
            let source = matrix[i];
            let sourceStr = source.toString().toLowerCase();

            for (let x = 0; x < seedSize; x++) {
                let seed = seeds[x];
                if (sourceStr.indexOf(seed) != -1) {
                    targetRows.push(source);
                    break;
                }
            }
        }
        let contractArr = new Array();
        for (let i = 0; i < targetRows.length; i++) {
            let targetArr = targetRows[i];
            for (let j = 0; j < targetArr.length; j++) {
                if (parseFloat(targetArr[j])) contractArr.push(parseFloat(targetArr[j]));
            }
        }
        //console.log(contractArr);
        if (contractSeq == 1) {
            contractedVal = contractArr[0];
        } else if (contractSeq == 2) {
            if (contractArr.length > 1) contractedVal = contractArr[1];
        }

        return contractedVal;
    }
}

/* pt60ExtL: string;
pt60ExtR: string;
ptb60ExtL: string;
ptb60ExtR: string;
torq30_60ExtL: string;
torq30_60ExtR: string;
torq18_60ExtL: string;
torq18_60ExtR: string;
cofe60ExtL: string;
cofe60ExtR: string;
totWo60ExtL: string;
totWo60ExtR: string;
workFat60ExtL: string;
workFat60ExtR: string;
avgPow60ExtL: string;
avgPow60ExtR: string;
agan60ExtL: string;
agan60ExtR: string;

pt60FlxL: string;
pt60FlxR: string;
ptb60FlxL: string;
ptb60FlxR: string;
torq30_60FlxL: string;
torq30_60FlxR: string;
torq18_60FlxL: string;
torq18_60FlxR: string;
cofe60FlxL: string;
cofe60FlxR: string;
totWo60FlxL: string;
totWo60FlxR: string;
workFat60FlxL: string;
workFat60FlxR: string;
avgPow60FlxL: string;
avgPow60FlxR: string;
agan60FlxL: string;
agan60FlxR: string;
 */
// 180 , 300 추가 필요

//uploadBDFile: string;
