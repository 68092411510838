import React from 'react';
import RetrieveUser from '../../../../domain/user/RetrieveUser';
import Roles from '../../../../domain/user/Roles';
import RehabManageUserTableTr from './RehabManageUserTableTr';

interface RehabManageUserTableProps {
    hasCenterOperAuth?: boolean;
    users?: RetrieveUser[];
    roles?: Roles[];
}

function RehabManageUserTable(props: RehabManageUserTableProps) {
    return (
        <table className="center-list-table" cellPadding="0" cellSpacing="0">
            <thead>
                <tr>
                    <td>No.</td>
                    <td>회원등급</td>
                    <td>아이디</td>
                    <td>이름</td>
                    <td>생성일</td>
                    <td>사용자권한</td>
                    {props.hasCenterOperAuth ? <td>관리</td> : <></>}
                </tr>
            </thead>
            <tbody>
                {props.users?.map((user: RetrieveUser, index: number) => {
                    return (
                        <RehabManageUserTableTr
                            hasCenterOperAuth={props.hasCenterOperAuth}
                            user={user}
                            roles={props.roles}
                            idx={index}
                        ></RehabManageUserTableTr>
                    );
                })}
            </tbody>
        </table>
    );
}

export default RehabManageUserTable;
