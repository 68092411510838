import { useEffect, useState } from 'react';
import Event from '../../domain/event/Event';
import EventServiceImpl from '../../service/event/impl/EventServiceImpl';
const eventService = new EventServiceImpl();

function useEventRegiste(event?: Event) {
    const [registedEvent, setRegistedEvent] = useState<Event>();

    useEffect(() => {
        if (event) {
            eventService.registeEvent(event).then((registedEvent) => {
                setRegistedEvent(registedEvent);
            });
        }
    }, [event]);

    return { registedEvent, setRegistedEvent };
}

export default useEventRegiste;
