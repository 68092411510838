import { JsonProperty, JsonObject } from 'json2typescript';
import Code from '../code/Code';

@JsonObject('couponStatusOfEvent')
class CouponStatusOfEvent {
    @JsonProperty('regionCodeId')
    regionCodeId?: number;
    @JsonProperty('regionCode')
    regionCode?: Code;

    @JsonProperty('eventCode')
    eventCode?: Code;

    @JsonProperty('couponCode')
    couponCode?: Code;

    @JsonProperty('cnt')
    cnt?: number;
}

export default CouponStatusOfEvent;
