import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import Prescription from '../../../../../domain/Prescription';
import Exercise from '../../../../../domain/Exercise';
import ActionComponent from '../../../ActionComponent';
import Code from '../../../../../../../domain/code/Code';
import PrescriptionServiceImpl from '../../../../../service/impl/PrescriptionServiceImpl';
import ItemsPrescription from '../../../../../domain/prescription/ItemsPrescription';

interface IProps {
    prescription: Prescription;
}
const prescriptionService = new PrescriptionServiceImpl();
let retrievedExercises: Exercise[];

const ByPartPrescriptionFormComponent = (props: IProps) => {
    let bodyPrescription = props.prescription.bodyPrescription;
    let selectedExercises = bodyPrescription.exercises;
    const [registeredItems, setRegisteredItems] = useState<Code[]>([]);
    const [groupedExercises, setGroupedExercises] = useState<Exercise[]>();

    const handler = (evt: any) => {
        let target = evt.target;
        let inputVal = target.value;
        let checked = target.checked;
        let localName = target.localName;
        let sendObj = { target: {} };

        if (localName === 'button') {
            // 종목 변경으로 이전 입력 정보 초기화
            // 선택 된 Exercise 초기화
            // applyedLevel 1단계로 변경
            var actions = ActionComponent();
            var targetId = target.id;
            var targetName = target.name;

            let btns = document.getElementsByName(targetName);
            btns.forEach((elem) => {
                elem.classList.remove('gra-on');
            });

            target.classList.add('gra-on');
            // 운동 종목 별 처방 정보 초기화
            exerciseForItem = [];
            // 화면 탭 초기화 (1단계로 이동)

            if (groupedExercises)
                Object.entries(groupedExercises).map(([key, value]) => {
                    if (key === targetId) exerciseForItem = exerciseForItem.concat(value);
                });

            exerciseTab(exerciseForItem, '1');
            exerciseTab(exerciseForItem, '2');
            exerciseTab(exerciseForItem, '3');

            actions.ballOpen();
            actions.step1Open();

            let tempPrescription = new ItemsPrescription();
            tempPrescription.applyedLevel = '1';
            tempPrescription.exerItem = targetId;

            let valueSet = { id: 'exerItem', name: 'bodyPrescription', value: tempPrescription };
            sendObj.target = valueSet;
        } else if (localName === 'a') {
            // 적용단계 (applyedLevel 변경으로) 종목 제외한 내용 초기
            let action = ActionComponent();
            let targetId = target.id;

            if (targetId === 'tab-part1') {
                inputVal = '1';
                action.part1Open();
            } else if (targetId === 'tab-part2') {
                inputVal = '2';
                action.part2Open();
            } else if (targetId === 'tab-part3') {
                inputVal = '3';
                action.part3Open();
            }

            let tempPrescription = new ItemsPrescription();
            tempPrescription.applyedLevel = inputVal;
            tempPrescription.exerItem = bodyPrescription.exerItem;

            let valueSet = { id: 'applyedLevel', name: 'bodyPrescription', value: tempPrescription };
            sendObj.target = valueSet;
        } else if (localName === 'input') {
            //운동 처방 추가 / 제거 로직
            let newObj: Exercise[];
            if (typeof checked != 'undefined' && !checked) {
                newObj = selectedExercises.filter((exercise) => {
                    return exercise.exerNo.toString() !== inputVal.toString();
                });
            } else {
                newObj = retrievedExercises.filter((exercise) => {
                    return exercise.exerNo.toString() === inputVal.toString();
                });
                newObj = selectedExercises.concat(newObj);
            }

            let target = { id: 'exercises', name: evt.target.name, value: newObj };
            sendObj.target = target;
        } else {
            // 추가의견 관리
            let target = { id: 'opinion', name: evt.target.name, value: inputVal };
            sendObj.target = target;
        }
    };

    useEffect(() => {
        let pagination = { size: 100000, page: 0 };
        prescriptionService.getExerciseListByTypes('42', pagination).then((exercises: { data: Exercise[]; totalSize: number }) => {
            let keys: string[] = [];
            let retrievedItemsPerItemNo: Code[] = [];
            retrievedExercises = exercises.data;
            retrievedExercises.map((item) => {
                if (item.exerItemCode) {
                    let itKey = item.exerItemCode.codeId.toString();
                    if (itKey && !keys.includes(itKey)) {
                        keys.push(itKey);
                        retrievedItemsPerItemNo.push(item.exerItemCode);
                    }
                }
            });

            let grouped = retrievedExercises.reduce(function (exercise, it) {
                exercise[it.exerItem] = exercise[it.exerItem] || [];
                exercise[it.exerItem].push(it);
                return exercise;
            }, Object.create(null));

            setRegisteredItems(retrievedItemsPerItemNo);
            setGroupedExercises(grouped);
        });
    }, []);

    const itemTable = (types: Code[]) => {
        let typeTable = () => {
            var tbArr: Code[][] = [];
            var rowArr: Code[] = [];
            var rowCnt = 10;
            var length = types.length;

            for (let i = 0; i < length; i++) {
                let row = i % rowCnt;
                if (row === 0) {
                    rowArr = [types[i]];
                    tbArr.push(rowArr);
                } else {
                    rowArr.push(types[i]);
                }
            }

            return tbArr;
        };

        let typeBtns = typeTable().map((columns, idx) => {
            return (
                <div className="flex-basic" style={{ width: '100%' }} key={idx + '_itm_btn'}>
                    {columns.map((column, i) => {
                        var name = column.name.substring(0, 3);
                        return (
                            <button
                                id={column.codeId.toString()}
                                name="types"
                                className={bodyPrescription.exerItem === column.codeId.toString() ? 'center-button-gra gra-on' : 'center-button-gra'}
                                onClick={handler}
                                style={{ flex: 1 }}
                                key={i + 'item'}
                                value={column.codeId}
                            >
                                {/* <i className="fas fa-basketball-ball"></i>
                                <br /> */}
                                {name}
                            </button>
                        );
                    })}
                </div>
            );
        });

        return typeBtns;
    };

    let exerciseForItem: Exercise[] = [];

    const prescribePart = (prescribe: ItemsPrescription) => {
        let actions = ActionComponent();

        let selectedItemId = prescribe.exerItem;
        let applyedLevel = prescribe.applyedLevel;
        if (groupedExercises)
            Object.entries(groupedExercises).map(([key, value]) => {
                if (key === selectedItemId) exerciseForItem = exerciseForItem.concat(value);
            });

        if (selectedItemId) {
            actions.partOpen();
            if (applyedLevel === '1') actions.part1Open();
            else if (applyedLevel === '2') actions.part2Open();
            else if (applyedLevel === '3') actions.part3Open();
        }

        return (
            <>
                <div className="center-tabbt-s flex">
                    <a href="#" id="tab-part1" className={prescribe.applyedLevel === '1' ? 'on' : ''} onClick={handler}>
                        1단계
                    </a>
                    <a href="#" id="tab-part2" className={prescribe.applyedLevel === '2' ? 'on' : ''} onClick={handler}>
                        2 단계
                    </a>
                    <a href="#" id="tab-part3" className={prescribe.applyedLevel === '3' ? 'on' : ''} onClick={handler}>
                        3 단계
                    </a>
                </div>
                <div id="part1">{exerciseTab(exerciseForItem, '1')}</div>
                <div id="part2">{exerciseTab(exerciseForItem, '2')}</div>
                <div id="part3">{exerciseTab(exerciseForItem, '3')}</div>
            </>
        );
    };

    let exerciseTab = (exercises: Exercise[], level: string) => {
        let displayItems: Exercise[] = [];
        exercises.map((item, key) => {
            if (item.applyedLevel === level) {
                displayItems.push(item);
            }
        });

        let tbElem = <></>;
        if (displayItems.length == 0) {
            tbElem = (
                <>
                    <table className="center-co">
                        <tbody>
                            <tr>
                                <td>
                                    등록 된 운동 처방이 없습니다.
                                    <br />
                                    등록 후 선택해 주세요.!
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <h2 className="center">추가의견</h2>
                    <textarea value={bodyPrescription.opinion} name="bodyPrescription" className="center-textarea-s" onChange={handler}></textarea>
                </>
            );
        } else {
            tbElem = (
                <>
                    <table className="center-co">
                        <tbody>
                            {displayItems.map((item, idx) => {
                                let selectedItems = bodyPrescription.exercises;
                                let checked = false;
                                selectedItems.forEach((sitem) => {
                                    if (item.exerNo === sitem.exerNo) checked = true;
                                });
                                return (
                                    <tr key={idx}>
                                        <td>
                                            <input name="bodyPrescription" value={item.exerNo} type="checkbox" onChange={handler} checked={checked} />
                                        </td>
                                        <td>
                                            <img src={item.refImgPath} />
                                        </td>
                                        <td>
                                            <b>{item.title}</b>
                                            <br />
                                            {item.exerciseWay}
                                        </td>
                                        <td>{item.exerciseDesc}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                    <p></p>
                    <h2 className="center">추가의견</h2>
                    <textarea name="bodyPrescription" className="center-textarea-s" value={bodyPrescription.opinion} onChange={handler}></textarea>
                </>
            );
        }

        return tbElem;
    };

    return (
        <>
            <h1 className="center">손상부위 선택</h1>
            <div className="flex-top">
                <div className="center-w120 body-bg"></div>
                <div className="center-part-btn-group">
                    <p>
                        <button
                            name="bodyPrescription"
                            className={bodyPrescription.exerItem === '56' ? 'center-button-no center-w100  gra-on' : 'center-button-no center-w100'}
                            onClick={handler}
                            id="56"
                        >
                            목
                        </button>
                    </p>
                    <p>
                        <button
                            name="bodyPrescription"
                            className={bodyPrescription.exerItem === '53' ? 'center-button-no center-w100  gra-on' : 'center-button-no center-w100'}
                            onClick={handler}
                            id="53"
                        >
                            등
                        </button>
                    </p>
                    <p>
                        <button
                            name="bodyPrescription"
                            className={bodyPrescription.exerItem === '51' ? 'center-button-no center-w100  gra-on' : 'center-button-no center-w100'}
                            onClick={handler}
                            id="51"
                        >
                            어깨
                        </button>
                    </p>
                    <p>
                        <button
                            name="bodyPrescription"
                            className={bodyPrescription.exerItem === '57' ? 'center-button-no center-w100  gra-on' : 'center-button-no center-w100'}
                            onClick={handler}
                            id="57"
                        >
                            팔꿈치
                        </button>
                    </p>
                    <p>
                        <button
                            name="bodyPrescription"
                            className={bodyPrescription.exerItem === '54' ? 'center-button-no center-w100 gra-on' : 'center-button-no center-w100'}
                            onClick={handler}
                            id="54"
                        >
                            골반
                        </button>
                    </p>
                    <p>
                        <button
                            name="bodyPrescription"
                            className={bodyPrescription.exerItem === '52' ? 'center-button-no center-w100 gra-on' : 'center-button-no center-w100'}
                            onClick={handler}
                            id="52"
                        >
                            무릎
                        </button>
                    </p>
                    <p>
                        <button
                            name="bodyPrescription"
                            className={bodyPrescription.exerItem === '55' ? 'center-button-no center-w100 gra-on' : 'center-button-no center-w100'}
                            onClick={handler}
                            id="55"
                        >
                            발목
                        </button>
                    </p>
                </div>
            </div>
            {/* <!--//flex-top--> */}
            <div id="body-part" className="center-select-prescription">
                <h2 className="center">처방운동 선택</h2>
                {prescribePart(bodyPrescription)}
            </div>
        </>
    );
};

export default ByPartPrescriptionFormComponent;
