class TotalVisitedTeamInfoSearchCondition {
    indexDate?: string;
    parentRegionCodeIds?: string;
    regionCodeIds?: string;
    belongCodeIds?: string;
    classficationCodeIds?: string;
    sportCodeIds?: string;
    yearMonth?: string;
    visitedStartDate?: string;
    visitedEndDate?: string;
    visitedDate?: string;

    constructor(parentRegionCodeIds?: string, regionCodeIds?: string, visitedStartDate?: string, visitedEndDate?: string) {
        this.parentRegionCodeIds = parentRegionCodeIds;
        this.regionCodeIds = regionCodeIds;
        this.visitedStartDate = visitedStartDate;
        this.visitedEndDate = visitedEndDate;
    }
}

export default TotalVisitedTeamInfoSearchCondition;
