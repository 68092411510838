import { useEffect, useState } from 'react';
import UserServiceImpl from '../../service/user/impl/UserServiceImpl';
import UserDetailInfo from '../../domain/user/UserDetailInfo';
import BelongTeam from '../../domain/user/BelongTeam';
const userService = new UserServiceImpl();

function useUserRetrieveByUserNo(userNo?: string) {
    const [userDetailInfo, setUserDetailInfo] = useState<UserDetailInfo>();
    const [userBelongedTeams, setUserBelongedTeams] = useState<BelongTeam[]>();

    useEffect(() => {
        if (userNo) {
            userService.getUserDetailInfo(userNo).then((userDetailInfo: UserDetailInfo) => {
                setUserDetailInfo(userDetailInfo);
            });
            userService.getBelongTeamInfo(userNo).then((belongedTeams: BelongTeam[]) => {
                setUserBelongedTeams(belongedTeams);
            });
        }
    }, [userNo]);

    return {
        userDetailInfo,
        userBelongedTeams,
    };
}

export default useUserRetrieveByUserNo;
